import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { convertADtoBS } from '../../../../../../helper/function/abstract'
import { AbstractJournalAccountAddComponent, mapStateToProps } from './abstract'

class JournalAccountAddComponent extends AbstractJournalAccountAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setTransactionDate = this._setTransactionDate.bind(this)
        this._setAccount = this._setAccount.bind(this)
    }

    _setTransactionDate(data) {
        this._handleChange('date', data)
    }

    _setAccount(key, value, index) {
        let accounts = this.state.accounts
        if (key == 'credit') {
            accounts[index]['debit'] = '0'
        } else if (key == 'debit') {
            accounts[index]['credit'] = '0'
        }
        if (key == 'account') {
            accounts[index]['account'] = value.value
        } else {
            accounts[index][key] = value
        }
        //calculate total
        if (key == 'credit' || key == 'debit') {
            let totalCredit = 0
            let totalDebit = 0

            accounts.map((item) => {
                const credit = item.credit && item.credit != '' ? item.credit : 0
                const debit = item.debit && item.debit != '' ? item.debit : 0
                totalCredit = totalCredit + parseFloat(credit)
                totalDebit = totalDebit + parseFloat(debit)
            })
            this.setState({
                accounts,
                totalCredit,
                totalDebit,
            })
        } else {
            this.setState({
                accounts,
            })
        }
    }

    loadIncomeProductService() {
        const { t } = this.props
        const accounts = this.state.accounts
        const data = accounts.map((item, index) => {
            const credit = parseFloat(item.credit)
            const debit = parseFloat(item.debit)
            let account = null
            this.state.chartOfAccounts.map((el) => {
                const child = el.options.find((o) => o.value == item.account)
                if (child != null) {
                    account = child
                }
            })
            return (
                <tr style={{ position: 'relative' }}>
                    <td>
                        <div>
                            <Select
                                name="account"
                                className="mb-3"
                                style={{ width: '100%' }}
                                options={this.state.chartOfAccounts}
                                placeholder={t('banking:account')}
                                value={account}
                                onChange={(val) => this._setAccount('account', val, index)}
                            />
                            {this.state.accountErrors && (
                                <label className="table-error error text-danger">
                                    {this.state.accountErrors[index].account}
                                </label>
                            )}
                        </div>
                    </td>

                    <td>
                        <Input
                            min={0}
                            type="number"
                            name="debit"
                            className="form-control mb-3"
                            placeholder={t('doubleentry:debit')}
                            value={item.debit}
                            disabled={item.credit > 0 ? true : false}
                            onChange={(key, val) => this._setAccount('debit', val, index)}
                        />
                        {this.state.accountErrors && (
                            <label className="table-error error text-danger">
                                {this.state.accountErrors[index].debit}
                            </label>
                        )}
                    </td>
                    <td>
                        <Input
                            type="text"
                            className="form-control mb-3"
                            name="credit"
                            placeholder={t('doubleentry:credit')}
                            value={item.credit}
                            disabled={item.debit > 0 ? true : false}
                            onChange={(key, val) => this._setAccount('credit', val, index)}
                        />
                        {this.state.accountErrors && (
                            <label className="table-error error text-danger">
                                {this.state.accountErrors[index].credit}
                            </label>
                        )}
                    </td>
                    <td>
                        <Input
                            type="text"
                            className="form-control mb-3"
                            name="description"
                            placeholder={t('common:description')}
                            value={item.description}
                            onChange={(key, val) => this._setAccount('description', val, index)}
                        />
                        {this.state.accountErrors && (
                            <label className="table-error error text-danger">
                                {this.state.accountErrors[index].description}
                            </label>
                        )}
                    </td>

                    <td>
                        <Button
                            onClick={() => this.removeRow(index)}
                            type="button"
                            text={SVG.deleteComment()}
                            cancel={true}
                        />
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="double_entry" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.journalaccount.view}>
                                    {t('doubleentry:journalaccount')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:createjournalentry')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-4 float-left form-group">
                                    <label className="form-title text-left">{t('doubleentry:transactiondate')} *</label>
                                    <DatePicker
                                        placeholderText={t('doubleentry:transactiondate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        defaultValue={this.state.date}
                                        selected={this.state.date}
                                        onChange={this._setTransactionDate}
                                    />
                                    {this.props.school != null && this.props.school.date_format == 'BS' && (
                                        <label className="date-bs">
                                            {this.state.date != ''
                                                ? convertADtoBS(this.state.date) != null
                                                    ? convertADtoBS(this.state.date) + ' BS'
                                                    : 'Invalid Date Range'
                                                : ''}
                                        </label>
                                    )}
                                    {this.state.errors.date && (
                                        <label className="error text-danger">{this.state.errors.date}</label>
                                    )}
                                </div>
                                <div className="col-md-8 float-left form-group">
                                    <label className="form-title">{t('income:reference')}  </label>
                                    <Input
                                        name="reference"
                                        type="text"
                                        placeholder={t('income:reference')}
                                        value={this.state.reference}
                                        onChange={this._handleChange}
                                    />
                                </div>

                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('common:description')} * </label>
                                    <TextArea
                                        name="description"
                                        placeholder={t('common:description')}
                                        value={this.state.description}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.description && (
                                        <label className="error text-danger">{this.state.errors.description}</label>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="main-table">
                        {this.state.accounts.length > 0 && (
                            <div className="table-all table-responsive">

                            <table className="table responsiveTable fee_table">
                                <thead>
                                    <tr>
                                        <th width="30%">{t('sidebar:account')} *</th>
                                        <th>{t('doubleentry:debit')} *</th>
                                        <th>{t('doubleentry:credit')} *</th>
                                        <th>{t('common:description')} *</th>
                                        <th>{t('activity:action')}</th>
                                    </tr>
                                </thead>
                                <tbody>{this.loadIncomeProductService()}</tbody>
                                <tfoot className="tfootstrong">
                                    <tr>
                                        <td colSpan={4}></td>
                                        <td>
                                            <Button
                                                onClick={() => this.addRow()}
                                                type="button"
                                                text={SVG.addTable()}
                                                main={false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">
                                            <strong>{t('accounting:total')}</strong>
                                        </td>
                                        <td>{this.props.school.currency + ' ' + this.state.totalDebit.toFixed(2)}</td>
                                        <td>{this.props.school.currency + ' ' + this.state.totalCredit.toFixed(2)}</td>
                                        <td colSpan={2}></td>
                                    </tr>
                                </tfoot>
                            </table>
                            </div>
                        )}
                        {this.state.accounts.length == 0 && <p className="no-data"> {SVG.nodata()}</p>}
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const JournalAccountAdd = withTranslation()(connect(mapStateToProps)(JournalAccountAddComponent))
export { JournalAccountAdd }
