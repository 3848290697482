import {Component} from 'react'
import {Dispatch} from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { getBankAccount, updateBankAccount} from '~/services/api'


type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractBankAccountEditComponent extends Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state = {
            bankId: '',
            loading: false,
            loading_text: "Loading",
            holder_name:'',
            bank_name:'',
            account_number:'',
            opening_balance:'',
            contact_number:'',
            bank_address:'',
            errors:{
                holder_name: null,
                bank_name:null,
                account_number: null,
                opening_balance: null,
                contact_number: null,
                bank_address:null
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount(){
        this.loadBankAccount()

    }
    _handleChange(key, data){
        this.setState({[key]: data})
    }
    loadBankAccount(){
        const {t} = this.props
        this._handleChange('loading_text', "Loading")
        this._handleChange('loading', true)
        getBankAccount(this.props.match.params.id, this.props.token).then((response)=>{
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                const item = response.data.bankAccount
                this.setState({
                    holder_name: item.holder_name,
                    bank_name:item.bank_name,
                    account_number:item.account_number,
                    opening_balance: item.opening_balance,
                    contact_number: item.contact_number,
                    bank_address:item.bank_address,
                    bankId:item.id 
                })
            } 
            
        })

    }
    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors[item] !=null)
        if(validate.length == 0){
            const data ={
                holder_name:this.state.holder_name,
                bank_name:this.state.bank_name,
                account_number: this.state.account_number,
                opening_balance: this.state.opening_balance,
                contact_number: this.state.contact_number,
                bank_address:this.state.bank_address,
                bankId: this.state.bankId
            }
            this._handleChange('loading', true)
            updateBankAccount(this.state.bankId, data, this.props.token).then((response)=>{
                this._handleChange('loading', false)
                if(response.code == API_SUCCESS){
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.banking.account.view)
                }else{
                    toastMessage('error', response.data)
                    if(response.data){
                        if('errors' in response.data){
                            this.setState({errors: response.data.errors})
                        }
                    }
                }
            })
        }
    }

}
export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}