import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { logout, viewStudent, addStudentAttendance, viewStudentAttendance, updateStudentAttendance, getSection, getSubject } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { PRESENT, ABSENT } from '~/helper/constant/attendance'
import * as STATUS from '~/helper/constant/status'
import { signout } from '~/store'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { APP_URL } from '~/helper/constant/config'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    switchClass: Array,
    students: Array,
    search: String,
}

export class AbstractStudentAttendanceViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            search: null,
            loading: false,
            loadingText: 'Loading',
            students: [],
            studentAttendance: [],
            switchClass: [],
            absentStudents: [],
            section_id: this.props.match.params.section_id,
            attendance_type: this.props.match.params.attendance_type == 'general' ? 1 : 0,
            attendence_type_string: this.props.match.params.attendance_type,
            subject_id: this.props.match.params.subject_id == 0 ? null : this.props.match.params.subject_id,
            attendence_recorded: false,
            uploadedFiles: {},
            media: {},
            deletedMedia: {},
            remarks: '',
            subtitle: '',
            reviewModal: false,
            date: this.props.match.params.date,
        }
        this._handleChange = this._handleChange.bind(this)
        // this.loadJS = this.loadJS.bind(this);
        this.setAttendance = this.setAttendance.bind(this)
        this.updateStudentAttendance = this.updateStudentAttendance.bind(this)
        this.toggleReviewModal = this.toggleReviewModal.bind(this)
    }

    componentDidMount() {
        this.loadSection()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.user_type != prevProps.match.params.user_type || this.props.match.params.role_id != prevProps.match.params.role_id || this.props.match.params.date != prevProps.match.params.date) {
            this.setState(
                {
                    section_id: this.props.match.params.section_id,
                    attendance_type: this.props.match.params.attendance_type == 'general' ? 1 : 0,
                    attendence_type_string: this.props.match.params.attendance_type,
                    subject_id: this.props.match.params.subject_id == 0 ? null : this.props.match.params.subject_id,
                    date: this.props.match.params.date,
                },
                () => {
                    this.loadSection()
                }
            )
        }
    }

    toggleReviewModal() {
        this.setState({
            reviewModal: !this.state.reviewModal,
        })
    }

    loadSection() {
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Loading')
        getSection(this.state.section_id, this.props.token).then((response) => {
            let subtitle = this.state.subtitle
            if (response.code == API_SUCCESS) {
                const section = response.data.section
                subtitle = section.classes.name + ' - ' + section.name

                this.setState(
                    {
                        subtitle,
                    },
                    () => {
                        if (this.state.subject_id != null) {
                            this.loadSubject()
                        } else {
                            this.getAttendance()
                        }
                    }
                )
            }
        })
    }

    loadSubject() {
        getSubject(this.state.subject_id, this.props.token).then((response) => {
            let subtitle = this.state.subtitle
            if (response.code == API_SUCCESS) {
                const subject = response.data.subject
                subtitle = subject.name + ' : ' + subtitle

                this.setState(
                    {
                        subtitle,
                    },
                    () => this.getAttendance()
                )
            }
        })
    }

    getAttendance() {
        let subject_id = null
        if (this.state.subject_id != null) {
            subject_id = this.state.subject_id
        }
        viewStudentAttendance(this.state.date, this.state.attendence_type_string, this.state.section_id, subject_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let studentAttendance = []
            if (response.code == API_SUCCESS) {
                response.data.attendance.map((item, index) => {
                    let attendanceData = {}
                    attendanceData['attendance_id'] = item.id
                    attendanceData['status'] = item.status
                    attendanceData['user_school_id'] = item.user_school_id
                    attendanceData['gallery'] = item.gallery != null ? item.gallery.media[0] : null
                    attendanceData['remarks'] = item.remarks
                    studentAttendance.push(attendanceData)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState(
                {
                    studentAttendance,
                },
                () => {
                    this.loadStudent()
                }
            )
        })
    }

    loadStudent() {
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Loading')
        let studentAttendance = this.state.studentAttendance
        viewStudent(this.state.section_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let students = []
            let user_school_id = null
            if (response.code == API_SUCCESS) {
                const activeStudent = response.data.student.filter((el) => {
                    return el.status == STATUS.ACTIVE
                })
                activeStudent.map((item, index) => {
                    const data: StudentDTO = item
                    let initial = data.user.name ? data.user.name.substring(0, 1) : data.user.email.substring(0, 1)
                    if (data.user.user_detail) {
                        initial = data.user.user_detail.profile != null ? data.user.user_detail.profile : initial
                    }
                    data.user['initial'] = initial
                    user_school_id = data.id
                    data.user['user_school_id'] = user_school_id
                    const user_data = studentAttendance.find((el) => {
                        if (el.user_school_id == user_school_id) {
                            return el
                        }
                    })
                    data.user['status'] = user_data && user_data.status == 2 ? false : true
                    data.user['remarks'] = user_data ? user_data.remarks : ''
                    data.user['attendance_id'] = user_data ? user_data.attendance_id : null
                    data.user['media'] = user_data ? user_data.gallery : null
                    students.push(data.user)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState({
                students,
                loaded: true,
            })
        })
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    setAttendance(current) {
        const students = []
        this.state.students.find((item, index) => {
            if (index == current) {
                item.status = !item.status
            }
            students.push(item)
        })
        this.setState({
            students,
        })
    }

    submitAttendance() {
        const component = this
        return new Promise(function (resolve, reject) {
            let students = component.state.students
            const attendance = []
            component.state.students.map((item) => {
                const attendanceData = {
                    user_school_id: item.user_school_id,
                    status: item.status,
                }
                if (component.state.subject_id != null) {
                    attendanceData['subject_id'] = component.state.subject_id
                }
                attendance.push(attendanceData)
            })
            const data = {
                date: component.state.date,
                attendance,
            }
            addStudentAttendance(data, component.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.getAttendance()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(true)
                }
            })
        })
    }

    updateStudentAttendance(index) {
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            component._handleChange('loadingText', 'Saving')
            const student = component.state.students[index]
            let studentAttendance = component.state.studentAttendance
            const data = {
                deletedMedia: component.state.deletedMedia,
            }
            data['media'] = component.state.media
            data['remarks'] = component.state.remarks != '' ? component.state.remarks : student.remarks
            const userAttendanceData = studentAttendance.find((el) => {
                if (el.user_school_id == student.user_school_id) {
                    data['attendanceStatus'] = student.status
                    return el
                }
            })
            updateStudentAttendance(userAttendanceData.attendance_id, data, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.setState({
                        attendence_recorded: true,
                        remarks: '',
                        media: {},
                        deletedMedia: {},
                        uploadedFiles: {},
                    })
                    component.getAttendance()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            component.setState({ errors: response.data.errors })
                        }
                    }
                    resolve(false)
                }
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
