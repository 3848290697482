import $ from 'jquery'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { capitalize } from '../../../../../helper/function/abstract'
// import { configRoute, convertADtoBS, getInvoiceId } from '../../../../../../helper/function/abstract'
import { AbstractBookIssueDetailsComponent, mapStateToProps } from './abstract'

class BookIssueDetailsComponent extends AbstractBookIssueDetailsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />

                <Sidebar id="library" isAccount={false} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.bookissue.view}>{t('library:bookissue')} > </a>
                            </li>
                            <li>{t('common:details')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-8">
                                <h5 className="main-title heading5">{t('common:details')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-table">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="w-25">
                                        Book Issue Details
                                    </th>
                                    <th scope="col" className="w-75">
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead>
                            {console.log(this.state)}
                            <tbody>
                                <tr>
                                    <td className="w-25">Book Title</td>
                                    <td className="w-75">{capitalize(this.state.book)}</td>
                                </tr>
                                <tr>
                                    <td>Book Shelf</td>
                                    <td>{this.state.bookShelf}</td>
                                </tr>
                                <tr>
                                    <td>Issued To</td>
                                    <td>{this.state.issuedTo}</td>
                                </tr>
                                <tr>
                                    <td>Issued By</td>
                                    <td>{this.state.issuedBy}</td>
                                </tr>
                                <tr>
                                    <td>Received By</td>
                                    <td>{this.state.receivedBy ? this.state.receivedBy : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td className="w-25">Issue Date</td>
                                    <td className="w-75">{this.state.issueDate}</td>
                                </tr>
                                <tr>
                                    <td>Due Date</td>
                                    <td>{this.state.dueDate}</td>
                                </tr>

                                <tr>
                                    <td>Return Date</td>
                                    <td>{this.state.returnDate ? this.state.returnDate : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Fine Amount</td>
                                    <td>{this.state.fineAmount ? this.state.fineAmount : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

const BookIssueDetails = withTranslation()(connect(mapStateToProps)(BookIssueDetailsComponent))
export { BookIssueDetails }
