import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewTrialBalance } from '../../../../../services/api/accounting/doubleentry'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractTrialBalanceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            start_date: moment().startOf('month').toDate(),
            end_date: moment().endOf('month').toDate(),
            duration: null,
            chartOfAccounts: [],
            errors: {
                start_date: null,
                end_date: null,
            },

            journalItems: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }
    componentDidMount() {
        this._onSearch()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const start_date =
                convertADtoBS(this.state.start_date) != null
                    ? convertADtoBS(this.state.start_date) + ' BS'
                    : 'Invalid Date Range'
            const end_date =
                convertADtoBS(this.state.end_date) != null
                    ? convertADtoBS(this.state.end_date) + ' BS'
                    : 'Invalid Date Range'

            this.setState({
                duration: start_date + ' || ' + end_date,
            })
        } else {
            const start_date = moment(this.state.start_date).format('yyyy-MM-DD')
            const end_date = moment(this.state.end_date).format('yyyy-MM-DD')
            this.setState({
                duration: start_date + ' || ' + end_date,
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const start_date = moment(this.state.start_date).format('yyyy-MM-DD')
            const end_date = moment(this.state.end_date).format('yyyy-MM-DD')
            viewTrialBalance(start_date, end_date, this.props.token).then((response) => {
                this._handleChange('loading', false)
                console.log(response)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            journalItems: response.data.journalItem,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
