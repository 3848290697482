import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractEmployeeMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'
class EmployeeMenuComponent extends AbstractEmployeeMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectUserType = this._selectUserType.bind(this)
        this._selectCustomRole = this._selectCustomRole.bind(this)
    }

    _selectUserType(data) {
        if (data.value == 1) {
            $('#custom-role').show()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedRole: this.state.customRoleAry[0] ? this.state.customRoleAry[0].value : null,
                defaultRole: this.state.customRoleAry[0] ? this.state.customRoleAry[0] : null,
            })
        } else {
            $('#custom-role').hide()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedRole: 0,
                defaultRole: null,
            })
        }
    }

    _selectCustomRole(data) {
        super._handleChange('selectedRole', data.value)
        super._handleChange('defaultRole', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>{t('employee:employee-menu.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('employee:employee-menu.title')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('employee:employee-menu.userTypeLabel')} *
                                    </label>
                                    <Select name="type" options={this.state.userTypeAry} placeholder={t('employee:employee-menu.userTypeLabel')} searchable={true} value={this.state.defaultUserType} onChange={this._selectUserType} />
                                    {this.state.selectedUserType == null && <label className="error text-danger">{t('toaster:required')} *</label>}
                                </div>
                            </div>
                            <div className="row clearfix" id="custom-role">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('attendance:role')} *
                                    </label>
                                    <Select name="role" options={this.state.customRoleAry} placeholder={t('attendance:role')} searchable={true} value={this.state.defaultRole} onChange={this._selectCustomRole} />
                                    {this.state.selectedUserType == 1 && this.state.selectedRole == null && <label className="error text-danger">{t('toaster:required')} *</label>}
                                </div>
                            </div>
                            <div className="form-button text-left">
                                <button type="button" className="main-button" onClick={this.getURL}>
                                    {t('common:searchBtn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const EmployeeMenu = withTranslation()(connect(mapStateToProps)(EmployeeMenuComponent))

export { EmployeeMenu }
