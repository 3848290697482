import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import {
    activeClass,
    getClass,
    getCourseItems,
    editCourseModule,
    deleteCourseModule,
    addCourseModule,
    viewCourseModule,
    addCourseContent,
    editCourseContent,
    deleteCourseContent,
    viewCourseProgress,
} from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import * as UserType from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import { redirect } from '../../../../../helper/function'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseModuleStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            course_progress: [],
            course: [],
            class_id: '',
            loading: true,
            loading_text: 'Loading',
            courseModule: [],
            courseModuleData: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourse = this.loadCourse.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
    }

    componentDidMount() {
        this.loadClass()
        this.loadCourseProgress()
    }

    loadClass() {
        if (this.props.school.user_type == UserType.Student) {
            this.setState(
                {
                    class_id: this.props.school.active_user_academics.section.class_id,
                },
                () => {
                    this.loadSubject()
                }
            )
        } else {
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let section = null
                let classes = null
                if (response.code == API_SUCCESS) {
                    section = response.data.section
                    this.setState(
                        {
                            class_id: section.classes.id,
                        },
                        () => {
                            this.loadSubject()
                        }
                    )
                }
            })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const course_progress = response.data.course_progress
                this.setState(
                    {
                        course_progress,
                    },
                    () => this.loadCourseModule()
                )
            }
        })
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseItems(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            // if (moment().diff(moment(response.data.post.from_date)) < 0) {
            //     redirect(route.course.student.view, this.props.history)
            // }
            if (response.code == API_SUCCESS) {
                this.setState({
                    course: response.data.post,
                    loaded: true,
                })
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseModule(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let courseModule = response.data.course_module
                courseModule = courseModule.filter((el) => {
                    return moment().diff(moment(el.release_date)) >= 0
                })
                let courseModuleData = []
                this.setState({
                    courseModule,
                    courseModuleData,
                })
            }
        })
    }

    loadSubject() {
        if (this.state.class_id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourse()
                        }
                    )
                }
            })
        } else {
            this.loadCourse()
        }
    }

    openDetailProperties(index) {
        const component = this
        return new Promise(function (resolve, reject) {
            const selectedCourse = component.state.course.filter((item) => item.subject_id == index)[0]
            component.setState(
                {
                    detail: {
                        title: selectedCourse.title,
                        description: selectedCourse.description,
                        topic: selectedCourse.course.topic,
                        createdOn: moment(selectedCourse.created_at).format('YYYY-MM-DD - hh:mm A'),
                        privacy: selectedCourse.restriction == 1 ? 'Private' : 'Public',
                    },
                },
                () => {
                    resolve(true)
                }
            )
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
