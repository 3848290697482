import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { viewBooks, toggleBooks} from '~/services/api'
import {book_type} from '~/helper/constant/books.js'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}

const tableData= {
    title: {
        field: 'text',
        route: false,
        sortable: true,
        translate: 'common:name',
    },
    author: {
        field: 'text',
        route: false,
        sortable: true,
        translate: 'library:author',
    },
    type:{
        field: 'text',
        route:false,
        sortable:false,
        translate: 'attendance:type'

    },
    status: {
        field: 'badge',
        route: false,
        translate: 'common:status',
    },
    action: {
        field: 'button',
        route: true,
        sortable: false,
        translate: 'activity:action',
    },
    detail: {
        field: 'button',
        route: true,
        sortable: false,
        translate: 'common:detail',
    },

}

export class AbstractBooksComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            bookType:book_type,
            hardcopy: [tableData],
            digital: [tableData],
           bookList:[]
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.loadBooks()
    }


    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(link){
        const{t} = this.props
        const component = this
        console.log(link)
        return new Promise(function(resolve, reject){
            component._handleChange("loading_text", "Loading")
            component._handleChange("loading", true)
            toggleBooks(link, component.props.token).then((response)=>{
                component._handleChange("loading", false)
                if(response.code == API_SUCCESS){
                    toastMessage("success", response.message)
                    component.loadBooks()
                    resolve(true)
                }else{
                    toastMessage('error', response.message)
                    resolve(false)
                }

            })

        })

    }

    loadBooks() {
        const { t } = this.props
        const hardcopy = [tableData]
        const digital = [tableData]
        const bookList = []
        this._handleChange("loading_text", "Loading")
            this._handleChange("loading", true)
        viewBooks(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code === API_SUCCESS) {
                response.data.book.map((item, index) => {
                    let text= null
                        if(item.status == Status.ACTIVE){
                            text={
                              text: 'Active',
                              translate: 'common:statusActive',
                              badge: 'table-green',
                              modal: 'modal_class_' + index,
                            }
                          } else{
                            text = {
                              text: 'Inactive',
                              translate: 'common:statusInactive',
                              badge: 'table-red',
                              modal: 'modal_class_' + index,
                            }
                          }
                    if (item.book_type === 0 ){
                        const type = this.state.bookType.find((list)=> list.value == item.book_type)
                        
                    const rowData = {
                        title: { text: item.title },
                        author: { text: item.author },
                        type: { text: type.label },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.library.books.edit + '/' + item.id,
                        },
                        detail: {
                            text: 'Detail',
                            translate: 'common:detail',
                            route: route.admin.library.books.detail + '/' + item.id,
                        },
                    }
                    digital.push(rowData)
                }

                if (item.book_type === 1){
                    const type = this.state.bookType.find((list)=> list.value == item.book_type)

                const rowData = {
                    title: { text: item.title },
                    author: { text: item.author },
                    type: { text: type.label },
                    status: text,
                    action: {
                        text: 'Edit',
                        translate: 'common:EditBtn',
                        route: route.admin.library.books.edit + '/' + item.id,
                    },
                    detail: {
                        text: 'Detail',
                        translate: 'common:detail',
                        route: route.admin.library.books.detail + '/' + item.id,
                    },
                }
                hardcopy.push(rowData)
            }
            bookList.push(item)
                })

                this.setState({
                    digital,
                    hardcopy,
                    bookList,

                })
            }
        })
    }


}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
