import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { editStudent, getStudent, activeStudentCategory, getSection, findUser } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    email: String,
    student: Array,
    data: Array,
    loading: Boolean,
    dob: String,
    joining_date: String,
    errors: Object,
    students: Array,
    section_id: integer,
    section: Object,
    loading_text: String,
}

export class AbstractStudentEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: null,
            loading: false,
            joining_date: null,
            dob: null,
            email: null,
            section: null,
            section_id: this.props.match.params.section_id,
            student_id: this.props.match.params.student_id,
            defaultSelected: null,
            selectedStudentCategory: null,
            student_category: [],
            errors: {
                name: null,
                dob: null,
                joining_date: null,
            },
            students: [],
            loadingText: 'Saving',
            roll_no: null,
            old_roll_no: null,
            roll_no_required: false,
            roll_number_validationAry: [],
            roll_number_validation_msg: null,
            student_regcode: '',
            student_regcode_error: null,
            found_student: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadStudentCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadStudentCategory() {
        activeStudentCategory(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                let student_category = []
                response.data.user_category.map((item, index) => {
                    let student_category_data = {}
                    student_category_data['value'] = item.id
                    student_category_data['label'] = item.name + ' - ' + item.code
                    student_category.push(student_category_data)
                })
                this.setState({ student_category }, () => {
                    this.loadSection()
                })
            }
        })
    }

    loadSection() {
        getSection(this.state.section_id, this.props.token).then((response) => {
            let section = null
            let classes = null
            let roll_number_validationAry = []
            if (response.code == API_SUCCESS) {
                section = response.data.section
                roll_number_validationAry = response.data.roll_number
                if (section.roll_type == 0) {
                    this.setState({
                        roll_no_required: true,
                    })
                }
                this.setState(
                    {
                        section,
                        roll_number_validationAry,
                    },
                    () => {
                        this.loadStudent()
                    }
                )
            }
        })
    }

    loadStudent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getStudent(this.state.student_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.student

                const roll_no = item.user_academics.find((el) => {
                    return el.status == Status.ACTIVE
                }).roll_no
                this.setState({
                    name: item.user.name,
                    email: item.user.email,
                    dob: moment(item.user.dob).toDate(),
                    old_roll_no: roll_no,
                    roll_no: roll_no,
                    joining_date: moment(item.joining_date).toDate(),
                    defaultSelected: this.state.student_category.filter((el) => el.value == item.users_category_id),
                    selectedStudentCategory: item.users_category_id,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        let hasError = false
        if (this.state.roll_no_required) {
            if (this.state.roll_no == null) {
                this.setState({ roll_number_validation_msg: t('toaster:required') })
                return
            }
            if (this.state.roll_no != this.state.old_roll_no) {
                const roll_noAry = this.state.roll_number_validationAry
                //validate RollNumber
                if (this.state.roll_no < 1) {
                    this.setState({
                        roll_number_validation_msg: t('toaster:greaterThan0'),
                    })
                    return
                }
                if (roll_noAry.indexOf(this.state.roll_no) > -1) {
                    this.setState({ roll_number_validation_msg: t('toaster:duplicate') })
                    return
                }
            }
        }
        const errors = validation.required(Object.keys(this.state.errors), this.state)

        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            const data = {
                name: this.state.name,
                email: this.state.email,
                dob: moment(this.state.dob).format('yyyy-MM-DD'),
                joining_date: moment(this.state.joining_date).format('yyyy-MM-DD'),
                section_id: this.state.section_id,
                users_category_id: this.state.selectedStudentCategory,
            }
            if (this.state.roll_no_required) {
                data['roll_no'] = this.state.roll_no
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editStudent(this.state.student_id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.user.student.view + '/' + this.state.section_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

const AbstractStudentEdit = withTranslation()(AbstractStudentEditComponent)

export { AbstractStudentEdit }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
