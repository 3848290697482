import $ from 'jquery'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Button } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Input } from '../../../../../../components/form/input'
import { convertADtoBS } from '../../../../../../helper/function/abstract'
import { AbstractInvoiceTemplateComponent, mapStateToProps } from './abstract'

class InvoiceTemplateViewComponent extends AbstractInvoiceTemplateComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.delete = this.delete.bind(this)
        this._setIssueDate = this._setIssueDate.bind(this)
        this._setDueDate = this._setDueDate.bind(this)
        this._setStatus = this._setStatus.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.delTemplate != null && prevState.delTemplate != this.state.delTemplate) {
            if (this.state.delTemplate == null) {
                $('#modal_delete').modal('hide')
            } else {
                $('#modal_delete').modal('show')
            }
        }
        if (this.state.editTemplate != null && prevState.editTemplate != this.state.editTemplate) {
            if (this.state.editTemplate == null) {
                $('#modal_generate').modal('hide')
            } else {
                this.setState(
                    {
                        issue_date: null,
                        due_date: null,
                        status: null,
                        selectedStatus:null,
                        errors: {
                            issue_date: null,
                            due_date: null,
                            selectedStatus:null
                        },
                    },
                    () => {
                        $('#modal_generate').modal('show')
                    }
                )
            }
        }
    }

    _setIssueDate(data) {
        this._handleChange('issue_date', data)
    }

    _setDueDate(data) {
        this._handleChange('due_date', data)
    }

    _setStatus(type, data) {
        this.setState({
            [`default${type}`]: data,
            [`selected${type}`]: data.value,
        })
    }

    delete() {
        super.delete().then((response) => {
            if (response) {
                $('#close_delete').trigger('click')
            }
        })
    }

    _onSave() {
        super._onSave().then((response) => {
            if (response) {
                $('#close_generate').trigger('click')
            }
        })
    }

    generateModal() {
        const { t } = this.props
        const template = this.state.editTemplate
        if (template != null) {
            return (
                <div id="modal_generate" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('income:invoiceMsg')}</p>
                                <div class="row clearFix">
                                    <div className="col-sm-12 float-left form-group mb-0">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('common:title')} *</label>
                                            <Input
                                                name="title"
                                                type="text"
                                                className="form-control"
                                                placeholder={t('common:title')}
                                                value={template.title}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title text-left">{t('income:issuedate')} *</label>
                                        <DatePicker
                                            placeholderText={t('income:selectissuedate')}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            defaultValue={this.state.issue_date}
                                            selected={this.state.issue_date ? new Date(this.state.issue_date) : null}
                                            onChange={this._setIssueDate}
                                        />
                                        {this.props.school != null &&
                                            this.props.school.school != null &&
                                            this.props.school.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.issue_date != ''
                                                        ? convertADtoBS(this.state.issue_date) != null
                                                            ? convertADtoBS(this.state.issue_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}

                                        {this.state.errors.issue_date && (
                                            <label className="error text-danger">{this.state.errors.issue_date}</label>
                                        )}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title text-left">{t('income:duedate')} *</label>
                                        <DatePicker
                                            placeholderText={t('income:selectduedate')}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            defaultValue={this.state.due_date}
                                            selected={this.state.due_date ? new Date(this.state.due_date) : null}
                                            onChange={this._setDueDate}
                                        />
                                        {this.props.school != null &&
                                            this.props.school.school != null &&
                                            this.props.school.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.due_date != ''
                                                        ? convertADtoBS(this.state.due_date) != null
                                                            ? convertADtoBS(this.state.due_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                        {this.state.errors.due_date && (
                                            <label className="error text-danger">{this.state.errors.due_date}</label>
                                        )}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title text-left">{t('common:status')} *</label>
                                        <Select
                                            name="selectedStatus"
                                            options={this.state.statusList}
                                            placeholder={t('common:status')}
                                            value={this.state.defaultStatus}
                                            onChange={(data)=>this._setStatus('Status',data)}
                                            searchable={true}
                                        />
                                        {console.log(this.state.errors ? this.state.errors : null)}

                                        {this.state.errors.selectedStatus && (
                                            <label className="error text-danger setup-error text-left">
                                                {this.state.errors.selectedStatus}
                                            </label>
                                        )}
                                        {console.log(this.state.errors ? this.state.errors : null)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button
                                    onClick={this._onSave}
                                    type="button"
                                    text={t('common:modal confirm')}
                                    main={true}
                                />
                                <button
                                    id="close_generate"
                                    type="button"
                                    className="cancel-button"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.setState({
                                            editTemplate: null,
                                        })
                                    }}
                                >
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    deleteModal() {
        const { t } = this.props
        const template = this.state.delTemplate
        if (template != null) {
            return (
                <div id="modal_delete" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('activity:delete_msg')}</p>
                                <p>{template.title}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button
                                    onClick={this.delete}
                                    type="button"
                                    text={t('common:modal confirm')}
                                    main={true}
                                />
                                <button id="close_delete" type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="income" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.income.menu}>{t('income:income')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('income:template')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">
                                    {t('income:manageinvoice')} {t('income:template')}
                                </h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.accounting.income.template.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <Table data={this.state.tableData} />
                        <br />
                    </div>
                </div>
                {this.generateModal()}
                {this.deleteModal()}
            </div>
        )
    }
}
const InvoiceTemplateView = withTranslation()(connect(mapStateToProps)(InvoiceTemplateViewComponent))

export { InvoiceTemplateView }
