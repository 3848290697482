import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractRoutineMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

class RoutineMenuComponent extends AbstractRoutineMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getClass = this.getClass.bind(this)
    }

    getClass() {
        if (this.state.exam != null) {
            const data = this.state.classes.map((item, index) => {
                return (
                    <div key={index} className="folder-list question-bank-list qbl clearfix" onClick={() => (window.location = route.examination.exam.routine.schedule + '/' + this.state.exam.id + '/' + item.id)}>
                        <a href={route.examination.exam.routine.schedule + '/' + this.state.exam.id + '/' + item.id} className="folder-img">
                            {SVG.questionClass()}
                        </a>
                        <a href={route.examination.exam.routine.schedule + '/' + this.state.exam.id + '/' + item.id} className="folder-name">
                            <div className="move-sent">
                                {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && 'Academic'}
                                {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && item.name}
                            </div>
                        </a>
                    </div>
                )
            })
            return data
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.exam.view}>{t('exam:exam:categoryTitle')} &gt; </a>
                            </li>
                            <li>{this.state.exam != null ? this.state.exam.title : t('sidebar:exam')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{t('exam:exam:routine:class')}</span>
                                <h5 className="main-title heading5">{this.state.exam != null ? this.state.exam.title : t('sidebar:exam')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="clearfix">
                            <div className="float-left">{this.getClass()}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const RoutineMenu = withTranslation()(connect(mapStateToProps)(RoutineMenuComponent))

export { RoutineMenu }
