import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractCalendarComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Dashboard } from '~/components/dashboard'
import { Post } from '~/components/post'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import DatePicker from 'react-datepicker'
import { Input, Button, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import * as PostType from '~/helper/constant/post_type'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import { calendar_color_home } from '~/helper/constant/color'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import moment from 'moment'
import { convertADtoBS } from '../../../helper/function'
import { NepaliEventCalendar } from '../../../components/nepali-event-calendar'
class CalendarComponent extends AbstractCalendarComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setHolidayStart = this._setHolidayStart.bind(this)
        this._setHolidayEnd = this._setHolidayEnd.bind(this)
        this._setEventStart = this._setEventStart.bind(this)
        this._setEventEnd = this._setEventEnd.bind(this)
        this.getHolidayModal = this.getHolidayModal.bind(this)
        this.getEventModal = this.getEventModal.bind(this)
        this.hoverCalendar = this.hoverCalendar.bind(this)
        this.getCalendarDetail = this.getCalendarDetail.bind(this)
        this._onHolidaySave = this._onHolidaySave.bind(this)
        this._onEventSave = this._onEventSave.bind(this)
        this._onEventSaveSchedule = this._onEventSaveSchedule.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    _onHolidaySave(e) {
        e.preventDefault()
        super._onHolidaySave(e).then((response) => {
            if (response) {
                $('#close_holiday_add').trigger('click')
            }
        })
    }

    _onEventSaveSchedule(e) {
        e.preventDefault()
        this._onSave(true).then((response) => {
            if (response) {
                $('#close_event_add').trigger('click')
                setTimeout(() => {
                    redirect(route.admin.event.schedule + '/' + response, this.props.history)
                }, 1000)
            }
        })
    }

    _onEventSave(e) {
        e.preventDefault()
        this._onSave(false).then((response) => {
            if (response) {
                $('#close_event_add').trigger('click')
            }
        })
    }

    hoverCalendar(event, el) {
        let data = event?.event?.extendedProps ?? null
        if (data == null) {
            data = event
        }

        const type = PostType.activity.find((item) => item.value == data.type)
        this.setState(
            {
                modal_event_title: data.title,
                modal_event_type: type,
                modal_event_start: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' ? (convertADtoBS(data.from_date) != null ? convertADtoBS(data.from_date) : 'Invalid Date Range') : data.from_date,
                modal_event_end: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' ? (convertADtoBS(data.to_date) != null ? convertADtoBS(data.to_date) : 'Invalid Date Range') : data.to_date,
            },
            () => {
                $('#calendar_detail').modal('show')
            }
        )
    }
    _setHolidayStart(data) {
        this._handleChange('holiday_start', data)
    }

    _setHolidayEnd(data) {
        this._handleChange('holiday_end', data)
    }

    _setEventStart(data) {
        this._handleChange('event_start', data)
    }

    _setEventEnd(data) {
        this._handleChange('event_end', data)
    }

    getCalendarDetail() {
        const { t } = this.props
        const start = this.state.modal_event_start != null ? this.state.modal_event_start.split('T') : []
        const end = this.state.modal_event_end != null ? this.state.modal_event_end.split('T') : []
        return (
            <div id="calendar_detail" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{this.state.modal_event_type != null ? t(this.state.modal_event_type.translate) : t('calendar:calendarDetail')} :</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="form-group row clearfix folder-detail">
                                <div className="col-md-4 form-title">{this.state.modal_event_type != null ? t(this.state.modal_event_type.translate) : t('calendar:calendarDetail')}</div>
                                <div className="col-md-8">
                                    <span className="teacher-name form-title">{this.state.modal_event_title}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group row clearfix folder-detail">
                                <div className="col-md-4 form-title">{t('common:start date')}</div>
                                <div className="col-md-8">
                                    {start.length > 0 && <span className="teacher-name form-title">{start[0]}</span>}
                                    {start.length > 1 && <span className="teacher-name form-title">{moment(start[1], 'HH:mm').format('hh:mm a')}</span>}
                                </div>
                            </div>
                            <hr />
                            <div className="form-group row clearfix folder-detail">
                                <div className="col-md-4 form-title">{t('common:end date')}</div>
                                <div className="col-md-8">
                                    {end.length > 0 && <span className="teacher-name form-title">{start[0] == end[0] ? moment(end[0]).format('yyyy-MM-DD') : moment(end[0]).subtract(1, 'days').format('yyyy-MM-DD')}</span>}
                                    {end.length > 1 && <span className="teacher-name form-title">{moment(end[1], 'HH:mm').format('hh:mm a')}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getEventModal() {
        const { t } = this.props

        return (
            <div id="modal_event_add" data-id="modal_event_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('calendar:event.add.title')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('calendar:event.add.nameLabel')} *</label>
                                        <Input name="event_description" onChange={this._handleChange} placeholder={t('calendar:event.add.namePlaceholder')} type="text" value={this.state.event_description} />
                                        {this.state.event_errors.event_description && <label className="error text-danger">{this.state.event_errors.event_description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('calendar:event.add.startDateLabel')} *</label>
                                        <DatePicker placeholderText={t('calendar:event.add.startDatePlaceholder')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.event_start} selected={this.state.event_start} onChange={this._setEventStart} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.event_start != '' ? (convertADtoBS(this.state.event_start) != null ? convertADtoBS(this.state.event_start) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.event_errors.event_start && <label className="error text-danger">{this.state.event_errors.event_start}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('calendar:event.add.endDateLabel')} *</label>
                                        <DatePicker placeholderText={t('calendar:event.add.endDatePlaceholder')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.event_end} selected={this.state.event_end} onChange={this._setEventEnd} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.event_end != '' ? (convertADtoBS(this.state.event_end) != null ? convertADtoBS(this.state.event_end) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.event_errors.event_end && <label className="error text-danger">{this.state.event_errors.event_end}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onEventSaveSchedule} type="submit" text={t('calendar:event.add.saveSheduleBtn')} main={true} />
                                <Button onClick={this._onEventSave} type="submit" text={t('common:savebtn')} main={true} />
                                <button id="close_event_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getHolidayModal() {
        const { t } = this.props

        return (
            <div id="modal_holiday_add" data-id="modal_holiday_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('calendar:holiday.add.title')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('calendar:holiday.add.nameLabel')} *</label>
                                        <Input name="holiday_description" onChange={this._handleChange} placeholder={t('calendar:holiday.add.namePlaceholder')} type="text" value={this.state.holiday_description} />
                                        {this.state.holiday_errors.holiday_description && <label className="error text-danger">{this.state.holiday_errors.holiday_description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('calendar:holiday.add.startDateLabel')}*</label>
                                        <DatePicker placeholderText={t('calendar:holiday.add.startDatePlaceholder')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.holiday_start} selected={this.state.holiday_start} onChange={this._setHolidayStart} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.holiday_start != '' ? (convertADtoBS(this.state.holiday_start) != null ? convertADtoBS(this.state.holiday_start) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.holiday_errors.holiday_start && <label className="error text-danger">{this.state.holiday_errors.holiday_start}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('calendar:holiday.add.endDateLabel')} *</label>
                                        <DatePicker placeholderText={t('calendar:holiday.add.endDatePlaceholder')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.holiday_end} selected={this.state.holiday_end} onChange={this._setHolidayEnd} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.holiday_end != '' ? (convertADtoBS(this.state.holiday_end) != null ? convertADtoBS(this.state.holiday_end) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.holiday_errors.holiday_end && <label className="error text-danger">{this.state.holiday_errors.holiday_end}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onHolidaySave} type="submit" text={t('common:savebtn')} main={true} />
                                <button id="close_holiday_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t, school } = this.props
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="calendar" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.calendar}>{t('calendar:calendar-menu.breadcrumb')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">
                                    {t('academic:academic')} {t('calendar:calendar-menu.title')}:
                                </h5>
                            </div>
                            {this.props.school && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    {AccessModule.checkSubAccess([AccessModule.EVENT], role) && (
                                        <div className="display-inline-block">
                                            <button className="btn main-button" type="button" onClick={this._eventView}>
                                                {t('calendar:calendar-menu.eventBtn')}
                                            </button>
                                        </div>
                                    )}
                                    {this.props.school.user_type != UserType.Alumni && AccessModule.checkSubAccess([AccessModule.HOLIDAY], role) && (
                                        <div className="display-inline-block">
                                            <button className="btn main-button" type="button" onClick={this._holidayView}>
                                                {t('calendar:calendar-menu.holidayBtn')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.props.school && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && AccessModule.checkSubAccess([AccessModule.EVENT], role) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle main-button" type="button" data-toggle="collapse" data-target="#event_dropdown" aria-haspopup="true" aria-expanded="false">
                                                {t('calendar:calendar-menu.eventBtn')}
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="e-d-t" id="event_dropdown">
                                                <button className="dropdown-item" data-toggle="modal" data-target="#modal_event_add">
                                                    {t('calendar:calendar-menu.eventAdd')}
                                                </button>

                                                <button className="dropdown-item" onClick={this._eventView} type="button">
                                                    {t('calendar:calendar-menu.eventView')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.isAlumni == false && AccessModule.checkSubAccess([AccessModule.HOLIDAY], role) && (
                                        <div className="display-inline-block">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle main-button" type="button" data-toggle="collapse" data-target="#holiday_dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {t('calendar:calendar-menu.holidayBtn')}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="e-d-t" id="holiday_dropdown">
                                                    <button className="dropdown-item" data-toggle="modal" data-target="#modal_holiday_add">
                                                        {t('calendar:calendar-menu.holidayAdd')}
                                                    </button>

                                                    <button className="dropdown-item" onClick={this._holidayView} type="button">
                                                        {t('calendar:calendar-menu.holidayView')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="main-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="index-flex">
                                    <div>{t('setting:index')} : </div>
                                    {calendar_color_home.map((item, index) => {
                                        if (this.props.isAlumni == true) {
                                            if (index == 0) {
                                                return (
                                                    <div className={this.state.filterType == PostType.Event ? item + ' active' : item} key={index}>
                                                        {index == 0 && (
                                                            <small
                                                                onClick={(e) => {
                                                                    this.toggleFilter(this.state.filterType == PostType.Event ? null : PostType.Event)
                                                                }}
                                                                className="fc-event-title cursor"
                                                            >
                                                                {t('calendar:event.event')}
                                                            </small>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        } else {
                                            return (
                                                <Fragment>
                                                    {index == 0 && (
                                                        <div className={this.state.filterType == PostType.Event ? item + ' active' : item} key={index}>
                                                            <small
                                                                onClick={(e) => {
                                                                    this.toggleFilter(this.state.filterType == PostType.Event ? null : PostType.Event)
                                                                }}
                                                                className="fc-event-title cursor"
                                                            >
                                                                {t('calendar:event.event')}
                                                            </small>
                                                        </div>
                                                    )}
                                                    {index == 1 && (
                                                        <div className={this.state.filterType == PostType.Holiday ? item + ' active' : item} key={index}>
                                                            <small
                                                                onClick={(e) => {
                                                                    this.toggleFilter(this.state.filterType == PostType.Holiday ? null : PostType.Holiday)
                                                                }}
                                                                className="fc-event-title cursor"
                                                            >
                                                                {t('calendar:calendar-menu.holidayBtn')}
                                                            </small>
                                                        </div>
                                                    )}
                                                    {index == 2 && (
                                                        <div className={this.state.filterType == PostType.Homework ? item + ' active' : item} key={index}>
                                                            <small
                                                                onClick={(e) => {
                                                                    this.toggleFilter(this.state.filterType == PostType.Homework ? null : PostType.Homework)
                                                                }}
                                                                className="fc-event-title cursor"
                                                            >
                                                                {t('sidebar:todo')}
                                                            </small>
                                                        </div>
                                                    )}
                                                    {index == 3 && (
                                                        <div className={this.state.filterType == PostType.Exam ? item + ' active' : item} key={index}>
                                                            <small
                                                                onClick={(e) => {
                                                                    this.toggleFilter(this.state.filterType == PostType.Exam ? null : PostType.Exam)
                                                                }}
                                                                className="fc-event-title cursor"
                                                            >
                                                                {t('sidebar:exam')}
                                                            </small>
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )
                                        }
                                    })}
                                </div>
                                <hr />
                            </div>
                        </div>
                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <NepaliEventCalendar minDate={this.state.start_date} maxDate={this.state.end_date} events={this.state.filterEvents} eventClick={this.hoverCalendar} />}
                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                            <FullCalendar
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                                }}
                                initialDate={this.state.initial_date}
                                height="80vh"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                initialView="dayGridMonth"
                                allDay={true}
                                validRange={{
                                    start: this.state.start_date,
                                    end: this.state.end_date,
                                }}
                                events={this.state.filterEvents}
                                eventClick={this.hoverCalendar}
                            />
                        )}
                    </div>
                </div>
                {((this.props.school && this.props.school.user_type == UserType.Admin) || (this.props.school && this.props.school.user_type == UserType.Custom)) && (
                    <Fragment>
                        {this.getEventModal()}
                        {this.getHolidayModal()}
                    </Fragment>
                )}
                {this.getCalendarDetail()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')
            if (id == 'modal_holiday_add') {
                component._handleChange('holiday_description', '')
                component._handleChange('holiday_start', '')
                component._handleChange('holiday_end', '')
                const holiday_errors = {
                    holiday_description: null,
                    holiday_start: null,
                    holiday_end: null,
                }
                component._handleChange('holiday_errors', holiday_errors)
            } else if (id == 'modal_event_add') {
                component._handleChange('event_description', '')
                component._handleChange('event_start', '')
                component._handleChange('event_end', '')
                const event_errors = {
                    event_description: null,
                    event_start: null,
                    event_end: null,
                }
                component._handleChange('event_errors', event_errors)
            }
        })
    }
}

const Calendar = withTranslation()(connect(mapStateToProps)(CalendarComponent))

export { Calendar }
