import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getQuestionBank, editQuestionBank, addQuestion, deleteQuestion, deletePost, viewQuestion, selectQuestion } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import * as QuestionType from '~/helper/constant/question_type'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    question_bank: Object,
    classes: Array,
    question: Array,
    subject: Array,
    activeTab: Integer,
    isPrivate: Boolean,
    employee: Array,
    student: Array,
    selected_subject: Integer,
    errors: Object,
    hideClass: Boolean,
    hideSection: Boolean,
    selectedClass: Integer,
    showSubject: Boolean,
    title: String,
    description: String,
    question_title: String,
    question_type: Integer,
    question_marks: Double,
    defaultType: Object,
    uploads: Array,
    media: Array,
    count: Integer,
    answers: Array,
    tableData: Array,
    questionList: Array,
    selectedQuestion: Array,
}

export class AbstractQuestionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loaded: false,
            classes: [],
            question: [],
            showSubject: false,
            // selectedClass: -1,
            hideClass: true,
            isPrivate: false,
            employee: [],
            uploads: [],
            media: [],
            student: [],
            errors: {
                title: null,
                description: null,
            },
            objective_errors: '',
            answer_errors: [],
            question_errors: {
                question_title: null,
                question_type: null,
                question_marks: null,
            },
            answers: [],
            questionList: [],
            selectedQuestion: [],
            tableData: [
                {
                    question: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'exam:questionbank.questions',
                    },
                    type: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'attendance:type',
                    },
                    marks: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'exam:marks',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this.loadQuestionBank = this.loadQuestionBank.bind(this)
        this.loadQuestion = this.loadQuestion.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._addAnswer = this._addAnswer.bind(this)
        this._removeAnswer = this._removeAnswer.bind(this)
        this._onSaveQuestion = this._onSaveQuestion.bind(this)
        this._deleteQuestion = this._deleteQuestion.bind(this)
        this._deleteQuestionBank = this._deleteQuestionBank.bind(this)
        this._selectQuestion = this._selectQuestion.bind(this)
    }

    componentDidMount() {
        this.loadQuestionBank()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _addAnswer() {
        const answers = this.state.answers
        const data = {
            count: answers.length,
            answers: '',
            is_correct: answers.length == 0 ? true : false,
        }
        answers.push(data)
        this.setState({
            answers,
        })
    }

    _removeAnswer(id) {
        let answers = this.state.answers.filter((item) => item.count != id)
        const check = answers.find((item) => item.is_correct == true)
        if (check == null && answers.length > 0) {
            answers[0].is_correct = true
        }
        this.setState({
            answers,
        })
    }

    _deleteQuestionBank(e) {
        const link = e.target.getAttribute('link')
        const component = this
        return new Promise(function (resolve, reject) {
            deletePost(link, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }

    _deleteQuestion(e) {
        const link = e.target.getAttribute('link')
        deleteQuestion(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadQuestionBank()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _selectQuestion(e) {
        const link = e.target.getAttribute('link')
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        selectQuestion(this.props.match.params.id, link, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadQuestionBank()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadQuestionBank() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getQuestionBank(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let selectedQuestion = []
                response.data.question_bank.question.map((item) => {
                    selectedQuestion.push(item.id)
                    if (!item.isParent) {
                        selectedQuestion.push(item.parent_id)
                    }
                })
                this.setState(
                    {
                        loaded: true,
                        selectedQuestion,
                        question_bank: response.data.question_bank,
                        classes: response.data.question_bank.class_access_information,
                        question: response.data.question_bank.question,
                        selectedClass: response.data.question_bank.class_access_information.length == 0 ? -1 : response.data.question_bank.class_access_information[0].id,
                    },
                    () => {
                        this.loadQuestion()
                    }
                )
            }
        })
    }

    loadQuestion() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let classes = null
        let subject = this.state.question_bank.subject_id
        if (this.state.classes.length > 0) {
            classes = this.state.classes[0].id
        }
        let tableData = []
        let questionList = []
        tableData.push(this.state.tableData[0])
        viewQuestion(classes, subject, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.question.map((item) => {
                    if (!this.state.selectedQuestion.includes(item.id)) {
                        const data = {
                            question: {
                                text: item.questions,
                            },
                            type: {
                                text: item.type == QuestionType.Subjective ? t('exam:subjective') : t('exam:objective'),
                            },
                            marks: {
                                text: item.marks,
                            },
                            action: {
                                text: 'select',
                                modal: 'select_question_' + item.id,
                            },
                        }
                        questionList.push(item)
                        tableData.push(data)
                    }
                })
                this.setState({
                    tableData,
                    questionList,
                })
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Saving')
                component._handleChange('loading', true)
                const data = {
                    title: component.state.title,
                    description: component.state.description,
                    restriction: component.state.isPrivate,
                }

                if (component.state.classes.length > 0) {
                    data.class_access = [component.state.classes[0].id]
                }
                if (component.state.isPrivate) {
                    if (component.state.student != null && component.state.employee != null) {
                        data.access = component.state.employee.concat(component.state.student)
                    } else if (component.state.student != null) {
                        data.access = component.state.student
                    } else {
                        data.access = component.state.employee
                    }
                }
                editQuestionBank(component.props.match.params.id, data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.loadQuestionBank()
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }

    _onSaveQuestion(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            let question_errors = validation.required(Object.keys(component.state.question_errors), component.state)
            component.setState({ question_errors })
            let validate = Object.keys(question_errors).filter((item) => question_errors[item] != null)
            if (validate.length == 0) {
                component.setState({ objective_errors: '' })
                if (component.state.question_type == QuestionType.Objective && component.state.answers.length == 0) {
                    const objective_errors = t('exam:noObjective')
                    component.setState({ objective_errors })
                } else {
                    let answer_errors = []
                    component.state.answers.map((item, index) => {
                        if (item.answers == '') {
                            answer_errors[index] = t('common:required')
                        }
                    })
                    if (component.state.question_type == QuestionType.Objective && answer_errors.length > 0) {
                        component.setState({ answer_errors })
                        return
                    }
                    component._handleChange('loading_text', 'Saving')
                    component._handleChange('loading', true)
                    const data = {
                        questions: component.state.question_title,
                        marks: component.state.question_marks,
                        type: component.state.question_type,
                        post_id: component.props.match.params.id,
                        media: component.state.media,
                    }
                    if (component.state.classes.length > 0) {
                        data.class_id = component.state.classes[0].id
                    }
                    if (component.state.question_bank.subject_id != null) {
                        data.subject_id = component.state.question_bank.subject_id
                    }
                    if (component.state.answers.length > 0) {
                        data.answers = component.state.answers
                    }
                    addQuestion(data, component.props.token).then((response) => {
                        component._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            component.loadQuestionBank()
                            resolve(true)
                        } else {
                            toastMessage('error', response.message)
                            if (response.data) {
                                if ('errors' in response.data) {
                                    component.setState({ errors: response.data.errors })
                                }
                            }
                            resolve(false)
                        }
                    })
                }
            }
        })
    }
}

const AbstractQuestion = withTranslation()(AbstractQuestionComponent)

export { AbstractQuestion }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
