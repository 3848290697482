import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as Notification from '~/helper/constant/notification'
import * as route from '~/helper/constant/route'

type Props = {
    schoolPackage: Object,
    dispatch: Dispatch<any>,
}

type State = {
    access: Array,
    notification: Array,
}

export class AbstractNotificationViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            notification: [
                {
                    notification: {
                        field: 'text',
                        translate: 'notification:notification',
                        route: false,
                        sortable: true,
                    },
                    setting: {
                        field: 'button',
                        translate: 'notification:setting',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }

    componentDidMount() {
        const module = Notification.getAll()
        module.sort((a, b) => (a.label > b.label ? 1 : -1))
        const notification = []
        notification.push(this.state.notification[0])
        module.map((item) => {
            let found = false
            if (this.props.schoolPackage != null && this.props.schoolPackage.length > 0) {
                item.access.map((access) => {
                    JSON.parse(this.props.schoolPackage).map((packageAccess) => {
                        if (packageAccess == access) {
                            found = true
                        }
                    })
                })
                if (found) {
                    const data = {
                        notification: {
                            text: item.label,
                            translate: item.translate,
                        },
                        setting: {
                            text: 'setting',
                            translate: 'common:settings',
                            route: route.admin.setting.notification.edit + '/' + item.value,
                        },
                    }
                    notification.push(data)
                }
            }
        })
        this.setState({
            notification,
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        schoolPackage: state.schoolPackage,
    }
}
