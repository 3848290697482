import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import * as route from '~/helper/constant/route'
import { Header } from '../../../../../components/header/header'
import { Loading } from '../../../../../components/loader/loader'
import { Sidebar } from '../../../../../components/sidebar/sidebar'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractIncomeExpenseSummaryComponent, mapStateToProps } from './abstract'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Income vs Expense',
        },
    },
}

const chart_data = {}
class IncomeExpenseSummaryComponent extends AbstractIncomeExpenseSummaryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setYear = this._setYear.bind(this)
    }

    _setYear(data) {
        this.setState(
            {
                year: data,
            },
            () => {
                this._onSearch()
            }
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="report_accounting" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.reports.menu}>
                                    {t('reports:reports')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('reports:incomeexpense')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('reports:incomeexpense')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={this.props.school.short_code + '-' + t('reports:incomeexpense')}
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="timetable-form mb-5">
                                    <div className="row ">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 header-filter">
                                            <label className="table-form-title">
                                                {t('academic:table.start date')} :
                                            </label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="yyyy"
                                                className="form-control"
                                                defaultValue={this.state.year}
                                                selected={this.state.year}
                                                onChange={this._setYear}
                                                showYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.year != ''
                                                        ? convertADtoBS(this.state.year) != null
                                                            ? convertADtoBS(this.state.year).slice(0, -6) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.year && (
                                                <label className="error text-danger">{this.state.errors.year}</label>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="chart-content">
                                                <Line
                                                    options={options}
                                                    data={{
                                                        labels: this.state.monthList,
                                                        datasets: [
                                                            {
                                                                label: 'Profit',
                                                                translate: t('reports:profit'),
                                                                fill: true,
                                                                data: this.state.chartData,
                                                                borderColor: 'rgb(53, 162, 235)',
                                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                            },
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('reports:incomeexpense')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                                {this.state.user != null &&
                                                                    this.state.user.value != '' && (
                                                                        <div className="invoice_detail">
                                                                            <strong>{t('income:customer')} : </strong>
                                                                            <br />
                                                                            {this.state.user.label}
                                                                        </div>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_dark">
                                                            <td>{t('alumni:category')}</td>
                                                            {this.state.monthList.map((item) => {
                                                                return <td>{item}</td>
                                                            })}
                                                        </tr>
                                                        {/*<tr className="invoice_table_header_light">
                                                            <td colSpan={13}>
                                                                <strong>{t('income:income')}</strong>
                                                            </td>
                                                        </tr>
                                                         <tr className="invoice_table_border">
                                                            <td colSpan={this.state.revenueList.length < 0 ? 13 : 0}>
                                                                {t('income:revenue')}
                                                            </td>
                                                            {this.state.revenueList.map((item) => {
                                                                return <td>{parseFloat(item).toFixed(2)}</td>
                                                            })}
                                                        </tr> */}

                                                        <tr className="invoice_table_border">
                                                            <td colSpan={this.state.invoiceList.length < 0 ? 13 : 0}>
                                                                {t('income:income')}
                                                            </td>
                                                            {this.state.invoiceList.map((item) => {
                                                                return <td>{parseFloat(item).toFixed(2)}</td>
                                                            })}
                                                        </tr>
                                                        {/* <tr className="invoice_table_header_light">
                                                            <td colSpan={13}>
                                                                <strong>{t('accounting:expense')}</strong>
                                                            </td>
                                                        </tr>
                                                         <tr className="invoice_table_border">
                                                            <td colSpan={this.state.paymentList.length < 0 ? 13 : 0}>
                                                                {t('accounting:payment')}
                                                            </td>
                                                            {this.state.paymentList.map((item) => {
                                                                return <td>{parseFloat(item).toFixed(2)}</td>
                                                            })}
                                                        </tr> */}

                                                        <tr className="invoice_table_border">
                                                            <td colSpan={this.state.billList.length < 0 ? 13 : 0}>
                                                                {t('accounting:expense')}
                                                            </td>
                                                            {this.state.billList.map((item) => {
                                                                return <td>{parseFloat(item).toFixed(2)}</td>
                                                            })}
                                                        </tr>
                                                        <tr className="invoice_table_header_dark">
                                                            <td colSpan={this.state.chartData.length == 0 ? 13 : 0}>
                                                                {t('reports:profit')}
                                                            </td>
                                                            {this.state.chartData.length > 0 &&
                                                                this.state.chartData.map((item) => {
                                                                    return (
                                                                        <td
                                                                            className={
                                                                                item < 0 ? 'negative-amount' : ''
                                                                            }
                                                                        >
                                                                            <strong>
                                                                                <small>
                                                                                    {this.props.school.currency}
                                                                                </small>
                                                                                &nbsp;
                                                                                {item < 0
                                                                                    ? parseFloat(item * -1).toFixed(2)
                                                                                    : parseFloat(item).toFixed(2)}
                                                                            </strong>
                                                                        </td>
                                                                    )
                                                                })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const IncomeExpenseSummary = withTranslation()(connect(mapStateToProps)(IncomeExpenseSummaryComponent))

export { IncomeExpenseSummary }
