export const CATEGORY_PARTICULAR = 0
export const CATEGORY_INVOICE = 1
export const CATEGORY_BILL = 2

export const category_type = [
    {
        label: 'Praticular',
        value: CATEGORY_PARTICULAR,
    },
    {
        label: 'Invoice',
        value: CATEGORY_INVOICE,
    },
    {
        label: 'Bill',
        value: CATEGORY_BILL,
    },
]

export const type_list = [
    {
        label: 'Income',
        value: 'Product',
    },
    {
        label: 'Expense',
        value: 'Service',
    },
]
export const module_list = [
    {
        label: 'Module 1',
        value: 0,
    },
    {
        label: 'Module 2',
        value: 1,
    },
    {
        label: 'Module 3',
        value: 2,
    },
]

export const customer_list = [
    {
        label: 'Ram Shrestha',
        value: 0,
    },
    {
        label: 'Shiva Shrestha',
        value: 1,
    },
    {
        label: 'Krishna Shrestha',
        value: 2,
    },
]

export const invoice_list = [
    {
        label: '#0111111',
        value: 0,
    },
    {
        label: '#0222222',
        value: 1,
    },
    {
        label: '#0333333',
        value: 2,
    },
]

export const account_list = [
    {
        label: 'Computer Equipment',
        value: 0,
    },
    {
        label: 'Inventory',
        value: 1,
    },
    {
        label: 'Accounts Payable',
        value: 2,
    },
]

export const year_list = [
    {
        label: '2023',
        value: 0,
    },
    {
        label: '2022',
        value: 1,
    },
    {
        label: '2021',
        value: 2,
    },
    {
        label: '2020',
        value: 3,
    },
]

export const status_list = [
    {
        label: 'status 1',
        value: 0,
    },
    {
        label: 'status 2',
        value: 1,
    },
    {
        label: 'status 3',
        value: 2,
    },
    {
        label: 'status 4',
        value: 3,
    },
]

export const book_category = [
    {
        label:"book 1",
        value:0,
    },
    {
        label:'book 2',
        value:1
    },
    {
        label:'book 3',
        value:2
    }
]
