import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import { Loading } from '../../../../../../components/loader/loader'
import * as route from '../../../../../../helper/constant/route'
import { AbstractChartAccountViewComponent, mapStateToProps } from './abstract'

class ChartAccountViewComponent extends AbstractChartAccountViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="double_entry" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('doubleentry:chartofaccount')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:managechartofaccount')}</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a
                                        href={route.admin.accounting.doubleentry.chartofaccount.add}
                                        className="main-button"
                                    >
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="navtab_pill">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="pill" href="#assets">
                                        {t('doubleentry:assets')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#liabilities">
                                        {t('doubleentry:liabilities')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#expense">
                                        {t('doubleentry:expenses')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#income">
                                        {t('doubleentry:income')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#equity">
                                        {t('doubleentry:equity')}
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div id="assets" role="tabpanel" className="tab-pane fade show active">
                                    <div className="main-form">
                                        <Table data={this.state.assets} />
                                    </div>
                                </div>
                                <div id="liabilities" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.liabilities} />
                                    </div>
                                </div>
                                <div id="expense" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.expenses} />
                                    </div>
                                </div>
                                <div id="income" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.income} />
                                    </div>
                                </div>
                                <div id="equity" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.equity} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
const ChartAccountView = withTranslation()(connect(mapStateToProps)(ChartAccountViewComponent))

export { ChartAccountView }
