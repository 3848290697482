import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

type Props = {
    id: string,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    locale: String,
}

export class AbstractLanguageSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            locale: this.props.school != null && this.props.school.school != null ? this.props.school.school.locale : 'en',
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        schoolPackage: state.schoolPackage,
    }
}
