import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'react-redux'
import { getClass, viewCourseMeta, getCourseContentItems, editCourseContent, deleteCourseMeta, editCourseMeta, addCourseMeta, addQuestion, addCourseNotification, deleteCourseNotification, editCourseNotification, getCourseItems, addCourseExam, viewCourseModule } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import * as courseActionType from '~/helper/constant/course'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import { getReadableFileSize } from '~/helper/function/abstract'
import moment from 'moment'
import { examination } from '../../../../../helper/constant/route'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseManageComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            isPlaying: true,
            isVideoContent: 1,
            action_type: [
                {
                    label: 'Description',
                    value: 0,
                    translate: 'common:description',
                },
                {
                    label: 'MCQ',
                    value: 1,
                },
            ],
            selectedActionType: {
                label: 'Description',
                value: 0,
                translate: 'common:description',
            },

            smsNotificationEnabled: false,
            allow_after_completion: 0,
            start_time: null,
            timeline: [],
            description: null,
            coursedescription: null,
            coursetitle: null,
            media: null,
            media_url: null,
            media_name: null,
            selectedIndex: null,
            media_size: null,
            media_format: null,
            course: [],
            course_content_meta: [],
            question_id: null,
            timeline: [],
            exam: [],
            course_meta: [],
            course_exam_id: null,
            courseEndDate: moment().format('yyyy-MM-DD'),
            courseModuleStartDate: moment().format('yyyy-MM-DD'),
            question: '',
            answers: [null, null, null, null],
            hours: 0,
            minutes: 0,
            questionError: null,
            answerError: [null, null, null, null],
            seconds: 0,
            thumbnail: {},
            timestamp: '',
            answer: ['', '', '', ''],
            notification_time: 0,
            maxtimestamp: 0,
            emailNotification: false,
            smsNotification: false,
            exam_id: null,
            unit: [
                {
                    label: 'minutes',
                    value: 'minutes',
                    translate: 'course:minutes',
                },
                {
                    label: 'hours',
                    value: 'hours',
                    translate: 'course:hours',
                },
                {
                    label: 'days',
                    value: 'days',
                    translate: 'common:days',
                },
                {
                    label: 'months',
                    value: 'months',
                    translate: 'calendar:month',
                },
            ],
            selectUnit: {
                label: 'minutes',
                value: 'minutes',
                translate: 'course:minutes',
            },
            notification_data: [],
            notification_table: [
                {
                    notification_time: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:notification_time',
                    },
                    email_notification: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:email_notification',
                    },
                    update: {
                        field: 'html',
                        route: false,
                        sortable: false,
                        translate: 'common:EditBtn',
                    },
                    action: {
                        field: 'html',
                        route: false,
                        sortable: false,
                        translate: 'classes:class.action',
                    },
                },
            ],
            errors: {
                coursetitle: null,
                coursedescription: null,
            },
            course_meta_errors: {
                hours: null,
                minutes: null,
                seconds: null,
            },
            notification_meta_errors: {
                notification_time: null,
            },
            descriptionError: null,
            descriptionUpdateError: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseContent = this.loadCourseContent.bind(this)
        this.editCourse = this.editCourse.bind(this)
        this.openAddCourseMeta = this.openAddCourseMeta.bind(this)
        this.openAddCourseMetaQuiz = this.openAddCourseMetaQuiz.bind(this)
        this.saveCourseMeta = this.saveCourseMeta.bind(this)
        this._onSaveQuestion = this._onSaveQuestion.bind(this)
        this.openDeleteMeta = this.openDeleteMeta.bind(this)
        this.openNotificationEdit = this.openNotificationEdit.bind(this)
        this.openNotificationModel = this.openNotificationModel.bind(this)
        this.setMaxTimeStamp = this.setMaxTimeStamp.bind(this)
        this.saveNotification = this.saveNotification.bind(this)
        this.deleteNotification = this.deleteNotification.bind(this)
        this.loadClass = this.loadClass.bind(this)
        this._onSaveExam = this._onSaveExam.bind(this)
    }

    componentDidMount() {
        this.loadCourseContent()
        this.loadCourse()
        if (this.state.smsNotificationEnabled) {
            this.setState({
                notification_table: [
                    {
                        notification_time: {
                            field: 'text',
                            route: false,
                            sortable: true,
                        },
                        email_notification: {
                            field: 'text',
                            route: false,
                            sortable: true,
                        },
                        sms_notification: {
                            field: 'text',
                            route: false,
                            sortable: true,
                        },
                        update: {
                            field: 'html',
                            route: false,
                            sortable: false,
                        },
                        action: {
                            field: 'html',
                            route: false,
                            sortable: false,
                        },
                    },
                ],
            })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setMaxTimeStamp(duration) {
        this.setState({
            maxtimestamp: duration,
        })
    }

    openAddCourseMeta() {
        this.setState(
            {
                selectedActionType: this.state.action_type[0],
                hours: 0,
                minutes: 0,
                seconds: 0,
                timestamp: '',
                selectedIndex: null,
                description: '',
                answers: [null, null, null, null],
                answer: ['', '', '', ''],
                question: '',
                course_meta_errors: {
                    hours: '',
                    minutes: '',
                    seconds: '',
                },
                questionError: null,
                answerError: [null, null, null, null],
                descriptionError: null,
            },
            () => {
                $('#modal_course_meta_add').modal('show')
            }
        )
    }

    openDeleteMeta(index) {
        this.setState(
            {
                selectedIndex: index,
            },
            () => $('#modal_course_meta_delete').modal('show')
        )
    }

    openNotificationEdit(index) {
        this.setState(
            {
                selectedIndex: index,
                notification_time: this.state.notification_data[index]['notification_time'],
                selectUnit: this.state.unit.filter((el) => el.value == this.state.notification_data[index]['notification_unit'])[0],
                emailNotification: this.state.notification_data[index]['email'],
                smsNotification: this.state.notification_data[index]['sms'],
            },
            () => $('#modal_notification_add').modal('show')
        )
    }

    openNotificationDelete(index) {
        this.setState(
            {
                selectedIndex: index,
            },
            () => $('#modal_course_notification_delete').modal('show')
        )
    }

    openNotificationModel() {
        this.setState(
            {
                notification_time: '',
                selectedIndex: null,
                emailNotification: false,
                smsNotification: false,
            },
            () => {
                $('#modal_notification_add').modal('show')
            }
        )
    }

    openAddCourseMetaQuiz() {
        let answer = ['', '', '', '']
        this.setState(
            {
                selectedActionType: this.state.action_type[1],
                hours: 0,
                minutes: 0,
                seconds: 0,
                selectedIndex: null,
                timestamp: '',
                value: '',
                description: '',
                answer: answer,
                answers: [null, null, null, null],
                question: '',
                course_meta_errors: {
                    hours: '',
                    minutes: '',
                    seconds: '',
                },
                questionError: null,
                answerError: [null, null, null, null],
                descriptionError: null,
            },
            () => {
                $('#modal_course_meta_addQ').modal('show')
            }
        )
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseItems(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        course_name: response.data.post.title,
                        courseEndDate: moment(response.data.post.to_date).format('yyyy-MM-DD'),
                        course_id: response.data.post.id,
                        class_id: response.data.post.class_access_information[0].id,
                        subject_id: response.data.post.subject_id,
                    },
                    () => {
                        // this.loadCourseModule()
                        this.loadClass()
                    }
                )
            }
        })
    }

    loadClass() {
        this._handleChange('loading', true)
        getClass(this.state.class_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    classes: response.data.class,
                })
            }
        })
    }
    // loadCourseModule() {
    //     this._handleChange('loading_text', 'Loading')
    //     this._handleChange('loading', true)
    //     console.log(this.props.match.params.course_id)
    //     viewCourseModule(this.props.match.params.course_id, this.props.token).then((response) => {
    //         this._handleChange('loading', false)
    //         if (response.code == API_SUCCESS) {
    //             const courseModule = response.data.course_module
    //             console.log(courseModule)
    //             this.setState({
    //                 courseModuleStartDate: moment(courseModule.release_date).format('yyyy-MM-DD'),
    //             })
    //         }
    //     })
    // }

    loadCourseContent() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseContentItems(this.props.match.params.course_content_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let notification_table = []
                let notification_data = []
                let tableData = {}
                notification_table.push(this.state.notification_table[0])
                const courseModule = response.data.course_content.course_module

                const course_notification = response.data.course_content.notification.sort((x, y) => moment(y.timestamp, 'yyyy-MM-DD - hh:mm:ss a').diff(moment(x.timestamp, 'yyyy-MM-DD - hh:mm:ss a')))
                course_notification.map((item, index) => {
                    let timediff = moment(response.data.course_content.start_time, 'yyyy-MM-DD - hh:mm:ss a').diff(moment(item.timestamp, 'yyyy-MM-DD - hh:mm:ss a')) / 1000
                    let notification_unit = ''
                    let notification_time = ''
                    if (timediff < 3600) {
                        notification_time = parseInt(timediff / 60)
                        notification_unit = 'minutes'
                        timediff = parseInt(timediff / 60).toString() + ' minutes'
                    } else if (timediff < 86400) {
                        notification_time = parseInt(timediff / (60 * 60))
                        notification_unit = 'hours'
                        timediff = parseInt(timediff / (60 * 60)).toString() + ' hours'
                    } else if (timediff < 2592000) {
                        notification_time = parseInt(timediff / (60 * 60 * 24))
                        notification_unit = 'days'
                        timediff = parseInt(timediff / (60 * 60 * 24)).toString() + ' days'
                    } else {
                        notification_time = parseInt(timediff / (60 * 60 * 24 * 30))
                        notification_unit = 'months'
                        timediff = parseInt(timediff / (60 * 60 * 24 * 30)).toString() + ' months'
                    }
                    if (this.state.smsNotificationEnabled) {
                        tableData = {
                            notification_time: {
                                text: timediff,
                            },
                            email_notification: {
                                text: item.email ? 'Yes' : 'no',
                            },
                            sms_notification: {
                                text: item.sms ? 'Yes' : 'no',
                            },
                            update: {
                                text: 'html',
                                html: (
                                    <button type="button" className="table-check" data-dismiss="modal" onClick={() => this.openNotificationEdit(item.id)}>
                                        {t('common:EditBtn')}
                                    </button>
                                ),
                            },
                            action: {
                                text: 'html',
                                html: (
                                    <button type="button" className="table-check" data-dismiss="modal" onClick={() => this.openNotificationDelete(item.id)}>
                                        {t('common:delete')}
                                    </button>
                                ),
                            },
                        }
                    } else {
                        tableData = {
                            notification_time: {
                                text: timediff,
                            },
                            email_notification: {
                                text: item.email ? t('common:yesBtn') : t('common:noBtn'),
                            },
                            update: {
                                text: 'html',
                                html: (
                                    <button type="button" className="table-check" data-dismiss="modal" onClick={() => this.openNotificationEdit(item.id)}>
                                        {t('common:EditBtn')}
                                    </button>
                                ),
                            },
                            action: {
                                text: 'html',
                                html: (
                                    <button type="button" className="table-check" data-dismiss="modal" onClick={() => this.openNotificationDelete(item.id)}>
                                        {t('common:delete')}
                                    </button>
                                ),
                            },
                        }
                    }
                    item['notification_time'] = notification_time
                    item['notification_unit'] = notification_unit
                    notification_data[item.id] = item
                    notification_table.push(tableData)
                })
                const isVideoContent = response.data.course_content.media.media_type.includes('video') ? 1 : 0
                this.setState(
                    {
                        courseModuleStartDate: moment(courseModule.release_date).format('yyyy-MM-DD'),
                        notification_data,
                        notification_table,
                        isVideoContent,
                        course: response.data.course_content,
                        start_time: response.data.course_content.start_time ? moment(response.data.course_content.start_time, 'yyyy-MM-DD - hh:mm:ss a').toDate() : null,
                        coursetitle: response.data.course_content.name,
                        coursedescription: response.data.course_content.description,
                        media: response.data.course_content.media,
                        media_date: response.data.course_content.start_time,
                        media_name: response.data.course_content.media.name,
                        media_size: getReadableFileSize(response.data.course_content.media.size),
                        media_format: response.data.course_content.media.name.split('.').pop(),
                        media_url: response.data.course_content.media.media_url,
                        thumbnail_url: response.data.course_content.thumbnail ? response.data.course_content.thumbnail.media_url : null,
                        course_content_meta: response.data.course_content.meta,
                    },
                    () => this.loadCourseMeta()
                )
            }
        })
    }

    loadCourseMeta() {
        let course_meta = []
        let timeline = []
        let exam = []
        let exam_id = null
        const course_content_meta = this.state.course_content_meta.sort((x, y) => x.timestamp - y.timestamp)
        course_content_meta.map((item, index) => {
            const data = item
            let hours = 0
            let minutes = 0
            let seconds = 0
            let action_type = null
            let value = null

            action_type = this.state.action_type.filter((el) => el.value == data.action_type)
            action_type = action_type ? action_type[0].label : ''
            hours = parseInt(data.timestamp / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
            minutes = parseInt((data.timestamp % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
            seconds = parseInt(data.timestamp % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
            value = data.action_type == 0 ? data.Description : data.post_child
            data['timestamp'] = data.timestamp
            data['hours'] = hours
            data['minutes'] = minutes
            data['seconds'] = seconds

            if (data.action_type == 0) {
                timeline.push(data)
            } else {
                if (data.questions.length > 0 && this.state.exam_id == null) {
                    this.setState({
                        exam_id: data.questions[0]['exam_id'],
                    })
                }
                data['post_child'] = data.post_child
                exam.push(data)
            }
            course_meta.push(data)
        })
        this.setState({
            timeline,
            exam,
            course_meta,
        })
    }

    updateCourseMeta(meta_id, type, index, post_child) {
        const { t } = this.props

        let hasError = false
        let timestamp = null
        if (type == courseActionType.TIMELINE) {
            if (this.state.timeline[index]['hours'] == '' || this.state.timeline[index]['minutes'] == '' || this.state.timeline[index]['seconds'] == '') {
                toastMessage('error', t('course:timeline_1'))
                hasError = true
            }
            timestamp = parseInt(this.state.timeline[index]['hours']) * 3600 + parseInt(this.state.timeline[index]['minutes']) * 60 + parseInt(this.state.timeline[index]['seconds'])
        } else {
            if (this.state.exam[index]['hours'] == '' || this.state.exam[index]['minutes'] == '' || this.state.exam[index]['seconds'] == '') {
                toastMessage('error', t('course:timeline_1'))
                hasError = true
            }
            timestamp = parseInt(this.state.exam[index]['hours']) * 3600 + parseInt(this.state.exam[index]['minutes']) * 60 + parseInt(this.state.exam[index]['seconds'])
        }

        if (timestamp > this.player.duration) {
            toastMessage('error', t('course:time_1'))
            return
        }
        const checkIfTimeExists = this.state.course_meta.filter((el) => el.timestamp == timestamp && el.id != meta_id)
        if (checkIfTimeExists.length > 0) {
            toastMessage('error', t('course:quiz_1'))
            return
        }
        if (type == courseActionType.TIMELINE) {
            if (this.state.timeline[index]['Description'] == '') {
                let descriptionUpdateError = []
                descriptionUpdateError[index] = t('toaster:required')
                this.setState({
                    descriptionUpdateError,
                })
                hasError = true
            }
        }
        if (hasError == false) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                timestamp: timestamp,
                action_type: type == courseActionType.TIMELINE ? 0 : 1,
                course_content_id: this.props.match.params.course_content_id,
            }

            if (type == courseActionType.TIMELINE) {
                data['description'] = this.state.timeline[index]['Description']
            } else {
                data['post_child'] = post_child
            }
            editCourseMeta(meta_id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    this.setState({
                        descriptionUpdateError: [],
                    })
                    this.loadCourseContent()
                    // window.reload();
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    deleteMeta() {
        this._handleChange('loading_text', 'Deleting')
        this._handleChange('loading', true)

        deleteCourseMeta(this.state.selectedIndex, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadCourseContent()
                $('#close_media_modal').trigger('click')
                this.setState({
                    selectedIndex: null,
                })
                // window.reload();
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    deleteNotification() {
        this._handleChange('loading_text', 'Deleting')
        this._handleChange('loading', true)

        deleteCourseNotification(this.state.selectedIndex, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadCourseContent()
                this.setState({
                    selectedIndex: null,
                })
                $('#close_notification_modal').trigger('click')
                // window.reload();
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    editCourse() {
        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        let data = {
            name: this.state.coursetitle,
            description: this.state.coursedescription,
            course_module_id: this.state.course.course_module_id,
            thumbnail: this.state.thumbnail,
            start_time: moment(this.state.start_time).format('yyyy-MM-DD - hh:mm:ss A'),
            allow_after_completion: this.state.allow_after_completion,
        }
        editCourseContent(this.props.match.params.course_content_id, data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.setState({
                    thumbnail: {},
                })
                this.loadCourseContent()
                // window.reload();
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    saveCourseMeta() {
        const { t } = this.props
        let hasError = false
        const course_meta_errors = validation.required(Object.keys(this.state.course_meta_errors), this.state)
        this.setState({ course_meta_errors })
        const validate = Object.keys(course_meta_errors).filter((item) => course_meta_errors[item] != null)
        if (this.state.selectedActionType.value == courseActionType.TIMELINE) {
            if (this.state.description == '') {
                this.setState({
                    descriptionError: t('toaster:required'),
                })
                hasError = true
            }
        }
        if (validate.length == 0 && hasError == false) {
            const timestamp = parseInt(this.state.hours) * 3600 + parseInt(this.state.minutes) * 60 + parseInt(this.state.seconds)
            if (timestamp > this.player.duration) {
                toastMessage('error', t('course:time_1'))
                return
            }
            const checkIfTimeExists = this.state.course_meta.filter((el) => el.timestamp == timestamp)
            if (checkIfTimeExists.length > 0) {
                toastMessage('error', t('course:quiz_1'))
                return
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                timestamp: timestamp,
                action_type: this.state.selectedActionType.value,
                course_content_id: this.props.match.params.course_content_id,
            }

            if (this.state.selectedActionType.value == courseActionType.TIMELINE) {
                data['description'] = this.state.description
            } else {
                data['post_child'] = this.state.course_exam_id
            }

            addCourseMeta(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    $('#close_add').trigger('click')
                    this.loadCourseContent()
                    // window.reload();
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    saveNotification() {
        const { t } = this.props

        let hasError = false
        const notification_meta_errors = validation.required(Object.keys(this.state.notification_meta_errors), this.state)
        this.setState({ notification_meta_errors })
        const validate = Object.keys(notification_meta_errors).filter((item) => notification_meta_errors[item] != null)
        if (!this.state.start_time) {
            toastMessage('error', t('course:start_notif'))
            return
        }
        if (parseInt(this.state.notification_time) < 0) {
            this.setState({
                notification_meta_errors: {
                    notification_time: t('course:notif_negative'),
                },
            })
            return
        }
        const timestamp = moment(this.state.start_time, 'yyyy-MM-DD - hh:mm:ss a').subtract(this.state.notification_time, this.state.selectUnit.value).format('yyyy-MM-DD - hh:mm:ss a')
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                course_content_id: this.props.match.params.course_content_id,
                timestamp: timestamp,
                email: this.state.emailNotification,
                sms: this.state.smsNotification,
            }
            if (this.state.selectedIndex) {
                editCourseNotification(this.state.selectedIndex, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add_notif').trigger('click')
                        this.loadCourseContent()
                        // window.reload();
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            } else {
                addCourseNotification(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add_notif').trigger('click')
                        this.loadCourseContent()
                        // window.reload();
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _onSaveQuestion(e) {
        const { t } = this.props

        e.preventDefault()
        let hasError = false
        let answerError = []
        const timestamp = parseInt(this.state.hours) * 3600 + parseInt(this.state.minutes) * 60 + parseInt(this.state.seconds)
        if (timestamp > this.player.duration) {
            toastMessage('error', t('course:time_1'))
            return
        }
        const checkIfTimeExists = this.state.course_meta.filter((el) => el.timestamp == timestamp)
        if (checkIfTimeExists.length > 0) {
            toastMessage('error', t('course:quiz_1'))
            return
        }
        const course_meta_errors = validation.required(Object.keys(this.state.course_meta_errors), this.state)
        this.setState({ course_meta_errors })
        if (this.state.question == null || this.state.question == '') {
            hasError = true
            this.setState({
                questionError: t('toaster:required'),
            })
        } else {
            this.setState({
                questionError: '',
            })
        }

        this.state.answers.map((item, index) => {
            if (this.state.answers[index] == null || this.state.answers[index] == '') {
                hasError = true
                answerError.push(t('toaster:required'))
            } else {
                answerError.push(null)
            }
        })
        this.setState(
            {
                answerError: answerError,
            },
            () => {}
        )
        const validate = Object.keys(course_meta_errors).filter((item) => course_meta_errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this.setState({ objective_errors: '' })
            if (this.state.answers.length == 0) {
                const objective_errors = t('course:no_objective')
                this.setState({ objective_errors })
            } else {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                const data = {
                    questions: this.state.question,
                    answers: this.state.answers,
                    marks: 1,
                    type: 1,
                    answers: this.state.answers,
                }
                addQuestion(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        // toastMessage('success', response.message)
                        const question_id = response.data.question.id
                        this.setState(
                            {
                                question_id,
                            },
                            () => {
                                this._onSaveExam()
                            }
                        )
                        $('#close_addQ').trigger('click')
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _onSaveExam() {
        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        const data = {
            course_content_id: this.props.match.params.course_content_id,
            class_id: this.state.class_id,
            question_id: this.state.question_id,
            subject_id: this.state.subject_id,
        }
        if (this.state.exam_id != null) {
            data['exam_id'] = this.state.exam_id
        }
        addCourseExam(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const course_exam_id = response.data.course_exam.id
                // toastMessage('success', response.message)
                this.setState(
                    {
                        course_exam_id,
                    },
                    () => this.saveCourseMeta()
                )
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }
}

withTranslation()(AbstractCourseManageComponent)
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
