import { accountingGet, accountingPost } from '../accountingRequest'

export const viewBill = (start_date, end_date, vendor, status, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'bill?bill_date=' + start_date + ' to ' + end_date + '&vender=' + vendor + '&status=' + status,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getVendorBalance = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('vender/balance/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addBill = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addBulkBill = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/bulk/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editBill = (bill, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('bill/edit/' + bill, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBill = (bill, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/update/' + bill, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteBill = (bill, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('bill/delete' + bill, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addPaymentBill = (bill_id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/' + bill_id + '/payment', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deletePaymentBill = (bill_id, payment_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/' + bill_id + '/payment/' + payment_id + '/destroy', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteBillRow = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bill/product/destroy', token, id).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewPayment = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('payment', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addPayment = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('payment/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editPayment = (expense, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('payment/edit/' + expense, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updatePayment = (expense, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('payment/update/' + expense, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
// export const deletePayment = (expense, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
//     return new Promise(function (resolve, reject) {
//         try {
//             accountingGet('payment/delete' + expense, data, token).then((response) => {
//                 resolve(response)
//             })
//         } catch ($e) {
//             resolve(null)
//         }
//     })
// }
