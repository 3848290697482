import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractUserComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'

class UserComponent extends AbstractUserComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')}</a>
                            </li>
                        </ul>
                        {AccessModule.checkSubAccess([AccessModule.STUDENT, AccessModule.STUDENT_CATEGORY], role) && access.includes(AccessModule.Module_Student_Management) && (
                            <div className="row clearfix">
                                <div className="col-md-5 float-left">
                                    <h5 className="main-title heading5">{t('users:studentTitle')}:</h5>
                                </div>
                            </div>
                        )}
                    </div>
                    {AccessModule.checkSubAccess([AccessModule.STUDENT, AccessModule.STUDENT_CATEGORY], role) && access.includes(AccessModule.Module_Student_Management) && (
                        <div className="folder-page">
                            <div className="row clearfix">
                                {AccessModule.checkSubAccess([AccessModule.STUDENT_CATEGORY], role) && (
                                    <div className="float-left course-your">
                                        <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.student.category.view)}>
                                            <a href={route.admin.user.student.category.view} className="folder-img">
                                                {SVG.studentcategory()}
                                            </a>
                                            <a href={route.admin.user.student.category.view} className="folder-name">
                                                {t('users:student.categoryTitle')}
                                                <span> {t('users:student.categorySubTitle')}</span>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {AccessModule.checkSubAccess([AccessModule.STUDENT], role) && (
                                    <div className="float-left course-your">
                                        <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.student.menu)}>
                                            <a href={route.admin.user.student.menu} className="folder-img">
                                                {SVG.student()}
                                            </a>
                                            <a href={route.admin.user.student.menu} className="folder-name">
                                                {t('users:student.title')}
                                                <span> {t('users:student.subTitle')}</span>
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {AccessModule.checkSubAccess([AccessModule.STUDENT], role) && (
                                    <div className="float-left course-your">
                                        <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.transfer)}>
                                            <a href={route.admin.user.transfer} className="folder-img">
                                                {SVG.sectionTransfer()}
                                            </a>
                                            <a href={route.admin.user.transfer} className="folder-name">
                                                {t('users:student.sectionTitle')}
                                                <span> {t('users:student.sectionSubTitle')}</span>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <br />
                    {AccessModule.checkSubAccess([AccessModule.ROLE_MANAGEMENT, AccessModule.EMPLOYEE_CATEGORY, AccessModule.EMPLOYEE], role) && access.includes(AccessModule.Module_Employee_Management) && (
                        <Fragment>
                            <div className="main-head-option">
                                <div className="row clearfix">
                                    <div className="col-md-5 float-left">
                                        <h5 className="main-title heading5">{t('users:EmployeeTitle')}:</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="folder-page">
                                <div className="row clearfix">
                                    {AccessModule.checkSubAccess([AccessModule.ROLE_MANAGEMENT], role) && (
                                        <div className="float-left course-your">
                                            <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.role.view)}>
                                                <a href={route.admin.user.role.view} className="folder-img">
                                                    {SVG.rolemanagement()}
                                                </a>
                                                <a href={route.admin.user.role.view} className="folder-name">
                                                    {t('users:role.title')}
                                                    <span> {t('users:role.subTitle')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {AccessModule.checkSubAccess([AccessModule.EMPLOYEE_CATEGORY], role) && (
                                        <div className="float-left course-your">
                                            <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.employee.category.view)}>
                                                <a href={route.admin.user.employee.category.view} className="folder-img">
                                                    {SVG.employeecategory()}
                                                </a>
                                                <a href={route.admin.user.employee.category.view} className="folder-name">
                                                    {t('users:employee.categoryTitle')}
                                                    <span>{t('users:employee.categorySubTitle')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {AccessModule.checkSubAccess([AccessModule.EMPLOYEE], role) && (
                                        <div className="float-left course-your">
                                            <div className="folder-list clearfix" onClick={() => (window.location = route.admin.user.employee.menu)}>
                                                <a href={route.admin.user.employee.menu} className="folder-img">
                                                    {SVG.employee()}
                                                </a>
                                                <a href={route.admin.user.employee.menu} className="folder-name">
                                                    {t('users:employee.title')}
                                                    <span>{t('users:employee.subTitle')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        )
    }
}

const User = withTranslation()(connect(mapStateToProps)(UserComponent))

export { User }
