import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSmsPackageEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import { country } from '~/helper/constant/country'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { sms } from '~/helper/constant/sms'
import { Loading } from '~/components/loader'
class SmsPackageEditComponent extends AbstractSmsPackageEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectCurrency = this._selectCurrency.bind(this)
        this._selectSms = this._selectSms.bind(this)
        this._selectCountry = this._selectCountry.bind(this)
    }

    _selectCurrency(data) {
        super._handleChange('currency', data.value)
        this._handleChange('defaultCurrency', data)
    }

    _selectSms(data) {
        super._handleChange('type', data.value)
        this._handleChange('defaultType', data)
    }

    _selectCountry(data) {
        const country = []
        if (data) {
            data.map((item) => {
                country.push(item.value)
            })
        }
        super._handleChange('country', country)
        this._handleChange('defaultCountry', data)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="sms_package" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.sms_package.view}>SMS Packages &gt; </a>
                            </li>
                            <li>Edit</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Edit SMS Package:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">SMS Package Name *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder="SMS Package Name" type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">SMS Package Code *</label>
                                    <Input name="code" disabled="disabled" placeholder="SMS Package Code" type="text" value={this.state.code} />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">SMS Limit *</label>
                                    <Input name="limit" onChange={this._handleChange} placeholder="SMS Limit"  value={this.state.limit} />
                                    {this.state.errors.limit && <label className="error text-danger">{this.state.errors.limit}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-4 float-left form-group main-select">
                                    <label className="form-title">SMS Vendor*</label>
                                    <Select name="type" options={sms} placeholder="SMS Vendor" searchable={true} value={this.state.defaultType} defaultValue={sms[0]} onChange={this._selectSms} />
                                    {this.state.errors.type && <label className="error text-danger">{this.state.errors.type}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group main-select">
                                    <label className="form-title">Currency *</label>
                                    <Select name="currency" options={currency} placeholder="Currency" searchable={true} value={this.state.defaultCurrency} defaultValue={currency[31]} onChange={this._selectCurrency} />
                                    {this.state.errors.currency && <label className="error text-danger">{this.state.errors.currency}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">Price *</label>
                                    <Input name="rate" onChange={this._handleChange} placeholder="Price"  value={this.state.rate} />
                                    {this.state.errors.rate && <label className="error text-danger">{this.state.errors.rate}</label>}
                                </div>
                            </div>
                            <hr />
                            <div className="main-head-option">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left">
                                        <h5>Country Availability</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group main-select main-multi-select">
                                    <label className="form-title">Country *</label>
                                    <Select name="country" options={country} placeholder="Country" searchable={true} isMulti value={this.state.defaultCountry} onChange={this._selectCountry} />
                                    {this.state.errors.country && <label className="error text-danger">{this.state.errors.country}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text="Update" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SmsPackageEdit = connect(mapStateToProps)(SmsPackageEditComponent)

export { SmsPackageEdit }
