import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { AbstractMySchoolComponent, mapStateToProps } from './abstract'

class MySchoolComponent extends AbstractMySchoolComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._getSchool = this._getSchool.bind(this)
    }

    _getSchool() {
        const school = this.props.user.user_school.map((item, index) => {
            let role = UserType.role_type.find((role) => item.user_type == role.value)
            role = role.label
            const { t } = this.props
            return (
                <div key={index} className="float-left form-group col-md-4 col-lg-3">
                    <label className="choose-role-list choose-course">
                        <input
                            type="radio"
                            name="selectclass"
                            className="select-class"
                            defaultChecked={item.id == this.props.school.id ? true : false}
                        />
                        <div className="class-select">
                            <div className="sc-card" onClick={() => this._onSave(item.id)}>
                                {item.school.profile == null && (
                                    <div className="school-title">
                                        <span>{item.school.name.charAt(0)}</span>
                                    </div>
                                )}
                                {item.school.profile != null && <img src={item.school.profile} alt="" />}
                                <h3>{item.school.name}</h3>
                                <span>{role}</span>
                            </div>
                            {item.id == this.props.school.id && <div className="checked-icon">{SVG.checked()}</div>}
                            <div className="e-d-t">
                                <div className="dropdown">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        id="e-d-t"
                                        data-toggle="collapse"
                                        data-target={'#edit_' + item.id}
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                    </button>
                                    <div className="dropdown-menu" id={'edit_' + item.id} aria-labelledby="e-d-t">
                                        {item.user_type == UserType.Admin && (
                                            <button
                                                onClick={() => this._onEdit(item.school_id)}
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                {t('EditBtn')}
                                            </button>
                                        )}
                                        <button
                                            onClick={() => this._onSave(item.id)}
                                            className="dropdown-item"
                                            type="button"
                                        >
                                            {t('select')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            )
        })
        return school
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content setup-main">
                    <Header history={this.props.history} className="setup-nav" hideToggle={true} />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.activity}>{t('setting:dashboard')} &gt; </a>
                            </li>
                            <li>{t('header:myschool')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title">{t('header:myschool')}:</h5>
                            </div>
                        </div>
                    </div>
                    <section className="school-list">
                        <form className="choose-role">
                            <div className="row clearfix">{this._getSchool()}</div>
                        </form>
                    </section>
                </div>
            </div>
        )
    }
}

const MySchool = withTranslation()(connect(mapStateToProps)(MySchoolComponent))

export { MySchool }
