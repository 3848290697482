import React, { Component, Fragment } from 'react'
import { AbstractNepaliEventCalendarComponent } from './abstract'
import { NepaliMonth } from '../../helper/constant/nepali-month'
import { days, short_days } from '../../helper/constant/days'
import { convertBStoAD } from '../../helper/function'
import moment from 'moment'
import * as SVG from '~/assets/svg'

export class NepaliEventCalendar extends AbstractNepaliEventCalendarComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.error == false && (
                    <div class="fc fc-media-screen fc-direction-ltr fc-theme-standard nepali-event-calendar" bis_skin_checked="1">
                        <div class="fc-header-toolbar fc-toolbar fc-toolbar-ltr" bis_skin_checked="1">
                            <div class="fc-toolbar-chunk" bis_skin_checked="1">
                                <div class="fc-button-group" bis_skin_checked="1">
                                    <button onClick={this.prevMonth} class="fc-prev-button fc-button fc-button-primary" type="button" aria-label="prev">
                                        <span class="fc-icon fc-icon-chevron-left"></span>
                                    </button>
                                    <button onClick={this.nextMonth} class="fc-next-button fc-button fc-button-primary" type="button" aria-label="next" disabled="">
                                        <span class="fc-icon fc-icon-chevron-right"></span>
                                    </button>
                                </div>
                                <button onClick={this.today} disabled={this.state.disableToday ? 'disabled' : ''} class="fc-today-button fc-button fc-button-primary" type="button">
                                    today
                                </button>
                            </div>
                            <div class="fc-toolbar-chunk" bis_skin_checked="1">
                                <h2 class="fc-toolbar-title">
                                    {NepaliMonth[this.state.currentMonth]} {this.state.currentYear}
                                </h2>
                            </div>
                            <div class="fc-toolbar-chunk" bis_skin_checked="1">
                                {/* <div class="fc-button-group" bis_skin_checked="1">
                                    <button class="fc-dayGridMonth-button fc-button fc-button-primary fc-button-active" type="button">
                                        month
                                    </button>
                                    <button class="fc-timeGridWeek-button fc-button fc-button-primary" type="button">
                                        week
                                    </button>
                                    <button class="fc-timeGridDay-button fc-button fc-button-primary" type="button">
                                        day
                                    </button>
                                    <button class="fc-listWeek-button fc-button fc-button-primary" type="button">
                                        list
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div class="fc-view-harness fc-view-harness-active" bis_skin_checked="1">
                            <div class="fc-daygrid fc-dayGridMonth-view fc-view" bis_skin_checked="1">
                                <table class="fc-scrollgrid  fc-scrollgrid-liquid">
                                    <thead>
                                        <tr class="fc-scrollgrid-section fc-scrollgrid-section-header ">
                                            <td>
                                                <div class="fc-scroller-harness" bis_skin_checked="1">
                                                    <div class="fc-scroller" bis_skin_checked="1">
                                                        <table class="fc-col-header ">
                                                            <colgroup></colgroup>
                                                            <tbody>
                                                                <tr>
                                                                    <th class="fc-col-header-cell fc-day fc-day-sun">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Sun</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-mon">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Mon</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-tue">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Tue</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-wed">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Wed</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-thu">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Thu</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-fri">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion ">Fri</a>
                                                                        </div>
                                                                    </th>
                                                                    <th class="fc-col-header-cell fc-day fc-day-sat">
                                                                        <div class="fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                            <a class="fc-col-header-cell-cushion error">Sat</a>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="fc-scrollgrid-section fc-scrollgrid-section-body  fc-scrollgrid-section-liquid">
                                            <td>
                                                <div class="fc-scroller-harness fc-scroller-harness-liquid" bis_skin_checked="1">
                                                    <div class="fc-scroller fc-scroller-liquid-absolute" bis_skin_checked="1">
                                                        <div class="fc-daygrid-body fc-daygrid-body-unbalanced " bis_skin_checked="1">
                                                            <table class="fc-scrollgrid-sync-table">
                                                                <colgroup></colgroup>
                                                                <tbody>
                                                                    {this.state.calendar.map((calendar) => {
                                                                        return (
                                                                            <tr>
                                                                                {calendar.map((item, index) => {
                                                                                    const dayName = short_days[index]
                                                                                    let calendarClass = 'fc-daygrid-day fc-day'
                                                                                    if (item == 0) {
                                                                                        calendarClass = calendarClass + ' fc-day-' + dayName + ' fc-day-disabled'
                                                                                    } else {
                                                                                        calendarClass = calendarClass + ' fc-day-' + dayName + ' fc-day-past'
                                                                                    }
                                                                                    const currentMonth = this.state.currentMonth + 1
                                                                                    const month = currentMonth < 10 ? '0' + currentMonth : currentMonth
                                                                                    const day = item < 10 ? '0' + item : item
                                                                                    const date = this.state.currentYear + '-' + month + '-' + day
                                                                                    let events = []
                                                                                    if (item != 0) {
                                                                                        events = this.state.events.filter((event) => {
                                                                                            if (event.allDay == true) {
                                                                                                const englishDate = moment(convertBStoAD(date), 'yyyy-MM-DD')
                                                                                                const from_date = moment(event.from_date, 'yyyy-MM-DD').subtract(1, 'day')
                                                                                                const to_date = moment(event.to_date, 'yyyy-MM-DD').subtract(1, 'day')
                                                                                                if (englishDate.isAfter(from_date) && englishDate.isSameOrBefore(to_date)) {
                                                                                                    return true
                                                                                                }
                                                                                            } else {
                                                                                                const englishDate = moment(convertBStoAD(date), 'yyyy-MM-DD')
                                                                                                const from_date = moment(event.nepali_from_date, 'yyyy-MM-DD')
                                                                                                const to_date = moment(event.nepali_to_date, 'yyyy-MM-DD')
                                                                                                if (englishDate.isSame(from_date)) {
                                                                                                    return true
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    return (
                                                                                        <td class={calendarClass} data-date={date}>
                                                                                            <div class="fc-daygrid-day-frame fc-scrollgrid-sync-inner" bis_skin_checked="1">
                                                                                                <div class="fc-daygrid-day-top" bis_skin_checked="1">
                                                                                                    {index == 6 && <a class="fc-daygrid-day-number error">{item != 0 ? item : ''}</a>}
                                                                                                    {index != 6 && <a class="fc-daygrid-day-number">{item != 0 ? item : ''}</a>}
                                                                                                </div>

                                                                                                <div class="fc-daygrid-day-events" bis_skin_checked="1">
                                                                                                    {events.map((event) => {
                                                                                                        if (event.allDay == true) {
                                                                                                            return (
                                                                                                                <div class="fc-daygrid-event-harness" bis_skin_checked="1">
                                                                                                                    <a onClick={(e) => this.props.eventClick(event, e)} class={'fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-start fc-event-end fc-event-past  ' + event.classNames}>
                                                                                                                        <div class="fc-event-main" bis_skin_checked="1">
                                                                                                                            <div class="fc-event-main-frame" bis_skin_checked="1">
                                                                                                                                <div class="fc-event-title-container" bis_skin_checked="1">
                                                                                                                                    <div class="fc-event-title fc-sticky" bis_skin_checked="1">
                                                                                                                                        {event.title}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <div class="fc-daygrid-event-harness" bis_skin_checked="1">
                                                                                                                    <a onClick={(e) => this.props.eventClick(event, e)} class={'fc-daygrid-event fc-daygrid-dot-event fc-event fc-event-start fc-event-end fc-event-past ' + event.classNames}>
                                                                                                                        <div class="fc-daygrid-event-dot" bis_skin_checked="1"></div>
                                                                                                                        <div class="fc-event-time" bis_skin_checked="1">
                                                                                                                            {event.start_time}
                                                                                                                        </div>
                                                                                                                        <div class="fc-event-title" bis_skin_checked="1">
                                                                                                                            {event.title}
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </div>
                                                                                                <div class="fc-daygrid-day-bg" bis_skin_checked="1"></div>
                                                                                            </div>
                                                                                        </td>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.error && (
                    <p className="no-data">
                        {SVG.nodata()}
                        Invalid Date Range
                    </p>
                )}
            </Fragment>
        )
    }
}
