import { get, post } from '../request'

export const viewFine = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/fine', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addFine = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/fine/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editFine = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/fine/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFine = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/fine/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleFine = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/fine/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
