import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { reportPost, myCircle, getAllActiveSchoolUser, getSingleChat, getRecentChat, getGroupChat, getChatRoom, createGroup, editGroup, deleteGroup, leaveGroup, getChatMessage, sendMessage, editMessage, deleteMessage } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'
import { Connection } from '~/services/socket'
import { socketUpdate, checkOnline, updateChatRoom, filterRecent, filterGroup, filterUser, updateMessage } from './function'
import * as MessageType from '~/helper/constant/message_type'
import { withTranslation } from 'react-i18next'

const socket = new Connection()

type Props = {
    dispatch: Dispatch<any>,
    token: String,
    user: Object,
    school: Object,
    participants: String,
}

type State = {
    emoji: Boolean,
    total_page: Integer,
    current_page: Integer,
    sidebarActive: String,
    activeInfo: String,
    userList: Array,
    userFilterList: Array,
    memberList: Array,
    userFilter: Integer,
    recentList: Array,
    recentFilterList: Array,
    groupList: Array,
    groupFilterList: Array,
    chatRoom: Object,
    title: String,
    access: Array,
    profile: Object,
    messageList: Array,
    message: String,
    chat_message_id: String,
    chat_message: String,
    receivedMessage: String,
    myCircle: Array,
    uploads: Array,
    media: Array,
    sending: Boolean,
}

export class AbstractChatComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            sending: false,
            emoji: false,
            mediaNameError: null,
            total_page: 1,
            current_page: 1,
            sidebarActive: '',
            activeTab: '',
            recent_search: '',
            user_search: '',
            group_search: '',
            userList: [],
            userFilter: 0,
            userFilterList: [],
            recentList: [],
            recentFilterList: [],
            groupList: [],
            groupFilterList: [],
            title: '',
            message: '',
            access: [],
            profile: {},
            memberList: [],
            messageList: [],
            chat_message_id: '',
            chat_message: '',
            myCircle: [],
            chat_errors: {
                chat_message: null,
            },
            errors: {
                title: null,
                access: null,
                profile: null,
            },
            receivedMessage: '',
            uploads: [],
            media: [],
            reportedUser: null,
            report_remarks: null,
            report_type: null,
            selectedReportType: null,
            report_errors: {
                report_remarks: null,
                report_type: null,
            },
        }
        this._toggleSidebar = this._toggleSidebar.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this.setUserFilter = this.setUserFilter.bind(this)
        this.setRecentFilter = this.setRecentFilter.bind(this)
        this.setGroupFilter = this.setGroupFilter.bind(this)
        this._onSave = this._onSave.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this._delete = this._delete.bind(this)
        this._leave = this._leave.bind(this)
        this._sendMessage = this._sendMessage.bind(this)
        this._editMessage = this._editMessage.bind(this)
        this._deleteMessage = this._deleteMessage.bind(this)
        this.toggleEmoji = this.toggleEmoji.bind(this)
        this.setEmoji = this.setEmoji.bind(this)
        this._report = this._report.bind(this)
    }

    componentDidMount() {
        this.loadAllUser()
        socket.receive('receivedMessage', this)
    }

    _handleChange(key, value) {
        this.setState({
            [key]: value,
        })
    }

    setEmoji(data) {
        this.setState({
            message: this.state.message + data,
        })
    }

    toggleEmoji(e) {
        e.preventDefault()
        this.setState({
            emoji: this.state.emoji ? false : true,
        })
    }

    //SOCKET
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.participants != this.props.participants) {
            checkOnline(this)
        }
        if (prevState.receivedMessage != this.state.receivedMessage) {
            socketUpdate(this)
        }
    }

    //LOAD
    loadAllUser() {
        getAllActiveSchoolUser(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const userList = []
                const userFilterList = []
                const memberList = []
                let users = response.data.users
                if (this.props.school.user_type == UserType.Alumni) {
                    users = users.filter((item) => item.user_type != UserType.Student && item.user_type != UserType.Parents)
                }
                users.map((item, index) => {
                    userList.push(item)
                    if (item.id != this.props.school.id) {
                        const data = {
                            label: item.user.name != null ? item.user.name + ' - ' + item.user.reg_no : item.user.reg_no,
                            value: item.id,
                        }
                        memberList.push(data)
                        userFilterList.push(item)
                    }
                })
                this.setState(
                    {
                        userList,
                        userFilterList,
                        memberList,
                    },
                    () => {
                        checkOnline(this)
                        this.loadMyCircle()
                        this.loadRecentChat()
                        this.loadGroupChat()
                        if (this.props.match.params.id != null) {
                            this.loadChatRoom(this.props.match.params.id)
                        }
                    }
                )
            }
        })
    }

    loadMyCircle() {
        if (this.props.school != null && this.props.school.user_type != UserType.Admin) {
            myCircle(this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    const myCircle = []
                    response.data.users.map((item) => {
                        myCircle.push(item.id)
                    })
                    this.setState(
                        {
                            myCircle,
                        },
                        () => {
                            filterUser(this)
                        }
                    )
                }
            })
        }
    }

    loadRecentChat() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getRecentChat(20, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        recentList: response.data.chat,
                        recentFilterList: response.data.chat,
                    },
                    () => {
                        filterRecent(this)
                    }
                )
            }
        })
    }

    loadGroupChat() {
        getGroupChat(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        groupList: response.data.chat,
                        groupFilterList: response.data.chat,
                    },
                    () => {
                        filterGroup(this)
                    }
                )
            }
        })
    }

    loadChatRoom(id) {
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            component._handleChange('loading_text', 'Loading')
            getChatRoom(id, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    const chatRoom = response.data.chat
                    const chatMessage = response.data.chatMessage
                    component.setState(
                        {
                            chatRoom,
                        },
                        () => {
                            updateChatRoom(component, chatRoom, chatMessage)
                            resolve(true)
                        }
                    )
                } else {
                    resolve(false)
                }
            })
        })
    }

    loadUserChatRoom(id) {
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            component._handleChange('loading_text', 'Loading')
            getSingleChat(id, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    const chatRoom = response.data.chat
                    const chatMessage = response.data.chatMessage
                    const data = {
                        room: component.props.school.school.short_code,
                        data: chatRoom,
                        type: MessageType.CREATE_CHAT_ROOM,
                    }
                    socket.send(data)
                    component.setState(
                        {
                            chatRoom,
                        },
                        () => {
                            component.loadChatRoom(chatRoom.id)
                            resolve(true)
                        }
                    )
                } else {
                    resolve(false)
                }
            })
        })
    }

    loadPreviousMesage() {
        let current_page = this.state.current_page
        const total_page = this.state.total_page
        if (current_page + 1 <= total_page) {
            this.loadChatMessage(parseInt(current_page) + 1)
        }
    }

    loadChatMessage(page) {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getChatMessage(this.state.chatRoom.id, page, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const messageList = this.state.messageList
                response.data.chat.data.map((item) => {
                    messageList.unshift(item)
                })
                this.setState(
                    {
                        current_page: response.data.chat.current_page,
                        total_page: response.data.chat.last_page,
                        messageList,
                    },
                    () => {
                        // redirect(route.chat + '/' + this.state.chatRoom.id, this.props.history)
                    }
                )
            }
        })
    }

    //UPDATE AND FILTER
    setUserFilter(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                filterUser(this)
            }
        )
    }

    setRecentFilter(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                filterRecent(this)
            }
        )
    }

    setGroupFilter(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                filterGroup(this)
            }
        )
    }

    _toggleSidebar(activeTab) {
        let sidebarActive = 'active'
        let newTab = true
        if (this.state.sidebarActive == 'active') {
            sidebarActive = ''
        }
        if (activeTab == this.state.activeTab) {
            newTab = false
        }
        this.setState(
            {
                sidebarActive,
                activeTab,
            },
            () => {
                if (newTab && sidebarActive == '') {
                    setTimeout(() => {
                        this._handleChange('sidebarActive', 'active')
                    }, 250)
                }
            }
        )
    }

    _report() {
        const component = this
        return new Promise(function (resolve, reject) {
            const report_errors = validation.required(Object.keys(component.state.report_errors), component.state)
            component.setState({ report_errors })
            const validate = Object.keys(report_errors).filter((item) => report_errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    reported_user_school_id: component.state.reportedUser,
                    type: component.state.report_type,
                    remarks: component.state.report_remarks,
                }
                reportPost(data, component.props.token).then((response) => {
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                    } else {
                        toastMessage('error', response.message)
                    }
                    resolve(true)
                })
            } else {
                resolve(false)
            }
        })
    }

    //SEND, EDIT AND DELETE MESSAGE
    _sendMessage(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            if (component.state.message.trim().length == 0) {
                component.setState({
                    mediaNameError: t('toaster:required'),
                })
                return resolve(false)
            }
            if (component.state.message != '' && component.state.chatRoom != null) {
                const data = {
                    chat_id: component.state.chatRoom.id,
                    message: component.state.message,
                    media: component.state.media,
                }
                component._handleChange('sending', true)
                sendMessage(data, component.props.token).then((response) => {
                    component._handleChange('sending', false)
                    if (response.code == API_SUCCESS) {
                        const message = response.data.message
                        const data = {
                            room: component.state.chatRoom.room_name,
                            data: message,
                            type: MessageType.SEND_MESSAGE,
                        }
                        socket.send(data)
                        const messageList = component.state.messageList
                        messageList.push(message)
                        component.setState(
                            {
                                messageList,
                                mediaNameError: null,
                            },
                            () => {
                                updateMessage(component, message)
                                resolve(true)
                            }
                        )
                    }
                })
            } else {
                toastMessage('error', t('toaster:addMsgBeforeSending'))
                resolve(false)
            }
        })
    }

    _editMessage(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            if (component.state.chat_message != '' && component.state.chat_message_id != '') {
                component.setState({
                    chat_errors: {
                        chat_message: null,
                    },
                })
                const data = {
                    message: component.state.chat_message,
                }
                editMessage(component.state.chat_message_id, data, component.props.token).then((response) => {
                    if (response.code == API_SUCCESS) {
                        const message = response.data.message
                        const data = {
                            room: component.state.chatRoom.room_name,
                            data: message,
                            type: MessageType.EDIT_MESSAGE,
                        }
                        socket.send(data)
                        const messageList = component.state.messageList
                        messageList.map((item) => {
                            if (item.id == message.id) {
                                item.message = message.message
                            }
                        })
                        const latest_message = messageList[messageList.length - 1]

                        component.setState(
                            {
                                messageList,
                            },
                            () => {
                                updateMessage(component, latest_message)
                            }
                        )
                    }
                    resolve(true)
                })
            } else {
                component.setState({
                    chat_errors: {
                        chat_message: t('toaster:required'),
                    },
                })
                resolve(false)
            }
        })
    }

    _deleteMessage(id) {
        deleteMessage(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const deletedMessage = this.state.messageList.find((item) => item.id == id)
                const messageList = this.state.messageList.filter((item) => item.id != id)
                const message = messageList[messageList.length - 1]
                const data = {
                    room: this.state.chatRoom.room_name,
                    data: {
                        deleted: deletedMessage,
                        latest: message,
                    },
                    type: MessageType.DELETE_MESSAGE,
                }
                socket.send(data)
                this.setState(
                    {
                        messageList,
                    },
                    () => {
                        updateMessage(this, message)
                    }
                )
            }
        })
    }

    //CREATE , EDIT AND DELETE GROUP

    _onSave() {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            if (Object.keys(component.state.profile).length === 0) {
                errors.profile = t('toaster:required')
            }
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                let access = []
                if (typeof document != 'undefined') {
                    component.state.access.map((item) => {
                        access.push(item.value)
                    })
                } else {
                    access = component.state.access
                }
                const data = {
                    name: component.state.title,
                    profile: component.state.profile,
                    access,
                }
                component._handleChange('loading', true)
                component._handleChange('loading_text', 'Saving')
                createGroup(data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        const groupList = component.state.groupList
                        groupList.push(response.data.chat)
                        const data = {
                            room: component.props.school.school.short_code,
                            data: response.data.chat,
                            type: MessageType.CREATE_GROUP_CHAT_ROOM,
                        }
                        socket.send(data)
                        component.setState(
                            {
                                groupList,
                            },
                            () => {
                                updateChatRoom(component, response.data.chat, null)
                            }
                        )
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }

    _onEdit() {
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            if (Object.keys(component.state.profile).length === 0) {
                errors.profile = t('toaster:required')
            }
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                let access = []
                if (typeof document != 'undefined') {
                    component.state.access.map((item) => {
                        access.push(item.value)
                    })
                } else {
                    access = component.state.access
                }
                const data = {
                    name: component.state.title,
                    profile: component.state.profile,
                    access,
                }

                component._handleChange('loading', true)
                component._handleChange('loading_text', 'Saving')
                editGroup(component.state.chatRoom.id, data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        const recentList = component.state.recentList
                        const groupList = component.state.groupList
                        const chatRoom = response.data.chat
                        recentList.map((item) => {
                            if (item.id == chatRoom.id) {
                                item.name = chatRoom.name
                                item.media = chatRoom.media
                                item.media_id = chatRoom.media_id
                                item.access = chatRoom.access
                            }
                        })
                        groupList.map((item) => {
                            if (item.id == chatRoom.id) {
                                item.name = chatRoom.name
                                item.media = chatRoom.media
                                item.media_id = chatRoom.media_id
                                item.access = chatRoom.access
                            }
                        })
                        component.setState(
                            {
                                chatRoom: response.data.chat,
                                groupList,
                                recentList,
                            },
                            () => {
                                filterRecent(component)
                                filterGroup(component)
                            }
                        )
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)

                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }

    _delete() {
        const component = this
        return new Promise(function (resolve, reject) {
            if (component.state.chatRoom != null) {
                component._handleChange('loading', true)
                component._handleChange('loading_text', 'Deleting')
                deleteGroup(component.state.chatRoom.id, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const data = {
                            room: component.state.chatRoom.room_name,
                            data: component.state.chatRoom,
                            type: MessageType.DELETE_GROUP_CHAT_ROOM,
                        }
                        socket.send(data)
                        const recentList = component.state.recentList.filter((item) => item.id != component.state.chatRoom.id)
                        const groupList = component.state.groupList.filter((item) => item.id != component.state.chatRoom.id)
                        component.setState(
                            {
                                recentList,
                                groupList,
                                chatRoom: null,
                            },
                            () => {
                                filterRecent(component)
                                filterGroup(component)
                            }
                        )
                        resolve(true)
                        toastMessage('success', response.message)
                    } else {
                        toastMessage('error', response.message)
                        resolve(false)
                    }
                })
            } else {
                resolve(false)
            }
        })
    }

    _leave() {
        const component = this
        return new Promise(function (resolve, reject) {
            if (component.state.chatRoom != null) {
                component._handleChange('loading', true)
                component._handleChange('loading_text', 'Leaving')
                leaveGroup(component.state.chatRoom.id, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const recentList = component.state.recentList.filter((item) => item.id != component.state.chatRoom.id)
                        const groupList = component.state.groupList.filter((item) => item.id != component.state.chatRoom.id)
                        component.setState(
                            {
                                recentList,
                                groupList,
                                chatRoom: null,
                            },
                            () => {
                                filterRecent(component)
                                filterGroup(component)
                            }
                        )
                        toastMessage('success', response.message)
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        resolve(false)
                    }
                })
            } else {
                resolve(false)
            }
        })
    }
}

const AbstractChat = withTranslation()(AbstractChatComponent)

export { AbstractChat }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        user: state.user,
        school: state.school,
        participants: state.participants,
    }
}
