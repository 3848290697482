import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import * as route from '~/helper/constant/route'
import { Header } from '../../../../../components/header/header'
import { Loading } from '../../../../../components/loader/loader'
import { Sidebar } from '../../../../../components/sidebar/sidebar'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractTaxSummaryComponent, mapStateToProps } from './abstract'
class TaxSummaryComponent extends AbstractTaxSummaryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setYear = this._setYear.bind(this)
    }
    _setYear(data) {
        this.setState(
            {
                year: data,
            },
            () => {
                this._onSearch()
            }
        )
    }

    getIncome() {
        const { t } = this.props
        if (this.state.incomeList != null) {
            if (Object.keys(this.state.incomeList).length > 0) {
                const data = Object.keys(this.state.incomeList).map((tax) => {
                    const data = this.state.incomeList[tax]
                    return (
                        <tr>
                            <td>{tax}</td>
                            {Object.keys(data).map((item) => {
                                return <td>{parseFloat(data[item]).toFixed(2)}</td>
                            })}
                        </tr>
                    )
                })
                return data
            }
        }

        return (
            <tr>
                <td colSpan={13}>
                    <p className="no-data">
                        {SVG.nodata()}
                        {t('common:table.nodata')}
                    </p>
                </td>
            </tr>
        )
    }

    getExpense() {
        const { t } = this.props

        if (this.state.expenseList != null) {
            if (Object.keys(this.state.expenseList).length > 0) {
                const data = Object.keys(this.state.expenseList).map((tax) => {
                    const data = this.state.incomeList[tax]
                    return (
                        <tr>
                            <td>{tax}</td>
                            {Object.keys(data).map((item) => {
                                return <td>{parseFloat(data[item]).toFixed(2)}</td>
                            })}
                        </tr>
                    )
                })
                return data
            }
        }
        return (
            <tr>
                <td colSpan={13}>
                    <p className="no-data">
                        {SVG.nodata()}
                        {t('common:table.nodata')}
                    </p>
                </td>
            </tr>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="report_accounting" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.reports.menu}>
                                    {t('reports:reports')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('reports:taxsummary')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('reports:taxsummary')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={this.props.school.short_code + '-' + t('reports:taxsummary')}
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="timetable-form mb-5">
                                    <div className="row clearfix ">
                                        <div className="col-md-9"></div>
                                        <div className="col-md-3 header-filter">
                                            <label className="table-form-title">{t('reports:selectyear')} :</label>
                                            <DatePicker
                                                placeholderText={t('reports:selectyear')}
                                                dateFormat="yyyy"
                                                className="form-control"
                                                defaultValue={this.state.year}
                                                selected={this.state.year}
                                                onChange={this._setYear}
                                                showYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.year != ''
                                                        ? convertADtoBS(this.state.year) != null
                                                            ? convertADtoBS(this.state.year).slice(0, -6) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.year && (
                                                <label className="error text-danger">{this.state.errors.year}</label>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('reports:taxsummary')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_dark">
                                                            <td colSpan={13}>
                                                                <strong>{t('income:income')}</strong>
                                                                &nbsp; [ &nbsp;
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp; ]
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_light">
                                                            <td>{t('income:tax')}</td>
                                                            {this.state.monthList.map((item) => {
                                                                return <td>{item}</td>
                                                            })}
                                                        </tr>
                                                        {this.getIncome()}
                                                        <tr className="invoice_total_border">
                                                            <td colSpan={13}></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={13}></td>
                                                        </tr>
                                                        <tr className="invoice_table_header_dark">
                                                            <td colSpan={13}>
                                                                <strong>{t('accounting:expense')}</strong>
                                                                &nbsp; [ &nbsp;
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp; ]
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_light">
                                                            <td>{t('income:tax')}</td>
                                                            {this.state.monthList.map((item) => {
                                                                return <td>{item}</td>
                                                            })}
                                                        </tr>
                                                        {this.getExpense()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const TaxSummary = withTranslation()(connect(mapStateToProps)(TaxSummaryComponent))

export { TaxSummary }
