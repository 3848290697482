const PENDING = 0
const PROCESSING = 1
const COMPLETE = 2
const CANCELLED = 3
const FAILED = 4
const PAYMENT_STATUS_OBJECT = [
    { label: 'Pending', value: 0, translate: 'fee:pending' },
    { label: 'Processing', value: 1, translate: 'fee:processing' },
    { label: 'Complete', value: 2, translate: 'fee:complete' },
    { label: 'Cancelled', value: 3, translate: 'fee:cancelled' },
    { label: 'Failed', value: 4, translate: 'fee:failed' },
]
export { PENDING, PROCESSING, COMPLETE, CANCELLED, FAILED, PAYMENT_STATUS_OBJECT }
