import * as MessageType from '~/helper/constant/message_type'
import * as UserType from '~/helper/constant/user_type'

export function socketUpdate(component) {
    const response = component.state.receivedMessage
    if (response.type == MessageType.CREATE_GROUP_CHAT_ROOM) {
        const chatRoom = response.data
        if (chatRoom.author_id != component.props.school.id) {
            chatRoom.message_count = 0
            const groupList = component.state.groupList
            groupList.push(chatRoom)
            component.setState(
                {
                    groupList,
                },
                () => {
                    filterGroup(component)
                }
            )
        }
    } else if (response.type == MessageType.DELETE_GROUP_CHAT_ROOM) {
        const chatRoom = response.data
        if (chatRoom.author_id != component.props.school.id) {
            const groupList = component.state.groupList.filter((item) => item.id != chatRoom.id)
            const recentList = component.state.recentList.filter((item) => item.id != chatRoom.id)
            component.setState(
                {
                    groupList,
                    recentList,
                },
                () => {
                    filterGroup(component)
                    filterRecent(component)
                }
            )
            if (component.state.chatRoom != null && component.state.chatRoom.id == chatRoom.id) {
                component.setState({
                    chatRoom: null,
                })
            }
        }
    } else if (response.type == MessageType.DELETE_MESSAGE) {
        const message = response.data
        if (message.sender_id != component.props.school.id) {
            if (component.state.chatRoom != null && component.state.chatRoom.id == message.deleted.chat_id) {
                const messageList = component.state.messageList.filter((item) => item.id != message.deleted.id)
                const groupList = component.state.groupList
                groupList.map((item) => {
                    if (item.id == message.deleted.chat_id) {
                        item.latest_message = messageList[messageList.length - 1]
                    }
                })
                const recentList = component.state.recentList
                recentList.map((item) => {
                    if (item.id == message.deleted.chat_id) {
                        item.latest_message = messageList[messageList.length - 1]
                    }
                })
                component.setState(
                    {
                        messageList,
                        groupList,
                        recentList,
                    },
                    () => {
                        filterGroup(component)
                        filterRecent(component)
                    }
                )
            } else {
                const groupList = component.state.groupList
                groupList.map((item) => {
                    if (item.id == message.deleted.chat_id) {
                        item.latest_message = message.latest
                    }
                })
                const recentList = component.state.recentList
                recentList.map((item) => {
                    if (item.id == message.deleted.chat_id) {
                        item.latest_message = message.latest
                    }
                })
                component.setState(
                    {
                        groupList,
                        recentList,
                    },
                    () => {
                        filterGroup(component)
                        filterRecent(component)
                    }
                )
            }
        }
    } else if (response.type == MessageType.EDIT_MESSAGE) {
        const message = response.data
        if (message.sender_id != component.props.school.id) {
            if (component.state.chatRoom != null && component.state.chatRoom.id == message.chat_id) {
                const messageList = component.state.messageList
                messageList.map((item) => {
                    if (item.id == message.id) {
                        item.message = message.message
                    }
                })
                component.setState({
                    messageList,
                })
            }
            const groupList = component.state.groupList
            groupList.map((item) => {
                if (item.id == message.chat_id) {
                    item.latest_message = message
                }
            })
            const recentList = component.state.recentList
            recentList.map((item) => {
                if (item.id == message.chat_id) {
                    item.latest_message = message
                }
            })
            component.setState(
                {
                    recentList,
                    groupList,
                },
                () => {
                    filterRecent(component)
                    filterGroup(component)
                }
            )
        }
    } else if (response.type == MessageType.SEND_MESSAGE) {
        const message = response.data
        if (message.sender_id != component.props.school.id) {
            if (component.state.chatRoom != null && component.state.chatRoom.id == message.chat_id) {
                const messageList = component.state.messageList
                messageList.push(message)
                component.setState({
                    messageList,
                })
            }
            const group = component.state.groupList.find((item) => item.id == message.chat_id)
            if (group != null) {
                const groupList = component.state.groupList
                groupList.map((item) => {
                    if (item.id == message.chat_id) {
                        item.latest_message = message
                        item.message_count = parseInt(item.message_count) + 1
                    }
                })
                component.setState(
                    {
                        groupList,
                    },
                    () => filterGroup(component)
                )
            } else {
                component.loadGroupChat()
            }

            let recentList = component.state.recentList
            const recent = component.state.recentList.find((item) => item.id == message.chat_id)
            if (recent != null) {
                recentList = component.state.recentList.filter((item) => item.id != message.chat_id)
                recent.latest_message = message
                recent.message_count = parseInt(recent.message_count) + 1
                recentList.unshift(recent)
                component.setState(
                    {
                        recentList,
                    },
                    () => {
                        filterRecent(component)
                    }
                )
            } else {
                component.loadRecentChat()
            }
        }
    }
}

export function checkOnline(component) {
    if (component.state.userList != null) {
        if (component.props.participants != null) {
            const participants = JSON.parse(component.props.participants)
            const userList = component.state.userList
            userList.map((item) => {
                if (participants.includes(item.id)) {
                    item.isOnline = true
                } else {
                    item.isOnline = false
                }
            })
            component.setState({ userList })
        }
    }
}

export function updateMessage(component, message) {
    const chatRoom = component.state.chatRoom
    const groupList = component.state.groupList
    groupList.map((item) => {
        if (item.id == chatRoom.id) {
            item.latest_message = message
        }
    })
    const recentList = component.state.recentList.filter((item) => item.id != chatRoom.id)
    const checkRecent = component.state.recentList.find((item) => item.id == chatRoom.id)
    if (checkRecent != null) {
        checkRecent.latest_message = message
        recentList.unshift(checkRecent)
    }
    component.setState(
        {
            message: '',
            groupList,
            recentList,
        },
        () => {
            filterRecent(component)
            filterGroup(component)
        }
    )
}

export function updateChatRoom(component, chatRoom, chatMessage) {
    chatRoom.message_count = 0
    let recentList = component.state.recentList
    let groupList = component.state.groupList
    let recent = component.state.recentList.find((item) => item.id == chatRoom.id)
    if (recent != null) {
        recentList.map((item) => {
            if (item.id == chatRoom.id) {
                item.message_count = 0
            }
        })
    } else {
        recentList.unshift(chatRoom)
    }
    if (chatRoom.isGroup) {
        let group = component.state.groupList.find((item) => item.id == chatRoom.id)
        if (group != null) {
            groupList.map((item) => {
                if (item.id == chatRoom.id) {
                    item.message_count = 0
                }
            })
        } else {
            groupList.unshift(chatRoom)
        }
    }
    const messageList = []
    if (chatMessage != null) {
        chatMessage.data.map((item) => {
            messageList.unshift(item)
        })
    }
    component.setState(
        {
            recentList,
            groupList,
            message: '',
            messageList,
            current_page: chatMessage == null ? 1 : chatMessage.current_page,
            total_page: chatMessage == null ? 1 : chatMessage.last_page,
        },
        () => {
            //component.loadChatMessage(1)
            filterRecent(component)
            filterGroup(component)
        }
    )
}

export function filterRecent(component) {
    const recentList = component.state.recentList
    let user_search = component.state.recent_search
    const recentFilterList = recentList.filter((item, index) => {
        let name = item.name
        let reg_no = ''
        if (!item.isGroup) {
            let user = component.state.userList.find((user) => user.id != component.props.school.id && (user.id == item.author_id || item.access.includes(user.id)))
            if (user == null) {
                return false
            } else {
                name = user.user.name != null ? user.user.name.toLowerCase() : ''
                reg_no = user.user.reg_no.toLowerCase()
            }
        }
        name = name != '' ? name.toLowerCase() : ''
        reg_no = reg_no.toLowerCase()
        if (user_search != '') {
            if (!Number.isInteger(user_search)) {
                user_search = user_search.toLowerCase()
            }
        }
        return user_search != '' ? (name.includes(user_search) || reg_no.includes(user_search) ? true : false) : true
    })
    component.setState({
        recentFilterList,
    })
}

export function filterGroup(component) {
    const groupList = component.state.groupList
    let user_search = component.state.group_search
    const groupFilterList = groupList.filter((item, index) => {
        let name = item.name.toLowerCase()
        if (user_search != '') {
            if (!Number.isInteger(user_search)) {
                user_search = user_search.toLowerCase()
            }
        }
        return user_search != '' ? (name.includes(user_search) ? true : false) : true
    })
    component.setState({
        groupFilterList,
    })
}

export function filterUser(component) {
    const userList = component.state.userList
    let user_search = component.state.user_search
    const userFilterList = userList.filter((item, index) => {
        if (component.props.school.id != item.id) {
            const name = item.user.name != null ? item.user.name.toLowerCase() : ''
            const reg_no = item.user.reg_no.toLowerCase()
            let result = false
            if (user_search != '') {
                if (!Number.isInteger(user_search)) {
                    user_search = user_search.toLowerCase()
                }
            }
            if (component.props.school.user_type == UserType.Admin) {
                result = true
            } else if (component.props.school.user_type == UserType.Teacher) {
                if (component.state.myCircle.includes(item.id) || (item.user_type != UserType.Student && item.user_type != UserType.Parents)) {
                    result = true
                }
            } else if (component.props.school.user_type == UserType.Student || component.props.school.user_type == UserType.Parents) {
                if (component.state.myCircle.includes(item.id)) {
                    result = true
                }
            } else {
                result = true
            }
            if (result) {
                if (component.state.userFilter == 0) {
                    return user_search != '' ? (name.includes(user_search) || reg_no.includes(user_search) ? true : false) : true
                } else if (component.state.userFilter == 1) {
                    return item.user_type == UserType.Student || item.user_type == UserType.Parents ? (user_search != '' ? (name.includes(user_search) || reg_no.includes(user_search) ? true : false) : true) : false
                } else if (component.state.userFilter == 2) {
                    return item.user_type != UserType.Alumni && item.user_type != UserType.Student && item.user_type != UserType.Parents ? (user_search != '' ? (name.includes(user_search) || reg_no.includes(user_search) ? true : false) : true) : false
                } else {
                    return item.user_type == UserType.Alumni ? (user_search != '' ? (name.includes(user_search) || reg_no.includes(user_search) ? true : false) : true) : false
                }
            } else {
                return result
            }
        }
    })
    component.setState({
        userFilterList,
    })
}
