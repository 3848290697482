import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewApplicant } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import moment from 'moment'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAlumniJobApplicantsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            start_date: moment(moment().format('yyyy-MM-DD')).subtract(2, 'M').toDate(),
            end_date: moment(moment().format('yyyy-MM-DD')).toDate(),
            applicant: [
                {
                    applied_date: {
                        field: 'text',
                        translate: 'alumni:appliedDate',
                        route: false,
                        sortable: true,
                    },
                    alumni: {
                        field: 'text',
                        translate: 'alumni:alumni',
                        route: true,
                        sortable: true,
                    },
                    job_title: {
                        field: 'text',
                        translate: 'title',
                        route: false,
                        sortable: true,
                    },
                    company: {
                        field: 'text',
                        translate: 'alumni:company',
                        route: false,
                        sortable: true,
                    },
                    designation: {
                        field: 'text',
                        translate: 'alumni:designation',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        translate: 'alumni:CV',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.validateFilter = this.validateFilter.bind(this)
    }

    componentDidMount() {
        this.loadApplicant()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    validateFilter() {
        this._handleChange('filter_error', null)
        const from = moment(this.state.start_date, 'yyyy-MM-DD')
        const to = moment(this.state.end_date, 'yyyy-MM-DD')
        if (from.isAfter(to)) {
            this._handleChange('filter_error', 'Invalid Range')
            return false
        }
        return true
    }

    loadApplicant() {
        this._handleChange('loading', true)
        const component = this
        return new Promise(function (resolve, reject) {
            const check = component.validateFilter()
            if (!check) {
                resolve(false)
            }
            const from = moment(component.state.start_date).format('yyyy-MM-DD')
            const to = moment(component.state.end_date).format('yyyy-MM-DD')
            viewApplicant(from, to, component.props.token).then((response) => {
                component._handleChange('loading', false)
                let applicant = []
                applicant.push(component.state.applicant[0])
                let dataList = []
                if (response.code == API_SUCCESS) {
                    response.data.job_applicant.map((item, index) => {
                        const data = item
                        const tableData = {
                            applied_date: {
                                text: moment(item.created_at).format('yyyy-MM-DD'),
                            },
                            alumni: {
                                text: item.user_school.user.name != null ? item.user_school.user.name : item.user_school.user.reg_no,
                            },
                            job_title: {
                                text: item.post.title,
                            },
                            company: {
                                text: item.post.job_vacancy.alumni_company.name,
                            },
                            designation: {
                                text: item.post.job_vacancy.alumni_designation.name,
                            },
                            action: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                route: route.user_profile + '/' + item.user_school.user.id,
                            },
                        }
                        applicant.push(tableData)
                    })
                    resolve(true)
                } else {
                    resolve(false)
                }
                component.setState({
                    applicant,
                })
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
