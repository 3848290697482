import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { getJobVacancy, deletePost, applyJob } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAlumniJobDetailComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loaded: false,
            loading_text: 'Loading...',
            job: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.delete = this.delete.bind(this)
        this.apply = this.apply.bind(this)
    }

    componentDidMount() {
        this.loadJob()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    apply(e) {
        e.preventDefault()
        if (this.state.job != null) {
            this._handleChange('loading_text', 'Deleting...')
            this._handleChange('loading', true)
            applyJob(this.state.job.post.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }

    delete(e) {
        const { t } = this.props
        e.preventDefault()
        if (this.state.job != null) {
            this._handleChange('loading_text', 'Deleting...')
            this._handleChange('loading', true)
            deletePost(this.state.job.post.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', t('toaster:jobDeleteMsg'))
                    this.props.history.goBack()
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }

    loadJob() {
        this._handleChange('loading_text', 'Loading...')
        this._handleChange('loading', true)
        getJobVacancy(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    job: response.data.job,
                    loaded: true,
                })
            }
        })
    }
}

const AbstractAlumniJobDetail = withTranslation()(connect(mapStateToProps)(AbstractAlumniJobDetailComponent))

export { AbstractAlumniJobDetail }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
