import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractGamificationEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import MultiImageInput from 'react-multiple-image-input'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import { medalRules } from '~/helper/constant/medal_rule'

class GamificationEditComponent extends AbstractGamificationEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setIcon = this._setIcon.bind(this)
    }

    _setIcon(data) {
        this._handleChange('icon', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.setting.gamification.view}>{t('gamification:gamification')} > </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('gamification:editGamification')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('common:name')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">XP *</label>
                                    <Input name="points" type="number" min="0" onChange={this._handleChange} placeholder="XP" value={this.state.points} />
                                    {this.state.errors.points && <label className="error text-danger">{this.state.errors.points}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value={this.state.description} />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group profile-container">
                                    <label className="form-title">{t('gamification:icon')} *</label>
                                    <MultiImageInput images={this.state.icon} max={1} setImages={this._setIcon} allowCrop={false} theme="light" />
                                    {this.state.errors.icon && <label className="error text-danger">{this.state.errors.icon}</label>}
                                </div>
                            </div>
                            <div className="main-head-option">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left">
                                        <h5>{t('gamification:automationRules')}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                {medalRules.map((item, index) => {
                                    if (this.props.schoolPackage.includes(item.value)) {
                                        return (
                                            <div key={index} className="col-md-4 col-sm-6 float-left access-status">
                                                <div className="security-list">
                                                    <label className="form-title">{t(item.translate)}</label>
                                                    <div className="tog-button">
                                                        <div className="button-check r" id="button-2">
                                                            <Checkbox className="checkbox-form checkbox" onChange={() => this.toggleRules(item.value)} name={'rules_' + item.value} value={this.state.rules.includes(item.value)} />
                                                            <div className="knobs"></div>
                                                            <div className="layer"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const GamificationEdit = withTranslation()(connect(mapStateToProps)(GamificationEditComponent))

export { GamificationEdit }
