import $ from 'jquery'
import { MODE } from '~/helper/constant/config'

export function script() {
    $(document).click(function (e) {
        if (!$(e.target).is('.collapse-body') && !$(e.target).parents().is('.collapse-body')) {
            $('.collapse').collapse('hide')
        }
    })
    $(document).bind('contextmenu', function (e) {
        if (MODE != 'LOCAL') {
            return false
        }
    })
}
