import $ from 'jquery'
import React from 'react';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { AbstractCourseContentStudentComponent, mapStateToProps } from './abstract'
class CourseContentStudentComponent extends AbstractCourseContentStudentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.showContent = this.showContent.bind(this)
        this.feedbackAddModal = this.feedbackAddModal.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    addFeedback(current_progress, current_course) {
        super.addFeedback(current_progress, current_course).then((response) => {
            if (response) {
                $('#modal_feedback_add').modal('show')
            }
        })
    }

    editProgress(current_progress, validate) {
        super.editProgress(current_progress, validate).then((response) => {
            if (response) {
                if (this.state.current_progress) {
                    this.clearState()
                }
                $('#modal_feedback_add').modal('hide')
            }
        })
    }

    feedbackAddModal() {
        const { t } = this.props
        return (
            <div id="modal_feedback_add" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('student_panel:content.addFeedback')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('student_panel:content.feedback')} *</label>
                                        <TextArea
                                            name="feedback"
                                            onChange={this._handleChange}
                                            placeholder={t('student_panel:content.feedback')}
                                            type="text"
                                            value={this.state.feedback}
                                        />
                                        {this.state.errorsfeedback && (
                                            <label className="error text-danger">{this.state.errorsfeedback}</label>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button
                                        onClick={() => this.editProgress(this.state.current_progress, 1)}
                                        type="button"
                                        text={t('common:savebtn')}
                                        main={true}
                                    />
                                    <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    showContent() {
        const { t } = this.props
        let data = this.state.courseContent.map((item, index) => {
            if (this.state.course_progress) {
                const course_progress = this.state.course_progress.filter((obj) => {
                    if (this.props.school.user_type == UserType.Parents) {
                        return item.id === obj.course_content_id && obj.user_school_id == this.props.student
                    } else {
                        return (
                            item.id === obj.course_content_id &&
                            obj.user_school_id == this.props.school.active_user_academics.user_school_id
                        )
                    }
                })

                return (
                    <div className="clearfix course-video" key={index}>
                        <div className="media-elements video-feedbck clearfix">
                            <div className="video-content">
                                <div className="col-3 module-img float-left">
                                    {(item.thumbnail && item.thumbnail.media_url && (
                                        <img src={item.thumbnail.media_url} alt="image" />
                                    )) ||
                                        (item.media && item.media.media_type.includes('image') && (
                                            <img src={item.media.media_url} alt="image" />
                                        )) ||
                                        (item.media.media_type.includes('audio') && (
                                            <img src="/assets/images/sound.png" alt="notification" />
                                        )) || <img src="/assets/images/video.png" alt="notification" />}
                                </div>
                                <div className="col-9 module-detail smw-100 float-left clearfix">
                                    <p>{item.name}</p>
                                    <span>{item.description}</span>
                                    <br />
                                    {course_progress.length != 0 && (
                                        <a
                                            href={
                                                route.course.student.details +
                                                '/' +
                                                this.props.match.params.course_id +
                                                '/' +
                                                this.props.match.params.module_id +
                                                '/' +
                                                item.id
                                            }
                                            className="resume-but"
                                        >
                                            <Button type="submit" text={t('common:viewBtn')} main={true} />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row course-feedback">
                            {course_progress.length == 0 && (
                                <button
                                    className="message second-button pointer"
                                    onClick={() => this.addProgress(item.media.media_url, item.id)}
                                >
                                    {t('student_panel:content.startCourse')}
                                </button>
                            )}
                            {/*course_progress.length > 0 && course_progress[0].user_progress < 2 && (
                                <button className="fc-dayGridMonth-button fc-button fc-button-primary fc-button-active pointer" onClick={() => this.editProgress(course_progress[0].id, '')}>
                                    {t('student_panel:content.setAsComplete')}
                                </button>
                            )*/}
                            {course_progress.length > 0 && course_progress[0].user_progress == 2 && (
                                <span className="color-green flex-1">{t('course:completed')}</span>
                            )}
                            {course_progress.length > 0 && course_progress[0].user_progress == 2 && (
                                <button
                                    className="message pointer"
                                    onClick={() => this.addFeedback(course_progress[0], item.id)}
                                >
                                    {t('student_panel:content.sendFeedback')}
                                </button>
                            )}
                        </div>
                    </div>
                )
            }
        })
        if (data == '') {
            return (
                <div className="float-left course-your">
                    <p>{t('student_panel:content.noModuleContentFound')}</p>
                </div>
            )
        }
        return data
    }
    render() {
        const { t } = this.props

        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.student.view}>
                                    {t('student_panel:content.courseBreadcrumb')} &gt;{' '}
                                </a>
                            </li>
                            <li>
                                <a
                                    href={
                                        route.course.student.module +
                                        '/' +
                                        this.state.courseModule.course_id +
                                        '/' +
                                        this.state.post_id
                                    }
                                >
                                    {' '}
                                    {this.state.courseModule != null && this.state.courseModule.course != null
                                        ? this.state.courseModule.course.post.title
                                        : 'Course Title'}{' '}
                                    &gt;{' '}
                                </a>
                            </li>
                            <li>{this.state.courseModule.name}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-4 float-left">
                                <h5 className="main-title">
                                    {this.state.courseModule.name}
                                    {/* <ins className="new-con">New</ins> */}
                                </h5>
                            </div>
                            <div className="col-md-8 calendar-module float-left text-right">
                                {/* 
               <div className="display-inline-block">
                  <a href="#" className="second-button">
                  Bulk Upload
                  </a>
               </div>
               */}
                            </div>
                        </div>
                    </div>
                    {this.showContent()}
                    {this.feedbackAddModal()}
                    {/* 
      <div className="clearfix text-right form-button">
         <button className="second-button">Save</button>
         <button className="main-button">Preview</button> 
      </div>
      */}
                </div>
            </div>
        )
    }

    clearState() {
        let component = this
        const id = $(this).attr('id')
        component._handleChange('feedback', '')
        component._handleChange('current_progress', null)
        component._handleChange('current_course', null)
        const errors = {
            feefback: null,
        }
        component._handleChange('errors', errors)
        component._handleChange('errorsfeedback', null)
    }
}
const CourseContentStudent = withTranslation()(connect(mapStateToProps)(CourseContentStudentComponent))
export { CourseContentStudent }
