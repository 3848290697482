import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import * as route from '~/helper/constant/route'
import { Header } from '../../../../../components/header/header'
import { Loading } from '../../../../../components/loader/loader'
import { Sidebar } from '../../../../../components/sidebar/sidebar'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractTransactionComponent, mapStateToProps } from './abstract'
class TransactionComponent extends AbstractTransactionComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setStartDate = this._setStartDate.bind(this)
        this._setEndDate = this._setEndDate.bind(this)
        this._setAccount = this._setAccount.bind(this)
        this._setCategory = this._setCategory.bind(this)
    }

    _setStartDate(data) {
        this._handleChange('start_date', data)
    }
    _setEndDate(data) {
        this._handleChange('end_date', data)
    }
    _setAccount(data) {
        this._handleChange('account', data)
    }
    _setCategory(data) {
        this._handleChange('category', data)
    }

    getTransactions() {
        const data = this.state.transactions.map((item, index) => {
            const account = this.state.accountList.find((el) => el.value == item.account)
            return (
                <tr>
                    <td>{this.props.school.date_format == 'BS' ? convertADtoBS(item.date) : item.date}</td>
                    <td>{account != null ? account.label : '-'}</td>
                    <td>{item.category}</td>
                    <td>{item.description}</td>
                    <td>
                        <small>{this.props.school.currency}</small>
                        &nbsp;
                        {item.amount}
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="report_accounting" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.reports.menu}>
                                    {t('reports:reports')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('reports:transaction')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('reports:transactionsummary')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={
                                            this.props.school.short_code + '-' + t('reports:transactionsummary')
                                        }
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="timetable-form mb-5">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.start date')}  : *</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="MMM yyyy"
                                                className="form-control"
                                                defaultValue={this.state.start_date}
                                                selected={this.state.start_date}
                                                onChange={this._setStartDate}
                                                showMonthYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.start_date != ''
                                                        ? convertADtoBS(this.state.start_date) != null
                                                            ? convertADtoBS(this.state.start_date).slice(0, -3) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.start_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.start_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.end date')} : *</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.end date')}
                                                dateFormat="MMM yyyy"
                                                className="form-control"
                                                defaultValue={this.state.end_date}
                                                selected={this.state.end_date}
                                                onChange={this._setEndDate}
                                                showMonthYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.end_date != ''
                                                        ? convertADtoBS(this.state.end_date) != null
                                                            ? convertADtoBS(this.state.end_date).slice(0, -3) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.end_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.end_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('sidebar:account')} :</label>
                                            <Select
                                                name="account"
                                                options={this.state.accountList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.account}
                                                onChange={this._setAccount}
                                            />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('attendance:type')} :</label>
                                            <Select
                                                name="category"
                                                options={this.state.categoryList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.category}
                                                onChange={this._setCategory}
                                            />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <button className="main-button" onClick={() => this._onSearch()}>
                                                {t('attendance:filter')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={5} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('reports:transactionsummary')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={5} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border invoice_table_header_dark">
                                                            <td>
                                                                <strong>{t('activity:date')}</strong>
                                                                &nbsp;
                                                                {this.props.school.date_format == 'BS' ? (
                                                                    <small>( B.S )</small>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td>
                                                                <strong>{t('sidebar:account')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('alumni:category')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('common:description')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('income:amount')}</strong>
                                                            </td>
                                                        </tr>
                                                        {this.state.transactions.length == 0 && (
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <p className="no-data">
                                                                        {SVG.nodata()}
                                                                        {t('common:table.nodata')}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {this.state.transactions.length > 0 && this.getTransactions()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const Transaction = withTranslation()(connect(mapStateToProps)(TransactionComponent))

export { Transaction }
