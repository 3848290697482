import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const addJobVacancy = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('alumni/job-vacancy/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getJobVacancy = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/job-vacancy/view/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const applyJob = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/job-vacancy/apply/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const searchVacancy = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('alumni/job-vacancy/search', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewApplicant = (from, to, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/job-vacancy/applicant/' + from + '/' + to, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
