import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import {editBookCategories, updateBookCategories} from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

export class AbstractBookCategoryEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: null,
            name: '',
            code: '',
            errors: {
                name:null,
                code: null
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }


    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadBookCategory()
    }

    loadBookCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        editBookCategories(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.bookCategory;
                this.setState({
                    name: item.name,
                    code: item.code
                })
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code : this.state.code,
            }
            this._handleChange('loading', true)
            updateBookCategories(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.library.bookcategory.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
