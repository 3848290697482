import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { AbstractGalleryComponent } from './abstract'

export class ReactGallery extends AbstractGalleryComponent {
    constructor(props: Props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return (
            <div>
                {this.props.images ? (
                    <Fragment>
                        {this.state.showGallery == true && <Gallery photos={this.props.images} onClick={this.openLightbox} />}
                        <ModalGateway>
                            {this.state.viewerIsOpen ? (
                                <Modal onClose={this.closeLightbox}>
                                    <Carousel
                                        currentIndex={this.state.currentImage}
                                        views={this.props.images.map((x) => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title,
                                        }))}
                                        trackProps={{
                                            onViewChange: (index) => this.setState({ currentImage: index }),
                                        }}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </Fragment>
                ) : null}
            </div>
        )
    }
}
