import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { detailBookIssue, viewBookShelf } from '~/services/api'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBookIssueDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            fineAmount: '',
            returnDate: '',
            bookShelf: '',
            book: '',
            issueDate: '',
            dueDate: '',
            issuedBy: '',
            receivedBy: '',
            issuedTo: '',
            bookShelves: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadBookShelf()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadBookShelf() {
        this._handleChange('loading_text', 'loading')
        this._handleChange('loading', true)
        viewBookShelf(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookShelves = response.data.shelf.map((item) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        bookShelves,
                    },
                    () => {
                        this.loadDetail()
                    }
                )
            }
        })
    }

    loadDetail() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        detailBookIssue(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookIssue = response.data.bookIssued
                console.log('bookIssue', bookIssue)
                const bookShelf = this.state.bookShelves.find((el) => el.value == bookIssue.book_shelf.shelves_id)

                this.setState({
                    book: bookIssue.book_shelf ? bookIssue.book_shelf.books.title : null,
                    issuedTo: bookIssue.users_issued ? bookIssue.users_issued.name : null,
                    bookShelf: bookShelf ? bookShelf.label : null,
                    fineAmount: bookIssue.fine_amount,
                    returnDate: bookIssue.return_date,
                    issueDate: bookIssue.issue_date,
                    dueDate: bookIssue.due_date,
                    issuedBy: bookIssue.issued_by ? bookIssue.issued_by.name : null,
                    receivedBy: bookIssue.received_by ? bookIssue.received_by.name : null,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school.school,
    }
}
