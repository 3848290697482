import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {AbstractReportCardComponent, mapStateToProps } from './abstract'
import {Sidebar} from '~/components/sidebar'
import {Header} from '~/components/header'
import {Table} from '~/components/table'
import {Button, Input, TextArea} from '~/components/form'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import * as ReactTable from 'react-super-responsive-table'
import {withTranslation} from 'react-i18next'
import {Loading} from '~/components/loader'
import {convertADtoBS} from '~/helper/function'



class ReportCardComponent extends AbstractReportCardComponent {
  constructor(props){
    super(props)
    this.state= {
      ...this.state,
    }
  }
  render() {
    const {t} = this.props
    return (      
      <div className="main-page">
        <Sidebar id="exam" />
        <Loading status={this.state.loading} text={this.state.loading_text} />

        <div className="main-content">
            <Header />
            <div className="main-head-option">
              <ul className="list-inline breadcrum clearfix">
                <li>
                    <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                </li>
                <li>
                    <a href={route.examination.result.view}>{t('exam:result.categoryTitle')} &gt; </a>
                </li>
                <li><a href={route.examination.result.reportcardmenu + '/' + this.state.exam_id }>{t('exam:result.filter')} &gt;</a></li>
                <li>{t('reportcard:reportcard')}</li>
              </ul>

              <div className="row clearfix">
                <div className="col-md-5 float-left">
                    <h5 className="main-title heading5">{t('reportcard:reportcard')} :</h5>
                </div>
              </div>
              <div className="main-form">
                <Table data={this.state.tableData} />
              </div>

            </div>
        </div>
      </div>
    )
  }
}

const ReportCard = withTranslation()(connect(mapStateToProps)(ReportCardComponent))
export {ReportCard}


