import React, { Component } from 'react'
import { date_color } from '~/helper/constant/color'
import { getNumberofDays } from '~/helper/function/util'
import { getCalendar } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as PostType from '~/helper/constant/post_type'
import moment from 'moment'

const year = new Date().getFullYear()
const month = new Date().getMonth() + 1
const days = new Date().getDate()
const endDate = getNumberofDays(year, month)

type Props = {
    token: String,
}

type State = {
    start_date: String,
    end_date: String,
    minDate: Object,
    maxDate: Object,
    eventDate: Object,
    events: Object,
    holidays: Object,
    todo: Object,
}

export class AbstractDashboardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            start_date: moment(year + '-' + month + '-' + days).format('yyyy-MM-DD'),
            end_date: moment(year + '-' + month + '-' + days)
                .add(2, 'M')
                .format('yyyy-MM-DD'),
            events: [],
            holidays: [],
            todo: [],
            minDate: {
                year: year,
                month: month,
                day: 1,
            },
            maxDate: {
                year: year,
                month: month,
                day: endDate,
            },
            eventDate: [],
        }
        this.loadCalendar = this.loadCalendar.bind(this)
    }

    componentDidMount() {
        this.loadCalendar()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAlumni != this.props.isAlumni) {
            this.loadCalendar()
        }
    }

    loadCalendar() {
        getCalendar(this.state.start_date, this.state.end_date, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const eventDate = []
                const events = []
                const holidays = []
                const todo = []
                const posts = response.data.post.filter((item) => item.isAlumni == this.props.isAlumni)
                posts.map((item) => {
                    let color = date_color[1]
                    if (item.type == PostType.Holiday) {
                        color = date_color[0]
                        holidays.push(item)
                    }
                    if (item.type == PostType.Event) {
                        color = date_color[1]
                        events.push(item)
                    }
                    if (item.type == PostType.Homework) {
                        color = date_color[1]
                        todo.push(item)
                    }
                    if (item.from_date != null) {
                        const date = item.from_date.split('-')

                        const data = {
                            year: parseInt(date[0]),
                            month: parseInt(date[1]),
                            day: parseInt(date[2]),
                            className: color,
                        }
                        eventDate.push(data)
                        item.event.map((eventItem) => {
                            const event_date = eventItem.date.split('-')
                            const eventData = {
                                year: parseInt(event_date[0]),
                                month: parseInt(event_date[1]),
                                day: parseInt(event_date[2]),
                                className: color,
                            }
                            eventDate.push(eventData)
                        })
                    }
                })
                this.setState({
                    eventDate,
                    holidays,
                    todo,
                    events,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        isAlumni: state.isAlumni,
        school: state.school,
    }
}
