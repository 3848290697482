import { post } from './request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const login = (data) => {
    return new Promise(function (resolve, reject) {
        try {
            post('login', null, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const logout = (token, device_id) => {
    return new Promise(function (resolve, reject) {
        try {
            post('logout', token, device_id).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
