import React from 'react'
import htmlParser from 'react-html-parser'

String.prototype.highlightHashtags = function () {
    let str = this
    str = str.replace(/\n/g, '<br>')
    if (
        !str.match(
            /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?#([a-zA-Z0-9]+)/g
        ) &&
        !str.match(
            /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?@([a-zA-Z0-9]+)/g
        ) &&
        !str.match(
            /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?#([\u0600-\u06FF]+)/g
        ) &&
        !str.match(
            /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?@([\u0600-\u06FF]+)/g
        )
    ) {
        if (!str.match(/#(([_a-zA-Z0-9]+)|([\u0600-\u06FF]+)|([ㄱ-ㅎㅏ-ㅣ가-힣]+)|([ぁ-んァ-ン]+)|([一-龯]+))#/g)) {
            //arabic support, CJK support
            str = str.replace(
                /#(([_a-zA-Z0-9]+)|([\u0600-\u06FF]+)|([ㄱ-ㅎㅏ-ㅣ가-힣]+)|([ぁ-んァ-ン]+)|([一-龯]+))/g,
                '<mark>#$1</mark>'
            )
        } else {
            str = str.replace(
                /#(([_a-zA-Z0-9]+)|([\u0600-\u06FF]+)|([ㄱ-ㅎㅏ-ㅣ가-힣]+)|([ぁ-んァ-ン]+)|([一-龯]+))#(([_a-zA-Z0-9]+)|([\u0600-\u06FF]+)|([ㄱ-ㅎㅏ-ㅣ가-힣]+)|([ぁ-んァ-ン]+)|([一-龯]+))/g,
                '<mark>#$1</mark>'
            )
        }
    }
    if (str.match(/(https?:\/\/[^\s]+)/g)) {
        str = str.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank"  className="post-link">$1</a>')
    }
    return str
}

const Hashtags = (props) => {
    return props.hasOwnProperty('text')
        ? htmlParser(props.text.replaceAll('className', 'class').highlightHashtags())
        : htmlParser(props.children.replaceAll('className', 'class').highlightHashtags())
}

export default Hashtags
