import React from 'react'
import { withTranslation } from 'react-i18next'
import MultiImageInput from 'react-multiple-image-input'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Button, Input } from '~/components/form'
import { ColorPicker } from '~/components/form/color-picker'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { TOP_LEFT, TOP_RIGHT, TOP_CENTER} from '~/helper/constant/reportcard'
import { AbstractReportCardEditComponent, mapStateToProps } from './abstract'

class ReportCardEditComponent extends AbstractReportCardEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }

        this.OnFilesError = this.onFilesError.bind(this)
        this._setBackground = this._setBackground.bind(this)
    }

    _setBackground(data, type) {
        if (type === 'border') {
            this._handleChange('border', data)
        } else {
            this._handleChange('logo', data)
        }
    }
    selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    onFilesError(error, file) {
        alert('Error' + error.message)
    }
    render() {
        const { t } = this.props
        const reportCardClasses = `report-card report-card--${
            this.state.selectedDisplayMode ? 'portrait' : 'landscape'
        }`
        const titleContainerClasses = `title-container title-container--${this.state.selectedTitlePosition}`
        const logoContainerClasses = `logo-container logo-container--${this.state.selectedLogoPosition}`
        const selectedLogoPosition = this.state.selectedLogoPosition
        const selectedTitlePosition = this.state.selectedTitlePosition
        return (

            
            <div className="main-page">
                <Sidebar id="setting" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="fee-design">
                        <div className="main-head-option">
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.admin.setting.menu}>{t('common:settings')} &gt; </a>
                                </li>
                                <li>
                                    <a href={route.admin.setting.reportcard.view}>{t('reportcard:reportcard')} &gt; </a>
                                </li>
                                <li>{t('common:EditBtn')}</li>
                            </ul>
                            <div className="row clearfix">
                                <div className="col-md-12 float-left">
                                    <h5 className="main-title heading5">{t('reportcard:editReportCard')} :</h5>
                                </div>
                            </div>
                        </div>
                        <div className="main-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <form>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('common:title')} *</label>
                                                <Input
                                                    name="title"
                                                    onChange={this._handleChange}
                                                    placeholder={t('common:title')}
                                                    type="text"
                                                    value={this.state.title}
                                                />
                                                {this.state.errors.title && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.title}
                                                    </label>
                                                )}
                                            </div>
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">
                                                    {t('classes:subject.add.codeLabel')} *
                                                </label>
                                                <Input
                                                    name="code"
                                                    onChange={this._handleChange}
                                                    placeholder={t('reportcard:code')}
                                                    type="text"
                                                    value={this.state.code}
                                                    disabled
                                                />
                                                {this.state.errors.code && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.code}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('grading:grading')}</label>
                                                <Select
                                                    name="grading"
                                                    options={this.state.grades}
                                                    placeholder=""
                                                    searchable={true}
                                                    value={this.state.defaultGrading}
                                                    onChange={(data) => this.selectList('Grading', data)}
                                                />
                                            </div>
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('reportcard:titlePosition')} </label>
                                                <Select
                                                    name="title_position"
                                                    options={this.state.titlePosition}
                                                    placeholder=""
                                                    searchable={true}
                                                    onChange={(data) => this.selectList('TitlePosition', data)}
                                                    value={this.state.defaultTitlePosition}
                                                />
                                            </div>
                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('reportcard:color')} *</label>
                                                <ColorPicker
                                                    value={this.state.color}
                                                    name="color"
                                                    onChange={this._handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('reportcard:fontSize')}</label>
                                              
                                                <Select
                                                    name="font-size"
                                                    options={this.state.fontSize}
                                                    placeholder=""
                                                    searchable={true}
                                                    onChange={(data) => this.selectList('FontSize', data)}
                                                    value={this.state.defaultFontSize}
                                                />
                                                
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('reportcard:displayMode')} </label>
                                                <Select
                                                    name="display_mode"
                                                    options={this.state.displayMode}
                                                    placeholder=""
                                                    searchable={true}
                                                    onChange={(data) => this.selectList('DisplayMode', data)}
                                                    value={this.state.defaultDisplayMode}
                                                />
                                                {this.state.errors.displayMode && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.displayMode}
                                                    </label>
                                                )}
                                            </div>

                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('reportcard:logoPosition')} </label>
                                                <Select
                                                    name="logo_position"
                                                    options={this.state.logoPosition}
                                                    placeholder=""
                                                    searchable={true}
                                                    onChange={(data) => this.selectList('LogoPosition', data)}
                                                    value={this.state.defaultLogoPosition}
                                                />
                                                {this.state.errors.logoPosition && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.logoPosition}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('reportcard:bg')}</label>
                                                <MultiImageInput
                                                    images={this.state.border}
                                                    max={1}
                                                    setImages={(data) => this._setBackground(data, 'border')}
                                                    allowCrop={false}
                                                    theme="light"
                                                />
                                                {this.state.errors.border && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.border}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('reportcard:logo')}</label>
                                                <MultiImageInput
                                                    images={this.state.logo}
                                                    max={1}
                                                    setImages={(data) => this._setBackground(data, 'logo')}
                                                    allowCrop={false}
                                                    theme="light"
                                                />
                                            </div>
                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('reportcard:note')} </label>
                                                <Input
                                                    name="note"
                                                    onChange={this._handleChange}
                                                    placeholder={t('reportcard:note')}
                                                    type="text"
                                                    value={this.state.note}
                                                />
                                                {this.state.errors.note && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.note}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-sm-3 float-left form-group">
                                                <Button
                                                    onClick={this.OnSave}
                                                    type="submit"
                                                    text={t('common:savebtn')}
                                                    main={true}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="reportHeading">Report Card Live Preview</h6>
                                    <div
                                        className={reportCardClasses}
                                        style={{
                                            background: this.state.border ? `url(${this.state.border[0]})` : '#f5f5f5',
                                        }}
                                    >
                                        <div className={titleContainerClasses}>
                                            <h3
                                                style={{
                                                    color: this.state.color,
                                                    fontSize: this.state.selectedFontSize + 'px',
                                                }}
                                            >
                                                {this.state.title ? this.state.title : 'Report Card'}
                                            </h3>
                                        </div>

                                        <div
                                            className={logoContainerClasses}
                                            style={
                                                (selectedTitlePosition === TOP_LEFT && selectedLogoPosition === TOP_LEFT) || 
                                                (selectedTitlePosition === TOP_RIGHT && selectedLogoPosition === TOP_RIGHT) ||
                                                (selectedTitlePosition === TOP_CENTER && selectedLogoPosition === TOP_CENTER)
                                                    ? { top: '10%' }
                                                    : {}
                                            }
                                        >
                                            {this.state.logo[0] && (
                                                <img src={this.state.logo[0]} alt="Logo" className="logo" />
                                            )}
                                        </div>
                                        <div className="reportpreviewnote">
                                            <p style={{ color: this.state.color }}>{this.state.note}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ReportCardEdit = withTranslation()(connect(mapStateToProps)(ReportCardEditComponent))
export { ReportCardEdit }
