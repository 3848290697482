const introReducer = (state = true, action) => {
    switch (action.type) {
        case 'OPEN_INTRO':
            return true
        case 'CLOSE_INTRO':
            return false
        default:
            return state
    }
}

export default introReducer
