export const book_type = [
    {
        label:"Digital",
        value:0,
    },
    {
        label:'Hard Copy',
        value:1
    },
]

export const issue_status = [
    {
        label:"Issued",
        value:0,
    },
    {
        label:'Returned',
        value:1
    },
]