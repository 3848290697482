import { get, post } from '../request'

export const addReportCard = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/report-card/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewReportCard = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/report-card', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeReportCard = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/report-card/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleReportCard = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/report-card/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getReportCard = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/report-card/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editReportCard = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/report-card/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
