import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass, getPost } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam: Object,
    classes: Array,
}

export class AbstractRoutineMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            teacherClass: [],
            classes: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Teacher) {
            this.loadClass()
        } else {
            this.loadExam()
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let teacherClass = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    teacherClass.push(item.id)
                })
            }
            this.setState(
                {
                    teacherClass,
                },
                () => {
                    this.loadExam()
                }
            )
        })
    }

    loadExam() {
        this._handleChange('loading', true)
        getPost(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const classes = []
                if (this.props.school.user_type == UserType.Teacher) {
                    response.data.post.class_access_information
                        .filter((item) => this.state.teacherClass.includes(item.id))
                        .map((item) => {
                            classes.push(item)
                        })
                } else {
                    response.data.post.class_access_information.map((item) => {
                        classes.push(item)
                    })
                }
                this.setState({
                    exam: response.data.post,
                    classes,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
