import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { getScheduleByUser, viewFine, getFeeByUser, getDiscountByUser, getChildrenProfile } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as PAYMENT_STATUS from '~/helper/constant/payment_status'
import { discount_type } from '~/helper/constant/discount'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import * as Bill_TYPE from '~/helper/constant/bill_type'
import * as PAYMENT from '~/helper/constant/payment_types'
import * as SVG from '~/assets/svg'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractFeeViewStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            currentDate: moment().format('Y-MM-D'),
            currentDue: 0,
            previousDue: 0,
            totalDue: 0,
            userBillData: [],
            children: null,
            billSchedule: [
                {
                    bill_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'fee:billDate',
                    },
                    due_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'common:duedate',
                    },
                    amount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:fee_amount',
                    },
                    discount: {
                        field: 'text',
                        route: false,
                        sortable: false,
                        translate: 'fee:discount',
                    },
                    fine: {
                        field: 'text',
                        route: false,
                        sortable: false,
                        translate: 'fee:fine',
                    },
                    previous_due: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:previous_due',
                    },
                    total: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:total',
                    },
                    status: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:status',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadFine = this.loadFine.bind(this)
        this.loadDiscount = this.loadDiscount.bind(this)
        this.loadBill = this.loadBill.bind(this)
        this.loadBillSchedule = this.loadBillSchedule.bind(this)
        this.calculateFine = this.calculateFine.bind(this)
        this.applyDiscount = this.applyDiscount.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Parents) {
            this.loadChildren()
        } else {
            this.loadFine()
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadChildren() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getChildrenProfile(this.props.student, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const children = response.data.children.children
                this.setState(
                    {
                        children,
                    },
                    () => {
                        this.loadFine()
                    }
                )
            }
        })
    }

    loadFine() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let fineData = []
        viewFine(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.fine.map((item, index) => {
                    let fineElement = {
                        label: item.name,
                        value: item.id,
                        flat: item.flat,
                        amount: item.amount,
                        fine_slab: item.fine_slab,
                        is_slab: item.is_slab,
                    }
                    fineData.push(fineElement)
                })
                this.setState(
                    {
                        fineData,
                    },
                    () => {
                        this.loadDiscount()
                    }
                )
            }
        })
    }

    loadDiscount() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let discountData = []
        getDiscountByUser(this.props.school.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.discount.map((item, index) => {
                    let discountElement = {
                        label: item.name,
                        value: item.id,
                        flat: item.flat,
                        discount: item.discount,
                    }
                    discountData.push(discountElement)
                })
                this.setState(
                    {
                        discountData,
                    },
                    () => {
                        this.loadBill()
                    }
                )
            }
        })
    }

    loadBill() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let userBillData = []
        getFeeByUser(this.props.school.id, null, null, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        userBillData: response.data.billing,
                    },
                    () => this.loadBillSchedule()
                )
            } else {
                this.loadBillSchedule()
            }
        })
    }

    loadBillSchedule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let billSchedule = []
        billSchedule.push(this.state.billSchedule[0])
        let classId = null
        let studentId = null
        if (this.state.children != null) {
            classId = this.state.children.active_user_academics.section.class_id
            studentId = this.state.children.id
        } else {
            classId = this.props.school.active_user_academics.section.class_id
            studentId = this.props.school.id
        }
        getScheduleByUser(classId, studentId, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const userBill = this.state.userBillData
                const previousDue = response.data.due_amount
                if (response.data.bill_schedule.length == 0) {
                    this.setState({
                        previousDue,
                        currentDue: 0,
                        totalDue: 0 + previousDue,
                    })
                }
                response.data.bill_schedule
                    .sort((a, b) => {
                        const one = moment(a.from_date, 'yyyy-MM-DD')
                        const two = moment(a.to_date, 'yyyy-MM-DD')
                        return one.isSameOrAfter(two) ? -1 : 1
                    })
                    .map((item, index) => {
                        const bill_detail = userBill ? (userBill.filter((el) => el.bill_schedule_id == item.id).length > 0 ? userBill.filter((el) => el.bill_schedule_id == item.id)[0] : null) : null
                        let payment_status = 'N/A'
                        if (bill_detail != null) {
                            item['bill_id'] = bill_detail.id
                            payment_status = PAYMENT_STATUS.PAYMENT_STATUS_OBJECT.filter((el) => el.value == bill_detail.status) ? PAYMENT_STATUS.PAYMENT_STATUS_OBJECT.filter((el) => el.value == bill_detail.status)[0].label : 'N/A'
                        }
                        //DISCOUNT

                        const discountData = this.applyDiscount(item, bill_detail)
                        item['discount'] = discountData.discount
                        item['total_amount'] = discountData.total_amount
                        item['discount_detail'] = discountData.discount_detail

                        //FINE
                        let totalFine = 0
                        if (bill_detail == null) {
                            const fine = item.fine
                            if (fine != null) {
                                totalFine = this.calculateFine(fine, item)
                                const fine_detail = {
                                    label: fine.name,
                                    value: fine.id,
                                    flat: fine.flat,
                                    amount: totalFine,
                                    fine_slab: fine.fine_slab,
                                    is_slab: fine.is_slab,
                                }
                                item['fine_detail'] = [fine_detail]
                                item['total_amount'] = item['total_amount'] + totalFine
                            } else {
                                item['fine_detail'] = []
                            }
                        } else if (bill_detail != null && bill_detail.fine_detail != null && bill_detail.fine_detail.length > 0) {
                            item['fine_detail'] = bill_detail.fine_detail
                            bill_detail.fine_detail.map((el, i) => {
                                const fine_amount = this.calculateFine(el, item)
                                el.amount = fine_amount
                                totalFine = totalFine + parseFloat(fine_amount)
                            })
                            item['total_amount'] = item['total_amount'] + totalFine
                        } else {
                            item['fine_detail'] = []
                        }

                        if (index == 0) {
                            item['previous_due'] = previousDue
                        } else {
                            item['previous_due'] = 0
                        }

                        const tableData = {
                            bill_date: {
                                text: item.from_date,
                            },
                            due_date: {
                                text: item.to_date,
                            },
                            amount: {
                                text: this.props.currency + ' ' + item.amount.toFixed(2),
                            },
                            discount: {
                                text: this.props.currency + ' ' + item.discount.toFixed(2),
                            },
                            fine: {
                                text: this.props.currency + ' ' + totalFine.toFixed(2),
                            },
                            previous_due: {
                                text: this.props.currency + ' ' + parseFloat(item.previous_due).toFixed(2),
                            },
                            total: {
                                text: this.props.currency + ' ' + (item.total_amount + parseFloat(item.previous_due)).toFixed(2),
                            },
                            status: {
                                text: payment_status,
                            },
                        }
                        billSchedule.push(tableData)
                        if (index == 0) {
                            this.setState({
                                previousDue,
                                currentDue: item['total_amount'],
                                totalDue: item['total_amount'] + previousDue,
                            })
                        }
                    })
                this.setState({
                    billSchedule,
                })
            }
        })
    }

    calculateFine(fine, item) {
        if (fine.is_slab) {
            const current_date = moment()
            if (moment(item.to_date).isBefore(current_date)) {
                const due_date = moment(item.to_date)
                const current_date = moment()
                const date_difference = current_date.diff(due_date, 'days')
                let slabFine = 0
                fine.fine_slab.map((el, i) => {
                    if (parseInt(el.from) <= date_difference && parseInt(el.to) >= date_difference) {
                        slabFine = el.flat ? (el.amount / 100) * item.total_amount : el.amount
                    } else if (parseInt(el.to) <= date_difference) {
                        slabFine = el.flat ? (el.amount / 100) * item.total_amount : el.amount
                    }
                })
                return slabFine
            } else {
                return 0
            }
        } else {
            return fine.flat ? (fine.amount / 100) * item.total_amount : fine.amount
        }
    }

    applyDiscount(item, bill_detail) {
        let totalDiscount = 0
        let discount_detail = []
        const selectedDiscount = []
        if (bill_detail == null) {
            totalDiscount = 0
        } else if (bill_detail != null && bill_detail.discount_detail != null && bill_detail.discount_detail.length > 0) {
            bill_detail.discount_detail.map((el, i) => {
                const bill_discount = el.flat ? (el.discount / 100) * item.amount : el.discount
                totalDiscount = totalDiscount + parseFloat(bill_discount)
            })
            discount_detail = bill_detail.discount_detail
        }
        this.setState({
            selectedDiscount,
        })
        const data = {
            discount_detail,
            discount: totalDiscount,
            total_amount: item['amount'] - totalDiscount,
        }
        return data
    }
}

const AbstractFeeViewStudent = withTranslation()(AbstractFeeViewStudentComponent)

export { AbstractFeeViewStudent }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        currency: state.school.school.currency,
        school_code: state.school.school.code,
        school: state.school,
        student: state.student,
    }
}
