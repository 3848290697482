import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { display_mode, font_size, title_position } from '~/helper/constant/reportcard'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { viewSingleProductUnit, updateProductUnit} from '~/services/api'


type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantUnitEditComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            unitId:"",
            loading: false,
            loading_text:'Loading',
            name:'',
            errors:{
                name:null
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(){
        this.loadUnit()
    }
    _handleChange(key, data){
        this.setState({[key]: data})
    }

    loadUnit(){
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewSingleProductUnit(this.props.match.params.id, this.props.token).then((response)=>{
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                this.setState({
                    name: response.data.unit.name,
                    unitId: response.data.unit.id
                })

            }
        })

    }

    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors[item] != null)
        if(validate.length == 0){
            const data ={
                name: this.state.name
            }
            this._handleChange('loading', true)
            updateProductUnit(this.state.unitId, data, this.props.token).then((response)=>{

                this._handleChange('loading', false)
                if(response.code == API_SUCCESS ){
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.constant.unit.view)
                }else{
                    toastMessage('error', response.data)
                if(response.data){
                    if('errors' in response.data){
                        this.setState({errors: response.data.errors})
                    }
                }

                }

            })

        }

    }

}
export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}