import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSection, activeSubject, viewTimetable, deleteTimetable, toggleTimetable, viewTeacher, editTimetable } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import * as Status from '~/helper/constant/status'
import moment from 'moment'
import { components } from 'react-select'
import * as validation from '~/helper/constant/validation'
import { faThumbsDown } from '@fortawesome/free-regular-svg-icons'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
}

type State = {
    loading: Boolean,
    today: String,
    activeDay: String,
    classes: Object,
    section: Object,
    timetable: Array,
    filterTimeTable: Array,
    dispatch: Dispatch<any>,
    errors: Object,
    teacher: Array,
    substitute: Array,
    teacherAvailabilty: Array,
    selectedTeacher: Object,
    teacher_id: String,
    selectedSubject: Object,
    subject_id: String,
    live_class_status: Boolean,
    selectedSubstitute: Array,
    substitute_id: Array,
    start_time: String,
    end_time: String,
    defaultTeacher: String,
    timetable_id: String,
    loading_text: String,
    day: String,
}

export class AbstractTimetableViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loaded: false,
            clearState: false,
            loading: false,
            loading_text: 'Loading',
            start_time: '09:00:00',
            end_time: '05:00:00',
            today: moment(today).format('dddd'),
            timetable: [],
            substitute_id: [],
            filterTimeTable: [],
            activeDay: days.find((item) => item.value == 0),
            teacher: [],
            teacherAvailabilty: [
                {
                    days: {
                        field: 'text',
                        translate: 'common:days',
                        route: false,
                        sortable: true,
                    },
                    class: {
                        field: 'text',
                        translate: 'timetable:class',
                        route: false,
                        sortable: true,
                    },
                    start_time: {
                        field: 'text',
                        translate: 'timetable:startTime',
                        route: false,
                        sortable: true,
                    },
                    end_time: {
                        field: 'text',
                        translate: 'calendar:event.schedule.add.endTimeLabel',
                        route: false,
                        sortable: true,
                    },
                },
            ],
            errors: {
                subject_id: null,
                teacher_id: null,
            },
            selectedTeacher: {
                label: '',
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.changeDay = this.changeDay.bind(this)
        this.nextDay = this.nextDay.bind(this)
        this.prevDay = this.prevDay.bind(this)
        this.loadSection = this.loadSection.bind(this)
        this._delete = this._delete.bind(this)
        this._toggle = this._toggle.bind(this)
        this.loadTeacherAvailabilty = this.loadTeacherAvailabilty.bind(this)
        this.loadSubject = this.loadSubject.bind(this)
        this.filterSubstitute = this.filterSubstitute.bind(this)
        this._join = this._join.bind(this)
        this.validateTeacherTimeSlot = this.validateTeacherTimeSlot.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        const item = days.find((item) => item.label == this.state.today)
        this._handleChange('activeDay', item)
        this.loadSection()
        this.loadTeacher()
    }

    loadSection() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getSection(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let section = null
            let classes = null
            if (response.code == API_SUCCESS) {
                section = response.data.section
                if (response.data.section.classes) {
                    classes = response.data.section.classes
                }
                this.setState(
                    {
                        classes,
                        section,
                    },
                    () => {
                        this.loadSubject()
                    }
                )
            }
        })
    }

    loadSubject() {
        activeSubject(this.state.classes.id, this.props.token).then((response) => {
            let subject = []
            if (response.code == API_SUCCESS) {
                response.data.subject.map((item, index) => {
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    subject.push(data)
                })
                if (subject.length > 0) {
                    this.setState(
                        {
                            subject,
                            subject_id: subject[0].value,
                            selectedSubject: subject[0],
                        },
                        () => {
                            this.loadTeacher()
                        }
                    )
                } else {
                    this.setState(
                        {
                            subject,
                        },
                        () => {
                            this.loadTeacher()
                        }
                    )
                }
            }
        })
    }

    loadTeacher() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewTeacher(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let teacher = []
            const defaultData = {
                label: '-- Select Teacher --',
                value: '',
                timetable: [],
            }
            teacher.push(defaultData)
            if (response.code == API_SUCCESS) {
                response.data.teacher.map((item) => {
                    const timetable = []
                    item.timetable.map((time) => {
                        const data = {
                            start_time: time.start_time,
                            end_time: time.end_time,
                            day: time.day,
                            classes: time.section.classes.name,
                            section: time.section.name,
                        }
                        timetable.push(data)
                    })
                    const data = {
                        label: item.user.name + ' - ' + item.user.reg_no,
                        value: item.id,
                        timetable,
                    }
                    teacher.push(data)
                })
                if (teacher.length > 0) {
                    this.setState(
                        {
                            teacher,
                            teacher_id: teacher[0].value,
                            selectedTeacher: teacher[0],
                        },
                        () => {
                            this.loadTimetable()
                            this.loadTeacherAvailabilty()
                            this.filterSubstitute(this.state.selectedTeacher.id)
                        }
                    )
                }
            }
        })
    }

    _join(e) {
        let link = e
        if (typeof document != 'undefined') {
            link = e.target.getAttribute('link')
        }
        let id = link.split(',')
        if (typeof document != 'undefined') {
            window.open(route.bbb + '/' + id[1], '_blank')
        } else {
            redirect(route.bbb + '/' + id[1], this.props.history)
        }
    }

    loadTeacherAvailabilty() {
        let teacherAvailabilty = []
        teacherAvailabilty.push(this.state.teacherAvailabilty[0])
        this.state.selectedTeacher.timetable.map((item) => {
            const days_name = days.find((result) => result.value == item.day)
            const data = {
                days: {
                    text: days_name.label,
                },
                class: {
                    text: item.classes + ' - ' + item.section,
                },
                start_time: {
                    text: item.start_time,
                },
                end_time: {
                    text: item.end_time,
                },
            }
            teacherAvailabilty.push(data)
        })
        this.setState({
            teacherAvailabilty,
        })
    }

    filterSubstitute(id) {
        const substitute = []
        const substitute_id = []
        const selectedSubstitute = []
        this.state.teacher
            .filter((item) => item.value != id)
            .map((item) => {
                substitute.push(item)
            })
        if (this.state.substitute_id) {
            this.state.substitute_id
                .filter((item) => item != id)
                .map((item) => {
                    substitute_id.push(item)
                })
            this.state.teacher
                .filter((item) => substitute_id.includes(item.value))
                .map((item) => {
                    selectedSubstitute.push(item)
                })
        }

        this._handleChange('substitute', substitute)
        this._handleChange('substitute_id', substitute_id)
        this._handleChange('selectedSubstitute', selectedSubstitute)
    }

    _toggle(e) {
        let link = e
        if (typeof document != 'undefined') {
            link = e.target.getAttribute('link')
        }
        toggleTimetable(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                const timetable = response.data.timetable
                //this.loadTimetable()
                if (timetable.status == Status.ACTIVE) {
                    this.loadTimetable()
                    if (timetable.bbb != null) {
                        if (typeof document != 'undefined') {
                            window.open(route.bbb + '/' + timetable.bbb.id, '_blank')
                        } else {
                            redirect(route.bbb + '/' + timetable.bbb.id, this.props.history)
                        }
                    }
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _delete(e) {
        const link = e.target.getAttribute('link')
        deleteTimetable(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadTimetable()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadTimetable() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        if (this.state.section != null) {
            viewTimetable(this.state.section.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                let timetable = []
                if (response.code == API_SUCCESS) {
                    response.data.timetable.map((item, index) => {
                        timetable.push(item)
                    })
                    this.setState(
                        {
                            timetable,
                            loaded: true,
                        },
                        () => {
                            this.filterTable()
                        }
                    )
                }
            })
        }
    }

    filterTable() {
        let filterTimeTable = []
        const timetable = this.state.timetable
        timetable
            .filter((item) => item.day == this.state.activeDay.value)
            .sort((a, b) => {
                const a_start_time = moment(a.start_time, 'hh:mm a')
                const b_start_time = moment(b.start_time, 'hh:mm a')
                return a_start_time.isAfter(b_start_time) ? 1 : -1
            })
            .map((item) => {
                filterTimeTable.push(item)
            })
        this.setState(
            {
                filterTimeTable,
                clearState: true,
            },
            () => {}
        )
    }

    nextDay(value) {
        if (value < 6) {
            this.changeDay(value + 1)
        } else {
            this.changeDay(0)
        }
    }

    prevDay(value) {
        if (value > 0) {
            this.changeDay(value - 1)
        } else {
            this.changeDay(6)
        }
    }

    changeDay(value) {
        const activeDay = days.find((item) => item.value == value)
        this.setState(
            {
                activeDay,
            },
            () => {
                this.filterTable()
            }
        )
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    validateTeacherTimeSlot() {
        if (this.state.defaultTeacher != this.state.teacher_id) {
            const occupiedTeacherTime = []
            this.state.selectedTeacher.timetable
                .filter((result) => {
                    const start_time = moment(this.state.start_time, 'hh:mm a')
                    const end_time = moment(this.state.end_time, 'hh:mm a')
                    const occupied_start_time = moment(result.start_time, 'hh:mm a')
                    const occupied_end_time = moment(result.end_time, 'hh:mm a')
                    if (occupied_start_time.isBetween(start_time, end_time) || occupied_end_time.isBetween(start_time, end_time) || start_time.isBetween(occupied_start_time, occupied_end_time) || end_time.isBetween(occupied_start_time, occupied_end_time) || (start_time.isSame(occupied_start_time) && end_time.isSame(occupied_end_time) && this.state.day == result.day)) {
                        return true
                    }
                })
                .map((result) => {
                    occupiedTeacherTime.push(result.day)
                })
            const checkTeacherTimeSlot = []
            days.filter((item) => occupiedTeacherTime.includes(item.value)).map((item, index) => {
                if (index == 0) {
                    checkTeacherTimeSlot.push('Time slot not available for teacher for ' + item.label)
                } else {
                    checkTeacherTimeSlot.push(' , ' + item.label)
                }
            })
            return checkTeacherTimeSlot
        } else {
            return []
        }
    }

    _onSave(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    substitute: component.state.substitute_id,
                    subject_id: component.state.subject_id,
                    teacher_id: component.state.teacher_id,
                    live_class_status: component.state.live_class_status,
                }
                const checkTeacherTimeSlot = component.validateTeacherTimeSlot()
                if (checkTeacherTimeSlot.length > 0) {
                    errors['teacher_id'] = checkTeacherTimeSlot
                } else {
                    errors['teacher_id'] = null
                }
                component.setState({ errors })
                const validate = Object.keys(errors).filter((item) => errors[item] != null)
                if (validate.length == 0) {
                    component._handleChange('loading_text', 'Saving')
                    component._handleChange('loading', true)
                    editTimetable(component.state.timetable_id, data, component.props.token).then((response) => {
                        component._handleChange('loading', false)
                        let result = false
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            result = true
                        } else {
                            toastMessage('error', response.message)
                        }
                        component.loadTimetable()
                        component.loadTeacher()
                        resolve(result)
                    })
                } else {
                    resolve(false)
                }
            } else {
                resolve(false)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
