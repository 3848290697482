const packageReducer = (state = null, action) => {
    switch (action.type) {
        case 'PACKAGE':
            if (action.data != null) {
                return JSON.stringify(action.data)
            }
            return null
        case 'SIGN_OUT':
            return null
        case 'RESET':
            return null
        default:
            return state
    }
}

export default packageReducer
