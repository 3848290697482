import React, {Component} from 'react'
import {Dispatch} from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { redirect, toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'
import { addProductUnit } from '~/services/api'



type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantUnitAddComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            name:'',
            errors:{
                name:null,
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    compnentDidMount(){}
    _handleChange(key, data){
        this.setState({[key]: data})
    }
    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=> errors [item] != null)
        if (validate.length == 0){
            const data={
                name: this.state.name,
            }
        this._handleChange("loading", true)
        addProductUnit(data, this.props.token).then((response)=>{
            this._handleChange("loading", false)
            if(response.code == API_SUCCESS){
                toastMessage("success", response.message)
                redirect(route.admin.accounting.constant.unit.view)
            }else{
                toastMessage('error', response.message)
                if(response.data){
                    if('errors' in response.data){
                        this.setState({errors:response.data.errors})
                    }
                }
            }
        })
        }


    }


}

export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}