import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { CATEGORY_INVOICE } from '../../../../../../helper/constant/category'
import { toastMessage } from '../../../../../../helper/function/util'
import { viewProductCategory } from '../../../../../../services/api/accounting/constant'
import { addBulkInvoice } from '../../../../../../services/api/accounting/income'
import { deleteTemplate, getFeeTemplate } from '../../../../../../services/api/admin/bill_template'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractInvoiceTemplateComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            delTemplate: null,
            editTemplate: null,
            categoryList: [],
            templates: [],
            tableData: [
                {
                    title: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:title',
                    },
                    users: {
                        field: 'participants',
                        translate: 'common:student',
                        route: false,
                        sortable: false,
                    },
                    category: {
                        field: 'text',
                        translate: 'accounting:category',
                        route: false,
                        sortable: true,
                    },
                    generate: {
                        field: 'html',
                        translate: 'income:generate',
                        route: false,
                        sortable: false,
                    },
                    action: {
                        field: 'html',
                        translate: 'activity:action',
                        route: false,
                        sortable: false,
                    },
                },
            ],
            issue_date: null,
            due_date: null,
            status: null,
            statusList: [
                { label: 'Draft', value: 0 },
                { label: 'Publish', value: 1 },
            ],
            errors: {
                issue_date: null,
                due_date: null,
                selectedStatus:null
            },


            

        }
        this._handleChange = this._handleChange.bind(this)
        this.delete = this.delete.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadCategory() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewProductCategory(this.props.accounting_token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const filteredcategory = response.data.categories.filter((item) => item.type == CATEGORY_INVOICE)
                const categoryList = filteredcategory.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        categoryList,
                    },
                    () => {
                        this.loadBill()
                    }
                )
            }
        })
    }

    loadBill() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableData = []
        tableData.push(this.state.tableData[0])
        getFeeTemplate(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const templates = response.data.templates
                templates.map((item, index) => {
                    let users = {
                        text: [],
                    }
                    if (item.user_information != null) {
                        let access = []
                        item.user_information.map((access_data) => {
                            const name = access_data.user.name != null ? access_data.user.name : access_data.user.reg_no
                            access.push(name.charAt(0))
                        })
                        users = {
                            text: access,
                        }
                    }
                    const category = this.state.categoryList.find((e) => e.value == item.account_category_id)
                    const data = {
                        title: {
                            text: item.title,
                        },
                        users,
                        category: {
                            text: category != null ? category.label : 'N/A',
                        },
                        generate: {
                            text: 'html',
                            html: (
                                <Fragment>
                                    <button
                                        type="button"
                                        className="second-button"
                                        onClick={() =>
                                            this.setState({
                                                editTemplate: item,
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon className="icon" icon="share" size="sm" />
                                    </button>
                                </Fragment>
                            ),
                        },
                        action: {
                            text: 'html',
                            html: (
                                <Fragment>
                                    <a
                                        href={route.admin.accounting.income.template.edit + '/' + item.id}
                                        className="second-button"
                                    >
                                        <FontAwesomeIcon className="icon" icon="cog" size="sm" />
                                    </a>
                                    <button
                                        type="button"
                                        className="second-button"
                                        onClick={() =>
                                            this.setState({
                                                delTemplate: item,
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon className="icon" icon="trash" size="sm" />
                                    </button>
                                </Fragment>
                            ),
                        },
                    }
                    tableData.push(data)
                })
                this.setState({
                    tableData,
                    templates,
                })
            }
        })
    }

    delete() {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading_text', 'Deleting')
            component._handleChange('loading', true)
            deleteTemplate(component.state.delTemplate.id, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.loadBill()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }

    _onSave() {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Loading')
                component._handleChange('loading', true)
                const templates = component.state.editTemplate
                const items = templates.particulars.map((el) => ({
                    item: el.item,
                    tax: el.defaultProduct.selectedTax.map((tax) => tax.value).join(','), // Join tax_ids with commas
                    discount: el.discount,
                    quantity: el.quantity,
                    price: el.price,
                    description: el.description,
                }))

                const customer_id = templates.user_information.map((e) => {
                    return e.customer_account_id
                })
                const data = {
                    customer_id,
                    issue_date: moment(component.state.issue_date).format('yyyy-MM-DD'),
                    due_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    category_id: templates.account_category_id,
                    items,
                    status: component.state.selectedStatus,
                }

                addBulkInvoice(data, component.props.accounting_token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accounting_token: state.school.accounting_token,
    }
}
