import moment from 'moment'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import { getReadableFileSize } from '~/helper/function/abstract'
import { toastMessage } from '~/helper/function/util'
import { deleteMedia, editDrive, getDriveItems, getSchoolUser } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    defaultStudent: Object,
    defaultEmployee: Object,
}

export class AbstractDriveFolderComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            folders: [],
            sortTerm: 'date',
            orderBy: 'desc',
            folderData: [],
            folder_id: this.props.match.params.id,
            folder_name: '',
            folder_description: '',
            author: '',
            allUsers: [],
            sharedTo: [],
            access: [],
            selectAllEmployee: false,
            selectAllStudent: false,
            apiSelectedStudent: null,
            apiDefaultStudent: null,
            apiSelectedEmployee: null,
            apiDefaultEmployee: null,
            apiDefaultPrivacy: false,
            studentAccess: [],
            employeeAccess: [],
            studentList: [],
            employeeList: [],
            classList: [],
            sectionList: [],
            userTypeList: [],
            customRoleList: [],
            privacy: false,
            uploadedFiles: [],
            mediaAry: [],
            media: {},
            selectedMedia: null,
            selectedMediaName: null,
            selectedMediaType: null,
            defaultRole: null,
            defaultUserType: null,
            defaultClass: null,
            defaultSection: null,
            defaultEmployee: null,
            defaultStudent: null,
            selectedRole: null,
            selectedUserType: null,
            selectedClass: null,
            selectedSection: null,
            selectedStudent: null,
            selectedEmployee: null,
            currentAccess: [],
            detail: {
                file_name: null,
                owner: null,
                createdOn: null,
                folderSize: null,
                sharedTo: [],
            },
            errors: {
                folder_name: null,
                folder_description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.updateFolder = this.updateFolder.bind(this)
        this.resetState = this.resetState.bind(this)
        this.openDetailFolder = this.openDetailFolder.bind(this)
        this.deleteFolder = this.deleteFolder.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.search = this.search.bind(this)
        this.changeOrderBy = this.changeOrderBy.bind(this)
        this.changeSortBy = this.changeSortBy.bind(this)
        this.loadUser = this.loadUser.bind(this)
        this.searchData = this.searchData.bind(this)
        this.orderBy = this.orderBy.bind(this)
        this.sortBy = this.sortBy.bind(this)
    }

    componentDidMount() {
        this.loadAll()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAll() {
        this._handleChange('loading', true)
        getSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let studentList = []
            let employeeList = []
            let allUsers = []
            if (response.code == API_SUCCESS) {
                response.data.users.map((item, index) => {
                    allUsers.push(item)
                    if (item.user_type == 3) {
                        studentList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    } else {
                        employeeList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    }
                })
                this.setState(
                    {
                        allUsers,
                    },
                    () => this.loadDriveItems()
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadUser(data) {
        this.setState({
            allUsers: data,
        })
    }
    _selectStudent(data) {
        this.setState({
            selectedStudent: data,
        })
    }
    _selectEmployee(data) {
        this.setState({
            selectedEmployee: data,
        })
    }

    loadDriveItems() {
        this._handleChange('loading', true)
        getDriveItems(this.state.folder_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let folders = []
            let folderData = []
            let folder_name = ''
            let folder_description = ''
            let author = null
            let defaultStudent = []
            let defaultEmployee = []
            let selectedEmployee = []
            let selectedStudent = []
            let apiDefaultStudent = []
            let apiDefaultEmployee = []
            let apiSelectedEmployee = []
            let apiSelectedStudent = []
            const allUsers = this.state.allUsers
            if (response.code == API_SUCCESS) {
                const folderList = response.data.drive
                folder_name = folderList.title
                folder_description = folderList.description
                author = folderList.author.id
                if (folderList.gallery) {
                    folderList.gallery.media.map((item, index) => {
                        folders.push(item)
                        folderData.push(item)
                    })
                }
                if (folderList.restriction) {
                    const currentAccess = folderList.access
                    const accessAry = []
                    if (folderList.access) {
                        currentAccess.map((item, index) => {
                            const user = allUsers.filter((el) => {
                                return el.id == item
                            })
                            if (user != null && user.length != 0) {
                                if (user[0].user_type == 3) {
                                    apiSelectedStudent.push(user[0].id)
                                    apiDefaultStudent.push({
                                        value: user[0].id,
                                        label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                    })
                                    selectedStudent.push(user[0].id)
                                    defaultStudent.push({
                                        value: user[0].id,
                                        label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                    })
                                } else {
                                    apiSelectedEmployee.push(user[0].id)
                                    apiDefaultEmployee.push({
                                        value: user[0].id,
                                        label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                    })
                                    selectedEmployee.push(user[0].id)
                                    defaultEmployee.push({
                                        value: user[0].id,
                                        label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                    })
                                }
                            }
                        })
                    }
                    // $('.access-list').show()
                    this.setState({
                        currentAccess,
                        privacy: true,
                        apiDefaultPrivacy: true,
                        selectedStudent,
                        selectedEmployee,
                        defaultEmployee,
                        defaultStudent,
                        apiSelectedStudent,
                        apiDefaultStudent,
                        apiDefaultEmployee,
                        apiSelectedEmployee,
                    })
                } else {
                    this.setState({
                        currentAccess: [],
                        privacy: false,
                        apiDefaultPrivacy: false,
                        selectedStudent: [],
                        selectedEmployee: [],
                        defaultEmployee: null,
                        defaultStudent: null,
                        apiSelectedStudent: null,
                        apiDefaultStudent: null,
                        apiDefaultEmployee: null,
                        apiSelectedEmployee: null,
                    })
                }
            } else {
                toastMessage('error', response.message)
            }
            this.setState({ folders, folderData, folder_name, folder_description, author }, () => this._handleChange('loading', false))
        })
    }

    search(e) {
        this.searchData('searchText', e.target.value)
    }

    searchData(key, value) {
        this.setState({
            [key]: value,
        })
        const searchTerm = value
        const folderData = this.state.folderData
        let folders = []
        if (searchTerm.length > 0) {
            folders = folderData.filter((el) => {
                return el.name.toLowerCase().includes(searchTerm.toLowerCase())
            })
            this.setState({ folders })
        } else {
            this.setState({ folders: folderData })
        }
    }

    changeOrderBy(e) {
        this.orderBy(e.value)
    }

    orderBy(value) {
        this.setState({ orderBy: value })
        const orderby = value
        const sortby = this.state.sortTerm
        const folderData = this.state.folderData
        let folders = []
        if (sortby == 'name') {
            folders = folderData.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        } else {
            folders = folderData.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1))
        }
        if (value == 'desc') {
            folders = folderData.reverse()
        }

        this.setState({ folders, orderby })
    }

    changeSortBy(e) {
        this.sortBy(e.value)
    }

    sortBy(value) {
        const sortTerm = value
        const orderby = this.state.orderBy
        const folderData = this.state.folderData
        let folders = []
        if (value == 'name') {
            folders = folderData.sort((a, b) => (a.name > b.name ? 1 : -1))
        } else {
            folders = folderData.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1))
        }
        if (orderby == 'desc') {
            folders = folderData.reverse()
        }

        this.setState({ folders, sortTerm })
    }

    updateFolder(e, type) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            if (type == 'media') {
                if (component.state.media.length == undefined || component.state.media.length == 0) {
                    toastMessage('error', t('toaster:noFilesAddedMsg'))
                    return
                }
            }
            let hasError = false
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0 && hasError == false) {
                let data = {
                    title: component.state.folder_name,
                    description: component.state.folder_description,
                    restriction: component.state.privacy,
                }
                data['restriction'] = false
                if (component.state.privacy) {
                    const studentAccess = component.state.selectedStudent
                    const employeeAccess = component.state.selectedEmployee
                    let access = []
                    if (studentAccess == null) {
                        access = component.state.selectedEmployee
                    } else if (employeeAccess == null) {
                        access = component.state.selectedStudent
                    } else {
                        access = studentAccess.concat(employeeAccess)
                    }
                    data['access'] = access
                    data['restriction'] = true
                }
                if (component.state.media.length != 0) {
                    data['media'] = component.state.media
                }
                component._handleChange('loading', true)
                editDrive(component.state.folder_id, data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.resetState()
                        component.loadDriveItems()
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }

    getDeleteModal(index) {
        const component = this
        return new Promise(function (resolve, reject) {
            const folder = component.state.folders
            component.setState(
                {
                    selectedMedia: folder[index].id,
                    selectedMediaName: folder[index].name,
                    selectedMediaType: folder[index].media_type,
                },
                () => {
                    resolve(true)
                }
            )
        })
    }

    deleteFolder() {
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            deleteMedia(component.state.selectedMedia, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.resetState()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            component.setState({ errors: response.data.errors })
                        }
                    }
                    resolve(false)
                }
            })
        })
    }

    openDetailFolder(index) {
        const component = this
        return new Promise(function (resolve, reject) {
            const allUsers = component.state.allUsers
            const folderDetail = component.state.folders
            const access = component.state.currentAccess
            const sharedTo = []
            access.map((item, i) => {
                const user = allUsers.filter((el) => {
                    return el.id == item
                })
                if (user) {
                    let initial = user[0].user.name != null ? user[0].user.name.substring(0, 1) : 'C'
                    if (user[0].user.user_detail != null && user[0].user.user_detail.profile != null) {
                        initial = user[0].user.user_detail.profile
                    }
                    sharedTo.push(initial)
                }
            })
            const author = folderDetail[index].author_detail

            component.setState(
                {
                    detail: {
                        file_name: folderDetail[index].name,
                        owner: author != null ? (author.name != null ? author.name : author.email) : 'N/A',
                        createdOn: moment(folderDetail[index].created_at).format('YYYY-MM-DD'),
                        folderSize: getReadableFileSize(folderDetail[index].size),
                        sharedTo: sharedTo,
                    },
                },
                () => {
                    resolve(true)
                }
            )
        })
    }

    resetState() {
        let uploads = this.state.uploadedFiles
        for (let i = uploads.length; i >= 0; i--) {
            uploads.pop()
        }
        this.setState(
            {
                folders: [],
                folder_name: '',
                folder_description: '',
                sharedTo: [],
                access: [],
                studentAccess: [],
                employeeAccess: [],
                uploadedFiles: uploads,
                media: {},
                mediaAry: [],
                selectedMedia: null,
                selectedMediaName: null,
                defaultEmployee: null,
                defaultStudent: null,
                selectedStudent: null,
                selectedEmployee: null,
                errors: {
                    folder_name: null,
                    folder_description: null,
                },
            },
            () => this.loadDriveItems()
        )
    }
}

const AbstractDriveFolder = withTranslation()(AbstractDriveFolderComponent)

export { AbstractDriveFolder }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
