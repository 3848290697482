import $ from 'jquery'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractBillEditComponent, mapStateToProps } from './abstract'

class BillEditComponent extends AbstractBillEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }

        this._setBillDate = this._setBillDate.bind(this)
        this._setDueDate = this._setDueDate.bind(this)
        this._selectList = this._selectList.bind(this)
        this._setQuantity = this._setQuantity.bind(this)
        this.deleteModal = this.deleteModal.bind(this)
    }

    calculateTotalAmount(product) {
        const quantity = parseFloat(product.quantity) || 0
        const price = parseFloat(product.price) || 0
        const discount = parseFloat(product.discount) || 0
        const defaultProduct = product.defaultProduct || {}
        const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)
        // const taxes = product.defaultProduct.selectedTax.map(tax => parseFloat(tax.rate) || 0);
        const pq = quantity * price
        const pqd = pq - discount
        const taxAmount = pqd * taxes.reduce((acc, tax) => acc + tax / 100, 0)
        const totalAmount = pqd + taxAmount

        return totalAmount
    }

    // Calculate the sum of prices for all rows
    calculateTotalPrice(productElements) {
        return productElements.reduce(
            (acc, product) => acc + (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0),
            0
        )
    }

    calculateTotalDiscount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.discount) || 0), 0)
    }

    // Calculate the sum of tax amounts for all rows
    calculateTotalTaxAmount(productElements) {
        return productElements.reduce((acc, product) => {
            const quantity = parseFloat(product.quantity) || 0
            const price = parseFloat(product.price) || 0
            const discount = parseFloat(product.discount) || 0

            // Pass index to retrieve the defaultProduct for the current row
            const defaultProduct = product.defaultProduct || {}
            const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

            const pq = quantity * price
            const pqd = pq - discount
            const taxAmount = pqd * taxes.reduce((taxAcc, tax) => taxAcc + tax / 100, 0)

            return acc + taxAmount
        }, 0)
    }

    calculateFinalTotalAmount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.amount) || 0), 0)
    }

    _setBillDate(data) {
        this._handleChange('bill_date', data)
    }
    _setDueDate(data) {
        this._handleChange('due_date', data)
    }

    _selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }
    _setProduct(data, i) {
        this.setState((prevState) => {
            const productElements = [...prevState.productElements]
            productElements[i].defaultProduct = data
            productElements[i].item = data.value
            productElements[i].amount = this.calculateTotalAmount(productElements[i])

            const productErrors = [...prevState.productErrors]
            productErrors[i].item = null

            return {
                productElements: productElements,
                productErrors: productErrors,
            }
        })
    }

    _setQuantity(data, i) {
        this.setState((prevState) => {
            const productElements = [...prevState.productElements]
            productElements[i].quantity = data
            productElements[i].amount = this.calculateTotalAmount(productElements[i])
            const productErrors = [...this.state.productErrors]
            productErrors[i].quantity = null
            return {
                productElements: productElements,
                productErrors,
            }
        })
    }

    _setPrice(data, i) {
        this.setState((prevState) => {
            const productElements = [...prevState.productElements]
            productElements[i].price = data
            productElements[i].amount = this.calculateTotalAmount(productElements[i])

            const productErrors = [...prevState.productErrors]
            productErrors[i].price = null

            return {
                productElements: productElements,
                productErrors: productErrors,
            }
        })
    }

    _setDiscount(data, i) {
        this.setState((prevState) => {
            const productElements = [...prevState.productElements]
            productElements[i].discount = data
            productElements[i].amount = this.calculateTotalAmount(productElements[i])

            return {
                productElements: productElements,
            }
        })
    }

    _setDescription(data, i) {
        const productElements = this.state.productElements
        productElements[i].description = data
        this.setState({
            productElements: productElements,
        })
    }

    deleteModal() {
        const { t } = this.props
        const productElements = this.state.productElements
        const modal = productElements.map((item, index) => {
            return (
                <div
                    key={index}
                    id={'modal_delete_' + item.id}
                    data-id={item.id}
                    className="modal fade show"
                    role="dialog"
                >
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to delete the particular?</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button
                                    onClick={(e) => this.onDelete(e, item.id, index)}
                                    type="submit"
                                    text={t('delete')}
                                    main={true}
                                />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    loadExpenseProductService() {
        const { t } = this.props
        const productElements = this.state.productElements
        const data = productElements.map((item, index) => {
            return (
                <>
                    <tr>
                        <td className="w-25">
                            <Select
                                className="mb-3"
                                name="productList"
                                options={this.state.products}
                                value={this.state.productElements[index].defaultProduct}
                                placeholder={t('income:selectproduct')}
                                onChange={(data) => this._setProduct(data, index)}
                                searchable={true}
                            />
                            {this.state.productErrors[index].item && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].item}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                min={0}
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:quantity')}
                                value={this.state.productElements[index].quantity}
                                onChange={(key, val) => this._setQuantity(val, index)}
                            />
                            {this.state.productErrors[index].quantity && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].quantity}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:price')}
                                value={this.state.productElements[index].price}
                                onChange={(key, val) => this._setPrice(val, index)}
                            />

                            {this.state.productErrors[index].price && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].price}
                                </label>
                            )}
                        </td>

                        <td>
                            <Input
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:discount')}
                                value={this.state.productElements[index].discount}
                                onChange={(key, val) => this._setDiscount(val, index)}
                            />
                        </td>

                        <td>
                            {item.defaultProduct &&
                                item.defaultProduct.selectedTax.map((item, i) => (
                                    <div key={i}>
                                        <span className="bg-light">
                                            {item.label} - {item.rate}
                                        </span>
                                    </div>
                                ))}
                        </td>
                        <td>
                            <small>{this.props.school.currency}</small> &nbsp;{' '}
                            {this.state.productElements[index].amount}
                        </td>
                        <td>
                            <div className="d-flex align-items-center mb-3">
                                <Button
                                    onClick={() => this.addRow()}
                                    type="button"
                                    text={SVG.addTable()}
                                    main={false}
                                />
                                {item.id == '' ? (
                                    <Button
                                        onClick={() => this.removeRow(index)}
                                        type="button"
                                        text={SVG.deleteComment()}
                                        main={false}
                                    />
                                ) : (
                                    <Button
                                        modal={'modal_delete_' + item.id}
                                        type="button"
                                        text={SVG.deleteComment()}
                                        main={false}
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <TextArea
                                value={this.state.productElements[index].description}
                                onChange={(key, val) => this._setDescription(val, index)}
                                placeholder={t('common:description')}
                            />
                        </td>
                        <td colspan="5"></td>
                    </tr>
                </>
            )
        })
        return data
    }

    publishModal() {
        const { t } = this.props
        return (
            <div id="modal_publish" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('expense:publishBill')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button
                                onClick={(e) => {
                                    this.onSavePublish(e)
                                    $('#close_publish').trigger('click')
                                }}
                                type="button"
                                text={t('common:modal confirm')}
                                main={true}
                            />
                            <button id="close_publish" type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        const totalPrices = this.calculateTotalPrice(this.state.productElements)
        const totalDiscount = this.calculateTotalDiscount(this.state.productElements)
        const totalAmount = this.calculateFinalTotalAmount(this.state.productElements)
        const totalTaxAmount = this.calculateTotalTaxAmount(this.state.productElements)
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="expense" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.expense.menu}>
                                    {t('accounting:expense')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.expense.bill.view}>
                                    {t('accounting:bill')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('expense:editbill')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('income:customer')} *</label>
                                        <Select
                                            name="userList"
                                            options={this.state.userLists}
                                            value={this.state.defaultUserList}
                                            placeholder={t('income:selectcustomer')}
                                            onChange={(data) => this._selectList('UserList', data)}
                                            searchable={true}
                                        />

                                        {this.state.errors.selectedUserList && (
                                            <label className="table-error error text-danger">
                                                {this.state.errors.selectedUserList}
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title text-left">{t('expense:billdate')} *</label>
                                    <DatePicker
                                        placeholderText={t('expense:selectbilldate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        selected={this.state.bill_date ? new Date(this.state.bill_date) : null}
                                        onChange={this._setBillDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.bill_date != ''
                                                    ? convertADtoBS(this.state.bill_date) != null
                                                        ? convertADtoBS(this.state.bill_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}

                                    {this.state.errors.bill_date && (
                                        <label className="table-error error text-danger">
                                            {this.state.errors.bill_date}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title text-left">{t('income:duedate')} *</label>
                                    <DatePicker
                                        placeholderText={t('income:selectduedate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        selected={this.state.due_date ? new Date(this.state.due_date) : null}
                                        onChange={this._setDueDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.due_date != ''
                                                    ? convertADtoBS(this.state.due_date) != null
                                                        ? convertADtoBS(this.state.due_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}
                                    {this.state.errors.due_date && (
                                        <label className="table-error error text-danger">
                                            {this.state.errors.due_date}
                                        </label>
                                    )}
                                </div>
                                {/* <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('expense:billnumber')} </label>
                                            <Input name="code" type="text"  placeholder={t('expense:billnumber')} value="#0101110" />
                                    </div> */}
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('income:categorytype')} *</label>
                                        <Select
                                            name="selectedCategory"
                                            options={this.state.category}
                                            placeholder="Selects Category"
                                            value={this.state.defaultCategory}
                                            searchable={true}
                                            onChange={(data) => this._selectList('Category', data)}
                                        />
                                        {this.state.errors.selectedCategory && (
                                            <label className="table-error error text-danger">
                                                {this.state.errors.selectedCategory}
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:reference')} </label>
                                    <Input
                                        name="order_number"
                                        onChange={this._handleChange}
                                        type="text"
                                        value={this.state.order_number}
                                        placeholder={t('income:reference')}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <>
                        <hr />
                        <div className="fee-title-btn">
                            <h6>{t('doubleentry:particular')}:</h6>
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                        </div>
                        <hr />
                        <div className="main-table">
                            <div className="table-all table-responsive">
                                <table className="table responsiveTable fee_table">
                                    <thead>
                                        <tr>
                                            <th>{t('income:items')} *</th>
                                            <th>{t('income:quantity')} *</th>
                                            <th>{t('income:price')} *</th>
                                            <th>{t('fee:discount')}</th>
                                            <th>{t('income:tax')}</th>
                                            <th className="text-end">
                                                {t('income:amount')}
                                                <br />
                                                <span className="text-danger smallfont">
                                                    {t('income:beforetaxdiscount')}
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.loadExpenseProductService()}</tbody>
                                    {this.state.productElements.length != 0 && (
                                        <tfoot className="tfootstrong">
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <strong>{t('fee:subTotal')} </strong>
                                                </td>

                                                <td class="text-end subTotal">
                                                    <small>{this.props.school.currency}</small> &nbsp;{' '}
                                                    {totalPrices.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('fee:discount')} </strong>
                                                </td>
                                                <td class="text-end totalDiscount">
                                                    <small>{this.props.school.currency}</small> &nbsp;{' '}
                                                    {totalDiscount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="blue-text">
                                                    <strong>{t('accounting:total')} </strong>
                                                </td>
                                                <td class="text-end totalAmount blue-text">
                                                    <small>{this.props.school.currency}</small> &nbsp;{' '}
                                                    {totalAmount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('income:tax')} </strong>
                                                </td>
                                                <td class="text-end totalTax">
                                                    <small>{this.props.school.currency}</small> &nbsp;{' '}
                                                    {totalTaxAmount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                                {this.state.productElements.length == 0 && <p className="no-data"> {SVG.nodata()}</p>}
                            </div>
                        </div>
                    </>
                    <div className="form-button text-left">
                        <Button onClick={this.onSaveDraft} type="submit" text="Update" main={false} />
                        <Button modal="modal_publish" type="button" text="Publish" main={true} />
                        {this.publishModal()}
                    </div>
                </div>
                <Fragment>{this.deleteModal()}</Fragment>
            </div>
        )
    }
}

const BillEdit = withTranslation()(connect(mapStateToProps)(BillEditComponent))
export { BillEdit }
