import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import {
    viewBookShelf,
    viewBookShelves,
    detailBookIssue,
    getSchoolUser,
    editBookIssue,
    updateBookIssue,
    viewBooks,
} from '~/services/api'
import { issue_status } from '~/helper/constant/books.js'
import * as UserType from '~/helper/constant/user_type'
import moment from 'moment'

export class AbstractBookIssueEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            issueDate: '',
            dueDate: '',
            returnDate: '',
            loading: false,
            loading_text: 'loading',
            bookShelves: [],
            bookShelf: '',
            book: '',
            bookId: '',
            issueStatus: '',
            defaultIssueStatus: '',
            selectedIssueStatus: '',
            name: '',
            users: [],
            defaultUser: '',
            selectedUser: '',
            userType: UserType,
            fineAmount: '',
            errors: {
                selectedUser: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadUser()
    }

    loadUser() {
        if (this.props.school.user_type !== UserType.Student && this.props.school.user_type !== UserType.Parents) {
            this._handleChange('loading', true)
            getSchoolUser(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let users = []
                if (response.code === API_SUCCESS) {
                    response.data.users.map((item) => {
                        if (item.user_type != UserType.Parents) {
                            users.push({
                                value: item.id,
                                label:
                                    item.user.name != null
                                        ? item.user.name + ' - ' + item.user.reg_no
                                        : item.user.reg_no,
                                type: item.user_type,
                            })
                        }
                    })
                    this.setState(
                        {
                            users: users,
                        },
                        () => {
                            this.loadBookShelf()
                        }
                    )
                }
            })
        }
    }

    loadBookShelf() {
        this._handleChange('loading_text', 'loading')
        this._handleChange('loading', true)
        viewBookShelf(this.props.token).then((response) => {
            console.log('bookshelf', response)
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookShelves = response.data.shelf.map((item) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        bookShelves,
                    },
                    () => this.loadBookIssue()
                )
            }
        })
    }

    loadBookIssue() {
        this._handleChange('loading_text', 'loading')
        this._handleChange('loading', true)
        detailBookIssue(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                console.log('book issue', response.data)
                const item = response.data.bookIssued
                // const defaultBook = this.state.books.find((book)=>book.value==parseInt(item.book_shelf.book_id))

                // const book =  this.state.bookObject.find(shelf => shelf.id === defaultBook.value)
                // const filterList = book.book_shelf.map(shelve=> {
                //     return {
                //     label: shelve.shelves.name,
                //     value: shelve.shelves.id
                //     }
                // })
                const bookShelf = this.state.bookShelves.find(
                    (shelf) => shelf.value == parseInt(item.book_shelf.shelves_id)
                )
                const defaultUser = this.state.users.find((user) => user.value == parseInt(item.user_id))
                console.log(defaultUser)

                // const defaultIssueStatus = this.state.issueStatus.find((issue) => issue.value == parseInt(item.issue_status))
                this.setState({
                    // defaultBook,
                    // selectedBook: defaultBook != null ? defaultBook.value: null,
                    book: item.book_shelf.books.title,
                    bookId: item.book_shelf.book_id,
                    defaultUser,
                    selectedUser: defaultUser != null ? defaultUser.value : null,
                    bookShelf: bookShelf.label,
                    issueStatus: item.issue_status,
                    // defaultIssueStatus,
                    // selectedIssueStatus: defaultIssueStatus != null ? defaultIssueStatus.value : null,
                    issueDate: item.issue_date,
                    dueDate: item.due_date,
                    // returnDate:item.return_date,
                    // fineAmount: item.fine_amount
                })
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                book_shelves_id: this.state.bookId,
                user_id: this.state.selectedUser,
                issue_date: moment(this.state.issueDate).format('yyyy-MM-DD'),
                due_date: moment(this.state.dueDate).format('yyyy-MM-DD'),
            }
            this._handleChange('Loading', true)
            updateBookIssue(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('Loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.library.bookissue.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
        school: state.school,
        user: state.user,
    }
}
