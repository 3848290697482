import { get, post } from '../request'

export const viewExam = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addExam = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/exam/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editExam = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/exam/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleExamPost = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getRoutine = (id, classId, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/routine/view/' + id + '/' + classId, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAllRoutine = (id, classId, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/routine/class/' + id + '/' + classId, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addRoutine = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/exam/routine/add/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteRoutine = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/routine/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getOnlineExam = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/online/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getUserProgress = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/online-exam/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getUserProgressByQuiz = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/online-exam/course-quiz-id/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getUserProgressByExam = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/exam/online-exam/exam-id/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveUserProgress = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/exam/online-exam/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
