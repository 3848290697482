import React, { Component } from 'react'
import moment from 'moment'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { viewBookShelf, viewBookShelves, getSchoolUser, addBookIssue, viewBooks} from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import {issue_status} from '~/helper/constant/books.js'
import * as UserType from '~/helper/constant/user_type'

export class AbstractBookIssueAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            issueDate: '',
            dueDate:'',
            returnDate:null,
            loading: false,
            loading_text: "loading",
            bookShelves: [],
            defaultBookShelves: '',
            selectedBookShelves: '',
            issueStatus: issue_status,
            defaultIssueStatus: '',
            selectedIssueStatus: '',
            name: '',
            bookObject:[],
            users:[],
            books:[],
            selectedBook:'',
            defaultBook:'',
            defaultUser:'',
            selectedUser:'',
            userType: UserType,
            shelflist:[],
            fineAmount: null,
            errors: {
                selectedBookShelves: null,
                selectedUser: null,
                issueDate: null,
                dueDate:null,
                selectedBook:null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }
    componentDidMount(){
        this.loadUser()
        this.filterBookShelf()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadUser() {
        if (this.props.school.user_type !== UserType.Student && this.props.school.user_type !== UserType.Parents) {
            this._handleChange('loading', true);
            getSchoolUser(this.props.token).then((response) => {
                this._handleChange('loading', false);
                let users = [];
                if (response.code === API_SUCCESS) {
                    response.data.users.map((item) => {
                        if (item.user_type != UserType.Parents) {
                            users.push({
                                value: item.id,
                                label: item.user.name != null ? item.user.name + ' - ' + item.user.reg_no : item.user.reg_no,
                                type: item.user_type,
                            });
                        }

                    });
                    this.setState({
                        users: users,
                    },
                    ()=>{
                        this.loadBooks()

                    });
                } 
            });
        }
    }
    loadBooks() {
        this._handleChange('loading_text', 'Loading');
        this._handleChange('loading', true);
        viewBooks(this.props.token).then((response) => {
          this._handleChange('loading', false);
          if (response.code === API_SUCCESS) {
            const bookObject = response.data.book;
            const books = bookObject.map((item, index) => {
                console.log("book object", item)
              return {
                label:item.title,
                value: item.id
              }
             
            });
      
            this.setState({
              books,
              bookObject
            })
          }
        });
    }

    filterBookShelf(){
        this.state.bookObject.filter(item=>item.id ==this.state.selectedBook)
        .map(shelves=>shelves.book_shelf)
    }


    // loadBookShelf(){
    //     this._handleChange('loading_text', "loading")
    //     this._handleChange("loading", true)
    //     viewBookShelves(this.props.token).then((response)=>{
    //         console.log("bookshelf", response)
    //         this._handleChange("loading", false)
    //         if(response.code == API_SUCCESS){
    //             const bookShelves = response.data.shelf.map((item)=>{
    //                 return {
    //                     label:item.name + '-' + item.code,
    //                     value: item.id
    //                 }
    //             })
    //             this.setState({
    //                 bookShelves
    //             })
    //         }
    //     })

    // }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                book_shelves_id: this.state.selectedBookShelves,
                user_id: this.state.selectedUser,
                issue_date: moment(this.state.issueDate).format('yyyy-MM-DD'),
                due_date: moment(this.state.dueDate).format('yyyy-MM-DD'),
                // fine_amount: this.state.fineAmount,
                issued_by: this.props.user.id,
                // issue_status: this.state.selectedIssueStatus
            }
            
            
            this._handleChange('loading', true)
            addBookIssue(data, this.props.token).then((response) => {
                this._handleChange('Loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.library.bookissue.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
        school: state.school,
        user:state.user
    }
}
