import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Input } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { circle } from '~/helper/constant/color'
import * as UserType from '~/helper/constant/user_type'
import { AbstractMyCircleComponent, mapStateToProps } from './abstract'

const list = [
    { label: 'List', value: 1 },
    { label: 'Grid', value: 0 },
]
class MyCircleComponent extends AbstractMyCircleComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.loadStudent = this.loadStudent.bind(this)
        this.loadEmployee = this.loadEmployee.bind(this)
        this._selectView = this._selectView.bind(this)
        this.loadGrid = this.loadGrid.bind(this)
    }

    _selectView(data) {
        this._handleChange('list', data)
    }

    loadStudent() {
        const { t } = this.props
        let i = -1
        if (this.state.student.length > 0) {
            const data = this.state.student.map((item, index) => {
                const name = item.user.name != null ? item.user.name : item.user.reg_no
                const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                i++
                if (i == 3) {
                    i = 0
                }
                return (
                    <div key={index} className="circle-category-list">
                        <div className={'user-img ' + circle[i]}>
                            {profile != null && <img src={profile} alt="post-user" />}
                            {profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                        </div>
                        <div className="circle-user">
                            <h5>{name}</h5>
                            <span>{item.user.reg_no}</span>
                        </div>
                        <button onClick={() => this.openChatRoom(item.id)} className="message friend-message">
                            {t('circle:message')}
                        </button>
                    </div>
                )
            })
            return data
        } else {
            return (
                <div className="pb-4">
                    <span className="badge table-red remarks-status">
                        {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <Fragment>{t('circle:noFriendsFound')}</Fragment>}
                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <Fragment>{t('circle:noStudentsFound')}</Fragment>}
                    </span>
                </div>
            )
        }
    }

    loadEmployee() {
        const { t } = this.props
        let i = -1
        if (this.state.employee.length > 0) {
            const data = this.state.employee.map((item, index) => {
                const name = item.user.name != null ? item.user.name : item.user.reg_no
                const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                i++
                if (i == 3) {
                    i = 0
                }
                return (
                    <div key={index} className="circle-category-list">
                        <div className={'user-img ' + circle[i]}>
                            {profile != null && <img src={profile} alt="post-user" />}
                            {profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                        </div>
                        <div className="circle-user">
                            <h5>{name}</h5>
                            <span>{item.user.reg_no}</span>
                        </div>
                        <button onClick={() => this.openChatRoom(item.id)} className="message friend-message">
                            {t('circle:message')}
                        </button>
                    </div>
                )
            })
            return data
        } else {
            return (
                <div className="pb-4">
                    <span className="badge table-red remarks-status">
                        {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <Fragment>{t('circle:noTeachersFound')}</Fragment>}
                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <Fragment>{t('circle:noColleaguesFound')}</Fragment>}
                    </span>
                </div>
            )
        }
    }

    loadGrid() {
        const { t } = this.props
        const users = this.state.student.concat(this.state.employee)
        const student_label = this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents ? t('circle:friend') : t('circle:student')
        const teacher_label = this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents ? t('timetable:teacher') : t('chat:colleague')
        let i = -1
        if (users.length > 0) {
            const data = users.map((item, index) => {
                const name = item.user.name != null ? item.user.name : item.user.reg_no
                const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                i++
                if (i == 3) {
                    i = 0
                }
                return (
                    <div className="my-circle-list">
                        <div className={'circle-user-img ' + circle[i]}>
                            {profile != null && <img src={profile} alt="post-user" />}
                            {profile == null && <p className="name-letter">{name.charAt(0)}</p>}
                        </div>
                        <h4>{name}</h4>
                        <span className="circle-designation">
                            {(item.user_type == UserType.Student || item.user_type == UserType.Parents) && <Fragment>{student_label}</Fragment>}
                            {item.user_type != UserType.Student && item.user_type != UserType.Parents && <Fragment>{teacher_label}</Fragment>}
                        </span>
                        <div className="circle-info">
                            <span className="clearfix text-center">
                                <b>{item.user.reg_no}</b>
                            </span>
                        </div>
                        <div className="clearfix circle-button text-center">
                            <button className="second-button" onClick={() => this.openChatRoom(item.id)}>
                                {t('circle:message')}
                            </button>
                        </div>
                    </div>
                )
            })
            return data
        } else {
            return (
                <div className="col-md-12">
                    <span className="badge table-red remarks-status">
                        {t('common:noBtn')} {student_label}s / {teacher_label}s {t('common:found')}{' '}
                    </span>
                </div>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="my_circle" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>{t('circle:myCircle')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-lg-5 float-left">
                                <h5 className="main-title heading5">{t('circle:myCircle')}:</h5>
                            </div>
                            <div className="col-lg-7">
                                <div className="display-inline-block  col-md-8 col-lg-10">
                                    <form className="main-search float-right">
                                        <Input name="search" onChange={this._search} placeholder={t('common:searchBtn')} type="text" value={this.state.search} />
                                        <div className="search-icon">{SVG.tablesearch()}</div>
                                    </form>
                                </div>
                                <div className="float-right display-inline-block col-md-4 col-lg-2">
                                    <Select placeholder={t('common:viewBtn')} options={list} onChange={this._selectView} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        {this.state.list.label == 'List' && (
                            <Fragment>
                                <div className="col-lg-6 float-left">
                                    <div className="circle-role">
                                        {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <h4>{t('circle:myFriends')}</h4>}
                                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <h4>{t('circle:myStudents')}</h4>}
                                        <div className="circle-category">{this.loadStudent()}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 float-left">
                                    <div className="circle-role">
                                        {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <h4>{t('circle:myTeachers')}</h4>}
                                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <h4>{t('circle:myColleagues')}</h4>}
                                        <div className="circle-category">{this.loadEmployee()}</div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {this.state.list.label == 'Grid' && <div className="my-circle clearfix">{this.loadGrid()}</div>}
                    </div>
                </div>
            </div>
        )
    }
}

const MyCircle = withTranslation()(connect(mapStateToProps)(MyCircleComponent))

export { MyCircle }
