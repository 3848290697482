import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getHoliday = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/holiday/view', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addHoliday = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('calendar-management/holiday/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleHoliday = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/holiday/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
