import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import * as route from '../../../../../../helper/constant/route'
import { getJournalId } from '../../../../../../helper/function/abstract'
import { viewJournalAccount } from '../../../../../../services/api/accounting/doubleentry'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractJournalAccountComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            tableData: [
                {
                    invoice: {
                        field: 'button',
                        route: true,
                        sortable: true,
                        translate: 'income:invoice',
                    },
                    date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'header:date',
                    },
                    amount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'income:amount',
                    },
                    description: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:description',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadJournalVoucher()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadJournalVoucher() {
        this._handleChange('loading', true)
        viewJournalAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                console.log(response)
                const tableData = [this.state.tableData[0]]
                response.data.journalEntries.map((item) => {
                    let amount = 0
                    item.accounts.map((amt) => {
                        amount = amount + amt.credit
                    })
                    const data = {
                        invoice: {
                            text: getJournalId(item.id),
                            route: route.admin.accounting.doubleentry.journalaccount.invoice + '/' + item.id,
                        },
                        date: {
                            text: item.date,
                        },
                        amount: {
                            text: this.props.school.currency + ' ' + amount,
                        },
                        description: {
                            text: item.description,
                        },
                    }
                    tableData.push(data)
                })
                this.setState({
                    tableData,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
