import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { display_mode, font_size, title_position } from '~/helper/constant/reportcard'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { addReportCard, getActiveGrading } from '~/services/api'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}
type State = {
    loading: Boolean,
    loading_text: String,
    title: String,
    code: String,
    logo: Object,
    background: Object,
    logoPosition: String,
    titlePosition: String,
    displayMode: integer,
    grades: Array,
    errors: Object,
}

export class AbstractReportCardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            title: '',
            code: '',
            color: '#000000',
            fontSize: font_size,
            defaultFontSize: font_size[0],
            selectedFontSize: font_size[0].value,
            logoAry: [],
            logo: {},
            backgroundAry: [],
            border: {},
            logoUploadedFiles: [],
            bgUploadedFiles: [],
            logoPosition: title_position,
            defaultLogoPosition: title_position[0],
            selectedLogoPosition: title_position[0].value,
            titlePosition: title_position,
            defaultTitlePosition: title_position[0],
            selectedTitlePosition: title_position[0].value,
            displayMode: display_mode,
            defaultDisplayMode: display_mode[0],
            selectedDisplayMode: display_mode[0].value,
            status: true,
            grades: [],
            defaultGrading: '',
            selectedGrading: '',
            note: '',
            errors: {
                title: null,
                code: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.OnSave = this.OnSave.bind(this)
    }
    componentDidMount() {
        this.loadGrading()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadGrading() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getActiveGrading(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let grades = []
            let defaultGrading = ''
            let selectedGrading = ''
            if (response.code == API_SUCCESS) {
                response.data.grading.map((item, index) => {
                    const data = {
                        label: item.title + '-' + item.code,
                        value: item.id,
                    }
                    grades.push(data)
                    if (index == 0) {
                        defaultGrading = data
                        selectedGrading = item.id
                    }
                })
                this.setState({
                    grades,
                    defaultGrading,
                    selectedGrading,
                })
            }
        })
    }

    OnSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        if(JSON.stringify(this.state.logo) === '{}'){
            errors['logo'] = 'Required'
        }
        if(JSON.stringify(this.state.border) === '{}'){
            errors['border'] = 'Required'
        }
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                code: this.state.code,
                logo: this.state.logo,
                border: this.state.border,
                logo_position: this.state.selectedLogoPosition,
                title_position: this.state.selectedTitlePosition,
                display_mode: this.state.selectedDisplayMode,
                grading_id: this.state.selectedGrading,
                status: this.state.status,
                color: this.state.color,
                font_size: this.state.selectedFontSize,
                note: this.state.note,
            }

            this._handleChange('loading', true)
            addReportCard(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.setting.reportcard.view)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
