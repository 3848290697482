import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentSearchStatementComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'
import * as route from '~/helper/constant/route'
import { convertADtoBS } from '../../../../helper/function'

class StudentSearchStatementComponent extends AbstractStudentSearchStatementComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._startDate = this._startDate.bind(this)
        this._endDate = this._endDate.bind(this)
    }

    _startDate(data) {
        this._handleChange('from_date', data)
    }

    _endDate(data) {
        this._handleChange('to_date', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="fee" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.student_fee.menu}>{t('fee:fee')} > </a>
                                </li>
                                <li>
                                    {t('common:searchBtn')} {t('fee:invoice')}
                                </li>
                            </ul>
                            <div className="col-md-4 float-left">
                                <h5 className="main-title">
                                    {t('common:searchBtn')} {t('fee:invoice')}:
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 float-left form-group">
                            <label className="form-title text-left">{t('academic:add.startdate-label')} *</label>
                            <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._startDate} selected={this.state.from_date} placeholderText={t('academic:add.startdate-placeholder')} />
                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs small-bs">{this.state.from_date != '' ? (convertADtoBS(this.state.from_date) != null ? convertADtoBS(this.state.from_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                            {this.state.errors.from_date && <label className="error text-danger setup-error text-left">{this.state.errors.from_date}</label>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 float-left form-group">
                            <label className="form-title text-left">{t('academic:add.enddate-label')} *</label>
                            <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._endDate} selected={this.state.to_date} placeholderText={t('academic:add.enddate-placeholder')} />
                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs small-bs">{this.state.to_date != '' ? (convertADtoBS(this.state.to_date) != null ? convertADtoBS(this.state.to_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                            {this.state.errors.to_date && <label className="error text-danger setup-error  text-left">{this.state.errors.to_date}</label>}
                        </div>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.viewInvoice} type="button" text={t('common:searchBtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const StudentSearchStatement = withTranslation()(connect(mapStateToProps)(StudentSearchStatementComponent))

export { StudentSearchStatement }
