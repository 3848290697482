import {Component} from 'react'
import {Dispath} from 'react-redux'
import {category_type} from '~/helper/constant/category'



type Props = {
    dispatch: Dispath<any>
}
type State = {}

export class AbstractConstantContractTypeAddComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            category_list: category_type,
            color: '#000000',
        }
    }
    compnentDidMount(){}


}

export function mapStateToProps(state:Object){
    return {
        token: state.token,
    }
}