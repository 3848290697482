export const fullscreen_open = () => {
    return {
        type: 'FULLSCREEN_OPEN',
    }
}

export const fullscreen_close = () => {
    return {
        type: 'FULL_SCREEN_CLOSE',
    }
}
