import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewStudentAttendance = (date, attendance_type, section_id, subject_id, token) => {
    let url = 'student-management/student-attendance/' + attendance_type + '/' + date + '/' + section_id + '/' + subject_id
    if (subject_id == null) {
        url = 'student-management/student-attendance/' + attendance_type + '/' + date + '/' + section_id
    }
    return new Promise(function (resolve, reject) {
        try {
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewEmployeeAttendance = (date, user_type, role_id, token) => {
    let url = 'employee-management/employee-attendance/attendance/' + date + '/' + user_type + '/' + role_id
    if (role_id == null) {
        url = 'employee-management/employee-attendance/attendance/' + date + '/' + user_type
    }
    return new Promise(function (resolve, reject) {
        try {
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addStudentAttendance = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student-attendance/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateStudentAttendance = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student-attendance/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addEmployeeAttendance = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee-attendance/attendance/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateEmployeeAttendance = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee-attendance/attendance/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
