import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { viewAccountTax, viewProductCategory, viewProductService } from '~/services/api'
import { FEE } from '../../../../../../helper/constant/bill_type'
import { CATEGORY_INVOICE } from '../../../../../../helper/constant/category'
import { addTemplate } from '../../../../../../services/api/admin/bill_template'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractInvoiceTemplateAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            categoryList: [],
            title: null,
            category: null,
            customers: [],
            taxes: [],
            defaultTax: [],
            selectedTax: [],
            products: [],
            productElements: [],
            productErrors: [],
            errors: {
                title: null,
                category: null,
                customers: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadTax()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadTax() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAccountTax(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const taxes = response.data.taxes.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                        rate: item.rate,
                        index: index,
                    }
                })
                this.setState(
                    {
                        taxes,
                    },
                    () => {
                        this.loadProductCategory()
                    }
                )
            }
        })
    }

    loadProductCategory() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewProductCategory(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const filteredcategory = response.data.categories.filter((item) => item.type == CATEGORY_INVOICE)
                const categoryList = filteredcategory.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        categoryList,
                    },
                    () => {
                        this.loadProducts()
                    }
                )
            }
        })
    }

    loadProducts() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewProductService(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const products = response.data.productServices
                    .filter((item) => item.type == 'Product')
                    .map((item, index) => {
                        const taxIds = item.tax_id.split(',').map((id) => parseInt(id.trim()))
                        // Find tax names from the loaded taxes
                        const selectedTax = this.state.taxes.filter((tax) => taxIds.includes(tax.value))
                        // const taxLabels = relatedTaxes.map(tax => tax.label);

                        return {
                            label: item.name,
                            value: item.id,
                            selectedTax,
                        }
                    })
                this.setState({
                    products,
                })
            }
        })
    }

    onSave() {
        let hasError = false
        const productElements = this.state.productElements
        const productErrors = this.state.productErrors
        productElements.map((el, i) => {
            if (el.item == '') {
                productErrors[i].item = 'Required'
                hasError = true
            }
            if (el.quantity == '') {
                productErrors[i].quantity = 'Required'
                hasError = true
            }
            if (el.price == '') {
                productErrors[i].price = 'Required'
                hasError = true
            }
        })
        this.setState({
            productErrors,
        })

        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            const data = {
                type: FEE,
                title: this.state.title,
                users: this.state.customers,
                account_category_id: this.state.category.value,
                particulars: productElements,
            }
            addTemplate(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.income.template.view)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accountingToken: state.school.accounting_token,
        school: state.school.school,
    }
}
