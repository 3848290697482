import $ from 'jquery'
import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { activeClass, activeReportCard, viewResult, addExam, editExam, toggleExamPost, viewExam } from '~/services/api'

type Props = {
    token: Object,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    tableData: Array,
    exam: Array,
    title: String,
    description: String,
    from_date: String,
    to_date: String,
    classes: Array,
    selectedClass: Array,
    errors: Object,
    remarks: Integer,
    additional_information: Integer,
    all: Boolean,
    reportCards:Array,
}

export class AbstractExamViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            all: false,
            loading: false,
            loading_text: 'Loading',
            title: '',
            description: '',
            from_date: '',
            to_date: '',
            classes: [],
            selectedClass: [],
            reportCards: [],
            defaultReportCard: [],
            selectedReportCard: [],
            selectedReportCardTitle:'',
            errors: {
                title: null,
                description: null,
                from_date: null,
                to_date: null,
                selectedClass: null,
                additional_information: null,
                remarks: null,
            },
            exam: [],
            tableData:
                this.props.school.user_type == UserType.Admin
                    ? [
                          {
                              exam: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'exam:exam.categoryTitle',
                              },
                              class: {
                                  field: 'badge',
                                  route: false,
                                  sortable: false,
                                  translate: 'exam:exam.routine.class',
                              },
                              from_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'fromdate',
                              },
                              to_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'todate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  sortable: true,
                                  translate: 'status',
                              },
                              routine: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'exam:result.routine',
                              },
                              edit: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'EditBtn',
                              },
                          },
                      ]
                    : [
                          {
                              exam: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'sidebar:exam',
                              },
                              class: {
                                  field: 'badge',
                                  route: false,
                                  sortable: false,
                                  translate: 'exam:exam.routine.class',
                              },
                              from_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'fromdate',
                              },
                              to_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'todate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  sortable: true,
                                  translate: 'status',
                              },
                              routine: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'exam:exam.routine.routine',
                              },
                          },
                      ],
        }
        this._handleChange = this._handleChange.bind(this)
        this._save = this._save.bind(this)
        this.loadExam = this.loadExam.bind(this)
        this.toggle = this.toggle.bind(this)
        this._edit = this._edit.bind(this)
    }

    componentDidMount() {
        this.loadClass()
        this.clearState()
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_status_' + link).trigger('click')
        toggleExamPost(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadExam()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }



    loadReportCard() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeReportCard(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let reportCards = []
            let selectedReportCard = ''
            let selectedReportCardTitle= ''
            if (response.code == API_SUCCESS) {
                response.data.reportCard.map((item, index) => {
                    const data = {
                        label: item.title + '-' + item.code,
                        value: item.id,
                    }
                    reportCards.push(data)
                    if (index == 0) {
                        selectedReportCard = item.id
                        selectedReportCardTitle = item.title
                    }
                })
                this.setState(
                    {
                        reportCards,
                        selectedReportCard,
                        selectedReportCardTitle
                    },
                    () => {
                        this.loadExam()
                    }
                )
            }
        })
    }

    loadClass() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        if (this.props.school.user_type == UserType.Parents) {
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let classes = []
                let section = response.data.section
                if (response.code == API_SUCCESS) {
                    response.data.class.map((item, index) => {
                        const data = {
                            label: item.name + ' - ' + item.code,
                            value: item.id,
                        }
                        classes.push(data)
                    })
                    this.setState(
                        {
                            classes,
                            section,
                        },
                        () => {
                            this.loadReportCard()
                        }
                    )
                }
            })
        } else {
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let classes = []
                if (response.code == API_SUCCESS) {
                    response.data.class.map((item, index) => {
                        const data = {
                            label: item.name + ' - ' + item.code,
                            value: item.id,
                        }
                        classes.push(data)
                    })
                    this.setState(
                        {
                            classes,
                        },
                        () => {
                            this.loadReportCard()
                        }
                    )
                }
            })
        }
    }

    loadExam() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewExam(this.props.token).then((response) => {
            console.log("viewExam", response)
            this._handleChange('loading', false)
            let tableData = []
            tableData.push(this.state.tableData[0])
            let exam = []
            if (response.code == API_SUCCESS) {
                response.data.post.map((item, index) => {
                    let status = {
                        text: 'Draft',

                        badge: 'table-red',
                        modal: 'modal_status_' + item.id,
                        translate: 'exam:draft',
                    }
                    if (item.status == Status.ACTIVE) {
                        status = {
                            text: 'Published',
                            translate: 'exam:published',
                            badge: 'table-green',
                            modal: 'modal_status_' + item.id,
                        }
                    }
                    let access_data = []
                    item.class_access_information.map((classes) => {
                        access_data.push(classes.name + ' - ' + classes.code)
                    })
                    const data = {
                        exam: {
                            text: item.title,
                        },
                        class: {
                            text: access_data,
                            badge: 'table-grey',
                        },
                        from_date: {
                            text: item.from_date,
                        },
                        to_date: {
                            text: item.to_date,
                        },
                        status,
                    }
                    if (this.props.school.user_type == UserType.Student) {
                        data['routine'] = {
                            text: 'Routine',
                            route:
                                route.examination.exam.routine.schedule +
                                '/' +
                                item.id +
                                '/' +
                                this.props.school.active_user_academics.section.class_id,
                            translate: 'exam:result.routine',
                        }
                    } else if (this.props.school.user_type == UserType.Parents) {
                        data['routine'] = {
                            text: 'Routine',
                            route:
                                route.examination.exam.routine.schedule +
                                '/' +
                                item.id +
                                '/' +
                                this.state.section.classes.id,
                            translate: 'exam:result.routine',
                        }
                    } else {
                        data['routine'] = {
                            text: 'Routine',
                            route: route.examination.exam.routine.menu + '/' + item.id,
                            translate: 'exam:result.routine',
                        }
                    }
                    if (this.props.school.user_type == UserType.Admin) {
                        data['edit'] = {
                            text: 'Edit',
                            modal: 'modal_edit_' + item.id,
                            translate: 'EditBtn',
                        }
                    }
                    exam.push(item)
                    tableData.push(data)

                })
                this.setState(
                    {
                        exam,
                        tableData,
                        
                    },
                    () => {
                        this.clearState()
                    }
                )
            }
        })
    }

    _save(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const from_date = moment(this.state.from_date, 'yyyy-MM-DD')
            const end_date = moment(this.state.to_date, 'yyyy-MM-DD')
            if (from_date.isAfter(end_date)) {
                errors['from_date'] = 'Invalid date range'
                errors['to_date'] = 'Invalid date range'
            }
            if (isNaN(this.state.additional_information) == true) {
                errors['additional_information'] = 'Number only'
                if (this.state.additional_information < 0) {
                    errors['additional_information'] = 'Must be greater than equals to 0'
                }
            }
            if (isNaN(this.state.remarks) == true) {
                errors['remarks'] = 'Number only'
                if (this.state.remarks < 0) {
                    errors['remarks'] = 'Must be greater than equals to 0'
                }
            }

            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                let class_access = []
                this.state.selectedClass.map((item) => {
                    class_access.push(item.value)
                })
                const data = {
                    title: this.state.title,
                    description: this.state.description,
                    from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                    to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
                    additional_information: this.state.additional_information,
                    remarks: this.state.remarks,
                    class_access,
                    report_card_id: this.state.selectedReportCard,
                }
                addExam(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.loadExam()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _edit(e) {
        e.preventDefault()
        const link = e.target.getAttribute('link')
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const from_date = moment(this.state.from_date, 'yyyy-MM-DD')
            const end_date = moment(this.state.to_date, 'yyyy-MM-DD')
            if (from_date.isAfter(end_date)) {
                errors['from_date'] = 'Invalid date range'
                errors['to_date'] = 'Invalid date range'
            }
            if (this.state.additional_information < 0) {
                errors['additional_information'] = 'Must be greater than equals to 0'
            }
            if (this.state.remarks < 0) {
                errors['remarks'] = 'Must be greater than equals to 0'
            }
            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                let class_access = []
                this.state.selectedClass.map((item) => {
                    class_access.push(item.value)
                })
                const data = {
                    title: this.state.title,
                    description: this.state.description,
                    from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                    to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
                    additional_information: this.state.additional_information,
                    remarks: this.state.remarks,
                    class_access,
                    report_card_id: this.state.selectedReportCard,

                }
                editExam(link, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_edit_' + link).trigger('click')
                        this.loadExam()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('from_date', '')
                component._handleChange('to_date', '')
                component._handleChange('all', false)
                component._handleChange('selectedClass', [])
                component._handleChange('additional_information', '')
                component._handleChange('remarks', '')
                const errors = {
                    title: null,
                    description: null,
                    from_date: null,
                    to_date: null,
                    selectedClass: null,
                    additional_information: null,
                    remarks: null,
                    reportCards: null
                }
                component._handleChange('errors', errors)
            } else {
                const exam_id = $(this).attr('data-id')
                const exam = component.state.exam.find((item) => item.id == exam_id)

                if (exam != null) {
                    const reportCard = component.state.reportCards.find((item) => item.value == exam.report_card_id);
                    component._handleChange('title', exam.title)
                    component._handleChange('description', exam.description)
                    component._handleChange('from_date', moment(exam.from_date, 'yyyy-MM-DD').toDate())
                    component._handleChange('to_date', moment(exam.to_date, 'yyyy-MM-DD').toDate())
                    component._handleChange('additional_information', exam.additional_information)
                    component._handleChange('remarks', exam.remarks)
                    component._handleChange('all', false)
                    component._handleChange('selectedReportCard', exam.report_card_id)
                    if(reportCard != null){
                       component.setState({
                        defaultReportCard: reportCard
                       })
                    }
                   
                    let selectedClass = []
                    exam.class_access_information.map((item) => {
                        const data = {
                            label: item.name + ' - ' + item.code,
                            value: item.id,
                        }
                        selectedClass.push(data)
                    })
                    component._handleChange('selectedClass', selectedClass)
                    const errors = {
                        title: null,
                        description: null,
                        from_date: null,
                        to_date: null,
                        selectedClass: null,
                        additional_information: null,
                        remarks: null,
                        reportCards:null
                    }
                    component._handleChange('errors', errors)
                }
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
