import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractChatComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import { circle } from '~/helper/constant/color'
import * as SVG from '~/assets/svg'
import { Input, Checkbox, Button, TextArea } from '~/components/form'
import * as UserType from '~/helper/constant/user_type'
import MultiImageInput from 'react-multiple-image-input'
import { ReactGallery } from '~/components/gallery'
import { getBase64, getMediaIcon, getReadableFileSize } from '~/helper/function'
import '~/assets/css/chat.css'
import moment from 'moment'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import Picker from 'emoji-picker-react'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import * as Report from '~/helper/constant/report_type'
import { toastMessage } from '~/helper/function/util'
class ChatComponent extends AbstractChatComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectUser = this._selectUser.bind(this)
        this._setProfile = this._setProfile.bind(this)
        this.openEditModal = this.openEditModal.bind(this)
        this._onEmojiClick = this._onEmojiClick.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this._onEnterPress = this._onEnterPress.bind(this)
        this._sendMessage = this._sendMessage.bind(this)
        this._editMessage = this._editMessage.bind(this)
        this.loadChatRoom = this.loadChatRoom.bind(this)
        this.loadUserChatRoom = this.loadUserChatRoom.bind(this)
        this._onSave = this._onSave.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this._delete = this._delete.bind(this)
        this._leave = this._leave.bind(this)
        this._showReportUserModal = this._showReportUserModal.bind(this)
        this._report = this._report.bind(this)
        this._setReportType = this._setReportType.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
        setTimeout(function () {
            $('.zsiq_floatmain').addClass('zoho-hide')
        }, 1000)
    }

    componentWillUnmount() {
        $('.zsiq_floatmain').removeClass('zoho-hide')
    }

    _setReportType(data) {
        this.setState({
            selectedReportType: data,
            report_type: data.value,
        })
    }

    _report(e) {
        e.preventDefault()
        super._report().then((response) => {
            if (response) {
                $('#close_report').trigger('click')
            }
        })
    }

    loadChatRoom(id) {
        super.loadChatRoom(id).then((response) => {
            if (response) {
                this.resetHeight()
            }
        })
    }

    loadUserChatRoom(id) {
        super.loadUserChatRoom(id).then((response) => {
            if (response) {
                this.resetHeight()
            }
        })
    }

    _sendMessage(e) {
        if (this.state.sending == false) {
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Uploading')
            super._sendMessage(e).then((response) => {
                this._handleChange('loading', false)
                if (response) {
                    $('#close_upload_file').trigger('click')
                    this.resetHeight()
                }
            })
        }
    }

    _editMessage(e) {
        super._editMessage(e).then((response) => {
            if (response) {
                $('#close_edit_message').trigger('click')
                this.resetHeight()
            }
        })
    }

    _onSave() {
        super._onSave().then((response) => {
            if (response) {
                $('#close_add').trigger('click')
            }
        })
    }

    _onEdit(e) {
        super._onEdit(e).then((response) => {
            if (response) {
                $('#close_edit').trigger('click')
            }
        })
    }

    _delete(e) {
        super._delete(e).then((response) => {
            if (response) {
                $('#close_delete').trigger('click')
            }
        })
    }

    _leave(e) {
        super._leave(e).then((response) => {
            if (response) {
                $('#close_leave').trigger('click')
            }
        })
    }

    _onEnterPress(e) {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault()
            if (this.state.sending == false) {
                this._sendMessage(e)
            }
        }
    }

    _uploadFile(data) {
        if (data.length > 0) {
            const media = this.state.media
            const uploadedFile = data[data.length - 1]
            getBase64(uploadedFile).then((file) => {
                if (file.error == null) {
                    media.push(file)
                    this._handleChange('media', media)
                    this._handleChange('uploads', data)
                }
            })
        }
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }

    _onEmojiClick(event, data) {
        if (this.state.sending == false) {
            this.setEmoji(data.emoji)
        }
        this.toggleEmoji(event)
    }

    resetHeight() {
        $('#chat_message_panel').animate({ scrollTop: $('#chat_message_panel')[0].scrollHeight }, 1000)
    }

    _setProfile(data) {
        this._handleChange('profile', data)
    }

    openEditModal(id, message) {
        this.setState(
            {
                chat_message_id: id,
                chat_message: message,
            },
            () => {
                $('#edit_message').modal('show')
            }
        )
    }

    _selectUser(data) {
        this._handleChange('access', data)
    }

    getProfile() {
        let image = false
        if (this.props.user.user_detail != null) {
            if (this.props.user.user_detail.profile != null) {
                image = true
            }
        }
        if (image) {
            return <img src={this.props.user.user_detail.profile} alt="user-img" />
        } else {
            if (this.props.user.name != null) {
                return <span>{this.props.user.name.charAt(0)}</span>
            } else {
                return <span>C</span>
            }
        }
    }

    getGallery(gallery) {
        const { t } = this.props
        if (gallery.length > 0) {
            const media = []
            gallery.map((data) => {
                const result = {
                    src: data.media_url,
                }
                media.push(result)
            })
            if (media.length > 0) {
                return <ReactGallery images={media} showGallery={true} />
            }
        } else {
            return (
                <div className="participant">
                    <div className="chat-no-data">{t('chat:noImagesAvailable')}</div>
                </div>
            )
        }
    }

    getDocument(gallery) {
        const { t } = this.props
        if (gallery.length > 0) {
            const data = gallery.map((item) => {
                return (
                    <li className="pdf-download">
                        <a href={route.gallery + '/' + item.id} target="_blank">
                            <div className="svg-icon">{getMediaIcon(item.media_type)}</div>
                            <h4>
                                {item.name}
                                <ins>{getReadableFileSize(item.size)}</ins>
                            </h4>
                            <span>{moment(item.created_at).format('DD/MM/yyyy')}</span>
                        </a>
                    </li>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:noDocumentsAvailable')}</div>
        }
    }

    getVideo(gallery) {
        const { t } = this.props
        if (gallery.length > 0) {
            const data = gallery.map((item) => {
                return (
                    <li className="pdf-download">
                        <a href={route.gallery + '/' + item.id} target="_blank">
                            <div className="svg-icon">{getMediaIcon(item.media_type)}</div>
                            <h4>
                                {item.name}
                                <ins>{getReadableFileSize(item.size)}</ins>
                            </h4>
                            <span>{moment(item.created_at).format('DD/MM/yyyy')}</span>
                        </a>
                    </li>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:noVideoAvailable')}</div>
        }
    }

    getSidebar() {
        const { t } = this.props
        const { chatRoom } = this.state
        if (chatRoom != null) {
            let i = 0
            let chat_profile = ''
            let chat_name = chatRoom.name
            const users = this.state.userList.filter((item) => chatRoom.access.includes(item.id))
            const admin = this.state.userList.find((item) => item.id == chatRoom.author_id)
            const admin_profile = admin.user.user_detail != null ? admin.user.user_detail.profile : null
            const admin_name = admin.user.name != null ? admin.user.name : admin.user.reg_no
            let admin_type = UserType.role_type.find((role) => admin.user_type == role.value)
            if (admin_type != null && admin_type.value != UserType.Custom) {
                admin_type = admin_type.label
            } else {
                admin_type = t('chat:others')
            }

            if (chatRoom.isGroup) {
                chat_profile = chatRoom.media != null ? chatRoom.media.media_url : this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
            } else {
                let user = this.state.userList.find((user) => user.id != this.props.school.id && (user.id == chatRoom.author_id || chatRoom.access.includes(user.id)))
                if (user != null) {
                    chat_profile = user.user.user_detail != null ? user.user.user_detail.profile : null
                    chat_name = user.user.name != null ? user.user.name : user.user.reg_no
                }
            }
            const gallery = chatRoom.gallery != null ? (chatRoom.gallery.media != null ? chatRoom.gallery.media : []) : []
            if (this.state.activeTab == 'media') {
                return (
                    <Fragment>
                        <div className="info-head">
                            <button type="button" id="close" onClick={() => this._toggleSidebar('media')}>
                                {SVG.chatBack()}
                            </button>
                            <h5>{t('chat:chatMedia')}</h5>
                        </div>
                        <div className="large-info gm">
                            <div className="group-media">
                                <ul className="nav clearfix" id="myTab" role="tablist">
                                    <li>
                                        <a className="active" id="gallery-tab" data-toggle="tab" href="#gallery" role="tab" aria-controls="gallery" aria-selected="true">
                                            {t('chat:gallery')}
                                        </a>
                                    </li>
                                    <li>
                                        <a id="files-tab" data-toggle="tab" href="#files" role="tab" aria-controls="files" aria-selected="false">
                                            {t('chat:files')}
                                        </a>
                                    </li>
                                    <li>
                                        <a id="links-tab" data-toggle="tab" href="#links" role="tab" aria-controls="links" aria-selected="false">
                                            {t('chat:video')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="group-media-content">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
                                        <div className="old-gallery">{this.getGallery(gallery.filter((item) => item.media_type.includes('image')))}</div>
                                    </div>
                                    <div className="tab-pane fade show" id="files" role="tabpanel" aria-labelledby="files-tab">
                                        <div className="participant">
                                            <ul>{this.getDocument(gallery.filter((item) => item.media_type.includes('application')))}</ul>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show" id="links" role="tabpanel" aria-labelledby="links-tab">
                                        <div className="participant">
                                            <ul>{this.getVideo(gallery.filter((item) => item.media_type.includes('video')))}</ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            } else if (this.state.activeTab == 'info') {
                return (
                    <Fragment>
                        <div className="info-head">
                            <button type="button" id="close" onClick={() => this._toggleSidebar('info')}>
                                {SVG.chatBack()}
                            </button>
                            <h5>{t('chat:chatInformation')}</h5>
                        </div>
                        <div className="large-info">
                            {chat_profile != null && <img src={chat_profile} alt="group" className="group-img" />}
                            {chat_profile == null && <span className="chat-profile">{chat_name.charAt(0)}</span>}
                            <span className="group-date">
                                {t('chat:createdOn')}: {moment(chatRoom.created_at).format('yyyy-MM-DD')}
                            </span>
                            <ul className="info-list">
                                <li>
                                    <a onClick={() => this._toggleSidebar('media')}>
                                        <span className="group-media">{SVG.chatMedia()}</span>
                                        {t('chat:media')}
                                    </a>
                                </li>
                                {chatRoom.isGroup == true && chatRoom.author_id == this.props.user.id && (
                                    <li>
                                        <a data-toggle="modal" data-target="#edit_group">
                                            <span className="add-participant">{SVG.chatParticipants()}</span>
                                            {t('chat:addParticipants')}
                                        </a>
                                    </li>
                                )}
                                {/* <li>
                                    <a href="#">
                                        <span className="share-add">{SVG.chatShare()}</span>
                                        Share and Add
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a href="#">
                                        <span className="enable-notification">{SVG.chatNotification()}</span>
                                        Enable Notification
                                    </a>
                                </li> */}
                            </ul>
                            {chatRoom.isGroup == true && (
                                <div className="participant">
                                    <h6>{t('admin')}</h6>
                                    <ul>
                                        <li>
                                            <div className="chatpage-user-img">
                                                {admin_profile != null && <img src={admin_profile} alt="user" />}
                                                {admin_profile == null && <ins className="usernoImg">{admin_name.charAt(0)}</ins>}
                                            </div>
                                            <h4>
                                                {admin_name}
                                                <span>{admin_type}</span>
                                            </h4>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div className="participant">
                                <h6>{t('chat:participants')}</h6>
                                <ul>
                                    {chatRoom.isGroup == false && (
                                        <li>
                                            <div className="chatpage-user-img">
                                                {admin_profile != null && <img src={admin_profile} alt="user" />}
                                                {admin_profile == null && <ins className="usernoImg">{admin_name.charAt(0)}</ins>}
                                            </div>
                                            <h4>
                                                {admin_name}
                                                <span>{admin_type}</span>
                                            </h4>
                                        </li>
                                    )}
                                    {users.map((item) => {
                                        const profile = item.user.user_detail != null ? item.user.user_detail.profile : null
                                        const name = item.user.name != null ? item.user.name : item.user.reg_no
                                        let type = UserType.role_type.find((role) => item.user_type == role.value)
                                        if (type != null && type.value != UserType.Custom) {
                                            type = type.label
                                        } else {
                                            type = 'Others'
                                        }
                                        if (i > 2) {
                                            i = 0
                                        }
                                        const color = circle[i]
                                        if (profile == null) {
                                            i++
                                        }
                                        return (
                                            <li>
                                                <div className={'chatpage-user-img ' + color}>
                                                    {profile != null && <img src={profile} alt="user" />}
                                                    {profile == null && <ins className="usernoImg">{name.charAt(0)}</ins>}
                                                </div>
                                                <h4>
                                                    {name}
                                                    <span>{type}</span>
                                                </h4>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Fragment>
                )
            } else {
                return <div></div>
            }
        }
    }

    getRecent() {
        const { t } = this.props
        let i = 0
        if (this.state.recentFilterList.length > 0) {
            const data = this.state.recentFilterList.map((item, index) => {
                let profile = null
                let name = 'Unknown'
                const message = item.latest_message != null ? item.latest_message.message : t('chat:startYourConversation')
                const message_time = item.latest_message != null ? item.latest_message.created_at : null
                if (item.isGroup) {
                    profile = item.media != null ? item.media.media_url : this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
                    name = item.name
                } else {
                    let user = this.state.userList.find((user) => user.id != this.props.school.id && (user.id == item.author_id || item.access.includes(user.id)))
                    if (user != null) {
                        profile = user.user.user_detail != null ? user.user.user_detail.profile : null
                        name = user.user.name != null ? user.user.name : user.user.reg_no
                    }
                }
                if (i > 2) {
                    i = 0
                }
                const color = circle[i]
                if (profile == null) {
                    i++
                }
                return (
                    <a className="user-list" key={index} onClick={() => this.loadChatRoom(item.id)}>
                        <div className={'chatpage-user-img ' + color}>
                            {profile != null && <img src={profile} alt="user" />}
                            {profile == null && <span>{name.charAt(0)}</span>}
                        </div>
                        <div className="message-detail">
                            <span>
                                {name}
                                {item.message_count > 0 && (
                                    <Fragment>
                                        <ins>{item.message_count}</ins>
                                    </Fragment>
                                )}
                                {item.isGroup == true && SVG.groupRecent()}
                            </span>
                            <p>
                                {message}
                                {message_time != null && <ins>{moment(message_time).fromNow()}</ins>}
                            </p>
                        </div>
                    </a>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:noRecentMessages')}</div>
        }
    }

    getUsers() {
        const { t } = this.props
        let i = 0
        if (this.state.userFilterList.length > 0) {
            const data = this.state.userFilterList.map((item, index) => {
                const profile = item.user.user_detail != null ? item.user.user_detail.profile : null
                const name = item.user.name != null ? item.user.name : item.user.reg_no
                let type = UserType.role_type.find((role) => item.user_type == role.value)
                if (type != null && type.value != UserType.Custom) {
                    type = type.label
                } else {
                    type = 'Others'
                }
                if (i > 2) {
                    i = 0
                }
                const color = circle[i]
                if (profile == null) {
                    i++
                }
                return (
                    <a className="user-list" key={index} onClick={() => this.loadUserChatRoom(item.id)}>
                        <div className={'chatpage-user-img ' + color}>
                            {profile != null && <img src={profile} alt="user" />}
                            {profile == null && <span>{name.charAt(0)}</span>}
                        </div>
                        <div className="message-detail">
                            <span>{name}</span>
                            <p>{type}</p>
                        </div>
                    </a>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:noUsersFound')}</div>
        }
    }

    getGroup() {
        const { t } = this.props
        let i = 0
        if (this.state.groupFilterList.length > 0) {
            const data = this.state.groupFilterList.map((item, index) => {
                const message = item.latest_message != null ? item.latest_message.message : t('chat:startYourConversation')
                const message_time = item.latest_message != null ? item.latest_message.created_at : null
                const profile = item.media != null ? item.media.media_url : this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
                const name = item.name
                if (i > 2) {
                    i = 0
                }
                const color = circle[i]
                if (profile == null) {
                    i++
                }
                return (
                    <a className="user-list" key={index} onClick={() => this.loadChatRoom(item.id)}>
                        <div className={'chatpage-user-img ' + color}>
                            {profile != null && <img src={profile} alt="user" />}
                            {profile == null && <span>{name.charAt(0)}</span>}
                        </div>
                        <div className="message-detail">
                            <span>
                                {name}
                                <ins>{item.message_count}</ins>
                            </span>
                            <p>
                                {message}
                                {message_time != null && <ins>{moment(message_time).fromNow()}</ins>}
                            </p>
                        </div>
                    </a>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:noGroupMessages')}</div>
        }
    }

    _showReportUserModal(id) {
        this.setState(
            {
                reportedUser: id,
            },
            () => {
                $('#modal_report').modal('show')
            }
        )
    }

    getReportModal() {
        const { t } = this.props
        return (
            <div id="modal_report" data-id="modal_report" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:report')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label className="form-title"> {t('alumni:reportContent')}:</label>
                                    <Select name="report_type" options={Report.all} placeholder={t('alumni:reportContent')} searchable={true} value={this.state.selectedReportType} onChange={this._setReportType} />
                                    {this.state.report_errors.report_type && <label className="error text-danger setup-error text-left">{this.state.report_errors.report_type}</label>}
                                </div>
                                <div className="col-md-12 form-group">
                                    <label className="form-title">{t('attendance:remarks')} :</label>
                                    <TextArea value={this.state.report_remarks} name="report_remarks" onChange={this._handleChange} type="text" placeholder={t('attendance:remarks')} />
                                    {this.state.report_errors.report_remarks && <label className="error text-danger setup-error text-left">{this.state.report_errors.report_remarks}</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={this._report} type="button" text={t('common:modal confirm')} main={true} />
                            <button id={'close_report'} type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getChatRoomHeader() {
        const { t } = this.props
        const { chatRoom } = this.state
        if (chatRoom == null || chatRoom == undefined) {
            const profile = this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
            const name = this.props.school != null ? (this.props.school.school != null ? this.props.school.school.name : 'Classpedia') : 'Classpedia'
            return (
                <div className="user-message">
                    <div className="chatpage-user-img">
                        {profile != null && <img src={profile} alt="user" />}
                        {profile == null && <span>{name.charAt(0)}</span>}
                    </div>
                    <div className="user-message-tab">
                        <h5 className="clearfix">
                            <span>{t('chat:chatroom')}</span>
                        </h5>
                        <span className="online">{t('chat:youAreOnline')}</span>
                    </div>
                </div>
            )
        } else {
            let profile = null
            let name = 'Unknown'
            let type = null
            let online = false
            let user = null
            if (chatRoom.isGroup) {
                profile = chatRoom.media != null ? chatRoom.media.media_url : this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
                name = chatRoom.name
                type = 'Group'
                online = true
            } else {
                user = this.state.userList.find((item) => item.id != this.props.school.id && (item.id == chatRoom.author_id || chatRoom.access.includes(item.id)))
                if (user != null) {
                    profile = user.user.user_detail != null ? user.user.user_detail.profile : null
                    name = user.user.name != null ? user.user.name : user.user.reg_no
                    type = UserType.role_type.find((item) => user.user_type == item.value)
                    online = user.isOnline
                    if (type != null && type.value != UserType.Custom) {
                        type = type.label
                    } else {
                        type = 'Others'
                    }
                }
            }
            return (
                <div className="user-message">
                    <div className="chatpage-user-img name-color3">
                        {profile != null && <img src={profile} alt="user" />}
                        {profile == null && <span>{name.charAt(0)}</span>}
                    </div>
                    <div className="user-message-tab">
                        <h5 className="clearfix">
                            <span>{name}</span> <ins>{type}</ins>
                        </h5>
                        {online == true && <span className="online">{t('chat:online')}</span>}
                        {online == false && <span className="offline">{t('chat:offline')}</span>}
                        <div className="clearfix message-dd">
                            {/* <div className="message-search">
                                <button>{SVG.chatSearch()}</button>
                            </div> */}
                            <div className="chat-information">
                                <button id="chat-info" className="btn chatpage-head-button dropdown-toggle" data-toggle="collapse" data-target="#chat_dropdown" aria-haspopup="true" aria-expanded="false">
                                    {SVG.chatMenu()}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="chat-info" id="chat_dropdown">
                                    <button type="button" className="media" onClick={() => this._toggleSidebar('media')}>
                                        <div className="info-chat">{SVG.chatMedia()}</div>
                                        {t('chat:media')}
                                    </button>
                                    <button type="button" className="chatpage-info" onClick={() => this._toggleSidebar('info')}>
                                        <div className="info-chat">{SVG.chatInfo()}</div>
                                        {t('chat:info')}
                                    </button>
                                    {chatRoom.isGroup == false && (
                                        <button type="button" className="chatpage-info" onClick={() => this._showReportUserModal(user.id)}>
                                            <div className="info-chat">{SVG.chatInfo()}</div>
                                            {t('alumni:report')}
                                        </button>
                                    )}
                                    {chatRoom.isGroup == true && chatRoom.author_id != this.props.school.id && (
                                        <button type="button" className="lg" data-toggle="modal" data-target="#leave_group">
                                            <div className="info-chat">{SVG.chatLeave()}</div>
                                            {t('chat:leave')}
                                        </button>
                                    )}
                                    {chatRoom.isGroup == true && chatRoom.author_id == this.props.school.id && (
                                        <Fragment>
                                            <button type="button" className="eg" data-toggle="modal" data-target="#edit_group">
                                                <div className="info-chat">{SVG.editGroup()}</div>
                                                {t('common:EditBtn')}
                                            </button>
                                            <button type="button" className="dg" data-toggle="modal" data-target="#delete_group">
                                                <div className="info-chat">{SVG.deleteGroup()}</div>
                                                {t('common:DeleteBtn')}
                                            </button>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getMedia(media) {
        if (media.media_type.includes('image')) {
            return (
                <ReactGallery
                    images={[
                        {
                            src: media.media_url,
                        },
                    ]}
                    showGallery={true}
                />
            )
        } else if (media.media_type.includes('video')) {
            return <video src={media.media_url} width="100%" height="130" controls />
        } else {
            return (
                <a href={route.gallery + '/' + media.id} target="_blank" className="pdf-download">
                    <small>
                        {getMediaIcon(media.media_type)}
                        {media.name}
                        <strong>{getReadableFileSize(media.size)}</strong>
                    </small>
                </a>
            )
        }
    }

    getMessage() {
        const { t } = this.props
        if (this.state.messageList.length > 0) {
            const data = this.state.messageList.map((item, index) => {
                let myMessage = item.media != null ? 'media-upload ' : ''
                if (this.props.school.id == item.sender_id) {
                    myMessage = myMessage + 'my-message'
                }
                const user = this.state.userList.find((user) => item.sender_id == user.id)
                let name = 'Unknown'
                if (user != null) {
                    name = user.user.name != null ? user.user.name : user.user.reg_no
                }

                return (
                    <div className="clearfix">
                        <div class={'message-list ' + myMessage}>
                            <p className="file-upload">
                                {item.media != null && this.getMedia(item.media)}
                                {item.message}
                            </p>
                            <span>{moment(item.created_at).fromNow()}</span>
                            <ins>{name}</ins>
                            {this.props.school.id == item.sender_id && (
                                <div className="e-d-t">
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                            <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                        </button>
                                        <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                            <Fragment>
                                                <button className="dropdown-item" type="button" onClick={() => this.openEditModal(item.id, item.message)}>
                                                    {t('common:EditBtn')}
                                                </button>
                                                <button className="dropdown-item" type="button" onClick={() => this._deleteMessage(item.id)}>
                                                    {t('common:DeleteBtn')}
                                                </button>
                                            </Fragment>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            })
            return data
        } else {
            return <div className="chat-no-data">{t('chat:sayHiToStartYourConversation')}</div>
        }
    }

    getChatRoomContent() {
        const { t } = this.props
        if (this.state.chatRoom == null) {
            const name = this.props.school != null ? (this.props.school.school != null ? this.props.school.school.name : 'Classpedia') : 'Classpedia'
            return (
                <div className="chat-no-data-content">
                    <p className="no-data">
                        {SVG.nodata()}
                        {t('chat:welcomeTo')} {name} {t('chat:chatroom')}
                    </p>
                </div>
            )
        } else {
            return (
                <Fragment>
                    <div id="chat_message_panel" className="messages">
                        {this.state.current_page < this.state.total_page && (
                            <div className="load-previous text-center" onClick={() => this.loadPreviousMesage()}>
                                {t('chat:loadPrevious')}
                            </div>
                        )}
                        {this.getMessage()}
                    </div>
                    <div className="message-send">
                        {this.state.emoji && <Picker onEmojiClick={this._onEmojiClick} />}
                        <button type="button" className="emo-button" onClick={this.toggleEmoji}>
                            {SVG.chatEmo()}
                        </button>
                        <div className="type-message">
                            <TextArea name="message" onKeyDown={this._onEnterPress} onChange={this._handleChange} placeholder={t('chat:typeHerePlaceholder')} type="text" value={this.state.message} />
                            {this.state.sending && (
                                <Fragment>
                                    <em className="msg-detail">{t('chat:sending')}..</em>
                                    <div className="sending">
                                        <div className="sending-circle">
                                            <div></div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                            {!this.state.sending && (
                                <button type="submit" onClick={this._sendMessage}>
                                    {SVG.chatSubmit()}
                                </button>
                            )}
                        </div>
                        <div className="clearfix message-option">
                            <button type="button" data-toggle="modal" data-target="#upload_file">
                                {SVG.chatAttachFile()}
                            </button>
                            {/* <button>{SVG.chatUpload()}</button> */}
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    getUploadFile() {
        const { t } = this.props
        return (
            <div id="upload_file" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:uploadFile')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:message')} *</label>
                                        <Input name="message" onChange={this._handleChange} placeholder={t('chat:typeHerePlaceholder')} type="text" value={this.state.message} />
                                        {this.state.mediaNameError && <label className="error text-danger">{this.state.mediaNameError}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:file')}</label>
                                        <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                            {this.previewDocument()}
                                            {this.state.media.length < 1 && (
                                                <Files maxFileSize={100000000} onError={() => toastMessage('error', 'Max upload size is 100MB')} className="files-dropzone" onChange={this._uploadFile} multiple maxFiles={1} minFileSize={0} clickable>
                                                    {t('chat:addFiles')}
                                                </Files>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this._sendMessage} type="submit" text={t('chat:send')} main={true} />
                                <button id="close_upload_file" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getCreateGroup() {
        const { t } = this.props
        return (
            <div id="create_group" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:createGroupChat')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:groupProfile')} *</label>
                                        <MultiImageInput allowCrop={false} images={this.state.profile} max={1} setImages={this._setProfile} theme="light" />
                                        {this.state.errors.profile && <label className="error text-danger">{this.state.errors.profile}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:groupName')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('chat:groupName')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:members')} *</label>
                                        <Select name="access" options={this.state.memberList} isMulti placeholder={t('chat:groupMembersPlaceholder')} searchable={true} value={this.state.access} onChange={this._selectUser} />
                                        {this.state.errors.access && <label className="error text-danger">{this.state.errors.access}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={() => this._onSave()} type="button" text="Save" main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getEditGroup() {
        const { t } = this.props
        return (
            <div id="edit_group" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:editGroupChat')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:groupProfile')} *</label>
                                        <MultiImageInput allowCrop={false} images={this.state.profile} max={1} setImages={this._setProfile} theme="light" />
                                        {this.state.errors.profile && <label className="error text-danger">{this.state.errors.profile}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:groupName')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('chat:groupName')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:members')} *</label>
                                        <Select name="access" options={this.state.memberList} isMulti placeholder={t('chat:groupMembersPlaceholder')} searchable={true} value={this.state.access} onChange={this._selectUser} />
                                        {this.state.errors.access && <label className="error text-danger">{this.state.errors.access}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={() => this._onEdit()} type="button" text={t('common:updateBtn')} main={true} />
                                <button id="close_edit" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getLeaveGroup() {
        const { t } = this.props
        return (
            <div id="leave_group" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:leaveGroupChat')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">{t('chat:areYouSureYouWantToLeaveThisGroupChat')}?</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={() => this._leave()} type="button" text={t('chat:leave')} e main={true} />
                                <button id="close_leave" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getDeleteGroup() {
        const { t } = this.props
        return (
            <div id="delete_group" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:deleteGroupChat')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">{t('chat:areYouSureYouWantToDeleteThisGroupChat')}?</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={() => this._delete()} type="submit" text={t('common:DeleteBtn')} main={true} />
                                <button id="close_delete" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getEditMessage() {
        const { t } = this.props
        return (
            <div id="edit_message" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('chat:editMessage')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('chat:message')} *</label>
                                        <Input name="chat_message" onChange={this._handleChange} placeholder={t('chat:message')} type="text" value={this.state.chat_message} />
                                        {this.state.chat_errors.chat_message && <label className="error text-danger">{this.state.chat_errors.chat_message}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this._editMessage} type="submit" text={t('common:updateBtn')} main={true} />
                                <button id="close_edit_message" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="chat-page clearfix">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="chat-left dropdown-menu collapse show" aria-labelledby="chat-infos" id="chat-leftbar">
                    {/* <button id="chat-infos" className="btn leftside-button dropdown-toggle" data-toggle="collapse" data-target="#chat-leftbar" aria-haspopup="true" aria-expanded="false">
                        {SVG.closeuserchat()}
                    </button> */}
                    <div className="collapse-body">
                        <div className="main-user">
                            <div className="chatpage-user-img name-color2">{this.getProfile()}</div>

                            <div className="user-detail">
                                <h4>
                                    {this.props.user && this.props.user.name && this.props.user.name}
                                    {this.props.user && !this.props.user.name && 'Classpedia Member'}
                                </h4>
                                <span className="online">{t('chat:online')}</span>
                            </div>
                        </div>
                        <div className="chat-set">
                            <ul className="nav clearfix" id="myChatTab" role="tablist">
                                <li>
                                    <a className="active" id="recent-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                                        {t('chat:recent')}
                                    </a>
                                </li>
                                <li>
                                    <a id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="false">
                                        {t('chat:users')}
                                    </a>
                                </li>
                                <li>
                                    <a id="group-tab" data-toggle="tab" href="#group" role="tab" aria-controls="group" aria-selected="false">
                                        {t('chat:groups')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content" id="myChatTabContent">
                        <div className="tab-pane fade show active" id="recent" role="tabpanel" aria-labelledby="recent-tab">
                            <div className="user-search collapse-body">
                                <Input onChange={this.setRecentFilter} name="recent_search" placeholder={t('common:searchhere')} type="text" value={this.state.recent_search} />
                                <div className="svg-icon">{SVG.chatSearch()}</div>
                            </div>
                            <div className="user-message-list">{this.getRecent()}</div>
                        </div>
                        <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                            <div className="user-search collapse-body">
                                <Input onChange={this.setUserFilter} name="user_search" placeholder={t('common:searchhere')} type="text" value={this.state.user_search} />
                                <div className="svg-icon">{SVG.chatSearch()}</div>
                            </div>
                            <div className="chat-clarify collapse-body">
                                <button id="chat-filter" data-toggle="collapse" data-target="#chat_filter" aria-haspopup="true" aria-expanded="false">
                                    {SVG.chatfilter()} {t('chat:filter')}
                                </button>
                                <div className="dropdown-menu collapse-body" aria-labelledby="chat-filter" id="chat_filter">
                                    <ul>
                                        <li>
                                            <Input id="all" type="radio" className="chat-radio" onChange={this.setUserFilter} name="userFilter" value="0" defaultChecked={true} />
                                            <label htmlFor="all">{t('chat:all')}</label>
                                        </li>
                                        {this.props.school.user_type != UserType.Alumni && (
                                            <li>
                                                <Input id="student" type="radio" className="chat-radio" onChange={this.setUserFilter} name="userFilter" value="1" />
                                                <label htmlFor="student">{t('chat:student')}</label>
                                            </li>
                                        )}
                                        <li>
                                            <Input id="colleague" type="radio" className="chat-radio" onChange={this.setUserFilter} name="userFilter" value="2" />
                                            {this.props.school != null && this.props.school.user_type != UserType.Alumni && this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <label htmlFor="colleague">{t('chat:colleague')}</label>}
                                            {this.props.school != null && (this.props.school.user_type == UserType.Alumni || this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <label htmlFor="colleague">{t('chat:teachers')}</label>}
                                        </li>
                                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                            <li>
                                                <Input id="alumni" type="radio" className="chat-radio" onChange={this.setUserFilter} name="userFilter" value="3" />
                                                <label htmlFor="alumni">{t('alumni:alumni')}</label>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="user-message-list">{this.getUsers()}</div>
                        </div>
                        <div className="tab-pane fade" id="group" role="tabpanel" aria-labelledby="group-tab">
                            <div className="user-search collapse-body">
                                <Input onChange={this.setGroupFilter} name="group_search" placeholder={t('common:searchhere')} type="text" value={this.state.group_search} />
                                <div className="svg-icon">{SVG.chatSearch()}</div>
                            </div>
                            {this.props.school != null && this.props.school.user_type != UserType.Alumni && this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                <div className="chat-clarify collapse-body">
                                    <button data-toggle="modal" className="creategroupBtn" data-target="#create_group">
                                        {SVG.createGroup()} {t('chat:createGroup')}
                                    </button>
                                </div>
                            )}

                            <div className="user-message-list">{this.getGroup()}</div>
                        </div>
                    </div>
                </div>
                <div className="for-right">
                    <div id="content">
                        <div className="chat-right">
                            <button id="chat-infos" className="btn leftside-button dropdown-toggle" data-toggle="collapse" data-target="#chat-leftbar" aria-haspopup="true" aria-expanded="false">
                                <span className="openchatleft">{SVG.openuserchat()}</span>
                            </button>
                            {this.getChatRoomHeader()}
                            {this.getChatRoomContent()}
                        </div>
                    </div>
                    <div id="media-sidebar" className={'chat-sidebar ' + this.state.sidebarActive}>
                        {this.getSidebar()}
                    </div>
                </div>
                {this.getCreateGroup()}
                {this.getEditGroup()}
                {this.getDeleteGroup()}
                {this.getLeaveGroup()}
                {this.getEditMessage()}
                {this.getUploadFile()}
                {this.getReportModal()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'create_group') {
                component._handleChange('title', '')
                component._handleChange('access', [])
                component._handleChange('profile', {})
                const errors = {
                    title: null,
                    access: null,
                    profile: null,
                }
                component._handleChange('errors', errors)
            } else if (id == 'edit_group') {
                const chatRoom = component.state.chatRoom
                if (chatRoom != null) {
                    component._handleChange('title', chatRoom.name)
                    const memberList = component.state.memberList.filter((item) => chatRoom.access.includes(item.value))
                    component._handleChange('access', memberList)
                    if (chatRoom.media != null) {
                        component._handleChange('profile', { 0: chatRoom.media.media_type + ';base64,' + chatRoom.media.media_data })
                    }
                    const errors = {
                        title: null,
                        access: null,
                        profile: null,
                    }
                    component._handleChange('errors', errors)
                }
            }
        })
        $('.modal').on('hidden.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'upload_file') {
                let uploads = component.state.uploads
                for (let i = uploads.length; i >= 0; i--) {
                    uploads.pop()
                }
                // component.state.uploads.map((item, index) => {
                //     uploads.pop()
                // })
                // uploads.pop()
                component._handleChange('uploads', uploads)
                component._handleChange('media', [])
            }
            if (id == 'modal_report') {
                component.setState({
                    report_errors: {
                        report_type: null,
                        report_remarks: null,
                    },
                    report_remarks: '',
                    report_type: null,
                    selectedReportType: null,
                })
            }
        })
    }
}

const Chat = withTranslation()(connect(mapStateToProps)(ChatComponent))

export { Chat }
