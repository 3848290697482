import { accountingGet, accountingPost } from '../accountingRequest'

export const viewBankAccount =(token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('bank-account', token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}
export const getBankAccount =(bankAccount, token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('bank-account/edit/' + bankAccount, token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const addBankAccount = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bank-account/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBankAccount = (bankAccount, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('bank-account/update/' + bankAccount, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteBankAccount = (bankAccount, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('bank-account/delete' + bankAccount, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewBankTransfer =(token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('transfer', token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const getBankTransfer =(id, token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('transfer/edit/' + id, token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const addBankTransfer = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('transfer/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBankTransfer = (id, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('transfer/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteBankTransfer = (id, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('transfer/delete' + id, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

