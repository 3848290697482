import React, { Fragment } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import * as route from '~/helper/constant/route'
import { Login } from '~/views/login'
import { Account } from '~/views/account'
import { Reset } from '~/views/reset'
import { ForgotPassword } from '~/views/forgot-password'
import { PageNotFound, MeetingClose } from '~/views/Pages'
import { createHashHistory } from 'history'
import { configRoute } from '~/helper/function'
import { Authenticated } from './authenticated'
import { Administration } from './administration'
import { SuperAdmin } from './superadmin'
import { Admin } from './admin'
import { Parent } from './parent'
import { Student } from './student'
import { Common } from './common'
import { Teacher } from './teacher'
import { Alumni } from './alumni'
import { Register } from '~/views/register'
import { Restrict } from '../../views/restrict'

const hashHistory = createHashHistory()

export class Routing {
    getRoute() {
        return (
            <Router history={hashHistory}>
                <Switch>
                    <Route exact path={configRoute(route.index)} component={Login} />
                    <Route exact path={configRoute(route.register) + '/:id'} component={Register} />
                    <Route exact path={configRoute(route.forgot_password)} component={ForgotPassword} />
                    <Route exact path={configRoute(route.reset)} component={Reset} />
                    <Route exact path={configRoute(route.account) + '/:id'} component={Account} />
                    <Route path="/live/close" component={MeetingClose} />
                    <Route path="/restrict" component={Restrict} />

                    {/* SUPERADMIN */}
                    {SuperAdmin().map((item) => {
                        return item
                    })}

                    {/* ADMIN */}
                    {Admin().map((item) => {
                        return item
                    })}

                    {/* ADMINISTRATION */}
                    {Administration().map((item) => {
                        return item
                    })}

                    {/* TEACHER */}
                    {Teacher().map((item) => {
                        return item
                    })}

                    {/* STUDENT */}
                    {Student().map((item) => {
                        return item
                    })}

                    {/* PARENT */}
                    {Parent().map((item) => {
                        return item
                    })}

                    {/* ALUMNI */}
                    {Alumni().map((item) => {
                        return item
                    })}

                    {/* COMMON */}
                    {Common().map((item) => {
                        return item
                    })}

                    {/* AUTHENTICATED */}
                    {Authenticated().map((item) => {
                        return item
                    })}

                    <Route path="*" component={PageNotFound} />
                </Switch>
            </Router>
        )
    }
}
