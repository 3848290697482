import moment from 'moment'
import { Component } from 'react'
import { Dispath } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import * as route from '../../../../../../helper/constant/route'
import { redirect, toastMessage } from '../../../../../../helper/function/util'
import { addJournalAccount, viewChartOfAccount } from '../../../../../../services/api/accounting/doubleentry'

type Props = {
    dispatch: Dispath<any>,
}
type State = {}

export class AbstractJournalAccountAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            date: '',
            reference: '',
            description: '',
            accounts: [
                {
                    account: null,
                    debit: 0,
                    credit: 0,
                    description: null,
                    amount: null,
                },
            ],
            accountErrors: [
                {
                    account: null,
                    debit: null,
                    credit: null,
                    description: null,
                    amount: null,
                },
            ],
            totalCredit: 0.0,
            totalDebit: 0.0,
            chartOfAccounts: [],
            errors: {
                date: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadChartOfAccount()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    addRow() {
        let accounts = this.state.accounts
        let accountErrors = this.state.accountErrors
        accounts.push({
            account: null,
            debit: 0,
            credit: 0,
            description: null,
            amount: null,
        })

        accountErrors.push({
            account: null,
            debit: null,
            credit: null,
            description: null,
            amount: null,
        })
        this.setState({
            accounts,
            accountErrors,
        })
    }

    removeRow(index) {
        let accounts = this.state.accounts
        let accountErrors = this.state.accountErrors
        accounts.splice(index, 1)
        accountErrors.splice(index, 1)
        this.setState({
            accounts,
            accountErrors,
        })
    }

    loadChartOfAccount() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        viewChartOfAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const assetsChildren = response.data.chartAccounts.Assets.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const liabilitiesChildren = response.data.chartAccounts.Liabilities.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const expenseChildren = response.data.chartAccounts.Expenses.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const incomeChildren = response.data.chartAccounts.Income.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const equityChildren = response.data.chartAccounts.Equity.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const chartOfAccounts = [
                    {
                        label: 'Assets',
                        options: assetsChildren,
                    },
                    {
                        label: 'Liabilities',
                        options: liabilitiesChildren,
                    },
                    {
                        label: 'Expense',
                        options: expenseChildren,
                    },
                    {
                        label: 'Income',
                        options: incomeChildren,
                    },
                    {
                        label: 'Equity',
                        options: equityChildren,
                    },
                ]

                this.setState({
                    chartOfAccounts,
                })
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const accountErrors = this.state.accountErrors
            let error = false
            this.state.accounts.map((item, index) => {
                if (item.account == null) {
                    accountErrors[index]['account'] = 'Required'
                    error = true
                }
                if (item.debit == null) {
                    accountErrors[index]['debit'] = 'Required'
                    error = true
                }
                if (item.credit == null) {
                    accountErrors[index]['credit'] = 'Required'
                    error = true
                }
                if (item.description == null) {
                    accountErrors[index]['description'] = 'Required'
                    error = true
                }
            })
            this.setState({ accountErrors })
            if (!error) {
                if (this.state.totalCredit == this.state.totalDebit) {
                    const data = {
                        date: moment(this.state.date).format('yyyy-MM-DD'),
                        reference: this.state.reference,
                        description: this.state.description,
                        accounts: this.state.accounts,
                    }
                    this._handleChange('loading', true)
                    this._handleChange('loading_text', 'Saving')
                    addJournalAccount(data, this.props.token).then((response) => {
                        this._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            redirect(route.admin.accounting.doubleentry.journalaccount.view, this.props.history)
                        } else {
                            toastMessage('error', response.message)
                            if (response.data) {
                                if ('errors' in response.data) {
                                    this.setState({ errors: response.data.errors })
                                }
                            }
                        }
                    })
                } else {
                    toastMessage('error', 'Debit amount must be equal to Credit amount')
                }
            }
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
