import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { TeacherRoute } from './guard'
import * as AdminComponent from '~/views/admin'
import * as SharedComponent from '~/views/shared'
import * as AccessModule from '~/helper/constant/modules'

export const Teacher = () => [
    <TeacherRoute exact key="1" access={[AccessModule.Module_School_Management]} subAccess={AccessModule.TIMETABLE} path={configRoute(route.teacher.timetable)} component={SharedComponent.Timetable} />,

    <TeacherRoute exact key="2" path={configRoute(route.teacher.attendance.menu)} component={AdminComponent.Attendance} />,

    <TeacherRoute exact key="3" path={configRoute(route.teacher.attendance.filter)} component={AdminComponent.StudentAtttendanceMenu} />,

    <TeacherRoute exact key="4" path={configRoute(route.teacher.my_circle)} component={SharedComponent.MyCircle} />,

    <TeacherRoute exact key="5" path={configRoute(route.teacher.attendance.view + '/:attendance_type/:section_id/:subject_id/:date')} component={AdminComponent.StudentAttendanceView} />,
]
