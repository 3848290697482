import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractChildrenProfileComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { gender } from '~/helper/constant/gender'
import { country } from '~/helper/constant/country'
import { blood_group } from '~/helper/constant/blood_group'
import * as Status from '~/helper/constant/status'
import { Input, Button } from '~/components/form'
import { Loading } from '~/components/loader'
import Select from 'react-select'
import Files from 'react-files'
import { getBase64, getReadableFileSize } from '~/helper/function'
import MultiImageInput from 'react-multiple-image-input'
import { withTranslation } from 'react-i18next'

class ChildrenProfileComponent extends AbstractChildrenProfileComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setGender = this._setGender.bind(this)
        this._setCountry = this._setCountry.bind(this)
        this._setBloodGroup = this._setBloodGroup.bind(this)
        this.previewDocument = this.previewDocument.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this._deleteFile = this._deleteFile.bind(this)
        this._loadDocuments = this._loadDocuments.bind(this)
        this._setProfile = this._setProfile.bind(this)
    }

    _setProfile(data) {
        this._handleChange('profile', data)
    }

    _setGender(data) {
        this._handleChange('gender', data.value)
        this._handleChange('defaultGender', data)
    }

    _setCountry(data) {
        this._handleChange('country', data.value)
        this._handleChange('defaultCountry', data)
    }

    _setBloodGroup(data) {
        this._handleChange('blood_group', data.value)
        this._handleChange('defaultBloodGroup', data)
    }

    _uploadFile(data) {
        const media = this.state.media
        const uploadedFile = data[data.length - 1]
        getBase64(uploadedFile).then((file) => {
            if (file.error == null) {
                media.push(file)
                this._handleChange('media', media)
                this._handleChange('uploads', data)
            } else {
                console.log('Max File Exceeded!')
            }
        })
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }

    _deleteFile(id) {
        let deletedMedia = this.state.deletedMedia
        let uploadedMedia = this.state.uploadedMedia.filter((item) => item.id != id)
        deletedMedia.push(id)
        this._handleChange('deletedMedia', deletedMedia)
        this._handleChange('uploadedMedia', uploadedMedia)
    }

    _loadDocuments() {
        if (this.state.uploadedMedia.length > 0) {
            const media = this.state.uploadedMedia.map((item, index) => {
                const extension = item.media_type.split('/')
                return (
                    <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                        <div className="gsEyTH files-list-item">
                            <a href={item.media_url} target="_blank">
                                <div className="files-list-item-preview">
                                    <div className="files-list-item-preview-extension">{extension[1]}</div>
                                </div>
                                <div className="files-list-item-content">
                                    <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                                    <div className="files-list-item-content-item files-list-item-content-item-2">{getReadableFileSize(item.size)}</div>
                                </div>
                            </a>
                            <div
                                id={item.id}
                                className="files-list-item-remove"
                                onClick={() => this._deleteFile(item.id)} // eslint-disable-line
                            />
                        </div>
                    </div>
                )
            })
            return media
        } else {
            const { t } = this.props
            return <div className="gallery-message">{t('common:uploaddocumentdetail')}</div>
        }
    }

    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="children" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.activity}>{t('setting:dashboard')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.children}>My Children &gt; </a>
                            </li>
                            <li>Profile</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{this.state.name != '' ? this.state.name + "'s Profile" : 'Children Profile'}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="clearfix">
                                <div className="float-left form-group profile-container">
                                    <label className="form-title">{t('setting:profilepicture')}</label>
                                    <MultiImageInput images={this.state.profile} max={1} setImages={this._setProfile} allowCrop={false} theme="light" />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('name')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('name')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('contact')} *</label>
                                    <Input name="contact" onChange={this._handleChange} placeholder={t('contact')} type="text" value={this.state.contact} />
                                    {this.state.errors.contact && <label className="error text-danger">{this.state.errors.contact}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fathername')} *</label>
                                    <Input name="father_name" onChange={this._handleChange} placeholder={t('fathername')} type="text" value={this.state.father_name} />
                                    {this.state.errors.father_name && <label className="error text-danger">{this.state.errors.father_name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('mothername')} *</label>
                                    <Input name="mother_name" onChange={this._handleChange} placeholder={t('mothername')} type="text" value={this.state.mother_name} />
                                    {this.state.errors.mother_name && <label className="error text-danger">{this.state.errors.mother_name}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('gender')} *</label>
                                    <Select name="gender" options={gender} placeholder={t('gender')} searchable={true} value={this.state.defaultGender} onChange={this._setGender} />
                                    {this.state.errors.gender && <label className="error text-danger">{this.state.errors.gender}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('country')} *</label>
                                    <Select name="country" options={country} placeholder={t('country')} searchable={true} value={this.state.defaultCountry} onChange={this._setCountry} />
                                    {this.state.errors.country && <label className="error text-danger">{this.state.errors.country}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('permanentaddress')} *</label>
                                    <Input name="permanent_address" onChange={this._handleChange} placeholder={t('permanentaddress')} type="text" value={this.state.permanent_address} />
                                    {this.state.errors.permanent_address && <label className="error text-danger">{this.state.errors.permanent_address}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('temporaryaddress')}</label>
                                    <Input name="temporary_address" onChange={this._handleChange} placeholder={t('temporaryaddress')} type="text" value={this.state.temporary_address} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('bloodgroup')}</label>
                                    <Select name="blood_group" options={blood_group} placeholder={t('bloodgroup')} searchable={true} value={this.state.defaultBloodGroup} onChange={this._setBloodGroup} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12 float-left form-group">
                                    <label className="form-title">
                                        {t('uploaddocuments')} {t('common:onlypdf')}
                                    </label>
                                    <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                        {this.previewDocument()}
                                        <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                            <Files className="files-dropzone" onChange={this._uploadFile} accepts={['.pdf']} multiple maxFiles={5} maxFileSize={10000000} minFileSize={0} clickable>
                                                {t('adddocument')}
                                            </Files>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('document')} </label>
                                    <div className="gallery-container">{this._loadDocuments()}</div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const ChildrenProfile = withTranslation()(connect(mapStateToProps)(ChildrenProfileComponent))
export { ChildrenProfile }
