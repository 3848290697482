import React, { Component } from 'react'

export class LoginAnimation extends Component {
    render() {
        return (
            <div className="page clearfix">
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
                <div className="box box-1 navy"></div>
                <div className="box box-2 cyan"></div>
                <div className="box box-3 tan"></div>
                <div className="box box-4 pink"></div>
                <div className="box box-5 tan"></div>
                <div className="box box-6 orange"></div>
                <div className="box box-7 cyan"></div>
                <div className="box box-8 green"></div>
                <div className="box box-9 pink"></div>
                <div className="box box-10 orange"></div>
                <div className="box box-11 navy"></div>
                <div className="box box-12 tan"></div>
            </div>
        )
    }
}
