import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getCourseItems, editCourse, getClass } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import { redirect } from '~/helper/function/util'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractCourseEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            className: '',
            class_access: [],
            allUsers: [],
            classCode: '',
            loading: true,
            loading_text: 'Loading',
            title: '',
            selected_subject: null,
            isPrivate: false,
            description: '',
            topic: '',
            class_id: this.props.match.params.id,
            course_id: this.props.match.params.course_id,
            afterDueDate: false,
            quizAfterDueDate: false,
            // selectedStudent: null,
            // selectedEmployee: null,
            // selectedSubject: null,
            courseData: [],
            errors: {
                title: null,
                description: null,
                is_course_progressive: null,
                topic: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.loadUser = this.loadUser.bind(this)
    }

    componentDidMount() {
        this.loadClass()
        this.loadCourse()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        getClass(this.props.match.params.id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                this._handleChange('classId', response.data.class.id)
                this._handleChange('className', response.data.class.name)
                this._handleChange('classCode', response.data.class.code)
            }
        })
    }

    loadUser(data) {
            this.setState(
                {
                    allUsers:data,
                },
                () => {
                    this.loadCourse()
                }
            )    
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseItems(this.state.course_id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const courseData = response.data.post

                let selectedEmployee = []
                let selectedStudent = []
                const currentAccess = courseData.access
                const accessAry = []
                const allUsers = this.state.allUsers

                if (currentAccess != null) {
                    currentAccess.map((item, index) => {
                        const user = allUsers.filter((el) => {
                            return el.id == item
                        })

                        if (user.length != 0) {
                            if (user[0].user_type == 3) {
                                selectedStudent.push(user[0].id)
                            } else {
                                selectedEmployee.push(user[0].id)
                            }
                        }
                        // if (selectedStudent.length == 0) {
                        //     selectedStudent.push(-1)
                        // }
                        // if (selectedEmployee.length == 0) {
                        //     selectedEmployee.push(-1)
                        // }
                    })
                }

                this.setState(
                    {
                        title: courseData.title,
                        selectedEmployee,
                        selectedStudent,
                        selected_subject: courseData.subject ? courseData.subject.id : -1,
                        class_access: courseData.class_access,
                        description: courseData.description,
                        isPrivate: courseData.restriction,
                        // is_course_progressive: courseData.course.is_progressive,
                        is_course_progressive: true,
                        quizAfterDueDate: courseData.course.quiz_after_due,
                        afterDueDate: courseData.course.available_after_due,
                        topic: courseData.course.topic,
                        due_date: courseData.to_date ? moment(courseData.to_date, 'yyyy-MM-DD').toDate() : null,
                    },
                    () => this._handleChange('loading', false)
                )
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        let hasError = false
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                title: this.state.title,
                description: this.state.description,
                topic: this.state.topic,
                available_after_due: this.state.afterDueDate,
                quiz_after_due: this.state.quizAfterDueDate,
                //is_progressive: this.state.is_course_progressive,
                is_progressive: true,
                from_date: moment(this.state.start_date).format('yyyy-MM-DD'),
                to_date: moment(this.state.due_date).format('yyyy-MM-DD'),
                restriction: this.state.isPrivate,
                class_access: this.state.class_access,
            }
            if (this.state.isPrivate) {
                if (this.state.selectedStudent != null && this.state.selectedStudent != -1 && this.state.selectedEmployee != -1 && this.state.selectedEmployee != null) {
                    data.access = this.state.selectedEmployee.concat(this.state.selectedStudent)
                } else if (this.state.selectedStudent != null) {
                    data.access = this.state.selectedStudent
                } else {
                    data.access = this.state.selectedEmployee
                }
                if (this.state.selected_subject > 0) {
                    data.subject_id = this.state.selected_subject
                }
            }

            editCourse(this.state.course_id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)

                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.course.view + '/' + this.state.class_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
