import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractAcademicViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import { academic } from '../../../../assets/svg/general/general'
class AcademicViewComponent extends AbstractAcademicViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getModal = this.getModal.bind(this)
    }

    getModal() {
        const { t } = this.props
        const modal = this.state.data.map((item, index) => {
            if (item.status == Status.PENDING) {
                return (
                    <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('academic:status alert')}.</p>
                                    <p className="delete-file-name">
                                        {SVG.unknownicon()}
                                        {item.name}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                    <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (item.status == Status.ACTIVE) {
                return (
                    <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('academic:information')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('academic:status info')}.</p>
                                    <p className="delete-file-name">"{item.name}"</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('academic:information')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('academic:closeNotify')}.</p>
                                    <p className="delete-file-name">"{item.name}"</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
        return modal
    }

    getNoPromoteModal() {
        const { t } = this.props
        return (
            <div id="modal_no_promote" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('academic:information')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('academic:noPreviousYear')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <button id={'close_no_promote'} type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getPromote() {
        const { t } = this.props
        if (this.state.academic.length <= 1) {
            return (
                <div className="display-inline-block">
                    <a data-toggle="modal" data-target="#modal_no_promote" className="main-button">
                        {t('academic:promote.promoteStudent')}
                    </a>
                </div>
            )
        } else {
            return (
                <div className="display-inline-block">
                    <a href={route.admin.academic.promote} className="main-button">
                        {SVG.promoteStudent()}&nbsp;{t('academic:promote.promoteStudent')}
                    </a>
                </div>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="academic" />
                <div className="main-content">
                    <Header />

                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.academic.view}>{t('academic:title')} </a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('academic:title')} :</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                {this.state.access.includes(AccessModule.Module_Student_Management) && this.getPromote()}
                                <div className="display-inline-block">
                                    <a href={route.admin.academic.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix">
                        <div className="col-sm-12 float-left form-group">
                            <div className="alert alert-primary" role="alert">
                                {t('academic:alert')}.
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.academic} />
                </div>
                {this.getModal()}
                {this.getNoPromoteModal()}
            </div>
        )
    }
}
const AcademicView = withTranslation()(connect(mapStateToProps)(AcademicViewComponent))
export { AcademicView }
