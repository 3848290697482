import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractLibraryComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
class LibraryComponent extends AbstractLibraryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Sidebar id="library" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('library:libraryview')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                        {access.includes(AccessModule.Module_Exam_Management) && AccessModule.checkSubAccess([AccessModule.REPORT_CARD], role) && (
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.library.bookcategory.view)}>
                                    <a href={route.admin.library.bookcategory.view} className="folder-img">
                                        {SVG.libraryAssignBookIcon()}
                                    </a>
                                    <a href={route.admin.library.bookcategory.view} className="folder-name">
                                        {t('library:bookscategory')}
                                        <span> {t('library:managebookscategory')}</span>
                                    </a>
                                </div>
                            </div>
                        )}

                        {access.includes(AccessModule.Module_Exam_Management) && AccessModule.checkSubAccess([AccessModule.REPORT_CARD], role) && (
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.library.shelfmanagement.view)}>
                                    <a href={route.admin.library.shelfmanagement.view} className="folder-img">
                                    {SVG.librarySettingIcon()}
                                    </a>
                                    <a href={route.admin.library.shelfmanagement.view} className="folder-name">
                                        {t('library:shelfmanagement')}
                                        <span> {t('library:shelfmanagement')}</span>
                                    </a>
                                </div>
                            </div>
                        )}

                        {access.includes(AccessModule.Module_Exam_Management) && AccessModule.checkSubAccess([AccessModule.REPORT_CARD], role) && (
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.library.books.view)}>
                                    <a href={route.admin.library.books.view} className="folder-img">
                                        {SVG.libraryReceiveBookIcon()}
                                    </a>
                                    <a href={route.admin.library.books.view} className="folder-name">
                                        {t('library:books')}
                                        <span> {t('library:managebooks')}</span>
                                    </a>
                                </div>
                            </div>
                        )}

                        {access.includes(AccessModule.Module_Accounting) && AccessModule.checkSubAccess([AccessModule.LIBRARY], role) && (
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.library.bookissue.view)}>
                                    <a href={route.admin.library.bookissue.view} className="folder-img">
                                        {SVG.libraryDigitalLibraryIcon()}
                                    </a>
                                    <a href={route.admin.library.bookissue.view} className="folder-name">
                                        {t('library:bookissue')}
                                        <span> {t('library:viewbookissue')}</span>
                                    </a>
                                </div>
                            </div>
                        )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Library = withTranslation()(connect(mapStateToProps)(LibraryComponent))

export { Library }
