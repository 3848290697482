import moment from 'moment'
import React, { Component } from 'react'
import { convertADtoBS, convertBStoAD, getBSWeek } from '../../helper/function/abstract'
import DateBS from '../../helper/date-converter'
import { calendar } from '../../helper/constant/route'
import { days } from '../../helper/constant/days'

export class AbstractNepaliEventCalendarComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            today: convertADtoBS(moment().format('Y-MM-D')),
            currentYear: '',
            currentMonth: '',
            currentMonthDays: '',
            startingDay: '',
            calendar: [],
            maxDate: null,
            minDate: null,
            disableToday: false,
            events: this.props.events != null ? this.props.events : [],
            error: false,
            selectedDate: this.props.selectedDate != null ? convertADtoBS(this.props.selectedDate) : '',
        }
        this.prevMonth = this.prevMonth.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
        this.today = this.today.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.maxDate != this.props.maxDate) {
            this.today()
        }
        if (prevProps.minDate != this.props.minDate) {
            this.today()
        }
        if (prevProps.events?.length != this.props.events?.length) {
            this.setState({
                events: this.props.events,
            })
            console.log(this.props.events)
        }
    }

    today() {
        let date = this.state.today.split('-')
        if (this.props.maxDate) {
            let formatDate = moment(this.props.maxDate).subtract(1, 'days')
            let nepaliMaxDate = convertADtoBS(moment(formatDate).format('yyyy-MM-DD'))
            nepaliMaxDate = nepaliMaxDate != null ? nepaliMaxDate.split('-') : null
            if (nepaliMaxDate != null) {
                if (parseInt(nepaliMaxDate[0]) < parseInt(date[0]) || (parseInt(nepaliMaxDate[0]) == parseInt(date[0]) && parseInt(nepaliMaxDate[1]) < parseInt(date[1]))) {
                    date = nepaliMaxDate
                    this.setState({
                        disableToday: true,
                    })
                }
            } else {
                this.setState({
                    error: true,
                })
                return
            }
        }
        if (this.props.minDate) {
            let formatDate = moment(this.props.minDate).subtract(1, 'days')
            let nepaliMinDate = convertADtoBS(moment(formatDate).format('yyyy-MM-DD'))
            nepaliMinDate = nepaliMinDate != null ? nepaliMinDate.split('-') : null
            if (nepaliMinDate != null) {
                if (parseInt(nepaliMinDate[0]) > parseInt(date[0]) || (parseInt(nepaliMinDate[0]) == parseInt(date[0]) && parseInt(nepaliMinDate[1]) > parseInt(date[1]))) {
                    date = nepaliMinDate
                    this.setState({
                        disableToday: true,
                    })
                }
            } else {
                this.setState({
                    error: true,
                })
                return
            }
        }

        this.setState(
            {
                currentYear: parseInt(date[0]),
                currentMonth: parseInt(date[1]) - 1,
                currentDay: parseInt(date[2]) - 1,
                currentMonthDays: DateBS.daysInMonth(parseInt(date[0]), parseInt(date[1])),
                startingDay: getBSWeek(parseInt(date[0]) + '-' + parseInt(date[1]) + '-' + '01'),
            },
            () => {
                this.loadDays()
            }
        )
    }

    prevMonth() {
        if (this.props.minDate) {
            let formatDate = moment(this.props.minDate).add(1, 'days')
            let nepaliMinDate = convertADtoBS(moment(formatDate).format('yyyy-MM-DD'))

            nepaliMinDate = nepaliMinDate != null ? nepaliMinDate.split('-') : null
            if (nepaliMinDate != null) {
                if (parseInt(nepaliMinDate[0]) == this.state.currentYear && this.state.currentMonth + 1 == parseInt(nepaliMinDate[1])) {
                    return
                }
            }
        }

        let currentMonth = this.state.currentMonth - 1
        if (currentMonth == -1) {
            currentMonth = 11
            let currentYear = this.state.currentYear - 1
            this.setState(
                {
                    currentMonth,
                    currentYear,
                    currentMonthDays: DateBS.daysInMonth(currentYear, currentMonth + 1),
                    startingDay: getBSWeek(currentYear + '-' + (currentMonth + 1) + '-' + '01'),
                    selectedDate: '',
                },
                () => {
                    this.loadDays()
                    if (this.props.onDateChange != null) {
                        this.props.onDateChange(this.state.selectedDate)
                    }
                }
            )
        } else {
            this.setState(
                {
                    currentMonth,
                    currentMonthDays: DateBS.daysInMonth(this.state.currentYear, currentMonth + 1),
                    startingDay: getBSWeek(this.state.currentYear + '-' + (currentMonth + 1) + '-' + '01'),
                    selectedDate: '',
                },
                () => {
                    this.loadDays()
                    if (this.props.onDateChange != null) {
                        this.props.onDateChange(this.state.selectedDate)
                    }
                }
            )
        }
    }

    nextMonth() {
        if (this.props.maxDate) {
            let formatDate = moment(this.props.maxDate).subtract(1, 'days')
            let nepaliMaxDate = convertADtoBS(moment(formatDate).format('yyyy-MM-DD'))
            nepaliMaxDate = nepaliMaxDate != null ? nepaliMaxDate.split('-') : null

            if (nepaliMaxDate != null) {
                if (parseInt(nepaliMaxDate[0]) == this.state.currentYear && this.state.currentMonth + 1 == parseInt(nepaliMaxDate[1])) {
                    return
                }
            }
        }
        let currentMonth = this.state.currentMonth + 1
        if (currentMonth == 12) {
            currentMonth = 0
            let currentYear = this.state.currentYear + 1
            this.setState(
                {
                    currentMonth,
                    currentYear,
                    currentMonthDays: DateBS.daysInMonth(currentYear, currentMonth + 1),
                    startingDay: getBSWeek(currentYear + '-' + (currentMonth + 1) + '-' + '01'),
                    selectedDate: '',
                },
                () => {
                    this.loadDays()
                    if (this.props.onDateChange != null) {
                        this.props.onDateChange(this.state.selectedDate)
                    }
                }
            )
        } else {
            this.setState(
                {
                    currentMonth,
                    currentMonthDays: DateBS.daysInMonth(this.state.currentYear, currentMonth + 1),
                    startingDay: getBSWeek(this.state.currentYear + '-' + (currentMonth + 1) + '-' + '01'),
                    selectedDate: '',
                },
                () => {
                    this.loadDays()
                    if (this.props.onDateChange != null) {
                        this.props.onDateChange(this.state.selectedDate)
                    }
                }
            )
        }
    }

    loadDays() {
        let starting = days.find((item) => item.label == this.state.startingDay)
        let week = days != null ? starting.value : 0
        let weekCount = 0
        let calendar = []
        if (week != 0) {
            calendar.push([0, 0, 0, 0, 0, 0, 0])
        }
        for (let i = 1; i <= this.state.currentMonthDays; i++) {
            if (week == 0) {
                calendar.push([0, 0, 0, 0, 0, 0, 0])
            }
            calendar[weekCount][week] = i
            if (week == 6) {
                week = 0
                weekCount++
            } else {
                week++
            }
        }

        this.setState({
            calendar,
        })
    }

    componentDidMount() {
        this.today()
    }
}
