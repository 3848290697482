import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentCategoryEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input } from '~/components/form'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class StudentCategoryEditComponent extends AbstractStudentCategoryEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.user.student.category.view}>{t('student:student-category.breadcrumb')} > </a>
                            </li>
                            <li>{t('student:student-category.edit.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('student:student-category.edit.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student-category.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('student:student-category.add.namePlaceholder')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student-category.add.codeLabel')} *</label>
                                    <Input name="code" disabled="disabled" placeholder={t('student:student-category.add.codeLabel')} type="text" value={this.state.code} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:updateBtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const StudentCategoryEdit = withTranslation()(connect(mapStateToProps)(StudentCategoryEditComponent))

export { StudentCategoryEdit }
