export const SuperAdmin = 0
export const Custom = 1
export const Admin = 2
export const Student = 3
export const Teacher = 4
export const Parents = 5
export const Alumni = 6

export const all_role = [
    { value: 0, label: 'Superadmin' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Student' },
    { value: 4, label: 'Teacher' },
    { value: 5, label: 'Parents' },
    { value: 1, label: 'Custom' },
    { value: 6, label: 'Alumni' },
]

export const employee_type = [
    { value: 2, label: 'Admin' },
    { value: 4, label: 'Teacher' },
    { value: 1, label: 'Custom' },
]

export const role_type = [
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Student' },
    { value: 4, label: 'Teacher' },
    { value: 5, label: 'Parents' },
    { value: 1, label: 'Custom' },
    { value: 6, label: 'Alumni' },
]

export const filter_role = [
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Student' },
    { value: 4, label: 'Teacher' },
]

export const invite_role = [
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Student' },
    { value: 4, label: 'Teacher' },
    { value: 1, label: 'Custom' },
    { value: 6, label: 'Alumni' },
]
