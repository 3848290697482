import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addSchool, activePackage } from '~/services/api'
import { PackageDTO, SchoolDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    short_code: String,
    sub_domain: String,
    package_id: String,
    email: String,
    dob: string,
    locale: String,
    packages: Object,
    errors: Object,
    loading: Boolean,
    joining_date: String,
    country: String,
    date_format: String,
}

export class AbstractSchoolAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            packages: [],
            name: '',
            short_code: '',
            sub_domain: '',
            email: '',
            dob: '',
            package_id: '',
            locale: '',
            joining_date: '',
            isOnlyApp: '',
            country: '',
            date_format: '',
            errors: {
                name: null,
                short_code: null,
                sub_domain: null,
                package_id: null,
                email: null,
                dob: null,
                locale: null,
                joining_date: null,
                isOnlyApp: null,
                country: null,
                date_format: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadPackages()
    }

    loadPackages() {
        const packages = []
        activePackage(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.package.map((item, index) => {
                    const result: PackageDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    packages.push(data)
                })
                this.setState({ packages })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                short_code: this.state.short_code,
                sub_domain: this.state.sub_domain,
                package_id: this.state.package_id,
                email: this.state.email,
                locale: this.state.locale,
                dob: moment(this.state.dob).format('yyyy-MM-DD'),
                joining_date: moment(this.state.dob).format('yyyy-MM-DD'),
                isOnlyApp: this.state.isOnlyApp,
                country: this.state.country,
                date_format: this.state.date_format,
            }
            this._handleChange('loading', true)
            addSchool(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.school.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
