import React from 'react'
import { connect } from 'react-redux'
import { AbstractBankAccountEditComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class BankAccountEditComponent extends AbstractBankAccountEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="banking" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.banking.menu}>
                                    {t('banking:banking')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.banking.account.view}>
                                    {t('banking:managebankaccount')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('banking:editBanking')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('banking:bankholdername')} * </label>
                                    <Input
                                        name="holder_name"
                                        onChange={this._handleChange}
                                        value={this.state.holder_name}
                                        placeholder={t('banking:bankholdername')}
                                        type="text"
                                    />
                                    {this.state.errors.holder_name && (
                                        <label className="error text-danger">{this.state.errors.holder_name}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('banking:bankname')} * </label>
                                    <Input
                                        name="bank_name"
                                        type="text"
                                        onChange={this._handleChange}
                                        placeholder={t('banking:bankname')}
                                        value={this.state.bank_name}
                                    />
                                    {this.state.errors.bank_name && (
                                        <label className="error text-danger">{this.state.errors.bank_name}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('banking:accountnumber')} *</label>
                                    <Input
                                        name="account_number"
                                        onChange={this._handleChange}
                                        placeholder={t('banking:accountnumber')}
                                        type="text"
                                        value={this.state.account_number}
                                    />
                                    {this.state.errors.account_number && (
                                        <label className="error text-danger">{this.state.errors.account_number}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('banking:openingbalance')} * </label>
                                    <Input
                                        name="opening_balance"
                                        onChange={this._handleChange}
                                        type="text"
                                        placeholder={t('banking:openingbalance')}
                                        value={this.state.opening_balance}
                                    />
                                    {this.state.errors.opening_balance && (
                                        <label className="error text-danger">{this.state.errors.opening_balance}</label>
                                    )}
                                </div>
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('banking:contactnumber')} * </label>
                                    <Input
                                        name="contact_number"
                                        onChange={this._handleChange}
                                        type="text"
                                        placeholder={t('banking:contactnumber')}
                                        value={this.state.contact_number}
                                    />
                                    {this.state.errors.contact_number && (
                                        <label className="error text-danger">{this.state.errors.contact_number}</label>
                                    )}
                                </div>

                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('banking:bankaddresss')} *</label>
                                    <TextArea
                                        name="bank_address"
                                        onChange={this._handleChange}
                                        placeholder={t('banking:bankaddresss')}
                                        value={this.state.bank_address}
                                    />
                                    {this.state.errors.bank_address && (
                                        <label className="error text-danger">{this.state.errors.bank_address}</label>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}
const BankAccountEdit = withTranslation()(connect(mapStateToProps)(BankAccountEditComponent))
export { BankAccountEdit }
