import moment from 'moment'
import { Component } from 'react'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewExpenseSummary } from '../../../../../services/api/accounting/report'
import { getAllSchoolUser } from '../../../../../services/api/users'
import { Teacher, Admin, Custom } from '~/helper/constant/user_type'

const defaultCategory = {
    label: 'All',
    value: '',
}

const defaultUser = {
    label: 'All',
    value: '',
}

export class AbstractExpenseSummaryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            year: moment().startOf('month').toDate(),
            duration: null,
            paymentList: [],
            billList: [],
            userList: [defaultUser],
            user: defaultUser,
            categoryList: [defaultCategory],
            category: defaultCategory,
            errors: {
                year: null,
            },
            monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chartData: [],
            transactions: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }
    componentDidMount() {
        this.loadUser()
        this._onSearch()
    }

    loadUser() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getAllSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const userList = [defaultUser]
                response.data.users.filter((item)=>item.user_type == Teacher || item.user_type == Custom || item.user_type == Admin).map((item) => {
                    const data = {
                        label: item.user.name ? item.user.reg_no + ' - ' + item.user.name : item.user.reg_no,
                        value: item.customer_account_id,
                    }
                    userList.push(data)
                })
                console.log(userList)
                this.setState({
                    userList,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const year =
                convertADtoBS(this.state.year) != null
                    ? convertADtoBS(this.state.year).slice(0, -6) + ' BS'
                    : 'Invalid Date Range'
            this.setState({
                duration: year,
            })
        } else {
            const year = moment(this.state.year).format('yyyy-MM-DD')
            this.setState({
                duration: year.slice(0, -6),
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const year = moment(this.state.year).format('yyyy')
            const customer = this.state.user != null ? this.state.user.value : ''
            const category = this.state.category != null ? this.state.category.value : ''

            viewExpenseSummary(year, category, customer, this.props.accountToken).then((response) => {
                this._handleChange('loading', false)
                const accountList = []
                const categoryList = []
                if (response.code == API_SUCCESS) {
                    const expenseData = response.data.data
                    const categoryData = expenseData.category
                    const categoryList = []
                    Object.keys(categoryData).map((item) => {
                        categoryList.push({
                            label: categoryData[item],
                            value: item,
                        })
                    })
                    this.setState(
                        {
                            categoryList,
                            paymentList: expenseData.expenseArr,
                            billList: expenseData.billArray,
                            chartData: expenseData.chartExpenseArr,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accountToken: state.school.accounting_token,
        school: state.school.school,
    }
}
