import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { Loading } from '../../../../../components/loader/loader'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractLedgerSummaryComponent, mapStateToProps } from './abstract'
class LedgerSummaryComponent extends AbstractLedgerSummaryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setStartDate = this._setStartDate.bind(this)
        this._setEndDate = this._setEndDate.bind(this)
        this._setAccount = this._setAccount.bind(this)
    }

    _setStartDate(data) {
        this._handleChange('start_date', data)
    }
    _setEndDate(data) {
        this._handleChange('end_date', data)
    }

    _setAccount(data) {
        this._handleChange('account', data)
    }

    getJournalItem() {
        let balance = 0
        let credit = 0
        let debit = 0
        const data = this.state.journalItems.map((item, index) => {
            const created_at = moment(item.created_at).format('yyyy-MM-DD')
            if (item.debit > 0) {
                debit += parseFloat(item.debit)
            } else {
                credit += parseFloat(item.credit)
            }
            balance = credit - debit
            return (
                <tr>
                    <td>{index + 1}</td>
                    <td>
                        {this.props.school.date_format == 'BS'
                            ? convertADtoBS(item.transaction_date)
                            : item.transaction_date}
                    </td>
                    <td>{this.props.school.date_format == 'BS' ? convertADtoBS(created_at) : created_at}</td>
                    <td>{item.description}</td>
                    <td>
                        <small>{this.props.school.currency}</small>
                        &nbsp;
                        {parseFloat(item.debit).toFixed(2)}
                    </td>
                    <td>
                        <small>{this.props.school.currency}</small>
                        &nbsp;
                        {parseFloat(item.credit).toFixed(2)}
                    </td>
                    <td className={balance < 0 ? 'negative-amount' : ''}>
                        <small>{this.props.school.currency}</small>
                        &nbsp;
                        {balance < 0 ? (balance * -1).toFixed(2) : balance.toFixed(2)}
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="double_entry" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('doubleentry:ledgersummary')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:ledgersummary')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={
                                            this.props.school.short_code + '-' + t('doubleentry:ledgersummary')
                                        }
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="timetable-form mb-5">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.start date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                defaultValue={this.state.start_date}
                                                selected={this.state.start_date}
                                                onChange={this._setStartDate}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.start_date != ''
                                                        ? convertADtoBS(this.state.start_date) != null
                                                            ? convertADtoBS(this.state.start_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.start_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.start_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.end date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.end date')}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                defaultValue={this.state.end_date}
                                                selected={this.state.end_date}
                                                onChange={this._setEndDate}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.end_date != ''
                                                        ? convertADtoBS(this.state.end_date) != null
                                                            ? convertADtoBS(this.state.end_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.end_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.end_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('sidebar:account')} :</label>
                                            <Select
                                                name="account"
                                                options={this.state.chartOfAccounts}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.account}
                                                onChange={this._setAccount}
                                            />
                                            {this.state.errors.account && (
                                                <label className="error text-danger">{this.state.errors.account}</label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <button className="main-button" onClick={() => this._onSearch()}>
                                                {t('attendance:filter')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={7} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('doubleentry:ledgersummary')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={7} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                                <div className="invoice_detail">
                                                                    <strong>{t('doubleentry:accountname')} : </strong>
                                                                    <br />
                                                                    {this.state.selectedAccount != null
                                                                        ? this.state.selectedAccount
                                                                        : ''}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border invoice_table_header_dark">
                                                            <td>
                                                                <strong>{t('academic:table.s.no')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {t('reports:transactionDate')}{' '}
                                                                    {this.props.school.date_format == 'BS' && (
                                                                        <small>( B.S )</small>
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {t('reports:createdAt')}{' '}
                                                                    {this.props.school.date_format == 'BS' && (
                                                                        <small>( B.S )</small>
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong> {t('common:description')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong> {t('doubleentry:debit')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong> {t('doubleentry:credit')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong> {t('reports:balance')}</strong>
                                                            </td>
                                                        </tr>
                                                        {this.state.journalItems.length == 0 && (
                                                            <tr>
                                                                <td colSpan={7}>
                                                                    <p className="no-data">
                                                                        {SVG.nodata()}
                                                                        {t('common:table.nodata')}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {this.state.journalItems.length > 0 && this.getJournalItem()}
                                                        <tr className="invoice_total_border invoice_table_header_dark">
                                                            <td colSpan={4} className="text-right">
                                                                <strong>{t('accounting:total')} :</strong>{' '}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            </td>
                                                            <td
                                                                className={
                                                                    this.state.totalAmount['debit'] < 0
                                                                        ? 'negative-amount'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;
                                                                <strong>
                                                                    {this.state.totalAmount['debit'] < 0
                                                                        ? parseFloat(
                                                                              this.state.totalAmount['debit'] * -1
                                                                          ).toFixed(2)
                                                                        : parseFloat(
                                                                              this.state.totalAmount['debit']
                                                                          ).toFixed(2)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className={
                                                                    this.state.totalAmount['credit'] < 0
                                                                        ? 'negative-amount'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;
                                                                <strong>
                                                                    {this.state.totalAmount['credit'] < 0
                                                                        ? parseFloat(
                                                                              this.state.totalAmount['credit'] * -1
                                                                          ).toFixed(2)
                                                                        : parseFloat(
                                                                              this.state.totalAmount['credit']
                                                                          ).toFixed(2)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className={
                                                                    this.state.totalAmount['balance'] < 0
                                                                        ? 'negative-amount'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;
                                                                <strong>
                                                                    {this.state.totalAmount['balance'] < 0
                                                                        ? parseFloat(
                                                                              this.state.totalAmount['balance'] * -1
                                                                          ).toFixed(2)
                                                                        : parseFloat(
                                                                              this.state.totalAmount['balance']
                                                                          ).toFixed(2)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const LedgerSummary = withTranslation()(connect(mapStateToProps)(LedgerSummaryComponent))

export { LedgerSummary }
