import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractProfileComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { gender } from '~/helper/constant/gender'
import { country } from '~/helper/constant/country'
import { blood_group } from '~/helper/constant/blood_group'
import * as Status from '~/helper/constant/status'
import { Input, Button, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import Select from 'react-select'
import Files from 'react-files'
import { getBase64, getReadableFileSize } from '~/helper/function'
import MultiImageInput from 'react-multiple-image-input'
import { withTranslation } from 'react-i18next'
import * as ReactTable from 'react-super-responsive-table'
import DatePicker from 'react-datepicker'

class ProfileComponent extends AbstractProfileComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setGender = this._setGender.bind(this)
        this._setCountry = this._setCountry.bind(this)
        this._setBloodGroup = this._setBloodGroup.bind(this)
        this.previewDocument = this.previewDocument.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this._deleteFile = this._deleteFile.bind(this)
        this._loadDocuments = this._loadDocuments.bind(this)
        this._setProfile = this._setProfile.bind(this)
        this._setGroup = this._setGroup.bind(this)
        this._setCompany = this._setCompany.bind(this)
        this._setDesignation = this._setDesignation.bind(this)
    }

    _setGroup(data) {
        this.setState({
            selectedGroup: data,
            alumni_group_id: data.value,
        })
    }

    _setCompany(data) {
        this.setState({
            selectedCompany: data,
            alumni_company_id: data.value,
        })
    }

    _setDesignation(data) {
        this.setState({
            selectedDesignation: data,
            alumni_designation_id: data.value,
        })
    }

    _setProfile(data) {
        this._handleChange('profile', data)
    }

    _setGender(data) {
        this._handleChange('gender', data.value)
        this._handleChange('defaultGender', data)
    }

    _setCountry(data) {
        this._handleChange('country', data.value)
        this._handleChange('defaultCountry', data)
    }

    _setBloodGroup(data) {
        this._handleChange('blood_group', data.value)
        this._handleChange('defaultBloodGroup', data)
    }

    _uploadFile(data) {
        const media = this.state.media
        const uploadedFile = data[data.length - 1]
        getBase64(uploadedFile).then((file) => {
            if (file.error == null) {
                media.push(file)
                this._handleChange('media', media)
                this._handleChange('uploads', data)
            }
        })
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }

    _deleteFile(id) {
        let deletedMedia = this.state.deletedMedia
        let uploadedMedia = this.state.uploadedMedia.filter((item) => item.id != id)
        deletedMedia.push(id)
        this._handleChange('deletedMedia', deletedMedia)
        this._handleChange('uploadedMedia', uploadedMedia)
    }

    _loadDocuments() {
        if (this.state.uploadedMedia.length > 0) {
            const media = this.state.uploadedMedia.map((item, index) => {
                const extension = item.media_type.split('/')
                return (
                    <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                        <div className="gsEyTH files-list-item">
                            <a href={item.media_url} target="_blank">
                                <div className="files-list-item-preview">
                                    <div className="files-list-item-preview-extension">{extension[1]}</div>
                                </div>
                                <div className="files-list-item-content">
                                    <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                                    <div className="files-list-item-content-item files-list-item-content-item-2">{getReadableFileSize(item.size)}</div>
                                </div>
                            </a>
                            <div
                                id={item.id}
                                className="files-list-item-remove"
                                onClick={() => this._deleteFile(item.id)} // eslint-disable-line
                            />
                        </div>
                    </div>
                )
            })
            return media
        } else {
            const { t } = this.props
            return <div className="gallery-message">{t('common:uploaddocumentdetail')}</div>
        }
    }

    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    render() {
        const { t } = this.props
        let check = true
        if (this.props.school == null) {
            check = false
        } else if (this.props.school.active_user_academics == null && this.props.school.user_type == UserType.Student) {
            check = false
        } else if (this.props.student == null && this.props.school.user_type == UserType.Parents) {
            check = false
        } else if (this.props.school.status != Status.ACTIVE) {
            check = false
        } else if (this.props.school.school != null && this.props.school.school.setup == false) {
            check = false
        } else {
            check = true
        }
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                {check == true && <Sidebar id="" />}
                <div className={check == true ? 'main-content' : 'main-content setup-main'}>
                    {check == true && <Header />}
                    {check == false && <Header className="setup-nav" hideToggle={true} />}
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            {check == true && (
                                <li>
                                    <a href={route.activity}>{t('setting:dashboard')} &gt; </a>
                                </li>
                            )}
                            <li>{t('header:myprofile')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('header:myprofile')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="clearfix">
                                <div className="float-left form-group profile-container">
                                    <label className="form-title">{t('setting:profilepicture')}</label>
                                    <MultiImageInput images={this.state.profile} max={1} setImages={this._setProfile} allowCrop={false} theme="light" />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('name')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('name')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('contact')} *</label>
                                    <Input name="contact" onChange={this._handleChange} placeholder={t('contact')} type="text" value={this.state.contact} />
                                    {this.state.errors.contact && <label className="error text-danger">{this.state.errors.contact}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fathername')} *</label>
                                    <Input name="father_name" onChange={this._handleChange} placeholder={t('fathername')} type="text" value={this.state.father_name} />
                                    {this.state.errors.father_name && <label className="error text-danger">{this.state.errors.father_name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('mothername')} *</label>
                                    <Input name="mother_name" onChange={this._handleChange} placeholder={t('mothername')} type="text" value={this.state.mother_name} />
                                    {this.state.errors.mother_name && <label className="error text-danger">{this.state.errors.mother_name}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('gender')} *</label>
                                    <Select name="gender" options={gender} placeholder={t('gender')} searchable={true} value={this.state.defaultGender} onChange={this._setGender} />
                                    {this.state.errors.gender && <label className="error text-danger">{this.state.errors.gender}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('country')} *</label>
                                    <Select name="country" options={country} placeholder={t('country')} searchable={true} value={this.state.defaultCountry} onChange={this._setCountry} />
                                    {this.state.errors.country && <label className="error text-danger">{this.state.errors.country}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('permanentaddress')} *</label>
                                    <Input name="permanent_address" onChange={this._handleChange} placeholder={t('permanentaddress')} type="text" value={this.state.permanent_address} />
                                    {this.state.errors.permanent_address && <label className="error text-danger">{this.state.errors.permanent_address}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('temporaryaddress')}</label>
                                    <Input name="temporary_address" onChange={this._handleChange} placeholder={t('temporaryaddress')} type="text" value={this.state.temporary_address} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('bloodgroup')}</label>
                                    <Select name="blood_group" options={blood_group} placeholder={t('bloodgroup')} searchable={true} value={this.state.defaultBloodGroup} onChange={this._setBloodGroup} />
                                </div>
                            </div>
                            {this.props.school.user_type == UserType.Alumni && (
                                <Fragment>
                                    <hr />
                                    <p>{t('alumni:currentmsg')}</p>
                                    <hr />
                                    <div className="row clearfix">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('alumni:faculty')} *</label>
                                                <Select name="alumni_group_id" options={this.state.groupList} placeholder={t('alumni:faculty')} searchable={true} value={this.state.selectedGroup} onChange={this._setGroup} />
                                                {/* {this.state.alumni_errors.alumni_group_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_group_id}</label>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('alumni:company')} *</label>
                                                <Select name="alumni_company_id" options={this.state.companyList} placeholder={t('alumni:company')} searchable={true} value={this.state.selectedCompany} onChange={this._setCompany} />
                                                {/* {this.state.alumni_errors.alumni_company_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_company_id}</label>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('alumni:designation')} *</label>
                                                <Select name="alumni_designation_id" options={this.state.designationList} placeholder={t('alumni:designation')} searchable={true} value={this.state.selectedDesignation} onChange={this._setDesignation} />
                                                {/* {this.state.alumni_errors.alumni_designation_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_designation_id}</label>} */}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <p>
                                        {t('alumni:academicQualification')}{' '}
                                        <button type="button" className="main-button" onClick={(e) => this.addQualification()}>
                                            +
                                        </button>
                                    </p>
                                    <hr />
                                    {this.state.qualification.map((item, index) => {
                                        if (item.deleted == false) {
                                            return (
                                                <div className="row">
                                                    <div className="col-md-2 form-group">
                                                        <DatePicker dateFormat="yyyy-MM-dd" selected={item.from} onChange={(date) => this.setQualificationFromDate(index, date)} className="form-control" placeholderText={t('common:fromdate')} />
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <DatePicker dateFormat="yyyy-MM-dd" selected={item.to} onChange={(date) => this.setQualificationToDate(index, date)} className="form-control" placeholderText={t('common:todate')} />
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <Input name="description" value={item.description} onChange={(key, value) => this.setQualificationDescription(index, value)} placeholder={t('alumni:degree')} type="text" />
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <button type="button" className="main-button" onClick={(e) => this.addQualification()}>
                                                            +
                                                        </button>
                                                        <button type="button" className="main-button danger" onClick={(e) => this.removeQualification(index)}>
                                                            -
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <hr />
                                    <p>
                                        {t('alumni:experience')}
                                        <button type="button" className="main-button" onClick={(e) => this.addExperience()}>
                                            +
                                        </button>
                                    </p>
                                    <hr />
                                    {this.state.experience.map((item, index) => {
                                        if (item.deleted == false) {
                                            return (
                                                <div className="row">
                                                    <div className="col-md-2 form-group">
                                                        <DatePicker dateFormat="yyyy-MM-dd" selected={item.from} onChange={(date) => this.setExperienceFromDate(index, date)} className="form-control" placeholderText={t('common:fromdate')} />
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <DatePicker dateFormat="yyyy-MM-dd" selected={item.to} disabled={item.isPresent} onChange={(date) => this.setExperienceToDate(index, date)} className="form-control" placeholderText={t('common:todate')} />
                                                        <div className="form-check">
                                                            <Checkbox name="isPresent" checked={item.isPresent} value={item.isPresent} onChange={(key, value) => this.setExperienceIsPresent(index, value)} />
                                                            <label className="form-check-label form-title small-checkbox">{t('alumni:present')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 form-group">
                                                        <Input name="description" value={item.description} onChange={(key, value) => this.setExperienceDescription(index, value)} placeholder={t('alumni:designation')} type="text" />
                                                    </div>
                                                    <div className="col-md-3 form-group">
                                                        <Input name="company" placeholder={t('alumni:company')} value={item.company} type="text" onChange={(key, value) => this.setExperienceCompany(index, value)} />
                                                    </div>
                                                    <div className="col-md-2 form-group">
                                                        <button type="button" className="main-button" onClick={(e) => this.addExperience()}>
                                                            +
                                                        </button>
                                                        <button type="button" className="main-button danger" onClick={(e) => this.removeExperience(index)}>
                                                            -
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <hr />
                                </Fragment>
                            )}
                            <div className="row clearfix">
                                <div className="col-md-12 float-left form-group">
                                    <label className="form-title">
                                        {t('uploaddocuments')} {t('common:onlypdf')}
                                    </label>
                                    <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                        {this.previewDocument()}
                                        <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                            <Files className="files-dropzone" onChange={this._uploadFile} accepts={['.pdf']} multiple maxFiles={5} maxFileSize={10000000} minFileSize={0} clickable>
                                                {t('adddocument')}
                                            </Files>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('document')} </label>
                                    <div className="gallery-container">{this._loadDocuments()}</div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const Profile = withTranslation()(connect(mapStateToProps)(ProfileComponent))
export { Profile }
