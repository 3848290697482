import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSetupComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Button, Input, Checkbox } from '~/components/form'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

class SetupComponent extends AbstractSetupComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._startDate = this._startDate.bind(this)
        this._endDate = this._endDate.bind(this)
    }

    _startDate(data) {
        this._handleChange('from_date', data)
    }

    _endDate(data) {
        this._handleChange('to_date', data)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content setup-main">
                    <Header className="setup-nav" hideToggle={true} />
                    <div className="row clearfix">
                        <div className="col-md-6 offset-md-3">
                            <div className="school-detail">
                                <img src="/assets/images/mobile-logo.png" />
                            </div>
                            <br />
                            <div className="setup-container">
                                <h5>Academic Year Setup</h5>
                                <hr />
                                <br />
                                <form>
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-title text-left">Name *</label>
                                                <Input onChange={this._handleChange} name="name" placeholder="Academic Year Name" type="text" value="" />
                                                {this.state.errors.name && <label className="error text-danger setup-error text-left">{this.state.errors.name}</label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">Start Date *</label>
                                                <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._startDate} selected={this.state.from_date} placeholderText="Start date of the year*" />
                                                {this.state.errors.from_date && <label className="error text-danger setup-error text-left">{this.state.errors.from_date}</label>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">End Date *</label>
                                                <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._endDate} selected={this.state.to_date} placeholderText="End date of the year*" />
                                                {this.state.errors.to_date && <label className="error text-danger setup-error  text-left">{this.state.errors.to_date}</label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group text-left">
                                                <Button onClick={this._onSave} type="submit" text="Save" main={true} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Setup = connect(mapStateToProps)(SetupComponent)

export { Setup }
