import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { addAcademic } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    loading: Boolean,
    errors: Object,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    from_date: String,
    to_date: String,
    loading: Boolean,
}

export class AbstractAcademicAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loading: false,
            name: '',
            from_date: '',
            to_date: '',
            errors: {
                name: null,
                from_date: null,
                to_date: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    checkDate(start_date, end_date) {
        const start = moment(start_date, 'yyyy-MM-DD')
        const end = moment(end_date, 'yyyy-MM-DD')
        if (end.isBefore(start)) {
            return 'Invalid'
        }
        return null
    }

    _onSave(e) {
        e.preventDefault()
        let errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const checkDate = this.checkDate(this.state.from_date, this.state.to_date)
            if (checkDate != null) {
                errors.from_date = checkDate
                errors.to_date = checkDate
                this.setState({ errors })
            }
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    name: this.state.name,
                    from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                    to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
                }
                this._handleChange('loading', true)
                addAcademic(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        redirect(route.admin.academic.view, this.props.history)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
