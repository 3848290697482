import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getInvite = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('invitation-management/invite/view', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getInviteById = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('invitation-management/invite/view/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getInviteByToken = (inviteToken, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('invite/token/' + inviteToken, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const sendInvitation = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('invitation-management/invite/send', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const userRegister = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('register/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const invitationVerify = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('invitation-management/invite/verify/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
