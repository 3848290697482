import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { type_list } from '~/helper/constant/category'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { addProductService, viewProductCategory, viewProductUnit, viewAccountTax } from '~/services/api'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractProductServiceAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            category:[],
            units:[],
            defaultUnit:'',
            selectedUnit:'',
            defaultCategory:'',
            selectedCategory:'',
            name: '',
            sku: '',
            description:'',
            sale_price: '',
            purchase_price: '',
            category_id: '',
            unit_id: '',
            tax:'',
            taxes:[],
            all:false,
            selectedTax:[],
            type_list: type_list,
            defaultType: type_list[0],
            selectedType: type_list[0].value,
            quantity: '',
            errors: {
                name: null,
                sku: null,
                // sale_price: null,
                // purchase_price: null,
                selectedCategory: null,
                selectedUnit: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadProductCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
    


    loadTax() {
        viewAccountTax(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let taxes = []
                if (response.code == API_SUCCESS) {
                    response.data.taxes.map((item, index) => {
                        const data = {
                            label: item.name,
                            value: item.id,
                        }
                        taxes.push(data)
                    })
                    this.setState(
                        {
                            taxes,
                        },
                       
                    )
                }
            })
        
    }

    loadProductCategory() {
        const {t} = this.props
        this._handleChange('loading_text', "Loading")
        this._handleChange('loading', true)

        viewProductCategory(this.props.token).then((response)=> {
            this._handleChange('loading', false)
            
            let category = []
            let defaultCategory = ''
            let selectedCategory = ''
            if(response.code == API_SUCCESS){
                const filteredcategory = response.data.categories.filter((item) => item.type == 0)
                filteredcategory.map((item, index)=>{
                    const data = {
                        label: item.name,
                        value: item.id
                    }
                    category.push(data)

                 })
                 this.setState({
                    category,

                 },
                 ()=>{this.loadProductUnit()})
            }
        })
    }


    loadProductUnit() {
        viewProductUnit(this.props.token).then((response)=> {
            let units = []
            let defaultUnit = ''
            let selectedUnit = ''
            if(response.code == API_SUCCESS){
                response.data.units.map((item, index)=>{

                    const data = {
                        label: item.name,
                        value: item.id
                    }
                    units.push(data)

                 })
                 this.setState({
                    units,


                 },
                 ()=>{
                    this.loadTax()
                 })
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let tax_access = []
                this.state.selectedTax.map((item) => {
                    tax_access.push(item.value)
                })
            const data = {
                name: this.state.name,
                sku: this.state.sku,
                sale_price: 1,
                tax_id:tax_access,
                description: this.state.description,
                purchase_price: 1,
                category_id: this.state.selectedCategory ? this.state.selectedCategory : null ,
                unit_id: this.state.selectedUnit,
                type: this.state.selectedType,
                quantity: 1,
                
            }
            this._handleChange('loading', true)
            addProductService(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.productservice.view)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            }).catch(($e)=>{
                console.log("error", $e)
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
