//TYPE
export const ASSETS = 1
export const LIABILITIES = 2
export const EXPENSE = 3
export const INCOME = 4
export const EQUITY = 5

//SUB TYPE
export const CURRENT_ASSETS = 1
export const FIXED_ASSETS = 2
export const INVENTORY_ASSETS = 3
export const NON_CURRENT_ASSETS = 4
export const PRE_PAYMENT_ASSETS = 5
export const BANK_CASH_ASSETS = 6
export const DEPRECIATION_ASSETS = 7
export const CURRENT_LIABILITY = 8
export const LIABILITY = 9
export const NON_CURRENT_LIABILITY = 10
export const DIRECT_COST = 11
export const EXPENSES = 12
export const REVENUE = 13
export const SALES = 14
export const OTHER_INCOME = 15
export const EQUITIES = 16

export const CHART_OF_ACCOUNT_TYPE = [
    { label: 'Assets', value: ASSETS, translate: 'doubleentry:assets' },
    { label: 'Liabilites', value: LIABILITIES },
    { label: 'Expense', value: EXPENSE },
    { label: 'Income', value: INCOME },
    { label: 'Equity', value: EQUITY },
]

export const CHART_OF_ACCOUNT_SUB_TYPE = [
    { label: 'Current Asset', value: CURRENT_ASSETS, type: ASSETS },
    { label: 'Fixed Asset', value: FIXED_ASSETS, type: ASSETS },
    { label: 'Inventory', value: INVENTORY_ASSETS, type: ASSETS },
    { label: 'Non-current Asset', value: NON_CURRENT_ASSETS, type: ASSETS },
    { label: 'Prepayment', value: PRE_PAYMENT_ASSETS, type: ASSETS },
    { label: 'Bank & Cash', value: BANK_CASH_ASSETS, type: ASSETS },
    { label: 'Depreciation', value: DEPRECIATION_ASSETS, type: ASSETS },
    { label: 'Current Liability', value: CURRENT_LIABILITY, type: LIABILITIES },
    { label: 'Liability', value: LIABILITY, type: LIABILITIES },
    { label: 'Non Current Liability', value: NON_CURRENT_LIABILITY, type: LIABILITIES },
    { label: 'Direct Costs', value: DIRECT_COST, type: EXPENSE },
    { label: 'Expense', value: EXPENSES, type: EXPENSE },
    { label: 'Revenue', value: REVENUE, type: INCOME },
    { label: 'Sales', value: SALES, type: INCOME },
    { label: 'Other Income', value: OTHER_INCOME, type: INCOME },
    { label: 'Equity', value: EQUITIES, type: EQUITY },
]
