import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import { activeClass, getClass, viewAllCourseModule, viewCourse, viewCourseProgress } from '~/services/api'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseViewStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            courseCount: 0,
            activeTab: 0,
            loading: true,
            loading_text: 'Loading',
            courseModule: [],
            courseModuleData: [],
            class_id: '',
            course_progress: [],
            course: [],
            classes: [],
            subject: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourse = this.loadCourse.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
    }

    componentDidMount() {
        this.loadClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        if (this.props.school.user_type == UserType.Student) {
            this.setState(
                {
                    class_id: this.props.school.active_user_academics.section.class_id,
                },
                () => {
                    this.loadSubject()
                }
            )
        } else {
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let section = null
                let classes = null
                if (response.code == API_SUCCESS) {
                    section = response.data.section
                    this.setState(
                        {
                            class_id: section.classes.id,
                        },
                        () => {
                            this.loadSubject()
                        }
                    )
                }
            })
        }
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const course_progress = response.data.course_progress
                this.setState(
                    {
                        course_progress,
                    },
                    () => this.loadCourseModule()
                )
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAllCourseModule(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                let courseModuleData = []
                courseModule.map((item, index) => {
                    const courseModuleSelect = {
                        value: item.id,
                        label: item.name,
                    }
                    courseModuleData.push(courseModuleSelect)
                })
                this.setState(
                    {
                        courseModule,
                        courseModuleData,
                    },
                    () => this.loadCourse()
                )
            }
        })
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourse(this.state.class_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let course = []
                const courseModule = this.state.courseModule
                const course_progress = this.state.course_progress.filter((el) => {
                    return el.user_progress == 2
                })
                response.data.post.map((item, index) => {
                    let courseContentCount = 0
                    let completedCourseContentCount = 0

                    if (item.course) {
                        const currentModule = courseModule.filter((el) => {
                            return el.course_id == item.course.id
                        })
                        currentModule.map((el, i) => {
                            courseContentCount = courseContentCount + el.content.length
                        })
                        if (this.props.school.user_type == UserType.Student) {
                            completedCourseContentCount = course_progress.filter((el) => {
                                return (
                                    el.course_id == item.course.id &&
                                    el.user_school_id == this.props.school.active_user_academics.user_school_id &&
                                    el.user_progress == 2
                                )
                            })
                        } else {
                            completedCourseContentCount = course_progress.filter((el) => {
                                return (
                                    el.course_id == item.course.id &&
                                    el.user_school_id == this.props.student &&
                                    el.user_progress == 2
                                )
                            })
                        }

                        item['courseContentCount'] = item.course && courseContentCount > 0 ? courseContentCount : 1
                        item['completedCourseContentCount'] = item.course ? completedCourseContentCount.length : 0
                    }
                    course.push(item)
                })
                this.setState({
                    course,
                    loaded: true,
                })
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseProgress()
                        }
                    )
                }
            })
        } else {
            this.loadCourse()
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        student: state.student,
    }
}
