import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { getHoliday, deletePost, toggleHoliday } from '~/services/api'
import moment from 'moment'
import $ from 'jquery'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

type Props = {
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    holiday: Object,
    data: Object,
    loading: Boolean,
}

export class AbstractHolidayComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            data: [],
            holiday:
                this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom
                    ? [
                          {
                              title: {
                                  field: 'text',
                                  translate: 'title',
                                  route: false,
                                  sortable: true,
                              },
                              start_date: {
                                  field: 'date',
                                  translate: 'calendar:holiday.add.startDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              end_date: {
                                  field: 'date',
                                  translate: 'calendar:holiday.add.endDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              posted_by: {
                                  field: 'text',
                                  translate: 'calendar:author',
                                  route: false,
                                  sortable: true,
                              },
                              status: {
                                  field: 'badge',
                                  translate: 'common:status',
                                  route: false,
                                  sortable: true,
                              },
                          },
                      ]
                    : [
                          {
                              title: {
                                  field: 'text',
                                  translate: 'title',
                                  route: false,
                                  sortable: true,
                              },
                              start_date: {
                                  field: 'date',
                                  translate: 'calendar:holiday.add.startDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              end_date: {
                                  field: 'date',
                                  translate: 'calendar:holiday.add.endDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              posted_by: {
                                  field: 'text',
                                  translate: 'calendar:author',
                                  route: false,
                                  sortable: true,
                              },
                              status: {
                                  field: 'badge',
                                  translate: 'common:status',
                                  route: false,
                                  sortable: true,
                              },
                              action: {
                                  field: 'button',
                                  translate: 'calendar:holiday.action',
                                  modal: true,
                                  sortable: false,
                              },
                          },
                      ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadHoliday = this.loadHoliday.bind(this)
        this.deleteHoliday = this.deleteHoliday.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadHoliday()
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_status_' + link).trigger('click')
        toggleHoliday(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadHoliday()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    deleteHoliday(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        deletePost(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadHoliday()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadHoliday() {
        this._handleChange('loading', true)
        let holiday = []
        let data = []
        holiday.push(this.state.holiday[0])
        getHoliday(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.post.map((item, index) => {
                    let status = {
                        text: 'Draft',
                        translate: 'calendar:holiday.draft',
                        badge: 'table-red',
                        modal: this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom ? '' : item.author.id == this.props.school.id ? 'modal_status_' + item.id : '',
                    }
                    if (item.status == Status.ACTIVE) {
                        status = {
                            text: 'Published',
                            translate: 'calendar:holiday.published',
                            badge: 'table-green',
                            modal: this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom ? '' : item.author.id == this.props.school.id ? 'modal_status_' + item.id : '',
                        }
                    }
                    let result = {
                        title: {
                            text: item.description,
                        },
                        start_date: {
                            text: item.from_date,
                        },
                        end_date: {
                            text: item.to_date,
                        },
                        posted_by: {
                            text: item.author.user.name,
                        },
                        status,
                    }
                    if (this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom)) {
                        result['action'] = {
                            text: 'Delete',
                            translate: 'DeleteBtn',
                            modal: 'modal_delete_' + item.id,
                        }
                    }
                    data.push(item)
                    holiday.push(result)
                })
                this.setState({
                    holiday,
                    data,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
}

const AbstractHoliday = withTranslation()(AbstractHolidayComponent)

export { AbstractHoliday }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
