import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { SchoolRoute } from './guard'
import * as SharedComponent from '~/views/shared'
import * as AdminComponent from '~/views/admin'
import * as AccessModule from '~/helper/constant/modules'
import { BigBlueButton } from '~/views/bbb'
import { SchoolProfile } from '~/views/setting'

export const Common = () => [
    <SchoolRoute exact key="1" path={configRoute(route.user_profile + '/:id')} component={SharedComponent.UserProfile} />,
    /* SCHOOL PROFILE  */
    <SchoolRoute exact key="2" path={configRoute(route.admin.school.profile) + '/:id'} component={SchoolProfile} />,
    <SchoolRoute exact key="3" path={configRoute(route.activity)} component={SharedComponent.Activity} />,
    <SchoolRoute exact key="4" path={configRoute(route.activity + '/:id')} component={SharedComponent.PostView} />,
    <SchoolRoute exact key="5" access={[AccessModule.Module_Calendar_Management]} subAccess={AccessModule.EVENT} path={configRoute(route.admin.event.schedule + '/:id')} component={SharedComponent.Schedule} />,
    <SchoolRoute exact key="6" path={configRoute(route.calendar + '/:initial?')} access={[AccessModule.Module_Calendar_Management]} component={SharedComponent.Calendar} />,
    /* ATTENDANCE */
    <SchoolRoute exact key="7" access={[AccessModule.Module_Student_Management, AccessModule.Module_Employee_Management]} path={configRoute(route.report.attendance)} component={AdminComponent.AttendanceReport} />,

    /* DRIVE  */
    <SchoolRoute exact key="8" access={[AccessModule.Module_Drive]} subAccess={AccessModule.DRIVE} path={configRoute(route.drive.view)} component={SharedComponent.Drive} />,
    <SchoolRoute exact key="9" access={[AccessModule.Module_Drive]} subAccess={AccessModule.DRIVE} path={configRoute(route.drive.folder + '/:id')} component={SharedComponent.DriveFolder} />,

    /* TODO  */
    <SchoolRoute exact key="10" access={[AccessModule.Module_Task_Management]} subAccess={AccessModule.TODO} path={configRoute(route.todo.view)} component={SharedComponent.Todo} />,
    <SchoolRoute exact key="12" access={[AccessModule.Module_Task_Management]} subAccess={AccessModule.TODO} path={configRoute(route.todo.detail + '/:id')} component={SharedComponent.TodoView} />,
    /* EXAM  */
    <SchoolRoute exact key="13" access={[AccessModule.Module_Exam_Management]} path={configRoute(route.examination.menu)} component={SharedComponent.ExamMenu} />,
    <SchoolRoute exact key="14" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.EXAM} path={configRoute(route.examination.exam.view)} component={SharedComponent.ExamView} />,
    <SchoolRoute exact key="15" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.EXAM} path={configRoute(route.examination.exam.routine.schedule + '/:id' + '/:classId')} component={SharedComponent.RoutineSchedule} />,
    <SchoolRoute exact key="16" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.QUESTION_BANK} path={configRoute(route.examination.question_bank.menu)} component={SharedComponent.QuestionBankMenu} />,
    <SchoolRoute exact key="17" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.QUESTION_BANK} path={configRoute(route.examination.question_bank.view + '/:id')} component={SharedComponent.QuestionBankView} />,
    <SchoolRoute exact key="18" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.QUESTION_BANK} path={configRoute(route.examination.question_bank.question.view + '/:id')} component={SharedComponent.Question} />,
    <SchoolRoute exact key="19" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.EXAM} path={configRoute(route.online + '/:id')} component={SharedComponent.OnlineExam} />,
    /* Result  */
    <SchoolRoute exact key="20" access={[AccessModule.Module_Exam_Management]} subAccess={AccessModule.RESULT} path={configRoute(route.examination.result.view)} component={SharedComponent.ResultView} />,
    /* EVENT  */
    <SchoolRoute exact key="21" access={[AccessModule.Module_Calendar_Management]} subAccess={AccessModule.HOLIDAY} path={configRoute(route.admin.holiday.view)} component={SharedComponent.Holiday} />,
    <SchoolRoute exact key="22" access={[AccessModule.Module_Calendar_Management]} subAccess={AccessModule.EVENT} path={configRoute(route.admin.event.view)} component={SharedComponent.Event} />,
    /* Chat  */
    <SchoolRoute exact key="23" access={[AccessModule.Module_Chat]} subAccess={AccessModule.CHAT} path={configRoute(route.chat) + '/:id?'} component={SharedComponent.Chat} />,
    /*Gallery  */
    <SchoolRoute exact key="24" path={configRoute(route.gallery) + '/:id'} component={SharedComponent.Gallery} />,
    /* Zoom */
    <SchoolRoute exact key="25" path={configRoute(route.bbb) + '/:id'} component={BigBlueButton} />,
    /* Leaderboard */
    <SchoolRoute exact key="26" access={[AccessModule.Module_Gamification]} path={configRoute(route.leaderboard.view)} component={SharedComponent.LeaderBoard} />,
]
