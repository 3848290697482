import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractScheduleComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Dashboard } from '~/components/dashboard'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import { Loading } from '~/components/loader'
import moment from 'moment'
import Select from 'react-select'
import { timetable_color } from '~/helper/constant/color'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
class ScheduleComponent extends AbstractScheduleComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setStartTime = this._setStartTime.bind(this)
        this._setEndTime = this._setEndTime.bind(this)
        this._selectDays = this._selectDays.bind(this)
        this.getDate = this.getDate.bind(this)
        this.getSchedule = this.getSchedule.bind(this)
        this.getEvent = this.getEvent.bind(this)
        this.getScheduleModal = this.getScheduleModal.bind(this)
        this.getLiveModal = this.getLiveModal.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getEditModal = this.getEditModal.bind(this)
        this._selectModerator = this._selectModerator.bind(this)
    }

    _setStartTime(data) {
        this._handleChange('start_time', data)
    }

    _setEndTime(data) {
        this._handleChange('end_time', data)
    }

    _selectDays(data) {
        this._handleChange('days', data)
    }

    _selectModerator(data) {
        this._handleChange('moderator', data)
    }

    getDeleteModal() {
        const { t } = this.props

        if (this.state.post.event && this.state.post.event.length > 0) {
            const modal = this.state.post.event.map((item, index) => {
                return (
                    <div key={index} data-id="modal_delete" id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('calendar:event.view.deleteScheduleAlert')}</p>
                                    <p className="delete-file-name">"{item.start_time + ' - ' + item.end_time}"</p>
                                    <p className="delete-file-name">"{item.description}"</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._delete} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                    <button id={'close_delete_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getLiveModal() {
        const { t } = this.props

        if (this.state.post.event && this.state.post.event.length > 0) {
            const modal = this.state.post.event.map((item, index) => {
                let text = t('calendar:stop')
                if (item.status != Status.ACTIVE) {
                    text = t('calendar:start')
                }
                return (
                    <div key={index} id={'modal_toggle_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">
                                        {t('calendar:eventStart_msg')} {text} {t('calendar:calendar-menu.eventBtn')}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._toggle} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                    <button id={'close_toggle_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getJoinModal() {
        const { t } = this.props

        if (this.state.post.event && this.state.post.event.length > 0) {
            const modal = this.state.post.event.map((item, index) => {
                return (
                    <div key={index} id={'modal_join_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('calendar:eventJoin')}</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    {item.bbb != null && <Button onClick={this._join} link={item.id + ',' + item.bbb.id} type="submit" text={t('common:modal confirm')} main={true} />}
                                    <button id={'close_join_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }
    getEditModal() {
        const { t } = this.props
        if (this.state.post.event && this.state.post.event.length > 0) {
            const modal = this.state.post.event.map((item, index) => {
                return (
                    <div key={index} id={'modal_edit_' + item.id} data-id="modal_edit" data-target={index} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('calendar:event.schedule.edit.title')}:</span>
                            </div>
                            <form className="p-0">
                                <div className="modal-content">
                                    <div className="pop-main-content">
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('calendar:event.schedule.add.dateLabel')} *</label>
                                                <Select name="type" options={this.state.date} placeholder={t('calendar:event.schedule.add.datePlaceholder')} searchable={true} value={this.state.days} onChange={this._selectDays} />
                                                {this.state.errors.days && <label className="error text-danger">{this.state.errors.days}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group main-select">
                                                <label className="form-title">{t('calendar:event.schedule.add.startTimeLabel')} *</label>
                                                <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('calendar:event.schedule.add.startTimeLabel')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.start_time} selected={this.state.start_time} onChange={this._setStartTime} />
                                                {this.state.errors.start_time && <label className="error text-danger">{this.state.errors.start_time}</label>}
                                            </div>
                                            <div className="col-sm-6 float-left form-group main-select">
                                                <label className="form-title">{t('calendar:event.schedule.add.endTimeLabel')} *</label>
                                                <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('calendar:event.schedule.add.endTimeLabel')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.end_time} selected={this.state.end_time} onChange={this._setEndTime} />
                                                {this.state.errors.end_time && <label className="error text-danger">{this.state.errors.end_time}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('calendar:event.schedule.add.venueLabel')} *</label>
                                                <Input name="venue" onChange={this._handleChange} placeholder={t('calendar:event.schedule.add.venuePlaceholder')} type="text" value={this.state.venue} />
                                                {this.state.errors.venue && <label className="error text-danger">{this.state.errors.venue}</label>}
                                            </div>
                                        </div>
                                        {/* <div className="form-check form-group">
                                            <label className="form-title">{t('calendar:event.schedule.add.liveClass')}</label>
                                            <Checkbox id="live_class_status" onChange={this._handleChange} name="live_class_status" value={this.state.live_class_status} />
                                        </div> */}
                                        {this.state.live_class_status == true && (
                                            <Fragment>
                                                <div className="row clearfix">
                                                    <div className="col-sm-12 float-left form-group">
                                                        <label className="form-title">{t('calendar:moderator')} *</label>
                                                        <Select name="type" options={this.state.users} placeholder={t('calendar:moderator')} searchable={true} value={this.state.moderator} onChange={this._selectModerator} />
                                                        {this.state.errors.moderator && <label className="error text-danger">{this.state.errors.moderator}</label>}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('calendar:event.schedule.add.descriptionLabel')} *</label>
                                                <TextArea name="description" onChange={this._handleChange} placeholder={t('calendar:event.schedule.add.descriptionPlaceholder')} type="text" value={this.state.description} />
                                                {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="text-center form-group">
                                        <Button onClick={this._onEdit} link={item.id} type="submit" text={t('common:updateBtn')} main={true} />
                                        <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('common:modal close')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getScheduleModal() {
        const { t } = this.props

        return (
            <div id="modal_schedule_add" data-id="modal_schedule_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('calendar:event.schedule.add.title')}:</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('calendar:event.schedule.add.dateLabel')} *</label>
                                        <Select name="type" options={this.state.date} placeholder={t('calendar:event.schedule.add.datePlaceholder')} searchable={true} value={this.state.days} onChange={this._selectDays} />
                                        {this.state.errors.days && <label className="error text-danger">{this.state.errors.days}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('calendar:event.schedule.add.startTimeLabel')} *</label>
                                        <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('calendar:event.schedule.add.startTimePlaceholder')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.start_time} selected={this.state.start_time} onChange={this._setStartTime} />
                                        {this.state.errors.start_time && <label className="error text-danger">{this.state.errors.start_time}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('calendar:event.schedule.add.endTimeLabel')} *</label>
                                        <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('calendar:event.schedule.add.endTimePlaceholder')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.end_time} selected={this.state.end_time} onChange={this._setEndTime} />
                                        {this.state.errors.end_time && <label className="error text-danger">{this.state.errors.end_time}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title">{t('calendar:event.schedule.add.venueLabel')} *</label>
                                        <Input name="venue" onChange={this._handleChange} placeholder={t('calendar:event.schedule.add.venuePlaceholder')} type="text" value={this.state.venue} />
                                        {this.state.errors.venue && <label className="error text-danger">{this.state.errors.venue}</label>}
                                    </div>
                                </div>
                                {/* <div className="form-check form-group">
                                    <label className="form-title" htmlFor="live_class_status">
                                        {t('calendar:event.schedule.add.liveClass')}
                                    </label>
                                    <Checkbox id="live_class_status" onChange={this._handleChange} name="live_class_status" value={this.state.live_class_status} />
                                </div> */}
                                {this.state.live_class_status == true && (
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('calendar:moderator')} *</label>
                                            <Select name="type" options={this.state.users} placeholder={t('calendar:moderator')} searchable={true} value={this.state.moderator} onChange={this._selectModerator} />
                                            {this.state.errors.moderator && <label className="error text-danger">{this.state.errors.moderator}</label>}
                                        </div>
                                    </div>
                                )}

                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title">{t('calendar:event.schedule.add.descriptionLabel')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('calendar:event.schedule.add.descriptionPlaceholder')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getDate() {
        const { t } = this.props
        const date = this.state.date.map((item, index) => {
            return (
                <li key={index}>
                    <a onClick={() => this._handleChange('activeTab', index)} className={index == this.state.activeTab ? 'active' : ''} id={'day-tab-' + index} data-toggle="tab" href={'#day_' + index} role="tab" aria-controls="day" aria-selected="false">
                        {item.label} - {t('calendar:calendar-menu.day')} {index + 1}
                    </a>
                </li>
            )
        })
        return date
    }

    getSchedule() {
        const schedule = this.state.date.map((item, index) => {
            return (
                <div key={index} className={index == this.state.activeTab ? 'tab-pane fade show active' : 'tab-pane fade show'} id={'day_' + index} role="tabpanel" aria-labelledby={'day-tab-' + index}>
                    <div className="row clearfix w-100">
                        <div className="month-view offset-1 col-md-11">
                            <ul className="list-inline day-view-list">{this.getEvent(item.value)}</ul>
                        </div>
                    </div>
                </div>
            )
        })
        return schedule
    }

    getEvent(value) {
        const { t } = this.props

        if (this.state.post.event && this.state.post.event.length > 0) {
            let i = -1
            let data = this.state.post.event
                .filter((item) => item.date == value)
                .sort((a, b) => {
                    const a_start_time = moment(a.start_time, 'hh:mm a')
                    const b_start_time = moment(b.start_time, 'hh:mm a')
                    return a_start_time.isAfter(b_start_time) ? 1 : -1
                })
                .map((item, index) => {
                    i++
                    if (i == 4) {
                        i = 0
                    }
                    const name = item.moderator != null ? (item.moderator.name != null ? item.moderator.name : item.moderator.reg_no) : null
                    return (
                        <li key={index} className={timetable_color[i]}>
                            <small>{item.start_time}</small>
                            <div className="day-view-detail">
                                <ins>
                                    {item.description} &nbsp;&nbsp;
                                    {item.live_class_status == true && <ins className="new-con">{t('calendar:live')}</ins>}
                                </ins>
                                {item.venue && (
                                    <span>
                                        {t('calendar:venue')} : {item.venue}
                                    </span>
                                )}
                                {item.live_class_status == true && name != null && (
                                    <span>
                                        {t('calendar:moderator')}: {name}
                                    </span>
                                )}
                            </div>
                            {this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom || this.props.school.user_id == item.moderator_id) && (
                                <Fragment>
                                    {item.live_class_status == true && item.status == Status.INACTIVE && (
                                        <button className="start" data-toggle="modal" data-target={'#modal_toggle_' + item.id}>
                                            {t('calendar:start')}
                                        </button>
                                    )}
                                </Fragment>
                            )}
                            {item.live_class_status == true && item.status == Status.ACTIVE && (
                                <button className="start" data-toggle="modal" data-target={'#modal_join_' + item.id}>
                                    {t('calendar:join')}
                                </button>
                            )}
                            {this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="e-d-t">
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                            <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                        </button>
                                        <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                            <button className="dropdown-item" data-toggle="modal" data-target={'#modal_edit_' + item.id} type="button">
                                                {t('common:EditBtn')}
                                            </button>
                                            <button className="dropdown-item" data-toggle="modal" data-target={'#modal_delete_' + item.id}>
                                                {t('calendar:event.view.delete')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </li>
                    )
                })
            if (data.length > 0) {
                return data
            } else {
                return (
                    <p className="no-data">
                        {SVG.nodata()}
                        {t('calendar:event.schedule.noSchedule')}.
                    </p>
                )
            }
        } else {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('calendar:event.schedule.noSchedule')}.
                </p>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="calendar" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.calendar}>{t('calendar:calendar-menu.breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.event.view}>{t('calendar:event.view.breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('calendar:event.schedule.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{this.state.post.description}:</h5>
                            </div>
                            {this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <Button modal="modal_schedule_add" type="button" text={t('calendar:event.schedule.addSchedule')} main={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="event-accordion">
                        <div className="role-pop-head">
                            <ul className="nav" id="myTab" role="tablist">
                                {this.getDate()}
                            </ul>
                        </div>
                        <div className="role-pop-content">
                            <div className="tab-content" id="myTabContent">
                                {this.getSchedule()}
                            </div>
                        </div>
                    </div>
                </div>
                {this.getScheduleModal()}
                {this.getEditModal()}
                {this.getLiveModal()}
                {this.getJoinModal()}
                {this.getDeleteModal()}
            </div>
        )
    }
}

const Schedule = withTranslation()(connect(mapStateToProps)(ScheduleComponent))

export { Schedule }
