import { get, post } from '../request'

export const viewFee = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/billing', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addFee = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/billing/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editFee = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/billing/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFee = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/billing/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFeeByUser = (id, from = null, to = null, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'accounting-management/fee/billing/user/' + id
            if (from != null && to != null) {
                url = url + '/' + from + '/' + to
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFeeByCheque = (cheque_no, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/billing/cheque/' + cheque_no, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateCheque = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/billing/status/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
