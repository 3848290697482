import React from 'react'
import { connect } from 'react-redux'
import { AbstractConstantCategoryViewComponent, mapStateToProps } from './abstract'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { Sidebar } from '~/components/sidebar'
import { withTranslation } from 'react-i18next'
import { Table } from '~/components/table'
import { Loading } from '~/components/loader'

class ConstantCategoryViewComponent extends AbstractConstantCategoryViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="constant" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.constant.menu}>
                                    {t('constant:constant')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('alumni:category')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('constant:manageconstantcategory')}</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.accounting.constant.category.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                    <div className="navtab_pill">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="pill" href="#particular">
                                        {t('doubleentry:particular')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#income">
                                        {t('income:invoice')}
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="pill" href="#expense">
                                        {t('expense:bill')}
                                    </a>
                                </li>
                               

                            </ul>

                            <div className="tab-content">
                                <div id="particular" role="tabpanel" className="tab-pane fade show active">
                                    <div className="main-form">
                                        <Table data={this.state.particular} />
                                    </div>
                                </div>
                                <div id="income" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.income} />
                                    </div>
                                </div>

                                <div id="expense" role="tabpanel" className="tab-pane fade">
                                    <div className="main-form">
                                        <Table data={this.state.expense} />
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                        {/* <Table data={this.state.tableStructure} /> */}
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
const ConstantCategoryView = withTranslation()(connect(mapStateToProps)(ConstantCategoryViewComponent))

export { ConstantCategoryView }
