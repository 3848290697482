import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractResultMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Button } from '~/components/form'
import Select from 'react-select'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'

class ResultMenuComponent extends AbstractResultMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
        this._selectSection = this._selectSection.bind(this)
        this._selectRoutine = this._selectRoutine.bind(this)
    }

    _selectClass(data) {
        this.setState(
            {
                selectedClass: data.value,
                defaultClass: data,
            },
            () => {
                this.loadRoutine()
            }
        )
    }

    _selectSection(data) {
        this.setState({
            selectedSection: data.value,
            defaultSection: data,
        })
    }

    _selectRoutine(data) {
        this.setState({
            selectedRoutine: data.value,
            defaultRoutine: data,
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="exam" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.result.view}>{t('exam:result.categoryTitle')} &gt; </a>
                            </li>
                            <li>{t('exam:result.filter')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{this.state.exam != null ? this.state.exam.title : 'Examination Result'} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('exam:exam.routine.class')} *</label>
                                    <Select name="type" options={this.state.classes} placeholder={t('exam:result.selectClass')} value={this.state.defaultClass} searchable={true} onChange={this._selectClass} />
                                    {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('exam:result.section')} *</label>
                                    <Select name="type" options={this.state.section} placeholder={t('exam:result.selectSection')} value={this.state.defaultSection} searchable={true} onChange={this._selectSection} />
                                    {this.state.errors.selectedSection && <label className="error text-danger">{this.state.errors.selectedSection}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('exam:result.routine')} *</label>
                                    <Select name="type" options={this.state.routine} placeholder={t('exam:result.selectRoutine')} value={this.state.defaultRoutine} searchable={true} onChange={this._selectRoutine} />
                                    {this.state.errors.selectedRoutine && <label className="error text-danger">{this.state.errors.selectedRoutine}</label>}
                                </div>
                            </div>
                            <div className="form-button text-left">
                                <Button onClick={this._onSearch} type="submit" text={t('common:searchBtn')} main={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const ResultMenu = withTranslation()(connect(mapStateToProps)(ResultMenuComponent))

export { ResultMenu }
