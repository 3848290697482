import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass, activeSection, getAllRoutine, getPost } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../../helper/function'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam: Object,
    classes: Array,
    section: Array,
    routine: Array,
    errors: Object,
    selectedClass: Integer,
    defaultClass: Object,
    selectedSection: Integer,
    defaultSection: Object,
    selectedRoutine: Integer,
    defaultRoutine: Object,
}

export class AbstractResultMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            teacherClass: [],
            classes: [],
            selectedClass: '',
            errors: {
                selectedClass: null,
                selectedSection: null,
                selectedRoutine: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadRoutine = this.loadRoutine.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Teacher) {
            this.loadClass()
        } else {
            this.loadExam()
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let teacherClass = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    teacherClass.push(item.id)
                })
            }
            this.setState(
                {
                    teacherClass,
                },
                () => {
                    this.loadExam()
                }
            )
        })
    }

    loadExam() {
        this._handleChange('loading', true)
        getPost(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const classes = []
                if (this.props.school.user_type == UserType.Teacher) {
                    response.data.post.class_access_information
                        .filter((item) => this.state.teacherClass.includes(item.id))
                        .map((item) => {
                            const data = {
                                label: item.name + ' - ' + item.code,
                                value: item.id,
                            }
                            classes.push(data)
                        })
                } else {
                    response.data.post.class_access_information.map((item) => {
                        const data = {
                            label: item.name + ' - ' + item.code,
                            value: item.id,
                        }
                        classes.push(data)
                    })
                }

                this.setState({
                    exam: response.data.post,
                    classes,
                })
            }
        })
    }

    loadRoutine() {
        const { t } = this.props
        this._handleChange('loading', 'Loading')
        this._handleChange('loading', true)
        getAllRoutine(this.state.exam.id, this.state.selectedClass, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let section = []
                let routine = []
                response.data.class.active_section.map((item) => {
                    const section_data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    section.push(section_data)
                })
                response.data.exam.map((item) => {
                    const is_online = item.is_online ? [t('chat:online')] : ''
                    const date = this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && item.exam_date != null ? (convertADtoBS(item.exam_date) != null ? convertADtoBS(item.exam_date) : 'Invalid Date Range') : moment(item.exam_date).format('DD MMM')
                    const routine_data = {
                        label: item.subject.name + ' [ ' + date + ' : ' + item.start_time + ' - ' + item.end_time + ' ] ' + is_online,
                        value: item.id,
                    }
                    routine.push(routine_data)
                })
                this.setState({
                    section,
                    selectedSection: null,
                    defaultSection: null,
                    routine,
                    selectedRoutine: null,
                    defaultRoutine: null,
                })
            }
        })
    }

    _onSearch(e) {
        e.preventDefault()
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            redirect(route.examination.result.assign + '/' + this.state.selectedClass + '/' + this.state.selectedSection + '/' + this.state.selectedRoutine, this.props.history)
        }
    }
}

const AbstractResultMenu = withTranslation()(AbstractResultMenuComponent)

export { AbstractResultMenu }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
