import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractCategoryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {}
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
    }
}
