import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractGamificationViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Button } from '~/components/form'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as SVG from '~/assets/svg'

class GamificationViewComponent extends AbstractGamificationViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.search = this.search.bind(this)
    }

    search(e) {
        const value = e.target.value
        super.search(value)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')} > </a>
                            </li>
                            <li>{t('gamification:gamification')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('gamification:gamification')} :</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.setting.gamification.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <div className="row clearfix" style={{ paddingLeft: '15px' }}>
                            <div className="display-inline-block col-sm-5">
                                <form className="main-search">
                                    <input onChange={(e) => this.search(e)} name="search" placeholder={t('common:searchhere')} type="text" />
                                    <div className="search-icon">{SVG.tablesearch()}</div>
                                </form>
                            </div>
                        </div>
                        <br />
                        {this.state.filterMedalList.map((item) => {
                            return (
                                <div className="float-left medal-setup">
                                    <div className="sc-card text-center">
                                        <img src={item.profile} />
                                        <h3>{item.name}</h3>
                                        <span>{item.points} XP</span>
                                        <button onClick={(e) => (window.location.href = route.admin.setting.gamification.edit + '/' + item.id)} className="gradient-button">
                                            {t('common:viewBtn')}
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                        {this.state.filterMedalList.length == 0 && (
                            <p className="no-data">
                                {SVG.nodata()}
                                {t('gamification:manageGamification')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const GamificationView = withTranslation()(connect(mapStateToProps)(GamificationViewComponent))

export { GamificationView }
