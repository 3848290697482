import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewQuestionBank = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'exam-management/question-bank'
            if (id != null && id != 0) {
                url = url + '/' + id
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addQuestionBank = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/question-bank/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getQuestionBank = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/question-bank/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewQuestion = (classId, subjectId, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'exam-management/question-bank/question/view'
            if (classId != null) {
                url = url + '/' + classId
            }
            if (subjectId != null) {
                url = url + '/' + subjectId
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editQuestionBank = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/question-bank/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addQuestion = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/question-bank/question/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const selectQuestion = (postId, id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/question-bank/question/select/' + postId + '/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteQuestion = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/question-bank/question/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
