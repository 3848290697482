import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { grading_type } from '~/helper/constant/grading'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { getGrading, updateGrading } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractGradingEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            title: '',
            code: '',
            school_id: this.props.user.user_school[0].school_id,
            grading_id: this.props.match.params.id,
            grading: '',
            gradingElements: [],
            grading_type: grading_type,
            defaultGrading: grading_type[0],
            selectedGrading: grading_type[0].value,
            selectedElements: [],
            errors: {
                title: null,
                grading_type: null,
            },
            gradingErrors: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.loadGrading = this.loadGrading.bind(this)
    }
    componentDidMount() {
        this.loadGrading()
    }
    checkForDuplicates(field, value){
       const checkDuplicate = this.state.gradingElements.filter((item) => item[field] == value)
       if(checkDuplicate.length > 1){
        return "Duplicate Value";
       }else{
        return null;
       }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadGrading() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getGrading(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.grading
                let gradingElements = []
                let gradingErrors = []

                item.formula.map((el, index) => {
                    gradingElements.push({
                        id: el.id,
                        from: el.from,
                        to: el.to,
                        gpaScale: el.gpaScale,
                        letterGrade: el.letterGrade,
                    })
                    gradingErrors.push({
                        from: null,
                        to: null,
                        gpaScale: null,
                        letterGrade: null,
                    })
                })

                this.setState(
                    {
                        gradingElements,
                        gradingErrors,
                        title: item.title,
                        code: item.code,
                        selectedGrading: item.grading_type,
                        defaultGrading: this.state.grading_type.filter((el) => {
                            return el.value == item.grading_type
                        })[0],
                    },
                    () => {}
                )
            }
        })
    }
    onSave() {
        let hasError = false
        if (this.state.grading_type) {
            const gradingErrors = this.state.gradingErrors
            const gradingElements = this.state.gradingElements
            gradingElements.map((el, i) => {
                if (el.from == '') {
                    gradingErrors[i].from = 'Required'
                    hasError = true
                }else {
                    const check = this.checkForDuplicates('from', el.from)
                    if(check !== null){
                        hasError = true;
                    }
                    gradingErrors[i].from = check;
                    
                } 
                if (el.to == '') {
                    gradingErrors[i].to = 'Required'
                    hasError = true
                }
                else {
                    const check = this.checkForDuplicates('to', el.to)
                    if(check !== null){
                        hasError = true;
                    }
                    gradingErrors[i].to = check;
                    
                } 
                const from_marks = parseFloat(el.from)
                const to_marks = parseFloat(el.to)
                if (from_marks > to_marks) {
                    gradingErrors[i].from = 'From Cannot be greater than To'
                    hasError = true
                }
                if (this.state.selectedGrading == 1) {
                    if (el.gpaScale == '') {
                        gradingErrors[i].gpaScale = 'Required'
                        hasError = true
                    }else {
                        const check = this.checkForDuplicates('gpaScale', el.gpaScale)
                        if(check !== null){
                            hasError = true;
                        }
                        gradingErrors[i].gpaScale = check;
                        
                    } 
                }
                if (el.letterGrade == '') {
                    gradingErrors[i].letterGrade = 'Required'
                    hasError = true
                }else {
                    const check = this.checkForDuplicates('letterGrade', el.letterGrade)
                    if(check !== null){
                        hasError = true;
                    }
                    gradingErrors[i].letterGrade = check;
                    
                } 
                this.setState({
                    gradingErrors,
                })
            });

            gradingElements.sort((a, b) => {
                const gradeA = a.letterGrade.toUpperCase();
                const gradeB = b.letterGrade.toUpperCase();
                console.log(gradeA, gradeB);
            
                // Extract main letter grade and modifiers
                const mainGradeA = gradeA.replace(/[+-]/g, '');
                const mainGradeB = gradeB.replace(/[+-]/g, '');
            
                // Compare main letter grades
                if (mainGradeA < mainGradeB) {
                    return -1;
                } else if (mainGradeA > mainGradeB) {
                    return 1;
                }
            
                // If main letter grades are the same, compare modifiers
                const hasPlusA = gradeA.includes('+');
                const hasPlusB = gradeB.includes('+');
                const hasMinusA = gradeA.includes('-');
                const hasMinusB = gradeB.includes('-');
            
                // Sort grades with "+" at the top
                if (hasPlusA && !hasPlusB) {
                    return -1;
                } else if (!hasPlusA && hasPlusB) {
                    return 1;
                }
            
                // Sort grades with "-" at the bottom
                if (hasMinusA && !hasMinusB) {
                    return 1;
                } else if (!hasMinusA && hasMinusB) {
                    return -1;
                }
            
                // If no modifiers or both have the same modifiers, compare using the original strings
                return gradeA.localeCompare(gradeB);
            });
        }

        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            const data = {
                title: this.state.title,
                code: this.state.code,
                school_id: this.state.school_id,
                status: this.state.status,
                grading_type: this.state.selectedGrading,
                formula: this.state.gradingElements,
            }

            updateGrading(this.props.match.params.id, this.props.token, data)
                .then((response) => {
                    this._handleChange('loading', false)

                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        window.location = route.admin.setting.grading.view
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
                .catch((err) => console.log(err))
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        user: state.user,
    }
}
