import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { addPaymentBill, deletePaymentBill, editBill, viewAccountTax, viewBankAccount } from '~/services/api'
// import { getAccountingEncryptedKey } from '../../../../../../helper/function'
import { redirect } from '../../../../../../helper/function/util'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBillDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            bill: null,
            payments: [],
            accounts: [],
            selectedAccount: '',
            amount: '',
            reference: '',
            description: '',
            date: '',
            user: '',
            category: '',
            discount: '',
            productItem: [],
            taxes: [],
            selectedTax: [],
            finalTaxes: [],
            totalCalculation: [],
            totalPaidAmount: '',
            errors: {
                date: null,
                amount: null,
                selectedAccount: null,
                reference: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.removeRow = this.removeRow.bind(this)
    }
    componentDidMount() {
        this.loadBanking()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    removeRow(index) {
        let payments = this.state.payments
        payments.splice(index, 1)
        this.setState({
            payments,
        })
    }

    loadBanking() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewBankAccount(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const accounts = response.data.accounts.map((item, index) => {
                    return {
                        label: item.bank_name + '-' + item.holder_name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        accounts,
                    },
                    () => {
                        this.loadTax()
                    }
                )
            }
        })
    }

    loadTax() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAccountTax(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const taxes = response.data.taxes.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                        rate: item.rate,
                        index: index,
                    }
                })
                this.setState(
                    {
                        taxes,
                    },
                    () => {
                        this.loadEditBill()
                    }
                )
            }
        })
    }

    loadEditBill() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        // const key = getAccountingEncryptedKey(this.props.match.params.id)
        editBill(this.props.match.params.id, this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            console.log('expense', response.data.bill)
            if (response.code == API_SUCCESS) {
                if (response.data.bill.status != 0) {
                    const productItem = response.data.bill.items
                    const discount = productItem.reduce((acc, product) => acc + (parseFloat(product.discount) || 0), 0)
                    const finalTaxes = productItem.map((pItem) => {
                        console.log('pitem', pItem)
                        const quantity = parseFloat(pItem.quantity) || 0
                        const price = parseFloat(pItem.price) || 0
                        const discount = parseFloat(pItem.discount) || 0
                        const pq = price * quantity
                        const pqd = pq - discount
                        const taxIds = pItem.tax ? pItem.tax.split(',').map((id) => parseFloat(id.trim())) : []
                        const selectedTax = this.state.taxes.filter((tax) => taxIds.includes(tax.value))
                        const finalTax = selectedTax.map((tax) => tax.label + '-' + tax.rate).join(',')
                        const taxRateArray = selectedTax.map((tax) => tax.rate)
                        const taxAmount = pqd * taxRateArray.reduce((acc, tax) => acc + tax / 100, 0)
                        const totalAmount = pqd + taxAmount
                        return {
                            product_id: pItem.product_id,
                            taxRateArray: taxRateArray,
                            taxArray: finalTax,
                            taxAmount,
                            totalAmount,
                            pq,
                        }
                    })
                    const totalCalculation = finalTaxes.reduce(
                        (acc, calValue) => {
                            const totalTax = acc.totalTax + parseFloat(calValue.taxAmount) || 0
                            const finalTotalAmount = acc.finalTotalAmount + parseFloat(calValue.totalAmount) || 0
                            const subTotal = acc.subTotal + parseFloat(calValue.pq) || 0
                            return {
                                totalTax,
                                finalTotalAmount,
                                subTotal,
                            }
                        },
                        { totalTax: 0, finalTotalAmount: 0, subTotal: 0 }
                    )

                    const totalPaidAmount = response.data.bill.payments.reduce(
                        (acc, paidAmount) => acc + (parseFloat(paidAmount.amount) || 0),
                        0
                    )

                    this.setState({
                        bill: response.data.bill,
                        payments: response.data.bill.payments,
                        user: response.data.venders[response.data.bill.vender_id],
                        category: response.data.category[response.data.bill.category_id],
                        products: response.data.product_services,
                        productItem: response.data.bill.items,
                        finalTaxes,
                        discount,
                        totalCalculation,
                        amount: parseFloat(response.data.bill.due_amount).toFixed(2),
                        totalPaidAmount,
                    })
                } else {
                    redirect(route.admin.accounting.expense.bill.view, this.props.history)
                }
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    date: moment(component.state.date).format('yyyy-MM-DD'),
                    amount: component.state.amount,
                    account_id: component.state.selectedAccount,
                    reference: component.state.reference,
                    description: component.state.description,
                }
                component._handleChange('loading', true)
                addPaymentBill(component.props.match.params.id, data, component.props.accountingToken)
                    .then((response) => {
                        component._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            component.loadBanking()
                            resolve(true)
                        } else {
                            toastMessage('error', response.message)
                            if (response.data) {
                                if ('errors' in response.data) {
                                    component.setState({
                                        errors: response.data.errors,
                                    })
                                }
                            }
                            resolve(false)
                        }
                    })
                    .catch(($e) => {
                        console.log('error', $e)
                    })
            }
        })
    }

    onDelete(e, itemId, index) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            component._handleChange('loading_text', 'loading')

            deletePaymentBill(component.props.match.params.id, itemId, component.props.accountingToken).then(
                (response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        component.removeRow(index)
                        toastMessage('success', response.message)
                        component.loadBanking()
                        resolve(true)
                    } else {
                        toastMessage('errors' in response.data)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                }
            )
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        accountingToken: state.school.accounting_token,
        school: state.school.school,
    }
}
