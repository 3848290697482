import CryptoJS from 'crypto-js'
import { toast } from 'react-toastify'

toast.configure()

export * from './abstract'

const ACCOUNTING_KEY = process.env.REACT_APP_ACCOUNTING_KEY

export function toastMessage(type, message) {
    if (type == 'success') {
        toast.success(message)
    } else if (type == 'error') {
        toast.error(message)
    } else if (type == 'info') {
        toast.info(message)
    } else {
        toast.warn(message)
    }
}

export function redirect(route) {
    window.location = route
}

export function getAccountingEncryptedKey(data) {
    try {
        let iv = CryptoJS.lib.WordArray.random(16),
            key = CryptoJS.enc.Base64.parse(atob(ACCOUNTING_KEY))
        let options = {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
        let encrypted = CryptoJS.AES.encrypt(data, key, options)
        encrypted = encrypted.toString()
        iv = CryptoJS.enc.Base64.stringify(iv)
        let result = {
            iv: iv,
            value: encrypted,
            mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString(),
        }
        result = JSON.stringify(result)
        result = CryptoJS.enc.Utf8.parse(result)
        return CryptoJS.enc.Base64.stringify(result)
    } catch (e) {
        console.log(e)
    }
}

function getAccountingDecryptedKey(encryptStr) {
    encryptStr = CryptoJS.enc.Base64.parse(encryptStr)
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8)
    encryptData = JSON.parse(encryptData)
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv)
    var decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Utf8.parse(atob(ACCOUNTING_KEY)), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    })
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted)
    return decrypted
}
