import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { addBookShelf } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

export class AbstractShelfManagementAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            laoding_text: "loading",
            name: "",
            code : "",
            errors: {
                name: null,
                code: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors[item] != null)
        if(validate.length == 0){
            const data ={
                name: this.state.name,
                code : this.state.code,
            }
        this._handleChange("loading", true)
        addBookShelf(data, this.props.token).then((response)=>{
            if(response.code == API_SUCCESS){
                toastMessage("success", response.message)
                redirect(route.admin.library.shelfmanagement.view, this.props.history)

            }else{
                toastMessage('error', response.data)
                if(response.data){
                    if('errors' in response.data){
                        this.setState({errors:
                        response.data.errors})
                    }
                }
            }
        })
        }

    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
