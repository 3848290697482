import { Component } from 'react'
import { Dispath } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import { CHART_OF_ACCOUNT_TYPE } from '../../../../../../helper/constant/chart_of_account'
import * as route from '../../../../../../helper/constant/route'
import { redirect, toastMessage } from '../../../../../../helper/function/util'
import { addChartOfAccount } from '../../../../../../services/api/accounting/doubleentry'

type Props = {
    dispatch: Dispath<any>,
}
type State = {}

export class AbstractChartAccountAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            code: '',
            type: CHART_OF_ACCOUNT_TYPE[0],
            sub_type: null,
            is_enabled: true,
            description: '',
            errors: {
                name: null,
                code: null,
                type: null,
                sub_type: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    compnentDidMount() {}

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                description: this.state.description,
                type: this.state.type.value,
                sub_type: this.state.sub_type.value,
            }
            if (this.state.is_enabled) {
                data['is_enabled'] = this.state.is_enabled
            }
            this._handleChange('loading', true)
            addChartOfAccount(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.doubleentry.chartofaccount.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
