import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { resetPassword, getSession } from '~/services/api'
import { employee_type, Student } from '~/helper/constant/user_type'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'

type Props = {
    dispatch: Dispatch<any>,
}

export class AbstractUserModelComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            loading: false,
            userData: this.props.userData ? this.props.userData : [],
            session: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.resetPassword = this.resetPassword.bind(this)
        this.fetchSession = this.fetchSession.bind(this)
    }

    componentDidMount() {}

    componentDidUpdate() {
        if (this.state.userData != this.props.userData) {
            this.setState({
                userData: this.props.userData,
            })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    resetPassword(id) {
        resetPassword(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                $('.resetPasswordAlert').hide()
                $('.resetBtn').fadeIn()
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }
    fetchSession(id) {
        this._handleChange('loading', true)
        getSession(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const session = response.data.user.session
                this._handleChange('loading', false)
                this._handleChange('session', session)
                // toastMessage("success", response.message);
            } else {
                toastMessage('error', response.message)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
