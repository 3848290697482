// const BSMonths = [
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
//     [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], //79
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
//     [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
//     [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
//     [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
//     [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
//     [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
//     [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
//     [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30],
// ]

export const BSMonths = [
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30], //80
    [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
    [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30], //2099
]
class DateBS {
    constructor(year, month, day) {
        this.year = year
        this.month = month
        this.day = day
        //this.startingDateAD = new Date("1944-01-01T00:00");
        /*
          this.startingDateBS = {
            "year": 2000,
            "month": 9,
            "day": 17,
        };
        */
        return this
    }
    static fromString(datestring) {
        let info = datestring.match(/(\d+)-(\d+)-(\d+)/)
        return new DateBS(parseInt(info[1]), parseInt(info[2]), parseInt(info[3]))
    }
    toString() {
        return this.year + '-' + this.month + '-' + this.day
    }
    dayOfYear() {
        let months = this.monthsInYear()
        return months.slice(0, this.month - 1).reduce((sum, daysInMonth) => (sum = sum + daysInMonth), 0) + this.day
    }
    daysInYear() {
        return DateBS.daysInYear(this.year)
    }
    static daysInYear(year) {
        return DateBS.monthsInYear(year).reduce((sum, daysInMonth) => (sum = sum + daysInMonth), 0)
    }
    daysSince(date = new DateBS(2000, 9, 17)) {
        let days = 0

        for (let year = date.year; year < this.year; year++) {
            days += DateBS.daysInYear(year)
        }
        days = days + this.dayOfYear() - date.dayOfYear()
        return days
    }
    add(day, month = 0, year = 0) {
        this.month += month
        this.year += year + Math.floor(this.month / 12)
        this.month = this.month % 12
        let diff = day
        while (diff > 0) {
            let daysInMonth = this.daysInMonth()
            let daysLeft = daysInMonth - this.day + 1
            if (diff >= daysLeft) {
                if (this.month === 12) {
                    this.year += 1
                    this.month = 1
                    this.day = 1
                } else {
                    this.month += 1
                    this.day = 1
                }

                diff -= daysLeft
            } else {
                this.day += diff
                diff -= diff
            }
        }
        return this
    }
    toAD() {
        let startingDateAD = new Date('1944-01-01T00:00')
        startingDateAD.setDate(1 + this.daysSince())
        return startingDateAD
    }

    static fromAD(date = new Date()) {
        let startingDateAD = new Date('1944-01-01T00:00')
        let diff = DateBS.daysDiff(date, startingDateAD) + 1
        let startingDateBS = new DateBS(2000, 9, 17)
        startingDateBS.add(diff)
        return startingDateBS
    }

    static daysDiff(a, b) {
        let before = new Date('2020-07-01')
        let after = new Date('2020-07-02')
        return Math.floor((a - b) / (after - before))
    }
    monthsInYear() {
        return DateBS.monthsInYear(this.year)
    }
    static monthsInYear(year) {
        let yearIndex = year - 2000
        if (yearIndex >= 0) {
            return BSMonths[yearIndex]
        }
    }
    daysInMonth() {
        return DateBS.daysInMonth(this.year, this.month)
    }
    static daysInMonth(year, month) {
        return DateBS.monthsInYear(year)[month - 1]
    }
}

export default DateBS
