import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSchoolEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Loading } from '~/components/loader'
import { language } from '~/helper/constant/language'
import { studentRestriction } from '../../../../helper/constant/restriction'
import { country } from '../../../../helper/constant/country'
import { dateFormat } from '../../../../helper/constant/date_format'
class SuperAdminSchoolEditComponent extends AbstractSchoolEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setPackage = this.setPackage.bind(this)
        this.setLocale = this.setLocale.bind(this)
        this.setDob = this.setDob.bind(this)
        this.setStudentAccess = this.setStudentAccess.bind(this)
        this.setCountry = this.setCountry.bind(this)
        this.setDateFormat = this.setDateFormat.bind(this)
    }

    setLocale(data) {
        super._handleChange('locale', data.value)
        super._handleChange('defaultLocale', data)
    }

    setCountry(data) {
        super._handleChange('country', data.value)
        super._handleChange('defaultCountry', data)
        if (data.value != 'Nepal') {
            super._handleChange('date_format', dateFormat[0].value)
            super._handleChange('defaultDateFormat', dateFormat[0])
        }
    }

    setDateFormat(data) {
        super._handleChange('date_format', data.value)
        super._handleChange('defaultDateFormat', data)
    }

    setPackage(data) {
        super._handleChange('package_id', data.value)
        super._handleChange('defaultPackage', data)
    }

    setDob(data) {
        super._handleChange('dob', data)
    }

    setStudentAccess(data) {
        super._handleChange('isOnlyApp', data.value)
        super._handleChange('defaultAccess', data)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.school.view}>School &gt; </a>
                            </li>
                            <li>Edit</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Edit School:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">School Name *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder="School Name" type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Short Code *</label>
                                    <Input name="short_code" disabled="disabled" placeholder="Short Code" type="text" value={this.state.short_code} />
                                    {this.state.errors.short_code && <label className="error text-danger">{this.state.errors.short_code}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Sub-Domain *</label>
                                    <Input name="sub_domain" disabled="disabled" placeholder="Sub Domain" type="text" value={this.state.sub_domain} />
                                    {this.state.errors.sub_domain && <label className="error text-danger">{this.state.errors.sub_domain}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">Package *</label>
                                    <Select name="package_id" options={this.state.packages} placeholder="Packages" searchable={true} value={this.state.defaultPackage} onChange={this.setPackage} />
                                    {this.state.errors.package_id && <label className="error text-danger">{this.state.errors.package_id}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Restrict Student Access *</label>
                                    <Select name="locale" options={studentRestriction} value={this.state.defaultAccess} placeholder="Restrict Student Access" searchable={true} onChange={this.setStudentAccess} />
                                    {this.state.errors.isOnlyApp && <label className="error text-danger">{this.state.errors.isOnlyApp}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Country *</label>
                                    <Select name="country" options={country} placeholder="Select Country" searchable={true} value={this.state.defaultCountry} onChange={this.setCountry} />
                                    {this.state.errors.country && <label className="error text-danger">{this.state.errors.country}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Locale *</label>
                                    <Select name="locale" options={language} placeholder="Select Default Language" searchable={true} value={this.state.defaultLocale} onChange={this.setLocale} />
                                    {this.state.errors.locale && <label className="error text-danger">{this.state.errors.locale}</label>}
                                </div>
                                {this.state.country == 'Nepal' && (
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">Date Format *</label>
                                        <Select name="date_format" value={this.state.defaultDateFormat} options={dateFormat} placeholder="Select Date Format" searchable={true} onChange={this.setDateFormat} />
                                        {this.state.errors.date_format && <label className="error text-danger">{this.state.errors.date_format}</label>}
                                    </div>
                                )}
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="button" text="Update" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SuperAdminSchoolEdit = connect(mapStateToProps)(SuperAdminSchoolEditComponent)

export { SuperAdminSchoolEdit }
