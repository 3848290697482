import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractTodoComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { Loading } from '~/components/loader'
import Select from 'react-select'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import moment from 'moment'
import Files from 'react-files'
import { getBase64, getReadableFileSize } from '~/helper/function'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'
import $ from 'jquery'
import { toastMessage } from '~/helper/function/util'
import * as AccessModule from '~/helper/constant/modules'
import { convertADtoBS } from '../../../helper/function'
class TodoComponent extends AbstractTodoComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setDueDate = this._setDueDate.bind(this)
        this._setEndDate = this._setEndDate.bind(this)
        this._setStartDate = this._setStartDate.bind(this)
        this.getTaskAdd = this.getTaskAdd.bind(this)
        this.getBlank = this.getBlank.bind(this)
        this.getFilter = this.getFilter.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this.previewDocument = this.previewDocument.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this.clearState = this.clearState.bind(this)
        this.filterTodo = this.filterTodo.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
    }
    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }
    onFilesError(error, file) {
        toastMessage('error', error.message)
    }
    _selectEmployee(data) {
        this._handleChange('employee', data)
    }
    _selectStudent(data) {
        this._handleChange('student', data)
    }
    _selectSubject(data) {
        this._handleChange('subject', data)
    }
    _setDueDate(data) {
        this._handleChange('due_date', data)
    }
    _setEndDate(data) {
        this._handleChange('end_date', data)
    }
    _setStartDate(data) {
        this._handleChange('start_date', data)
    }
    _onSave(e) {
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_add').trigger('click')
            }
        })
    }
    filterTodo(e) {
        e.preventDefault()
        this.loadTodo().then((response) => {
            if (response) {
                $('#filter_close').trigger('click')
            }
        })
    }
    getBlank() {
        if (this.state.task.length == 0) {
            const { t } = this.props
            return (
                <div className="text-center no-data">
                    {SVG.todo()}
                    {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher) && <span className="no-task">{t('todo:notaskrightnow')}</span>}
                    {this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Teacher && <span className="no-task">{t('todo:taskmsg1')}.</span>}
                </div>
            )
        }
    }
    getFilter() {
        const { t } = this.props
        return (
            <div id="modal_filter" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('todo:filterbyduedate')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('fromdate')}</label>
                                        <DatePicker placeholderText={t('common:selectFromDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.start_date} selected={this.state.start_date} onChange={this._setStartDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.start_date != null ? (convertADtoBS(this.state.start_date) != null ? convertADtoBS(this.state.start_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('todate')}</label>
                                        <DatePicker placeholderText="Select To Date" dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.end_date} selected={this.state.end_date} onChange={this._setEndDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.end_date != null ? (convertADtoBS(this.state.end_date) != null ? convertADtoBS(this.state.end_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.filterTodo} type="submit" text={t('todo:filter')} main={true} />
                                <button id="filter_close" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    _uploadFile(data) {
        const { t } = this.props
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                    if (file.length > 10) {
                        errors['media'] = t('todo:maxLimit')
                    }
                })
            })
        }
        this._handleChange('media', media)
        this._handleChange('uploads', data)
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }
    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={'file_remove_' + item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }
    getTaskAdd() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school.role != null ? school.role.access : null
        return (
            <div id="modal_task_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('todo:addtask')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('todo:task')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('todo:task')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('todo:taskdescription')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:duedate')} *</label>
                                        <DatePicker placeholderText={t('common:selectDueDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.due_date} selected={this.state.due_date} onChange={this._setDueDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.due_date != '' ? (convertADtoBS(this.state.due_date) != null ? convertADtoBS(this.state.due_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.errors.due_date && <label className="error text-danger">{this.state.errors.due_date}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="form-check">
                                            <label className="form-title" htmlFor="select-remarks">
                                                {t('todo:OnlineSubmission')}
                                            </label>
                                            <Checkbox id="select-remarks" onChange={this._handleChange} className="" name="remarks" value={this.state.remarks} />
                                        </div>
                                    </div>
                                </div>
                                {AccessModule.checkSubAccess([AccessModule.STUDENT], role) && access.includes(AccessModule.Module_Student_Management) && (
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <div className="form-check">
                                                <label className="form-title" htmlFor="select-subject">
                                                    {t('common:selectSubject')}
                                                </label>
                                                <Checkbox id="select-subject" onChange={this._handleChange} className="" name="isSubject" value={this.state.isSubject} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="add-option-exam">
                                    <UserFilter selectedClass={this.state.selectedClass} showSubject={this.state.isSubject} resetOnChange={this.state.isSubject} onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} onSubjectSelect={this._selectSubject} selectedStudent={this.state.student} selectedEmployee={this.state.employee} />
                                </div>
                                <div className="row clearfix">
                                    <div className="col-md-12 float-left">
                                        <label className="form-title">{t('todo:uploadresource')}</label>
                                        <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                            {this.previewDocument()}
                                            <div className="file-lists sc-AxirZ sc-AxhCb eJEChV todo-file-upload">
                                                <Files className="files-dropzone" onError={this.onFilesError} onChange={this._uploadFile} accepts={['image/*', 'video/*', 'audio/*', '.pdf', '.docx', '.pptx', '.xlsx', '.doc', '.ppt', '.xls']} multiple maxFiles={10} maxFileSize={200000000} minFileSize={0} clickable>
                                                    {t('todo:addresource')}
                                                </Files>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="homework" />
                <div className="main-content">
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.todo.view}>{t('todo:todo')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('todo:todo')}:</h5>
                                <small className="small-main-title">
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && (
                                        <Fragment>
                                            {convertADtoBS(this.state.start_date) != null ? convertADtoBS(this.state.start_date) + ' BS' : 'Invalid Date Range'}
                                            &nbsp; to &nbsp;
                                            {convertADtoBS(this.state.end_date) != null ? convertADtoBS(this.state.end_date) + ' BS' : 'Invalid Date Range'}
                                        </Fragment>
                                    )}
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                                        <Fragment>
                                            {moment(this.state.start_date).format('D MMM')}
                                            &nbsp; - &nbsp;
                                            {moment(this.state.end_date).format('D MMM')}
                                        </Fragment>
                                    )}
                                </small>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button type="button" text={t('todo:filter')} modal="modal_filter" main={true} />
                                    {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && <Button type="button" text={t('todo:createtask')} modal="modal_task_add" main={true} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.task.length > 0 && <Table data={this.state.data} />}
                    {this.getBlank()}
                    {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && this.getTaskAdd()}
                    {this.getFilter()}
                </div>
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_task_add') {
                let uploads = component.state.uploads
                for (let i = uploads.length; i >= 0; i--) {
                    uploads.pop()
                }
                // component.state.uploads.map((item, index) => {
                //     uploads.pop()
                // })
                // uploads.pop()
                component._handleChange('uploads', uploads)
                component._handleChange('media', [])
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('due_date', '')
                component._handleChange('remarks', '')
                component._handleChange('student', [])
                component._handleChange('employee', [])
                component._handleChange('subject', null)
                component._handleChange('isSubject', false)
                const errors = {
                    title: null,
                    description: null,
                    due_date: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}
const Todo = withTranslation()(connect(mapStateToProps)(TodoComponent))
export { Todo }
