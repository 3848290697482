import { get, post } from '../request'

export const viewDiscount = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/discount', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addDiscount = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/discount/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editDiscount = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/discount/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getDiscount = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/discount/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getDiscountByUser = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/discount/user/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleDiscount = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/discount/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
