import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '../../../../helper/constant/api_status'
import { getDashboard } from '../../../../services/api/accounting/dashboard'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAccountingDashboardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            report: null,
            incomeExpense: {
                todayIncome: 0,
                todayExpense: 0,
                incomeCurrentMonth: 0,
                expenseCurrentMonth: 0,
            },
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadDashboard()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadDashboard() {
        this._handleChange('loading', true)
        getDashboard(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                console.log(response)
                this.setState({
                    report: response.data.report,
                    incomeExpense: response.data.users.report,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
