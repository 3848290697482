export const TOP_LEFT = 0
export const TOP_CENTER = 1
export const TOP_RIGHT = 2


export const LANDSCAPE_VIEW = 0
export const PORTRAIT_VIEW = 1

export const title_position = [
    {
        label: 'Top Left',
        value: TOP_LEFT,
        translate: 'reportcard:tl',
    },
    {
        label: 'Top Right',
        value: TOP_RIGHT,
        translate: 'reportcard:tr',
    },
    {
        label: 'Top Center',
        value: TOP_CENTER,
        translate: 'reportcard:tc',
    },
]

export const display_mode = [
    {
        label: 'Landscape',
        value: LANDSCAPE_VIEW,
        translate: 'reportcard:landscape',
    },
    {
        label: 'Portrait',
        value: PORTRAIT_VIEW,
        translate: 'reportcard:portrait',
    },
]

export const font_size = [
    {
        label: '14px',
        value: 14,
    },
    {
        label: '15px',
        value: 15,
    },
    {
        label: '16px',
        value: 16,
    },
    {
        label: '17px',
        value: 17,
    },
    {
        label: '18px',
        value: 18,
    },
    {
        label: '19px',
        value: 19,
    },
    {
        label: '20px',
        value: 20,
    },
    {
        label: '21px',
        value: 21,
    },
    {
        label: '22px',
        value: 22,
    },
    {
        label: '23px',
        value: 23,
    },
    {
        label: '24px',
        value: 24,
    },
    {
        label: '25px',
        value: 25,
    },
    {
        label: '26px',
        value: 26,
    },
    {
        label: '27px',
        value: 27,
    },
    {
        label: '28px',
        value: 28,
    },
    {
        label: '29px',
        value: 29,
    },
    {
        label: '30px',
        value: 30,
    },
    {
        label: '31px',
        value: 31,
    },
    {
        label: '32px',
        value: 32,
    },
    {
        label: '33px',
        value: 33,
    },
    {
        label: '34px',
        value: 34,
    },
]
