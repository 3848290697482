import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { addJobVacancy, viewAlumniCategory, searchVacancy } from '~/services/api'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import moment from 'moment'
import { toastMessage } from '~/helper/function/util'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAlumniJobViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            loading: false,
            loading_text: 'Loading...',
            title: null,
            type: null,
            selectedType: null,
            isNegotiable: null,
            selectedNegotiable: null,
            experience: null,
            selectedExperience: null,
            alumni_company_id: null,
            selectedCompany: null,
            alumni_group_id: null,
            selectedGroup: null,
            alumni_designation_id: null,
            selectedDesignation: null,
            no_of_vacancy: null,
            salary: null,
            due_date: null,
            description: null,
            job_specification: null,
            alumniCategoryList: [],
            groupList: [],
            companyList: [],
            designationList: [],
            groupListOption: [],
            designationListOption: [],
            companyListOption: [],
            jobVacancyList: [],
            filteredjobVacancyList: [],
            errors: {
                title: null,
                type: null,
                isNegotiable: null,
                experience: null,
                alumni_company_id: null,
                alumni_group_id: null,
                alumni_designation_id: null,
                no_of_vacancy: null,
                salary: null,
                due_date: null,
                description: null,
                job_specification: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadFilter = this.loadFilter.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
        this._onSave = this._onSave.bind(this)
        this.filterJob = this.filterJob.bind(this)
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    filterJob(value) {
        const filteredjobVacancyList = this.state.jobVacancyList.filter((item) => {
            return item.post.title.toLowerCase().includes(value.toLowerCase())
        })
        this.setState({
            filteredjobVacancyList,
        })
    }

    loadFilter(type, id) {
        if (type == ALUMNI_CATEGORY.Alumni_Group) {
            let groupList = this.state.groupList
            const check = this.state.groupList.find((item) => item == id)
            if (check == null) {
                groupList.push(id)
            } else {
                groupList = groupList.filter((item) => item != id)
            }
            this.setState({
                groupList,
            })
        } else if (type == ALUMNI_CATEGORY.Alumni_Company) {
            let companyList = this.state.companyList
            const check = this.state.companyList.find((item) => item == id)
            if (check == null) {
                companyList.push(id)
            } else {
                companyList = companyList.filter((item) => item != id)
            }
            this.setState({
                companyList,
            })
        } else if (type == ALUMNI_CATEGORY.Alumni_Designation) {
            let designationList = this.state.designationList
            const check = this.state.designationList.find((item) => item == id)
            if (check == null) {
                designationList.push(id)
            } else {
                designationList = designationList.filter((item) => item != id)
            }
            this.setState({
                designationList,
            })
        }
    }

    clearFilter() {
        this.setState(
            {
                companyList: [],
                groupList: [],
                designationList: [],
            },
            () => {
                this.loadVacancy()
            }
        )
    }

    loadAlumniCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAlumniCategory(this.props.school.school_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const alumniCategoryList = response.data.alumni_category.sort((a, b) => (a.name > b.name ? 1 : -1))
                let groupListOption = []
                let companyListOption = []
                let designationListOption = []
                alumniCategoryList.map((item) => {
                    let category = {}
                    category['value'] = item.id
                    category['label'] = item.name
                    if (item.type == ALUMNI_CATEGORY.Alumni_Group) {
                        groupListOption.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Company) {
                        companyListOption.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Designation) {
                        designationListOption.push(category)
                    }
                })
                this.setState(
                    {
                        groupListOption,
                        companyListOption,
                        designationListOption,
                        alumniCategoryList,
                    },
                    () => {
                        this.loadVacancy()
                    }
                )
            }
        })
    }

    loadVacancy() {
        const data = {
            company_id: this.state.companyList,
            group_id: this.state.groupList,
            designation_id: this.state.designationList,
            date: moment().format('yyyy-MM-DD'),
        }
        this._handleChange('loading', true)
        searchVacancy(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let data = []
            if (response.code == API_SUCCESS) {
                data = response.data.job.sort((a, b) => {
                    return a.post.title > b.post.title ? 1 : -1
                })
            }
            this.setState({
                loaded: true,
                jobVacancyList: data,
                filteredjobVacancyList: data,
            })
        })
    }

    _onSave(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                let data = {
                    title: component.state.title,
                    type: component.state.type,
                    isNegotiable: component.state.isNegotiable,
                    experience: component.state.experience,
                    alumni_company_id: component.state.alumni_company_id,
                    alumni_group_id: component.state.alumni_group_id,
                    alumni_designation_id: component.state.alumni_designation_id,
                    no_of_vacancy: component.state.no_of_vacancy,
                    salary: component.state.salary,
                    from_date: moment().format('yyyy-MM-DD'),
                    to_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    description: component.state.description,
                    job_specification: component.state.job_specification,
                }
                component._handleChange('loading', true)
                component._handleChange('loadingText', 'Saving...')
                addJobVacancy(data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.loadVacancy()
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            } else {
                resolve(false)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
