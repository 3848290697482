import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractPostViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Dashboard } from '~/components/dashboard'
import { Post } from '~/components/post'
import { withTranslation } from 'react-i18next'

class PostViewComponent extends AbstractPostViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="activity" />
                <div className="main-content">
                    <Header />
                    <div className="main-box">
                        <div className="activity">
                            <div className="main-head-option">
                                <ul className="list-inline breadcrum clearfix">
                                    <li>
                                        <a href={route.activity}>{t('activity:dashboard')} &gt; </a>
                                    </li>
                                    <li>{t('activity:activity')}</li>
                                </ul>
                            </div>
                            <Post create={false} postId={this.state.id} loader={true} />
                        </div>
                        <div>
                            <Dashboard />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const PostView = withTranslation()(connect(mapStateToProps)(PostViewComponent))

export { PostView }
