import { accountingGet } from '../accountingRequest'

export const getDashboard = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('dashboard', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
