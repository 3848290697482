import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewTodo = (from, to, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('task-management/todo/homework/' + from + '/' + to, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addTodo = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('task-management/todo/homework/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editTodo = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('task-management/todo/homework/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getTodo = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('task-management/todo/homework/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const submitTodo = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('task-management/todo/homework-submit/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleTodo = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('task-management/todo/homework-submit/status/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteTodo = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('task-management/todo/homework-submit/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
