import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addSubject, activeSubjectType } from '~/services/api'
import { SubjectDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    subject: Array,
    data: Array,
    loading: Boolean,
    description: String,
    media: object,
    uploadedFiles: object,
    errors: Object,
    subject_type: Array,
    subject_type_id: integer,
    weekly_classes: integer,
    credit_hour: integer,
    exam_status: integer,
    exam_option: Object,
    class_id: integer,
}

export class AbstractSubjectAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            exam_option: [
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
            ],
            loading: false,
            data: [],
            uploadedFiles: {},
            description: '',
            mediaAry: [],
            media: {},
            class_id: this.props.match.params.id,
            errors: {
                name: null,
                code: null,
                description: null,
                exam_status: null,
            },
            subject_type: [],
            subject: [
                {
                    class: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    code: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadSubjectType()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadSubjectType() {
        const { t } = this.props
        const subject_type = []
        // const defaultType={
        //   label : "Default",
        //   value: ''
        // }
        // subject_type.push(defaultType);
        activeSubjectType(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.subjectType.map((item, index) => {
                    const result: SubjectTypeDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    subject_type.push(data)
                })
                this.setState({ subject_type })
            } else {
                toastMessage('error', t('toaster:errorFetchingClassList') + response.message)
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors }, () => {})
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                subject_type_id: this.state.subject_type_id,
                description: this.state.description,
                gallery_id: '',
                exam_status: this.state.exam_status,
                class_id: this.state.class_id,
                media: this.state.media,
            }
            this._handleChange('loading', true)
            addSubject(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.clearState()
                    toastMessage('success', response.message)
                    redirect(route.admin.school.subject.view + '/' + this.state.class_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    clearState() {
        this.setState({
            media: {},
            uploadedFiles: {},
        })
    }
}

const AbstractSubjectAdd = withTranslation()(AbstractSubjectAddComponent)

export { AbstractSubjectAdd }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
