import { post, get } from '../request'

export const viewBooks = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addBooks = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            console.log('from add Books', $e)
            resolve(null)
        }
    })
}

export const editBooks = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const detailBooks = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book/detail/' + id, token).then((response) => {
                console.log(response)
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteBookShelf = (id, token) => {
    console.log('from api', id, token)
    return new Promise(function (resolve, reject) {
        try {
            post('book-shelf/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            console.log('error api', $e)
            resolve(null)
        }
    })
}

export const updateBooks = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleBooks = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeBooks = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book/active/', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
