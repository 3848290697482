import React, { Component } from 'react'
import { fine_type } from '~/helper/constant/fine'
import { connect } from 'react-redux'
import { AbstractGradingEditComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import Select from 'react-select'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class GradingEditComponent extends AbstractGradingEditComponent{
    constructor(props){
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectGrading = this._selectGrading.bind(this)
        this._setFrom = this._setFrom.bind(this)
        this._setTo = this._setTo.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
    }
    updateChildLetterGrade(el, i){
        const gradingElements = this.state.gradingElements
        gradingElements[i].letterGrade = el
        this.setState({
            gradingElements: gradingElements,
        })
    }

    updateChildGpaScale(el, i){
        const gradingElements= this.state.gradingElements
        gradingElements[i].gpaScale = el
        this.setState({
            gradingElements: gradingElements,
        })
    }
    _selectGrading(data, i){
        this.setState({
            defaultGrading: data,
            selectedGrading: data.value,
        })

    }
    _setFrom(data, i){
        const gradingElements = this.state.gradingElements
        gradingElements[i].from = data
        this.setState({
            gradingElements: gradingElements,
        })
    }
    _setTo(data, i){
        const gradingElements = this.state.gradingElements
        gradingElements[i].to = data
        this.setState({
            gradingElements: gradingElements,
        })
    }
    addRow(){
        let gradingElements = this.state.gradingElements
        let gradingErrors = this.state.gradingErrors

        if(this.state.selectedGrading == 0){
            gradingElements.push({
                from: '',
                to:'',
                letterGrade: ''
            })
            gradingErrors.push({
                from: null,
                to:null,
                letterGrade:null
            })
        }else{
            gradingElements.push({
                from: '',
                to:'',
                gpaScale:'',
                letterGrade: ''
            })
            gradingErrors.push({
                from: null,
                to:null,
                gpaScale:null,
                letterGrade:null
            })
        }  
        this.setState({
            gradingElements,
            gradingErrors,
        })
    }
    removeRow(index){
        let gradingElements = this.state.gradingElements
        gradingElements.splice(index, 1)
        this.setState({
            gradingElements,
        })

    }
    loadGrade(){
        const {t} = this.props
        const gradingElements = this.state.gradingElements
        const data = gradingElements.map((item, index)=>{
            return (
                <tr>   
                                        
                    <td>
                        <Input min={0} type="number" className="form-control mb-3" id="heading" placeholder={t('grading:from')} value={this.state.gradingElements[index].from} onChange={(key, val) => this._setFrom(val, index)} />
                        {this.state.gradingErrors[index].from && <label className="table-error error text-danger">{this.state.gradingErrors[index].from}</label>}
                    </td>
                    <td>
                        <Input min={0} type="number" className="form-control mb-3" id="heading" placeholder={t('grading:to')} value={this.state.gradingElements[index].to} onChange={(key, val) => this._setTo(val, index)} />
                        {this.state.gradingErrors[index].to && <label className="table-error error text-danger">{this.state.gradingErrors[index].to}</label>}
                    </td>
                    {this.state.selectedGrading == 1 && <td>
                        <Input type="text" className="form-control mb-3" id="heading" placeholder={t('grading:gpascale')} value={this.state.gradingElements[index].gpaScale} onChange={(key, val) => this.updateChildGpaScale(val, index)} />
                        {this.state.gradingErrors[index].gpaScale && <label className="table-error error text-danger">{this.state.gradingErrors[index].gpaScale}</label>}
                    </td>}
                    

                    <td>
                        <Input type="text" className="form-control mb-3" id="heading" placeholder={t('grading:lettergrade')} value={this.state.gradingElements[index].letterGrade} onChange={(key, val)=> this.updateChildLetterGrade(val, index)} />
                        {this.state.gradingErrors[index].letterGrade && <label className="table-error error text-danger">{this.state.gradingErrors[index].letterGrade}</label>}
                    </td>
                    <td>
                        <div className="d-flex align-items-center mb-3">
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                            <Button onClick={() => this.removeRow(index)} type="button" text={SVG.deleteComment()} main={false} />
                        </div>
                    </td>
                </tr>
            )
        })
        return data
    }

    render(){
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="fee-design">
                        <div className="main-head-option">
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.admin.setting.menu}>{t('common:settings')} &gt; </a>
                                </li>
                                <li>
                                    <a href={route.admin.setting.grading.view}>{t('grading:grading')} &gt; </a>
                                </li>
                                <li>{t('common:EditBtn')}</li>
                            </ul>
                            <div className="row clearfix">
                                <div className="col-md-5 float-left">
                                    <h5 className="main-title">
                                        {t('common:EditBtn')} {t('grading:grading')}:
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="main-form">
                            <div className="row">
                                <div className="col-sm-6 float-left form-group clearfix">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input name="title" type="text" className="form-control" id="heading" placeholder={t('common:name')} value={this.state.title} onChange={this._handleChange} />
                                    {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-title text-left">{t('grading:gradingtype')} *</label>
                                    <Select name="class" options={this.state.grading_type} placeholder="" searchable={true} onChange={this._selectGrading} value={this.state.defaultGrading} />
                                    {this.state.errors.grading_type && <label className="error text-danger">{this.state.errors.medal_id}</label>}
                                </div>
                            </div>
                            </div>
                        </div>
                        <>
                                    <hr />
                                    <div className="fee-title-btn">
                                        <h6>{t('grading:grading')}:</h6>
                                        <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                                    </div>
                                    <hr />
                                    <div className="main-table">
                                        <div className="table-all table-responsive">
                                            <table className="table responsiveTable fee_table">
                                                <thead>
                                                    <tr>
                                                        <th>{t('grading:from')}</th>
                                                        <th>{t('grading:to')}</th>
                                                        {this.state.selectedGrading == 1 && <th width="150px">{t('grading:gpascale')}</th> }
                                                        <th width="150px">{t('grading:lettergrade')}</th>
                                                        <th>{t('fee:action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.loadGrade()}</tbody>
                                            </table>
                                            {this.state.gradingElements.length == 0 && (
                                                <p className="no-data">
                                                    {' '}
                                                    {SVG.nodata()} {t('fee:feeScheduleMsg')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </>

                                <div className="form-button text-left">
                                <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                            </div>

                    </div>
                </div>
            </div>
        )
    }
}

const GradingEdit = withTranslation()(connect(mapStateToProps)(GradingEditComponent))
export { GradingEdit }

