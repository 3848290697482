import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getFeeByUser, getSection, getStudent } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as PAYMENT_STATUS from '~/helper/constant/payment_status'
import { discount_type } from '~/helper/constant/discount'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import * as Bill_TYPE from '~/helper/constant/bill_type'
import * as SVG from '~/assets/svg'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractStudentStatementComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            invoiceList: [
                {
                    invoice_no: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:invoice',
                    },
                    bill_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'fee:billDate',
                    },
                    amount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:fee_amount',
                    },
                    discount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:discount',
                    },
                    fine: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:fine',
                    },
                    tax: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:tax',
                    },
                    previous_due: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:previous_due',
                    },
                    total: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:total',
                    },
                    paid: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:paid',
                    },
                    status: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:status',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'fee:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadBill()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadBill() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let invoiceList = []
        invoiceList.push(this.state.invoiceList[0])
        let studentId = this.props.school.id
        if (this.props.school.user_type == UserType.Parents) {
            studentId = this.props.student
        }

        getFeeByUser(studentId, this.props.match.params.from_date, this.props.match.params.to_date, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.billing.map((item, index) => {
                    let discount = 0
                    let totalFine = 0
                    let paymentStatus = PAYMENT_STATUS.PAYMENT_STATUS_OBJECT.filter((el) => el.value == item.status) ? PAYMENT_STATUS.PAYMENT_STATUS_OBJECT.filter((el) => el.value == item.status)[0].label : 'N/A'
                    if (item.discount_detail && item.discount_detail.length > 0) {
                        item.discount_detail.map((el, i) => {
                            discount += !el.flat ? el.discount : (item.amount * el.discount) / 100
                        })
                    }
                    if (item.fine_detail && item.fine_detail.length > 0) {
                        item.fine_detail.map((el, i) => {
                            const fine = el.flat ? (el.amount / 100) * item.amount : el.amount
                            totalFine = totalFine + fine
                        })
                    }
                    const tableData = {
                        invoice_no: {
                            text: item.invoice,
                        },
                        bill_date: {
                            text: item.bill_schedule.from_date,
                        },
                        amount: {
                            text: this.props.currency + ' ' + item.bill_schedule.amount.toFixed(2),
                        },
                        discount: {
                            text: this.props.currency + ' ' + discount.toFixed(2),
                        },
                        fine: {
                            text: this.props.currency + ' ' + totalFine.toFixed(2),
                        },
                        tax: {
                            text: this.props.currency + ' ' + (item.grand_total - item.total_amount - parseFloat(item.previous_due)).toFixed(2),
                        },
                        previous_due: {
                            text: this.props.currency + ' ' + parseFloat(item.previous_due).toFixed(2),
                        },
                        total: {
                            text: this.props.currency + ' ' + item.grand_total.toFixed(2),
                        },
                        paid: {
                            text: this.props.currency + ' ' + item.paid_amount.toFixed(2),
                        },
                        status: {
                            text: paymentStatus,
                        },
                        action: {
                            text: 'View',
                            translate: 'common:viewBtn',
                            route: route.student_fee.statement.detail + '/' + this.props.school.id + '/' + item.id,
                        },
                    }
                    invoiceList.push(tableData)
                })
            }
            this.setState({
                invoiceList,
            })
        })
    }
}

const AbstractStudentStatement = withTranslation()(AbstractStudentStatementComponent)

export { AbstractStudentStatement }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        currency: state.school.school.currency,
        school: state.school,
        student: state.student,
    }
}
