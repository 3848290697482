import $ from 'jquery'
import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import { getReadableFileSize } from '~/helper/function/abstract'
import { toastMessage } from '~/helper/function/util'
import { addDrive, deletePost, editDrive, getSchoolUser, viewDrive } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    defaultStudent: Object,
    defaultEmployee: Object,
}

export class AbstractDriveComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            data: [],
            folder_id: null,
            folders: [],
            sortTerm: 'date',
            orderBy: 'desc',
            folderData: [],
            folder_name: '',
            folder_description: '',
            searchText: '',
            allUsers: [],
            access: [],
            studentAccess: [],
            employeeAccess: [],
            studentList: [],
            employeeList: [],
            classList: [],
            sectionList: [],
            userTypeList: [],
            customRoleList: [],
            privacy: false,
            selectAllEmployee: false,
            selectAllStudent: false,
            uploadedFiles: [],
            media: {},
            mediaAry: [],
            selectedMedia: null,
            selectedMediaName: null,
            defaultRole: null,
            defaultUserType: null,
            defaultClass: null,
            defaultSection: null,
            defaultEmployee: null,
            defaultStudent: null,
            selectedRole: null,
            selectedUserType: null,
            selectedClass: null,
            selectedSection: null,
            selectedStudent: null,
            eselectedStudent: null,
            selectedEmployee: null,
            eselectedEmployee: null,
            detail: {
                file_name: null,
                file_description: null,
                owner: null,
                createdOn: null,
                folderSize: null,
                sharedTo: [],
                folderItemsCount: null,
            },
            errors: {
                folder_name: null,
                folder_description: null,
            },
            eerrors: {
                efolder_name: null,
                efolder_description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.addFolder = this.addFolder.bind(this)
        this.resetState = this.resetState.bind(this)
        this.openDetailFolder = this.openDetailFolder.bind(this)
        this.deleteFolder = this.deleteFolder.bind(this)
        this.updateFolder = this.updateFolder.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getEditModal = this.getEditModal.bind(this)
        this.openAddFolder = this.openAddFolder.bind(this)
        this.search = this.search.bind(this)
        this.changeOrderBy = this.changeOrderBy.bind(this)
        this.changeSortBy = this.changeSortBy.bind(this)
        this.loadUser = this.loadUser.bind(this)
        this.searchData = this.searchData.bind(this)
        this.orderBy = this.orderBy.bind(this)
        this.sortBy = this.sortBy.bind(this)
    }

    componentDidMount() {
        this.loadAll()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAll() {
        this._handleChange('loading', true)
        getSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let studentList = []
            let employeeList = []
            let allUsers = []
            if (response.code == API_SUCCESS) {
                response.data.users.map((item, index) => {
                    allUsers.push(item)
                    if (item.user_type == 3) {
                        studentList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    } else {
                        employeeList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    }
                })
                this.setState(
                    {
                        allUsers,
                    },
                    () => this.loadDriveItems()
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadUser(data) {
        this.setState({
            allUsers: data,
        })
    }

    _selectStudent(data) {
        this.setState({
            selectedStudent: data,
        })
    }
    _selectEmployee(data) {
        this.setState({
            selectedEmployee: data,
        })
    }

    search(e) {
        e.preventDefault()
        this.searchData('searchText', e.target.value)
    }

    searchData(key, value) {
        this.setState({
            [key]: value,
        })
        const searchTerm = value
        const folderData = this.state.folderData
        let folders = []
        if (searchTerm.length > 0) {
            folders = folderData.filter((el) => {
                return el.title.toLowerCase().includes(searchTerm.toLowerCase())
            })
            this.setState({ folders })
        } else {
            this.setState({ folders: folderData })
        }
    }

    changeOrderBy(e) {
        this.orderBy(e.value)
    }

    orderBy(value) {
        this.setState({ orderBy: value })
        const orderby = value
        const sortby = this.state.sortTerm
        const folderData = this.state.folderData
        let folders = []
        if (sortby == 'name') {
            folders = folderData.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
        } else {
            folders = folderData.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1))
        }
        if (value == 'desc') {
            folders = folderData.reverse()
        }

        this.setState({ folders, orderby })
    }

    changeSortBy(e) {
        this.sortBy(e.value)
    }

    sortBy(value) {
        const sortTerm = value
        const orderby = this.state.orderBy
        const folderData = this.state.folderData
        let folders = []
        if (value == 'name') {
            folders = folderData.sort((a, b) => (a.title > b.title ? 1 : -1))
        } else {
            folders = folderData.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1))
        }
        if (orderby == 'desc') {
            folders = folderData.reverse()
        }

        this.setState({ folders, sortTerm })
    }

    loadDriveItems() {
        this._handleChange('loading', true)
        viewDrive(this.props.token).then((response) => {
            let folders = []
            let folderData = []
            if (response.code == API_SUCCESS) {
                const folderList = response.data.drive
                folderList.map((item, index) => {
                    folderData.push(item)
                    folders.push(item)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState({ folders, folderData }, () => this._handleChange('loading', false))
        })
    }

    addFolder(e) {
        e.preventDefault()
        let hasError = false
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading', true)
            let data = {
                title: this.state.folder_name,
                description: this.state.folder_description,
                restriction: this.state.privacy,
            }
            if (this.state.privacy) {
                const studentAccess = this.state.selectedStudent
                const employeeAccess = this.state.selectedEmployee
                let access = []
                if (studentAccess == null) {
                    access = employeeAccess
                } else if (employeeAccess == null) {
                    access = studentAccess
                } else {
                    access = studentAccess.concat(employeeAccess)
                }
                data['restriction'] = true
                data['access'] = access
            }
            if (this.state.media.length != 0) {
                data['media'] = this.state.media
            }
            addDrive(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    $('#close_add').trigger('click')
                    this.resetState()
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    openAddFolder() {
        let uploads = this.state.uploadedFiles
        for (let i = uploads.length; i >= 0; i--) {
            uploads.pop()
        }
        this.setState(
            {
                folder_name: '',
                folder_description: '',
                efolder_name: '',
                efolder_description: '',
                sharedTo: [],
                access: [],
                studentAccess: [],
                employeeAccess: [],
                privacy: false,
                eprivacy: false,
                uploadedFiles: uploads,
                media: {},
                mediaAry: [],
                selectedMedia: [],
                selectedMediaName: [],
                defaultEmployee: null,
                defaultStudent: null,
                selectedStudent: [],
                selectedEmployee: [],
                errors: {
                    folder_name: null,
                    folder_description: null,
                },
                eerrors: {
                    efolder_name: null,
                    efolder_description: null,
                },
            },
            () => {
                $('.access-list').hide()
                $('#modal_drive_add').modal('show')
            }
        )
    }

    openDetailFolder(index) {
        const folderDetail = this.state.folders
        const allUsers = this.state.allUsers
        const sharedTo = []
        if (folderDetail[index].access != null) {
            const access = folderDetail[index].access
            access.map((item, i) => {
                const user = allUsers.filter((el) => {
                    return el.id == item
                })
                if (user && user.length != 0) {
                    let initial = user[0].user.name != null ? user[0].user.name.substring(0, 1) : 'C'
                    if (user[0].user.user_detail) {
                        initial = user[0].user.user_detail.profile
                    }
                    sharedTo.push(initial)
                }
            })
        }
        let folderSize = '0 KB'
        let folderItemsCount = 0
        if (folderDetail[index].gallery) {
            folderSize = getReadableFileSize(folderDetail[index].gallery.media_size)
            folderItemsCount = folderDetail[index].gallery.media_count
        }
        const author = folderDetail[index].author?.user

        this.setState({
            detail: {
                file_name: folderDetail[index].title,
                file_description: folderDetail[index].description,
                owner: author != null ? (author.name != null ? author.name : author.email) : 'N/A',
                createdOn: moment(folderDetail[index].created_at).format('YYYY-MM-DD'),
                folderItemsCount,
                folderSize,
                sharedTo,
            },
        })
        $('#details-drive').modal('show')
    }

    updateFolder(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.eerrors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            let data = {
                title: this.state.efolder_name,
                description: this.state.efolder_description,
                restriction: this.state.eprivacy,
            }
            data['restriction'] = false
            if (this.state.eprivacy) {
                const studentAccess = this.state.selectedStudent
                const employeeAccess = this.state.selectedEmployee
                let access = []
                if (studentAccess == null) {
                    access = employeeAccess
                } else if (employeeAccess == null) {
                    access = studentAccess
                } else {
                    access = studentAccess.concat(employeeAccess)
                }
                data['access'] = access
                data['restriction'] = true
            }
            editDrive(this.state.selectedMedia, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    $('#close_edit').trigger('click')
                    this.resetState()
                    this.loadDriveItems()
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    deleteFolder() {
        this._handleChange('loading', true)
        deletePost(this.state.selectedMedia, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                $('#close_delete').trigger('click')
                this.resetState()
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    getEditModal(index) {
        const folder = this.state.folders
        let eprivacy = false
        let defaultStudent = []
        let defaultEmployee = []
        let selectedEmployee = []
        let selectedStudent = []
        if (folder[index].restriction) {
            eprivacy = true
            if (eprivacy) {
                const currentAccess = folder[index].access
                const accessAry = []
                const allUsers = this.state.allUsers
                if (currentAccess != null) {
                    currentAccess.map((item, index) => {
                        const user = allUsers.filter((el) => {
                            return el.id == item
                        })

                        if (user != null && user.length != 0) {
                            if (user[0].user_type == 3) {
                                selectedStudent.push(user[0].id)
                                defaultStudent.push({
                                    value: user[0].id,
                                    label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                })
                            } else {
                                selectedEmployee.push(user[0].id)
                                defaultEmployee.push({
                                    value: user[0].id,
                                    label: user[0].user.name + ' - ' + user[0].user.reg_no,
                                })
                            }
                        }
                    })
                }
                $('.eaccess-list').show()
            }
        } else {
            $('.eaccess-list').hide()
        }
        this.setState(
            {
                selectedMedia: folder[index].id,
                efolder_name: folder[index].title,
                efolder_description: folder[index].description,
                eprivacy,
                selectedStudent,
                selectedEmployee,
                defaultEmployee,
                defaultStudent,
            },
            () => {
                if (this.state.selectedMedia) {
                    $('#modal_folder_edit').modal('show')
                }
            }
        )
    }

    getDeleteModal(index) {
        const folder = this.state.folders
        this.setState(
            {
                selectedMedia: folder[index].id,
                selectedMediaName: folder[index].title,
            },
            () => {
                if (this.state.selectedMedia != null) {
                    $('#modal_class_delete').modal('show')
                }
            }
        )
    }

    resetState() {
        this.setState(
            {
                folder_id: null,
                folders: [],
                folder_name: '',
                folder_description: '',
                efolder_name: '',
                efolder_description: '',
                sharedTo: [],
                access: [],
                studentAccess: [],
                employeeAccess: [],
                privacy: false,
                eprivacy: false,
                uploadedFiles: [],
                media: {},
                mediaAry: [],
                selectedMedia: null,
                selectedMediaName: null,
                defaultEmployee: null,
                defaultStudent: null,
                selectedStudent: null,
                selectedEmployee: null,
                errors: {
                    folder_name: null,
                    folder_description: null,
                },
                eerrors: {
                    efolder_name: null,
                    efolder_description: null,
                },
            },
            () => this.loadDriveItems()
        )
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
