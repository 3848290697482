import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { getSubject, editSubject, activeSubjectType } from '~/services/api'
import { SubjectDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { Alert } from 'bootstrap'
import { redirect } from '~/helper/function/util'
type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    subject: Array,
    data: Array,
    loading: Boolean,
    description: String,
    media: object,
    defaultMedia: Array,
    deletedMedia: Array,
    errors: Object,
    subject_type: Array,
    deletedMediaAry: Array,
    defaultSubjectType: Object,
    defaultExamStatus: Object,
    subject_type_id: integer,
    weekly_classes: integer,
    credit_hour: integer,
    exam_status: integer,
    exam_option: Object,
    class_id: integer,
}

export class AbstractSubjectEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            exam_option: [
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' },
            ],
            loading: false,
            data: [],
            description: '',
            media: {},
            defaultMedia: [],
            mediaAry: [],
            deletedMediaAry: [],
            deletedMedia: [],
            class_id: this.props.match.params.class_id,
            errors: {
                name: null,
                code: null,
                description: null,
                exam_status: null,
            },
            subject_type: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadSubjectType()
    }

    componentDidMount() {
        this.loadSubject()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadSubject() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getSubject(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let defaultSubjectType = {}
                let defaultMedia = []
                let defaultExamStatus = {
                    label: 'No',
                    value: 0,
                }
                if (response.data.subject.subject_type_id) {
                    defaultSubjectType = {
                        label: response.data.subject.subject_type.name + ' - ' + response.data.subject.subject_type.code,
                        value: response.data.subject.subject_type.id,
                    }
                }
                if (response.data.subject.exam_status) {
                    defaultExamStatus = {
                        label: 'Yes',
                        value: 1,
                    }
                }
                const subject = response.data.subject

                if (subject.gallery) {
                    subject.gallery.media.map((item, index) => {
                        defaultMedia.push(item)
                    })
                }
                this.setState({
                    name: subject.name,
                    code: subject.code,
                    credit_hour: subject.credit_hour,
                    description: subject.description,
                    weekly_classes: subject.weekly_classes,
                    subject_type_id: subject.subject_type_id,
                    exam_status: subject.exam_status,
                    defaultMedia,
                    defaultSubjectType,
                    defaultExamStatus,
                })
            }
        })
    }

    loadSubjectType() {
        const subject_type = []
        // const defaultType = {
        //     label: 'Default',
        //     value: '',
        // }
        // subject_type.push(defaultType)
        activeSubjectType(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.subjectType.map((item, index) => {
                    const result: SubjectTypeDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    subject_type.push(data)
                })
                this.setState({ subject_type })
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })

        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                subject_type_id: this.state.subject_type_id,
                description: this.state.description,
                gallery_id: '',
                exam_status: this.state.exam_status,
                class_id: this.state.class_id,
                media: this.state.media,
                deletedMedia: this.state.deletedMedia,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editSubject(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.school.subject.view + '/' + this.state.class_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
