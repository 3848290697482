import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractIncomeComponent, mapStateToProps } from './abstract'

class IncomeComponent extends AbstractIncomeComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="income" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('income:income')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('income:income')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.income.invoice.view)}
                                >
                                    <a href={route.admin.accounting.income.invoice.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.income.invoice.view} className="folder-name">
                                        {t('income:invoice')}
                                        <span> {t('income:manageinvoice')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.income.template.view)}
                                >
                                    <a href={route.admin.accounting.income.template.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.income.template.view} className="folder-name">
                                        {t('income:invoice')} {t('income:template')}
                                        <span>
                                            {t('income:createinvoice')} {t('income:template')}
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.income.invoicesummary)}
                                >
                                    <a href={route.admin.accounting.income.invoicesummary} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.income.invoicesummary} className="folder-name">
                                        {t('reports:invoicesummary')}
                                        <span> {t('reports:viewinvoicesummary')}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Income = withTranslation()(connect(mapStateToProps)(IncomeComponent))

export { Income }
