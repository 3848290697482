import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { detailBookShelf, viewBookShelf } from '~/services/api'
import * as Status from '~/helper/constant/status'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractShelfManagementDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            bookShelf: '',
            tableData:[],
            tableStructure: [{
                name: {
                    field: 'text',
                    route: false,
                    sortable: true,
                    translate: 'librarry:books',
                },
                category:{
                    field: 'text',
                    route: false,
                    sortable: true,
                    translate:'category'
                },
                action:{
                    field: 'button',
                    route:true,
                    sortable:false,
                    translate:"common:detail"
                }
            
            }],
        }
        this._handleChange = this._handleChange.bind(this)

    }
    componentDidMount() {
        this.loadDetail()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }


    loadDetail() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableData=[]
        let tableStructure = []
        tableStructure.push(this.state.tableStructure[0])
        detailBookShelf(this.props.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookShelf = response.data.shelf;
                console.log(response.data)
                bookShelf.book_shelf.map((item, index)=>{
                    console.log(item)
              const tableData1 = {
                name: {
                  text:item.books.title,
                },
                category:{
                    text:item.books.categories.name
                },
                action:{
                    text:"Edit",
                    translate: 'common:detail',
                    route: route.admin.library.books.detail + '/' + item.book_id,
                }
                
              }
              tableData.push(item)
              tableStructure.push(tableData1)
                })

                this.setState({
                    bookShelf: response.data.shelf.name,
                    tableData,
                    tableStructure
                    
                })
            }
        })
    }

    
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
