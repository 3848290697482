import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractExamMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classes: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            this.loadClasses()
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClasses() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    classes: response.data.class,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
