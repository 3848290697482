import { get, post } from '../request'

export const viewResult = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/result/exam/result', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewStudentResult = (class_id, section_id, routine_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get(
                'exam-management/result/exam/result/view/' + class_id + '/' + section_id + '/' + routine_id,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveResult = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/result/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const studentResult = (id, token, studentId = null) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'exam-management/result/view/' + id

            if (studentId != null) {
                url = url + '/' + studentId
            }

            get(url, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const downloadResult = (id, token, studentId = null) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'exam-management/result/download/' + id
            if (studentId != null) {
                url = url + '/' + studentId
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}
