import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractChatNotificationComponent, mapStateToProps } from './abstract_chat'
import $ from 'jquery'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import { circle } from '~/helper/constant/color'
import moment from 'moment'
import * as route from '~/helper/constant/route'
import * as STATUS from '~/helper/constant/status'

class ChatNotificationComponent extends AbstractChatNotificationComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getRecent() {
        let i = 0
        const data = this.state.recentList.map((item, index) => {
            let profile = null
            let name = 'Unknown'
            const message = item.latest_message != null ? item.latest_message.message : 'Start your conversation'
            const message_time = item.latest_message != null ? item.latest_message.created_at : null
            if (item.isGroup) {
                profile = item.media != null ? item.media.media_url : this.props.school != null ? (this.props.school.school != null ? this.props.school.school.profile : null) : null
                name = item.name
            } else {
                if (this.props.school.id == item.author_id) {
                    let status = null
                    item.access_information.map((participant) => {
                        profile = participant.user.user_detail != null ? participant.user.user_detail.profile : null
                        name = participant.user.name != null ? participant.user.name : participant.user.reg_no
                        status = participant.status && participant.user.status
                    })
                } else {
                    profile = item.author.user.user_detail != null ? item.author.user.user_detail.profile : null
                    name = item.author.user.name != null ? item.author.user.name : item.author.user.reg_no
                }

                if (i > 2) {
                    i = 0
                }
                const color = circle[i]
                if (profile == null) {
                    i++
                }
                return (
                    <a className="list-noti unread" href={route.chat + '/' + item.id} target="_blank" key={index}>
                        <div id="addClass">
                            <div className={'chat-img user-img ' + color}>
                                {profile != null && <img src={profile} alt="user" />}
                                {profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                            </div>
                            <p>{name}</p>
                            <span className="msg">{message}</span>
                            <ins className="msg-count">{item.message_count}</ins>
                            {message_time != null && <ins>{moment(message_time).fromNow()}</ins>}
                        </div>
                    </a>
                )
            }
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <li>
                <div className="dropdown">
                    <button className="btn head-button dropdown-toggle" type="button" data-toggle="collapse" data-target="#chat_dropdown">
                        {SVG.chat()}
                        <span>{this.state.count}</span>
                    </button>
                    <div className="dropdown-menu notif-pop main main-border-radius main-background chat-top" aria-labelledby="chat_dropdown" id="chat_dropdown">
                        <button type="button" className="close" data-dismiss="modal" data-toggle="collapse" data-target="#chat_dropdown">
                            &times;
                        </button>
                        <span>{t('header:chat')}</span>
                        <div className="notif-dropdown">
                            {this.state.recentList.length > 0 && <div className="notif-list">{this.getRecent()}</div>}
                            {this.state.recentList.length == 0 && <div className="notification-text">{t('header:nonotifications')}</div>}
                            <a href={route.chat} target="_blank" className="box-view-all notification-more notification-view">
                                {t('chat:showChat')}
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

const ChatNotification = withTranslation()(connect(mapStateToProps)(ChatNotificationComponent))

export { ChatNotification }
