import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as ReactTable from 'react-super-responsive-table'
import * as SVG from '~/assets/svg'
import { Checkbox, Input } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as RollType from '~/helper/constant/roll_type'
import * as route from '~/helper/constant/route'
import { AbstractPromoteStudentComponent, mapStateToProps } from './abstract'

class PromoteStudentComponent extends AbstractPromoteStudentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
        this._selectSection = this._selectSection.bind(this)
        this._selectTransferSection = this._selectTransferSection.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._updateRollNo = this._updateRollNo.bind(this)
        this._selectAcademic = this._selectAcademic.bind(this)
        this._selectFilterClass = this._selectFilterClass.bind(this)
    }

    _selectAcademic(data) {
        this._handleChange('defaultAcademicYear', data)
        this.setState(
            {
                studentAry: [],
                defaultStudent: [],
                selectedStudent: [],
                selectAll: null,
                selectedAcademicYear: data.value,
            },
            () => {
                this.loadStudent()
            }
        )
    }

    _selectClass(data) {
        //super._handleChange('selectedClass', data.value)
        this._handleChange('defaultClass', data)
        this.setState(
            {
                selectedClass: data.value,
                selectAll: null,
            },
            () => this.loadSection(data.value)
        )
    }

    _selectFilterClass(data) {
        this._handleChange('defaultFilteredClass', data)
        this.setState(
            {
                filteredClass: data.value,
            },
            () => this.loadFilteredSection(data.value)
        )
    }

    _selectSection(data) {
        this._handleChange('defaultSection', data)
        this.setState(
            {
                studentAry: [],
                defaultStudent: [],
                selectedStudent: [],
                selectAll: null,
                selectedSection: data.value,
            },
            () => {
                this.loadStudent()
            }
        )
    }

    _selectTransferSection(data) {
        this._handleChange('defaultFilteredSection', data)
        this.setState(
            {
                filteredSection: data.value,
            },
            () => {
                this.loadCurrentStudent()
            }
        )
    }

    _selectStudent(data) {
        const selectedStudent = []
        if (data != null) {
            this._handleChange('defaultStudent', data)
            data.map((item) => {
                selectedStudent.push(item.value)
            })
        } else {
            this._handleChange('defaultStudent', [])
        }
        this._handleChange('selectedStudent', selectedStudent)
    }

    _updateRollNo(id, value) {
        const defaultStudent = []
        this.state.defaultStudent.map((item) => {
            if (item.value == id) {
                item.rollNo = value
            }
            defaultStudent.push(item)
        })
        this._handleChange('defaultStudent', defaultStudent)
    }

    getSelectedStudent() {
        const { t } = this.props
        if (this.state.defaultStudent.length > 0) {
            const roll_type = this.state.defaultFilteredSection.roll_type
            const data = this.state.defaultStudent.map((item, index) => {
                return (
                    <ReactTable.Tr key={index}>
                        <ReactTable.Td>{item.label}</ReactTable.Td>
                        {roll_type == RollType.AUTO && (
                            <ReactTable.Td>
                                <span className="table-status table-green">{t('academic:promote.autoIncrement')}</span>
                            </ReactTable.Td>
                        )}
                        {roll_type == RollType.ALPHA && (
                            <ReactTable.Td>
                                <span className="table-status table-green">{t('academic:promote.alphabetically')}</span>
                            </ReactTable.Td>
                        )}
                        {roll_type == RollType.MANUAL && (
                            <ReactTable.Td>
                                <Input type="number" min={1} max={100000000} name={'rollNo_' + item.value} value={item.rollNo} onChange={(key, value) => this._updateRollNo(item.value, value)} />
                                {this.state.rollError.length > 0 && <label className="error text-danger">{this.state.rollError[item.value]}</label>}
                            </ReactTable.Td>
                        )}
                    </ReactTable.Tr>
                )
            })
            return data
        } else {
            return (
                <ReactTable.Tr>
                    <ReactTable.Td colSpan="2">
                        <p className="no-data">
                            {SVG.nodata()} {t('academic:promote.noStudentSelected')}.
                        </p>
                    </ReactTable.Td>
                </ReactTable.Tr>
            )
        }
    }

    getCurrentStudentModal() {
        const { t } = this.props
        return (
            <div id="check_student_list" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('academic:promote.studentList')} </span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <Table data={this.state.student} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="academic" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option no-border pb-0">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.academic.view}>{t('academic:breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('academic:promote.promoteStudent')}</li>
                        </ul>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-lg-4">
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <h5 className="main-title heading5">
                                                {SVG.promoteStudentIcon()}
                                                {t('academic:promote.promoteStudent')}:
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="timetable-form mb-5">
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('academic:promote.previousAcademicYear')} *
                                                </label>
                                                <Select name="type" options={this.state.academicYear} placeholder={t('academic:promote.previousAcademicYear')} value={this.state.defaultAcademicYear} searchable={true} onChange={this._selectAcademic} />
                                                {this.state.errors.selectedAcademicYear && <label className="error text-danger">{this.state.errors.selectedAcademicYear}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('student:student-menu.classLabel')} *
                                                </label>
                                                <Select name="type" options={this.state.classAry} placeholder={t('academic:promote.classPlaceholder')} value={this.state.defaultClass} searchable={true} onChange={this._selectClass} />
                                                {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('student:student-menu.sectionLabel')} *
                                                </label>
                                                <Select name="type" options={this.state.sectionAry} placeholder={t('academic:promote.selectSection')} value={this.state.defaultSection} searchable={true} onChange={this._selectSection} />
                                                {this.state.errors.selectedSection && <label className="error text-danger">{this.state.errors.selectedSection}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('academic:promote.students')} *
                                                </label>
                                                <Select isMulti name="type" options={this.state.studentAry} placeholder={t('users:role.add.student-management.selectStudent')} value={this.state.defaultStudent} searchable={true} onChange={this._selectStudent} />
                                                <div className="form-check">
                                                    <Checkbox id="show" className="" name="selectAll" value={this.state.selectAll} onChange={(key, value) => this._selectAll(key, value)} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="show">
                                                        {t('todo:selectAllStudent')}
                                                    </label>
                                                </div>
                                                {this.state.errors.selectedStudent && <label className="error text-danger">{this.state.errors.selectedStudent}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('academic:promote.transferToClass')} *
                                                </label>
                                                <Select name="type" options={this.state.classAry} placeholder={t('academic:promote.classPlaceholder')} value={this.state.defaultFilteredClass} searchable={true} onChange={this._selectFilterClass} />
                                                {this.state.errors.filteredClass && <label className="error text-danger">{this.state.errors.filteredClass}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label htmlFor="departmentclass" className="form-title">
                                                    {t('academic:promote.transferToSection')} *
                                                </label>
                                                <Select name="type" options={this.state.filteredSectionAry} placeholder={t('academic:promote.selectTransferSection')} value={this.state.defaultFilteredSection} searchable={true} onChange={this._selectTransferSection} />
                                                <a data-toggle="modal" data-target="#check_student_list" className="small-text cursor">
                                                    {t('academic:promote.checkStudentList')}
                                                </a>
                                                {this.state.errors.filteredSection && <label className="error text-danger">{this.state.errors.filteredSection}</label>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <h5 className="main-title heading5">
                                                {SVG.promoteStudentList()}
                                                {t('academic:promote.promoteStudent')} {this.state.defaultFilteredSection != null ? 'to ' + this.state.defaultFilteredSection.label : ''} :{' '}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <div className="main-table">
                                                <div className="table-all table-responsive">
                                                    <ReactTable.Table className="table">
                                                        <ReactTable.Thead>
                                                            <ReactTable.Tr>
                                                                <ReactTable.Th>{t('academic:promote.student')}</ReactTable.Th>
                                                                <ReactTable.Th>{t('academic:promote.rollNo')}</ReactTable.Th>
                                                            </ReactTable.Tr>
                                                        </ReactTable.Thead>
                                                        <ReactTable.Tbody>{this.getSelectedStudent()}</ReactTable.Tbody>
                                                    </ReactTable.Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.defaultStudent.length > 0 && (
                                        <div className="form-button text-left">
                                            <button type="button" className="main-button" onClick={this._onSave}>
                                                {t('academic:promote.transfer')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {this.getCurrentStudentModal()}
            </div>
        )
    }
}

const PromoteStudent = withTranslation()(connect(mapStateToProps)(PromoteStudentComponent))

export { PromoteStudent }
