import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getRecentNotification = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('web-alert/recent', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const allNotification = (token, page) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'web-alert'
            if (page != null) {
                url = url + '?page=' + page
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const setNotificationSeen = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('web-alert/seen', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const getNotificationDetail = (id,token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notification/edit/'+ id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
