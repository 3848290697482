import { get, post } from './request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getProfile = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('profile', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getDocuments = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('documents', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveProfile = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('profile', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const password = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('change-password', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
