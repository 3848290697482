import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewEnquiry, editEnquiry, toggleEnquiry } from '~/services/api'
import { EnquiryDTO } from '~/model'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    start_time: String,
    enquiries: Array,
    data: Array,
    errors: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractEnquiryViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            data: [],
            errors: {
                start_time: null,
            },
            enquiries: [
                {
                    institution: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    name: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    email: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    phone: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    verified: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        modal: true,
                        sortable: true,
                    },
                    schedule: {
                        field: 'button',
                        modal: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadEnquiries()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(id) {
        toggleEnquiry(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEnquiries()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _onSave(id) {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                start_time: moment(this.state.start_time).format('yyyy-MM-DD H:mm:ss'),
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editEnquiry(id, data, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    this.setState({
                        start_time: '',
                        errors: {
                            start_time: null,
                        },
                    })
                    this.loadEnquiries()
                } else {
                    this._handleChange('loading', false)
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    loadEnquiries() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewEnquiry(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let enquiries = []
            enquiries.push(this.state.enquiries[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.enquiry.map((item, index) => {
                    const data: EnquiryDTO = item
                    let text = null
                    let verified = null
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Pending',
                            badge: 'table-green',
                            modal: 'modal_enquiry_' + index,
                        }
                    } else {
                        text = {
                            text: 'Complete',
                            badge: 'table-red',
                            modal: 'modal_enquiry_' + index,
                        }
                    }
                    if (data.verified) {
                        verified = {
                            text: 'Verified',
                            badge: 'table-green',
                        }
                    } else {
                        verified = {
                            text: 'Unverified',
                            badge: 'table-red',
                        }
                    }
                    const tableData = {
                        institution: {
                            text: data.institution,
                        },
                        name: {
                            text: data.name,
                        },
                        email: {
                            text: data.email,
                        },
                        phone: {
                            text: data.phone,
                        },
                        verified: verified,
                        status: text,
                        schedule: {
                            text: 'Schedule',
                            modal: 'modal_schedule_' + index,
                        },
                    }
                    dataList.push(data)
                    enquiries.push(tableData)
                })
            }
            this.setState({
                data: dataList,
                enquiries,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
