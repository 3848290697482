import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { configRoute, convertADtoBS } from '../../../../../../helper/function/abstract'
import { AbstractBillComponent, mapStateToProps } from './abstract'

class BillViewComponent extends AbstractBillComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setFromDate = this._setFromDate.bind(this)
        this._setToDate = this._setToDate.bind(this)
        this._setStatus = this._setStatus.bind(this)
        this._setUser = this._setUser.bind(this)
    }

    _setFromDate(data) {
        this._handleChange('fromDate', data)
    }
    _setToDate(data) {
        this._handleChange('toDate', data)
    }
    _setStatus(data) {
        this._handleChange('status', data)
    }

    _setUser(data) {
        this._handleChange('user', data)
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                {this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view) && (
                    <Sidebar id="expense" isAccount={true} />
                )}
                {this.props.location.pathname != configRoute(route.admin.accounting.expense.bill.view) && (
                    <Sidebar id="salary" isAccount={false} />
                )}
                <Loading status={this.state.loading} text="Loading" />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        {this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view) && (
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                                </li>
                                <li>
                                    <a href={route.admin.accounting.expense.menu}>
                                        {t('accounting:expense')} &nbsp; &gt; &nbsp;
                                    </a>
                                </li>
                                <li>{t('accounting:bill')}</li>
                            </ul>
                        )}
                        {this.props.location.pathname != configRoute(route.admin.accounting.expense.bill.view) && (
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.teacher.salary.view}>Salary</a>
                                </li>
                            </ul>
                        )}
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                {this.props.location.pathname ==
                                    configRoute(route.admin.accounting.expense.bill.view) && (
                                    <h5 className="main-title heading5">{t('income:managebill')}</h5>
                                )}
                                {this.props.location.pathname !=
                                    configRoute(route.admin.accounting.expense.bill.view) && (
                                    <h5 className="main-title heading5">Salary</h5>
                                )}
                            </div>
                            {this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <a href={route.admin.accounting.expense.bill.add} className="main-button">
                                            {t('common:addBtn')}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="main-table">
                        <div className="row clearfix table-head">
                            <div
                                className={
                                    this.props.location.pathname ==
                                    configRoute(route.admin.accounting.expense.bill.view)
                                        ? 'col-md-3 form-group header-filter'
                                        : 'col-md-4 form-group header-filter'
                                }
                            >
                                <label className="table-form-title text-left">{t('common:start date')} : *</label>
                                <DatePicker
                                    placeholderText={t('common:start date')}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    defaultValue={this.state.fromDate}
                                    selected={this.state.fromDate}
                                    onChange={this._setFromDate}
                                />
                                {this.props.userSchool != null && this.props.userSchool.school.date_format == 'BS' && (
                                    <label className="date-bs">
                                        {this.state.fromDate != ''
                                            ? convertADtoBS(this.state.fromDate) != null
                                                ? convertADtoBS(this.state.fromDate) + ' BS'
                                                : 'Invalid Date Range'
                                            : ''}
                                    </label>
                                )}

                                {this.state.errors.fromDate && (
                                    <label className="error text-danger">{this.state.errors.fromDate}</label>
                                )}
                            </div>

                            <div
                                className={
                                    this.props.location.pathname ==
                                    configRoute(route.admin.accounting.expense.bill.view)
                                        ? 'col-md-3 form-group header-filter'
                                        : 'col-md-4 form-group header-filter'
                                }
                            >
                                <label className="table-form-title text-left">{t('common:end date')} : *</label>
                                <DatePicker
                                    placeholderText={t('common:end date')}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    defaultValue={this.state.toDate}
                                    selected={this.state.toDate}
                                    onChange={this._setToDate}
                                />
                                {this.props.userSchool != null && this.props.userSchool.school.date_format == 'BS' && (
                                    <label className="date-bs">
                                        {this.state.toDate != ''
                                            ? convertADtoBS(this.state.toDate) != null
                                                ? convertADtoBS(this.state.toDate) + ' BS'
                                                : 'Invalid Date Range'
                                            : ''}
                                    </label>
                                )}
                                {this.state.errors.filterDate && (
                                    <label className="error text-danger">{this.state.errors.filterDate}</label>
                                )}
                            </div>
                            {this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view) && (
                                <div className="col-md-3 form-group">
                                    <label className="table-form-title text-left">{t('sidebar:users')}</label>
                                    <Select
                                        name="userList"
                                        options={this.state.userLists}
                                        placeholder={t('sidebar:users')}
                                        onChange={this._setUser}
                                        value={this.state.user}
                                        searchable={true}
                                    />
                                </div>
                            )}

                            <div
                                className={
                                    this.props.location.pathname ==
                                    configRoute(route.admin.accounting.expense.bill.view)
                                        ? 'col-md-2 form-group'
                                        : 'col-md-3 form-group'
                                }
                            >
                                <label className="table-form-title text-left">{t('common:status')}</label>
                                <Select
                                    name="status"
                                    options={this.state.filterStatus.filter((item) =>
                                        this.props.location.pathname ==
                                        configRoute(route.admin.accounting.expense.bill.view)
                                            ? true
                                            : item.value != '0'
                                            ? true
                                            : false
                                    )}
                                    placeholder={t('common:status')}
                                    value={this.state.status}
                                    onChange={this._setStatus}
                                    searchable={true}
                                />
                            </div>

                            <div className="col-md-1">
                                <button className="main-button" onClick={() => this.loadBill()}>
                                    {t('attendance:filter')}
                                </button>
                            </div>
                        </div>
                        <div className="row clearFix"></div>
                        {this.state.userBalance != null && (
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    <div className="table-all table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="info-one">
                                                            <span>{t('expense:totalBill')} :</span>
                                                            <ins>{this.state.userBalance.totalBill}</ins>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="info-one">
                                                            <span>{t('expense:totalBillSum')} :</span>{' '}
                                                            <ins>
                                                                <small>{this.props.userSchool.school.currency}</small>{' '}
                                                                {this.state.userBalance.totalBillSum.toFixed(2)}
                                                            </ins>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="info-one">
                                                            <span>{t('reports:balance')} :</span>
                                                            <ins
                                                                className={
                                                                    this.state.userBalance.balance < 0
                                                                        ? 'text-danger'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.userSchool.school.currency}</small>{' '}
                                                                {Math.abs(this.state.userBalance.balance).toFixed(2)}
                                                            </ins>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="info-one">
                                                            <span>{t('expense:overdue')} :</span>
                                                            <ins>
                                                                <small>{this.props.userSchool.school.currency}</small>{' '}
                                                                {this.state.userBalance.overdue.toFixed(2)}
                                                            </ins>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Table data={this.state.tableStructure} />
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}
const BillView = withTranslation()(connect(mapStateToProps)(BillViewComponent))

export { BillView }
