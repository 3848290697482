import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Table } from '~/components/table'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { capitalize } from '../../../../../helper/function/abstract'
import { AbstractBooksDetailsComponent, mapStateToProps } from './abstract'

class BooksDetailsComponent extends AbstractBooksDetailsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        
    }

    

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                {/* <Loading status={this.state.loading} text="Loading" /> */}

                    <Sidebar id="library" isAccount={false} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                    <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.books.view}>{t('library:books')} > </a>
                            </li>
                            <li>{t('common:details')}</li>
                        </ul>


                    </div>
                    <div className="main-table">
                        
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                <th scope="col" className="w-25">Book Details</th>
                                <th scope="col" className="w-75"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="w-25">Book Title</td>
                                <td className="w-75">{capitalize(this.state.title)}</td>
                                </tr>
                                <tr>
                                <td className="w-25">ISBN</td>
                                <td className="w-75">{this.state.isbn}</td>
                                </tr>
                                <tr>
                                <td>Author</td>
                                <td>{this.state.author}</td>
                                </tr>
                                <tr>
                                <td>Edition</td>
                                <td>{this.state.edition}</td>
                                </tr>
                                <tr>
                                <td>Published Date</td>
                                <td>{this.state.published_date}</td>

                                </tr>
                                <tr>
                                <td>Added Date</td>
                                <td>{this.state.added_date}</td>
                                </tr>
                                <tr>
                                <td>Book Type</td>
                                <td>{this.state.bookType}</td>
                                </tr>
                                <tr>
                                <td>Book Category</td>
                                <td>{this.state.bookCategory}</td>
                                </tr>
                                <tr>
                                <td>Book Description</td>
                                <td>{this.state.description}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="mt-5">
                        <Table data={this.state.tableStructure} />
                        </div>
                        <br />

                    </div>

                           

                </div>
            </div>
        )
    }


 }

const BooksDetails = withTranslation()(connect(mapStateToProps)(BooksDetailsComponent))
export { BooksDetails }
