import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import { detailBooks, viewBookCategories, updateBooks, activeBookShelf, deleteBookShelf } from '~/services/api'
import { book_type } from '~/helper/constant/books.js'
import { book_category } from '~/helper/constant/category.js'
import moment from 'moment'

export class AbstractBooksEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            publishedDate: '',
            addedDate: '',
            loading: false,
            loading_text: null,
            shelf: [],
            title: '',
            isbn: '',
            author: '',
            edition: '',
            description: '',
            quantity: 0,
            bookCategories: [],
            activeBookShelf: [],
            defaultBookCategories: '',
            selectedBookCategories: '',
            bookType: book_type,
            defaultBookType: '',
            selectedBookType: '',
            defaultBookType: book_type[0],
            shelfElements: [],
            shelfErrors: [],
            errors: {
                title: null,
                isbn: null,
                description: null,
                author: null,
                edition: null,
                addedDate: null,
                selectedBookType: null,
                selectedBookCategories: null,
                publishedDate: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.delete = this.delete.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadBookCategory()
    }
    loadBookCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewBookCategories(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let bookCategories = []
            let defaultBookCategories = ''
            let selectedBookCategories = ''
            if (response.code == API_SUCCESS) {
                response.data.bookCategory.map((item, index) => {
                    const data = {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                    bookCategories.push(data)
                    if (index == 0) {
                        defaultBookCategories = data
                        selectedBookCategories = item.id
                    }
                })
                this.setState(
                    {
                        bookCategories,
                        defaultBookCategories,
                        selectedBookCategories,
                    },
                    () => {
                        this.loadBookShelves()
                    }
                )
            }
        })
    }

    loadBooks() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        detailBooks(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.book
                let shelfElements = []
                let shelfErrors = []
                item.book_shelf.map((el, index) => {
                    const bookShelfId = el.id
                    const defaultShelf = {
                        label: el.shelves.code + '-' + el.shelves.name,
                        value: el.shelves.id,
                    }

                    shelfElements.push({
                        defaultShelf,
                        bookShelfId,
                        shelves_id: defaultShelf.value,
                        quantity: el.quantity,
                    })

                    shelfErrors.push({
                        shelf: null,
                        quantity: null,
                    })
                })

                this.setState({
                    title: item.title,
                    isbn: item.isbn,
                    author: item.author,
                    edition: item.edition,
                    quantity: item.quantity,
                    publishedDate: item.published_date,
                    addedDate: item.added_date,
                    description: item.description,
                    defaultBookCategories: this.state.bookCategories.find(
                        (bookcat) => bookcat.value === item.book_categories_id
                    ),
                    selectedBookCategories: item.book_categories_id,
                    defaultBookType: book_type.find((e) => e.value == item.book_type),
                    selectedBookType: item.book_type,
                    shelfElements,
                    shelfErrors,
                })
            }
        })
    }
    loadBookShelves() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeBookShelf(this.props.token).then((response) => {
            this._handleChange('loading', false)

            if (response.code === API_SUCCESS) {
                const activeBookShelf = response.data.shelf.map((item, index) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })

                this.setState(
                    {
                        activeBookShelf,
                    },
                    () => {
                        this.loadBooks()
                    }
                )
            }
        })
    }

    delete() {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading_text', 'Deleting')
            component._handleChange('loading', true)
            deleteBookShelf(component.state.shelf.bookShelfId, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)

                    component.loadBooks()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }

    onSave(e) {
        e.preventDefault()
        let hasError = false

        const shelfErrors = this.state.shelfErrors
        const shelfElements = this.state.shelfElements
        shelfElements.map((el, i) => {
            if (el.shelf == '') {
                shelfErrors[i].shelf = 'Required'
                hasError = true
            }
            if (el.quantity == '') {
                shelfErrors[i].quantity = 'Required'
                hasError = true
            }
        })
        this.setState({
            shelfErrors,
        })
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                isbn: this.state.isbn,
                author: this.state.author,
                edition: this.state.edition,
                quantity: this.state.quantity,
                book_type: this.state.selectedBookType,
                book_categories_id: this.state.selectedBookCategories,
                published_date: moment(this.state.publishedDate).format('yyyy-MM-DD'),
                added_date: moment(this.state.addedDate).format('yyyy-MM-DD'),
                description: this.state.description,
                bookShelf: shelfElements,
            }
            this._handleChange('loading', true)
            updateBooks(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.library.books.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
