export default class PackageDTO {
    id: integer
    name: String
    student: integer
    teacher: integer
    storage: double
    price: double
    access: Array
    show: Boolean
    code: String
    status: integer
    currency: String

    constructor(id, name, student, teacher, storage, price, access, show, code, status, currency) {
        this.id = id
        this.name = name
        this.student = student
        this.teacher = teacher
        this.storage = storage
        this.price = price
        this.access = access
        this.show = show
        this.code = code
        this.status = status
        this.currency = currency
    }
}
