import React, { Component } from 'react'
import { AbstractCheckboxComponent } from './abstract'

export class Checkbox extends AbstractCheckboxComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return <input id={this.props.id} disabled={this.props.disabled != null ? this.props.disabled : false} onChange={this._onChange} className={'form-check-input ' + this.props.className} type="checkbox" name={this.props.name} value={this.state.value} checked={this.state.checked} />
    }
}
