import { Component } from 'react'
import { Dispath } from 'react-redux'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import { getJournalAccount } from '../../../../../../services/api/accounting/doubleentry'

type Props = {
    dispatch: Dispath<any>,
}
type State = {}

export class AbstractJournalAccountInvoiceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            journalVoucher: null,
            chartOfAccounts: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadJournal()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadJournal() {
        this._handleChange('loading', true)
        getJournalAccount(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    chartOfAccounts: response.data.accounts,
                    journalVoucher: response.data.journalEntry,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
