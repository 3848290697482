import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Input } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as route from '~/helper/constant/route'
import { AbstractAlumniDashboardComponent, mapStateToProps } from './abstract'

class AlumniDashboardComponent extends AbstractAlumniDashboardComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="alumniDashboard" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('setting:dashboard')}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="alumni-dashboard">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="custom-card-box">
                                    <div className="alumni-filter">
                                        <div className="alumni-filter-wrap">
                                            <p className="heading">{t('alumni:searchmsg1')}</p>
                                            <ul className="filter-list">
                                                <div id="batchContent">
                                                    {ALUMNI_CATEGORY.categoryList.map((item, index) => {
                                                        let categoryList = []
                                                        if (item.value == ALUMNI_CATEGORY.Alumni_Batch) {
                                                            categoryList = this.state.batchList
                                                        } else if (item.value == ALUMNI_CATEGORY.Alumni_Group) {
                                                            categoryList = this.state.groupList
                                                        } else if (item.value == ALUMNI_CATEGORY.Alumni_Company) {
                                                            categoryList = this.state.companyList
                                                        } else {
                                                            categoryList = this.state.designationList
                                                        }
                                                        return (
                                                            <div className="filter-accordion">
                                                                <li>
                                                                    <button className={index == 0 ? 'down-arrow float-right' : 'down-arrow float-right collapsed'} data-parent="#batchContent" data-toggle="collapse" data-target={'#batchCollapse' + index} aria-expanded={index == 0 ? 'true' : 'false'} aria-controls={'batchCollapse' + index}>
                                                                        {t(item.translate)}
                                                                    </button>
                                                                </li>
                                                                <div id={'batchCollapse' + index} className={index == 0 ? 'collapse-body collapse show' : 'collapse-body collapse'} data-parent="#batchContent">
                                                                    {this.state.alumniCategoryList
                                                                        .filter((category) => category.type == item.value)
                                                                        .map((category, index) => {
                                                                            return (
                                                                                <div className="filter-body">
                                                                                    <input type="checkbox" name="" checked={categoryList.includes(category.id)} onChange={() => this.loadFilter(item.value, category.id)} />
                                                                                    <p>{category.name}</p>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="form-button text-left">
                                                    <button
                                                        type="button"
                                                        className="main-button"
                                                        onClick={() => {
                                                            this.loadAlumni()
                                                        }}
                                                    >
                                                        {t('alumni:search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="cancel-button"
                                                        onClick={() => {
                                                            this.clearFilter()
                                                        }}
                                                    >
                                                        {t('alumni:clear')}
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="yearbook-course-wrap">
                                    {(this.state.batchList.length > 0 || this.state.companyList.length > 0 || this.state.designationList.length > 0 || this.state.groupList.length > 0) && (
                                        <div className="second-title">
                                            <h6>{t('alumni:search')}</h6>
                                            <div className="w-100 pl-3">
                                                <span className="title-line"></span>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.batchList.length == 0 && this.state.companyList.length == 0 && this.state.designationList.length == 0 && this.state.groupList.length == 0 && (
                                        <div className="second-title">
                                            <h6>{t('alumni:latestAlumni')}</h6>
                                            <div className="t-line pl-3">
                                                <span className="title-line"></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="y-search-wrap">
                                    <form className="main-search alumni-main-search">
                                        <Input name="search" placeholder={t('alumni:searchPlaceholder')} type="text" value="" onChange={(key, value) => this.filterAlumni(value)} />
                                        <div className="search-icon">{SVG.tablesearch()}</div>
                                    </form>
                                </div>
                                <div>
                                    <div className="row">
                                        {this.state.filteredAlumniList.length > 0 && (
                                            <Fragment>
                                                {this.state.filteredAlumniList.map((item) => {
                                                    const name = item.user_school.user.name != null ? item.user_school.user.name : item.user_school.user.reg_no
                                                    const company = item.alumni_company != null ? item.alumni_company.name : t('common:others')
                                                    const designation = item.alumni_designation != null ? item.alumni_designation.name : t('common:others')
                                                    const profile = item.user_school.user.user_detail != null ? (item.user_school.user.user_detail.profile != null ? item.user_school.user.user_detail.profile : null) : null
                                                    return (
                                                        <div className="col-md-6 col-lg-4">
                                                            <a href={route.user_profile + '/' + item.user_school.user.id}>
                                                                <div className="alumni-box ">
                                                                    {profile != null && <img src={profile} alt="post-user" />}
                                                                    {profile == null && <div className="alumni-profile-letter">{name.charAt(0)}</div>}
                                                                    <div className="mt-3">
                                                                        <h5>{name}</h5>
                                                                        <p>{company}</p>
                                                                        <span>{designation}</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </Fragment>
                                        )}
                                        {this.state.filteredAlumniList.length == 0 && (
                                            <p className="no-data">
                                                {SVG.nodata()}
                                                {t('common:table.nodata')}.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AlumniDashboard = withTranslation()(connect(mapStateToProps)(AlumniDashboardComponent))

export { AlumniDashboard }
