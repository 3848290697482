import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirectDomain } from '~/helper/constant/config'
import * as UserType from '~/helper/constant/user_type'
import { toastMessage, loader, redirect } from '~/helper/function/util'
import { login, getSchoolPackage } from '~/services/api'
import { LoginDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { messaging } from '~/firebaseMessaging'
import { signin, currentSchool, packageAdd, selectChildren, alumni_page, school_page } from '~/store'
import { withTranslation } from 'react-i18next'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    username: string,
    password: string,
    remember: boolean,
    errors: object,
    loading: boolean,
    device_id: String,
}

export class AbstractRedirectLoginComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            loading: false,
            device_id: null,
            errors: {
                username: null,
                password: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onLogin = this._onLogin.bind(this)
        this.loadPackage = this.loadPackage.bind(this)
        if (this.props.school != null) {
            const user_type = this.props.school.user_type
            if (user_type == UserType.SuperAdmin) {
                redirect(route.superadmin.dashboard, this.props.history)
            } else {
                redirect(route.activity, this.props.history)
            }
        }
    }

    async componentDidMount() {
        let device_id = null
        await messaging
            .requestPermission()
            .then(async function () {
                const token = await messaging.getToken()
                device_id = token
            })
            .catch(function (err) {})
        await this.setState({
            device_id,
        })
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    loadPackage(token) {
        this._handleChange('loading', true)
        getSchoolPackage(token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const packages = response.data.package
                this.props.dispatch(packageAdd(packages.access))
            }
        })
    }

    _onLogin = (e) => {
        const { t } = this.props
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data: LoginDTO = {
                username: this.state.username,
                password: this.state.password,
                device_id: this.state.device_id,
            }
            this._handleChange('loading', true)
            login(data).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    // toastMessage("success" ,response.message);
                    const url = window.location.href
                    const user_school = response.data.user.user_school.find((el) => {
                        if (el.user_type != UserType.SuperAdmin) {
                            if (url.includes(el.school.domain) || url.includes(el.school.sub_domain)) {
                                return true
                            }
                        }
                    })
                    if (user_school) {
                        const user_data = {
                            user: response.data.user,
                            token: response.data.token,
                        }
                        if (user_school.user_type == UserType.Alumni) {
                            this.props.dispatch(alumni_page())
                        } else {
                            if (url.includes('alumni')) {
                                this.props.dispatch(alumni_page())
                            } else {
                                this.props.dispatch(school_page())
                            }
                        }
                        this.props.dispatch(currentSchool(user_school))
                        if (user_school.user_type === UserType.Parents) {
                            if (user_school.student.length > 0) {
                                this.props.dispatch(selectChildren(user_school.student[0].student_id))
                            }
                        }
                        this.loadPackage(user_data.token)
                        this.props.dispatch(signin(user_data))
                    } else {
                        toastMessage('error', t('toaster:unauthorizedAccess'))
                        redirect(route.index, this.props.history)
                    }
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

const AbstractRedirectLogin = withTranslation()(AbstractRedirectLoginComponent)

export { AbstractRedirectLogin }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
    }
}
