import { get, post } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getChatCount = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/count', token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getChatRoomName = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/room', token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getRecentChat = (limit, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/recent/' + limit, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getRecentDetailChat = (limit, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/recent/detail/' + limit, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getGroupChat = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/group', token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getChatRoom = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/chat-room/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getSingleChat = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/chat-room/users/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const createGroup = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('chat/chat-room/group/add', token, data).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const editGroup = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('chat/chat-room/group/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const deleteGroup = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/chat-room/group/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const leaveGroup = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/chat-room/leave/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const getChatMessage = (id, page, token) => {
    return new Promise(function (resolve, reject) {
        try {
            if (page != null) {
                id = id + '?page=' + page
            }
            get('chat/chat-room/message/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const sendMessage = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('chat/chat-room/message/send', token, data).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const editMessage = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('chat/chat-room/message/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}

export const deleteMessage = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('chat/chat-room/message/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}
