import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { resetPasswordUser } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    otp: string,
}

export class AbstractResetComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            otp: null,
            otp_one: null,
            otp_two: null,
            otp_three: null,
            otp_four: null,
            otp_five: null,
            otp_six: null,
            errors: {
                otp: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    _onSave() {
        let otp = this.state.otp_one + this.state.otp_two + this.state.otp_three + this.state.otp_four + this.state.otp_five + this.state.otp_six
        this.setState(
            {
                otp,
            },
            () => {
                const errors = validation.required(Object.keys(this.state.errors), this.state)
                this.setState({ errors })
                const validate = Object.keys(errors).filter((item) => errors[item] != null)
                if (validate.length == 0) {
                    const data = {
                        otp: this.state.otp,
                    }
                    this._handleChange('loading', true)
                    resetPasswordUser(data).then((response) => {
                        this._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            redirect(route.index, this.props.history)
                        } else {
                            toastMessage('error', response.message)
                            if (response.data) {
                                if ('errors' in response.data) {
                                    this.setState({ errors: response.data.errors })
                                }
                            }
                        }
                    })
                }
            }
        )
    }
}

export function mapStateToProps(state: Object) {
    return {}
}
