import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { getNotice, editNotice } from '~/services/api'
import { NoticeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    title: String,
    code: String,
    description: String,
    media: object,
    defaultMedia: Array,
    deletedMedia: Array,
    errors: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractNoticeEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            title: '',
            code: '',
            description: '',
            media: {},
            defaultMedia: [],
            deletedMedia: [],
            errors: {
                title: null,
                code: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._deleteImage = this._deleteImage.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let defaultMedia = []
        getNotice(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const notice: NoticeDTO = response.data.notice
                let defauleMediaBlob
                if (response.data.notice.gallery) {
                    response.data.notice.gallery.media.map((item, index) => {
                        defaultMedia.push(item)
                        //media[index] = item.media_type+";base64,"+item.media;
                    })
                }
                this.setState({
                    id: notice.id,
                    title: notice.title,
                    code: notice.code,
                    description: notice.description,
                    defaultMedia,
                    defauleMediaBlob,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _deleteImage(id) {
        let deletedMedia = this.state.deletedMedia
        deletedMedia.push(id)
        this._handleChange('deletedMedia', deletedMedia)
        const defaultMedia = this.state.defaultMedia.filter((item) => item.id != id)
        this._handleChange('defaultMedia', defaultMedia)
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                code: this.state.code,
                description: this.state.description,
                media: this.state.media,
                deletedMedia: this.state.deletedMedia,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editNotice(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.notice.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
