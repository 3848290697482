import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractTimetableMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class TimetableMenuComponent extends AbstractTimetableMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
        this._selectSection = this._selectSection.bind(this)
    }

    _selectClass(data) {
        this._handleChange('selectedClass', data.value)
        this._handleChange('defaultClass', data)
        this.loadSection(data.value)
    }

    _selectSection(data) {
        this._handleChange('selectedSection', data.value)
        this._handleChange('defaultSection', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="timetable" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.timetable.menu}>{t('timetable:timetable')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('timetable:timetable')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:classTitle')} *</label>
                                    <Select name="type" options={this.state.classes} placeholder={t('classes:classTitle')} value={this.state.defaultClass} searchable={true} onChange={this._selectClass} />
                                    {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:section.title')} *</label>
                                    <Select name="type" options={this.state.section} placeholder={t('classes:section.title')} value={this.state.defaultSection} searchable={true} onChange={this._selectSection} />
                                    {this.state.errors.selectedSection && <label className="error text-danger">{this.state.errors.selectedSection}</label>}
                                </div>
                            </div>
                            <div className="form-button text-left">
                                <Button onClick={this._onSearch} type="submit" text={t('common:searchBtn')} main={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const TimetableMenu = withTranslation()(connect(mapStateToProps)(TimetableMenuComponent))

export { TimetableMenu }
