export default class ApiResponse {
    code: string
    status: string
    message: string
    data: object

    constructor(code, status, message, data) {
        this.code = code
        this.status = status
        this.message = message
        this.data = data
    }
}
