import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { editMedal } from '~/services/api/admin/gamification'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import { getMedal } from '../../../../../services/api/admin/gamification'

export class AbstractGamificationEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: null,
            name: null,
            description: null,
            points: null,
            icon: {},
            iconBlob: [],
            rules: [],
            errors: {
                name: null,
                description: null,
                points: null,
                icon: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggleRules = this.toggleRules.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    toggleRules(id) {
        let rules = []
        if (this.state.rules.includes(id)) {
            rules = this.state.rules.filter((item) => item != id)
        } else {
            rules = this.state.rules
            rules.push(id)
        }
        this.setState({
            rules,
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadMedal()
    }

    loadMedal() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getMedal(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this._handleChange('name', response.data.medal.name)
                this._handleChange('description', response.data.medal.description)
                this._handleChange('points', response.data.medal.points)
                this._handleChange('rules', response.data.medal.rules)
                if (response.data.medal.gallery) {
                    if (response.data.medal.gallery.media) {
                        const images = response.data.medal.gallery.media.find((item) => item.media_type.includes('data:image/'))
                        const profileBlob = []
                        if (images != null) {
                            const profile = {
                                0: images.media_type + ';base64,' + images.media_data,
                            }
                            profileBlob.push(images)
                            this._handleChange('icon', profile)
                            this._handleChange('iconBlob', profileBlob)
                        }
                    }
                }
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        if (JSON.stringify(this.state.icon) === '{}') {
            errors['icon'] = 'Required'
        }
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                description: this.state.description,
                points: this.state.points,
                icon: this.state.icon,
                rules: this.state.rules,
            }
            this._handleChange('loading', true)
            editMedal(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.setting.gamification.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
