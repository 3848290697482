import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractAccountComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { Input, Button } from '~/components/form'
import { Loading } from '~/components/loader'

class AccountComponent extends AbstractAccountComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
            </div>
        )
    }
}

const Account = connect(mapStateToProps)(AccountComponent)

export { Account }
