import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractInviteApprovalComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import { Alumni_Batch } from '../../../../../helper/constant/alumni_category'
import { convertADtoBS } from '../../../../../helper/function'
class InviteApprovalComponent extends AbstractInviteApprovalComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('notification:setting')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.setting.invite.view}>{t('invite:invite')} &gt; </a>
                            </li>
                            <li>{t('invite:approval')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('invite:formApproval')} :</h5>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('common:email')} *</label>
                                <Input name="email" placeholder={t('common:email')} type="text" disabled="disable" value={this.state.email} />
                            </div>
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('common:name')} *</label>
                                <Input name="name" placeholder={t('common:name')} type="text" disabled="disable" value={this.state.name} />
                                <div className="form-group form-check">
                                    <Checkbox name="name_recheck" value={this.state.name_recheck} onChange={this._handleRecheckChange} />
                                    <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('common:contact')} *</label>
                                <Input name="contact" placeholder={t('common:contact')} type="text" disabled="disable" value={this.state.contact} />
                                <div className="form-group form-check">
                                    <Checkbox name="contact_recheck" value={this.state.contact_recheck} onChange={this._handleRecheckChange} />
                                    <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                </div>
                            </div>
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('student:student:add:dobLabel')} *</label>
                                <Input name="dob" placeholder={t('student:student:add:dobLabel')} type="text" disabled="disable" value={this.state.dob} />
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs small-bs">{this.state.dob != null ? (convertADtoBS(this.state.dob) != null ? convertADtoBS(this.state.dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                <div className="form-group form-check">
                                    <Checkbox name="dob_recheck" value={this.state.dob_recheck} onChange={this._handleRecheckChange} />
                                    <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                </div>
                            </div>
                        </div>
                        {this.state.isCategory == true && (
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('users:userCategory')} *</label>
                                    <Input name="user_category_id" placeholder={t('users:userCategory')} type="text" disabled="disable" value={this.state.user_category != null ? this.state.user_category.name : ''} />
                                    <div className="form-group form-check">
                                        <Checkbox name="user_category_recheck" value={this.state.user_category_recheck} onChange={this._handleRecheckChange} />
                                        <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.isParent == true && (
                            <Fragment>
                                <hr />
                                <div className="row clearfix">
                                    <div className="col-md-12 float-left">
                                        <h6 className="line-title heading5">{t('invite:parentInfo')}</h6>
                                    </div>
                                </div>
                                <hr />
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('invite:parentName')} *</label>
                                        <Input name="parent_name" placeholder={t('invite:parentName')} type="text" disabled="disable" value={this.state.parent_name} />
                                        <div className="form-group form-check">
                                            <Checkbox name="parent_name_recheck" value={this.state.parent_name_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('invite:parentEmail')} *</label>
                                        <Input name="parent_email" placeholder={t('invite:parentEmail')} type="text" disabled="disable" value={this.state.parent_email} />
                                        <div className="form-group form-check">
                                            <Checkbox name="parent_email_recheck" value={this.state.parent_email_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('invite:parentContact')} *</label>
                                        <Input name="parent_contact" placeholder={t('invite:parentContact')} type="text" disabled="disable" value={this.state.parent_contact} />
                                        <div className="form-group form-check">
                                            <Checkbox name="parent_contact_recheck" value={this.state.parent_contact_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('invite:parentDOB')}*</label>
                                        <Input name="parent_dob" placeholder={t('invite:parentDOB')} type="text" disabled="disable" value={this.state.parent_dob} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs small-bs">{this.state.parent_dob != null ? (convertADtoBS(this.state.parent_dob) != null ? convertADtoBS(this.state.parent_dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        <div className="form-group form-check">
                                            <Checkbox name="parent_dob_recheck" value={this.state.parent_dob_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('invite:Relation')} *</label>
                                        <Input name="relation" placeholder={t('invite:Relation')} type="text" disabled="disable" value={this.state.relation} />
                                        <div className="form-group form-check">
                                            <Checkbox name="parent_relation_recheck" value={this.state.parent_relation_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {this.state.user_type == UserType.Alumni && (
                            <Fragment>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('alumni:batch')} *</label>
                                        <Input name="alumni_category_id" placeholder={t('alumni:batch')} type="text" disabled="disable" value={this.state.alumni_batch != null ? this.state.alumni_batch.name : ''} />
                                        <div className="form-group form-check">
                                            <Checkbox name="batch_recheck" value={this.state.batch_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('alumni:faculty')} *</label>
                                        <Input name="almuni_group_id" placeholder={t('alumni:faculty')} type="text" disabled="disable" value={this.state.alumni_group != null ? this.state.alumni_group.name : ''} />
                                        <div className="form-group form-check">
                                            <Checkbox name="group_recheck" value={this.state.group_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('alumni:company')} *</label>
                                        <Input name="alumni_company_id" placeholder={t('alumni:company')} type="text" disabled="disable" value={this.state.alumni_company != null ? this.state.alumni_company.name : ''} />
                                        <div className="form-group form-check">
                                            <Checkbox name="company_recheck" value={this.state.company_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('alumni:designation')} *</label>
                                        <Input name="almuni_designation_id" placeholder={t('alumni:designation')} type="text" disabled="disable" value={this.state.alumni_designation != null ? this.state.alumni_designation.name : ''} />
                                        <div className="form-group form-check">
                                            <Checkbox name="designation_recheck" value={this.state.designation_recheck} onChange={this._handleRecheckChange} />
                                            <label className="form-check-label form-title small-checkbox">{t('invite:checkResubmit')}</label>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        <hr />
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h6 className="line-title heading5">{t('attendance:remarks')}</h6>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-12 float-left form-group">
                                <TextArea rows={10} name="remarks" value={this.state.remarks} />
                            </div>
                        </div>
                        <hr />
                        <div className="row clearfix jsuti">
                            <div className="col-sm-12 d-flex align-items-center justify-content-end float-right form-group">
                                {this.state.status == Status.ACTIVE && (
                                    <Fragment>
                                        <Button type="button" onClick={this.resubmit} text={t('invite:askResubmit')} />
                                        <Button type="button" onClick={this.approve} text={t('invite:accept')} main={true} />
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const InviteApproval = withTranslation()(connect(mapStateToProps)(InviteApprovalComponent))

export { InviteApproval }
