import React, { Component, Fragment } from 'react'
import { AbstractNepaliCalendarComponent } from './abstract'
import { NepaliMonth } from '../../helper/constant/nepali-month'
import { days, short_days } from '../../helper/constant/days'
import { convertBStoAD } from '../../helper/function'
import moment from 'moment'
import * as SVG from '~/assets/svg'
import { BSMonths } from '../../helper/date-converter'
import { date_color } from '../../helper/constant/color'

export class NepaliCalendar extends AbstractNepaliCalendarComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.error == false && (
                    <div class="Calendar -noFocusOutline  -ltr nepali-calendar" role="grid" bis_skin_checked="1">
                        <div class="Calendar__header" bis_skin_checked="1">
                            <div class="Calendar__monthYearContainer" data-testid="month-year-container" bis_skin_checked="1">
                                &nbsp;
                                <div class="Calendar__monthYear -shown" role="presentation" bis_skin_checked="1">
                                    <button type="button" class="Calendar__monthText" aria-label="Open Month Selector" tabIndex="0">
                                        {NepaliMonth[this.state.currentMonth]}
                                    </button>
                                    <button type="button" class="Calendar__yearText" aria-label="Open Year Selector" tabIndex="0">
                                        {this.state.currentYear}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="Calendar__weekDays" bis_skin_checked="1">
                            <abbr title="Sunday" class="Calendar__weekDay">
                                S
                            </abbr>
                            <abbr title="Monday" class="Calendar__weekDay">
                                M
                            </abbr>
                            <abbr title="Tuesday" class="Calendar__weekDay">
                                T
                            </abbr>
                            <abbr title="Wednesday" class="Calendar__weekDay">
                                W
                            </abbr>
                            <abbr title="Thursday" class="Calendar__weekDay">
                                T
                            </abbr>
                            <abbr title="Friday" class="Calendar__weekDay">
                                F
                            </abbr>
                            <abbr title="Saturday" class="Calendar__weekDay">
                                S
                            </abbr>
                        </div>
                        <div class="Calendar__sectionWrapper" role="presentation" data-testid="days-section-wrapper" bis_skin_checked="1">
                            <div class="Calendar__section -shown" role="rowgroup" bis_skin_checked="1">
                                {this.state.calendar.map((calendar, calendarIndex) => {
                                    return (
                                        <div class="Calendar__weekRow" role="row" bis_skin_checked="1">
                                            {calendar.map((item, index) => {
                                                const dayName = short_days[index]
                                                let calendarClass = 'fc-daygrid-day fc-day'
                                                if (item == 0) {
                                                    calendarClass = calendarClass + ' fc-day-' + dayName + ' fc-day-disabled'
                                                } else {
                                                    calendarClass = calendarClass + ' fc-day-' + dayName + ' fc-day-past'
                                                }
                                                const currentMonth = this.state.currentMonth + 1
                                                const month = currentMonth < 10 ? '0' + currentMonth : currentMonth
                                                const day = item < 10 ? '0' + item : item
                                                const date = this.state.currentYear + '-' + month + '-' + day
                                                let events = []
                                                if (item != 0) {
                                                    events = this.state.events.filter((event) => {
                                                        const englishDate = moment(convertBStoAD(date), 'yyyy-MM-DD')
                                                        const from_date = moment(event.year + '-' + event.month + '-' + event.day, 'yyyy-MM-DD')
                                                        if (englishDate.isSame(from_date)) {
                                                            return true
                                                        }
                                                    })
                                                }
                                                let className = ''
                                                if (index == 6 && item != 0) {
                                                    className = date_color[0] + ' -selected'
                                                }
                                                if (events.length > 0) {
                                                    className = date_color[1] + ' -selected'
                                                }
                                                return (
                                                    <div tabIndex="0" class={'Calendar__day -ltr ' + className} aria-disabled="false" aria-label="Monday, 1 May 2023" aria-selected="false" role="gridcell" data-is-default-selectable="true" bis_skin_checked="1">
                                                        {item == 0 ? '' : item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div class="Calendar__footer" bis_skin_checked="1"></div>
                    </div>
                )}
                {this.state.error && <p className="no-data">Invalid Date Range</p>}
            </Fragment>
        )
    }
}
