import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractConstantCategoryEditComponent, mapStateToProps } from './abstract'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import Select from 'react-select'
import { ColorPicker } from '~/components/form/color-picker'

class ConstantCategoryEditComponent extends AbstractConstantCategoryEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }
    selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="constant" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.constant.menu}>
                                    {t('constant:constant')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.constant.category.view}>
                                    {t('alumni:category')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('constant:editcategory')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('constant:categoryname')} * </label>
                                    <Input
                                        name="name"
                                        value={this.state.name}
                                        placeholder={t('constant:categoryname')}
                                        onChange={this._handleChange}
                                        type="text"
                                    />
                                    {this.state.errors.name && (<div className="error text-danger">{this.state.errors.name}</div>)}
                                </div>
                                <div className="col-md-6 float-left form-group">
                                    <label className="form-title text-left">{t('constant:categorytype')} *</label>
                                    <Select
                                        name="class"
                                        options={this.state.category_list}
                                        placeholder=""
                                        value={this.state.defaultType}
                                        onChange={(data) => this.selectList('Type', data)}
                                        searchable={true}
                                    />
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('constant:color')} *</label>

                                    <ColorPicker value={this.state.color} name="color" onChange={this._handleChange} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const ConstantCategoryEdit = withTranslation()(connect(mapStateToProps)(ConstantCategoryEditComponent))
export { ConstantCategoryEdit }
