import $ from 'jquery'
import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { messaging } from '~/firebaseMessaging'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'
import { logout } from '~/services/api'
import { post_search, signout } from '~/store'

type Props = {
    isLogged: Boolean,
    user: Object,
    school: Object,
    postSearch: Object,
    className: String,
    hideToggle: Boolean,
    schoolPackage: String,
    history: Object,
    dispatch: Dispatch<any>,
}

type State = {
    hideToggle: Boolean,
    date: string,
    from_date: String,
    to_date: String,
    post_type: Array,
    user_type: Array,
    errors: Object,
    access: Array,
}

export class AbstractHeaderComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            date: moment(today).format('Do MMM'),
            hideToggle: this.props.hideToggle ? this.props.hideToggle : false,
            from_date:
                this.props.postSearch != null
                    ? this.props.postSearch.from_date != null
                        ? moment(this.props.postSearch.from_date, 'yyyy-MM-DD').toDate()
                        : ''
                    : '',
            to_date:
                this.props.postSearch != null
                    ? this.props.postSearch.to_date != null
                        ? moment(this.props.postSearch.to_date, 'yyyy-MM-DD').toDate()
                        : ''
                    : '',
            post_type:
                this.props.postSearch != null
                    ? this.props.postSearch.post_type != null
                        ? this.props.postSearch.post_type
                        : []
                    : [],
            user_type:
                this.props.postSearch != null
                    ? this.props.postSearch.user_type != null
                        ? this.props.postSearch.user_type
                        : []
                    : [],
            errors: {
                from_date: null,
                to_date: null,
            },
            notificationCount: 0,
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setPostType = this.setPostType.bind(this)
        this.setUserType = this.setUserType.bind(this)
        this.clearState = this.clearState.bind(this)
        this.gotoActivity = this.gotoActivity.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAlumni != this.props.isAlumni) {
            this.clearState()
        }
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }

    async componentDidMount() {
        let device_id = null
        await messaging()
            .requestPermission()
            .then(async function () {
                const token = await messaging().getToken()
                device_id = token
            })
            .catch(function (err) {})
        await this.setState({
            device_id,
        })
    }

    gotoActivity(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            $('#search_close').trigger('click')
            const data = {
                from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
                post_type: this.state.post_type,
                user_type: this.state.user_type,
            }
            this.props.dispatch(post_search(data))
            redirect(route.activity, this.props.history)
        }
    }

    setPostType(id) {
        let post_type = this.state.post_type
        const check = post_type.find((item) => item == id)
        if (check != undefined) {
            post_type = post_type.filter((item) => item != id)
        } else {
            post_type.push(id)
        }
        this.setState({
            post_type,
        })
    }

    setUserType(id) {
        let user_type = this.state.user_type
        const check = user_type.find((item) => item == id)
        if (check != undefined) {
            user_type = user_type.filter((item) => item != id)
        } else {
            user_type.push(id)
        }
        this.setState({
            user_type,
        })
    }

    _logout() {
        this._handleChange('loading', true)
        const token = this.props.token
        this.props.dispatch(signout())
        logout(this.props.token, this.state.device_id).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                redirect(route.index, this.props.history)
                toastMessage('success', response.message)
                // window.location = APP_URL
            } else {
                redirect(route.index, this.props.history)
            }
        })
    }

    clearState() {
        this.setState({
            from_date: null,
            to_date: null,
            post_type: [],
            user_type: [],
        })
        this.props.dispatch(post_search(null))
    }
}

export function mapStateToProps(state: Object) {
    return {
        user: state.user,
        isLogged: state.isLogged,
        school: state.school,
        postSearch: state.postSearch,
        schoolPackage: state.schoolPackage,
        isAlumni: state.isAlumni,
        isFullScreen: state.isFullScreen,
    }
}
