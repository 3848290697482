import { get, post } from './request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getAttendanceReport = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('report/attendance', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const reportPost = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('report/post', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getReport = (from, to, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('moderate-post/' + from + '/' + to, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
