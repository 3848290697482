import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSection, viewTimetable, toggleTeacherTimetable, viewTeacher, teacherTimetable, activeClass } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import moment from 'moment'
import { components } from 'react-select'
import * as validation from '~/helper/constant/validation'
import { faThumbsDown } from '@fortawesome/free-regular-svg-icons'
import * as UserType from '~/helper/constant/user_type'
import { redirect } from '~/helper/function/util'
import * as Status from '~/helper/constant/status'

type Props = {
    token: String,
    school: Object,
    user: Object,
}

type State = {
    loading: Boolean,
    today: String,
    activeDay: String,
    classes: Object,
    section: Object,
    timetable: Array,
    filterTimeTable: Array,
    dispatch: Dispatch<any>,
    teacher: Array,
    loading_text: String,
    day: String,
}

export class AbstractTimetableComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loaded: false,
            loading: false,
            loading_text: 'Loading',
            today: moment(today).format('dddd'),
            timetable: [],
            filterTimeTable: [],
            activeDay: days.find((item) => item.value == 0),
            teacher: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.changeDay = this.changeDay.bind(this)
        this.nextDay = this.nextDay.bind(this)
        this.prevDay = this.prevDay.bind(this)
        this.loadSection = this.loadSection.bind(this)
        this._toggle = this._toggle.bind(this)
        this._join = this._join.bind(this)
    }

    componentDidMount() {
        this.initialize()
    }

    initialize() {
        const item = days.find((item) => item.label == this.state.today)
        this._handleChange('activeDay', item)
        if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            this.loadSection()
        }
        if (this.props.school.user_type == UserType.Teacher) {
            this.loadTeacherTimetable()
        }
        this.loadTeacher()
    }

    loadTeacher() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewTeacher(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let teacher = []
            if (response.code == API_SUCCESS) {
                response.data.teacher.map((item) => {
                    const data = {
                        label: item.user.name + ' - ' + item.user.reg_no,
                        value: item.id,
                    }
                    teacher.push(data)
                })
                if (teacher.length > 0) {
                    this.setState({
                        teacher,
                    })
                }
            }
        })
    }

    loadTeacherTimetable() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        teacherTimetable(this.props.school.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let timetable = []
            if (response.code == API_SUCCESS) {
                response.data.timetable.map((item, index) => {
                    timetable.push(item)
                })
                this.setState(
                    {
                        timetable,
                        loaded: true,
                    },
                    () => {
                        this.filterTable()
                    }
                )
            }
        })
    }

    _toggle(e) {
        let link = e
        if (typeof document != 'undefined') {
            link = e.target.getAttribute('link')
        }
        toggleTeacherTimetable(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                const timetable = response.data.timetable
                if (timetable.status == Status.ACTIVE) {
                    this.loadTeacherTimetable()
                    if (timetable.bbb != null) {
                        if (typeof document != 'undefined') {
                            window.open(route.bbb + '/' + timetable.bbb.id, '_blank')
                        } else {
                            redirect(route.bbb + '/' + timetable.bbb.id, this.props.history)
                        }
                    }
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _join(e) {
        let link = e
        if (typeof document != 'undefined') {
            link = e.target.getAttribute('link')
        }
        let id = link.split(',')
        if (typeof document != 'undefined') {
            window.open(route.bbb + '/' + id[1], '_blank')
        } else {
            redirect(route.bbb + '/' + id[1], this.props.history)
        }
    }

    loadSection() {
        if (this.props.school.user_type == UserType.Parents) {
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let section = null
                let classes = null
                if (response.code == API_SUCCESS) {
                    section = response.data.section
                    classes = response.data.section.classes
                    this.setState(
                        {
                            classes,
                            section,
                        },
                        () => {
                            this.loadTimetable()
                        }
                    )
                }
            })
        } else {
            const id = this.props.school.active_user_academics != null ? this.props.school.active_user_academics.section_id : null
            getSection(id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                let section = null
                let classes = null
                if (response.code == API_SUCCESS) {
                    section = response.data.section
                    if (response.data.section.classes) {
                        classes = response.data.section.classes
                    }
                    this.setState(
                        {
                            classes,
                            section,
                        },
                        () => {
                            this.loadTimetable()
                        }
                    )
                }
            })
        }
    }

    loadTimetable() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewTimetable(this.state.section.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let timetable = []
            if (response.code == API_SUCCESS) {
                response.data.timetable.map((item, index) => {
                    timetable.push(item)
                })
                this.setState(
                    {
                        timetable,
                        loaded: true,
                    },
                    () => {
                        this.filterTable()
                    }
                )
            }
        })
    }

    filterTable() {
        let filterTimeTable = []
        this.state.timetable
            .filter((item) => item.day == this.state.activeDay.value)
            .sort((a, b) => {
                const a_start_time = moment(a.start_time, 'hh:mm a')
                const b_start_time = moment(b.start_time, 'hh:mm a')
                return a_start_time.isAfter(b_start_time) ? 1 : -1
            })
            .map((item) => {
                filterTimeTable.push(item)
            })
        this.setState({
            filterTimeTable,
        })
    }

    nextDay(value) {
        if (value < 6) {
            this.changeDay(value + 1)
        } else {
            this.changeDay(0)
        }
    }

    prevDay(value) {
        if (value > 0) {
            this.changeDay(value - 1)
        } else {
            this.changeDay(6)
        }
    }

    changeDay(value) {
        const activeDay = days.find((item) => item.value == value)
        this.setState(
            {
                activeDay,
            },
            () => {
                this.filterTable()
            }
        )
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        user: state.user,
    }
}
