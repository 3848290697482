import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { previousAcademic, activeClass, activeSection, viewAcademicStudent, sectionTransfer, viewStudent } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'
import * as RollType from '~/helper/constant/roll_type'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    classAry: Array,
    data: Array,
    selectedClass: String,
    errors: Object,
    selectAll: Boolean,
}

export class AbstractPromoteStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            data: [],
            student: [
                {
                    roll_no: {
                        field: 'text',
                        translate: 'academic:promote.rollNo',
                        route: false,
                        sortable: true,
                    },
                    reg_no: {
                        field: 'text',
                        translate: 'attendance:regNo',
                        route: false,
                        sortable: true,
                    },
                    student: {
                        field: 'text',
                        translate: 'academic:promote.student',
                        route: false,
                        sortable: true,
                    },
                },
            ],
            classAry: [],
            sectionAry: [],
            studentAry: [],
            filteredSectionAry: [],
            defaultClass: null,
            defaultSection: null,
            defaultStudent: [],
            defaultFilteredClass: null,
            defaultFilteredSection: null,
            selectedClass: null,
            selectedSection: null,
            selectedStudent: [],
            filteredClass: null,
            filteredSection: null,
            rollError: [],
            academicYear: [],
            defaultAcademicYear: null,
            selectedAcademicYear: null,
            errors: {
                selectedClass: null,
                selectedSection: null,
                filteredSection: null,
                filteredClass: null,
                selectedStudent: null,
                selectedAcademicYear: null,
            },
            selectAll: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._selectAll = this._selectAll.bind(this)
    }

    _selectAll(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                const selectedStudent = []
                const defaultStudent = []
                if (this.state.selectAll) {
                    this.state.studentAry.map((item) => {
                        defaultStudent.push(item)
                        selectedStudent.push(item.value)
                    })
                }
                this.setState({
                    defaultStudent,
                    selectedStudent,
                })
            }
        )
    }

    componentDidMount() {
        this.loadAcademicYear()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAcademicYear() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        previousAcademic(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let academicYear = []
            if (response.code == API_SUCCESS) {
                if (response.data.academic_year.length == 0) {
                    redirect(route.admin.academic.view, this.props.history)
                }
                response.data.academic_year.map((item, index) => {
                    academicYear.push({
                        value: item.id,
                        label: item.name,
                    })
                })
                this.setState(
                    {
                        academicYear,
                        defaultAcademicYear: academicYear.length > 0 ? academicYear[0] : null,
                        selectedAcademicYear: academicYear.length > 0 ? academicYear[0].value : null,
                    },
                    () => {
                        this.loadClass()
                    }
                )
            }
        })
    }

    loadClass() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classAry = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classAry.push({
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                    })
                })
                this._handleChange('data', response.data.class)
                this._handleChange('classAry', classAry)
                this._handleChange('defaultClass', classAry.length > 0 ? classAry[0] : null)
                this._handleChange('selectedClass', classAry.length > 0 ? classAry[0].value : null)
                this._handleChange('defaultFilteredClass', classAry.length > 0 ? classAry[0] : null)
                this._handleChange('filteredClass', classAry.length > 0 ? classAry[0].value : null)
                this._handleChange('studentAry', [])
                this._handleChange('defaultStudent', [])
                this._handleChange('selectedStudent', [])
                if (classAry.length > 0) {
                    this.loadSection(classAry[0].value)
                    this.loadFilteredSection(classAry[0].value)
                }
            }
        })
    }

    loadSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let sectionAry = []
        let defaultSection = null
        let selectedSection = null
        classes.active_section.map((item, index) => {
            sectionAry.push({ value: item.id, label: item.name + ' - ' + item.code, roll_type: item.roll_type, roll_number: item.roll_number })
        })
        if (sectionAry.length > 0) {
            defaultSection = sectionAry[0]
            selectedSection = sectionAry[0].value
        }
        this.setState(
            {
                sectionAry,
                defaultSection,
                selectedSection,
                studentAry: [],
                defaultStudent: [],
                selectedStudent: [],
            },
            () => {
                this.loadStudent()
            }
        )
    }

    loadFilteredSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let filteredSectionAry = []
        let defaultFilteredSection = null
        let filteredSection = null
        classes.active_section.map((item, index) => {
            filteredSectionAry.push({ value: item.id, label: item.name + ' - ' + item.code, roll_type: item.roll_type, roll_number: item.roll_number })
        })
        if (filteredSectionAry.length > 0) {
            defaultFilteredSection = filteredSectionAry[0]
            filteredSection = filteredSectionAry[0].value
        }
        this.setState(
            {
                filteredSectionAry,
                defaultFilteredSection,
                filteredSection,
            },
            () => {
                this.loadCurrentStudent()
            }
        )
    }

    loadStudent() {
        if (this.state.selectedAcademicYear != null && this.state.selectedSection != null) {
            viewAcademicStudent(this.state.selectedAcademicYear, this.state.selectedSection, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    let studentAry = []
                    response.data.student.map((item, index) => {
                        studentAry.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                            rollNo: '',
                        })
                    })
                    this._handleChange('studentAry', studentAry)
                }
            })
        }
    }

    loadCurrentStudent() {
        if (this.state.filteredSection != null) {
            let student = []
            student.push(this.state.student[0])
            viewStudent(this.state.filteredSection, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    response.data.student.map((item, index) => {
                        const tableData = {
                            roll_no: {
                                text: item.user_academics[0].roll_no,
                            },
                            reg_no: {
                                text: item.user.reg_no,
                            },
                            student: {
                                text: item.user.name,
                            },
                        }
                        student.push(tableData)
                    })
                    this.setState({
                        student,
                    })
                }
            })
        }
    }

    _onSave() {
        const { t } = this.props
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        const rollError = []
        this.setState({ errors, rollError })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            if (this.state.defaultFilteredSection.roll_type == RollType.MANUAL) {
                const roll_number = []
                this.state.defaultFilteredSection.roll_number.map((item) => {
                    roll_number.push(item.roll_no)
                })
                this.state.defaultStudent.map((item, index) => {
                    if (item.rollNo == '') {
                        rollError[item.value] = t('common:required')
                    } else if (roll_number.includes(item.rollNo)) {
                        rollError[item.value] = t('toaster:duplicate')
                    } else {
                        roll_number.push(item.rollNo)
                    }
                })
            }

            if (rollError.length > 0) {
                this.setState({ rollError })
            } else {
                const data = {
                    section_id: this.state.filteredSection,
                    student: this.state.defaultStudent,
                }
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                sectionTransfer(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        this.loadCurrentStudent()
                        toastMessage('success', response.message)
                    } else {
                        toastMessage('error', response.message)
                    }
                })
            }
        }
    }
}

const AbstractPromoteStudent = withTranslation()(AbstractPromoteStudentComponent)

export { AbstractPromoteStudent }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
