import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { convertADtoBS, getJournalId } from '../../../../../../helper/function/abstract'
import { AbstractJournalAccountInvoiceComponent, mapStateToProps } from './abstract'

class JournalAccountInvoiceComponent extends AbstractJournalAccountInvoiceComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.ref
    }

    render() {
        const { t } = this.props
        let totalCredit = 0
        let totalDebit = 0
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="double_entry" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.journalaccount.view}>
                                    {t('doubleentry:journalaccount')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('income:invoice')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-8">
                                <h5 className="main-title heading5">{getJournalId(this.props.match.params.id)} :</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={
                                            this.props.school.short_code +
                                            '-' +
                                            getJournalId(this.props.match.params.id)
                                        }
                                        trigger={() => <a className="main-button">Print</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-table">
                        {this.state.journalVoucher != null && (
                            <div className="table-responsive">
                                <table
                                    className="table invoice_table"
                                    ref={(div) => {
                                        this.ref = div
                                    }}
                                >
                                    <tr className="invoice_table_border">
                                        <td colSpan={5} className="text-center">
                                            <div className="invoice-image">
                                                <img src={this.props.school.profile} />
                                            </div>
                                            <div className="invoice-title">{this.props.school.name}</div>
                                            <div className="invoice-sub-title">Journal Voucher</div>
                                        </td>
                                    </tr>
                                    <tr className="invoice_table_border">
                                        <td colSpan={5} className="text-right">
                                            <div className="invoice_detail">
                                                <strong>{getJournalId(this.props.match.params.id)}</strong>
                                            </div>
                                            <div className="invoice_detail">
                                                <strong>Journal Date : </strong>
                                                <br />
                                                {this.props.school.date_format == 'BS'
                                                    ? convertADtoBS(this.state.journalVoucher.date)
                                                    : this.state.journalVoucher.date}
                                            </div>
                                            <div className="invoice_detail">
                                                <strong>Journal Reference : </strong>
                                                <br />
                                                {this.state.journalVoucher.reference}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="invoice_table_border invoice_table_header_dark">
                                        <td>
                                            <strong>{t('academic:table.s.no')}</strong>
                                        </td>
                                        <td>
                                            <strong>{t('banking:account')}</strong>
                                        </td>
                                        <td>
                                            <strong>{t('doubleentry:debit')}</strong>
                                        </td>
                                        <td>
                                            <strong>{t('doubleentry:credit')}</strong>
                                        </td>
                                        <td>
                                            <strong>{t('common:description')}</strong>
                                        </td>
                                    </tr>
                                    {this.state.journalVoucher.accounts.length > 0 && (
                                        <Fragment>
                                            {this.state.journalVoucher.accounts.map((item, index) => {
                                                totalCredit = totalCredit + parseFloat(item.credit)
                                                totalDebit = totalDebit + parseFloat(item.debit)
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{this.state.chartOfAccounts[item.account]}</td>
                                                        <td>
                                                            <small>{this.props.school.currency}</small>&nbsp;
                                                            {parseFloat(item.debit).toFixed(2)}
                                                        </td>
                                                        <td>
                                                            <small>{this.props.school.currency}</small>&nbsp;
                                                            {parseFloat(item.credit).toFixed(2)}
                                                        </td>
                                                        <td>{item.description}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="invoice_total_border invoice_table_header_dark">
                                                <td colSpan={2} className="text-right">
                                                    <strong>Total :</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    <small>{this.props.school.currency}</small>&nbsp;
                                                    <strong>{parseFloat(totalDebit).toFixed(2)}</strong>
                                                </td>
                                                <td>
                                                    <small>{this.props.school.currency}</small>&nbsp;
                                                    <strong>{parseFloat(totalCredit).toFixed(2)}</strong>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </Fragment>
                                    )}
                                    {this.state.journalVoucher.accounts.length == 0 && (
                                        <tr>
                                            <td colSpan={5}>
                                                <p className="no-data">
                                                    {SVG.nodata()}
                                                    {t('common:table.nodata')}
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.journalVoucher.description != null && (
                                        <tr>
                                            <td colSpan={5}>
                                                <small>
                                                    <strong>Note :</strong>
                                                    <br />
                                                    <i>{this.state.journalVoucher.reference}</i>
                                                </small>
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            </div>
                        )}
                        {this.state.journalVoucher == null && (
                            <p className="no-data">
                                {SVG.nodata()}
                                {t('common:table.nodata')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const JournalAccountInvoice = withTranslation()(connect(mapStateToProps)(JournalAccountInvoiceComponent))
export { JournalAccountInvoice }
