import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { saveChildrenProfile, getChildrenProfile } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { UserDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { profile_update } from '~/store'
import { gender } from '~/helper/constant/gender'
import { country } from '~/helper/constant/country'
import { blood_group } from '~/helper/constant/blood_group'
import { redirect } from '~/helper/function/util'

type Props = {
    user: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    name: String,
    contact: String,
    father_name: String,
    mother_name: String,
    gender: String,
    country: String,
    defaultGender: Object,
    defaultCountry: Object,
    permanent_address: String,
    temporary_address: String,
    blood_group: String,
    defaultBloodGroup: Object,
    user: Object,
    loading: Boolean,
    errors: Object,
    media: Array,
    deletedMedia: Array,
    uploads: Array,
    uploadedMedia: Array,
    profile: Object,
    profileBlob: Array,
}

export class AbstractChildrenProfileComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            contact: '',
            user: null,
            father_name: '',
            mother_name: '',
            gender: '',
            defaultGender: '',
            country: '',
            defaultCountry: '',
            permanent_address: '',
            temporary_address: '',
            blood_group: '',
            defaultBloodGroup: '',
            uploadedMedia: [],
            media: [],
            uploads: [],
            deletedMedia: [],
            profile: {},
            profileBlob: [],
            errors: {
                name: null,
                contact: null,
                father_name: null,
                mother_name: null,
                gender: null,
                country: null,
                permanent_address: null,
            },
        }
        this._onSave = this._onSave.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.getProfile()
    }

    getProfile() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getChildrenProfile(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const user = response.data.children.children.user
                this.setState({
                    name: user ? user.name : null,
                    contact: user ? user.contact : null,
                    user: user ? user : null,
                    father_name: user.user_detail ? (user.user_detail.father_name != null ? user.user_detail.father_name : '') : '',
                    mother_name: user.user_detail ? (user.user_detail.mother_name != null ? user.user_detail.mother_name : '') : '',
                    gender: user.user_detail ? (user.user_detail.gender != null ? user.user_detail.gender : '') : '',
                    defaultGender: user.user_detail ? (user.user_detail.gender != null ? gender.find((item) => item.value == user.user_detail.gender) : '') : '',
                    country: user.user_detail ? (user.user_detail.nationality != null ? user.user_detail.nationality : '') : '',
                    defaultCountry: user.user_detail ? (user.user_detail.nationality != null ? country.find((item) => item.value == user.user_detail.nationality) : '') : '',
                    permanent_address: user.user_detail ? (user.user_detail.permanent_address != null ? user.user_detail.permanent_address : '') : '',
                    temporary_address: user.user_detail ? (user.user_detail.temporary_address != null ? user.user_detail.temporary_address : '') : '',
                    blood_group: user.user_detail ? (user.user_detail.blood_group != null ? user.user_detail.blood_group : '') : '',
                    defaultBloodGroup: user.user_detail ? (user.user_detail.blood_group != null ? blood_group.find((item) => item.value == user.user_detail.blood_group) : '') : '',
                    uploadedMedia: [],
                })
                if (user.user_detail.gallery) {
                    if (user.user_detail.gallery.media) {
                        const images = user.user_detail.gallery.media.find((item) => item.media_type.includes('data:image/'))
                        const documents = user.user_detail.gallery.media.filter((item) => !item.media_type.includes('data:image/'))
                        this._handleChange('uploadedMedia', documents)
                        const profileBlob = []
                        if (images != null) {
                            const profile = {
                                0: images.media_type + ';base64,' + images.media_data,
                            }
                            profileBlob.push(images)
                            this._handleChange('profile', profile)
                            this._handleChange('profileBlob', profileBlob)
                        }
                    }
                }
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                contact: this.state.contact,
                father_name: this.state.father_name,
                mother_name: this.state.mother_name,
                gender: this.state.gender,
                nationality: this.state.country,
                permanent_address: this.state.permanent_address,
                temporary_address: this.state.temporary_address,
                blood_group: this.state.blood_group,
                media: this.state.media,
                deletedMedia: this.state.deletedMedia,
                profile: this.state.profile,
            }
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            saveChildrenProfile(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.children, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        user: state.user,
        token: state.token,
    }
}
