import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { role_type } from '~/helper/constant/user_type'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import { AbstractUserModelComponent, mapStateToProps } from './abstract'
import moment from 'moment'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
export class UserModelComponent extends AbstractUserModelComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        const item = this.state.userData
        let name = null
        let profile = null
        let user = null
        let user_type = null
        if (item.user) {
            user = item.user
            user_type = item.user_type
            name = item.user.name != null ? item.user.name.charAt(0).toUpperCase() : 'CM'
            profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
        } else {
            user = item.user_school.user
            user_type = item.user_school.user_type
            name = item.user_school.user.name != null ? item.user_school.user.name.charAt(0).toUpperCase() : 'CM'
            profile = item.user_school.user.user_detail != null ? (item.user_school.user.user_detail.profile != null ? item.user_school.user.user_detail.profile : null) : null
        }
        if (user) {
            return (
                <div className="modal-dialog role-pop  modal-lg main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('student:student.info.title')}:</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content mb-0">
                            <div className="video-manage clearfix">
                                <div className="role-pop-head">
                                    <ul className="nav" id={'myTab-' + item.id} role="tablist">
                                        <li>
                                            <a className="active show" id={'userinfo-' + item.id} data-toggle="tab" href={'#userinfo-tab-' + item.id} role="tab" aria-controls="userinfo" aria-selected="true">
                                                {t('student:student.info.user-info.userInfo')}
                                            </a>
                                        </li>
                                        <li>
                                            <a id={'session-' + item.id} data-toggle="tab" href={'#session-tab-' + item.id} role="tab" aria-controls="session" aria-selected="false" className="" onClick={() => this.fetchSession(user.id)}>
                                                {t('student:student.info.session.session')}
                                            </a>
                                        </li>
                                        <li>
                                            <a id={'reset-' + item.id} data-toggle="tab" href={'#reset-tab-' + item.id} role="tab" aria-controls="reset" aria-selected="false">
                                                {t('student:student.info.reset.reset')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="role-pop-content">
                                    <div className="tab-content" id={'myTabContent-' + item.id}>
                                        <div className="tab-pane fade active show" id={'userinfo-tab-' + item.id} role="tabpanel" aria-labelledby="userinfo-tab">
                                            <div className="clearfix row user-info-content p-10">
                                                <div className="col-md-3">
                                                    {profile != null && <img src={profile} className="role-user-img" alt="user-img" />}
                                                    {profile == null && <span className="role-user-img">{name}</span>}
                                                </div>
                                                <div className="col-md-4 mid-content">
                                                    <div className="info-one">
                                                        <span> {t('student:student.info.user-info.regNo')}.</span>
                                                        <ins>{user.reg_no}</ins>
                                                        <hr />
                                                    </div>
                                                    <div className="info-one">
                                                        <span>{t('student:student.info.user-info.emailAddress')}</span>
                                                        <ins>
                                                            {user.email == null && 'N/A'}
                                                            {user.email != null && user.email}
                                                        </ins>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="info-one">
                                                        <span>{t('student:student.info.user-info.name')}</span>
                                                        <ins>
                                                            {user.name == null && 'N/A'}
                                                            {user.name != null && user.name}
                                                        </ins>
                                                        <hr />
                                                    </div>
                                                    <div className="info-one">
                                                        <span>{t('student:student.info.user-info.role')}</span>
                                                        <ins>{role_type.find((data) => data.value == user_type).label}</ins>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="tab-pane fade" id={'session-tab-' + item.id} role="tabpanel" aria-labelledby="session-tab">
                                            <div className="clearfix row session-content">
                                                <Loading status={this.state.loading} text="Loading" />
                                                {(this.state.session.length > 0 &&
                                                    this.state.session.map((data, index) => {
                                                        return (
                                                            <div className="col-md-4">
                                                                <div className="session-list">
                                                                    <div className="session-icon">{SVG.usermonitor()}</div>
                                                                    <div className="session-info" data-toggle="collapse" data-target={'#session-' + item.id + '-' + index}>
                                                                        <span>{data.ip_address}</span>
                                                                        <ins>Logged in {moment.unix(data.last_activity).fromNow()}</ins>
                                                                        <div id={'session-' + item.id + '-' + index} className="collapse">
                                                                            <div className="clearfix session-extra">
                                                                                <span>OS:</span>
                                                                                <ins>{data.device.os}</ins>
                                                                            </div>
                                                                            <div className="clearfix session-extra">
                                                                                <span>Browser:</span>
                                                                                <ins>{data.device.browser}</ins>
                                                                            </div>
                                                                            <div className="clearfix session-extra">
                                                                                <span>Device:</span>
                                                                                <ins>{data.device.device}</ins>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })) || (
                                                    <p className="no-data">
                                                        {SVG.nodata()}
                                                        {t('student:student.info.session.noData')}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id={'reset-tab-' + item.id} role="tabpanel" aria-labelledby="reset-tab">
                                            <br />
                                            <button
                                                type="button"
                                                className="cancel-button resetBtn"
                                                onClick={() => {
                                                    $('.resetPasswordAlert').fadeIn()
                                                    $('.resetBtn').hide()
                                                }}
                                            >
                                                {t('common:resetPasswordBtn')}
                                            </button>
                                            <div className="resetPasswordAlert">
                                                <p>{t('student:student.info.reset.resetPasswordMsg')}</p>
                                                <p>
                                                    {t('student:student.info.reset.newPassword')} : {user.dob.replaceAll('-', '')}
                                                </p>
                                                <small>
                                                    <a className="main-button" onClick={() => this.resetPassword(user.id)}>
                                                        {t('common:yesBtn')}
                                                    </a>
                                                    <a
                                                        className="user-info cancel-button" 
                                                        onClick={() => {
                                                            $('.resetPasswordAlert').hide()
                                                            $('.resetBtn').fadeIn()
                                                        }}
                                                    >
                                                        {t('common:modal cancel')}
                                                    </a>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center form-group">
                        <button type="upload" className="main-button" data-dismiss="modal">
                            {t('common:modal close')}
                        </button>
                    </div>
                </div>
            )
        }
    }
}
const UserModel = withTranslation()(connect(mapStateToProps)(UserModelComponent))
export { UserModel }
