const loggedReducer = (state = false, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return true
        case 'SIGN_OUT':
            if (typeof document != 'undefined') {
                localStorage.removeItem('videoPlayback')
            }
            return false
        case 'RESET':
            if (typeof document != 'undefined') {
                localStorage.removeItem('videoPlayback')
            }
            return false
        default:
            return state
    }
}

export default loggedReducer
