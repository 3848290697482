import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractNotificationComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'
import moment from 'moment'
import {withTranslation} from 'react-i18next'
class NotificationComponent extends AbstractNotificationComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="" />
                <div className="main-content">
                    <Header />
                    <div className="main-box">
                        <div className="main-head-option">
                            <div className="row Clearfix">
                                <div className="col-md-12 float-left">
                                    <h5 className="main-title heading5">{t('notification:notification')} :</h5>
                                </div>
                            </div>
                        </div>
                        <div className="posted main-background main-mb">
                            <div className="posted-main">
                                <div className="clearfix">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="other-user-detail">
                                                <strong>{this.state.title}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="notification-time">{moment(this.state.created_at).fromNow()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="posted-main">
                                <p className="post-paragraph">{this.state.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Notification = withTranslation() (connect(mapStateToProps)(NotificationComponent))

export { Notification }
