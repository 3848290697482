import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewEmployee = (user_type, role, token) => {
    let url = 'employee-management/employee/role/' + user_type + '/' + role
    if (role == null || role == 0) {
        url = 'employee-management/employee/role/' + user_type
    }
    return new Promise(function (resolve, reject) {
        try {
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewTeacher = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee/teacher', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addEmployee = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editEmployee = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteEmployee = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee/delete/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleEmployee = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getEmployee = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
