import { Component } from 'react'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import { CHART_OF_ACCOUNT_SUB_TYPE } from '../../../../../../helper/constant/chart_of_account'
import * as route from '../../../../../../helper/constant/route'
import * as Status from '../../../../../../helper/constant/status'
import { viewChartOfAccount } from '../../../../../../services/api/accounting/doubleentry'

const tableData = {
    code: {
        field: 'text',
        route: false,
        sortable: true,
        translate: 'fee:code',
    },
    name: {
        field: 'text',
        route: false,
        sortable: true,
        translate: 'common:name',
    },
    type: {
        field: 'text',
        route: false,
        sortable: false,
        translate: 'attendance:type',
    },
    balance: {
        field: 'text',
        route: false,
        sortable: true,
        translate: 'doubleentry:balance',
    },
    status: {
        field: 'badge',
        route: false,
        sortable: true,
        translate: 'common:status',
    },
    action: {
        field: 'button',
        route: true,
        sortable: false,
        translate: 'activity:action',
    },
}
export class AbstractChartAccountViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            assets: [tableData],
            liabilities: [tableData],
            expenses: [tableData],
            income: [tableData],
            equity: [tableData],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadChartOfAccount()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadChartOfAccount() {
        this._handleChange('loading', true)
        const assets = [tableData]
        const liabilities = [tableData]
        const expenses = [tableData]
        const income = [tableData]
        const equity = [tableData]
        viewChartOfAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                console.log(response.data.chartAccounts)
                response.data.chartAccounts.Assets.map((item, index) => {
                    let text = null
                    if (item.is_enabled == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const type = CHART_OF_ACCOUNT_SUB_TYPE.find((itemType) => itemType.value == item.sub_type)
                    const credit = item.accounts_sum_credit ? item.accounts_sum_credit : 0
                    const debit = item.accounts_sum_debit ? item.accounts_sum_debit : 0
                    const balance = parseFloat(credit) - parseFloat(debit)
                    const data = {
                        code: {
                            text: item.code,
                        },
                        name: {
                            text: item.name,
                        },
                        type: {
                            text: type != null ? type.label : '',
                        },
                        balance: {
                            text:
                                balance < 0
                                    ? this.props.school.currency + ' ' + (balance * -1).toFixed(2)
                                    : this.props.school.currency + ' ' + balance.toFixed(2),
                            color: balance < 0 ? 'red' : 'black',
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.doubleentry.chartofaccount.edit + '/' + item.id,
                        },
                    }
                    assets.push(data)
                })
                response.data.chartAccounts.Liabilities.map((item, index) => {
                    let text = null
                    if (item.is_enabled == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const type = CHART_OF_ACCOUNT_SUB_TYPE.find((itemType) => itemType.value == item.sub_type)
                    const credit = item.accounts_sum_credit ? item.accounts_sum_credit : 0
                    const debit = item.accounts_sum_debit ? item.accounts_sum_debit : 0
                    const balance = parseFloat(credit) - parseFloat(debit)
                    const data = {
                        code: {
                            text: item.code,
                        },
                        name: {
                            text: item.name,
                        },
                        type: {
                            text: type != null ? type.label : '',
                        },
                        balance: {
                            text:
                                balance < 0
                                    ? this.props.school.currency + ' ' + (balance * -1).toFixed(2)
                                    : this.props.school.currency + ' ' + balance.toFixed(2),
                            color: balance < 0 ? 'red' : 'black',
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.doubleentry.chartofaccount.edit + '/' + item.id,
                        },
                    }
                    liabilities.push(data)
                })
                response.data.chartAccounts.Expenses.map((item, index) => {
                    let text = null
                    if (item.is_enabled == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const type = CHART_OF_ACCOUNT_SUB_TYPE.find((itemType) => itemType.value == item.sub_type)
                    const credit = item.accounts_sum_credit ? item.accounts_sum_credit : 0
                    const debit = item.accounts_sum_debit ? item.accounts_sum_debit : 0
                    const balance = parseFloat(credit) - parseFloat(debit)
                    const data = {
                        code: {
                            text: item.code,
                        },
                        name: {
                            text: item.name,
                        },
                        type: {
                            text: type != null ? type.label : '',
                        },
                        balance: {
                            text:
                                balance < 0
                                    ? this.props.school.currency + ' ' + (balance * -1).toFixed(2)
                                    : this.props.school.currency + ' ' + balance.toFixed(2),
                            color: balance < 0 ? 'red' : 'black',
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.doubleentry.chartofaccount.edit + '/' + item.id,
                        },
                    }
                    expenses.push(data)
                })
                response.data.chartAccounts.Income.map((item, index) => {
                    let text = null
                    if (item.is_enabled == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const type = CHART_OF_ACCOUNT_SUB_TYPE.find((itemType) => itemType.value == item.sub_type)
                    const credit = item.accounts_sum_credit ? item.accounts_sum_credit : 0
                    const debit = item.accounts_sum_debit ? item.accounts_sum_debit : 0
                    const balance = parseFloat(credit) - parseFloat(debit)
                    const data = {
                        code: {
                            text: item.code,
                        },
                        name: {
                            text: item.name,
                        },
                        type: {
                            text: type != null ? type.label : '',
                        },
                        balance: {
                            text:
                                balance < 0
                                    ? this.props.school.currency + ' ' + (balance * -1).toFixed(2)
                                    : this.props.school.currency + ' ' + balance.toFixed(2),
                            color: balance < 0 ? 'red' : 'black',
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.doubleentry.chartofaccount.edit + '/' + item.id,
                        },
                    }
                    income.push(data)
                })
                response.data.chartAccounts.Equity.map((item, index) => {
                    let text = null
                    if (item.is_enabled == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const type = CHART_OF_ACCOUNT_SUB_TYPE.find((itemType) => itemType.value == item.sub_type)
                    const credit = item.accounts_sum_credit ? item.accounts_sum_credit : 0
                    const debit = item.accounts_sum_debit ? item.accounts_sum_debit : 0
                    const balance = parseFloat(credit) - parseFloat(debit)
                    const data = {
                        code: {
                            text: item.code,
                        },
                        name: {
                            text: item.name,
                        },
                        type: {
                            text: type != null ? type.label : '',
                        },
                        balance: {
                            text:
                                balance < 0
                                    ? this.props.school.currency + ' ' + (balance * -1).toFixed(2)
                                    : this.props.school.currency + ' ' + balance.toFixed(2),
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.doubleentry.chartofaccount.edit + '/' + item.id,
                        },
                    }
                    equity.push(data)
                })
                this.setState({
                    assets,
                    liabilities,
                    expenses,
                    income,
                    equity,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
