import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import React, { Fragment } from 'react'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Folder } from '~/components/drive'
import { Button, Checkbox, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { UserFilter } from '~/components/userfilter'
import * as route from '~/helper/constant/route'
import * as USERTYPE from '~/helper/constant/user_type'
import { getBase64 } from '~/helper/function'
import { convertADtoBS } from '../../../helper/function'
import { AbstractDriveComponent, mapStateToProps } from './abstract'

const order = [
    { value: 'asc', label: 'Asc' },
    { value: 'desc', label: 'Desc' },
]
const sortby = [
    { value: 'date', label: 'Date' },
    { value: 'name', label: 'Name' },
]
class DriveComponent extends AbstractDriveComponent {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            ...this.state,
            language: this.props.i18n.language,
            sortby: [
                { value: 'date', label: t('drive:date') },
                { value: 'name', label: t('drive:name') },
            ],
            order: [
                { value: 'asc', label: t('common:Asc') },
                { value: 'desc', label: t('common:Desc') },
            ],
        }
        this.fileRef = React.createRef()
        this.setPrivacy = this.setPrivacy.bind(this)
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
    }
    componentDidUpdate(prevProps, prevState) {
        const { t } = this.props
        if (prevState.language != this.props.i18n.language) {
            const { t } = this.props
            this.setState({
                language: this.props.i18n.language,
                order: [
                    { value: 'Asc', label: t('common:Asc') },
                    { value: 'Desc', label: t('common:Desc') },
                ],
                sortby: [
                    { value: 'Date', label: t('drive:date') },
                    { value: 'Name', label: t('drive:name') },
                ],
            })
        }
    }

    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }
    filesRemoveOne = (file) => {
        let uploads = this.state.uploadedFiles
        let index = uploads.indexOf(file)
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploadedFiles', uploads)
    }
    selectFiles(data) {
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                })
            })
            this._handleChange('media', media)
            this._handleChange('uploadedFiles', data)
        }
    }
    setPrivacy(mode) {
        if (mode == 'add') {
            const privacy = !this.state.privacy
            this.setState({ privacy })
            if (privacy) {
                $('.access-list').show()
            } else {
                $('.access-list').hide()
            }
        } else {
            const eprivacy = !this.state.eprivacy
            this.setState({ eprivacy })
            if (eprivacy) {
                $('.eaccess-list').show()
            } else {
                $('.eaccess-list').hide()
            }
        }
    }
    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_drive_add" className="modal fade show" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:addFolder')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('drive:folderName')} *</label>
                                        <Input name="folder_name" onChange={this._handleChange} placeholder={t('drive:folderName')} type="text" value={this.state.folder_name} />
                                        {this.state.errors.folder_name && <label className="error text-danger">{this.state.errors.folder_name}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="folder_description" onChange={this._handleChange} placeholder={t('drive:folderDescription')} type="text" value={this.state.folder_description} />
                                        {this.state.errors.folder_description && <label className="error text-danger">{this.state.errors.folder_description}</label>}
                                    </div>
                                </div>
                                <div className="form-check">
                                    <label className="form-title" htmlFor="private">
                                        {t('common:private')}
                                    </label>
                                    <Checkbox id="private" onChange={() => this.setPrivacy('add')} className="" name="private" value={this.state.privacy} />
                                </div>
                                <div className="add-option-exam access-list">
                                    <UserFilter onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} selectedStudent={this.state.selectedStudent} selectedEmployee={this.state.selectedEmployee} onUserLoad={this.loadUser} />
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('drive:files')}</label>
                                        <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                            {this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                                ? this.state.uploadedFiles.map((file) => (
                                                      <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                          <div className="gsEyTH files-list-item" key={file.id}>
                                                              <div className="files-list-item-preview">{file.preview.type === 'image' ? <img className="files-list-item-preview-image" src={file.preview.url} /> : <div className="files-list-item-preview-extension">{file.extension}</div>}</div>
                                                              <div className="files-list-item-content">
                                                                  <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                                                  <div className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</div>
                                                              </div>
                                                              <div
                                                                  id={file.id}
                                                                  className="files-list-item-remove"
                                                                  onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                                                              />
                                                          </div>
                                                      </div>
                                                  ))
                                                : null}
                                            <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                <Files ref={this.fileRef} className="files-dropzone" onChange={this.selectFiles} onError={this.onFilesError} accepts={['image/*', 'video/*', 'audio/*', '.pdf', '.docx', '.pptx', '.xlsx', '.doc', '.ppt', '.xls']} multiple maxFiles={10} maxFileSize={200000000} minFileSize={0} clickable>
                                                    {t('common:adddocument')}
                                                </Files>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.addFolder} type="submit" text={t('common:addBtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    editFolder() {
        const { t } = this.props
        return (
            <div id="modal_folder_edit" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:editFolder')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:name')} *</label>
                                        <Input name="efolder_name" onChange={this._handleChange} placeholder={t('drive:folderName')} type="text" value={this.state.efolder_name} />
                                        {this.state.errors.efolder_name && <label className="error text-danger">{this.state.errors.efolder_name}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="efolder_description" onChange={this._handleChange} placeholder={t('drive:folderDescription')} type="text" value={this.state.efolder_description} />
                                        {this.state.errors.efolder_description && <label className="error text-danger">{this.state.errors.efolder_description}</label>}
                                    </div>
                                </div>
                                <div className="form-check">
                                    <label className="form-title" htmlFor="eprivate">
                                        {t('common:private')} *
                                    </label>
                                    <Checkbox id="eprivate" onChange={() => this.setPrivacy('edit')} name="eprivate" value={this.state.eprivacy} />
                                </div>
                                <div className="add-option-exam eaccess-list">
                                    <UserFilter onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} selectedStudent={this.state.selectedStudent} selectedEmployee={this.state.selectedEmployee} onUserLoad={this.loadUser} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this.updateFolder} type="submit" text={t('updateBtn')} main={true} />
                                <button id="close_edit" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    deleteModal() {
        const { t } = this.props
        return (
            <div key={this.state.selectedMedia} id="modal_class_delete" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('drive:areYouSureYouWantToDeleteTheFolder')}?</p>
                            <p className="delete-file-name">"{this.state.selectedMediaName}"</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={() => this.deleteFolder()} type="button" text={t('common:modal confirm')} main={true} />
                            <button id={'close_delete'} type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    detailFolder() {
        const { t } = this.props
        return (
            <div id="details-drive" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:folderDetails')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:folderName')}</span>
                                    <ins className="col-md-8">{this.state.detail.file_name}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:folderDescription')}</span>
                                    <ins className="col-md-8">{this.state.detail.file_description}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:owner')}</span>
                                    <ins className="col-md-8">{this.state.detail.owner}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:created_on')}</span>
                                    <ins className="col-md-8">{this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.detail.createdOn != '' ? (convertADtoBS(this.state.detail.createdOn) != null ? convertADtoBS(this.state.detail.createdOn) : 'Invalid Date Range') : this.state.detail.createdOn}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:folderSize')}</span>
                                    <ins className="col-md-8">{this.state.detail.folderSize}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:fileCount')}</span>
                                    <ins className="col-md-8">{this.state.detail.folderItemsCount}</ins>
                                </div>
                                {this.state.detail.sharedTo.length > 0 && (
                                    <div className="form-group row clearfix folder-detail">
                                        <span className="col-md-4">{t('drive:shared_to')}</span>
                                        <ins className="col-md-8 small-ins">
                                            <div className="participant-img clearfix">
                                                {this.state.detail.sharedTo.map((item, index) => {
                                                    if (item != null) {
                                                        if (index < 3) {
                                                            if (item.length == 1) {
                                                                return (
                                                                    <div className="img-part">
                                                                        <span>{item}</span>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="img-part">
                                                                        <img src={item} alt={'shared' + index} />
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })}
                                                {this.state.detail.sharedTo.length - 3 > 0 && (
                                                    <span className="part-number">
                                                        {this.state.detail.sharedTo.length - 3} {t('drive:more')}
                                                    </span>
                                                )}
                                            </div>
                                        </ins>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button className="main-button" type="button" data-dismiss="modal">
                                    {t('common:modal close')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    displayData() {
        const { t } = this.props
        if (this.state.searchText.length != 0 && this.state.folders.length == 0) {
            return (
                <div className="text-center no-data">
                    {SVG.nodata()}
                    <span className="no-task">{t('drive:itemNotFound')}.</span>
                </div>
            )
        } else if (this.state.folders.length == 0) {
            return (
                <div className="text-center no-data">
                    {SVG.nodata()}
                    <span className="no-task">{t('drive:driveEmpty')}.</span>
                </div>
            )
        }
        const folderList = this.state.folders.map((folderObj, index) => {
            return (
                <div className="folder-item" key={index}>
                    <Folder index={index} folderName={folderObj.title} folderUrl={route.drive.folder + '/' + folderObj.id} privacy={(folderObj.restriction && true) || false} parentMethod={this.addFolder} />
                    <div className="e-d-t">
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                            </button>
                            <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.props.school.id == folderObj.author.id && (
                                    <button className="dropdown-item" type="button" onClick={() => this.getEditModal(index)}>
                                        {t('common:EditBtn')}
                                    </button>
                                )}
                                <button className="dropdown-item" type="button" onClick={() => this.openDetailFolder(index)}>
                                    {t('details')}
                                </button>
                                {(this.props.school.id == folderObj.author.id || this.props.school.user_type == USERTYPE.Admin) && (
                                    <Fragment>
                                        <button className="dropdown-item" type="button" onClick={() => this.getDeleteModal(index)}>
                                            {t('common:delete')}
                                        </button>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return folderList
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="drive" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.drive.view}>{t('drive:drive')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-3 float-left">
                                <h5 className="main-title heading5">{t('drive:drive')} &gt;</h5>
                            </div>
                            <div className="col-md-9 calendar-module text-right">{this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && <Button onClick={() => this.openAddFolder()} type="button" text={t('drive:add')} main={true} />}</div>
                        </div>
                    </div>
                    <div className="main-box">
                        <div className="row clearfix">
                            {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && (
                                <div className="display-inline-block col-sm-5">
                                    <form className="main-search">
                                        <input onChange={(e) => this.search(e)} className="input-border" name="search" placeholder={t('common:searchhere')} type="text" value={this.state.searchText} />
                                        <div className="search-icon">{SVG.tablesearch()}</div>
                                    </form>
                                </div>
                            )}
                            {(this.props.school.user_type == USERTYPE.Student || this.props.school.user_type == USERTYPE.Parents) && (
                                <div className="display-inline-block col-sm-5">
                                    <form className="main-search">
                                        <input onChange={(e) => this.search(e)} name="search" placeholder={t('common:searchhere')} type="text" value={this.state.searchText} />
                                        <div className="search-icon">{SVG.tablesearch()}</div>
                                    </form>
                                </div>
                            )}
                            <div className="col-sm-7 table-sort col-xs-12 float-left text-right">
                                <div className="display-inline-block top-select">
                                    <Select placeholder={t('common:sort')} options={this.state.order} onChange={(e) => this.changeOrderBy(e)} />
                                </div>
                                <div className="display-inline-block top-select">
                                    <Select placeholder={t('common:sortby')} options={this.state.sortby} onChange={this.changeSortBy} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="drive-page">
                            <div className="clearfix">{this.displayData()}</div>
                        </div>
                    </div>
                </div>
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.getAddModal()}
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.editFolder()}
                {this.detailFolder()}
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.deleteModal()}
            </div>
        )
    }
}
const Drive = withTranslation()(connect(mapStateToProps)(DriveComponent))
export { Drive }
