import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSchoolAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Loading } from '~/components/loader'
import { language } from '~/helper/constant/language'
import { studentRestriction } from '../../../../helper/constant/restriction'
import { country } from '../../../../helper/constant/country'
import { dateFormat } from '../../../../helper/constant/date_format'
class SuperAdminSchoolAddComponent extends AbstractSchoolAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setPackage = this.setPackage.bind(this)
        this.setLocale = this.setLocale.bind(this)
        this.setDob = this.setDob.bind(this)
        this.setJoinDate = this.setJoinDate.bind(this)
        this.setStudentAccess = this.setStudentAccess.bind(this)
        this.setCountry = this.setCountry.bind(this)
        this.setDateFormat = this.setDateFormat.bind(this)
    }

    setLocale(data) {
        super._handleChange('locale', data.value)
    }

    setCountry(data) {
        super._handleChange('country', data.value)
        if (data.value != 'Nepal') {
            super._handleChange('date_format', dateFormat[0].value)
        }
    }

    setDateFormat(data) {
        super._handleChange('date_format', data.value)
    }

    setPackage(data) {
        super._handleChange('package_id', data.value)
    }

    setDob(data) {
        super._handleChange('dob', data)
    }

    setJoinDate(data) {
        super._handleChange('joining_date', data)
    }

    setStudentAccess(data) {
        super._handleChange('isOnlyApp', data.value)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.school.view}>School &gt; </a>
                            </li>
                            <li>Add</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Add School:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">School Name *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder="School Name" type="text" value="" />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Short Code *</label>
                                    <Input name="short_code" onChange={this._handleChange} placeholder="Short Code" type="text" value="" />
                                    {this.state.errors.short_code && <label className="error text-danger">{this.state.errors.short_code}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Sub-Domain *</label>
                                    <Input name="sub_domain" onChange={this._handleChange} placeholder="Sub Domain" type="text" value="" />
                                    {this.state.errors.sub_domain && <label className="error text-danger">{this.state.errors.sub_domain}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">Package *</label>
                                    <Select name="package_id" options={this.state.packages} placeholder="Packages" searchable={true} onChange={this.setPackage} />
                                    {this.state.errors.package_id && <label className="error text-danger">{this.state.errors.package_id}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Restrict Student Access *</label>
                                    <Select name="locale" options={studentRestriction} placeholder="Restrict Student Access" searchable={true} onChange={this.setStudentAccess} />
                                    {this.state.errors.isOnlyApp && <label className="error text-danger">{this.state.errors.isOnlyApp}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Country *</label>
                                    <Select name="country" options={country} placeholder="Select Country" searchable={true} onChange={this.setCountry} />
                                    {this.state.errors.country && <label className="error text-danger">{this.state.errors.country}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Locale *</label>
                                    <Select name="locale" options={language} placeholder="Select Default Language" searchable={true} onChange={this.setLocale} />
                                    {this.state.errors.locale && <label className="error text-danger">{this.state.errors.locale}</label>}
                                </div>
                                {this.state.country == 'Nepal' && (
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">Date Format *</label>
                                        <Select name="date_format" value={dateFormat.find((item) => item.value == this.state.date_format)} options={dateFormat} placeholder="Select Date Format" searchable={true} onChange={this.setDateFormat} />
                                        {this.state.errors.date_format && <label className="error text-danger">{this.state.errors.date_format}</label>}
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div className="main-head-option">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left">
                                        <h5>Admin Login Email</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Admin Email *</label>
                                    <Input name="email" onChange={this._handleChange} placeholder="Admin Email" type="text" value="" />
                                    {this.state.errors.email && <label className="error text-danger">{this.state.errors.email}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Admin DOB *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" selected={this.state.dob} onChange={this.setDob} placeholderText="DOB" className="form-control" />
                                    {this.state.errors.dob && <label className="error text-danger">{this.state.errors.dob}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Joining Date*</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.joining_date} onChange={this.setJoinDate} placeholderText="joining Date" />
                                    {this.state.errors.joining_date && <label className="error text-danger">{this.state.errors.joining_date}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="button" text="Save" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SuperAdminSchoolAdd = connect(mapStateToProps)(SuperAdminSchoolAddComponent)

export { SuperAdminSchoolAdd }
