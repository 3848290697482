import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    classes: Array,
    section: Array,
    data: Object,
    defaultClass: Object,
    defaultSection: Object,
    selectedClass: String,
    selectedSection: String,
    errors: Object,
}

export class AbstractTimetableMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classes: [],
            section: [],
            data: {},
            selectedClass: '',
            selectedSection: '',
            errors: {
                selectedClass: null,
                selectedSection: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }

    componentDidMount() {
        this.loadClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classes = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classes.push({ value: item.id, label: item.name + ' - ' + item.code })
                })
                this._handleChange('data', response.data.class)
                this._handleChange('classes', classes)
                this._handleChange('defaultClass', classes[0])
                this._handleChange('selectedClass', classes[0].value)
                this.loadSection(classes[0].value)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let section = []
        classes.active_section.map((item, index) => {
            section.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        if (section.length > 0) {
            this._handleChange('section', section)
            this._handleChange('defaultSection', section[0])
            this._handleChange('selectedSection', section[0].value)
        } else {
            this._handleChange('section', section)
            this._handleChange('defaultSection', '')
            this._handleChange('selectedSection', '')
        }
    }

    _onSearch(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            redirect(route.admin.timetable.view + '/' + this.state.selectedSection, this.props.history)
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
