import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { convertADtoBS } from '../../../../../helper/function'
import { AbstractEmployeeAttendanceMenuComponent, mapStateToProps } from './abstract'
class EmployeeAttendanceMenuComponent extends AbstractEmployeeAttendanceMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectUserType = this._selectUserType.bind(this)
        this._selectCustomRole = this._selectCustomRole.bind(this)
        this._setToDate = this._setToDate.bind(this)
    }

    _setToDate(data) {
        this._handleChange('date', data)
    }

    _selectUserType(data) {
        if (data.value == 1) {
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedRole: this.state.customRoleAry[0].value,
                defaultRole: this.state.customRoleAry[0],
            })
        } else {
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedRole: 0,
                defaultRole: null,
            })
        }
    }

    _selectCustomRole(data) {
        super._handleChange('selectedRole', data.value)
        super._handleChange('defaultRole', data)
    }

    render() {
        const { t } = this.props
        const academic_year = this.props.school?.school?.academic_year
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="attendance" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.attendance.menu}>{t('attendance:attendance')} > </a>
                            </li>
                            <li>{t('attendance:employee_menu')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('attendance:employee_attendance')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('attendance:attendanceDate')} *</label>
                                    <DatePicker minDate={moment(academic_year.from_date).toDate()} maxDate={moment().toDate()} placeholderText={t('attendance:attendanceDate')} className="form-control" defaultValue={this.state.date} selected={this.state.date} onChange={this._setToDate} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.date != null ? (convertADtoBS(this.state.date) != null ? convertADtoBS(this.state.date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.date && <label className="error text-danger">{this.state.errors.date}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('attendance:user_type')} *
                                    </label>
                                    <Select name="type" options={this.state.userTypeAry} placeholder={t('attendance:role')} searchable={true} value={this.state.defaultUserType} onChange={this._selectUserType} />
                                    {this.state.selectedUserType == null && <label className="error text-danger">{t('toaster:required')} *</label>}
                                </div>
                            </div>
                            {this.state.selectedUserType == 1 && (
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label htmlFor="departmentclass" className="form-title">
                                            {t('attendance:role')} *
                                        </label>
                                        <Select name="role" options={this.state.customRoleAry} placeholder={t('attendance:role')} searchable={true} value={this.state.defaultRole} onChange={this._selectCustomRole} />
                                        {this.state.selectedUserType == 1 && this.state.selectedRole == null && <label className="error text-danger">{t('toaster:required')} *</label>}
                                    </div>
                                </div>
                            )}
                            <div className="form-button text-left">
                                <button type="button" className="main-button" onClick={this.getURL}>
                                    {t('common:searchBtn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const EmployeeAttendanceMenu = withTranslation()(connect(mapStateToProps)(EmployeeAttendanceMenuComponent))

export { EmployeeAttendanceMenu }
