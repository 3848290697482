import React, { Component } from 'react'

type Props = {
    onClick: any,
    type: string,
    text: string,
    style: any,
    login: boolean,
    table: boolean,
    link: string,
    main: boolean,
    cancel: Boolean,
    modal: string,
    disabled: Boolean,
    className: string,
    titleStyle: string,
}

type State = {}

export class AbstractButtonComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {}
        this._onClick = this._onClick.bind(this)
    }

    _onClick(e) {
        if (this.props.onClick != null) {
            this.props.onClick(e)
        }
    }
}
