import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Checkbox } from '~/components/form'
import * as AccessModule from '~/helper/constant/modules'
import { AbstractUserFilterComponent, mapStateToProps } from './abstract'
export class UserFilterComponent extends AbstractUserFilterComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSection = this._selectSection.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectClass = this._selectClass.bind(this)
        this._selectUserType = this._selectUserType.bind(this)
        this._selectCustomRole = this._selectCustomRole.bind(this)
        this.selectAllStudent = this.selectAllStudent.bind(this)
        this.selectAllEmployee = this.selectAllEmployee.bind(this)

    }
    selectAllStudent() {
        // this.props.onAllStudentSelect(
        //   this.state.selectAllStudent,
        //   this.state.studentList
        // );
        if (!this.state.selectAllStudent) {
            let selectedStudent =
                this.state.selectedStudent != null && this.state.selectedStudent != '' ? this.state.selectedStudent : []
            const studentList = this.state.studentList
            let defaultStudent =
                this.state.defaultStudent != null && this.state.defaultStudent != '' ? this.state.defaultStudent : []
            studentList.map((item, index) => {
                const checkDefault = defaultStudent.find((std) => std.value == item.value)
                const checkSelected = selectedStudent.find((std) => std == item.value)
                if (!checkDefault) {
                    defaultStudent.push(item)
                }
                if (!checkSelected) {
                    selectedStudent.push(item.value)
                }
            })
            this.setState(
                {
                    selectedStudent: [],
                    defaultStudent: [],
                },
                () => {
                    this.setState(
                        {
                            selectedStudent,
                            defaultStudent,
                            selectAllStudent: true,
                        },
                        () => this.props.onStudentSelect(this.state.selectedStudent)
                    )
                }
            )
        } else {
            this.setState(
                {
                    selectedStudent: null,
                    defaultStudent: null,
                    selectAllStudent: false,
                },
                () => this.props.onStudentSelect(null)
            )
        }
    }
    selectAllEmployee() {
        // this.props.onAllEmployeeSelect(
        //   this.state.selectAllEmployee,
        //   this.state.employeeList
        // );
        if (!this.state.selectAllEmployee) {
            const employeeList = this.state.employeeList
            let selectedEmployee =
                this.state.selectedEmployee != null && this.state.selectedEmployee != ''
                    ? this.state.selectedEmployee
                    : []
            let defaultEmployee =
                this.state.defaultEmployee != null && this.state.defaultEmployee != '' ? this.state.defaultEmployee : []
            employeeList.map((item, index) => {
                const checkDefault = defaultEmployee.find((emp) => emp.value == item.value)
                const checkSelected = selectedEmployee.find((emp) => emp == item.value)
                if (!checkDefault) {
                    defaultEmployee.push(item)
                }
                if (!checkSelected) {
                    selectedEmployee.push(item.value)
                }
            })
            this.setState(
                {
                    selectedEmployee: [],
                    defaultEmployee: [],
                },
                () => {
                    this.setState(
                        {
                            selectedEmployee,
                            defaultEmployee,
                            selectAllEmployee: true,
                        },
                        () => {
                            this.props.onEmployeeSelect(this.state.selectedEmployee)
                        }
                    )
                }
            )
        } else {
            this.setState(
                {
                    selectedEmployee: null,
                    defaultEmployee: null,
                    selectAllEmployee: false,
                },
                () => this.props.onEmployeeSelect(null)
            )
        }
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school.role != null ? school.role.access : null
        return (
            //     <UserFilter
            //     onStudentSelect={this._selectStudent}
            //     onEmployeeSelect={this._selectEmployee}
            //     selectedStudent={this.state.selectedStudent}
            //     selectedEmployee={this.state.selectedEmployee}
            //     onUserLoad={this.loadUser}
            //     hideEmployee //To Hide Employee
            //     hideStudent //To hide Student
            //     hideClass //To Hide Class
            //     hideSection //To hide Section
            //     selectedSubject={this.state.selectedSubject}
            //     selectedclassName={this.state.selectedClass}
            //     selectedSection={this.state.selectedSection}
            //     onSubjectSelect={this._selectSubject}
            //     onSectionSelect={this._selectSection}
            //     showSubject //To Display Subject
            //     resetOnChange={true|false}  //Reset on change of filter
            //   />

            
            <div className="access-list2">
                
                {!this.props.hideEmployee == true && (
                    <div>
                        {this.state.showHeader == true && (
                            <div className="row access-list2 clearfix">
                                <div className="col-sm-12 float-left">
                                    <h6 className="form-heading">{t('common:employee')}</h6>
                                </div>
                            </div>
                        )}
                        <div className="access-list2 clearfix">
                            <div className="row">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('todo:employeeType')} </label>
                                    <Select
                                        name="class"
                                        options={this.state.userTypeList}
                                        placeholder={t('attendance:user_type')}
                                        searchable={true}
                                        value={this.state.defaultUserType}
                                        onChange={this._selectUserType}
                                    />
                                </div>
                                <div className="col-sm-6 float-left form-group" id="custom-role">
                                    <label className="form-title">{t('todo:employeeRole')} </label>
                                    <Select
                                        name="section"
                                        options={this.state.customRoleList}
                                        placeholder={t('users:role.customRole')}
                                        searchable={true}
                                        value={this.state.defaultCustomRole}
                                        onChange={this._selectCustomRole}
                                    />
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('todo:selectEmployee')} * </label>
                                    <Select
                                        id="employee_filter"
                                        name="employee"
                                        options={this.state.employeeList}
                                        placeholder={t('users:employee.selectEmployee')}
                                        searchable={true}
                                        isMulti
                                        value={this.state.defaultEmployee}
                                        onChange={this._selectEmployee}
                                    />
                                    <div className="form-check">
                                        <Checkbox
                                            id="showEm"
                                            onChange={() => this.selectAllEmployee()}
                                            className=""
                                            name="selectAllEmployee"
                                            value={this.state.selectAllEmployee}
                                        />
                                        <label className="form-check-label form-title small-checkbox" htmlFor="showEm">
                                            {t('todo:selectAllEmployee')}
                                        </label>
                                        
                                    </div>
                                    {this.props.error && (<div className="error text-danger mt-2">{this.props.error}</div>)}
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                )}
                {!this.props.hideStudent &&
                    AccessModule.checkSubAccess([AccessModule.STUDENT], role) &&
                    access.includes(AccessModule.Module_Student_Management) && (
                        <div>
                            {this.state.showHeader == true && (
                                <div className="row access-list2 clearfix">
                                    <div className="col-sm-12 float-left">
                                        <h6 className="form-heading">{t('todo:student')}</h6>
                                    </div>
                                </div>
                            )}
                            <div className="access-list2 clearfix">
                                <div className="row">
                                    {!this.props.hideClass && (
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('todo:selectClass')} </label>
                                            <Select
                                                name="class"
                                                options={this.state.classList}
                                                placeholder={t('todo:selectClass')}
                                                searchable={true}
                                                value={this.state.defaultClass}
                                                onChange={this._selectClass}
                                            />
                                        </div>
                                    )}
                                    {!this.props.hideSection && (
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('todo:selectSection')} </label>
                                            <Select
                                                name="section"
                                                options={this.state.sectionList}
                                                placeholder={t('todo:selectSection')}
                                                searchable={true}
                                                value={this.state.defaultSection}
                                                onChange={this._selectSection}
                                            />
                                        </div>
                                    )}
                                    {this.props.showSubject && (
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('todo:selectSubject')}</label>
                                            <Select
                                                name="subject"
                                                options={this.state.subjectList}
                                                placeholder={t('todo:selectSubject')}
                                                searchable={true}
                                                value={this.state.defaultSubject}
                                                onChange={this._selectSubject}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={'col-sm-' + this.state.studentColumn + ' float-left form-group mb-0'}
                                    >
                                        <label className="form-title">{t('todo:selectStudent')} *</label>
                                        <Select
                                            id="student_filter"
                                            name="student"
                                            options={this.state.studentList}
                                            placeholder={t('todo:selectStudent')}
                                            isMulti
                                            searchable={true}
                                            value={this.state.defaultStudent}
                                            onChange={this._selectStudent}
                                        />
                                        <div className="form-check">
                                            <Checkbox
                                                id="show"
                                                onChange={() => this.selectAllStudent()}
                                                className=""
                                                name="selectAllStudent"
                                                value={this.state.selectAllStudent}
                                            />
                                            <label
                                                className="form-check-label form-title small-checkbox"
                                                htmlFor="show"
                                            >
                                                {t('todo:selectAllStudent')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        )
    }
}
const UserFilter = withTranslation()(connect(mapStateToProps)(UserFilterComponent))
export { UserFilter }
