import moment from 'moment'
import React, { Component } from 'react'
import { convertADtoBS, convertBStoAD, getBSWeek } from '../../helper/function/abstract'
import DateBS from '../../helper/date-converter'
import { calendar } from '../../helper/constant/route'
import { days } from '../../helper/constant/days'

export class AbstractNepaliCalendarComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            today: convertADtoBS(moment().format('Y-MM-D')),
            currentYear: '',
            currentMonth: '',
            currentMonthDays: '',
            startingDay: '',
            calendar: [],
            events: this.props.events != null ? this.props.events : [],
            error: false,
        }
        this.today = this.today.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.events.length != this.props.events.length) {
            this.setState({
                events: this.props.events,
            })
        }
    }

    today() {
        let date = this.state.today.split('-')
        this.setState(
            {
                currentYear: parseInt(date[0]),
                currentMonth: parseInt(date[1]) - 1,
                currentMonthDays: DateBS.daysInMonth(parseInt(date[0]), parseInt(date[1])),
                startingDay: getBSWeek(parseInt(date[0]) + '-' + parseInt(date[1]) + '-' + '01'),
            },
            () => {
                this.loadDays()
            }
        )
    }

    loadDays() {
        let starting = days.find((item) => item.label == this.state.startingDay)
        let week = days != null ? starting.value : 0
        let weekCount = 0
        let calendar = []
        calendar.push([0, 0, 0, 0, 0, 0, 0])
        for (let i = 1; i <= this.state.currentMonthDays; i++) {
            calendar[weekCount][week] = i
            if (week == 6) {
                week = 0
                weekCount++
                calendar.push([0, 0, 0, 0, 0, 0, 0])
            } else {
                week++
            }
        }

        this.setState({
            calendar,
        })
    }

    componentDidMount() {
        this.today()
    }
}
