import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { viewBankTransfer, viewBankAccount } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBankTransferComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            bank_name: [],
            loading_text: 'Loading',
            tableData: [],
            tableStructure: [
                {
                    from_account: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:fromaccount',
                    },
                    to_account: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:toaccount',
                    },
                    amount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'income:amount',
                    },
                    reference: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:reference',
                    },
                    description: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:description',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadBankTransfer()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadBankTransfer() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        viewBankTransfer(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.transfers.map((item, index) => {
                    let text = null
                    let amount = {
                        text:this.props.school.currency + " " + Math.abs(item.amount).toFixed(2),
                    }

                    if (item.amount < 0) {
                        amount.color = 'red'
                    }
                    const rowData = {
                        from_account: {
                            text: item.from_bank_account ? (item.from_bank_account.bank_name + '-' + item.from_bank_account.holder_name) : 'N/A',
                        },
                        to_account: {
                            text: item.to_bank_account ? (item.to_bank_account.bank_name + '-' + item.to_bank_account.holder_name) : 'N/A',
                        },
                        amount: amount,
                        reference: {
                            text: item.reference,
                        },
                        description: {
                            text: item.description,
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.banking.transfer.edit + '/' + item.id,
                        },
                    }
                    tableData.push(item)
                    tableStructure.push(rowData)
                })
                this.setState({
                    tableData,
                    tableStructure,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school:state.school.school
    }
}
