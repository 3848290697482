import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniYearbookComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Input, Button } from '~/components/form'
import UserImg from '~/assets/images/user.png'
import YearbookBg from '~/assets/images/yearbookbg.png'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { kFormatter } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'
import { UserFilter } from '~/components/userfilter'
import $ from 'jquery'
import * as UserType from '~/helper/constant/user_type'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'

class AlumniYearbookComponent extends AbstractAlumniYearbookComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectStudent = this._selectStudent.bind(this)
        this._setBatch = this._setBatch.bind(this)
        this._setGroup = this._setGroup.bind(this)
        this._setCompany = this._setCompany.bind(this)
        this._setDesignation = this._setDesignation.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    _onSave(e) {
        super._onSave(e).then((response) => {
            if (response) {
                $('#modal_add').trigger('click')
            }
        })
    }

    _selectStudent(data) {
        this.setState({
            selectedStudent: data,
        })
    }

    _setBatch(data) {
        this.setState({
            selectedBatch: data,
            alumni_batch_id: data.value,
        })
    }

    _setGroup(data) {
        this.setState({
            selectedGroup: data,
            alumni_group_id: data.value,
        })
    }

    _setCompany(data) {
        this.setState({
            selectedCompany: data,
            alumni_company_id: data.value,
        })
    }

    _setDesignation(data) {
        this.setState({
            selectedDesignation: data,
            alumni_designation_id: data.value,
        })
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:addAlumni')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:batch')} *</label>
                                            <Select name="alumni_batch_id" options={this.state.batchList} placeholder={t('alumni:batch')} searchable={true} value={this.state.selectedBatch} onChange={this._setBatch} />
                                            {this.state.alumni_errors.alumni_batch_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_batch_id}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:faculty')} *</label>
                                            <Select name="alumni_group_id" options={this.state.groupList} placeholder={t('alumni:faculty')} searchable={true} value={this.state.selectedGroup} onChange={this._setGroup} />
                                            {this.state.alumni_errors.alumni_group_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_group_id}</label>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:company')} *</label>
                                            <Select name="alumni_company_id" options={this.state.companyList} placeholder={t('alumni:company')} searchable={true} value={this.state.selectedCompany} onChange={this._setCompany} />
                                            {this.state.alumni_errors.alumni_company_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_company_id}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:designation')} *</label>
                                            <Select name="alumni_designation_id" options={this.state.designationList} placeholder={t('alumni:designation')} searchable={true} value={this.state.selectedDesignation} onChange={this._setDesignation} />
                                            {this.state.alumni_errors.alumni_designation_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_designation_id}</label>}
                                        </div>
                                    </div>
                                </div>
                                <UserFilter reload={this.state.reload} studentColumn={12} showHeader={false} hideEmployee={true} onStudentSelect={this._selectStudent} selectedStudent={this.state.selectedStudent} selectedClass={this.state.selectedClass} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onSave} type="submit" text={t('common:addBtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="alumniYearbook" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                {/* Yearbook First */}
                {/* <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading">Yearbook</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href="" className="cancel-button">
                                        Create Yearbook
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="second-title">
                        <h6>Computer Science</h6>
                        <p>233 Members</p>
                    </div>
                    <div className="alumni-dashboard">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="custom-card-box">
                                    <div className="alumni-filter">
                                        <form className="main-search alumni-main-search">
                                            <Input name="search" placeholder="Search anything" type="text" value="" />
                                            <div className="search-icon">{SVG.tablesearch()}</div>
                                        </form>
                                        <div className="alumni-filter-wrap mt-4">
                                            <p className="heading">Mentorship</p>
                                            <div>
                                                <ul class="nav nav-pills">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" aria-current="page">
                                                            Active
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link">Inactive</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="alumni-filter-wrap mt-4">
                                            <p className="heading">Location</p>
                                            <div className="alumni-location">
                                                <select class="form-select" name="type" options="" placeholder="Bangalore, India" value="Bangalore, India">
                                                    <option>Kathmandu, Nepal</option>
                                                    <option>Bangalore, India</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="alumni-filter-wrap mt-4">
                                            <p className="heading">More</p>
                                            <ul className="filter-list">
                                                <li>
                                                    <input type="checkbox" name="" id="" /> Created by me
                                                    <span>99</span>
                                                </li>
                                                <li>
                                                    <input type="checkbox" name="" id="" /> Draft Event
                                                    <span>25</span>
                                                </li>
                                                <li>
                                                    <input type="checkbox" name="" id="" /> Invited
                                                    <span>88</span>
                                                </li>
                                                <li>
                                                    <input type="checkbox" name="" id="" /> Attended by me
                                                    <span>46</span>
                                                </li>
                                                <li>
                                                    <input type="checkbox" name="" id="" /> Upcoming
                                                    <span>45</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="alumni-box">
                                                <img src={UserImg} />
                                                <div className="mt-3">
                                                    <h5>Cheyenne Dokidis</h5>
                                                    <p>Microsoft India</p>
                                                    <span>Sr. Data Engineer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="alumni-box">
                                                <img src={UserImg} />
                                                <div className="mt-3">
                                                    <h5>Cheyenne Dokidis</h5>
                                                    <p>Microsoft India</p>
                                                    <span>Sr. Data Engineer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="alumni-box">
                                                <img src={UserImg} />
                                                <div className="mt-3">
                                                    <h5>Cheyenne Dokidis</h5>
                                                    <p>Microsoft India</p>
                                                    <span>Sr. Data Engineer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="alumni-box">
                                                <img src={UserImg} />
                                                <div className="mt-3">
                                                    <h5>Cheyenne Dokidis</h5>
                                                    <p>Microsoft India</p>
                                                    <span>Sr. Data Engineer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Yearbook Second */}
                {/* <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading">Yearbook</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href="" className="cancel-button">
                                        Create Yearbook
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="y-search-wrap">
                        <form className="main-search alumni-main-search">
                            <Input name="search" placeholder="Search for Yearbook" type="text" value="" />
                            <div className="search-icon">{SVG.tablesearch()}</div>
                        </form>
                        <div className="alumni-location">
                            <select class="form-select" name="type" options="" placeholder="Year" value="Bangalore, India">
                                <option>2022</option>
                            </select>
                        </div>
                    </div>

                    <div className="yearbook-course-wrap">
                        <div className="second-title">
                            <h6>BTech</h6>
                            <div className='w-100 pl-3'>
                                <span className="title-line"></span>
                            </div>
                        </div>
                        <div className="yearbook-courses">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="yearbook-course-wrap">
                        <div className="second-title">
                            <h6>Management</h6>
                            <div className='w-100 pl-3'>
                                <span className="title-line"></span>
                            </div>
                        </div>
                        <div className="yearbook-courses">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="yearbook-box" style={{ backgroundImage: `url(${YearbookBg})` }}>
                                        <h6>Computer Science</h6>
                                        <p>3k Members</p>
                                        <span className="y-overlay"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Yearbook Third */}
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading">{t('alumni:yearBook')}</h5>
                            </div>
                            {this.props.school.user_type != UserType.Alumni && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <button data-toggle="modal" data-target="#modal_add" className="main-button">
                                            {t('alumni:addAlumni')}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="y-search-wrap">
                        <form className="main-search alumni-main-search">
                            <Input name="search" placeholder={t('alumni:yearBookmsg1')} type="text" value="" onChange={(key, value) => this.filterYearbook(value)} />
                            <div className="search-icon">{SVG.tablesearch()}</div>
                        </form>
                    </div>
                    <div className="yearbook-course-wrap">
                        <div className="yearbook-courses">
                            <div className="row">
                                {this.state.filteredAlumniCategoryList.length > 0 &&
                                    this.state.filteredAlumniCategoryList.map((item) => {
                                        return (
                                            <div
                                                className="col-lg-3"
                                                onClick={() => {
                                                    redirect(route.alumni.yearbook.view + '/' + item.id, this.props.history)
                                                }}
                                            >
                                                <div className="yearbook-box y-box-date cursor" style={{ backgroundImage: `url(${item.profile != null ? item.profile : YearbookBg})` }}>
                                                    <div>
                                                        <p>{t('alumni:batch')}</p>
                                                        <h2>{item.name}</h2>
                                                    </div>
                                                    <p className="y-members">
                                                        {kFormatter(item.alumni_count)} {t('alumni:members')}
                                                    </p>
                                                    <span className="y-overlay"></span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                {this.state.filteredAlumniCategoryList.length == 0 && (
                                    <p className="no-data">
                                        {SVG.nodata()}
                                        {t('common:table.nodata')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.getAddModal()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('selectedBatch', null)
                component._handleChange('selectedGroup', null)
                component._handleChange('selectedCompany', null)
                component._handleChange('selectedDesignation', null)
                component._handleChange('selectedStudent', [])
                component._handleChange('selectedClass', null)
                component._handleChange('alumni_batch_id', null)
                component._handleChange('alumni_group_id', null)
                component._handleChange('alumni_company_id', null)
                component._handleChange('alumni_designation_id', null)
                const alumni_errors = {
                    alumni_group_id: null,
                    alumni_batch_id: null,
                    alumni_designation_id: null,
                    alumni_company_id: null,
                }
                component._handleChange('alumni_errors', alumni_errors)
            }
        })
    }
}

const AlumniYearbook = withTranslation()(connect(mapStateToProps)(AlumniYearbookComponent))

export { AlumniYearbook }
