import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractActivityComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Dashboard } from '~/components/dashboard'
import { Post } from '~/components/post'
import { ReactGallery } from '~/components/gallery'
import { withTranslation } from 'react-i18next'

class ActivityComponent extends AbstractActivityComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getNoticeModal() {
        if (this.state.noticeList.length > 0) {
            const media = []
            this.state.noticeList.map((item) => {
                if (item.gallery != null) {
                    item.gallery.media.map((gallery) => {
                        const result = {
                            src: gallery.media_url,
                            title: item.title,
                            description: item.description,
                        }
                        media.push(result)
                    })
                }
            })
            if (media.length > 0) {
                return <ReactGallery showGallery={false} images={media} viewerIsOpen={this.state.viewerIsOpen} onChange={(data) => this.setState({ viewerIsOpen: data })} />
            }
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="activity" />
                <div className="main-content">
                    <Header />

                    <div className="main-box">
                        <div className="activity">
                            {this.state.noticeList.length > 0 && (
                                <div className="row">
                                    <div className="col-sm-12 float-left form-group pointer" onClick={() => this.setState({ viewerIsOpen: true })}>
                                        <div className="alert alert-primary" role="alert">
                                            {t('common:collabarate_msg')}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Post create={true} />
                        </div>
                        <div>
                            <Dashboard />
                        </div>
                    </div>
                </div>
                {this.getNoticeModal()}
            </div>
        )
    }
}

const Activity = withTranslation()(connect(mapStateToProps)(ActivityComponent))

export { Activity }
