import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { AbstractBlockComponent, mapStateToProps } from './abstract'
class BlockComponent extends AbstractBlockComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getParents() {
        if (this.props.school != null && this.props.school.user_type == UserType.Parents) {
            return (
                <Fragment>
                    <h5 className="error">Access Denied !!!</h5>
                    <hr />
                    <br />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="form-group">
                                <p>
                                    Your children has not been enrolled in any class. Please contact the administation.
                                </p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    getStudent() {
        if (this.props.school != null && this.props.school.user_type == UserType.Student) {
            return (
                <Fragment>
                    <h5 className="error">Access Denied !!!</h5>
                    <hr />
                    <br />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="form-group">
                                <p>You have not been enrolled in any class. Please contact the administation.</p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    getOther() {
        if (
            this.props.school != null &&
            this.props.school.user_type != UserType.Student &&
            this.props.school.user_type != UserType.Parents
        ) {
            return (
                <Fragment>
                    <h5 className="error">Access Denied !!!</h5>
                    <hr />
                    <br />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="form-group">
                                <p>You dont have the permission to access this module.</p>
                                <a href={route.activity} className="main-button">
                                    Home
                                </a>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    getDisabled() {
        if (this.props.school != null) {
            return (
                <Fragment>
                    <h5 className="error">Access Denied !!!</h5>
                    <hr />
                    <br />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="form-group">
                                <p>You account has been blocked. Please contact the administation.</p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <div className="main-content setup-main">
                    <Header className="setup-nav" hideToggle={true} />
                    <div className="row clearfix">
                        <div className="col-md-6 offset-md-3">
                            <a className="blocked-logo-link">
                                {this.props.school != null &&
                                    this.props.school.school != null &&
                                    this.props.school.school.profile != null && (
                                        <Fragment>
                                            <img className="logo" src={this.props.school.school.profile} alt="logo" />
                                        </Fragment>
                                    )}
                                {this.props.school != null &&
                                    this.props.school.school != null &&
                                    this.props.school.school.profile == null && (
                                        <Fragment>
                                            <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                                        </Fragment>
                                    )}
                                {this.props.school != null && this.props.school.school == null && (
                                    <Fragment>
                                        <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                                    </Fragment>
                                )}
                            </a>
                            <div className="setup-container">
                                {this.props.school != null &&
                                    this.props.school != null &&
                                    this.props.school.status == Status.ACTIVE && (
                                        <Fragment>
                                            {this.getParents()}
                                            {this.getStudent()}

                                            {this.getOther()}
                                        </Fragment>
                                    )}
                                {this.props.school != null && this.props.school.status != Status.ACTIVE && (
                                    <Fragment>{this.getDisabled()}</Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Block = connect(mapStateToProps)(BlockComponent)

export { Block }
