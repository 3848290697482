export const grading_type = [
    {
        label: "Grading",
        value: 0,
        translate: "grading:grading"
    },
    {
        label: "GPA",
        value: 1,
        translate: "grading:gpa"
    }
]