import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import { detailBookCategories, toggleBookCategories, viewBookCategories } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}

export class AbstractBookCategoryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            bookDetailList: [],
            bookDetailId: null,
            bookCategoryData: [],
            bookCategoryStructure: [
                {
                    name: {
                        field: 'html',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    code: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:code',
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        translate: 'common:status',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.loadBookCategories()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
    toggle(link) {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading_text', 'Loading')
            component._handleChange('loading', true)
            toggleBookCategories(link, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.loadBookCategories()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }

    loadBookCategories() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let bookCategoryStructure = []
        let bookCategoryData = []
        bookCategoryStructure.push(this.state.bookCategoryStructure[0])
        viewBookCategories(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.bookCategory.map((item, index) => {
                    let text = null
                    if (item.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'common:statusActive',
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'common:statusInactive',
                            badge: 'table-red',
                            modal: 'modal_class_' + index,
                        }
                    }
                    const tableData = {
                        name: {
                            text: 'html',
                            html: (
                                <Fragment>
                                    <button
                                        type="button"
                                        className="table-check"
                                        onClick={() =>
                                            this.setState({
                                                bookDetailId: item.id,
                                            })
                                        }
                                    >
                                        {item.name}
                                    </button>
                                </Fragment>
                            ),
                        },
                        code: {
                            text: item.code,
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.library.bookcategory.edit + '/' + item.id,
                        },
                    }
                    bookCategoryData.push(item)
                    bookCategoryStructure.push(tableData)
                })
                this.setState(
                    {
                        bookCategoryData,
                        bookCategoryStructure,
                    }
                    // this.loadDetail()
                )
            }
        })
    }

    loadDetail() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        detailBookCategories(this.state.bookDetailId, this.props.token).then((response) => {
            console.log(this.state.bookDetailId)
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookDetailList = response.data.bookCategory
                console.log(bookDetailList)

                this.setState({
                    bookCategory: response.data.bookCategory.name,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
