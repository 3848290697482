import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { getSchoolUser, activeClass, getAttendanceReport, getChildren } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import { withTranslation } from 'react-i18next'

type Props = {
    user: Object,
    student: String,
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {}

const userType = [
    { label: 'Student', value: 0 },
    { label: 'Employee', value: 1 },
]

export class AbstractAttendanceReportComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            from_date: moment().toDate(),
            to_date: moment().toDate(),
            users: [],
            filteredUser: [],
            classes: [],
            section: [],
            subject: [],
            tableData: [],
            isIndividual: false,
            userType: userType,
            user_type: userType[0],
            selectedClass: null,
            selectedSubject: null,
            selectedUser: null,
            selectedSubject: null,
            count: false,
            errors: {
                from_date: null,
                to_date: null,
                user_type: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._search = this._search.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Teacher) {
            userType[1].label = 'Your Attendance'
        } else {
            userType[1].label = 'Employee'
        }
        this.loadUser()
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    loadUser() {
        if (this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents) {
            this._handleChange('loading', true)
            getSchoolUser(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let users = []
                if (response.code == API_SUCCESS) {
                    response.data.users.map((item, index) => {
                        if (item.user_type != UserType.Alumni && item.user_type != UserType.Parents)
                            users.push({
                                value: item.id,
                                label: item.user.name != null ? item.user.name + ' - ' + item.user.reg_no : item.user.reg_no,
                                type: item.user_type,
                                name: item.user.name,
                                reg_no: item.user.reg_no,
                                section: item.active_user_academics != null ? item.active_user_academics.section_id : null,
                                class: item.active_user_academics != null ? item.active_user_academics.section.class_id : null,
                                created_at: item.active_user_academics != null ? item.active_user_academics.created_at : item.created_at,
                                user_created_at: item.created_at,
                            })
                    })
                    this.setState(
                        {
                            users,
                        },
                        () => {
                            this.loadFilteredUser()
                            this.loadClass()
                        }
                    )
                }
            })
        } else if (this.props.school.user_type == UserType.Parents) {
            this._handleChange('loading', true)
            getChildren(this.props.token).then((response) => {
                this._handleChange('loading', false)

                if (response.code == API_SUCCESS) {
                    const children = response.data.children.find((item) => item.student_id == this.props.student)
                    let users = []
                    users.push({
                        value: this.props.student,
                        label: children.children.user.name != null ? children.children.user.name + ' - ' + children.children.user.reg_no : children.children.user.reg_no,
                        type: children.children.user_type,
                        name: children.children.user.name,
                        reg_no: children.children.user.reg_no,
                        section: children.children.active_user_academics != null ? children.children.active_user_academics.section_id : null,
                        class: children.children.active_user_academics != null ? children.children.active_user_academics.section.class_id : null,
                        created_at: children.children.active_user_academics != null ? children.children.active_user_academics.created_at : children.children.created_at,
                        user_created_at: children.children.created_at,
                    })
                    this.setState(
                        {
                            users,
                            isIndividual: true,
                            selectedUser: users[0],
                        },
                        () => {
                            this.loadFilteredUser()
                            this.loadClass()
                        }
                    )
                }
            })
        } else {
            let users = []
            users.push({
                value: this.props.school.id,
                label: this.props.user.name != null ? this.props.user.name + ' - ' + this.props.user.reg_no : this.props.user.reg_no,
                type: this.props.school.user_type,
                name: this.props.user.name,
                reg_no: this.props.user.reg_no,
                section: this.props.school.active_user_academics != null ? this.props.school.active_user_academics.section_id : null,
                class: this.props.school.active_user_academics != null ? this.props.school.active_user_academics.section.class_id : null,
                created_at: this.props.school.active_user_academics != null ? this.props.school.active_user_academics.created_at : this.props.school.created_at,
                user_created_at: this.props.school.created_at,
            })
            this.setState(
                {
                    users,
                    isIndividual: true,
                    selectedUser: users[0],
                },
                () => {
                    this.loadClass()
                }
            )
        }
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const classes = []
                response.data.class.map((item) => {
                    const data = {
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                        subject: item.subject,
                        section: item.active_section,
                    }
                    classes.push(data)
                })
                this.setState(
                    {
                        classes,
                        loaded: true,
                    },
                    () => {
                        this.loadFilteredUser()
                        this.loadSection()
                        this.loadSubject()
                    }
                )
            }
        })
    }

    loadFilteredUser() {
        const filteredUser = []
        this.state.users.filter((item) => {
            if (this.state.user_type.value == userType[0].value) {
                if (item.type == UserType.Student) {
                    if (this.props.school.user_type == UserType.Teacher) {
                        const check = this.state.classes.find((classItem) => {
                            if (classItem.section != null) {
                                const sectionCheck = classItem.section.find((sectionItem) => sectionItem.id == item.section)
                                if (sectionCheck != null) {
                                    return true
                                }
                            }
                        })
                        if (check != null) {
                            filteredUser.push(item)
                        }
                    } else {
                        filteredUser.push(item)
                    }
                }
            } else {
                if (item.type != UserType.Student) {
                    if (this.props.school.user_type == UserType.Teacher) {
                        if (item.value == this.props.school.id) {
                            filteredUser.push(item)
                        }
                    } else {
                        filteredUser.push(item)
                    }
                }
            }
        })
        let selectedUser = null
        if (this.props.school.user_type == UserType.Teacher) {
            if (this.state.user_type.value == 1) {
                selectedUser = filteredUser.length > 0 ? filteredUser[0] : null
            } else {
                selectedUser = null
            }
        } else if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            selectedUser = filteredUser.length > 0 ? filteredUser[0] : null
        }

        this.setState({
            filteredUser,
            selectedUser,
        })
    }

    loadSection() {
        const { t } = this.props
        const classes = this.state.selectedClass
        let section = [
            {
                label: 'All Section',
                value: null,
                translate: 'classes:section.allSection',
            },
        ]
        if (this.props.school.user_type == UserType.Teacher) {
            section = []
        }
        if (classes != null) {
            classes.section.map((item) => {
                const data = {
                    label: item.name + ' - ' + item.code,
                    value: item.id,
                }
                section.push(data)
            })
        }

        this.setState({
            section,
            selectedSectionId: section.length > 0 ? section[0].value : 0,
            selectedSection: section[0],
        })
    }

    loadSubject() {
        const { t } = this.props
        if (this.state.selectedUser != null) {
            const classes = this.state.classes.find((item) => {
                return item.section.find((sectionItem) => {
                    if (sectionItem.id == this.state.selectedUser.section) {
                        return true
                    }
                })
            })
            const subject = [
                {
                    label: 'General',
                    value: null,
                    name: 'General',
                    translate: 'attendance:general',
                },
            ]
            if (classes != null) {
                classes.subject.map((item) => {
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                        name: item.name,
                    }
                    subject.push(data)
                })
            }
            this.setState({
                subject,
                selectedSubjectId: subject.length > 0 ? subject[0].value : null,
                selectedSubject: subject[0],
            })
        } else {
            const classes = this.state.selectedClass
            const subject = [
                {
                    isValue: true,
                    label: 'General',
                    value: null,
                    name: 'General',
                    translate: 'attendance:general',
                },
            ]
            if (classes != null) {
                classes.subject.map((item) => {
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                        name: item.name,
                    }
                    subject.push(data)
                })
            }
            this.setState({
                subject,
                selectedSubjectId: subject.length > 0 ? subject[0].value : null,
                selectedSubject: subject[0],
            })
        }
    }

    checkDate() {
        const from_date = moment(this.state.from_date, 'yyyy-MM-DD')
        const to_date = moment(this.state.to_date, 'yyyy-MM-DD')
        if (from_date.isAfter(to_date)) {
            return false
        }
        return true
    }

    _search(e) {
        const { t } = this.props
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        const validate = Object.keys(errors).filter((item) => errors[item] != null)

        if (validate.length == 0) {
            const checkDate = this.checkDate()
            if (!checkDate) {
                errors['from_date'] = t('toaster:invalidDate')
                errors['to_date'] = t('toaster:invalidDate')
            }
            if (this.state.user_type.value == userType[0].value) {
                if (this.state.isIndividual) {
                    if (this.state.selectedUser == null) {
                        errors['users'] = t('toaster:required')
                    }
                } else {
                    if (this.state.selectedClass == null) {
                        errors['classes'] = t('toaster:required')
                    }
                }
            } else {
                if (this.state.selectedUser == null) {
                    errors['users'] = t('toaster:required')
                }
            }

            this.setState({ errors }, () => {})
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading', true)
                const data = {
                    from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                    to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
                }

                if (this.state.user_type.value == userType[0].value) {
                    if (this.state.selectedUser != null) {
                        data.user_school_id = this.state.selectedUser.value
                    }
                    if (this.state.selectedSubject != null && this.state.selectedSubject.value != null) {
                        data.subject_id = this.state.selectedSubject.value
                    }
                    if (this.state.selectedSection != null && this.state.selectedSection.value != null) {
                        data.section_id = this.state.selectedSection.value
                        data.class_id = this.state.selectedClass.value
                    }
                    if (this.state.selectedClass != null && this.state.selectedClass.value != null) {
                        data.class_id = this.state.selectedClass.value
                    }
                } else {
                    if (this.state.selectedUser != null) {
                        data.user_school_id = this.state.selectedUser.value
                    }
                }
                getAttendanceReport(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const attendanceData = response.data.report

                        let from_date = moment(this.state.from_date, 'yyyy-MM-DD')
                        const to_date = moment(this.state.to_date, 'yyyy-MM-DD')
                        const tableData = []
                        let users = this.state.selectedUser
                        let subject = t('attendance:general')
                        if (this.state.user_type.value == userType[0].value) {
                            if (this.state.selectedSubject != null && this.state.selectedSubject.value != null) {
                                subject = this.state.selectedSubject.name
                            }
                            if (this.state.selectedSection != null && this.state.selectedSection.value != null) {
                                users = []
                                this.state.users.filter((item) => {
                                    if (item.section == this.state.selectedSection.value) {
                                        users.push(item)
                                    }
                                })
                            } else if (this.state.selectedClass != null && this.state.selectedClass.value != null) {
                                users = []
                                this.state.users.filter((item) => {
                                    if (item.class == this.state.selectedClass.value) {
                                        users.push(item)
                                    }
                                })
                            }
                        }
                        while (!from_date.isAfter(to_date)) {
                            if (!Array.isArray(users)) {
                                const currentDate = moment(from_date, 'yyyy-MM-DD')
                                const createDate = moment(users.user_created_at, 'yyyy-MM-DD')
                                if (currentDate.isSameOrAfter(createDate)) {
                                    const attendance = attendanceData.find((item) => {
                                        const check_date = moment(moment(from_date).format('yyyy-MM-DD'), 'yyyy-MM-DD')
                                        const attendance_date = moment(item.created_at, 'yyyy-MM-DD')
                                        if (users.value == item.user_school_id && check_date.isSame(attendance_date)) {
                                            return true
                                        }
                                    })
                                    const data = {
                                        date: moment(from_date).format('yyyy-MM-DD').toString(),
                                        reg_no: users.reg_no,
                                        name: users.name,
                                        status: attendance != null ? attendance.status : Status.PENDING,
                                        type: subject,
                                        remarks: attendance != null ? attendance.remarks : '-',
                                        gallery: attendance != null ? attendance.gallery : null,
                                    }
                                    tableData.push(data)
                                }
                            } else {
                                users.map((item) => {
                                    const currentDate = moment(from_date, 'yyyy-MM-DD')
                                    const createDate = moment(item.created_at, 'yyyy-MM-DD')
                                    if (currentDate.isSameOrAfter(createDate)) {
                                        const attendance = attendanceData.find((attendanceItem) => {
                                            const check_date = moment(moment(from_date).format('yyyy-MM-DD'), 'yyyy-MM-DD')
                                            const attendance_date = moment(attendanceItem.created_at, 'yyyy-MM-DD')
                                            if (item.value == attendanceItem.user_school_id && check_date.isSame(attendance_date)) {
                                                return true
                                            }
                                        })
                                        const data = {
                                            date: moment(from_date).format('yyyy-MM-DD').toString(),
                                            reg_no: item.reg_no,
                                            name: item.name,
                                            status: attendance != null ? attendance.status : Status.PENDING,
                                            type: subject,
                                            remarks: attendance != null ? attendance.remarks : '-',
                                            gallery: attendance != null ? attendance.gallery : null,
                                        }
                                        tableData.push(data)
                                    }
                                })
                            }
                            from_date.add(1, 'days')
                        }
                        this.setState({
                            count: Array.isArray(users) ? false : true,
                            tableData,
                            loadModal: false,
                        })
                    } else {
                        toastMessage('error', response.message)
                        const tableData = []
                        this.setState({
                            tableData,
                        })
                    }
                })
            }
        }
    }
}

const AbstractAttendanceReport = withTranslation()(AbstractAttendanceReportComponent)

export { AbstractAttendanceReport }

export function mapStateToProps(state: Object) {
    return {
        student: state.student,
        user: state.user,
        school: state.school,
        token: state.token,
    }
}
