import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { type_list } from '~/helper/constant/category'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import {
    editProductService,
    updateProductService,
    viewAccountTax,
    viewProductCategory,
    viewProductUnit,
} from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractProductServiceEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            all: false,
            product_id: '',
            loading: false,
            loading_text: 'Loading',
            name: '',
            sku: '',
            sale_price: '',
            purchase_price: '',
            quantity: '',
            taxes: [],
            tax_id: [],
            unit_id: '',
            category_id: '',
            categories: [],
            defaultCategory: '',
            selectedCategory: '',
            selectedTax: [],
            units: [],
            defaultUnit: '',
            selectedUnit: '',
            type_list: type_list,
            defaultType: type_list[0],
            selectedType: type_list[0].value,
            description: '',
            errors: {
                name: null,
                sku: null,
                // sale_price: null,
                // purchase_price: null,
                categories: null,
                units: null,
                type_list: null,
                // quantity: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadTax()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadTax() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        viewAccountTax(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const taxes = response.data.taxes.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        taxes,
                    },
                    () => {
                        this.loadCategory()
                    }
                )
            }
        })
    }

    loadCategory() {
        this._handleChange('loading', true)
        viewProductCategory(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const filteredcategory = response.data.categories.filter((item) => item.type == 0)
                const categories = filteredcategory.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        categories,
                    },
                    () => {
                        this.loadUnit()
                    }
                )
            }
        })
    }

    loadUnit() {
        this._handleChange('loading', true)
        viewProductUnit(this.props.token).then((response) => {
            this._handleChange('loading', false)

            if (response.code == API_SUCCESS) {
                const units = response.data.units.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        units,
                    },
                    () => {
                        this.loadProductService()
                    }
                )
            }
        })
    }

    loadProductService() {
        const { t } = this.props
        this._handleChange('loading', true)
        editProductService(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.productService
                const tax_id = item.tax_id.map((t) => {
                    return parseInt(t)
                })
                const selectedTax = this.state.taxes.filter((tax) => tax_id.includes(tax.value))
                const defaultCategory = this.state.categories.find((cat) => cat.value == parseInt(item.category_id))
                const defaultUnit = this.state.units.find((unit) => unit.value == parseInt(item.unit_id))
                this.setState({
                    name: item.name,
                    sku: item.sku,
                    tax_id,
                    description: item.description,
                    // sale_price: item.sale_price,
                    // purchase_price: item.purchase_price,
                    // quantity: item.quantity,
                    category_id: item.category_id,
                    unit_id: item.unit_id,
                    product_id: item.id,
                    defaultType: type_list.find((e) => e.value == item.type),
                    selectedType: item.type,
                    selectedTax,
                    defaultCategory,
                    selectedCategory: defaultCategory != null ? defaultCategory.value : null,
                    defaultUnit,
                    selectedUnit: defaultUnit != null ? defaultUnit.value : null,
                })
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let tax_access = []
            this.state.selectedTax.map((item) => {
                tax_access.push(item.value)
            })
            const data = {
                name: this.state.name,
                sku: this.state.sku,
                tax_id: tax_access,
                description: this.state.description,
                sale_price: 1,
                purchase_price: 1,
                quantity: 1,
                category_id: this.state.selectedCategory,
                unit_id: this.state.selectedUnit,
                type: this.state.selectedType,
            }
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            updateProductService(this.state.product_id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.productservice.view)
                } else {
                    toastMessage('error', response.data)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
