import React, { Fragment } from 'react'
import { AbstractInputComponent } from './abstract'

export class Input extends AbstractInputComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    checkClassName() {
        if (this.props.className) {
            return 'input-border ' + this.props.className
        } else {
            return 'form-control input-border'
        }
    }

    render() {
        if (this.props.type == 'radio') {
            return (
                <input
                    autoComplete="off"
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    type={this.props.type}
                    name={this.props.name}
                    readOnly={this.props.readonly}
                    disabled={this.props.disabled}
                    readOnly={this.state.readOnly ? true : false}
                    defaultChecked={this.props.defaultChecked}
                    checked={this.props.checked}
                    value={this.state.value}
                    style={this.props.style}
                    className={this.checkClassName()}
                    onChange={this._onChange}
                />
            )
        }
        if (this.props.type == 'number') {
            return (
                <Fragment>
                    <input
                        autoComplete="off"
                        placeholder={this.props.placeholder}
                        id={this.props.id}
                        type="text"
                        name={this.props.name}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        min={this.props.min != null ? this.props.min : 100000000000000}
                        max={this.props.max != null ? this.props.max : 100000000000000}
                        value={this.state.value}
                        style={this.props.style}
                        className={this.checkClassName()}
                        onChange={this._onNumberChange}
                        onBlur={(e) => {
                            this.checkValue(e)
                        }}
                    />
                    {this.state.inputError != null && (
                        <label className="error text-danger">{this.state.inputError}</label>
                    )}
                </Fragment>
            )
        } else {
            return (
                <input
                    autoComplete="off"
                    type={this.props.type}
                    id={this.props.id}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    readOnly={this.state.readOnly ? true : false}
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength != null ? this.props.maxLength : 100000000000000}
                    value={this.state.value}
                    style={this.props.style}
                    className={this.checkClassName()}
                    onChange={this._onChange}
                />
            )
        }
    }
}
