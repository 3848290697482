import {Component} from 'react'
import {Dispatch} from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { addBankAccount } from '~/services/api'


type Props = {
    dispatch: Dispath<any>
}
type State = {}

export class AbstractBankAccountAddComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            name: '',
            bank: '',
            account_number: '',
            opening_balance:'',
            contact_number:'',
            bank_address: '',
            errors:{
                name:null,
                bank:null,
                account_number:null,
                opening_balance:null,
                contact_number:null,
                bank_address:null

            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    compnentDidMount(){}
    _handleChange(key, data){
        this.setState({[key]: data})
    }
    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors[item] != null)
        if(validate.length == 0){
            const data={
                holder_name: this.state.name,
                bank_name: this.state.bank,
                account_number: this.state.account_number,
                opening_balance: this.state.opening_balance,
                contact_number: this.state.contact_number,
                bank_address: this.state.bank_address
            }
            this._handleChange("loading", true)
            addBankAccount(data, this.props.token).then(
                (response)=>{
                    this._handleChange("loading", false)
                    if(response.code == API_SUCCESS){
                        toastMessage("success", response.message)
                        redirect(route.admin.accounting.banking.account.view)
                    }else{
                        toastMessage('error', response.message)
                        if(response.data){
                            if ('errors' in response.data.errors){
                                this.setState({
                                    errors:response.data.errors
                                })
                            }
                        }
                    }
                }
            ).catch((error)=>{
                console.error(error)

            })
        }
    }
}

export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}