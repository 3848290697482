import React, { Component } from 'react'
import $ from 'jquery'

type Props = {
    id: String,
    onChange: any,
    value: String,
    style: any,
    name: String,
    className: String,
    checkedColor: String,
    uncheckedColor: String,
    center: Boolean,
}

type State = {
    value: String,
    checked: String,
}

export class AbstractCheckboxComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            value: this.props.value,
            checked: this.props.value ? 'checked' : '',
        }
        this._onChange = this._onChange.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value })
            this.setState({ checked: this.props.value ? 'checked' : '' })
            // if(this.props.onChange){
            //     this.props.onChange(this.props.name,this.props.value);
            // }
        }
    }

    _onChange(e) {
        if (this.state.value) {
            this.setState({ value: false })
            this.setState({ checked: '' })
            if (this.props.onChange != null) {
                this.props.onChange(this.props.name, false)
            }
        } else {
            this.setState({ value: true })
            this.setState({ checked: 'checked' })
            if (this.props.onChange != null) {
                this.props.onChange(this.props.name, true)
            }
        }
        // this.toggleCheck();
    }
}
