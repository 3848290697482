import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractGalleryComponent, mapStateToProps } from './abstract'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { getMediaIcon } from '~/helper/function'

class GalleryComponent extends AbstractGalleryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getUnsupported(media_type) {
        return (
            <Fragment>
                <div className="unsupport">
                    <a className="folder">{getMediaIcon(media_type)}</a>
                    Unsupported file format
                </div>
            </Fragment>
        )
    }

    getGalleryImage() {
        const media_type = this.state.gallery != null ? this.state.gallery.media_type : ''
        const media_url = this.state.gallery != null ? this.state.gallery.media_url : ''
        if (media_type.includes('pdf')) {
            return <iframe src={media_url} />
        } else if (media_type.includes('video')) {
            return <video src={media_url} controls autoplay />
        } else if (media_type.includes('image')) {
            return <img src={media_url} />
        } else if (media_type.includes('presentation') || media_type.includes('ms-powerpoint')) {
            return (
                <Fragment>
                    {this.getUnsupported(media_type)}
                    <iframe src={media_url} />
                </Fragment>
            )
        } else if (media_type.includes('wordprocessing') || media_type.includes('msword')) {
            return (
                <Fragment>
                    {this.getUnsupported(media_type)}
                    <iframe src={media_url} />
                </Fragment>
            )
        } else if (media_type.includes('spreadsheet') || media_type.includes('octet-stream')) {
            return (
                <Fragment>
                    {this.getUnsupported(media_type)}
                    <iframe src={media_url} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    {this.getUnsupported(media_type)}
                    <iframe src={media_url} />
                </Fragment>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <Fragment>
                <Loading status={this.state.loading} text="Loading" />
                {this.state.gallery != null && <div className="full-frame">{this.getGalleryImage()}</div>}
            </Fragment>
        )
    }
}

const Gallery = connect(mapStateToProps)(GalleryComponent)

export { Gallery }
