import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as UserType from '~/helper/constant/user_type'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Fragment } from 'react'
import * as route from '~/helper/constant/route'
import { AbstractBooksComponent, mapStateToProps } from './abstract'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import $, { type } from 'jquery'

class BooksViewComponent extends AbstractBooksComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusToggleModal = this.getStatusToggleModal.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }
    toggle(e) {
        const link = e.target.getAttribute('link')
        console.log(link)
        super.toggle(link).then((response) => {
            if (response) { 
                $('#close_' + link).trigger('click')
            }
        })
    }

    getStatusToggleModal(){
        const {t} = this.props
        const modal = this.state.bookList.map((item, index)=>{
            let text = t('fee:disable')
            if (item.status != Status.ACTIVE){
                text = t('fee:enable')
            }
            return(
                <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('calendar:event.areyousureyouwantto')} {text + ' ' + item.title}?"
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }
      
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="library" isAccount={false} />
                <Loading status={this.state.loading} text={this.state.loading_text} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('library:books')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('library:books')}</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.library.books.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                    

                    <div className="main-form">
                    <div className="navtab_pill">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="pill" href="#digitallibrary">
                                    {t('library:digitallibrary')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="pill" href="#hardcopy">
                                    {t('library:hardcopy')}
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div id="digitallibrary" role="tabpanel" className="tab-pane fade show active">
                                <div className="main-form">
                                    <Table data={this.state.digital} /> 
                                </div>
                                
                            </div>
                            <div id="hardcopy" role="tabpanel" className="tab-pane fade">
                                <div className="main-form">
                                    <Table data={this.state.hardcopy} />
                                </div>
                            </div>
                            {this.getStatusToggleModal()}

                        </div>
                        </div>
                        <br />
                    </div>
                </div>

            </div>
        )
    }
}
const BooksView = withTranslation()(connect(mapStateToProps)(BooksViewComponent))

export { BooksView }
