import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { getNotificationDetail } from '~/services/api'

type Props = {
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractNotificationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            title: '',
            description: '',
        }
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.loadNotification()
        }
    }

    componentDidMount() {
        this.loadNotification()
    }

    loadNotification() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getNotificationDetail(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const notificationData = response.data.notification
                this.setState({
                    title: notificationData.title,
                    description: notificationData.message,
                    created_at: notificationData.created_at,
                    loaded: true,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
