import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractLeaderBoardAddComponent, mapStateToProps } from './abstract'
import { Header } from '~/components/header'
import * as UserType from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { Sidebar } from '~/components/sidebar'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import { Button, Input, TextArea } from '~/components/form'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

class LeaderBoardAddComponent extends AbstractLeaderBoardAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setMedal = this.setMedal.bind(this)
        this.setUser = this.setUser.bind(this)
    }

    setMedal(data) {
        this._handleChange('selectedMedal', data)
        this._handleChange('medal_id', data.value)
    }

    setUser(data) {
        this._handleChange('selectedUserSchool', data)
        this._handleChange('users_school_id', data.value)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="leaderboard" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.leaderboard.view}>{t('gamification:leaderboard')} > </a>
                            </li>
                            <li>{t('common:addBtn')} XP</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('common:addBtn')} XP :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('gamification:medal')} *</label>
                                        <Select selected={this.state.medal_id} onChange={this.setMedal} name="medal_id" options={this.state.medalList} placeholder={t('gamification:medal')} searchable={true} value={this.state.selectedMedal} />
                                        {this.state.errors.medal_id && <label className="error text-danger">{this.state.errors.medal_id}</label>}
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('gamification:user')} *</label>
                                        <Select selected={this.state.users_school_id} onChange={this.setUser} name="user_school_id" options={this.state.userList} placeholder={t('gamification:user')} searchable={true} value={this.state.selectedUserSchool} />
                                        {this.state.errors.users_school_id && <label className="error text-danger">{this.state.errors.users_school_id}</label>}
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value="" />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const LeaderBoardAdd = withTranslation()(connect(mapStateToProps)(LeaderBoardAddComponent))

export { LeaderBoardAdd }
