import React, { Component, useCallback } from 'react'

type Props = {
    images: Array,
    viewerIsOpen: Boolean,
    showGallery: Boolean,
    onChange: any,
}

type State = {
    currentImage: Integer,
    viewerIsOpen: Boolean,
}

export class AbstractGalleryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            currentImage: 0,
            showGallery: this.props.showGallery == true ? true : false,
            viewerIsOpen: this.props.viewerIsOpen == true ? true : false,
        }
        this.openLightbox = this.openLightbox.bind(this)
        this.closeLightbox = this.closeLightbox.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.viewerIsOpen != prevProps.viewerIsOpen) {
            this.setState({
                viewerIsOpen: this.props.viewerIsOpen == true ? true : false,
            })
        }
    }

    openLightbox = (event, { photo, index }) => {
        this.setState(
            {
                currentImage: index,
                viewerIsOpen: true,
            },
            () => {
                if (this.props.onChange != null) {
                    this.props.onChange(this.state.viewerIsOpen)
                }
            }
        )
    }

    closeLightbox() {
        this.setState(
            {
                currentImage: 0,
                viewerIsOpen: false,
            },
            () => {
                if (this.props.onChange != null) {
                    this.props.onChange(this.state.viewerIsOpen)
                }
            }
        )
    }
}
