import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractRedirectLoginComponent, mapStateToProps } from './abstract'
import { Input, Button, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import { LoginAnimation } from '~/components/animation'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import LanguageSelector from '~/components/language/language'

class RedirectLoginComponent extends AbstractRedirectLoginComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="clearfix login-page">
                <div className="login-language">
                    <LanguageSelector />
                </div>
                <Loading status={this.state.loading} text="Loading" />
                <LoginAnimation />
                <div className="login-form">
                    <div className="login-main">
                        <div className="school-detail">
                            <img src="/assets/images/mobile-logo.png" />
                        </div>
                        <h5 className="text-center login-title">{t('login:title')}:</h5>
                        <br />
                        <form>
                            <div className="form-group">
                                <label>{t('login:username.label')}</label>
                                <div>
                                    <Input onChange={this._handleChange} name="username" placeholder={t('login:username.placeholder')} type="text" value={this.state.username} />
                                    {this.state.errors.username && <label className="error text-danger">{this.state.errors.username}</label>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t('login:password.label')}</label>
                                <div>
                                    <Input onChange={this._handleChange} name="password" placeholder={t('login:password.placeholder')} type="password" value={this.state.password} />
                                    {this.state.errors.password && <label className="error text-danger">{this.state.errors.password}</label>}
                                </div>
                            </div>
                            {/* <div className="col-md-6 form-check float-left">
                                <Checkbox onChange={this._handleChange} name="remember" id="remember" />
                                <label className="form-check-label" htmlFor="remember">
                                    {t('login:remember')}
                                </label>
                            </div> */}
                            <Button onClick={this._onLogin} type="submit" text={t('login:login')} login={true} />
                            <div className="clearfix form-group row">
                                <div className="col-md-12 text-right">
                                    <a className="login-forget" href={route.forgot_password}>
                                        {t('login:forgot')}
                                    </a>
                                </div>
                            </div>
                            <span className="powered">
                                {t('login:powered')}{' '}
                                <a href="https://www.classpedia.io" target="_blank">
                                    {t('collabarate')}
                                </a>
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const RedirectLogin = withTranslation()(connect(mapStateToProps)(RedirectLoginComponent))

export { RedirectLogin }
