import {Component} from 'react'
import {Dispath} from 'react-redux'


type Props = {
    dispatch: Dispath<any>
}
type State = {}

export class AbstractReportsComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading'
        }
    }
    compnentDidMount(){}


}

export function mapStateToProps(state:Object){
    return {
        token: state.token,
    }
}