export const alumni_page = (data) => {
    return {
        type: 'ALUMNI_PAGE',
    }
}

export const school_page = () => {
    return {
        type: 'SCHOOL_PAGE',
    }
}
