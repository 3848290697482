import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractRoutineScheduleComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import * as Status from '~/helper/constant/status'
import { timetable_color } from '~/helper/constant/color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

class RoutineScheduleComponent extends AbstractRoutineScheduleComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getDate = this.getDate.bind(this)
        this.getSchedule = this.getSchedule.bind(this)
        this.getRoutine = this.getRoutine.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectExamDate = this._selectExamDate.bind(this)
        this._selectQuestionBank = this._selectQuestionBank.bind(this)
        this._setStartTime = this._setStartTime.bind(this)
        this._setEndTime = this._setEndTime.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getInfoModal = this.getInfoModal.bind(this)
        this.getExamModal = this.getExamModal.bind(this)
        this.changeFullMarks = this.changeFullMarks.bind(this)
    }

    _setStartTime(data) {
        this._handleChange('start_time', data)
    }

    _setEndTime(data) {
        this._handleChange('end_time', data)
    }

    _selectExamDate(data) {
        this._handleChange('exam_date', data)
    }

    _selectQuestionBank(data) {
        this._handleChange('selectedQuestionBank', data)
    }

    _selectSubject(data) {
        this.setState(
            {
                selectedSubject: data,
            },
            () => {
                this.filterQuestionBank()
            }
        )
    }

    changeFullMarks(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                this.filterQuestionBank()
            }
        )
    }

    getDate() {
        const { t } = this.props
        const date = this.state.date.map((item, index) => {
            return (
                <li key={index}>
                    <a onClick={() => this._handleChange('activeTab', index)} className={index == this.state.activeTab ? 'active' : ''} id={'day-tab-' + index} data-toggle="tab" href={'#day_' + index} role="tab" aria-controls="day" aria-selected="false">
                        {item.label} - {t('calendar:calendar-menu.day')} {index + 1}
                    </a>
                </li>
            )
        })
        return date
    }

    getSchedule() {
        const schedule = this.state.date.map((item, index) => {
            return (
                <div key={index} className={index == this.state.activeTab ? 'tab-pane fade show active' : 'tab-pane fade show'} id={'day_' + index} role="tabpanel" aria-labelledby={'day-tab-' + index}>
                    <div className="row clearfix">
                        <div className="month-view col-md-12">
                            <ul className="list-inline day-view-list">{this.getRoutine(item.value)}</ul>
                        </div>
                    </div>
                </div>
            )
        })
        return schedule
    }

    getRoutine(id) {
        const { t } = this.props
        if (this.state.routine.length > 0) {
            const routine = this.state.routine
                .filter((item) => item.exam_date == id)
                .sort((a, b) => {
                    const a_start_time = moment(a.start_time, 'hh:mm a')
                    const b_start_time = moment(b.start_time, 'hh:mm a')
                    return a_start_time.isAfter(b_start_time) ? 1 : -1
                })
            let i = -1
            if (routine.length > 0) {
                const modal = routine.map((item, index) => {
                    i++
                    if (i == 4) {
                        i = 0
                    }
                    return (
                        <li key={index} className={timetable_color[i]}>
                            <small>{item.start_time}</small>
                            <div className="day-view-detail">
                                <ins>
                                    {item.subject.name} &nbsp;&nbsp;
                                    {item.is_online == true && <ins className="new-con">{t('exam:onlineexam')}</ins>}
                                </ins>
                                <span>
                                    {t('exam:fullMarks')} : {item.full_mark}
                                </span>
                                <span>
                                    {t('exam:passMarks')} : {item.pass_mark}
                                </span>
                            </div>
                            {item.is_online == true && (
                                <button className="start" data-toggle="modal" data-target={'#modal_start_' + item.id}>
                                    {t('start')}
                                </button>
                            )}
                            <div className="e-d-t">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                        <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                    </button>
                                    <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                        <button className="dropdown-item" data-toggle="modal" data-target={'#modal_info_' + item.id} type="button">
                                            {t('Info')}
                                        </button>
                                        {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                            <Fragment>
                                                {this.state.exam != null && this.state.exam.status != Status.ACTIVE && (
                                                    <button className="dropdown-item" data-toggle="modal" data-target={'#modal_delete_' + item.id}>
                                                        {t('common:DeleteBtn')}
                                                    </button>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })
                return modal
            } else {
                const { t } = this.props
                return (
                    <p className="no-data">
                        {SVG.nodata()} {t('exam:exam:routine:noexamroutine')}
                    </p>
                )
            }
        } else {
            const { t } = this.props
            return (
                <p className="no-data">
                    {' '}
                    {SVG.nodata()}
                    {t('exam:exam:routine:noexamroutine')}
                </p>
            )
        }
    }

    getExamModal() {
        const { t } = this.props
        if (this.state.routine.length > 0) {
            const modal = this.state.routine.map((item, index) => {
                return (
                    <div key={index} id={'modal_start_' + item.id} data-id={'modal_start_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('exam:liveExam_msg')}</p>
                                    <p className="delete-file-name">"{item.subject.name}"</p>
                                    <p className="delete-file-name">
                                        {item.start_time} - {item.end_time}
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button type="submit" text={t('start')} link={item.id} main={true} onClick={this._start} />
                                    <button id={'close_start_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getDeleteModal() {
        const { t } = this.props
        if (this.state.routine.length > 0) {
            const modal = this.state.routine.map((item, index) => {
                return (
                    <div key={index} id={'modal_delete_' + item.id} data-id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')}!!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">{t('exam:deleteMsg')}</p>
                                    <p className="delete-file-name">"{item.subject.name}"</p>
                                    <p className="delete-file-name">
                                        "{item.start_time} - {item.end_time}"
                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button type="submit" text={t('common:DeleteBtn')} link={item.id} main={true} onClick={this._delete} />
                                    <button id={'close_delete_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getInfoModal() {
        const { t } = this.props
        if (this.state.routine.length > 0) {
            const modal = this.state.routine.map((item, index) => {
                return (
                    <div key={index} id={'modal_info_' + item.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('exam:examRoutineInformation')} : </span>
                            </div>
                            <form className="main-form month-view">
                                <ul className="list-inline day-view-list">
                                    <li className="small-green">
                                        <small>{item.start_time}</small>
                                        <div className="day-view-detail">
                                            <ins>
                                                {item.subject.name} &nbsp;&nbsp;
                                                {item.is_online == true && <ins className="new-con">{t('exam:onlineexam')}</ins>}
                                            </ins>
                                            <span>
                                                {t('exam:fullMarks')} : {item.full_mark}
                                            </span>
                                            <span>
                                                {t('exam:passMarks')}: {item.pass_mark}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <hr />
                                <div className="row">
                                    <label className="form-title col-md-5 float-left">{t('exam:examType')}</label>
                                    <div className="col-md-7 float-left">
                                        <span className="teacher-name">{item.is_online ? t('exam:onlineexam') : t('exam:written')}</span>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <label className="form-title col-md-5 float-left">{t('exam:examDate')}</label>
                                    <div className="col-md-7 float-left">
                                        <span className="teacher-name">{item.exam_date}</span>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <label className="form-title col-md-5 float-left">{t('exam:examTime')}</label>
                                    <div className="col-md-7 float-left">
                                        <span className="teacher-name">{item.start_time}</span>
                                        &nbsp; - &nbsp;
                                        <span className="teacher-name">{item.end_time}</span>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <label className="form-title col-md-5 float-left">{t('exam:fullMarks')}</label>
                                    <div className="col-md-7 float-left">
                                        <span className="teacher-name">{item.full_mark}</span>
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <label className="form-title col-md-5 float-left">{t('exam:passMarks')}</label>
                                    <div className="col-md-7 float-left">
                                        <span className="teacher-name">{item.pass_mark}</span>
                                        <br />
                                    </div>
                                </div>
                                {item.is_online == true && <hr />}
                                {item.is_online == true && (item.negative_marking == true || item.show_result == true || item.show_correct_answer == true || item.skip_question == true) && (
                                    <Fragment>
                                        <div className="row">
                                            <label className="form-title col-md-5 float-left">{t('exam:exam_info')}</label>
                                            <div className="col-md-7 float-left">
                                                {item.negative_marking == true && <span className="teacher-name">{t('exam:negativeMarking')}</span>}
                                                {item.show_result == true && <span className="teacher-name">{t('exam:showResultAfterUserFinishesExam')}.</span>}
                                                {item.show_correct_answer == true && <span className="teacher-name">{t('exam:showCorrectAnswerAfterSubmittingAnswer')}.</span>}
                                                {item.skip_question == true && <span className="teacher-name">{t('exam:allowUserToSkipQuestions')}.</span>}
                                                <br />
                                            </div>
                                        </div>
                                        <hr />
                                    </Fragment>
                                )}
                                {item.is_online == true && this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                    <Fragment>
                                        <div className="row">
                                            <label className="form-title col-md-5 float-left">{t('exam:questionbank:categoryTitle')}</label>
                                            <div className="col-md-7 float-left">
                                                <span className="teacher-name">{item.question_bank.title}</span>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </form>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <button id={'close_info_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:addexam')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('exam:examDate')}*</label>
                                        <Select name="exam_date" options={this.state.date} placeholder={t('exam:selectExamDatePlaceholder')} searchable={true} value={this.state.exam_date} onChange={this._selectExamDate} />
                                        {this.state.errors.exam_date && <label className="error text-danger">{this.state.errors.exam_date}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('exam:subject')} *</label>
                                        <Select name="subject" options={this.state.subject} placeholder={t('exam:selectSubjectPlaceholder')} searchable={true} value={this.state.selectedSubject} onChange={this._selectSubject} />
                                        {this.state.errors.selectedSubject && <label className="error text-danger">{this.state.errors.selectedSubject}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:startTime')} *</label>
                                        <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('exam:selectStartTime')} dateFormat="yyyy-MM-dd" className="form-control" dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} defaultValue={this.state.start_time} selected={this.state.start_time} onChange={this._setStartTime} />
                                        {this.state.errors.start_time && <label className="error text-danger">{this.state.errors.start_time}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:endTime')} *</label>
                                        <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('exam:selectEndTime')} dateFormat="yyyy-MM-dd" className="form-control" dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} defaultValue={this.state.end_time} selected={this.state.end_time} onChange={this._setEndTime} />
                                        {this.state.errors.end_time && <label className="error text-danger">{this.state.errors.end_time}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:fullMarks')} *</label>
                                        <Input name="full_mark" onChange={(key, value) => this.changeFullMarks(key, value)} placeholder={t('exam:fullMarks')} value={this.state.full_mark} />
                                        {this.state.errors.full_mark && <label className="error text-danger">{this.state.errors.full_mark}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:passMarks')} *</label>
                                        <Input name="pass_mark" onChange={this._handleChange} placeholder={t('exam:passMarks')} value={this.state.pass_mark} />
                                        {this.state.errors.pass_mark && <label className="error text-danger">{this.state.errors.pass_mark}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="form-check">
                                            <Checkbox id="is_online" onChange={this._handleChange} className="" name="is_online" value={this.state.is_online} />
                                            <label className="form-check-label form-title small-checkbox" htmlFor="is_online">
                                                {t('exam:onlineexam')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.is_online == true && (
                                    <div className="add-option-exam">
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('exam:selectQuestionBank')} *</label>
                                                <i>
                                                    <small>[ {t('exam:questionMsg')} ]</small>
                                                </i>
                                                <Select name="question_bank" options={this.state.filtered_question_bank} placeholder={t('exam:selectQuestionBank')} searchable={true} value={this.state.selectedQuestionBank} onChange={this._selectQuestionBank} />
                                                {this.state.online_errors.selectedQuestionBank && <label className="error text-danger">{this.state.online_errors.selectedQuestionBank}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <div className="form-group form-check">
                                                    <Checkbox id="negative_marking" onChange={this._handleChange} className="" name="negative_marking" value={this.state.negative_marking} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="negative_marking">
                                                        {t('exam:negativeMarking')}
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-6 float-left form-group">
                                                <div className="form-group form-check">
                                                    <Checkbox id="show_result" onChange={this._handleChange} className="" name="show_result" value={this.state.show_result} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="show_result">
                                                        {t('exam:showResultAfterExamCompleted')}
                                                    </label>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <div className="form-group form-check">
                                                    <Checkbox id="show_correct_answer" onChange={this._handleChange} className="" name="show_correct_answer" value={this.state.show_correct_answer} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="show_correct_answer">
                                                        {t('exam:showCorrectAnswerAfterSubmittingAnswer')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 float-left form-group">
                                                <div className="form-group form-check">
                                                    <Checkbox id="skip_question" onChange={this._handleChange} className="" name="skip_question" value={this.state.skip_question} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="skip_question">
                                                        {t('exam:allowToSkipQuestions')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.exam.view}>{t('exam:exam:categoryTitle')} &gt; </a>
                            </li>
                            {this.state.exam != null && this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                <li>
                                    <a href={route.examination.exam.routine.menu + '/' + this.state.exam.id}>{this.state.exam.title} &gt; </a>
                                </li>
                            )}
                            {this.state.exam != null && (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <li>{this.state.exam.title}</li>}
                            {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && this.state.activeClass != null && <li>{this.state.activeClass.name}</li>}
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                {this.state.activeClass != null && <span className="sub-main-title">{this.state.activeClass.name}</span>}
                                <h5 className="main-title heading5">{this.state.exam != null ? this.state.exam.title : t('sidebar:exam')}:</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <Fragment>
                                    {this.state.exam != null && this.state.exam.status != Status.ACTIVE && (
                                        <div className="col-md-7 calendar-module float-left text-right">
                                            <div className="display-inline-block">
                                                <Button modal="modal_add" type="button" text={t('addBtn')} main={true} />
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="event-accordion">
                        <div className="role-pop-head">
                            <ul className="nav" id="myTab" role="tablist">
                                {this.getDate()}
                            </ul>
                        </div>
                        <div className="role-pop-content">
                            <div className="tab-content" id="myTabContent">
                                {this.getSchedule()}
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                    <Fragment>
                        {this.getAddModal()}
                        {this.getDeleteModal()}
                    </Fragment>
                )}
                {this.getInfoModal()}
                {this.getExamModal()}
            </div>
        )
    }
}

const RoutineSchedule = withTranslation()(connect(mapStateToProps)(RoutineScheduleComponent))

export { RoutineSchedule }
