import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addStudent, viewStudent, activeStudentCategory, getSection, findUser } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    email: String,
    student: Array,
    data: Array,
    loading: Boolean,
    dob: String,
    joining_date: String,
    errors: Object,
    students: Array,
    section_id: integer,
    section: Object,
}

export class AbstractStudentAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: null,
            loading: false,
            joining_date: null,
            dob: null,
            email: null,
            section: null,
            section_id: this.props.match.params.section_id,
            errors: {
                name: null,
                dob: null,
                joining_date: null,
            },
            loadingText: 'Saving',
            selectedStudentCategory: null,
            student_category: [],
            students: [],
            roll_no: null,
            roll_no_required: false,
            roll_number_validationAry: [],
            roll_number_validation_msg: null,
            student_regcode: '',
            student_regcode_error: null,
            found_student: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this.findUserByRegCode = this.findUserByRegCode.bind(this)
        this.clearRegData = this.clearRegData.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadStudentCategory()
        this.loadSection()
    }

    clearRegData(user_type) {
        if (user_type == 'student') {
            this.setState({
                student_regcode: '',
                student_regcode_error: null,
                found_student: false,
                name: '',
                email: '',
                dob: null,
                stringDob: '',
                joining_date: null,
            })
        }
    }

    findUserByRegCode(user_type) {
        const { t } = this.props
        if (this.state.student_regcode == null) {
            this.setState({
                student_regcode_error: t('toaster:required'),
            })
            return
        }
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Searching')

        findUser(this.state.student_regcode, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response) {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    const user = response.data.user
                    this.setState({
                        name: user.name,
                        email: user.email,
                        dob: moment(user.dob).toDate(),
                        stringDob: user.dob,
                        joining_date: moment(user.joining_date).toDate(),
                        roll_no: user.user_school.roll_no,
                        found_student: true,
                        selectedStudentCategory: user.user_school.users_category_id,
                    })
                } else {
                    toastMessage('error', response.message)
                    this.setState({ found_student: false })
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            }
        })
    }

    loadSection() {
        getSection(this.state.section_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let section = null
            let classes = null
            let roll_number_validationAry = []
            if (response.code == API_SUCCESS) {
                section = response.data.section
                roll_number_validationAry = response.data.roll_number
                if (section.roll_type == 0) {
                    this.setState({
                        roll_no_required: true,
                    })
                }
                this.setState({
                    section,
                    roll_number_validationAry,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadStudentCategory() {
        activeStudentCategory(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let student_category = []
                response.data.user_category.map((item, index) => {
                    let student_category_data = {}
                    student_category_data['value'] = item.id
                    student_category_data['label'] = item.name + ' - ' + item.code
                    student_category.push(student_category_data)
                })
                this.setState({ student_category })
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        let hasError = false
        const dob = moment(this.state.dob)
        const joining_date = moment(this.state.joining_date)
        if (joining_date.diff(dob) < 0) {
            toastMessage('error', t('toaster:joinDateLessThanDOB'))
            return
        }
        if (this.state.roll_no_required) {
            if (this.state.roll_no == null) {
                this.setState({ roll_number_validation_msg: t('toaster:required') })
                return
            }
            if (this.state.roll_no != this.state.old_roll_no) {
                const roll_noAry = this.state.roll_number_validationAry
                //validate RollNumber
                if (this.state.roll_no < 1) {
                    this.setState({
                        roll_number_validation_msg: t('toaster:greaterThan0'),
                    })
                    return
                }
                if (roll_noAry.indexOf(this.state.roll_no) > -1) {
                    this.setState({ roll_number_validation_msg: t('toaster:duplicate') })
                    return
                }
            }
        }
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            let data = {
                name: this.state.name,
                dob: moment(this.state.dob).format('yyyy-MM-DD'),
                joining_date: moment(this.state.joining_date).format('yyyy-MM-DD'),
                section_id: this.state.section_id,
                users_category_id: this.state.selectedStudentCategory,
            }
            if (this.state.roll_no_required) {
                data['roll_no'] = this.state.roll_no
            }
            if (this.state.found_student) {
                data['reg_no'] = this.state.student_regcode
            } else {
                data['email'] = this.state.email
            }
            this._handleChange('loading', true)
            this._handleChange('loadingText', 'Saving')
            addStudent(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.user.student.view + '/' + this.state.section_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

const AbstractStudentAdd = withTranslation()(AbstractStudentAddComponent)

export { AbstractStudentAdd }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
