import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewParent, addParent, editParent, getStudent, toggleParent, findUser, resetPassword } from '~/services/api'
import { ClassDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as validation from '~/helper/constant/validation'
import { components } from 'react-select'
import { roll_type } from '~/helper/constant/roll_type'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    roll_type: String,
    student_id: Integer,
    errors: Object,
    Parent: Array,
    data: Array,
    classes: Object,
    loading: Boolean,
    loading_text: String,
    defaultRoll: Object,
    hasChildren: Boolean,
}

export class AbstractParentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            hasChildren: false,
            joining_date: null,
            joining_date_string: '',
            dob: null,
            data: [],
            dob_string: '',
            relation: '',
            section_id: this.props.match.params.section_id,
            student_id: this.props.match.params.student_id,
            student_name: '',
            loading: false,
            loading_text: 'Loading',
            class: null,
            found_parent: false,
            parent_regcode: '',
            parent_regcode_error: null,
            parentData: [],
            Parent: [
                {
                    name: {
                        field: 'text',
                        translate: 'name',
                        route: false,
                        sortable: true,
                    },
                    reg_no: {
                        field: 'text',
                        translate: 'student:student.regNo',
                        route: false,
                        sortable: true,
                    },
                    email: {
                        field: 'text',
                        translate: 'student:student.parent.add.emailLabel',
                        route: false,
                        sortable: true,
                    },
                    dob: {
                        field: 'date',
                        translate: 'student:student.parent.add.dobLabel',
                        route: false,
                        sortable: true,
                    },
                    join_date: {
                        field: 'date',
                        translate: 'student:student.parent.add.joinDateLabel',
                        route: false,
                        sortable: true,
                    },
                    relation: {
                        field: 'text',
                        translate: 'student:student.parent.add.reationLabel',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    info: {
                        field: 'button',
                        translate: 'Info',
                        route: true,
                        sortable: false,
                    },
                    action: {
                        field: 'button',
                        translate: 'student:student.action',
                        route: true,
                        sortable: false,
                    },
                },
            ],
            perrors: {
                email: null,
                name: null,
                relation: null,
                joining_date: null,
                dob: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this._onSave = this._onSave.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this.findUserByRegCode = this.findUserByRegCode.bind(this)
        this.clearRegData = this.clearRegData.bind(this)
    }

    componentDidMount() {
        this.loadStudent()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleParent(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadParent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    clearRegData(user_type) {
        if (user_type == 'parent') {
            this.setState({
                parent_regcode: '',
                parent_regcode_error: null,
                found_parent: false,
                name: '',
                email: '',
                parent_dob: null,
                stringDob: '',
                relation: '',
                hasChildren: false,
                perrors: {
                    email: null,
                    name: null,
                    relation: null,
                    joining_date: null,
                    dob: null,
                },
            })
        }
    }

    findUserByRegCode(user_type) {
        const { t } = this.props
        if (this.state.parent_regcode == null) {
            this.setState({
                parent_regcode_error: t('toaster:required'),
            })
            return
        }
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Searching')

        findUser(this.state.parent_regcode, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response) {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    const user = response.data.user
                    this.setState({
                        name: user.name,
                        email: user.email,
                        dob: moment(user.dob).toDate(),
                        dob_string: user.dob,
                        joining_date: moment(user.joining_date).toDate(),
                        joining_date_string: user.joining_date,
                        roll_no: user.user_school.roll_no,
                        found_parent: true,
                        selectedparentCategory: user.user_school.users_category_id,
                    })
                } else {
                    toastMessage('error', response.message)
                    this.setState({ found_parent: false })
                }
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const dob = moment(this.state.dob)
        const joining_date = moment(this.state.joining_date)
        if (joining_date.diff(dob) < 0) {
            toastMessage('error', t('toaster:joinDateLessThanDOB'))
            return
        }
        const perrors = validation.required(Object.keys(this.state.perrors), this.state)
        this.setState({ perrors })
        const validate = Object.keys(perrors).filter((item) => perrors[item] != null)
        if (validate.length == 0) {
            let data = {
                name: this.state.name,
                joining_date: moment(this.state.joining_date).format('yyyy-MM-DD'),
                dob: moment(this.state.dob).format('yyyy-MM-DD'),
                relation: this.state.relation,
                student_id: this.state.student_id,
                hasChildren: this.state.hasChildren,
            }
            if (this.state.found_parent) {
                data['reg_no'] = this.state.parent_regcode
            } else {
                data['email'] = this.state.email
            }

            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            addParent(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState({
                        name: '',
                        dob: '',
                        email: '',
                        relation: '',
                        found_parent: false,
                        joining_date: null,
                        joining_date_string: '',
                        dob: null,
                        dob_string: '',
                        parent_regcode: '',
                        parent_regcode_error: null,
                        hasChildren: false,
                    })
                    $('#close_add').trigger('click')
                    this.loadParent()
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ perrors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    loadStudent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getStudent(this.state.student_id, this.props.token).then((response) => {
            this._handleChange('loading', true)
            if (response.code == API_SUCCESS) {
                const item = response.data.student

                this.setState(
                    {
                        student_name: item.user.name,
                    },
                    () => this.loadParent()
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _onEdit(e) {
        const { t } = this.props
        e.preventDefault()
        const id = e.target.getAttribute('link')
        if (this.state.relation == '' || this.state.relation == null) {
            this.setState({
                perrors: {
                    relation: t('toaster:required'),
                    name: null,
                    joining_date: null,
                    dob: null,
                },
            })
            return
        }

        let data = {
            relation: this.state.relation,
            student_id: this.state.student_id,
        }

        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        editParent(id, data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    name: '',
                    dob: '',
                    email: '',
                    relation: '',
                    found_parent: false,
                    joining_date: null,
                    joining_date_string: '',
                    dob: null,
                    dob_string: '',
                    parent_regcode: '',
                    parent_regcode_error: null,
                })
                $('#close_edit_' + id).trigger('click')
                setTimeout(this.loadParent(), 2000)
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    loadParent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewParent(this.state.student_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let Parent = []
            let parentData = null
            Parent.push(this.state.Parent[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                parentData = response.data.student.parents
                if (response.data.student.parents) {
                    response.data.student.parents.map((item, index) => {
                        const data = item
                        let text = null
                        const roll_no = roll_type.find((item) => item.value == data.roll_type)
                        if (data.user_school.status == Status.ACTIVE) {
                            text = {
                                text: 'Active',
                                translate: 'statusActive',
                                badge: 'table-green',
                                modal: 'modal_Parent_' + data.user_school.id,
                            }
                        } else {
                            text = {
                                text: 'Inactive',
                                translate: 'statusInactive',
                                badge: 'table-red',
                                modal: 'modal_Parent_' + data.user_school.id,
                            }
                        }
                        const tableData = {
                            name: {
                                text: data.user_school.user.name,
                            },
                            reg_no: {
                                text: data.user_school.user.reg_no,
                            },
                            email: {
                                text: data.user_school.user.email,
                            },
                            dob: {
                                text: data.user_school.user.dob,
                            },
                            join_date: {
                                text: data.user_school.joining_date,
                            },
                            relation: {
                                text: data.relation,
                            },
                            status: text,
                            info: {
                                text: 'view',
                                translate: 'viewBtn',
                                modal: 'modal_info_' + index,
                            },
                            action: {
                                text: 'Edit',
                                translate: 'EditBtn',
                                modal: 'modal_Parent_edit_' + data.user_school.id,
                            },
                        }
                        dataList.push(data)
                        Parent.push(tableData)
                    })
                }
            }
            this.setState(
                {
                    data: dataList,
                    Parent,
                    parent_regcode: '',
                    parentData,
                    name: '',
                    code: '',
                    roll_type: '',
                },
                () => {
                    this.clearState()
                }
            )
        })
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')
            if (id != undefined) {
                const name = component.state.parentData[id].user_school.user.name
                const email = component.state.parentData[id].user_school.user.email
                const relation = component.state.parentData[id].relation
                const dob = component.state.parentData[id].user_school.user.dob
                const joining_date = component.state.parentData[id].user_school.joining_date
                component._handleChange('name', name)
                component._handleChange('email', email)
                component._handleChange('relation', relation)
                component.setState({
                    name,
                    email,
                    relation,
                    joining_date: moment(joining_date).toDate(),
                    joining_date_string: joining_date,
                    dob: moment(dob).toDate(),
                    dob_string: dob,
                })
            } else {
                component.setState({
                    name: '',
                    email: '',
                    parent_regcode: '',
                    relation: '',
                    joining_date: null,
                    joining_date_string: '',
                    dob: null,
                    dob_string: '',
                })
            }
            const errors = {
                name: null,
                code: null,
                roll_type: null,
            }
            component._handleChange('errors', errors)
        })
    }
}

const AbstractParent = withTranslation()(AbstractParentComponent)

export { AbstractParent }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
