import React, { Component } from 'react'
import { AbstractLoaderComponent } from './abstract'
import Loader from 'react-loader-spinner'

export class Loading extends AbstractLoaderComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getLoader() {
        if (this.state.status) {
            return (
                <div className="loader-container">
                    <Loader type="ThreeDots" color="#7d56aa" height={50} width={50} active={this.state.status} />
                    <p>{this.props.text}</p>
                </div>
            )
        } else {
            return <div></div>
        }
    }

    render() {
        return this.getLoader()
    }
}
