import React, { Component, Fragment } from 'react'
import * as SVG from '~/assets/svg'

export class Folder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            folderName: props.folderName,
            folderUrl: props.folderUrl,
            privacy: props.privacy ? props.privacy : false,
            index: props.index,
        }
    }

    privacyIcon() {
        return <div className="privacy">{SVG.privacyicon()}</div>
    }

    render() {
        return (
            <div className="drive-list" onClick={() => (window.location = this.props.folderUrl)}>
                <a href={this.props.folderUrl} className="folder">
                    {SVG.foldericon()}
                </a>
                <div className="overflow-hidden">
                    <a href={this.props.folderUrl} className="drive-name">
                        {this.props.folderName}
                    </a>
                </div>
                {this.props.privacy && this.privacyIcon()}
            </div>
        )
    }
}
