import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniYearbookViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Input, Button } from '~/components/form'
import UserImg from '~/assets/images/user.png'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import { Alumni_Designation } from '../../../helper/constant/alumni_category'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'

class AlumniYearbookViewComponent extends AbstractAlumniYearbookViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="alumniYearbook" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{t('alumni:batch')}</span>
                                <h5 className="main-title heading5">{this.state.alumniCategory != null ? this.state.alumniCategory.name : ''}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="y-search-wrap">
                        <form className="main-search alumni-main-search">
                            <Input name="search" placeholder={t('alumni:searchPlaceholder')} type="text" value="" onChange={(key, value) => this.filterAlumni(value)} />
                            <div className="search-icon">{SVG.tablesearch()}</div>
                        </form>
                    </div>
                    <div className="alumni-dashboard">
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <div className="row">
                                        {this.state.filteredAlumniList.length > 0 && (
                                            <Fragment>
                                                {this.state.filteredAlumniList.map((item) => {
                                                    const name = item.user_school.user.name != null ? item.user_school.user.name : item.user_school.user.reg_no
                                                    const company = item.alumni_company != null ? item.alumni_company.name : t('common:others')
                                                    const designation = item.alumni_designation != null ? item.alumni_designation.name : t('common:others')
                                                    const profile = item.user_school.user.user_detail != null ? (item.user_school.user.user_detail.profile != null ? item.user_school.user.user_detail.profile : null) : null
                                                    return (
                                                        <div className="col-sm-3 col-md-3">
                                                            <a href={route.user_profile + '/' + item.user_school.user.id}>
                                                                <div className="alumni-box ">
                                                                    {profile != null && <img src={profile} alt="post-user" />}
                                                                    {profile == null && <div className="alumni-profile-letter">{name.charAt(0)}</div>}
                                                                    <div className="mt-3">
                                                                        <h5>{name}</h5>
                                                                        <p>{company}</p>
                                                                        <span>{designation}</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </Fragment>
                                        )}
                                        {this.state.filteredAlumniList.length == 0 && (
                                            <p className="no-data">
                                                {SVG.nodata()}
                                                {t('common:table.nodata')}.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AlumniYearbookView = withTranslation()(connect(mapStateToProps)(AlumniYearbookViewComponent))

export { AlumniYearbookView }
