import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractPackageEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import * as modules from '~/helper/constant/modules'
import { Loading } from '~/components/loader'
class PackageEditComponent extends AbstractPackageEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectCurrency = this._selectCurrency.bind(this)
    }

    _selectCurrency(data) {
        this._handleChange('currency', data.value)
        this._handleChange('defaultCurrency', data)
    }

    getAccessManagement() {
        const access = modules.access_management.map((item, index) => {
            let value = false
            if (this.state.access.includes(item.value)) {
                value = true
            }
            return (
                <div key={index} className="col-md-4 col-sm-6 float-left access-status">
                    <div className="security-list">
                        <label className="form-title">{item.label}</label>
                        <div className="tog-button">
                            <div className="button-check r" id="button-2">
                                <Checkbox onChange={this._selectModules} name={item.code} className="checkbox-form checkbox" value={value} />
                                <div className="knobs"></div>
                                <div className="layer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return access
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="package" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.package.view}>Packages &gt; </a>
                            </li>
                            <li>Edit</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Edit Package:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title">Package Name *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder="Package Name" type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title">Package Code *</label>
                                    <Input name="code" disabled="disabled" placeholder="Package Code" type="text" value={this.state.code} />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                                <div className="col-sm-3 float-left form-group main-select">
                                    <label className="form-title">Currency *</label>
                                    <Select
                                        name="currency"
                                        options={currency}
                                        onChange={this._selectCurrency}
                                        placeholder="Currency"
                                        searchable={true}
                                        value={this.state.defaultCurrency}
                                        defaultValue={currency[31]}
                                        // onChange={this.changeSortBy}
                                    />
                                    {this.state.errors.currency && <label className="error text-danger">{this.state.errors.currency}</label>}
                                </div>
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title">Price *</label>
                                    <Input name="price" onChange={this._handleChange} placeholder="Price"  value={this.state.price} />
                                    {this.state.errors.price && <label className="error text-danger">{this.state.errors.price}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">Student Limit *</label>
                                    <Input name="student" onChange={this._handleChange} placeholder="Student Limit"  value={this.state.student} />
                                    {this.state.errors.student && <label className="error text-danger">{this.state.errors.student}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">Staff Limit *</label>
                                    <Input name="teacher" onChange={this._handleChange} placeholder="Teacher Limit"  value={this.state.teacher} />
                                    {this.state.errors.teacher && <label className="error text-danger">{this.state.errors.teacher}</label>}
                                </div>
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">Storage Limit *</label>
                                    <Input name="storage" onChange={this._handleChange} placeholder="Storage Limit"  value={this.state.storage} />
                                    {this.state.errors.storage && <label className="error text-danger">{this.state.errors.storage}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group form-check ml-3">
                                    <Checkbox id="show" onChange={this._handleChange} name="show" value={this.state.show} />
                                    <label className="form-title small-checkbox mt-0">Display on homepage *</label>
                                </div>
                            </div>
                            <hr />
                            <div className="main-head-option">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left">
                                        <h5>Access Modules</h5>
                                        {this.state.errors.access && <label className="error text-danger">{this.state.errors.access}</label>}
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">{this.getAccessManagement()}</div>
                            <hr />
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text="Update" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const PackageEdit = connect(mapStateToProps)(PackageEditComponent)

export { PackageEdit }
