import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getProfile, viewAcademic, getSchoolPackage } from '~/services/api'
import { UserDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { APP_URL } from '~/helper/constant/config'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { signin, currentSchool, packageAdd, selectChildren, alumni_page, school_page } from '~/store'
import * as UserType from '~/helper/constant/user_type'
import { getAcademic } from '../../services/api/admin/academic_year'
import { redirect } from '~/helper/function/util'

type Props = {
    dispatch: Dispatch<any>,
    token: String,
}

type State = {
    loading: Boolean,
    role: String,
    userType: String,
}

export class AbstractAccountComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this.getUser = this.getUser.bind(this)
        this.getAcademic = this.getAcademic.bind(this)
        this.loadPackage = this.loadPackage.bind(this)
    }

    componentDidMount() {
        this.getUser()
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    getAcademic() {
        this._handleChange('loading', true)
        viewAcademic(this.props.match.params.id).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (response.data.academic_year.length == 0) {
                    redirect(route.admin.setup, this.props.history)
                } else {
                    if (this.state.schoolCount > 1) {
                        redirect(route.my_school, this.props.history)
                    } else {
                        redirect(route.activity, this.props.history)
                    }
                }
            }
        })
    }

    loadPackage() {
        this._handleChange('loading', true)
        getSchoolPackage(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const packages = response.data.package
                this.props.dispatch(packageAdd(packages.access))
            }
        })
    }

    getUser() {
        this._handleChange('loading', true)
        getProfile(this.props.match.params.id).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const data = {
                    user: response.data.user,
                    token: this.props.match.params.id,
                }
                this.props.dispatch(signin(data))
                const user_school = response.data.user.user_school
                let userType = null
                let school = null
                if (typeof document != 'undefined') {
                    let domain = window.location.host.split(':')
                    domain = domain[0].split('.')
                    user_school
                        .filter((item) => {
                            if (item.school.domain == domain[0] || item.school.sub_domain == domain[0]) {
                                return true
                            }
                        })
                        .map((item) => {
                            userType = item.user_type
                            school = item.school
                            if (userType == UserType.Alumni) {
                                this.props.dispatch(alumni_page())
                            } else {
                                this.props.dispatch(school_page())
                            }
                            this.props.dispatch(currentSchool(item))
                            if (item.user_type === UserType.Parents) {
                                if (item.student.length > 0) {
                                    this.props.dispatch(selectChildren(item.student[0].student_id))
                                }
                            }
                            this.loadPackage(item.school.package_id)
                        })
                } else {
                    userType = user_school[0].user_type
                    school = user_school[0].school
                    this.props.dispatch(currentSchool(user_school[0]))
                    if (user_school[0].user_type === UserType.Parents) {
                        if (user_school[0].student.length > 0) {
                            this.props.dispatch(selectChildren(user_school[0].student[0].student_id))
                        }
                    }
                    this.loadPackage(user_school[0].school.package_id)
                }
                this.setState(
                    {
                        userType,
                        school,
                        schoolCount: user_school.length,
                    },
                    () => {
                        if (userType == UserType.Admin) {
                            if (school.setup == false) {
                                redirect(route.admin.setup, this.props.history)
                            } else {
                                this.getAcademic()
                            }
                        } else {
                            if (user_school.length > 1) {
                                redirect(route.my_school, this.props.history)
                            } else {
                                redirect(route.activity, this.props.history)
                            }
                        }
                    }
                )
            } else {
                toastMessage('error', response.message)
                if (typeof document != 'undefined') {
                    window.location = APP_URL
                } else {
                    redirect(route.index, this.props.history)
                }
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
