import $ from 'jquery'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import { viewProductService, viewAccountTax } from '~/services/api'
import {type_list} from '~/helper/constant/category.js'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

const tableData = 
    {
        title: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'common:name',
        },
        Code: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'fee:code',
        },


        tax: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'income:tax',
        },
        category: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'accounting:category',
        },
        unit: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'course:unit',
        },

        type: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'attendance:type',
        },
        action: {
            field: 'button',
            route: true,
            sortable: false,
            translate: 'activity:action',
        },
    }


export class AbstractProductServiceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            type_list: type_list,
            taxes: [],
            expense: [tableData],
            income: [tableData],
            // tableStructure: [
            //     {
            //         title: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'common:name',
            //         },
            //         Code: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'fee:code',
            //         },
            //         sale_price: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'accounting:salePrice',
            //         },
            //         purchase_price: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'income:purchasePrice',
            //         },

            //         tax: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'income:tax',
            //         },
            //         category: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'accounting:category',
            //         },
            //         unit: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'course:unit',
            //         },
            //         quantity: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'income:quantity',
            //         },
            //         type: {
            //             field: 'text',
            //             route: false,
            //             sortable: true,
            //             translate: 'attendance:type',
            //         },
            //         action: {
            //             field: 'button',
            //             route: true,
            //             sortable: false,
            //             translate: 'activity:action',
            //         },
            //     },
            // ],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadTaxes()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadTaxes() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)

        viewAccountTax(this.props.token).then((response) => {
            let taxes = []
            if (response.code == API_SUCCESS) {
                response.data.taxes.map((item, index) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    }
                    taxes.push(data)
                })
                this.setState(
                    {
                        taxes,
                    },
                    this.loadProduct()
                )
            }
        })
    }

    loadProduct() {
        const { t } = this.props
        const expense = [tableData]
        const income = [tableData]

        viewProductService(this.props.token).then((response) => {
            console.log('Product service from abstract view', response.data)
            this._handleChange('loading', false)
            if (response.code === API_SUCCESS) {
                response.data.productServices.map((item, index) => {
                    const taxesIdsArray = item.tax_id.split(',').map((id) => parseInt(id.trim()))

                    // Filter taxes based on tax IDs
                    const filteredTaxes = this.state.taxes.filter((tax) => taxesIdsArray.includes(tax.value))
                    // Create an array of tax names
                    const taxNames = filteredTaxes.map((tax) => tax.label)

                    if (item.type == "Product"){
                        const type = this.state.type_list.find((list)=> list.value == item.type)

                    const rowData = {
                        title: { text: item.name },
                        Code: { text: item.sku },
                        tax: { text: taxNames.join(', ') }, // Join tax names if there are multiple taxes
                        category: { text: item.category ? item.category.name : '' },
                        unit: { text: item.unit_relation.name },
                        type: { text: type.label },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.productservice.edit + '/' + item.id,
                        },
                    }
                    income.push(rowData)
                }

                if (item.type == "Service"){
                    const type = this.state.type_list.find((list)=> list.value == item.type)

                const rowData = {
                    title: { text: item.name },
                    Code: { text: item.sku },
                    tax: { text: taxNames.join(', ') }, // Join tax names if there are multiple taxes
                    category: { text: item.category ? item.category.name : '' },
                    unit: { text: item.unit_relation.name },
                    type: { text: type.label },
                    action: {
                        text: 'Edit',
                        translate: 'common:EditBtn',
                        route: route.admin.accounting.productservice.edit + '/' + item.id,
                    },
                }
                expense.push(rowData)
            }
                })

                this.setState({
                    income,
                    expense,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
