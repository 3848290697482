import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, getCourseItems, editCourseModule, deleteCourseModule, addCourseModule, viewCourseModule, addCourseContent, editCourseContent, deleteCourseContent } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseModuleComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            title: null,
            file_message: null,
            description: null,
            coursedescription: null,
            coursetitle: null,
            start_time: null,
            selectedModule: null,
            selectedContent: null,
            subject: [],
            employee: [],
            course: [],
            courseModule: [],
            courseModuleData: [],
            student: [],
            modules: [],
            media: [],
            loading: true,
            loading_text: 'Loading',
            release_date: null,
            mediaAry: {},
            courseStartDate: moment().format('yyyy-MM-DD'),
            courseEndDate: moment().format('yyyy-MM-DD'),
            courseModuleStartDate: moment().format('yyyy-MM-DD'),
            after_completion: null,
            detail: {
                name: '',
                description: '',
                topic: '',
                privacy: '',
            },
            errors: {
                title: null,
                description: null,
                release_date: null,
            },
            courseerrors: {
                coursetitle: null,
                coursedescription: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourse = this.loadCourse.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
        this.openDetailProperties = this.openDetailProperties.bind(this)
        this.openAddModule = this.openAddModule.bind(this)
        this.openEditModule = this.openEditModule.bind(this)
        this.openAddCourse = this.openAddCourse.bind(this)
        this.openEditCourse = this.openEditCourse.bind(this)
        this.openDeleteCourse = this.openDeleteCourse.bind(this)
        this.addModule = this.addModule.bind(this)
        this.deleteModule = this.deleteModule.bind(this)
        this.deleteContent = this.deleteContent.bind(this)
        this.addCourse = this.addCourse.bind(this)
    }

    componentDidMount() {
        this.loadSubject()
        this.loadCourseModule()
        this.clearState()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    deleteModule(e) {
        e.preventDefault()
        deleteCourseModule(this.state.selectedModule, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.setState({ selectedModule: null })
                $('#close_add').trigger('click')
                this.loadCourseModule()
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    deleteContent() {
        deleteCourseContent(this.state.selectedContent, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.setState({ selectedModule: null, selectedContent: null })
                $('#close_delete').trigger('click')
                this.loadCourseModule()
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    addCourse(e) {
        e.preventDefault()
        let hasError = false
        const courseerrors = validation.required(Object.keys(this.state.courseerrors), this.state)
        this.setState({ courseerrors })
        const validate = Object.keys(courseerrors).filter((item) => courseerrors[item] != null)
        if (this.state.selectedContent == null) {
            if (this.state.media.length == undefined) {
                this.setState({ file_message: 'Please select a file' })
                return
            }
        }
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                name: this.state.coursetitle,
                description: this.state.coursedescription,
                course_module_id: this.state.selectedModule,
                start_time: moment(this.state.start_time).format('yyyy-MM-DD - hh:mm:ss a'),
            }
            if (this.state.selectedContent == null) {
                if (this.state.media.length != 0) {
                    const uploadedFiles = this.state.uploadedFiles
                    const media = this.state.media
                    let i = 0
                    uploadedFiles.map((item, index) => {
                        let content = {}
                        data['media'] = { name: media[i].name, data: media[i].data }
                    })
                }
                addCourseContent(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_course_add').trigger('click')
                        this.loadCourseModule()
                        // window.reload();
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            } else {
                editCourseContent(this.state.selectedContent, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        this.setState({ selectedModule: null, selectedContent: null })
                        $('#close_course_add').trigger('click')
                        this.loadCourseModule()
                        // window.reload();
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    addModule(e) {
        e.preventDefault()
        let hasError = false
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            let data = {
                name: this.state.title,
                description: this.state.description,
                release_date: moment(this.state.release_date).format('yyyy-MM-DD'),
                after_completion: this.state.after_completion ? this.state.after_completion.value : null,
                course_id: this.state.course.course.id,
            }
            if (this.state.selectedModule == null) {
                addCourseModule(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.loadCourseModule()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            } else {
                editCourseModule(this.state.selectedModule, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.setState({ selectedModule: null })
                        this.loadCourseModule()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    openAddModule() {
        this.setState(
            {
                title: '',
                description: '',
                uploadedFiles: [],
                media: {},
                mediaAry: [],
                courseModuleSelectFilter: this.state.courseModuleData,
                release_date: null,
                selectedModule: null,
                after_completion: null,
                errors: {
                    title: '',
                    description: '',
                    release_date: '',
                },
            },
            () => {
                $('#modal_drive_add').modal('show')
            }
        )
    }

    openEditModule(module_id) {
        let currentModule = this.state.courseModule.find((el) => el.id == module_id)
        const courseModuleSelect = this.state.courseModuleData.filter((el) => el.value != module_id)
        let afterCompletion = null
        if (currentModule.after_completion != null) {
            afterCompletion = courseModuleSelect.find((el) => el.value == currentModule.after_completion)
        }
        this.setState(
            {
                title: currentModule.name,
                description: currentModule.description,
                uploadedFiles: [],
                media: {},
                mediaAry: [],
                courseModuleSelectFilter: courseModuleSelect,
                release_date: currentModule.release_date ? moment(currentModule.release_date, 'yyyy-MM-DD').toDate() : null,
                after_completion: afterCompletion,
                selectedModule: module_id,
                errors: {
                    title: '',
                    description: '',
                },
            },
            () => {
                $('#modal_drive_add').modal('show')
            }
        )
    }

    openAddCourse(module_id) {
        const courseModuleStartDate = this.state.courseModule.filter((el) => el.id == module_id)
        this.setState(
            {
                courseModuleStartDate: moment(courseModuleStartDate[0].release_date).format('yyyy-MM-DD'),
                coursetitle: '',
                coursedescription: '',
                start_time: '',
                uploadedFiles: [],
                media: {},
                mediaAry: [],
                file_message: null,
                selectedModule: module_id,
                selectedContent: null,
                contenterrors: {
                    coursetitle: '',
                    coursedescription: '',
                },
            },
            () => {
                $('#modal_course_add').modal('show')
            }
        )
    }

    openEditCourse(module_id, course_id) {
        let currentModule = this.state.courseModule.find((el) => el.id == module_id)
        if (currentModule.content) {
            let currentCourse = currentModule.content.find((el) => el.id == course_id)
            this.setState(
                {
                    courseModuleStartDate: moment(currentModule[0].release_date).format('yyyy-MM-DD'),
                    coursetitle: currentCourse.name,
                    coursedescription: currentCourse.description,
                    uploadedFiles: [],
                    media: {},
                    mediaAry: [],
                    file_message: null,
                    selectedContent: course_id,
                    selectedModule: module_id,
                    errors: {
                        coursetitle: '',
                        coursedescription: '',
                    },
                },
                () => {
                    $('#modal_course_add').modal('show')
                }
            )
        }
    }

    openDeleteCourse(module_id, course_id) {
        let currentModule = this.state.courseModule.find((el) => el.id == module_id)
        if (currentModule.content) {
            let currentCourse = currentModule.content.find((el) => el.id == course_id)
            this.setState(
                {
                    coursetitle: currentCourse.name,
                    coursedescription: currentCourse.description,
                    uploadedFiles: [],
                    media: {},
                    mediaAry: [],
                    file_message: null,
                    selectedContent: course_id,
                    selectedModule: module_id,
                    errors: {
                        coursetitle: '',
                        coursedescription: '',
                    },
                },
                () => {
                    $('#modal_class_delete').modal('show')
                }
            )
        }
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseItems(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    course: response.data.post,
                    courseStartDate: moment(response.data.post.from_date).format('yyyy-MM-DD'),
                    courseEndDate: moment(response.data.post.to_date).format('yyyy-MM-DD'),
                })
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseModule(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                let courseModuleData = []
                courseModule.map((item, index) => {
                    const courseModuleSelect = {
                        value: item.id,
                        label: item.name,
                    }
                    courseModuleData.push(courseModuleSelect)
                })
                this.setState({
                    courseModule,
                    courseModuleData,
                })
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.props.match.params.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourse()
                        }
                    )
                }
            })
        } else {
            this.loadCourse()
        }
    }

    openDetailProperties(index) {
        const selectedCourse = this.state.course.filter((item) => item.subject_id == index)[0]
        this.setState({
            detail: {
                title: selectedCourse.title,
                description: selectedCourse.description,
                topic: selectedCourse.course.topic,
                createdOn: moment(selectedCourse.created_at).format('YYYY-MM-DD - hh:mm A'),
                privacy: selectedCourse.restriction == 1 ? 'Private' : 'Public',
            },
        })
        $('#details-course').modal('show')
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                // component.state.uploads.map((item, index) => {
                //   uploads.pop();
                // });
                // uploads.pop();
                // component._handleChange("uploads", uploads);
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('student', [])
                component._handleChange('employee', [])
                component._handleChange('isPrivate', true)
                component._handleChange('selected_subject', null)
                const errors = {
                    title: null,
                    description: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
