import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addCourse, viewCourse, viewCourseProgress, viewCourseContent, getCourseModuleItems, viewCourseModuleItems, getCourseItems, getSchoolUser, getSchoolUserProfile } from '~/services/api'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'
import { course_progress_constant } from '~/helper/constant/course_progress'

import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseInsightsUserDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            courseCount: 0,
            activeTab: 0,
            loading: true,
            loading_text: 'Loading',
            courseModule: [],
            courseContent: [],
            courseModuleData: [],
            class_id: this.props.match.params.id,
            course_id: this.props.match.params.course_id,
            module_id: this.props.match.params.module_id,
            course_progress: [],
            course: [],
            classes: [],
            subject: [],
            user: [],
            accessCount: 1,
            insight_details: [
                {
                    subject: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'exam:subject',
                    },
                    // content: {
                    //   field: "text",
                    //   route: false,
                    //   sortable: true,
                    // },
                    user_progress: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:user_progress',
                    },
                    feedback: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:feedback',
                    },
                    quiz: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'course:quiz',
                    },
                },
            ],
            course_details: [],
            selectedUser: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
    }

    componentDidMount() {
        this.loadSubject()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let insight_details = []
                let course_details = []
                insight_details.push(this.state.insight_details[0])
                const course_progress = response.data.course_progress
                const userData = []
                let moduleCourse = []
                const user = this.state.user
                this.state.courseModule.content.map((obj, i) => {
                    let progress = course_progress.filter((el) => el.course_content_id == obj.id)
                    const feedback = progress.length > 0 ? progress[0].feedback : 'N/A'
                    progress = progress.length > 0 ? progress[0].user_progress : 'N/A'
                    progress = progress == 2 ? 'Completed' : 'In Progress'
                    const tableData = {
                        subject: {
                            text: (
                                <span>
                                    <b>{obj.name}</b> <br /> {obj.description}
                                </span>
                            ),
                        },
                        user_progress: {
                            text: progress,
                        },
                        feedback: {
                            text: feedback,
                        },
                        quiz: {
                            text: 'view Result',
                            route: route.course.insights_quiz + '/' + this.props.match.params.id + '/' + this.props.match.params.course_id + '/' + this.props.match.params.module_id + '/' + obj.id + '/' + this.props.match.params.user_id,
                            translate: 'course:view_result',
                        },
                    }
                    moduleCourse.push(obj.id)
                    insight_details.push(tableData)
                })

                this.setState(
                    {
                        course_progress,
                        insight_details,
                        course_details: userData,
                    },
                    () => {}
                )
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseModuleItems(this.props.match.params.module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                const course_progress = this.state.course_progress
                const courseContent = []
                let inProgressUsers = []
                let courseModuleData = []

                courseModule.content.map((obj, index) => {
                    if (obj.course_module_id == this.state.module_id) {
                        courseContent.push(obj)
                    }
                })
                this.setState(
                    {
                        courseContent,
                        courseModule,
                    },
                    () => this.loadCourseProgress()
                )
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseModule()
                        }
                    )
                }
            })
        } else {
            this.loadCourseModule()
        }
    }
}

withTranslation()(AbstractCourseInsightsUserDetailsComponent)
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
