import $ from 'jquery'
import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as JOB_TYPE from '~/helper/constant/job_type'
import * as route from '~/helper/constant/route'
import * as USER_TYPE from '~/helper/constant/user_type'
import { convertADtoBS } from '../../../../helper/function'
import { AbstractAlumniJobViewComponent, mapStateToProps } from './abstract'

class AlumniJobViewComponent extends AbstractAlumniJobViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setDate = this.setDate.bind(this)
        this.setJobType = this.setJobType.bind(this)
        this.setNegotiation = this.setNegotiation.bind(this)
        this.setExperience = this.setExperience.bind(this)
        this.setCompany = this.setCompany.bind(this)
        this.setGroup = this.setGroup.bind(this)
        this.setDesignation = this.setDesignation.bind(this)
        this.clearState = this.clearState.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    _onSave(e) {
        e.preventDefault()
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_add').trigger('click')
            }
        })
    }
    setDate(data) {
        this._handleChange('due_date', data)
    }

    setNegotiation(data) {
        this._handleChange('selectedNegotiable', data)
        this._handleChange('isNegotiable', data.value)
    }

    setJobType(data) {
        this._handleChange('selectedType', data)
        this._handleChange('type', data.value)
    }

    setExperience(data) {
        this._handleChange('selectedExperience', data)
        this._handleChange('experience', data.value)
    }

    setCompany(data) {
        this._handleChange('selectedCompany', data)
        this._handleChange('alumni_company_id', data.value)
    }

    setGroup(data) {
        this._handleChange('selectedGroup', data)
        this._handleChange('alumni_group_id', data.value)
    }

    setDesignation(data) {
        this._handleChange('selectedDesignation', data)
        this._handleChange('alumni_designation_id', data.value)
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:addJobVacancy')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:jobTitle')} *</label>
                                            <Input name="title" value={this.state.title} onChange={this._handleChange} placeholder={t('alumni:jobTitle')} />
                                            {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:jobType')} *</label>
                                            <Select selected={this.state.type} onChange={this.setJobType} name="type" options={JOB_TYPE.jobTypeList} placeholder={t('alumni:jobType')} searchable={true} value={this.state.selectedType} />
                                            {this.state.errors.type && <label className="error text-danger">{this.state.errors.type}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:vacancyNumber')} *</label>
                                            <Input type="number" name="no_of_vacancy" value={this.state.no_of_vacancy} onChange={this._handleChange} placeholder={t('alumni:vacancyNumber')} min={1} max={100} />
                                            {this.state.errors.no_of_vacancy && <label className="error text-danger">{this.state.errors.no_of_vacancy}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:experience')} *</label>
                                            <Select name="experience" selected={this.state.experience} onChange={this.setExperience} options={JOB_TYPE.exprienceList} placeholder={t('alumni:experience')} searchable={true} value={this.state.selectedExperience} />
                                            {this.state.errors.experience && <label className="error text-danger">{this.state.errors.experience}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:salary')} *</label>
                                            <Input type="text" name="salary" value={this.state.salary} onChange={this._handleChange} placeholder={t('alumni:salary')} />
                                            {this.state.errors.salary && <label className="error text-danger">{this.state.errors.salary}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:negotiable')} *</label>
                                            <Select name="isNegotiable" selected={this.state.isNegotiable} onChange={this.setNegotiation} options={JOB_TYPE.negotiableList} placeholder={t('alumni:negotiable')} searchable={true} value={this.state.selectedNegotiable} />
                                            {this.state.errors.isNegotiable && <label className="error text-danger">{this.state.errors.isNegotiable}</label>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:company')} *</label>
                                            <Select name="alumni_company_id" selected={this.state.alumni_company_id} onChange={this.setCompany} options={this.state.companyListOption} placeholder={t('alumni:company')} searchable={true} value={this.state.selectedCompany} />
                                            {this.state.errors.alumni_company_id && <label className="error text-danger">{this.state.errors.alumni_company_id}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:faculty')} *</label>
                                            <Select name="alumni_group_id" selected={this.state.alumni_group_id} onChange={this.setGroup} options={this.state.groupListOption} placeholder={t('alumni:faculty')} searchable={true} value={this.state.selectedGroup} />
                                            {this.state.errors.alumni_group_id && <label className="error text-danger">{this.state.errors.alumni_group_id}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:designation')} *</label>
                                            <Select name="alumni_designation_id" selected={this.state.alumni_designation_id} onChange={this.setDesignation} options={this.state.designationListOption} placeholder={t('alumni:designation')} searchable={true} value={this.state.selectedDesignation} />
                                            {this.state.errors.alumni_designation_id && <label className="error text-danger">{this.state.errors.alumni_designation_id}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('common:duedate')} *</label>
                                            <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.due_date} onChange={this.setDate} placeholderText={t('common:duedate')} />
                                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.due_date != '' ? (convertADtoBS(this.state.due_date) != null ? convertADtoBS(this.state.due_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                            {this.state.errors.due_date && <label className="error text-danger">{this.state.errors.due_date}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:jobDescription')} *</label>
                                            <TextArea value={this.state.description} onChange={this._handleChange} name="description" type="text" placeholder={t('alumni:jobDescription')} />
                                            {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-title text-left">{t('alumni:jobSpecification')}*</label>
                                            <TextArea value={this.state.job_specification} onChange={this._handleChange} name="job_specification" type="text" placeholder={t('alumni:jobSpecification')} />
                                            {this.state.errors.job_specification && <label className="error text-danger">{this.state.errors.job_specification}</label>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('common:addBtn')} main={true} onClick={this._onSave} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t, school } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="alumniJobVacancy" />
                <div className="main-content">
                    <Header />

                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.alumni.job.menu}>{t('alumni:jobs')} > </a>
                            </li>
                            <li>{t('alumni:vacany')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading">{t('alumni:vacany')}:</h5>
                            </div>
                            {(this.props.school.user_type == USER_TYPE.Admin || this.props.school.user_type == USER_TYPE.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <button data-toggle="modal" data-target="#modal_add" className="main-button">
                                            {t('alumni:addJobVacancy')}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="alumni-dashboard">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="custom-card-box">
                                    <div className="alumni-filter">
                                        <div className="alumni-filter-wrap">
                                            <p className="heading">{t('alumni:searchmsg2')}</p>
                                            <ul className="filter-list">
                                                <div id="batchContent">
                                                    {ALUMNI_CATEGORY.categoryList.map((item, index) => {
                                                        let categoryList = []
                                                        if (item.value == ALUMNI_CATEGORY.Alumni_Designation) {
                                                            categoryList = this.state.designationList
                                                        } else if (item.value == ALUMNI_CATEGORY.Alumni_Group) {
                                                            categoryList = this.state.groupList
                                                        } else if (item.value == ALUMNI_CATEGORY.Alumni_Company) {
                                                            categoryList = this.state.companyList
                                                        }
                                                        if (item.value != ALUMNI_CATEGORY.Alumni_Batch) {
                                                            return (
                                                                <div className="filter-accordion">
                                                                    <li>
                                                                        <button className={index == 0 ? 'down-arrow float-right' : 'down-arrow float-right collapsed'} data-parent="#batchContent" data-toggle="collapse" data-target={'#batchCollapse' + index} aria-expanded={index == 0 ? 'true' : 'false'} aria-controls={'batchCollapse' + index}>
                                                                            {t(item.translate)}
                                                                        </button>
                                                                    </li>
                                                                    <div id={'batchCollapse' + index} className={index == 0 ? 'collapse-body collapse show' : 'collapse-body collapse'} data-parent="#batchContent">
                                                                        {this.state.alumniCategoryList
                                                                            .filter((category) => category.type == item.value)
                                                                            .map((category, index) => {
                                                                                return (
                                                                                    <div className="filter-body">
                                                                                        <input type="checkbox" name="" checked={categoryList.includes(category.id)} onChange={() => this.loadFilter(item.value, category.id)} />
                                                                                        <p>{category.name}</p>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                                <div className="form-button text-left">
                                                    <button
                                                        type="button"
                                                        className="main-button"
                                                        onClick={() => {
                                                            this.loadVacancy()
                                                        }}
                                                    >
                                                        {t('common:searchBtn')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="cancel-button"
                                                        onClick={() => {
                                                            this.clearFilter()
                                                        }}
                                                    >
                                                        {t('alumni:clear')}
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="yearbook-course-wrap">
                                    {(this.state.companyList.length > 0 || this.state.designationList.length > 0 || this.state.groupList.length > 0) && (
                                        <div className="second-title">
                                            <h6>{t('common:searchBtn')}</h6>
                                            <div className="w-100 pl-3">
                                                <span className="title-line"></span>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.companyList.length == 0 && this.state.designationList.length == 0 && this.state.groupList.length == 0 && (
                                        <div className="second-title">
                                            <h6>{t('alumni:latestVacancy')}</h6>
                                            <div className="t-line pl-3">
                                                <span className="title-line"></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="y-search-wrap">
                                    <form className="main-search alumni-main-search">
                                        <Input name="search" placeholder={t('alumni:searchPlaceholder')} type="text" onChange={(key, value) => this.filterJob(value)} />
                                        <div className="search-icon">{SVG.tablesearch()}</div>
                                    </form>
                                </div>
                                <div>
                                    <div className="row">
                                        {this.state.filteredjobVacancyList.map((item) => {
                                            const profile = item.alumni_company.profile
                                            const name = item.alumni_company.name.charAt(0)
                                            const today = moment()
                                            const due_date = moment(item.post.to_date)
                                            const status = item.applicant_list.includes(this.props.school.id) ? 'Applied' : item.viewed_list.includes(this.props.school.id) ? 'Viewed' : null
                                            if (today.isSameOrBefore(due_date)) {
                                                return (
                                                    <div className="col-sm-6">
                                                        <a href={route.alumni.job.detail + '/' + item.id}>
                                                            <div className="alumni-box">
                                                                {status != null && (
                                                                    <div className="alumni-job-status">
                                                                        <span className={'table-status'}>{t('alumni:' + status)}</span>
                                                                    </div>
                                                                )}
                                                                <div className="vacancy-box">
                                                                    <div className="vacancy-logo small">
                                                                        {profile != null && <img src={profile} alt="post-user" />}
                                                                        {profile == null && <div className="alumni-job">{name.charAt(0)}</div>}
                                                                    </div>
                                                                    <div className="vacancy-header">
                                                                        <h5>{item.post.title}</h5>
                                                                        <p>{item.alumni_company.name}</p>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="vacancy-box-due">
                                                                    <span>{item.alumni_designation.name}</span>
                                                                    <div className="vacancy-due-date">
                                                                        {t('alumni:expires')}: {moment(item.post.to_date).fromNow()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        })}
                                        {this.state.filteredjobVacancyList.length == 0 && (
                                            <p className="no-data">
                                                {SVG.nodata()}
                                                {t('common:table.nodata')}.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getAddModal()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component.setState({
                    title: '',
                    type: null,
                    selectedType: null,
                    isNegotiable: null,
                    selectedNegotiable: null,
                    experience: null,
                    selectedExperience: null,
                    alumni_company_id: null,
                    selectedCompany: null,
                    alumni_group_id: null,
                    selectedGroup: null,
                    alumni_designation_id: null,
                    selectedDesignation: null,
                    no_of_vacancy: '',
                    salary: '',
                    due_date: '',
                    description: '',
                    job_specification: '',
                    errors: {
                        title: null,
                        type: null,
                        isNegotiable: null,
                        experience: null,
                        alumni_company_id: null,
                        alumni_group_id: null,
                        alumni_designation_id: null,
                        no_of_vacancy: null,
                        salary: null,
                        due_date: null,
                        description: null,
                        job_specification: null,
                    },
                })
            }
        })
    }
}

const AlumniJobView = withTranslation()(connect(mapStateToProps)(AlumniJobViewComponent))

export { AlumniJobView }
