import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentStatementComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import Select from 'react-select'
import { Table } from '~/components/table'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import DatePicker from 'react-datepicker'
import { UserFilter } from '~/components/userfilter'
import { Loading } from '~/components/loader'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

class StudentStatementComponent extends AbstractStudentStatementComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="fee" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.student_fee.menu}>{t('fee:fee')} > </a>
                            </li>
                            <li>
                                <a href={route.student_fee.statement.search}>{t('common:searchBtn')} > </a>
                            </li>
                            <li>{t('fee:invoice')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title">{t('fee:invoice')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form fee-table">
                        <Table data={this.state.invoiceList} message="fee:nostatementmsg" />
                    </div>
                </div>
            </div>
        )
    }
}

const StudentStatement = withTranslation()(connect(mapStateToProps)(StudentStatementComponent))

export { StudentStatement }
