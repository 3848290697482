import React from 'react'
import {connect} from 'react-redux'
import { AbstractBankTransferComponent, mapStateToProps } from './abstract'
import {Header} from '~/components/header'
import * as route from '~/helper/constant/route'
import { Sidebar } from '~/components/sidebar'
import { withTranslation } from 'react-i18next'
import { Table } from '~/components/table'
import {Loading} from '~/components/loader'

class BankTransferViewComponent extends AbstractBankTransferComponent{
    constructor(props){
        super(props)
        this.state ={
            ...this.state,
        }

    }
    render(){
        const {t} = this.props
        return (
            <div className="main-page">
                <Sidebar id="banking" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>
                                    {t('sidebar:account') } &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li><a href={route.admin.accounting.banking.menu}>{t('banking:banking')} &nbsp; &gt; &nbsp;</a></li>
                            <li>{t('banking:bankbalancetransfer')}</li>
                        </ul>
                        <div className='row clearfix'>
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('banking:bankbalancetransfer')}</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.accounting.banking.transfer.add} className="main-button">{t('common:addBtn')}</a>
                                </div>
                            </div>
                        </div> 
                    </div>
                
                    <div className="main-form">
                        <Table data={this.state.tableStructure} />
                        
                        <br />
                    </div>
                </div>

            </div>
        )
    }
}
const BankTransferView = withTranslation()(connect(mapStateToProps)
(BankTransferViewComponent))

export {BankTransferView}
