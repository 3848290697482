import React,{ Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { viewBookShelf, toggleBookShelf } from '~/services/api'
import { toastMessage } from '~/helper/function/util'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}


export class AbstractShelfManagementComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            bookShelfDetail: null,
            tableData: [],
            tableStructure: [
                {
                    name: {
                        field: 'html',
                        route: false,
                        sortable: true,
                        translate: 'student:name',
                    },
                    code: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:code',
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                        translate: 'common:status',
                    },

                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.loadBookShelf()
    }
    

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(link){
        const{t} =this.props
        const component = this
        return new Promise(function(resolve, reject){
          component._handleChange("loading_text", "Loading")
          component._handleChange("loading", true)
          toggleBookShelf(link, component.props.token).then((response)=>{
            component._handleChange("loading", false)
            if(response.code == API_SUCCESS){
              toastMessage("success", response.message)
              component.loadBookShelf()
              resolve(true)
            }else{
              toastMessage('error', response.message)
              resolve(false)
            }
          })
  
        })
      }

    loadBookShelf(){
        this._handleChange('loading_text', "loading")
        this._handleChange("loading", true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        viewBookShelf(this.props.token).then((response)=>{
            this._handleChange("loading", false)
            if(response.code == API_SUCCESS){
                response.data.shelf.map((item, index)=>{
                    let text = null
                    if(item.status == Status.ACTIVE){
                        text={
                            text:"Active",
                            translate:"common:statusActive",
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    }else{
                        text={
                            text:"Inactive",
                            translate: 'common:statusInactive',
                            badge: 'table-red',
                            modal:'modal_class_' + index,
                        }
                    }
                    const tableDataShelf = {
                        name:{
                            text:'html',
                            html: (
                                <Fragment>
                                    <button
                                        type="button"
                                        className="table-check"
                                        onClick={() =>
                                            this.setState({
                                                bookShelfDetail: item,
                                            })
                                        }
                                    >
                                        {item.name}
                                    </button>
                                </Fragment>
                            ),
                        },
                        code:{
                            text:item.code,
                        },
                        status:text,
                        action:{
                            text:"Edit",
                            translate: 'common:EditBtn',
                            route: route.admin.library.shelfmanagement.edit + '/' + item.id,
                        }
                    }
                    tableData.push(item)
                    tableStructure.push(tableDataShelf)

                })
                this.setState({
                    tableData,
                    tableStructure

                })
            }



        })

    }


}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
