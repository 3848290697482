import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as route from '~/helper/constant/route'
import { Header } from '../../../../../components/header/header'
import { Loading } from '../../../../../components/loader/loader'
import { Sidebar } from '../../../../../components/sidebar/sidebar'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractIncomeSummaryComponent, mapStateToProps } from './abstract'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Income Summary',
        },
    },
}

const chart_data = {}
class IncomeSummaryComponent extends AbstractIncomeSummaryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setYear = this._setYear.bind(this)
        this._setUser = this._setUser.bind(this)
        this._setCategory = this._setCategory.bind(this)
    }

    _setYear(data) {
        this._handleChange('year', data)
    }

    _setUser(data) {
        this._handleChange('user', data)
    }
    _setCategory(data) {
        this._handleChange('category', data)
    }

    getRevenue() {
        const data = this.state.revenueList.map((item) => {
            const amountData = item.data
            return (
                <tr className="invoice_table_border">
                    <td>{item.category}</td>
                    {Object.keys(amountData).map((amount) => {
                        return (
                            <td>
                                <small>{this.props.school.currency}</small>
                                &nbsp;
                                {parseFloat(amountData[amount]).toFixed(2)}
                            </td>
                        )
                    })}
                </tr>
            )
        })
        return data
    }

    getInvoice() {
        if (this.state.invoiceList.length > 0) {
            const data = this.state.invoiceList.map((item) => {
                const amountData = item.data
                return (
                    <tr className="invoice_table_border">
                        <td>{item.category}</td>
                        {Object.keys(amountData).map((amount) => {
                            return (
                                <td>
                                    <small>{this.props.school.currency}</small>
                                    &nbsp;
                                    {parseFloat(amountData[amount]).toFixed(2)}
                                </td>
                            )
                        })}
                    </tr>
                )
            })

            return data
        } else {
            return (
                <tr className="invoice_table_border">
                    <td colSpan={13} className="text-center">
                        No data available
                    </td>
                </tr>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="report_accounting" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.reports.menu}>
                                    {t('reports:reports')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('reports:incomesummary')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('reports:incomesummary')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={this.props.school.short_code + '-' + t('reports:incomesummary')}
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="timetable-form mb-5">
                                    <div className="row ">
                                        <div className="col-md-3 header-filter">
                                            <label className="table-form-title">
                                                {t('academic:table.start date')} :
                                            </label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="yyyy"
                                                className="form-control"
                                                defaultValue={this.state.year}
                                                selected={this.state.year}
                                                onChange={this._setYear}
                                                showYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.year != ''
                                                        ? convertADtoBS(this.state.year) != null
                                                            ? convertADtoBS(this.state.year).slice(0, -6) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.year && (
                                                <label className="error text-danger">{this.state.errors.year}</label>
                                            )}
                                        </div>
                                        <div className="col-md-3 ">
                                            <label className="table-form-title">{t('attendance:type')} :</label>
                                            <Select
                                                name="category"
                                                options={this.state.categoryList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.category}
                                                onChange={this._setCategory}
                                            />
                                        </div>
                                        <div className="col-md-4 ">
                                            <label className="table-form-title">{t('gamification:user')} :</label>
                                            <Select
                                                name="user"
                                                options={this.state.userList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.user}
                                                onChange={this._setUser}
                                            />
                                        </div>
                                        <div className="col-md-2 tax-filter">
                                            <button className="main-button" onClick={() => this._onSearch()}>
                                                {t('attendance:filter')}
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="chart-content">
                                                <Line
                                                    options={options}
                                                    data={{
                                                        labels: this.state.monthList,
                                                        datasets: [
                                                            {
                                                                label: 'Income',
                                                                fill: true,
                                                                data: this.state.chartData,
                                                                borderColor: 'rgb(53, 162, 235)',
                                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                            },
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('reports:incomesummary')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                                {this.state.user != null &&
                                                                    this.state.user.value != '' && (
                                                                        <div className="invoice_detail">
                                                                            <strong>{t('income:customer')} : </strong>
                                                                            <br />
                                                                            {this.state.user.label}
                                                                        </div>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_dark">
                                                            <td colSpan={13}>
                                                                <strong>{t('income:income')}</strong>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_header_light">
                                                            <td>{t('alumni:category')}</td>
                                                            {this.state.monthList.map((item) => {
                                                                return <td>{item}</td>
                                                            })}
                                                        </tr>
                                                        {/*   <tr className="invoice_table_border">
                                                            <td colSpan={13}>
                                                                <strong>{t('income:revenue')}</strong>
                                                            </td>
                                                        </tr>
                                                        {this.getRevenue()}
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={13}>
                                                                <strong>{t('income:invoice')}</strong>
                                                            </td>
                                                        </tr> */}
                                                        {this.getInvoice()}
                                                        <tr className="invoice_table_header_dark">
                                                            <td colSpan={this.state.chartData.length == 0 ? 13 : 0}>
                                                                {t('fee:total')} {t('income:income')}
                                                            </td>
                                                            {this.state.chartData.map((item) => {
                                                                return (
                                                                    <td>
                                                                        <strong>
                                                                            <small>{this.props.school.currency}</small>
                                                                            &nbsp;
                                                                            {parseFloat(item).toFixed(2)}
                                                                        </strong>
                                                                    </td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const IncomeSummary = withTranslation()(connect(mapStateToProps)(IncomeSummaryComponent))

export { IncomeSummary }
