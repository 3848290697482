import moment from 'moment'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { redirect } from '~/helper/function'
import { toastMessage } from '~/helper/function/util'
import {
    activeClass,
    editCourseProgress,
    getCourseContentItems,
    getCourseModuleItems,
    getUserProgress,
    saveResult,
    saveUserProgress,
    viewCourseProgress,
} from '~/services/api'
import { DOMAIN, HTTP_PROTOCOL } from '~helper/constant/config'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseContentDetailsStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            videoTime: '00:00:00',
            currentVideoTime: '00:00:00',
            videoVolume: 1,
            isPlaying: false,
            post_id: 0,
            progress_id: 0,
            videoEnded: false,
            videoStartTime: this.props.match.params.videotime ? this.props.match.params.videotime : 0,
            currentTime: 0,
            shareAtCurrentTime: false,
            shareUrl:
                HTTP_PROTOCOL +
                this.props.school.school.sub_domain +
                '.' +
                DOMAIN +
                route.course.student.details +
                '/' +
                this.props.match.params.course_id +
                '/' +
                this.props.match.params.course_content_id +
                '/' +
                this.props.match.params.module_id,
            userProgress: 1,
            playing: true,
            isVideoContent: 1,
            allow_after_completion: 0,
            start_time: null,
            timeline: [],
            description: null,
            coursedescription: null,
            currentCourseProgress: null,
            coursetitle: null,
            media: null,
            media_url: null,
            media_name: null,
            selectedIndex: null,
            media_size: null,
            media_format: null,
            course: [],
            course_content_meta: [],
            question_id: null,
            timeline: [],
            exam: [],
            courseModule: [],
            selectedModule: false,
            question: '',
            answers: [null, null, null, null],
            hours: 0,
            minutes: 0,
            seconds: 0,
            thumbnail: {},
            timestamp: '',
            notification_time: 0,
            maxtimestamp: null,
            correctAnswers: [],
            emailNotification: false,
            smsNotification: false,
            questionId: 0,
            selectedAnswer: '',
            givenAnswer: [],
            course_progress: [],
            currentExam: null,
            grade: 0,
            result: [],
            exam_id: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseContent = this.loadCourseContent.bind(this)
        this.setMaxTimeStamp = this.setMaxTimeStamp.bind(this)
        this.randomFunc = this.randomFunc.bind(this)
        this.saveResult = this.saveResult.bind(this)
    }

    componentDidMount() {
        this.loadCourseContent()
        this.loadCourseModule()
        this.loadCourseProgress()
    }

    loadClass() {
        if (this.props.school.user_type == UserType.Student) {
            this.setState({
                class_id: this.props.school.active_user_academics.section.class_id,
            })
        } else {
            this._handleChange('loading', true)
            activeClass(this.props.token).then((response) => {
                this._handleChange('loading', false)
                let section = null
                let classes = null
                if (response.code == API_SUCCESS) {
                    section = response.data.section
                    this.setState({
                        class_id: section.classes.id,
                    })
                }
            })
        }
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAnswers() {
        let givenAnswer = this.state.givenAnswer
        let result = this.state.result
        let grade = this.state.grade
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getUserProgress(this.state.exam_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const data = response.data.onlineExam

                data.map((item, index) => {
                    let resultObj = {}
                    givenAnswer[item.question_id] = item.answer_id
                    resultObj['exam_id'] = item.exam_id
                    resultObj['marks'] = item.marks
                    resultObj['user_school_id'] = item.user_school_id
                    result.push(resultObj)
                    if (givenAnswer[item.question_id] == this.state.correctAnswers[item.question_id]) {
                        grade = grade + 1
                    }
                })
                this.setState({
                    givenAnswer,
                    result,
                })
            }
        })
    }

    answerQuestion(question_id) {
        const component = this
        return new Promise(function (resolve, reject) {
            let givenAnswer = component.state.givenAnswer
            let result = component.state.result
            givenAnswer[question_id] = component.state.selectedAnswer
            let grade = component.state.grade
            let marks = 0
            if (givenAnswer[question_id] == component.state.correctAnswers[question_id]) {
                grade = grade + 1
                marks = 1
            }
            const data = {
                exam_id: component.state.exam_id,
                question_id: question_id,
                duration: component.state.currentTime,
                answer_id: component.state.selectedAnswer,
                time: moment().format('hh:mm a'),
            }
            let resultObj = {}
            resultObj['exam_id'] = component.state.exam_id
            resultObj['marks'] = 1
            resultObj['user_school_id'] = component.props.school.active_user_academics.user_school_id
            result.push(resultObj)

            saveUserProgress(data, component.props.token).then((response) => {
                component._handleChange('loading', false)

                if (response.code == API_SUCCESS) {
                    component.setState(
                        {
                            givenAnswer,
                            grade: grade,
                            result,
                        },
                        () => {
                            resolve(component.state.exam_id)
                        }
                    )
                }
            })
        })
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const course_progress = response.data.course_progress
                let currentCourseProgress = null
                if (this.props.school.user_type == UserType.Student) {
                    currentCourseProgress = course_progress.filter(
                        (el) =>
                            el.course_content_id == this.props.match.params.course_content_id &&
                            el.user_school_id == this.props.school.active_user_academics.user_school_id
                    )
                } else {
                    currentCourseProgress = course_progress.filter(
                        (el) =>
                            el.course_content_id == this.props.match.params.course_content_id &&
                            el.user_school_id == this.props.student
                    )
                }
                const userProgress = currentCourseProgress.length > 0 ? currentCourseProgress[0].user_progress : 1
                const progress_id = currentCourseProgress.length > 0 ? currentCourseProgress[0].id : 1
                this.setState({
                    progress_id,
                    userProgress,
                    currentCourseProgress,
                    course_progress,
                })
            }
        })
    }

    saveResult() {
        const { t } = this.props
        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        if (this.state.result.length != this.state.exam.length) {
            toastMessage('error', t('toaster:answerAllQuestionsMsg'))
            return
        }
        const data = {
            exam_id: this.state.exam_id,
            marks: this.state.grade,
            result: this.state.result,
            is_course: true,
        }
        let data2 = {
            course_id: this.props.match.params.course_id,
            user_school_id: this.props.school.active_user_academics.user_school_id,
            user_progress: 2,
            feedback: '',
        }
        if (this.state.exam_id == null) {
            editCourseProgress(this.state.progress_id, data2, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(
                        route.course.student.content +
                            '/' +
                            this.props.match.params.course_id +
                            '/' +
                            +this.props.match.params.module_id,
                        this.props.history
                    )
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        } else {
            saveResult(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    if (this.state.userProgress > 0) {
                        editCourseProgress(this.state.progress_id, data2, this.props.token).then((response) => {
                            this._handleChange('loading', false)
                            if (response.code == API_SUCCESS) {
                                //redirect(route.course.student.content + '/' + this.props.match.params.course_id + '/' + +this.props.match.params.module_id)
                            } else {
                                toastMessage('error', response.message)
                                if (response.data) {
                                    if ('errors' in response.data) {
                                        this.setState({ errors: response.data.errors })
                                    }
                                }
                            }
                        })
                    }
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }

    randomFunc(myArr) {
        if (myArr) {
            var l = myArr.length,
                temp,
                index
            while (l > 0) {
                index = Math.floor(Math.random() * l)
                l--
                temp = myArr[l]
                myArr[l] = myArr[index]
                myArr[index] = temp
            }
            return myArr
        }
    }

    setMaxTimeStamp(duration) {
        const hours = parseInt(duration / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const minutes = parseInt((duration % 3600) / 60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const seconds = parseInt(duration % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const videoTime = hours + ':' + minutes + ':' + seconds
        this.setState({
            videoTime,
            maxtimestamp: duration,
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseModuleItems(this.props.match.params.module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                let post_id = courseModule.course.post_id
                this.setState({
                    courseModule,
                    post_id,
                })
            }
        })
    }

    loadCourseContent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseContentItems(this.props.match.params.course_content_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const isVideoContent = response.data.course_content.media.media_type.includes('video') ? 1 : 0
                const courseStartTimeDiff = moment().diff(
                    moment(response.data.course_content.start_time, 'yyyy-MM-DD - hh:mm:ss a')
                )
                if (courseStartTimeDiff < 0) {
                    redirect(route.course.student.view, this.props.history)
                }
                // if(courseStartTimeDiff > 0 && courseStartTimeDiff < this.state.maxtimestamp) {
                //     this.setState({
                //         videoStartTime: this.state.maxtimestamp - courseStartTimeDiff
                //     })
                // }
                this.setState(
                    {
                        isVideoContent,
                        course: response.data.course_content,
                        start_time: response.data.course_content.start_time,
                        coursetitle: response.data.course_content.name,
                        coursedescription: response.data.course_content.description,
                        media: response.data.course_content.media,
                        media_date: moment(response.data.course_content.start_time, 'yyyy-MM-DD - hh:mm:ss a').format(
                            'yyyy-MM-DD - hh:mm:ss a'
                        ),
                        media_name: response.data.course_content.media.name,
                        media_format: response.data.course_content.media.name.split('.').pop(),
                        media_url: response.data.course_content.media.media_url,
                        thumbnail_url: response.data.course_content.thumbnail
                            ? response.data.course_content.thumbnail.media_url
                            : null,
                        course_content_meta: response.data.course_content.meta,
                        loaded: true,
                    },
                    () => this.loadCourseMeta()
                )
            }
        })
    }

    loadCourseMeta() {
        let course_meta = []
        let timeline = []
        let exam = []
        let correctAnswers = []
        const course_content_meta = this.state.course_content_meta.sort((x, y) => x.timestamp - y.timestamp)
        course_content_meta.map((item, index) => {
            const data = item
            let hours = 0
            let minutes = 0
            let seconds = 0
            let action_type = null
            let value = null
            hours = parseInt(data.timestamp / 3600).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })
            minutes = parseInt((data.timestamp % 3600) / 60).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })
            seconds = parseInt(data.timestamp % 60).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })
            value = data.action_type == 0 ? data.Description : data.post_child
            data['hours'] = hours
            data['minutes'] = minutes
            data['seconds'] = seconds
            if (data.action_type == 0) {
                timeline.push(data)
            } else {
                data['post_child'] = data.post_child
                if (data.questions.length > 0) {
                    correctAnswers[item.questions[0].question[0].id] = item.questions[0].question[0].answer.filter(
                        (el) => el.is_correct == 1
                    )[0].id
                    data.questions[0].question[0].answer = this.randomFunc(item.questions[0].question[0].answer)
                    if (this.state.exam_id == null) {
                        this.setState({
                            exam_id: data.questions[0]['exam_id'],
                        })
                    }
                }
                exam.push(data)
            }
        })
        this.setState(
            {
                correctAnswers,
                timeline,
                exam,
            },
            () => this.loadAnswers()
        )
    }
}

const AbstractCourseContentDetailsStudent = withTranslation()(AbstractCourseContentDetailsStudentComponent)

export { AbstractCourseContentDetailsStudent }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
