import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSubjectMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { withTranslation } from 'react-i18next'

class SubjectMenuComponent extends AbstractSubjectMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
    }

    _selectClass(data) {
        super._handleChange('selectedClass', data.value)
        super._handleChange('defaultClass', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')} > </a>
                            </li>
                            <li>{t('classes:subject.subjectMenuBreadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('classes:subject.title')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('classes:subject.classLabel')} *
                                    </label>
                                    <Select name="type" options={this.state.classAry} value={this.state.defaultClass} placeholder={t('classes:subject.classLabel')} searchable={true} onChange={this._selectClass} />
                                    {this.state.error && <label className="error text-danger">{t('toaster:required')}*</label>}
                                </div>
                            </div>
                            <div className="text-left">
                                <button type="button" className="main-button" onClick={this.getURL}>
                                    {t('common:searchBtn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SubjectMenu = withTranslation()(connect(mapStateToProps)(SubjectMenuComponent))

export { SubjectMenu }
