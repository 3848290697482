import $ from 'jquery'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import { Student, employee_type } from '~/helper/constant/user_type'
import { toastMessage } from '~/helper/function/util'
import { activeClass, activeRole, getSchoolUser } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

export class AbstractUserFilterComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            reload: this.props.reload != null ? this.props.reload : false,
            studentColumn: this.props.studentColumn != null ? this.props.studentColumn : 6,
            showHeader: this.props.showHeader != null ? this.props.showHeader : true,
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            data: [],
            allUsers: [],
            studentList: [],
            allStudent: [],
            allEmployee: [],
            employeeList: [],
            subjectList: [],
            classList: [],
            sectionList: [],
            userTypeList: [],
            customRoleList: [],
            selectAllEmployee: false,
            selectAllStudent: false,
            defaultRole: null,
            defaultUserType: null,
            defaultClass: null,
            defaultSection: null,
            resetOnChange: this.props.resetOnChange ? this.props.resetOnChange : false,
            showSubject: this.props.showSubject ? this.props.showSubject : false,
            hideClass: this.props.hideClass ? this.props.hideClass : false,
            hideSection: this.props.hideSection ? this.props.hideSection : false,
            defaultEmployee: null,
            defaultStudent: null,
            defaultSubject: null,
            selectedRole: null,
            selectedUserType: null,
            selectedClass: this.props.selectedClass ? this.props.selectedClass : null,
            selectedSection: this.props.selectedSection ? this.props.selectedSection : null,
            selectedStudent: this.props.selectedStudent ? this.props.selectedStudent : null,
            selectedEmployee: this.props.selectedEmployee ? this.props.selectedEmployee : null,
            selectedSubject: this.props.selectedSubject ? this.props.selectedSubject : null,
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadAll()
        // this.loadSubject();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reload != this.props.reload && this.props.reload == true) {
            this.loadAll()
        }
        if (this.state.allUsers.length != 0) {
            if (
                this.props.selectedClass != null &&
                this.state.selectedClass != undefined &&
                this.props.selectedClass != this.state.selectedClass
            ) {
                this.setState(
                    {
                        selectedClass: this.props.selectedClass,
                        defaultClass: this.state.classList.find((item) => item.value == this.props.selectedClass),
                    },
                    () => {
                        this.loadSection(this.state.selectedClass)
                        this.loadSubject(this.state.selectedClass)
                        // this.filterStudent(this.state.selectedClass,-1)
                    }
                )
            }
            if (this.props.selectedSection != null && this.props.selectedSection != this.state.selectedSection) {
                let defaultSection = []
                const sectionList = this.state.sectionList
                const selectedSection = this.props.selectedSection
                defaultSection = sectionList.filter((el) => {
                    return selectedSection == el.value
                })
                this.setState({
                    defaultSection,
                    selectedSection: this.props.selectedSection,
                })
            }
            if (this.props.selectedStudent != null && this.props.selectedStudent != this.state.selectedStudent) {
                // if (this.props.selectedClass == null && this.state.selectedClass != this.props.selectedClass) {
                //     this.setState({
                //         selectedClass: this.state.classList[0].value,
                //         defaultClass: this.state.classList[0],
                //     })
                // }
                let defaultStudent = []
                const studentList = this.state.allStudent
                const selectedStudent = this.props.selectedStudent
                // let selectAllStudent = false
                // if (selectedStudent.length == this.state.allStudent.length) {
                //     selectAllStudent = true
                // }
                defaultStudent = studentList.filter((el) => {
                    return selectedStudent.includes(el.value)
                })
                this.setState({
                    defaultStudent,
                    selectedStudent: this.props.selectedStudent,
                    // selectAllStudent,
                })
            }
            if (this.props.selectedEmployee != null && this.props.selectedEmployee != this.state.selectedEmployee) {
                // this.setState({
                //     selectedUserType: this.state.userTypeList[0].value,
                //     defaultUserType: this.state.userTypeList[0],
                // })
                let defaultEmployee = []
                const employeeList = this.state.allEmployee
                const selectedEmployee = this.props.selectedEmployee
                // let selectAllEmployee = false
                // if (selectedEmployee.length == this.state.allEmployee.length) {
                //     selectAllEmployee = true
                // }
                defaultEmployee = employeeList.filter((el) => {
                    return selectedEmployee.includes(el.value)
                })
                this.setState({
                    defaultEmployee,
                    selectedEmployee: this.props.selectedEmployee,
                    // selectAllEmployee,
                })
            }
        }
        if (this.props.showSubject != prevProps.showSubject && this.state.subjectList.length != 0) {
            let defaultSubject = []
            const selectedSubject = this.props.selectedSubject
            if (this.props.selectedSubject != null && this.props.selectedSubject != this.state.selectedSubject) {
                const subjectList = this.state.subjectList
                defaultSubject = subjectList.filter((el) => {
                    return selectedSubject == el.value
                })
            }
            this.setState(
                {
                    selectedStudent: [],
                    defaultStudent: [],
                    defaultSubject,
                    selectedSubject,
                    selectAllStudent: false,
                },
                () => {
                    this.props.onStudentSelect(this.state.selectedStudent)
                }
            )
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setSelected() {
        if (this.state.allUsers.length != 0) {
            if (this.props.selectedStudent) {
                let defaultStudent = []
                const studentList = this.state.studentList
                const selectedStudent = this.props.selectedStudent
                defaultStudent = studentList.filter((el) => {
                    return selectedStudent.includes(el.value)
                })
                this.setState({
                    defaultStudent,
                    selectedStudent: this.props.selectedStudent,
                })
            }
            if (this.props.selectedEmployee) {
                let defaultEmployee = []
                const employeeList = this.state.employeeList
                const selectedEmployee = this.props.selectedEmployee
                defaultEmployee = employeeList.filter((el) => {
                    return selectedEmployee.includes(el.value)
                })
                this.setState({
                    defaultEmployee,
                    selectedEmployee: this.props.selectedEmployee,
                })
            }
        }
        if (this.props.showSubject && this.state.subjectList.length != 0)
            if (this.props.selectedSubject) {
                let defaultSubject = []
                const subjectList = this.state.subjectList
                const selectedSubject = this.props.selectedSubject
                defaultSubject = subjectList.filter((el) => {
                    return selectedSubject == el.value
                })
                this.setState({
                    defaultSubject,
                    selectedSubject: this.props.selectedSubject,
                })
            }
    }

    /*Dropdown Select*/

    _selectClass(data) {
        if (this.props.resetOnChange) {
            this.setState({
                selectedStudent: null,
                selectAllStudent: false,
                defaultStudent: null,
            })
            this.props.onStudentSelect(null)
        }
        this.setState(
            {
                defaultClass: data,
                selectedClass: data.value,
                selectAllStudent: false,
            },
            () => {
                this.loadSection(data.value)
                this.loadSubject(data.value)
            }
        )
    }
    _selectSection(data) {
        if (this.props.resetOnChange) {
            this.setState({
                selectedStudent: null,
                selectAllStudent: false,
                defaultStudent: null,
            })
            this.props.onStudentSelect(null)
        }
        if (this.props.onSectionSelect != null) {
            this.props.onSectionSelect(data.value)
        }
        this.setState(
            {
                defaultSection: data,
                selectedSection: data.value,
                selectAllStudent: false,
            },
            () => this.filterStudent(this.state.selectedClass, data.value)
        )
    }
    _selectSubject(data) {
        if (this.props.resetOnChange) {
            this.setState({
                selectedStudent: null,
                defaultStudent: null,
                selectAllStudent: false,
            })
            this.props.onStudentSelect(null)
        }
        this.props.onSubjectSelect(data.value)
        this.setState({
            defaultSubject: data,
            selectedSubject: data.value,
        })
    }
    _selectStudent(data) {
        const defaultStudent = []
        const selectedStudent = []
        if (data) {
            data.map((item) => {
                selectedStudent.push(item.value)
                defaultStudent.push(item)
            })
        }
        this.props.onStudentSelect(selectedStudent)
        // let selectAllStudent = false
        // if (selectedStudent.length == this.state.allStudent.length) {
        //     selectAllStudent = true
        // }
        const selectAllStudent = selectedStudent.length == 0 ? false : this.state.selectAllStudent
        this.setState({
            defaultStudent,
            selectedStudent,
            selectAllStudent,
        })
    }
    _selectEmployee(data) {
        const defaultEmployee = []
        const selectedEmployee = []
        if (data) {
            data.map((item) => {
                selectedEmployee.push(item.value)
                defaultEmployee.push(item)
            })
        }
        this.props.onEmployeeSelect(selectedEmployee)
        // let selectAllEmployee = false
        // if (selectedEmployee.length == this.state.allEmployee.length) {
        //     selectAllEmployee = true
        // }
        const selectAllEmployee = selectedEmployee.length == 0 ? false : this.state.selectAllEmployee
        this.setState({
            defaultEmployee,
            selectedEmployee,
            selectAllEmployee,
        })
    }

    _selectUserType(data) {
        if (this.props.resetOnChange) {
            this.setState({
                selectedEmployee: null,
                defaultEmployee: null,
                selectAllEmployee: false,
            })
            this.props.onEmployeeSelect(null)
        }
        if (data.value == 1) {
            $('#custom-role').show()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedCustomRole: this.state.customRoleList[0].value,
                defaultCustomRole: this.state.customRoleList[0],
                selectAllEmployee: false,
            })
        } else {
            $('#custom-role').hide()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedCustomRole: 0,
                defaultCustomRole: null,
                selectAllEmployee: false,
            })
        }
        this.filterEmployee(data.value, -1)
    }

    _selectCustomRole(data) {
        if (this.props.resetOnChange) {
            this.props.onEmployeeSelect(null)
            this.setState({
                selectedEmployee: null,
                defaultEmployee: null,
                selectAllEmployee: false,
            })
        }
        this._handleChange('selectedCustomRole', data.value)
        this._handleChange('defaultCustomRole', data)
        this.filterEmployee(this.state.selectedUserType, data.value)
    }

    /*Data Load*/

    loadClass() {
        const { t } = this.props
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classList = []
            if (response.code == API_SUCCESS) {
                classList.push({
                    value: -1,
                    label: 'All Class',
                })
                response.data.class.map((item, index) => {
                    classList.push({
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                    })
                })
                this.setState(
                    {
                        data: response.data.class,
                        classList: classList,
                    },
                    () => {
                        if (this.state.selectedClass != null) {
                            const defaultClass = classList.filter((el) => {
                                return this.state.selectedClass == el.value
                            })
                            this.setState(
                                {
                                    defaultClass,
                                    selectedClass: this.state.selectedClass,
                                },
                                () => {
                                    this.loadSection(this.state.selectedClass)
                                    this.loadSubject(this.state.selectedClass)
                                    this.filterStudent(this.state.selectedClass, -1)
                                }
                            )
                        } else {
                            this.setState(
                                {
                                    defaultClass: classList[0],
                                    selectedClass: classList[0].value,
                                },
                                () => {
                                    this.loadSection(this.state.selectedClass)
                                    this.loadSubject(this.state.selectedClass)
                                    this.filterStudent(this.state.selectedClass, -1)
                                }
                            )
                        }
                    }
                )
            }
        })
    }

    loadSection(class_id) {
        let sectionList = []
        sectionList.push({
            value: -1,
            label: 'All Section',
            translate: 'classes:section.allSection',
        })
        if (class_id != -1) {
            const classes = this.state.data.find((item) => item.id == class_id)
            classes.active_section.map((item, index) => {
                sectionList.push({
                    value: item.id,
                    label: item.name + ' - ' + item.code,
                })
            })
        }
        if (this.props.selectedSection != null) {
            const defaultSection = sectionList.filter((el) => {
                return this.props.selectedSection == el.value
            })
            this.setState(
                {
                    sectionList,
                    defaultSection: defaultSection,
                    selectedSection: this.props.selectedSection,
                },
                () => {
                    this.filterStudent(class_id, this.props.selectedSection)
                }
            )
        } else {
            this.setState(
                {
                    sectionList,
                    defaultSection: sectionList[0],
                    selectedSection: sectionList[0].value,
                },
                () => {
                    this.filterStudent(class_id, sectionList[0].value)
                }
            )
        }
    }

    loadSubject(class_id) {
        let subjectList = []
        subjectList.push({
            value: -1,
            label: 'All Subject',
        })
        if (class_id != -1) {
            const classes = this.state.data.find((item) => item.id == class_id)
            classes.subject.map((item, index) => {
                subjectList.push({
                    value: item.id,
                    label: item.name + ' - ' + item.code,
                })
            })
        }
        if (this.props.selectedSubject == null) {
            this._handleChange('subjectList', subjectList)
            this._handleChange('defaultSubject', subjectList[0])
            this._handleChange('selectedSubject', subjectList[0].value)
        } else if (this.props.selectedSubject != null) {
            this.loadSubjectAndSection()
        }
    }

    loadSubjectAndSection() {
        const classAry = this.state.data
        let selectedClassID
        let selectedSectionID
        let subjectList = []
        let defaultSubject
        let defaultSection
        let sectionList = []
        subjectList.push({
            value: -1,
            label: 'All Subject',
        })

        sectionList.push({
            value: -1,
            label: 'All Section',
            translate: 'classes:section.allSection',
        })
        const selectedClass = classAry.some((item, index) => {
            const subject = item.subject.filter((el) => el.id == this.props.selectedSubject)
            if (subject != null) {
                selectedClassID = item.id
                return true
            }
        })
        const defaultClass = this.state.classList.filter((el) => el.value == selectedClassID)

        const classes = this.state.data.find((item) => item.id == selectedClassID)
        classes.subject.map((item, index) => {
            subjectList.push({
                value: item.id,
                label: item.name + ' - ' + item.code,
            })
            if (item.id == this.props.selectedSubject) {
                defaultSubject = {
                    value: item.id,
                    label: item.name + ' - ' + item.code,
                }
            }
        })
        const selectedStudent = this.state.allUsers.some((item, index) => {
            if (item.user_type == Student) {
                selectedSectionID = item.active_user_academics.section_id
            }
        })
        classes.active_section.map((item, index) => {
            sectionList.push({
                value: item.id,
                label: item.name + ' - ' + item.code,
            })
            if (item.id == selectedSectionID) {
                defaultSection = {
                    value: item.id,
                    label: item.name + ' - ' + item.code,
                }
            }
        })
        this.setState({
            selectedSubject: this.props.selectedSubject,
            selectedClass: selectedClassID,
            selectedSection: selectedSectionID,
            defaultSection,
            defaultClass,
            defaultSubject,
            sectionList,
            subjectList,
        })
    }

    loadUserTypes() {
        let userTypeList = []
        userTypeList.push({
            value: -1,
            label: 'All Employee',
        })
        userTypeList = userTypeList.concat(employee_type)
        this._handleChange('userTypeList', userTypeList)
        this._handleChange('defaultUserType', userTypeList[0])
        this._handleChange('selectedUserType', userTypeList[0].value)
        this.loadRole()
    }

    loadRole() {
        activeRole(this.props.token).then((response) => {
            let customRoleList = []
            if (response.code == API_SUCCESS) {
                customRoleList.push({ value: -1, label: 'All Roles' })
                response.data.role.map((item, index) => {
                    customRoleList.push({ value: item.id, label: item.name })
                })
                this.setState({
                    defaultRole: customRoleList[0],
                    selectedRole: customRoleList[0].value,
                    customRoleList,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadAll() {
        getSchoolUser(this.props.token).then((response) => {
            let studentList = []
            let employeeList = []
            let allUsers = []
            if (response.code == API_SUCCESS) {
                response.data.users.map((item, index) => {
                    allUsers.push(item)
                    if (item.user_type == UserType.Student) {
                        studentList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    } else if (item.user_type != UserType.Alumni && item.user_type != UserType.Parents) {
                        employeeList.push({
                            value: item.id,
                            label: item.user.name + ' - ' + item.user.reg_no,
                        })
                    }
                })
                this.setState(
                    {
                        allUsers,
                        allStudent: studentList,
                        allEmployee: employeeList,
                        studentList,
                        employeeList,
                    },
                    () => {
                        this.setSelected()
                        this.loadUserTypes()
                        this.loadClass()
                    }
                )
                if (this.props.onUserLoad) {
                    this.props.onUserLoad(allUsers)
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    /*Filter Data*/

    filterStudent(class_id, section_id) {
        const allUsers = this.state.allUsers
        const students = allUsers.filter((el) => {
            return el.user_type == 3
        })
        let studentList = []
        if (class_id == -1) {
            // students.map((item, index) => {
            //     studentList.push({
            //         value: item.id,
            //         label: item.user.name + ' - ' + item.user.reg_no,
            //     })
            // })
            this.state.data.map((classes) => {
                classes.active_section.map((item, index) => {
                    const studentAry = students.filter((el) => {
                        if (el.active_user_academics != null) {
                            return el.active_user_academics.section_id == item.id
                        }
                    })
                    if (studentAry.length) {
                        studentAry.map((item, index) => {
                            studentList.push({
                                value: item.id,
                                label: item.user.name + ' - ' + item.user.reg_no,
                            })
                        })
                    }
                })
            })
        } else if (section_id == -1) {
            const classes = this.state.data.find((item) => item.id == class_id)

            const studentArry = []
            classes.active_section.map((item, index) => {
                students.filter((el) => {
                    if (el.active_user_academics != null && el.active_user_academics.section_id == item.id) {
                        studentArry.push(el)
                    }
                })
            })

            if (studentArry.length) {
                studentArry.map((item, index) => {
                    studentList.push({
                        value: item.id,
                        label: item.user.name + ' - ' + item.user.reg_no,
                    })
                })
            }
        } else {
            const studentAry = students.filter((el) => {
                if (el.active_user_academics != null) {
                    return el.active_user_academics.section_id == section_id
                }
            })
            if (studentAry.length) {
                studentAry.map((item, index) => {
                    studentList.push({
                        value: item.id,
                        label: item.user.name + ' - ' + item.user.reg_no,
                    })
                })
            }
        }
        this.setState({
            studentList,
        })
    }

    filterEmployee(user_type, role_id) {
        const allUsers = this.state.allUsers
        const employees = allUsers.filter((el) => {
            return el.user_type != Student
        })
        let employeeList = []
        if (user_type == -1) {
            employees.map((item, index) => {
                employeeList.push({
                    value: item.id,
                    label: item.user.name + ' - ' + item.user.reg_no,
                })
            })
        } else if (role_id == -1) {
            const employeeAry = employees.filter((el) => {
                return el.user_type == user_type
            })
            employeeAry.map((item, index) => {
                employeeList.push({
                    value: item.id,
                    label: item.user.name + ' - ' + item.user.reg_no,
                })
            })
        } else {
            const employeeAry = employees.filter((el) => {
                return el.role_id == role_id
            })
            employeeAry.map((item, index) => {
                employeeList.push({
                    value: item.id,
                    label: item.user.name + ' - ' + item.user.reg_no,
                })
            })
        }
        this.setState({
            employeeList,
        })
    }
}

const AbstractUserFilter = withTranslation()(AbstractUserFilterComponent)

export { AbstractUserFilter }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        schoolPackage: state.schoolPackage,
    }
}
