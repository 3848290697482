const Full_Time = 0
const Part_Time = 1
const Remote = 2

export const jobTypeList = [
    {
        value: Full_Time,
        label: 'Full Time',
    },
    {
        value: Part_Time,
        label: 'Part Time',
    },
    {
        value: Remote,
        label: 'Remote',
    },
]

export const negotiableList = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
]

export const exprienceList = [
    {
        value: 0,
        label: 'Internship',
    },
    {
        value: 1,
        label: 'Begineer',
    },
    {
        value: 2,
        label: '1+ Years',
    },
    {
        value: 3,
        label: '2+ Years',
    },
    {
        value: 4,
        label: '3+ Years',
    },
    {
        value: 5,
        label: '4+ Years',
    },
    {
        value: 5,
        label: '5+ Years',
    },
    {
        value: 7,
        label: '6+ Years',
    },
    {
        value: 9,
        label: '7+ Years',
    },
    {
        value: 10,
        label: '8+ Years',
    },
    {
        value: 11,
        label: '9+ Years',
    },
    {
        value: 12,
        label: '10+ Years',
    },
]

export { Full_Time, Part_Time, Remote }
