import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { messaging } from '~/firebaseMessaging'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { redirectDomain } from '~/helper/constant/config'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { LoginDTO } from '~/model'
import { login } from '~/services/api'
import { alumni_page, currentSchool, school_page, signin } from '~/store'
import { closIntro } from '../../store/action'

type Props = {
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    username: string,
    password: string,
    remember: boolean,
    errors: object,
    loading: boolean,
    device_id: String,
}

export class AbstractLoginComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            loading: false,
            device_id: null,
            terms: false,
            errors: {
                username: null,
                password: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onLogin = this._onLogin.bind(this)
        this.loadLogin = this.loadLogin.bind(this)
        if (this.props.school != null && this.props.token != null) {
            const user_type = this.props.school.user_type
            if (user_type == UserType.SuperAdmin) {
                redirect(route.superadmin.dashboard, this.props.history)
            } else {
                redirect(route.activity, this.props.history)
            }
        }
    }

    loadLogin() {
        this.props.dispatch(closIntro())
    }

    async componentDidMount() {
        let device_id = null
        if (typeof document != 'undefined') {
            await messaging
                .requestPermission()
                .then(async function () {
                    const token = await messaging.getToken()
                    device_id = token
                })
                .catch(function (err) {})
            await this.setState({
                device_id,
            })
        } else {
            messaging()
                .getToken()
                .then((device_id) => {
                    this.setState({
                        device_id,
                    })
                })
        }
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    _onLogin = (e) => {
        e.preventDefault()
        if (typeof document == 'undefined' && this.state.terms == false) {
            alert('Please accept the terms and condition before login.')
            return
        }
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data: LoginDTO = {
                username: this.state.username,
                password: this.state.password,
                device_id: this.state.device_id,
            }
            this._handleChange('loading', true)
            login(data).then((response) => {
                this._handleChange('loading', false)

                if (response.code == API_SUCCESS) {
                    // toastMessage("success" ,response.message);
                    const user_school = response.data.user.user_school
                    if (user_school.length == 1) {
                        if (user_school[0].user_type === UserType.SuperAdmin) {
                            this.props.dispatch(signin(response.data))
                            this.props.dispatch(currentSchool(user_school[0]))
                            redirect(route.superadmin.dashboard, this.props.history)
                        } else {
                            if (user_school[0].user_type == UserType.Alumni) {
                                this.props.dispatch(alumni_page())
                            } else {
                                this.props.dispatch(school_page())
                            }
                            if (user_school[0].school.domain) {
                                if (typeof document != 'undefined') {
                                    window.location = redirectDomain(user_school[0].school.domain, response.data.token)
                                } else {
                                    this.props.dispatch(currentSchool(user_school[0]))
                                    redirect(route.account + '/' + response.data.token, this.props.history)
                                }
                            } else {
                                if (typeof document != 'undefined') {
                                    window.location = redirectDomain(
                                        user_school[0].school.sub_domain,
                                        response.data.token
                                    )
                                } else {
                                    this.props.dispatch(currentSchool(user_school[0]))
                                    redirect(route.account + '/' + response.data.token, this.props.history)
                                }
                            }
                        }
                    } else if (user_school.length > 1) {
                        if (typeof document != 'undefined') {
                            // console.log(redirectDomain(user_school[0].school.sub_domain, response.data.token))
                            // return
                            window.location = redirectDomain(user_school[0].school.sub_domain, response.data.token)
                        } else {
                            if (user_school[0].user_type == UserType.Alumni) {
                                this.props.dispatch(alumni_page())
                            } else {
                                this.props.dispatch(school_page())
                            }
                            this.props.dispatch(currentSchool(user_school[0]))
                            redirect(route.account + '/' + response.data.token, this.props.history)
                        }
                    } else {
                        this.props.dispatch(signin(response.data))
                    }
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
        isIntro: state.isIntro,
    }
}
