const fullScreenReducer = (state = false, action) => {
    switch (action.type) {
        case 'FULLSCREEN_OPEN':
            return true
        case 'FULL_SCREEN_CLOSE':
            return false
        case 'SIGN_OUT':
            return false
        case 'RESET':
            return false
        default:
            return state
    }
}

export default fullScreenReducer
