import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { editTodo, deleteMedia, submitTodo, toggleTodo } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    student: String,
    dispatch: Dispatch<any>,
}

type State = {
    id: String,
    posts: Object,
    loading: Boolean,
    loading_text: String,
    task: Array,
    data: Array,
    errors: Object,
    title: String,
    description: String,
    student: Array,
    employee: Array,
    subject: String,
    due_date: String,
    default_due_date: String,
    isSubject: Boolean,
    reload: Boolean,
    media: Array,
    uploads: Array,
    submit_description: String,
    file: Array,
    uploadFile: Array,
    homework: Array,
    selectedClass: Integer,
}

export class AbstractTodoViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loaded: false,
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            id: this.props.match.params.id,
            posts: null,
            student: [],
            employee: [],
            title: '',
            description: '',
            due_date: '',
            default_due_date: '',
            selectedClass: null,
            isSubject: false,
            subject: null,
            reload: false,
            remarks: false,
            homework_remarks: '',
            media: [],
            uploads: [],
            submit_description: '',
            file: [],
            uploadFile: [],
            homework: [
                {
                    participants: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'todo:participants',
                    },
                    submitted_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'todo:submitted_date',
                    },
                    description: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:description',
                    },
                    file: {
                        field: 'file',
                        route: true,
                        sortable: true,
                        translate: 'drive:files',
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                        translate: 'common:status',
                    },
                },
            ],
            errors: {
                title: null,
                description: null,
                due_date: null,
            },
            submit_errors: {
                submit_description: null,
            },
        }
        this._loadPost = this._loadPost.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._deleteMediaFile = this._deleteMediaFile.bind(this)
        this._submitTask = this._submitTask.bind(this)
        this._onApprove = this._onApprove.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onApprove(id, status) {
        const data = {
            status: status,
            remarks: this.state.homework_remarks,
        }
        toggleTodo(id, data, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.setState(
                    {
                        reload: true,
                    },
                    () => {
                        this.setState({
                            reload: false,
                        })
                    }
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _loadPost(posts) {
        const { t } = this.props
        let isSubject = false
        let student = []
        let employee = []
        let homework = []
        let subject = null
        homework.push(this.state.homework[0])
        if (posts.subject_id != null) {
            isSubject = true
            subject = posts.subject_id
        }
        if (posts.access_information != null) {
            const due_date = moment(posts.from_date, 'yyyy-MM-DD')
            posts.access_information
                .filter((item) => item.user_type == UserType.Student)
                .map((item) => {
                    const name = item.user.name != null ? item.user.name : item.user.reg_no
                    const submission = posts.homework.find((submit) => submit.users_schools_id == item.id)
                    let status = {
                        text: t('todo:pendingSubmission'),
                        badge: 'table-yellow',
                    }
                    let description = 'N/A'
                    let submitted_date = {
                        text: 'N/A',
                    }
                    let file = 'N/A'
                    let file_url = '#'
                    if (submission != null) {
                        description = submission.description
                        submitted_date = {
                            text: submission.submitted_date,
                        }
                        file = submission.gallery == null ? 'N/A' : submission.gallery.media.length == 0 ? 'N/A' : submission.gallery.media[0].name
                        file_url = submission.gallery == null ? '#' : submission.gallery.media.length == 0 ? '#' : route.gallery + '/' + submission.gallery.media[0].id
                        const submission_date = moment(submission.submitted_date, 'yyyy-MM-DD')
                        if (submission_date.isAfter(due_date)) {
                            submitted_date = {
                                text: submission.submitted_date,
                                color: 'red',
                            }
                        }
                        if (submission.status == Status.PENDING) {
                            status = {
                                text: t('todo:approval'),
                                badge: 'table-blue',
                                modal: 'verify-task-' + submission.id,
                            }
                        } else if (submission.status == Status.ACTIVE) {
                            status = {
                                text: t('todo:approved'),
                                badge: 'table-green',
                                modal: 'verify-task-' + submission.id,
                            }
                        } else {
                            status = {
                                text: t('todo:rejected'),
                                badge: 'table-red',
                                modal: 'verify-task-' + submission.id,
                            }
                        }
                    }
                    const tableData = {
                        participants: {
                            text: name,
                        },
                        submitted_date,
                        description: {
                            text: description,
                        },
                        file: {
                            text: file,
                            route: file_url,
                        },
                        status,
                    }
                    homework.push(tableData)
                    student.push(item.id)
                })
            posts.access_information
                .filter((item) => item.user_type != UserType.Student)
                .map((item) => {
                    const name = item.user.name != null ? item.user.name : item.user.reg_no
                    const submission = posts.homework.find((submit) => submit.users_schools_id == item.id)
                    let file = 'N/A'
                    let file_url = '#'
                    let status = {
                        text: t('todo:pendingSubmission'),
                        badge: 'table-yellow',
                    }
                    let description = 'N/A'
                    let submitted_date = {
                        text: 'N/A',
                    }
                    if (submission != null) {
                        description = submission.description
                        submitted_date = {
                            text: submission.submitted_date,
                        }
                        file = submission.gallery == null ? 'N/A' : submission.gallery.media.length == 0 ? 'N/A' : submission.gallery.media[0].name
                        file_url = submission.gallery == null ? '#' : submission.gallery.media.length == 0 ? '#' : submission.gallery.media[0].media_url
                        const submission_date = moment(submission.submitted_date, 'yyyy-MM-DD')
                        if (submission_date.isAfter(due_date)) {
                            submitted_date = {
                                text: submission.submitted_date,
                                color: 'red',
                            }
                        }
                        if (submission.status == Status.PENDING) {
                            status = {
                                text: t('todo:approval'),
                                badge: 'table-blue',
                                modal: 'verify-task-' + submission.id,
                            }
                        } else if (submission.status == Status.ACTIVE) {
                            status = {
                                text: t('todo:approved'),
                                badge: 'table-green',
                                modal: 'verify-task-' + submission.id,
                            }
                        } else {
                            status = {
                                text: t('todo:rejected'),
                                badge: 'table-red',
                                modal: 'verify-task-' + submission.id,
                            }
                        }
                    }
                    const tableData = {
                        participants: {
                            text: name,
                        },
                        submitted_date,
                        description: {
                            text: description,
                        },
                        file: {
                            text: file,
                            route: file_url,
                        },
                        status,
                    }
                    homework.push(tableData)
                    employee.push(item.id)
                })
        }
        this.setState({
            posts,
            student,
            employee,
            subject,
            title: posts.title,
            remarks: posts.remarks == t('todo:OnlineSubmission') ? true : false,
            description: posts.description,
            due_date: moment(posts.to_date, 'yyyy-MM-DD').toDate(),
            default_due_date: moment(posts.to_date, 'yyyy-MM-DD').toDate(),
            isSubject,
            homework,
            loaded: true,
        })
    }

    _deleteMediaFile(e) {
        const link = e.target.getAttribute('link')
        deleteMedia(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.setState(
                    {
                        reload: true,
                    },
                    () => {
                        this.setState({
                            reload: false,
                        })
                    }
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _submitTask(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const submit_errors = validation.required(Object.keys(component.state.submit_errors), component.state)
            component.setState({ submit_errors })
            const validate = Object.keys(submit_errors).filter((item) => submit_errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Saving')
                component._handleChange('loading', true)
                const data = {
                    description: component.state.submit_description,
                    media: component.state.file,
                }
                submitTodo(component.state.id, data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.setState(
                            {
                                reload: true,
                            },
                            () => {
                                component.setState({
                                    reload: false,
                                })
                            }
                        )
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const today = moment(moment().format('yyyy-MM-DD'))
            const due = moment(component.state.due_date, 'yyyy-MM-DD')
            const default_due = moment(component.state.default_due_date, 'yyyy-MM-DD')
            if (due.isBefore(today) && !default_due.isSame(due)) {
                errors.due_date = t('todo:taskmsg5')
                component.setState({ errors })
            }
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Saving')
                component._handleChange('loading', true)
                const data = {
                    title: component.state.title,
                    description: component.state.description,
                    from_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    to_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    media: component.state.media,
                    remarks: component.state.remarks == true ? t('todo:OnlineSubmission') : t('todo:PhysicalSubmission'),
                }
                if (component.state.student != null && component.state.employee != null) {
                    data.access = component.state.employee.concat(component.state.student)
                } else if (component.state.student != null) {
                    data.access = component.state.student
                } else {
                    data.access = component.state.employee
                }
                if (component.state.isSubject) {
                    if (component.state.subject != null && component.state.subject != -1) {
                        data.subject_id = component.state.subject
                    }
                }
                editTodo(component.state.id, data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.setState(
                            {
                                reload: true,
                            },
                            () => {
                                component.setState({
                                    reload: false,
                                })
                            }
                        )
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }
}

const AbstractTodoView = withTranslation()(AbstractTodoViewComponent)

export { AbstractTodoView }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        student: state.student,
        schoolPackage: state.schoolPackage,
    }
}
