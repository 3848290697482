import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faArrowsAlt,
    faChalkboard,
    faChevronCircleDown,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faClone,
    faCog,
    faColumns,
    faCommentAlt,
    faDesktop,
    faEdit,
    faEllipsisV,
    faEye,
    faFilter,
    faGlobeAsia,
    faHome,
    faMicrophone,
    faMicrophoneSlash,
    faMinusCircle,
    faPaperPlane,
    faPaperclip,
    faPause,
    faPhone,
    faPlay,
    faRecordVinyl,
    faSearch,
    faShare,
    faSquare,
    faTh,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrash,
    faUserCircle,
    faUserPlus,
    faUsers,
    faVideo,
} from '@fortawesome/free-solid-svg-icons'

export default function getFont() {
    return library.add(
        fab,
        faChalkboard,
        faChevronCircleDown,
        faChevronCircleLeft,
        faChevronCircleRight,
        faChevronCircleUp,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faClone,
        faCommentAlt,
        faColumns,
        faEllipsisV,
        faFilter,
        faSearch,
        faDesktop,
        faGlobeAsia,
        faHome,
        faMicrophone,
        faMicrophoneSlash,
        faMinusCircle,
        faPaperclip,
        faPaperPlane,
        faPhone,
        faRecordVinyl,
        faSquare,
        faTh,
        faTimesCircle,
        faUsers,
        faUserPlus,
        faVideo,
        faEye,
        faEdit,
        faTimes,
        faThumbsUp,
        faTrash,
        faPlay,
        faPause,
        faArrowsAlt,
        faUserCircle,
        faCog,
        faShare
    )
}
