import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as ReactTable from 'react-super-responsive-table'
import * as SVG from '~/assets/svg'
import { Input } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { circle } from '~/helper/constant/color'
import { durationList, group, limitList, user_role } from '~/helper/constant/leaderboard_filter'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { AbstractLeaderBoardComponent, mapStateToProps } from './abstract'

class LeaderBoardComponent extends AbstractLeaderBoardComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        let i = 0
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="leaderboard" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.leaderboard.view}>{t('gamification:leaderboard')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('gamification:leaderboard')} :</h5>
                            </div>
                            {this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <a href={route.leaderboard.add} className="main-button">
                                            {t('common:addBtn')} XP
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="main-table">
                        <div className="row clearfix table-head">
                            <div className="col-lg-12 table-sort col-xs-12 float-left text-right form-group">
                                <div className="row">
                                    <div className="col-lg-3 display-inline-block top-select-leaderboard">
                                        <label className="table-form-title text-left">{t('gamification:limit')} :</label>
                                        <Select placeholder={t('gamification:limit')} value={this.state.limit} options={limitList} onChange={this.changeLimit} />
                                    </div>
                                    {this.props.school != null && (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                        <div className="col-lg-3 display-inline-block top-select-leaderboard">
                                            <label className="table-form-title text-left">{t('chat:group')} :</label>
                                            <Select placeholder={t('chat:group')} value={this.state.group} options={group} onChange={this.changeGroup} />
                                        </div>
                                    )}
                                    {this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                        <div className="col-lg-3 display-inline-block top-select-leaderboard">
                                            <label className="table-form-title text-left">{t('gamification:role')} :</label>
                                            <Select placeholder={t('gamification:role')} value={this.state.user_type} options={user_role} onChange={this.changeRole} />
                                        </div>
                                    )}
                                    <div className="col-lg-3 display-inline-block top-select-leaderboard">
                                        <label className="table-form-title text-left">{t('gamification:medal')} :</label>
                                        <Select placeholder={t('gamification:medal')} value={this.state.medal} options={this.state.medalList} onChange={this.changeMedal} />
                                    </div>
                                    <div className="col-lg-3 display-inline-block top-select-leaderboard">
                                        <label className="table-form-title text-left">{t('gamification:duration')} :</label>
                                        <Select placeholder={t('gamification:duration')} value={this.state.duration} options={durationList} onChange={this.changeDuration} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 table-search col-xs-12 float-left">
                                <label className="table-form-title text-left">{t('common:searchBtn')} :</label>
                                <form className="main-search leaderboard">
                                    <Input onChange={this.search} name="search" placeholder={t('common:searchhere')} type="text" value={this.state.search} />
                                    <div className="search-icon">{SVG.tablesearch()}</div>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="table-all table-responsive">
                            <ReactTable.Table className="table">
                                <ReactTable.Thead>
                                    <ReactTable.Tr>
                                        <ReactTable.Th>
                                            <span className="look-down">{t('gamification:ranking')}</span>
                                        </ReactTable.Th>
                                        <ReactTable.Th>
                                            <span className="look-down">{t('common:name')}</span>
                                        </ReactTable.Th>
                                        <ReactTable.Th>
                                            <span className="look-down">{t('gamification:medal')}</span>
                                        </ReactTable.Th>
                                        <ReactTable.Th>
                                            <span className="look-down">{t('gamification:points')}</span>
                                        </ReactTable.Th>
                                    </ReactTable.Tr>
                                </ReactTable.Thead>
                                <ReactTable.Tbody>
                                    {this.state.filteredLeaderBoardList.length > 0 && (
                                        <Fragment>
                                            {this.state.filteredLeaderBoardList.map((item, index) => {
                                                let currentUserId = this.props.school.id
                                                if (this.props.school.user_type == UserType.Parents) {
                                                    currentUserId = this.props.student
                                                }
                                                const currentUser = item.id == currentUserId ? true : false
                                                const name = item.name != null ? item.name : item.reg_no
                                                if (item.profile == null) {
                                                    i++
                                                }
                                                if (i == 3 || i == -1) {
                                                    i = 0
                                                }
                                                return (
                                                    <ReactTable.Tr className={currentUser ? 'current-user-table' : ''}>
                                                        <ReactTable.Th>{item.ranking}</ReactTable.Th>
                                                        <ReactTable.Th>
                                                            <div className="circle-category-list" style={{ marginBottom: 0 }}>
                                                                <div className={'user-img ' + circle[i]}>
                                                                    {item.profile != null && <img src={item.profile} alt="post-user" />}
                                                                    {item.profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                                                                </div>
                                                                <div className="circle-user">
                                                                    <h5>
                                                                        <a href={route.user_profile + '/' + item.user_id}>{name}</a>
                                                                    </h5>
                                                                    <span>{item.reg_no}</span>
                                                                </div>
                                                            </div>
                                                        </ReactTable.Th>
                                                        <ReactTable.Th>{item.medal}</ReactTable.Th>
                                                        <ReactTable.Th>{item.points != null ? item.points : 0} XP</ReactTable.Th>
                                                    </ReactTable.Tr>
                                                )
                                            })}
                                        </Fragment>
                                    )}
                                    {this.state.filteredLeaderBoardList.length == 0 && (
                                        <ReactTable.Tr>
                                            <ReactTable.Td colSpan={4}>
                                                <p className="no-data">
                                                    {SVG.nodata()}
                                                    {t('common:table.nodata')}.
                                                </p>
                                            </ReactTable.Td>
                                        </ReactTable.Tr>
                                    )}
                                </ReactTable.Tbody>
                            </ReactTable.Table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const LeaderBoard = withTranslation()(connect(mapStateToProps)(LeaderBoardComponent))

export { LeaderBoard }
