import React, { Component } from 'react'
import { AbstractDashboardComponent, mapStateToProps } from './abstract'
import { connect } from 'react-redux'
import { Calendar } from 'react-modern-calendar-datepicker'
import * as route from '~/helper/constant/route'
import * as Color from '~/helper/constant/color'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import { NepaliCalendar } from '../nepali-calendar'
import { convertADtoBS } from '../../helper/function'
import { NepaliMonthShort } from '../../helper/constant/nepali-month'
export class DashboardComponent extends AbstractDashboardComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getHoliday = this.getHoliday.bind(this)
        this.getTodo = this.getTodo.bind(this)
        this.getEvent = this.getEvent.bind(this)
    }

    getTodo() {
        const { t } = this.props
        let i = -1
        const data = this.state.todo.map((item, index) => {
            const date = item.from_date.split('-')
            const name = item.author.user.name != null ? item.author.user.name : item.author.user.reg_no
            let bsDate = convertADtoBS(item.from_date)
            if (bsDate != null) {
                bsDate = bsDate.split('-')
            } else {
                bsDate = [0, 0, 0]
            }
            i++
            return (
                <a key={index} href={route.todo.detail + '/' + item.id} className="content-list">
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && (
                        <span className="bg-green2">
                            {bsDate[2]}
                            <br />
                            {NepaliMonthShort[parseInt(bsDate[1]) - 1]}
                        </span>
                    )}
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                        <span className="bg-green2">
                            {moment(item.from_date).format('DD')}
                            <br />
                            {moment(item.from_date).format('MMM')}
                        </span>
                    )}
                    <div className="box-content">
                        <p>{item.title}</p>
                        <ins>
                            {t('todo:assignedBy')} : {name}
                        </ins>
                        <FontAwesomeIcon className="arrow-right icon" icon="chevron-right" color="#333" size="sm" />
                    </div>
                </a>
            )
        })
        return data
    }

    getHoliday() {
        const { t } = this.props
        const data = this.state.holidays.map((item, index) => {
            const date = item.from_date.split('-')
            let bsDate = convertADtoBS(item.from_date)
            if (bsDate != null) {
                bsDate = bsDate.split('-')
            } else {
                bsDate = [0, 0, 0]
            }
            return (
                <a key={index} className="content-list" href={route.calendar + '/' + item.from_date}>
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && (
                        <span className="bg-pink1">
                            {bsDate[2]}
                            <br />
                            {NepaliMonthShort[parseInt(bsDate[1]) - 1]}
                        </span>
                    )}
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                        <span className="bg-pink1">
                            {moment(item.from_date).format('DD')}
                            <br />
                            {moment(item.from_date).format('MMM')}
                        </span>
                    )}

                    <div className="box-content">
                        <p>{item.description}</p>
                        <ins>{t('calendar:holiday.add.namePlaceholder')}</ins>
                        <FontAwesomeIcon className="arrow-right icon" icon="chevron-right" color="#333" size="sm" />
                    </div>
                </a>
            )
        })
        return data
    }

    getEvent() {
        const { t } = this.props
        let i = -1
        const data = this.state.events.map((item, index) => {
            const date = item.from_date.split('-')
            let bsDate = convertADtoBS(item.from_date)
            if (bsDate != null) {
                bsDate = bsDate.split('-')
            } else {
                bsDate = [0, 0, 0]
            }
            i++
            return (
                <a key={index} className="content-list" href={route.calendar + '/' + item.from_date}>
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && (
                        <span className="bg-purple2">
                            {bsDate[2]}
                            <br />
                            {NepaliMonthShort[parseInt(bsDate[1]) - 1]}
                        </span>
                    )}
                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                        <span className="bg-purple2">
                            {moment(item.from_date).format('DD')}
                            <br />
                            {moment(item.from_date).format('MMM')}
                        </span>
                    )}
                    <div className="box-content">
                        <p>{item.description}</p>
                        <ins>{t('calendar:event.event')}</ins>
                        <FontAwesomeIcon className="arrow-right icon" icon="chevron-right" color="#333" size="sm" />
                    </div>
                </a>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <nav role="navigation">
                <div id="menuToggle">
                    <input type="checkbox" className="click-hide" />
                    <button className="hide-click">
                        <img src="assets/images/arrow.png" />
                    </button>
                    <div id="right-boxes" className="activity-right">
                        <div className="box calender">
                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <NepaliCalendar events={this.state.eventDate} />}
                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && <Calendar minimumDate={this.state.minDate} maximumDate={this.state.maxDate} value={this.state.eventDate} customDaysClassName={this.state.eventDate} />}

                            <a href={route.calendar} className="box-view-all">
                                {t('viewall')} &nbsp;
                                <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                            </a>
                        </div>
                        {this.state.todo.length > 0 && (
                            <div className="box">
                                <h5>{t('todo:task')}</h5>
                                <div className="box-content-list">{this.getTodo()}</div>
                                <a href={route.todo.view} className="box-view-all">
                                    {t('viewall')} &nbsp;
                                    <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                                </a>
                            </div>
                        )}
                        {this.state.events.length > 0 && (
                            <div className="box">
                                <h5>{t('calendar:event.event')}</h5>
                                <div className="box-content-list">{this.getEvent()}</div>
                                <a href={route.calendar} className="box-view-all">
                                    {t('viewall')} &nbsp;
                                    <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                                </a>
                            </div>
                        )}
                        {this.state.holidays.length > 0 && (
                            <div className="box">
                                <h5>{t('calendar:holiday.add.namePlaceholder')}</h5>
                                <div className="box-content-list">{this.getHoliday()}</div>
                                <a href={route.calendar} className="box-view-all">
                                    {t('viewall')} &nbsp;
                                    <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        )
    }
}

const Dashboard = withTranslation()(connect(mapStateToProps)(DashboardComponent))

export { Dashboard }
