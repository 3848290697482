import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewBankAccount } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}

export class AbstractBankAccountComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            loading_text: 'Loading',
            tableData: [],
            tableStructure: [
                {
                    title: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    bank: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:bank',
                    },
                    account_number: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:accountnumber',
                    },
                    current_balance: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:currentBalance',
                    },
                    contact_number: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:contactnumber',
                    },
                    bank_branch: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'banking:bank_branch',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadBanking()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadBanking() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        viewBankAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.accounts.map((items, index) => {
                    let text = null

                    let amount = {
                        text:this.props.school.currency + " " + Math.abs(items.opening_balance).toFixed(2),
                    }

                    if (items.opening_balance < 0) {
                        amount.color = 'red'
                    }
                    const rowData = {
                        title: {
                            text: items.holder_name,
                        },
                        bank: {
                            text: items.bank_name,
                        },
                        account_number: {
                            text: items.account_number,
                        },
                        current_balance: amount,
                        contact_number: {
                            text: items.contact_number,
                        },
                        bank_branch: {
                            text: items.bank_address,
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.banking.account.edit + '/' + items.id,
                        },
                    }
                    tableData.push(items)
                    tableStructure.push(rowData)
                })
                this.setState({
                    tableData,
                    tableStructure,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school
    }
}
