import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import { AbstractLanguageSelector, mapStateToProps } from './abstract'
import { language } from '~/helper/constant/language'
import * as SVG from '~/assets/svg'
import Select from 'react-select'
import { capitalize } from '~/helper/function'

let changeLocale = null
class LanguageSelectorComponent extends AbstractLanguageSelector {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._changeLanguage = this._changeLanguage.bind(this)
        this.setLocale = this.setLocale.bind(this)
    }

    componentDidMount() {
        if (changeLocale == null) {
            changeLocale = this.state.locale
        }
        this._changeLanguage()
    }

    setLocale(value) {
        changeLocale = value
        this._changeLanguage()
    }

    _changeLanguage() {
        const { i18n } = this.props
        i18n.changeLanguage(changeLocale)
    }

    render() {
        const { t } = this.props
        return (
            <li className="position-relative">
                <button className="btn head-button dropdown-toggle language-icon" type="button" data-toggle="collapse" data-target="#language_dropdown">
                    {SVG.language()}
                    <ins>{changeLocale != null ? capitalize(changeLocale) : 'En'}</ins>
                </button>
                <div className="dropdown-menu select-language notif-pop main main-border-radius main-background collapse-body" aria-labelledby="language_dropdown" id="language_dropdown">
                    <button type="button" className="close" data-toggle="collapse" data-target="#language_dropdown">
                        &times;
                    </button>
                    <span>{t('common:chooseLang')}</span>
                    {/* <Select>
                            <option>English</option>
                            <option>English</option>
                        </Select> */}
                    <div className="notif-dropdown">
                        {language.map((item, index) => {
                            return (
                                <a className="list-noti" key={index} onClick={() => this.setLocale(item.value)}>
                                    {item.label}
                                </a>
                            )
                        })}
                    </div>
                </div>
                {/* <select onChange={this.setLocale}>
                    {language.map((item, index) => {
                        if (item.value == changeLocale) {
                            return (
                                <option value={item.value} key={index} selected="selected">
                                    {item.label}
                                </option>
                            )
                        } else {
                            return (
                                <option value={item.value} key={index}>
                                    {item.label}
                                </option>
                            )
                        }
                    })}
                </select> */}
            </li>
        )
    }
}

const LanguageSelector = withTranslation()(connect(mapStateToProps)(LanguageSelectorComponent))

export default LanguageSelector
