import { accountingGet, accountingPost } from '../accountingRequest'

export const viewInvoice = (start_date, end_date, customer, status, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'invoice?issue_date=' + start_date + ' to ' + end_date + '&customer=' + customer + '&status=' + status,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCustomerBalance = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('customer/balance/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addInvoice = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addBulkInvoice = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/bulk/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editInvoice = (invoice, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('invoice/edit/' + invoice, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateInvoice = (invoice, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/update/' + invoice, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteInvoice = (invoice, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('invoice/delete' + invoice, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteInvoiceRow = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/product/destroy', token, id).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewInvoicePayment = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('invoice/payment', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addInvoicePayment = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/payment/add', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateInvoicePayment = (invoice, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/payment/update' + invoice, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deletePayment = (invoice, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('invoice/payment/delete' + invoice, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addPaymentInvoice = (invoice_id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/' + invoice_id + '/payment', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deletePaymentInvoice = (invoice_id, payment_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('invoice/' + invoice_id + '/payment/' + payment_id + '/destroy', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewRevenue = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('revenues', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addRevenue = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('revenues/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateRevenue = (revenue, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('revenues/update' + revenue, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteRevenue = (revenue, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('revenues/delete' + revenue, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
