import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { getRole, editRole } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    access: Array,
    errors: Object,
    role: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractRoleEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            schoolAccess: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            loading: false,
            name: '',
            access: [],
            errors: {
                name: null,
                access: null,
            },
            role: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._selectModules = this._selectModules.bind(this)
        this._selectAllModules = this._selectAllModules.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                schoolAccess: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }

    getData() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getRole(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const role = response.data.role
                this.setState({
                    name: role.name,
                    access: role.access,
                    role,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _selectAllModules(key, value) {
        const access = this.state.access
        if (value) {
            modules.all_access
                .filter((item) => item.parent_code == key)
                .map((item) => {
                    if (!access.includes(item.value)) {
                        access.push(item.value)
                    }
                })
            this.setState({ access })
        } else {
            if (access.length > 0) {
                modules.all_access
                    .filter((item) => item.parent_code == key)
                    .map((item) => {
                        const remove = access.indexOf(item.value)
                        access.splice(remove, 1)
                    })
                this.setState({ access })
            }
        }
    }

    _selectModules(key, value) {
        const access = this.state.access
        if (value) {
            modules.all_access
                .filter((item) => item.code == key)
                .map((item, index) => {
                    access.push(item.value)
                })
            this.setState({ access })
        } else {
            let filter = []
            modules.all_access
                .filter((item) => item.code == key)
                .map((item, index) => {
                    access
                        .filter((data) => data != item.value)
                        .map((result, index) => {
                            filter.push(result)
                        })
                })
            this.setState({ access: filter })
        }
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                access: this.state.access,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editRole(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.user.role.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
