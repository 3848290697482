import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

type Props = {
    school: Object,
    schoolPackage: Object,
    dispatch: Dispatch<any>,
}

type State = {
    access: Array,
}

export class AbstractUserComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        schoolPackage: state.schoolPackage,
    }
}
