//MAIN
export const Module_School_Management = 0
export const Module_Student_Management = 1
export const Module_Employee_Management = 2
export const Module_Calendar_Management = 3
export const Module_Drive = 4
export const Module_Course_Management = 5
export const Module_Task_Management = 6
export const Module_Chat = 7
export const Module_Accounting = 8
export const Module_Exam_Management = 9
export const Module_Alumni = 10
export const Module_Report = 11
export const Module_Notification = 12
export const Module_Invite = 13
export const Module_Gamification = 14
export const Module_Library = 15

//SUB
export const ACADEMIC_YEAR = 0
export const CLASS_MANAGEMENT = 1
export const SUBJECT_CATEGORY = 2
export const SUBJECT = 3
export const TIMETABLE = 4
export const NOTIFICATION = 5
export const STUDENT_CATEGORY = 6
export const STUDENT = 7
export const STUDENT_ATTENDANCE = 8
export const ROLE_MANAGEMENT = 9
export const EMPLOYEE_CATEGORY = 10
export const EMPLOYEE = 11
export const EMPLOYEE_ATTENDANCE = 12
export const TEACHER_ROLE = 13
export const EVENT = 14
export const HOLIDAY = 15
export const DRIVE = 16
export const COURSE = 17
export const TODO = 18
export const CHAT = 19
export const FEE = 20
export const EXAM = 21
export const RESULT = 22
export const QUESTION_BANK = 23
export const INVITE = 24
export const ALUMNI = 25
export const MODERATE_POST = 26
export const GAMIFICATION = 27
export const REPORT_CARD = 28
export const GRADING_SETTING = 29
export const ASSET = 30
export const PRODUCT = 31
export const BANK = 32
export const CONSTANT = 33
export const INCOME = 34
export const EXPENSE = 35
export const DOUBLE_ENTRY = 36
export const ACCOUNTING_REPORTS = 37
export const LIBRARY = 38

export function checkSubAccess(subAccess, access) {
    let allow = false
    if (access == null) {
        allow = true
    } else {
        if (access.length > 0) {
            subAccess.map((item) => {
                if (access.includes(item)) {
                    allow = true
                }
            })
        } else {
            allow = false
        }
    }
    return allow
}

export const access_management = [
    {
        label: 'School Management',
        code: 'P000',
        value: Module_School_Management,
        translate: 'users:role.add.school-management.schoolManagement',
    },
    {
        label: 'Student Management',
        code: 'P001',
        value: Module_Student_Management,
        translate: 'users:role.add.student-management.studentManagement',
    },
    {
        label: 'Employee Management',
        code: 'P002',
        value: Module_Employee_Management,
        translate: 'users:role.add.employee-management.employeeManagement',
    },
    {
        label: 'Calendar Management',
        code: 'P003',
        value: Module_Calendar_Management,
        translate: 'users:role.add.calendar-management.calendarManagement',
    },
    { label: 'Drive', code: 'P004', value: Module_Drive, translate: 'users:role.drive' },
    {
        label: 'Course Management',
        code: 'P005',
        value: Module_Course_Management,
        translate: 'users:role.add.course-management.courseManagement',
    },
    {
        label: 'Task Management',
        code: 'P006',
        value: Module_Task_Management,
        translate: 'users:role.add.task-management.taskManagement',
    },
    { label: 'Chat', code: 'P007', value: Module_Chat, translate: 'users:role.add.chat' },
    {
        label: 'Accounting Management',
        code: 'P008',
        value: Module_Accounting,
        translate: 'Accounting Management',
    },
    {
        label: 'Exam Management',
        code: 'P009',
        value: Module_Exam_Management,
        translate: 'users:role.add.exam-management.examManagement',
    },
    { label: 'Alumni', code: 'P010', value: Module_Alumni, translate: 'alumni:alumni' },
    { label: 'Report', code: 'P011', value: Module_Report, translate: 'alumni:report' },
    {
        label: 'Notification Management',
        code: 'P012',
        value: Module_Notification,
        translate: 'users:role.add.notification',
    },
    { label: 'Invite', code: 'P013', value: Module_Invite, translate: 'invite:invite' },
    { label: 'Gamification', code: 'P014', value: Module_Gamification, translate: 'Gamification' },
    { label: 'Library', code: 'P015', value: Module_Library, translate: 'Library' },
]
export const all_access = [
    {
        label: 'Academic Year',
        code: 'A000',
        parent_code: 'P000',
        parent_value: Module_School_Management,
        value: ACADEMIC_YEAR,
        translate: 'users:role.add.school-management.academicYear',
    },
    {
        label: 'Class Management',
        code: 'A001',
        parent_code: 'P000',
        parent_value: Module_School_Management,
        value: CLASS_MANAGEMENT,
        translate: 'users:role.add.school-management.classManagement',
    },
    {
        label: 'Subject Category',
        code: 'A002',
        parent_code: 'P000',
        parent_value: Module_School_Management,
        value: SUBJECT_CATEGORY,
        translate: 'users:role.add.school-management.subjectCategory',
    },
    {
        label: 'Subject',
        code: 'A003',
        parent_code: 'P000',
        parent_value: Module_School_Management,
        value: SUBJECT,
        translate: 'users:role.add.school-management.subject',
    },
    {
        label: 'Timetable',
        code: 'A004',
        parent_code: 'P000',
        parent_value: Module_School_Management,
        value: TIMETABLE,
        translate: 'users:role.add.school-management.timetable',
    },
    {
        label: 'Student Category',
        code: 'A006',
        parent_code: 'P001',
        parent_value: Module_Student_Management,
        value: STUDENT_CATEGORY,
        translate: 'users:role.add.student-management.studentCategory',
    },
    {
        label: 'Student',
        code: 'A007',
        parent_code: 'P001',
        parent_value: Module_Student_Management,
        value: STUDENT,
        translate: 'users:role.add.student-management.student',
    },
    {
        label: 'Student Attendance',
        code: 'A008',
        parent_code: 'P001',
        parent_value: Module_Student_Management,
        value: STUDENT_ATTENDANCE,
        translate: 'users:role.add.student-management.studentAttendance',
    },
    {
        label: 'Role Management',
        code: 'A009',
        parent_code: 'P002',
        parent_value: Module_Employee_Management,
        value: ROLE_MANAGEMENT,
        translate: 'users:role.title',
    },
    {
        label: 'Employee Category',
        code: 'A010',
        parent_code: 'P002',
        parent_value: Module_Employee_Management,
        value: EMPLOYEE_CATEGORY,
        translate: 'users:role.add.employee-management.employeeCategory',
    },
    {
        label: 'Employee',
        code: 'A011',
        parent_code: 'P002',
        parent_value: Module_Employee_Management,
        value: EMPLOYEE,
        translate: 'users:role.add.employee-management.employee',
    },
    {
        label: 'Employee Attendance',
        code: 'A012',
        parent_code: 'P002',
        parent_value: Module_Employee_Management,
        value: EMPLOYEE_ATTENDANCE,
        translate: 'users:role.add.employee-management.employeeAttendance',
    },
    {
        label: 'Teacher Role',
        code: 'A013',
        parent_code: 'P002',
        parent_value: Module_Employee_Management,
        value: TEACHER_ROLE,
        translate: 'users:role.add.employee-management.teacherRole',
    },
    {
        label: 'Event',
        code: 'A014',
        parent_code: 'P003',
        parent_value: Module_Calendar_Management,
        value: EVENT,
        translate: 'users:role.add.calendar-management.event',
    },
    {
        label: 'Holiday',
        code: 'A015',
        parent_code: 'P003',
        parent_value: Module_Calendar_Management,
        value: HOLIDAY,
        translate: 'users:role.add.calendar-management.holiday',
    },
    {
        label: 'Drive',
        code: 'A016',
        parent_code: 'P004',
        parent_value: Module_Drive,
        value: DRIVE,
        translate: 'users:role.drive',
    },
    {
        label: 'Course',
        code: 'A017',
        parent_code: 'P005',
        parent_value: Module_Course_Management,
        value: COURSE,
        translate: 'users:role.add.course-management.course',
    },
    {
        label: 'Todo',
        code: 'A018',
        parent_code: 'P006',
        parent_value: Module_Task_Management,
        value: TODO,
        translate: 'users:role.add.task-management.todo',
    },
    {
        label: 'Chat',
        code: 'A019',
        parent_code: 'P007',
        parent_value: Module_Chat,
        value: CHAT,
        translate: 'users:role.add.chat',
    },
    {
        label: 'Exam',
        code: 'A021',
        parent_code: 'P009',
        parent_value: Module_Exam_Management,
        value: EXAM,
        translate: 'users:role.add.exam-management.exam',
    },
    {
        label: 'Result',
        code: 'A022',
        parent_code: 'P009',
        parent_value: Module_Exam_Management,
        value: RESULT,
        translate: 'users:role.add.exam-management.result',
    },
    {
        label: 'Question Bank',
        code: 'A023',
        parent_code: 'P009',
        parent_value: Module_Exam_Management,
        value: QUESTION_BANK,
        translate: 'users:role.add.exam-management.questionBank',
    },
    {
        label: 'Alumni',
        code: 'A025',
        parent_code: 'P010',
        parent_value: Module_Alumni,
        value: ALUMNI,
        translate: 'alumni:alumni',
    },
    {
        label: 'Moderate Post',
        code: 'A026',
        parent_code: 'P011',
        parent_value: Module_Report,
        value: MODERATE_POST,
        translate: 'alumni:postMod',
    },
    {
        label: 'Notification Management',
        code: 'A005',
        parent_code: 'P012',
        parent_value: Module_Notification,
        value: NOTIFICATION,
        translate: 'users:role.add.notification',
    },
    {
        label: 'Invite',
        code: 'A024',
        parent_code: 'P013',
        parent_value: Module_Invite,
        value: INVITE,
        translate: 'invite:invite',
    },
    {
        label: 'Gamification',
        code: 'A027',
        parent_code: 'P014',
        parent_value: Module_Gamification,
        value: GAMIFICATION,
        translate: 'Gamification',
    },
    {
        label: 'Report Card',
        code: 'A028',
        parent_code: 'P009',
        parent_value: Module_Exam_Management,
        value: REPORT_CARD,
        translate: 'Report Card',
    },
    {
        label: 'Grading Setting',
        code: 'A029',
        parent_code: 'P009',
        parent_value: Module_Exam_Management,
        value: GRADING_SETTING,
        translate: 'Grading Setting',
    },
    {
        label: 'Assets',
        code: 'A030',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: ASSET,
        translate: 'Assets',
    },
    {
        label: 'Products',
        code: 'A031',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: PRODUCT,
        translate: 'Products',
    },
    {
        label: 'Bank',
        code: 'A032',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: BANK,
        translate: 'Bank',
    },
    {
        label: 'Fee',
        code: 'A020',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: FEE,
        translate: 'users:role.add.fee-management.fee',
    },
    {
        label: 'Constant',
        code: 'A033',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: CONSTANT,
        translate: 'constant:constant',
    },
    {
        label: 'Income',
        code: 'A034',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: INCOME,
        translate: 'income:income',
    },
    {
        label: 'Expense',
        code: 'A035',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: EXPENSE,
        translate: 'expense:expense',
    },
    {
        label: 'Double Entry',
        code: 'A036',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: DOUBLE_ENTRY,
        translate: 'doubleentry:doubleentry',
    },
    {
        label: 'ACCOUNTING_REPORTS',
        code: 'A037',
        parent_code: 'P008',
        parent_value: Module_Accounting,
        value: ACCOUNTING_REPORTS,
        translate: 'reports:accountingReports',
    },
    {
        label: 'LIBRARY',
        code: 'A038',
        parent_code: 'P015',
        parent_value: Module_Library,
        value: LIBRARY,
        translate: 'library:library',
    },
]

export const school_management = all_access.filter((item) => item.parent_value == Module_School_Management)

export const student_management = all_access.filter((item) => item.parent_value == Module_Student_Management)

export const hr_management = all_access.filter((item) => item.parent_value == Module_Employee_Management)

export const calendar_management = all_access.filter((item) => item.parent_value == Module_Calendar_Management)

export const drive = all_access.filter((item) => item.parent_value == Module_Drive)

export const course_management = all_access.filter((item) => item.parent_value == Module_Course_Management)

export const task_management = all_access.filter((item) => item.parent_value == Module_Task_Management)

export const chat_management = all_access.filter((item) => item.parent_value == Module_Chat)

export const accouting_management = all_access.filter((item) => item.parent_value == Module_Accounting)

export const exam_management = all_access.filter((item) => item.parent_value == Module_Exam_Management)

export const alumni_management = all_access.filter((item) => item.parent_value == Module_Alumni)

export const report_management = all_access.filter((item) => item.parent_value == Module_Report)

export const notification_management = all_access.filter((item) => item.parent_value == Module_Notification)

export const invitation_management = all_access.filter((item) => item.parent_value == Module_Invite)

export const gamification_management = all_access.filter((item) => item.parent_value == Module_Gamification)

export const library_management = all_access.filter((item) => item.parent_value == Module_Library)

export const role_management = [
    { code: 'P000', data: school_management, value: Module_School_Management },
    { code: 'P001', data: student_management, value: Module_Student_Management },
    { code: 'P002', data: hr_management, value: Module_Employee_Management },
    { code: 'P003', data: calendar_management, value: Module_Calendar_Management },
    { code: 'P005', data: course_management, value: Module_Course_Management },
    { code: 'P006', data: task_management, value: Module_Task_Management },
    { code: 'P008', data: accouting_management, value: Module_Accounting },
    { code: 'P009', data: exam_management, value: Module_Exam_Management },
    { code: 'P010', data: alumni_management, value: Module_Alumni },
    { code: 'P004', data: drive, value: Module_Drive },
    { code: 'P014', data: gamification_management, value: Module_Gamification },
    { code: 'P007', data: chat_management, value: Module_Chat },
    { code: 'P012', data: notification_management, value: Module_Notification },
    { code: 'P013', data: invitation_management, value: Module_Invite },
    { code: 'P011', data: report_management, value: Module_Report },
    { code: 'P015', data: library_management, value: Module_Library },
]
