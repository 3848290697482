import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { redirectDomain } from '~/helper/constant/config'
import { redirect } from '~/helper/function/util'
import { signout, currentSchool, packageAdd } from '~/store'
import { getSchoolPackage } from '~/services/api'

type Props = {
    token: String,
    user: Object,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
}

export class AbstractMySchoolComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loading: false,
        }
        this._onSave = this._onSave.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this._handleChange = this._handleChange.bind(this)
    }

    logout(dispatch) {
        return new Promise(function (resolve, reject) {
            dispatch(signout())
            resolve(true)
        })
    }

    _handleChange(key, value) {
        this.setState({
            [key]: value,
        })
    }

    loadPackage() {
        getSchoolPackage(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const packages = response.data.package
                this.props.dispatch(packageAdd(packages.access))
            }
        })
    }

    _onSave(id) {
        if (id != this.props.school.id) {
            const token = this.props.token
            const userSchool = this.props.user.user_school.find((item) => item.id == id)
            if (typeof document != 'undefined') {
                this.logout(this.props.dispatch).then((response) => {
                    if (userSchool.school.domain) {
                        window.location = redirectDomain(userSchool.school.domain, token)
                    } else {
                        window.location = redirectDomain(userSchool.school.sub_domain, token)
                    }
                })
            } else {
                this.props.dispatch(currentSchool(userSchool))
                this.loadPackage()
                redirect(route.activity, this.props.history)
            }
        } else {
            redirect(route.activity, this.props.history)
        }
    }

    _onEdit(id) {
        redirect(route.admin.school.profile + '/' + id, this.props.history)
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        user: state.user,
        school: state.school,
    }
}
