import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { studentResult } from '~/services/api'

type Props = {
    token: Object,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    tableData: Array,
    post: Object,
}

export class AbstractStudentResultComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            post: {},
            downloadLink: null,
            fileName: null,
            tableData: [
                {
                    subject: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'classes:subject.title',
                    },
                    full_marks: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'exam:fullMarks',
                    },
                    pass_marks: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'exam:passMarks',
                    },
                    obtained_marks: {
                        field: 'text',
                        route: false,
                        sortable: false,
                        translate: 'exam:obtainedMarks',
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                        translate: 'common:status',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadResult = this.loadResult.bind(this)
    }

    componentDidMount() {
        this.loadResult()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadResult() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)

        studentResult(this.props.match.params.id, this.props.token).then((response) => {
            console.log("klasjflkd", response.data)
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let tableData = []
                let downloadLink = '#'
                let fileName = 'Report_Card.pdf'
                if (response.data.pdf != null) {
                    downloadLink = response.data.pdf.stream
                    fileName = response.data.pdf.filename
                }
                tableData.push(this.state.tableData[0])
                response.data.result.map((item, index) => {
                    let status = {
                        text: 'Fail',
                        translate: 'exam:fail',
                        badge: 'table-red',
                    }
                    if (item.is_pass) {
                        status = {
                            text: 'Pass',
                            translate: 'exam:pass',
                            badge: 'table-green',
                        }
                    }
                    const data = {
                        subject: {
                            text: item.exam.subject.name,
                        },
                        full_marks: {
                            text: item.exam.full_mark,
                        },
                        pass_marks: {
                            text: item.exam.pass_mark,
                        },
                        obtained_marks: {
                            text: item.marks,
                        },
                        status,
                    }
                    tableData.push(data)
                })
                this.setState({
                    tableData,
                    post: response.data.post,
                    downloadLink,
                    fileName,
                })
            }
            // let tableData = []
            // tableData.push(this.state.tableData[0])
            // let result = []
            // if (response.code == API_SUCCESS) {
            //     response.data.post.map((item, index) => {
            //         let status = {
            //             text: 'Draft',
            //             badge: 'table-red',
            //             modal: 'modal_status_' + item.id,
            //         }
            //         if (item.status == Status.ACTIVE) {
            //             status = {
            //                 text: 'Published',
            //                 badge: 'table-green',
            //                 modal: 'modal_status_' + item.id,
            //             }
            //         }
            //         let access_data = []
            //         item.class_access_information.map((classes) => {
            //             access_data.push(classes.name + ' - ' + classes.code)
            //         })
            //         const data = {
            //             result: {
            //                 text: item.title,
            //             },
            //             class: {
            //                 text: access_data,
            //                 badge: 'table-grey',
            //             },
            //             status,
            //         }
            //         if (this.props.school.user_type == UserType.Student) {
            //             data['view'] = {
            //                 text: 'View',
            //                 route: route.examination.result.result + '/' + item.parent_id,
            //             }
            //         } else {
            //             data['assign_marks'] = {
            //                 text: 'Manage',
            //                 route: route.examination.result.menu + '/' + item.parent_id,
            //             }
            //         }
            //         result.push(item)
            //         tableData.push(data)
            //     })
            //     this.setState({
            //         result,
            //         tableData,
            //     })
            // }
        })
    }
}

const AbstractStudentResult = withTranslation()(AbstractStudentResultComponent)

export { AbstractStudentResult }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
