import moment from 'moment'
import { Component } from 'react'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewTransaction } from '../../../../../services/api/accounting/report'

const defaultAccount = {
    label: 'Select Account',
    value: '',
}

const defaultCategory = [
    {
        label: 'Select Category',
        value: '',
    },
    {
        label: 'Invoice',
        value: 'Invoice',
    },
    {
        label: 'Bill',
        value: 'Bill',
    },
]

export class AbstractTransactionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            start_date: moment().startOf('month').toDate(),
            end_date: moment().endOf('month').toDate(),
            duration: null,
            chartOfAccounts: [],
            accountList: [defaultAccount],
            account: defaultAccount,
            categoryList: defaultCategory,
            category: defaultCategory[0],
            errors: {
                start_date: null,
                end_date: null,
            },
            transactions: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }
    componentDidMount() {
        this._onSearch()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const start_date =
                convertADtoBS(this.state.start_date) != null
                    ? convertADtoBS(this.state.start_date).slice(0, -3) + ' BS'
                    : 'Invalid Date Range'
            const end_date =
                convertADtoBS(this.state.end_date) != null
                    ? convertADtoBS(this.state.end_date).slice(0, -3) + ' BS'
                    : 'Invalid Date Range'

            this.setState({
                duration: start_date + ' || ' + end_date,
            })
        } else {
            const start_date = moment(this.state.start_date).format('yyyy-MM-DD')
            const end_date = moment(this.state.end_date).format('yyyy-MM-DD')
            this.setState({
                duration: start_date.slice(0, -3) + ' || ' + end_date.slice(0, -3),
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const start_date = moment(this.state.start_date).format('MMM-yyyy')
            const end_date = moment(this.state.end_date).format('MMM-yyyy')
            const account = this.state.account != null ? this.state.account.value : ''
            const category = this.state.category != null ? this.state.category.value : ''
            viewTransaction(start_date, end_date, account, category, this.props.token).then((response) => {
                this._handleChange('loading', false)
                const accountList = []
                const categoryList = []
                if (response.code == API_SUCCESS) {
                    console.log(response)
                    const accountData = response.data.account
                    // const categoryData = response.data.category
                    Object.keys(accountData).map((item) => {
                        accountList.push({
                            label: accountData[item],
                            value: item,
                        })
                    })
                    // Object.keys(categoryData).map((item) => {
                    //     categoryList.push({
                    //         label: categoryData[item],
                    //         value: item,
                    //     })
                    // })
                    this.setState(
                        {
                            accountList,
                            // categoryList,
                            transactions: response.data.transactions,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
