import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { display_mode, font_size, title_position } from '~/helper/constant/reportcard'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { viewSingleProductCategory, updateProductCategory} from '~/services/api'
import {category_type} from '~/helper/constant/category'


type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantCategoryEditComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            name:"",
            catId:'',
            type:category_type,
            defaultType: '',
            selectedType:category_type[0].value,
            category_list:category_type,
            color:"#000000",
            errors:{
                name:null,
                type:null,
                color:null,
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount(){
        this.loadCategory()

    }

    _handleChange(key, data){
        this.setState({[key]: data})

    }

    loadCategory(){
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewSingleProductCategory(this.props.match.params.id, this.props.token).then((response)=>{
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                const item = response.data.category
                this.setState({
                    name:item.name,
                    defaultType: category_type.find((e)=>e.value == item.type),
                    selectedType: item.type,
                    color: item.color,
                    catId: item.id
        
                })
            }
        })
    }

    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors[item] != null)
        if(validate.length == 0){
            const data ={
                name: this.state.name,
                type:this.state.selectedType,
                color:this.state.color
            }
            this._handleChange('loading', true)
            updateProductCategory(this.state.catId, data, this.props.token).then((response)=>{
                this._handleChange('loading', false)
                if(response.code == API_SUCCESS){
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.constant.category.view)
                }else{
                    toastMessage('error', response.data)
                    if(response.data){
                        if('errors' in response.data){
                            this.setState({errors: response.data.errors})
                        }
                    }
                }
            })

        }
    }


}

export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}