import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractUserProfileComponent, mapStateToProps } from './abstract'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { gender } from '~/helper/constant/gender'
import { Alumni } from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'
import Table from 'react-super-responsive-table'
import { getReadableFileSize, getMediaIcon } from '~/helper/function'

class UserProfileComponent extends AbstractUserProfileComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getResources() {
        const { t } = this.props

        const media = this.state.media.map((item, index) => {
            return (
                <div key={index} className="circle-category-list">
                    <div className="user-img name-folder">
                        <a href={route.gallery + '/' + item.id} target="_blank">
                            <span className="mt-2">{getMediaIcon(item.media_type)}</span>
                        </a>
                    </div>
                    <div className="circle-user">
                        <a href={route.gallery + '/' + item.id} target="_blank">
                            <h5>{item.name}</h5>
                        </a>
                        <span>{getReadableFileSize(item.size)}</span>
                    </div>
                </div>
            )
        })
        return media
    }

    getTotalXP() {
        let totalXP = 0
        if (this.state.school != null) {
            this.state.school.leaderboard.map((item) => {
                totalXP = totalXP + item.points
            })
        }
        return totalXP
    }

    getMedal() {
        if (this.state.school != null) {
            const medal = []
            const totalMedalXP = []
            const totalMedal = []
            let totalXP = 0
            this.state.school.leaderboard.map((item) => {
                if (!medal.find((el) => el.id == item.medal_id)) {
                    medal.push(item.medal)
                }
                if (totalMedalXP[item.medal_id] == null) {
                    totalMedalXP[item.medal_id] = item.points
                } else {
                    totalMedalXP[item.medal_id] = totalMedalXP[item.medal_id] + item.points
                }
                if (totalMedal[item.medal_id] == null) {
                    totalMedal[item.medal_id] = 1
                } else {
                    totalMedal[item.medal_id] = totalMedal[item.medal_id] + 1
                }
            })
            const data = medal.map((item, index) => {
                const media_url = item.gallery.media[0].media_url
                return (
                    <div key={index} className="circle-category-list">
                        <div className="user-img name-folder">
                            <img src={media_url} />
                        </div>
                        <div className="circle-user">
                            <h5>{totalMedalXP[item.id]} XP</h5>
                            <span>
                                {item.name} x {totalMedal[item.id]}
                            </span>
                        </div>
                    </div>
                )
            })
            return data
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.reset}>{t('exam:profile')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">
                                    <div className="sidebar-icon arrow-left cursor" onClick={() => this.props.history.goBack()}>
                                        {SVG.sidebarScroll('#333', 1)}
                                    </div>
                                    &nbsp;
                                    {t('exam:profile')} :
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        {this.state.user == null && (
                            <p className="no-data">
                                {SVG.nodata()}
                                {t('common:noUsersSearched')}.
                            </p>
                        )}

                        {this.state.user != null && (
                            <div className="folder-page">
                                <div className="row clearfix">
                                    <div className="col-md-3">
                                        <div className="row clearfix">
                                            <div className="float-left course-your col-md-12">
                                                <div className="folder-list clearfix">
                                                    {this.state.user.user_detail != null && this.state.user.user_detail.profile != null && <img src={this.state.user.user_detail.profile} />}
                                                    {this.state.user.user_detail != null && this.state.user.user_detail.profile == null && <div className="role-user-img superadmin">{this.state.user.name != null ? this.state.user.name.charAt(0) : 'C'}</div>}
                                                    {this.state.user.user_detail == null && <div className="role-user-img superadmin">{this.state.user.name != null ? this.state.user.name.charAt(0) : 'C'}</div>}
                                                </div>
                                            </div>
                                            {this.state.school != null && this.state.school.leaderboard.length > 0 && (
                                                <div className="col-md-12">
                                                    <div className="todo-box">
                                                        <h5>Achievements - {this.getTotalXP()} XP </h5>
                                                        <div className="box-content-list form-group">{this.getMedal()}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row clearfix">
                                            <div className="float-left course-your col-md-12">
                                                <div className="folder-list dashboard">
                                                    <div className="view-progress mt-0">
                                                        <h5>{t('employee:employee.info.user-info.userInfo')} :</h5>
                                                        <hr />
                                                        <div className="table table-responsive">
                                                            <table className="table-bordered dashboard">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">{t('attendance:regNo')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.reg_no}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:name')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.name}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:email')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.email}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:contact')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.contact}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('fee:dob')} :</strong>
                                                                            <br />
                                                                            <br />

                                                                            {this.state.user.dob}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:gender')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null && gender.find((item) => item.value == this.state.user.user_detail.gender) != null && gender.find((item) => item.value == this.state.user.user_detail.gender).label}
                                                                            {this.state.user.user_detail == null && 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:bloodgroup')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.blood_group : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:fathername')}:</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.father_name : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:mothername')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.mother_name : 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:nationality')} :</strong>
                                                                            <br />
                                                                            <br />

                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.nationality : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:permanentaddress')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.permanent_address : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">{t('common:temporaryaddress')} :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.temporary_address : 'N/A'}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.school != null && this.state.school.user_type == Alumni && (
                                            <Fragment>
                                                {this.state.qualification.length > 0 && (
                                                    <div className="row clearfix">
                                                        <div className="float-left course-your col-md-12">
                                                            <div className="folder-list dashboard">
                                                                <div className="view-progress mt-0">
                                                                    <h5>Academic Qualification :</h5>
                                                                    <hr />
                                                                    <div className="table-all table-responsive">
                                                                        <ReactTable.Table className="table">
                                                                            <ReactTable.Thead>
                                                                                <ReactTable.Tr>
                                                                                    <ReactTable.Th>S.No</ReactTable.Th>
                                                                                    <ReactTable.Th>From</ReactTable.Th>
                                                                                    <ReactTable.Th>To</ReactTable.Th>
                                                                                    <ReactTable.Th>Degree</ReactTable.Th>
                                                                                </ReactTable.Tr>
                                                                            </ReactTable.Thead>
                                                                            <ReactTable.Tbody>
                                                                                {this.state.qualification.map((item, index) => {
                                                                                    return (
                                                                                        <ReactTable.Tr>
                                                                                            <ReactTable.Td>{index + 1}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.from}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.to}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.description}</ReactTable.Td>
                                                                                        </ReactTable.Tr>
                                                                                    )
                                                                                })}
                                                                            </ReactTable.Tbody>
                                                                        </ReactTable.Table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {this.state.experience.length > 0 && (
                                                    <div className="row clearfix">
                                                        <div className="float-left course-your col-md-12">
                                                            <div className="folder-list dashboard">
                                                                <div className="view-progress mt-0">
                                                                    <h5>Work Experience :</h5>
                                                                    <hr />
                                                                    <div className="table-all table-responsive">
                                                                        <ReactTable.Table className="table">
                                                                            <ReactTable.Thead>
                                                                                <ReactTable.Tr>
                                                                                    <ReactTable.Th>S.No</ReactTable.Th>
                                                                                    <ReactTable.Th>From</ReactTable.Th>
                                                                                    <ReactTable.Th>To</ReactTable.Th>
                                                                                    <ReactTable.Th>Designation</ReactTable.Th>
                                                                                    <ReactTable.Th>Company</ReactTable.Th>
                                                                                </ReactTable.Tr>
                                                                            </ReactTable.Thead>
                                                                            <ReactTable.Tbody>
                                                                                {this.state.experience.map((item, index) => {
                                                                                    return (
                                                                                        <ReactTable.Tr>
                                                                                            <ReactTable.Td>{index + 1}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.from}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.isPresent ? 'Present' : item.to}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.description}</ReactTable.Td>
                                                                                            <ReactTable.Td>{item.company}</ReactTable.Td>
                                                                                        </ReactTable.Tr>
                                                                                    )
                                                                                })}
                                                                            </ReactTable.Tbody>
                                                                        </ReactTable.Table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                        {this.state.media.length > 0 && (
                                            <div className="row clearfix">
                                                <div className="float-left course-your col-md-12">
                                                    <div className="folder-list dashboard">
                                                        <div className="view-progress mt-0">
                                                            <h5>Documents :</h5>
                                                            <hr />
                                                            <div className="box-content-list form-group">{this.getResources()}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const UserProfile = withTranslation()(connect(mapStateToProps)(UserProfileComponent))

export { UserProfile }
