import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewSchool = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addSchool = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSchool = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editSchool = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleSchool = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
