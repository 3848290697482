import moment from 'moment'
import { CircularProgressbar } from 'react-circular-progressbar'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { convertADtoBS } from '../../../../../helper/function'
import { AbstractCourseViewStudentComponent, mapStateToProps } from './abstract'
import React, { Fragment } from 'react'

class CourseViewStudentComponent extends AbstractCourseViewStudentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getSubject = this.getSubject.bind(this)
        this.getCourse = this.getCourse.bind(this)
        this.getCourseDetail = this.getCourseDetail.bind(this)
    }

    getSubject() {
        const data = this.state.subject.map((item, index) => {
            return (
                <li key={index}>
                    <a
                        onClick={() => this._handleChange('activeTab', index)}
                        className={index == this.state.activeTab ? 'active' : ''}
                        id={'subject-' + index}
                        data-toggle="tab"
                        href={'#subject_' + index}
                        role="tab"
                        aria-controls="subject"
                        aria-selected="false"
                    >
                        {item.name}
                    </a>
                </li>
            )
        })
        return data
    }

    getCourse() {
        const data = this.state.subject.map((item, index) => {
            return (
                <div
                    key={index}
                    className={
                        index == this.state.activeTab
                            ? 'tab-pane fade show active folder-page course-folder row'
                            : 'tab-pane fade show folder-page course-folder row'
                    }
                    id={'subject_' + index}
                    role="tabpanel"
                    aria-labelledby={'subject-' + index}
                >
                    <div className="clearfix">{this.getCourseDetail(item.id, index)}</div>
                </div>
            )
        })
        return data
    }

    getCourseDetail(id, courseindex) {
        const { t } = this.props
        let data = this.state.course
            .filter((item) => {
                const today = moment(moment().format('yyyy-MM-DD'), 'yyyy-MM-DD')
                const dueDate = moment(item.to_date, 'yyyy-MM-DD')
                if (
                    item.subject_id == id &&
                    (!item.course.available_after_due ||
                        (today.isSameOrBefore(dueDate) && item.course.available_after_due))
                ) {
                    return true
                } else {
                    return false
                }
            })
            .map((item, index) => {
                let completedPercent = parseInt((item.completedCourseContentCount / item.courseContentCount) * 100)
                if (completedPercent > 100) {
                    completedPercent = 100
                }
                const today = moment(moment().format('yyyy-MM-DD'), 'yyyy-MM-DD')
                const dueDate = moment(item.to_date, 'yyyy-MM-DD')
                const startDate = moment(item.from_date, 'yyyy-MM-DD')
                const isOpen =
                    !item.course.available_after_due ||
                    (today.isSameOrBefore(dueDate) && item.course.available_after_due)

                const isUpcoming = startDate.isAfter(today)
                return (
                    <div className="col-md-6 float-left" key={index}>
                        <div className="folder-list no-transition insight-list">
                            <div className="clearfix">
                                <span className="new-insight">
                                    <CircularProgressbar
                                        value={completedPercent}
                                        text={completedPercent + `%`}
                                        strokeWidth={9}
                                    />
                                </span>
                                <a
                                    // href={
                                    //     isOpen && !isUpcoming
                                    //         ? route.course.student.module + '/' + this.state.class_id + '/' + item.id
                                    //         : 'javascript:void()'
                                    // }
                                    href={route.course.student.module + '/' + this.state.class_id + '/' + item.id}
                                    className="folder-name"
                                >
                                    {item.title}
                                    <span>{item.restriction == 1 ? 'Private' : 'Public'}</span>
                                    <span>
                                        {isOpen && (
                                            <Fragment>
                                                {isUpcoming && (
                                                    <div className="table-status table-blue">
                                                        Releasing in {startDate.diff(today, 'd')} day/s
                                                    </div>
                                                )}
                                                {!isUpcoming && (
                                                    <Fragment>
                                                        {t('student_panel:view.dueOn')}
                                                        {this.props.school != null &&
                                                            this.props.school.school != null &&
                                                            this.props.school.school.date_format == 'BS' && (
                                                                <ins>
                                                                    {convertADtoBS(item.to_date) != null
                                                                        ? convertADtoBS(item.to_date)
                                                                        : 'Invalid Date Range'}
                                                                </ins>
                                                            )}
                                                        {this.props.school != null &&
                                                            this.props.school.school != null &&
                                                            this.props.school.school.date_format == 'AD' && (
                                                                <ins>{item.to_date}</ins>
                                                            )}
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        {!isOpen && (
                                            <div className="table-status table-red">{t('common:modal close')}</div>
                                        )}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            })
        if (data == '' || data.length == 0) {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('student_panel:view.noCourseFound')}.
                </p>
            )
        }
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>Course</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">
                                    {this.state.classes != null ? this.state.classes.name : 'Others'}
                                </span>
                                <h5 className="main-title heading5">{t('student_panel:view.course-title')}</h5>
                            </div>
                        </div>
                    </div>
                    {this.state.subject.length > 0 && (
                        <div className="event-accordion">
                            <div className="role-pop-head">
                                <ul className="nav" id="myTab" role="tablist">
                                    {this.getSubject()}
                                    <li>
                                        <a
                                            onClick={() => this._handleChange('activeTab', 'other')}
                                            className={'other' == this.state.activeTab ? 'active' : ''}
                                            id="subject-other"
                                            data-toggle="tab"
                                            href="#subject_other"
                                            role="tab"
                                            aria-controls="subject"
                                            aria-selected="false"
                                        >
                                            {t('student_panel:view.others')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="role-pop-content">
                                <div className="tab-content" id="myTabContent">
                                    {this.getCourse()}
                                    <div
                                        className={
                                            'other' == this.state.activeTab
                                                ? 'tab-pane fade show active folder-page course-folder row'
                                                : 'tab-pane fade show folder-page course-folder row'
                                        }
                                        id="subject_other"
                                        role="tabpanel"
                                        aria-labelledby="subject-other"
                                    >
                                        {this.getCourseDetail(null, -1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const CourseViewStudent = withTranslation()(connect(mapStateToProps)(CourseViewStudentComponent))

export { CourseViewStudent }
