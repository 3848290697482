import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewEmployeeCategory = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee-category', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeEmployeeCategory = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee-category/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeEmployeeCategoryBySchool = (schoolId) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/employee-category/' + schoolId, null).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addEmployeeCategory = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee-category/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getEmployeeCategory = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee-category/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editEmployeeCategory = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('employee-management/employee-category/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleEmployeeCategory = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('employee-management/employee-category/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
