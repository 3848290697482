import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractFeeViewStudentComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import Select from 'react-select'
import { Table } from '~/components/table'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import DatePicker from 'react-datepicker'
import { UserFilter } from '~/components/userfilter'
import { Loading } from '~/components/loader'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../helper/function'

class FeeViewStudentComponent extends AbstractFeeViewStudentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="fee" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.student_fee.menu}>{t('fee:fee')} > </a>
                            </li>
                            <li>{t('fee:upcoming_payment')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title">{t('fee:upcoming_payment')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="table-all table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="info-one">
                                                    <span>{t('fee:date')}</span>
                                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <ins>{convertADtoBS(this.state.currentDate.toString()) != null ? convertADtoBS(this.state.currentDate.toString()) : 'Invalid Date Range'}</ins>}
                                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && <ins>{this.state.currentDate.toString()}</ins>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="info-one">
                                                    <span>{t('fee:current_due')}</span>
                                                    <ins>{this.props.currency + ' ' + this.state.currentDue.toFixed(2)}</ins>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="info-one">
                                                    <span>{t('fee:previous_due')}</span>
                                                    <ins>{this.props.currency + ' ' + this.state.previousDue.toFixed(2)}</ins>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="info-one">
                                                    <span>{t('fee:payableAmount')}</span>
                                                    <ins>{this.props.currency + ' ' + this.state.totalDue.toFixed(2)}</ins>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="main-form fee-table">
                        <Table data={this.state.billSchedule} message="fee:upcomingPayment_msg" />
                    </div>
                </div>
            </div>
        )
    }
}

const FeeViewStudent = withTranslation()(connect(mapStateToProps)(FeeViewStudentComponent))

export { FeeViewStudent }
