import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractRegisterComponent, mapStateToProps } from './abstract'
import { Input, Button, Checkbox } from '~/components/form'
import { LoginAnimation } from '~/components/animation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as UserType from '~/helper/constant/user_type'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import Select from 'react-select'
class RegisterComponent extends AbstractRegisterComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setDob = this._setDob.bind(this)
        this._setParentDob = this._setParentDob.bind(this)
        this._setCategory = this._setCategory.bind(this)
        this._setBatch = this._setBatch.bind(this)
        this._setGroup = this._setGroup.bind(this)
        this._setCompany = this._setCompany.bind(this)
        this._setDesignation = this._setDesignation.bind(this)
    }

    _setDob(data) {
        this._handleChange('dob', data)
    }

    _setParentDob(data) {
        this._handleChange('parent_dob', data)
    }

    _setCategory(data) {
        this.setState({
            selectedUserCategory: data,
            user_category_id: data.value,
        })
    }

    _setBatch(data) {
        this.setState({
            selectedBatch: data,
            alumni_batch_id: data.value,
        })
    }

    _setGroup(data) {
        this.setState({
            selectedGroup: data,
            alumni_group_id: data.value,
        })
    }

    _setCompany(data) {
        this.setState({
            selectedCompany: data,
            alumni_company_id: data.value,
        })
    }

    _setDesignation(data) {
        this.setState({
            selectedDesignation: data,
            alumni_designation_id: data.value,
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="register-content setup-main">
                    <div className="row clearfix">
                        <div className="col-md-6 offset-md-3">
                            <div className="school-detail">
                                <img src="/assets/images/mobile-logo.png" />
                            </div>
                            <div className="setup-container">
                                <h5 className="text-center login-title">Register:</h5>
                                <hr />
                                {this.state.remarks != null && (
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <div className="alert alert-primary" role="alert">
                                                <span className="text-left">{this.state.remarks}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <form>
                                    <div className="row clearfix">
                                        <div className="col-md-12 text-left">Fill your information</div>
                                    </div>
                                    <hr />

                                    <div className="row clearfix">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('common:email')} *</label>
                                                <Input onChange={this._handleChange} name="email" placeholder={t('common:email')} disabled="disable" type="text" value={this.state.email} />
                                                {this.state.errors.email && <label className="error text-danger setup-error text-left">{this.state.errors.email}</label>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('common:name')} *</label>
                                                <Input onChange={this._handleChange} name="name" placeholder={t('common:name')} type="text" value={this.state.name} />
                                                {this.state.errors.name && <label className="error text-danger setup-error text-left">{this.state.errors.name}</label>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('common:contact')} *</label>
                                                <Input onChange={this._handleChange} name="contact" placeholder={t('common:contact')} type="text" value={this.state.contact} />
                                                {this.state.errors.contact && <label className="error text-danger setup-error text-left">{this.state.errors.contact}</label>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-title text-left">{t('student:student.add.dobLabel')} *</label>
                                                <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._setDob} selected={this.state.dob} placeholderText={t('student:student.add.dobLabel')} />
                                                {this.state.errors.dob && <label className="error text-danger setup-error text-left">{this.state.errors.dob}</label>}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.isCategory == true && (
                                        <div className="row clearfix">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-title text-left">Category *</label>
                                                    <Select name="user_category_id" options={this.state.userCategoryList} placeholder="Category" searchable={true} value={this.state.selectedUserCategory} onChange={this._setCategory} />
                                                    {this.state.errors.user_category && <label className="error text-danger setup-error text-left">{this.state.errors.user_category}</label>}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.isParent == true && (
                                        <Fragment>
                                            <hr />
                                            <div className="row clearfix">
                                                <div className="col-md-12 text-left">Fill your parent's information</div>
                                            </div>
                                            <hr />

                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Parent Email *</label>
                                                        <Input onChange={this._handleChange} name="parent_email" placeholder="Email" type="text" value={this.state.parent_email} />
                                                        {this.state.errors.parent_email && <label className="error text-danger setup-error text-left">{this.state.errors.parent_email}</label>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Parent Name *</label>
                                                        <Input onChange={this._handleChange} name="parent_name" placeholder="Full Name" type="text" value={this.state.parent_name} />
                                                        {this.state.errors.parent_name && <label className="error text-danger setup-error text-left">{this.state.errors.parent_name}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Parent Contact *</label>
                                                        <Input onChange={this._handleChange} name="parent_contact" placeholder="Contact" type="text" value={this.state.parent_contact} />
                                                        {this.state.errors.parent_contact && <label className="error text-danger setup-error text-left">{this.state.errors.parent_contact}</label>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Parent DOB *</label>
                                                        <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._setParentDob} selected={this.state.parent_dob} placeholderText="Date of Birth" />
                                                        {this.state.errors.parent_dob && <label className="error text-danger setup-error text-left">{this.state.errors.parent_dob}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Relation *</label>
                                                        <Input onChange={this._handleChange} name="relation" placeholder="Relation" type="text" value={this.state.relation} />
                                                        {this.state.errors.relation && <label className="error text-danger setup-error text-left">{this.state.errors.relation}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                    {this.state.user_type == UserType.Alumni && (
                                        <Fragment>
                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Batch *</label>
                                                        <Select name="alumni_batch_id" options={this.state.batchList} placeholder="Batch" searchable={true} value={this.state.selectedBatch} onChange={this._setBatch} />
                                                        {this.state.alumni_errors.alumni_batch_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_batch_id}</label>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Faculty *</label>
                                                        <Select name="alumni_group_id" options={this.state.groupList} placeholder="Faculty" searchable={true} value={this.state.selectedGroup} onChange={this._setGroup} />
                                                        {this.state.alumni_errors.alumni_group_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_group_id}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Company *</label>
                                                        <Select name="alumni_company_id" options={this.state.companyList} placeholder="Company" searchable={true} value={this.state.selectedCompany} onChange={this._setCompany} />
                                                        {this.state.alumni_errors.alumni_company_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_company_id}</label>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-title text-left">Designation *</label>
                                                        <Select name="alumni_designation_id" options={this.state.designationList} placeholder="Designation" searchable={true} value={this.state.selectedDesignation} onChange={this._setDesignation} />
                                                        {this.state.alumni_errors.alumni_designation_id && <label className="error text-danger setup-error text-left">{this.state.alumni_errors.alumni_designation_id}</label>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                    <hr />
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="text-center">
                                                <Button onClick={this.register} type="button" text="Register" main={true} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Register = withTranslation()(connect(mapStateToProps)(RegisterComponent))

export { Register }
