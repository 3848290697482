import { Component } from 'react'

type Props = {
    onChange: any,
    type: string,
    value: string,
    placeholder: string,
    style: any,
    name: string,
    className: string,
    id: string,
    disabled: string,
    rows: Integer,
    onKeyDown: any,
}

type State = {
    value: string,
}

export class AbstractTextAreaComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            value: this.props.value,
        }
        this._onChange = this._onChange.bind(this)
        this._keyDown = this._keyDown.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value })
            if (this.props.onChange) {
                this.props.onChange(this.props.name, this.props.value)
            }
        }
    }

    _onChange(e) {
        if (typeof document != 'undefined') {
            this.setState({ value: e.target.value })
            if (this.props.onChange) {
                this.props.onChange(e.target.name, e.target.value)
            }
        } else {
            this.setState({ value: e })
            if (this.props.onChange) {
                this.props.onChange(this.props.name, e)
            }
        }
    }

    _keyDown(e) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e)
        }
    }
}
