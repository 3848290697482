import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractQuestionComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as QuestionType from '~/helper/constant/question_type'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import Select from 'react-select'
import Files from 'react-files'
import { getBase64, getReadableFileSize } from '~/helper/function'
import * as ReactTable from 'react-super-responsive-table'
import { Fragment } from 'react'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import { redirect } from '~/helper/function/util'
import $ from 'jquery'
class QuestionComponent extends AbstractQuestionComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getEditModal = this.getEditModal.bind(this)
        this.getQuestionModal = this.getQuestionModal.bind(this)
        this.getAnswerModal = this.getAnswerModal.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectType = this._selectType.bind(this)
        this.previewDocument = this.previewDocument.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this._setAnswer = this._setAnswer.bind(this)
        this._setCorrectAnswer = this._setCorrectAnswer.bind(this)
        this.getQuestion = this.getQuestion.bind(this)
        this.getAnswers = this.getAnswers.bind(this)
        this.getMedia = this.getMedia.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getDeleteQuestionModal = this.getDeleteQuestionModal.bind(this)
        this.getQuestionList = this.getQuestionList.bind(this)
        this.getQuestionListModal = this.getQuestionListModal.bind(this)
        this._deleteQuestionBank = this._deleteQuestionBank.bind(this)
        this._deleteQuestion = this._deleteQuestion.bind(this)
        this._selectQuestion = this._selectQuestion.bind(this)
        this._onSave = this._onSave.bind(this)
        this._onSaveQuestion = this._onSaveQuestion.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentDidUpdate(prevProps) {
        if (this.state.loaded) {
            this.clearState()
        }
    }

    _onSave(e) {
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_edit').trigger('click')
            }
        })
    }

    _onSaveQuestion(e) {
        super._onSaveQuestion(e).then((response) => {
            if (response) {
                $('#close_question_add').trigger('click')
            }
        })
    }

    _deleteQuestionBank(e) {
        super._deleteQuestionBank.then((response) => {
            if (response) {
                $('#close_delete').trigger('click')
                redirect(route.examination.question_bank.view + '/' + (this.state.classes.length > 0 ? this.state.classes[0].name : 0), this.props.history)
            }
        })
    }

    _deleteQuestion(e) {
        const link = e.target.getAttribute('link')
        $('#close_question_del_' + link).trigger('click')
        super._deleteQuestion(e)
    }

    _selectQuestion(e) {
        const link = e.target.getAttribute('link')
        $('#close_select_question_' + link).trigger('click')
        super._selectQuestion(e)
    }

    _selectEmployee(data) {
        this._handleChange('employee', data)
    }

    _selectStudent(data) {
        this._handleChange('student', data)
    }

    _selectSubject(data) {
        this._handleChange('selected_subject', data)
    }

    _selectType(data) {
        this._handleChange('defaultType', data)
        this._handleChange('question_type', data.value)
        if (data.value == 0) {
            this._handleChange('answers', [])
        } else {
            this._addAnswer()
        }
    }

    _setAnswer(id, value) {
        const answers = this.state.answers.map((item, index) => {
            if (item.count == id) {
                item.answers = value
            }
            return item
        })
        this._handleChange('answers', answers)
    }

    _setCorrectAnswer(id, value) {
        const answers = this.state.answers.map((item, index) => {
            if (item.count == id) {
                item.is_correct = true
            } else {
                item.is_correct = false
            }
            return item
        })
        this._handleChange('answers', answers)
    }

    _uploadFile(data) {
        const media = this.state.media
        const uploads = this.state.uploads
        const uploadedFile = data[data.length - 1]
        getBase64(uploadedFile).then((file) => {
            if (file.error == null) {
                media.push(file)
                uploads.push(data)
                this._handleChange('media', media)
                this._handleChange('uploads', data)
            } else {
                console.log('Max File Exceeded!')
            }
        })
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }

    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={'file_remove_' + item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    getEditModal() {
        const { t } = this.props
        return (
            <div id="modal_edit" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:questionbank:editQuestionBank')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:title')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('common:title')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('exam:questionbank:questionBankDescription')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="form-check">
                                            <label className="form-title" htmlFor="private">
                                                {t('exam:questionbank.private')}
                                            </label>
                                            <Checkbox id="private" onChange={this._handleChange} className="" name="isPrivate" value={this.state.isPrivate} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.isPrivate == true && (
                                    <div className="add-option-exam">
                                        <UserFilter selectedClass={this.state.selectedClass} hideClass={this.state.hideClass} hideSection={this.state.hideSection} showSubject={this.state.showSubject} resetOnChange={false} selectedStudent={this.state.student} selectedEmployee={this.state.employee} onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} onSubjectSelect={this._selectSubject} />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('updateBtn')} main={true} />
                                    <button id="close_edit" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getAnswerModal() {
        const { t } = this.props
        const data = this.state.answers.map((item, index) => {
            return (
                <ReactTable.Tr key={index}>
                    <ReactTable.Td>
                        <Input name={'answer_' + item.count} onChange={(key, value) => this._setAnswer(item.count, value)} placeholder={t('exam:questionbank.answers')} type="text" value={item.answers} />
                        {this.state.answer_errors.length > 0 && this.state.answer_errors[index] && <label className="error text-danger">{this.state.answer_errors[index]}</label>}
                    </ReactTable.Td>
                    <ReactTable.Td>
                        <Checkbox onChange={(key, value) => this._setCorrectAnswer(item.count, value)} className="answer-checkbox" name={'correct_' + item.count} value={item.is_correct} />
                    </ReactTable.Td>
                    <ReactTable.Td className="answer-head">
                        <button type="button" className="answer-del-button" onClick={(e) => this._removeAnswer(item.count)}>
                            ×
                        </button>
                    </ReactTable.Td>
                </ReactTable.Tr>
            )
        })
        return data
    }

    getQuestionModal() {
        const { t } = this.props
        return (
            <div id="modal_question_add" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:questionbank:addquestion')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('exam:questionbank.question')} *</label>
                                        <Input name="question_title" onChange={this._handleChange} placeholder={t('exam:questionbank.question')} type="text" value={this.state.question_title} />
                                        {this.state.question_errors.question_title && <label className="error text-danger">{this.state.question_errors.question_title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:questionbank.questionType')} *</label>
                                        <Select name="question_type" options={QuestionType.all} placeholder={t('exam:questionbank.questionType')} searchable={true} onChange={this._selectType} value={this.state.defaultType} />
                                        {this.state.question_errors.question_type && <label className="error text-danger">{this.state.question_errors.question_type}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:marks')} *</label>
                                        <Input name="question_marks" onChange={this._handleChange} placeholder={t('exam:marks')} value={this.state.question_marks} />
                                        {this.state.question_errors.question_marks && <label className="error text-danger">{this.state.question_errors.question_marks}</label>}
                                    </div>
                                </div>
                                {this.state.question_type == QuestionType.Objective && (
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <ReactTable.Table className="table table-bordered">
                                                <ReactTable.Thead>
                                                    <ReactTable.Tr>
                                                        <ReactTable.Td className="answer-head">
                                                            {t('exam:questionbank.answers')}
                                                            {this.state.objective_errors != '' && (
                                                                <Fragment>
                                                                    <br />
                                                                    <label className="error text-danger">{this.state.objective_errors}</label>
                                                                </Fragment>
                                                            )}
                                                        </ReactTable.Td>
                                                        <ReactTable.Td className="answer-head">{t('exam:questionbank.correct')}</ReactTable.Td>
                                                        <ReactTable.Td className="answer-head">
                                                            <button type="button" className="answer-add-button" onClick={this._addAnswer}>
                                                                +
                                                            </button>
                                                        </ReactTable.Td>
                                                    </ReactTable.Tr>
                                                </ReactTable.Thead>
                                                <ReactTable.Tbody>{this.getAnswerModal()}</ReactTable.Tbody>
                                            </ReactTable.Table>
                                        </div>
                                    </div>
                                )}
                                <div className="row clearfix">
                                    <div className="col-md-12 float-left">
                                        <label className="form-title">{t('exam:questionbank.questionResources')}</label>
                                        <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                            {this.previewDocument()}
                                            {this.state.media.length == 0 && (
                                                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV todo-file-upload">
                                                    <Files className="files-dropzone" onChange={this._uploadFile} multiple={false} accepts={['image/*', 'video/*', 'audio/*', '.pdf']} maxFileSize={200000000} minFileSize={0} clickable>
                                                        {t('exam:questionbank.addResources')}
                                                    </Files>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this._onSaveQuestion} type="submit" text={t('submit')} main={true} />
                                    <button id="close_question_add" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getQuestion() {
        const { t } = this.props
        const data = this.state.question.map((item, index) => {
            const media = item.gallery != null ? item.gallery.media : null
            const answers = item.answer
            return (
                <div className="accordion-list" key={index}>
                    <div id={'heading_' + index}>
                        {this.state.question_bank != null && this.state.question_bank.question_exam_count == 0 && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && (
                            <button type="button" data-toggle="modal" data-target={'#modal_question_delete_' + item.id} className="answer-del-button absolute collapse-body">
                                ×
                            </button>
                        )}
                        <h5 data-toggle="collapse" data-target={'#collapse_' + index} aria-expanded={index == 0 ? 'true' : 'false'} aria-controls={'collapse_' + index}>
                            <span>Q{index + 1}</span> {item.questions}
                        </h5>
                    </div>
                    <div id={'collapse_' + index} className={index == 0 ? 'accordion-content collapse-body objective-question collapse show' : 'accordion-content collapse-body objective-question collapse'} aria-labelledby={'heading_' + index} data-parent="#accordion">
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group">
                                {item.type == QuestionType.Subjective && <TextArea placeholder={t('exam:subAns')} type="text" className="answer-textarea-height" disabled="disabled" />}
                                {item.type == QuestionType.Objective && this.getAnswers(answers)}
                            </div>
                            <div className="col-sm-6 float-right text-center form-group">{this.getMedia(media)}</div>
                        </div>
                        <em className="info text-right">
                            {t('exam:marks')} = {item.marks}
                        </em>
                    </div>
                </div>
            )
        })
        return data
    }

    getAnswers(answers) {
        if (answers != null) {
            const data = answers.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <div className="question-radio">
                            {item.is_correct == true && <input defaultChecked={true} type="radio" id={'question_' + item.question_id} name={'question_' + item.question_id} value={item.id} />}
                            {item.is_correct == false && <input type="radio" disabled="disabled" id={'question_' + item.question_id} name={'question_' + item.question_id} value={item.id} />}
                            <label>{item.answers}</label>
                            <br />
                        </div>
                    </Fragment>
                )
            })
            return data
        }
    }

    getMedia(media) {
        if (media != null) {
            const data = media.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {item.media_type.includes('image') && <img src={item.media_url} />}
                        {(item.media_type.includes('video') || item.media_type.includes('audio')) && <video height="150px" className="w-100 " src={item.media_url} controls />}
                        {item.media_type.includes('application') && <embed height="150px" src={item.media_url} />}
                    </Fragment>
                )
            })
            return data
        }
    }

    getDeleteQuestionModal() {
        const data = this.state.question.map((item, index) => {
            const { t } = this.props
            return (
                <div key={index} id={'modal_question_delete_' + item.id} data-id={'modal_question_delete_' + item.id} className="modal fade show collapse-body" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('exam:questionbank.areYouSureYouWantDeleteQuestion')}?</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('DeleteBtn')} link={item.id} main={true} onClick={this._deleteQuestion} />
                                <button id={'close_question_del_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return data
    }

    getDeleteModal() {
        if (this.state.question_bank != null) {
            const { t } = this.props
            return (
                <div id={'modal_delete'} data-id={'modal_delete'} className="modal fade show collapse-body" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('exam:questionbank.areYouSureYouWantDeleteQuestionBank')}?</p>
                                <p className="delete-file-name">"{this.state.question_bank.title}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('DeleteBtn')} link={this.state.question_bank.id} main={true} onClick={this._deleteQuestionBank} />
                                <button id={'close_delete'} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getQuestionList() {
        const { t } = this.props
        return (
            <div id="question_list" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:questionbank.previouslyAddedQuestions')}</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <Table data={this.state.tableData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <button id="close_question_list" type="button" className="cancel-button" data-dismiss="modal">
                                {t('modal close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getQuestionListModal() {
        const data = this.state.questionList.map((item, index) => {
            const { t } = this.props
            return (
                <div key={index} id={'select_question_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('exam:questionbank.areYouSureYouWantToAddTheQuestionBank')}?</p>
                                <p className="delete-file-name">
                                    <small>{item.questions}</small>
                                </p>
                                <p className="delete-file-name">
                                    <small>
                                        {t('exam:questionbank.questionType')}: {item.type == QuestionType.Subjective ? t('exam:subjective') : t('exam:objective')}
                                    </small>
                                </p>
                                <p className="delete-file-name">
                                    <small>
                                        {t('exam:marks')}: {item.marks}
                                    </small>
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Add" link={item.id} main={true} onClick={this._selectQuestion} />
                                <button id={'close_select_question_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.question_bank.menu}>{t('exam:questionbank:categoryTitle')} &gt; </a>
                            </li>
                            {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                <li>
                                    <a href={route.examination.question_bank.view + '/' + (this.state.classes.length > 0 ? this.state.classes[0].id : 0)}>{this.state.classes.length > 0 ? 'Academic' : 'Others'} &gt; </a>
                                </li>
                            )}
                            {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                <li>
                                    <a href={route.examination.question_bank.view + '/' + (this.state.classes.length > 0 ? this.state.classes[0].id : 0)}>{this.state.classes.length > 0 ? this.state.classes[0].name : 'Others'} &gt; </a>
                                </li>
                            )}

                            <li>{t('exam:questionbank:questions')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{this.state.question_bank != null ? this.state.question_bank.title : t('exam:questionbank.categoryTitle')}</span>
                                <h5 className="main-title heading5">{t('exam:questionbank:questions')}:</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right collapse-body">
                                    {this.state.question_bank != null && this.state.question_bank.question_exam_count == 0 && (
                                        <div className="display-inline-block">
                                            <div className="dropdown collapse-body">
                                                <button className="btn dropdown-toggle main-button" type="button" data-toggle="collapse" data-target="#question_dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {t('exam:questionbank:addquestion')}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="e-d-t" id="question_dropdown">
                                                    <button className="dropdown-item" type="button" data-toggle="modal" data-target="#modal_question_add">
                                                        {t('exam:questionbank.addNew')}
                                                    </button>
                                                    <button className="dropdown-item" type="button" data-toggle="modal" data-target="#question_list">
                                                        {t('exam:questionbank:selectExisting')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.question_bank != null && this.state.question_bank.author.id == this.props.school.id && (
                                        <div className="display-inline-block">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle main-button" type="button" data-toggle="collapse" data-target="#question_bank_dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {t('settings')}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="e-d-t" id="question_bank_dropdown">
                                                    <button className="dropdown-item" type="button" data-toggle="modal" data-target="#modal_edit">
                                                        {t('EditBtn')}
                                                    </button>
                                                    <button className="dropdown-item" type="button" data-toggle="modal" data-target="#modal_delete">
                                                        {t('DeleteBtn')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.question_bank != null && this.state.question_bank.question_exam_count > 0 && (
                        <div className="row">
                            <div className="col-sm-12 float-left form-group">
                                <div className="alert alert-primary" role="alert">
                                    {t('exam:questionbank.alreadyAddedMsg')}
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.question.length == 0 && (
                        <p className="no-data">
                            {SVG.nodata()}
                            {t('exam:questionbank.noQuestionsAdded')}.
                        </p>
                    )}
                    {this.state.question.length > 0 && (
                        <div id="accordion" className="main-accordion question-accordion">
                            {this.getQuestion()}
                        </div>
                    )}
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && (
                    <Fragment>
                        {this.state.question_bank != null && this.state.question_bank.author.id == this.props.school.id && (
                            <Fragment>
                                {this.getEditModal()}
                                {this.getDeleteModal()}
                            </Fragment>
                        )}
                        {this.getQuestionModal()}
                        {this.getDeleteQuestionModal()}
                        {this.getQuestionList()}
                        {this.getQuestionListModal()}
                    </Fragment>
                )}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_question_add') {
                let uploads = component.state.uploads
                for (let i = uploads.length; i >= 0; i--) {
                    uploads.pop()
                }
                // component.state.uploads.map((item, index) => {
                //     uploads.pop()
                // })
                // uploads.pop()
                component._handleChange('uploads', uploads)
                component._handleChange('media', [])
                component._handleChange('answers', [])
                component._handleChange('question_title', '')
                component._handleChange('question_marks', '')
                component._handleChange('question_type', '')
                component._handleChange('defaultType', '')
            } else if (id == 'modal_edit') {
                component._handleChange('title', component.state.question_bank.title)
                component._handleChange('description', component.state.question_bank.description)
                component._handleChange('isPrivate', component.state.question_bank.restriction)
                component._handleChange('selected_subject', component.state.question_bank.subject_id)
                component._handleChange('hideSection', false)
                let employee = []
                let student = []
                if (component.state.classes.length > 0) {
                    // component._handleChange('hideClass', false)
                    component._handleChange('selectedClass', component.state.classes[0].id)
                } else {
                    // component._handleChange('hideClass', false)
                    component._handleChange('selectedClass', -1)
                }
                if (component.state.question_bank.access_information != null) {
                    component.state.question_bank.access_information
                        .filter((item) => item.user_type == UserType.Student)
                        .map((item) => {
                            student.push(item.id)
                        })
                    component.state.question_bank.access_information
                        .filter((item) => item.user_type != UserType.Student)
                        .map((item) => {
                            employee.push(item.id)
                        })
                }
                component._handleChange('student', student)
                component._handleChange('employee', employee)
                const errors = {
                    title: null,
                    description: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

const Question = withTranslation()(connect(mapStateToProps)(QuestionComponent))

export { Question }
