const schoolReducer = (state = null, action) => {
    switch (action.type) {
        case 'CURRENT_SCHOOL':
            return action.data
        case 'SIGN_OUT':
            return null
        case 'RESET':
            return null
        default:
            return state
    }
}

export default schoolReducer
