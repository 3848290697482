export const Post = 0
export const Event = 1
export const Drive = 2
export const Medal = 3
export const Homework = 4
export const Holiday = 5
export const Course = 6
export const Exam = 7
export const Question = 8
export const Result = 9
export const Job = 10

export const activity = [
    { label: 'Post', value: Post, translate: 'activity:post' },
    { label: 'Event', value: Event, translate: 'calendar:calendar-menu.eventBtn' },
    { label: 'Drive', value: Drive, translate: 'drive:drive' },
    { label: 'Medal', value: Medal, translate: 'Medal' },
    { label: 'Homework', value: Homework, translate: 'todo:todo' },
    { label: 'Holiday', value: Holiday, translate: 'calendar:calendar-menu.holidayBtn' },
    { label: 'Course', value: Course, translate: 'course:course' },
    { label: 'Exam', value: Exam, translate: 'exam:exam.categoryTitle' },
    { label: 'Question Bank', value: Question, translate: 'exam:questionbank.question' },
]
