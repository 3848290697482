import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractResetComponent, mapStateToProps } from './abstract'
import { Input, Button, Checkbox } from '~/components/form'
import { LoginAnimation } from '~/components/animation'
import * as route from '~/helper/constant/route'
import $ from 'jquery'

class ResetComponent extends AbstractResetComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, value) {
        super._handleChange(key, value)
        if (key == 'otp_one') {
            if (value != '') {
                $('#otp_two').focus()
            }
        } else if (key == 'otp_two') {
            if (value != '') {
                $('#otp_three').focus()
            }
        } else if (key == 'otp_three') {
            if (value != '') {
                $('#otp_four').focus()
            }
        } else if (key == 'otp_four') {
            if (value != '') {
                $('#otp_five').focus()
            }
        } else if (key == 'otp_five') {
            if (value != '') {
                $('#otp_six').focus()
            }
        }
    }

    render() {
        return (
            <div className="clearfix login-page">
                <LoginAnimation />
                <div className="login-form">
                    <div className="login-main">
                        <div className="school-detail">
                            <a href={route.index}>
                                <img src="/assets/images/mobile-logo.png" />
                            </a>
                        </div>
                        <h5 className="text-center login-title">Please enter the OTP to reset your password.</h5>
                        <br />
                        <form>
                            <div className="form-group text-center">
                                <label>OTP Code:</label>
                                <div className="otp-field">
                                    <Input id="otp_one" name="otp_one" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_one} />
                                    <Input id="otp_two" name="otp_two" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_two} />
                                    <Input id="otp_three" name="otp_three" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_three} />
                                    <Input id="otp_four" name="otp_four" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_four} />
                                    <Input id="otp_five" name="otp_five" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_five} />
                                    <Input id="otp_six" name="otp_six" onChange={this._handleChange} className="otp-input form-control" type="text" maxLength="1" placeholder="-" value={this.state.otp_six} />
                                </div>
                                {this.state.errors.otp && <label className="error text-danger">{this.state.errors.otp}</label>}
                            </div>
                            <Button type="button" onClick={this._onSave} text="Reset Password" login={true} />
                            <div className="clearfix form-group row">
                                <div className="col-md-12 text-right">
                                    <a className="login-forget" href={route.index}>
                                        Go to Login
                                    </a>
                                </div>
                            </div>
                            <span className="powered">
                                Powered by{' '}
                                <a href="https://www.classpedia.io" target="_blank">
                                    Classpedia
                                </a>
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const Reset = connect(mapStateToProps)(ResetComponent)

export { Reset }
