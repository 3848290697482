import { get, post } from '../request'

export const viewGrading = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/grading', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getActiveGrading = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/grading/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addGrading = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/grading/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const getGrading = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/grading/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const updateGrading = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('exam-management/grading/update/' + id, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleGrade = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('exam-management/grading/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
