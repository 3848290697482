import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractExamViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../../helper/function'
class ExamViewComponent extends AbstractExamViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state
        }
        this.getAddModal = this.getAddModal.bind(this)
        this._selectClass = this._selectClass.bind(this)
        this._selectAll = this._selectAll.bind(this)
        this._setFromDate = this._setFromDate.bind(this)
        this._setToDate = this._setToDate.bind(this)
        this.getStatusModal = this.getStatusModal.bind(this)
        this.getEditModal = this.getEditModal.bind(this)
        this.selectList = this.selectList.bind(this)
    }

    _setFromDate(data) {
        this._handleChange('from_date', data)
    }

    _setToDate(data) {
        this._handleChange('to_date', data)
    }

    _selectAll(key, value) {
        this._handleChange(key, value)
        if (value) {
            this._handleChange('selectedClass', this.state.classes)
        } else {
            this._handleChange('selectedClass', [])
        }
    }
    selectList(data){
        this.setState({
            defaultReportCard:data,
            selectedReportCard: data.value
        })

    }

    _selectClass(data) {
        if (data == null) {
            this._handleChange('all', false)
        }
        this._handleChange('selectedClass', data)
    }


    getStatusModal() {
        const { t } = this.props

        const modal = this.state.exam.map((item, index) => {
            return (
                <div key={index} id={'modal_status_' + item.id} data-id={'modal_status_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                {item.status != Status.ACTIVE && (
                                    <Fragment>
                                        <p className="modal-question">
                                            {t('exam:examstatusMsg1')} {''}
                                            {t('classes:class.areyousureyouwantto')} {''}
                                            {/* {t('exam:publish')}  */}
                                            {/* {t('exam:exam.categoryTitle')}? */}
                                        </p>
                                        <p className="delete-file-name">{item.description}</p>
                                    </Fragment>
                                )}
                                {item.status == Status.ACTIVE && <p className="modal-question">{t('exam:examstatusMsg2')}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                {item.status != Status.ACTIVE && (
                                    <Fragment>
                                        <Button type="submit" text={t('updateBtn')} link={item.id} main={true} onClick={this.toggle} />
                                        <button id={'close_status_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('modal cancel')}
                                        </button>
                                    </Fragment>
                                )}
                                {item.status == Status.ACTIVE && (
                                    <button id={'close_status_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal close')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getEditModal() {
        let classList = null
        this.state.selectedClass.map((classValue) => {
            classList = classList == null ? classValue.label : classList + ' , ' + classValue.label
        })

        const modal = this.state.exam.map((item, index) => {
            const { t } = this.props
            console.log("exam", item)


            return (
                <div key={index} id={'modal_edit_' + item.id} data-id={item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('exam:exam:editExam')}</span>
                        </div>
                        <br />
                        <form className="p-0">
                           
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('exam:exam:categoryTitle')} *</label>
                                            <Input name="title" disabled={item.status == Status.ACTIVE ? true : false} onChange={this._handleChange} placeholder={t('exam:exam.examName')} type="text" value={item.title} />
                                            {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('common:description')} *</label>
                                            <TextArea name="description" disabled={item.status == Status.ACTIVE ? true : false} onChange={this._handleChange} placeholder={t('exam:exam:examdescription')} type="text" value={this.state.description} />
                                            {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('fromdate')} *</label>
                                            <DatePicker disabled={item.status == Status.ACTIVE ? true : false} placeholderText={t('common:selectFromDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.from_date} selected={this.state.from_date} onChange={this._setFromDate} />
                                            {this.state.errors.from_date && <label className="error text-danger">{this.state.errors.from_date}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('todate')} *</label>
                                            <DatePicker disabled={item.status == Status.ACTIVE ? true : false} placeholderText={t('common:selectToDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.to_date} selected={this.state.to_date} onChange={this._setToDate} />
                                            {this.state.errors.to_date && <label className="error text-danger">{this.state.errors.to_date}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('exam:exam:failConsideration')} *</label>
                                            <Input disabled={item.status == Status.ACTIVE ? true : false} name="remarks" onChange={this._handleChange} placeholder={t('exam:exam:failConsiderationPlaceholder')} value={this.state.remarks} />
                                            {this.state.errors.remarks && <label className="error text-danger">{this.state.errors.remarks}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('exam:exam:failPercentage')} *</label>
                                            <Input disabled={item.status == Status.ACTIVE ? true : false} name="additional_information" onChange={this._handleChange} placeholder={t('exam:exam:failPercentage')} value={item.additional_information} />
                                            {this.state.errors.additional_information && <label className="error text-danger">{this.state.errors.additional_information}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-6 float-left">
                                            <label className="form-title">{t('exam:exam:selectClass')} *</label>
                                            {item.status != Status.ACTIVE && <Select name="classes" options={this.state.classes} isMulti placeholder={t('exam:exam:selectClass')} searchable={true} value={this.state.selectedClass} onChange={this._selectClass} />}
                                            {item.status == Status.ACTIVE && <Input disabled={true} placeholder={t('exam:exam:selectClass')} value={classList} />}
                                            {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}

                                            {item.status != Status.ACTIVE && (
                                            <div className="col-sm-12 float-left form-group mt-1">
                                                <div className="form-check">
                                                    <Checkbox id="all" onChange={(key, value) => this._selectAll(key, value)} className="" name="all" value={this.state.all} />
                                                    <label className="form-check-label form-title small-checkbox" htmlFor="all">
                                                        {t('exam:exam:selectAll')}
                                                    </label>
                                                </div>
                                            </div>
                                            
                                        )}
                                        </div>

                                        <div className="col-sm-6 float-left  form-group">                                            
                                            <label className="form-title">{t('reportcard:reportcard')} *</label>
                                            {item.status != Status.ACTIVE &&  <Select name="reportcard" options={this.state.reportCards} placeholder="Report card"
                                            searchable={true} onChange = {this.selectList} value={this.state.defaultReportCard} />}
                                            {item.status == Status.ACTIVE && 
                                            <Input disabled={true} placeholder= "Report card" value={this.state.defaultReportCard.label} />}
                                                   

                                        {/* {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>} */}
                                    </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    {item.status != Status.ACTIVE && (
                                        <Fragment>
                                            <Button onClick={this._edit} link={item.id} type="submit" text={t('updateBtn')} main={true} />
                                            <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                                {t('modal cancel')}
                                            </button>
                                        </Fragment>
                                    )}
                                    {item.status == Status.ACTIVE && (
                                        <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('modal close')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        })
        return modal
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:exam:categoryTitle')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('exam:exam:categoryTitle')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('exam:exam:categoryTitle')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('exam:exam:examdescription')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('fromdate')} *</label>
                                        <DatePicker placeholderText={t('common:selectFromDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.from_date} selected={this.state.from_date} onChange={this._setFromDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.from_date != '' ? (convertADtoBS(this.state.from_date) != null ? convertADtoBS(this.state.from_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.errors.from_date && <label className="error text-danger">{this.state.errors.from_date}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('todate')} *</label>
                                        <DatePicker placeholderText={t('common:selectToDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.to_date} selected={this.state.to_date} onChange={this._setToDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.to_date != '' ? (convertADtoBS(this.state.to_date) != null ? convertADtoBS(this.state.to_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.errors.to_date && <label className="error text-danger">{this.state.errors.to_date}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:exam:failConsideration')} *</label>
                                        <Input name="remarks" onChange={this._handleChange} placeholder={t('exam:exam:failConsiderationPlaceholder')} value={this.state.remarks} />
                                        {this.state.errors.remarks && <label className="error text-danger">{this.state.errors.remarks}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:exam:failPercentage')} *</label>
                                        <Input name="additional_information" onChange={this._handleChange} placeholder={t('exam:exam:failPercentage')} value={this.state.additional_information} />
                                        {this.state.errors.additional_information && <label className="error text-danger">{this.state.errors.additional_information}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('exam:exam:selectClass')} *</label>
                                        <Select name="classes" options={this.state.classes} isMulti placeholder={t('exam:exam:selectClass')} searchable={true} value={this.state.selectedClass} onChange={this._selectClass} />
                                        <div className="form-check">
                                            <Checkbox id="all" onChange={(key, value) => this._selectAll(key, value)} className="" name="all" value={this.state.all} />
                                            <label className="form-check-label form-title small-checkbox" htmlFor="all">
                                                {t('exam:exam:selectAll')}
                                            </label>
                                        </div>
                                        {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('reportcard:reportcard')}</label>
                                            <Select
                                                name="reportcard"
                                                options={this.state.reportCards}
                                                placeholder="Report card"
                                                searchable={true}
                                                onChange = {this.selectList}  
                                            />

                                        {/* {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this._save} type="submit" text={t('savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <div className="main-content">
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('exam:exam:categoryTitle')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('exam:breadcrumb')}:</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <Button modal="modal_add" type="button" text={t('addBtn')} main={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Table data={this.state.tableData} message="exam:exam.noexammsg" />
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                    <Fragment>
                        {this.getAddModal()}
                        {this.getStatusModal()}
                        {this.getEditModal()}
                    </Fragment>
                )}
            </div>
        )
    }
}

const ExamView = withTranslation()(connect(mapStateToProps)(ExamViewComponent))

export { ExamView }
