import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { Alumni, Student } from '~/helper/constant/user_type'
import { getInvoiceId } from '~/helper/function'
import { getAllSchoolUser, getCustomerBalance, viewInvoice } from '~/services/api'
import { Parents } from '../../../../../../helper/constant/user_type'
import { configRoute } from '../../../../../../helper/function/abstract'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractInvoiceComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            tableData: [],
            filterStatus: [],
            toDate: moment().endOf('month').toDate(),
            fromDate: moment().subtract(3, 'months').startOf('month').toDate(),
            userLists: [],
            user: null,
            status: null,
            userBalance: null,
            tableStructure:
                this.props.location.pathname == configRoute(route.admin.accounting.income.invoice.view) ||
                (this.props.location.pathname != configRoute(route.admin.accounting.income.invoice.view) &&
                    this.props.userSchool.user_type == Parents)
                    ? [
                          {
                              invoice: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:invoice',
                              },
                              customer: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'gamification:user',
                              },
                              category: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'accounting:category',
                              },
                              issue_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:issuedate',
                              },
                              due_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:duedate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  translate: 'common:status',
                              },
                              action: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'activity:action',
                              },
                          },
                      ]
                    : [
                          {
                              invoice: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:invoice',
                              },
                              category: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                              },
                              issue_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:issuedate',
                              },
                              due_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:duedate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  tanslate: 'common:status',
                              },
                              action: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'activity:action',
                              },
                          },
                      ],
            errors: {
                fromDate: null,
                toDate: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadInvoice = this.loadInvoice.bind(this)
    }
    componentDidMount() {
        this.loaduser()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loaduser() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAllSchoolUser(this.props.schoolToken).then((response) => {
            console.log('User', response)
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (this.props.location.pathname == configRoute(route.admin.accounting.income.invoice.view)) {
                    const userLists = [
                        { label: 'All', value: '' },
                        ...response.data.users
                            .filter((item) => item.user_type == Student || item.user_type == Alumni)
                            .map((item) => ({
                                label: item.user.name ? `${item.user.reg_no} - ${item.user.name}` : item.user.reg_no,
                                value: item.customer_account_id,
                            })),
                    ]
                    this.setState(
                        {
                            userLists,
                        },
                        () => {
                            this.loadInvoice()
                        }
                    )
                } else {
                    let userSchoolId = this.props.userSchool.id
                    if (this.props.userSchool.user_type == Parents) {
                        userSchoolId = this.props.student
                    }
                    const userLists = [
                        { label: 'All', value: '' },
                        ...response.data.users
                            .filter((item) => userSchoolId == item.id)
                            .map((item) => ({
                                label: item.user.name ? `${item.user.reg_no} - ${item.user.name}` : item.user.reg_no,
                                value: item.customer_account_id,
                                id: item.id,
                            })),
                    ]
                    this.setState(
                        {
                            userLists,
                            user: userLists.find((data) => data.id == userSchoolId),
                        },
                        () => {
                            this.loadInvoice()
                        }
                    )
                }
            }
        })
    }

    loadInvoice() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []

        tableStructure.push(this.state.tableStructure[0])
        const customer = this.state.user != null ? this.state.user.value : ''
        const status = this.state.status != null ? this.state.status.value : ''
        const start_date = moment(this.state.fromDate).format('yyyy-MM-DD')
        const end_date = moment(this.state.toDate).format('yyyy-MM-DD')
        console.log(start_date)
        console.log(end_date)
        console.log(status)
        viewInvoice(start_date, end_date, customer, status, this.props.token).then((response) => {
            this._handleChange('loading', false)
            const filterStatus = [
                { label: 'All', value: '' },
                ...response.data.status.map((item, index) => ({
                    label: item,
                    value: index.toString(), // Convert index to string to ensure consistent type
                })),
            ]

            if (response.code == API_SUCCESS) {
                const statusArray = response.data.status
                response.data.invoices
                    .filter((item) =>
                        this.props.location.pathname == configRoute(route.admin.accounting.income.invoice.view)
                            ? true
                            : item.status != 0
                            ? true
                            : false
                    )
                    .map((item, index) => {
                        let text = null
                        let due_date = {
                            text: item.due_date,
                        }
                        if (item.status == 0) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-yellow',
                            }
                        } else if (item.status == 1) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-blue',
                            }
                        } else if (item.status == 2) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-red',
                            }
                        } else if (item.status == 3) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-purple',
                            }
                        } else {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-green',
                            }
                        }
                        const today = moment(moment().format('yyyy-MM-DD'))
                        const due = moment(item.due_date, 'yyyy-MM-DD')
                        if (due.isSameOrBefore(today)) {
                            due_date.color = 'red'
                        }

                        const rowData = {
                            invoice: {
                                text: getInvoiceId(item.invoice_id),
                            },
                        }
                        if (
                            this.props.location.pathname == configRoute(route.admin.accounting.income.invoice.view) ||
                            (this.props.location.pathname != configRoute(route.admin.accounting.income.invoice.view) &&
                                this.props.userSchool.user_type == Parents)
                        ) {
                            rowData.customer = {
                                text: item.customer_id == item.customer.id && item.customer.name,
                            }
                        }
                        rowData.category = {
                            text: item.category_id == item.category.id && item.category.name,
                        }
                        rowData.issue_date = {
                            text: item.issue_date,
                        }
                        ;(rowData.due_date = due_date),
                            (rowData.status = {
                                text: text.text,
                                badge: text.badge,
                            })
                        rowData.action = {
                            text: item.status == 0 ? 'Edit' : 'Invoice',
                            translate: item.status == 0 ? 'common:EditBtn' : 'income:invoice',
                            route:
                                item.status == 0
                                    ? route.admin.accounting.income.invoice.edit + '/' + item.id
                                    : this.props.location.pathname ==
                                      configRoute(route.admin.accounting.income.invoice.view)
                                    ? route.admin.accounting.income.invoice.details + '/' + item.id
                                    : route.student.fee.detail + '/' + item.id,
                        }

                        tableData.push(item)
                        tableStructure.push(rowData)
                    })
                this.setState(
                    {
                        tableData,
                        tableStructure,
                        filterStatus,
                        userBalance: null,
                    },
                    () => {
                        if (customer != null) {
                            this.loadCustomerBalance(customer)
                        }
                    }
                )
            }
        })
    }

    loadCustomerBalance(id) {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)

        getCustomerBalance(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    userBalance: response.data.balance,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        userSchool: state.school,
        token: state.school.accounting_token,
        schoolToken: state.token,
        student: state.student,
    }
}
