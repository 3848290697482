import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { editUserSchool, getUserSchool } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import * as UserType from '~/helper/constant/user_type'
import { currency } from '~/helper/constant/currency'
import { timezone } from '~/helper/constant/timezone'
import { currentSchool, profile_update } from '~/store'
import { redirect } from '~/helper/function/util'
import { language } from '~/helper/constant/language'
import { dateFormat } from '../../../helper/constant/date_format'

type Props = {
    token: String,
    user: Object,
    school: Object,
    loading: Boolean,
    errors: Object,
    dispatch: Dispatch<any>,
}

type State = {
    school: Object,
    name: String,
    email: String,
    contact: String,
    address: String,
    currency: String,
    defaultCurrency: Object,
    timezone: String,
    defaultTimezone: String,
    defaultLocale: String,
    locale: String,
    profile: Object,
    loading: Boolean,
    dateFormat: String,
}

export class AbstractSchoolProfileComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loading: false,
            name: '',
            email: '',
            contact: '',
            address: '',
            currency: '',
            country: '',
            defaultCurrency: '',
            timezone: '',
            defaultTimezone: '',
            defaultLocale: '',
            locale: 'en',
            date_format: '',
            defaultDateFormat: '',
            profile: {},
            errors: {
                name: null,
                email: null,
                contact: null,
                address: null,
                currency: null,
                timezone: null,
                locale: null,
                date_format: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._check = this._check.bind(this)
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    componentDidMount() {
        this._check()
    }

    _check() {
        const id = this.props.match.params.id
        this._handleChange('loading', true)
        const check = this.props.user.user_school.find((item) => item.school_id == id && item.user_type == UserType.Admin)
        if (check != null) {
            getUserSchool(id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    const school = response.data.school
                    this._handleChange('school', school)
                    if (school.currency != null) {
                        this.setState({
                            currency: school.currency,
                            defaultCurrency: currency.find((item) => item.value == school.currency),
                        })
                    }
                    if (school.timezone != null) {
                        this.setState({
                            timezone: school.timezone,
                            defaultTimezone: timezone.find((item) => item.value == school.timezone),
                        })
                    }
                    if (school.date_format != null) {
                        this.setState({
                            date_format: school.date_format,
                            defaultDateFormat: dateFormat.find((item) => item.value == school.date_format),
                        })
                    }
                    if (school.gallery) {
                        if (school.gallery.media) {
                            const images = school.gallery.media.find((item, index) => index == 0)
                            if (images) {
                                const profile = {
                                    0: images.media_type + ';base64,' + images.media_data,
                                }
                                this.setState({
                                    profile,
                                })
                            }
                        }
                    }
                    const defaultLocale = language.find((item) => item.value == school.locale)
                    this.setState({
                        country: school.country,
                        name: school.name,
                        email: school.email,
                        contact: school.contact,
                        address: school.address,
                        locale: school.locale,
                        defaultLocale,
                    })
                } else {
                    redirect(route.my_school, this.props.history)
                }
            })
        } else {
            redirect(route.activity, this.props.history)
        }
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })

        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                email: this.state.email,
                contact: this.state.contact,
                address: this.state.address,
                currency: this.state.currency,
                time_zone: this.state.timezone,
                profile: this.state.profile,
                locale: this.state.locale,
                date_format: this.state.date_format,
            }
            this._handleChange('loading', true)
            editUserSchool(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    const school = response.data.user.user_school
                    this.props.dispatch(profile_update(response.data))
                    const activeSchool = school.find((item) => item.school_id == this.props.match.params.id)
                    if (activeSchool != null) {
                        this.props.dispatch(currentSchool(activeSchool))
                    }
                    // if(this.props.school.school_id == school.id){
                    //     const data = this.props.school;
                    //     data.school = school;

                    // }
                    redirect(route.activity, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        user: state.user,
        school: state.school,
    }
}
