import moment from 'moment'
import { Component } from 'react'
import { Alumni, Student } from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewInvoiceSummary } from '../../../../../services/api/accounting/report'
import { getAllSchoolUser } from '../../../../../services/api/users'

const defaultUser = {
    label: 'All',
    value: '',
}

const defaultStatus = {
    label: 'All',
    value: '',
}

export class AbstractInvoiceSummaryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            start_month: moment().startOf('month').toDate(),
            end_month: moment().endOf('month').toDate(),
            duration: null,
            userList: [defaultUser],
            user: defaultUser,
            statusList: [defaultStatus],
            status: defaultStatus,
            errors: {
                start_month: null,
                end_month: null,
            },
            totalAmount: {
                balance: 0.0,
                paid: 0.0,
                due: 0.0,
            },
            invoices: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }

    componentDidMount() {
        this.loadUser()
        this._onSearch()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadUser() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getAllSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const userList = [defaultUser]
                response.data.users
                    .filter((item) => item.user_type == Student || item.user_type == Alumni)
                    .map((item) => {
                        const data = {
                            label: item.user.name ? item.user.reg_no + ' - ' + item.user.name : item.user.reg_no,
                            value: item.customer_account_id,
                        }
                        userList.push(data)
                    })
                this.setState({
                    userList,
                })
            }
        })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const start_month =
                convertADtoBS(this.state.start_month) != null
                    ? convertADtoBS(this.state.start_month).slice(0, -3) + ' BS'
                    : 'Invalid Date Range'
            const end_month =
                convertADtoBS(this.state.end_month) != null
                    ? convertADtoBS(this.state.end_month).slice(0, -3) + ' BS'
                    : 'Invalid Date Range'

            this.setState({
                duration: start_month + ' || ' + end_month,
            })
        } else {
            const start_month = moment(this.state.start_month).format('yyyy-MM-DD')
            const end_month = moment(this.state.end_month).format('yyyy-MM-DD')
            this.setState({
                duration: start_month.slice(0, -3) + ' || ' + end_month.slice(0, -3),
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const start_month = moment(this.state.start_month).format('yyyy-MM')
            const end_month = moment(this.state.end_month).format('yyyy-MM')
            const user = this.state.user.value
            const status = this.state.status.value
            viewInvoiceSummary(start_month, end_month, user, status, this.props.accountingToken).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    console.log(response)
                    const statusData = response.data.status

                    const statusList = []
                    statusList.push(defaultStatus)
                    statusData.map((item, index) => {
                        if (index != 0) {
                            statusList.push({
                                label: item,
                                value: index,
                            })
                        }
                    })
                    console.log(statusList)

                    const totalAmount = {
                        balance: response.data.totalInvoice,
                        paid: response.data.totalPaidInvoice,
                        due: response.data.totalDueInvoice,
                    }
                    this.setState(
                        {
                            statusList,
                            totalAmount,
                            invoices: response.data.invoices,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accountingToken: state.school.accounting_token,
        school: state.school.school,
    }
}
