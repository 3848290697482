import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { display_mode, font_size, title_position } from '~/helper/constant/reportcard'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { editReportCard, getActiveGrading, getReportCard } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    title: String,
    code: String,
    logo: Object,
    background: Object,
    logoPosition: String,
    titlePosition: String,
    displayMode: integer,
    grades: Array,
    errors: Object,
}

export class AbstractReportCardEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            logo_id: '',
            border_id: '',
            reportCardId: '',
            title: '',
            code: '',
            color: '#000000',
            logoAry: [],
            logo: {},
            defaultLogo: null,
            defaultBorder: null,
            backgroundAry: [],
            border: {},
            logoUploadedFiles: [],
            bgUploadedFiles: [],
            logoPosition: title_position,
            defaultLogoPosition: '',
            selectedLogoPosition: title_position[0].value,
            titlePosition: title_position,
            defaultTitlePosition: '',
            selectedTitlePosition: title_position[0].value,
            displayMode: display_mode,
            defaultDisplayMode: '',
            selectedDisplayMode: display_mode[0].value,
            status: true,
            grades: [],
            defaultGrading: null,
            selectedGrading: '',
            note: '',
            fontSize: font_size,
            defaultFontSize: '',
            selectedFontSize: font_size[0].value,
            gradingId: '',
            errors: {
                title: null,
                code: null,
                logo: null,
                border: null,
                displayMode: null,
                fontSize: null,
                note: null,
                titlePosition: null,
                logoPosition: null,
                selectedGrading: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.OnSave = this.OnSave.bind(this)
    }
    componentDidMount() {
        this.loadGrading()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
    loadGrading() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getActiveGrading(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let grades = []
            let defaultGrading = ''
            let selectedGrading = ''
            if (response.code == API_SUCCESS) {
                response.data.grading.map((item, index) => {
                    const data = {
                        label: item.title + '-' + item.code,
                        value: item.id,
                    }
                    grades.push(data)
                    if (item.id == this.state.gradingId) {
                        defaultGrading = data
                        selectedGrading = item.id
                    }
                })
                this.setState(
                    {
                        grades,
                        defaultGrading,
                        selectedGrading,
                    },
                    () => {
                        this.loadReportCard()
                    }
                )
            }
        })
    }
    loadReportCard() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getReportCard(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const item = response.data.reportCard
                const defaultGrading = this.state.grades.find((grading) => grading.value === item.grading_id)
                const imageTypes = ['logo', 'border']
                const imageKeys = ['logoAry', 'backgroundAry']
                imageTypes.forEach((type, index) => {
                    if (response.data.reportCard[type]) {
                        const images = response.data.reportCard[type]
                        const profileBlob = []
                        if (images != null) {
                            const profile = {
                                0: images.media_url,
                            }
                            profileBlob.push(images)
                            this._handleChange(type, profile)
                            this._handleChange(imageKeys[index], profileBlob)
                            if (type === 'logo') {
                                this._handleChange('defaultLogo', images.media_url)
                            } else {
                                this._handleChange('defaultBorder', images.media_url)
                            }
                        }
                    }
                })
                this.setState({
                    reportCardId: item.id,
                    title: item.title,
                    code: item.code,
                    defaultTitlePosition: title_position.find((e) => e.value == item.title_position),
                    defaultDisplayMode: display_mode.find((e) => e.value == item.display_mode),
                    defaultLogoPosition: title_position.find((e) => e.value == item.logo_position),
                    selectedTitlePosition: item.title_position,
                    selectedLogoPosition: item.logo_position,
                    selectedDisplayMode: item.display_mode,
                    defaultFontSize: font_size.find((e) => e.value == item.font_size),
                    selectedFontSize: item.font_size,
                    color: item.color,
                    gradingId: item.grading_id,
                    note: item.note,
                    selectedGrading: item.grading_id,
                    defaultGrading: defaultGrading,
                    logo_id: item.logo_id,
                    border_id: item.border_id,
                })
            }
        })
    }

    compareMediaUrl = (defaultMediaUrl, uploadedMediaUrl, type) => {
        if (JSON.stringify(defaultMediaUrl) !== JSON.stringify(uploadedMediaUrl)) {
            return this.state[`${type}_id`]
        } else {
            return null
        }
    }

    OnSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        if (JSON.stringify(this.state.logo) === '{}') {
            errors['logo'] = 'Required'
        }
        if (JSON.stringify(this.state.border) === '{}') {
            errors['border'] = 'Required'
        }
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const deleted = []
            const deletedLogo = this.compareMediaUrl(this.state.defaultLogo, this.state.logo[0], 'logo')
            if (deletedLogo !== null) {
                deleted.push(deletedLogo)
            }
            const deletedBorder = this.compareMediaUrl(this.state.defaultBorder, this.state.border[0], 'border')
            if (deletedBorder !== null) {
                deleted.push(deletedBorder)
            }
            const data = {
                title: this.state.title,
                code: this.state.code,
                logo: this.state.logo,
                border: this.state.border,
                logo_position: this.state.selectedLogoPosition,
                title_position: this.state.selectedTitlePosition,
                display_mode: this.state.selectedDisplayMode,
                grading_id: this.state.selectedGrading,
                status: this.state.status,
                color: this.state.color,
                font_size: this.state.selectedFontSize,
                note: this.state.note,
                deletedMedia: deleted,
            }

            this._handleChange('loading', true)
            editReportCard(this.state.reportCardId, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.setting.reportcard.view)
                } else {
                    toastMessage('error', response.data)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
