import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addCourse, viewCourse, viewCourseProgress, viewCourseContent, getCourseModuleItems, viewCourseModuleItems, getCourseItems, getSchoolUser } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import $ from 'jquery'
import moment from 'moment'
import { course_progress_constant } from '~/helper/constant/course_progress'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseInsightsDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            courseCount: 0,
            activeTab: 0,
            loading: true,
            loading_text: 'Loading',
            courseModule: [],
            courseContent: [],
            courseModuleData: [],
            class_id: this.props.match.params.id,
            course_id: this.props.match.params.course_id,
            module_id: this.props.match.params.module_id,
            course_progress: [],
            course: [],
            classes: [],
            subject: [],
            allUsers: [],
            accessCount: 1,
            insight_details: [
                {
                    student: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:insight_details',
                    },
                    // content: {
                    //   field: "text",
                    //   route: false,
                    //   sortable: true,
                    // },
                    user_progress: {
                        field: 'html',
                        route: false,
                        sortable: true,
                        translate: 'course:user_progress',
                    },
                    info: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'common:Info',
                    },
                    // feedback: {
                    //   field: "text",
                    //   route: false,
                    //   sortable: true,
                    // },
                },
            ],
            course_details: [],
            selectedUser: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
    }

    componentDidMount() {
        this.loadAllUsers()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAllUsers() {
        getSchoolUser(this.props.token).then((response) => {
            let studentList = []
            let employeeList = []
            let allUsers = []
            if (response.code == API_SUCCESS) {
                response.data.users.map((item, index) => {
                    allUsers.push(item)
                })
                this.setState(
                    {
                        allUsers,
                    },
                    () => {
                        this.loadSubject()
                    }
                )
                if (this.props.onUserLoad) {
                    this.props.onUserLoad(allUsers)
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadCourseProgress() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let insight_details = []
                let course_details = []
                insight_details.push(this.state.insight_details[0])
                const course_progress = response.data.course_progress
                const userData = []
                let moduleCourse = []
                this.state.courseModule.content.map((obj, i) => {
                    moduleCourse.push(obj.id)
                })
                this.state.allUsers.map((o, i) => {
                    let userObj = {}
                    const completedCount = course_progress
                        .filter((el) => {
                            return moduleCourse.indexOf(el.course_content_id) > -1
                        })
                        .filter((el) => {
                            return el.user_school_id == o.id && el.user_progress == 2
                        })
                    if (completedCount.length > 0) {
                        let courseArray = []
                        userObj['name'] = o.user.name
                        userObj['completed'] = completedCount.length
                        userObj['id'] = o.id
                        const cp = course_progress.filter((el) => {
                            return el.user_school_id == o.id
                        })
                        cp.map((item, index) => {
                            let courseObj = {}
                            let userProgress = course_progress_constant.filter((el) => {
                                return el.value == item.user_progress
                            })
                            let courseContent = this.state.courseContent.filter((el) => {
                                return el.id == item.course_content_id
                            })
                            courseObj['user_progress'] = userProgress.length > 0 ? userProgress[0].label : null
                            courseObj['content'] = courseContent.length > 0 ? courseContent[0].name : null
                            courseObj['feedback'] = item.feedback
                            courseArray.push(courseObj)
                        })
                        userObj['course_progress'] = courseArray
                        userData.push(userObj)
                    }
                })
                userData.map((item, index) => {
                    const styleObj = {
                        width: (item.completed / this.state.courseModule.content.length) * 100 + '%',
                    }
                    const tableData = {
                        student: {
                            text: item.name,
                        },
                        user_progress: {
                            text: 'Progress',
                            html: (
                                <div className="progress-group clearfix">
                                    <span className="float-right progress-percent">{((item.completed / this.state.courseModule.content.length) * 100 ? ((item.completed / this.state.courseModule.content.length) * 100).toFixed(2) : 0) + '%'}</span>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar progress-blue" style={styleObj}></div>
                                    </div>
                                </div>
                            ),
                        },
                        info: {
                            text: 'View',
                            route: route.course.insights_user + '/' + this.props.match.params.id + '/' + this.props.match.params.course_id + '/' + this.props.match.params.module_id + '/' + item.id,
                            translate: 'common:viewBtn',
                        },
                    }
                    insight_details.push(tableData)
                })

                this.setState(
                    {
                        course_progress,
                        insight_details,
                        course_details: userData,
                    },
                    () => {}
                )
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseModuleItems(this.props.match.params.module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                const course_progress = this.state.course_progress
                const courseContent = []
                let inProgressUsers = []
                let courseModuleData = []

                courseModule.content.map((obj, index) => {
                    if (obj.course_module_id == this.state.module_id) {
                        courseContent.push(obj)
                    }
                })
                this.setState(
                    {
                        courseContent,
                        courseModule,
                    },
                    () => this.loadCourseProgress()
                )
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseModule()
                        }
                    )
                }
            })
        } else {
            this.loadCourseModule()
        }
    }
}

withTranslation()(AbstractCourseInsightsDetailsComponent)
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
