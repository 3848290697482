import {Component} from 'react'
import {Dispatch} from 'react-redux'
import {type_list, module_list} from '~/helper/constant/category'


type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantCustomFieldEditComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            type_list:type_list,
            module_list: module_list,
        }
    }
    compnentDidMount(){}


}

export function mapStateToProps(state:Object){
    return {
        token: state.token,
    }
}