export const participant_join = (participant) => {
    return {
        type: 'PARTICIPANT_JOIN',
        participant
    }
}

export const participant_leave = (participant) => {
    return {
        type: 'PARTICIPANT_LEAVE',
        participant
    }
}