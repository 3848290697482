const CHEQUE = 0
const CASH = 1
const CREDIT = 2

const payment_types = [
    { label: 'Bank', value: 0 },
    { label: 'Cash', value: 1 },
    { label: 'Wallet', value: 2 },
]

export { CHEQUE, CASH, CREDIT, payment_types }
