import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractConstantContractTypeAddComponent, mapStateToProps } from './abstract'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import Select from 'react-select'
import { ColorPicker } from '~/components/form/color-picker'

class ConstantContractTypeAddComponent extends AbstractConstantContractTypeAddComponent{
    constructor(props){
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render(){
        const {t} = this.props
        return(
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="constant" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                            <a href={route.admin.accounting.constant.menu}>{t('constant:constant')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li><a href={route.admin.accounting.constant.contracttype.view}>{t("constant:contracttype")} &nbsp; &gt; &nbsp; </a></li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('constant:createnewcontract')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} </label>
                                    <Input name="title" placeholder={t('common:name')} type="text" value="" />
                                   
                                </div>
                                
                               

                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )

    }

}

const ConstantContractTypeAdd = withTranslation()(connect(mapStateToProps)(ConstantContractTypeAddComponent))
export {ConstantContractTypeAdd}