import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { getInviteById, invitationVerify } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'

export class AbstractInviteApprovalComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            invitaion: null,
            email: null,
            user_type: null,
            name: null,
            contact: null,
            dob: null,
            isParent: false,
            isCategory: false,
            parent_email: null,
            parent_name: null,
            parent_contact: null,
            parent_dob: null,
            user_category: null,
            relation: null,
            alumni_batch: null,
            alumni_group: null,
            alumni_company: null,
            alumni_designation: null,
            name_recheck: false,
            contact_recheck: false,
            dob_recheck: false,
            user_category_recheck: false,
            parent_email_recheck: false,
            parent_name_recheck: false,
            parent_contact_recheck: false,
            parent_dob_recheck: false,
            parent_relation_recheck: false,
            batch_recheck: false,
            group_recheck: false,
            company_recheck: false,
            designation_recheck: false,
            remarks: null,
            status: null,
        }

        this._handleChange = this._handleChange.bind(this)
        this._handleRecheckChange = this._handleRecheckChange.bind(this)
        this.save = this.save.bind(this)
        this.resubmit = this.resubmit.bind(this)
        this.approve = this.approve.bind(this)
    }

    componentDidMount() {
        this.loadInvitation()
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    _handleRecheckChange(key, value) {
        this.setState({ [key]: value }, () => {
            let remarks = ''
            if (this.state.name_recheck) {
                remarks = 'Please verify your name data.'
            }
            if (this.state.contact_recheck) {
                remarks = remarks + '\n' + 'Please verify your contact data.'
            }
            if (this.state.dob_recheck) {
                remarks = remarks + '\n' + 'Please verify your dob data.'
            }
            if (this.state.user_category_recheck) {
                remarks = remarks + '\n' + 'Please verify your user category data.'
            }
            if (this.state.parent_email_recheck) {
                remarks = remarks + '\n' + 'Please verify your parent email data.'
            }
            if (this.state.parent_name_recheck) {
                remarks = remarks + '\n' + 'Please verify your parent name data.'
            }
            if (this.state.parent_contact_recheck) {
                remarks = remarks + '\n' + 'Please verify your parent contact data.'
            }
            if (this.state.parent_dob_recheck) {
                remarks = remarks + '\n' + 'Please verify your parent dob data.'
            }
            if (this.state.parent_relation_recheck) {
                remarks = remarks + '\n' + 'Please verify your relation data.'
            }
            if (this.state.batch_recheck) {
                remarks = remarks + '\n' + 'Please verify your batch data.'
            }
            if (this.state.group_recheck) {
                remarks = remarks + '\n' + 'Please verify your faculty data.'
            }
            if (this.state.company_recheck) {
                remarks = remarks + '\n' + 'Please verify your company data.'
            }
            if (this.state.designation_recheck) {
                remarks = remarks + '\n' + 'Please verify your designation data.'
            }
            this.setState({
                remarks,
            })
        })
    }

    loadInvitation() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getInviteById(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (response.data.invite != null) {
                    const invitation = response.data.invite

                    this.setState({
                        invitation,
                        user_type: invitation.user_type,
                        name: invitation.name,
                        email: invitation.email,
                        contact: invitation.contact,
                        dob: invitation.dob,
                        parent_email: invitation.parent_email,
                        parent_name: invitation.parent_name,
                        parent_contact: invitation.parent_contact,
                        parent_dob: invitation.parent_dob,
                        status: invitation.status,
                        remarks: invitation.remarks,
                        relation: invitation.relation,
                        isParent: invitation.isParent,
                        isCategory: invitation.isCategory,
                        user_category: invitation.user_category,
                        alumni_batch: invitation.alumni_batch,
                        alumni_group: invitation.alumni_group,
                        alumni_company: invitation.alumni_company,
                        alumni_designation: invitation.alumni_designation,
                    })
                }
            }
        })
    }

    resubmit(e) {
        e.preventDefault()
        this.setState(
            {
                status: Status.PENDING,
            },
            () => {
                this.save()
            }
        )
    }

    approve(e) {
        e.preventDefault()
        this.setState(
            {
                status: Status.INACTIVE,
            },
            () => {
                this.save()
            }
        )
    }

    save() {
        const data = {
            remarks: this.state.remarks,
            status: this.state.status,
        }
        invitationVerify(this.props.match.params.id, data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                redirect(route.admin.setting.invite.view)
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors, parent_errors: response.data.errors })
                    }
                }
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
