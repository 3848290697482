export const language = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Hindi',
        value: 'in',
    },
    {
        label: 'Japanese',
        value: 'jp',
    },
    {
        label: 'Nepali',
        value: 'np',
    },
]
