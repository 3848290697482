import { accountingGet } from '../accountingRequest'

export const viewTransaction = (start_month, end_month, account, category, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/transactions?start_month=' +
                    start_month +
                    '&end_month=' +
                    end_month +
                    '&account=' +
                    account +
                    '&category=' +
                    category,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewAccountStatement = (start_month, end_month, account, type, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/account-statement?start_month=' +
                    start_month +
                    '&end_month=' +
                    end_month +
                    '&account=' +
                    account +
                    '&type=' +
                    type,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewIncomeSummary = (year, category, customer, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/income-summary?year=' + year + '&category=' + category + '&customer=' + customer,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewExpenseSummary = (year, category, vender, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/expense-summary?year=' + year + '&category=' + category + '&vender=' + vender,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewIncomeExpenseSummary = (year, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/income-expense-summary?year=' + year, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const viewTaxSummary = (year, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/tax-summary?year=' + year, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewProfitLossSummary = (year, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/profit-loss-summary?year=' + year, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewInvoiceSummary = (start_month, end_month, customer, status, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/invoice-summary?start_month=' +
                    start_month +
                    '&end_month=' +
                    end_month +
                    '&customer=' +
                    customer +
                    '&status=' +
                    status,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewBillSummary = (start_month, end_month, vender, status, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/bill-summary?start_month=' +
                    start_month +
                    '&end_month=' +
                    end_month +
                    '&vender=' +
                    vender +
                    '&status=' +
                    status,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewProductStock = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/product-stock', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
