import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractBooksEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import * as SVG from '~/assets/svg'
import { faThemeisle } from '@fortawesome/free-brands-svg-icons'
import $ from 'jquery'

class BooksEditComponent extends AbstractBooksEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.selectList = this.selectList.bind(this)
        this.setDate = this.setDate.bind(this)
        this._setQuantity = this._setQuantity.bind(this)
        this.delete = this.delete.bind(this)
        this.handleModalClose = this.handleModalClose.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shelf != null && prevState.shelf != this.state.shelf) {
            if (this.state.shelf == null) {
                $('#modal_deleteModel').modal('hide')
            } else {
                $('#modal_deleteModel').modal('show')
            }
        }
    }

    handleModalClose = () => {
        // Reset bookDetail state
        this.setState({ shelf: null })
    }

    delete() {
        super.delete().then((response) => {
            if (response) {
                $('#close_delete').trigger('click')
            }
        })
    }

    removeRow(index) {
        let shelfElements = this.state.shelfElements
        shelfElements.splice(index, 1)
        this.setState({
            shelfElements,
        })
    }

    selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    _setShelf(data, i) {
        const shelfElements = this.state.shelfElements
        shelfElements[i].defaultShelf = data
        shelfElements[i].shelves_id = data.value

        const shelfErrors = [...this.state.shelfErrors]
        shelfErrors[i].shelves_id = null

        this.setState({
            shelfElements: shelfElements,
            shelfErrors,
        })
    }

    _setQuantity(data, i) {
        const shelfElements = this.state.shelfElements
        shelfElements[i].quantity = data
        this.setState({
            shelfElements: shelfElements,
        })
    }
    setDate(selectDate, data) {
        this._handleChange(selectDate, data)
    }

    addRow() {
        let shelfElements = this.state.shelfElements
        let shelfErrors = this.state.shelfErrors
        shelfElements.push({
            shelves_id: '',
            quantity: '',
        })
        shelfErrors.push({
            shelves_id: null,
            quantity: null,
        })

        this.setState({
            shelfElements,
            shelfErrors,
        })
    }

    deleteModal() {
        const { t } = this.props

        return (
            <div id={'modal_deleteModel'} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" onClick={this.handleModalClose} data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('activity:delete_msg')}</p>
                            {/* <p>{item.name}</p> */}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={this.delete} type="button" text={t('common:modal confirm')} main={true} />
                            <button
                                id="close_delete"
                                type="upload"
                                onClick={this.handleModalClose}
                                className="cancel-button"
                                data-dismiss="modal"
                            >
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
        return shelfElements
    }

    loadBookShelf() {
        const { t } = this.props
        const shelfElements = this.state.shelfElements
        const selectedShelves = []

        const data = shelfElements.map((item, index) => {
            // Reset selectedShelves array for each row
            selectedShelves.length = 0

            // Loop through all rows to gather selected shelves
            for (let i = 0; i < shelfElements.length; i++) {
                const selectedShelf = shelfElements[i].defaultShelf
                if (selectedShelf && !selectedShelves.includes(selectedShelf.value)) {
                    selectedShelves.push(selectedShelf.value)
                }
            }

            // Filter out the activeBookShelf options to exclude the selected shelves
            const activeShelves = this.state.activeBookShelf.filter((shelf) => {
                // Check if the shelf is not in the selectedShelves array
                return !selectedShelves.includes(shelf.value)
            })

            // Update selectedShelves array with the current selected shelf
            if (item.defaultShelf) {
                selectedShelves.push(item.defaultShelf.value)
            }

            return (
                <tr key={index}>
                    <td>
                        <Select
                            className="mb-3 bookShelfAbove"
                            value={item.defaultShelf}
                            isDisabled={item.bookShelfId ? true : false}
                            options={activeShelves}
                            placeholder={t('common:select')}
                            onChange={(data) => this._setShelf(data, index)}
                            searchable={true}
                        />
                    </td>
                    <td>
                        <Input
                            min={0}
                            type="number"
                            className="form-control mb-3"
                            id="heading"
                            placeholder={t('accounting:quantity')}
                            value={item.quantity}
                            onChange={(key, val) => this._setQuantity(val, index)}
                        />
                        {this.state.shelfErrors[index].quantity && (
                            <label className="table-error error text-danger">
                                {this.state.shelfErrors[index].quantity}
                            </label>
                        )}
                    </td>

                    <td>
                        <div className="d-flex align-items-center mb-3">
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                            <Button
                                type="button"
                                text={SVG.deleteComment()}
                                main={false}
                                onClick={() => {
                                    item.shelves_id
                                        ? this.setState({
                                              shelf: this.state.shelfElements[index],
                                          })
                                        : this.removeRow(index)
                                }}
                            />
                        </div>
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="library" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.books.view}>{t('library:books')} > </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('library:editbooks')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:title')} *</label>
                                    <Input
                                        name="title"
                                        onChange={this._handleChange}
                                        placeholder={t('common:title')}
                                        type="text"
                                        value={this.state.title}
                                    />
                                    {this.state.errors.title && (
                                        <label className="error text-danger">{this.state.errors.title}</label>
                                    )}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:isbn')} *</label>
                                    <Input
                                        name="isbn"
                                        onChange={this._handleChange}
                                        placeholder={t('library:isbn')}
                                        type="text"
                                        value={this.state.isbn}
                                    />
                                    {this.state.errors.isbn && (
                                        <label className="error text-danger">{this.state.errors.isbn}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('calendar:author')} *</label>
                                    <Input
                                        name="author"
                                        onChange={this._handleChange}
                                        placeholder={t('calendar:author')}
                                        type="text"
                                        value={this.state.author}
                                    />
                                    {this.state.errors.author && (
                                        <label className="error text-danger">{this.state.errors.author}</label>
                                    )}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:editions')} *</label>
                                    <Input
                                        name="edition"
                                        onChange={this._handleChange}
                                        placeholder={t('library:editions')}
                                        type="text"
                                        value={this.state.edition}
                                    />
                                    {this.state.errors.edition && (
                                        <label className="error text-danger">{this.state.errors.edition}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:booktype')} *</label>
                                    <Select
                                        className="mb-3"
                                        name="selectedBookType"
                                        options={this.state.bookType}
                                        placeholder={t('common:select')}
                                        searchable={true}
                                        value={this.state.defaultBookType}
                                        onChange={(data) => {
                                            this.selectList('BookType', data)
                                        }}
                                    />
                                    {this.state.errors.selectedBookType && (
                                        <label className="error text-danger">
                                            {this.state.errors.selectedBookType}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:bookscategory')} *</label>
                                    <Select
                                        className="mb-3"
                                        name="selectedBookCategories"
                                        options={this.state.bookCategories}
                                        placeholder={t('common:select')}
                                        searchable={true}
                                        value={this.state.defaultBookCategories}
                                        onChange={(data) => this.selectList('BookCategories', data)}
                                    />
                                    {this.state.errors.selectedBookCategories && (
                                        <label className="error text-danger">
                                            {this.state.errors.selectedBookCategories}
                                        </label>
                                    )}
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('library:publisheddate')} *</label>
                                    <DatePicker
                                        name="publishedDate"
                                        placeholderText={t('library:publisheddate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this.setDate('publishedDate', data)}
                                        selected={this.state.publishedDate ? new Date(this.state.publishedDate) : null}
                                    />
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:addeddate')} *</label>
                                    <DatePicker
                                        name="addedDate"
                                        placeholderText={t('library:addeddate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this.setDate('addedDate', data)}
                                        selected={this.state.addedDate ? new Date(this.state.addedDate) : null}
                                    />
                                    {this.state.errors.addedDate && (
                                        <label className="error text-danger">{this.state.errors.addedDate}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea
                                        name="description"
                                        onChange={this._handleChange}
                                        placeholder={t('classes:subject.add.descriptionPlaceholder')}
                                        type="text"
                                        value={this.state.description}
                                    />
                                    {this.state.errors.description && (
                                        <label className="error text-danger">{this.state.errors.description}</label>
                                    )}
                                </div>
                            </div>
                        </form>

                        <>
                            <hr />
                            <div className="fee-title-btn">
                                <h6>{t('library:bookshelves')}:</h6>
                                <Button
                                    onClick={() => this.addRow()}
                                    type="button"
                                    text={SVG.addTable()}
                                    main={false}
                                />
                            </div>
                            <hr />
                            <div className="main-table">
                                <div className="table-all table-responsive">
                                    <table className="table responsiveTable fee_table">
                                        <thead>
                                            <tr>
                                                <th>{t('library:shelf')}</th>
                                                <th>{t('accounting:quantity')}</th>
                                                <th>{t('fee:action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{this.loadBookShelf()}</tbody>
                                    </table>
                                    {this.state.shelfElements.length == 0 && (
                                        <p className="no-data">
                                            {' '}
                                            {SVG.nodata()} {t('library:nobookshelves')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>

                        <div className="row clearfix">
                            <div className="col-sm-3 float-left form-group">
                                <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <Fragment>{this.deleteModal()}</Fragment>
            </div>
        )
    }
}

const BooksEdit = withTranslation()(connect(mapStateToProps)(BooksEditComponent))

export { BooksEdit }
