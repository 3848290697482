import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addEmployee, activeEmployeeCategory, activeRole, findUser } from '~/services/api'
import { EmployeeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { employee_type } from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'
type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    email: String,
    data: Array,
    loading: Boolean,
    dob: String,
    joining_date: String,
    errors: Object,
    employees: Array,
    section_id: integer,
    employee_category: Array,
}

export class AbstractEmployeeAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: null,
            loading: false,
            joining_date: null,
            dob: null,
            selectedEmployeCategory: null,
            email: null,
            user_type: this.props.match.params.user_type,
            role_id: this.props.match.params.role_id,
            errors: {
                name: null,
                dob: null,
                joining_date: null,
            },
            employee_regcode: '',
            employee_regcode_error: null,
            found_employee: false,
            employees: [],
            employee_category: [],
            selectedRole: 0,
            selectedUserType: '',
            defaultUserType: this.props.match.params.user_type,
            defaultRole: '',
        }
        this._handleChange = this._handleChange.bind(this)
        this.clearRegData = this.clearRegData.bind(this)
        this.findUserByRegCode = this.findUserByRegCode.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadRole()
        this.loadUserTypes()
        this.loadEmployeeCategory()
    }

    clearRegData(user_type) {
        if (user_type == 'employee') {
            this.setState({
                employee_regcode: '',
                employee_regcode_error: null,
                found_employee: false,
                name: '',
                email: '',
                dob: null,
                stringDob: '',
                joining_date: null,
            })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadUserTypes() {
        const data = employee_type.find((el) => el.value == this.props.match.params.user_type)
        this.setState({
            userTypeAry: employee_type,
            defaultUserType: data,
            selectedUserType: this.props.match.params.user_type,
        })
        if (this.props.match.params.user_type == 1) {
            $('#custom-role').show()
        } else {
            $('#custom-role').hide()
        }
    }

    loadRole() {
        this._handleChange('loading', true)
        activeRole(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let customRoleAry = []
            if (response.code == API_SUCCESS) {
                response.data.role.map((item, index) => {
                    customRoleAry.push({ value: item.id, label: item.name })
                })
                const data = customRoleAry.find((el) => el.value == this.props.match.params.role_id)
                this.setState({
                    defaultRole: data,
                    selectedRole: this.props.match.params.role_id != 0 ? this.props.match.params.role_id : 0,
                    customRoleAry,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadEmployeeCategory() {
        activeEmployeeCategory(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let employee_category = []
                response.data.user_category.map((item, index) => {
                    let employee_category_data = {}
                    employee_category_data['value'] = item.id
                    employee_category_data['label'] = item.name + ' - ' + item.code
                    employee_category.push(employee_category_data)
                })
                this.setState({ employee_category })
            }
        })
    }

    findUserByRegCode(user_type) {
        const { t } = this.props
        if (this.state.employee_regcode == null) {
            this.setState({
                employee_regcode_error: t('toaster:required'),
            })
            return
        }
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Searching')
        findUser(this.state.employee_regcode, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response) {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    const user = response.data.user
                    this.setState({
                        name: user.name,
                        email: user.email,
                        dob: moment(user.dob).toDate(),
                        stringDob: user.dob,
                        joining_date: moment(user.joining_date).toDate(),
                        found_employee: true,
                    })
                } else {
                    toastMessage('error', response.message)
                    this.setState({ found_employee: false })
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const dob = moment(this.state.dob)
        const joining_date = moment(this.state.joining_date)
        if (joining_date.diff(dob) < 0) {
            toastMessage('error', t('toaster:joinDateLessThanDOB'))
            return
        }
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        let role_id = 0
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let data = {
                name: this.state.name,
                dob: moment(this.state.dob).format('yyyy-MM-DD'),
                joining_date: moment(this.state.joining_date).format('yyyy-MM-DD'),
                user_type: this.state.user_type,
                users_category_id: this.state.selectedEmployeCategory,
            }
            if (this.state.role_id != null && this.state.role_id != '0' && this.state.role_id != 'null') {
                data['role_id'] = this.state.role_id
                role_id = this.state.role_id
            }
            if (this.state.found_employee) {
                data['reg_no'] = this.state.employee_regcode
            } else {
                data['email'] = this.state.email
            }
            this._handleChange('loading', true)
            addEmployee(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.user.employee.view + '/' + this.state.user_type + '/' + role_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

const AbstractEmployeeAdd = withTranslation()(AbstractEmployeeAddComponent)

export { AbstractEmployeeAdd }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
