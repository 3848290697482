import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { getInviteByToken, userRegister, activeStudentCategoryBySchool, activeEmployeeCategoryBySchool, viewAlumniCategory } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'

export class AbstractRegisterComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            school_id: null,
            invitaion: null,
            email: null,
            user_type: null,
            name: null,
            contact: null,
            dob: null,
            isParent: false,
            isCategory: false,
            parent_email: null,
            parent_name: null,
            parent_contact: null,
            parent_dob: null,
            relation: null,
            remarks: null,
            user_category_id: null,
            userCategoryList: [],
            selectedUserCategory: null,
            batchList: [],
            groupList: [],
            designationList: [],
            companyList: [],
            alumni_batch_id: null,
            alumni_group_id: null,
            alumni_company_id: null,
            alumni_designation_id: null,
            selectedBatch: null,
            selectedGroup: null,
            selectedCompany: null,
            selectedDesignation: null,
            errors: {
                email: null,
                name: null,
                contact: null,
                dob: null,
            },
            parent_errors: {
                parent_email: null,
                parent_name: null,
                parent_contact: null,
                parent_dob: null,
                relation: null,
            },
            alumni_errors: {
                alumni_group_id: null,
                alumni_batch_id: null,
                alumni_designation_id: null,
                alumni_company_id: null,
            },
        }

        this._handleChange = this._handleChange.bind(this)
        this.register = this.register.bind(this)
    }

    componentDidMount() {
        this.loadInvitation()
    }

    loadInvitation() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getInviteByToken(this.props.match.params.id, null).then((response) => {
            this._handleChange('loading', false)
            let classList = []
            if (response.code == API_SUCCESS) {
                if (response.data.invite != null) {
                    const invitation = response.data.invite

                    this.setState(
                        {
                            invitation,
                            user_type: invitation.user_type,
                            name: invitation.name,
                            email: invitation.email,
                            contact: invitation.contact,
                            dob: invitation.dob != null ? moment(invitation.dob).toDate() : null,
                            parent_email: invitation.parent_email,
                            parent_name: invitation.parent_name,
                            parent_contact: invitation.parent_contact,
                            parent_dob: invitation.parent_dob != null ? moment(invitation.parent_dob).toDate() : null,
                            remarks: invitation.remarks,
                            isParent: invitation.isParent,
                            isCategory: invitation.isCategory,
                            relation: invitation.relation,
                            school_id: invitation.school_id,
                            user_category_id: invitation.users_category_id,
                            alumni_batch_id: invitation.alumni_batch_id,
                            alumni_group_id: invitation.alumni_group_id,
                            alumni_company_id: invitation.alumni_company_id,
                            alumni_designation_id: invitation.alumni_designation_id,
                        },
                        () => {
                            if (invitation.user_type == UserType.Alumni) {
                                this.loadAlumniCategory()
                            } else {
                                this.loadCategory()
                            }
                        }
                    )
                } else {
                    redirect(route.index, this.props.history)
                }
            }
        })
    }

    loadAlumniCategory() {
        this._handleChange('loading', true)
        viewAlumniCategory(this.state.school_id).then((response) => {
            this._handleChange('loading', false)
            let batchList = []
            let groupList = []
            let designationList = []
            let companyList = []
            let selectedBatch = null
            let selectedGroup = null
            let selectedCompany = null
            let selectedDesignation = null
            if (response.code == API_SUCCESS) {
                const alumni_category = response.data.alumni_category
                alumni_category.map((item) => {
                    let category = {}
                    category['value'] = item.id
                    category['label'] = item.name
                    if (item.id == this.state.alumni_batch_id) {
                        selectedBatch = category
                    } else if (item.id == this.state.alumni_group_id) {
                        selectedGroup = category
                    } else if (item.id == this.state.alumni_company_id) {
                        selectedCompany = category
                    } else if (item.id == this.state.alumni_designation_id) {
                        selectedDesignation = category
                    }
                    if (item.type == ALUMNI_CATEGORY.Alumni_Group) {
                        groupList.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Batch) {
                        batchList.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Designation) {
                        designationList.push(category)
                    } else {
                        companyList.push(category)
                    }
                })
            }

            this.setState({
                batchList,
                groupList,
                designationList,
                companyList,
                selectedBatch,
                selectedGroup,
                selectedCompany,
                selectedDesignation,
            })
        })
    }

    loadCategory() {
        if (this.state.user_type == UserType.Student) {
            this._handleChange('loading', true)
            activeStudentCategoryBySchool(this.state.school_id).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    let userCategoryList = []
                    response.data.user_category.map((item, index) => {
                        let category = {}
                        category['value'] = item.id
                        category['label'] = item.name + ' - ' + item.code
                        userCategoryList.push(category)
                    })
                    this.setState({ userCategoryList }, () => {
                        if (this.state.userCategoryList.length > 0) {
                            let selectedUserCategory = this.state.userCategoryList.find((item) => item.value == this.state.user_category_id)
                            if (selectedUserCategory == null) {
                                selectedUserCategory = this.state.userCategoryList[0]
                            }
                            this.setState({
                                selectedUserCategory,
                                user_category_id: selectedUserCategory.value,
                            })
                        }
                    })
                }
            })
        } else {
            this._handleChange('loading', true)
            activeEmployeeCategoryBySchool(this.state.school_id).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    let userCategoryList = []
                    response.data.user_category.map((item, index) => {
                        let category = {}
                        category['value'] = item.id
                        category['label'] = item.name + ' - ' + item.code
                        userCategoryList.push(category)
                    })
                    this.setState({ userCategoryList }, () => {
                        if (this.state.userCategoryList.length > 0) {
                            let selectedUserCategory = this.state.userCategoryList.find((item) => item.value == this.state.user_category_id)
                            if (selectedUserCategory == null) {
                                selectedUserCategory = this.state.userCategoryList[0]
                            }
                            this.setState({
                                selectedUserCategory,
                                user_category_id: selectedUserCategory.value,
                            })
                        }
                    })
                }
            })
        }
    }

    register(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        let validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            if (this.state.isParent == true) {
                const parent_errors = validation.required(Object.keys(this.state.parent_errors), this.state)
                this.setState({ parent_errors })
                validate = Object.keys(parent_errors).filter((item) => parent_errors[item] != null)
            }
            if (this.state.user_type == UserType.Alumni) {
                const alumni_errors = validation.required(Object.keys(this.state.alumni_errors), this.state)
                this.setState({ alumni_errors })
                validate = Object.keys(alumni_errors).filter((item) => alumni_errors[item] != null)
            }
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)

                const data = {
                    user_type: this.state.user_type,
                    email: this.state.email,
                    name: this.state.name,
                    contact: this.state.contact,
                    dob: moment(this.state.dob).format('yyyy-MM-DD'),
                    parent_email: this.state.parent_email,
                    parent_name: this.state.parent_name,
                    parent_contact: this.state.parent_contact,
                    parent_dob: moment(this.state.parent_dob).format('yyyy-MM-DD'),
                    relation: this.state.relation,
                    users_category_id: this.state.user_category_id,
                    alumni_batch_id: this.state.alumni_batch_id,
                    alumni_group_id: this.state.alumni_group_id,
                    alumni_company_id: this.state.alumni_company_id,
                    alumni_designation_id: this.state.alumni_designation_id,
                }
                userRegister(this.props.match.params.id, data, null).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        redirect(route.index)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors, parent_errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }
}

export function mapStateToProps(state: Object) {
    return {}
}
