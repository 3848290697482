import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addSmsPackage } from '~/services/api'
import { SmsPackageDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    limit: String,
    type: String,
    currency: String,
    country: Array,
    errors: Object,
    loading: Boolean,
}

export class AbstractSmsPackageAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            code: '',
            limit: 0,
            type: 0,
            currency: 'INR',
            rate: 0,
            country: [],
            errors: {
                name: null,
                code: null,
                limit: null,
                type: null,
                country: null,
                currency: null,
                rate: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data: SmsPackageDTO = {
                name: this.state.name,
                code: this.state.code,
                limit: this.state.limit,
                type: this.state.type,
                country: this.state.country,
                currency: this.state.currency,
                rate: this.state.rate,
            }
            this._handleChange('loading', true)
            addSmsPackage(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.sms_package.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
