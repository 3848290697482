import $ from 'jquery'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { configRoute, convertADtoBS, getInvoiceId } from '../../../../../../helper/function/abstract'
import { AbstractInvoiceDetailsComponent, mapStateToProps } from './abstract'

class InvoiceDetailsComponent extends AbstractInvoiceDetailsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.ref
        this.getAddModal = this.getAddModal.bind(this)
        this._setDate = this._setDate.bind(this)
        this._setAccounts = this._setAccounts.bind(this)
        this.clearState = this.clearState.bind(this)
    }
    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }
    _setDate(data) {
        this._handleChange('date', data)
    }
    _setAccounts(data) {
        this.setState({
            defaultAccount: data,
            selectedAccount: data.value,
        })
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('income:addPayment')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('header:date')} *</label>
                                        <DatePicker
                                            placeholderText={t('calendar:event:schedule:add:datePlaceholder')}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            selected={this.state.date}
                                            onChange={this._setDate}
                                        />
                                        {this.props.school != null &&
                                            this.props.school.school != null &&
                                            this.props.school.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.date != ''
                                                        ? convertADtoBS(this.state.date) != null
                                                            ? convertADtoBS(this.state.date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                        {this.state.errors.date && (
                                            <label className="error text-danger">{this.state.errors.date}</label>
                                        )}
                                    </div>

                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('income:amount')} *</label>
                                        <Input
                                            name="amount"
                                            onChange={this._handleChange}
                                            placeholder={t('income:amount')}
                                            type="number"
                                            value={this.state.amount}
                                            min={0}
                                            max={this.state.invoice != null && this.state.invoice.due_amount}
                                        />
                                        {this.state.errors.amount && (
                                            <label className="error text-danger">{this.state.errors.amount}</label>
                                        )}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('sidebar:account')} *</label>
                                        <Select
                                            name="accounts"
                                            options={this.state.accounts}
                                            placeholder={t('sidebar:account')}
                                            value={this.state.defaultAccount}
                                            searchable={true}
                                            onChange={(data) => this._setAccounts(data)}
                                        />
                                        {this.state.errors.selectedAccount && (
                                            <label className="error text-danger">
                                                {this.state.errors.selectedAccount}
                                            </label>
                                        )}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('income:reference')} *</label>
                                        <Input
                                            name="reference"
                                            onChange={this._handleChange}
                                            value={this.state.reference}
                                            placeholder={t('income:reference')}
                                        />
                                        {this.state.errors.reference && (
                                            <label className="error text-danger">{this.state.errors.reference}</label>
                                        )}
                                    </div>
                                </div>

                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea
                                            name="description"
                                            onChange={this._handleChange}
                                            placeholder={t('common:description')}
                                            value={this.state.description}
                                            type="text"
                                        />
                                        {this.state.errors.description && (
                                            <label className="error text-danger">{this.state.errors.description}</label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button onClick={this.onSave} type="submit" text={t('savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    deleteModal() {
        const { t } = this.props
        const payments = this.state.payments
        const modal = payments.map((item, index) => {
            return (
                <div
                    key={index}
                    id={'modal_delete_' + item.id}
                    data-id={item.id}
                    className="modal fade show"
                    role="dialog"
                >
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <div class="modal-body">
                            <span>{t('activity:delete_msg')}</span>
                        </div>
                        <br />
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button
                                    onClick={(e) => this.onDelete(e, item.id, index)}
                                    type="submit"
                                    text={t('delete')}
                                    main={true}
                                />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    onSave(e) {
        super.onSave(e).then((response) => {
            if (response) {
                $('#close_add').trigger('click')
                // $('body').removeClass('modal-open');
                // $('.modal-backdrop').remove();
            }
        })
    }

    onDelete(e, id, index) {
        super.onDelete(e, id, index).then((response) => {
            if (response) {
                $('#close_add').trigger('click')
                $('body').removeClass('modal-open')
                $('.modal-backdrop').remove()
            }
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                {this.props.location.pathname.includes(configRoute(route.admin.accounting.income.invoice.details)) && (
                    <Sidebar id="income" isAccount={true} />
                )}
                {!this.props.location.pathname.includes(configRoute(route.admin.accounting.income.invoice.details)) && (
                    <Sidebar id="fee" isAccount={false} />
                )}

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        {this.props.location.pathname.includes(
                            configRoute(route.admin.accounting.income.invoice.details)
                        ) && (
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                                </li>
                                <li>
                                    <a href={route.admin.accounting.income.menu}>
                                        {t('income:income')} &nbsp; &gt; &nbsp;
                                    </a>
                                </li>
                                <li>
                                    <a href={route.admin.accounting.income.invoice.view}>
                                        {t('income:invoice')} &nbsp; &gt; &nbsp;{' '}
                                    </a>
                                </li>
                                <li>{t('common:details')}</li>
                            </ul>
                        )}
                        {!this.props.location.pathname.includes(
                            configRoute(route.admin.accounting.income.invoice.details)
                        ) && (
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.student.fee.view}>{t('fee:Fee')} &nbsp; &gt; &nbsp; </a>
                                </li>
                                <li>{t('common:details')}</li>
                            </ul>
                        )}
                        <div className="row clearfix">
                            <div className="col-md-8">
                                <h5 className="main-title heading5">
                                    {this.state.invoice != null
                                        ? getInvoiceId(this.state.invoice.invoice_id)
                                        : 'Invoice'}{' '}
                                    :
                                </h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    {this.props.location.pathname.includes(
                                        configRoute(route.admin.accounting.income.invoice.details)
                                    ) &&
                                        this.state.invoice != null &&
                                        this.state.invoice.status != 4 && (
                                            <Button
                                                modal="modal_add"
                                                type="button"
                                                text={t('income:addPayment')}
                                                main={true}
                                            />
                                        )}

                                    <ReactToPrint
                                        documentTitle={
                                            this.props.school.short_code +
                                            '-' +
                                            getInvoiceId(this.state.invoice != null && this.state.invoice.invoice_id)
                                        }
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        ref={(div) => {
                            this.ref = div
                        }}
                    >
                        <div className="main-table">
                            {this.state.invoice != null && (
                                <div className="table-responsive">
                                    <table className="table invoice_table">
                                        <tr className="invoice_table_border">
                                            <td colSpan={7} className="text-center">
                                                <div className="invoice-image">
                                                    <img src={this.props.school.profile} />
                                                </div>
                                                <div className="invoice-title">{this.props.school.name}</div>
                                                <div className="invoice-sub-title">{t('income:invoice')}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={7} className="text-right">
                                                <div className="invoice_detail">
                                                    <strong>
                                                        {getInvoiceId(
                                                            this.state.invoice != null && this.state.invoice.invoice_id
                                                        )}
                                                    </strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="invoice_detail">
                                                    <strong>{t('gamification:user')} : </strong>
                                                    <br />
                                                    {this.state.customer}
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div className="invoice_detail">
                                                    <strong>{t('accounting:category')} : </strong>
                                                    <br />
                                                    {this.state.category}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="invoice_detail">
                                                    <strong>
                                                        {t('income:invoice')} {t('income:reference')} :{' '}
                                                    </strong>
                                                    <br />
                                                    {this.state.invoice.ref_number}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="invoice_detail">
                                                    <strong>{t('income:issuedate')} : </strong>
                                                    <br />
                                                    {this.props.school.date_format == 'BS'
                                                        ? convertADtoBS(this.state.invoice.issue_date)
                                                        : this.state.invoice.issue_date}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="invoice_detail">
                                                    <strong>{t('income:duedate')} : </strong>
                                                    <br />
                                                    {this.props.school.date_format == 'BS'
                                                        ? convertADtoBS(this.state.invoice.due_date)
                                                        : this.state.invoice.due_date}
                                                </div>
                                            </td>
                                        </tr>

                                        {this.state.productItem.length > 0 && (
                                            <Fragment>
                                                <tr className="invoice_table_border invoice_table_header_dark">
                                                    <td>
                                                        <strong>{t('activity:item')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('accounting:quantity')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('income:price')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('income:discount')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('income:tax')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('common:description')}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{t('income:amount')}</strong>
                                                    </td>
                                                </tr>
                                                {this.state.productItem.map((item, index) => {
                                                    const associatedDetails = this.state.finalTaxes.find(
                                                        (taxInfo) => taxInfo.product_id === item.product_id
                                                    )
                                                    const taxArray = associatedDetails ? associatedDetails.taxArray : []
                                                    const totalAmount = associatedDetails
                                                        ? associatedDetails.totalAmount
                                                        : []

                                                    return (
                                                        <tr>
                                                            <td>{this.state.products[item.product_id]}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>
                                                                <small>{this.props.school.currency}</small> &nbsp;
                                                                {parseFloat(item.price).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;{parseFloat(item.discount).toFixed(2)}
                                                            </td>
                                                            <td>{taxArray}</td>
                                                            <td style={{ width: '300px' }}>{item.description}</td>
                                                            <td style={{ width: '150px' }}>
                                                                <small>{this.props.school.currency}</small> &nbsp;
                                                                {parseFloat(totalAmount).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tfoot className="tfootstrong">
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>
                                                            <strong>{t('fee:subTotal')}</strong>
                                                        </td>
                                                        <td class="text-end subTotal">
                                                            <small>{this.props.school.currency}</small> &nbsp;
                                                            {parseFloat(this.state.totalCalculation.subTotal).toFixed(
                                                                2
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>
                                                            <strong>{t('income:discount')}</strong>
                                                        </td>
                                                        <td class="text-end totalDiscount">
                                                            <small>{this.props.school.currency}</small> &nbsp;
                                                            {parseFloat(this.state.discount).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>
                                                            <strong>{t('income:tax')}</strong>
                                                        </td>
                                                        <td class="text-end totalTax">
                                                            <small>{this.props.school.currency}</small>&nbsp;
                                                            {parseFloat(this.state.totalCalculation.totalTax).toFixed(
                                                                2
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td class="blue-text">
                                                            <strong>
                                                                {t('fee:total')} {t('income:amount')}
                                                            </strong>
                                                        </td>
                                                        <td class="text-end totalAmount blue-text">
                                                            <small>{this.props.school.currency}</small>&nbsp;
                                                            {parseFloat(
                                                                this.state.totalCalculation.finalTotalAmount
                                                            ).toFixed(2)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td class="blue-text">
                                                            <strong>
                                                                {t('accounting:paid')} {t('income:amount')}
                                                            </strong>
                                                        </td>
                                                        <td class="text-end totalAmount blue-text">
                                                            <small>{this.props.school.currency}</small> &nbsp;
                                                            {parseFloat(this.state.totalPaidAmount).toFixed(2)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td class="blue-text">
                                                            <strong>
                                                                {t('accounting:due')} {t('income:amount')}
                                                            </strong>
                                                        </td>
                                                        <td class="text-end totalAmount blue-text">
                                                            <small>{this.props.school.currency}</small> &nbsp;
                                                            {parseFloat(this.state.invoice.due_amount).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Fragment>
                                        )}
                                    </table>
                                </div>
                            )}
                            {this.state.invoice == null && (
                                <p className="no-data">
                                    {SVG.nodata()}
                                    {t('common:table.nodata')}
                                </p>
                            )}
                        </div>
                        {this.state.payments.length > 0 && (
                            <div className="main-table mt-3">
                                <div className="table-responsive">
                                    <h6 className="h6 my-3 pl-3">
                                        <strong>{t('income:receiptSummary')}</strong>
                                    </h6>

                                    <table className="table invoice_table">
                                        <tr className="invoice_table_border invoice_table_header_dark">
                                            <td>
                                                <strong>{t('header:date')}</strong>
                                            </td>
                                            <td>
                                                <strong>{t('income:amount')}</strong>
                                            </td>
                                            <td>
                                                <strong>{t('income:paymentType')}</strong>
                                            </td>
                                            <td>
                                                <strong>{t('sidebar:account')}</strong>
                                            </td>
                                            <td>
                                                <strong>{t('banking:reference')}</strong>
                                            </td>
                                            <td>
                                                <strong>{t('common:description')}</strong>
                                            </td>
                                            {this.props.location.pathname.includes(
                                                configRoute(route.admin.accounting.income.invoice.details)
                                            ) && (
                                                <td>
                                                    <strong>{t('fee:action')}</strong>
                                                </td>
                                            )}
                                        </tr>

                                        {this.state.payments.map((item, index) => {
                                            const account = this.state.accounts.find(
                                                (acc) => acc.value === item.account_id
                                            )
                                            const accountName = account ? account.label : 'N/A'
                                            return (
                                                <tr>
                                                    <td>{item.date}</td>
                                                    <td>
                                                        <small>{this.props.school.currency}</small>&nbsp;{item.amount}
                                                    </td>
                                                    <td>{item.payment_type}</td>
                                                    <td>{accountName}</td>
                                                    <td>{item.reference}</td>
                                                    <td>{item.description}</td>
                                                    {this.props.location.pathname.includes(
                                                        configRoute(route.admin.accounting.income.invoice.details)
                                                    ) && (
                                                        <td>
                                                            <Button
                                                                modal={'modal_delete_' + item.id}
                                                                type="button"
                                                                text={SVG.deleteComment()}
                                                                main={false}
                                                            />
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Fragment>{this.getAddModal()}</Fragment>

                <Fragment>{this.deleteModal()}</Fragment>
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('date', '')
                component._handleChange('defaultAccount', null)
                component._handleChange('selectedAccount', '')
                component._handleChange('reference', '')
                component._handleChange('description', '')

                const errors = {
                    selectedAccount: null,
                    date: null,
                    reference: null,
                    description: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

const InvoiceDetails = withTranslation()(connect(mapStateToProps)(InvoiceDetailsComponent))
export { InvoiceDetails }
