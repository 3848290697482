import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addNotice } from '~/services/api'
import { NoticeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    title: String,
    code: String,
    description: String,
    media: object,
    errors: Object,
    loading: Boolean,
}

export class AbstractNoticeAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            title: '',
            code: '',
            description: '',
            media: {},
            errors: {
                title: null,
                code: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                code: this.state.code,
                description: this.state.description,
                media: this.state.media,
            }
            this._handleChange('loading', true)
            addNotice(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.notice.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
