import React, { Component } from 'react'
import persistence from '~/store/reducers'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import getFont from '~/helper/font-awesome'
import { App } from './app'
import regeneratorRuntime from 'regenerator-runtime'
import { toastMessage } from '~/helper/function/util'
getFont()

const { store, persistor } = persistence()

export default function AppContainer() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    )
}
