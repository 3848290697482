import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSectionComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { roll_type } from '~/helper/constant/roll_type'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import { language } from '../../../../helper/constant/language'
class SectionComponent extends AbstractSectionComponent {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            ...this.state,
            language: this.props.i18n.language,
            rolltypeOption: roll_type.map((item) => {
                return {
                    value: item.value,
                    label: t(item.translate),
                }
            }),
        }
        this.getModal = this.getModal.bind(this)
        this.getAddModal = this.getAddModal.bind(this)
        this.getEditModal = this.getEditModal.bind(this)
        this.setRoll = this.setRoll.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        const { t } = this.props
        if (prevState.language != this.props.i18n.language) {
            this.setState({
                language: this.props.i18n.language,
                rolltypeOption: roll_type.map(
                    (item) => {
                        return {
                            value: item.value,
                            label: t(item.translate),
                        }
                    },
                    () => {
                        this._handleChange('defaultRoll', this.state.rolltypeOption[0])
                    }
                ),
            })
        }
    }
    setRoll(data) {
        this._handleChange('roll_type', data.value)
        this._handleChange('defaultRoll', data)
    }
    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_section_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('classes:section.title')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('classes:section.add.nameLabel')} *</label>
                                        <Input name="name" onChange={this._handleChange} placeholder={t('classes:section.add.namePlaceholder')} type="text" value={this.state.name} />
                                        {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('classes:section.add.codeLabel')} *</label>
                                        <Input name="code" onChange={this._handleChange} placeholder={t('classes:section.add.codePlaceholder')} type="text" value={this.state.code} />
                                        {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('classes:section.add.rollNoLabel')} *</label>
                                        <Select name="roll_type" options={this.state.rolltypeOption} placeholder={t('classes:section.add.rollNoLabel')} searchable={true} value={this.state.defaultRoll} onChange={this.setRoll} />
                                        {this.state.errors.roll_type && <label className="error text-danger">{this.state.errors.roll_type}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    getEditModal() {
        const { t } = this.props

        if (this.state.classes.section) {
            const modal = this.state.classes.section.map((item, index) => {
                return (
                    <div key={index} data-id={index} id={'modal_section_edit_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('classes:section.edit.title')}</span>
                            </div>
                            <br />
                            <form className="p-0">
                                <div className="modal-content">
                                    <div className="pop-main-content">
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('classes:section.add.nameLabel')} *</label>
                                                <Input id={'name_' + item.id} name="name" onChange={this._handleChange} placeholder={t('classes:section.add.namePlaceholder')} type="text" value={this.state.name} />
                                                {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                            </div>
                                            <div className="col-sm-6 float-left form-group">
                                                <label className="form-title">{t('classes:section.add.codeLabel')} *</label>
                                                <Input id={'code_' + item.id} disabled="disabled" name="code" placeholder={t('classes:section.add.codeLabel')} type="text" value={item.code} />
                                                {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-6 float-left form-group main-select">
                                                <label className="form-title">{t('classes:section.add.rollNoLabel')} *</label>
                                                <Select name="roll_type" options={this.state.rolltypeOption} value={this.state.defaultRoll} placeholder={t('classes:section.add.rollNoLabel')} searchable={true} onChange={this.setRoll} />
                                                {this.state.errors.roll_type && <label className="error text-danger">{this.state.errors.roll_type}</label>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="text-center">
                                        <Button onClick={this._onEdit} link={item.id} onClick={this._onEdit} type="submit" text={t('common:updateBtn')} main={true} />
                                        <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('common:modal cancel')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            })
            return modal
        }
        return null
    }
    getModal() {
        const { t } = this.props

        if (this.state.classes.section) {
            const modal = this.state.classes.section.map((item, index) => {
                let text = t('student:student-category.disable')
                if (item.status != Status.ACTIVE) {
                    text = t('student:student-category.enable')
                }
                return (
                    <div key={index} id={'modal_section_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('common:warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">
                                        {t('student:student-category.areyousureyouwantto')} {text} {t('classes:section.title')}?
                                    </p>
                                    <p className="delete-file-name">"{item.name + ' - ' + item.code}"</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this.toggle} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                    <button id={'close_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
        return null
    }
    render() {
        const { t } = this.props

        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.school.classes.view}>{t('classes:breadcrumb')} > </a>
                            </li>
                            <li>{t('classes:section.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{this.state.classes && 'Class : ' + this.state.classes.name}</span>
                                <h5 className="main-title heading5">{t('classes:section.title')}:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button modal="modal_section_add" type="button" text={t('common:addBtn')} main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.section} message="classes:section.noSectionMsg" />
                </div>
                {this.getAddModal()}
                {this.state.classes && this.getModal()}
                {this.state.classes && this.getEditModal()}
            </div>
        )
    }
}
const Section = withTranslation()(connect(mapStateToProps)(SectionComponent))
export { Section }
