export const days = [
    { label: 'Sunday', value: 0, translate: 'timetable:sunday' },
    { label: 'Monday', value: 1, translate: 'timetable:monday' },
    { label: 'Tuesday', value: 2, translate: 'timetable:tuesday' },
    { label: 'Wednesday', value: 3, translate: 'timetable:wednesday' },
    { label: 'Thursday', value: 4, translate: 'timetable:thursday' },
    { label: 'Friday', value: 5, translate: 'timetable:friday' },
    { label: 'Saturday', value: 6, translate: 'timetable:saturday' },
]

export const short_days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
