import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as UserType from '~/helper/constant/user_type'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Fragment } from 'react'
import * as route from '~/helper/constant/route'
import { AbstractShelfManagementComponent, mapStateToProps } from './abstract'
import {ShelfManagementDetails} from '../details/details'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import $, { type } from 'jquery'

class ShelfManagementViewComponent extends AbstractShelfManagementComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusToggleModal = this.getStatusToggleModal.bind(this)
        this.detailShelfModal =  this.detailShelfModal.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }

    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.bookShelfDetail != this.state.bookShelfDetail) {
            $('#detailShelfModal').modal('show')
        }
    }
    toggle(e) {
        const link = e.target.getAttribute('link')
        super.toggle(link).then((response) => {
            if (response) { 
                $('#close_' + link).trigger('click')
            }
        })
    }

    getStatusToggleModal(){
        const {t} = this.props
        const modal = this.state.tableData.map((item, index)=>{
            let text = t('fee:disable')
            if (item.status != Status.ACTIVE){
                text = t('fee:enable')
            }
            return(
                <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('calendar:event.areyousureyouwantto')} {text + ' ' + item.name}?"
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    detailShelfModal() {
        const { t } = this.props
        const bookShelfDetail = this.state.bookShelfDetail
        if (bookShelfDetail != null) {
            return (
                <div
                    id="detailShelfModal"
                    className="modal fade show"
                    role="dialog"
                    data-backdrop={false}
                    data-keyboard={false}
                >
                    <div className="modal-dialog modal-xl pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() =>
                                    this.setState({
                                        bookShelfDetail: null,
                                    })
                                }
                            >
                                ×
                            </button>
                            <span>{bookShelfDetail.name}</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <ShelfManagementDetails id={bookShelfDetail.id} />
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button
                                    id="close_generate"
                                    type="button"
                                    className="cancel-button"
                                    data-dismiss="modal"
                                    onClick={() =>
                                        this.setState({
                                            bookShelfDetail: null,
                                        })
                                    }
                                >
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="library" isAccount={false} />
                <Loading status={this.state.loading} text={this.state.loading_text} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('library:shelfmanagement')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('library:shelfmanagement')}</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.library.shelfmanagement.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>

                    <div className="main-form">
                        <Table data={this.state.tableStructure} />
                        <br />
                    </div>
                    {this.detailShelfModal()}
                    {this.getStatusToggleModal()}
                </div>

            </div>
        )
    }
}
const ShelfManagementView = withTranslation()(connect(mapStateToProps)(ShelfManagementViewComponent))

export { ShelfManagementView }
