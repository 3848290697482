import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getRoutine, getClass, viewQuestionBank, addRoutine, deleteRoutine } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import moment from 'moment'
import * as Status from '~/helper/constant/status'
import * as validation from '~/helper/constant/validation'
import * as UserType from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../../../helper/function'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam: Object,
    routine: Array,
    date: Array,
    classes: Array,
    activeClass: Object,
    subject: Array,
    question_bank: Array,
    filtered_question_bank: Array,
    activeTab: Boolean,
    exam_date: String,
    start_time: String,
    end_time: String,
    pass_mark: Double,
    full_mark: Double,
    duration: String,
    is_online: Boolean,
    negative_marking: Boolean,
    show_result: Boolean,
    show_correct_answer: Boolean,
    skip_question: Boolean,
    selectedSubject: Object,
    selectedQuestionBank: Object,
    errors: Object,
    online_errors: Object,
}

export class AbstractRoutineScheduleComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classes: [],
            routine: [],
            date: [],
            subject: [],
            question_bank: [],
            filtered_question_bank: [],
            activeTab: 0,
            is_online: false,
            negative_marking: false,
            show_result: false,
            show_correct_answer: false,
            skip_question: false,
            errors: {
                exam_date: null,
                start_time: null,
                end_time: null,
                pass_mark: null,
                full_mark: null,
                selectedSubject: null,
            },
            online_errors: {
                selectedQuestionBank: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadExam = this.loadExam.bind(this)
        this.calculateDays = this.calculateDays.bind(this)
        this.loadClass = this.loadClass.bind(this)
        this._onSave = this._onSave.bind(this)
        this._delete = this._delete.bind(this)
        this._start = this._start.bind(this)
        this.filterQuestionBank = this.filterQuestionBank.bind(this)
    }

    componentDidMount() {
        this.loadClass()
        this.loadQuestionBank()
        this.loadExam()
        this.clearState()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    filterQuestionBank() {
        if (this.state.selectedSubject != null && this.state.full_mark != undefined) {
            const filtered = this.state.question_bank.filter((item) => item.subject_id == this.state.selectedSubject.value && item.total_marks == this.state.full_mark)
            if (filtered.length > 0) {
                this.setState({
                    filtered_question_bank: filtered,
                    selectedQuestionBank: filtered[0],
                })
            } else {
                this.setState({
                    filtered_question_bank: [],
                    selectedQuestionBank: '',
                })
            }
        } else {
            this.setState({
                filtered_question_bank: [],
                selectedQuestionBank: '',
            })
        }
    }

    _start(e) {
        const { t } = this.props
        e.preventDefault()
        const link = e.target.getAttribute('link')
        $('#close_start_' + link).trigger('click')
        if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            const routine = this.state.routine.find((item) => item.id == link)
            if (routine != null) {
                const exam_date = moment(routine.exam_date, 'yyyy-MM-DD')
                const start_time = moment(routine.start_time, 'hh:mm a')
                const end_time = moment(routine.end_time, 'hh:mm a')
                const today = moment(moment().format('yyyy-MM-DD'))
                const current_time = moment(moment(), 'hh:mm a')
                if (exam_date.isSame(today)) {
                    if (current_time.isSameOrAfter(start_time) && current_time.isSameOrBefore(end_time)) {
                        window.open(route.online + '/' + link, '_blank')
                    } else if (current_time.isBefore(start_time)) {
                        toastMessage('error', t('exam:examStartMsg') + routine.exam_date + ' - ' + routine.start_time)
                    } else {
                        toastMessage('error', t('exam:examConducted'))
                    }
                } else if (exam_date.isBefore(today)) {
                    toastMessage('error', t('exam:examConducted'))
                } else {
                    toastMessage('error', t('exam:examStartMsg') + routine.exam_date)
                }
            }
        } else {
            window.open(route.online + '/' + link, '_blank')
        }
    }

    _delete(e) {
        const link = e.target.getAttribute('link')
        $('#close_delete_' + link).trigger('click')
        deleteRoutine(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadExam()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadClass() {
        this._handleChange('loading', 'Loading')
        this._handleChange('loading', true)
        getClass(this.props.match.params.classId, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (response.data.class != null) {
                    const subject = []
                    const activeClass = response.data.class
                    activeClass.subject.map((item) => {
                        const data = {
                            label: item.name + ' - ' + item.code,
                            value: item.id,
                        }
                        subject.push(data)
                    })
                    
                    this.setState({
                        subject,
                        activeClass,
                    })
                }
            }
        })
    }

    loadQuestionBank() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewQuestionBank(this.props.match.params.classId, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const question_bank = []
                response.data.question_bank.map((item) => {
                    const data = {
                        label: item.title,
                        value: item.id,
                        subject_id: item.subject_id,
                        total_marks: item.totalMarks,
                    }
                    question_bank.push(data)
                })
                this.setState({
                    question_bank,
                })
            }
        })
    }

    loadExam() {
        this._handleChange('loading', 'Loading')
        this._handleChange('loading', true)
        getRoutine(this.props.match.params.id, this.props.match.params.classId, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const classes = []
                response.data.post.class_access_information.map((item) => {
                    classes.push(item)
                })
                this.setState(
                    {
                        exam: response.data.post,
                        routine: response.data.post.exam,
                        classes,
                        date: [],
                    },
                    () => {
                        this.calculateDays(this.state.exam.from_date)
                    }
                )
            }
        })
    }

    calculateDays(date) {
        if (this.state.exam != null) {
            const current = moment(date, 'yyyy-MM-DD')
            const end = moment(this.state.exam.to_date, 'yyyy-MM-DD')
            const data = this.state.date
            if (current.isBefore(end)) {
                const result = {
                    label: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && date != null ? (convertADtoBS(date) != null ? convertADtoBS(date) + ' BS' : 'Invalid Date Range') : moment(date).format('DD MMM'),
                    value: date,
                }
                data.push(result)
                this.setState(
                    {
                        date: data,
                    },
                    () => {
                        const next = current.add(1, 'days').format('yyyy-MM-DD')
                        this.calculateDays(next)
                    }
                )
            } else {
                const result = {
                    label: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.exam.to_date != null ? (convertADtoBS(this.state.exam.to_date) != null ? convertADtoBS(this.state.exam.to_date) + ' BS' : 'Invalid Date Range') : moment(this.state.exam.to_date).format('DD MMM'),
                    value: this.state.exam.to_date,
                }
                data.push(result)
                this.setState({
                    date: data,
                })
            }
        }
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        let validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && this.state.is_online) {
            const online_errors = validation.required(Object.keys(this.state.online_errors), this.state)
            this.setState({ online_errors })
            validate = Object.keys(online_errors).filter((item) => online_errors[item] != null)
        }
        if (validate.length == 0) {
            if (parseFloat(this.state.full_mark) <= 0) {
                errors['full_mark'] = t('exam:fullMarks0')
            }
            if (parseFloat(this.state.pass_mark) <= 0) {
                errors['pass_mark'] = t('exam:passMarks0')
            }
            if (parseFloat(this.state.full_mark) <= parseFloat(this.state.pass_mark)) {
                errors['full_mark'] = t('exam:fullMarksless')
                errors['pass_mark'] = t('exam:passMarksGreater')
            }
            const start_time = moment(this.state.start_time, 'hh:mm a')
            const end_time = moment(this.state.end_time, 'hh:mm a')
            if (start_time.isAfter(end_time) || (start_time.hours() == end_time.hours() && start_time.minutes() == end_time.minutes())) {
                errors['start_time'] = t('exam:invalidRange')
                errors['end_time'] = t('exam:invalidRange')
            }
            this.setState({ errors })
            validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    exam_date: moment(this.state.exam_date.value).format('yyyy-MM-DD'),
                    start_time: moment(this.state.start_time).format('hh:mm a'),
                    end_time: moment(this.state.end_time).format('hh:mm a'),
                    pass_mark: this.state.pass_mark,
                    full_mark: this.state.full_mark,
                    is_online: this.state.is_online,
                    negative_marking: this.state.negative_marking,
                    show_result: this.state.show_result,
                    show_correct_answer: this.state.show_correct_answer,
                    skip_question: this.state.skip_question,
                    subject_id: this.state.selectedSubject.value,
                    class_id: this.props.match.params.classId,
                }
                if (this.state.is_online) {
                    data['question_bank_id'] = this.state.selectedQuestionBank.value
                }
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                addRoutine(this.props.match.params.id, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.loadExam()
                    } else {
                        toastMessage('error', response.message)
                    }
                })
            }
        }
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('exam_date', '')
                component._handleChange('start_time', '')
                component._handleChange('end_time', '')
                component._handleChange('pass_mark', '')
                component._handleChange('full_mark', '')
                component._handleChange('is_online', false)
                component._handleChange('negative_marking', false)
                component._handleChange('show_result', false)
                component._handleChange('show_correct_answer', false)
                component._handleChange('skip_question', false)
                component._handleChange('selectedSubject', '')
                component._handleChange('selectedQuestionBank', '')
                component._handleChange('filtered_question_bank', [])
                const errors = {
                    exam_date: null,
                    start_time: null,
                    end_time: null,
                    pass_mark: null,
                    full_mark: null,
                    selectedSubject: null,
                }
                const online_errors = {
                    selectedQuestionBank: null,
                }
                component._handleChange('errors', errors)
                component._handleChange('online_errors', online_errors)
            }
        })
    }
}

const AbstractRoutineSchedule = withTranslation()(AbstractRoutineScheduleComponent)

export { AbstractRoutineSchedule }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
