import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { searchUser, superResetPassword, toggle } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    user: Object,
    search: String,
}

export class AbstractResetUserComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            user: null,
            search: null,
            error: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this._search = this._search.bind(this)
        this._reset = this._reset.bind(this)
        this._toggle = this._toggle.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _reset() {
        if (this.state.user != null) {
            this._handleChange('loading', true)
            superResetPassword(this.state.user.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }

    _toggle() {
        if (this.state.user != null) {
            this._handleChange('loading', true)
            toggle(this.state.user.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState({
                        user: response.data.user,
                    })
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }

    _search() {
        if (this.state.search != '') {
            this._handleChange('loading', true)
            searchUser(this.state.search, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState({
                        user: response.data.user,
                    })
                } else {
                    toastMessage('error', response.message)
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
