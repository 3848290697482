import { Component } from 'react'

type Props = {
    onChange: any,
    type: string,
    value: string,
    placeholder: string,
    style: any,
    name: string,
    className: string,
    id: string,
    disabled: string,
    checked: String,
    inputContainerStyle: String,
    placeholder: String,
    placeholderTextColor: String,
    defaultChecked: Boolean,
    onKeyDown: any,
    readOnly: Boolean,
    min: Integer,
    max: Integer,
    maxLength: Integer,
}

type State = {
    value: string,
}

export class AbstractInputComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            value: this.props.value,
            readOnly: this.props.readOnly,
            inputError: null,
        }
        this._onChange = this._onChange.bind(this)
        this.checkValue = this.checkValue.bind(this)
        this._onNumberChange = this._onNumberChange.bind(this)
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value }, () => {
                if (this.props.onChange) {
                    if (this.props.onChange) {
                        this.props.onChange(this.props.name, this.state.value)
                    }
                }
            })
        }
        if (prevProps.readOnly != this.props.readOnly) {
            this.setState({ readOnly: this.props.readOnly })
        }

        if (prevProps.min != this.props.min) {
            this.setState({ min: this.props.min })
        }

        if (prevProps.max != this.props.max) {
            this.setState({ max: this.props.max })
        }
    }

    checkValue(e) {
        let value = e
        if (typeof document != 'undefined') {
            value = e.target.value
        }
        console.log(value)
        console.log(this.props.min)
        console.log(this.props.max)
        if (parseFloat(value) < parseFloat(this.props.min)) {
            this.setState(
                {
                    value: this.props.min,
                    inputError: 'Valid value is between ' + this.props.min + ' and ' + this.props.max,
                },
                () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.props.name, this.props.min)
                    }
                }
            )
        } else if (parseFloat(this.props.max) < parseFloat(value)) {
            this.setState(
                {
                    value: this.props.max,
                    inputError: 'Valid value is between ' + this.props.min + ' and ' + this.props.max,
                },
                () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.props.name, this.props.max)
                    }
                }
            )
        }
    }

    _onNumberChange(e) {
        let value = e
        if (typeof document != 'undefined') {
            value = e.target.value
        }
        value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        this.setState(
            {
                value,
                inputError: null,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.props.name, value)
                }
            }
        )
    }

    _onChange(e) {
        let value = e
        if (typeof document != 'undefined') {
            value = e.target.value
        }
        this.setState({ value }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.name, value)
            }
        })
    }

    // static getDerivedStateFromProps(props, state) {
    //     if(props.defaultValue != null) {
    //       return {
    //         value: props.value ? props.value : props.defaultValue,
    //         defaultValue: props.defaultValue,
    //       };
    //     }
    // }
}
