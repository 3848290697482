import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { password } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { UserDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    password: String,
    password_confirmation: String,
    errors: Object,
    loading: Boolean,
}

export class AbstractPasswordComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            password: '',
            password_confirmation: '',
            errors: {
                password: null,
                password_confirmation: null,
            },
            user: this.props.user ? this.props.user : null,
        }
        this._onSave = this._onSave.bind(this)
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
            }
            this._handleChange('loading', true)
            password(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    this.setState({
                        password: '',
                        password_confirmation: '',
                        errors: {
                            password: null,
                            password_confirmation: null,
                        },
                    })
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        user: state.user,
        school: state.school,
        token: state.token,
    }
}
