import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { viewAlumniCategory, addAlumni } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as validation from '~/helper/constant/validation'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    data: Array,
    loading: Boolean,
}

export class AbstractAlumniYearbookComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            reload: false,
            loading: false,
            loading_text: 'Loading...',
            selectedClass: null,
            selectedStudent: [],
            batchList: [],
            groupList: [],
            designationList: [],
            companyList: [],
            aluminiCategoryList: [],
            filteredAlumniCategoryList: [],
            selectedBatch: null,
            selectedGroup: null,
            selectedCompany: null,
            selectedDesignation: null,
            alumni_batch_id: null,
            alumni_group_id: null,
            alumni_company_id: null,
            alumni_designation_id: null,
            alumni_errors: {
                alumni_group_id: null,
                alumni_batch_id: null,
                alumni_designation_id: null,
                alumni_company_id: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.filterYearbook = this.filterYearbook.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    filterYearbook(value) {
        const filteredAlumniCategoryList = this.state.aluminiCategoryList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        this.setState({
            filteredAlumniCategoryList,
        })
    }

    loadAlumniCategory() {
        this._handleChange('loading_text', 'Loading...')
        this._handleChange('loading', true)
        viewAlumniCategory(this.props.school.school_id).then((response) => {
            this._handleChange('loading', false)
            let batchList = []
            let groupList = []
            let designationList = []
            let companyList = []
            let aluminiCategoryList = []
            let filteredAlumniCategoryList = []
            let selectedBatch = null
            let selectedGroup = null
            let selectedCompany = null
            let selectedDesignation = null
            if (response.code == API_SUCCESS) {
                const alumni_category = response.data.alumni_category
                alumni_category.map((item) => {
                    let category = {}
                    category['value'] = item.id
                    category['label'] = item.name
                    if (item.id == this.state.alumni_batch_id) {
                        selectedBatch = category
                    } else if (item.id == this.state.alumni_group_id) {
                        selectedGroup = category
                    } else if (item.id == this.state.alumni_company_id) {
                        selectedCompany = category
                    } else if (item.id == this.state.alumni_designation_id) {
                        selectedDesignation = category
                    }
                    if (item.type == ALUMNI_CATEGORY.Alumni_Group) {
                        groupList.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Batch) {
                        batchList.push(category)
                        aluminiCategoryList.push(item)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Designation) {
                        designationList.push(category)
                    } else {
                        companyList.push(category)
                    }
                })
                filteredAlumniCategoryList = aluminiCategoryList.sort((a, b) => (a.name > b.name ? 1 : -1))
            }
            this.setState({
                aluminiCategoryList,
                filteredAlumniCategoryList,
                batchList,
                groupList,
                designationList,
                companyList,
                reload: false,
            })
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            if (component.state.selectedStudent.length > 0) {
                const alumni_errors = validation.required(Object.keys(component.state.alumni_errors), component.state)
                component.setState({ alumni_errors })
                let validate = Object.keys(alumni_errors).filter((item) => alumni_errors[item] != null)
                if (validate.length == 0) {
                    component._handleChange('loading_text', 'Saving...')
                    component._handleChange('loading', true)
                    const data = {
                        user_id: component.state.selectedStudent,
                        alumni_batch_id: component.state.alumni_batch_id,
                        alumni_group_id: component.state.alumni_group_id,
                        alumni_company_id: component.state.alumni_company_id,
                        alumni_designation_id: component.state.alumni_designation_id,
                    }

                    addAlumni(data, component.props.token).then((response) => {
                        component._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            component._handleChange('reload', true)
                            component.loadAlumniCategory()
                            resolve(true)
                        } else {
                            toastMessage('error', response.message)
                            if (response.data) {
                                if ('errors' in response.data) {
                                    component.setState({ alumni_errors: response.data.errors })
                                }
                            }
                            resolve(false)
                        }
                    })
                }
            } else {
                toastMessage('error', t('toaster:selectStudentMsg'))
                resolve(false)
            }
        })
    }
}

const AbstractAlumniYearbook = withTranslation()(connect(mapStateToProps)(AbstractAlumniYearbookComponent))

export { AbstractAlumniYearbook }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
