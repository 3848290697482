import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewStudentResult, saveResult } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import { toastMessage } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam: Object,
    section: Object,
    student: Array,
    result: Array,
}

export class AbstractResultAssignComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            student: [],
            marksList: [],
            onlineMarksList: [],
            result: [],
            question: [],
            online_exam: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.updateMarks = this.updateMarks.bind(this)
        this._updateAnswerMarks = this._updateAnswerMarks.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadData() {
        this._handleChange('loading', true)
        viewStudentResult(this.props.match.params.classId, this.props.match.params.sectionId, this.props.match.params.routineId, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        exam: response.data.exam,
                        section: response.data.section,
                        student: response.data.student,
                        result: response.data.exam.result,
                        question: response.data.exam.question_bank != null ? response.data.exam.question_bank.question : [],
                        online_exam: response.data.exam.online_exam != null ? response.data.exam.online_exam : [],
                    },
                    () => {
                        this.loadMarks()
                    }
                )
            }
        })
    }

    loadMarks() {
        const marksList = []
        const onlineMarksList = []
        this.state.student.map((item, index) => {
            const result = this.state.result.find((resultItem) => resultItem.user_school_id == item.id)
            let total_marks = 0
            this.state.question.map((question, index) => {
                const studentAnswer = this.state.online_exam.find((result) => result.question_id == question.id && item.id == result.user_school_id)
                let marks = parseFloat(studentAnswer != undefined && studentAnswer != null ? (studentAnswer.marks != null ? studentAnswer.marks : 0) : 0)
                total_marks = total_marks + marks
                if (studentAnswer != undefined && studentAnswer != null) {
                    const data = {
                        id: studentAnswer.id,
                        marks,
                        subjective_answer: studentAnswer.subjective_answers,
                        full_mark: question.marks,
                    }
                    onlineMarksList.push(data)
                }
            })
            let data = {
                user_school_id: item.id,
            }
            if (result != null) {
                data.marks = total_marks != 0 ? total_marks : result.marks
            } else {
                data.marks = total_marks
            }
            marksList.push(data)
        })
        this._handleChange('marksList', marksList)
        this._handleChange('onlineMarksList', onlineMarksList)
    }

    updateMarks(index, value) {
        const { t } = this.props
        let marksList = this.state.marksList
        marksList[index]['marks'] = value == 'NaN' ? '0' : value.toString()

        if (parseFloat(value) > this.state.exam.full_mark) {
            marksList[index]['error'] = t('exam:exceed')
        } else {
            marksList[index]['error'] = null
        }
        this.setState(
            {
                marksList,
            },
            () => {}
        )
    }

    _updateAnswerMarks(id, studentId, studentIndex, value) {
        let onlineMarksList = this.state.onlineMarksList
        onlineMarksList.map((item) => {
            if (item.id == id) {
                value = value != '' ? value : 0
                if (value > item.full_mark) {
                    item.error = t('exam:exceed')
                } else {
                    item.error = null
                }
                item.marks = parseFloat(value)
            }
        })
        this.setState(
            {
                onlineMarksList,
            },
            () => {
                let total_marks = 0
                this.state.question.map((question, index) => {
                    const studentAnswer = this.state.online_exam.find((result) => result.question_id == question.id && studentId == result.user_school_id)
                    let marks = parseFloat(studentAnswer != undefined && studentAnswer != null ? (studentAnswer.marks != null ? studentAnswer.marks : 0) : 0)

                    if (studentAnswer != undefined && studentAnswer != null) {
                        const onlineMarks = this.state.onlineMarksList.find((result) => result.id == studentAnswer.id)
                        marks = onlineMarks != undefined && onlineMarks != null ? onlineMarks.marks : marks
                    }
                    total_marks = total_marks + parseFloat(marks)
                })
                let marksList = this.state.marksList
                marksList[studentIndex]['marks'] = total_marks
                this._handleChange('marksList', marksList)
            }
        )
    }

    _onSave(e) {
        const { t } = this.props
        const result = this.state.marksList.filter((item) => item.marks !== '')
        if (result.length > 0) {
            let error = result.filter((item) => item.error != null)
            if (error.length == 0) {
                error = this.state.onlineMarksList.filter((item) => item.error != null)
                if (error.length == 0) {
                    this._handleChange('loading_text', 'Saving')
                    this._handleChange('loading', true)
                    const data = {
                        exam_id: this.props.match.params.routineId,
                        result,
                        onlineResult: this.state.onlineMarksList,
                    }
                    saveResult(data, this.props.token).then((response) => {
                        this._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                        } else {
                            toastMessage('error', response.message)
                        }
                    })
                }
            }
        } else {
            toastMessage('error', t('exam:insertMarks'))
        }
    }
}

const AbstractResultAssign = withTranslation()(AbstractResultAssignComponent)

export { AbstractResultAssign }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
