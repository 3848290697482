import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Button, Checkbox, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractProductServiceEditComponent, mapStateToProps } from './abstract'

class ProductServiceEditComponent extends AbstractProductServiceEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.selectList = this.selectList.bind(this)
        this.selectTax = this.selectTax.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }
    selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    selectTax(data) {
        if (data == null) {
            this._handleChange('all', false)
            this._handleChange('selectedTax', [])
        } else {
            this._handleChange('selectedTax', data)
        }
    }

    selectAll(key, value) {
        this._handleChange(key, value)
        if (value) {
            this._handleChange('selectedTax', this.state.taxes)
        } else {
            this._handleChange('selectedTax', [])
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="products" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.productservice.view}>
                                    {t('doubleentry:particular')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:editParticular')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input
                                        name="name"
                                        placeholder={t('common:name')}
                                        type="text"
                                        value={this.state.name}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.name && (
                                        <label className="error text-danger">{this.state.errors.name}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:code')} *</label>
                                    <Input
                                        name="sku"
                                        type="text"
                                        placeholder={t('fee:code')}
                                        value={this.state.sku}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.sku && (
                                        <label className="error text-danger">{this.state.errors.sku}</label>
                                    )}
                                </div>
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('common:description')}</label>
                                    <TextArea
                                        onChange={this._handleChange}
                                        placeholder={t('common:description')}
                                        value={this.state.description}
                                    />
                                </div>

                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('accounting:salePrice')} *</label>
                                    <Input
                                        onChange={this._handleChange}
                                        name="sale_price"
                                        placeholder={t('accounting:salePrice')}
                                        type="text"
                                        value={this.state.sale_price}
                                    />
                                    {this.state.errors.sale_price && (
                                        <label className="error text-danger">{this.state.errors.sale_price}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:purchasePrice')} *</label>
                                    <Input
                                        onChange={this._handleChange}
                                        name="purchase_price"
                                        type="text"
                                        placeholder={t('income:purchasePrice')}
                                        value={this.state.purchase_price}
                                    />
                                    {this.state.errors.purchase_price && (
                                        <label className="error text-danger">{this.state.errors.purchase_price}</label>
                                    )}
                                </div> */}
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:tax')} </label>
                                    <Select
                                        name="taxes"
                                        options={this.state.taxes}
                                        isMulti
                                        placeholder={t('fee:select_fee')}
                                        searchable={true}
                                        value={this.state.selectedTax}
                                        onChange={this.selectTax}
                                    />
                                    <div className="form-check">
                                        <Checkbox
                                            id="all"
                                            onChange={(key, value) => this.selectAll(key, value)}
                                            className=""
                                            name="all"
                                            value={this.state.all}
                                        />
                                        <label className="form-check-label form-title small-checkbox" htmlFor="all">
                                            {t('exam:exam:selectAll')}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6 float-left form-group">
                                    <label className="form-title text-left">{t('accounting:category')} *</label>
                                    <Select
                                        name="categories"
                                        options={this.state.categories}
                                        placeholder=""
                                        value={this.state.defaultCategory}
                                        searchable={true}
                                        onChange={(data) => this.selectList('Category', data)}
                                    />

                                    {this.state.errors.categories && (
                                        <label className="error text-danger">{this.state.errors.categories}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:unit')} *</label>
                                    <Select
                                        name="units"
                                        options={this.state.units}
                                        placeholder=""
                                        value={this.state.defaultUnit}
                                        searchable={true}
                                        onChange={(data) => this.selectList('Unit', data)}
                                    />
                                    {this.state.errors.units && (
                                        <label className="error text-danger">{this.state.errors.units}</label>
                                    )}
                                </div>
                                {/* <div className="col-sm-6 float-left form-group">
                                    <label onChange={this._handleChange} className="form-title">{t('accounting:quantity')} *</label>
                                    <Input name="quantity" placeholder={t('accounting:quantity')} type="text" 
                                    value={this.state.quantity && this.state.quantity} /> 
                                    {this.state.errors.quantity && <label className="error text-danger">{this.state.errors.quantity}</label>}
                                   
                                </div> */}
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('attendance:type')} </label>

                                    <Select
                                        name="type_list"
                                        options={this.state.type_list}
                                        placeholder={t('attendance:type')}
                                        searchable={true}
                                        value={this.state.defaultType}
                                        onChange={(data) => this.selectList('Type', data)}
                                    />
                                    {this.state.errors.type_list && (
                                        <label className="error text-danger">{this.state.errors.type_list}</label>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}
const ProductServiceEdit = withTranslation()(connect(mapStateToProps)(ProductServiceEditComponent))
export { ProductServiceEdit }
