import $ from 'jquery'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractInvoiceAddComponent, mapStateToProps } from './abstract'

class InvoiceAddComponent extends AbstractInvoiceAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this._setIssueDate = this._setIssueDate.bind(this)
        this._setDueDate = this._setDueDate.bind(this)
        this._selectList = this._selectList.bind(this)
        this._setQuantity = this._setQuantity.bind(this)
    }

    calculateTotalAmount(product) {
        const quantity = parseFloat(product.quantity) || 0
        const price = parseFloat(product.price) || 0
        const discount = parseFloat(product.discount) || 0
        const defaultProduct = product.defaultProduct || {}
        const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

        // const taxes = product.defaultProduct.selectedTax.map(tax => parseFloat(tax.rate) || 0);

        const pq = quantity * price
        const pqd = pq - discount
        const taxAmount = pqd * taxes.reduce((acc, tax) => acc + tax / 100, 0)
        const totalAmount = pqd + taxAmount

        return totalAmount
    }

    // Calculate the sum of prices for all rows
    calculateTotalPrice(productElements) {
        return productElements.reduce(
            (acc, product) => acc + (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0),
            0
        )
    }

    calculateTotalDiscount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.discount) || 0), 0)
    }

    // Calculate the sum of tax amounts for all rows
    calculateTotalTaxAmount(productElements) {
        return productElements.reduce((acc, product) => {
            const quantity = parseFloat(product.quantity) || 0
            const price = parseFloat(product.price) || 0
            const discount = parseFloat(product.discount) || 0

            // Pass index to retrieve the defaultProduct for the current row
            const defaultProduct = product.defaultProduct || {}
            const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

            const pq = quantity * price
            const pqd = pq - discount
            const taxAmount = pqd * taxes.reduce((taxAcc, tax) => taxAcc + tax / 100, 0)

            return acc + taxAmount
        }, 0)
    }

    calculateFinalTotalAmount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.amount) || 0), 0)
    }

    _setIssueDate(data) {
        this._handleChange('issue_date', data)
    }
    _setDueDate(data) {
        this._handleChange('due_date', data)
    }

    _selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }
    _setProduct(data, i) {
        const productElements = this.state.productElements
        productElements[i].defaultProduct = data
        productElements[i].item = data.value
        productElements[i].amount = this.calculateTotalAmount(productElements[i])

        const productErrors = [...this.state.productErrors]
        productErrors[i].item = null

        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setQuantity(data, i) {
        const productElements = this.state.productElements
        productElements[i].quantity = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        const productErrors = [...this.state.productErrors]
        productErrors[i].quantity = null
        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setPrice(data, i) {
        const productElements = this.state.productElements
        productElements[i].price = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        const productErrors = [...this.state.productErrors]
        productErrors[i].price = null
        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setDiscount(data, i) {
        const productElements = this.state.productElements
        productElements[i].discount = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        this.setState({
            productElements: productElements,
        })
    }

    _setDescription(data, i) {
        const productElements = this.state.productElements
        productElements[i].description = data
        this.setState({
            productElements: productElements,
        })
    }

    addRow() {
        let productElements = this.state.productElements
        let productErrors = this.state.productErrors

        productElements.push({
            item: '',
            quantity: '',
            price: '',
            discount: '',
            tax: [],
            amount: '',
            description: '',
        })
        productErrors.push({
            item: null,
            quantity: null,
            price: null,
        })
        this.setState({
            productElements,
            productErrors,
        })
    }

    removeRow(index) {
        let productElements = this.state.productElements
        productElements.splice(index, 1)
        this.setState({
            productElements,
        })
    }

    loadIncomeProductService() {
        const { t } = this.props
        const productElements = this.state.productElements
        const data = productElements.map((item, index) => {
            return (
                <>
                    <tr>
                        <td className="w-25">
                            <Select
                                className="mb-3"
                                name="productList"
                                options={this.state.products}
                                placeholder={t('common:select')}
                                onChange={(data) => this._setProduct(data, index)}
                                searchable={true}
                            />
                            {this.state.productErrors[index].item && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].item}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                min={0}
                                type="number"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:quantity')}
                                value={this.state.productElements[index].quantity}
                                onChange={(key, val) => this._setQuantity(val, index)}
                            />
                            {this.state.productErrors[index].quantity && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].quantity}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                type="number"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:price')}
                                value={this.state.productElements[index].price}
                                onChange={(key, val) => this._setPrice(val, index)}
                            />

                            {this.state.productErrors[index].price && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].price}
                                </label>
                            )}
                        </td>

                        <td>
                            <Input
                                type="number"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('fee:discount')}
                                value={this.state.productElements[index].discount}
                                onChange={(key, val) => this._setDiscount(val, index)}
                            />
                        </td>

                        <td>
                            {item.defaultProduct &&
                                item.defaultProduct.selectedTax.map((item, i) => (
                                    <div key={i}>
                                        <span className="bg-light">
                                            {item.label} - {item.rate}
                                        </span>
                                    </div>
                                ))}
                        </td>
                        <td className={this.state.productElements[index].amount < 0 && 'text-danger'}>
                            {this.state.productElements[index].amount}
                        </td>
                        <td>
                            <div className="d-flex align-items-center mb-3">
                                <Button
                                    onClick={() => this.addRow()}
                                    type="button"
                                    text={SVG.addTable()}
                                    main={false}
                                />
                                <Button
                                    onClick={() => this.removeRow(index)}
                                    type="button"
                                    text={SVG.deleteComment()}
                                    main={false}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <TextArea
                                value={this.state.productElements[index].descprition}
                                onChange={(key, val) => this._setDescription(val, index)}
                                placeholder={t('common:description')}
                            />
                        </td>
                        <td colspan="5"></td>
                    </tr>
                </>
            )
        })
        return data
    }

    publishModal() {
        const { t } = this.props
        return (
            <div id="modal_publish" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('income:publishInvoice')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button
                                onClick={(e) => {
                                    this.onSavePublish(e)
                                    $('#close_publish').trigger('click')
                                }}
                                type="button"
                                text={t('common:modal confirm')}
                                main={true}
                            />
                            <button id="close_publish" type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const totalPrices = this.calculateTotalPrice(this.state.productElements)
        const totalDiscount = this.calculateTotalDiscount(this.state.productElements)
        const totalAmount = this.calculateFinalTotalAmount(this.state.productElements)
        const totalTaxAmount = this.calculateTotalTaxAmount(this.state.productElements)
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="income" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.income.menu}>
                                    {t('accounting:income')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.income.invoice.view}>
                                    {t('income:invoice')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('income:createinvoice')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('income:customer')} *</label>
                                        <Select
                                            name="userList"
                                            options={this.state.userLists}
                                            placeholder={t('income:selectcustomer')}
                                            onChange={(data) => this._selectList('UserList', data)}
                                            searchable={true}
                                        />

                                        {this.state.errors.selectedUserList && (
                                            <label className="text-left error text-danger">
                                                {this.state.errors.selectedUserList}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title text-left">{t('income:issuedate')} *</label>
                                    <DatePicker
                                        placeholderText={t('income:selectissuedate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        defaultValue={this.state.issue_date}
                                        selected={this.state.issue_date}
                                        onChange={this._setIssueDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.issue_date != ''
                                                    ? convertADtoBS(this.state.issue_date) != null
                                                        ? convertADtoBS(this.state.issue_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}

                                    {this.state.errors.issue_date && (
                                        <label className="text-left error setup-error text-danger">
                                            {this.state.errors.issue_date}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-3 float-left form-group">
                                    <label className="form-title text-left">{t('income:duedate')} *</label>
                                    <DatePicker
                                        placeholderText={t('income:selectduedate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        defaultValue={this.state.due_date}
                                        selected={this.state.due_date}
                                        onChange={this._setDueDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.due_date != ''
                                                    ? convertADtoBS(this.state.due_date) != null
                                                        ? convertADtoBS(this.state.due_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}
                                    {this.state.errors.due_date && (
                                        <label className="text-left error setup-error text-danger">
                                            {this.state.errors.due_date}
                                        </label>
                                    )}
                                </div>
                                {/* <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('expense:billnumber')} </label>
                                            <Input name="code" type="text"  placeholder={t('expense:billnumber')} value="#0101110" />
                                    </div> */}
                            </div>
                            <div class="row clearFix">
                                <div className="col-sm-6 float-left form-group">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('income:categorytype')} *</label>
                                        <Select
                                            name="selectedCategory"
                                            options={this.state.category}
                                            placeholder={t('income:categorytype')}
                                            searchable={true}
                                            onChange={(data) => this._selectList('Category', data)}
                                        />
                                        {this.state.errors.selectedCategory && (
                                            <label className="table-error error text-danger">
                                                {this.state.errors.selectedCategory}
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:refnumber')}</label>
                                    <Input
                                        name="ref_number"
                                        onChange={this._handleChange}
                                        type="text"
                                        placeholder={t('income:refnumber')}
                                        value=""
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <>
                        <hr />
                        <div className="fee-title-btn">
                            <h6>{t('doubleentry:particular')}:</h6>
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                        </div>
                        <hr />
                        <div className="main-table">
                            <div className="table-all table-responsive">
                                <table className="table responsiveTable fee_table">
                                    <thead>
                                        <tr>
                                            <th>{t('income:items')} *</th>
                                            <th>{t('income:quantity')} *</th>
                                            <th>{t('income:price')} *</th>
                                            <th>{t('fee:discount')}</th>
                                            <th>{t('income:tax')}</th>
                                            <th className="text-end">
                                                {t('income:amount')}
                                                <br />
                                                <span className="text-danger smallfont">
                                                    {t('income:beforetaxdiscount')}
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.loadIncomeProductService()}</tbody>
                                    {this.state.productElements.length != 0 && (
                                        <tfoot className="tfootstrong">
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('fee:subTotal')} </strong>
                                                </td>
                                                <td className="text-end subTotal">
                                                    <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalPrices.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('fee:discount')} </strong>
                                                </td>
                                                <td className="text-end totalDiscount">
                                                    <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalDiscount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="blue-text">
                                                    <strong> {t('accounting:total')}</strong>
                                                </td>
                                                <td
                                                    className={
                                                        totalAmount < 0
                                                            ? 'text-danger text-end totalAmount blue-text'
                                                            : 'text-end totalAmount blue-text'
                                                    }
                                                >
                                                    <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalAmount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('income:tax')} </strong>
                                                </td>
                                                <td className="text-end totalTax">
                                                    <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalTaxAmount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                                {/* {this.state.errors.items && (
                                        <label className="text-left error setup-error text-danger pl-3">
                                            {this.state.errors.items}
                                        </label>
                                    )} */}
                                {this.state.productElements.length == 0 &&
                                <>
                                <div className="error text-danger pl-3">
                                At least, a product item is required.
                                </div>
                                <p className="no-data">
                                     {SVG.nodata()}
                                </p>
                                </>}
                            </div>
                        </div>
                    </>
                    <div className="form-button text-left">
                        <Button
                            onClick={this.onSaveDraft}
                            type="submit"
                            text={t('accounting:save_as_draft')}
                            main={false}
                        />
                        <Button modal="modal_publish" type="button" text={t('exam:publish')} main={true} />
                        {this.publishModal()}
                    </div>
                </div>
            </div>
        )
    }
}

const InvoiceAdd = withTranslation()(connect(mapStateToProps)(InvoiceAddComponent))
export { InvoiceAdd }
