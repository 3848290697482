import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { ParentRoute } from './guard'
import * as AdminComponent from '~/views/admin'
import * as SharedComponent from '~/views/shared'
import * as AccessModule from '~/helper/constant/modules'

export const Parent = () => [<ParentRoute exact key="1" access={[AccessModule.Module_Student_Management]} subAccess={AccessModule.STUDENT} path={configRoute(route.children)} component={SharedComponent.Children} />, <ParentRoute exact key="2" access={[AccessModule.Module_Student_Management]} subAccess={AccessModule.STUDENT} path={configRoute(route.children + '/:id')} component={SharedComponent.ChildrenProfile} />]
