import $ from 'jquery'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import { toggleTax, viewAccountTax } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAccountTaxComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            loading_text: 'Loading',
            name: '',
            code: '',
            taxData: [],
            classList: [],
            taxStructure: [
                {
                    name: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    tax: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'fee:tax',
                    },
                    // status: {
                    //     field: 'badge',
                    //     route: false,
                    //     sortable: true,
                    //     translate: 'common:status',
                    // },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        // this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadTax()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    // toggle(e) {
    //     const link = e.target.getAttribute('link')
    //     $('#close_' + link).trigger('click')
    //     toggleTax(link, this.props.token).then((response) => {
    //         if (response.code == API_SUCCESS) {
    //             toastMessage('success', response.message)
    //             this.loadTax()
    //         } else {
    //             toastMessage('error', response.message)
    //         }
    //     })
    // }

    loadTax() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let taxStructure = []
        let taxData = []
        taxStructure.push(this.state.taxStructure[0])
        viewAccountTax(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                // const taxData = response.data.bill_particular;
                const classAry = []
                response.data.taxes.map((item, index) => {
                    let text = null
                    // if (item.status == Status.ACTIVE) {
                    //     text = {
                    //         text: 'Active',
                    //         translate: 'common:statusActive',
                    //         badge: 'table-green',
                    //         modal: 'modal_class_' + index,
                    //     }
                    // } else {
                    //     text = {
                    //         text: 'Inactive',
                    //         translate: 'common:statusInactive',
                    //         badge: 'table-red',
                    //         modal: 'modal_class_' + index,
                    //     }
                    // }
                    const tableData = {
                        name: {
                            text: item.name,
                        },
                        tax: {
                            text: item.rate + ' %',
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.constant.tax.edit + '/' + item.id,
                        },
                    }
                    taxData.push(item)
                    taxStructure.push(tableData)
                })
                this.setState({
                    taxData,
                    taxStructure,
                })
            }
        })
    }
}

// const AbstractAccountTax = withTranslation()(AbstractAccountTaxComponent)

// export { AbstractAccountTax }

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
