import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { addCoupon, activePackage } from '~/services/api'
import { PackageDTO, CouponDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { Loading } from '~/components/loader'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    days: integer,
    package_id: integer,
    packages: Object,
    errors: Object,
    loading: Boolean,
}

export class AbstractCouponAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            packages: [],
            days: 1,
            package_id: '',
            errors: {
                days: null,
                package_id: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadPackages()
    }

    loadPackages() {
        const packages = []
        activePackage(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.package.map((item, index) => {
                    const result: PackageDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    packages.push(data)
                })
                this.setState({ packages })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                days: this.state.days,
                package_id: this.state.package_id,
            }
            this._handleChange('loading', true)
            addCoupon(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.coupon.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
