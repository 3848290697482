import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewNotice = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notice', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeNotice = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notice/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addNotice = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('notice/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getNotice = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notice/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editNotice = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('notice/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleNotice = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notice/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const postViewCount = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('post/viewCount/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}