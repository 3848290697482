import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import { viewBankAccount, addBankTransfer } from '~/services/api'

type Props = {
    dispatch: Dispath<any>,
}
type State = {}

export class AbstractBankTransferAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            allAccount: [],
            fromAccount: [],
            defaultFromAccount: '',
            selectedFromAccount: '',
            toAccount: [],
            defaultToAccount: '',
            selectedToAccount: '',
            amount: '',
            date: '',
            reference: '',
            description: '',
            errors: {
                amount: null,
                date: null,
                reference: null,
                description: null,
                selectedToAccount: null,
                selectedFromAccount:null,

            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadAccount()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAccount() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewBankAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let fromAccount = []
            let defaultFromAccount = ''
            let selectedFromAccount = ''
            let toAccount = []
            let defaultToAccount = ''
            let selectedToAccount = ''
            if (response.code == API_SUCCESS) {
                response.data.accounts.map((item, index) => {
                    const data = {
                        label: item.holder_name,
                        value: item.id,
                    }

                    fromAccount.push(data)
                    toAccount.push(data)
                })
                this.setState({
                    allAccounts: response.data.accounts,
                    fromAccount,
                    defaultFromAccount,
                    selectedFromAccount,
                    toAccount,
                    selectedToAccount,
                    selectedToAccount,
                })
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                from_account: this.state.selectedFromAccount,
                to_account: this.state.selectedToAccount,
                amount: this.state.amount,
                date: this.state.date,
                reference: this.state.reference,
                description: this.state.description,
            }
            this._handleChange('loading', true)
            addBankTransfer(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.banking.transfer.view)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school,
    }
}
