import { accountingGet, accountingPost } from '../accountingRequest'

export const viewChartOfAccount = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('chart-of-account', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getChartOfAccount = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('chart-of-account/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addChartOfAccount = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('chart-of-account/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateChartOfAccount = (id, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('chart-of-account/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteChartOfAccount = (chartOfAccount, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('chart-of-account/delete' + chartOfAccount, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewJournalAccount = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('journal-entry', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getJournalAccount = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('journal-entry/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addJournalAccount = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('journal-entry/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateJournalAccount = (id, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('journal-entry/update' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteJournalAccount = (journalEntry, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('journal-entry/delete' + journalEntry, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const viewLedgerSummary = (start_date, end_date, account, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet(
                'report/ledger-summary?start_date=' + start_date + '&end_date=' + end_date + '&account=' + account,
                token
            ).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewBalanceSheet = (start_date, end_date, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/balance-sheet?start_date=' + start_date + '&end_date=' + end_date, token).then(
                (response) => {
                    resolve(response)
                }
            )
        } catch ($e) {
            resolve(null)
        }
    })
}
export const viewTrialBalance = (start_date, end_date, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('report/trial-balance?start_date=' + start_date + '&end_date=' + end_date, token).then(
                (response) => {
                    resolve(response)
                }
            )
        } catch ($e) {
            resolve(null)
        }
    })
}
