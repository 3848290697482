import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { getPost, addEventSchedule, toggleEvent, deleteEvent, editEventSchedule, getSchoolUser } from '~/services/api'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../helper/function/abstract'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    activeTab: Integer,
    loading: Boolean,
    loading_text: String,
    post_id: String,
    post: Object,
    start_date: String,
    end_date: String,
    date: Array,
    days: String,
    start_time: String,
    end_time: String,
    venue: String,
    description: String,
    live_class_status: Boolean,
    errors: Object,
    users: Array,
    moderator: Integer,
}

export class AbstractScheduleComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            activeTab: 0,
            post: {},
            start_date: '',
            end_date: '',
            date: [],
            users: [],
            moderator: '',
            days: '',
            start_time: '',
            end_time: '',
            venue: '',
            description: '',
            live_class_status: false,
            post_id: '',
            errors: {
                days: null,
                start_time: null,
                end_time: null,
                venue: null,
                description: null,
                live_class_status: null,
            },
        }
        this.loadEvent = this.loadEvent.bind(this)
        this.calculateDays = this.calculateDays.bind(this)
        this.clearState = this.clearState.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._toggle = this._toggle.bind(this)
        this._delete = this._delete.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this.checkTime = this.checkTime.bind(this)
        this._join = this._join.bind(this)
    }

    componentDidMount() {
        this.loadUsers()
        this.clearState()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _join(e) {
        const link = e.target.getAttribute('link')
        let id = link.split(',')
        $('#close_join_' + id[0]).trigger('click')
        if (typeof document != 'undefined') {
            window.open(route.bbb + '/' + id[1], '_blank')
        } else {
            redirect(route.bbb + '/' + id[1], this.props.history)
        }
    }

    _toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_toggle_' + link).trigger('click')
        toggleEvent(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                const event = response.data.event
                if (event.status == Status.ACTIVE) {
                    this.loadEvent()
                    if (event.bbb != null) {
                        if (typeof document != 'undefined') {
                            window.open(route.bbb + '/' + event.bbb.id, '_blank')
                        } else {
                            redirect(route.bbb + '/' + event.bbb.id, this.props.history)
                        }
                    }
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _delete(e) {
        const link = e.target.getAttribute('link')
        $('#close_delete_' + link).trigger('click')
        deleteEvent(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEvent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadUsers() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const users = []
                response.data.users.map((item) => {
                    const data = {
                        value: item.user.id,
                        label: item.user.name != null ? item.user.name + ' - ' + item.user.reg_no : item.user.reg_no,
                    }
                    users.push(data)
                })
                this.setState(
                    {
                        users,
                    },
                    () => {
                        this.loadEvent()
                    }
                )
            }
        })
    }

    loadEvent() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getPost(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this._handleChange('post', response.data.post)
                this.setState(
                    {
                        post_id: response.data.post.id,
                        start_date: response.data.post.from_date,
                        end_date: response.data.post.to_date,
                        date: [],
                    },
                    () => {
                        this.calculateDays(response.data.post.from_date)
                        this.clearState()
                    }
                )
            }
        })
    }

    calculateDays(date) {
        const current = moment(date, 'yyyy-MM-DD')
        const end = moment(this.state.end_date, 'yyyy-MM-DD')
        const data = this.state.date
        if (current.isBefore(end)) {
            const result = {
                label: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && date != null ? (convertADtoBS(date) != null ? convertADtoBS(date) + ' BS' : 'Invalid Date Range') : moment(date).format('DD MMM'),
                value: date,
            }
            data.push(result)
            this.setState(
                {
                    date: data,
                },
                () => {
                    const next = current.add(1, 'days').format('yyyy-MM-DD')
                    this.calculateDays(next)
                }
            )
        } else {
            const result = {
                label: this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.end_date != null ? (convertADtoBS(this.state.end_date) != null ? convertADtoBS(this.state.end_date) + ' BS' : 'Invalid Date Range') : moment(this.state.end_date).format('DD MMM'),
                value: this.state.end_date,
            }
            data.push(result)
            this.setState({
                date: data,
            })
        }
    }

    checkTime() {
        const start = moment(this.state.start_time, 'hh:mm a')
        const end = moment(this.state.end_time, 'hh:mm a')
        if (end.isSame(start)) {
            return 'Invalid'
        }
        if (end.isBefore(start)) {
            return 'Invalid'
        }
        return null
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let data = {
                post_id: this.state.post_id,
                date: this.state.days.value,
                start_time: moment(this.state.start_time).format('hh:mm a'),
                end_time: moment(this.state.end_time).format('hh:mm a'),
                venue: this.state.venue,
                description: this.state.description,
                live_class_status: this.state.live_class_status,
            }
            const checkTime = this.checkTime()
            if (checkTime != null) {
                errors['start_time'] = checkTime
                errors['end_time'] = checkTime
                this.setState({ errors })
            }
            if (this.state.live_class_status) {
                if (this.state.moderator == null || this.state.moderator == '') {
                    errors['moderator'] = t('toaster:required')
                    this.setState({ errors })
                } else {
                    data['moderator_id'] = this.state.moderator.value
                }
            }
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading', true)
                this._handleChange('loading_text', 'Saving')
                addEventSchedule(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.loadEvent()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _onEdit(e) {
        const { t } = this.props
        e.preventDefault()
        const link = e.target.getAttribute('link')
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let data = {
                post_id: this.state.post_id,
                date: this.state.days.value,
                start_time: moment(this.state.start_time).format('hh:mm a'),
                end_time: moment(this.state.end_time).format('hh:mm a'),
                venue: this.state.venue,
                description: this.state.description,
                live_class_status: this.state.live_class_status,
            }
            const checkTime = this.checkTime()
            if (checkTime != null) {
                errors['start_time'] = checkTime
                errors['end_time'] = checkTime
                this.setState({ errors })
            }
            if (this.state.live_class_status == true) {
                if (this.state.moderator == null || this.state.moderator == '') {
                    errors['moderator'] = t('toaster:required')
                    this.setState({ errors })
                } else {
                    data['moderator_id'] = this.state.moderator.value
                }
            }
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading', true)
                this._handleChange('loading_text', 'Saving')
                editEventSchedule(link, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_edit_' + link).trigger('click')
                        this.loadEvent()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')
            if (id == 'modal_schedule_add') {
                component.setState({
                    days: '',
                    start_time: '',
                    end_time: '',
                    venue: '',
                    description: '',
                    live_class_status: false,
                    moderator: '',
                })
            } else if (id == 'modal_edit') {
                const value = $(this).data('target')
                const event = component.state.post.event
                const result = event[value]
                const days = component.state.date.find((item) => item.value == result.date)
                let moderator = component.state.users.find((item) => {
                    if (result.moderator != null && item.value == result.moderator.id) {
                        return true
                    }
                })
                moderator = moderator != null ? moderator : ''
                component.setState({
                    days: days,
                    start_time: moment(result.start_time, 'hh:mm a').toDate(),
                    end_time: moment(result.end_time, 'hh:mm a').toDate(),
                    venue: result.venue,
                    description: result.description,
                    live_class_status: result.live_class_status,
                    moderator,
                })
            }
            const errors = {
                days: null,
                start_time: null,
                end_time: null,
                venue: null,
                description: null,
            }
            component._handleChange('errors', errors)
        })
    }
}

const AbstractSchedule = withTranslation()(AbstractScheduleComponent)
export { AbstractSchedule }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
