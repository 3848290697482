import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractOnlineExamComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as QuestionType from '~/helper/constant/question_type'
import { Dashboard } from '~/components/dashboard'
import { Post } from '~/components/post'
import { Fragment } from 'react'
import ReactPlayer from 'react-player/file'
import moment from 'moment'
import { pad2 } from '~/helper/function'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class OnlineExamComponent extends AbstractOnlineExamComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getQuestion = this.getQuestion.bind(this)
        this.getAnswer = this.getAnswer.bind(this)
        this.getQuestionList = this.getQuestionList.bind(this)
        this.getMedia = this.getMedia.bind(this)
        this.updateValue = this.updateValue.bind(this)
    }

    updateValue(value, id) {
        let answerList = this.state.answerList
        answerList[id] = value
        this.setState(
            {
                answerList,
            },
            () => {}
        )
    }

    getAnswer(answer) {
        if (answer.length > 0) {
            const modal = answer.map((item, index) => {
                return (
                    <div key={index}>
                        <label className="checkboxs">
                            {this.state.answerList[item.question_id] == item.id && <Input type="radio" checked="checked" onChange={(key, value) => this.updateValue(item.id, item.question_id)} value={item.id} name={'answerList[' + item.question_id + ']'} />}
                            {this.state.answerList[item.question_id] != item.id && <Input type="radio" onChange={(key, value) => this.updateValue(item.id, item.question_id)} value={item.id} name={'answerList[' + item.question_id + ']'} />}
                            <span className="checkmark"></span>
                            {item.answers}
                        </label>
                    </div>
                )
            })
            return modal
        }
    }

    getMedia(gallery) {
        if (gallery != null && gallery.media.length > 0) {
            const media = gallery.media[0]
            if (media.media_type.includes('image')) {
                return <img src={media.media_url} />
            } else if (media.media_type.includes('video')) {
                return (
                    <ReactPlayer
                        url={media.media_url}
                        controls
                        pip
                        playsinline
                        height={'auto'}
                        width={'100%'}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                },
                            },
                        }}
                    />
                )
            } else {
                return <embed src={media.media_url}></embed>
            }
        }
    }

    getQuestion() {
        const { t } = this.props
        if (this.state.question.length > 0) {
            const modal = this.state.question.map((item, index) => {
                return (
                    <div key={index} id={index} className={this.state.active == index ? 'question-active' : 'question-hidden'}>
                        <div className="main-exam">
                            <h4>
                                <small>{index + 1})</small>&nbsp;&nbsp; {item.questions}
                            </h4>
                            <div className="row clearfix">
                                {item.type == QuestionType.Subjective && (
                                    <div className="col-sm-8">
                                        <TextArea rows="10" onChange={(key, value) => this.updateValue(value, item.id)} value={this.state.answerList[item.id]} name={'answerList[' + item.id + ']'} />
                                    </div>
                                )}
                                {item.type == QuestionType.Objective && <div className="col-sm-8">{this.getAnswer(item.answer)}</div>}
                                <div className="col-sm-4">
                                    {this.getMedia(item.gallery)}
                                    {item.gallery != null && item.gallery.media.length > 0 && (
                                        <button type="button" className="online-view-pdf-btn" data-toggle="modal" data-target={'#viewPdfModal' + index}>
                                            {t('exam:expand')}
                                        </button>
                                    )}
                                </div>

                                {/* Modal For View PDF */}
                                <div class="modal fade online-view-document-modal" id={'viewPdfModal' + index} tabindex="-1" role="dialog" aria-labelledby="viewPdfModalTitle" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">{this.getMedia(item.gallery)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12">
                                    <small className="float-right">
                                        <i>
                                            {t('exam:marks')} : {item.marks}
                                        </i>
                                    </small>
                                </div>
                            </div>
                            <ul className="question-condition list-inline">
                                <li>
                                    <span className="exam-current"></span> {t('exam:current')}
                                </li>
                                <li>
                                    <span className="exam-attempt"></span> {t('exam:attempted')}
                                </li>
                                <li>
                                    <span className="exam-not-answer"></span> {t('exam:notanswer')}
                                </li>
                                <li>
                                    <span className="exam-not-attempt"></span> {t('exam:notattempted')}
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            })
            return modal
        }
    }

    getQuestionList() {
        if (this.state.question.length > 0) {
            const modal = this.state.question.map((item, index) => {
                return (
                    <li key={index} onClick={(e) => this.skipQuestion(index)} data-index={index}>
                        <a data-index={index} className={this.state.active == index ? 'exam-current' : this.state.answerList[item.id] != undefined ? (this.state.answerList[item.id] == '' ? 'exam-not-answer' : 'exam-attempt') : 'exam-not-attempt'}>
                            {index + 1}
                        </a>
                    </li>
                )
            })
            return modal
        }
    }

    render() {
        const { t } = this.props

        return (
            <Fragment>
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <header className="exam-header">
                    <div className="custom-containers clearfix">
                        <ins className="logo">
                            {this.props.school.school.profile != null && <img src={this.props.school.school.profile} height="40" alt="logo" />}
                            {this.props.school.school.profile == null && <img src="assets/images/mobile-logo.png" alt="logo" />}
                        </ins>
                        <div className="row">
                            <div className="col-sm-4 exam-col-date">
                                {this.state.exam != null && (
                                    
                                    <span>
                                        {moment(this.state.exam.exam_date).format('DD MMM , YYYY')}
                                        <br />
                                        <small>
                                            [  {this.state.exam.start_time} - {this.state.exam.end_time} ]
                                        </small>
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-4 exam-col-info">
                                <span className="text-center">
                                    {this.state.exam == null && <Fragment>{t('exam:onlineexam')}</Fragment>}
                                    {this.state.exam != null && (
                                        <Fragment>
                                            {this.state.exam.post.title}
                                            <br />
                                            {this.state.exam.subject != null && <small>[ {this.state.exam.subject.name} ]</small>}
                                        </Fragment>
                                    )}
                                </span>
                            </div>
                            <div className="col-sm-4 exam-col-marks">
                                {this.state.exam != null && (
                                    <span className="text-right">
                                        {t('exam:fullMarks')} : {this.state.exam.full_mark}
                                        <br />
                                        {t('exam:passMarks')} : {this.state.exam.pass_mark}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </header>
                <main>
                    {this.state.question.length > 0 && this.state.exam_start == false && this.state.time_over == false && (
                        <div className="custom-containers exam-screen clearfix">
                            <div className="offset-3 col-md-6 ">
                                <div className="setup-container">
                                    <h5 className="text-left">{t('exam:examMsg')}</h5>
                                    <hr />
                                    <br />
                                    <p className="text-left">
                                        {t('exam:exam.categoryTitle')} : {this.state.exam.post.title}
                                    </p>
                                    {this.state.exam.subject != null && (
                                        <p className="text-left">
                                            {t('exam:subject')} : {this.state.exam.subject.name}
                                        </p>
                                    )}
                                    <p className="text-left">
                                        {t('exam:examTime')} : {this.state.exam.start_time} - {this.state.exam.end_time}
                                    </p>
                                    <hr />
                                    <div className="text-left">
                                        <Button onClick={this.startExam} type="button" text={t('calendar:start')} main={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.question.length > 0 && this.state.exam_start == true && this.state.time_over == false && (
                        <div className="custom-containers exam-screen clearfix">
                            <div className="col-md-3 clearfix float-right plr-s-0">
                                <div className="exam-time exam-option clearfix">
                                    <h3>{t('exam:timeLeft')}</h3>
                                    <ul className="list-inline clearfix">
                                        {this.state.duration == null && (
                                            <Fragment>
                                                <li>
                                                    00 <span>{t('exam:hours')}</span>
                                                </li>
                                                <li>
                                                    00 <span>{t('exam:min')}</span>
                                                </li>
                                                <li>
                                                    00 <span>{t('exam:sec')}</span>
                                                </li>
                                            </Fragment>
                                        )}
                                        {console.log("pasapas", this.state.duration)}
                                        {this.state.duration != null && (
                                            <Fragment>
                                                <li>
                                                    {pad2(this.state.duration.hours())} <span>{t('exam:hours')}</span>
                                                </li>
                                                <li>
                                                    {pad2(this.state.duration.minutes())} <span>{t('exam:min')}</span>
                                                </li>
                                                <li>
                                                    {pad2(this.state.duration.seconds())} <span>{t('exam:sec')}</span>
                                                </li>
                                            </Fragment>
                                        )}
                                    </ul>
                                </div>
                                <div className="exam-option exam-first-section float-right">
                                    <h3>{t('exam:questionbank.questions')}</h3>
                                    <ul className="exam-question-number list-inline">
                                        {this.getQuestionList()}
                                        {/* <li><a href="#" className="exam-attempt">1</a></li>
                                        <li><a href="#" className="exam-current">5</a></li>
                                        <li><a href="#" className="exam-not-answer">6</a></li>
                                        <li><a href="#" className="exam-not-attempt">7</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9 float-left plr-s-0 exam-content">
                                {this.getQuestion()}
                                <div className="row buttons-extra clearfix">
                                    <div className="col-sm-4 float-left">{/* <button type="button" className="exam-mark-review">
                                            Mark for Review
                                        </button> */}</div>
                                    <div className="col-sm-5 float-left text-center clearfix">
                                        {this.state.active != 0 && (
                                            <button type="button" className="exam-previous" onClick={this.prevQuestion}>
                                                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.91378 0.711167C8.00109 0.798252 8.07036 0.901707 8.11762 1.0156C8.16488 1.1295 8.18921 1.2516 8.18921 1.37492C8.18921 1.49823 8.16488 1.62033 8.11762 1.73423C8.07036 1.84813 8.00109 1.95158 7.91378 2.03867L2.95066 6.99992L7.91378 11.9612C8.08982 12.1372 8.18872 12.376 8.18872 12.6249C8.18872 12.8739 8.08982 13.1126 7.91378 13.2887C7.73775 13.4647 7.49899 13.5636 7.25003 13.5636C7.00108 13.5636 6.76232 13.4647 6.58628 13.2887L0.961283 7.66367C0.873977 7.57658 0.80471 7.47313 0.757448 7.35923C0.710186 7.24533 0.685857 7.12323 0.685857 6.99992C0.685857 6.8766 0.710186 6.7545 0.757448 6.6406C0.80471 6.52671 0.873977 6.42325 0.961283 6.33617L6.58628 0.711167C6.67337 0.623861 6.77682 0.554593 6.89072 0.50733C7.00462 0.460068 7.12672 0.435741 7.25003 0.435741C7.37335 0.435741 7.49545 0.460068 7.60935 0.50733C7.72324 0.554593 7.8267 0.623861 7.91378 0.711167Z"
                                                        fill="#FCFEFF"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M21.3125 7C21.3125 7.24864 21.2137 7.4871 21.0379 7.66291C20.8621 7.83873 20.6236 7.9375 20.375 7.9375L3.5 7.9375C3.25136 7.9375 3.01291 7.83873 2.83709 7.66291C2.66127 7.4871 2.5625 7.24864 2.5625 7C2.5625 6.75136 2.66127 6.5129 2.83709 6.33709C3.01291 6.16127 3.25136 6.0625 3.5 6.0625L20.375 6.0625C20.6236 6.0625 20.8621 6.16127 21.0379 6.33709C21.2137 6.5129 21.3125 6.75136 21.3125 7Z"
                                                        fill="#FCFEFF"
                                                    />
                                                </svg>
                                                &nbsp; {t('exam:previous')}
                                            </button>
                                        )}
                                        {this.state.active + 1 != this.state.question.length && (
                                            <button type="button" className="exam-next" onClick={this.nextQuestion}>
                                                {t('exam:next')} &nbsp;
                                                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M14.0862 13.2888C13.9989 13.2017 13.9296 13.0983 13.8824 12.9844C13.8351 12.8705 13.8108 12.7484 13.8108 12.6251C13.8108 12.5018 13.8351 12.3797 13.8824 12.2658C13.9296 12.1519 13.9989 12.0484 14.0862 11.9613L19.0493 7.00008L14.0862 2.03883C13.9102 1.8628 13.8113 1.62404 13.8113 1.37508C13.8113 1.12613 13.9102 0.88737 14.0862 0.711333C14.2623 0.535295 14.501 0.436399 14.75 0.436399C14.9989 0.436399 15.2377 0.535295 15.4137 0.711333L21.0387 6.33633C21.126 6.42342 21.1953 6.52687 21.2426 6.64077C21.2898 6.75467 21.3141 6.87677 21.3141 7.00008C21.3141 7.1234 21.2898 7.2455 21.2426 7.3594C21.1953 7.47329 21.126 7.57675 21.0387 7.66383L15.4137 13.2888C15.3266 13.3761 15.2232 13.4454 15.1093 13.4927C14.9954 13.5399 14.8733 13.5643 14.75 13.5643C14.6267 13.5643 14.5046 13.5399 14.3907 13.4927C14.2768 13.4454 14.1733 13.3761 14.0862 13.2888Z"
                                                        fill="#FCFEFF"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M0.6875 7C0.6875 6.75136 0.786272 6.5129 0.962088 6.33709C1.1379 6.16127 1.37636 6.0625 1.625 6.0625L18.5 6.0625C18.7486 6.0625 18.9871 6.16127 19.1629 6.33709C19.3387 6.5129 19.4375 6.75136 19.4375 7C19.4375 7.24864 19.3387 7.4871 19.1629 7.66291C18.9871 7.83873 18.7486 7.9375 18.5 7.9375L1.625 7.9375C1.37636 7.9375 1.1379 7.83873 0.962088 7.66291C0.786272 7.4871 0.6875 7.24864 0.6875 7Z"
                                                        fill="#FCFEFF"
                                                    />
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-sm-3 float-left text-right">
                                        <button type="button" onClick={this.endExam} className="exam-end-test">
                                            {t('exam:endTest')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.question.length > 0 && this.state.time_over == true && (
                        <div className="custom-containers exam-screen clearfix">
                            <div className="offset-3 col-md-6 ">
                                <div className="setup-container">
                                    <h5>{t('exam:examCompleted')}</h5>
                                    <hr />
                                    <br />
                                    <p> {t('exam:examCompletedMsg')}</p>
                                    <br />
                                    <hr />
                                    <div>
                                        <Button onClick={this.closeExam} type="button" text={t('common:modal close')} main={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.question.length == 0 && (
                        <div className="text-center mt-5">
                            {SVG.todo()}
                            <span className="no-task ">{t('exam:contactAdmin')}</span>
                        </div>
                    )}
                </main>
            </Fragment>
        )
    }
}

const OnlineExam = withTranslation()(connect(mapStateToProps)(OnlineExamComponent))

export { OnlineExam }
