import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewParent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/parents/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addParent = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/parents/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editParent = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/parents/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteParent = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/parents/delete/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleParent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/parents/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getChildren = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('my-children', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getChildrenProfile = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('my-children/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveChildrenProfile = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('my-children/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
