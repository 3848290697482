import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractChildrenComponent, mapStateToProps } from './abstract'

class ChildrenComponent extends AbstractChildrenComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getChildren() {
        const data = this.state.childrenList.map((item, index) => {
            const children = item.children
            const profile = children.user.user_detail != null ? (children.user.user_detail.profile != null ? children.user.user_detail.profile : null) : null
            const name = children.user.name != null ? children.user.name : children.user.reg_no
            return (
                <div key={index} className="float-left form-group col-md-6 col-lg-3" onClick={() => this.setChildren(item.student_id)}>
                    <label className="choose-role-list choose-course">
                        {item.student_id == this.props.student && <input type="radio" name="selectclass" className="select-class" checked="checked" />}
                        {item.student_id != this.props.student && <input type="radio" name="selectclass" className="select-class" />}
                        <div className="class-select col-md-12">
                            <div className="sc-card children">
                                {profile == null && (
                                    <div className="children-title">
                                        <span>{name.charAt(0)}</span>
                                    </div>
                                )}
                                {profile != null && <img src={profile} alt="" />}
                                <h3>{name}</h3>
                                <span>Class : {children.active_user_academics.section.classes.name}</span>
                                <span>Section : {children.active_user_academics.section.name}</span>
                            </div>
                            {item.student_id == this.props.student && <div className="checked-icon">{SVG.checked()}</div>}
                            <div className="e-d-t">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" id="e-d-t" data-toggle="collapse" data-target={'#edit_' + item.id} aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                    </button>
                                    <div className="dropdown-menu" id={'edit_' + item.id} aria-labelledby="e-d-t">
                                        <button onClick={() => this.editProfile(item.student_id)} className="dropdown-item" type="button">
                                            Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            )
        })
        return data
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="children" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.children}>My Children</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">My Children :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-box">
                        <form className="choose-role">
                            <div className="row clearfix">{this.getChildren()}</div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const Children = connect(mapStateToProps)(ChildrenComponent)

export { Children }
