import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass, viewResult } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'

import { withTranslation } from 'react-i18next'
import { toastMessage } from '~/helper/function'


type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam: Object,
    classes: Array,
    section: Array,
    data:Object,
    routine: Array,
    errors: Object,
    selectedClass: Integer,
    defaultClass: Object,
    selectedSection: Integer,
    defaultSection: Object,
}

export class AbstractReportCardMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classes: [],
            section:[],
            exam_id: this.props.match.params.id, 
            data:{},
            selectedClass: null,
            selectedClassMultiple:[],
            selectedSection:'',
            errors: {
                selectedClass: null,
                selectedSection: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }

    componentDidMount() {
        this.loadSelectedClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classes = []
            let selectedClassIds = []
            
            if (response.code == API_SUCCESS) {
                // Filter the classes based on selectedClassMultiple
                this.state.selectedClassMultiple.map((selectMainClass)=>{
                    selectMainClass.map((item)=>{
                        selectedClassIds.push(item.id)})
                })
 
            response.data.class.map((item, index) => {
                if (selectedClassIds.includes(item.id)) {
                    classes.push({ value: item.id, label: item.name + ' - ' + item.code });
                }
            });
                this._handleChange('data', response.data.class)
                this._handleChange('classes', classes)
                this._handleChange('defaultClass', classes[0])
                this._handleChange('selectedClass', classes[0].value)
                this.loadSection(classes[0].value)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadSelectedClass(){
        this._handleChange('loading', true)
        viewResult(this.props.token).then((response)=>{
            this._handleChange('loading', false)
            let selectedClassMultiple = []
            if (response.code == API_SUCCESS) {
               
                const selectClass = response.data.post.filter((item, index)=>item.parent_id == this.state.exam_id);
                selectClass.map((item) => {
                    selectedClassMultiple.push(item.class_access_information);
                  });
                
            }
            this.setState({
                selectedClassMultiple
            },
            ()=>{
                this.loadClass()
            })
        });
       
        

    }
    


    loadSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let section = []
        classes.active_section.map((item, index) => {
            section.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        if (section.length > 0) {
            this._handleChange('section', section)
            this._handleChange('defaultSection', section[0])
            this._handleChange('selectedSection', section[0].value)
        } else {
            this._handleChange('section', section)
            this._handleChange('defaultSection', '')
            this._handleChange('selectedSection', '')
        }
    }

    

    _onSearch(e) {
        e.preventDefault()
        console.log(this.props.match.params.examId)
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            redirect(route.examination.result.reportcard + '/'+ this.state.exam_id + '/' + this.state.selectedClass + '/' + this.state.selectedSection, this.props.history)
        }
    }
}

const AbstractReportCardMenu = withTranslation()(AbstractReportCardMenuComponent)

export { AbstractReportCardMenu }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
