import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractNotificationViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Button } from '~/components/form'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'

class NotificationViewComponent extends AbstractNotificationViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')} > </a>
                            </li>
                            <li>{t('header:notification')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('header:notificationSetting')}:</h5>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.notification} />
                </div>
            </div>
        )
    }
}

const NotificationView = withTranslation()(connect(mapStateToProps)(NotificationViewComponent))

export { NotificationView }
