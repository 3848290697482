import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { addAcademic, viewAcademic } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import { APP_URL } from '~/helper/constant/config'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    school: Object,
    loading: Boolean,
    errors: Object,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    from_date: String,
    to_date: String,
    loading: Boolean,
}

export class AbstractSetupComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const today = new Date()
        this.state = {
            loading: false,
            name: '',
            from_date: '',
            to_date: '',
            errors: {
                name: null,
                from_date: null,
                to_date: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this._check = this._check.bind(this)
        // this._check();
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    componentDidMount() {
        this._check()
    }

    _check() {
        this._handleChange('loading', true)
        viewAcademic(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (response.data.academic_year.length > 0) {
                    if (this.props.school.school.setup == false) {
                        redirect(route.admin.school.profile + '/' + this.props.school.school_id, this.props.history)
                    } else {
                        redirect(route.activity, this.props.history)
                    }
                }
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                from_date: moment(this.state.from_date).format('yyyy-MM-DD'),
                to_date: moment(this.state.to_date).format('yyyy-MM-DD'),
            }
            this._handleChange('loading', true)
            addAcademic(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.school.profile + '/' + this.props.school.school_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
