import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { addTodo, viewTodo } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    student: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    task: Array,
    data: Array,
    errors: Object,
    title: String,
    description: String,
    student: Array,
    employee: Array,
    subject: String,
    due_date: String,
    start_date: String,
    end_date: String,
    isSubject: Boolean,
    media: Array,
    uploads: Array,
}

export class AbstractTodoComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            start_date: moment(moment().format('yyyy-MM-DD')).subtract(1, 'M').toDate(),
            end_date: moment(moment().format('yyyy-MM-DD')).add(1, 'M').toDate(),
            task: [],
            student: [],
            employee: [],
            title: '',
            description: '',
            due_date: '',
            remarks: false,
            isSubject: false,
            subject: null,
            media: [],
            uploads: [],
            errors: {
                title: null,
                description: null,
                due_date: null,
            },
            filter_error: null,
            data: [
                {
                    task: {
                        field: 'text',
                        translate: 'todo:task',
                        route: false,
                        sortable: true,
                    },
                    participants: {
                        field: 'participants',
                        translate: 'todo:participants',
                        route: false,
                        sortable: false,
                    },
                    assigned_by: {
                        field: 'text',
                        translate: 'todo:assignedBy',
                        route: false,
                        sortable: true,
                    },
                    due_date: {
                        field: 'date',
                        translate: 'todo:dueOn',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'common:status',
                        route: false,
                        sortable: true,
                    },
                    view: {
                        field: 'button',
                        translate: 'common:viewBtn',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadTodo = this.loadTodo.bind(this)
        this._onSave = this._onSave.bind(this)

        this.validateFilter = this.validateFilter.bind(this)
    }

    componentDidMount() {
        this.loadTodo()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    validateFilter() {
        this._handleChange('filter_error', null)
        const from = moment(this.state.start_date, 'yyyy-MM-DD')
        const to = moment(this.state.end_date, 'yyyy-MM-DD')
        if (from.isAfter(to)) {
            this._handleChange('filter_error', 'Invalid Range')
            return false
        }
        return true
    }

    loadTodo() {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            const check = component.validateFilter()
            if (!check) {
                resolve(false)
            }
            component._handleChange('loading_text', 'Loading')
            component._handleChange('loading', true)
            let data = []
            data.push(component.state.data[0])
            const from = moment(component.state.start_date).format('yyyy-MM-DD')
            const to = moment(component.state.end_date).format('yyyy-MM-DD')
            viewTodo(from, to, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    const task = []
                    response.data.post.map((item) => {
                        task.push(item)
                        let status = {
                            text: t('todo:assigned'),
                            badge: 'table-green',
                        }
                        let due_date = {
                            text: item.to_date,
                        }
                        let participants = {
                            text: [],
                        }
                        const today = moment(moment().format('yyyy-MM-DD'))
                        const due = moment(item.to_date, 'yyyy-MM-DD')
                        if (due.isSameOrBefore(today)) {
                            due_date = {
                                text: item.to_date,
                                color: 'red',
                            }
                        }
                        if (component.props.school.id == item.author.id) {
                            if (due.isBefore(today)) {
                                status = {
                                    text: t('todo:closed'),
                                    badge: 'table-red',
                                }
                            }
                        } else {
                            const homework = item.homework.find((submit) => {
                                const id = component.props.school.user_type == UserType.Parents ? component.props.student : component.props.school.id
                                if (submit.users_schools_id == id) {
                                    return submit
                                }
                            })
                            if (homework == null) {
                                status = {
                                    text: t('todo:pendingSubmission'),
                                    badge: 'table-yellow',
                                }
                            } else {
                                if (homework.status == Status.PENDING) {
                                    status = {
                                        text: 'Pending Approval',
                                        translate: 'todo:approval',
                                        badge: 'table-blue',
                                    }
                                } else if (homework.status == Status.ACTIVE) {
                                    status = {
                                        text: 'Approved',
                                        translate: 'todo:approved',
                                        badge: 'table-green',
                                    }
                                } else {
                                    status = {
                                        text: 'Rejected',
                                        translate: 'todo:rejected',
                                        badge: 'table-red',
                                    }
                                }
                            }
                        }
                        if (item.access_information != null) {
                            let access = []
                            item.access_information.map((access_data) => {
                                const name = access_data.user.name != null ? access_data.user.name : access_data.user.reg_no
                                access.push(name.charAt(0))
                            })
                            participants = {
                                text: access,
                            }
                        }
                        const tableData = {
                            task: {
                                text: item.title,
                            },
                            participants,
                            assigned_by: {
                                text: item.author.user.name,
                            },
                            due_date,
                            status,
                            view: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                route: route.todo.detail + '/' + item.id,
                            },
                        }
                        data.push(tableData)
                    })
                    component._handleChange('task', task)
                    component._handleChange('data', data)
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const today = moment(moment().format('yyyy-MM-DD'))
            const due = moment(component.state.due_date, 'yyyy-MM-DD')
            if (due.isBefore(today)) {
                errors.due_date = t('todo:taskmsg5')
                component.setState({ errors })
            }

            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Saving')
                component._handleChange('loading', true)
                const data = {
                    title: component.state.title,
                    description: component.state.description,
                    from_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    to_date: moment(component.state.due_date).format('yyyy-MM-DD'),
                    media: component.state.media,
                    remarks: component.state.remarks == true ? t('todo:OnlineSubmission') : t('todo:PhysicalSubmission'),
                }
                if (component.state.student != null && component.state.employee != null) {
                    data.access = component.state.employee.concat(component.state.student)
                } else if (component.state.student != null) {
                    data.access = component.state.student
                } else {
                    data.access = component.state.employee
                }
                if (component.state.isSubject) {
                    if (component.state.subject != null && component.state.subject != -1) {
                        data.subject_id = component.state.subject
                    }
                }
                addTodo(data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.loadTodo()
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }
}

const AbstractTodo = withTranslation()(AbstractTodoComponent)

export { AbstractTodo }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        student: state.student,
        schoolPackage: state.schoolPackage,
    }
}
