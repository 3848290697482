import React from 'react'
import { connect } from 'react-redux'
import { AbstractProductServiceAddComponent, mapStateToProps } from './abstract'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import Select from 'react-select'

class ProductServiceAddComponent extends AbstractProductServiceAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectTax = this._selectTax.bind(this)
        this._selectAll = this._selectAll.bind(this)
        this._selectList = this._selectList.bind(this)
    }
    _selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    _selectTax(data) {
        if (data == null) {
            this._handleChange('all', false)
        }
        this._handleChange('selectedTax', data)
    }

    _selectAll(key, value) {
        this._handleChange(key, value)
        if (value) {
            this._handleChange('selectedTax', this.state.taxes)
        } else {
            this._handleChange('selectedTax', [])
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="products" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.productservice.view}>
                                    {t('doubleentry:particular')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:addParticular')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input
                                        name="name"
                                        placeholder={t('common:name')}
                                        type="text"
                                        onChange={this._handleChange}
                                        value=""
                                    />
                                    {this.state.errors.name && (
                                        <label className="error text-danger">{this.state.errors.name}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:code')} *</label>
                                    <Input
                                        name="sku"
                                        type="text"
                                        placeholder={t('fee:code')}
                                        value=""
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.sku && (
                                        <label className="error text-danger">{this.state.errors.sku}</label>
                                    )}
                                </div>
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('common:description')}</label>
                                    <TextArea
                                        name="description"
                                        onChange={this._handleChange}
                                        placeholder={t('common:description')}
                                        type="text"
                                        value={this.state.description}
                                    />
                                </div>

                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('accounting:salePrice')} *</label>
                                    <Input
                                        name="sale_price"
                                        placeholder={t('accounting:salePrice')}
                                        type="text"
                                        value=""
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.sale_price && (
                                        <label className="error text-danger">{this.state.errors.sale_price}</label>
                                    )}
                                </div> */}
                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:purchasePrice')} *</label>
                                    <Input
                                        name="purchase_price"
                                        type="text"
                                        placeholder={t('income:purchasePrice')}
                                        value=""
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.purchase_price && (
                                        <label className="error text-danger">{this.state.errors.purchase_price}</label>
                                    )}
                                </div> */}
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:tax')} </label>
                                    <Select
                                        name="taxes"
                                        options={this.state.taxes}
                                        isMulti
                                        placeholder={t('fee:tax')}
                                        searchable={true}
                                        value={this.state.selectedTax}
                                        onChange={this._selectTax}
                                    />
                                    <div className="form-check">
                                        <Checkbox
                                            id="all"
                                            onChange={(key, value) => this._selectAll(key, value)}
                                            className=""
                                            name="all"
                                            value={this.state.all}
                                        />
                                        <label className="form-check-label form-title small-checkbox" htmlFor="all">
                                            {t('exam:exam:selectAll')}
                                        </label>
                                    </div>
                                    {this.state.errors.selectedTax && (
                                        <label className="error text-danger">{this.state.errors.selectedTax}</label>
                                    )}
                                </div>
                                <div className="col-md-6 float-left form-group">
                                    <label className="form-title text-left">{t('accounting:category')} *</label>
                                    <Select
                                        name="category"
                                        options={this.state.category}
                                        placeholder={t('income:categorytype')}
                                        searchable={true}
                                        onChange={(data) => this._selectList('Category', data)}
                                    />
                                    {this.state.errors.selectedCategory && (
                                        <label className="error text-danger">{this.state.errors.selectedCategory}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:unit')} *</label>
                                    <Select
                                        name="units"
                                        options={this.state.units}
                                        placeholder={t('course:unit')}
                                        searchable={true}
                                        onChange={(data) => this._selectList('Unit', data)}

                                    />
                                    {this.state.errors.selectedUnit && (
                                        <label className="error text-danger">{this.state.errors.selectedUnit}</label>
                                    )}
                                </div>
                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('productservice:quantity')} *</label>
                                    <Input
                                        name="quantity"
                                        placeholder={t('productservice:quantity')}
                                        type="text"
                                        value=""
                                        onChange={this._handleChange}
                                    />
                                </div> */}
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('attendance:type')} </label>
                                    <Select
                                        name="type"
                                        options={this.state.type_list}
                                        placeholder={t('attendance:type')}
                                        searchable={true}
                                        onChange={(data) => this._selectList('Type', data)}
                                    />
                                    {/* {this.state.errors.type && (
                                        <label className="error text-danger">{this.state.errors.type}</label>
                                    )} */}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const ProductServiceAdd = withTranslation()(connect(mapStateToProps)(ProductServiceAddComponent))
export { ProductServiceAdd }
