import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractBigBlueButtonComponent, mapStateToProps } from './abstract'
import { Header } from '~/components/header'
import { Button } from '~/components/form'
import * as UserType from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { Loading } from '~/components/loader'

class BigBlueButtonComponent extends AbstractBigBlueButtonComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <Fragment>
                <Loading status={this.state.loading} text="Loading" />
                {this.state.status == false && (
                    <div className="main-page" id="collabarate_live_close">
                        <div className="main-content setup-main">
                            <div className="row clearfix">
                                <div className="col-md-6 offset-md-3">
                                    <a className="blocked-logo-link">
                                        <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                                    </a>
                                    <div className="setup-container">
                                        <h5 className="error">Waiting....</h5>
                                        <hr />
                                        <br />
                                        <div className="row clearfix">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <p>Your live session has not started yet.</p>
                                                </div>
                                                <Button onClick={this.loadMeeting} type="button" text="Reload" main={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.url != null && this.state.status == true && (
                    <div className="full-frame">
                        <iframe
                            id="iframe"
                            ref={(c) => {
                                this.iframe = c
                            }}
                            src={this.state.url}
                            samesite="none"
                            secure="true"
                            allowFullScreen
                            allow="camera *;microphone *"
                        />
                    </div>
                )}
            </Fragment>
        )
    }
}

const BigBlueButton = connect(mapStateToProps)(BigBlueButtonComponent)

export { BigBlueButton }
