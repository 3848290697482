import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { detailBookCategories } from '~/services/api'
import * as route from '~/helper/constant/route'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBookCategoryDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            bookCategory: '',
            tableData: [],
            tableStructure: [
                {
                    name: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    author: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'library:author',
                    },
                    shelf:{
                        field:'text',
                        route:false,
                        sortable:true,
                        transtate:'library:shelf'
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadDetail()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadDetail() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableData = []
        let tableStructure = []
        tableStructure.push(this.state.tableStructure[0])
        detailBookCategories(this.props.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookList = response.data.bookCategory
                bookList.books.map((item, index) => {
                    const shevles = item.book_shelf.filter((filtershelf)=>filtershelf.book_id === item.id)
                    .map((bookshelf)=>bookshelf.shelves.name)
                    .join(', ');
                    let text = null
                    if (item.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'common:statusActive',
                            badge: 'table-green',
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'common:statusInactive',
                            badge: 'table-red',
                        }
                    }
                    const tableData1 = {
                        name: {
                            text: item.title,
                        },
                        author: {
                            text: item.author,
                        },
                        shelf:{
                            text:shevles
                        },
                        action: {
                            text: 'View',
                            translate: 'common:detail',
                            route:route.admin.library.books.detail + '/' + item.id,
                        },
                    }
                    tableData.push(item)
                    tableStructure.push(tableData1)
                })

                this.setState({
                    bookCategory: response.data.bookCategory.name,
                    tableData,
                    tableStructure,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school.school,
    }
}
