export default class NoticeDTO {
    id: integer
    title: String
    description: String
    status: integer
    code: String
    gallery_id: integer

    constructor(id, title, description, status, code, gallery_id) {
        this.id = id
        this.title = title
        this.description = description
        this.status = status
        this.code = code
        this.gallery_id = gallery_id
    }
}
