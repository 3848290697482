import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractParentComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { role_type } from '~/helper/constant/user_type'
import { UserModel } from '~/components/userModel'
import Select from 'react-select'
import $ from 'jquery'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { convertADtoBS } from '../../../../helper/function'

class ParentComponent extends AbstractParentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getModal = this.getModal.bind(this)
        this.getAddModal = this.getAddModal.bind(this)
        this.setParentDob = this.setParentDob.bind(this)
        this.setParentJoinDate = this.setParentJoinDate.bind(this)
        this.getInfoModal = this.getInfoModal.bind(this)
    }

    getInfoModal() {
        const modal = this.state.data.map((item, index) => {
            return (
                <div key={index} id={'modal_info_' + index} className="modal fade mychat show" role="dialog">
                    <UserModel userData={item} />
                </div>
            )
        })
        return modal
    }

    setParentDob(data) {
        super._handleChange('dob', data)
        super._handleChange('dob_string', data.value)
    }

    setParentJoinDate(data) {
        super._handleChange('joining_date', data)
        super._handleChange('joining_date_string', data.value)
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_Parent_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('student:student.parent.add.title')}</span>
                    </div>
                    <br />
                    <form>
                        <div className="row clearfix">
                            <div className="col-sm-12 float-left form-group">
                                <div className="alert alert-primary" role="alert">
                                    {t('student:children_msg')}.
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left find-user form-group">
                                <label className="form-title">{t('student:student.add.searchByRegCodeLabel')}</label>
                                <div className="user-load">
                                    <Input name="parent_regcode" onChange={this._handleChange} placeholder={t('student:student.add.searchByRegCodePlaceholder')} type="text" value={this.state.parent_regcode} />
                                    {!this.state.found_parent && (
                                        <span onClick={this.findUserByRegCode.bind(this, 'parent')}>
                                            <FontAwesomeIcon className="icon" icon="search" color="#333" size="1x" />
                                        </span>
                                    )}
                                    {this.state.found_parent && (
                                        <span onClick={this.clearRegData.bind(this, 'parent')}>
                                            <FontAwesomeIcon className="icon" icon="times" color="#333" size="1x" />
                                        </span>
                                    )}
                                    {this.state.parent_regcode_error && <label className="error text-danger">{this.state.parent_regcode_error}</label>}
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group">
                                <Checkbox onChange={this._handleChange} className="checkbox-form" name="hasChildren" value={this.state.hasChildren} />
                                <label className="form-title small-checkbox mt-0 ml-3">{t('student:hasanotherchildern')}.</label>
                            </div>
                        </div>
                    </form>
                    <hr />
                    <br />
                    <form>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('student:student.parent.add.nameLabel')} *</label>
                                <Input name="name" onChange={this._handleChange} placeholder={t('student:student.parent.add.namePlaceholder')} type="text" disabled={this.state.found_parent} value={this.state.name} />
                                {this.state.perrors.name && <label className="error text-danger">{this.state.perrors.name}</label>}
                            </div>
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('student:student.parent.add.emailLabel')} </label>
                                <Input name="email" onChange={this._handleChange} placeholder={t('student:student.parent.add.emailPlaceholder')} type="text" disabled={this.state.found_parent} value={this.state.email} />
                                {this.state.perrors.email && <label className="error text-danger">{this.state.perrors.email}</label>}
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group main-select">
                                <label className="form-title">{t('student:student.parent.add.dobLabel')} *</label>
                                <DatePicker name="dob" dateFormat="yyyy-MM-dd" className="form-control" onChange={this.setParentDob.bind(this)} selected={this.state.dob} readOnly={this.state.found_parent} placeholderText={t('student:student.parent.add.dobPlaceholder')} maxDate={moment().toDate()} />
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.dob != null ? (convertADtoBS(this.state.dob) != null ? convertADtoBS(this.state.dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                {this.state.perrors.dob && <label className="error text-danger">{this.state.perrors.dob}</label>}
                            </div>
                            <div className="col-sm-6 float-left form-group">
                                <label className="form-title">{t('student:student.parent.add.joinDateLabel')} *</label>
                                <DatePicker name="joining_date" dateFormat="yyyy-MM-dd" className="form-control" onChange={this.setParentJoinDate.bind(this)} selected={this.state.joining_date} readOnly={this.state.found_parent} placeholderText={t('student:student.parent.add.joinDatePlaceholder')} minDate={moment().toDate()} />
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.joining_date != null ? (convertADtoBS(this.state.joining_date) != null ? convertADtoBS(this.state.joining_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                {this.state.perrors.joining_date && <label className="error text-danger">{this.state.perrors.joining_date}</label>}
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-6 float-left form-group main-select">
                                <label className="form-title">{t('student:student.parent.add.reationLabel')} *</label>
                                <Input name="relation" onChange={this._handleChange} placeholder={t('student:student.parent.add.relationPlaceholder')} type="text" value={this.state.relation} />
                                {this.state.perrors.relation && <label className="error text-danger">{this.state.perrors.relation}</label>}
                            </div>
                        </div>
                        <br />
                        <div className="text-center form-group">
                            <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                            <button
                                id="close_add"
                                type="button"
                                className="cancel-button"
                                onClick={() => {
                                    this.clearRegData('parent')
                                    $('.modal').modal('hide')
                                }}
                            >
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getEditModal() {
        const { t } = this.props
        if (this.state.parentData) {
            const modal = this.state.parentData.map((item, index) => {
                const id = item.user_school.id
                return (
                    <div key={index} data-id={index} id={'modal_Parent_edit_' + item.user_school.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('student:student.parent.edit.title')}</span>
                            </div>
                            <br />
                            <form>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('student:student.parent.add.nameLabel')} *</label>
                                        <Input id={'name_' + id} name="name" onChange={this._handleChange} placeholder={t('student:student.parent.add.nameLabel')} disabled={true} type="text" value={this.state.name} />
                                        {this.state.perrors.name && <label className="error text-danger">{this.state.perrors.name}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('student:student.parent.add.emailLabel')}</label>
                                        <Input id={'email_' + id} name="code" disabled={true} placeholder={t('student:student.parent.add.emailLabel')} type="text" value={this.state.email} />
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('student:student.parent.add.dobLabel')} *</label>
                                        <DatePicker name="dob" dateFormat="yyyy-MM-dd" className="form-control" onChange={this.setParentDob.bind(this)} selected={this.state.dob} readOnly={true} placeholderText={t('student:student.parent.add.dobLabel')} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.dob != null ? (convertADtoBS(this.state.dob) != null ? convertADtoBS(this.state.dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.perrors.dob && <label className="error text-danger">{this.state.perrors.dob}</label>}
                                    </div>
                                    <div className="col-sm-6 float-left form-group">
                                        <label className="form-title">{t('student:student.parent.add.joinDateLabel')} *</label>
                                        <DatePicker name="joining_date" dateFormat="yyyy-MM-dd" className="form-control" onChange={this.setParentJoinDate.bind(this)} selected={this.state.joining_date} placeholderText={t('student:student.parent.add.joinDateLabel')} readOnly={true} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.joining_date != null ? (convertADtoBS(this.state.joining_date) != null ? convertADtoBS(this.state.joining_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.perrors.joining_date && <label className="error text-danger">{this.state.perrors.joining_date}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('student:student.parent.add.reationLabel')} *</label>
                                        <Input name="relation" onChange={this._handleChange} placeholder={t('student:student.parent.add.reationLabel')} type="text" value={this.state.relation} />
                                        {this.state.perrors.relation && <label className="error text-danger">{this.state.perrors.relation}</label>}
                                    </div>
                                </div>
                                <br />
                                <div className="text-center form-group">
                                    <Button link={id} onClick={this._onEdit} type="submit" text={t('common:updateBtn')} main={true} />
                                    <button
                                        id={'close_edit_' + id}
                                        type="button"
                                        className="cancel-button"
                                        onClick={() => {
                                            this.clearRegData('parent')
                                            $('.modal').modal('hide')
                                        }}
                                    >
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            })
            return modal
        }
        return null
    }

    getModal() {
        const { t } = this.props
        if (this.state.parentData) {
            const modal = this.state.parentData.map((item, index) => {
                let text = 'disable'
                if (item.user_school.status != Status.ACTIVE) {
                    text = 'enable'
                }
                const { t } = this.props
                return (
                    <div key={index} id={'modal_Parent_' + item.user_school.id} className="modal fade show" role="dialog">
                        <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('warning')} !!!</span>
                            </div>
                            <br />
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <p className="modal-question">
                                        {t('classes:class.areyousureyouwantto')} {''}
                                        {text} {t('student.parent.title')}?
                                    </p>
                                    <p className="delete-file-name">"{item.user_school.user.name + ' - ' + item.relation}"</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this.toggle} link={item.user_school.id} data={index} type="submit" text={t('common:modal confirm')} main={true} />
                                    <button id={'close_' + item.user_school.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return modal
        }
        return null
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.user.student.view + '/' + this.state.section_id}>{t('student:student-menu.breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('student:student.parent.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                {/* <span className="sub-main-title">{'Student: ' + this.state.student_name}</span> */}
                                <span className="sub-main-title">
                                    {t('student:student.subTitle')} : {this.state.student_name}
                                </span>
                                <h5 className="main-title heading5">{t('student:student.parent.title')}:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button modal="modal_Parent_add" type="button" text={t('common:addBtn')} main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.Parent} />
                </div>
                {this.getAddModal()}
                {this.getModal()}
                {this.getEditModal()}
                {this.getInfoModal()}
            </div>
        )
    }
}

const Parent = withTranslation()(connect(mapStateToProps)(ParentComponent))

export { Parent }
