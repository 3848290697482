export const durationList = [
    {
        label: 'Overall',
        value: 0,
    },
    {
        label: 'This Month',
        value: 1,
    },
    {
        label: 'Last Month',
        value: 2,
    },
    {
        label: 'Last year',
        value: 3,
    },
]

export const limitList = [
    {
        label: 'Top 3 List',
        value: 3,
    },
    {
        label: 'Top 5 List',
        value: 5,
    },
    {
        label: 'Top 10 List',
        value: 10,
    },
    {
        label: 'Top 50 List',
        value: 50,
    },
    {
        label: 'Top 100 List',
        value: 100,
    },
]

export const user_role = [
    { value: 'overall', label: 'Overall' },
    { value: 2, label: 'Admin' },
    { value: 3, label: 'Student' },
    { value: 4, label: 'Teacher' },
    { value: 1, label: 'Custom' },
    { value: 6, label: 'Alumni' },
]

export const group = [
    { value: 0, label: 'Overall' },
    { value: 1, label: 'My Class' },
    { value: 2, label: 'My Section' },
]
