import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { AlumniRoute } from './guard'
import * as AlumniComponent from '~/views/alumni'
import { Block } from '~/views/blocked'
import * as AccessModule from '~/helper/constant/modules'

export const Alumni = () => [
    <AlumniRoute exact key="1" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.dashboard)} component={AlumniComponent.AlumniDashboard} />,
    <AlumniRoute exact key="2" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.yearbook.menu)} component={AlumniComponent.AlumniYearbook} />,
    <AlumniRoute exact key="3" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.yearbook.view + '/:id')} component={AlumniComponent.AlumniYearbookView} />,
    <AlumniRoute exact key="4" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.job.menu)} component={AlumniComponent.AlumniJobMenu} />,
    <AlumniRoute exact key="5" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.job.view)} component={AlumniComponent.AlumniJobView} />,
    <AlumniRoute exact key="6" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.job.detail + '/:id')} component={AlumniComponent.AlumniJobDetail} />,
    <AlumniRoute exact key="7" access={[AccessModule.Module_Alumni]} subAccess={AccessModule.ALUMNI} path={configRoute(route.alumni.job.applicant)} component={AlumniComponent.AlumniJobApplicants} />,
]
