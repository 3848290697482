const Alumni_Group = 0
const Alumni_Batch = 1
const Alumni_Designation = 2
const Alumni_Company = 3

export const categoryList = [
    {
        value: Alumni_Batch,
        label: 'Batch',
        translate: 'alumni:batch',
    },
    {
        value: Alumni_Group,
        label: 'Faculty',
        translate: 'alumni:faculty',
    },
    {
        value: Alumni_Company,
        label: 'Company',
        translate: 'alumni:company',
    },
    {
        value: Alumni_Designation,
        label: 'Designation',
        translate: 'alumni:designation',
    },
]
export { Alumni_Group, Alumni_Batch, Alumni_Designation, Alumni_Company }
