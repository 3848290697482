export const signin = (data) => {
    return {
        type: 'SIGN_IN',
        user: data.user,
        token: data.token,
    }
}

export const signout = () => {
    return {
        type: 'SIGN_OUT',
    }
}
