export const openIntro = (data) => {
    return {
        type: 'OPEN_INTRO',
    }
}

export const closIntro = () => {
    return {
        type: 'CLOSE_INTRO',
    }
}
