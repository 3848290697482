import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSchoolProfileComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Button, Input, Checkbox } from '~/components/form'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { currency } from '~/helper/constant/currency'
import { timezone } from '~/helper/constant/timezone'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import MultiImageInput from 'react-multiple-image-input'
import { withTranslation } from 'react-i18next'
import { language } from '~/helper/constant/language'
import { dateFormat } from '../../../helper/constant/date_format'

class SchoolProfileComponent extends AbstractSchoolProfileComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setCurrency = this._setCurrency.bind(this)
        this._setTimezone = this._setTimezone.bind(this)
        this._setProfile = this._setProfile.bind(this)
        this.getBreadCrumb = this.getBreadCrumb.bind(this)
        this.setLocale = this.setLocale.bind(this)
        this.setDateFormat = this.setDateFormat.bind(this)
    }

    setLocale(data) {
        super._handleChange('locale', data.value)
        super._handleChange('defaultLocale', data)
    }

    setDateFormat(data) {
        super._handleChange('date_format', data.value)
        super._handleChange('defaultDateFormat', data)
    }

    _setProfile(data) {
        this._handleChange('profile', data)
    }

    _setCurrency(data) {
        this._handleChange('currency', data.value)
        this._handleChange('defaultCurrency', data)
    }

    _setTimezone(data) {
        this._handleChange('timezone', data.value)
        this._handleChange('defaultTimezone', data)
    }

    getBreadCrumb() {
        if (this.state.school) {
            if (this.props.school.school_id != this.state.school.id || (this.props.school.school_id == this.state.school.id && this.state.school.setup == true)) {
                const { t } = this.props
                return (
                    <ul className="list-inline breadcrum clearfix">
                        <li>
                            <a href={route.activity}>{t('setting:dashboard')} &gt; </a>
                        </li>
                        <li>
                            <a href={route.my_school}>{t('header:myschool')} &gt; </a>
                        </li>
                        <li>{t('setting:schoolinformation')}</li>
                    </ul>
                )
            }
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <div className="main-content setup-main">
                    <Header history={this.props.history} className="setup-nav" hideToggle={true} />
                    <div className="main-head-option">
                        {this.getBreadCrumb()}
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('setting:schoolinformation')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="clearfix">
                                <div className="float-left form-group profile-container">
                                    <label className="form-title text-left">{t('setting:profilepicture')} *</label>
                                    <MultiImageInput images={this.state.profile} max={1} setImages={this._setProfile} allowCrop={false} theme="light" />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-4 float-left form-group">
                                    <label className="form-title text-left">{t('name')} *</label>
                                    <Input onChange={this._handleChange} name="name" placeholder={t('name')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger setup-error text-left">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-md-4 float-left form-group">
                                    <label className="form-title text-left">{t('email')} *</label>
                                    <Input onChange={this._handleChange} name="email" placeholder={t('email')} type="text" value={this.state.email} />
                                    {this.state.errors.email && <label className="error text-danger setup-error text-left">{this.state.errors.email}</label>}
                                </div>
                                <div className="col-md-4 float-left form-group">
                                    <label className="form-title text-left">{t('contact')} *</label>
                                    <Input onChange={this._handleChange} name="contact" placeholder={t('contact')} type="text" value={this.state.contact} />
                                    {this.state.errors.contact && <label className="error text-danger setup-error text-left">{this.state.errors.contact}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-4 float-left form-group">
                                    <label className="form-title text-left">{t('address')} *</label>
                                    <Input onChange={this._handleChange} name="address" placeholder={t('address')} type="text" value={this.state.address} />
                                    {this.state.errors.address && <label className="error text-danger setup-error text-left">{this.state.errors.address}</label>}
                                </div>
                                <div className="col-md-4 float-left form-group main-select">
                                    <label className="form-title text-left">{t('currency')} *</label>
                                    <Select name="currency" options={currency} placeholder={t('currency')} searchable={true} value={this.state.defaultCurrency} onChange={this._setCurrency} />
                                    {this.state.errors.currency && <label className="error text-danger setup-error text-left">{this.state.errors.currency}</label>}
                                </div>
                                <div className="col-md-4 float-left form-group main-select">
                                    <label className="form-title text-left">{t('timezone')} *</label>
                                    <Select name="timezone" options={timezone} placeholder={t('timezone')} searchable={true} value={this.state.defaultTimezone} onChange={this._setTimezone} />
                                    {this.state.errors.timezone && <label className="error text-danger setup-error text-left">{this.state.errors.timezone}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                {this.state.country == 'Nepal' && (
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">Date Format *</label>
                                        <Select name="date_format" value={this.state.defaultDateFormat} options={dateFormat} placeholder="Select Date Format" searchable={true} onChange={this.setDateFormat} />
                                        {this.state.errors.date_format && <label className="error text-danger">{this.state.errors.date_format}</label>}
                                    </div>
                                )}
                                <div className="col-sm-4 float-left form-group">
                                    <label className="form-title">{t('common:locale')} *</label>
                                    <Select name="locale" options={language} placeholder={t('common:locale')} searchable={true} value={this.state.defaultLocale} onChange={this.setLocale} />
                                    {this.state.errors.locale && <label className="error text-danger">{this.state.errors.locale}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    <div className="form-group text-left">
                                        <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SchoolProfile = withTranslation()(connect(mapStateToProps)(SchoolProfileComponent))

export { SchoolProfile }
