import React from 'react'
import InputColor from 'react-input-color'
import { AbstractColorPickerComponent } from './abstract'

export class ColorPicker extends AbstractColorPickerComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        return (
            <div className="color-picker-content">
                <input
                    value={this.state.value}
                    disabled={true}
                    className="form-control input-border color-picker-input"
                    onChange={(event) => {
                        this._onChange(event.target.value)
                    }}
                />
                <InputColor initialValue={this.state.value} placement="right" onChange={(color) => this._onChange(color.hex)} />
            </div>
        )
    }
}
