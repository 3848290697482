import React, { Component } from 'react'
import { AbstractButtonComponent } from './abstract'

export class Button extends AbstractButtonComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._onClick = this._onClick.bind(this)
    }

    getClass() {
        let className = ''
        if (this.props.login) {
            className = className + ' login-button'
        } else if (this.props.table) {
            className = className + ' table-check'
        } else if (this.props.main) {
            className = className + ' main-button'
        } else if (this.props.cancel) {
            className = className + ' cancel-button'
        } else {
            className = className + ' second-button'
        }
        return this.props.className != null ? className + ' ' + this.props.className : className
    }

    getButton() {
        if (this.props.modal) {
            console.log(this.props.modal)
            return (
                <button
                    link={this.props.link}
                    data-toggle="modal"
                    data-target={'#' + this.props.modal}
                    type={this.props.type}
                    className={this.getClass()}
                >
                    {this.props.text}
                </button>
            )
        } else {
            return (
                <button
                    onClick={this._onClick}
                    link={this.props.link}
                    type={this.props.type}
                    className={this.getClass()}
                    disabled={this.props.disabled ? true : false}
                >
                    {this.props.text}
                </button>
            )
        }
    }

    render() {
        return this.getButton()
    }
}
