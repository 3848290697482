import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractDriveFolderComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import Select from 'react-select'
import { Header } from '~/components/header'
import { UserFilter } from '~/components/userfilter'
import { Folder, DriveFile } from '~/components/drive'
import * as route from '~/helper/constant/route'
import Files from 'react-files'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import * as USERTYPE from '~/helper/constant/user_type'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import { getBase64, getReadableFileSize, getMediaIcon } from '~/helper/function'
import { convertADtoBS } from '../../../../helper/function'

const order = [
    { value: 'asc', label: 'Asc' },
    { value: 'desc', label: 'Desc' },
]
const sortby = [
    { value: 'date', label: 'Date' },
    { value: 'name', label: 'Name' },
]
class DriveFolderComponent extends AbstractDriveFolderComponent {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            ...this.state,
        }
        this.setPrivacy = this.setPrivacy.bind(this)
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this.selectAllStudent = this.selectAllStudent.bind(this)
        this.selectAllEmployee = this.selectAllEmployee.bind(this)
        this.openEditFolder = this.openEditFolder.bind(this)
        this.updateFolder = this.updateFolder.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.deleteFolder = this.deleteFolder.bind(this)
        this.openAddFolder = this.openAddFolder.bind(this)
        this.openDetailFolder = this.openDetailFolder.bind(this)
    }

    updateFolder(e, type) {
        super.updateFolder(e, type).then((response) => {
            if (response) {
                if (type == 'data') {
                    $('#close_edit').trigger('click')
                } else {
                    $('#close_add').trigger('click')
                }
            }
        })
    }

    getDeleteModal(index) {
        super.getDeleteModal(index).then((response) => {
            if (response) {
                $('#modal_class_delete').modal('show')
            }
        })
    }

    deleteFolder() {
        super.deleteFolder().then((response) => {
            if (response) {
                $('#close_delete').trigger('click')
            }
        })
    }

    openAddFolder() {
        $('#modal_drive_add').modal('show')
    }

    openDetailFolder(index) {
        super.openDetailFolder(index).then((response) => {
            if (response) {
                $('#details-drive').modal('show')
            }
        })
    }

    selectAllStudent(selectAllStudent, data) {
        if (!selectAllStudent) {
            const studentList = data
            let selectedStudent = []
            studentList.map((item, index) => {
                selectedStudent.push(item.value)
            })
            this.setState({
                selectedStudent,
            })
        } else {
            this.setState({
                selectedStudent: null,
            })
        }
    }
    selectAllEmployee(selectAllEmployee, data) {
        if (!selectAllEmployee) {
            const employeeList = data
            let selectedEmployee = []
            employeeList.map((item, index) => {
                selectedEmployee.push(item.value)
            })
            this.setState({
                selectedEmployee,
            })
        } else {
            this.setState({
                selectedEmployee: null,
            })
        }
    }

    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }
    filesRemoveOne = (file) => {
        let uploads = this.state.uploadedFiles
        let index = uploads.indexOf(file)
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploadedFiles', uploads)
    }
    selectFiles(data) {
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                })
            })
            this._handleChange('media', media)
            this._handleChange('uploadedFiles', data)
        }
    }
    setPrivacy() {
        const privacy = !this.state.privacy
        this.setState({ privacy })
        if (privacy) {
            // this.setState({
            //   errors: {
            //     folder_name: null,
            //     selectedStudent: null,
            //   },
            // });
            $('.access-list').show()
        } else {
            $('.access-list').hide()
            // this.setState({
            //   errors: {
            //     folder_name: null,
            //   },
            // });
        }
    }
    deleteModal() {
        const { t } = this.props
        return (
            <div id="modal_class_delete" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('drive:areYouSureYouWantToDeleteTheFile')}?</p>
                            <div className="file-detail">
                                <p className="delete-file-name">{getMediaIcon(this.state.selectedMediaType)}</p>
                                <p className="mb-0">{this.state.selectedMediaName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={() => this.deleteFolder()} type="button" text={t('common:modal confirm')} main={true} />
                            <button id="close_delete" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    detailFolder() {
        const { t } = this.props
        return (
            <div id="details-drive" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:fileDetails')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:fileName')}</span>
                                    <ins className="col-md-8">{this.state.detail.file_name}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:owner')}</span>
                                    <ins className="col-md-8">{this.state.detail.owner}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:created_on')}</span>
                                    <ins className="col-md-8">{this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.detail.createdOn != '' ? (convertADtoBS(this.state.detail.createdOn) != null ? convertADtoBS(this.state.detail.createdOn) : 'Invalid Date Range') : this.state.detail.createdOn}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-md-4">{t('drive:fileSize')}</span>
                                    <ins className="col-md-8">{this.state.detail.folderSize}</ins>
                                </div>
                                {this.state.detail.sharedTo.length > 0 && (
                                    <div className="form-group row clearfix folder-detail">
                                        <span className="col-md-4">{t('drive:shared_to')}</span>
                                        <ins className="col-md-8 small-ins">
                                            <div className="participant-img clearfix">
                                                {this.state.detail.sharedTo.map((item, index) => {
                                                    if (index < 3) {
                                                        if (item.length == 1) {
                                                            return (
                                                                <div className="img-part">
                                                                    <span>{item}</span>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="img-part">
                                                                    <img src={item} alt={'shared' + index} />
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                })}
                                                {this.state.detail.sharedTo.length - 3 > 0 && (
                                                    <span className="part-number">
                                                        {this.state.detail.sharedTo.length - 3} {t('drive:more')}
                                                    </span>
                                                )}
                                            </div>
                                        </ins>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button className="main-button" type="button" data-dismiss="modal">
                                    {t('common:modal close')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    openEditFolder() {
        const apiSelectedStudent = this.state.apiSelectedStudent
        const apiDefaultStudent = this.state.apiDefaultStudent
        const apiSelectedEmployee = this.state.apiSelectedEmployee
        const apiDefaultEmployee = this.state.apiDefaultEmployee
        const apiDefaultPrivacy = this.state.apiDefaultPrivacy
        this.setState(
            {
                selectedStudent: apiSelectedStudent,
                defaultStudent: apiDefaultStudent,
                selectedEmployee: apiSelectedEmployee,
                defaultEmployee: apiDefaultEmployee,
                privacy: apiDefaultPrivacy,
            },
            () => {
                if (this.state.privacy) {
                    $('.access-list').show()
                } else {
                    $('.access-list').hide()
                }
                $('#modal_folder_edit').modal('show')
            }
        )
    }
    editFolder() {
        const { t } = this.props
        return (
            <div id="modal_folder_edit" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:editFolder')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:name')} *</label>
                                        <Input name="folder_name" onChange={this._handleChange} placeholder={t('drive:folderName')} type="text" value={this.state.folder_name} />
                                        {this.state.errors.folder_name && <label className="error text-danger">{this.state.errors.folder_name}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="folder_description" onChange={this._handleChange} placeholder={t('drive:folderDescription')} type="text" value={this.state.folder_description} />
                                        {this.state.errors.folder_description && <label className="error text-danger">{this.state.errors.folder_description}</label>}
                                    </div>
                                </div>
                                <div className="form-check">
                                    <label className="form-title" htmlFor="private">
                                        {t('common:private')} *
                                    </label>
                                    <Checkbox id="private" onChange={this.setPrivacy} className="" name="privacy" value={this.state.privacy} />
                                </div>
                                <div className="add-option-exam access-list">
                                    <UserFilter onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} selectedStudent={this.state.selectedStudent} selectedEmployee={this.state.selectedEmployee} onUserLoad={this.loadUser} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={(e) => this.updateFolder(e, 'data')} type="submit" text={t('common:updateBtn')} main={true} />
                                    <button id="close_edit" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_drive_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('drive:addFiles')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left">
                                        <label className="form-title">{t('drive:files')}</label>
                                        <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                            {this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                                ? this.state.uploadedFiles.map((file) => (
                                                      <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                          <div className="gsEyTH files-list-item" key={file.id}>
                                                              <div className="files-list-item-preview">{file.preview.type === 'image' ? <img className="files-list-item-preview-image" src={file.preview.url} /> : <div className="files-list-item-preview-extension">{file.extension}</div>}</div>
                                                              <div className="files-list-item-content">
                                                                  <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                                                  <div className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</div>
                                                              </div>
                                                              <div
                                                                  id={file.id}
                                                                  className="files-list-item-remove"
                                                                  onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                                                              />
                                                          </div>
                                                      </div>
                                                  ))
                                                : null}
                                            <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                <Files ref={this.fileRef} className="files-dropzone" onChange={this.selectFiles} onError={this.onFilesError} accepts={['image/*', 'video/*', 'audio/*', '.pdf', '.docx', '.pptx', '.xlsx', '.doc', '.ppt', '.xls']} multiple maxFiles={10} maxFileSize={200000000} minFileSize={0} clickable>
                                                    {t('common:adddocument')}
                                                </Files>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={(e) => this.updateFolder(e, 'media')} type="submit" text={t('common:addBtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    displayData() {
        const { t } = this.props
        if (this.state.folders.length == 0) {
            return (
                <div className="text-center no-data">
                    {SVG.nodata()}
                    <span className="no-task">{t('drive:empty')}</span>
                </div>
            )
        }
        const folderList = this.state.folders.map((folderObj, index) => {
            const { t } = this.props
            return (
                <div key={index} className="folder-item">
                    <DriveFile index={index} folderName={folderObj.name} folderUrl={route.gallery + '/' + folderObj.id} media_type={folderObj.media_type} />
                    <div className="e-d-t">
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                            </button>
                            <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                <a href={route.gallery + '/' + folderObj.id} target="_blank">
                                    <button className="dropdown-item" type="button">
                                        {t('common:viewBtn')}
                                    </button>
                                </a>
                                <button className="dropdown-item" type="button" onClick={() => this.openDetailFolder(index)}>
                                    {t('common:details')}
                                </button>
                                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && (
                                    <Fragment>
                                        <button className="dropdown-item" type="button" onClick={() => this.getDeleteModal(index)}>
                                            {t('common:delete')}
                                        </button>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return folderList
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="drive" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.drive.view}>{t('drive:drive')} &gt; </a>
                            </li>
                            <li>{this.state.folder_name}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-3 float-left">
                                <h5 className="main-title heading5">
                                    <a className="vertical-middle" href={route.drive.view}>
                                        {t('drive:drive')}
                                    </a>{' '}
                                    &gt; {this.state.folder_name}
                                    {this.props.school.id == this.state.author && (
                                        <button className="dropdown-item edit-button" type="button" onClick={() => this.openEditFolder()}>
                                            <FontAwesomeIcon className="icon" icon="edit" color="#333" size="sm" />
                                        </button>
                                    )}
                                </h5>
                            </div>
                            <div className="col-md-9">
                                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && (
                                    <div className="display-inline-block  col-md-6 float-left">
                                        <form className="main-search float-right">
                                            <input onChange={(e) => this.search(e)} name="search" placeholder={t('common:searchhere')} type="text" value={this.state.searchText} />
                                            <div className="search-icon">{SVG.tablesearch()}</div>
                                        </form>
                                    </div>
                                )}
                                {(this.props.school.user_type == USERTYPE.Student || this.props.school.user_type == USERTYPE.Parents) && (
                                    <div className="display-inline-block  col-md-8 float-left">
                                        <form className="main-search float-right">
                                            <input onChange={(e) => this.search(e)} name="search" placeholder={t('common:searchhere')} type="text" value={this.state.searchText} />
                                            <div className="search-icon">{SVG.tablesearch()}</div>
                                        </form>
                                    </div>
                                )}
                                <div className="display-inline-block  col-md-2 float-left mb-2">
                                    <Select placeholder={t('common:sort')} options={order} onChange={(e) => this.changeOrderBy(e)} />
                                </div>
                                <div className="display-inline-block  col-md-2 float-left mb-2">
                                    <Select placeholder={t('common:sortby')} options={sortby} onChange={this.changeSortBy} />
                                </div>
                                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && (
                                    <div className="col-md-2 float-left text-center">
                                        <Button onClick={() => this.openAddFolder()} type="button" text={t('drive:addFiles')} main={true} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="main-box">
                        <div className="drive-page">
                            <div className="clearfix">{this.displayData()}</div>
                        </div>
                    </div>
                </div>
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.getAddModal()}
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.editFolder()}
                {this.detailFolder()}
                {this.props.school.user_type != USERTYPE.Student && this.props.school.user_type != USERTYPE.Parents && this.deleteModal()}
            </div>
        )
    }
}
const DriveFolder = withTranslation()(connect(mapStateToProps)(DriveFolderComponent))
export { DriveFolder }
