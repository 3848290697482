import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSubjectEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea } from '~/components/form'
import { Loading } from '~/components/loader'
import MultiImageInput from 'react-multiple-image-input'
import Select from 'react-select'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import { getBase64, getReadableFileSize, getMediaIcon } from '~/helper/function'
class SubjectEditComponent extends AbstractSubjectEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.filesRemoveServer = this.filesRemoveServer.bind(this)
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this.setSubjectType = this.setSubjectType.bind(this)
        this.setExamStatus = this.setExamStatus.bind(this)
    }

    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }

    filesRemoveOne = (file) => {
        let uploads = this.state.uploadedFiles
        let index = uploads.indexOf(file)
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploadedFiles', uploads)
    }

    filesRemoveServer = (file) => {
        const deletedMediaAry = this.state.deletedMediaAry
        let fileAry = this.state.defaultMedia
        let deleteIndex = fileAry.indexOf(file)
        fileAry.splice(deleteIndex, 1)
        deletedMediaAry.push(file.id)
        this.setState({ deletedMedia: deletedMediaAry, defaultMedia: fileAry })
    }

    selectFiles(data) {
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                })
            })
            this._handleChange('media', media)
            this._handleChange('uploadedFiles', data)
        }
    }

    getGallery() {
        const { t } = this.props
        if (this.state.defaultMedia.length > 0) {
            const modal = this.state.defaultMedia.map((file, index) => {
                const extension = file.media_type.replace('data:application/', '')
                const filesize = getReadableFileSize(file.size)
                return (
                    <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                        <div className="gsEyTH files-list-item" key={file.id}>
                            <div className="files-list-item-preview">
                                <div className="files-list-item-preview-extension">{extension}</div>
                            </div>
                            <div className="files-list-item-content">
                                <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                <div className="files-list-item-content-item files-list-item-content-item-2">{filesize}</div>
                            </div>
                            <div
                                id={file.id}
                                className="files-list-item-remove"
                                onClick={this.filesRemoveServer.bind(this, file)} // eslint-disable-line
                            />
                        </div>
                    </div>
                )
            })
            return modal
        } else {
            return <div className="gallery-message">{t('classes:subject.edit.galleryPlaceholder')}</div>
        }
    }

    setSubjectType(data) {
        super._handleChange('subject_type_id', data.value)
        super._handleChange('defaultSubjectType', data)
    }

    setExamStatus(data) {
        super._handleChange('exam_status', data.value)
        super._handleChange('defaultExamStatus', data)
    }
    render() {
        const { t } = this.props

        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.school.subject.view + '/' + this.state.class_id}>{t('classes:subject.breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('classes:subject.edit.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('classes:subject.edit.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('classes:subject.add.namePlaceholder')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.codeLabel')} *</label>
                                    <Input name="code" disabled="disabled" placeholder={t('classes:subject.add.codeLabel')} type="text" value={this.state.code} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:subject.add.subjectTypelabel')}</label>
                                    <Select name="subject_type" options={this.state.subject_type} placeholder={t('classes:subject.add.subjectTypelabel')} searchable={true} value={this.state.defaultSubjectType} onChange={this.setSubjectType} />
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:subject.add.examStatusLabel')} *</label>
                                    <Select name="exam_status" options={this.state.exam_option} placeholder={t('classes:subject.add.examStatusLabel')} searchable={true} value={this.state.defaultExamStatus} onChange={(data) => this.setExamStatus(data)} />
                                    {this.state.errors.exam_status && <label className="error text-danger">{this.state.errors.exam_status}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value={this.state.description} />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.mediaLabel')} [ .pdf ]</label>
                                    <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                        {this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                            ? this.state.uploadedFiles.map((file) => (
                                                  <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                      <div className="gsEyTH files-list-item" key={file.id}>
                                                          <div className="files-list-item-preview">{file.preview.type === 'image' ? <img className="files-list-item-preview-image" src={file.preview.url} /> : <div className="files-list-item-preview-extension">{file.extension}</div>}</div>
                                                          <div className="files-list-item-content">
                                                              <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                                              <div className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</div>
                                                          </div>
                                                          <div
                                                              id={file.id}
                                                              className="files-list-item-remove"
                                                              onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                                                          />
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                        <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                            <Files className="files-dropzone" onChange={this.selectFiles} onError={this.onFilesError} accepts={['.pdf']} multiple maxFiles={3} maxFileSize={10000000} minFileSize={0} clickable>
                                                {t('classes:subject.add.mediaDropZone')}
                                            </Files>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title"> {t('classes:subject.edit.galleryLabel')} </label>
                                    <div className="gallery-container">{this.getGallery()}</div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:updateBtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SubjectEdit = withTranslation()(connect(mapStateToProps)(SubjectEditComponent))

export { SubjectEdit }
