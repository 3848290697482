import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractAcademicAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input } from '~/components/form'
import { Loading } from '~/components/loader'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../helper/function'

class AcademicAddComponent extends AbstractAcademicAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._startDate = this._startDate.bind(this)
        this._endDate = this._endDate.bind(this)
    }

    _startDate(data) {
        this._handleChange('from_date', data)
    }

    _endDate(data) {
        this._handleChange('to_date', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="academic" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.academic.view}>{t('academic:breadcrumb')} > </a>
                            </li>
                            <li>{t('academic:addLink')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('academic:addTitle')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('academic:add.label')} *</label>
                                    <Input onChange={this._handleChange} name="name" placeholder={t('academic:add.placeholder')} type="text" value="" />
                                    {this.state.errors.name && <label className="error text-danger setup-error text-left">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('academic:add.startdate-label')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._startDate} selected={this.state.from_date} placeholderText={t('academic:add.startdate-placeholder')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.from_date != '' ? (convertADtoBS(this.state.from_date) != null ? convertADtoBS(this.state.from_date) + ' BS' : 'Invalid Date Range') : ''}</label>}

                                    {this.state.errors.from_date && <label className="error text-danger setup-error text-left">{this.state.errors.from_date}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('academic:add.enddate-label')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" onChange={this._endDate} selected={this.state.to_date} placeholderText={t('academic:add.enddate-placeholder')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.to_date != '' ? (convertADtoBS(this.state.to_date) != null ? convertADtoBS(this.state.to_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.to_date && <label className="error text-danger setup-error  text-left">{this.state.errors.to_date}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const AcademicAdd = withTranslation()(connect(mapStateToProps)(AcademicAddComponent))

export { AcademicAdd }
