import React, {Component} from 'react'
import {Dispatch} from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'
import {viewProductCategory} from '~/services/api'
import {category_type} from '~/helper/constant/category.js'


type Props = {
    dispatch: Dispatch<any>
}
type State = {}

const tableData = {
    category:{
        field:'text',
        route:false,
        sortable:true,
        translate:'alumni:category'
    },
    type:{
        field:'text',
        route:false,
        sortable:true,
        translate:"attendance:type"
    },
    action:{
        field:'button',
        route:true,
        sortable:false,
        translate:'activity:action',
    }
}

export class AbstractConstantCategoryViewComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            particular: [tableData],
            expense: [tableData],
            income: [tableData],
            category_type:category_type,
            // tableStructure:[
            //     {
            //         category:{
            //             field:'text',
            //             route:false,
            //             sortable:true,
            //             translate:'alumni:category'
            //         },
            //         type:{
            //             field:'text',
            //             route:false,
            //             sortable:true,
            //             translate:"attendance:type"
            //         },
            //         action:{
            //             field:'button',
            //             route:true,
            //             sortable:false,
            //             translate:'activity:action',
            //         }
            //     }
            // ]
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount(){
        this.loadCategory()

    }
    _handleChange(key, data){
        this.setState({[key]:data})
    }
 
    loadCategory(){
        const {t} = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        const particular =  [tableData]
        const expense = [tableData]
        const income = [tableData]
        // tableStructure.push(this.state.tableStructure[0])
        viewProductCategory(this.props.token).then((response)=>{
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                response.data.categories.map((item, index)=>{
                    console.log("Newa The King", item)
                    
                    let text = null
                    if (item.type == 0){
                        const rowData = {
                            category:{
                                text: item.name
                            },
                            type:{
                                text: category_type[0].label
                            },
                            action: {
                                text: 'Edit',
                                translate: 'common:EditBtn',
                                route: route.admin.accounting.constant.category.edit + '/' + item.id,
                              },
                        }

                        particular.push(rowData)

                    }
                    if (item.type == 1){
                        const rowData = {
                            category:{
                                text: item.name
                            },
                            type:{
                                text: category_type[1].label
                            },
                            action: {
                                text: 'Edit',
                                translate: 'common:EditBtn',
                                route: route.admin.accounting.constant.category.edit + '/' + item.id,
                              },
                        }

                        income.push(rowData)

                    }
                    if (item.type == 2){
                        const rowData = {
                            category:{
                                text: item.name
                            },
                            type:{
                                text: category_type[2].label
                            },
                            action: {
                                text: 'Edit',
                                translate: 'common:EditBtn',
                                route: route.admin.accounting.constant.category.edit + '/' + item.id,
                              },
                        }

                        expense.push(rowData)

                    }
                    
                    
                    // tableStructure.push(rowData)
                })
                this.setState({
                    particular,
                    income,
                    expense

                })
                
            }

        })
        
    }



}

export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}