import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import moment from 'moment'
import * as validation from '~/helper/constant/validation'
import { activeClass, activeRole, getInvite, sendInvitation } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as UserType from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'

type Props = {
    user: Object,
    student: String,
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    category: Array,
    data: Array,
    loading: Boolean,
}

export class AbstractInviteComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            isParent: false,
            isCategory: false,
            classData: [],
            classList: [],
            sectionList: [],
            roleList: [],
            selectedUserType: UserType.invite_role[1],
            selectedClass: null,
            selectedSection: null,
            selectedRole: null,
            user_type: UserType.invite_role[1].value,
            class: null,
            section: null,
            role: null,
            users: [],
            errors: {
                user_type: null,
                users: null,
            },
            invites: [
                {
                    email: {
                        field: 'text',
                        translate: 'email',
                        route: false,
                        sortable: true,
                    },
                    user_type: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'attendance:userType',
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        translate: 'view',
                        route: true,
                        sortable: false,
                        translate: 'common:viewBtn',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadClass()
        this.loadRole()
    }

    loadClass() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classList = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classList.push({
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                    })
                })
                this._handleChange('classData', response.data.class)
                this._handleChange('classList', classList)
                if (classList.length > 0) {
                    this.loadSection(classList[0].value)
                }
            }
        })
    }

    loadSection(class_id) {
        const classes = this.state.classData.find((item) => item.id == class_id)
        let sectionList = []
        classes.active_section.map((item, index) => {
            sectionList.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        this._handleChange('sectionList', sectionList)
    }

    loadRole() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeRole(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let roleList = []
            if (response.code == API_SUCCESS) {
                response.data.role.map((item, index) => {
                    roleList.push({ value: item.id, label: item.name })
                })
                this.setState(
                    {
                        roleList,
                    },
                    () => {
                        this.loadInvite()
                    }
                )
            }
        })
    }

    loadInvite() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getInvite(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let invites = []
            invites.push(this.state.invites[0])
            if (response.code == API_SUCCESS) {
                response.data.invite.map((item, index) => {
                    let role = UserType.all_role.find((role) => role.value == item.user_type)
                    let text = null
                    if (item.status == Status.PENDING) {
                        text = {
                            text: 'Sent',
                            translate: 'common:sent',
                            badge: 'table-blue',
                        }
                    } else {
                        text = {
                            text: 'Pending',
                            translate: 'common:pending',
                            badge: 'table-green',
                        }
                    }
                    const tableData = {
                        email: {
                            text: item.email,
                        },
                        user_type: {
                            text: role.label,
                        },
                        status: text,
                        action: {
                            text: 'View',
                            translate: 'common:viewBtn',
                            route: route.admin.setting.invite.approval.view + '/' + item.id,
                        },
                    }
                    invites.push(tableData)
                })
            }
            this.setState({
                invites,
            })
        })
    }

    sendInvitation() {
        let errors = {
            user_type: null,
            users: null,
        }
        const { t } = this.props
        errors = validation.required(Object.keys(errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            if (this.state.user_type == UserType.Student) {
                if (this.state.class == null) {
                    errors.class = t('toaster:required')
                }
                if (this.state.section == null) {
                    errors.section = t('toaster:required')
                }
            } else if (this.state.user_type == UserType.Custom) {
                if (this.state.role == null) {
                    errors.section = t('toaster:required')
                }
            }
            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                const data = {
                    user_type: this.state.user_type,
                    section_id: this.state.section,
                    users: this.state.users,
                    role_id: this.state.role_id,
                    isParent: this.state.isParent,
                    isCategory: this.state.isCategory,
                }
                sendInvitation(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        $('#close_add').trigger('click')
                        this.loadInvite()
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        }
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }
}

const AbstractInvite = withTranslation()(AbstractInviteComponent)

export { AbstractInvite }

export function mapStateToProps(state: Object) {
    return {
        student: state.student,
        user: state.user,
        school: state.school,
        token: state.token,
    }
}
