import loggedReducer from './isLogged'
import tokenReducer from './token'
import userReducer from './user'
import schoolReducer from './school'
import postReducer from './post'
import participantsReducer from './participants'
import packageReducer from './package'
import childrenReducer from './student'
import alumniReducer from './isAlumni'
import fullScreenReducer from './isFullScreen'

import { createStore, combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
// import storageSession from 'redux-persist/lib/storage/session'
import AsyncStorage from '@react-native-community/async-storage'
import introReducer from './isIntro'

const rootReducers = combineReducers({
    isLogged: loggedReducer,
    token: tokenReducer,
    user: userReducer,
    school: schoolReducer,
    postSearch: postReducer,
    schoolPackage: packageReducer,
    participants: participantsReducer,
    student: childrenReducer,
    isAlumni: alumniReducer,
    isIntro: introReducer,
    isFullScreen: fullScreenReducer,
})

const persistConfig = {
    key: 'root',
    version: 0,
    storage: AsyncStorage,
    whitelist: ['school', 'isLogged', 'token', 'user', 'postSearch', 'participants', 'schoolPackage', 'student', 'isAlumni', 'isIntro', 'isFullScreen'],
}

const mainReducers = persistReducer(persistConfig, rootReducers)

const store = createStore(mainReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

const persistor = persistStore(store)

const persistence = () => {
    return {
        store,
        persistor,
    }
}

export default persistence
