import 'firebase/messaging'
import firebase from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyAb4F6Ygfo1KoHQ2YjeiztcgNESu4ZL7Yc',
    authDomain: 'collabarate-b67c6.firebaseapp.com',
    projectId: 'collabarate-b67c6',
    storageBucket: 'collabarate-b67c6.appspot.com',
    messagingSenderId: '714950726928',
    appId: '1:714950726928:web:fe855aa701b7ee6b3fc195',
    measurementId: 'G-3MBJ5YG4XE',
}
let messaging = null
let onMessageListener = null
firebase.initializeApp(firebaseConfig)
if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
    messaging
    onMessageListener = () =>
        new Promise((resolve) => {
            messaging.onMessage((payload) => {
                resolve(payload)
            })
        })
}

export { messaging, onMessageListener }
