import { get, post } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getGallery = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/media/' + id, token).then((response) => {
                resolve(response)
            })
        } catch (e) {
            resolve(null)
        }
    })
}
