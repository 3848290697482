import React, { Component, Fragment } from 'react'
import { Button } from '~/components/form'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'

export class PageNotFound extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <div className="main-content setup-main">
                    <div className="row clearfix">
                        <div className="col-md-6 offset-md-3">
                            <a className="blocked-logo-link">
                                <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                            </a>
                            <div className="setup-container">
                                <h5 className="error">404 Error</h5>
                                <hr />
                                <br />
                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>The page you are searching for is not found.</p>
                                        </div>
                                        <Button onClick={() => redirect(route.index, this.props.history)} type="button" text="Home" main={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
