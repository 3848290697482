import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseModuleStudentComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import ProgressBar from '@ramonak/react-progress-bar'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import $ from 'jquery'
import moment from 'moment'

class CourseModuleStudentComponent extends AbstractCourseModuleStudentComponent {
    constructor(props) {
        // const {t}= this.props
        super(props)
        this.state = {
            ...this.state,
        }
        this.showModules = this.showModules.bind(this)
        this.openDetailProperties = this.openDetailProperties.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    openDetailProperties(index) {
        super.openDetailProperties(index).then((response) => {
            if (response) {
                $('#details-course').modal('show')
            }
        })
    }

    showContent(item) {
        const { t } = this.props
        let data = item.content.map((content, ci) => {
            return (
                <li className="clearfix">
                    <div className="col-3 module-img float-left">
                        {/* <img src="assets/images/module-img1.png" alt="module-img" /> */}
                        <img src="/assets/images/course.png" alt="notification" />
                    </div>
                    <div className="col-9 module-detail float-left clearfix">
                        <a target="_blank" href={content.media ? content.media.media_url : '#!'}>
                            <p>{content.name}</p>
                        </a>
                        <span>
                            {content.description}
                            {/* Duration: <ins>33:34 mins</ins> */}
                        </span>
                    </div>
                    <div className="col-md-3 float-left text-right">
                        <button className="manage" onClick={() => this.openEditCourse(item.id, content.id)}>
                            {t('student_panel:module.manage')}
                        </button>
                        {'|'}
                        <button className="manage" onClick={() => this.openDeleteCourse(item.id, content.id)}>
                            {t('student_panel:module.delete')}
                        </button>
                    </div>
                </li>
            )
        })
        if (data != '') {
            return (
                <ul id="sub-module" className="sub-module list-inline">
                    {data}
                </ul>
            )
        } else {
            return
        }
    }

    showModules() {
        const { t } = this.props

        let data = this.state.courseModule.map((item, index) => {
            const courseCount = item.content.length
            const course_progress = this.state.course_progress.filter((el) => {
                if (this.props.school.user_type == UserType.Parents) {
                    return el.user_progress == 2 && el.user_school_id == this.props.student
                } else {
                    return (
                        el.user_progress == 2 &&
                        el.user_school_id == this.props.school.active_user_academics.user_school_id
                    )
                }
            })
            let dependentModule = null
            let completedCount = 0
            let courseContentAry = []
            item.content.map((obj, index) => {
                courseContentAry.push(obj.id)
            })
            course_progress.map((el, index) => {
                if (courseContentAry.indexOf(el.course_content_id) >= 0) {
                    completedCount++
                }
            })
            if (item.after_completion) {
                let dependentCompletedCount = 0
                const courseContentAry = []
                dependentModule = this.state.courseModule.filter((el) => {
                    return el.id == item.after_completion
                })[0]
                dependentModule.content.map((obj, index) => {
                    courseContentAry.push(obj.id)
                })
                course_progress.map((el, index) => {
                    if (courseContentAry.indexOf(el.course_content_id) >= 0) {
                        dependentCompletedCount++
                    }
                })
                if (dependentCompletedCount >= dependentModule.content.length) {
                    dependentModule = null
                }
            }
            const styleObj = {
                width: (completedCount / courseCount) * 100 + '%',
            }
            return (
                <div className="clearfix video-content course-video" key={index}>
                    <div className="col-3 module-img float-left">
                        <img src="/assets/images/course.png" alt="notification" />
                    </div>
                    <div className="col-9 module-detail smw-100 float-left clearfix">
                        <p>{item.name}</p>
                        <span>{item.description}</span>
                        <span>
                            {t('student_panel:module.totalContent')}: <ins>{courseCount}</ins>
                        </span>
                        <span>
                            {t('student_panel:module.totalCompleted')}: <ins>{completedCount}</ins>
                        </span>
                        <div className="progress-group clearfix">
                            <span className="float-right progress-percent">
                                {(((completedCount / courseCount) * 100 &&
                                    ((completedCount / courseCount) * 100).toFixed()) ||
                                    0) + '%'}
                            </span>
                            <div className="progress progress-sm">
                                <div className="progress-bar progress-blue" style={styleObj}></div>
                            </div>
                        </div>
                        {(!dependentModule && (
                            <a
                                className="resume-but"
                                href={route.course.student.content + '/' + item.course_id + '/' + item.id}
                            >
                                <button className="main-button">{t('common:viewBtn')}</button>
                            </a>
                        )) || (
                            <span className="resume-but">
                                {t('student_panel:module.complete')} <span>{dependentModule.name}</span>{' '}
                                {t('student_panel:module.first')}
                            </span>
                        )}
                    </div>
                </div>
            )
        })
        if (data == '') {
            const { t } = this.props
            return (
                <div className="row">
                    <div className="col-md-12">
                        <p className="no-data">
                            {SVG.nodata()} {t('common:table.nodata')}
                        </p>
                    </div>
                </div>
            )
        }
        return data
    }

    render() {
        const { t } = this.props
        const today = moment(moment().format('yyyy-MM-DD'), 'yyyy-MM-DD')
        const dueDate = moment(this.state.course?.to_date, 'yyyy-MM-DD')
        const startDate = moment(this.state.course?.from_date, 'yyyy-MM-DD')
        const isOpen =
            !this.state.course?.course?.available_after_due ||
            (today.isSameOrBefore(dueDate) && this.state.course?.course?.available_after_due)

        const isUpcoming = startDate.isAfter(today)
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.student.view}>
                                    {t('student_panel:content.courseBreadcrumb')} &gt;{' '}
                                </a>
                            </li>
                            <li> {this.state.course.title}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-4 float-left">
                                <h5 className="main-title">{this.state.course.title}</h5>
                            </div>
                            <div className="col-md-8 calendar-module float-left text-right"></div>
                        </div>
                    </div>
                    {isOpen && (
                        <Fragment>
                            {isUpcoming && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="no-data">
                                            This course is releasing in {startDate.diff(today, 'd')} day/s.
                                            <br />
                                            Course will be availabe on {this.state.course?.from_date}.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isUpcoming && <Fragment>{this.showModules()}</Fragment>}
                        </Fragment>
                    )}
                    {!isOpen && (
                        <div className="row">
                            <div className="col-md-12">
                                <p className="no-data">
                                    This course has already been closed.
                                    <br />
                                    You don't have the permission to view this course.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('student', [])
                component._handleChange('employee', [])
                component._handleChange('isPrivate', true)
                component._handleChange('selected_subject', null)
                const errors = {
                    title: null,
                    description: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

const CourseModuleStudent = withTranslation()(connect(mapStateToProps)(CourseModuleStudentComponent))

export { CourseModuleStudent }
