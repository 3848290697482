import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSchoolUserProfile } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import * as QualificationType from '~/helper/constant/qualification_type'
import moment from 'moment'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    user: Object,
    search: String,
}

export class AbstractUserProfileComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            user: null,
            school: null,
            qualification: [],
            experience: [],
            media: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadUser()
    }

    loadUser() {
        this._handleChange('loading', true)
        getSchoolUserProfile(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const school =
                    response.data.user.user_school != null
                        ? response.data.user.user_school.find((item) => item.school_id == this.props.school.school_id)
                        : null
                const qualification = []
                const experience = []
                response.data.user.user_qualification
                    .filter((item) => item.type == QualificationType.Qualification)
                    .map((item) => {
                        const data = {
                            id: item.id,
                            from: item.from != null ? moment(item.from).format('yyyy-MM-DD') : '',
                            to: item.to != null ? moment(item.to).format('yyyy-MM-DD') : '',
                            description: item.description,
                            company: item.company,
                            isPresent: item.isPresent,
                            deleted: false,
                        }
                        qualification.push(data)
                    })
                response.data.user.user_qualification
                    .filter((item) => item.type == QualificationType.Experience)
                    .map((item) => {
                        const data = {
                            id: item.id,
                            from: item.from != null ? moment(item.from).format('yyyy-MM-DD') : '',
                            to: item.to != null ? moment(item.to).format('yyyy-MM-DD') : '',
                            description: item.description,
                            company: item.company,
                            isPresent: item.isPresent,
                            deleted: false,
                        }
                        experience.push(data)
                    })
                const media =
                    response.data.user.user_detail != null
                        ? response.data.user.user_detail.gallery != null
                            ? response.data.user.user_detail.gallery.media
                            : []
                        : []
                this.setState({
                    user: response.data.user,
                    qualification,
                    experience,
                    school,
                    media,
                    loaded: true,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
