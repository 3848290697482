import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniJobApplicantsComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as UserType from '~/helper/constant/user_type'
import { Loading } from '~/components/loader'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import $ from 'jquery'
class AlumniJobApplicantsComponent extends AbstractAlumniJobApplicantsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setEndDate = this._setEndDate.bind(this)
        this._setStartDate = this._setStartDate.bind(this)
        this.filterJob = this.filterJob.bind(this)
    }

    _setEndDate(data) {
        this._handleChange('end_date', data)
    }
    _setStartDate(data) {
        this._handleChange('start_date', data)
    }

    filterJob(e) {
        e.preventDefault()
        this.loadApplicant().then((response) => {
            if (response) {
                $('#filter_close').trigger('click')
            }
        })
    }

    getFilter() {
        const { t } = this.props
        return (
            <div id="modal_filter" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('todo:filterbyduedate')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('fromdate')}</label>
                                        <DatePicker placeholderText={t('common:selectFromDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.start_date} selected={this.state.start_date} onChange={this._setStartDate} />
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('todate')}</label>
                                        <DatePicker placeholderText={t('common:selectToDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.end_date} selected={this.state.end_date} onChange={this._setEndDate} />
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.filterJob} type="submit" text={t('todo:filter')} main={true} />
                                <button id="filter_close" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t, school } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="alumniJobVacancy" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.alumni.job.menu}>{t('alumni:jobs')} > </a>
                            </li>
                            <li>{this.props.school.user_type == UserType.Alumni ? t('alumni:appliedJobs') : t('alumni:jobApplicants')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{this.props.school.user_type == UserType.Alumni ? t('alumni:appliedJobs') : t('alumni:jobApplicants')}:</h5>
                                <small className="small-main-title">
                                    {moment(this.state.start_date).format('D MMM')}
                                    &nbsp; - &nbsp;
                                    {moment(this.state.end_date).format('D MMM')}
                                </small>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button type="button" text={t('todo:filter')} modal="modal_filter" main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.applicant} />
                </div>
                {this.getFilter()}
            </div>
        )
    }
}

const AlumniJobApplicants = withTranslation()(connect(mapStateToProps)(AlumniJobApplicantsComponent))

export { AlumniJobApplicants }
