import React, { Component } from 'react'
import $ from 'jquery'

type Props = {
    status: boolean,
    text: String,
}

type State = {
    status: boolean,
}

export class AbstractLoaderComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            status: this.props.status ? true : false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status != this.props.status) {
            this.setState({ status: this.props.status ? true : false })
        }
    }
}
