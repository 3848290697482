import io from 'socket.io-client'
// import P2P from  "socket.io-p2p";
import { SOCKET_URL } from '~/helper/constant/config'
import { participant_join, participant_leave, chat } from '~/store/action'
import * as MessageType from '~/helper/constant/message_type'

const socket = io.connect(SOCKET_URL, { transports: ['polling'] })
// const socket = new P2P(connection);

export class Connection {
    login(dispatch, userSchool) {
        const data = {
            room: userSchool.school.short_code,
            data: {
                user: userSchool.id,
                isLogged: true,
            },
            type: MessageType.LOGIN,
        }
        socket.emit('login', data)
        dispatch(participant_join(data))
    }

    logout(userSchool) {
        const data = {
            room: userSchool.school.short_code,
            data: {
                user: userSchool.id,
                isLogged: false,
            },
            type: MessageType.LOGOUT,
        }
        socket.emit('logout', data)
    }

    join(dispatch, room, userSchoolId) {
        const data = {
            room: room,
            data: {
                user: userSchoolId,
                isLogged: true,
            },
            type: MessageType.JOIN,
        }
        socket.emit('subscribe', data)
    }

    leave(room, userSchoolId) {
        const data = {
            room: room,
            data: {
                user: userSchoolId,
                isLogged: false,
            },
            type: MessageType.LEAVE,
        }
        socket.emit('unsubscribe', data)
    }

    participants(dispatch, userSchool) {
        const userData = {
            room: userSchool.school.short_code,
            data: {
                user: userSchool.id,
                isLogged: true,
            },
            type: MessageType.PARTICIPANT,
        }
        socket.on('participants', (response) => {
            if (response.data.isLogged) {
                dispatch(participant_join(response))
                socket.emit('user-information', userData)
            } else {
                dispatch(participant_leave(response))
            }
        })
    }

    userInformation(dispatch) {
        socket.on('user-information', (response) => {
            if (response.data.isLogged) {
                dispatch(participant_join(response))
            } else {
                dispatch(participant_leave(response))
            }
        })
    }

    send(data) {
        socket.emit('message', data)
    }

    receive(key, state) {
        socket.on('message', (response) => {
            state.setState({
                [key]: response,
            })
        })
    }
}
