import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { addHoliday, getCalendar, addEvent, toggleEvent } from '~/services/api'
import moment from 'moment'
import { calendar_color_home } from '~/helper/constant/color'
import * as route from '~/helper/constant/route'
import * as PostType from '~/helper/constant/post_type'
import { calendar } from '../../../assets/svg/index'
import { redirect } from '~/helper/function/util'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

const year = moment().format('YYYY')
type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    initial_date: String,
    start_date: String,
    end_date: String,
    events: Object,
    loading: Boolean,
    loading_text: String,
    holiday_description: String,
    holiday_start: String,
    holiday_end: String,
    holiday_errors: Object,
    event_description: String,
    event_start: String,
    event_end: String,
    event_errors: Object,
}

export class AbstractCalendarComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            currentEvent: null,
            currentExam: null,
            loaded: false,
            modal_event_title: null,
            modal_event_type: null,
            modal_event_start: null,
            modal_event_end: null,
            initial_date: this.props.match.params.initial ? this.props.match.params.initial : moment().format('yyyy-MM-DD'),
            start_date: year + '-01-01',
            end_date: parseInt(year) + 1 + '-01-01',
            events: [],
            filterType: null,
            filterEvents: [],
            holiday_description: '',
            holiday_start: '',
            holiday_end: '',
            holiday_errors: {
                holiday_description: null,
                holiday_start: null,
                holiday_end: null,
            },
            event_description: '',
            event_start: '',
            event_end: '',
            event_errors: {
                event_description: null,
                event_start: null,
                event_end: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onHolidaySave = this._onHolidaySave.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadCalendar = this.loadCalendar.bind(this)
        this._holidayView = this._holidayView.bind(this)
        this._eventView = this._eventView.bind(this)
        this.checkDate = this.checkDate.bind(this)
        this._toggleEvent = this._toggleEvent.bind(this)
    }

    componentDidMount() {
        this.setState(
            {
                start_date: this.props.school != null && this.props.school.school != null && this.props.school.school.academic_year != null ? this.props.school.school.academic_year.from_date : this.state.start_date,
                end_date: this.props.school != null && this.props.school.school != null && this.props.school.school.academic_year != null ? this.props.school.school.academic_year.to_date : this.state.end_date,
            },
            () => {
                this.loadCalendar()
            }
        )
    }

    _holidayView() {
        redirect(route.admin.holiday.view, this.props.history)
    }

    _eventView() {
        redirect(route.admin.event.view, this.props.history)
    }

    _toggleEvent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        toggleEvent(this.state.currentEvent.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const event = response.data.event
                if (event.bbb != null) {
                    redirect(route.bbb + '/' + this.state.currentEvent.bbb.id, this.props.history)
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _startExam() {
        const { t } = this.props
        const routine = this.state.currentExam
        if (routine != null) {
            if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
                const exam_date = moment(routine.exam_date, 'yyyy-MM-DD')
                const start_time = moment(routine.start_time, 'hh:mm a')
                const end_time = moment(routine.end_time, 'hh:mm a')
                const today = moment(moment().format('yyyy-MM-DD'))
                const current_time = moment(moment(), 'hh:mm a')
                if (exam_date.isSame(today)) {
                    if (current_time.isSameOrAfter(start_time) && current_time.isSameOrBefore(end_time)) {
                        redirect(route.online + '/' + routine.id, this.props.history)
                    } else if (current_time.isBefore(start_time)) {
                        toastMessage('error', t('toaster:examStartMsg') + routine.exam_date + ' - ' + routine.start_time)
                        return false
                    } else {
                        toastMessage('error', t('toaster:examConductedMsg'))
                        return false
                    }
                } else if (exam_date.isBefore(today)) {
                    toastMessage('error', t('toaster:examConductedMsg'))
                    return false
                } else {
                    toastMessage('error', t('toaster:examStartMsg') + routine.exam_date)
                    return false
                }
            } else {
                redirect(route.online + '/' + routine.id, this.props.history)
            }
        }
    }

    toggleFilter(type) {
        if (type != this.state.filterType) {
            this.setState(
                {
                    filterType: type,
                },
                () => {
                    this.filterCalendar()
                }
            )
        }
    }
    filterCalendar() {
        let filterEvents = this.state.events
        if (this.state.filterType != null) {
            filterEvents = filterEvents.filter((item) => item.type == this.state.filterType)
        }
        this.setState({
            filterEvents,
        })
    }

    loadCalendar() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCalendar(this.state.start_date, this.state.end_date, this.props.token).then((response) => {
            this._handleChange('loading', false)

            if (response.code == API_SUCCESS) {
                const post = response.data.post.filter((item) => item.isAlumni == this.props.isAlumni)
                const events = []
                post.map((item) => {
                    let color = calendar_color_home[0]
                    let color_native = 'calendarTag1'
                    let title = item.description
                    if (item.type == PostType.Holiday) {
                        color = calendar_color_home[1]
                        color_native = 'calendarTag2'
                    } else if (item.type == PostType.Homework) {
                        color = calendar_color_home[2]
                        color_native = 'calendarTag3'
                    } else if (item.type == PostType.Exam) {
                        color = calendar_color_home[3]
                        color_native = 'calendarTag4'
                    }
                    if (item.type == PostType.Homework || item.type == PostType.Exam) {
                        title = item.title
                    }
                    let data = {
                        title,
                        type: item.type,
                        from_date: item.from_date,
                        to_date: moment(item.to_date).add(1, 'days').format('yyyy-MM-DD'),
                        start: item.from_date,
                        end: moment(item.to_date).add(1, 'days').format('yyyy-MM-DD'),
                        borderColor: '#fff',
                        classNames: color,
                        color_native,
                        allDay: true,
                    }
                    events.push(data)
                    item.event.map((eventItem) => {
                        data = {
                            title: eventItem.description,
                            type: item.type,
                            from_date: eventItem.date + 'T' + moment(eventItem.start_time, 'hh:mm:a').format('HH:mm'),
                            to_date: eventItem.date + 'T' + moment(eventItem.end_time, 'hh:mm:a').format('HH:mm'),
                            start: eventItem.date + 'T' + moment(eventItem.start_time, 'hh:mm:a').format('HH:mm'),
                            end: eventItem.date + 'T' + moment(eventItem.end_time, 'hh:mm:a').format('HH:mm'),
                            allDay: false,
                            nepali_from_date: eventItem.date,
                            nepali_to_date: eventItem.date,
                            start_time: eventItem.start_time,
                            end_time: eventItem.end_time,
                            classNames: color,
                            color_native,
                            eventData: eventItem,
                            live_event: eventItem.live_class_status,
                        }
                        events.push(data)
                    })
                    item.exam.map((examItem) => {
                        data = {
                            title: examItem.subject.name,
                            parentTitle: title,
                            type: item.type,
                            from_date: examItem.exam_date + 'T' + moment(examItem.start_time, 'hh:mm:a').format('HH:mm'),
                            to_date: examItem.exam_date + 'T' + moment(examItem.end_time, 'hh:mm:a').format('HH:mm'),
                            start: examItem.exam_date + 'T' + moment(examItem.start_time, 'hh:mm:a').format('HH:mm'),
                            end: examItem.exam_date + 'T' + moment(examItem.end_time, 'hh:mm:a').format('HH:mm'),
                            nepali_from_date: examItem.exam_date,
                            nepali_to_date: examItem.exam_date,
                            allDay: false,
                            start_time: examItem.start_time,
                            end_time: examItem.end_time,
                            classNames: color,
                            live_event: examItem.is_online,
                            examData: examItem,
                            color_native,
                        }
                        events.push(data)
                    })
                })

                this.setState({
                    events,
                    filterEvents: events,
                    loaded: true,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    checkDate(start_date, end_date) {
        const start = moment(start_date, 'yyyy-MM-DD')
        const end = moment(end_date, 'yyyy-MM-DD')
        if (end.isBefore(start)) {
            return 'Invalid'
        }
        return null
    }

    _onHolidaySave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const holiday_errors = validation.required(Object.keys(component.state.holiday_errors), component.state)
            component.setState({ holiday_errors })
            const validate = Object.keys(holiday_errors).filter((item) => holiday_errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    title: t('calendar:calendar-menu.holidayBtn'),
                    description: component.state.holiday_description,
                    from_date: moment(component.state.holiday_start).format('yyyy-MM-DD'),
                    to_date: moment(component.state.holiday_end).format('yyyy-MM-DD'),
                }
                const checkDate = component.checkDate(component.state.holiday_start, component.state.holiday_end)
                if (checkDate != null) {
                    holiday_errors['holiday_start'] = checkDate
                    holiday_errors['holiday_end'] = checkDate
                    component.setState({ holiday_errors })
                }
                const validate = Object.keys(holiday_errors).filter((item) => holiday_errors[item] != null)
                if (validate.length == 0) {
                    component._handleChange('loading_text', 'Saving')
                    component._handleChange('loading', true)
                    addHoliday(data, component.props.token).then((response) => {
                        component._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            toastMessage('success', response.message)
                            component.loadCalendar()
                            resolve(true)
                        } else {
                            toastMessage('error', response.message)
                            resolve(false)
                        }
                    })
                }
            }
        })
    }

    _onSave(schedule) {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            const event_errors = validation.required(Object.keys(component.state.event_errors), component.state)
            component.setState({ event_errors })
            const validate = Object.keys(event_errors).filter((item) => event_errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    title: t('calendar:calendar-menu.eventBtn'),
                    description: component.state.event_description,
                    from_date: moment(component.state.event_start).format('yyyy-MM-DD'),
                    to_date: moment(component.state.event_end).format('yyyy-MM-DD'),
                    isAlumni: component.props.isAlumni,
                }
                const checkDate = component.checkDate(component.state.event_start, component.state.event_end)
                if (checkDate != null) {
                    event_errors['event_start'] = checkDate
                    event_errors['event_end'] = checkDate
                    component.setState({ event_errors })
                }
                const validate = Object.keys(event_errors).filter((item) => event_errors[item] != null)
                if (validate.length == 0) {
                    component._handleChange('loading_text', 'Saving')

                    component._handleChange('loading', true)
                    addEvent(data, component.props.token).then((response) => {
                        component._handleChange('loading', false)
                        if (response.code == API_SUCCESS) {
                            if (schedule) {
                                resolve(response.data.post.id)
                            } else {
                                toastMessage('success', response.message)
                                component.loadCalendar()
                                resolve(true)
                            }
                        } else {
                            toastMessage('error', response.message)
                            resolve(false)
                        }
                    })
                }
            }
        })
    }
}

const AbstractCalendar = withTranslation()(AbstractCalendarComponent)

export { AbstractCalendar }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        isAlumni: state.isAlumni,
    }
}
