import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewSubjectType = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/subject-category/subject-type', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeSubjectType = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/subject-category/subject-type/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addSubjectType = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/subject-category/subject-type/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editSubjectType = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/subject-category/subject-type/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteSubjectType = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/subject-category/subject-type/delete/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleSubjectType = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/subject-category/subject-type/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSubjectType = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/subject-category/subject-type/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
