import React from 'react'
import { connect } from 'react-redux'
import { AbstractConstantComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as SVG from '~/assets/svg'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'

class ConstantComponent extends AbstractConstantComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="constant" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('constant:constant')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('constant:constant')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.constant.category.view)}
                                >
                                    <a href={route.admin.accounting.constant.category.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.constant.category.view} className="folder-name">
                                        {t('accounting:category')}
                                        <span> {t('constant:managecategory')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.constant.unit.view)}
                                >
                                    <a href={route.admin.accounting.constant.unit.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.constant.unit.view} className="folder-name">
                                        {t('course:unit')}
                                        <span> {t('constant:manageunit')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div
                                    className="folder-list clearfix"
                                    onClick={() => (window.location = route.admin.accounting.constant.tax.view)}
                                >
                                    <a href={route.admin.accounting.constant.tax.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.constant.tax.view} className="folder-name">
                                        {t('fee:tax')}
                                        <span> {t('constant:manageunit')}</span>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.constant.customfield.view)}>
                                    <a href={route.admin.accounting.constant.customfield.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.constant.customfield.view} className="folder-name">
                                        {t('constant:customfield')} 
                                        <span> {t('constant:managecategory')}</span>
                                    </a>
                                </div>
                            </div> */}

                            {/* <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.constant.contracttype.view)}>
                                    <a href={route.admin.accounting.constant.contracttype.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.constant.contracttype.view} className="folder-name">
                                        {t('constant:contracttype')} 
                                        <span> {t('constant:managecategory')}</span>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Constant = withTranslation()(connect(mapStateToProps)(ConstantComponent))

export { Constant }
