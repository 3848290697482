import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseManageComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as courseActionType from '~/helper/constant/course'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import Files from 'react-files'
import ReactPlayer from 'react-player/file'
import { withTranslation } from 'react-i18next'
import MultiImageInput from 'react-multiple-image-input'
import $ from 'jquery'
import moment from 'moment'

class CourseManageComponent extends AbstractCourseManageComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectActionType = this._selectActionType.bind(this)
        this.addAnswer = this.addAnswer.bind(this)
        this.updateData = this.updateData.bind(this)
        this.updateQuestion = this.updateQuestion.bind(this)
        this.updateAnswer = this.updateAnswer.bind(this)
        this.selectUnit = this.selectUnit.bind(this)
        this.setImages = this.setImages.bind(this)
        this.setNotificationMedium = this.setNotificationMedium.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        const component = this
        let inter = window.setInterval(function (t) {
            let player = document.getElementById('main_video')
            if (player) {
                component.setMaxTimeStamp(player.duration)
                // player.addEventListener('durationchange', () => {
                //     alert(player.duration)

                // })
                clearInterval(inter)
            }
        }, 1000)
    }

    setStartTime(data) {
        this._handleChange('start_time', data)
    }

    ref = (player) => {
        this.player = player
    }

    setImages(data) {
        super._handleChange('thumbnail', data)
    }

    selectUnit(data) {
        this.setState({
            selectUnit: data,
        })
    }

    setNotificationMedium(type) {
        if (type == 'email') {
            this.setState({
                emailNotification: !this.state.emailNotification,
            })
        } else {
            this.setState({
                smsNotification: !this.state.smsNotification,
            })
        }
    }

    updateData(index, key, value, type) {
        if (type == courseActionType.TIMELINE) {
            let timeline = this.state.timeline
            timeline[index][key] = value
            this.setState({
                timeline,
            })
        } else {
            let exam = this.state.exam
            exam[index][key] = value
            this.setState({
                exam,
            })
        }
    }

    updateQuestion(index, key, value) {
        let exam = this.state.exam
        exam[index]['questions'][0]['question']['0']['questions'] = value
        this.setState({
            exam,
        })
    }

    updateAnswer(index, key, value, answerIndex) {
        let exam = this.state.exam
        exam[index]['questions'][0]['question']['0']['answer'][answerIndex]['answers'] = value
        this.setState({
            exam,
        })
    }

    addAnswer(index, val) {
        let answers = this.state.answers
        let answer = this.state.answer
        const is_correct = index == 0 ? true : false
        answers[index] = { is_correct: is_correct, count: answers.length, answers: val }
        answer[index] = val
        this.setState({ answers, answer })
    }

    getVideoDuration() {
        if (this.state.maxtimestamp == null) {
            this.setState({
                maxtimestamp: parseInt(this.player.getDuration()),
            })
        }
    }

    seekVideoTo(duration) {
        // this.player.seekTo(duration)
        this.player.currentTime = duration
        // this.player.seekTo(parseFloat(duration))
        // this.setState(
        //     {
        //         isPlaying: true,
        //     },
        // )
    }

    _selectActionType(data) {
        this.setState({
            selectedActionType: data,
        })
    }

    getAddNotificationModal() {
        const { t } = this.props
        return (
            <div id="modal_notification_add" className="modal fade show collapse-body" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{(this.state.selectedIndex && t('course:edit_notification')) || t('course:add_notification')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <h6>{t('course:before_notifications')} </h6>
                                <div className="add-option-exam pl-0 pr-0 clearfix">
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:before')} *</label>
                                        <Input name="notification_time" onChange={this._handleChange} placeholder={t('course:notification_time')} value={this.state.notification_time} min={1} type="number" />
                                        {this.state.notification_meta_errors.notification_time && <label className="error text-danger">{this.state.notification_meta_errors.notification_time}</label>}
                                    </div>
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:unit')} *</label>
                                        <Select name="class" options={this.state.unit} placeholder={t('course:unit')} searchable={true} value={this.state.selectUnit} onChange={this.selectUnit} />
                                    </div>
                                </div>
                                <h6>{t('course:notification_medium')}: </h6>
                                <div className="add-option-exam">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <div className="form-check">
                                                <Checkbox onChange={() => this.setNotificationMedium('email')} value={this.state.emailNotification} />
                                                <label className="form-check-label form-title" htmlFor="showEm">
                                                    {t('course:email')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.smsNotificationEnabled && (
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <div className="form-check">
                                                    <Checkbox onChange={() => this.setNotificationMedium('sms')} value={this.state.smsNotification} />
                                                    <label className="form-check-label form-title" htmlFor="showEm">
                                                        {t('course:sms')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.saveNotification} type="button" text={this.state.selectedIndex ? t('common:updateBtn') : t('common:addBtn')} main={true} />
                                <button
                                    id="close_add_notif"
                                    type="button"
                                    className="cancel-button"
                                    onClick={() => {
                                        this.setState({
                                            notification_meta_errors: {
                                                notification_time: null,
                                            },
                                            notification_time: 0,
                                        })
                                        $('.modal').modal('hide')
                                    }}
                                >
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_course_meta_add" className="modal fade show collapse-body" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('course:add_timeline')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:hours')} *</label>
                                        <Input name="hours" min="0" onChange={this._handleChange} placeholder={t('course:hours')} type="number" value={this.state.hours} />
                                        {this.state.course_meta_errors.hours && <label className="error text-danger">{this.state.course_meta_errors.hours}</label>}
                                    </div>
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:minutes')} *</label>
                                        <Input name="minutes" min="0" onChange={this._handleChange} placeholder={t('course:minutes')} type="number" value={this.state.minutes} />
                                        {this.state.course_meta_errors.minutes && <label className="error text-danger">{this.state.course_meta_errors.minutes}</label>}
                                    </div>
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:seconds')} *</label>
                                        <Input name="seconds" min="0" onChange={this._handleChange} placeholder={t('course:seconds')} type="number" value={this.state.seconds} />
                                        {this.state.course_meta_errors.seconds && <label className="error text-danger">{this.state.course_meta_errors.seconds}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('course:description')} *</label>
                                        <TextArea id="description" name="description" onChange={this._handleChange} placeholder={t('course:description')} type="text" value={this.state.description} />
                                        {this.state.descriptionError && <label className="error text-danger">{this.state.descriptionError}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.saveCourseMeta} type="button" text={this.state.selectedModule ? t('common:updateBtn') : t('common:addBtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getAddQuizModal() {
        const { t } = this.props
        let hours = parseInt(this.state.maxtimestamp / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        let minutes = parseInt((this.state.maxtimestamp % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        let seconds = parseInt(this.state.maxtimestamp % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        return (
            <div id="modal_course_meta_addQ" className="modal fade show collapse-body" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('course:add_quiz')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:hours')} *</label>
                                        <Input name="hours" onChange={this._handleChange} placeholder={t('course:hours')} type="number" min="0" value={this.state.hours} />
                                        {this.state.course_meta_errors.hours && <label className="error text-danger">{this.state.course_meta_errors.hours}</label>}
                                    </div>
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:minutes')} *</label>
                                        <Input name="minutes" onChange={this._handleChange} placeholder={t('course:minutes')} type="number" min="0" value={this.state.minutes} />
                                        {this.state.course_meta_errors.minutes && <label className="error text-danger">{this.state.course_meta_errors.minutes}</label>}
                                    </div>
                                    <div className="col-sm-4 float-left form-group">
                                        <label className="form-title">{t('course:seconds')} *</label>
                                        <Input name="seconds" onChange={this._handleChange} placeholder={t('course:seconds')} type="number" min="0" value={this.state.seconds} />
                                        {this.state.course_meta_errors.seconds && <label className="error text-danger">{this.state.course_meta_errors.seconds}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('course:question')} *</label>
                                            <Input name="question" onChange={this._handleChange} placeholder={t('course:question')} type="text" value={this.state.question} />
                                            {this.state.questionError && <label className="error text-danger">{this.state.questionError}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('course:correct_answer')} *</label>
                                            <Input name="answer[0]" onChange={(e, val) => this.addAnswer(0, val)} placeholder={t('course:correct_answer')} type="text" value={this.state.answer[0]} />
                                            {this.state.answerError[0] && <label className="error text-danger">{this.state.answerError[0]}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('course:answer')} 2 *</label>
                                            <Input name="answer[1]" onChange={(e, val) => this.addAnswer(1, val)} placeholder={t('course:answer')} type="text" value={this.state.answer[1]} />
                                            {this.state.answerError[1] && <label className="error text-danger">{this.state.answerError[1]}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('course:answer')} 3 *</label>
                                            <Input name="answer[2]" onChange={(e, val) => this.addAnswer(2, val)} placeholder={t('course:answer')} type="text" value={this.state.answer[2]} />
                                            {this.state.answerError[2] && <label className="error text-danger">{this.state.answerError[2]}</label>}
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('course:answer')} 4 *</label>
                                            <Input name="answer[3]" onChange={(e, val) => this.addAnswer(3, val)} placeholder={t('course:answer')} type="text" value={this.state.answer[3]} />
                                            {this.state.answerError[3] && <label className="error text-danger">{this.state.answerError[3]}</label>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onSaveQuestion} type="button" text={this.state.selectedModule ? t('common:updateBtn') : t('common:addBtn')} main={true} />
                                <button id="close_addQ" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getDeleteModal() {
        const { t } = this.props
        return (
            <div id={'modal_course_meta_delete'} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')}!!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('course:meta_1')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <Button type="submit" text={t('common:DeleteBtn')} main={true} onClick={() => this.deleteMeta()} />
                            <button id={'close_media_modal'} type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getNotificationDeleteModal() {
        const { t } = this.props
        return (
            <div id={'modal_course_notification_delete'} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('course:notif_1')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <Button type="submit" text={t('common:DeleteBtn')} main={true} onClick={() => this.deleteNotification()} />
                            <button id={'close_notification_modal'} type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} &gt; </a>
                            </li>
                            <li>
                                {this.state.classes != null && <a href={route.course.view + '/' + this.state.classes.id}>{this.state.classes.name} &gt; </a>}
                                {this.state.classes == null && <a>{t('course:class')} &gt; </a>}
                            </li>
                            <li>
                                <a href={route.course.module + '/' + this.state.class_id + '/' + this.props.match.params.course_id}>{this.state.course_name}</a> &gt;{' '}
                            </li>

                            <li>{this.state.coursetitle}</li>
                        </ul>
                        {/* <div className="row clearfix">
                            <div className="col-md-4 float-left">
                                <h5 className="main-title">
                                    {this.state.course.title}
                                    <ins className="new-con">New</ins>
                                </h5>
                            </div>
                        </div> */}
                    </div>
                    <div className="video-manage clearfix">
                        <div className="role-pop-head">
                            <ul className="nav" id="myTab" role="tablist">
                                <li>
                                    <a className="active" id="content-tab" data-toggle="tab" href="#content" role="tab" aria-controls="content" aria-selected="true">
                                        {t('course:content')}
                                    </a>
                                </li>
                                <li className={(this.state.isVideoContent == 0 && 'hidden') || ''}>
                                    <a id="videoquiz-tab" data-toggle="tab" href="#videoquiz" role="tab" aria-controls="videoquiz" aria-selected="false">
                                        {t('course:video_quiz')}
                                    </a>
                                </li>
                                <li className={(this.state.isVideoContent == 0 && 'hidden') || ''}>
                                    <a id="videotimeline-tab" data-toggle="tab" href="#videotimeline" role="tab" aria-controls="videotimeline" aria-selected="false">
                                        {t('course:video_timeline')}
                                    </a>
                                </li>
                                <li>
                                    <a id="notification-tab" data-toggle="tab" href="#notification" role="tab" aria-controls="notification" aria-selected="false">
                                        {t('course:notifications')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="role-pop-content">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="content" role="tabpanel" aria-labelledby="content-tab">
                                    {this.state.media && (
                                        <form className="main-form video-comment">
                                            <div className="row clearfix">
                                                <div className="col-md-6 float-left form-group">
                                                    {/* <label className="form-title">{t('common:name')} *</label> */}
                                                    {/* <Input name="coursetitle" onChange={this._handleChange} placeholder={t('common:name')} type="text" value={this.state.coursetitle} />
                                                    {this.state.errors.coursetitle && <label className="error text-danger">{this.state.errors.coursetitle}</label>} */}
                                                    <h5 className="main-title heading5">{this.state.coursetitle}:</h5>
                                                </div>
                                            </div>
                                            <div className="clearfix video-content">
                                                <div className="col-md-3 module-img float-left module-vdo">{(this.state.media.media_type.includes('image') && <img src={this.state.media.media_url} alt="image" />) || (this.state.media.media_type.includes('audio') && <audio src={this.state.media_url} controls height={'auto'} width={'100%'} ref={this.ref} playing />) || <video id="main_video" src={this.state.media_url} controls pip playsinline height={'auto'} width={'100%'} ref={this.ref} />}</div>
                                                <div className="col-md-9 module-detail module-vdo-detail float-left clearfix">
                                                    <p>{this.state.media_name}</p>
                                                    <span>
                                                        {t('course:size')}: <ins>{this.state.media_size}</ins>
                                                    </span>
                                                    <span>
                                                        {t('course:date')}: <ins>{this.state.media_date}</ins>
                                                    </span>
                                                    <span>
                                                        {t('course:format')}: <ins>{this.state.media_format}</ins>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="clearfix video-button add-option-exam">
                                                <div className="row form-group clearfix">
                                                    <div className="col-md-6 col-sm-12 thumbnail-button">
                                                        <label className="form-title">
                                                            {t('course:start_date')} {this.state.courseModuleStartDate}*
                                                        </label>
                                                        <DatePicker showTimeSelect dateFormat="yyyy-MM-dd - hh:mm:ss a" className="form-control" placeholderText={t('course:start_date')} selected={this.state.start_time} onChange={this.setStartTime} minDate={moment(this.state.courseModuleStartDate).toDate()} maxDate={moment(this.state.courseEndDate).toDate()} />
                                                    </div>
                                                </div>
                                                <p>{t('course:course_thumbnail')}:</p>
                                                <div className="row clearfix">
                                                    {this.state.thumbnail_url && (
                                                        <div className="form-group thumbnail-image">
                                                            <img src={this.state.thumbnail_url} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="thumbnail-button">
                                                        <label className="form-title">{t('course:change_thumbnail')}:</label>
                                                        <MultiImageInput images={this.state.thumbnail} setImages={this.setImages} allowCrop={false} theme="light" max={1} />
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-6 col-sm-12 thumbnail-button">
                                                        <Button onClick={() => this.editCourse()} type="button" text={t('course:update_course')} className="main-button" main={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                                <div className="tab-pane fade" id="videoquiz" role="tabpanel" aria-labelledby="videoquiz-tab">
                                    <form className="main-form video-comment">
                                        <div className="text-right">
                                            <Button onClick={this.openAddCourseMetaQuiz} type="button" text={t('course:add_new')} className="second-button" />
                                        </div>
                                        {(this.state.exam &&
                                            this.state.exam.length > 0 &&
                                            this.state.exam.map((data, index) => {
                                                let hours = parseInt(data.timestamp / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
                                                let minutes = parseInt((data.timestamp % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
                                                let seconds = parseInt(data.timestamp % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
                                                return (
                                                    <div className="clearfix video-content quiz-content">
                                                        <div className="module-img course-module-img float-left">
                                                            {(this.state.thumbnail_url && <img src={this.state.thumbnail_url} alt="notification" />) || <img src="/assets/images/video.png" alt="notification" />}
                                                            <div className="set-timeline text-center clearfix">
                                                                <label>{t('course:time')}</label>
                                                                <div className="time-line">
                                                                    <Input onChange={(k, v) => this.updateData(index, 'hours', v, courseActionType.QUIZ)} className="time-line form-control" placeholder="Hr" value={data.hours} />
                                                                    <Input onChange={(k, v) => this.updateData(index, 'minutes', v, courseActionType.QUIZ)} className="time-line form-control" placeholder="Min" value={data.minutes} />
                                                                    <Input onChange={(k, v) => this.updateData(index, 'seconds', v, courseActionType.QUIZ)} className="time-line form-control" placeholder="Sec" value={data.seconds} />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="text-center">
                                                                <Button onClick={() => this.updateCourseMeta(data.id, courseActionType.QUIZ, index, data.post_child)} type="button" text={t('common:updateBtn')} className="main-button mt-0" main={false} />
                                                                <Button onClick={() => this.openDeleteMeta(data.id)} type="button" text={t('common:DeleteBtn')} className="second-button" main={false} />
                                                            </div>
                                                        </div>
                                                        <div className="module-detail course-module-detail float-left clearfix">
                                                            <div className="form-group">{<input type="text" onChange={(k, v) => this.updateQuestion(index, 'Question', v)} className="form-control" id="heading" placeholder={t('course:typeQuestionMsg')} value={data.questions[0].question[0].questions} readOnly={true} />}</div>

                                                            <div className="exam-question">
                                                                {data.questions[0].question[0].answer.map((item, i) => {
                                                                    if (item.answers) {
                                                                        return (
                                                                            <div className="exam-question-list">
                                                                                <label htmlFor="asd" className={item.is_correct == 1 ? 'if-upload-image correct' : 'if-upload-image'}>
                                                                                    <input onChange={(k, v) => this.updateAnswer(index, 'Answer', v, i)} type="text" className="form-control" placeholder="" value={item.answers} readOnly={true} />
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })) || (
                                            <p className="no-data">
                                                {SVG.nodata()}
                                                {t('course:no_quiz_added')}.
                                            </p>
                                        )}
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="videotimeline" role="tabpanel" aria-labelledby="videotimeline-tab">
                                    <div className="main-form video-comment">
                                        <div className="text-right">
                                            <Button onClick={this.openAddCourseMeta} type="button" text={t('course:add_new')} className="second-button" />
                                        </div>
                                        <br />
                                        <div className="subject-course-list">
                                            <div className="course-list-progress">
                                                {(this.state.timeline &&
                                                    this.state.timeline.length > 0 &&
                                                    this.state.timeline.map((data, index) => {
                                                        return (
                                                            <div className="clearfix video-content course-video mt-0">
                                                                <div className="col-md-3 module-img course-module-img float-left">{(this.state.thumbnail_url && <img src={this.state.thumbnail_url} alt="notification" />) || <img src="/assets/images/video.png" alt="notification" />}</div>
                                                                <div className="col-md-9 module-detail course-module-detail float-left clearfix">
                                                                    <div className="timeline-wrap">
                                                                        <div className="set-timeline clearfix">
                                                                            <label>{t('course:time')}</label>
                                                                            <div className="time-line">
                                                                                <Input type="number" min="0" onChange={(k, v) => this.updateData(index, 'hours', v, courseActionType.TIMELINE)} className="time-line form-control" placeholder="Hr" value={data.hours} />
                                                                                <Input type="number" min="0" onChange={(k, v) => this.updateData(index, 'minutes', v, courseActionType.TIMELINE)} className="time-line form-control" placeholder="Min" value={data.minutes} />
                                                                                <Input type="number" min="0" onChange={(k, v) => this.updateData(index, 'seconds', v, courseActionType.TIMELINE)} className="time-line form-control" placeholder="Sec" value={data.seconds} />
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <div className="form-group">
                                                                            <Input onChange={(k, v) => this.updateData(index, 'Description', v, courseActionType.TIMELINE)} type="text" className="form-control" id="heading" value={data.Description} />
                                                                            {this.state.descriptionUpdateError[index] && <label className="error text-danger">{this.state.descriptionUpdateError[index]}</label>}
                                                                        </div>
                                                                        <Button onClick={() => this.updateCourseMeta(data.id, courseActionType.TIMELINE, index, -1)} type="button" text={t('common:updateBtn')} className="main-button mt-0" main={true} />
                                                                        <Button onClick={() => this.openDeleteMeta(data.id)} type="button" text={t('common:DeleteBtn')} className="second-button" main={false} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })) || (
                                                    <p className="no-data">
                                                        {SVG.nodata()}
                                                        {t('course:no_timeline_added')} .
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notification-tab">
                                    <div className="video-comment">
                                        <div className="text-right">
                                            <Button onClick={this.openNotificationModel} type="button" text={t('course:add_new')} className="second-button" />
                                        </div>
                                        <br />
                                        <div className="row clearfix">
                                            <div className="col-sm-12">
                                                <Table data={this.state.notification_table} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.getAddModal()}
                {this.getAddQuizModal()}
                {this.getAddNotificationModal()}
                {this.getDeleteModal()}
                {this.getNotificationDeleteModal()}
            </div>
        )
    }
}
const CourseManage = withTranslation()(connect(mapStateToProps)(CourseManageComponent))
export { CourseManage }
