import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCouponEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Loading } from '~/components/loader'
class CouponEditComponent extends AbstractCouponEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setPackage = this.setPackage.bind(this)
    }

    setPackage(data) {
        super._handleChange('package_id', data.value)
        super._handleChange('defaultPackage', data)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="coupon" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.coupon.view}>Coupons &gt; </a>
                            </li>
                            <li>Edit</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Edit Coupon:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Days *</label>
                                    {this.state.school && <Input name="days" disabled="disabled" placeholder="Days"  value={this.state.days} />}
                                    {!this.state.school && <Input name="days" onChange={this._handleChange} placeholder="Days"  value={this.state.days} />}
                                    {this.state.errors.days && <label className="error text-danger">{this.state.errors.days}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">Package *</label>
                                    {this.state.school && <Input name="package_id" disabled="disabled" placeholder="Packages" type="text" value={this.state.defaultPackage.label} />}
                                    {!this.state.school && <Select name="package_id" options={this.state.packages} placeholder="Packages" searchable={true} value={this.state.defaultPackage} onChange={this.setPackage} />}
                                    {this.state.errors.package_id && <label className="error text-danger">{this.state.errors.package_id}</label>}
                                </div>
                            </div>
                            {this.state.school && (
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">Assigned to *</label>
                                        <Input name="school" disabled="disabled" placeholder="School" type="text" value={this.state.school.name + ' - ' + this.state.school.short_code} />
                                    </div>
                                </div>
                            )}
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="button" text="Update" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const CouponEdit = connect(mapStateToProps)(CouponEditComponent)

export { CouponEdit }
