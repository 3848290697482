import React from 'react'
import * as AccessModule from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import * as AdminComponent from '~/views/admin'
import * as SharedComponent from '~/views/shared'
import { StudentRoute } from './guard'

export const Student = () => [
    <StudentRoute
        exact
        key="1"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.TIMETABLE}
        path={configRoute(route.student.timetable)}
        component={SharedComponent.Timetable}
    />,

    <StudentRoute exact key="2" path={configRoute(route.student.my_circle)} component={SharedComponent.MyCircle} />,

    /* EXAM */
    <StudentRoute
        exact
        key="3"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.RESULT}
        path={configRoute(route.examination.result.result + '/:id')}
        component={SharedComponent.StudentResult}
    />,

    /* Course */

    <StudentRoute
        exact
        key="4"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.student.view)}
        component={SharedComponent.CourseViewStudent}
    />,
    <StudentRoute
        exact
        key="5"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.student.module + '/:id' + '/:course_id')}
        component={SharedComponent.CourseModuleStudent}
    />,
    <StudentRoute
        exact
        key="6"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.student.content + '/:course_id' + '/:module_id')}
        component={SharedComponent.CourseContentStudent}
    />,
    <StudentRoute
        exact
        key="7"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(
            route.course.student.details + '/:course_id' + '/:module_id' + '/:course_content_id' + '/:videotime?'
        )}
        component={SharedComponent.CourseContentDetailsStudent}
    />,
    // <StudentRoute
    //     exact
    //     key="8"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.student_fee.menu)}
    //     component={SharedComponent.StudentFeeMenu}
    // />,
    // <StudentRoute
    //     exact
    //     key="9"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.student_fee.upcoming)}
    //     component={SharedComponent.FeeViewStudent}
    // />,
    // <StudentRoute
    //     exact
    //     key="10"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.student_fee.statement.search)}
    //     component={SharedComponent.StudentSearchStatement}
    // />,
    // <StudentRoute
    //     exact
    //     key="8"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.student_fee.statement.view + '/:from_date/:to_date')}
    //     component={SharedComponent.StudentStatement}
    // />,
    // <StudentRoute
    //     exact
    //     key="9"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.student_fee.statement.detail + '/:user_id/:bill_id?')}
    //     component={AdminComponent.StatementDetail}
    // />,

    <StudentRoute
        exact
        key="8"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.student.fee.view)}
        component={AdminComponent.InvoiceView}
    />,
    <StudentRoute
        exact
        key="9"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.student.fee.detail + '/:id')}
        component={AdminComponent.InvoiceDetails}
    />,
]
