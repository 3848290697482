import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Table } from '~/components/table'
import * as Status from '~/helper/constant/status'
// import { configRoute, convertADtoBS, getInvoiceId } from '../../../../../../helper/function/abstract'
import { AbstractBookCategoryDetailsComponent, mapStateToProps } from './abstract'

class BookCategoryDetailsComponent extends AbstractBookCategoryDetailsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }

    }

 

    render() {
        const { t } = this.props
        return <Table data={this.state.tableStructure} />
    }
}

const BookCategoryDetails = withTranslation()(connect(mapStateToProps)(BookCategoryDetailsComponent))
export { BookCategoryDetails }
