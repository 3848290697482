import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseInsightsUserQuizComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
class CourseInsightsUserQuizComponent extends AbstractCourseInsightsUserQuizComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getInfoModal = this.getInfoModal.bind(this)
    }

    getInfoModal() {
        const { t } = this.props
        const modal = this.state.course_details.map((item, index) => {
            return (
                <div key={index} id={'modal_info_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('course:course_info')} : </span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <table data-testid="table" className="table table-bordered responsiveTable">
                                    <thead data-testid="thead">
                                        <tr data-testid="tr">
                                            <th data-testid="th">
                                                <span className="look-down">{t('course:sno')}</span>
                                            </th>
                                            <th data-testid="th">
                                                <span className="look-down">{t('course:course')}</span>
                                            </th>
                                            <th data-testid="th">
                                                <span className="look-down">{t('course:user_progress')}</span>
                                            </th>
                                            <th data-testid="th">
                                                <span className="look-down">{t('course:feedback')}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody data-testid="tbody">
                                        {item.course_progress.map((obj, i) => {
                                            return (
                                                <tr data-testid="tr">
                                                    <td data-testid="td" className="clearfix pivoted">
                                                        <div data-testid="td-before" className="tdBefore">
                                                            <span className="look-down">{t('course:sno')}</span>
                                                        </div>
                                                        {i + 1}
                                                    </td>
                                                    <td data-testid="td" className="clearfix pivoted">
                                                        <div data-testid="td-before" className="tdBefore">
                                                            <span className="look-down">{t('course:student')}</span>
                                                        </div>
                                                        {obj.content}
                                                    </td>
                                                    <td data-testid="td" className="clearfix pivoted">
                                                        <div data-testid="td-before" className="tdBefore">
                                                            <span className="look-down">{t('course:user_progress')}</span>
                                                        </div>
                                                        {obj.user_progress}
                                                    </td>
                                                    <td data-testid="td" className="clearfix pivoted">
                                                        <div data-testid="td-before" className="tdBefore">
                                                            <span className="look-down">{t('common:Info')}</span>
                                                        </div>
                                                        {obj.feedback}
                                                    </td>
                                                </tr>
                                            )
                                        }) || t('course:user_progress_msg')}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.course.insights + '/' + this.state.class_id + '/' + this.state.course_id}>{this.state.courseModule.course != null ? this.state.courseModule.course.post.title : 'Course'}</a> &gt;{' '}
                            </li>
                            <li>
                                <a href={route.course.insights_details + '/' + this.state.class_id + '/' + this.state.course_id}>{this.state.courseModule.name} &gt; </a>
                            </li>
                            <li>{t('course:insight_details')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{t('course:insight')}</span>
                                <h5 className="main-title heading5">{this.state.courseModule.name}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-head-option">
                        <b>{t('course:totalCorrect')}:</b> {this.state.correctCount} / {this.state.totalCount}
                    </div>
                    {this.state.insight_details.length > 0 && <Table data={this.state.insight_details} />}
                    {this.getInfoModal()}
                </div>
            </div>
        )
    }
}

const CourseInsightsUserQuiz = withTranslation()(connect(mapStateToProps)(CourseInsightsUserQuizComponent))

export { CourseInsightsUserQuiz }
