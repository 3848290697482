import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractInviteComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { Dashboard } from '~/components/dashboard'
import { Post } from '~/components/post'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import * as ReactTable from 'react-super-responsive-table'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { Fragment } from 'react'
import { Button, Checkbox, Input } from '~/components/form'
import { getMediaIcon } from '~/helper/function'
import * as UserType from '~/helper/constant/user_type'
import moment from 'moment'
import { download } from '../../../../assets/svg/general/general'
import TagsInput from 'react-tagsinput'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'
class InviteViewComponent extends AbstractInviteComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setUserType = this._setUserType.bind(this)
        this._setClass = this._setClass.bind(this)
        this._setSection = this._setSection.bind(this)
        this._setRole = this._setRole.bind(this)
        this._setUsers = this._setUsers.bind(this)
        this.setEmailError = this.setEmailError.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    setEmailError() {
        let errors = this.state.errors
        errors.users = 'Invalid Email'
        this.setState({
            errors,
        })
    }

    _setUserType(data) {
        this.setState(
            {
                selectedUserType: data,
                user_type: data.value,
                selectedClass: null,
                class: null,
                selectedSection: null,
                section: null,
                selectedRole: null,
                role: null,
            },
            () => {}
        )
    }

    _setClass(data) {
        this.setState(
            {
                selectedClass: data,
                class: data.value,
            },
            () => {
                this.loadSection(this.state.selectedClass.value)
            }
        )
    }

    _setSection(data) {
        this.setState(
            {
                selectedSection: data,
                section: data.value,
            },
            () => {}
        )
    }

    _setRole(data) {
        this.setState({
            selectedRole: data,
            role: data.value,
        })
    }

    _setUsers(users) {
        let errors = this.state.errors
        errors.users = null
        this.setState({
            users,
            errors,
        })
    }

    sendInviteModal() {
        const { t } = this.props
        return (
            <div id="send_invitation" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            x
                        </button>
                        <span> {t('invite:sendInvite')} </span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title">{t('attendance:userType')} *</label>
                                        <Select name="user_type" options={UserType.invite_role} placeholder={t('attendance:userType')} searchable={true} value={this.state.selectedUserType} onChange={this._setUserType} />
                                        {this.state.errors.user_type && <label className="error text-danger">{this.state.errors.user_type}</label>}
                                    </div>
                                </div>
                                {this.state.user_type == UserType.Student && (
                                    <Fragment>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('classes:classTitle')} *</label>
                                                <Select name="selctedClass" options={this.state.classList} placeholder={t('classes:classTitle')} searchable={true} value={this.state.selectedClass} onChange={this._setClass} />
                                                {this.state.errors.class && <label className="error text-danger">{this.state.errors.class}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('classes:section:title')} *</label>
                                                <Select name="selctedSection" options={this.state.sectionList} placeholder={t('classes:section:title')} searchable={true} value={this.state.selectedSection} onChange={this._setSection} />
                                                {this.state.errors.section && <label className="error text-danger">{this.state.errors.section}</label>}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {this.state.user_type == UserType.Custom && (
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('users:role:roleLabel')} *</label>
                                            <Select name="selectedRole" options={this.state.roleList} placeholder={t('users:role:roleLabel')} searchable={true} value={this.state.selectedRole} onChange={this._setRole} />
                                            {this.state.errors.role && <label className="error text-danger">{this.state.errors.role}</label>}
                                        </div>
                                    </div>
                                )}
                                {this.state.user_type == UserType.Student && (
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title">{t('invite:parent_detail')} </label>
                                        <Checkbox id="isParent" onChange={this._handleChange} className="" name="isParent" value={this.state.isParent} />
                                    </div>
                                )}
                                {this.state.user_type != UserType.Alumni && (
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title"> {t('invite:allow_userCategory')} </label>
                                        <Checkbox id="isCategory" onChange={this._handleChange} className="" name="isCategory" value={this.state.isCategory} />
                                    </div>
                                )}

                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group main-select">
                                        <label className="form-title">{t('common:email')} *</label>
                                        <TagsInput
                                            preventSubmit={true}
                                            validationRegex={/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i}
                                            onlyUnique={true}
                                            value={this.state.users}
                                            inputProps={{ placeholder: t('invite:addEmail') }}
                                            onChange={this._setUsers}
                                            onValidationReject={() => this.setEmailError()}
                                        />
                                        {this.state.errors.users && <label className="error text-danger">{this.state.errors.users}</label>}
                                        <div class="alert alert-primary mb-0 mt-2 d-inline-block p-2" role="alert">
                                            <small>{t('invite:anotherEmail')} .</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button type="button" text={t('chat:send')} main={true} onClick={() => this.sendInvitation()} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li></li>
                            <li>{t('invite:invite')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('invite:invite')} :</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a className="second-button" href={route.admin.setting.invite.approval.view} type="button">
                                        {t('invite:approval')}
                                    </a>
                                </div>
                                <div className="display-inline-block">
                                    <Button modal="send_invitation" type="button" text={t('invite:sendInvitation')} main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.category} />
                </div>
                <Fragment>{this.sendInviteModal()}</Fragment>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('setting:setting')} &gt; </a>
                            </li>
                            <li>{t('invite:invite')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('invite:invite')} :</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button modal="send_invitation" type="button" text={t('invite:sendInvitation')} main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.invites} />
                </div>
                <Fragment>{this.sendInviteModal()}</Fragment>
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'send_invitation') {
                component.setState({
                    selectedUserType: UserType.invite_role[1],
                    user_type: UserType.invite_role[1].value,
                    selectedClass: null,
                    class: null,
                    selectedSection: null,
                    section: null,
                    selectedRole: null,
                    role: null,
                    users: [],
                    isParent: false,
                    isCategory: false,
                })
            }
        })
    }
}

const InviteView = withTranslation()(connect(mapStateToProps)(InviteViewComponent))

export { InviteView }
