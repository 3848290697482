const alumniReducer = (state = false, action) => {
    switch (action.type) {
        case 'ALUMNI_PAGE':
            return true
        case 'SCHOOL_PAGE':
            return false
        case 'SIGN_OUT':
            return false
        case 'RESET':
            return false
        default:
            return state
    }
}

export default alumniReducer
