import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewClass = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/class-management/class', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeClass = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('class/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addClass = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/class-management/class/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getClass = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('class/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editClass = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/class-management/class/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleClass = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/class-management/class/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
