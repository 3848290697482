import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { Loading } from '../../../../../components/loader/loader'
import { convertADtoBS, getBillId } from '../../../../../helper/function/abstract'
import { AbstractBillSummaryComponent, mapStateToProps } from './abstract'
class BillSummaryComponent extends AbstractBillSummaryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setStartDate = this._setStartDate.bind(this)
        this._setEndDate = this._setEndDate.bind(this)
        this._setUser = this._setUser.bind(this)
        this._setStatus = this._setStatus.bind(this)
    }

    _setStartDate(data) {
        this._handleChange('start_month', data)
    }
    _setEndDate(data) {
        this._handleChange('end_month', data)
    }

    _setUser(data) {
        this._handleChange('user', data)
    }

    _setStatus(data) {
        this._handleChange('status', data)
    }

    getInvoices() {
        const data = this.state.invoices.map((item, index) => {
            const created_at = moment(item.created_at).format('yyyy-MM-DD')
            const customer = this.state.userList.find((cus) => cus.value == item.vender_id)
            const status = this.state.statusList.find((st) => st.value == item.status)
            return (
                <tr>
                    <td>{getBillId(item.bill_id)}</td>
                    <td>{this.props.school.date_format == 'BS' ? convertADtoBS(item.send_date) : item.send_date}</td>
                    <td>
                        {item.last_payments != null
                            ? this.props.school.date_format == 'BS'
                                ? convertADtoBS(item.last_payments.date)
                                : item.last_payments.date
                            : ''}
                    </td>
                    <td>{customer != null ? customer.label : ''}</td>
                    <td>{item.category != null ? item.category.name : ''}</td>
                    <td>{status != null ? status.label : ''}</td>
                    <td>
                        <small>{this.props.school.currency}</small>
                        &nbsp;{parseFloat(item.total_amount).toFixed(2)}
                    </td>
                    <td>
                        <small>{this.props.school.currency}</small>
                        &nbsp;{parseFloat(item.due_amount).toFixed(2)}
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="expense" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.expense.menu}>
                                    {t('accounting:expense')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('reports:billsummary')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('reports:billsummary')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={this.props.school.short_code + '-' + t('reports:billsummary')}
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="timetable-form mb-5">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.start date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="MMM yyyy"
                                                className="form-control"
                                                defaultValue={this.state.start_month}
                                                selected={this.state.start_month}
                                                onChange={this._setStartDate}
                                                showMonthYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.start_month != ''
                                                        ? convertADtoBS(this.state.start_month) != null
                                                            ? convertADtoBS(this.state.start_month).slice(0, -3) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.start_month && (
                                                <label className="error text-danger">
                                                    {this.state.errors.start_month}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.end date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.end date')}
                                                dateFormat="MMM yyyy"
                                                className="form-control"
                                                defaultValue={this.state.end_month}
                                                selected={this.state.end_month}
                                                onChange={this._setEndDate}
                                                showMonthYearPicker={true}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.end_month != ''
                                                        ? convertADtoBS(this.state.end_month) != null
                                                            ? convertADtoBS(this.state.end_month).slice(0, -3) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.end_month && (
                                                <label className="error text-danger">
                                                    {this.state.errors.end_month}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('gamification:user')} :</label>
                                            <Select
                                                name="user"
                                                options={this.state.userList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.user}
                                                onChange={this._setUser}
                                            />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('common:status')} :</label>
                                            <Select
                                                name="status"
                                                options={this.state.statusList}
                                                placeholder={t('common:select')}
                                                searchable={true}
                                                value={this.state.status}
                                                onChange={this._setStatus}
                                            />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <button className="main-button" onClick={() => this._onSearch()}>
                                                {t('attendance:filter')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={8} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('reports:billsummary')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={8} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border invoice_table_header_dark">
                                                            <td>
                                                                <strong>{t('income:invoice')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {t('activity:date')}
                                                                    {this.props.school.date_format == 'BS' && (
                                                                        <small>&nbsp;( B.S )</small>
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {t('accounting:payment')} {t('activity:date')}
                                                                    {this.props.school.date_format == 'BS' && (
                                                                        <small>&nbsp;( B.S )</small>
                                                                    )}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('income:customer')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('alumni:category')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>{t('common:status')}</strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {' '}
                                                                    {t('fee:total')} {t('income:amount')}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                <strong>
                                                                    {t('accounting:due')} {t('income:amount')}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        {this.state.invoices.length == 0 && (
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <p className="no-data">
                                                                        {SVG.nodata()}
                                                                        {t('common:table.nodata')}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {this.state.invoices.length > 0 && this.getInvoices()}
                                                        <tr className="invoice_total_border invoice_table_header_dark">
                                                            <td colSpan={6} className="text-right">
                                                                <strong>{t('accounting:total')} :</strong>{' '}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                            </td>
                                                            <td
                                                                className={
                                                                    this.state.totalAmount['balance'] < 0
                                                                        ? 'negative-amount'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;
                                                                <strong>
                                                                    {this.state.totalAmount['balance'] < 0
                                                                        ? parseFloat(
                                                                              this.state.totalAmount['balance'] * -1
                                                                          ).toFixed(2)
                                                                        : parseFloat(
                                                                              this.state.totalAmount['balance']
                                                                          ).toFixed(2)}
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className={
                                                                    this.state.totalAmount['due'] < 0
                                                                        ? 'negative-amount'
                                                                        : ''
                                                                }
                                                            >
                                                                <small>{this.props.school.currency}</small>
                                                                &nbsp;
                                                                <strong>
                                                                    {this.state.totalAmount['due'] < 0
                                                                        ? parseFloat(
                                                                              this.state.totalAmount['due'] * -1
                                                                          ).toFixed(2)
                                                                        : parseFloat(
                                                                              this.state.totalAmount['due']
                                                                          ).toFixed(2)}
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const BillSummary = withTranslation()(connect(mapStateToProps)(BillSummaryComponent))

export { BillSummary }
