import React, { Component } from 'react'
import { AbstractTextAreaComponent } from './abstract'

export class TextArea extends AbstractTextAreaComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    checkClassName() {
        if (this.props.className) {
            return this.props.className + ' form-control input-border'
        } else {
            return 'form-control input-border'
        }
    }

    render() {
        return <textarea onKeyDown={this._keyDown} rows={this.props.rows == null ? 5 : this.props.rows} id={this.props.id} onChange={this._onChange} name={this.props.name} placeholder={this.props.placeholder} style={this.props.style} autoComplete="off" value={this.state.value} disabled={this.props.disabled} className={this.checkClassName()} />
    }
}
