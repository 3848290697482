import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewSmsPackage = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('sms-package', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addSmsPackage = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('sms-package/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSmsPackage = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('sms-package/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editSmsPackage = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('sms-package/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleSmsPackage = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('sms-package/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
