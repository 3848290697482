import React, { Component, Fragment } from 'react'
import * as SVG from '~/assets/svg'
import { getMediaIcon } from '~/helper/function'

export class DriveFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            folderName: props.folderName,
            folderUrl: props.folderUrl,
            index: props.index,
            media_type: props.media_type,
        }
    }

    privacyIcon() {
        return <div className="privacy">{SVG.privacyicon()}</div>
    }

    render() {
        return (
            <div className="drive-list" onClick={() => window.open(this.props.folderUrl, '_blank')}>
                <a className="folder">{getMediaIcon(this.props.media_type)}</a>
                <div className="overflow-hidden">
                    <a className="drive-name">{this.props.folderName}</a>
                </div>
                {this.props.privacy && this.privacyIcon()}
            </div>
        )
    }
}
