import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { capitalize, underscoreToSpace } from '~/helper/function'
import { redirect } from '~/helper/function/util'

type Props = {
    dispatch: Dispatch<any>,
    data: object,
    history: Object,
    onPress: Object,
}

type State = {
    keys: object,
    search: string,
    sort: string,
    order: string,
}

export class AbstractTableComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            message: this.props.message != null ? this.props.message : null,
            keys: this.props.data && this.props.data.length > 0 ? this.props.data[0] : null,
            sortData: [],
        }
        this.textCapitalize = this.textCapitalize.bind(this)
        this.changeUnderScore = this.changeUnderScore.bind(this)
        this.search = this.search.bind(this)
        this.changeOrderBy = this.changeOrderBy.bind(this)
        this.changeSortBy = this.changeSortBy.bind(this)
        this.route = this.route.bind(this)
    }

    route(link) {
        redirect(link, this.props.history)
    }

    textCapitalize(str) {
        return capitalize(str)
    }

    changeUnderScore(str) {
        return underscoreToSpace(str)
    }

    search(key, value) {
        this.setState({ search: value })
    }

    changeOrderBy(order) {
        this.setState({ order })
    }

    changeSortBy(sort) {
        this.setState({ sort })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
    }
}
