import React from 'react'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import * as AdminComponent from '~/views/admin'
import * as AlumniComponent from '~/views/alumni'
import * as SharedComponent from '~/views/shared'
import * as AccessModule from '../../helper/constant/modules'
import { AdminRoute } from './guard'

export const Admin = () => [
    /* ACADEMIC YEAR  */

    <AdminRoute exact key="1" path={configRoute(route.admin.setup)} component={AdminComponent.Setup} />,

    <AdminRoute
        exact
        key="2"
        subAccess={AccessModule.ACADEMIC_YEAR}
        path={configRoute(route.admin.academic.view)}
        component={AdminComponent.AcademicView}
    />,

    <AdminRoute
        exact
        key="3"
        subAccess={AccessModule.ACADEMIC_YEAR}
        path={configRoute(route.admin.academic.add)}
        component={AdminComponent.AcademicAdd}
    />,

    <AdminRoute
        exact
        key="4"
        subAccess={AccessModule.ACADEMIC_YEAR}
        path={configRoute(route.admin.academic.edit) + '/:id'}
        component={AdminComponent.AcademicEdit}
    />,

    <AdminRoute
        exact
        key="5"
        subAccess={AccessModule.ACADEMIC_YEAR}
        path={configRoute(route.admin.academic.promote)}
        component={AdminComponent.PromoteStudent}
    />,

    /* SCHOOL  */

    <AdminRoute
        exact
        key="6"
        access={[AccessModule.Module_School_Management]}
        path={configRoute(route.admin.school.menu)}
        component={AdminComponent.School}
    />,

    <AdminRoute
        exact
        key="7"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.CLASS_MANAGEMENT}
        path={configRoute(route.admin.school.classes.view)}
        component={AdminComponent.ClassView}
    />,

    <AdminRoute
        exact
        key="8"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.CLASS_MANAGEMENT}
        path={configRoute(route.admin.school.classes.add)}
        component={AdminComponent.ClassAdd}
    />,

    <AdminRoute
        exact
        key="9"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.CLASS_MANAGEMENT}
        path={configRoute(route.admin.school.classes.edit) + '/:id'}
        component={AdminComponent.ClassEdit}
    />,

    <AdminRoute
        exact
        key="10"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SECTION}
        path={configRoute(route.admin.school.section.view) + '/:id'}
        component={AdminComponent.Section}
    />,

    <AdminRoute
        exact
        key="11"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT}
        path={configRoute(route.admin.school.subject.menu)}
        component={AdminComponent.SubjectMenu}
    />,

    <AdminRoute
        exact
        key="12"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT}
        path={configRoute(route.admin.school.subject.view) + '/:id'}
        component={AdminComponent.SubjectView}
    />,

    <AdminRoute
        exact
        key="13"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT}
        path={configRoute(route.admin.school.subject.add) + '/:id'}
        component={AdminComponent.SubjectAdd}
    />,

    <AdminRoute
        exact
        key="14"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT}
        path={configRoute(route.admin.school.subject.edit) + '/:id/:class_id'}
        component={AdminComponent.SubjectEdit}
    />,

    <AdminRoute
        exact
        key="15"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT_CATEGORY}
        path={configRoute(route.admin.school.subject_type.view)}
        component={AdminComponent.SubjectType}
    />,

    <AdminRoute
        exact
        key="16"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT_CATEGORY}
        path={configRoute(route.admin.school.subject_type.add)}
        component={AdminComponent.SubjectTypeAdd}
    />,

    <AdminRoute
        exact
        key="17"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.SUBJECT_CATEGORY}
        path={configRoute(route.admin.school.subject_type.edit) + '/:id'}
        component={AdminComponent.SubjectTypeEdit}
    />,

    /* USER  */

    <AdminRoute
        exact
        key="18"
        access={[AccessModule.Module_Student_Management, AccessModule.Module_Employee_Management]}
        path={configRoute(route.admin.user.menu)}
        component={AdminComponent.User}
    />,

    <AdminRoute
        exact
        key="19"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT_CATEGORY}
        path={configRoute(route.admin.user.student.category.view)}
        component={AdminComponent.StudentCategoryView}
    />,

    <AdminRoute
        exact
        key="20"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT_CATEGORY}
        path={configRoute(route.admin.user.student.category.add)}
        component={AdminComponent.StudentCategoryAdd}
    />,

    <AdminRoute
        exact
        key="21"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT_CATEGORY}
        path={configRoute(route.admin.user.student.category.edit) + '/:id'}
        component={AdminComponent.StudentCategoryEdit}
    />,

    <AdminRoute
        exact
        key="22"
        access={[AccessModule.Module_Student_Management]}
        path={configRoute(route.admin.user.student.menu)}
        component={AdminComponent.StudentMenu}
    />,

    <AdminRoute
        exact
        key="23"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT}
        path={configRoute(route.admin.user.student.view) + '/:section_id'}
        component={AdminComponent.StudentView}
    />,

    <AdminRoute
        exact
        key="24"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT}
        path={configRoute(route.admin.user.student.add) + '/:section_id'}
        component={AdminComponent.StudentAdd}
    />,

    <AdminRoute
        exact
        key="25"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT}
        path={configRoute(route.admin.user.student.edit) + '/:section_id/:student_id'}
        component={AdminComponent.StudentEdit}
    />,

    <AdminRoute
        exact
        key="26"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT}
        path={configRoute(route.admin.user.parent.view) + '/:section_id/:student_id'}
        component={AdminComponent.Parent}
    />,

    <AdminRoute
        exact
        key="27"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT}
        path={configRoute(route.admin.user.transfer)}
        component={AdminComponent.SectionTransfer}
    />,

    <AdminRoute
        exact
        key="28"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE_CATEGORY}
        path={configRoute(route.admin.user.employee.category.view)}
        component={AdminComponent.EmployeeCategoryView}
    />,

    <AdminRoute
        exact
        key="29"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE_CATEGORY}
        path={configRoute(route.admin.user.employee.category.add)}
        component={AdminComponent.EmployeeCategoryAdd}
    />,

    <AdminRoute
        exact
        key="30"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE_CATEGORY}
        path={configRoute(route.admin.user.employee.category.edit) + '/:id'}
        component={AdminComponent.EmployeeCategoryEdit}
    />,

    <AdminRoute
        exact
        key="31"
        access={[AccessModule.Module_Employee_Management]}
        path={configRoute(route.admin.user.employee.menu)}
        component={AdminComponent.EmployeeMenu}
    />,

    <AdminRoute
        exact
        key="32"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE}
        path={configRoute(route.admin.user.employee.view) + '/:user_type/:role_id'}
        component={AdminComponent.EmployeeView}
    />,

    <AdminRoute
        exact
        key="33"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE}
        path={configRoute(route.admin.user.employee.add) + '/:user_type/:role_id'}
        component={AdminComponent.EmployeeAdd}
    />,

    <AdminRoute
        exact
        key="34"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE}
        path={configRoute(route.admin.user.employee.edit) + '/:id/:user_type/:role_id'}
        component={AdminComponent.EmployeeEdit}
    />,

    <AdminRoute
        exact
        key="35"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.ROLE_MANAGEMENT}
        path={configRoute(route.admin.user.role.view)}
        component={AdminComponent.RoleView}
    />,

    <AdminRoute
        exact
        key="36"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.ROLE_MANAGEMENT}
        path={configRoute(route.admin.user.role.add)}
        component={AdminComponent.RoleAdd}
    />,

    <AdminRoute
        exact
        key="37"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.ROLE_MANAGEMENT}
        path={configRoute(route.admin.user.role.edit + '/:id')}
        component={AdminComponent.RoleEdit}
    />,

    /* ATTENDANCE  */

    // <AdminRoute exact  key="0" access={[AccessModule.Module_Student_Management, AccessModule.Module_Employee_Management]} path={configRoute(route.admin.attendance.menu)} component={AdminComponent.Attendance} />,

    <AdminRoute
        exact
        key="38"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT_ATTENDANCE}
        path={configRoute(route.admin.attendance.student.menu)}
        component={AdminComponent.StudentAtttendanceMenu}
    />,

    <AdminRoute
        exact
        key="39"
        access={[AccessModule.Module_Student_Management]}
        subAccess={AccessModule.STUDENT_ATTENDANCE}
        path={configRoute(route.admin.attendance.student.view + '/:attendance_type/:section_id/:subject_id/:date')}
        component={AdminComponent.StudentAttendanceView}
    />,

    <AdminRoute
        exact
        key="40"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE_ATTENDANCE}
        path={configRoute(route.admin.attendance.employee.menu)}
        component={AdminComponent.EmployeeAttendanceMenu}
    />,

    <AdminRoute
        exact
        key="41"
        access={[AccessModule.Module_Employee_Management]}
        subAccess={AccessModule.EMPLOYEE_ATTENDANCE}
        path={configRoute(route.admin.attendance.employee.view + '/:user_type/:role_id/:date')}
        component={AdminComponent.EmployeeAttendanceView}
    />,

    /* TIMETABLE  */

    <AdminRoute
        exact
        key="42"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.TIMETABLE}
        path={configRoute(route.admin.timetable.menu)}
        component={AdminComponent.TimetableMenu}
    />,

    <AdminRoute
        exact
        key="43"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.TIMETABLE}
        path={configRoute(route.admin.timetable.view + '/:id')}
        component={AdminComponent.TimetableView}
    />,

    <AdminRoute
        exact
        key="44"
        access={[AccessModule.Module_School_Management]}
        subAccess={AccessModule.TIMETABLE}
        path={configRoute(route.admin.timetable.add + '/:id')}
        component={AdminComponent.TimetableAdd}
    />,

    /* SETTING  */

    <AdminRoute exact key="45" path={configRoute(route.admin.setting.menu)} component={AdminComponent.Setting} />,

    <AdminRoute
        exact
        key="46"
        access={[AccessModule.Module_Notification]}
        subAccess={AccessModule.NOTIFICATION}
        path={configRoute(route.admin.setting.notification.view)}
        component={AdminComponent.NotificationView}
    />,

    <AdminRoute
        exact
        key="47"
        access={[AccessModule.Module_Notification]}
        subAccess={AccessModule.NOTIFICATION}
        path={configRoute(route.admin.setting.notification.edit + '/:id')}
        component={AdminComponent.NotificationEdit}
    />,

    <AdminRoute
        exact
        key="48"
        path={configRoute(route.admin.setting.subscription.view)}
        component={AdminComponent.Subscription}
    />,

    <AdminRoute
        exact
        key="49"
        access={[AccessModule.Module_Invite]}
        subAccess={AccessModule.INVITE}
        path={configRoute(route.admin.setting.invite.view)}
        component={AdminComponent.InviteView}
    />,

    <AdminRoute
        exact
        key="50"
        access={[AccessModule.Module_Invite]}
        subAccess={AccessModule.INVITE}
        path={configRoute(route.admin.setting.invite.approval.view + '/:id')}
        component={AdminComponent.InviteApproval}
    />,

    /* Fee  */
    // <AdminRoute
    //     exact
    //     key="51"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.menu)}
    //     component={AdminComponent.FeeMenu}
    // />,
    // <AdminRoute
    //     exact
    //     key="52"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.particulars.view)}
    //     component={AdminComponent.FeeParticulars}
    // />,
    // <AdminRoute
    //     exact
    //     key="53"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.particulars.child.view + '/:paernt_id')}
    //     component={AdminComponent.FeeParticularsChild}
    // />,
    // <AdminRoute exact key="54" access={[AccessModule.Module_Accounting]} subAccess={AccessModule.FEE} path={configRoute(route.admin.accounting.fee.feesetup.view)} component={AdminComponent.FeeSetup} />,
    // <AdminRoute exact key="55" access={[AccessModule.Module_Accounting]} subAccess={AccessModule.FEE} path={configRoute(route.admin.accounting.fee.feesetup.edit + '/:id')} component={AdminComponent.FeeSetupEdit} />,
    // <AdminRoute exact key="56" access={[AccessModule.Module_Accounting]} subAccess={AccessModule.FEE} path={configRoute(route.admin.accounting.fee.feesetup.preview + '/:id')} component={AdminComponent.FeeSetupPreview} />,
    // <AdminRoute
    //     exact
    //     key="54"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.discount.view)}
    //     component={AdminComponent.Discount}
    // />,
    // <AdminRoute
    //     exact
    //     key="55"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.discount.add)}
    //     component={AdminComponent.DiscountAdd}
    // />,
    // <AdminRoute
    //     exact
    //     key="56"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.discount.edit + '/:id')}
    //     component={AdminComponent.DiscountEdit}
    // />,
    // <AdminRoute
    //     exact
    //     key="57"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.discount.student + '/:id')}
    //     component={AdminComponent.DiscountStudent}
    // />,
    // <AdminRoute
    //     exact
    //     key="58"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.tax.view)}
    //     component={AdminComponent.Tax}
    // />,
    // <AdminRoute
    //     exact
    //     key="59"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.tax.add)}
    //     component={AdminComponent.TaxAdd}
    // />,
    // <AdminRoute
    //     exact
    //     key="60"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.tax.edit + '/:id')}
    //     component={AdminComponent.TaxEdit}
    // />,
    // <AdminRoute
    //     exact
    //     key="61"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.fine.view)}
    //     component={AdminComponent.Fine}
    // />,
    // <AdminRoute
    //     exact
    //     key="62"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.fine.add)}
    //     component={AdminComponent.FineAdd}
    // />,
    // <AdminRoute
    //     exact
    //     key="63"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.fine.edit + '/:id')}
    //     component={AdminComponent.FineEdit}
    // />,
    // <AdminRoute
    //     exact
    //     key="64"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.schedule.view)}
    //     component={AdminComponent.BillSchedule}
    // />,
    // <AdminRoute
    //     exact
    //     key="65"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.schedule.add)}
    //     component={AdminComponent.BillScheduleAdd}
    // />,
    // <AdminRoute
    //     exact
    //     key="66"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.schedule.edit + '/:id')}
    //     component={AdminComponent.BillScheduleEdit}
    // />,
    // <AdminRoute
    //     exact
    //     key="67"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.feecollect.view + '/:class_id/:user_id')}
    //     component={AdminComponent.FeeCollect}
    // />,
    // <AdminRoute
    //     exact
    //     key="68"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.feecollect.detail + '/:class_id/:user_id/:bill_id?')}
    //     component={AdminComponent.FeeCollectDetail}
    // />,
    // <AdminRoute
    //     exact
    //     key="69"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.feecollect.search)}
    //     component={AdminComponent.FeeCollectSearch}
    // />,
    // <AdminRoute
    //     exact
    //     key="70"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.statement.search)}
    //     component={AdminComponent.Statement}
    // />,
    // <AdminRoute
    //     exact
    //     key="71"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.statement.view + '/:user_id/:from_date/:to_date')}
    //     component={AdminComponent.StatementView}
    // />,
    // <AdminRoute
    //     exact
    //     key="72"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.FEE}
    //     path={configRoute(route.admin.accounting.fee.statement.detail + '/:user_id/:bill_id?')}
    //     component={AdminComponent.StatementDetail}
    // />,

    /* Alumni */
    <AdminRoute
        exact
        key="73"
        access={[AccessModule.Module_Alumni]}
        subAccess={AccessModule.ALUMNI}
        path={configRoute(route.admin.alumni.category.menu)}
        component={AlumniComponent.AlumniCategory}
    />,
    <AdminRoute
        exact
        key="74"
        access={[AccessModule.Module_Alumni]}
        subAccess={AccessModule.ALUMNI}
        path={configRoute(route.admin.alumni.category.view + '/:type')}
        component={AlumniComponent.AlumniCategoryView}
    />,
    <AdminRoute
        exact
        key="75"
        access={[AccessModule.Module_Alumni]}
        subAccess={AccessModule.ALUMNI}
        path={configRoute(route.admin.alumni.category.add + '/:type')}
        component={AlumniComponent.AlumniCategoryAdd}
    />,
    <AdminRoute
        exact
        key="76"
        access={[AccessModule.Module_Alumni]}
        subAccess={AccessModule.ALUMNI}
        path={configRoute(route.admin.alumni.category.edit + '/:type/:id')}
        component={AlumniComponent.AlumniCategoryEdit}
    />,

    /* Report */
    <AdminRoute
        exact
        key="77"
        access={[AccessModule.Module_Report]}
        subAccess={AccessModule.MODERATE_POST}
        path={configRoute(route.admin.report.view)}
        component={AdminComponent.PostReportView}
    />,

    /* Gamification */
    <AdminRoute
        exact
        key="78"
        access={[AccessModule.Module_Gamification]}
        subAccess={AccessModule.GAMIFICATION}
        path={configRoute(route.admin.setting.gamification.view)}
        component={AdminComponent.GamificationView}
    />,
    <AdminRoute
        exact
        key="79"
        access={[AccessModule.Module_Gamification]}
        subAccess={AccessModule.GAMIFICATION}
        path={configRoute(route.admin.setting.gamification.add)}
        component={AdminComponent.GamificationAdd}
    />,
    <AdminRoute
        exact
        key="80"
        access={[AccessModule.Module_Gamification]}
        subAccess={AccessModule.GAMIFICATION}
        path={configRoute(route.admin.setting.gamification.edit + '/:id')}
        component={AdminComponent.GamificationEdit}
    />,

    /* Leaderboard */
    <AdminRoute
        exact
        key="81"
        access={[AccessModule.Module_Gamification]}
        subAccess={AccessModule.GAMIFICATION}
        path={configRoute(route.leaderboard.add)}
        component={SharedComponent.LeaderBoardAdd}
    />,

    /*Grading*/
    <AdminRoute
        exact
        key="82"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.GRADING_SETTING}
        path={configRoute(route.admin.setting.grading.view)}
        component={AdminComponent.GradingView}
    />,
    <AdminRoute
        exact
        key="83"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.GRADING_SETTING}
        path={configRoute(route.admin.setting.grading.add)}
        component={AdminComponent.GradingAdd}
    />,
    <AdminRoute
        exact
        key="84"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.GRADING_SETTING}
        path={configRoute(route.admin.setting.grading.edit + '/:id')}
        component={AdminComponent.GradingEdit}
    />,

    /*Report Card*/
    <AdminRoute
        exact
        key="85"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.admin.setting.reportcard.view)}
        component={AdminComponent.ReportCardView}
    />,
    <AdminRoute
        exact
        key="86"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.admin.setting.reportcard.add)}
        component={AdminComponent.ReportCardAdd}
    />,
    <AdminRoute
        exact
        key="87"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.admin.setting.reportcard.edit + '/:id')}
        component={AdminComponent.ReportCardEdit}
    />,
    <AdminRoute
        exact
        key="88"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.examination.result.reportcardmenu + '/:id')}
        component={SharedComponent.ReportCardMenu}
    />,

    <AdminRoute
        exact
        key="89"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.examination.result.reportcard + '/:examId' + '/:classId' + '/:sectionId')}
        component={SharedComponent.ReportCard}
    />,
    <AdminRoute
        exact
        key="90"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.REPORT_CARD}
        path={configRoute(route.examination.result.reportcardresult + '/:examId' + '/:studentId')}
        component={SharedComponent.reportCardResult}
    />,

    /* Account */
    <AdminRoute
        exact
        key="91"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.dashboard)}
        component={AdminComponent.AccountingDashboard}
    />,

    <AdminRoute
        exact
        key="92"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ASSET}
        path={configRoute(route.admin.accounting.asset.view)}
        component={AdminComponent.AssetView}
    />,
    <AdminRoute
        exact
        key="93"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ASSET}
        path={configRoute(route.admin.accounting.asset.add)}
        component={AdminComponent.AssetAdd}
    />,
    <AdminRoute
        exact
        key="94"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ASSET}
        path={configRoute(route.admin.accounting.asset.edit + '/:id')}
        component={AdminComponent.AssetEdit}
    />,
    <AdminRoute
        exact
        key="95"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.menu)}
        component={AdminComponent.Banking}
    />,
    <AdminRoute
        exact
        key="96"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.account.view)}
        component={AdminComponent.BankAccountView}
    />,
    <AdminRoute
        exact
        key="97"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.account.add)}
        component={AdminComponent.BankAccountAdd}
    />,
    <AdminRoute
        exact
        key="98"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.account.edit + '/:id')}
        component={AdminComponent.BankAccountEdit}
    />,

    <AdminRoute
        exact
        key="99"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.transfer.view)}
        component={AdminComponent.BankTransferView}
    />,
    <AdminRoute
        exact
        key="100"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.transfer.add)}
        component={AdminComponent.BankTransferAdd}
    />,
    <AdminRoute
        exact
        key="101"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.BANK}
        path={configRoute(route.admin.accounting.banking.transfer.edit + '/:id')}
        component={AdminComponent.BankTransferEdit}
    />,

    <AdminRoute
        exact
        key="102"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.PRODUCT}
        path={configRoute(route.admin.accounting.productservice.view)}
        component={AdminComponent.ProductServiceView}
    />,

    <AdminRoute
        exact
        key="103"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.PRODUCT}
        path={configRoute(route.admin.accounting.productservice.add)}
        component={AdminComponent.ProductServiceAdd}
    />,

    <AdminRoute
        exact
        key="104"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.PRODUCT}
        path={configRoute(route.admin.accounting.productservice.edit + '/:id')}
        component={AdminComponent.ProductServiceEdit}
    />,

    <AdminRoute
        exact
        key="105"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.menu)}
        component={AdminComponent.Constant}
    />,

    <AdminRoute
        exact
        key="106"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.category.view)}
        component={AdminComponent.ConstantCategoryView}
    />,

    <AdminRoute
        exact
        key="107"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.category.add)}
        component={AdminComponent.ConstantCategoryAdd}
    />,
    <AdminRoute
        exact
        key="108"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.category.edit + '/:id')}
        component={AdminComponent.ConstantCategoryEdit}
    />,
    <AdminRoute
        exact
        key="106"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.unit.view)}
        component={AdminComponent.ConstantUnitView}
    />,
    <AdminRoute
        exact
        key="107"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.unit.add)}
        component={AdminComponent.ConstantUnitAdd}
    />,
    <AdminRoute
        exact
        key="108"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.unit.edit + '/:id')}
        component={AdminComponent.ConstantUnitEdit}
    />,
    <AdminRoute
        exact
        key="109"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.contracttype.view)}
        component={AdminComponent.ConstantContractTypeView}
    />,
    <AdminRoute
        exact
        key="110"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.contracttype.add)}
        component={AdminComponent.ConstantContractTypeAdd}
    />,
    <AdminRoute
        exact
        key="111"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.contracttype.edit)}
        component={AdminComponent.ConstantContractTypeEdit}
    />,
    <AdminRoute
        exact
        key="112"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.customfield.view)}
        component={AdminComponent.ConstantCustomFieldView}
    />,
    <AdminRoute
        exact
        key="113"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.customfield.add)}
        component={AdminComponent.ConstantCustomFieldAdd}
    />,
    <AdminRoute
        exact
        key="114"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.customfield.edit)}
        component={AdminComponent.ConstantCustomFieldEdit}
    />,

    //Income
    <AdminRoute
        exact
        key="115"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.menu)}
        component={AdminComponent.Income}
    />,
    <AdminRoute
        exact
        key="116"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.invoice.view)}
        component={AdminComponent.InvoiceView}
    />,
    <AdminRoute
        exact
        key="117"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.invoice.add)}
        component={AdminComponent.InvoiceAdd}
    />,
    <AdminRoute
        exact
        key="118"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.invoice.edit + '/:id')}
        component={AdminComponent.InvoiceEdit}
    />,
    <AdminRoute
        exact
        key="119"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.invoice.details + '/:id')}
        component={AdminComponent.InvoiceDetails}
    />,
    <AdminRoute
        exact
        key="148"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.invoicesummary)}
        component={AdminComponent.InvoiceSummary}
    />,

    //Expense
    <AdminRoute
        exact
        key="122"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.expense.menu)}
        component={AdminComponent.Expense}
    />,
    <AdminRoute
        exact
        key="123"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.bill.view)}
        component={AdminComponent.BillView}
    />,
    <AdminRoute
        exact
        key="124"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.bill.add)}
        component={AdminComponent.BillAdd}
    />,

    <AdminRoute
        exact
        key="125"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.bill.edit + '/:id')}
        component={AdminComponent.BillEdit}
    />,
    <AdminRoute
        exact
        key="126"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.bill.details + '/:id')}
        component={AdminComponent.BillDetails}
    />,

    <AdminRoute
        exact
        key="149"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.billsummary)}
        component={AdminComponent.BillSummary}
    />,

    // //productstock
    // <AdminRoute
    //     exact
    //     key="129"
    //     access={[AccessModule.Module_Accounting]}
    //     subAccess={AccessModule.PRODUCT}
    //     path={configRoute(route.admin.accounting.productstock.menu)}
    //     component={AdminComponent.ProductStock}
    // />,

    //doubleenty
    <AdminRoute
        exact
        key="130"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.menu)}
        component={AdminComponent.DoubleEntry}
    />,

    <AdminRoute
        exact
        key="131"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.trialbalance)}
        component={AdminComponent.TrialBalance}
    />,

    <AdminRoute
        exact
        key="132"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.ledgersummary)}
        component={AdminComponent.LedgerSummary}
    />,

    <AdminRoute
        exact
        key="133"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.balancesheet)}
        component={AdminComponent.BalanceSheet}
    />,

    //journalaccount

    <AdminRoute
        exact
        key="134"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.journalaccount.view)}
        component={AdminComponent.JournalAccountView}
    />,
    <AdminRoute
        exact
        key="135"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.journalaccount.add)}
        component={AdminComponent.JournalAccountAdd}
    />,
    <AdminRoute
        exact
        key="136"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.journalaccount.invoice + '/:id')}
        component={AdminComponent.JournalAccountInvoice}
    />,

    //chart of account

    <AdminRoute
        exact
        key="137"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.chartofaccount.view)}
        component={AdminComponent.ChartAccountView}
    />,
    <AdminRoute
        exact
        key="138"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.chartofaccount.add)}
        component={AdminComponent.ChartAccountAdd}
    />,
    <AdminRoute
        exact
        key="139"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.DOUBLE_ENTRY}
        path={configRoute(route.admin.accounting.doubleentry.chartofaccount.edit + '/:id')}
        component={AdminComponent.ChartAccountEdit}
    />,

    //reports

    <AdminRoute
        exact
        key="140"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.menu)}
        component={AdminComponent.Reports}
    />,

    <AdminRoute
        exact
        key="141"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.transaction)}
        component={AdminComponent.Transaction}
    />,
    <AdminRoute
        exact
        key="143"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.incomesummary)}
        component={AdminComponent.IncomeSummary}
    />,
    <AdminRoute
        exact
        key="144"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.expensesummary)}
        component={AdminComponent.ExpenseSummary}
    />,
    <AdminRoute
        exact
        key="145"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.incomeexpense)}
        component={AdminComponent.IncomeExpenseSummary}
    />,
    <AdminRoute
        exact
        key="146"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.taxsummary)}
        component={AdminComponent.TaxSummary}
    />,
    <AdminRoute
        exact
        key="147"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.ACCOUNTING_REPORTS}
        path={configRoute(route.admin.accounting.reports.profitloss)}
        component={AdminComponent.ProfitLoss}
    />,

    // constant tax added later
    <AdminRoute
        exact
        key="151"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.tax.view)}
        component={AdminComponent.TaxAccount}
    />,
    <AdminRoute
        exact
        key="152"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.tax.add)}
        component={AdminComponent.AcccountTaxAdd}
    />,
    <AdminRoute
        exact
        key="153"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.CONSTANT}
        path={configRoute(route.admin.accounting.constant.tax.edit + '/:id')}
        component={AdminComponent.AccountTaxEdit}
    />,

    <AdminRoute
        exact
        key="154"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.template.view)}
        component={AdminComponent.BillTemplateView}
    />,
    <AdminRoute
        exact
        key="155"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.admin.accounting.expense.template.add)}
        component={AdminComponent.BillTemplateAdd}
    />,

    <AdminRoute
        exact
        key="156"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.expense.template.edit + '/:id')}
        component={AdminComponent.BillTemplateEdit}
    />,

    <AdminRoute
        exact
        key="157"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.template.view)}
        component={AdminComponent.InvoiceTemplateView}
    />,
    <AdminRoute
        exact
        key="158"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.template.add)}
        component={AdminComponent.InvoiceTemplateAdd}
    />,

    <AdminRoute
        exact
        key="159"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.INCOME}
        path={configRoute(route.admin.accounting.income.template.edit + '/:id')}
        component={AdminComponent.InvoiceTemplateEdit}
    />,

    /* Account */

    /****Library *****/
    <AdminRoute exact key="160" path={configRoute(route.admin.library.menu)} component={AdminComponent.Library} />,
    <AdminRoute
        exact
        key="162"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookcategory.view)}
        component={AdminComponent.BookCategoryView}
    />,
    <AdminRoute
        exact
        key="163"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookcategory.add)}
        component={AdminComponent.BookCategoryAdd}
    />,

    <AdminRoute
        exact
        key="164"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookcategory.edit + '/:id')}
        component={AdminComponent.BookCategoryEdit}
    />,

    <AdminRoute
        exact
        key="165"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookcategory.detail + '/:id')} 
        component={AdminComponent.BookCategoryDetails}
    />,

    <AdminRoute
        exact
        key="166"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.shelfmanagement.view)}
        component={AdminComponent.ShelfManagementView}
    />,
    <AdminRoute
        exact
        key="167"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.shelfmanagement.add)}
        component={AdminComponent.ShelfManagementAdd}
    />,

    <AdminRoute
        exact
        key="168"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.shelfmanagement.edit + '/:id')} 
        component={AdminComponent.ShelfManagementEdit}
    />,

    <AdminRoute
        exact
        key="169"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.shelfmanagement.detail + '/:id')} 
        component={AdminComponent.ShelfManagementDetails}
    />,


    <AdminRoute
        exact
        key="170"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.books.view)}
        component={AdminComponent.BooksView}
    />,
    <AdminRoute
        exact
        key="171"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.books.add)}
        component={AdminComponent.BooksAdd}
    />,

    <AdminRoute
        exact
        key="172"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.books.edit + '/:id')} 
        component={AdminComponent.BooksEdit}
    />,

    <AdminRoute
        exact
        key="173"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.books.detail + '/:id')}Library     component={AdminComponent.BooksDetails}
    />,


    <AdminRoute
        exact
        key="174"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.shelfmanagement.detail)} // later we have to add id
        component={AdminComponent.ShelfManagementDetails}
    />,


    <AdminRoute
        exact
        key="175"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookissue.view)}
        component={AdminComponent.BookIssueView}
    />,
    <AdminRoute
        exact
        key="176"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookissue.add)}
        component={AdminComponent.BookIssueAdd}
    />,

    <AdminRoute
        exact
        key="177"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookissue.edit + '/:id')} // later we have to add id
        component={AdminComponent.BookIssueEdit}
    />,

    <AdminRoute
        exact
        key="178"
        access={[AccessModule.Module_Library]}
        subAccess={AccessModule.LIBRARY}
        path={configRoute(route.admin.library.bookissue.detail + '/:id')} // later we have to add id
        component={AdminComponent.BookIssueDetails}
    />
    /**** End Library *****/

]
