import { get, post } from '../request'
import { accountingGet, accountingPost } from '../accountingRequest'

export const viewTax = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/tax', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addTax = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/tax/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editTax = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/tax/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getTax = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/tax/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleTax = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/tax/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}


export const viewAccountTax = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('tax', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addAccountTax = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('tax/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editAccountTax = (tax, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('tax/update/' + tax, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAccountTax = (tax, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('tax/edit/' + tax, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
