export default class SchoolDTO {
    id: integer
    name: String
    short_code: String
    subscription_date: String
    logo: String
    currency: String
    timezone: String
    country: String
    address: String
    domain: String
    sub_domain: String
    contact: String
    email: String
    package_id: integer
    status: integer
    code: String
    isOnlyApp: String
    country: String
    date_format: String

    constructor(id, name, short_code, subscription_date, logo, currency, status, code, timezone, address, domain, sub_domain, contact, email, package_id, isOnlyApp, country, date_format) {
        this.id = id
        this.short_code = short_code
        this.subscription_date = subscription_date
        this.logo = logo
        this.currency = currency
        this.status = status
        this.country = country
        this.status = status
        this.code = code
        this.timezone = timezone
        this.address = address
        this.domain = domain
        this.sub_domain = sub_domain
        this.contact = contact
        this.email = email
        this.package_id = package_id
        this.isOnlyApp = isOnlyApp
        this.date_format = date_format
    }
}
