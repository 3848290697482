import React, { Component } from 'react'
import { activeNotice } from '../../../services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { Dispatch } from 'react-redux'
import * as UserType from '~/helper/constant/user_type'

type Props = {
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractActivityComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            noticeList: [],
            viewerIsOpen: false,
            isChanged: false,
        }
    }

    componentDidMount() {
        if (this.props.school != null && this.props.school.user_type == UserType.Admin) {
            activeNotice(this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    this.setState({
                        noticeList: response.data.notice,
                    })
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
