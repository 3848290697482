import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractResultViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Loading } from '~/components/loader'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import $ from 'jquery'

class ResultViewComponent extends AbstractResultViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusModal = this.getStatusModal.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        console.log(link)
        $('#close_status_' + link).trigger('click')
        super.toggle(link)
    }

    getStatusModal() {
        const { t } = this.props
        const modal = this.state.result.map((item, index) => {
            let text = t('exam:draft')
            if (item.status != Status.ACTIVE) {
                text = t('exam:publish')
            }
            // const { t } = this.props
            return (
                <div key={index} id={'modal_status_' + item.id} data-id={'modal_status_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('classes:class.areyousureyouwantto')} {''}
                                    {text} {t('exam:result.categoryTitle')}?
                                </p>
                                <p className="delete-file-name">"{item.description}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button type="submit" text={t('updateBtn')} link={item.id} main={true} onClick={this.toggle} />
                                <button id={'close_status_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <div className="main-content">
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('exam:result.categoryTitle')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('exam:result.categoryTitle')}:</h5>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.tableData} message="exam:result.noResults" />
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && <Fragment>{this.getStatusModal()}</Fragment>}
            </div>
        )
    }
}

const ResultView = withTranslation()(connect(mapStateToProps)(ResultViewComponent))

export { ResultView }
