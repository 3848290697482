import React, { Component } from 'react'
import { Dispatch } from 'react-redux'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    id: String,
}

export class AbstractPostViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
        }
    }
}

export function mapStateToProps(state: Object) {
    return {}
}
