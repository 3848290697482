import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import moment from 'moment'
import React, { Fragment } from 'react'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Button, Checkbox, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { convertADtoBS } from '../../../../../helper/function'
import { AbstractStudentAttendanceViewComponent, mapStateToProps } from './abstract'
let mediaAry = []
let uploadedFiles = []
let extension = ''
let filesize = ''
class StudentAttendanceViewComponent extends AbstractStudentAttendanceViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.addRemarksModel = this.addRemarksModel.bind(this)
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this.updateStudentAttendance = this.updateStudentAttendance.bind(this)
        this.submitAttendance = this.submitAttendance.bind(this)
    }

    updateStudentAttendance(index) {
        super.updateStudentAttendance(index).then((response) => {
            if (response) {
                $('#modal_remarks_' + index).modal('hide')
            }
        })
    }

    submitAttendance() {
        super.submitAttendance().then((response) => {
            if (response) {
                $('#modal_review').modal('hide')
            }
        })
    }

    getBase64(file) {
        if (file) {
            return new Promise((resolve, reject) => {
                var mediaAryInner = {}
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function () {
                    var encodedFile = reader.result
                    mediaAryInner['name'] = file.name
                    mediaAryInner['data'] = encodedFile
                    mediaAry.push(mediaAryInner)
                }
                reader.onerror = function (error) {
                    alert('Error: ', error)
                    return false
                }
            })
        }
    }
    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }
    filesRemoveOne = (file, i) => {
        let fileAry = this.state.uploadedFiles[i]
        let deletedMedia = []
        if (fileAry == undefined) {
            fileAry = this.state.students
            const deletedFile = fileAry[i]['media'].id
            fileAry[i]['media'] = null
            deletedMedia.push(deletedFile)
            this.setState({
                students: fileAry,
                deletedMedia,
            })
        } else {
            const deleteIndex = fileAry.indexOf(file)
            fileAry.splice(deleteIndex, 1)
            mediaAry.splice(deleteIndex, 1)
            this.setState({ uploadedFiles: fileAry, media: mediaAry })
        }
    }
    selectFiles(i, data) {
        var uploadedFile = data[data.length - 1]
        var promise = Promise.resolve()
        promise.then(() => this.getBase64(uploadedFile))
        promise.then(() => {
            uploadedFiles[i] = data
            super.setState({ media: mediaAry, uploadedFiles })
        })
    }
    getStudentList() {
        const { t } = this.props
        const { search } = this.state

        const student = this.state.students.filter((item) => {
            if (search == null) {
                return true
            } else {
                if (Number.isInteger(search)) {
                    if (item.name != null) {
                        if (item.name.includes(search)) {
                            return true
                        }
                    }
                    if (item.reg_no.includes(search)) {
                        return true
                    }
                } else {
                    if (item.name != null) {
                        if (item.name.toLowerCase().includes(search.toLowerCase())) {
                            return true
                        }
                    }
                    if (item.reg_no.toLowerCase().includes(search.toLowerCase())) {
                        return true
                    }
                }
            }
        })
        if (student.length > 0) {
            return student.map((el, index) => {
                let initial = el.initial
                if (el.initial.length > 1) {
                    initial = <img src={el.initial} />
                }
                return (
                    <div key={index} className="col-lg-4 float-left access-status student-attendance">
                        <div className="security-list">
                            <div className="files-list-item-preview student-initial">
                                <div className="files-list-item-preview-extension">{initial}</div>
                            </div>
                            <label className="form-title">
                                {el.name} <br />{' '}
                                <span>
                                    {t('attendance:regNo')}: {el.reg_no}
                                </span>
                            </label>
                            <div className="tog-button">
                                <div className="button-check r" id="button-2">
                                    <Checkbox id={'checkbox-toggle-' + index} onChange={this.setAttendance.bind(this, index)} name={'user-attendance-checkbox-' + index} className="checkbox-form checkbox" value={el.status} />
                                    <div className="knobs attendance-toggle"></div>
                                </div>
                            </div>
                            {el.attendance_id != null && (
                                <div className="e-d-t">
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                            <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="1x" />
                                        </button>
                                        <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                            <button className="dropdown-item" data-toggle="modal" data-target={'#modal_remarks_' + index}>
                                                {t('attendance:remarks')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('attendance:no_student')}
                </p>
            )
        }
    }

    addReviewModal() {
        const { t } = this.props
        if (this.state.students != null) {
            const presents = this.state.students.filter((item) => item.status == true).length
            const absents = this.state.students.filter((item) => item.status == false).length
            return (
                <div id="modal_review" className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('alumni:review')}</span>
                        </div>

                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix mt-2">
                                    <div className="col-sm-6 float-left access-status student-attendance">
                                        <div className="security-list">
                                            <div className="files-list-item-preview">{SVG.attendanceAbsent()}</div>
                                            <label className="form-title information">
                                                <span>{t('attendance:totalAbsent')}</span>
                                                <h4>
                                                    {absents} / {this.state.students.length}
                                                </h4>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 float-left access-status student-attendance ">
                                        <div className="security-list">
                                            <div className="files-list-item-preview ">{SVG.attendancePresent()}</div>
                                            <label className="form-title information">
                                                <span>{t('attendance:totalPresent')}</span>
                                                <h4>
                                                    {presents} / {this.state.students.length}
                                                </h4>
                                            </label>
                                        </div>
                                    </div>
                                    {absents > 0 && (
                                        <Fragment>
                                            <div className="col-sm-12">
                                                {t('attendance:absentees')}
                                                <hr />
                                            </div>
                                            {this.state.students
                                                .filter((item) => item.status == false)
                                                .map((el, index) => {
                                                    let initial = el.initial
                                                    if (el.initial.length > 1) {
                                                        initial = <img src={el.initial} />
                                                    }
                                                    return (
                                                        <div className="col-sm-6 float-left access-status student-attendance">
                                                            <div className="security-list">
                                                                <div className="files-list-item-preview student-initial">
                                                                    <div className="files-list-item-preview-extension">{initial}</div>
                                                                </div>
                                                                <label className="form-title">
                                                                    {el.name} <br />
                                                                    <span>
                                                                        {t('attendance:regNo')}: {el.reg_no}
                                                                    </span>
                                                                </label>
                                                                <div className="tog-button">
                                                                    <div className="button-check r" id="button-2">
                                                                        <Checkbox disabled={true} className="checkbox-form checkbox" value={el.status} />
                                                                        <div className="knobs attendance-toggle"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="button" text={t('common:submit')} main={true} onClick={() => this.submitAttendance()} />
                                <button type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    addRemarksModel(i, data) {
        const { t } = this.props
        if (this.state.students) {
            const modal = this.state.students.map((item, index) => {
                if (item.media) {
                    this.extension = item.media.media_type.replace('data:application/', '')
                    this.extension = this.extension.replace('data:image/', '')
                    this.filesize = parseFloat(Math.abs(item.media.size) / (1024 * 1024)).toFixed(2)
                }
                return (
                    <div key={index} data-id={index} id={'modal_remarks_' + index} className="modal fade show" role="dialog">
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('attendance:add_remarks')}</span>
                            </div>
                            <br />
                            <form className="p-0">
                                <div className="modal-content">
                                    <div className="pop-main-content">
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <label className="form-title">{t('attendance:remarks')}</label>
                                                <TextArea id={'name_' + item.id} name="remarks" onChange={this._handleChange} placeholder={t('attendance:remarks')} type="text" value={item.remarks} />
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12">
                                                <div className="doclist sc-AxirZ sc-AxiKw lmMaya student-attendance-file">
                                                    {(item.media && (
                                                        <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                            <div className="gsEyTH files-list-item" key={item.media.id}>
                                                                <div className="files-list-item-preview">
                                                                    <div className="files-list-item-preview-extension">{this.extension}</div>
                                                                </div>
                                                                <div className="files-list-item-content">
                                                                    <div className="files-list-item-content-item files-list-item-content-item-1">{item.media.name}</div>
                                                                    <div className="files-list-item-content-item files-list-item-content-item-2">{this.filesize + ' MB'}</div>
                                                                </div>
                                                                <div
                                                                    id={item.media.id}
                                                                    className="files-list-item-remove"
                                                                    onClick={this.filesRemoveOne.bind(this, item.media, index)} // eslint-disable-line
                                                                />
                                                                <div className="files-list-item-view-x">
                                                                    <a target="_blank" href={item.media.media_url}>
                                                                        <FontAwesomeIcon className="icon" icon="eye" color="#333" size="1x" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) ||
                                                        (this.state.uploadedFiles[index] && this.state.uploadedFiles[index].length > 0 && (
                                                            <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                                <div className="gsEyTH files-list-item" key={this.state.uploadedFiles[index][0].id}>
                                                                    <div className="files-list-item-preview">
                                                                        <div className="files-list-item-preview-extension">{this.state.uploadedFiles[index][0].extension}</div>
                                                                    </div>
                                                                    <div className="files-list-item-content">
                                                                        <div className="files-list-item-content-item files-list-item-content-item-1">{this.state.uploadedFiles[index][0].name}</div>
                                                                        <div className="files-list-item-content-item files-list-item-content-item-2">{this.state.uploadedFiles[index][0].sizeReadable}</div>
                                                                    </div>
                                                                    <div
                                                                        id={this.state.uploadedFiles[index][0].id}
                                                                        className="files-list-item-remove"
                                                                        onClick={this.filesRemoveOne.bind(this, this.state.uploadedFiles[index][0], index)} // eslint-disable-line
                                                                    />
                                                                    {/* <a target="_blank" href={this.state.uploadedFiles[index] && this.state.uploadedFiles[index][0].preview.url}>
                                                                        <FontAwesomeIcon className="icon" icon="eye" color="#333" size="1x" />
                                                                    </a> */}
                                                                </div>
                                                            </div>
                                                        )) || (
                                                            <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                                <Files className="files-dropzone" onChange={this.selectFiles.bind(this, index)} onError={this.onFilesError.bind(this, index)} accepts={['.pdf', '.png', '.jpg', '.jpeg']} maxFiles={1} maxFileSize={10000000} minFileSize={0} clickable>
                                                                    {t('attendance:add_doc')}
                                                                </Files>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="text-center">
                                        <Button onClick={this.updateStudentAttendance.bind(this, index)} link={item.id} type="button" text={t('common:submit')} main={true} />
                                        <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('common:modal cancel')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            })
            return modal
        }
        return null
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loadingText} />
                <Sidebar id="attendance" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.attendance.menu}>{t('attendance:attendance')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.attendance.student.menu}>{t('attendance:student')} > </a>
                            </li>
                            <li>{t('common:viewBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('attendance:student_attendance')}:</h5>
                            </div>
                            <div className="col-md-7 float-right">
                                <form className="main-search float-right">
                                    <Input onChange={this._handleChange} name="search" placeholder={t('common:searchhere')} type="text" value={this.state.search} />
                                    <div className="search-icon">{SVG.tablesearch()}</div>
                                </form>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="calender calendar-page">
                        <h5 className="main-title text-center">
                            <span className="sub-main-title">{this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.date != null ? (convertADtoBS(this.state.date) != null ? convertADtoBS(this.state.date) : 'Invalid Date Range') : moment(this.state.date).format('Do MMM yyyy')}</span>
                            <br />
                            {this.state.subtitle}
                        </h5>
                    </div>
                    <br />
                    <div className="row clearfix">{this.getStudentList()}</div>
                    {this.state.students.filter((item) => {
                        if (this.state.search == null) {
                            return true
                        } else {
                            if (Number.isInteger(this.state.search)) {
                                if (item.name != null) {
                                    if (item.name.includes(this.state.search)) {
                                        return true
                                    }
                                }
                                if (item.reg_no.includes(this.state.search)) {
                                    return true
                                }
                            } else {
                                if (item.name != null) {
                                    if (item.name.toLowerCase().includes(this.state.search.toLowerCase())) {
                                        return true
                                    }
                                }
                                if (item.reg_no.toLowerCase().includes(this.state.search.toLowerCase())) {
                                    return true
                                }
                            }
                        }
                    }).length > 0 && (
                        <div className="form-button text-left">
                            <button type="button" className="main-button" data-toggle="modal" data-target="#modal_review">
                                {t('attendance:review')}
                            </button>
                        </div>
                    )}

                    {this.state.students && this.addRemarksModel()}
                    {this.state.students && this.addReviewModal()}
                </div>
            </div>
        )
    }
}
const StudentAttendanceView = withTranslation()(connect(mapStateToProps)(StudentAttendanceViewComponent))
export { StudentAttendanceView }
