import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractResetUserComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Post } from '~/components/post'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import { Button, Input } from '~/components/form'
import * as Status from '~/helper/constant/status'
import { gender } from '~/helper/constant/gender'
import { role_type } from '~/helper/constant/user_type'
import $ from 'jquery'

class ResetUserComponent extends AbstractResetUserComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this._search = this._search.bind(this)
        this._reset = this._reset.bind(this)
        this._toggle = this._toggle.bind(this)
    }

    _reset(e) {
        $('#close_reset').trigger('click')
        super._reset()
    }

    _toggle(e) {
        $('#close_status').trigger('click')
        super._toggle()
    }

    _search(e) {
        e.preventDefault()
        super._search()
    }

    toggleModal() {
        if (this.state.user) {
            let status = 'disable'
            if (this.state.user.status == Status.INACTIVE) {
                status = 'enable'
            }
            return (
                <div id={'modal_status'} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to {status} the user?</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._toggle} type="button" text="Confirm" main={true} />
                                <button id={'close_status'} type="upload" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getDeleteModal() {
        if (this.state.user) {
            return (
                <div id={'modal_reset'} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to reset the user password?</p>
                                <p className="delete-file-name">New Password : {this.state.user.dob.replaceAll('-', '')}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._reset} type="button" text="Confirm" main={true} />
                                <button id={'close_reset'} type="upload" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.reset}>Reset User</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Reset User :</h5>
                            </div>
                            <div className="col-md-7 float-right">
                                <form className="main-search full-width">
                                    <div className="display-inline-block col-md-10 text-right">
                                        <form className="main-search mw-100">
                                            <Input onChange={this._handleChange} name="search" placeholder="Search Here" type="text" value={this.state.search} />
                                            <div className="search-icon">{SVG.tablesearch()}</div>
                                        </form>
                                    </div>
                                    <div className="display-inline-block col-md-2">
                                        <Button onClick={this._search} type="button" text="Search" main={true} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        {this.state.user == null && <p className="no-data">{SVG.nodata()}No user searched.</p>}
                        {this.state.user != null && (
                            <div className="folder-page">
                                <div className="row clearfix">
                                    <div className="col-md-3">
                                        <div className="row clearfix">
                                            <div className="float-left course-your col-md-12">
                                                <div className="folder-list clearfix">
                                                    {this.state.user.user_detail != null && this.state.user.user_detail.profile != null && <img src={this.state.user.user_detail.profile} />}
                                                    {this.state.user.user_detail != null && this.state.user.user_detail.profile == null && <div className="role-user-img superadmin">{this.state.user.name != null ? this.state.user.name.charAt(0) : 'C'}</div>}
                                                    {this.state.user.user_detail == null && <div className="role-user-img superadmin">{this.state.user.name != null ? this.state.user.name.charAt(0) : 'C'}</div>}
                                                </div>
                                            </div>
                                            <div className="float-left col-md-12">
                                                <div className="text-center form-group">
                                                    {this.state.user.status == Status.ACTIVE && (
                                                        <span data-toggle="modal" data-target="#modal_status" className="table-status cursor table-green">
                                                            Active
                                                        </span>
                                                    )}
                                                    {this.state.user.status == Status.INACTIVE && (
                                                        <span data-toggle="modal" data-target="#modal_status" className="table-status cursor table-red">
                                                            Inactive
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="float-left col-md-12">
                                                <div className="text-center form-group">
                                                    <Button type="button" text="Reset" modal="modal_reset" main={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="row clearfix">
                                            <div className="float-left course-your col-md-12">
                                                <div className="folder-list dashboard">
                                                    <div className="view-progress mt-0">
                                                        <h5>User Information :</h5>
                                                        <hr />
                                                        <div className="table table-responsive">
                                                            <table className="table-bordered dashboard">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">Registration No :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.reg_no}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Name :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.name}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Email :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.email}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">Contact :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.contact}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">DOB :</strong>
                                                                            <br />
                                                                            <br />

                                                                            {this.state.user.dob}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Gender :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null && gender.find((item) => item.value == this.state.user.user_detail.gender) != null && gender.find((item) => item.value == this.state.user.user_detail.gender).label}
                                                                            {this.state.user.user_detail == null && 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">Blood Group :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.blood_group : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Father Name :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.father_name : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Mother Name :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.mother_name : 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong className="user-title">Nationality :</strong>
                                                                            <br />
                                                                            <br />

                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.nationality : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Permanent Address :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.permanent_address : 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <strong className="user-title">Temporary Address :</strong>
                                                                            <br />
                                                                            <br />
                                                                            {this.state.user.user_detail != null ? this.state.user.user_detail.temporary_address : 'N/A'}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="float-left course-your col-md-12">
                                                <div className="folder-list dashboard">
                                                    <div className="view-progress mt-0">
                                                        <h5>User School :</h5>
                                                        <hr />
                                                        <div className="table table-responsive">
                                                            <table className="table-bordered dashboard">
                                                                {this.state.user.user_school.map((item, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.school != null ? item.school.name : 'Collabarate'}</td>
                                                                            <td className="text-right">{role_type.find((role) => role.value == item.user_type) != null && <span className="table-status table-green">{role_type.find((role) => role.value == item.user_type).label}</span>}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.getDeleteModal()}
                        {this.toggleModal()}
                    </div>
                </div>
            </div>
        )
    }
}

const ResetUser = connect(mapStateToProps)(ResetUserComponent)

export { ResetUser }
