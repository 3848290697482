import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { SuperAdminRoute } from './guard'
import * as SuperAdminComponent from '~/views/superadmin'

export const SuperAdmin = () => [
    <SuperAdminRoute exact key="1" path={configRoute(route.superadmin.dashboard)} component={SuperAdminComponent.SuperAdminDashboard} />,

    <SuperAdminRoute exact key="2" path={configRoute(route.superadmin.package.view)} component={SuperAdminComponent.PackageView} />,
    <SuperAdminRoute exact key="3" path={configRoute(route.superadmin.package.add)} component={SuperAdminComponent.PackageAdd} />,
    <SuperAdminRoute exact key="4" path={configRoute(route.superadmin.package.edit) + '/:id'} component={SuperAdminComponent.PackageEdit} />,

    <SuperAdminRoute exact key="5" path={configRoute(route.superadmin.sms_package.view)} component={SuperAdminComponent.SmsPackageView} />,
    <SuperAdminRoute exact key="6" path={configRoute(route.superadmin.sms_package.add)} component={SuperAdminComponent.SmsPackageAdd} />,
    <SuperAdminRoute exact key="7" path={configRoute(route.superadmin.sms_package.edit + '/:id')} component={SuperAdminComponent.SmsPackageEdit} />,

    <SuperAdminRoute exact key="8" path={configRoute(route.superadmin.school.view)} component={SuperAdminComponent.SuperAdminSchoolView} />,
    <SuperAdminRoute exact key="9" path={configRoute(route.superadmin.school.add)} component={SuperAdminComponent.SuperAdminSchoolAdd} />,
    <SuperAdminRoute exact key="10" path={configRoute(route.superadmin.school.edit + '/:id')} component={SuperAdminComponent.SuperAdminSchoolEdit} />,

    <SuperAdminRoute exact key="11" path={configRoute(route.superadmin.coupon.view)} component={SuperAdminComponent.CouponView} />,
    <SuperAdminRoute exact key="12" path={configRoute(route.superadmin.coupon.add)} component={SuperAdminComponent.CouponAdd} />,
    <SuperAdminRoute exact key="13" path={configRoute(route.superadmin.coupon.edit + '/:id')} component={SuperAdminComponent.CouponEdit} />,

    <SuperAdminRoute exact key="14" path={configRoute(route.superadmin.notice.view)} component={SuperAdminComponent.NoticeView} />,
    <SuperAdminRoute exact key="15" path={configRoute(route.superadmin.notice.add)} component={SuperAdminComponent.NoticeAdd} />,
    <SuperAdminRoute exact key="16" path={configRoute(route.superadmin.notice.edit + '/:id')} component={SuperAdminComponent.NoticeEdit} />,

    <SuperAdminRoute exact key="17" path={configRoute(route.superadmin.enquiries.view)} component={SuperAdminComponent.EnquiryView} />,

    <SuperAdminRoute exact key="18" path={configRoute(route.superadmin.reset)} component={SuperAdminComponent.ResetUser} />,
]
