import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractEmployeeEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea } from '~/components/form'
import MultiImageInput from 'react-multiple-image-input'
import { Loading } from '~/components/loader'
import Select from 'react-select'
import Files from 'react-files'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../../helper/function'

class EmployeeEditComponent extends AbstractEmployeeEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setDate = this.setDate.bind(this)
        this.setEmployeeCategory = this.setEmployeeCategory.bind(this)
        this.setJoinDate = this.setJoinDate.bind(this)
        this._selectUserType = this._selectUserType.bind(this)
        this._selectCustomRole = this._selectCustomRole.bind(this)
    }

    _selectUserType(data) {
        if (data.value == 1) {
            $('#custom-role').show()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                selectedRole: this.state.customRoleAry[0].value,
                defaultRole: this.state.customRoleAry[0],
                user_type: data.value,
                role_id: this.state.customRoleAry[0].value,
            })
        } else {
            $('#custom-role').hide()
            this.setState({
                selectedUserType: data.value,
                defaultUserType: data,
                user_type: data.value,
                role_id: '0',
                selectedRole: 0,
                defaultRole: null,
            })
        }
    }

    _selectCustomRole(data) {
        super._handleChange('selectedRole', data.value)
        super._handleChange('defaultRole', data)
        super._handleChange('role_id', data.value)
    }

    setEmployeeCategory(data) {
        super._handleChange('selectedEmployeCategory', data.value)
        super._handleChange('defaultEmployeCategory', data)
    }
    setDate(data) {
        super._handleChange('dob', data)
    }

    setJoinDate(data) {
        super._handleChange('joining_date', data)
    }

    render() {
        const { t } = this.props
        let role_id = 0
        if (this.state.role_id != null && this.state.role_id != '0' && this.state.role_id != 'null') {
            role_id = this.state.role_id
        }
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.user.employee.menu}>{t('employee:breadcrumb')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.user.employee.view + '/' + this.state.user_type + '/' + role_id}>{t('employee:employee.breadcrumb')} > </a>
                            </li>
                            <li>{t('employee:employee.edit.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('employee:employee.edit.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('employee:employee.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('employee:employee.add.namePlaceholder')} disabled={true} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('employee:employee.add.emailLabel')}</label>
                                    <Input name="email" onChange={this._handleChange} placeholder={t('employee:employee.add.emailLabel')} type="text" disabled={true} value={this.state.email} />
                                    {this.state.errors.email && <label className="error text-danger">{this.state.errors.email}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('employee:employee.add.dobLabel')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.dob} onChange={this.setDate} value={this.state.dob} placeholderText={t('employee:employee.add.dobLabel')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.dob != null ? (convertADtoBS(this.state.dob) != null ? convertADtoBS(this.state.dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.dob && <label className="error text-danger">{this.state.errors.dob}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('employee:employee.add.joinDateLabel')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.joining_date} onChange={this.setJoinDate} readOnly={true} value={this.state.joining_date} placeholderText={t('employee:employee.add.joinDateLabel')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.joining_date != null ? (convertADtoBS(this.state.joining_date) != null ? convertADtoBS(this.state.joining_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.joining_date && <label className="error text-danger">{this.state.errors.joining_date}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('employee:employee.add.employeeCategoryLabel')}</label>
                                    <Select name="employee_category" options={this.state.employee_category} placeholder={t('employee:employee.add.employeeCategoryPlaceholder')} searchable={true} value={this.state.defaultEmployeCategory && this.state.defaultEmployeCategory[0]} onChange={this.setEmployeeCategory} />
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('employee:employee.add.userTypeLabel')} *
                                    </label>
                                    <Select name="type" options={this.state.userTypeAry} placeholder={t('employee:employee.add.userTypeLabel')} searchable={true} value={this.state.defaultUserType} onChange={this._selectUserType} />
                                </div>
                            </div>
                            <div className="row clearfix" id="custom-role">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('users:role.roleLabel')} *
                                    </label>
                                    <Select name="role" options={this.state.customRoleAry} placeholder={t('users:role.roleLabel')} searchable={true} value={this.state.defaultRole} onChange={this._selectCustomRole} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const EmployeeEdit = withTranslation()(connect(mapStateToProps)(EmployeeEditComponent))

export { EmployeeEdit }
