import { Component } from 'react'
import { viewMedal, viewLeaderboard } from '~/services/api'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { durationList, limitList, user_role, group } from '~/helper/constant/leaderboard_filter'
import moment from 'moment'
import * as UserType from '~/helper/constant/user_type'
export class AbstractLeaderBoardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loaded: false,
            search: null,
            group: group[0],
            limit: limitList[2],
            duration: durationList[1],
            user_type: user_role[0],
            medal: {
                label: 'All',
                value: 0,
            },
            medalList: [
                {
                    label: 'All',
                    value: 0,
                },
            ],
            leaderBoardList: [],
            filteredLeaderBoardList: [],
        }
        this.search = this.search.bind(this)
        this.changeLimit = this.changeLimit.bind(this)
        this.changeDuration = this.changeDuration.bind(this)
        this.changeMedal = this.changeMedal.bind(this)
        this.changeRole = this.changeRole.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        if (
            this.props.school.user_type != UserType.Admin &&
            this.props.school.user_type != UserType.Custom &&
            this.props.school.user_type != UserType.Parents
        ) {
            const user_type = user_role.find((item) => item.value == this.props.school.user_type)
            this.setState(
                {
                    user_type,
                    group: this.props.school.user_type == UserType.Student ? group[2] : group[0],
                },
                () => {
                    this.loadMedal()
                }
            )
        } else if (this.props.school.user_type == UserType.Parents) {
            this.setState(
                {
                    user_type: user_role[2],
                    group: group[2],
                },
                () => {
                    this.loadMedal()
                }
            )
        } else {
            this.loadMedal()
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    search(key, value) {
        this.setState(
            {
                [key]: value,
            },
            () => {
                this.filterLeader()
            }
        )
    }

    filterLeader() {
        let search = this.state.search
        if (search == null || search == '') {
            this.setState({
                filteredLeaderBoardList: this.state.leaderBoardList,
            })
        } else {
            if (!Number.isInteger(search)) {
                search = this.state.search.toLowerCase()
            }
            const filteredLeaderBoardList = this.state.leaderBoardList.filter((item) => {
                if (item.name != null && item.name.toLowerCase().includes(search)) {
                    return true
                }
                if (item.reg_no.toLowerCase().includes(search)) {
                    return true
                }
            })
            this.setState({
                filteredLeaderBoardList,
            })
        }
    }

    changeGroup(group) {
        this.setState({ group }, () => {
            this.loadLeaderBoard()
        })
    }

    changeLimit(limit) {
        this.setState({ limit }, () => {
            this.loadLeaderBoard()
        })
    }

    changeDuration(duration) {
        this.setState({ duration }, () => {
            this.loadLeaderBoard()
        })
    }

    changeRole(user_type) {
        this.setState({ user_type }, () => {
            this.loadLeaderBoard()
        })
    }

    changeMedal(medal) {
        this.setState({ medal }, () => {
            this.loadLeaderBoard()
        })
    }

    loadMedal() {
        this._handleChange('loading', true)
        viewMedal(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const medalList = this.state.medalList
                response.data.medal.map((item) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    }
                    medalList.push(data)
                })
                this.setState(
                    {
                        medalList,
                    },
                    () => {
                        this.loadLeaderBoard()
                    }
                )
            }
        })
    }

    loadLeaderBoard() {
        this._handleChange('loading', true)
        let from_date = null
        let to_date = null
        if (this.state.duration.value == 1) {
            from_date = moment().startOf('month').format('yyyy-MM-DD')
            to_date = moment().endOf('month').format('yyyy-MM-DD')
        } else if (this.state.duration.value == 2) {
            from_date = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD')
            to_date = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD')
        } else if (this.state.duration.value == 3) {
            const last_year = moment().year() - 1
            from_date = last_year + '-01-01'
            to_date = last_year + '-12-31'
        }
        const data = {
            limit: this.state.limit.value,
            user_type: this.state.user_type.value != 'overall' ? this.state.user_type.value : null,
            medal_id: this.state.medal.value == 0 ? null : this.state.medal.value,
            group: this.state.group.value,
            from_date,
            to_date,
        }
        viewLeaderboard(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const leaderBoardList = []
                response.data.leaderboard.map((item, index) => {
                    const data = {
                        id: item.id,
                        user_id: item.user.id,
                        ranking: index + 1,
                        name: item.user.name,
                        medal: item.leaderboard_count,
                        reg_no: item.user.reg_no,
                        profile: item.user.user_detail != null ? item.user.user_detail.profile : null,
                        points: item.leaderboard_sum_points,
                    }
                    leaderBoardList.push(data)
                })
                this.setState(
                    {
                        leaderBoardList,
                        loaded: true,
                    },
                    () => {
                        this.search()
                    }
                )
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        student: state.student,
    }
}
