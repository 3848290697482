import { post, get } from '../request'

export const viewBookCategories = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-category', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        } 
    })
}

export const addBookCategories = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book-category/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}


export const editBookCategories = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-category/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const detailBookCategories = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-category/detail/' + id, token).then((response) => {
                console.log(response)
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBookCategories = (id, data, token)=>{
    return new Promise(function(resolve, reject){
        try{
            post('book-category/update/' + id, token, data).then((response)=>{
                resolve(response)
            })
        }catch($e){
            resolve(null)
        }

    })
}

export const toggleBookCategories = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-category/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeBookCategories = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-category/active/', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}