import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSingleChat } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { myCircle } from '~/services/api'
import * as UserType from '~/helper/constant/user_type'
import { Connection } from '~/services/socket'
import * as MessageType from '~/helper/constant/message_type'
import * as route from '~/helper/constant/route'

const socket = new Connection()
type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    users: Array,
    student: Array,
    employee: Array,
    loading: Boolean,
    searchText: String,
    list: String,
}

export class AbstractMyCircleComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            users: [],
            student: [],
            employee: [],
            loading: false,
            list: { label: 'List', value: 0 },
            searchText: '',
        }
        this._handleChange = this._handleChange.bind(this)
        this._search = this._search.bind(this)
        this.openChatRoom = this.openChatRoom.bind(this)
    }

    componentDidMount() {
        this.loadUser()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    openChatRoom(id) {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getSingleChat(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const chatRoom = response.data.chat
                const data = {
                    room: this.props.school.school.short_code,
                    data: chatRoom,
                    type: MessageType.CREATE_CHAT_ROOM,
                }
                socket.send(data)
                window.open(route.chat + '/' + chatRoom.id, '_blank')
            }
        })
    }

    _search(key, value) {
        this.setState(
            {
                search: value,
            },
            () => {
                let filter
                if (this.state.search != '') {
                    filter = this.state.users.filter((item) => {
                        const name = item.user.name != null ? item.user.name.toLowerCase() : null
                        const reg = item.user.reg_no.toLowerCase()
                        if (Number.isInteger(this.state.search)) {
                            if (name.includes(this.state.search) || reg.includes(this.state.search)) {
                                return true
                            }
                        } else {
                            if (name.includes(this.state.search.toLowerCase()) || reg.includes(this.state.search.toLowerCase())) {
                                return true
                            }
                        }
                    })
                } else {
                    filter = this.state.users
                }
                const student = filter.filter((item) => item.user_type == UserType.Student)
                const employee = filter.filter((item) => item.user_type != UserType.Student)
                this.setState({
                    student,
                    employee,
                })
            }
        )
    }

    loadUser() {
        this._handleChange('loading', true)
        myCircle(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const users = response.data.users
                const student = users.filter((item) => item.user_type == UserType.Student)
                const employee = users.filter((item) => item.user_type != UserType.Student)
                this.setState({
                    users,
                    student,
                    employee,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
