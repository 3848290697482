import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { AbstractAccountTaxComponent, mapStateToProps } from './abstract'


class TaxAccountComponent extends AbstractAccountTaxComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusToggleModal = this.getStatusToggleModal.bind(this)
    }

    getStatusToggleModal() {
        const { t } = this.props
        const modal = this.state.taxData.map((item, index) => {
            let text = t('fee:disable')
            if (item.status != Status.ACTIVE) {
                text = t('fee:enable')
            }
            return (
                <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('calendar:event.areyousureyouwantto')} {text + ' ' + item.name}?"
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button
                                    onClick={this.toggle}
                                    link={item.id}
                                    type="button"
                                    text={t('common:modal confirm')}
                                    main={true}
                                />
                                <button
                                    id={'close_' + item.id}
                                    type="upload"
                                    className="cancel-button"
                                    data-dismiss="modal"
                                >
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="constant" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.constant.menu}>{t('fee:fee')} > </a>
                            </li>
                            <li>{t('fee:tax')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title">{t('fee:tax_settings')}:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <a href={route.admin.accounting.constant.tax.add}>
                                    <Button type="button" text={t('common:addBtn')} main={true} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <Table data={this.state.taxStructure} />
                    </div>
                    {this.getStatusToggleModal()}
                </div>
            </div>
        )
    }
}

const TaxAccount = withTranslation()(connect(mapStateToProps)(TaxAccountComponent))

export { TaxAccount }
