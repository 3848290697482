import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSchoolComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'

class SchoolComponent extends AbstractSchoolComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('school:schoolTitle')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="folder-page">
                        <div className="row clearfix">
                            {AccessModule.checkSubAccess([AccessModule.CLASS_MANAGEMENT], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.school.classes.view)}>
                                        <a href={route.admin.school.classes.view} className="folder-img">
                                            {SVG.clases()}
                                        </a>
                                        <a href={route.admin.school.classes.view} className="folder-name">
                                            {/* Class */}
                                            {t('school:class.title')}
                                            <span> {t('school:class.subTitle')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {AccessModule.checkSubAccess([AccessModule.SUBJECT_CATEGORY], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.school.subject_type.view)}>
                                        <a href={route.admin.school.subject_type.view} className="folder-img">
                                            {SVG.subjecttype()}
                                        </a>
                                        <a href={route.admin.school.subject_type.view} className="folder-name">
                                            {t('school:subjectType.title')}
                                            <span>{t('school:subjectType.subTitle')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {AccessModule.checkSubAccess([AccessModule.SUBJECT], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.school.subject.menu)}>
                                        <a href={route.admin.school.subject.menu} className="folder-img">
                                            {SVG.subject()}
                                        </a>
                                        <a href={route.admin.school.subject.menu} className="folder-name">
                                            {t('school:subject.title')}

                                            <span>{t('school:subject.subTitle')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const School = withTranslation()(connect(mapStateToProps)(SchoolComponent))

export { School }
