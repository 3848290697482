import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import React, { Fragment } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import { Loading } from '../../../../components/loader/loader'
import { convertADtoBS, getBillId, getInvoiceId } from '../../../../helper/function/abstract'
import { AbstractAccountingDashboardComponent, mapStateToProps } from './abstract'
ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)
const cashflow_option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Cashflow',
        },
    },
}

const income_expense_option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Income vs Expense',
        },
    },
}

const status = ['Draft', 'Sent', 'Unpaid', 'Partialy Paid', 'Paid']
class AccountingDashboardComponent extends AbstractAccountingDashboardComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    getAccountDetail() {
        const { t } = this.props
        const { report } = this.state
        let i = -1
        if (report.bankAccountDetail.length > 0) {
            return (
                <div className="col-md-12">
                    <div class="todo-box">
                        <h4>{t('accounting:account_balance')}</h4>
                        <div class="box-content-list form-group">
                            {report.bankAccountDetail.map((item, index) => {
                                if (index == 5) {
                                    i = 0
                                } else {
                                    i++
                                }
                                const color = 'user-img name-color' + i
                                const name = item.bank_name.charAt(0)
                                return (
                                    <div class="circle-category-list">
                                        <div class={color}>
                                            <span class="name-letter">{name}</span>
                                        </div>
                                        <div class="circle-user">
                                            <h5 className={item.opening_balance < 0 ? 'negative-amount' : ''}>
                                                <small>{this.props.school.currency}</small> .&nbsp;
                                                {item.opening_balance < 0
                                                    ? parseFloat(item.opening_balance * -1).toFixed(2)
                                                    : parseFloat(item.opening_balance).toFixed(2)}
                                            </h5>
                                            <span>
                                                {item.bank_name} - {item.holder_name}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
    }
    getIncomeExpense() {
        const { t } = this.props
        const { incomeExpense } = this.state
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:income_expense')}</h4>
                    <div class="box-content-list form-group">
                        <div class="circle-category-list">
                            <div class="user-img bg-green2">
                                <span class="name-letter">I</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={incomeExpense.todayIncome < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {incomeExpense.todayIncome < 0
                                        ? parseFloat(incomeExpense.todayIncome * -1).toFixed(2)
                                        : parseFloat(incomeExpense.todayIncome).toFixed(2)}
                                </h5>
                                <span>{t('accounting:income_today')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img bg-pink1">
                                <span class="name-letter">I</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={incomeExpense.incomeCurrentMonth < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {incomeExpense.incomeCurrentMonth < 0
                                        ? parseFloat(incomeExpense.incomeCurrentMonth * -1).toFixed(2)
                                        : parseFloat(incomeExpense.incomeCurrentMonth).toFixed(2)}
                                </h5>
                                <span>{t('accounting:income_month')}</span>
                            </div>
                        </div>
                        <hr />
                        <div class="circle-category-list">
                            <div class="user-img bg-purple2">
                                <span class="name-letter">E</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={incomeExpense.todayExpense < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {incomeExpense.todayExpense < 0
                                        ? parseFloat(incomeExpense.todayExpense * -1).toFixed(2)
                                        : parseFloat(incomeExpense.todayExpense).toFixed(2)}
                                </h5>
                                <span>{t('accounting:expense_today')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img bg-orange1">
                                <span class="name-letter">E</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={incomeExpense.expenseCurrentMonth < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {incomeExpense.expenseCurrentMonth < 0
                                        ? parseFloat(incomeExpense.expenseCurrentMonth * -1).toFixed(2)
                                        : parseFloat(incomeExpense.expenseCurrentMonth).toFixed(2)}
                                </h5>
                                <span>{t('accounting:expense_month')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getWeeklyInvoiceStats() {
        const { t } = this.props
        const { report } = this.state
        const weeklyInvoice = report.weeklyInvoice
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:invoice_weekly')}</h4>
                    <div class="box-content-list form-group">
                        <div class="circle-category-list">
                            <div class="user-img name-color3">
                                <span class="name-letter">T</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyInvoice.invoiceTotal < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyInvoice.invoiceTotal < 0
                                        ? parseFloat(weeklyInvoice.invoiceTotal * -1).toFixed(2)
                                        : parseFloat(weeklyInvoice.invoiceTotal).toFixed(2)}
                                </h5>
                                <span>{t('accounting:invoice_generated')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color1">
                                <span class="name-letter">P</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyInvoice.invoicePaid < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyInvoice.invoicePaid < 0
                                        ? parseFloat(weeklyInvoice.invoicePaid * -1).toFixed(2)
                                        : parseFloat(weeklyInvoice.invoicePaid).toFixed(2)}
                                </h5>
                                <span>{t('accounting:paid')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color">
                                <span class="name-letter">D</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyInvoice.invoiceDue < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyInvoice.invoiceDue < 0
                                        ? parseFloat(weeklyInvoice.invoiceDue * -1).toFixed(2)
                                        : parseFloat(weeklyInvoice.invoiceDue).toFixed(2)}
                                </h5>
                                <span>{t('accounting:due')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getMonthlyInvoiceStats() {
        const { t } = this.props
        const { report } = this.state
        const monthlyInvoice = report.monthlyInvoice
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:invoice_monthly')}</h4>
                    <div class="box-content-list form-group">
                        <div class="circle-category-list">
                            <div class="user-img name-color3">
                                <span class="name-letter">T</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyInvoice.invoiceTotal < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyInvoice.invoiceTotal < 0
                                        ? parseFloat(monthlyInvoice.invoiceTotal * -1).toFixed(2)
                                        : parseFloat(monthlyInvoice.invoiceTotal).toFixed(2)}
                                </h5>
                                <span>{t('accounting:invoice_generated')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color1">
                                <span class="name-letter">P</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyInvoice.invoicePaid < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyInvoice.invoicePaid < 0
                                        ? parseFloat(monthlyInvoice.invoicePaid * -1).toFixed(2)
                                        : parseFloat(monthlyInvoice.invoicePaid).toFixed(2)}
                                </h5>
                                <span>{t('accounting:paid')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color">
                                <span class="name-letter">D</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyInvoice.invoiceDue < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyInvoice.invoiceDue < 0
                                        ? parseFloat(monthlyInvoice.invoiceDue * -1).toFixed(2)
                                        : parseFloat(monthlyInvoice.invoiceDue).toFixed(2)}
                                </h5>
                                <span>{t('accounting:due')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getWeeklyBillStats() {
        const { t } = this.props
        const { report } = this.state
        const weeklyBill = report.weeklyBill
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:bill_weekly')}</h4>
                    <div class="box-content-list form-group">
                        <div class="circle-category-list">
                            <div class="user-img name-color3">
                                <span class="name-letter">T</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyBill.billTotal < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyBill.billTotal < 0
                                        ? parseFloat(weeklyBill.billTotal * -1).toFixed(2)
                                        : parseFloat(weeklyBill.billTotal).toFixed(2)}
                                </h5>
                                <span>{t('accounting:bill_generated')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color1">
                                <span class="name-letter">P</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyBill.billPaid < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyBill.billPaid < 0
                                        ? parseFloat(weeklyBill.billPaid * -1).toFixed(2)
                                        : parseFloat(weeklyBill.billPaid).toFixed(2)}
                                </h5>
                                <span>{t('accounting:paid')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color">
                                <span class="name-letter">D</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={weeklyBill.billDue < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {weeklyBill.billDue < 0
                                        ? parseFloat(weeklyBill.billDue * -1).toFixed(2)
                                        : parseFloat(weeklyBill.billDue).toFixed(2)}
                                </h5>
                                <span>{t('accounting:due')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getMonthlyBillStats() {
        const { t } = this.props
        const { report } = this.state
        const monthlyBill = report.monthlyBill
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:bill_monthly')}</h4>
                    <div class="box-content-list form-group">
                        <div class="circle-category-list">
                            <div class="user-img name-color3">
                                <span class="name-letter">T</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyBill.billTotal < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyBill.billTotal < 0
                                        ? parseFloat(monthlyBill.billTotal * -1).toFixed(2)
                                        : parseFloat(monthlyBill.billTotal).toFixed(2)}
                                </h5>
                                <span>{t('accounting:bill_generated')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color1">
                                <span class="name-letter">P</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyBill.billPaid < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyBill.billPaid < 0
                                        ? parseFloat(monthlyBill.billPaid * -1).toFixed(2)
                                        : parseFloat(monthlyBill.billPaid).toFixed(2)}
                                </h5>
                                <span>{t('accounting:paid')}</span>
                            </div>
                        </div>
                        <div class="circle-category-list">
                            <div class="user-img name-color">
                                <span class="name-letter">D</span>
                            </div>
                            <div class="circle-user">
                                <h5 className={monthlyBill.billDue < 0 ? 'negative-amount' : ''}>
                                    <small>{this.props.school.currency}</small> .&nbsp;
                                    {monthlyBill.billDue < 0
                                        ? parseFloat(monthlyBill.billDue * -1).toFixed(2)
                                        : parseFloat(monthlyBill.billDue).toFixed(2)}
                                </h5>
                                <span>{t('accounting:due')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getCashFlow() {
        const { t } = this.props
        const { report } = this.state
        const incExpLineChartData = report.incExpLineChartData
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:cashflow')}</h4>
                    <div class="box-content-list form-group">
                        <div className="chart-content">
                            <Line
                                options={cashflow_option}
                                data={{
                                    labels: incExpLineChartData.day,
                                    datasets: [
                                        {
                                            label: 'Income',
                                            translate: t('accounting:income'),
                                            fill: true,
                                            data: incExpLineChartData.income,
                                            borderColor: 'rgb(53, 162, 235)',
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        },
                                        {
                                            label: 'Expense',
                                            translate: t('accounting:expense'),
                                            fill: true,
                                            data: incExpLineChartData.expense,
                                            borderColor: 'rgb(255, 58, 110)',
                                            backgroundColor: 'rgba(235, 184, 177, 0.5)',
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getIncomeExpenseChart() {
        const { t } = this.props
        const { report } = this.state
        const incExpBarChartData = report.incExpBarChartData
        const income = incExpBarChartData.income.map((item) => {
            return parseFloat(item).toFixed(2)
        })
        const expense = incExpBarChartData.expense.map((item) => {
            return parseFloat(item).toFixed(2)
        })
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:income_expense')}</h4>
                    <div class="box-content-list form-group">
                        <div className="chart-content">
                            <Bar
                                options={income_expense_option}
                                data={{
                                    labels: this.state.monthList,
                                    datasets: [
                                        {
                                            label: 'Income',
                                            translate: t('accounting:income'),
                                            data: income,
                                            borderColor: 'rgb(53, 162, 235)',
                                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            borderWidth: 1,
                                        },
                                        {
                                            label: 'Expense',
                                            translate: t('accounting:expense'),
                                            data: expense,
                                            borderColor: 'rgb(255, 58, 110)',
                                            backgroundColor: 'rgba(235, 184, 177, 0.5)',
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getLatestIncome() {
        const { t } = this.props
        const { report } = this.state
        const latestIncome = report.latestIncome
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:latest_income')}</h4>
                    <div class="box-content-list form-group">
                        <div className="table-responsive">
                            <table className="table invoice_table">
                                <tr className="invoice_table_border invoice_table_header_light">
                                    <td>
                                        <strong>{t('activity:date')}</strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>{t('gamification:user')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('income:amount')}</strong>
                                    </td>
                                </tr>
                                {latestIncome.length > 0 && (
                                    <Fragment>
                                        {latestIncome.map((item) => {
                                            return (
                                                <tr className="invoice_table_border">
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.date)
                                                            : item.date}
                                                    </td>
                                                    <td>{item.customer.name}</td>
                                                    <td>
                                                        <small>{this.props.school.currency}</small>
                                                        &nbsp;
                                                        {parseFloat(item.amount).toFixed(2)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )}
                                {latestIncome.length == 0 && (
                                    <tr className="invoice_table_border">
                                        <td colSpan={3} className="text-center">
                                            {t('accounting:nda')}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getLatestExpense() {
        const { t } = this.props
        const { report } = this.state
        const latestExpense = report.latestExpense
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:latest_expense')}</h4>
                    <div class="box-content-list form-group">
                        <div className="table-responsive">
                            <table className="table invoice_table">
                                <tr className="invoice_table_border invoice_table_header_light">
                                    <td>
                                        <strong>{t('activity:date')}</strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>{t('gamification:user')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('income:amount')}</strong>
                                    </td>
                                </tr>
                                {latestExpense.length > 0 && (
                                    <Fragment>
                                        {latestExpense.map((item) => {
                                            return (
                                                <tr className="invoice_table_border">
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.date)
                                                            : item.date}
                                                    </td>
                                                    <td>{item.vender.name}</td>
                                                    <td>
                                                        <small>{this.props.school.currency}</small>
                                                        &nbsp;
                                                        {parseFloat(item.amount).toFixed(2)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )}
                                {latestExpense.length == 0 && (
                                    <tr className="invoice_table_border">
                                        <td colSpan={3} className="text-center">
                                            {t('accounting:nda')}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getRecentInvoice() {
        const { t } = this.props
        const { report } = this.state
        const recentInvoice = report.recentInvoice
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:recent_invoice')}</h4>
                    <div class="box-content-list form-group">
                        <div className="table-responsive">
                            <table className="table invoice_table">
                                <tr className="invoice_table_border invoice_table_header_light">
                                    <td>
                                        <strong>{t('income:invoice')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('gamification:user')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('income:issuedate')}</strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>
                                            {t('accounting:due')} {t('activity:date')}
                                        </strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>{t('common:status')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('income:amount')}</strong>
                                    </td>
                                </tr>
                                {recentInvoice.length > 0 && (
                                    <Fragment>
                                        {recentInvoice.map((item) => {
                                            return (
                                                <tr className="invoice_table_border">
                                                    <td>{getInvoiceId(item.invoice_id)}</td>
                                                    <td>{item.customer.name}</td>
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.issue_date)
                                                            : item.date}
                                                    </td>
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.due_date)
                                                            : item.due_date}
                                                    </td>
                                                    <td>{status[item.status]}</td>
                                                    <td>
                                                        <small>{this.props.school.currency}</small> &nbsp;
                                                        {item.total_amount}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )}
                                {recentInvoice.length == 0 && (
                                    <tr className="invoice_table_border">
                                        <td colSpan={3} className="text-center">
                                            {t('accounting:nda')}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getRecentBill() {
        const { t } = this.props
        const { report } = this.state
        const recentBill = report.recentBill
        return (
            <div className="col-md-12">
                <div class="todo-box">
                    <h4>{t('accounting:recentBill')}</h4>
                    <div class="box-content-list form-group">
                        <div className="table-responsive">
                            <table className="table invoice_table">
                                <tr className="invoice_table_border invoice_table_header_light">
                                    <td>
                                        <strong>{t('accounting:bill')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('gamification:user')}</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            {t('accounting:bill')} {t('activity:date')}
                                        </strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>
                                            {t('accounting:due')} {t('activity:date')}
                                        </strong>
                                        &nbsp;
                                        {this.props.school.date_format == 'BS' ? <small>( B.S )</small> : ''}
                                    </td>
                                    <td>
                                        <strong>{t('common:status')}</strong>
                                    </td>
                                    <td>
                                        <strong>{t('income:amount')}</strong>
                                    </td>
                                </tr>
                                {recentBill.length > 0 && (
                                    <Fragment>
                                        {recentBill.map((item) => {
                                            return (
                                                <tr className="invoice_table_border">
                                                    <td>{getBillId(item.bill_id)}</td>
                                                    <td>{item.vender.name}</td>
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.bill_date)
                                                            : item.bill_date}
                                                    </td>
                                                    <td>
                                                        {this.props.school.date_format == 'BS'
                                                            ? convertADtoBS(item.due_date)
                                                            : item.due_date}
                                                    </td>
                                                    <td>{status[item.status]}</td>
                                                    <td>
                                                        <small>{this.props.school.currency}</small> &nbsp;
                                                        {item.total_amount}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )}
                                {recentBill.length == 0 && (
                                    <tr className="invoice_table_border">
                                        <td colSpan={3} className="text-center">
                                            {t('accounting:nda')}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        const { report, incomeExpense } = this.state
        return (
            <div className="main-page">
                <Sidebar id="account-dashboard" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('accounting:dashboard')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-box">
                        {report != null && (
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        {this.getCashFlow()}
                                        {this.getIncomeExpenseChart()}
                                        {/* {this.getLatestIncome()} */}
                                        {/* {this.getLatestExpense()} */}
                                        {this.getRecentInvoice()}
                                        {this.getRecentBill()}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        {this.getAccountDetail()}
                                        {this.getIncomeExpense()}
                                        {this.getWeeklyInvoiceStats()}
                                        {this.getWeeklyBillStats()}
                                        {this.getMonthlyInvoiceStats()}
                                        {this.getMonthlyBillStats()}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const AccountingDashboard = withTranslation()(connect(mapStateToProps)(AccountingDashboardComponent))

export { AccountingDashboard }
