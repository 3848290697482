import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { allStudent, activeClass } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { discount_type } from '~/helper/constant/discount'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import { redirect } from '~/helper/function'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractStudentSearchStatementComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            from_date: '',
            selectedStudent: null,
            to_date: '',
            classList: [],
            studentList: [],
            allStudent: [],
            selectedClass: null,
            selectedStudent: null,
            errors: {
                selectedStudent: null,
                from_date: null,
                to_date: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.viewInvoice = this.viewInvoice.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    viewInvoice() {
        let hasError = false
        let errors = this.state.errors
        if (this.state.to_date == '') {
            hasError = true
            errors['to_date'] = 'Required'
        }
        if (this.state.from_date == '') {
            hasError = true
            errors['from_date'] = 'Required'
        }
        if (!hasError) {
            redirect(route.student_fee.statement.view + '/' + moment(this.state.from_date).format('yyyy-MM-DD') + '/' + moment(this.state.to_date).format('yyyy-MM-DD'), this.props.history)
        } else {
            this.setState({
                errors,
            })
            toastMessage('error', 'Please check your data')
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
