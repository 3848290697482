import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getUserSchool = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/profile/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editUserSchool = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school/profile/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSchoolPackage = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/package', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSchoolPackageStatus = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/package/status', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
