export function required(key, state) {
    const errors = {}
    key.map((key) => {
        if (state[key] == undefined || state[key].toString().trim() == '' || state[key].length == 0) {
            return (errors[key] = 'Required')
        } else {
            return (errors[key] = null)
        }
    })
    return errors
}
