import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { searchAlumni, getAlumniCategory } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as validation from '~/helper/constant/validation'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    data: Array,
    loading: Boolean,
}

export class AbstractAlumniYearbookViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            alumniCategory: null,
            alumniList: [],
            filteredAlumniList: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.filterAlumni = this.filterAlumni.bind(this)
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    filterAlumni(value) {
        const filteredAlumniList = this.state.alumniList.filter((item) => {
            return item.user_school.user.name != null ? item.user_school.user.name.toLowerCase().includes(value.toLowerCase()) : item.user_school.user.toLowerCase().reg_no.includes(value.toLowerCase())
        })
        this.setState({
            filteredAlumniList,
        })
    }

    loadAlumniCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAlumniCategory(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        alumniCategory: response.data.alumni_category,
                    },
                    () => {
                        this.loadAlumni()
                    }
                )
            }
        })
    }

    loadAlumni() {
        this._handleChange('loading', true)
        const data = {
            batch_id: [parseInt(this.props.match.params.id)],
        }
        searchAlumni(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let data = []
            if (response.code == API_SUCCESS) {
                data = response.data.alumni.sort((a, b) => {
                    return a.user_school.user.name > b.user_school.user.name ? 1 : -1
                })
            }
            this.setState({
                alumniList: data,
                filteredAlumniList: data,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
