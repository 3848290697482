import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import * as UserType from '~/helper/constant/user_type'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Fragment } from 'react'
import * as route from '~/helper/constant/route'
import { AbstractBookIssueComponent, mapStateToProps } from './abstract'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/book_issue_status'
import DatePicker from 'react-datepicker'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import $, { type } from 'jquery'

class BookIssueViewComponent extends AbstractBookIssueComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusToggleModal = this.getStatusToggleModal.bind(this)
        this._setReturnDate = this._setReturnDate.bind(this)
        this.handleModalClose = this.handleModalClose.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
    }
    _setReturnDate(data) {
        this._handleChange('returnDate', data)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.bookDetail != null && prevState.bookDetail != this.state.bookDetail) {
            if (this.state.bookDetail == null) {
                $('#modal_bookissue').modal('hide')
            } else {
                $('#modal_bookissue').modal('show')
            }
        }
    }

    handleModalClose = () => {
        // Reset bookDetail state
        this.setState({ bookDetail: null })
    }

    toggle() {
        super.toggle().then((response) => {
            if (response) {
                $('#close_bookissue').trigger('click')
            }
        })
    }
    getStatusToggleModal() {
        const { t } = this.props
        const modal = this.state.tableData.map((item, index) => {
            return (
                <div key={index} id={'modal_bookissue'} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button
                                type="button"
                                onClick={this.handleModalClose}
                                className="close"
                                data-dismiss="modal"
                            >
                                ×
                            </button>
                            <span>{t('library:bookissue')}</span>
                        </div>
                        <br />
                        <form className="p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('library:returndate')} *</label>
                                            <DatePicker
                                                placeholderText={t('library:returndate')}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                onChange={this._setReturnDate}
                                                selected={this.state.returnDate}
                                            />
                                            {this.props.school != null &&
                                                this.props.school.school != null &&
                                                this.props.school.school.date_format == 'BS' && (
                                                    <label className="date-bs">
                                                        {this.state.returnDate != ''
                                                            ? convertADtoBS(this.state.returnDate) != null
                                                                ? convertADtoBS(this.state.returnDate) + ' BS'
                                                                : 'Invalid Date Range'
                                                            : ''}
                                                    </label>
                                                )}
                                                {this.state.errors.returnDate && 
                                            (<div className="error text-danger">{this.state.errors.returnDate}</div>)}
                                        </div>
                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('library:fineamount')} *</label>
                                            <Input
                                                name="fineAmount"
                                                onChange={this._handleChange}
                                                placeholder={t('library:fineamount')}
                                                type="text"
                                            />
                                            {this.state.errors.fineAmount && 
                                            (<div className="error text-danger">{this.state.errors.fineAmount}</div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this.toggle} type="submit" text={t('savebtn')} main={true} />
                                    <button
                                        id="close_add"
                                        type="button"
                                        onClick={this.handleModalClose}
                                        className="cancel-button"
                                        data-dismiss="modal"
                                    >
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="library" isAccount={false} />
                <Loading status={this.state.loading} text={this.state.loading_text} />

                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>{t('library:bookissue')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('library:bookissue')}</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin ||
                                this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <a href={route.admin.library.bookissue.add} className="main-button">
                                            {t('common:addBtn')}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="main-form">
                        <Table data={this.state.tableStructure} />
                        <br />
                    </div>
                    {this.getStatusToggleModal()}
                </div>
                {/* {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                    <Fragment>
                        {this.getAddModal()}
                    </Fragment>
                )} */}
            </div>
        )
    }
}
const BookIssueView = withTranslation()(connect(mapStateToProps)(BookIssueViewComponent))

export { BookIssueView }
