import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractCourseInsightsComponent, mapStateToProps } from './abstract'

import { CircularProgressbar } from 'react-circular-progressbar'

class CourseInsightsComponent extends AbstractCourseInsightsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getCourseDetail = this.getCourseDetail.bind(this)
    }

    getCourseDetail() {
        const { t } = this.props
        let data = this.state.courseModule.map((item, index) => {
            let progress_result = item.inprogress_count
            let total_completed = item.total_completed
            return (
                <div className="col-md-6 col-lg-4 float-left" key={index}>
                    <div className="folder-list no-transition insight-list">
                        <div className="clearfix">
                            <span className="new-insight">
                                <CircularProgressbar value={progress_result} text={progress_result + `%`} strokeWidth={5} />
                            </span>
                            <a href={route.course.insights_details + '/' + this.state.class_id + '/' + this.state.course_id + '/' + item.id} className="folder-name">
                                {item.name}
                                <span>
                                    {t('course:totalUsersAssigned')}: <ins>{this.state.accessCount}</ins>
                                </span>
                                <span>
                                    {t('course:totalUsersCompleted')}: <ins>{total_completed}</ins>
                                </span>
                            </a>
                        </div>
                        <hr />
                        <div className="text-center">
                            <a href={route.course.insights_details + '/' + this.state.class_id + '/' + this.state.course_id + '/' + item.id}>
                                <button className="main-button">{t('course:detailed_insight')}</button>
                            </a>
                        </div>
                    </div>
                </div>
            )
        })
        if (data == '') {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('course:noViewMsg')}
                </p>
            )
        }
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.course.view + '/' + this.state.class_id}> {this.state.classes != null ? this.state.classes.name : t('course:others')} &gt; </a>
                            </li>
                            <li>{this.state.courseData != null ? this.state.courseData.title : 'Course'}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{t('course:insight')}</span>
                                <h5 className="main-title heading5">{this.state.courseData != null ? this.state.courseData.title : 'Course'}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix">{this.getCourseDetail()}</div>
                </div>
            </div>
        )
    }
}

const CourseInsights = withTranslation()(connect(mapStateToProps)(CourseInsightsComponent))

export { CourseInsights }
