import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Checkbox, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractChartAccountEditComponent, mapStateToProps } from './abstract'

class ChartAccountEditComponent extends AbstractChartAccountEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="double_entry" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.chartofaccount.view}>
                                    {t('doubleentry:chartofaccount')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:editchartofaccount')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} * </label>
                                    <Input
                                        name="name"
                                        type="text"
                                        placeholder={t('common:name')}
                                        value={this.state.name}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.name && (
                                        <label className="error text-danger">{this.state.errors.name}</label>
                                    )}
                                </div>
                                <div className="col-md-6 float-left form-group">
                                    <label className="form-title">{t('fee:code')} * </label>
                                    <Input
                                        name="code"
                                        type="number"
                                        placeholder={t('fee:code')}
                                        value={this.state.code}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.code && (
                                        <label className="error text-danger">{this.state.errors.code}</label>
                                    )}
                                </div>
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('common:description')} *</label>
                                    <TextArea
                                        name="description"
                                        placeholder={t('common:description')}
                                        value={this.state.description}
                                        onChange={this._handleChange}
                                    />
                                    {this.state.errors.description && (
                                        <label className="error text-danger">{this.state.errors.description}</label>
                                    )}
                                </div>
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('doubleentry:isenabled')} </label>
                                    <div className="col-sm-12 pl-4">
                                        <Checkbox
                                            name="is_enabled"
                                            value={this.state.is_enabled}
                                            onChange={this._handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const ChartAccountEdit = withTranslation()(connect(mapStateToProps)(ChartAccountEditComponent))
export { ChartAccountEdit }
