import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractExamMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
class ExamMenuComponent extends AbstractExamMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('exam:title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            {AccessModule.checkSubAccess([AccessModule.EXAM], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.examination.exam.view)}>
                                        <a href={route.examination.exam.view} className="folder-img">
                                            {SVG.discountIcon()}
                                        </a>
                                        <a href={route.examination.exam.view} className="folder-name">
                                            {t('exam:exam:categoryTitle')}
                                            <span>{t('exam:exam:categorySubTitle')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {AccessModule.checkSubAccess([AccessModule.RESULT], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.examination.result.view)}>
                                        <a href={route.examination.result.view} className="folder-img">
                                            {SVG.student()}
                                        </a>
                                        <a href={route.examination.result.view} className="folder-name">
                                            {t('exam:result.categoryTitle')}
                                            <span>{t('exam:result.categorySubTitle')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {AccessModule.checkSubAccess([AccessModule.QUESTION_BANK], role) && (
                                <Fragment>
                                    {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                        <div className="float-left course-your">
                                            <div className="folder-list clearfix" onClick={() => (this.state.classes.length == 1 ? (window.location = route.examination.question_bank.view + '/' + this.state.classes[0].id) : (window.location = route.examination.question_bank.menu))}>
                                                <a href={this.state.classes.length == 1 ? route.examination.question_bank.view + '/' + this.state.classes[0].id : route.examination.question_bank.menu} className="folder-img">
                                                    {SVG.subject()}
                                                </a>
                                                <a href={this.state.classes.length == 1 ? route.examination.question_bank.view + '/' + this.state.classes[0].id : route.examination.question_bank.menu} className="folder-name">
                                                    {t('exam:questionbank:categoryTitle')}
                                                    <span>{t('exam:questionbank:categorySubTitle')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                        <div className="float-left course-your">
                                            <div className="folder-list clearfix" onClick={() => (window.location = route.examination.question_bank.menu)}>
                                                <a href={route.examination.question_bank.menu} className="folder-img">
                                                    {SVG.subject()}
                                                </a>
                                                <a href={route.examination.question_bank.menu} className="folder-name">
                                                    {t('exam:questionbank:categoryTitle')}
                                                    <span>{t('exam:questionbank:categorySubTitle')}</span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ExamMenu = withTranslation()(connect(mapStateToProps)(ExamMenuComponent))

export { ExamMenu }
