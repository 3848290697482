import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Input } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { AbstractAccountTaxAddComponent, mapStateToProps } from './abstract'

let tabObject = []

class AccountTaxAddComponent extends AbstractAccountTaxAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="constant" isAccount={true}/>
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="fee-design">
                        <div className="main-head-option">
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.admin.accounting.constant.menu}>{t('constant:constant')} > </a>
                                </li>
                                <li>
                                    <a href={route.admin.accounting.constant.tax.view}>{t('fee:tax')} > </a>
                                </li>
                                <li>{t('common:addBtn')}</li>
                            </ul>
                            <div className="row clearfix">
                                <div className="col-md-5 float-left">
                                    <h5 className="main-title">
                                        {t('common:addBtn')} {t('fee:tax')}:
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="main-form">
                            <div className="clearfix">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-sm-6 float-left form-group clearfix">
                                                <label className="form-title">{t('fee:tax_msg1')} *</label>
                                                <Input
                                                    name="tax_name"
                                                    type="text"
                                                    className="form-control"
                                                    id="heading"
                                                    placeholder={t('fee:tax_msg1')}
                                                    value={this.state.tax_name}
                                                    onChange={this._handleChange}
                                                />
                                                {this.state.errors.tax_name && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.tax_name}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 float-left form-group clearfix">
                                                <label className="form-title" htmlFor="showEm">
                                                    {t('fee:tax_amount')} *
                                                </label>
                                                <Input
                                                    name="tax_amount"
                                                    min={0}
                                                    type="number"
                                                    className="form-control"
                                                    id="heading"
                                                    placeholder={t('fee:tax_amount')}
                                                    value={this.state.tax_amount}
                                                    onChange={this._handleChange}
                                                />
                                                {this.state.errors.tax_amount && (
                                                    <label className="error text-danger">
                                                        {this.state.errors.tax_amount}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <Button
                                        onClick={this.onSave}
                                        type="submit"
                                        text={t('common:savebtn')}
                                        main={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AcccountTaxAdd = withTranslation()(connect(mapStateToProps)(AccountTaxAddComponent))

export { AcccountTaxAdd }
