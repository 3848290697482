import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { getEvent, deletePost, toggleEventPost } from '~/services/api'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import $ from 'jquery'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    event: Object,
    data: Object,
    loading: Boolean,
}

export class AbstractEventComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            data: [],
            event:
                this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom
                    ? [
                          {
                              title: {
                                  field: 'text',
                                  translate: 'calendar:calendar-menu.eventBtn',
                                  route: false,
                                  sortable: true,
                              },
                              start_date: {
                                  field: 'date',
                                  translate: 'calendar:event.add.startDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              end_date: {
                                  field: 'date',
                                  translate: 'calendar:event.add.endDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              posted_by: {
                                  field: 'text',
                                  translate: 'calendar:author',
                                  route: false,
                                  sortable: true,
                              },
                              status: {
                                  field: 'badge',
                                  translate: 'common:status',
                                  route: false,
                                  sortable: true,
                              },
                              schedule: {
                                  field: 'button',
                                  translate: 'calendar:event.schedule.breadcrumb',
                                  modal: true,
                                  sortable: false,
                              },
                          },
                      ]
                    : [
                          {
                              title: {
                                  field: 'text',
                                  translate: 'calendar:calendar-menu.eventBtn',
                                  route: false,
                                  sortable: true,
                              },
                              start_date: {
                                  field: 'date',
                                  translate: 'calendar:event.add.startDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              end_date: {
                                  field: 'date',
                                  translate: 'calendar:event.add.endDateLabel',
                                  route: false,
                                  sortable: true,
                              },
                              posted_by: {
                                  field: 'text',
                                  translate: 'calendar:author',
                                  route: false,
                                  sortable: true,
                              },
                              status: {
                                  field: 'badge',
                                  translate: 'common:status',
                                  route: false,
                                  sortable: true,
                              },
                              schedule: {
                                  field: 'button',
                                  translate: 'calendar:event.schedule.breadcrumb',
                                  modal: true,
                                  sortable: false,
                              },
                              action: {
                                  field: 'button',
                                  translate: 'calendar:event.action',
                                  modal: true,
                                  sortable: false,
                              },
                          },
                      ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadEvent = this.loadEvent.bind(this)
        this.deleteEvent = this.deleteEvent.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadEvent()
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_status_' + link).trigger('click')
        toggleEventPost(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEvent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    deleteEvent(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        deletePost(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEvent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadEvent() {
        this._handleChange('loading', true)
        let event = []
        let data = []
        event.push(this.state.event[0])
        getEvent(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const post = response.data.post.filter((item) => item.isAlumni == this.props.isAlumni)
                post.map((item, index) => {
                    let status = {
                        text: 'Draft',
                        translate: 'calendar:event.draft',
                        badge: 'table-red',
                        modal: this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom ? '' : item.author.id == this.props.school.id ? 'modal_status_' + item.id : '',
                    }
                    if (item.status == Status.ACTIVE) {
                        status = {
                            text: 'Published',
                            translate: 'calendar:event.published',
                            badge: 'table-green',
                            modal: this.props.school != null && this.props.school.user_type != UserType.Admin && this.props.school.user_type != UserType.Custom ? '' : item.author.id == this.props.school.id ? 'modal_status_' + item.id : '',
                        }
                    }
                    const result = {
                        title: {
                            text: item.description,
                        },
                        start_date: {
                            text: item.from_date,
                        },
                        end_date: {
                            text: item.to_date,
                        },
                        posted_by: {
                            text: item.author.user.name,
                        },
                        status,
                        schedule: {
                            text: 'Schedule',
                            translate: 'calendar:event.schedule.breadcrumb',
                            route: route.admin.event.schedule + '/' + item.id,
                        },
                    }
                    if (this.props.school != null && (this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom)) {
                        result['action'] = {
                            text: 'Delete',
                            translate: 'DeleteBtn',
                            modal: 'modal_delete_' + item.id,
                        }
                    }
                    data.push(item)
                    event.push(result)
                })
                this.setState({
                    event,
                    data,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
}

const AbstractEvent = withTranslation()(AbstractEventComponent)

export { AbstractEvent }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
        isAlumni: state.isAlumni,
    }
}
AbstractEventComponent
