import $ from 'jquery'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Table } from '~/components/table'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'


// import { configRoute, convertADtoBS, getInvoiceId } from '../../../../../../helper/function/abstract'
import { AbstractShelfManagementDetailsComponent, mapStateToProps } from './abstract'

class ShelfManagementDetailsComponent extends AbstractShelfManagementDetailsComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        
    }

    render() {

            const { t } = this.props

            return <Table data={this.state.tableStructure} />

    }


 }

const ShelfManagementDetails = withTranslation()(connect(mapStateToProps)(ShelfManagementDetailsComponent))
export { ShelfManagementDetails }
