import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewLeaderboard = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('gamification-management/leaderboard', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addLeaderboard = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('gamification-management/leaderboard/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
