import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Checkbox, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Post } from '~/components/post'
import { Sidebar } from '~/components/sidebar'
import { Table } from '~/components/table'
import { UserFilter } from '~/components/userfilter'
import { circle } from '~/helper/constant/color'
import * as AccessModule from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { getBase64, getMediaIcon, getReadableFileSize } from '~/helper/function'
import { toastMessage } from '~/helper/function/util'
import { convertADtoBS } from '../../../../helper/function'
import { AbstractTodoViewComponent, mapStateToProps } from './abstract'

class TodoViewComponent extends AbstractTodoViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getSubmit = this.getSubmit.bind(this)
        this.getParticipants = this.getParticipants.bind(this)
        this.getParticipantsModal = this.getParticipantsModal.bind(this)
        this.getParticipantsModalData = this.getParticipantsModalData.bind(this)
        this.getTaskEdit = this.getTaskEdit.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._setDueDate = this._setDueDate.bind(this)
        this.previewDocument = this.previewDocument.bind(this)
        this._uploadFile = this._uploadFile.bind(this)
        this._removeFile = this._removeFile.bind(this)
        this.getResources = this.getResources.bind(this)
        this.getResourceModal = this.getResourceModal.bind(this)
        this.getResourceModalData = this.getResourceModalData.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.submitHomework = this.submitHomework.bind(this)
        this._removeSubmitFile = this._removeSubmitFile.bind(this)
        this._submitFile = this._submitFile.bind(this)
        this.previewUploadFile = this.previewUploadFile.bind(this)
        this.checkTask = this.checkTask.bind(this)
        this.getApproval = this.getApproval.bind(this)
        this.getRemarks = this.getRemarks.bind(this)
        this._onApprove = this._onApprove.bind(this)
        this._deleteMediaFile = this._deleteMediaFile.bind(this)
        this._submitTask = this._submitTask.bind(this)
        this._onSave = this._onSave.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.posts != prevState.posts) {
            this.clearState()
        }
    }

    onFilesError(error, file) {
        toastMessage('error', error.message)
    }

    _submitTask(e) {
        super._submitTask(e).then((response) => {
            if (response) {
                $('#close_submit_modal').trigger('click')
            }
        })
    }

    _onSave(e) {
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_edit').trigger('click')
            }
        })
    }

    _deleteMediaFile(e) {
        super._deleteMediaFile(e)
        const link = e.target.getAttribute('link')
        $('#close_media_' + link).trigger('click')
        $('#close_resource_modal').trigger('click')
    }

    _onApprove(id, status) {
        super._onApprove(id, status)
        $('#verify-task-close-' + id).trigger('click')
    }

    _selectEmployee(data) {
        this._handleChange('employee', data)
    }

    _selectStudent(data) {
        this._handleChange('student', data)
    }

    _selectSubject(data) {
        this._handleChange('subject', data)
    }

    _setDueDate(data) {
        this._handleChange('due_date', data)
    }

    previewUploadFile() {
        const preview = this.state.uploadFile.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeSubmitFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    _removeSubmitFile(index) {
        let uploads = this.state.uploadFile
        let file = this.state.file
        uploads.splice(index, 1)
        file.splice(index, 1)
        this._handleChange('file', file)
        this._handleChange('uploadFile', uploads)
    }

    _submitFile(data) {
        const media = this.state.file
        const uploadedFile = data[data.length - 1]
        getBase64(uploadedFile).then((file) => {
            if (file.error == null) {
                media.push(file)
                this._handleChange('file', media)
                this._handleChange('uploadFile', data)
            }
        })
    }

    checkTask() {
        const { t } = this.props
        return (
            <div id="check-task" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb modal-xl">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:check')}</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <Table data={this.state.homework} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    submitHomework() {
        const { t } = this.props
        return (
            <div id="submit-task" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:submit')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-md-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea className={this.state.submit_errors.submit_description != null ? 'image-error' : ''} name="submit_description" onChange={this._handleChange} placeholder={t('common:description')} type="text" value={this.state.submit_description} />
                                        {this.state.submit_errors.submit_description && <label className="error text-danger">{this.state.submit_errors.submit_description}</label>}
                                    </div>
                                </div>
                                {this.state.posts.remarks == t('todo:OnlineSubmission') && (
                                    <div className="row clearfix">
                                        <div className="col-md-12 float-left">
                                            <label className="form-title">{t('todo:uploadFile')}</label>
                                            <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                                {this.previewUploadFile()}
                                                {this.state.file.length < 1 && (
                                                    <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                        <Files className="files-dropzone" onChange={this._submitFile} accepts={['.pdf']} multiple maxFiles={1} maxFileSize={10000000} minFileSize={0} clickable>
                                                            {t('todo:addFile')}
                                                        </Files>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._submitTask} type="submit" text={t('common:submit')} main={true} />
                                    <button id="close_submit_modal" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getSubmit() {
        const { t } = this.props
        if (this.state.posts != null) {
            let remarks = t('todo:taskmsg3')
            if (this.state.posts.remarks == t('todo:OnlineSubmission')) {
                remarks = t('todo:taskmsg4')
            }
            if (this.props.school.id == this.state.posts.author.id) {
                const { t } = this.props
                return (
                    <div className="col-md-12">
                        <div className="todo-box">
                            <h5>{t('activity:action')}</h5>
                            <div className="box-content-list form-group">
                                <small>{remarks}</small>
                            </div>
                            <div className="box-content-list form-group">
                                <Button type="button" text={t('common:check')} modal="check-task" />
                            </div>
                        </div>
                    </div>
                )
            } else {
                const homework = this.state.posts.homework.find((item) => {
                    const id = this.props.school.user_type == UserType.Parents ? this.props.student : this.props.school.id
                    if (item.users_schools_id == id) {
                        return item
                    }
                })
                if (homework == null) {
                    const { t } = this.props
                    return (
                        <div className="col-md-12">
                            <div className="todo-box">
                                <h5>{t('activity:action')}</h5>
                                <div className="box-content-list form-group">
                                    <small>{remarks}</small>
                                </div>
                                <div className="box-content-list form-group">
                                    <Button type="button" text={t('common:submit')} modal="submit-task" />
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="col-md-12">
                            <div className="todo-box">
                                <h5>{t('common:status')}</h5>
                                <div className="box-content-list form-group">
                                    <small className="mb-2">{remarks}</small>
                                </div>
                                <div className="box-content-list form-group">
                                    {homework.status == Status.PENDING && <span className="badge table-blue remarks-status">{t('todo:approval')}</span>}
                                    {homework.status == Status.ACTIVE && <span className="badge table-status table-green remarks-status">{t('todo:approved')}</span>}
                                    {homework.status == Status.INACTIVE && <span className="badge table-red remarks-status">{t('todo:rejected')}</span>}
                                </div>
                                {homework.status == Status.INACTIVE && (
                                    <div className="box-content-list form-group">
                                        <Button type="button" text={t('todo:reSubmit')} modal="submit-task" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    // remarkLength(){
    //     if (this.state.posts != null){
    //         const homework = this.state.posts.homework.find((item) => item.users_schools_id == this.props.school.id)
    //         if(homework.remark.length > 200){
    //             <div className="box-content-list form-group read-more">
    //                 <span id="readmore" onClick={() => this.readmore(1)}>
    //                     read more >>
    //                 </span>
    //                 <span id="readless" onClick={() => this.readmore(0)}>
    //                     read less >>
    //                 </span>
    //             </div>
    //         }
    //     }
    // }

    readmore(action) {
        if (action == 1) {
            $('#readmore').hide()
            $('#readless').show()
            $('#content-box').css('max-height', 'unset')
        } else {
            $('#readmore').show()
            $('#readless').hide()
            $('#content-box').css('max-height', '200px')
        }
    }

    getRemarks() {
        const { t } = this.props
        if (this.state.posts != null) {
            const homework = this.state.posts.homework.find((item) => item.users_schools_id == this.props.school.id)
            if (homework != null && homework.remarks != null) {
                if (homework.remarks.length > 200) {
                    return (
                        <div className="col-md-12">
                            <div className="todo-box">
                                <h5>{t('attendance:remarks')}</h5>
                                <div className="box-content-list form-group remark-box" id="content-box">
                                    <small>{homework.remarks}</small>
                                </div>
                                <div className="box-content-list form-group read-more">
                                    <span id="readmore" onClick={() => this.readmore(1)}>
                                        <b>{t('todo:readMore')}</b>
                                    </span>
                                    <span id="readless" onClick={() => this.readmore(0)}>
                                        <b>{t('todo:readLess')}</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="col-md-12">
                            <div className="todo-box">
                                <h5>{t('attendance:remarks')}</h5>
                                <div className="box-content-list form-group remark-box" id="content-box">
                                    <small>{homework.remarks}</small>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    getApproval() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.props.school.id == this.state.posts.author.id) {
                if (this.state.posts.access_information != null) {
                    const data = this.state.posts.access_information.map((item, index) => {
                        const homework = this.state.posts.homework.find((submit) => submit.users_schools_id == item.id)
                        if (homework != null) {
                            const name = item.user.name != null ? item.user.name : item.user.reg_no
                            const media = homework.gallery == null ? [] : homework.gallery.media
                            return (
                                <div data-id="verify" data-remarks={homework.remarks} id={'verify-task-' + homework.id} key={index} className="modal fade show" role="dialog">
                                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb modal-xl">
                                        <div className="header modal-title">
                                            <button type="button" id={'verify-task-close-' + homework.id} className="close" data-dismiss="modal">
                                                ×
                                            </button>
                                            <span>
                                                {t('todo:taskmsg2')} {name} - {item.user.reg_no}
                                            </span>
                                        </div>
                                        <div className="pop-main-content">
                                            <br />
                                            <div className="row clearfix">
                                                {media.map((file, index) => {
                                                    return (
                                                        <div className="col-sm-12 float-left form-group">
                                                            <div key={index} className="circle-category-list">
                                                                <div className="user-img name-folder">
                                                                    <a href={file.media_url} target="_blank">
                                                                        <span className="mt-2">{getMediaIcon(file.media_type)}</span>
                                                                    </a>
                                                                </div>
                                                                <div className="circle-user">
                                                                    <a href={file.media_url} target="_blank">
                                                                        <h5>{file.name}</h5>
                                                                    </a>
                                                                    <span>{getReadableFileSize(file.size)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('common:description')}</label>
                                                    <TextArea disabled={true} placeholder={t('common:description')} type="text" value={homework.description} />
                                                </div>
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('attendance:remarks')}</label>
                                                    <TextArea onChange={this._handleChange} name="homework_remarks" placeholder={t('attendance:remarks')} type="text" value={this.state.homework_remarks} />
                                                </div>
                                            </div>
                                            <div className="text-center form-group">
                                                <Button onClick={() => this._onApprove(homework.id, Status.ACTIVE)} type="submit" text={t('todo:approve')} main={true} />
                                                <Button onClick={() => this._onApprove(homework.id, Status.INACTIVE)} type="submit" text={t('todo:reject')} cancel={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                    return data
                }
            }
        }
    }

    getParticipants() {
        const { t } = this.props

        if (this.state.posts != null) {
            if (this.state.posts.access_information != null) {
                if (this.state.posts.access_information.length > 0) {
                    let i = 0
                    const data = this.state.posts.access_information.map((item, index) => {
                        if (index < 3) {
                            const name = item.user.name != null ? item.user.name : item.user.reg_no
                            const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                            if (profile == null) {
                                i++
                            }
                            if (i == 3 || i == -1) {
                                i = 0
                            }
                            return (
                                <div key={index} className="circle-category-list">
                                    <div className={'user-img ' + circle[i]}>
                                        {profile != null && <img src={profile} alt="post-user" />}
                                        {profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                                    </div>
                                    <div className="circle-user">
                                        <h5>{name}</h5>
                                        <span>{item.user.reg_no}</span>
                                    </div>
                                </div>
                            )
                        }
                    })
                    return data
                } else {
                    return <small>{t('todo:no_participants')}</small>
                }
            } else {
                return <small>{t('todo:no_participants')}</small>
            }
        }
    }

    getResources() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.state.posts.gallery != null) {
                const media = this.state.posts.gallery.media.map((item, index) => {
                    if (index < 3) {
                        const { t } = this.props
                        return (
                            <div key={index} className="circle-category-list">
                                <div className="user-img name-folder">
                                    <a href={route.gallery + '/' + item.id} target="_blank">
                                        <span className="mt-2">{getMediaIcon(item.media_type)}</span>
                                    </a>
                                </div>
                                <div className="circle-user">
                                    <a href={route.gallery + '/' + item.id} target="_blank">
                                        <h5>{item.name}</h5>
                                    </a>
                                    <span>
                                        {getReadableFileSize(item.size)}
                                        {this.state.posts.author.id == this.props.school.id && (
                                            <Fragment>
                                                &nbsp;|&nbsp;{' '}
                                                <a className="error cursor" data-toggle="modal" data-target={'#modal_delete_' + item.id}>
                                                    {t('common:DeleteBtn')}
                                                </a>
                                            </Fragment>
                                        )}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                })
                return media
            }
        }
    }

    getResourceModal() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.state.posts.gallery != null) {
                if (this.state.posts.gallery.media.length > 3) {
                    return (
                        <div id="resource_modal" className="modal fade show" role="dialog">
                            <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                                <div className="header modal-title">
                                    <button type="button" id="close_resource_modal" className="close" data-dismiss="modal">
                                        ×
                                    </button>
                                    <span>{t('todo:resources')}</span>
                                </div>
                                <br />
                                <form>
                                    <div className="modal-content">
                                        <div className="row clearfix">{this.getResourceModalData()}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    getResourceModalData() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.state.posts.gallery != null) {
                const data = this.state.posts.gallery.media.map((item, index) => {
                    return (
                        <div key={index} className="col-md-6">
                            <div className="circle-category-list">
                                <div className="user-img name-folder">
                                    <a href={route.gallery + '/' + item.id} target="_blank">
                                        <span className="mt-2">{getMediaIcon(item.media_type)}</span>
                                    </a>
                                </div>
                                <div className="circle-user">
                                    <a href={route.gallery + '/' + item.id} target="_blank">
                                        <h5>{item.name}</h5>
                                    </a>
                                    <span>
                                        {getReadableFileSize(item.size)}
                                        {this.state.posts.author.id == this.props.school.id && (
                                            <Fragment>
                                                &nbsp;|&nbsp;{' '}
                                                <a className="error cursor" data-toggle="modal" data-target={'#modal_delete_' + item.id}>
                                                    {t('common:DeleteBtn')}
                                                </a>
                                            </Fragment>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
                return data
            }
        }
    }

    getParticipantsModal() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.state.posts.access_information != null) {
                if (this.state.posts.access_information.length > 3) {
                    const { t } = this.props
                    return (
                        <div id="participant_modal" className="modal fade show" role="dialog">
                            <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                                <div className="header modal-title">
                                    <button type="button" className="close" data-dismiss="modal">
                                        ×
                                    </button>
                                    <span>{t('todo:participants')}</span>
                                </div>
                                <br />
                                <form>
                                    <div className="modal-content">
                                        <div className="row clearfix">{this.getParticipantsModalData()}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    getParticipantsModalData() {
        if (this.state.posts != null) {
            if (this.state.posts.access_information != null) {
                let i = 0
                const data = this.state.posts.access_information.map((item, index) => {
                    const name = item.user.name != null ? item.user.name : item.user.reg_no
                    const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                    if (profile == null) {
                        i++
                    }
                    if (i == 3 || i == -1) {
                        i = 0
                    }
                    return (
                        <div key={index} className="col-md-6">
                            <div className="circle-category-list">
                                <div className={'user-img ' + circle[i]}>
                                    {profile != null && <img src={profile} alt="post-user" />}
                                    {profile == null && <span className="name-letter">{name.charAt(0)}</span>}
                                </div>
                                <div className="circle-user">
                                    <h5>{name}</h5>
                                    <span>{item.user.reg_no}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
                return data
            }
        }
    }

    _uploadFile(data) {
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                })
            })
            this._handleChange('media', media)
            this._handleChange('uploads', data)
        }
    }

    _removeFile(index) {
        let uploads = this.state.uploads
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploads', uploads)
    }

    previewDocument() {
        const preview = this.state.uploads.map((item, index) => {
            return (
                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV" key={index}>
                    <div className="gsEyTH files-list-item">
                        <div className="files-list-item-preview">
                            <div className="files-list-item-preview-extension">{item.extension}</div>
                        </div>
                        <div className="files-list-item-content">
                            <div className="files-list-item-content-item files-list-item-content-item-1">{item.name}</div>
                            <div className="files-list-item-content-item files-list-item-content-item-2">{item.sizeReadable}</div>
                        </div>
                        <div
                            id={'file_remove_' + item.id}
                            className="files-list-item-remove"
                            onClick={() => this._removeFile(index)} // eslint-disable-line
                        />
                    </div>
                </div>
            )
        })
        return preview
    }

    getTaskEdit() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school.role != null ? school.role.access : null
        if (this.state.posts != null) {
            const { t } = this.props
            return (
                <div id="modal_task_edit" className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('todo:editTask')}</span>
                        </div>
                        <br />
                        <form className="p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('todo:task')} *</label>
                                            <Input name="title" onChange={this._handleChange} placeholder={t('todo:task')} type="text" value={this.state.title} />
                                            {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('common:description')} *</label>
                                            <TextArea name="description" onChange={this._handleChange} placeholder={t('todo:taskdescription')} type="text" value={this.state.description} />
                                            {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('common:duedate')} *</label>
                                            <DatePicker placeholderText={t('common:selectDueDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.due_date} selected={this.state.due_date} onChange={this._setDueDate} />
                                            {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.due_date != '' ? (convertADtoBS(this.state.due_date) != null ? convertADtoBS(this.state.due_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                            {this.state.errors.due_date && <label className="error text-danger">{this.state.errors.due_date}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <div className="form-check">
                                                <label className="form-title" htmlFor="select-remarks">
                                                    {t('todo:OnlineSubmission')}
                                                </label>
                                                <Checkbox id="select-remarks" onChange={this._handleChange} className="" name={t('attendance:remarks')} value={this.state.remarks} />
                                            </div>
                                        </div>
                                    </div>
                                    {AccessModule.checkSubAccess([AccessModule.STUDENT], role) && access.includes(AccessModule.Module_Student_Management) && (
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group">
                                                <div className="form-check">
                                                    <label htmlFor="select-subject" className="form-title">
                                                        {t('common:selectSubject')} *
                                                    </label>
                                                    <Checkbox onChange={this._handleChange} className="" name="isSubject" value={this.state.isSubject} id="select-subject" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="add-option-exam">
                                        <UserFilter selectedClass={this.state.selectedClass} showSubject={this.state.isSubject} resetOnChange={this.state.isSubject} onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} onSubjectSelect={this._selectSubject} selectedStudent={this.state.student} selectedEmployee={this.state.employee} selectedSubject={this.state.subject} />
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-12 float-left">
                                            <label className="form-title">{t('todo:uploadresource')}</label>
                                            <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                                {this.previewDocument()}
                                                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV todo-file-upload">
                                                    <Files className="files-dropzone" onError={this.onFilesError} onChange={this._uploadFile} accepts={['image/*', 'video/*', 'audio/*', '.pdf', '.docx', '.pptx', '.xlsx', '.doc', '.ppt', '.xls']} multiple maxFiles={10} maxFileSize={200000000} minFileSize={0} clickable>
                                                        {t('todo:addresource')}
                                                    </Files>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('updateBtn')} main={true} />
                                    <button id="close_edit" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }
    }

    getDeleteModal() {
        const { t } = this.props
        if (this.state.posts != null) {
            if (this.state.posts.gallery != null) {
                const data = this.state.posts.gallery.media.map((item, index) => {
                    const { t } = this.props
                    return (
                        <div key={index} id={'modal_delete_' + item.id} data-id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                            <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                                <div className="header modal-title">
                                    <button type="button" className="close" data-dismiss="modal">
                                        ×
                                    </button>
                                    <span>{t('warning')} !!!</span>
                                </div>
                                <br />
                                <div className="modal-content">
                                    <div className="pop-main-content">
                                        <p className="modal-question">{t('todo:taskmsg6')}</p>
                                        <p className="delete-file-name">"{item.name}"</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="text-center form-group">
                                        <Button type="submit" text={t('delete')} link={item.id} main={true} onClick={this._deleteMediaFile} />
                                        <button id={'close_media_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                            {t('modal cancel')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                return data
            }
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="homework" />
                <div className="main-content">
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-box">
                        <div className="main-head-option">
                            <ul className="list-inline breadcrum clearfix">
                                <li>
                                    <a href={route.todo.view}>{t('todo:todo')} &gt; </a>
                                </li>
                                <li>{t('common:details')}</li>
                            </ul>
                            <div className="row clearfix">
                                <div className="col-md-5 float-left">
                                    <h5 className="main-title heading5">{this.state.posts != null ? this.state.posts.title : t('todo:todo')} :</h5>
                                </div>
                                {this.state.posts != null && this.state.posts.author.id == this.props.school.id && (
                                    <div className="col-md-7 calendar-module float-left text-right">
                                        <div className="display-inline-block">
                                            <Button type="button" text={t('EditBtn')} modal="modal_task_edit" main={true} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-lg-7">
                                <Post loader={true} create={false} reload={this.state.reload} postId={this.state.id} onDeleteRoute={route.todo.view} onChange={this._loadPost} />
                            </div>
                            <div className="col-lg-3">
                                {this.getRemarks()}
                                {this.getSubmit()}
                                {this.state.posts != null && this.state.posts.gallery != null && this.state.posts.gallery.media.length > 0 && (
                                    <div className="col-md-12">
                                        <div className="todo-box">
                                            <h5>{t('todo:resources')}</h5>
                                            <div className="box-content-list form-group">{this.getResources()}</div>
                                            {this.state.posts != null && this.state.posts.gallery != null && this.state.posts.gallery.media.length > 3 && (
                                                <a data-toggle="modal" data-target="#resource_modal" className="box-view-all">
                                                    {t('viewall')} &nbsp;
                                                    <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {this.state.posts != null && this.state.posts.access_information != null && (
                                    <div className="col-md-12">
                                        <div className="todo-box">
                                            <h5>{t('todo:participants')}</h5>
                                            <div className="box-content-list form-group">{this.getParticipants()}</div>
                                            {this.state.posts.access_information.length > 3 && (
                                                <a data-toggle="modal" data-target="#participant_modal" className="box-view-all">
                                                    {t('viewall')} &nbsp;
                                                    <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="sm" />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.getParticipantsModal()}
                {this.getResourceModal()}
                {this.state.posts != null && this.state.posts.author.id == this.props.school.id && (
                    <Fragment>
                        {this.getTaskEdit()}
                        {this.getDeleteModal()}
                    </Fragment>
                )}
                {this.state.posts != null && this.state.posts.author.id != this.props.school.id && this.submitHomework()}
                {this.state.posts != null && this.state.posts.author.id == this.props.school.id && (
                    <Fragment>
                        {this.checkTask()}
                        {this.getApproval()}
                    </Fragment>
                )}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_task_edit') {
                //component._loadPost(component.state.posts)
                let uploads = component.state.uploads
                for (let i = uploads.length; i >= 0; i--) {
                    uploads.pop()
                }
                component._handleChange('uploads', uploads)
                component._handleChange('media', [])
                const errors = {
                    title: null,
                    description: null,
                    due_date: null,
                }
                component._handleChange('errors', errors)
            } else if (id == 'submit-task') {
                let uploads = component.state.uploadFile
                for (let i = uploads.length; i >= 0; i--) {
                    uploads.pop()
                }
                // component.state.uploadFile.map((item, index) => {
                //     uploads.pop()
                // })
                // uploads.pop()
                component._handleChange('uploadFile', uploads)
                component._handleChange('file', [])
                component._handleChange('submit_description', '')
                const submit_errors = {
                    submit_description: null,
                }
                component._handleChange('submit_errors', submit_errors)
            } else {
                const dataId = $(this).attr('data-id')
                if (dataId == 'verify') {
                    const remarks = $(this).attr('data-remarks')
                    component._handleChange('homework_remarks', remarks)
                }
            }
        })
    }
}

const TodoView = withTranslation()(connect(mapStateToProps)(TodoViewComponent))

export { TodoView }
