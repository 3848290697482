import { faTruckMonster } from '@fortawesome/free-solid-svg-icons'
import {Component} from 'react'
import {Dispath} from 'react-redux'


type Props = {
    dispatch: Dispath<any>
}
type State = {}

export class AbstractConstantCustomFieldViewComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            tableData:[
                {
                    customfield:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:'constant:customfield'
                    },
                    type:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:"attendance:type"
                    },
                    module:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:"course:module"
                    },
                    action:{
                        field:'button',
                        route:true,
                        sortable:false,
                        translate:'activity:action',
                    }
                }
            ]
        }
    }
    compnentDidMount(){}


}

export function mapStateToProps(state:Object){
    return {
        token: state.token,
    }
}