import { Component } from 'react'

export class AbstractColorPickerComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            value: this.props.value ? this.props.value : '#000000',
        }
        this._onChange = this._onChange.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value ? this.props.value : '#000000' })
        }
    }

    _onChange(color) {
        this.setState({ value: color }, () => {
            if (this.props.onChange != null) {
                this.props.onChange(this.props.name, color)
            }
        })
    }
}
