import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniJobDetailComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import { Input, Button, TextArea } from '~/components/form'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import companyLogo from '~/assets/images/company_logo.png'
import { Loading } from '~/components/loader'
import * as JOB_TYPE from '~/helper/constant/job_type'
import * as UserType from '~/helper/constant/user_type'
import $ from 'jquery'
import { convertADtoBS } from '../../../../helper/function'
class AlumniJobDetailComponent extends AbstractAlumniJobDetailComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.delete = this.delete.bind(this)
        this.apply = this.apply.bind(this)
    }

    delete(e) {
        e.preventDefault()
        $('#close_delete').trigger('click')
        super.delete(e)
    }

    apply(e) {
        e.preventDefault()
        $('#close_apply').trigger('click')
        super.apply(e)
    }

    applyModal() {
        const { t } = this.props
        return (
            <div id="modal_apply" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-md pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:applyJob')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p>{t('alumni:jobmsg1')}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('common:apply')} main={true} onClick={this.apply} />
                                <button id="close_apply" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    deleteModal() {
        const { t } = this.props
        return (
            <div id="modal_delete" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-md pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:deleteJob')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p>{t('alumni:jobmsg2')}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Yes" main={true} onClick={this.delete} />
                                <button id="close_delete" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t, school } = this.props
        const job = this.state.job
        let profile = null
        let name = null
        let experience = null
        let job_type = null
        let status = null
        if (job != null) {
            profile = job.alumni_company.profile
            name = job.alumni_company.name.charAt(0)
            job_type = JOB_TYPE.jobTypeList.find((item) => item.value == job.type)
            experience = JOB_TYPE.exprienceList.find((item) => item.value == job.experience)
            status = job.applicant_list.includes(this.props.school.id) ? 'Applied' : job.viewed_list.includes(this.props.school.id) ? 'Viewed' : null
        }
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="alumniJobVacancy" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.alumni.job.menu}>{t('alumni:jobs')} > </a>
                            </li>
                            <li>
                                <a href={route.alumni.job.view}>{t('alumni:vacany')} > </a>
                            </li>
                            <li>{t('alumni:jobs')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">
                                    {' '}
                                    <div className="sidebar-icon arrow-left cursor" onClick={() => this.props.history.goBack()}>
                                        {SVG.sidebarScroll('#333', 1)}
                                    </div>
                                    &nbsp;{t('alumni:jobDetail')}:
                                </h5>
                            </div>
                        </div>
                    </div>
                    {job != null && (
                        <Fragment>
                            {status == 'Applied' && (
                                <div className="row">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="alert alert-primary" role="alert">
                                            {t('alumni:appliedJobMsg')}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="job-detail-wrap">
                                <div className="job-detail">
                                    <div className="vacancy-logo">
                                        {profile != null && <img src={profile} alt="post-user" />}
                                        {profile == null && <div className="alumni-job">{name.charAt(0)}</div>}
                                    </div>
                                    <div className="job-info">
                                        <h5>{job.post.title}</h5>
                                        <p>{job.alumni_company.name}</p>
                                        <span>{job.alumni_designation.name}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="job-description">
                                <hr />
                                <h5>{t('alumni:companyDescription')}</h5>
                                <hr />
                                <p>{job.alumni_company.description}</p>
                            </div>

                            <div className="job-description">
                                <hr />
                                <h5>{t('alumni:jobDescription')}</h5>
                                <hr />

                                <p>{job.post.description}</p>
                            </div>

                            <div className="job-description">
                                <hr />
                                <h5>{t('alumni:jobSpecification')}</h5>
                                <hr />

                                <p>{job.job_specification}</p>
                            </div>

                            <hr />

                            <div className="job-description-list">
                                <p>
                                    <b>{t('alumni:faculty')} : </b> <span class="teacher-name"> {job.alumni_group.name}</span>
                                </p>
                                <p>
                                    <b>{t('alumni:jobType')} : </b> <span class="teacher-name"> {job_type != null ? job_type.label : 'N/A'}</span>
                                </p>
                                <p>
                                    <b>{t('alumni:experience')} :</b> <span class="teacher-name"> {experience != null ? experience.label : 'N/A'}</span>
                                </p>
                                <p>
                                    <b>{t('alumni:vacancyNumber')} :</b> <span class="teacher-name"> {job.no_of_vacancy}</span>
                                </p>
                                <p>
                                    <b>{t('alumni:salary')} :</b> <span class="teacher-name"> {job.salary}</span>
                                </p>
                                <p>
                                    <b>{t('alumni:negotiable')} : </b> <span class="teacher-name"> {job.isNegotiable == true ? 'Yes' : 'No'}</span>
                                </p>
                                <p>
                                    <b>{t('common:duedate')} : </b> <span class="teacher-name"> {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && job.post.to_date != null ? (convertADtoBS(job.post.to_date) != null ? convertADtoBS(job.post.to_date) + ' BS' : 'Invalid Date Range') : job.post.to_date}</span>
                                </p>
                            </div>

                            <hr />

                            <div className="form-button text-left">
                                {this.props.school.user_type == UserType.Alumni && status != 'Applied' && (
                                    <button type="button" className="main-button" data-toggle="modal" data-target="#modal_apply">
                                        {t('common:apply')}
                                    </button>
                                )}
                                {this.props.school.user_type != UserType.Alumni && (
                                    <button type="button" className="cancel-button danger" data-toggle="modal" data-target="#modal_delete">
                                        {t('common:DeleteBtn')}
                                    </button>
                                )}
                            </div>
                        </Fragment>
                    )}
                    {this.state.job == null && (
                        <p className="no-data">
                            {SVG.nodata()}
                            {t('common:table.nodata')}.
                        </p>
                    )}
                </div>

                {this.applyModal()}
                {this.deleteModal()}
            </div>
        )
    }
}

const AlumniJobDetail = withTranslation()(connect(mapStateToProps)(AlumniJobDetailComponent))

export { AlumniJobDetail }
