import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { editAccountTax, getAccountTax } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractAccountTaxEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            tax_name: null,
            tax_amount: null,
            classList: [],
            selectedClass: null,
            defaultClass: null,
            errors: {
                tax_name: null,
                tax_amount: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        this.loadTaxDetail()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadTaxDetail() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAccountTax(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                // const discountData = response.data.bill_particular;
                const classAry = []
                const item = response.data.tax
                this.setState({
                    tax_name: item.name,
                    tax_amount: item.rate,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    onSave() {
        let hasError = false
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)

        if (validate.length == 0) {
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            const data = {
                name: this.state.tax_name,
                rate: this.state.tax_amount,
            }
            editAccountTax(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    window.location = route.admin.accounting.constant.tax.view
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
