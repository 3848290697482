const tokenReducer = (state = null, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return action.token
        case 'SIGN_OUT':
            return null
        case 'RESET':
            return null
        default:
            return state
    }
}

export default tokenReducer
