import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewPost = (isAlumni, token, page = null) => {
    return new Promise(function (resolve, reject) {
        try {
            const path = isAlumni == true ? 'alumni' : 'normal'
            let post = 'post/activity/' + path
            if (page != null) {
                post = 'post/activity/' + path + '?page=' + page
            }
            get(post, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const searchPost = (isAlumni, data, token, page = null) => {
    return new Promise(function (resolve, reject) {
        try {
            const path = isAlumni == true ? 'alumni' : 'normal'
            let url = 'post/search/' + path
            if (page != null) {
                url = 'post/search/' + path + '?page=' + page
            }
            post(url, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addPost = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('post/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getPost = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editPost = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('post/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const togglePost = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const togglePostComment = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/comment/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const likePost = (token, id) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/like/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deletePost = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteMedia = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('post/media/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCalendar = (from, to, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar/' + from + '/' + to, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const metaTag = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('meta-tag', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
