import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getPackage, editPackage } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { PackageDTO } from '~/model'
import { currency } from '~/helper/constant/currency'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage, getCurrency } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    id: String,
    name: String,
    code: String,
    student: Integer,
    teacher: Integer,
    storage: Double,
    price: Double,
    currency: String,
    defaultCurrency: String,
    show: Boolean,
    access: Array,
    errors: Object,
    packages: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractPackageEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            name: '',
            code: '',
            student: 0,
            teacher: 0,
            storage: 0,
            price: 0,
            currency: 'INR',
            defaultCurrency: currency[31],
            show: true,
            access: this.loadModules(),
            errors: {
                name: null,
                code: null,
                student: null,
                teacher: null,
                storage: null,
                price: null,
                currency: null,
            },
            packages: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this._selectModules = this._selectModules.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getPackage(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const packages: PackageDTO = response.data.package
                this.setState({
                    id: packages.id,
                    name: packages.name,
                    code: packages.code,
                    student: parseFloat(packages.student),
                    teacher: parseFloat(packages.teacher),
                    storage: parseFloat(packages.storage),
                    price: parseFloat(packages.price),
                    currency: packages.currency,
                    defaultCurrency: getCurrency(packages.currency),
                    show: packages.show,
                    access: packages.access,
                    packages,
                })
            }
        })
    }

    loadModules() {
        const access = []
        modules.access_management.map((item, index) => {
            access.push(item.value)
        })
        return access
    }

    _selectModules(key, value) {
        const access = this.state.access
        if (value) {
            modules.access_management
                .filter((item) => item.code == key)
                .map((item, index) => {
                    access.push(item.value)
                })
            this.setState({ access })
        } else {
            let filter = []
            modules.access_management
                .filter((item) => item.code == key)
                .map((item, index) => {
                    access
                        .filter((data) => data != item.value)
                        .map((result, index) => {
                            filter.push(result)
                        })
                })

            this.setState({ access: filter })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data: PackageDTO = {
                name: this.state.name,
                student: this.state.student,
                teacher: this.state.teacher,
                storage: this.state.storage,
                currency: this.state.currency,
                price: this.state.price,
                show: this.state.show,
                access: this.state.access,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editPackage(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.package.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
