const HTTP = 'http://'
const HTTPS = 'https://'

//LOCAL CONFIGURATION
const LOCAL_SERVER_DOMAIN = 'localhost:8000'
const LOCAL = 'localhost:8080/'
const LOCAL_API = HTTP + LOCAL_SERVER_DOMAIN + '/api/'
const LOCAL_APP = HTTP + LOCAL
const LOCAL_SOCKET_URL = HTTPS + 'chat.classpedia.io/'
const LOCAL_ACCOUNTING_API = 'https://account.alphalaunch.net/api/'

//TEST CONFIGURATION
const TEST_SERVER_DOMAIN = 'alphalaunch.net'
const TEST = 'app.alphalaunch.net/'
const TEST_API = HTTPS + TEST_SERVER_DOMAIN + '/api/'
const TEST_APP = HTTPS + TEST
const TEST_SOCKET_URL = HTTPS + 'chat.classpedia.io/'
const TEST_ACCOUNTING_API = 'https://account.alphalaunch.net/api/'

//LIVE CONFIGURATION
const LIVE_SERVER_DOMAIN = 'classpedia.io'
const LIVE = 'app.classpedia.io/'
const LIVE_API = HTTPS + LIVE_SERVER_DOMAIN + '/api/'
const LIVE_APP = HTTPS + LIVE
const LIVE_SOCKET_URL = HTTPS + 'chat.classpedia.io/'
const LIVE_ACCOUNTING_API = 'https://account.alphalaunch.net/api/'

//MODE SETTING

const APP_VERSION = '29.0'
const MODE = 'TEST'

let API_URL = ''
let APP_URL = ''
let SOCKET_URL = ''
let DOMAIN = ''
let HTTP_PROTOCOL = ''
let ACCOUNTING_API = ''

if (MODE == 'LOCAL') {
    API_URL = LOCAL_API
    APP_URL = LOCAL_APP
    SOCKET_URL = LOCAL_SOCKET_URL
    DOMAIN = LOCAL
    HTTP_PROTOCOL = HTTP
    ACCOUNTING_API = LOCAL_ACCOUNTING_API
} else if (MODE == 'TEST') {
    API_URL = TEST_API
    APP_URL = TEST_APP
    SOCKET_URL = TEST_SOCKET_URL
    DOMAIN = TEST_SERVER_DOMAIN
    HTTP_PROTOCOL = HTTPS
    ACCOUNTING_API = TEST_ACCOUNTING_API
} else {
    API_URL = LIVE_API
    APP_URL = LIVE_APP
    SOCKET_URL = LIVE_SOCKET_URL
    DOMAIN = LIVE_SERVER_DOMAIN
    HTTP_PROTOCOL = HTTPS
    ACCOUNTING_API = LIVE_ACCOUNTING_API
}

export { ACCOUNTING_API, API_URL, APP_URL, APP_VERSION, DOMAIN, HTTP_PROTOCOL, MODE, SOCKET_URL }

export function redirectDomain($domain, $token) {
    const local = HTTP + $domain + '.' + LOCAL + '#/account/' + $token
    const test = HTTPS + $domain + '.' + TEST_SERVER_DOMAIN + '/#/account/' + $token
    const live = HTTPS + $domain + '.' + LIVE_SERVER_DOMAIN + '#/account/' + $token
    if (MODE == 'LOCAL') {
        return local
    } else if (MODE == 'TEST') {
        return test
    } else {
        return live
    }
}

export function getSchoolDomain($domain) {
    const local = HTTP + $domain + '.' + LOCAL_SERVER_DOMAIN + '/'
    const test = HTTPS + $domain + '.' + TEST_SERVER_DOMAIN + '/'
    const live = HTTPS + $domain + '.' + LIVE_SERVER_DOMAIN + '/'
    if (MODE == 'LOCAL') {
        return local
    } else if (MODE == 'TEST') {
        return test
    } else {
        return live
    }
}
