import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getGallery } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractGalleryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            gallery: null,
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loadGallery()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadGallery() {
        this._handleChange('loading', true)

        getGallery(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    gallery: response.data.media,
                })
            } else {
                toastMessage('error', response.message)
                redirect(route.activity, this.props.history)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
