export default class CouponDTO {
    id: integer
    days: integer
    package_id: integer
    school_id: integer
    status: integer
    code: String

    constructor(id, days, package_id, school_id, status, code) {
        this.id = id
        this.days = days
        this.package_id = package_id
        this.school_id = school_id
        this.status = status
        this.code = code
    }
}
