import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as Notification from '~/helper/constant/notification'
import * as validation from '~/helper/constant/validation'
import { viewNotificationSetting, saveNotificationSetting } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'
import DOMPurify from 'dompurify'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    notification: Array,
}

export class AbstractNotificationEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            notification: null,
            title: '',
            isEmail: true,
            isSms: true,
            isWeb: true,
            sms: '',
            email: '',
            isDefaultEmail: true,
            isDefaultSms: true,
            errors: { title: null, sms: null, email: null },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _handleChange(key, value) {
        this.setState({
            [key]: value,
        })
    }

    componentDidMount() {
        this.loadNotificationSetting()
    }

    loadNotificationSetting() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewNotificationSetting(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const module = Notification.getAll()
                const notification = module.find((item) => item.value == this.props.match.params.id)
                const data = response.data.notification_setting
                if (data != null) {
                    this.setState({
                        notification,
                        title: data.title,
                        isEmail: data.isEmail,
                        isSms: data.isSms,
                        isWeb: data.isWeb,
                        sms: data.sms,
                        email: data.email,
                        isDefaultEmail: data.isDefaultEmail,
                        isDefaultSms: data.isDefaultSms,
                    })
                } else {
                    this.setState({
                        notification,
                        title: notification != null ? notification.label : '',
                    })
                }
            } else {
                toastMessage('error', response.message)
                redirect(route.admin.setting.notification.view, this.props.history)
            }
        })
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        if (this.state.isDefaultEmail) {
            errors.email = null
        } else {
            if (this.state.email == '<p><br></p>') {
                errors.email = t('toaster:required')
            }
        }
        if (this.state.isDefaultSms) {
            errors.sms = null
        }
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                isEmail: this.state.isEmail,
                isSms: this.state.isSms,
                isWeb: this.state.isWeb,
                sms: this.state.sms,
                email: this.state.email,
                isDefaultEmail: this.state.isDefaultEmail,
                isDefaultSms: this.state.isDefaultSms,
                type: this.props.match.params.id,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            saveNotificationSetting(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.setting.notification.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

const AbstractNotificationEdit = withTranslation()(AbstractNotificationEditComponent)

export { AbstractNotificationEdit }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
