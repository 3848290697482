import $ from 'jquery'
import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { convertADtoBS } from '../../../../../helper/function'
import { AbstractStudentAtttendanceMenuComponent, mapStateToProps } from './abstract'
class StudentAtttendanceMenuComponent extends AbstractStudentAtttendanceMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
        this._selectSection = this._selectSection.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectAttendanceType = this._selectAttendanceType.bind(this)
        this._setToDate = this._setToDate.bind(this)
    }

    _setToDate(data) {
        this._handleChange('date', data)
    }

    _selectSubject(data) {
        super.setState({
            selectedSubject: data.value,
            defaultSubject: data,
        })
    }

    _selectSection(data) {
        super.setState({
            selectedSection: data.value,
            defaultSection: data,
        })
    }

    _selectClass(data) {
        super.setState(
            {
                selectedClass: data.value,
                defaultClass: data,
            },
            () => {
                super.loadSection(data.value)
                super.loadSubject(data.value)
            }
        )
    }

    _selectAttendanceType(data) {
        super.setState({
            selectedAttendanceType: data.value,
            defaultAttendanceType: data,
        })
        if (data.value == 0) {
            $('#display-subject').show()
        } else {
            $('#display-subject').hide()
        }
    }

    render() {
        const { t } = this.props
        const academic_year = this.props.school?.school?.academic_year
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="attendance" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.attendance.menu}>{t('attendance:attendance')} > </a>
                            </li>
                            <li>{t('attendance:menu')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('attendance:student_attendance')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <form>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('attendance:attendanceDate')} *</label>
                                        <DatePicker minDate={moment(academic_year.from_date).toDate()} maxDate={moment().toDate()} placeholderText={t('attendance:attendanceDate')} className="form-control" defaultValue={this.state.date} selected={this.state.date} onChange={this._setToDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.date != null ? (convertADtoBS(this.state.date) != null ? convertADtoBS(this.state.date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.errors.date && <label className="error text-danger">{this.state.errors.date}</label>}
                                    </div>
                                </div>
                            )}
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom) && (
                                <div className="row clearfix">
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label htmlFor="departmentclass" className="form-title">
                                            {t('attendance:attendance_type')} *
                                        </label>
                                        <Select name="type" options={this.state.attendance_type} value={this.state.defaultAttendanceType} placeholder={t('attendance:attendance_type')} searchable={true} onChange={this._selectAttendanceType} />
                                        {this.state.errors.selectedType && <label className="error text-danger">{t('toaster:required')}*</label>}
                                    </div>
                                </div>
                            )}

                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('classes:classTitle')} *
                                    </label>
                                    <Select name="class" options={this.state.classAry} value={this.state.defaultClass} placeholder={t('classes:classTitle')} searchable={true} onChange={this._selectClass} />
                                    {this.state.errors.selectedClass && <label className="error text-danger">{t('toaster:required')}*</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('classes:section.title')} *
                                    </label>
                                    <Select name="section" options={this.state.sectionAry} value={this.state.defaultSection} placeholder={t('classes:section.title')} searchable={true} onChange={this._selectSection} />
                                    {this.state.errors.selectedSection && <label className="error text-danger">{t('toaster:required')}*</label>}
                                </div>
                            </div>
                            <div className="row clearfix" id="display-subject">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('classes:subject.title')} *
                                    </label>
                                    <Select name="subject" options={this.state.subjectAry} value={this.state.defaultSubject} placeholder={t('classes:subject.title')} searchable={true} onChange={this._selectSubject} />
                                    {this.state.selectedSubjectError && <label className="error text-danger">{t('toaster:required')}*</label>}
                                </div>
                            </div>
                            <div className="form-button text-left">
                                <button type="button" className="main-button" onClick={this.getURL}>
                                    {t('common:searchBtn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const StudentAtttendanceMenu = withTranslation()(connect(mapStateToProps)(StudentAtttendanceMenuComponent))

export { StudentAtttendanceMenu }
