import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewRole, activeRole } from '~/services/api'
import { EmployeeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { employee_type } from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'

import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    customRoleAry: Array,
    userTypeAry: Array,
    selectedClass: String,
    errors: Object,
}

export class AbstractEmployeeAttendanceMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            userTypeAry: [],
            customRoleAry: [],
            defaultUserType: null,
            defaultRole: null,
            selectedRole: 0,
            selectedUserType: employee_type[0],
            date: moment().toDate(),
            errors: {
                type: null,
                role: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.getURL = this.getURL.bind(this)
    }

    componentDidMount() {
        this.loadRole()
        this.loadUserTypes()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    getURL() {
        let selectedRole = 0
        if (this.state.selectedUserType == 1) {
            selectedRole = this.state.selectedRole
        }
        redirect(route.admin.attendance.employee.view + '/' + this.state.selectedUserType + '/' + selectedRole + '/' + moment(this.state.date).format('yyyy-MM-DD'), this.props.history)
    }

    loadUserTypes() {
        this._handleChange('userTypeAry', employee_type)
        this._handleChange('defaultUserType', employee_type[0])
        this._handleChange('selectedUserType', employee_type[0].value)
        if (employee_type[0].value != 1) {
            this.setState({
                defaultRole: null,
                selectedRole: 0,
            })
        }
    }

    loadRole() {
        this._handleChange('loading', true)
        activeRole(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let customRoleAry = []
            if (response.code == API_SUCCESS) {
                response.data.role.map((item, index) => {
                    customRoleAry.push({ value: item.id, label: item.name })
                })
                this.setState({
                    defaultRole: customRoleAry[0],
                    selectedRole: customRoleAry[0].value,
                    customRoleAry,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
