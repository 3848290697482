import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractTimetableAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { days } from '~/helper/constant/days'
import Select from 'react-select'
import { Checkbox, Button, Input } from '~/components/form'
import { Loading } from '~/components/loader'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import DatePicker from 'react-datepicker'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
class TimetableAddComponent extends AbstractTimetableAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectSubject = this._selectSubject.bind(this)
        this._selectDays = this._selectDays.bind(this)
        this._setStartTime = this._setStartTime.bind(this)
        this._setEndTime = this._setEndTime.bind(this)
        this._selectTeacher = this._selectTeacher.bind(this)
        this.getModal = this.getModal.bind(this)
    }

    _setStartTime(data) {
        this._handleChange('start_time', data)
    }

    _setEndTime(data) {
        this._handleChange('end_time', data)
    }

    _selectSubject(data) {
        this._handleChange('subject_id', data.value)
        this._handleChange('selectedSubject', data)
    }

    _selectTeacher(data) {
        this._handleChange('teacher_id', data.value)
        this.setState(
            {
                selectedTeacher: data,
            },
            () => {
                this.loadTeacherAvailabilty()
            }
        )
    }

    _selectDays(data) {
        const selectedDays = []
        const days = []
        if (data) {
            data.map((item) => {
                days.push(item.value)
                selectedDays.push(item)
            })
        }
        this._handleChange('days', days)
        this._handleChange('selectedDays', selectedDays)
    }

    getModal() {
        const { t } = this.props
        return (
            <div id="check_availability" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('timetable:availableTeacher')}</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('timetable:teacher')} *</label>
                                    <Input name="name" disabled="disabled" type="text" value={this.state.selectedTeacher.label} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <Table data={this.state.teacherAvailabilty} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="timetable" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option no-border pb-0">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.timetable.menu}>{t('timetable:timetable')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.timetable.view + '/' + this.props.match.params.id}>{t('common:viewBtn')} &gt; </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                    </div>
                    <div className="main-form">
                        <div className="row clearfix timetable-page">
                            <div className="col-md-8 form-group">
                                <span className="sub-main-title">
                                    {this.state.classes && this.state.classes.name} - {this.state.section && this.state.section.name}{' '}
                                </span>
                                <h5 className="main-title heading5">
                                    {SVG.addeye()}
                                    {t('timetable:calendar')} :
                                </h5>
                                <br />
                                <FullCalendar
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'timeGridWeek,timeGridDay,listWeek',
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                    initialView="timeGridWeek"
                                    slotMinTime={this.state.timetable_start_time}
                                    slotMaxTime={this.state.timetable_end_time}
                                    slotDuration="00:15:00"
                                    allDaySlot={false}
                                    events={this.state.events}
                                />
                            </div>
                            <div className="col-md-4 form-group">
                                <span className="sub-main-title">&nbsp;</span>
                                <h5 className="main-title heading5">
                                    {SVG.addnotification()}
                                    {t('timetable:addTimetable')} :
                                </h5>
                                <br />
                                <form className="timetable-form">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('classes:subject.title')} *</label>
                                            <Select name="type" options={this.state.subject} placeholder={t('classes:subject.title')} value={this.state.selectedSubject} searchable={true} onChange={this._selectSubject} />
                                            {this.state.errors.subject_id && <label className="error text-danger">{this.state.errors.subject_id}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('common:days')} *</label>
                                            <Select name="type" options={days} placeholder={t('timetable:selectDaysPlaceholder')} searchable={true} value={this.state.selectedDays} isMulti onChange={this._selectDays} />
                                            {this.state.errors.days && <label className="error text-danger">{this.state.errors.days}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('common:start_time')} *</label>
                                            <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('timetable:selectStartTimePlaceholder')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.start_time} selected={this.state.start_time} onChange={this._setStartTime} />
                                            {this.state.errors.start_time && <label className="error text-danger">{this.state.errors.start_time}</label>}
                                        </div>
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('common:end_time')} *</label>
                                            <DatePicker showTimeSelect showTimeSelectOnly placeholderText={t('timetable:selectEndTimePlaceholder')} dateFormat="hh:mm a" timeFormat="hh:mm a" timeIntervals={15} className="form-control" defaultValue={this.state.end_time} selected={this.state.end_time} onChange={this._setEndTime} />
                                            {this.state.errors.end_time && <label className="error text-danger">{this.state.errors.end_time}</label>}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <label className="form-title">{t('timetable:subjectTeacher')} *</label>
                                            <Select name="type" options={this.state.teacher} placeholder={t('timetable:subjectTeacher')} searchable={true} value={this.state.selectedTeacher} onChange={this._selectTeacher} />
                                            <a data-toggle="modal" data-target="#check_availability" className="small-text">
                                                {t('timetable:checkTeacher')}
                                            </a>
                                            <br />
                                            {this.state.errors.teacher_id && <label className="error text-danger">{this.state.errors.teacher_id}</label>}
                                        </div>
                                    </div>
                                    {/* <div className="form-check">
                                        <label className="form-title" htmlFor="live-class">
                                            {t('timetable:liveClass')}
                                        </label>
                                        <Checkbox id="live-class" onChange={this._handleChange} name="live_class_status" value={this.state.live_class_status} />
                                    </div> */}
                                    <div className="form-button text-left">
                                        <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.getModal()}
            </div>
        )
    }
}

const TimetableAdd = withTranslation()(connect(mapStateToProps)(TimetableAddComponent))

export { TimetableAdd }
