import * as AccessModule from '~/helper/constant/modules'

export const Rule_Attendance = 0
export const Rule_Attendance_Conduct = 1
export const Rule_Todo_Assign = 2
export const Rule_Todo_Submit = 3
export const Rule_Course = 4
export const Rule_Exam = 5
export const Rule_Fee_Payer = 6
export const Rule_Fee_Receiver = 7
export const Rule_Alumni_Job_Apply = 8

export const medalRules = [
    {
        module: AccessModule.Module_School_Management,
        value: Rule_Attendance,
        label: 'Assign XP when the user is present on each day basis.',
        translate: 'gamification:medalmsg1',
    },
    {
        module: AccessModule.Module_School_Management,
        value: Rule_Attendance_Conduct,
        label: 'Assign XP when the user takes attendance on each day basis.',
        translate: 'gamification:medalmsg2',
    },
    {
        module: AccessModule.Module_Task_Management,
        value: Rule_Todo_Assign,
        label: 'Assign XP when the user assigns the task.',
        translate: 'gamification:medalmsg4',
    },
    {
        module: AccessModule.Module_Task_Management,
        value: Rule_Todo_Submit,
        label: 'Assign XP when the user completes the assigned task.',
        translate: 'gamification:medalmsg3',
    },
    {
        module: AccessModule.Module_Course_Management,
        value: Rule_Course,
        label: 'Assign XP when the user completes the module of the course.',
        translate: 'gamification:medalmsg5',
    },
    {
        module: AccessModule.Module_Exam_Management,
        value: Rule_Exam,
        label: 'Assign XP when the user completes the online exam.',
        translate: 'gamification:medalmsg6',
    },
    // {
    //     module: AccessModule.Module_Accounting,
    //     value: Rule_Fee_Payer,
    //     label: 'Assign XP when the user pays the fee.',
    //     translate: 'gamification:medalmsg7',
    // },
    // {
    //     module: AccessModule.Module_Accounting,
    //     value: Rule_Fee_Receiver,
    //     label: 'Assign XP when the user receives the fee.',
    //     translate: 'gamification:medalmsg8',
    // },
    {
        module: AccessModule.Module_Alumni,
        value: Rule_Alumni_Job_Apply,
        label: 'Assign XP when the user applies for the job.',
        translate: 'gamification:medalmsg9',
    },
]
