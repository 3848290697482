import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getEvent = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/event', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addEvent = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('calendar-management/event/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addEventSchedule = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('calendar-management/event/schedule', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editEventSchedule = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('calendar-management/event/schedule/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleEventPost = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/event/post-event/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleEvent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/event/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteEvent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('calendar-management/event/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
