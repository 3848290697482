import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '~/components/form'
import { AbstractNotificationComponent, mapStateToProps } from './abstract_notification'
import $ from 'jquery'
import * as SVG from '~/assets/svg'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import * as route from '~/helper/constant/route'

class NotificationComponent extends AbstractNotificationComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.notificationElement = this.notificationElement.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    componentDidMount() {
        window.addEventListener('focus', this.onFocus)
        window.addEventListener('blur', this.onBlur)
        super.componentDidMount()
    }
    onFocus = () => {
        if (!this.state.focus) {
            this.setState({ focus: true })
            this.loadRecentNotifications()
        }
    }

    onBlur = () => {
        if (this.state.focus) {
            this.setState({ focus: false })
        }
    }

    notificationElement(notifications) {
        const data = notifications.map((item, index) => {
            const link = item.link ? item.link : '#!'
            const created_date = item.created_at ? moment(item.created_at).format('yyyy-MM-DD') : 'today'
            const type = item.type
            return (
                <a className="list-noti" key={index} href={route.notification + '/' + item.id}>
                    {SVG.defaultnoti()}

                    <p>{item.message}</p>
                    <ins>{created_date}</ins>
                </a>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <li>
                <div className="dropdown">
                    <button className="btn head-button dropdown-toggle " type="button" data-toggle="collapse" data-target="#notification_dropdown" onClick={() => this.markNotificationsSeen()}>
                        {SVG.notification()}
                        <span>{this.state.notificationCount}</span>
                    </button>
                    <div className="dropdown-menu notif-pop main main-border-radius main-background collapse-body" aria-labelledby="notification_dropdown" id="notification_dropdown">
                        <button type="button" className="close" data-toggle="collapse" data-target="#notification_dropdown">
                            &times;
                        </button>
                        <span>{t('header:notifications')}</span>
                        <div className="notif-dropdown">
                            {this.state.unreadNotifications.length > 0 && (
                                <div className="notif-list">
                                    <small>{t('header:unread')}</small>
                                    {this.notificationElement(this.state.unreadNotifications)}
                                </div>
                            )}
                            {this.state.readNotifications.length > 0 && (
                                <div className="notif-list read">
                                    <small>{t('header:read')}</small>

                                    {this.notificationElement(this.state.readNotifications)}
                                </div>
                            )}
                        </div>
                        {this.state.notifications.length > 0 && this.state.lastPage == false && (
                            <span className="box-view-all notification-more notification-view" onClick={() => this.loadAllNotifications()}>
                                {t('header:showAllNotifications')}
                            </span>
                        )}
                        {this.state.notifications.length == 0 && <div className="notification-text">{t('header:nonotifications')}</div>}
                    </div>
                </div>
            </li>
        )
    }
}

const Notification = withTranslation()(connect(mapStateToProps)(NotificationComponent))

export { Notification }
