import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractSettingComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
class SettingComponent extends AbstractSettingComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')}</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('common:settings')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                        {access.includes(AccessModule.Module_Exam_Management) && AccessModule.checkSubAccess([AccessModule.REPORT_CARD], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.reportcard.view)}>
                                        <a href={route.admin.setting.reportcard.view} className="folder-img">
                                            {SVG.studentcategory()}
                                        </a>
                                        <a href={route.admin.setting.reportcard.view} className="folder-name">
                                            {t('reportcard:reportcard')}
                                            <span> {t('reportcard:manageReportcard')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}

                        {access.includes(AccessModule.Module_Exam_Management) && AccessModule.checkSubAccess([AccessModule.GRADING_SETTING], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.grading.view)}>
                                        <a href={route.admin.setting.grading.view} className="folder-img">
                                            {SVG.studentcategory()}
                                        </a>
                                        <a href={route.admin.setting.grading.view} className="folder-name">
                                            {t('grading:grading')}
                                            <span> {t('grading:manageGrading')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}


                            {access.includes(AccessModule.Module_Gamification) && AccessModule.checkSubAccess([AccessModule.GAMIFICATION], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.gamification.view)}>
                                        <a href={route.admin.setting.gamification.view} className="folder-img">
                                            {SVG.studentcategory()}
                                        </a>
                                        <a href={route.admin.setting.gamification.view} className="folder-name">
                                            {t('gamification:gamification')}
                                            <span> {t('gamification:manageGamification')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            {access.includes(AccessModule.Module_Notification) && AccessModule.checkSubAccess([AccessModule.NOTIFICATION], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.notification.view)}>
                                        <a href={route.admin.setting.notification.view} className="folder-img">
                                            {SVG.studentcategory()}
                                        </a>
                                        <a href={route.admin.setting.notification.view} className="folder-name">
                                            {t('setting:notification')}
                                            <span> {t('setting:customizeYourNotificationMessage')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.subscription.view)}>
                                    <a href={route.admin.setting.subscription.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.setting.subscription.view} className="folder-name">
                                        {t('subscription:subscription')}
                                        <span> {t('subscription:chooseSub')}</span>
                                    </a>
                                </div>
                            </div>
                            {access.includes(AccessModule.Module_Invite) && AccessModule.checkSubAccess([AccessModule.INVITE], role) && (
                                <div className="float-left course-your">
                                    <div className="folder-list clearfix" onClick={() => (window.location = route.admin.setting.invite.view)}>
                                        <a href={route.admin.setting.invite.view} className="folder-img">
                                            {SVG.studentcategory()}
                                        </a>
                                        <a href={route.admin.setting.invite.view} className="folder-name">
                                            {t('invite:invite')}
                                            <span> {t('invite:sendInvitation')}</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Setting = withTranslation()(connect(mapStateToProps)(SettingComponent))

export { Setting }
