import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getMeetingUrl, toggleTeacherTimetable, toggleTimetable, toggleEvent } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { redirect } from '~/helper/function/util'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'

type Props = {
    school: Object,
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    url: String,
}

let check = ''
export class AbstractBigBlueButtonComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            url: null,
            status: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadMeeting = this.loadMeeting.bind(this)
    }

    componentDidMount() {
        this.loadMeeting()
        const component = this
        check = setTimeout(function () {
            component.loadMeeting()
        }, 10000)
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    loadMeeting() {
        this._handleChange('loading', true)
        getMeetingUrl(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        url: response.data.url,
                        bbb: response.data.bbb,
                        status: response.data.status == true ? true : false,
                    },
                    () => {
                        if (this.state.status == true) {
                            clearInterval(check)
                        }
                    }
                )
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
