import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { editCoupon, getCoupon, activePackage } from '~/services/api'
import { PackageDTO, CouponDTO, SchoolDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    days: integer,
    package_id: integer,
    packages: Object,
    schools: Object,
    defaultPackage: Object,
    errors: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractCouponEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            packages: [],
            defaultPackage: null,
            days: '',
            package_id: '',
            defaultPackage: {
                label: '',
                value: '',
            },
            errors: {
                days: null,
                package_id: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadPackages()
    }

    loadPackages() {
        const packages = []
        activePackage(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.package.map((item, index) => {
                    const result: PackageDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    packages.push(data)
                })
                this.setState({ packages }, () => {
                    this.getData()
                })
            }
        })
    }

    getData() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCoupon(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const coupon: CouponDTO = response.data.coupon
                const defaultPackage = {
                    label: response.data.coupon.package.name + ' - ' + response.data.coupon.package.code,
                    value: response.data.coupon.package.id,
                }
                this.setState({
                    id: coupon.id,
                    days: parseFloat(coupon.days),
                    school_id: coupon.school_id,
                    package_id: coupon.package_id,
                    school: coupon.school,
                    defaultPackage,
                })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        if (!this.state.school) {
            const errors = validation.required(Object.keys(this.state.errors), this.state)
            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    days: this.state.days,
                    package_id: this.state.package_id,
                }
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                editCoupon(this.props.match.params.id, data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        redirect(route.superadmin.coupon.view, this.props.history)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        } else {
            toastMessage('info', 'Coupon has already been redeemed by ' + this.state.school.name)
            redirect(route.superadmin.coupon.view, this.props.history)
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
