export const GENERAL = 0
export const LOGIN = 1
export const LOGOUT = 2
export const JOIN = 3
export const LEAVE = 4
export const PARTICIPANT = 5
export const CREATE_CHAT_ROOM = 6
export const CREATE_GROUP_CHAT_ROOM = 7
export const DELETE_GROUP_CHAT_ROOM = 8
export const SEND_MESSAGE = 9
export const EDIT_MESSAGE = 10
export const DELETE_MESSAGE = 11
export const COMMENT_ADD = 12
export const COMMENT_DELETE = 13
export const LIKE = 14
