import $ from 'jquery'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import * as AccessModule from '../../helper/constant/modules'
import { alumni_page, school_page } from '../../store/action/isAlumni'
import { AbstractSidebarComponent, mapStateToProps } from './abstract'
class SidebarComponent extends AbstractSidebarComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            currentHeight: 0,
            showScroll: false,
        }
        this.checkActive = this.checkActive.bind(this)
        this.scroll = this.scroll.bind(this)
    }

    componentDidMount() {
        const component = this
        this.loadScroll()
        $(window).resize(function () {
            component.loadScroll()
        })
    }

    loadScroll() {
        const scrollHeight = $('.sidebar ul')[0].scrollHeight
        const elementHeight = $('.sidebar ul').outerHeight()
        if (scrollHeight != elementHeight) {
            this.setState({
                showScroll: true,
            })
        } else {
            this.setState({
                showScroll: false,
            })
        }
    }

    scroll(e) {
        e.preventDefault()
        const height = $('.sidebar ul')[0].scrollHeight
        if (this.state.currentHeight == 0) {
            this.setState(
                {
                    currentHeight: height,
                },
                () => {
                    $('.sidebar ul').scrollTop(height)
                }
            )
        } else {
            this.setState(
                {
                    currentHeight: 0,
                },
                () => {
                    $('.sidebar ul').scrollTop(0)
                }
            )
        }
    }

    checkActive(id) {
        return super.checkActive(id)
    }

    getSuperAdmin() {
        const { t } = this.props
        return (
            <Fragment>
                <li>
                    <a href={route.superadmin.dashboard} className={this.checkActive('dashboard') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.home()}</div>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.package.view} className={this.checkActive('package') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.sidepackage()}</div>
                        <span>Packages</span>
                    </a>
                </li>
                <li>
                    <a
                        href={route.superadmin.sms_package.view}
                        className={this.checkActive('sms_package') + ' clearfix'}
                    >
                        <div className="sidebar-icon">{SVG.sidesmspackage()}</div>
                        <span>SMS Packages</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.school.view} className={this.checkActive('school') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.school()}</div>
                        <span>Schools</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.coupon.view} className={this.checkActive('coupon') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.coupon()}</div>
                        <span>Coupons</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.notice.view} className={this.checkActive('notice') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.notices()}</div>
                        <span>Notices</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.enquiries.view} className={this.checkActive('enquiry') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.enquiry()}</div>
                        <span>Enquiries</span>
                    </a>
                </li>
                <li>
                    <a href={route.superadmin.reset} className={this.checkActive('user') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.users()}</div>
                        <span>Reset Users</span>
                    </a>
                </li>
            </Fragment>
        )
    }

    getAdmin() {
        const { t, school } = this.props
        const { access } = this.state
        if (school != null) {
            const role = school.role != null ? school.role.access : null
            return (
                <Fragment>
                    {this.props.isAlumni == false && (
                        <Fragment>
                            {this.props.isAccount != true && (
                                <Fragment>
                                    <li>
                                        <a href={route.activity} className={this.checkActive('activity') + ' clearfix'}>
                                            <div className="sidebar-icon">{SVG.home()}</div>
                                            <span>{t('sidebar:activity')}</span>
                                        </a>
                                    </li>
                                    {AccessModule.checkSubAccess([AccessModule.ACADEMIC_YEAR], role) && (
                                        <li>
                                            <a
                                                href={route.admin.academic.view}
                                                className={this.checkActive('academic') + ' clearfix'}
                                            >
                                                <div className="sidebar-icon">{SVG.academic()}</div>
                                                <span>{t('sidebar:academics')}</span>
                                            </a>
                                        </li>
                                    )}
                                    {AccessModule.checkSubAccess(
                                        [
                                            AccessModule.CLASS_MANAGEMENT,
                                            AccessModule.SUBJECT_CATEGORY,
                                            AccessModule.SUBJECT,
                                        ],
                                        role
                                    ) &&
                                        access.includes(AccessModule.Module_School_Management) && (
                                            <li>
                                                <a
                                                    href={route.admin.school.menu}
                                                    className={this.checkActive('school') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.school()}</div>
                                                    <span>{t('sidebar:school')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess(
                                        [
                                            AccessModule.STUDENT_CATEGORY,
                                            AccessModule.STUDENT,
                                            AccessModule.ROLE_MANAGEMENT,
                                            AccessModule.EMPLOYEE_CATEGORY,
                                            AccessModule.EMPLOYEE,
                                        ],
                                        role
                                    ) &&
                                        (access.includes(AccessModule.Module_Student_Management) ||
                                            access.includes(AccessModule.Module_Employee_Management)) && (
                                            <li>
                                                <a
                                                    href={route.admin.user.menu}
                                                    className={this.checkActive('user') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.users()}</div>
                                                    <span>{t('sidebar:users')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {access.includes(AccessModule.Module_Gamification) && (
                                        <li>
                                            <a
                                                href={route.leaderboard.view}
                                                className={this.checkActive('leaderboard') + ' clearfix'}
                                            >
                                                <div className="sidebar-icon">{SVG.leaderboard()}</div>
                                                <span>{t('gamification:leaderboard')}</span>
                                            </a>
                                        </li>
                                    )}
                                    {access.includes(AccessModule.Module_Accounting) && (
                                        <li>
                                            <a
                                                href={route.admin.library.menu}
                                                className={this.checkActive('library') + ' clearfix'}
                                            >
                                                <div className="sidebar-icon">{SVG.courseIcon()}</div>
                                                <span>{t('library:library')}</span>
                                            </a>
                                        </li>
                                    )}
                                    {AccessModule.checkSubAccess(
                                        [AccessModule.STUDENT_ATTENDANCE, AccessModule.EMPLOYEE_ATTENDANCE],
                                        role
                                    ) &&
                                        (access.includes(AccessModule.Module_Student_Management) ||
                                            access.includes(AccessModule.Module_Employee_Management)) && (
                                            <li>
                                                <a
                                                    href={route.admin.attendance.menu}
                                                    className={this.checkActive('attendance') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.attendance()}</div>
                                                    <span>{t('sidebar:attendance')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {access.includes(AccessModule.Module_Calendar_Management) && (
                                        <li>
                                            <a
                                                href={route.calendar}
                                                className={this.checkActive('calendar') + ' clearfix'}
                                            >
                                                <div className="sidebar-icon">{SVG.calendar()}</div>
                                                <span>{t('sidebar:calendar')}</span>
                                            </a>
                                        </li>
                                    )}
                                    {AccessModule.checkSubAccess([AccessModule.TIMETABLE], role) &&
                                        access.includes(AccessModule.Module_School_Management) && (
                                            <li>
                                                <a
                                                    href={route.admin.timetable.menu}
                                                    className={this.checkActive('timetable') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.timetable()}</div>
                                                    <span>{t('sidebar:timetable')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.DRIVE], role) &&
                                        access.includes(AccessModule.Module_Drive) && (
                                            <li>
                                                <a
                                                    href={route.drive.view}
                                                    className={this.checkActive('drive') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.drive()}</div>
                                                    <span>{t('sidebar:drive')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.COURSE], role) &&
                                        access.includes(AccessModule.Module_Course_Management) && (
                                            <li>
                                                <a
                                                    href={route.course.menu}
                                                    className={this.checkActive('course') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.courseIcon()}</div>
                                                    <span>{t('sidebar:course')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.TODO], role) &&
                                        access.includes(AccessModule.Module_Task_Management) && (
                                            <li>
                                                <a
                                                    href={route.todo.view}
                                                    className={this.checkActive('homework') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.homework()}</div>
                                                    <span>{t('sidebar:todo')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess(
                                        [AccessModule.EXAM, AccessModule.QUESTION_BANK, AccessModule.RESULT],
                                        role
                                    ) &&
                                        access.includes(AccessModule.Module_Exam_Management) && (
                                            <li>
                                                <a
                                                    href={route.examination.menu}
                                                    className={this.checkActive('exam') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.exam()}</div>
                                                    <span>{t('sidebar:exam')}</span>
                                                </a>
                                            </li>
                                        )}

                                    {this.props.school.user_type != UserType.Admin &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.teacher.salary.view}
                                                    className={this.checkActive('salary') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarIncome()}</div>
                                                    <span>Salary</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess(
                                        [
                                            AccessModule.ASSET,
                                            AccessModule.PRODUCT,
                                            AccessModule.BANK,
                                            AccessModule.FEE,
                                            AccessModule.CONSTANT,
                                            AccessModule.INCOME,
                                            AccessModule.EXPENSE,
                                            AccessModule.DOUBLE_ENTRY,
                                            AccessModule.ACCOUNTING_REPORTS,
                                        ],
                                        role
                                    ) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.dashboard}
                                                    className={this.checkActive('account') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.feeSidebarIcon()}</div>
                                                    <span>{t('accounting:accounting')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.ALUMNI], role) &&
                                        access.includes(AccessModule.Module_Alumni) && (
                                            <li>
                                                <a
                                                    onClick={(e) => {
                                                        this.props.dispatch(alumni_page())
                                                        window.location = route.activity
                                                    }}
                                                    className={this.checkActive('alumniDashboard') + ' clearfix cursor'}
                                                >
                                                    <div className="sidebar-icon">{SVG.alumniUser()}</div>
                                                    <span>{t('alumni:alumni')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {(AccessModule.checkSubAccess([AccessModule.NOTIFICATION], role) ||
                                        AccessModule.checkSubAccess([AccessModule.INVITE], role)) && (
                                        <li>
                                            <a
                                                href={route.admin.setting.menu}
                                                className={this.checkActive('setting') + ' clearfix'}
                                            >
                                                <div className="sidebar-icon">{SVG.settingSidebar()}</div>
                                                <span>{t('sidebar:settings')}</span>
                                            </a>
                                        </li>
                                    )}
                                    {AccessModule.checkSubAccess([AccessModule.MODERATE_POST], role) &&
                                        access.includes(AccessModule.Module_Report) && (
                                            <li>
                                                <a
                                                    href={route.admin.report.view}
                                                    className={this.checkActive('report') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.reportFlag()}</div>
                                                    <span>{t('alumni:reports')}</span>
                                                </a>
                                            </li>
                                        )}
                                </Fragment>
                            )}
                            {this.props.isAccount == true && (
                                <Fragment>
                                    {AccessModule.checkSubAccess([AccessModule.ACCOUNTING_REPORTS], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.dashboard}
                                                    className={this.checkActive('account-dashboard') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.home()}</div>
                                                    <span>{t('setting:dashboard')}</span>
                                                </a>
                                            </li>
                                        )}

                                    {AccessModule.checkSubAccess([AccessModule.ASSET], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.asset.view}
                                                    className={this.checkActive('assets') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarAsset()}</div>
                                                    <span>{t('doubleentry:assets')}</span>
                                                </a>
                                            </li>
                                        )}

                                    {AccessModule.checkSubAccess([AccessModule.BANK], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.banking.menu}
                                                    className={this.checkActive('banking') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarBanking()}</div>
                                                    <span>{t('banking:banking')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.PRODUCT], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.productservice.view}
                                                    className={this.checkActive('products') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarProduct()}</div>
                                                    <span>{t('doubleentry:particular')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {/* {AccessModule.checkSubAccess([AccessModule.FEE], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.fee.menu}
                                                    className={this.checkActive('fee') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarFee()}</div>
                                                    <span>{t('sidebar:fee')}</span>
                                                </a>
                                            </li>
                                        )} */}
                                    {/* {AccessModule.checkSubAccess([AccessModule.PRODUCT], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.productstock.menu}
                                                    className={this.checkActive('product_stock') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarProductStock()}</div>
                                                    <span>{t('productstock:productstock')}</span>
                                                </a>
                                            </li>
                                        )} */}

                                    {AccessModule.checkSubAccess([AccessModule.INCOME], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.income.menu}
                                                    className={this.checkActive('income') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarIncome()}</div>
                                                    <span>{t('income:income')}</span>
                                                </a>
                                            </li>
                                        )}

                                    {AccessModule.checkSubAccess([AccessModule.EXPENSE], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.expense.menu}
                                                    className={this.checkActive('expense') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarExpense()}</div>
                                                    <span>{t('accounting:expense')}</span>
                                                </a>
                                            </li>
                                        )}

                                    {AccessModule.checkSubAccess([AccessModule.DOUBLE_ENTRY], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.doubleentry.menu}
                                                    className={this.checkActive('double_entry') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarDoubleEntry()}</div>
                                                    <span>{t('doubleentry:doubleentry')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.CONSTANT], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.constant.menu}
                                                    className={this.checkActive('constant') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.settingSidebar()}</div>
                                                    <span>{t('constant:constant')}</span>
                                                </a>
                                            </li>
                                        )}
                                    {AccessModule.checkSubAccess([AccessModule.ACCOUNTING_REPORTS], role) &&
                                        access.includes(AccessModule.Module_Accounting) && (
                                            <li>
                                                <a
                                                    href={route.admin.accounting.reports.menu}
                                                    className={this.checkActive('report_accounting') + ' clearfix'}
                                                >
                                                    <div className="sidebar-icon">{SVG.sidebarReports()}</div>
                                                    <span>{t('alumni:reports')}</span>
                                                </a>
                                            </li>
                                        )}

                                    <span className="sidebar-divider"></span>
                                    <li>
                                        <a
                                            onClick={(e) => {
                                                window.location = route.activity
                                            }}
                                            className="clearfix cursor"
                                        >
                                            <div className="sidebar-icon arrow-left">{SVG.sidebarScroll()}</div>
                                            <span>{t('common:back')}</span>
                                        </a>
                                    </li>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {this.props.isAlumni == true && (
                        <Fragment>
                            {AccessModule.checkSubAccess([AccessModule.ALUMNI], role) &&
                                access.includes(AccessModule.Module_Alumni) && (
                                    <Fragment>{this.getAdminAlumni()}</Fragment>
                                )}{' '}
                            <span className="sidebar-divider"></span>
                            <li>
                                <a
                                    onClick={(e) => {
                                        this.props.dispatch(school_page())
                                        window.location = route.activity
                                    }}
                                    className="clearfix cursor"
                                >
                                    <div className="sidebar-icon arrow-left">{SVG.sidebarScroll()}</div>
                                    <span>{t('common:back')}</span>
                                </a>
                            </li>
                        </Fragment>
                    )}
                </Fragment>
            )
        }
    }

    getStudent() {
        const { t } = this.props
        const { access } = this.state
        return (
            <Fragment>
                <li>
                    <a href={route.activity} className={this.checkActive('activity') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.home()}</div>
                        <span>{t('sidebar:activity')}</span>
                    </a>
                </li>
                {this.props.school.user_type == UserType.Parents && (
                    <li>
                        <a href={route.children} className={this.checkActive('children') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.users()}</div>
                            <span>My Children</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Student_Management) && (
                    <li>
                        <a href={route.report.attendance} className={this.checkActive('attendance') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.attendance()}</div>
                            <span>{t('sidebar:attendance')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Calendar_Management) && (
                    <li>
                        <a href={route.calendar} className={this.checkActive('calendar') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.calendar()}</div>
                            <span>{t('sidebar:calendar')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_School_Management) && (
                    <li>
                        <a href={route.student.timetable} className={this.checkActive('timetable') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.timetable()}</div>
                            <span>{t('sidebar:timetable')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Drive) && (
                    <li>
                        <a href={route.drive.view} className={this.checkActive('drive') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.drive()}</div>
                            <span>{t('sidebar:drive')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Course_Management) && (
                    <li>
                        <a href={route.course.student.view} className={this.checkActive('course') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.courseIcon()}</div>
                            <span>{t('sidebar:course')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Task_Management) && (
                    <li>
                        <a href={route.todo.view} className={this.checkActive('homework') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.homework()}</div>
                            <span>{t('sidebar:todo')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Exam_Management) && (
                    <li>
                        <a href={route.examination.menu} className={this.checkActive('exam') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.exam()}</div>
                            <span>{t('sidebar:exam')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Accounting) && (
                    <li>
                        <a href={route.student.fee.view} className={this.checkActive('fee') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.feeSidebarIcon()}</div>
                            <span>{t('sidebar:fee')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Gamification) && (
                    <li>
                        <a href={route.leaderboard.view} className={this.checkActive('leaderboard') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.leaderboard()}</div>
                            <span>{t('gamification:leaderboard')}</span>
                        </a>
                    </li>
                )}
                {(access.includes(AccessModule.Module_Student_Management) ||
                    access.includes(AccessModule.Module_Employee_Management)) && (
                    <li>
                        <a href={route.student.my_circle} className={this.checkActive('my_circle') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.myCircle()}</div>
                            <span>{t('sidebar:mycircle')}</span>
                        </a>
                    </li>
                )}
            </Fragment>
        )
    }

    getTeacher() {
        const { t } = this.props
        const { access } = this.state
        return (
            <Fragment>
                <li>
                    <a href={route.activity} className={this.checkActive('activity') + ' clearfix'}>
                        <div className="sidebar-icon">{SVG.home()}</div>
                        <span>{t('sidebar:activity')}</span>
                    </a>
                </li>
                {(access.includes(AccessModule.Module_Student_Management) ||
                    access.includes(AccessModule.Module_Employee_Management)) && (
                    <li>
                        <a
                            href={route.teacher.attendance.menu}
                            className={this.checkActive('attendance') + ' clearfix'}
                        >
                            <div className="sidebar-icon">{SVG.attendance()}</div>
                            <span>{t('sidebar:attendance')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Calendar_Management) && (
                    <li>
                        <a href={route.calendar} className={this.checkActive('calendar') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.calendar()}</div>
                            <span>{t('sidebar:calendar')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_School_Management) && (
                    <li>
                        <a href={route.teacher.timetable} className={this.checkActive('timetable') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.timetable()}</div>
                            <span>{t('sidebar:timetable')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Drive) && (
                    <li>
                        <a href={route.drive.view} className={this.checkActive('drive') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.drive()}</div>
                            <span>{t('sidebar:drive')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Course_Management) && (
                    <li>
                        <a href={route.course.menu} className={this.checkActive('course') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.courseIcon()}</div>
                            <span>{t('sidebar:course')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Task_Management) && (
                    <li>
                        <a href={route.todo.view} className={this.checkActive('homework') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.homework()}</div>
                            <span>{t('sidebar:todo')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Exam_Management) && (
                    <li>
                        <a href={route.examination.menu} className={this.checkActive('exam') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.exam()}</div>
                            <span>{t('sidebar:exam')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Accounting) && (
                    <li>
                        <a href={route.teacher.salary.view} className={this.checkActive('salary') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.feeSidebarIcon()}</div>
                            <span>Salary</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_Gamification) && (
                    <li>
                        <a href={route.leaderboard.view} className={this.checkActive('leaderboard') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.leaderboard()}</div>
                            <span>{t('gamification:leaderboard')}</span>
                        </a>
                    </li>
                )}
                {access.includes(AccessModule.Module_School_Management) && (
                    <li>
                        <a href={route.teacher.my_circle} className={this.checkActive('my_circle') + ' clearfix'}>
                            <div className="sidebar-icon">{SVG.myCircle()}</div>
                            <span>{t('sidebar:mycircle')}</span>
                        </a>
                    </li>
                )}
            </Fragment>
        )
    }

    getCustom() {
        const { t, school } = this.props
        const { access } = this.state
        return (
            <Fragment>
                {this.getAdmin()}
                {((this.props.isAccount == false &&
                    this.props.isAlumni == false &&
                    school != null &&
                    school.role != null &&
                    school.role.access.includes(AccessModule.TEACHER_ROLE)) ||
                    (school != null && school.role == null)) &&
                    (access.includes(AccessModule.Module_Student_Management) ||
                        access.includes(AccessModule.Module_Employee_Management)) && (
                        <li>
                            <a href={route.teacher.my_circle} className={this.checkActive('my_circle') + ' clearfix'}>
                                <div className="sidebar-icon">{SVG.myCircle()}</div>
                                <span>{t('sidebar:mycircle')}</span>
                            </a>
                        </li>
                    )}
            </Fragment>
        )
    }

    getAdminAlumni() {
        const { t, school } = this.props
        const { access } = this.state
        if (school != null) {
            const role = school.role != null ? school.role.access : null
            return (
                <Fragment>
                    {access.includes(AccessModule.Module_Alumni) && (
                        <Fragment>
                            <li>
                                <a href={route.activity} className={this.checkActive('activity') + ' clearfix'}>
                                    <div className="sidebar-icon">{SVG.home()}</div>
                                    <span>{t('sidebar:activity')}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={route.alumni.dashboard}
                                    className={this.checkActive('alumniDashboard') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.category()}</div>
                                    <span>{t('activity:dashboard')}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={route.alumni.job.menu}
                                    className={this.checkActive('alumniJobVacancy') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.jobsIcon()}</div>
                                    <span>{t('alumni:jobs')}</span>
                                </a>
                            </li>
                            {access.includes(AccessModule.Module_Calendar_Management) && (
                                <li>
                                    <a href={route.calendar} className={this.checkActive('calendar') + ' clearfix'}>
                                        <div className="sidebar-icon">{SVG.calendar()}</div>
                                        <span>{t('sidebar:calendar')}</span>
                                    </a>
                                </li>
                            )}
                            <li>
                                <a
                                    href={route.admin.alumni.category.menu}
                                    className={this.checkActive('alumniCategory') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.batchIcon()}</div>
                                    <span>{t('alumni:category')}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Company}
                                    className={this.checkActive('alumniCompany') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.school()}</div>
                                    <span>{t('alumni:company')}</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href={route.alumni.yearbook.menu}
                                    className={this.checkActive('alumniYearbook') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.drive()}</div>
                                    <span>{t('alumni:yearBook')}</span>
                                </a>
                            </li>
                            {access.includes(AccessModule.Module_Gamification) && (
                                <li>
                                    <a
                                        href={route.leaderboard.view}
                                        className={this.checkActive('leaderboard') + ' clearfix'}
                                    >
                                        <div className="sidebar-icon">{SVG.leaderboard()}</div>
                                        <span>{t('gamification:leaderboard')}</span>
                                    </a>
                                </li>
                            )}
                        </Fragment>
                    )}
                    {(AccessModule.checkSubAccess([AccessModule.NOTIFICATION], role) ||
                        AccessModule.checkSubAccess([AccessModule.INVITE], role)) && (
                        <li>
                            <a href={route.admin.setting.menu} className={this.checkActive('setting') + ' clearfix'}>
                                <div className="sidebar-icon">{SVG.settingSidebar()}</div>
                                <span>{t('sidebar:settings')}</span>
                            </a>
                        </li>
                    )}
                    {AccessModule.checkSubAccess([AccessModule.MODERATE_POST], role) &&
                        access.includes(AccessModule.Module_Report) && (
                            <li>
                                <a href={route.admin.report.view} className={this.checkActive('report') + ' clearfix'}>
                                    <div className="sidebar-icon">{SVG.reportFlag()}</div>
                                    <span>{t('alumni:reports')}</span>
                                </a>
                            </li>
                        )}
                </Fragment>
            )
        }
    }

    getAlumni() {
        const { t, school } = this.props
        const { access } = this.state
        return (
            <Fragment>
                {access.includes(AccessModule.Module_Alumni) && (
                    <Fragment>
                        <li>
                            <a href={route.activity} className={this.checkActive('activity') + ' clearfix'}>
                                <div className="sidebar-icon">{SVG.home()}</div>
                                <span>{t('sidebar:activity')}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={route.alumni.dashboard}
                                className={this.checkActive('alumniDashboard') + ' clearfix'}
                            >
                                <div className="sidebar-icon">{SVG.category()}</div>
                                <span>{t('activity:dashboard')}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={route.alumni.job.menu}
                                className={this.checkActive('alumniJobVacancy') + ' clearfix'}
                            >
                                <div className="sidebar-icon">{SVG.jobsIcon()}</div>
                                <span>{t('alumni:jobs')}</span>
                            </a>
                        </li>
                        {access.includes(AccessModule.Module_Calendar_Management) && (
                            <li>
                                <a href={route.calendar} className={this.checkActive('calendar') + ' clearfix'}>
                                    <div className="sidebar-icon">{SVG.calendar()}</div>
                                    <span>{t('sidebar:calendar')}</span>
                                </a>
                            </li>
                        )}
                        <li>
                            <a
                                href={route.alumni.yearbook.menu}
                                className={this.checkActive('alumniYearbook') + ' clearfix'}
                            >
                                <div className="sidebar-icon">{SVG.drive()}</div>
                                <span>{t('alumni:yearBook')}</span>
                            </a>
                        </li>
                        {access.includes(AccessModule.Module_Gamification) && (
                            <li>
                                <a
                                    href={route.leaderboard.view}
                                    className={this.checkActive('leaderboard') + ' clearfix'}
                                >
                                    <div className="sidebar-icon">{SVG.leaderboard()}</div>
                                    <span>{t('gamification:leaderboard')}</span>
                                </a>
                            </li>
                        )}
                    </Fragment>
                )}
            </Fragment>
        )
    }

    render() {
        return (
            <div className="sidebar">
                <div className="position-relative">
                    {this.state.showScroll == true && (
                        <div className="scroll-down" onClick={this.scroll}>
                            <span>Scroll</span>
                            <div className={this.state.currentHeight != 0 ? 'rotate-arrow' : ''}>
                                {SVG.sidebarScroll()}
                            </div>
                        </div>
                    )}

                    <a className="logo-link">
                        {this.props.school.school != null && this.props.school.school.profile != null && (
                            <Fragment>
                                <img className="logo" src={this.props.school.school.profile} alt="logo" />
                                <span className="logo small-logo" alt="logo">
                                    {this.props.school.school.short_code}
                                </span>
                            </Fragment>
                        )}
                        {this.props.school.school != null && this.props.school.school.profile == null && (
                            <Fragment>
                                <img className="logo" src="/assets/images/small-logo.png" alt="logo" />
                                <img className="logo small-logo" src="/assets/images/small-logo.png" alt="logo" />
                            </Fragment>
                        )}
                        {this.props.school.school == null && (
                            <Fragment>
                                <img className="logo" src="/assets/images/small-logo.png" alt="logo" />
                                <img className="logo small-logo" src="/assets/images/small-logo.png" alt="logo" />
                            </Fragment>
                        )}
                    </a>
                    <ul className="list-inline main-list">
                        {this.props.school &&
                            this.props.school.user_type == UserType.SuperAdmin &&
                            this.getSuperAdmin()}
                        {this.props.school && this.props.school.user_type == UserType.Admin && this.getAdmin()}
                        {this.props.school &&
                            (this.props.school.user_type == UserType.Student ||
                                this.props.school.user_type == UserType.Parents) &&
                            this.getStudent()}
                        {this.props.school && this.props.school.user_type == UserType.Teacher && this.getTeacher()}
                        {this.props.school && this.props.school.user_type == UserType.Custom && this.getCustom()}
                        {this.props.school && this.props.school.user_type == UserType.Alumni && this.getAlumni()}
                    </ul>
                </div>
            </div>
        )
    }
}

const Sidebar = withTranslation()(connect(mapStateToProps)(SidebarComponent))

export { Sidebar }
