import { accountingGet, accountingPost } from '../accountingRequest'

export const viewAsset = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('assets', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAsset = (asset, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('assets/edit/' + asset, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addAsset = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('assets/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            console.log($e)
            resolve(null)
        }
    })
}

export const updateAsset = (assets, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('assets/update/' + assets, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteAsset = (assets, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('assets/delete' + assets, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
