import * as AccessModule from '~/helper/constant/modules'

export const COURSE_ENROLL = 0
export const DRIVE_ACCESS = 1
export const EVENT_PUBLISH = 2
export const EXAM_PUBLISH = 3
export const HOLIDAY_PUBLISH = 4
export const HOMEWORK_ASSIGN = 5
export const HOMEWORK_PENDING = 6
export const HOMEWORK_APPROVE = 7
export const HOMEWORK_REJECTED = 8
export const QUESTION_BANK_ACCESS = 9
export const SUBSTITUE_TEACHER = 10
export const TIMETABLE_SCHEDULE = 11
export const GENERAL_ATTENDANCE_ABSENT = 12
export const GENERAL_ATTENDANCE_PRESENT = 13
export const SUBJECT_ATTENDANCE_ABSENT = 14
export const SUBJECT_ATTENDANCE_PRESENT = 15
export const USER_REGISTER = 16
export const PASSWORD_RESET = 17
export const USER_ACTIVATED = 18
export const USER_DEACTIVATED = 19
export const COURSE_ALERT_NOTIFICATION = 20
export const LIVE_CLASS_START = 21
export const LIVE_CLASS_END = 22
export const INVITATION_SEND = 23
export const INVITATION_APPROVAL = 24
export const JOB_VACANCY_POST = 25
export const JOB_VACANCY_APPLY = 26

export function getAll() {
    return [
        {
            label: 'Course Enroll',
            translate: 'notification:courseEnroll',
            value: COURSE_ENROLL,
            note: ['course_title'],
            sms_note: [],
            access: [AccessModule.Module_Course_Management],
        },
        {
            label: 'Drive Access',
            translate: 'notification:driveAccess',
            value: DRIVE_ACCESS,
            note: ['drive_title'],
            sms_note: [],
            access: [AccessModule.Module_Drive],
        },
        {
            label: 'Event Publish',
            translate: 'notification:eventPublish',
            value: EVENT_PUBLISH,
            note: ['event_title', 'event_start_date', 'event_end_date'],
            sms_note: [],
            access: [AccessModule.Module_Calendar_Management],
        },
        {
            label: 'Exam Publish',
            translate: 'notification:examPublish',
            value: EXAM_PUBLISH,
            note: ['exam_title', 'exam_start_date', 'exam_end_date'],
            sms_note: [],
            access: [AccessModule.Module_Exam_Management],
        },
        {
            label: 'Holiday Publish',
            translate: 'notification:holidayPublish',
            value: HOLIDAY_PUBLISH,
            note: ['holiday_title', 'holiday_start_date', 'holiday_end_date'],
            sms_note: [],
            access: [AccessModule.Module_Calendar_Management],
        },
        {
            label: 'Todo Assign',
            translate: 'notification:todoAssign',
            value: HOMEWORK_ASSIGN,
            note: ['todo_title', 'todo_description', 'todo_assigned_by', 'todo_due_date'],
            sms_note: ['todo_title'],
            access: [AccessModule.Module_Task_Management],
        },
        {
            label: 'Todo Submit',
            translate: 'notification:todoSubmit',
            value: HOMEWORK_PENDING,
            note: ['todo_title', 'todo_description', 'todo_assigned_by', 'todo_submitted_by', 'todo_due_date'],
            sms_note: ['todo_title', 'todo_submitted_by'],
            access: [AccessModule.Module_Task_Management],
        },
        {
            label: 'Todo Approve',
            translate: 'notification:todoApprove',
            value: HOMEWORK_APPROVE,
            note: ['todo_title', 'todo_description', 'todo_submitted_by'],
            sms_note: ['todo_title'],
            access: [AccessModule.Module_Task_Management],
        },
        {
            label: 'Todo Rejected',
            translate: 'notification:todoRejected',
            value: HOMEWORK_REJECTED,
            note: ['todo_title', 'todo_description', 'todo_submitted_by'],
            sms_note: ['todo_title'],
            access: [AccessModule.Module_Task_Management],
        },
        {
            label: 'Question Bank Access',
            translate: 'notification:questionBankAccess',
            value: QUESTION_BANK_ACCESS,
            note: ['question_bank_title', 'question_bank_description'],
            sms_note: ['question_bank_title'],
            access: [AccessModule.Module_Exam_Management],
        },
        {
            label: 'Substitute Teacher Assign',
            translate: 'notification:substituteTeacherAssign',
            value: SUBSTITUE_TEACHER,
            note: [],
            sms_note: ['subject_name', 'timetable_start_time', 'timetable_end_time'],
            access: [AccessModule.Module_Employee_Management],
        },
        {
            label: 'Timetable Schedule',
            translate: 'notification:timetableSchedule',
            value: TIMETABLE_SCHEDULE,
            note: ['user_name'],
            sms_note: ['timetable_day', 'subject_name', 'timetable_start_time', 'timetable_end_time'],
            access: [AccessModule.Module_School_Management],
        },
        {
            label: 'General Attendance - Absent',
            translate: 'notification:generalAttendanceAbsent',
            value: GENERAL_ATTENDANCE_ABSENT,
            subject: 'General Attendance - Absent',
            note: ['user_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'General Attendance - Present',
            translate: 'notification:generalAttendancePresent',
            value: GENERAL_ATTENDANCE_PRESENT,
            subject: 'General Attendance - Present',
            note: ['user_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'Subject Attendance - Absent',
            translate: 'notification:subjectAttendanceAbsent',
            value: SUBJECT_ATTENDANCE_ABSENT,
            subject: 'User Attendance',
            note: ['user_name', 'subject_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'Subject Attendance - Present',
            translate: 'notification:subjectAttendancePresent',
            value: SUBJECT_ATTENDANCE_PRESENT,
            subject: 'User Attendance',
            note: ['user_name', 'subject_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'User Registration',
            translate: 'notification:userRegistration',
            value: USER_REGISTER,
            subject: 'User Registration',
            note: ['school_name', 'user_name', 'user_email', 'user_reg_no', 'user_password'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'User Password Reset',
            translate: 'notification:userPasswordReset',
            value: PASSWORD_RESET,
            note: ['school_name', 'user_name', 'user_email', 'user_reg_no', 'user_password'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'User Activated',
            translate: 'notification:userActivated',
            value: USER_ACTIVATED,
            note: ['user_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'User Deactivated',
            translate: 'notification:userDeactivated',
            value: USER_DEACTIVATED,
            note: ['user_name'],
            sms_note: [],
            access: [AccessModule.Module_Employee_Management, AccessModule.Module_Student_Management],
        },
        {
            label: 'Course Alert Notification',
            translate: 'notification:courseAlertNotification',
            value: COURSE_ALERT_NOTIFICATION,
            note: ['course_title'],
            sms_note: [],
            access: [AccessModule.Module_Course_Management],
        },
        {
            label: 'Live class start',
            translate: 'notification:liveStart',
            value: LIVE_CLASS_START,
            note: ['live_class_link', 'live_class_email', 'live_class_name', 'live_class_subject'],
            sms_note: [],
            access: [AccessModule.Module_School_Management],
        },
        {
            label: 'Live class end',
            translate: 'notification:liveEnd',
            value: LIVE_CLASS_END,
            note: ['live_class_link', 'live_class_email', 'live_class_name', 'live_class_subject'],
            sms_note: [],
            access: [AccessModule.Module_School_Management],
        },
        {
            label: 'Invitation mail',
            translate: 'notification:invitationMail',
            value: INVITATION_SEND,
            note: ['invitation_link', 'invitation_email', 'invitation_remarks'],
            sms_note: [],
            access: [AccessModule.Module_School_Management],
        },
        {
            label: 'Invitaion Approval Request',
            translate: 'notification:invitationApproval',
            value: INVITATION_APPROVAL,
            note: ['invitation_email'],
            sms_note: [],
            access: [AccessModule.Module_School_Management],
        },
        {
            label: 'Job Vacancy Post',
            translate: 'notification:jobVacancyPost',
            value: JOB_VACANCY_POST,
            note: ['job_title', 'job_company', 'job_designation'],
            sms_note: [],
            access: [AccessModule.Module_Alumni],
        },
        {
            label: 'Job Applicant Alert',
            translate: 'notification:jobApplicantAlert',
            value: JOB_VACANCY_APPLY,
            note: ['job_title', 'job_designation', 'applicant_email'],
            sms_note: [],
            access: [AccessModule.Module_Alumni],
        },
    ]
}
