import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSchoolPackageStatus } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    package: Object,
    storage: float,
    student: float,
    employee: float,
    progressStatus: Array,
}

export class AbstractSubscriptionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            packageData: null,
            storage: 0,
            employee: 0,
            student: 0,
            progressStatus: [
                { label: 'Student', translate: 'todo:student', bgcolor: '#656294', completed: 0, max: '100', isStorage: false },
                { label: 'Employee', translate: 'user_filter:employee', bgcolor: '#fd6569', completed: 0, max: '100', isStorage: false },
                { label: 'Storage', translate: 'common:storage', bgcolor: '#53b88e', completed: 0, max: '100', isStorage: true },
            ],
        }
    }

    _handleChange(key, value) {
        this.setState({
            [key]: value,
        })
    }

    componentDidMount() {
        this._handleChange('loading', true)
        getSchoolPackageStatus(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const packageData = response.data.package
                const progressStatus = [
                    { label: 'Student', translate: 'todo:student', bgcolor: '#656294', completed: response.data.student, max: packageData.student, isStorage: false },
                    { label: 'Employee', translate: 'user_filter:employee', bgcolor: '#fd6569', completed: response.data.employee, max: packageData.teacher, isStorage: false },
                    { label: 'Storage', translate: 'common:storage', bgcolor: '#53b88e', completed: response.data.storage, max: packageData.storage, isStorage: true },
                ]

                this.setState({
                    packageData,
                    storage: response.data.storage,
                    employee: response.data.employee,
                    student: response.data.student,
                    progressStatus,
                })
            }
        })
    }
}

const AbstractSubscription = withTranslation()(AbstractSubscriptionComponent)

export { AbstractSubscription }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
