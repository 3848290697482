import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { redirect } from '~/helper/function/util'
import { logout } from '~/services/api'
import { signout } from '~/store'

type Props = {
    id: string,
    user: Object,
    school: Object,
    schoolPackage: String,
    dispatch: Dispatch<any>,
    onToggle: Object,
    history: Object,
}

type State = {
    access: Array,
}

export class AbstractSidebarComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
        }
        this.checkActive = this.checkActive.bind(this)
        this._toggleSidebar = this._toggleSidebar.bind(this)
        this._logout = this._logout.bind(this)
    }

    async componentDidMount() {
        let device_id = null
        if (typeof document != 'undefined') {
            await messaging()
                .requestPermission()
                .then(async function () {
                    const token = await messaging().getToken()
                    device_id = token
                })
                .catch(function (err) {})
            await this.setState({
                device_id,
            })
        } else {
            messaging()
                .getToken()
                .then((device_id) => {
                    this.setState({
                        device_id,
                    })
                })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }

    _toggleSidebar() {
        if (this.props.onToggle) {
            this.props.onToggle(false)
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _logout() {
        this._handleChange('loading', true)
        const token = this.props.token
        this.props.dispatch(signout())
        logout(this.props.token, this.state.device_id).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                redirect(route.index, this.props.history)
                toastMessage('success', response.message)
                // window.location = APP_URL
            } else {
                redirect(route.index, this.props.history)
            }
        })
    }

    checkActive(id) {
        if (this.props.id == id) {
            return 'active'
        } else {
            return ''
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        user: state.user,
        school: state.school,
        schoolPackage: state.schoolPackage,
        isAlumni: state.isAlumni,
    }
}
