import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractQuestionBankViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import { withTranslation } from 'react-i18next'
import $ from 'jquery'

class QuestionBankViewComponent extends AbstractQuestionBankViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getSubject = this.getSubject.bind(this)
        this.getQuestionBank = this.getQuestionBank.bind(this)
        this.getQuestionBankAdd = this.getQuestionBankAdd.bind(this)
        this.getQuestionBankDetail = this.getQuestionBankDetail.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectSection = this._selectSection.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.clearState()
    }

    _onSave(e) {
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_add').trigger('click')
            }
        })
    }

    _selectEmployee(data) {
        this._handleChange('employee', data)
    }

    _selectStudent(data) {
        this._handleChange('student', data)
    }

    _selectSubject(data) {
        this._handleChange('selected_subject', data)
    }

    _selectSection(data) {
        this._handleChange('selectedSection', data)
    }

    getSubject() {
        const data = this.state.subject.map((item, index) => {
            return (
                <li key={index}>
                    <a onClick={() => this._handleChange('activeTab', index)} className={index == this.state.activeTab ? 'active' : ''} id={'subject-' + index} data-toggle="tab" href={'#subject_' + index} role="tab" aria-controls="subject" aria-selected="false">
                        {item.name}
                    </a>
                </li>
            )
        })
        return data
    }

    getQuestionBank() {
        const data = this.state.subject.map((item, index) => {
            return (
                <div key={index} className={index == this.state.activeTab ? 'tab-pane fade show active' : 'tab-pane fade show'} id={'subject_' + index} role="tabpanel" aria-labelledby={'subject-' + index}>
                    {this.getQuestionBankDetail(item.id)}
                </div>
            )
        })
        return data
    }

    getQuestionBankDetail(id) {
        const { t } = this.props
        const data = this.state.question_bank
            .filter((item) => item.subject_id == id)
            .map((item, index) => {
                return (
                    <div key={index} className="folder-list question-bank-list clearfix" onClick={() => (window.location = route.examination.question_bank.question.view + '/' + item.id)}>
                        <a href={route.examination.question_bank.question.view + '/' + item.id} className="folder-img">
                            {SVG.questionSubject()}
                        </a>
                        <a href={route.examination.question_bank.question.view + '/' + item.id} className="folder-name">
                            {item.title}
                        </a>
                    </div>
                )
            })
        if (data.length == 0) {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('exam:questionbank.noQuestionsAdded')}.
                </p>
            )
        }
        return data
    }

    getQuestionBankAdd() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('exam:questionbank:addQuestionBank')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:title')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('common:title')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:description')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('exam:questionbank:questionBankDescription')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <div className="form-check">
                                            <label className="form-title" htmlFor="private">
                                                {t('common:private')}
                                            </label>
                                            <Checkbox id="private" onChange={this._handleChange} className="" name="isPrivate" value={this.state.isPrivate} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.isPrivate == true && (
                                    <div className="add-option-exam">
                                        <UserFilter
                                            selectedClass={this.state.selectedClass}
                                            selectedSection={this.state.selectedSection}
                                            selectedSubject={this.state.selected_subject}
                                            selectedStudent={this.state.student}
                                            selectedEmployee={this.state.employee}
                                            hideClass={this.state.hideClass}
                                            hideSection={this.state.hideSection}
                                            showSubject={this.state.showSubject}
                                            resetOnChange={false}
                                            onStudentSelect={this._selectStudent}
                                            onEmployeeSelect={this._selectEmployee}
                                            onSubjectSelect={this._selectSubject}
                                            onSectionSelect={this._selectSection}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                    <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.question_bank.menu}>{t('exam:questionbank:categoryTitle')} &gt; </a>
                            </li>
                            {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <li>{this.state.classes != null ? 'Academic' : 'Others'}</li>}
                            {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && <li>{this.state.classes != null ? this.state.classes.name : 'Others'}</li>}
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && <span className="sub-main-title">{this.state.classes != null ? 'Academic' : 'Others'}</span>}
                                {this.props.school.user_type != UserType.Student && this.props.school.user_type == UserType.Parents && <span className="sub-main-title">{this.state.classes != null ? this.state.classes.name : 'Others'}</span>}
                                <h5 className="main-title heading5">{t('exam:questionbank:categoryTitle')}:</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <Button modal="modal_add" type="button" text={t('addBtn')} main={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="event-accordion">
                        <div className="role-pop-head">
                            <ul className="nav" id="myTab" role="tablist">
                                {this.state.subject.length > 0 && this.getSubject()}
                                <li>
                                    <a onClick={() => this._handleChange('activeTab', 'other')} className={'other' == this.state.activeTab ? 'active' : ''} id="subject-other" data-toggle="tab" href="#subject_other" role="tab" aria-controls="subject" aria-selected="false">
                                        {t('others')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="role-pop-content">
                            <div className="tab-content" id="myTabContent">
                                {this.state.subject.length > 0 && this.getQuestionBank()}
                                <div className={'other' == this.state.activeTab ? 'tab-pane fade show active' : 'tab-pane fade show'} id="subject_other" role="tabpanel" aria-labelledby="subject-other">
                                    {this.getQuestionBankDetail(null)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Teacher || this.props.school.user_type == UserType.Custom) && this.getQuestionBankAdd()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('student', [])
                component._handleChange('employee', [])
                component._handleChange('selectedClass', component.state.selectedClass)
                component._handleChange('isPrivate', true)
                component._handleChange('selected_subject', -1)
                component._handleChange('selectedSection', -1)
                const errors = {
                    title: null,
                    description: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

const QuestionBankView = withTranslation()(connect(mapStateToProps)(QuestionBankViewComponent))

export { QuestionBankView }
