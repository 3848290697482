import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractBookCategoryAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import MultiImageInput from 'react-multiple-image-input'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'

class BookCategoryAddComponent extends AbstractBookCategoryAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }

    }


    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="library" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.bookcategory.view}>{t('library:bookscategory')} > </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('library:addbookcategory')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('common:name')} type="text" value="" />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:code')} *</label>
                                    <Input name="code" onChange={this._handleChange} placeholder={t('fee:code')} type="text" value="" />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>

                            </div>
                            {/* <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value="" />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div> */}


                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const BookCategoryAdd = withTranslation()(connect(mapStateToProps)(BookCategoryAddComponent))

export { BookCategoryAdd }
