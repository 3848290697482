import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseModuleComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import Files from 'react-files'
import ReactPlayer from 'react-player/file'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { convertADtoBS } from '../../../../../helper/function'
class CourseModuleComponent extends AbstractCourseModuleComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getAddModal = this.getAddModal.bind(this)
        this.fileRef = React.createRef()
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this.setDate = this.setDate.bind(this)
        this.getBase64 = this.getBase64.bind(this)
        this.changeAfterCompletion = this.changeAfterCompletion.bind(this)
        this.showModules = this.showModules.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
    }

    setStartTime(data) {
        this._handleChange('start_time', data)
    }
    setDate(data) {
        super._handleChange('release_date', data)
    }
    changeAfterCompletion(item) {
        const after_completion = item
        this.setState({ after_completion })
    }
    getBase64(file) {
        if (file) {
            return new Promise((resolve, reject) => {
                var mediaAryInner = {}
                var reader = new FileReader()
                const mediaAry = this.state.mediaAry
                reader.readAsDataURL(file)
                reader.onload = function () {
                    var encodedFile = reader.result
                    mediaAryInner['name'] = file.name
                    mediaAryInner['data'] = encodedFile
                    mediaAry.push(mediaAryInner)
                    return mediaAry
                }
                reader.onerror = function (error) {
                    alert('Error: ', error)
                    return false
                }
            })
        }
    }
    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }
    filesRemoveOne = (file) => {
        let fileAry = this.state.uploadedFiles
        let deleteIndex = fileAry.indexOf(file)
        const mediaAry = this.state.mediaAry
        fileAry.splice(deleteIndex, 1)
        mediaAry.splice(deleteIndex, 1)
        super.setState({ media: mediaAry })
        // this.refs.files.removeFile(file);
    }
    selectFiles(data) {
        const data2 = data[data.length - 1]
        const uploadedFiles = this.state.uploadedFiles
        var promise = Promise.resolve()
        promise.then(() => {
            if (data.length > 0 && uploadedFiles.length != data.length) {
                var uploadedFile = data[data.length - 1]
                const mediaAry = this.getBase64(uploadedFile)
                this.setState({ media: mediaAry })
            }
        })
        promise.then(() => {
            if (data.length > 0 && uploadedFiles.length != data.length) {
                var uploadedFile = data[data.length - 1]
                const mediaAry = this.state.mediaAry
                uploadedFiles.push(uploadedFile)
                super.setState(
                    {
                        media: mediaAry,
                        uploadedFiles,
                    },
                    () => {
                        data.pop()
                    }
                )
            }
        })
    }
    deleteModal() {
        const { t } = this.props
        return (
            <div key={this.state.selectedMedia} id="modal_class_delete" className="modal fade show collapse-body" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('course:delete_msg')}</p>
                            <p className="delete-file-name">"{this.state.coursetitle}"</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={() => this.deleteContent()} type="button" text={t('common:modal confirm')} main={true} />
                            <button id={'close_delete'} type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    showContent(item) {
        const { t } = this.props
        let data = item.content.map((content, index) => {
            return (
                <li className="clearfix course-video">
                    <div className="media-elements clearfix">
                        <div className="col-3 module-img float-left">{(content.thumbnail && content.thumbnail.media_url && <img src={content.thumbnail.media_url} alt="image" />) || (content.media && content.media.media_type.includes('image') && <img src={content.media.media_url} alt="image" />) || (content.media.media_type.includes('audio') && <img src="/assets/images/sound.png" alt="notification" />) || <img src="/assets/images/video.png" alt="notification" />}</div>
                        <div className="col-9 module-detail float-left clearfix">
                            <p>{content.name}</p>
                            <span>
                                {content.description}
                                {/* Duration: <ins>33:34 mins</ins> */}
                            </span>
                        </div>
                    </div>
                    <div className="resume-but">
                        <a href={route.course.manage + '/' + this.props.match.params.course_id + '/' + content.id}>
                            <button className="manage collapse-body">{t('course:manage')}</button>
                        </a>
                        {'|'}
                        <button className="manage collapse-body" onClick={() => this.openDeleteCourse(item.id, content.id)}>
                            {t('common:DeleteBtn')}
                        </button>
                    </div>
                </li>
            )
        })
        if (data != '') {
            return (
                <ul id="sub-module" className="sub-module list-inline">
                    {data}
                </ul>
            )
        } else {
            return
        }
    }
    showModules() {
        const { t } = this.props
        let data = this.state.courseModule.map((item, index) => {
            return (
                <div className="module-box collapse-body accordion-list">
                    <h5 className="clearfix">
                        <span className="module-head">
                            {item.name}
                            <span className="pointer" onClick={() => this.openEditModule(item.id)}>
                                {SVG.editIcon()}
                            </span>
                        </span>
                        <button className={index == 0 ? 'down-arrow float-right' : 'down-arrow float-right collapsed'} data-parent="#moduleParent" data-toggle="collapse" data-target={'#moduleCollapse' + index} aria-expanded={index == 0 ? 'true' : 'false'} aria-controls={'moduleCollapse' + index}>
                            {/* <FontAwesomeIcon className="icon" icon="chevron-down" color="#333" size="m" /> */}
                        </button>
                        <button className="second-button float-right" id="add-module" onClick={() => this.openAddCourse(item.id)}>
                            {t('common:addBtn')}
                        </button>
                    </h5>
                    <div id={'moduleCollapse' + index} className={index == 0 ? 'accordion-content collapse-body collapse show' : 'accordion-content collapse-body collapse'} data-parent="#moduleParent">
                        {this.showContent(item)}
                    </div>
                </div>
            )
        })
        if (data == '') {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('course:module_msg1')}.
                </p>
            )
        }
        return data
    }
    getAddModal() {
        const { t } = this.props
        return (
            <div id="modal_drive_add" className="modal fade show collapse-body" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{(this.state.selectedModule && t('course:edit_module')) || t('course:add_module')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:name')} *</label>
                                        <Input name="title" onChange={this._handleChange} placeholder={t('common:name')} type="text" value={this.state.title} />
                                        {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('course:description')} *</label>
                                        <TextArea name="description" onChange={this._handleChange} placeholder={t('course:description')} type="text" value={this.state.description} />
                                        {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('course:release_date')} *</label>
                                        <DatePicker dateFormat="yyyy-MM-dd" minDate={new Date()} maxDate={moment(this.state.courseEndDate).toDate()} className="form-control" selected={this.state.release_date} onChange={this.setDate} placeholderText={t('course:release_date')} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.release_date != null ? (convertADtoBS(this.state.release_date) != null ? convertADtoBS(this.state.release_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.errors.release_date && <label className="error text-danger">{this.state.errors.release_date}</label>}
                                    </div>
                                </div>
                                {this.state.courseModuleData.length != 0 && (
                                    <div className="row clearfix">
                                        <div className="form-group col-sm-12 float-left">
                                            <label className="form-title" htmlFor="course">
                                                {t('course:module_msg2')}
                                            </label>
                                            <Select value={this.state.after_completion} placeholder={t('course:after_completion')} options={this.state.courseModuleSelectFilter} onChange={(e) => this.changeAfterCompletion(e)} />
                                        </div>
                                    </div>
                                )}
                                {this.state.selectedModule && (
                                    <div className="row clearfix">
                                        <div className="form-group col-sm-12 float-left">
                                            <div className="delete-row">
                                                <Button onClick={this.deleteModule} type="submit" text={t('course:delete_module')} main={true} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.addModule} type="submit" text={this.state.selectedModule ? t('common:updateBtn') : t('common:addBtn')} main={true} />
                                <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    getAddCourseModal() {
        const { t } = this.props
        return (
            <div id="modal_course_add" className="modal fade show collapse-body" role="dialog" ref={(modal) => (this.modal = modal)}>
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('course:add_content')}</span>
                    </div>
                    <br />
                    <form className="p-0" siq_id="autopick_388">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:name')} *</label>
                                        <Input name="coursetitle" onChange={this._handleChange} placeholder={t('common:name')} type="text" value={this.state.coursetitle} />
                                        {this.state.courseerrors.coursetitle && <label className="error text-danger">{this.state.courseerrors.coursetitle}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('course:description')} *</label>
                                        <TextArea name="coursedescription" onChange={this._handleChange} placeholder={t('course:description')} type="text" value={this.state.coursedescription} />
                                        {this.state.courseerrors.coursedescription && <label className="error text-danger">{this.state.courseerrors.coursedescription}</label>}
                                    </div>
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('common:start_time')}</label>
                                        <DatePicker showTimeSelect dateFormat="yyyy-MM-dd - hh:mm:ss a" className="form-control" placeholderText={t('common:start_time')} selected={this.state.start_time} onChange={this.setStartTime} minDate={moment(this.state.courseStartDate).toDate()} maxDate={moment(this.state.courseEndDate).toDate()} />
                                    </div>
                                </div>
                                {this.state.selectedContent == null && (
                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group">
                                            <label className="form-title">{t('course:files')}*</label>
                                            <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                                {this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                                    ? this.state.uploadedFiles.map((file) => (
                                                          <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                              <div className="gsEyTH files-list-item" key={file.id}>
                                                                  <div className="files-list-item-preview">{file.preview.type === 'image' ? <img className="files-list-item-preview-image" src={file.preview.url} /> : <div className="files-list-item-preview-extension">{file.extension}</div>}</div>
                                                                  <div className="files-list-item-content">
                                                                      <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                                                      <div className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</div>
                                                                  </div>
                                                                  <div
                                                                      id={file.id}
                                                                      className="files-list-item-remove"
                                                                      onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))
                                                    : null}
                                                <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                    <Files ref={this.fileRef} className="files-dropzone" onChange={this.selectFiles} onError={this.onFilesError} accepts={['video/*', 'audio/*']} multiple maxFiles={1} maxFileSize={200000000} minFileSize={0} clickable>
                                                        {t('course:document')}
                                                    </Files>
                                                    {this.state.file_message && <label className="error text-danger">{this.state.file_message}</label>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.addCourse} type="submit" text={this.state.selectedContent == null ? t('common:addBtn') : t('common:updateBtn')} main={true} />
                                <button id="close_course_add" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} &gt; </a>
                            </li>
                            <li>
                                {this.state.classes != null && <a href={route.course.view + '/' + this.state.classes.id}>{this.state.classes.name} &gt; </a>}
                                {this.state.classes == null && <a>Class &gt; </a>}
                            </li>
                            <li>{this.state.course.title}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-4 float-left">
                                <h5 className="main-title">
                                    {this.state.course.title}
                                    {/* <ins className="new-con">New</ins> */}
                                </h5>
                            </div>
                            <div className="col-md-8 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.course.edit + '/' + this.props.match.params.id + '/' + this.props.match.params.course_id} className="second-button">
                                        {t('course:settings')}
                                    </a>
                                </div>
                                {/* 
               <div className="display-inline-block">
                  <a href="#" className="second-button">
                  Bulk Upload
                  </a>
               </div>
               */}
                                <div className="display-inline-block collapse-body">
                                    <Button onClick={this.openAddModule} type="submit" text={t('common:addBtn')} main={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="moduleParent">{this.showModules()}</div>

                    {/* <div className="clearfix text-right form-button">
      <button className="second-button">Save</button>
      <button className="main-button">Preview</button> 
      </div>*/}
                </div>
                {this.getAddModal()}
                {this.getAddCourseModal()}
                {this.deleteModal()}
            </div>
        )
    }
}
const CourseModule = withTranslation()(connect(mapStateToProps)(CourseModuleComponent))
export { CourseModule }
