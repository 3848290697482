import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractDashboardComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import * as Reacttable from 'react-super-responsive-table'
import { Loading } from '~/components/loader'
import { Button } from '~/components/form'
import { getReadableFileSize } from '~/helper/function/abstract'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import $ from 'jquery'
class SuperAdminDashboardComponent extends AbstractDashboardComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.restartWebinar = this.restartWebinar.bind(this)
        this.restartChat = this.restartChat.bind(this)
        this.restartTest = this.restartTest.bind(this)
        this.restartLive = this.restartLive.bind(this)
        this.restartBackup = this.restartBackup.bind(this)
        this.getRestartModal = this.getRestartModal.bind(this)
    }

    restartWebinar() {
        $('#close_webinar').trigger('click')
        super.restartWebinar()
    }

    restartTest() {
        $('#close_test').trigger('click')
        super.restartTest()
    }

    restartChat() {
        $('#close_chat').trigger('click')
        super.restartChat()
    }

    restartLive() {
        $('#close_live').trigger('click')
        super.restartLive()
    }

    restartBackup() {
        $('#close_backup').trigger('click')
        super.restartBackup()
    }

    getRestartModal() {
        return (
            <Fragment>
                <div id="modal_webinar" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to restart the server?</p>
                                <p className="delete-file-name">"Webinar"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Restart" main={true} onClick={this.restartWebinar} />
                                <button id="close_webinar" type="button" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_test" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to restart the server?</p>
                                <p className="delete-file-name">"Test"</p>
                            </div>
                        </div>
                        <br />
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Restart" main={true} onClick={this.restartTest} />
                                <button id="close_test" type="button" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_chat" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question"> you sure you want to restart the server?</p>
                                <p className="delete-file-name">"Chat"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Restart" main={true} onClick={this.restartChat} />
                                <button id="close_chat" type="button" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_live" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to restart the server?</p>
                                <p className="delete-file-name">"Live"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Restart" main={true} onClick={this.restartLive} />
                                <button id="close_live" type="button" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal_backup" className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to restart the server?</p>
                                <p className="delete-file-name">"Backup"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text="Restart" main={true} onClick={this.restartBackup} />
                                <button id="close_backup" type="button" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <div className="main-page">
                <Sidebar id="dashboard" />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.dashboard}>Dashboard</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Dashboard:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.school.view)}>
                                    <a href={route.superadmin.school.view} className="folder-img">
                                        {SVG.schools()}
                                    </a>
                                    <a href={route.superadmin.school.view} className="folder-name">
                                        School
                                        <span>Manage schools in classpedia</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.package.view)}>
                                    <a href={route.superadmin.package.view} className="folder-img">
                                        {SVG.dashboardpackage()}
                                    </a>
                                    <a href={route.superadmin.package.view} className="folder-name">
                                        Packages
                                        <span>Manages school packages in classpedia</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.sms_package.view)}>
                                    <a href={route.superadmin.sms_package.view} className="folder-img">
                                        {SVG.smspackages()}
                                    </a>
                                    <a href={route.superadmin.sms_package.view} className="folder-name">
                                        SMS Packages
                                        <span>Manages sms packages in classpedia</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.coupon.view)}>
                                    <a href={route.superadmin.coupon.view} className="folder-img">
                                        {SVG.coupons()}
                                    </a>
                                    <a href={route.superadmin.coupon.view} className="folder-name">
                                        Coupons
                                        <span>Manages coupons in classpedia</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.notice.view)}>
                                    <a href={route.superadmin.notice.view} className="folder-img">
                                        {SVG.dashboardnotice()}
                                    </a>
                                    <a href={route.superadmin.notice.view} className="folder-name">
                                        Notices
                                        <span>Notify users about the upcoming updates in classpedia.</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.enquiries.view)}>
                                    <a href={route.superadmin.enquiries.view} className="folder-img">
                                        {SVG.enquiries()}
                                    </a>
                                    <a href={route.superadmin.enquiries.view} className="folder-name">
                                        Enquiries
                                        <span>Schedule demo with the customers.</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.superadmin.reset)}>
                                    <a href={route.superadmin.reset} className="folder-img">
                                        {SVG.resetpassword()}
                                    </a>
                                    <a href={route.superadmin.reset} className="folder-name">
                                        Reset User Password
                                        <span>Reset system users password.</span>
                                    </a>
                                </div>
                            </div>
                            <div className="float-left course-your col-lg-3 col-md-4 col-sm-6 col-12">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.profile)}>
                                    <a href={route.profile} className="folder-img">
                                        {SVG.dashboardprofile()}
                                    </a>
                                    <a href={route.profile} className="folder-name">
                                        My Profile
                                        <span>Update your personal information.</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="view-progress col-lg-3 col-sm-6 col-12">
                                <div className="folder-list dashboard insight-list">
                                    <div className="mt-0">
                                        <h6>School Packages :</h6>
                                        <hr />
                                        <div className="table table-responsive">
                                            <table className="table-bordered dashboard">
                                                <tbody>
                                                    {this.state.info.totalSchoolPackage != null &&
                                                        this.state.info.totalSchoolPackage.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {item.name}
                                                                        <br />
                                                                        <small>Code: {item.code}</small>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <span className={index % 2 == 0 ? 'table-status  table-green' : 'table-status  table-blue'}>{item.school_count}</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-progress col-lg-3 col-sm-6 col-12">
                                <div className="folder-list dashboard">
                                    <div className="mt-0">
                                        <h6>System Information :</h6>
                                        <hr />
                                        <div className="table table-responsive">
                                            <table className="table-bordered dashboard">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Total Users
                                                            <br />
                                                            <small>Total number of system users</small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-green">
                                                                {this.state.info.totalUser}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Total Schools
                                                            <br />
                                                            <small>Total number of schools</small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-blue">
                                                                {this.state.info.totalSchool}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Total Enquiry
                                                            <br />
                                                            <small>Total number of enquiries</small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-grey">
                                                                {this.state.info.totalEnquiry}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Total Packages
                                                            <br />
                                                            <small>Total number of packages</small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-blue">
                                                                {this.state.info.totalPackage}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Total SMS Packages
                                                            <br />
                                                            <small>Total number of sms packages</small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-green">
                                                                {this.state.info.totalSmsPackage}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-progress col-lg-3 col-sm-6 col-12">
                                <div className="folder-list dashboard">
                                    <div className="mt-0">
                                        <h6>Servers :</h6>
                                        <hr />
                                        <div className="table table-responsive">
                                            <table className="table-bordered dashboard">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Webinar
                                                            <br />
                                                            <small>
                                                                <a href="https://live.classpedia.com" target="_blank">
                                                                    https://live.classpedia.com
                                                                </a>
                                                            </small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_webinar" className="table-status cursor table-green">
                                                                Restart
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Test
                                                            <br />
                                                            <small>
                                                                <a href="https://alphalaunch.net" target="_blank">
                                                                    https://alphalaunch.net
                                                                </a>
                                                            </small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_test" className="table-status cursor table-blue">
                                                                Restart
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Chat
                                                            <br />
                                                            <small>
                                                                <a href="https://chat.classpedia.com" target="_blank">
                                                                    https://chat.classpedia.com
                                                                </a>
                                                            </small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_chat" className="table-status cursor table-green">
                                                                Restart
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Live
                                                            <br />
                                                            <small>
                                                                <a href="https://classpedia.com" target="_blank">
                                                                    https://classpedia.com
                                                                </a>
                                                            </small>
                                                        </td>
                                                        <td className="text-right">
                                                            <span data-toggle="modal" data-target="#modal_live" className="table-status cursor table-blue">
                                                                Restart
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="view-progress col-lg-3 col-sm-6 col-12">
                                <div className="folder-list dashboard insight-list">
                                    <div className="mt-0">
                                        <h6>Storage Information :</h6>
                                        <hr />
                                        <div className="text-center form-group donut-dashboard">
                                            <CircularProgressbar value={this.state.space_percentage} text={this.state.space_percentage + `%`} strokeWidth={5} />
                                        </div>
                                        <div className="text-center form-group">
                                            <small>
                                                Free space : &nbsp;
                                                <span className="table-status table-green">{getReadableFileSize(this.state.free_space)}</span>
                                            </small>
                                        </div>
                                        <div className="text-center form-group">
                                            <small>
                                                Used space : &nbsp;
                                                <span className="table-status table-blue">{getReadableFileSize(this.state.used_space)}</span>
                                            </small>
                                        </div>
                                        <div className="text-center form-group">
                                            <small>
                                                Total Storage : &nbsp;
                                                <span className="table-status table-yellow">250 GB</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.getRestartModal()}
            </div>
        )
    }
}

const SuperAdminDashboard = connect(mapStateToProps)(SuperAdminDashboardComponent)

export { SuperAdminDashboard }
