import { get, post } from '../request'

export const getFeeTemplate = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/template/fee', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSalaryTemplate = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/template/salary', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getTemplateById = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/template/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addTemplate = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/template/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editTemplate = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/template/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteTemplate = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/template/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
