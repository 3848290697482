import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewSchool, toggleSchool } from '~/services/api'
import { SchoolDTO, PackageDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    data: Array,
    school: Array,
    loading: Boolean,
}

export class AbstractSchoolViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            school: [
                {
                    short_code: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    name: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    country: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    package: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    student_restriction: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                    },
                    subscription_date: {
                        field: 'text',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadSchool()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(id) {
        toggleSchool(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadSchool()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadSchool() {
        this._handleChange('loading', true)
        viewSchool(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let school = []
            school.push(this.state.school[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.school.map((item, index) => {
                    const data: SchoolDTO = item
                    let text = null
                    let restriction = null
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            badge: 'table-green',
                            modal: 'modal_school_' + index,
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            badge: 'table-red',
                            modal: 'modal_school_' + index,
                        }
                    }
                    if (data.isOnlyApp) {
                        restriction = {
                            text: 'Allow access on only mobile app',
                            badge: 'table-blue',
                        }
                    } else {
                        restriction = {
                            text: 'Allow access on both web and mobile app',
                            badge: 'table-grey',
                        }
                    }
                    const tableData = {
                        short_code: {
                            text: data.short_code,
                        },
                        name: {
                            text: data.name,
                        },
                        country: {
                            text: data.country,
                        },
                        package: {
                            text: data.package.name,
                        },
                        student_restriction: restriction,
                        subscription_date: {
                            text: moment(data.subscription_date).format('yyyy-MM-DD'),
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            route: route.superadmin.school.edit + '/' + data.id,
                        },
                    }
                    dataList.push(data)
                    school.push(tableData)
                })
            }
            this.setState({
                data: dataList,
                school,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
