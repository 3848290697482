import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getOnlineExam, getUserProgress, saveUserProgress } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    exam_start: Boolean,
    time_over: Boolean,
    exam: Object,
    question: Array,
    active: Integer,
    answer: String,
    answerList: Array,
    duration: String,
}

export class AbstractOnlineExamComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            exam_start: false,
            time_over: false,
            question: [],
            answerList: [],
            active: 0,
            duration: null,
            is_finished: false,
            

        }
        this.getExam = this.getExam.bind(this)
        this.endExam = this.endExam.bind(this)
        this.closeExam = this.closeExam.bind(this)
        this.getProgress = this.getProgress.bind(this)
        this.saveProgress = this.saveProgress.bind(this)
        this.prevQuestion = this.prevQuestion.bind(this)
        this.nextQuestion = this.nextQuestion.bind(this)
        this.skipQuestion = this.skipQuestion.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this.startExam = this.startExam.bind(this)
    }

    componentDidMount() {
        this.getExam()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    closeExam() {
        window.close()
    }

    endExam() {
        this.setState(
            {
                time_over: true,
                is_finished: true,
            },
            () => {
                this.saveProgress()
            }
        )
    }

    startExam() {
        this.setState(
            {
                exam_start: true,
            },
            () => {
                this.updateDuration()
            }
        )
    }

    skipQuestion(index) {
        const id = index
        const active = this.state.active
        this.saveProgress()
        const question = this.state.question[active]
        let answerList = this.state.answerList
        if (answerList[question.id] == undefined) {
            answerList[question.id] = ''
        }
        this.setState(
            {
                answerList,
            },
            () => {
                this._handleChange('active', id)
            }
        )
    }

    prevQuestion(e) {
        e.preventDefault()
        let active = this.state.active
        if (active > 0) {
            this.saveProgress()
            const question = this.state.question[active]
            let answerList = this.state.answerList
            if (answerList[question.id] == undefined) {
                answerList[question.id] = ''
            }
            this.setState(
                {
                    answerList,
                },
                () => {
                    this._handleChange('active', parseInt(active) - 1)
                }
            )
        }
    }

    nextQuestion(e) {
        e.preventDefault()
        let active = this.state.active
        const total = this.state.question.length
        if (active < parseInt(total) - 1) {
            this.saveProgress()
            const question = this.state.question[active]
            let answerList = this.state.answerList
            if (answerList[question.id] == undefined) {
                answerList[question.id] = ''
            }
            this.setState(
                {
                    answerList,
                },
                () => {
                    this._handleChange('active', parseInt(active) + 1)
                }
            )
        }
    }

    getProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getUserProgress(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this._handleChange('loaded', true)
                const progress = response.data.onlineExam
                let duration = moment.duration(moment(this.state.exam.exam_date + ' ' + this.state.exam.end_time, 'yyyy-MM-DD hh:mm a').diff(moment(this.state.exam.exam_date + ' ' + this.state.exam.start_time, 'yyyy-MM-DD hh:mm a')))
                if (progress.length > 0) {
                    let time_over = false
                    let is_finished = false
                    const active = this.state.question.findIndex((item) => item.id == progress[0].question_id)
                    let answerList = []
                    progress.map((item, index) => {
                        if (item.answer_id != null) {
                            answerList[item.question_id] = item.answer_id
                        } else {
                            answerList[item.question_id] = item.subjective_answers
                        }
                        if (item.is_finished) {
                            time_over = true
                            is_finished = true
                        }
                    })
                    this.setState(
                        {
                            active,
                            time_over,
                            is_finished,
                            answerList,
                            duration: duration.subtract(duration.as('milliseconds') - progress[0].duration, 'milliseconds'),
                        },
                        () => {
                            this.startExam()
                        }
                    )
                } else {
                    this._handleChange('duration', duration)
                }
            } else {
                redirect(route.activity, this.props.history)
            }
        })
    }

    getExam() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getOnlineExam(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        exam: response.data.exam,
                        question: response.data.exam.question_bank != null ? response.data.exam.question_bank.question : null,
                    },
                    () => {
                        this.getProgress()
                    }
                )
            } else {
                redirect(route.activity, this.props.history)
            }
        })
    }

    updateDuration() {
        const interval = setInterval(() => {
            const end_time = moment(this.state.exam.end_time, 'hh:mm a')
            const current_time = moment(moment(), 'hh:mm a')
            if (current_time.isAfter(end_time)) {
                this.endExam()
                clearInterval(interval)
            } else {
                const realDuration = moment.duration(end_time.diff(current_time))
                let duration = realDuration.subtract(1, 'seconds')
                if (duration.as('milliseconds') > 0) {
                    this.setState({
                        duration: duration,
                    })
                } else {
                    this.setState({
                        time_over: true,
                    })
                }
            }
        }, 1000)
    }

    saveProgress() {
        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        const question = this.state.question[this.state.active]
        const answer = this.state.answerList[question.id]
        const data = {
            exam_id: this.props.match.params.id,
            question_id: question.id,
            duration: this.state.duration.as('milliseconds'),
            time: moment().format('hh:mm a'),
            is_finished: this.state.is_finished,
        }
        if (answer != undefined) {
            if (Number.isInteger(answer)) {
                data.answer_id = answer
            } else {
                data.subjective_answers = answer
            }
        }
        saveUserProgress(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
