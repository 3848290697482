import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractRoleViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { withTranslation } from 'react-i18next'

class RoleViewComponent extends AbstractRoleViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getModal = this.getModal.bind(this)
    }

    getModal() {
        const { t } = this.props
        const modal = this.state.data.map((item, index) => {
            let text = t('classes:class.disable')
            if (item.status != Status.ACTIVE) {
                text = t('classes:class.enable')
            }
            return (
                <div key={index} id={'modal_role_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('classes:class.areyousureyouwantto')} {''}
                                    {text} {t('employee:employee.info.user-info.role')}?
                                </p>
                                <p className="delete-file-name">"{item.name}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>{t('users:role.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('users:role.title')}:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.user.role.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.role} />
                </div>
                {this.getModal()}
            </div>
        )
    }
}

const RoleView = withTranslation()(connect(mapStateToProps)(RoleViewComponent))

export { RoleView }
