import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractHolidayComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Dashboard } from '~/components/dashboard'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import {withTranslation} from 'react-i18next'
class HolidayComponent extends AbstractHolidayComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getHolidayModal = this.getHolidayModal.bind(this)
        this.getStatusModal = this.getStatusModal.bind(this)
    }

    getStatusModal() {
        const {t}= this.props
        const modal = this.state.data.map((item, index) => {
            let text = t('calendar:holiday.disable')
            if (item.status != Status.ACTIVE) {
                text = t('calendar:holiday.enable')
            }
            return (
                <div key={index} id={'modal_status_' + item.id} data-id={'modal_status_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question"> {t('calendar:holiday.areyousureyouwantto')} {text}?</p>
                                <p className="delete-file-name">"{item.description}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('common:updateBtn')} link={item.id} main={true} onClick={this.toggle} />
                                <button id={'close_status_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getHolidayModal() {
        const {t}= this.props

        const modal = this.state.data.map((item, index) => {
            return (
                <div key={index} id={'modal_delete_' + item.id} data-id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('calendar:holiday.view.deletePostMsg')}</p>
                                <p className="delete-file-name">"{item.description}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button type="submit" text={t('calendar:holiday.view.delete')} link={item.id} main={true} onClick={this.deleteHoliday} />
                                <button id={'close_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const {t}= this.props

        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="calendar" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.calendar}>{t('calendar:calendar-menu.breadcrumb')} > </a>
                            </li>
                            <li>{t('calendar:holiday.view.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('calendar:holiday.view.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <Table data={this.state.holiday} />
                    </div>
                </div>
                {this.getHolidayModal()}
                {this.getStatusModal()}
            </div>
        )
    }
}

const Holiday = withTranslation()(connect(mapStateToProps)(HolidayComponent))

export { Holiday }
