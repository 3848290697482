import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addQuestionBank, viewQuestionBank } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    classes: Object,
    question_bank: Array,
    subject: Array,
    activeTab: Integer,
    isPrivate: Boolean,
    employee: Array,
    student: Array,
    selected_subject: Integer,
    errors: Object,
    hideClass: Boolean,
    hideSection: Boolean,
    selectedClass: Integer,
    showSubject: Boolean,
}

export class AbstractQuestionBankViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            activeTab: 0,
            selectedClass: this.props.match.params.id == 0 ? -1 : this.props.match.params.id,
            hideClass: this.props.match.params.id == 0 ? false : true,
            hideSection: false,
            showSubject: this.props.match.params.id == 0 ? false : true,
            isPrivate: true,
            subject: [],
            employee: [],
            question_bank: [],
            student: [],
            errors: {
                title: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadQuestionBank = this.loadQuestionBank.bind(this)
    }

    componentDidMount() {
        this.loadSubject()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadQuestionBank() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewQuestionBank(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    question_bank: response.data.question_bank,
                    loaded: true,
                })
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.props.match.params.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                            activeTab: response.data.class.subject.length == 0 ? 'other' : 0,
                        },
                        () => {
                            this.loadQuestionBank()
                        }
                    )
                }
            })
        } else {
            this.setState(
                {
                    activeTab: 'other',
                },
                () => {
                    this.loadQuestionBank()
                }
            )
            this.loadQuestionBank()
        }
    }

    _onSave(e) {
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const errors = validation.required(Object.keys(component.state.errors), component.state)
            component.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                component._handleChange('loading_text', 'Saving')
                component._handleChange('loading', true)
                const data = {
                    title: component.state.title,
                    description: component.state.description,
                    restriction: component.state.isPrivate ? 1 : 0,
                }
                if (component.state.classes != null) {
                    data.class_access = [component.state.classes.id]
                }
                if (component.state.isPrivate) {
                    if (component.state.student != null && component.state.employee != null) {
                        data.access = component.state.employee.concat(component.state.student)
                    } else if (component.state.student != null) {
                        data.access = component.state.student
                    } else {
                        data.access = component.state.employee
                    }
                    if (component.state.selected_subject != null || component.state.selected_subject != -1) {
                        data.subject_id = component.state.selected_subject
                    }
                }
                addQuestionBank(data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        component.loadQuestionBank()
                        resolve(true)
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ errors: response.data.errors })
                            }
                        }
                        resolve(false)
                    }
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
