import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea } from '~/components/form'
import MultiImageInput from 'react-multiple-image-input'
import { Loading } from '~/components/loader'
import Select from 'react-select'
import Files from 'react-files'
import DatePicker from 'react-datepicker'
import $ from 'jquery'
import { faEdgeLegacy } from '@fortawesome/free-brands-svg-icons'
import { withTranslation } from 'react-i18next'
import { convertADtoBS } from '../../../../../helper/function'

class StudentEditComponent extends AbstractStudentEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setDate = this.setDate.bind(this)
        this.setJoinDate = this.setJoinDate.bind(this)
        this.setStudentCategory = this.setStudentCategory.bind(this)
    }

    setStudentCategory(data) {
        super._handleChange('selectedStudentCategory', data.value)
        super._handleChange('defaultSelected', data)
    }

    setDate(data) {
        super._handleChange('dob', data)
    }

    setJoinDate(data) {
        super._handleChange('joining_date', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.user.student.menu}>{t('student:student.title')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.user.student.view + '/' + this.state.section_id}>{t('student:student.breadcrumb')} > </a>
                            </li>
                            <li>{t('student:student.edit.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <span className="sub-main-title">{this.state.section && this.state.section.classes.name + ' - ' + this.state.section.name}</span>
                                <h5 className="main-title heading5">{t('student:student.edit.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('student:student.add.nameLabel')} type="text" value={this.state.name} disabled={true} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student.add.emailLabel')}</label>
                                    <Input name="email" onChange={this._handleChange} placeholder={t('student:student.add.emailLabel')} type="text" value={this.state.email} disabled={true} />
                                    {this.state.errors.email && <label className="error text-danger">{this.state.errors.email}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student.add.dobLabel')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.dob} onChange={this.setDate} placeholderText={t('student:student.add.dobLabel')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.dob != null ? (convertADtoBS(this.state.dob) != null ? convertADtoBS(this.state.dob) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.dob && <label className="error text-danger">{this.state.errors.dob}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('student:student.add.joinDateLabel')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" readOnly={true} onChange={this.setJoinDate} selected={this.state.joining_date} placeholderText={t('student:student.add.joinDateLabel')} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.joining_date != null ? (convertADtoBS(this.state.joining_date) != null ? convertADtoBS(this.state.joining_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.joining_date && <label className="error text-danger">{this.state.errors.joining_date}</label>}
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('student:student.add.studentCategoryLabel')}</label>
                                    <Select name="student_category" options={this.state.student_category} placeholder={t('student:student.add.studentCategoryPlaceholder')} searchable={true} value={this.state.defaultSelected && this.state.defaultSelected[0]} onChange={this.setStudentCategory} />
                                </div>
                                {this.state.roll_no_required == true && (
                                    <div className="col-sm-6 float-left form-group main-select">
                                        <label className="form-title">{t('student:student.rollNo')}</label>
                                        <Input name="roll_no" onChange={this._handleChange} placeholder={t('student:student.rollNo')} type="number" value={this.state.roll_no} />
                                        {this.state.roll_number_validation_msg && <label className="error text-danger">{this.state.roll_number_validation_msg}</label>}
                                    </div>
                                )}
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const StudentEdit = withTranslation()(connect(mapStateToProps)(StudentEditComponent))

export { StudentEdit }
