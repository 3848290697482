import moment from 'moment'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { toastMessage } from '~/helper/function/util'
import {
    addCourseProgress,
    editCourseProgress,
    getClass,
    getCourseModuleItems,
    viewCourseContentByModule,
    viewCourseProgress,
} from '~/services/api'
import { redirect } from '../../../../../helper/function'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseContentStudentComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            course: [],
            loading: true,
            loading_text: 'Loading',
            current_content: {},
            courseContent: [],
            courseContentData: [],
            courseModule: [],
            courseModuleData: [],
            modules: [],
            post_id: 0,
            feedback: '',
            errorsfeedback: null,
            current_course: null,
            current_progress: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseContent = this.loadCourseContent.bind(this)
        this.addProgress = this.addProgress.bind(this)
        this.editProgress = this.editProgress.bind(this)
        this.addFeedback = this.addFeedback.bind(this)
    }

    componentDidMount() {
        this.loadCourseProgress()
        this.loadCourseContent()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    addFeedback(current_progress, current_course) {
        const component = this
        return new Promise(function (resolve, reject) {
            component.setState(
                {
                    errorsfeedback: null,
                    feedback: current_progress.feedback,
                    current_progress,
                    current_course,
                },
                () => {
                    resolve(true)
                }
            )
        })
    }

    addProgress(url, content_id) {
        this._handleChange('loading_text', 'Saving')
        this._handleChange('loading', true)
        // var win = window.open(url, "_blank");
        // win.focus();
        let data = {
            course_id: this.props.match.params.course_id,
            user_school_id:
                this.props.school.user_type == UserType.Parents
                    ? this.props.student
                    : this.props.school.active_user_academics.user_school_id,
            user_progress: 1,
            feedback: '',
            course_content_id: content_id,
        }

        addCourseProgress(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                // toastMessage("success", response.message);
                redirect(
                    route.course.student.details +
                        '/' +
                        this.props.match.params.course_id +
                        '/' +
                        this.props.match.params.module_id +
                        '/' +
                        content_id,
                    this.props.history
                )
                // this.loadCourseProgress()
                // this.loadCourseContent()
            } else {
                // toastMessage("error", response.message);
                // if (response.data) {
                //   if ("errors" in response.data) {
                //     this.setState({ errors: response.data.errors });
                //   }
                // }
            }
        })
    }

    editProgress(current_progress, validate) {
        const { t } = this.props
        const component = this
        return new Promise(function (resolve, reject) {
            if (validate == 1) {
                if (component.state.feedback == '' || component.state.feedback == null) {
                    component.setState({
                        errorsfeedback: t('toaster:required'),
                    })
                    resolve(false)
                    return
                }
            }
            component._handleChange('loading_text', 'Saving')
            component._handleChange('loading', true)
            let data = {
                course_id: component.props.match.params.course_id,
                user_school_id:
                    component.props.school.user_type == UserType.Parents
                        ? component.props.student
                        : component.props.school.active_user_academics.user_school_id,
                user_progress: current_progress.user_progress,
                feedback: component.state.feedback,
            }
            editCourseProgress(current_progress.id, data, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', t('toaster:feedbackSentMsg'))
                    component.loadCourseProgress()
                    component.loadCourseContent()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            component.setState({ errors: response.data.errors })
                        }
                    }
                    resolve(false)
                }
            })
        })
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                const course_progress = response.data.course_progress

                this.setState({
                    course_progress,
                })
            }
        })
    }

    loadCourseModule(module_id) {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseModuleItems(module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                let post_id = courseModule.course.post_id
                if (moment().diff(moment(courseModule.release_date)) < 0) {
                    window.location = route.course.student.view
                }
                this.setState({
                    courseModule,
                    post_id,
                    loaded: true,
                })
            }
        })
    }

    loadCourseContent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseContentByModule(this.props.match.params.module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let courseContent = response.data.course_content
                courseContent = courseContent.filter((el) => {
                    return moment().diff(moment(el.start_time, 'yyyy-MM-DD - hh:mm:ss a')) >= 0
                })
                let courseContentData = []
                // courseContent.map((item, index) => {
                //     const courseContentSelect = {
                //         value: item.id,
                //         label: item.name,
                //     }
                //     courseContentData.push(courseContentSelect)
                // })
                this.setState(
                    {
                        courseContent,
                        courseContentData,
                    },
                    () => {
                        if (courseContent.length > 0) {
                            if (courseContent[0].course_module_id) {
                                this.loadCourseModule(courseContent[0].course_module_id)
                            }
                        }
                    }
                )
            }
        })
    }

    loadSubject() {
        if (this.props.school.active_user_academics.section.class_id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.props.school.active_user_academics.section.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseContent()
                        }
                    )
                }
            })
        } else {
            this.loadCourseContent()
        }
    }
}
const AbstractCourseContentStudent = withTranslation()(AbstractCourseContentStudentComponent)

export { AbstractCourseContentStudent }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        student: state.student,
    }
}
