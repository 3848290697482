import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { searchAlumni, viewAlumniCategory } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as validation from '~/helper/constant/validation'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    data: Array,
    loading: Boolean,
}

export class AbstractAlumniDashboardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            alumniCategoryList: [],
            batchList: [],
            groupList: [],
            companyList: [],
            designationList: [],
            alumniList: [],
            filteredAlumniList: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.filterAlumni = this.filterAlumni.bind(this)
        this.loadFilter = this.loadFilter.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadFilter(type, id) {
        if (type == ALUMNI_CATEGORY.Alumni_Batch) {
            let batchList = this.state.batchList
            const check = this.state.batchList.find((item) => item == id)
            if (check == null) {
                batchList.push(id)
            } else {
                batchList = batchList.filter((item) => item != id)
            }
            this.setState({
                batchList,
            })
        } else if (type == ALUMNI_CATEGORY.Alumni_Group) {
            let groupList = this.state.groupList
            const check = this.state.groupList.find((item) => item == id)
            if (check == null) {
                groupList.push(id)
            } else {
                groupList = groupList.filter((item) => item != id)
            }
            this.setState({
                groupList,
            })
        } else if (type == ALUMNI_CATEGORY.Alumni_Company) {
            let companyList = this.state.companyList
            const check = this.state.companyList.find((item) => item == id)
            if (check == null) {
                companyList.push(id)
            } else {
                companyList = companyList.filter((item) => item != id)
            }
            this.setState({
                companyList,
            })
        } else {
            let designationList = this.state.designationList
            const check = this.state.designationList.find((item) => item == id)
            if (check == null) {
                designationList.push(id)
            } else {
                designationList = designationList.filter((item) => item != id)
            }
            this.setState({
                designationList,
            })
        }
    }

    filterAlumni(value) {
        const filteredAlumniList = this.state.alumniList.filter((item) => {
            return item.user_school.user.name != null ? item.user_school.user.name.toLowerCase().includes(value.toLowerCase()) : item.user_school.user.toLowerCase().reg_no.includes(value.toLowerCase())
        })
        this.setState({
            filteredAlumniList,
        })
    }

    loadAlumniCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAlumniCategory(this.props.school.school_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        alumniCategoryList: response.data.alumni_category.sort((a, b) => (a.name > b.name ? 1 : -1)),
                    },
                    () => {
                        this.loadAlumni()
                    }
                )
            }
        })
    }

    clearFilter() {
        this.setState(
            {
                batchList: [],
                companyList: [],
                groupList: [],
                designationList: [],
            },
            () => {
                this.loadAlumni()
            }
        )
    }

    loadAlumni() {
        const data = {
            batch_id: this.state.batchList,
            company_id: this.state.companyList,
            group_id: this.state.groupList,
            designation_id: this.state.designationList,
        }
        this._handleChange('loading', true)
        searchAlumni(data, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let data = []
            if (response.code == API_SUCCESS) {
                data = response.data.alumni.sort((a, b) => {
                    return a.user_school.user.name > b.user_school.user.name ? 1 : -1
                })
            }
            this.setState({
                alumniList: data,
                filteredAlumniList: data,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
