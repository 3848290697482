import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getReport, deletePost, deleteComment, toggleUserSchool } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import moment from 'moment'
import { toastMessage } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractPostReportViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            page: 0,
            postList: [],
            commentList: [],
            userList: [],
            loading: false,
            start_date: moment(moment().format('yyyy-MM-DD')).subtract(2, 'M').toDate(),
            end_date: moment(moment().format('yyyy-MM-DD')).toDate(),
            post_moderator: [
                {
                    recent_report_date: {
                        field: 'date',
                        translate: 'alumni:recentReportDate',
                        route: true,
                        sortable: true,
                    },
                    post_title: {
                        field: 'text',
                        translate: 'alumni:postTitle',
                        route: true,
                        sortable: true,
                    },
                    report_count: {
                        field: 'text',
                        translate: 'alumni:reportCount',
                        route: false,
                        sortable: true,
                    },
                    report: {
                        field: 'button',
                        translate: 'alumni:report',
                        route: false,
                        sortable: false,
                    },

                    action: {
                        field: 'button',
                        translate: 'alumni:review',
                        route: true,
                        sortable: false,
                    },
                },
            ],
            comment_moderator: [
                {
                    recent_report_date: {
                        field: 'date',
                        translate: 'alumni:recentReportDate',
                        route: true,
                        sortable: true,
                    },
                    comment: {
                        field: 'text',
                        translate: 'activity:comment',
                        route: true,
                        sortable: true,
                    },
                    report_count: {
                        field: 'text',
                        translate: 'alumni:reportCount',
                        route: false,
                        sortable: true,
                    },
                    report: {
                        field: 'button',
                        translate: 'alumni:report',
                        route: false,
                        sortable: false,
                    },
                },
            ],
            user_moderator: [
                {
                    recent_report_date: {
                        field: 'date',
                        translate: 'alumni:recentReportDate',
                        route: true,
                        sortable: true,
                    },
                    user: {
                        field: 'text',
                        translate: 'sidebar:users',
                        route: true,
                        sortable: true,
                    },
                    user_status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    report_count: {
                        field: 'text',
                        translate: 'alumni:reportCount',
                        route: false,
                        sortable: true,
                    },
                    report: {
                        field: 'button',
                        translate: 'alumni:report',
                        route: false,
                        sortable: false,
                    },
                    action: {
                        field: 'button',
                        translate: 'profile',
                        translate: 'exam:profile',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.validateFilter = this.validateFilter.bind(this)
        this._deleteComment = this._deleteComment.bind(this)
        this._deletePost = this._deletePost.bind(this)
        this._toggleUserStatus = this._toggleUserStatus.bind(this)
    }

    componentDidMount() {
        this.loadReport()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    validateFilter() {
        this._handleChange('filter_error', null)
        const from = moment(this.state.start_date, 'yyyy-MM-DD')
        const to = moment(this.state.end_date, 'yyyy-MM-DD')
        if (from.isAfter(to)) {
            this._handleChange('filter_error', 'Invalid Range')
            return false
        }
        return true
    }

    _deletePost(id) {
        this._handleChange('loading', true)
        deletePost(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadReport()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _deleteComment(id) {
        this._handleChange('loading', true)
        deleteComment(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadReport()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _toggleUserStatus(id) {
        this._handleChange('loading', true)
        toggleUserSchool(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadReport()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadReport() {
        const { t } = this.props
        this._handleChange('loading', true)
        const component = this
        return new Promise(function (resolve, reject) {
            const check = component.validateFilter()
            if (!check) {
                resolve(false)
            }
            const from = moment(component.state.start_date).format('yyyy-MM-DD')
            const to = moment(component.state.end_date).format('yyyy-MM-DD')
            getReport(from, to, component.props.token).then((response) => {
                component._handleChange('loading', false)
                const postList = []
                const commentList = []
                const userList = []
                let post_moderator = []
                post_moderator.push(component.state.post_moderator[0])
                let comment_moderator = []
                comment_moderator.push(component.state.comment_moderator[0])
                let user_moderator = []
                user_moderator.push(component.state.user_moderator[0])
                if (response.code == API_SUCCESS) {
                    response.data.report.post.map((item) => {
                        const data = {
                            recent_report_date: {
                                text: moment(item.latest_report.created_at).format('yyyy-MM-DD'),
                            },
                            post_title: {
                                text: item.title != null ? item.title : 'Post Id : ' + item.id,
                            },
                            report_count: {
                                text: item.report_count,
                            },
                            report: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                modal: 'modal_post_' + item.id,
                            },
                            action: {
                                text: 'Review',
                                translate: 'alumni:review',
                                route: route.activity + '/' + item.id,
                            },
                        }
                        post_moderator.push(data)
                        postList.push(item)
                    })
                    response.data.report.comment.map((item) => {
                        const data = {
                            recent_report_date: {
                                text: moment(item.latest_report.created_at).format('yyyy-MM-DD'),
                            },
                            comment: {
                                text: item.comment,
                            },
                            report_count: {
                                text: item.report_count,
                            },
                            report: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                modal: 'modal_comment_' + item.id,
                            },
                        }
                        comment_moderator.push(data)
                        commentList.push(item)
                    })
                    response.data.report.user.map((item) => {
                        let status = null
                        if (item.status == Status.ACTIVE) {
                            status = {
                                text: 'Active',
                                translate: t('common:statusActive'),
                                badge: 'table-green',
                            }
                        } else {
                            status = {
                                text: 'Inactive',
                                translate: t('common:statusInactive'),
                                badge: 'table-red',
                            }
                        }
                        const data = {
                            recent_report_date: {
                                text: moment(item.latest_report.created_at).format('yyyy-MM-DD'),
                            },
                            user: {
                                text: item.user.name != null ? item.user.name : item.user.reg_no,
                            },
                            user_status: status,
                            report_count: {
                                text: item.report_count,
                            },
                            report: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                modal: 'modal_user_' + item.id,
                            },
                            action: {
                                text: 'View',
                                translate: 'common:viewBtn',
                                route: route.user_profile + '/' + item.user.id,
                            },
                        }
                        user_moderator.push(data)
                        userList.push(item)
                    })
                    resolve(true)
                } else {
                    resolve(false)
                }
                component.setState({
                    post_moderator,
                    comment_moderator,
                    user_moderator,
                    postList,
                    commentList,
                    userList,
                })
            })
        })
    }
}

const AbstractPostReportView = withTranslation()(AbstractPostReportViewComponent)

export { AbstractPostReportView }

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
    }
}
