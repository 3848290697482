import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractTimetableComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as Status from '~/helper/constant/status'
import { timetable_color } from '~/helper/constant/color'
import { Button, Input, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import Select from 'react-select'
import { Table } from '~/components/table'
import { faFreebsd } from '@fortawesome/free-brands-svg-icons'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'

class TimetableComponent extends AbstractTimetableComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getFilteredTimetable = this.getFilteredTimetable.bind(this)
        this.getInfoModal = this.getInfoModal.bind(this)
        this.getLiveModal = this.getLiveModal.bind(this)
        this._toggle = this._toggle.bind(this)
        this._join = this._join.bind(this)
    }

    _toggle(e) {
        super._toggle(e)
        $('#close_toggle_' + link).trigger('click')
    }
    _join(e) {
        super._join(e)
        $('#close_join_' + id[0]).trigger('click')
    }

    getLiveModal() {
        const { t } = this.props
        const modal = this.state.filterTimeTable.map((item, index) => {
            let text = t('timetable:stopLiveClass')
            if (item.status != Status.ACTIVE) {
                text = t('timetable:startLiveClass')
            }
            return (
                <div key={index} id={'modal_toggle_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {text} {t('timetable:toggleLiveClass')}?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._toggle} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                <button id={'close_toggle_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getJoinModal() {
        const { t } = this.props
        const modal = this.state.filterTimeTable.map((item, index) => {
            return (
                <div key={index} id={'modal_join_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('timetable:join')} {t('timetable:toggleLiveClass')}?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                {item.bbb != null && <Button onClick={this._join} link={item.id + ',' + item.bbb.id} type="submit" text={t('common:modal confirm')} main={true} />}
                                <button id={'close_join_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getInfoModal() {
        const { t } = this.props
        const modal = this.state.filterTimeTable.map((item, index) => {
            return (
                <div key={index} id={'modal_info_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('timetable:periodInfo')} : </span>
                        </div>
                        <br />
                        <form className="main-form month-view p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <ul className="list-inline day-view-list">
                                        <li className="small-green">
                                            <small>{item.start_time}</small>
                                            <div className="day-view-detail">
                                                {item.subject && (
                                                    <ins>
                                                        {item.subject.name} &nbsp;&nbsp;
                                                        {item.live_class_status == true && <ins className="new-con">{t('timetable:liveClass')}</ins>}
                                                    </ins>
                                                )}
                                                {!item.subject && <ins>{t('timetable:break')}</ins>}
                                                {item.subject && (
                                                    <span>
                                                        {t('timetable:subject_code')}: {item.subject.code}
                                                    </span>
                                                )}
                                                {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                                    <Fragment>
                                                        {item.teacher && (
                                                            <strong>
                                                                {t('timetable:teacher')} : {item.teacher.user.name}
                                                            </strong>
                                                        )}
                                                    </Fragment>
                                                )}
                                                {this.props.school.user_type == UserType.Teacher && (
                                                    <Fragment>
                                                        {item.section.classes && (
                                                            <strong>
                                                                {t('timetable:class')} : {item.section.classes.name} - {item.section.name}
                                                            </strong>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="row">
                                        <label className="form-title col-md-5 float-left">{t('timetable:primaryTeacher')}</label>
                                        <div className="col-md-7 float-left">
                                            {item.teacher && <span className="teacher-name">{item.teacher.user.name}</span>}
                                            <br />
                                        </div>
                                    </div>

                                    {item.substitute != null && item.substitute.length > 0 && (
                                        <Fragment>
                                            <hr />
                                            <div className="row">
                                                <label className="form-title col-md-5 float-left">{t('timetable:substituteTeacher')}</label>
                                                <div className="col-md-7 float-left">
                                                    {this.state.teacher
                                                        .filter((data) => item.substitute.includes(data.value))
                                                        .map((data, index) => {
                                                            const name = data.label.split('-')
                                                            return (
                                                                <span key={index} className="teacher-name">
                                                                    {name[0]}
                                                                </span>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button id={'close_info_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getFilteredTimetable() {
        const { t } = this.props
        let i = -1
        if (this.state.filterTimeTable.length > 0) {
            const modal = this.state.filterTimeTable.map((item, index) => {
                i++
                if (i == 4) {
                    i = 0
                }
                return (
                    <li key={index} className={timetable_color[i]}>
                        <small>{item.start_time}</small>
                        <div className="day-view-detail">
                            {item.subject && (
                                <ins>
                                    {item.subject.name} &nbsp;&nbsp;
                                    {item.live_class_status == true && <ins className="new-con">{t('timetable:liveClass')}</ins>}
                                </ins>
                            )}
                            {!item.subject && <ins>{t('timetable:break')}</ins>}
                            {item.subject && (
                                <span>
                                    {t('timetable:subject_code')} : {item.subject.code}
                                </span>
                            )}
                            {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                <Fragment>
                                    {item.teacher && (
                                        <strong>
                                            {t('timetable:teacher')} : {item.teacher.user.name}
                                        </strong>
                                    )}
                                </Fragment>
                            )}
                            {this.props.school.user_type == UserType.Teacher && (
                                <Fragment>
                                    {item.section.classes && (
                                        <strong>
                                            {t('timetable:class')} : {item.section.classes.name} - {item.section.name}
                                        </strong>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        {this.props.school.user_type == UserType.Teacher && (
                            <Fragment>
                                {item.live_class_status == true && item.status == Status.INACTIVE && (
                                    <button className="start" data-toggle="modal" data-target={'#modal_toggle_' + item.id}>
                                        {t('timetable:startLiveClass')}
                                    </button>
                                )}
                            </Fragment>
                        )}
                        {item.live_class_status == true && item.status == Status.ACTIVE && (
                            <button className="start" data-toggle="modal" data-target={'#modal_join_' + item.id}>
                                {t('timetable:join')}
                            </button>
                        )}
                        <div className="e-d-t">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                    <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                </button>
                                <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                    <button className="dropdown-item" data-toggle="modal" data-target={'#modal_info_' + item.id}>
                                        {t('common:Info')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            })
            return modal
        } else {
            return (
                <p className="no-data">
                    {' '}
                    {SVG.nodata()} {t('timetable:assignedPeriod')}.{' '}
                </p>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="timetable" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option no-border mb-0 pb-0">
                        <ul className="list-inline breadcrum clearfix">
                            <li>{t('timetable:timetable')}</li>
                        </ul>
                    </div>
                    <div className="calender calendar-page">
                        <h5 className="main-title text-center">
                            <span className="sub-main-title">
                                {(this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) && (
                                    <Fragment>
                                        {this.state.classes && this.state.classes.name} - {this.state.section && this.state.section.name}
                                    </Fragment>
                                )}
                                {this.props.school.user_type == UserType.Teacher && this.props.user.name}
                            </span>
                            <br />
                            <button className="prev-next" onClick={(e) => this.prevDay(this.state.activeDay.value)}>
                                <FontAwesomeIcon className="icon" icon="chevron-left" color="#333" size="lg" />
                            </button>
                            {this.state.activeDay.label}
                            <button className="prev-next" onClick={(e) => this.nextDay(this.state.activeDay.value)}>
                                <FontAwesomeIcon className="icon" icon="chevron-right" color="#333" size="lg" />
                            </button>
                        </h5>
                        <br />
                        <div className="month-view">
                            <ul className="list-inline day-view-list">{this.getFilteredTimetable()}</ul>
                        </div>
                    </div>
                </div>
                {this.getInfoModal()}
                {this.getLiveModal()}
                {this.getJoinModal()}
            </div>
        )
    }
}

const Timetable = withTranslation()(connect(mapStateToProps)(TimetableComponent))

export { Timetable }
