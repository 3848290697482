import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getSection, activeSubject, viewTeacher, addTimetable, viewTimetable } from '~/services/api'
import { StudentDTO } from '~/model'
import * as Days from '~/helper/constant/days'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { calendar_color } from '~/helper/constant/color'
import * as Status from '~/helper/constant/status'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    events: Array,
    timetable_start_time: String,
    timetable_end_time: String,
    start_time: String,
    end_time: String,
    classes: Object,
    section: Object,
    subject: Object,
    selectedSubject: Object,
    subject_id: String,
    teacher: Array,
    selectedTeacher: Object,
    teacher_id: String,
    days: Array,
    selectedDays: Array,
    errors: Object,
    loading: Boolean,
    loading_text: String,
    live_class_status: Boolean,
    teacherAvailabilty: Array,
}

export class AbstractTimetableAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            events: [],
            timetable_start_time: '09:00:00',
            timetable_end_time: '17:00:00',
            subject_id: '',
            teacher_id: '',
            days: [],
            teacher: [],
            selectedDays: [],
            start_time: '',
            end_time: '',
            live_class_status: false,
            errors: {
                subject_id: null,
                days: null,
                start_time: null,
                end_time: null,
                teacher_id: null,
                live_class_status: null,
            },
            teacherAvailabilty: [
                {
                    days: {
                        field: 'text',
                        translate: 'common:days',
                        route: false,
                        sortable: true,
                    },
                    class: {
                        field: 'text',
                        translate: 'timetable:class',
                        route: false,
                        sortable: true,
                    },
                    start_time: {
                        field: 'text',
                        translate: 'timetable:startTime',
                        route: false,
                        sortable: true,
                    },
                    end_time: {
                        field: 'text',
                        translate: 'calendar:event.schedule.add.endTimeLabel',
                        route: false,
                        sortable: true,
                    },
                },
            ],
            selectedTeacher: {
                label: '',
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.validateTeacherTimeSlot = this.validateTeacherTimeSlot.bind(this)
        this.validateTimeSlot = this.validateTimeSlot.bind(this)
        this.loadTeacherAvailabilty = this.loadTeacherAvailabilty.bind(this)
    }

    clearState() {
        this._handleChange('subject_id', this.state.subject[0].value)
        this._handleChange('selectedSubject', this.state.subject[0])
        this._handleChange('teacher_id', this.state.teacher[0].value)
        this._handleChange('selectedTeacher', this.state.teacher[0])
        this._handleChange('start_time', '')
        this._handleChange('end_time', '')
        this._handleChange('days', [])
        this._handleChange('selectedDays', [])
    }

    componentDidMount() {
        this.loadSection()
        this.loadTeacher()
        this.calculateTime()
    }

    calculateTime() {
        let addedHour = 0
        let timetable_start_time = this.state.timetable_start_time
        let timetable_end_time = this.state.timetable_end_time
        this.state.events.map((item, index) => {
            if (index == 0) {
                timetable_start_time = item.startTime
                timetable_end_time = item.endTime
            } else {
                if (item.startTime < timetable_start_time) {
                    timetable_start_time = item.startTime
                }
                if (item.endTime > timetable_end_time) {
                    timetable_end_time = item.endTime
                }
            }
        })
        let time = timetable_start_time.split(':')
        timetable_start_time = time[0] + ':' + time[1] + ':' + '00'
        time = timetable_end_time.split(':')
        if (time[0] <= 23) {
            addedHour = parseInt(time[0]) + 1
        }
        timetable_end_time = addedHour + ':' + time[1] + ':' + '00'
        this.setState({
            timetable_start_time,
            timetable_end_time,
        })
    }

    loadTeacher() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewTeacher(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let teacher = []
            if (response.code == API_SUCCESS) {
                response.data.teacher.map((item) => {
                    const timetable = []
                    item.timetable.map((time) => {
                        const data = {
                            start_time: time.start_time,
                            end_time: time.end_time,
                            day: time.day,
                            classes: time.section.classes.name,
                            section: time.section.name,
                        }
                        timetable.push(data)
                    })
                    const data = {
                        label: item.user.name + ' - ' + item.user.reg_no,
                        value: item.id,
                        timetable,
                    }
                    teacher.push(data)
                })
                if (teacher.length > 0) {
                    this.setState(
                        {
                            teacher,
                            teacher_id: teacher[0].value,
                            selectedTeacher: teacher[0],
                        },
                        () => {
                            this.loadTeacherAvailabilty()
                        }
                    )
                }
            }
        })
    }

    loadTeacherAvailabilty() {
        const { t } = this.props
        let teacherAvailabilty = []
        teacherAvailabilty.push(this.state.teacherAvailabilty[0])
        this.state.selectedTeacher.timetable.map((item) => {
            const days = Days.days.find((result) => result.value == item.day)
            const data = {
                days: {
                    text: days.label,
                    translate: days.translate,
                },
                class: {
                    text: item.classes + ' - ' + item.section,
                },
                start_time: {
                    text: item.start_time,
                },
                end_time: {
                    text: item.end_time,
                },
            }
            teacherAvailabilty.push(data)
        })
        this.setState({
            teacherAvailabilty,
        })
    }

    loadSection() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getSection(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let section = null
            let classes = null
            if (response.code == API_SUCCESS) {
                section = response.data.section
                if (response.data.section.classes) {
                    classes = response.data.section.classes
                }
                this.setState(
                    {
                        classes,
                        section,
                    },
                    () => {
                        this.loadTimetable()
                        this.loadSubject()
                    }
                )
            }
        })
    }

    loadSubject() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeSubject(this.state.classes.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let subject = []
            if (response.code == API_SUCCESS) {
                response.data.subject.map((item, index) => {
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    subject.push(data)
                })
                if (subject.length > 0) {
                    this.setState({
                        subject,
                        subject_id: subject[0].value,
                        selectedSubject: subject[0],
                    })
                } else {
                    this.setState({
                        subject,
                    })
                }
            }
        })
    }

    loadTimetable() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewTimetable(this.state.section.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let events = []
            let i = -1
            if (response.code == API_SUCCESS) {
                response.data.timetable.map((item, index) => {
                    let title = 'Break'
                    if (item.subject) {
                        title = item.subject.name
                        if (item.teacher) {
                            title = title + ',\n' + item.teacher.user.name
                        }
                    }
                    i++
                    if (i == 6) {
                        i = 0
                    }
                    const data = {
                        title: title,
                        daysOfWeek: [item.day],
                        startTime: moment(item.start_time, 'hh:mm:a').format('HH:mm'),
                        endTime: moment(item.end_time, 'hh:mm:a').format('HH:mm'),
                        borderColor: '#fff',
                        classNames: calendar_color[i],
                    }
                    events.push(data)
                })
                this.setState(
                    {
                        events,
                    },
                    () => {
                        this.calculateTime()
                    }
                )
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    validateTimeSlot(timetable) {
        const occupiedTime = []
        const checkTimeSlot = []
        const start_time = moment(this.state.start_time, 'hh:mm a')
        const end_time = moment(this.state.end_time, 'hh:mm a')
        if (start_time.isAfter(end_time) || (start_time.hours() == end_time.hours() && start_time.minutes() == end_time.minutes())) {
            checkTimeSlot.push('Invalid time range')
            return checkTimeSlot
        }
        timetable.map((item) => {
            return this.state.events
                .filter((result) => {
                    const start_time = moment(item.start_time, 'hh:mm a')
                    const end_time = moment(item.end_time, 'hh:mm a')
                    const occupied_start_time = moment(result.startTime, 'hh:mm a')
                    const occupied_end_time = moment(result.endTime, 'hh:mm a')
                    if (occupied_start_time.isBetween(start_time, end_time) || occupied_end_time.isBetween(start_time, end_time) || start_time.isBetween(occupied_start_time, occupied_end_time) || end_time.isBetween(occupied_start_time, occupied_end_time) || (start_time.isSame(occupied_start_time) && end_time.isSame(occupied_end_time) && item.day == result.daysOfWeek[0])) {
                        return true
                    }
                })
                .map((result) => {
                    occupiedTime.push(result.daysOfWeek[0])
                })
        })
        this.state.selectedDays
            .filter((item) => occupiedTime.includes(item.value))
            .map((item, index) => {
                if (index == 0) {
                    checkTimeSlot.push('Time slot not available for ' + item.label)
                } else {
                    checkTimeSlot.push(' , ' + item.label)
                }
            })
        return checkTimeSlot
    }

    validateTeacherTimeSlot(timetable) {
        const occupiedTeacherTime = []
        timetable.map((item) => {
            return this.state.selectedTeacher.timetable
                .filter((result) => {
                    const start_time = moment(item.start_time, 'hh:mm a')
                    const end_time = moment(item.end_time, 'hh:mm a')
                    const occupied_start_time = moment(result.start_time, 'hh:mm a')
                    const occupied_end_time = moment(result.end_time, 'hh:mm a')
                    if (occupied_start_time.isBetween(start_time, end_time) || occupied_end_time.isBetween(start_time, end_time) || start_time.isBetween(occupied_start_time, occupied_end_time) || end_time.isBetween(occupied_start_time, occupied_end_time) || (start_time.isSame(occupied_start_time) && end_time.isSame(occupied_end_time) && item.day == result.day)) {
                        return true
                    }
                })
                .map((result) => {
                    occupiedTeacherTime.push(result.day)
                })
        })
        const checkTeacherTimeSlot = []
        this.state.selectedDays
            .filter((item) => occupiedTeacherTime.includes(item.value))
            .map((item, index) => {
                if (index == 0) {
                    checkTeacherTimeSlot.push('Time slot not available for teacher for ' + item.label)
                } else {
                    checkTeacherTimeSlot.push(' , ' + item.label)
                }
            })
        return checkTeacherTimeSlot
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            let timetable = []
            this.state.days.map((item) => {
                const data = {
                    day: item,
                    start_time: moment(this.state.start_time).format('hh:mm a'),
                    end_time: moment(this.state.end_time).format('hh:mm a'),
                    section_id: this.state.section.id,
                    subject_id: this.state.subject_id,
                    teacher_id: this.state.teacher_id,
                    live_class_status: this.state.live_class_status,
                }
                timetable.push(data)
            })
            const data = {
                timetable: timetable,
            }
            const checkTimeSlot = this.validateTimeSlot(timetable)
            const checkTeacherTimeSlot = this.validateTeacherTimeSlot(timetable)
            if (checkTimeSlot.length > 0) {
                errors['start_time'] = checkTimeSlot
            } else {
                errors['start_time'] = null
            }
            if (checkTeacherTimeSlot.length > 0) {
                errors['teacher_id'] = checkTeacherTimeSlot
            } else {
                errors['teacher_id'] = null
            }
            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                addTimetable(data, this.props.token).then((response) => {
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                    } else {
                        toastMessage('error', response.message)
                    }
                    this.clearState()
                    this.loadTimetable()
                    this.loadTeacher()
                    this._handleChange('loading', false)
                })
            }
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
