import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as ReactTable from 'react-super-responsive-table'
import * as SVG from '~/assets/svg'
import { Button, Input } from '~/components/form'
import { convertADtoBS } from '../../helper/function/abstract'
import { AbstractTableComponent, mapStateToProps } from './abstract'

class TableComponent extends AbstractTableComponent {
    constructor(props) {
        super(props)
        const { t } = this.props
        this.state = {
            ...this.state,
            language: this.props.i18n.language,
            orderData: [
                { value: 'Asc', label: t('Asc') },
                { value: 'Desc', label: t('Desc') },
            ],
        }
    }

    componentDidMount() {
        this.getSort()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.language != this.props.i18n.language) {
            const { t } = this.props
            this.setState({
                language: this.props.i18n.language,
                orderData: [
                    { value: 'Asc', label: t('Asc') },
                    { value: 'Desc', label: t('Desc') },
                ],
            })
            this.getSort()
        }
    }

    getSort() {
        const { t } = this.props
        let sortData = []
        if (this.state.keys) {
            Object.keys(this.state.keys).map((key, index) => {
                if (this.state.keys[key].sortable) {
                    sortData.push({
                        value: key,
                        label:
                            this.props.data[0][key].translate != null
                                ? this.textCapitalize(this.changeUnderScore(t(this.props.data[0][key].translate)))
                                : this.textCapitalize(this.changeUnderScore(key)),
                    })
                }
            })
        }
        this.setState({
            sortData,
        })
    }

    search(key, value) {
        super.search(key, value)
        this.getData()
    }

    textCapitalize(str) {
        return super.textCapitalize(str)
    }

    getKey() {
        const { t } = this.props
        if (this.state.keys) {
            return Object.keys(this.state.keys).map((key, index) => {
                let title = this.textCapitalize(this.changeUnderScore(key))
                if (this.props.data[0][key].translate != null) {
                    title = this.textCapitalize(this.changeUnderScore(t(this.props.data[0][key].translate)))
                }
                let date = null
                if (this.props.data[0][key].field == 'date') {
                    date =
                        this.props.school != null &&
                        this.props.school.school != null &&
                        this.props.school.school.date_format == 'BS'
                            ? this.props.school.school.date_format
                            : null
                }
                return (
                    <ReactTable.Th key={index}>
                        <span className="look-down">
                            {title} <small>{date != null ? '( ' + date + ' )' : ''}</small>
                        </span>
                    </ReactTable.Th>
                )
            })
        }
    }

    getData() {
        const { t } = this.props
        if (this.props.data) {
            const data = this.props.data
                .filter((item, index) => {
                    const head = index != 0
                    if (this.state.search) {
                        let search = ''
                        if (Number.isInteger(search)) {
                            search = this.state.search
                        } else {
                            search = this.state.search.toLowerCase()
                        }
                        return (
                            head &&
                            Object.keys(item).some((key) => {
                                if (item[key].text != null) {
                                    if (!Array.isArray(item[key].text)) {
                                        if (isNaN(item[key].text)) {
                                            return item[key].text.toLowerCase().includes(search)
                                        } else {
                                            return item[key].text.toString().includes(search)
                                        }
                                        // if (Number.isInteger(item[key].text)) {
                                        //     return item[key].text.toString().includes(search)
                                        // } else {
                                        //     return item[key].text.toLowerCase().includes(search)
                                        // }
                                    }
                                }
                            })
                        )
                    } else {
                        return head
                    }
                })
                .sort((a, b) => {
                    const { t } = this.props
                    if (this.state.sort && this.state.order) {
                        if (this.state.order.value == 'Asc') {
                            if (Number.isInteger(a[this.state.sort.value].text)) {
                                return a[this.state.sort.value].text > b[this.state.sort.value].text ? 1 : -1
                            } else {
                                if (a[this.state.sort.value].text != null || b[this.state.sort.value].text != null) {
                                    return a[this.state.sort.value].text > b[this.state.sort.value].text ? 1 : -1
                                } else {
                                    return a[this.state.sort.value].text.toLowerCase() >
                                        b[this.state.sort.value].text.toLowerCase()
                                        ? 1
                                        : -1
                                }
                            }
                        } else {
                            if (Number.isInteger(a[this.state.sort.value].text)) {
                                return a[this.state.sort.value].text < b[this.state.sort.value].text ? 1 : -1
                            } else {
                                if (a[this.state.sort.value].text != null || b[this.state.sort.value].text != null) {
                                    return a[this.state.sort.value].text < b[this.state.sort.value].text ? 1 : -1
                                } else {
                                    return a[this.state.sort.value].text.toLowerCase() <
                                        b[this.state.sort.value].text.toLowerCase()
                                        ? 1
                                        : -1
                                }
                            }
                        }
                    }
                })
                .map((data, index) => {
                    return (
                        <ReactTable.Tr key={index}>
                            <ReactTable.Td>{index + 1}</ReactTable.Td>
                            {this.getDataElement(data)}
                        </ReactTable.Tr>
                    )
                })
            if (data == '') {
                const length = Object.keys(this.state.keys).length
                return (
                    <ReactTable.Tr>
                        <ReactTable.Td colSpan={length + 1}>
                            <p className="no-data">
                                {SVG.nodata()}
                                {this.props.message == null ? t('common:table.nodata') : t(this.props.message)}
                            </p>
                        </ReactTable.Td>
                    </ReactTable.Tr>
                )
            } else {
                return data
            }
        } else {
            return (
                <ReactTable.Tr>
                    <ReactTable.Td colSpan={length + 1}>
                        <p className="no-data">
                            {SVG.nodata()}
                            {t('common:table.nodata')}.
                        </p>
                    </ReactTable.Td>
                </ReactTable.Tr>
            )
        }
    }

    getBadges(data) {
        return data.text.map((item, index) => {
            return <li key={index}>{this.textCapitalize(item)}</li>
        })
    }

    getDataElement(data) {
        const keys = Object.keys(data)
        const { t } = this.props
        return keys.map((key, index) => {
            const check = this.state.keys
            if (check[key].field == 'participants') {
                let text = data[key].text
                if (text == null || text.length == 0) {
                    return <ReactTable.Td key={index}>N/A</ReactTable.Td>
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            <div className="participant-img clearfix">
                                {text.length > 0 && (
                                    <div className="img-part">
                                        <span>{text[0]}</span>
                                    </div>
                                )}
                                {text.length > 1 && (
                                    <div className="img-part table">
                                        <span className="blue">{text[1]}</span>
                                    </div>
                                )}
                                {text.length > 2 && (
                                    <div className="img-part table">
                                        <span className="green">{text[2]}</span>
                                    </div>
                                )}
                                {text.length > 3 && (
                                    <span className="part-number">
                                        &nbsp; + {text.length - 3} {t('todo:more')}
                                    </span>
                                )}
                            </div>
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'file') {
                const file_label =
                    data[key].translate != null
                        ? this.textCapitalize(t(data[key].translate))
                        : this.textCapitalize(data[key].text)
                if (data[key].route == '#') {
                    return <ReactTable.Td key={index}>{file_label}</ReactTable.Td>
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            <a href={data[key].route} target="_blank">
                                {SVG.download()} &nbsp;
                                {file_label}
                            </a>
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'text') {
                const text_label =
                    data[key].translate != null
                        ? this.textCapitalize(t(data[key].translate))
                        : this.textCapitalize(data[key].text)
                if (data[key].color == null) {
                    return <ReactTable.Td key={index}>{text_label}</ReactTable.Td>
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            <span style={{ color: data[key].color }}>{text_label}</span>
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'date') {
                let text_label =
                    data[key].translate != null
                        ? this.textCapitalize(t(data[key].translate))
                        : this.textCapitalize(data[key].text)
                text_label =
                    this.props.school != null &&
                    this.props.school.school != null &&
                    this.props.school.school.date_format == 'BS' &&
                    text_label != 'N/A'
                        ? convertADtoBS(text_label) != null
                            ? convertADtoBS(text_label)
                            : 'Invalid Date Range'
                        : text_label
                if (data[key].color == null) {
                    return <ReactTable.Td key={index}>{text_label}</ReactTable.Td>
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            <span style={{ color: data[key].color }}>{text_label}</span>
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'badge') {
                const badge_label =
                    data[key].translate != null
                        ? this.textCapitalize(t(data[key].translate))
                        : this.textCapitalize(data[key].text)
                if (data[key].modal) {
                    return (
                        <ReactTable.Td key={index}>
                            <span
                                data-toggle="modal"
                                data-target={'#' + data[key].modal}
                                className={'table-status cursor ' + data[key].badge}
                            >
                                {badge_label}
                            </span>
                        </ReactTable.Td>
                    )
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            {Array.isArray(data[key].text) && (
                                <ul className="module-list">{this.getBadges(data[key])}</ul>
                            )}
                            {!Array.isArray(data[key].text) && (
                                <span className={'table-status ' + data[key].badge}>{badge_label}</span>
                            )}
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'button') {
                const button_label =
                    data[key].translate != null
                        ? this.textCapitalize(t(data[key].translate))
                        : this.textCapitalize(data[key].text)
                if (data[key].modal) {
                    return (
                        <ReactTable.Td key={index}>
                            <Button modal={data[key].modal} type="button" text={button_label} table={true} />
                        </ReactTable.Td>
                    )
                } else {
                    return (
                        <ReactTable.Td key={index}>
                            <Button
                                onClick={() => this.route(data[key].route)}
                                link={data[key].route}
                                type="button"
                                text={button_label}
                                table={true}
                            />
                        </ReactTable.Td>
                    )
                }
            } else if (check[key].field == 'html') {
                return <ReactTable.Td key={index}>{data[key].html}</ReactTable.Td>
            }
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <div className="main-table">
                    <div className="row clearfix table-head">
                        <div className="col-sm-5 table-search col-xs-12 float-left">
                            <form className="main-search">
                                <Input
                                    onChange={this.search}
                                    name="search"
                                    placeholder={t('common:searchhere')}
                                    type="text"
                                    value=""
                                />
                                <div className="search-icon">{SVG.tablesearch()}</div>
                            </form>
                        </div>
                        <div className="col-sm-7 table-sort col-xs-12 float-left text-right">
                            <div className="display-inline-block top-select">
                                <Select
                                    placeholder={t('common:sort')}
                                    options={this.state.orderData}
                                    onChange={this.changeOrderBy}
                                />
                            </div>
                            <div className="display-inline-block top-select">
                                <Select
                                    placeholder={t('common:sortby')}
                                    options={this.state.sortData}
                                    onChange={this.changeSortBy}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table-all table-responsive">
                        <ReactTable.Table className="table">
                            <ReactTable.Thead>
                                <ReactTable.Tr>
                                    <ReactTable.Th>
                                        <span className="look-down">{t('academic:table.s.no')}</span>
                                    </ReactTable.Th>
                                    {this.getKey()}
                                </ReactTable.Tr>
                            </ReactTable.Thead>
                            <ReactTable.Tbody>{this.getData()}</ReactTable.Tbody>
                        </ReactTable.Table>
                    </div>
                </div>
                {/* <div className="text-right">
                    <ul  className="pagination">
                        <li  className="page-item disabled">
                            <a  className="page-link" href="javascript:void(0)" tabindex="-1">&lt;&lt;</a>
                        </li>
                        <li  className="page-item">
                            <a  className="page-link" href="javascript:void(0)">&lt;</a>
                        </li>
                        <li  className="page-item">
                            <a  className="page-link" href="javascript:void(0)">1</a>
                        </li>
                        <li  className="page-item active">
                            <a  className="page-link" href="javascript:void(0)">2
                                <span  className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li  className="page-item">
                            <a  className="page-link" href="javascript:void(0)">3</a>
                        </li>
                        <li  className="page-item">
                            <a  className="page-link" href="javascript:void(0)">&gt;</a>
                        </li>
                        <li  className="page-item">
                            <a  className="page-link" href="javascript:void(0)">&gt;&gt;</a>
                        </li>
                    </ul>
                </div> */}
            </div>
        )
    }
}

const Table = withTranslation()(connect(mapStateToProps)(TableComponent))

export { Table }
