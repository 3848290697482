import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { webinar, test, chat, live, backup, getSystemInfo } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    info: Array,
    free_space: Double,
    used_space: Double,
    space_percentage: Double,
}

export class AbstractDashboardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            info: {
                totalEnquiry: 0,
                totalPackage: 0,
                totalSchool: 0,
                totalSmsPackage: 0,
                totalStorage: 0,
                totalUser: 0,
                totalSchoolPackage: [],
            },
            free_space: 0,
            used_space: 0,
            space_percentage: 0,
        }
        this.restartWebinar = this.restartWebinar.bind(this)
        this.restartChat = this.restartChat.bind(this)
        this.restartTest = this.restartTest.bind(this)
        this.restartLive = this.restartLive.bind(this)
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadSystemInfo()
    }

    loadSystemInfo() {
        this._handleChange('loading', true)
        getSystemInfo(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        info: response.data.count,
                    },
                    () => {
                        this.caluclateSpace()
                    }
                )
            }
        })
    }

    caluclateSpace() {
        const used_space = this.state.info.totalStorage
        const total = 268435456000
        const free_space = total - used_space
        const space_percentage = parseFloat((used_space / total) * 100).toFixed(2)
        this.setState({
            used_space,
            free_space,
            space_percentage,
        })
    }

    restartWebinar() {
        this._handleChange('loading', true)
        webinar(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    restartTest() {
        this._handleChange('loading', true)
        test(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    restartChat() {
        toastMessage('error', 'Only after system goes to live server')
        return
        this._handleChange('loading', true)
        chat(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    restartLive() {
        toastMessage('error', 'Only after system goes to live server')
        return
        this._handleChange('loading', true)
        live(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
