import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addTimetable = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/timetable/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editTimetable = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('school-management/timetable/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const teacherTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/teacher/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleTeacherTimetable = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school-management/timetable/teacher/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
