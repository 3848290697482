import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewEmployeeCategory, toggleEmployeeCategory } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    category: Array,
    data: Array,
    loading: Boolean,
}

export class AbstractEmployeeCategoryViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            category: [
                {
                    category: {
                        field: 'text',
                        translate:"employee:employee-category.category",
                        route: false,
                        sortable: true,
                    },
                    code: {
                        field: 'text',
                        translate: "employee:employee-category.add.codeLabel",
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: "status",
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        translate: "employee:employee-category.action",
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleEmployeeCategory(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadCategory()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadCategory() {
        this._handleChange('loading', true)
        viewEmployeeCategory(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let category = []
            category.push(this.state.category[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.user_category.map((item, index) => {
                    const data = item
                    let text = null
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: "statusActive",
                            badge: 'table-green',
                            modal: 'modal_category_' + index,
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: "statusInactive",
                            badge: 'table-red',
                            modal: 'modal_category_' + index,
                        }
                    }
                    const tableData = {
                        category: {
                            text: data.name,
                        },
                        code: {
                            text: data.code,
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: "EditBtn",
                            route: route.admin.user.employee.category.edit + '/' + data.id,
                        },
                    }
                    dataList.push(data)
                    category.push(tableData)
                })
            }
            this.setState({
                data: dataList,
                category,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
