import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import * as Status from '~/helper/constant/status'
import { detailBooks, activeBookShelf} from '~/services/api'
import { book_type } from '../../../../../helper/constant/books'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBooksDetailsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            title:'',
            isbn:'',
            author:'',
            description:'',
            edition:'',
            published_date:'',
            added_date:'',
            bookCategory:'',
            bookType: '',
            bookShelfList:[],
            tableData: [],
            tableStructure: [{
                quantity:{
                    field: 'text',
                    route:false,
                    sortable:true,
                    translate: 'income:quantity'
                },
                shelf:{
                    field:'text',
                    route:false,
                    sortable: true,
                    translate:'library:shelf'
                }
            }]


        }
        this._handleChange = this._handleChange.bind(this)

    }
    componentDidMount() {
        this.loadBookDetails()
        this.loadBookShelves()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
    loadBookShelves(){
        this._handleChange('loading_text', "Loading")
        this._handleChange('loading', true)
        activeBookShelf(this.props.token).then((res)=>{
            this._handleChange('loading', false)
            if(res.code === API_SUCCESS){
                const bookShelfList = res.data.shelf.map((item)=>{
                    return{
                        label:item.name + '-' + item.code,
                        value: item.value
                    }
                })
                this.setState({
                    bookShelfList
                })
            }
            

        })
        
    }
    
    loadBookDetails(){
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        detailBooks(this.props.match.params.id, this.props.token).then((response)=>{
            this._handleChange('loading', false)
            const bookDetails = response.data.book;
            const type = book_type.find(type=>type.value === bookDetails.book_type)
            bookDetails.book_shelf.map((item, index)=>{
               const rowData ={
                quantity:{text: item.quantity},
                shelf: {text: item.shelves.name + ' ' + item.shelves.code}
               }
               tableData.push(item)
               tableStructure.push(rowData)
            })

            this.setState({
                title: bookDetails.title,
                isbn: bookDetails.isbn,
                author: bookDetails.author,
                edition: bookDetails.edition,
                published_date: bookDetails.published_date,
                added_date: bookDetails.added_date,
                description:bookDetails.description,
                bookCategory:bookDetails.categories.name,
                bookType: type.label,
                tableData,
                tableStructure

            })
        })

    }


}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school.school,
    }
}
