import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class StudentMenuComponent extends AbstractStudentMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectClass = this._selectClass.bind(this)
        this._selectSection = this._selectSection.bind(this)
    }

    _selectClass(data) {
        super._handleChange('selectedClass', data.value)
        super._handleChange('defaultClass', data)
        this.setState(
            {
                selectedClass: data.value,
            },
            () => this.loadSection(data.value)
        )
    }

    _selectSection(data) {
        super._handleChange('selectedSection', data.value)
        this._handleChange('defaultSection', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} > </a>
                            </li>
                            <li>{t('student:student-menu.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('student:student-menu.title')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('student:student-menu.classLabel')} *
                                    </label>
                                    <Select name="type" options={this.state.classAry} placeholder={t('student:student-menu.classLabel')} value={this.state.defaultClass} searchable={true} onChange={this._selectClass} />
                                    {this.state.errors.selectedClass && <label className="error text-danger">{this.state.errors.selectedClass}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label htmlFor="departmentclass" className="form-title">
                                        {t('student:student-menu.sectionLabel')} *
                                    </label>
                                    <Select name="type" options={this.state.sectionAry} placeholder={t('student:student-menu.sectionLabel')} value={this.state.defaultSection} searchable={true} onChange={this._selectSection} />
                                    {this.state.errors.selectedSection && <label className="error text-danger">{this.state.errors.selectedSection}</label>}
                                </div>
                            </div>
                            <div className="form-button text-left">
                                <button type="button" className="main-button" onClick={this.getURL}>
                                    {t('common:searchBtn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const StudentMenu = withTranslation()(connect(mapStateToProps)(StudentMenuComponent))

export { StudentMenu }
