import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { studentResult } from '~/services/api'

type Props = {
    token: Object,
    school: Object,
    dispatch: Dispatch<any>,
}

type State ={
    loading: Boolean,
    loading_text: String,
    tableData: Array,
    post:Object,
}

export class AbstractReportCardResultComponent extends Component<Props, State>{
    constructor(props: Props){
        super(props)
        const {t} = this.props
        this.state = {
            post:{},
            downloadLink:null,
            fileName:null,
            user_school_id: '',
            exam_id: this.props.match.params.examId,
            tableData:[
                {
                    subject:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:'classes:subject.title'
                    },
                    full_marks:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:'exam:fullMarks',
                    },
                    pass_marks:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate: 'exam:passMarks',
                    },
                    obtained_marks:{
                        field:'text',
                        route:false,
                        sortable:false,
                        translate:'exam:obtainedMarks',
                    },
                    status:{
                        field:'badge',
                        route:false,
                        sortable:true,
                        translate:'commom:status',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadResult = this.loadResult.bind(this)

    }
    componentDidMount(){
        this.loadResult()
    }
    _handleChange(key, data){
        this.setState({[key]:data})
    }

    loadResult(){
        const{t} =this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)


        studentResult(this.props.match.params.examId, this.props.token, this.props.match.params.studentId).then((response)=>{
            console.log("top", response)
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                console.log("test", response)
                let tableData = []
                let downloadLink = "#"
                let fileName = 'Report_Card.pdf'
                if(response.data.pdf !=null){
                    downloadLink =response.data.pdf.stream 
                    fileName = response.data.pdf.filename
                }
                tableData.push(this.state.tableData[0])
                response.data.result.map((item, index) =>{
                    let status={
                        text: 'Fail',
                        translate: 'exam:fail',
                        badge: 'table-red',
                    }
                    if(item.is_pass){
                        status ={
                            text: 'Pass',
                            translate: 'exam:pass',
                            badge: 'table-green',
                        }
                    }
                    const data = {
                        subject:{
                            text:item.exam.subject.name,
                        },
                        full_marks:{
                            text:item.exam.full_mark,
                        },
                        pass_marks:{
                            text:item.exam.pass_mark,
                        },
                        obtained_marks:{
                            text:item.marks,
                        },
                        status,
                    }
                    tableData.push(data)
                })
                this.setState({
                    tableData,
                    post:response.data.post,
                    downloadLink,
                    fileName,
                })
            }
        })
    }
    
}
const AbstractReportCardResult = withTranslation()(AbstractReportCardResultComponent)

export{ AbstractReportCardResult }

export function mapStateToProps(state: Object){
    return {token: state.token,
    school: state.school,}
}