const participantsReducer = (state = null, action) => {
    let data = []
    switch (action.type) {
        case 'PARTICIPANT_JOIN':
            if (state != null) {
                data = JSON.parse(state)
                const checkState = data.filter((item) => item === action.participant.data.user)
                if (checkState.length == 0) {
                    data.push(action.participant.data.user)
                }
            } else {
                data.push(action.participant.data.user)
            }
            state = JSON.stringify(data)
            return state
        case 'PARTICIPANT_LEAVE':
            if (state != null) {
                data = JSON.parse(state)
                const newState = data.filter((item) => item !== action.participant.data.user)
                state = JSON.stringify(newState)
            }
            return state
        case 'SIGN_OUT':
            return null
        case 'RESET':
            return null
        default:
            return state
    }
}

export default participantsReducer
