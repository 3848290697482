import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { configRoute } from '~/helper/function'
import { RedirectLogin } from '~/views/redirectlogin'

function checkAccess(access, subAccess, state) {
    let allowAccess = false
    if (access != undefined && state.schoolPackage != null) {
        access.map((item) => {
            const schoolPackage = JSON.parse(state.schoolPackage)
            if (schoolPackage.includes(item)) {
                if (subAccess != undefined && state.school != null && state.school.role != null) {
                    if (state.school.role.access.includes(subAccess)) {
                        allowAccess = true
                    }
                } else {
                    allowAccess = true
                }
            }
        })
    } else if (subAccess != undefined && state.school != null && state.school.role != null) {
        if (state.school.role.access.includes(subAccess)) {
            allowAccess = true
        }
    } else {
        allowAccess = true
    }
    return allowAccess
}

function checkWebAccess(state) {
    let allowAccess = true
    if (typeof document != 'undefined') {
        if (state.school != null && state.school.school != null && state.school.school.isOnlyApp == true) {
            if (
                state.school.user_type != null &&
                (state.school.user_type == UserType.Parents ||
                    state.school.user_type == UserType.Student ||
                    state.school.user_type == UserType.Alumni)
            ) {
                allowAccess = false
            }
        }
    }
    return allowAccess
}

function checkUserSchool(state) {
    let allowAccess = true
    if (state.school == null) {
        allowAccess = false
    } else if (state.school.active_user_academics == null && state.school.user_type == UserType.Student) {
        allowAccess = false
    } else if (state.student == null && state.school.user_type == UserType.Parents) {
        allowAccess = false
    } else if (state.school.status != Status.ACTIVE) {
        allowAccess = false
    } else {
        allowAccess = true
    }

    return allowAccess
}

//SUPERADMIN
export const SuperAdminRoute = ({ component: Component, ...rest }) => {
    const state = useSelector((state) => state)
    return (
        <Route
            {...rest}
            render={(props) =>
                state.isLogged && state.school.user_type == UserType.SuperAdmin ? (
                    <Component {...props} />
                ) : (
                    <RedirectLogin />
                )
            }
        />
    )
}

//ADMINISTRATION
export const AdminRoute = ({ component: Component, access, subAccess, path, ...rest }) => {
    const state = useSelector((state) => state)
    if (state.school != null) {
        if (state.school.school.setup || path == configRoute(route.admin.setup)) {
            const webAccess = checkWebAccess(state)
            const allowAccess = checkAccess(access, subAccess, state) && webAccess
            const checkUser = checkUserSchool(state)
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        allowAccess ? (
                            state.isLogged &&
                            (state.school.user_type == UserType.Custom || state.school.user_type == UserType.Admin) ? (
                                checkUser ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect to="/block" />
                                )
                            ) : (
                                <RedirectLogin />
                            )
                        ) : webAccess ? (
                            <Redirect to="/block" />
                        ) : (
                            <Redirect to="/restrict" />
                        )
                    }
                />
            )
        } else {
            return <Redirect to="/admin/setup" />
        }
    } else {
        return <RedirectLogin />
    }
}

export const TeacherRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
    const checkUser = checkUserSchool(state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged &&
                    (state.school.user_type == UserType.Custom || state.school.user_type == UserType.Teacher) ? (
                        checkUser ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to="/block" />
                        )
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/restrict" />
                )
            }
        />
    )
}

export const AdministrationRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
    const checkUser = checkUserSchool(state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged &&
                    (state.school.user_type == UserType.Custom ||
                        state.school.user_type == UserType.Teacher ||
                        state.school.user_type == UserType.Admin) ? (
                        <Component {...props} />
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/restrict" />
                )
            }
        />
    )
}

//PROFILE ROUTE
export const SchoolRoute = ({ component: Component, access, subAccess, path, ...rest }) => {
    const state = useSelector((state) => state)
    if (state.school != null) {
        if (
            state.school.school.setup ||
            path.includes(configRoute(route.admin.school.profile)) ||
            path.includes(configRoute(route.user_profile))
        ) {
            const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
            const checkUser = checkUserSchool(state)
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        allowAccess ? (
                            state.isLogged && state.school.user_type != UserType.SuperAdmin ? (
                                checkUser ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect to="/block" />
                                )
                            ) : (
                                <RedirectLogin />
                            )
                        ) : (
                            <Redirect to="/restrict" />
                        )
                    }
                />
            )
        } else {
            return <Redirect to="/admin/setup" />
        }
    } else {
        return <RedirectLogin />
    }
}

//SHARED ROUTE
export const AuthenticatedRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged ? (
                        <Component {...props} />
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/activity" />
                )
            }
        />
    )
}

//STUDENT AND PARENTS
export const StudentRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
    const checkUser = checkUserSchool(state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged &&
                    (state.school.user_type == UserType.Student || state.school.user_type == UserType.Parents) ? (
                        checkUser ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to="/block" />
                        )
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/restrict" />
                )
            }
        />
    )
}

export const ParentRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
    const checkUser = checkUserSchool(state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged && state.school.user_type == UserType.Parents ? (
                        checkUser ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to="/block" />
                        )
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/restrict" />
                )
            }
        />
    )
}

export const AlumniRoute = ({ component: Component, access, subAccess, ...rest }) => {
    const state = useSelector((state) => state)
    const allowAccess = checkAccess(access, subAccess, state) && checkWebAccess(state)
    const checkUser = checkUserSchool(state)
    return (
        <Route
            {...rest}
            render={(props) =>
                allowAccess ? (
                    state.isLogged &&
                    (state.school.user_type == UserType.Custom ||
                        state.school.user_type == UserType.Admin ||
                        state.school.user_type == UserType.Alumni) ? (
                        checkUser ? (
                            <Component {...props} />
                        ) : (
                            <Redirect to="/block" />
                        )
                    ) : (
                        <RedirectLogin />
                    )
                ) : (
                    <Redirect to="/restrict" />
                )
            }
        />
    )
}
