export default class SubjectDTO {
    id: integer
    name: String
    code: String
    exam_status: integer
    credit_hour: integer
    weekly_classes: integer
    subject_type_id: integer
    class_id: integer
    status: integer
    description: String
    gallery_id: integer
    extra_data: String

    constructor(id, name, code, exam_status, credit_hour, weekly_classes, subject_type_id, class_id, status, description, gallery_id, extra_data) {
        this.id = id
        this.name = name
        this.code = code
        this.exam_status = exam_status
        this.credit_hour = credit_hour
        this.weekly_classes = weekly_classes
        this.subject_type_id = subject_type_id
        this.class_id = class_id
        this.status = status
        this.description = description
        this.gallery_id = gallery_id
        this.extra_data = extra_data
    }
}
