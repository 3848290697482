import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { Admin, Custom, Teacher } from '~/helper/constant/user_type'
import { getBillId } from '~/helper/function'
import { getAllSchoolUser, getVendorBalance, viewBill } from '~/services/api'
import { configRoute } from '../../../../../../helper/function/abstract'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractBillComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: true,
            loading_text: 'Loading',
            status: null,
            filterStatus: [],
            userLists: [],
            toDate: moment().endOf('month').toDate(),
            fromDate: moment().subtract(3, 'months').startOf('month').toDate(),
            user: null,
            tableData: [],
            userBalance: null,
            tableStructure:
                this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view)
                    ? [
                          {
                              bill: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'accounting:bill',
                              },
                              users: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'gamification:user',
                              },
                              category: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'alumni:category',
                              },
                              bill_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'fee:billDate',
                              },
                              due_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:duedate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  translate: 'common:status',
                              },
                              action: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'activity:action',
                              },
                          },
                      ]
                    : [
                          {
                              bill: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'accounting:bill',
                              },
                              category: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'alumni:category',
                              },
                              bill_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'fee:billDate',
                              },
                              due_date: {
                                  field: 'date',
                                  route: false,
                                  sortable: true,
                                  translate: 'income:duedate',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  tanslate: 'common:status',
                              },
                              action: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'activity:action',
                              },
                          },
                      ],
            errors: {
                fromDate: null,
                toDate: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        this.loaduser()
    }
    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loaduser() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAllSchoolUser(this.props.schoolToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view)) {
                    const userLists = [
                        { label: 'All', value: '' },
                        ...response.data.users
                            .filter(
                                (item) =>
                                    item.user_type == Teacher || item.user_type == Custom || item.user_type == Admin
                            )
                            .map((item) => ({
                                label: item.user.name ? `${item.user.reg_no} - ${item.user.name}` : item.user.reg_no,
                                value: item.vendor_account_id,
                            })),
                    ]
                    this.setState(
                        {
                            userLists,
                        },
                        () => {
                            this.loadBill()
                        }
                    )
                } else {
                    const userLists = [
                        { label: 'All', value: '' },
                        ...response.data.users
                            .filter((item) => this.props.userSchool.id == item.id)
                            .map((item) => ({
                                label: item.user.name ? `${item.user.reg_no} - ${item.user.name}` : item.user.reg_no,
                                value: item.vendor_account_id,
                            })),
                    ]
                    this.setState(
                        {
                            userLists,
                            user: userLists.find((data) => data.value == this.props.userSchool.vendor_account_id),
                        },
                        () => {
                            this.loadBill()
                        }
                    )
                }
            }
        })
    }

    loadBill() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        const vendor = this.state.user != null ? this.state.user.value : ''
        const status = this.state.status != null ? this.state.status.value : ''
        const start_date = moment(this.state.fromDate).format('yyyy-MM-DD')
        const end_date = moment(this.state.toDate).format('yyyy-MM-DD')
        viewBill(start_date, end_date, vendor, status, this.props.token).then((response) => {
            const filterStatus = [
                { label: 'All', value: '' },
                ...response.data.status.map((item, index) => ({
                    label: item,
                    value: index.toString(), // Convert index to string to ensure consistent type
                })),
            ]
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const statusArray = response.data.status
                response.data.bills
                    .filter((item) =>
                        this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view)
                            ? true
                            : item.status != 0
                            ? true
                            : false
                    )
                    .map((item, index) => {
                        let text = null
                        let due_date = {
                            text: item.due_date,
                        }
                        if (item.status == 0) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-yellow',
                            }
                        } else if (item.status == 1) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-blue',
                            }
                        } else if (item.status == 2) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-red',
                            }
                        } else if (item.status == 3) {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-purple',
                            }
                        } else {
                            text = {
                                text: statusArray[item.status],
                                badge: 'table-green',
                            }
                        }

                        const today = moment(moment().format('yyyy-MM-DD'))
                        const due = moment(item.due_date, 'yyyy-MM-DD')
                        if (due.isSameOrBefore(today)) {
                            due_date.color = 'red'
                        }
                        const rowData = {
                            bill: {
                                text: getBillId(item.bill_id),
                            },
                        }
                        if (this.props.location.pathname == configRoute(route.admin.accounting.expense.bill.view)) {
                            rowData.users = {
                                text: item.vender_id == item.vender.id && item.vender.name,
                            }
                        }
                        rowData.category = {
                            text: item.category_id == item.category.id && item.category.name,
                        }
                        rowData.bill_date = {
                            text: item.bill_date,
                        }
                        ;(rowData.due_date = due_date),
                            (rowData.status = {
                                text: text.text,
                                badge: text.badge,
                            })

                        rowData.action = {
                            text: item.status == 0 ? 'Edit' : 'Bill',
                            translate: item.status == 0 ? 'common:EditBtn' : 'expense:bill',
                            route:
                                item.status == 0
                                    ? route.admin.accounting.expense.bill.edit + '/' + item.id
                                    : this.props.location.pathname ==
                                      configRoute(route.admin.accounting.expense.bill.view)
                                    ? route.admin.accounting.expense.bill.details + '/' + item.id
                                    : route.teacher.salary.detail + '/' + item.id,
                        }

                        tableData.push(item)
                        tableStructure.push(rowData)
                    })
                this.setState(
                    {
                        tableData,
                        tableStructure,
                        filterStatus,
                        userBalance: null,
                    },
                    () => {
                        if (vendor != null) {
                            this.loadCustomerBalance(vendor)
                        }
                    }
                )
            }
        })
    }

    loadCustomerBalance(id) {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)

        getVendorBalance(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    userBalance: response.data.balance,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        userSchool: state.school,
        token: state.school.accounting_token,
        schoolToken: state.token,
    }
}
