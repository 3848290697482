import React from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Header} from '~/components/header'
import {Loading} from '~/components/loader'
import {Sidebar} from '~/components/sidebar'
import {Table} from '~/components/table'
import * as route from '~/helper/constant/route'
import {AbstractReportCardResultComponent, mapStateToProps} from './abstract'

class reportCardResultComponent extends AbstractReportCardResultComponent{
    constructor(props){
        super(props)
        this.state = {
            ...this.state,
        }
    }
    render(){
        const {t} = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <div className='main-content'>
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.result.view}>{t('exam:result.categoryTitle')} &gt; </a>
                            </li>
                            <li><a href={route.examination.result.reportcardmenu + '/' + this.state.exam_id}>{t('exam:result.filter')} &gt;</a></li>
                            <li>{t('reportcard:reportcard')}</li>

                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{this.state.post.title}</h5>
                            </div>
                            {this.state.downloadLink != "#" && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <a href={this.state.downloadLink}
                                        download ={this.state.fileName}
                                        className="main-button">Download PDF</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Table data={this.state.tableData} />
                </div>
            </div>
        )
        
    }
}

const reportCardResult = withTranslation()(connect(mapStateToProps)(reportCardResultComponent))
export {reportCardResult}