import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractNotificationEditComponent, mapStateToProps } from './abstract'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import ReactSummernote from 'react-summernote'
import * as Notification from '~/helper/constant/notification'

class NotificationEditComponent extends AbstractNotificationEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.setting.notification.view}>{t('setting:notification')} > </a>
                            </li>
                            <li>{t('common:EditBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">
                                    {this.state.notification != null ? t(this.state.notification.translate) : t('setting:notification')} {t('setting:setting')}:
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-md-7">
                                    <div className="row clearfix">
                                        <div className="col-sm-10 float-left form-group">
                                            <div className="alert alert-primary" role="alert">
                                                {t('setting:systemGeneratedEmail')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-10 float-left form-group">
                                            <label className="form-title">{t('setting:messageSubject')}*</label>
                                            <Input name="title" onChange={this._handleChange} placeholder={t('setting:messageSubject')} type="text" value={this.state.title} />
                                            {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                        </div>
                                    </div>
                                    {this.props.match.params.id != Notification.JOB_VACANCY_APPLY && (
                                        <div className="row clearfix">
                                            <div className="col-sm-10 float-left form-group">
                                                <Checkbox onChange={this._handleChange} className="checkbox-form" name="isDefaultSms" value={this.state.isDefaultSms} />
                                                <label className="form-title small-checkbox mt-0 ml-3">{t('setting:defaultSMSPushNotification')}</label>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.match.params.id != Notification.JOB_VACANCY_APPLY && this.state.isDefaultSms == false && (
                                        <div className="row clearfix">
                                            <div className="col-sm-10 float-left form-group">
                                                <label className="form-title">{t('setting:SMSPushMessage')}*</label>
                                                <TextArea name="sms" onChange={this._handleChange} placeholder={t('setting:SMSPushMessage')} type="text" value={this.state.sms} />
                                                {this.state.errors.sms && <label className="error text-danger">{this.state.errors.sms}</label>}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row clearfix">
                                        <div className="col-sm-10 float-left form-group">
                                            <Checkbox onChange={this._handleChange} className="checkbox-form" name="isDefaultEmail" value={this.state.isDefaultEmail} />
                                            <label className="form-title small-checkbox mt-0 ml-3">{t('setting:defaultEmailNotification')}</label>
                                        </div>
                                    </div>
                                    {this.state.isDefaultEmail == false && (
                                        <div className="row clearfix">
                                            <div className="col-sm-10 float-left form-group">
                                                <label className="form-title">{t('setting:emailMessage')}*</label>
                                                <ReactSummernote
                                                    value={this.state.email}
                                                    onInit={() => {
                                                        const editArea = document.querySelector('.note-editable')
                                                        editArea.innerHTML = this.state.email
                                                    }}
                                                    options={{
                                                        height: 350,
                                                        dialogsInBody: true,
                                                        toolbar: [
                                                            ['style', ['style']],
                                                            ['font', ['bold', 'underline', 'clear']],
                                                            ['fontname', ['fontname']],
                                                            ['para', ['ul', 'ol', 'paragraph']],
                                                            ['table', ['table']],
                                                            ['insert', ['link']],
                                                            ['view', ['codeview']],
                                                        ],
                                                    }}
                                                    onChange={(contents, $editable) => this._handleChange('email', contents)}
                                                />
                                                {this.state.errors.email && <label className="error text-danger">{this.state.errors.email}</label>}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row clearfix">
                                        <div className="col-sm-3 float-left form-group">
                                            <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row clearfix">
                                        <div className="col-md-12 float-left access-status">
                                            <div className="security-list">
                                                <label className="form-title">{t('setting:emailStatus')}</label>
                                                <div className="tog-button">
                                                    <div className="button-check r" id="button-2">
                                                        <Checkbox onChange={this._handleChange} name="isEmail" className="checkbox-form checkbox" value={this.state.isEmail} />
                                                        <div className="knobs"></div>
                                                        <div className="layer"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.match.params.id != Notification.JOB_VACANCY_APPLY && (
                                        <div className="row clearfix">
                                            <div className="col-md-12 float-left access-status">
                                                <div className="security-list">
                                                    <label className="form-title">{t('setting:sMSStatus')}</label>
                                                    <div className="tog-button">
                                                        <div className="button-check r" id="button-2">
                                                            <Checkbox onChange={this._handleChange} name="isSms" className="checkbox-form checkbox" value={this.state.isSms} />
                                                            <div className="knobs"></div>
                                                            <div className="layer"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.match.params.id != Notification.JOB_VACANCY_APPLY && (
                                        <div className="row clearfix">
                                            <div className="col-md-12 float-left  access-status">
                                                <div className="security-list">
                                                    <label className="form-title">{t('setting:pushNotificationStatus')}</label>
                                                    <div className="tog-button">
                                                        <div className="button-check r" id="button-2">
                                                            <Checkbox onChange={this._handleChange} name="isWeb" className="checkbox-form checkbox" value={this.state.isWeb} />
                                                            <div className="knobs"></div>
                                                            <div className="layer"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.notification != null && this.state.notification.sms_note.length > 0 && (
                                        <div className="row clearfix">
                                            <div className="col-md-12 float-left">
                                                <div className="todo-box">
                                                    <h5>{t('setting:sMSPushNotificationIndex')} :</h5>
                                                    <div className="box-content-list form-group">
                                                        {this.state.notification.sms_note.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <small>{t('notification:index.' + item)}</small>
                                                                    <hr />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.notification != null && this.state.notification.note.length > 0 && (
                                        <div className="row clearfix">
                                            <div className="col-md-12 float-left">
                                                <div className="todo-box">
                                                    <h5>{t('setting:index')} :</h5>
                                                    <div className="box-content-list form-group">
                                                        {this.state.notification.note.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <small>{t('notification:index.' + item)}</small>
                                                                    <hr />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const NotificationEdit = withTranslation()(connect(mapStateToProps)(NotificationEditComponent))

export { NotificationEdit }
