import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractRestrictComponent, mapStateToProps } from './abstract'
import { Header } from '~/components/header'
import * as UserType from '~/helper/constant/user_type'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'

class RestrictComponent extends AbstractRestrictComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <div className="main-content setup-main">
                    <Header className="setup-nav" hideToggle={true} />
                    <div className="row clearfix">
                        <div className="col-md-6 offset-md-3">
                            <a className="blocked-logo-link">
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.profile != null && (
                                    <Fragment>
                                        <img className="logo" src={this.props.school.school.profile} alt="logo" />
                                    </Fragment>
                                )}
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.profile == null && (
                                    <Fragment>
                                        <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                                    </Fragment>
                                )}
                                {this.props.school != null && this.props.school.school == null && (
                                    <Fragment>
                                        <img className="logo" src="/assets/images/mobile-logo.png" alt="logo" />
                                    </Fragment>
                                )}
                            </a>
                            <div className="setup-container">
                                <Fragment>
                                    <h5 className="error">Access Denied !!!</h5>
                                    <hr />
                                    <br />
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <p>You only have access to the system through mobile app. Please download the mobile app.</p>
                                            </div>
                                            <div className="download-group">
                                                <a href="https://play.google.com/store/apps/details?id=com.collabarate" target="_blank">
                                                    <img src="/assets/images/get_android.png" />
                                                </a>
                                                <a href="https://apps.apple.com/us/app/collabarate/id6444786273" target="_blank">
                                                    <img className="d-ios" src="/assets/images/get_ios.png" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Restrict = connect(mapStateToProps)(RestrictComponent)

export { Restrict }
