import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { editSchool, getSchool, activePackage } from '~/services/api'
import { PackageDTO, SchoolDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as modules from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
// import { Toast } from 'react-toastify/dist/components';
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import { language } from '~/helper/constant/language'
import { studentRestriction } from '../../../../helper/constant/restriction'
import { country } from '../../../../helper/constant/country'
import { dateFormat } from '../../../../helper/constant/date_format'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    short_code: String,
    sub_domain: String,
    package_id: String,
    email: String,
    dob: string,
    packages: Object,
    defaultPackage: Object,
    errors: Object,
    defaultLocale: String,
    locale: String,
    loading: Boolean,
    loading_text: String,
}

export class AbstractSchoolEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            packages: [],
            name: '',
            short_code: '',
            sub_domain: '',
            email: '',
            dob: '',
            defaultLocale: '',
            defaultAccess: '',
            locale: 'en',
            package_id: '',
            isOnlyApp: '',
            defaultCountry: '',
            defaultDateFormat: '',
            country: '',
            date_format: '',
            errors: {
                name: null,
                short_code: null,
                sub_domain: null,
                package_id: null,
                locale: null,
                isOnlyApp: null,
                country: null,
                date_format: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadPackages()
    }

    componentDidMount() {
        this.getData()
    }

    loadPackages() {
        const packages = []
        activePackage(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                response.data.package.map((item, index) => {
                    const result: PackageDTO = item
                    const data = {
                        label: item.name + ' - ' + item.code,
                        value: item.id,
                    }
                    packages.push(data)
                })
                this.setState({ packages })
            }
        })
    }

    getData() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getSchool(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const school: SchoolDTO = response.data.school

                this.setState({
                    id: school.id,
                    name: school.name,
                    short_code: school.short_code,
                    sub_domain: school.sub_domain,
                    package_id: school.package_id,
                    locale: school.locale,
                    isOnlyApp: school.isOnlyApp,
                    country: school.country,
                    date_format: school.date_format,
                })
                const defaultPackage = {
                    label: response.data.school.package.name + ' - ' + response.data.school.package.code,
                    value: response.data.school.package.id,
                }
                const defaultAccess = studentRestriction.find((item) => item.value == school.isOnlyApp)
                const defaultLocale = language.find((item) => item.value == school.locale)
                const defaultCountry = country.find((item) => item.value == school.country)
                const defaultDateFormat = dateFormat.find((item) => item.value == school.date_format)
                this.setState({ defaultPackage, defaultLocale, defaultAccess, defaultDateFormat, defaultCountry })
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                short_code: this.state.short_code,
                sub_domain: this.state.sub_domain,
                package_id: this.state.package_id,
                locale: this.state.locale,
                isOnlyApp: this.state.isOnlyApp,
                country: this.state.country,
                date_format: this.state.date_format,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editSchool(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.superadmin.school.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
