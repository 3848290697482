import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import {API_SUCCESS} from '~/helper/constant/api_status'
import { toastMessage, loader } from '~/helper/function/util'
import {viewStudent} from '~/services/api'
import * as STATUS from '~/helper/constant/status'
import { Link } from 'react-router-dom';

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading:Boolean,
    student:Array,

}

export class AbstractReportCardComponent extends Component<Props, State> {
    constructor(props: Props){
        super(props)
        const {t} =this.props
        this.state ={
            loading:false,
            loading_text:"Loading",
            exam_id: this.props.match.params.examId,
            students:[],
            section_id: this.props.match.params.sectionId,
            tableData:[
                {
                    title:{
                        field:'text',
                        route: false,
                        sortable:true,
                        translate:"common:name",
                    },
                    action:{
                        field:'button',
                        route:true,
                        sortable:false,
                        translate:'activity:action'
                    }

                }
            ]

        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount(){
        this.loadStudent()
    }
    _handleChange(key, data){
        this.setState({[key]:data})
    }
    loadStudent() {
        const {t} = this.props
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Loading')
        viewStudent(this.state.section_id, this.props.token).then((response) => {

            this._handleChange('loading', false)
            let students = []
            let tableData = []
            tableData.push(this.state.tableData[0])
            if (response.code == API_SUCCESS) {
                
                const activeStudent = response.data.student.filter((el)=>el.status == STATUS.ACTIVE)
                activeStudent.map((item, index)=>{
                    let text= null
                    if(item.status == STATUS.ACTIVE){
                        text={
                        text: 'Active',
                        translate: 'common:statusActive',
                        badge: 'table-green',
                        modal: 'modal_class_' + index,
                        }
                    } else{
                        text = {
                        text: 'Inactive',
                        translate: 'common:statusInactive',
                        badge: 'table-red',
                        modal: 'modal_class_' + index,
                        }
                    }
                    const tableDatas = {
                        title:{
                            text:item.user.name
                        }
                    }
                    tableDatas['action'] ={
                        text:'View',
                        translate:'viewBtn',
                        route:route.examination.result.reportcardresult + '/' + this.state.exam_id + '/' + item.id,
                    }
                    students.push(item.user)
                    tableData.push(tableDatas)
                })
                
            } else {
                toastMessage('error', response.message)
            }
            this.setState({
                students,
                tableData,
                loaded: true,
            })
        })
    }

}

const AbstractReportCard = withTranslation()(AbstractReportCardComponent)

export {AbstractReportCard}

export function mapStateToProps(state: Object){
    return{
        token:state.token,
    }
}
