export const index = '/#/'
export const block = '/#/block'
export const forgot_password = '/#/forgot-password'
export const reset = '/#/reset-password'
export const profile = '/#/profile'
export const user_profile = '/#/user/profile'
export const account = '/#/account'
export const password = '/#/password'
export const activity = '/#/activity'
export const calendar = '/#/calendar'
export const my_school = '/#/my-school'
export const online = '/#/online-exam'
export const chat = '/#/chat'
export const children = '/#/children'
export const gallery = '/#/gallery'
export const page_not_found = '/#/404'
export const bbb = '/#/live'
export const notification = '/#/notification'
export const register = '/#/register'

export const superadmin = {
    dashboard: '/#/superadmin/dashboard',
    package: {
        view: '/#/superadmin/packages',
        add: '/#/superadmin/packages/add',
        edit: '/#/superadmin/packages/edit',
    },
    sms_package: {
        view: '/#/superadmin/sms-packages',
        add: '/#/superadmin/sms-packages/add',
        edit: '/#/superadmin/sms-packages/edit',
    },
    school: {
        view: '/#/superadmin/schools',
        add: '/#/superadmin/schools/add',
        edit: '/#/superadmin/schools/edit',
    },
    coupon: {
        view: '/#/superadmin/coupons/view',
        add: '/#/superadmin/coupons/add',
        edit: '/#/superadmin/coupons/edit',
    },
    notice: {
        view: '/#/superadmin/notices/view',
        add: '/#/superadmin/notices/add',
        edit: '/#/superadmin/notices/edit',
    },
    enquiries: {
        view: '/#/superadmin/enquiries/view',
        add: '/#/superadmin/enquiries/add',
        edit: '/#/superadmin/enquiries/edit',
    },
    reset: '/#/superadmin/user',
}
export const admin = {
    accounting: {
        dashboard: '/#/admin/accounting',
        asset: {
            view: '/#/admin/accounting/asset/view',
            add: '/#/admin/accounting/asset/add',
            edit: '/#/admin/accounting/asset/edit',
        },
        banking: {
            menu: '/#/admin/accounting/banking',
            account: {
                view: '/#/admin/accounting/banking/account/view',
                add: '/#/admin/accounting/banking/account/add',
                edit: '/#/admin/accounting/banking/account/edit',
            },
            transfer: {
                view: '/#/admin/accounting/banking/transfer/view',
                add: '/#/admin/accounting/banking/transfer/add',
                edit: '/#/admin/accounting/banking/transfer/edit',
            },
        },
        productservice: {
            view: '/#/admin/accounting/particular/view',
            add: '/#/admin/accounting/particular/add',
            edit: '/#/admin/accounting/particular/edit',
        },
        productstock: {
            menu: '/#/admin/accounting/productstock',
        },
        doubleentry: {
            menu: '/#/admin/accounting/double-entry',
            trialbalance: '/#/admin/accounting/double-entry/trial-balance',
            ledgersummary: '/#/admin/accounting/double-entry/ledger-summary',
            balancesheet: '/#/admin/accounting/double-entry/balance-sheet',
            journalaccount: {
                view: '/#/admin/accounting/double-entry/journal-account/view',
                add: '/#/admin/accounting/double-entry/journal-account/add',
                invoice: '/#/admin/accounting/double-entry/journal-account/invoice',
            },
            chartofaccount: {
                view: '/#/admin/accounting/double-entry/chart-of-account/view',
                add: '/#/admin/accounting/double-entry/chart-of-account/add',
                edit: '/#/admin/accounting/double-entry/chart-of-account/edit',
            },
        },
        income: {
            menu: '/#/admin/accounting/income',
            invoicesummary: '/#/admin/accounting/income/invoice-summary',
            invoice: {
                view: '/#/admin/accounting/income/invoice/view',
                edit: '/#/admin/accounting/income/invoice/edit',
                add: '/#/admin/accounting/income/invoice/add',
                details: '/#/admin/accounting/income/invoice/details',
            },
            template: {
                view: '/#/admin/accounting/income/template/view',
                edit: '/#/admin/accounting/income/template/edit',
                add: '/#/admin/accounting/income/template/add',
            },
        },
        expense: {
            menu: '/#/admin/accounting/expense',
            billsummary: '/#/admin/accounting/expense/bill-summary',
            bill: {
                view: '/#/admin/accounting/expense/bill/view',
                edit: '/#/admin/accounting/expense/bill/edit',
                add: '/#/admin/accounting/expense/bill/add',
                details: '/#/admin/accounting/expense/bill/details',
            },
            template: {
                view: '/#/admin/accounting/expense/template/view',
                edit: '/#/admin/accounting/expense/template/edit',
                add: '/#/admin/accounting/expense/template/add',
            },
        },
        constant: {
            menu: '/#/admin/accounting/constant',
            category: {
                view: '/#/admin/accounting/constant/category',
                add: '/#/admin/accounting/constant/category/add',
                edit: '/#/admin/accounting/constant/category/edit',
            },
            unit: {
                view: '/#/admin/accounting/constant/unit',
                add: '/#/admin/accounting/constant/unit/add',
                edit: '/#/admin/accounting/constant/unit/edit',
            },
            contracttype: {
                view: '/#/admin/accounting/constant/contracttype',
                add: '/#/admin/accounting/constant/contracttype/add',
                edit: '/#/admin/accounting/constant/contracttype/edit',
            },
            customfield: {
                view: '/#/admin/accounting/constant/customfield',
                add: '/#/admin/accounting/constant/customfield/add',
                edit: '/#/admin/accounting/constant/customfield/edit',
            },

            tax: {
                view: '/#/admin/accounting/constant/tax',
                edit: '/#/admin/accounting/fee/constant/edit',
                add: '/#/admin/accounting/fee/constant/add',
            },
        },
        fee: {
            menu: '/#/admin/accounting/fee',
            particulars: {
                view: '/#/admin/accounting/fee/particulars',
                edit: '/#/admin/accounting/fee/particulars/edit',
                child: {
                    view: '/#/admin/accounting/fee/particulars/child',
                    edit: '/#/admin/accounting/fee/particulars/child/edit',
                },
            },
            discount: {
                view: '/#/admin/accounting/fee/discount',
                edit: '/#/admin/accounting/fee/discount/edit',
                add: '/#/admin/accounting/fee/discount/add',
                student: '/#/admin/accounting/fee/discount/student',
            },
            tax: {
                view: '/#/admin/accounting/fee/tax',
                edit: '/#/admin/accounting/fee/tax/edit',
                add: '/#/admin/accounting/fee/tax/add',
            },

            fine: {
                view: '/#/admin/accounting/fee/fine',
                edit: '/#/admin/accounting/fee/fine/edit',
                add: '/#/admin/accounting/fee/fine/add',
            },
            schedule: {
                view: '/#/admin/accounting/fee/schedule',
                edit: '/#/admin/accounting/fee/schedule/view',
                add: '/#/admin/accounting/fee/schedule/add',
            },
            statement: {
                search: '/#/admin/accounting/fee/statement',
                view: '/#/admin/accounting/fee/statement/view',
                detail: '/#/admin/accounting/fee/statement/detail',
            },
            feecollect: {
                view: '/#/admin/accounting/fee/collect',
                search: '/#/admin/accounting/fee/collect/search',
                detail: '/#/admin/accounting/fee/collect/detail',
            },
            cheque: {
                view: '/#/admin/accounting/fee/cheque',
                search: '/#/admin/accounting/fee/cheque/search',
            },
        },
        reports: {
            menu: '/#/admin/accounting/reports',
            transaction: '/#/admin/accounting/reports/transaction',
            incomesummary: '/#/admin/accounting/reports/income-summary',
            expensesummary: '/#/admin/accounting/reports/expense-summary',
            incomeexpense: '/#/admin/accounting/reports/income-expense',
            taxsummary: '/#/admin/accounting/reports/tax-summary',
            profitloss: '/#/admin/accounting/reports/profit-loss',
        },
    },
    holiday: {
        view: '/#/admin/calendar/holiday',
    },
    library:{
        menu: '/#/admin/library/menu',
        bookcategory:{
            view: '/#/admin/library/bookcategory/view',
            add: '/#/admin/library/bookcategory/add',
            edit: '/#/admin/library/bookcategory/edit',  
            detail: '/#/admin/library/bookcategory/detail', 
        },
        shelfmanagement:{
            view: '/#/admin/library/shelfmanagement/view',
            add: '/#/admin/library/shelfmanagement/add',
            edit: '/#/admin/library/shelfmanagement/edit',  
            detail: '/#/admin/library/shelfmanagement/detail', 
        },
        books:{
            view: '/#/admin/library/books/view',
            add: '/#/admin/library/books/add',
            edit: '/#/admin/library/books/edit',
            detail: '/#/admin/library/books/detail', 
        },
        bookissue:{
            view: '/#/admin/library/bookissue/view',
            add: '/#/admin/library/bookissue/add',
            edit: '/#/admin/library/bookissue/edit',  
            detail: '/#/admin/library/bookissue/detail', 
        }
    },
    event: {
        view: '/#/admin/calendar/event',
        schedule: '/#/admin/calendar/event/schedule',
    },
    setup: '/#/admin/setup',
    academic: {
        promote: '/#/admin/promote',
        view: '/#/admin/academic',
        add: '/#/admin/academic/add',
        edit: '/#/admin/academic/edit',
    },
    school: {
        profile: '/#/admin/school/profile',
        menu: '/#/admin/school',
        classes: {
            view: '/#/admin/school/classes',
            add: '/#/admin/school/classes/add',
            edit: '/#/admin/school/classes/edit',
        },
        section: {
            view: '/#/admin/school/section',
        },
        subject: {
            menu: '/#/admin/school/subject',
            view: '/#/admin/school/subject/view',
            add: '/#/admin/school/subject/add',
            edit: '/#/admin/school/subject/edit',
        },
        subject_type: {
            view: '/#/admin/school/subject_type',
            add: '/#/admin/school/subject_type/add',
            edit: '/#/admin/school/subject_type/edit',
        },
    },
    user: {
        menu: '/#/admin/users',
        student: {
            category: {
                view: '/#/admin/student/category',
                add: '/#/admin/student/category/add',
                edit: '/#/admin/student/category/edit',
            },
            menu: '/#/admin/student',
            view: '/#/admin/student/view',
            add: '/#/admin/student/add',
            edit: '/#/admin/student/edit',
        },
        parent: {
            view: '/#/admin/student/parent',
        },
        employee: {
            category: {
                view: '/#/admin/employee/category',
                add: '/#/admin/employee/category/add',
                edit: '/#/admin/employee/category/edit',
            },
            menu: '/#/admin/employee',
            view: '/#/admin/employee/view',
            add: '/#/admin/employee/add',
            edit: '/#/admin/employee/edit',
        },
        role: {
            view: '/#/admin/role-management',
            add: '/#/admin/role-management/add',
            edit: '/#/admin/role-management/edit',
        },
        transfer: '/#/admin/section-transfer',
    },
    attendance: {
        menu: '/#/admin/attendance',
        student: {
            menu: '/#/admin/attendance/student/menu',
            view: '/#/admin/attendance/student/view',
        },
        employee: {
            menu: '/#/admin/attendance/employee/menu',
            view: '/#/admin/attendance/employee/view',
        },
    },
    timetable: {
        menu: '/#/admin/timetable',
        view: '/#/admin/timetable/section',
        add: '/#/admin/timetable/add/section',
    },
    setting: {
        menu: '/#/admin/setting',
        gamification: {
            view: '/#/admin/setting/gamification',
            add: '/#/admin/setting/gamification/add',
            edit: '/#/admin/setting/gamification/edit',
        },
        notification: {
            view: '/#/admin/setting/notification',
            edit: '/#/admin/setting/notification/edit',
        },
        subscription: {
            view: '/#/admin/setting/subscription',
        },
        invite: {
            view: '/#/admin/setting/invite',
            approval: {
                view: '/#/admin/setting/invite/approval',
            },
        },
        grading: {
            view: '/#/admin/setting/grading',
            add: '/#/admin/setting/grading/add',
            edit: '/#/admin/setting/grading/edit',
        },
        reportcard: {
            view: '/#/admin/setting/reportcard',
            add: '/#/admin/setting/reportcard/add',
            edit: '/#/admin/setting/reportcard/edit',
        },
    },
    alumni: {
        category: {
            menu: '/#/admin/alumni/category',
            view: '/#/admin/alumni/category/view',
            add: '/#/admin/alumni/category/add',
            edit: '/#/admin/alumni/category/edit',
        },
    },
    report: {
        view: '/#/admin/moderate-post',
    },
}

export const student = {
    timetable: '/#/student/timetable/',
    my_circle: '/#/student/my-circle',
    fee: {
        view: '/#/student/fee/view',
        detail: '/#/student/fee/detail',
    },
}

export const teacher = {
    timetable: '/#/teacher/timetable/',
    my_circle: '/#/teacher/my-circle',
    attendance: {
        menu: '/#/teacher/attendance/menu',
        filter: '/#/teacher/attendance/filter',
        view: '/#/teacher/attendance/view',
    },
    salary: {
        view: '/#/teacher/salary/view',
        detail: '/#/teacher/salary/detail',
    },
}

export const drive = {
    view: '/#/drive',
    folder: '/#/drive/folder',
}

export const course = {
    menu: '/#/course',
    view: '/#/course/view',
    edit: '/#/course/edit',
    module: '/#/course/module',
    manage: '/#/course/manage',
    insights: '/#/course/insights',
    insights_details: '/#/course/insights-details',
    insights_user: '/#/course/insights-user',
    insights_quiz: '/#/course/insights-quiz',
    student: {
        view: '/#/course/student/view',
        module: '/#/course/student/module',
        content: '/#/course/student/content',
        details: '/#/course/student/details',
    },
}
export const todo = {
    view: '/#/todo',
    detail: '/#/todo/detail',
}

export const examination = {
    menu: '/#/examination',
    exam: {
        view: '/#/examination/view',
        routine: {
            menu: '/#/examination/routine',
            schedule: '/#/examination/routine/schedule',
        },
    },
    question_bank: {
        menu: '/#/question-bank',
        view: '/#/question-bank/view',
        question: {
            view: '/#/question-bank/question',
        },
    },
    result: {
        view: '/#/examination/result',
        menu: '/#/examination/result/filter',
        assign: '/#/examination/result/assign',
        result: '/#/examination/result/view',
        reportcardmenu: '/#/examination/result/report-card/menu',
        reportcard: '/#/examination/result/report-card/view',
        reportcardresult: '/#/examination/result/report-card/download',
    },
}

export const student_fee = {
    menu: '/#/accounting/fee',
    upcoming: '/#/accounting/fee/upcoming',
    statement: {
        search: '/#/accounting/fee/statement/search',
        view: '/#/accounting/fee/statement/view',
        detail: '/#/accounting/fee/statement/detail',
    },
}

export const report = {
    attendance: '/#/report/attendance',
}

export const alumni = {
    dashboard: '/#/alumni/alumni-dashboard',
    yearbook: {
        menu: '/#/alumni/alumni-yearbook',
        view: '/#/alumni/alumni-yearbook/view',
    },

    job: {
        menu: '/#/alumni/job-vacancy/menu',
        view: '/#/alumni/job-vacancy/view',
        detail: '/#/alumni/job-vacancy/detail',
        applicant: '/#/alumni/job-vacancy/applicant',
    },
}

export const leaderboard = {
    view: '/#/leaderboard',
    add: '/#/leaderboard/add',
}
