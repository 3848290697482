import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractHeaderSettingComponent, mapStateToProps } from './abstract'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import * as SVG from '~/assets/svg'
import { School } from '../../../views/admin/school/school'
import { withTranslation } from 'react-i18next'
class HeaderSettingComponent extends AbstractHeaderSettingComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getProfile = this.getProfile.bind(this)
    }

    getProfile() {
        let image = false
        if (this.props.user.user_detail != null) {
            if (this.props.user.user_detail.profile != null) {
                image = true
            }
        }

        if (image) {
            return <img src={this.props.user.user_detail.profile} alt="user-img" />
        } else {
            if (this.props.user.name != null) {
                return <span>{this.props.user.name.charAt(0)}</span>
            } else {
                return <span>C</span>
            }
        }
    }
    render() {
        const { t } = this.props
        return (
            <li>
                <Loading status={this.state.loading} text="Signing out" />
                <div className="dropdown">
                    <div className="user-img dropdown-toggle" id="setting" data-toggle="collapse" data-target="#setting_dropdown">
                        {this.getProfile()}
                    </div>
                    <div id="setting_dropdown" className="dropdown-menu notif-pop main main-border-radius main-background">
                        <button type="button" className="close" data-toggle="collapse" data-target="#setting_dropdown">
                            &times;
                        </button>
                        <span>{t('header:myaccount')}</span>
                        {this.props.school && this.props.school.user_type != UserType.SuperAdmin && (
                            <div className="notif-list">
                                <small>{t('header:menu')}</small>
                                <a href={route.my_school} className="list-noti">
                                    {SVG.myschool()}
                                    <p>{t('header:myschool')}</p>
                                </a>
                                {/* <div className="list-noti">
                                    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="14" fill="url(#paint0_linear)"/>
                                        <path d="M11.8064 17.3335H21.9464V16.0002H11.8064L14.2798 13.5135C14.3415 13.4513 14.3904 13.3776 14.4236 13.2966C14.4568 13.2155 14.4738 13.1287 14.4735 13.0411C14.4732 12.9536 14.4556 12.8669 14.4218 12.7861C14.388 12.7053 14.3386 12.6319 14.2764 12.5702C14.2143 12.5085 14.1406 12.4596 14.0595 12.4263C13.9785 12.3931 13.8917 12.3762 13.8041 12.3765C13.7165 12.3768 13.6298 12.3943 13.549 12.4281C13.4682 12.462 13.3948 12.5113 13.3331 12.5735L9.25977 16.6668L13.3331 20.7602C13.3924 20.8303 13.4655 20.8875 13.5479 20.9281C13.6304 20.9687 13.7203 20.9918 13.812 20.9959C13.9038 21.0001 13.9954 20.9853 14.0812 20.9524C14.167 20.9195 14.245 20.8692 14.3104 20.8047C14.3758 20.7402 14.4272 20.6629 14.4614 20.5776C14.4955 20.4923 14.5116 20.4009 14.5087 20.3091C14.5058 20.2173 14.484 20.127 14.4446 20.044C14.4052 19.9611 14.3491 19.8871 14.2798 19.8268L11.8064 17.3335Z" fill="#425BE5"/>
                                        <path d="M23.333 16H25.333V17.3333H23.333V16Z" fill="#425BE5"/>
                                        <path d="M26.667 16H28.0003V17.3333H26.667V16Z" fill="#425BE5"/>
                                        <path d="M26.6133 19.9065C26.5516 19.8444 26.4783 19.795 26.3974 19.7612C26.3166 19.7273 26.23 19.7098 26.1424 19.7095C26.0548 19.7092 25.968 19.7261 25.8869 19.7594C25.8059 19.7926 25.7322 19.8415 25.67 19.9032C25.6078 19.9649 25.5584 20.0383 25.5246 20.1191C25.4908 20.1999 25.4733 20.2866 25.473 20.3742C25.4727 20.4618 25.4896 20.5486 25.5228 20.6296C25.5561 20.7106 25.6049 20.7844 25.6667 20.8465L28.14 23.3332H18V24.6665H28.14L25.6667 27.1598C25.5973 27.2201 25.5412 27.2941 25.5018 27.377C25.4624 27.46 25.4406 27.5503 25.4377 27.6421C25.4348 27.7339 25.451 27.8253 25.4851 27.9106C25.5192 27.9959 25.5706 28.0732 25.636 28.1377C25.7014 28.2022 25.7795 28.2525 25.8652 28.2854C25.951 28.3183 26.0426 28.3331 26.1344 28.329C26.2262 28.3248 26.3161 28.3017 26.3985 28.2611C26.4809 28.2205 26.5541 28.1634 26.6133 28.0932L30.6667 23.9998L26.6133 19.9065Z" fill="#425BE5"/>
                                        <path d="M14.667 23.3335H16.667V24.6668H14.667V23.3335Z" fill="#425BE5"/>
                                        <path d="M12 23.3335H13.3333V24.6668H12V23.3335Z" fill="#425BE5"/>
                                        <defs>
                                            <linearGradient id="paint0_linear" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#DDE2FF"/>
                                                <stop offset="1" stopColor="#EFF1FF"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p>
                                        <a href="javascript:void()">Switch Academic years </a>
                                    </p>
                                </div> */}
                            </div>
                        )}
                        <div className={this.props.school && this.props.school.user_type != UserType.SuperAdmin ? 'notif-list read' : 'notif-list'}>
                            {/* <small>Setting</small> */}
                            <a href={route.profile} className="list-noti">
                                {SVG.myprofile()}
                                <p>{t('header:myprofile')}</p>
                            </a>
                            <a href={route.password} className="list-noti">
                                {SVG.changepassword()}
                                <p>{t('header:changepassword')}</p>
                            </a>
                            <a onClick={this._logout} className="list-noti">
                                {SVG.logout()}
                                <p>{t('header:logout')}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

const HeaderSetting = withTranslation()(connect(mapStateToProps)(HeaderSettingComponent))

export { HeaderSetting }
