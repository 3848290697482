import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

/*Course*/
export const viewCourse = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course/courses'
            if (id != null && id != 0) {
                url = url + '/' + id
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourse = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/courses/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourse = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/courses/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseItems = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course/courses/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
/*Course Module*/
export const viewCourseModule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course/course-module'
            if (id != null) {
                url = url + '/' + id
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const viewAllCourseModule = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course/course-module'
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourseModule = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/course-module/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseModule = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/course-module/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteCourseModule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course/course-module/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseModuleItems = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course/course-module/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewCourseContent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course/course-module'
            if (id != null || id != 0) {
                url = url + '/' + id
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewCourseContentByModule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course-content'
            if (id != null || id != 0) {
                url = url + '/' + id
            }
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
/*Course Content*/
export const addCourseContent = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/course-content/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseContent = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/course-content/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteCourseContent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course/course-content/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseContentItems = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course/course-content/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
/*Course Progress*/
export const viewCourseProgress = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course-progress'
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourseProgress = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-progress/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseProgress = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-progress/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseProgress = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course/course-progress/edit/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
/*Course Meta*/
export const viewCourseMeta = (course_content_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course-content-meta/' + course_content_id
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourseMeta = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-content-meta/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseMeta = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-content-meta/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseMeta = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-content-meta/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteCourseMeta = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course-content-meta/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

/* Course Notification */
export const viewCourseNotification = (course_content_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-notification/' + course_content_id
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourseNotification = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-notification/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseNotification = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-notification/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseNotification = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-notification/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteCourseNotification = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-notification/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

/*Course Exam*/
export const viewCourseExam = (course_content_id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            let url = 'course-management/course-exam/' + course_content_id
            get(url, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addCourseExam = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-exam/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editCourseExam = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-exam/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getCourseExam = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('course-management/course-exam/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteCourseExam = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('course-management/course-exam/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
