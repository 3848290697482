import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    classes: Array,
}

export class AbstractStudentFeeMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classes: [],
        }
        this._handleChange = this._handleChange.bind(this)
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
