import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { Loading } from '~/components/loader'
import * as UserType from '~/helper/constant/user_type'
import { UserFilter } from '~/components/userfilter'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { convertADtoBS } from '../../../../../helper/function'

class CourseViewComponent extends AbstractCourseViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getSubject = this.getSubject.bind(this)
        this.getCourse = this.getCourse.bind(this)
        this.getCourseAdd = this.getCourseAdd.bind(this)
        this.getCourseDetail = this.getCourseDetail.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this.setCourseProgressive = this.setCourseProgressive.bind(this)
        this.setAfterDueDate = this.setAfterDueDate.bind(this)
        this.propertiesModal = this.propertiesModal.bind(this)
        this.setStartDate = this.setStartDate.bind(this)
        this.setDate = this.setDate.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
    }

    _selectEmployee(data) {
        this._handleChange('employee', data)
    }

    _selectStudent(data) {
        this._handleChange('student', data)
    }

    _selectSubject(data) {
        this._handleChange('selected_subject', data)
    }

    setStartDate(data) {
        super._handleChange('start_date', data)
    }

    setDate(data) {
        super._handleChange('due_date', data)
    }

    setAfterDueDate(element) {
        if (element == 'course') {
            const available_after_due = !this.state.available_after_due
            this.setState({ available_after_due })
        } else {
            const quiz_after_due = !this.state.quiz_after_due
            this.setState({ quiz_after_due })
        }
    }

    getDeleteModal() {
        const { t } = this.props
        return (
            <div id={'delete-course'} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('common:warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question"> {t('course:course_1')} ?</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center form-group">
                            <Button type="submit" text={t('common:delete')} main={true} onClick={() => this.deleteCourse()} />
                            <button id={'close_delete_modal'} type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setCourseProgressive() {
        const is_course_progressive = !this.state.is_course_progressive
        this.setState({ is_course_progressive })
    }

    getSubject() {
        const data = this.state.subject.map((item, index) => {
            return (
                <li key={index}>
                    <a onClick={() => this._handleChange('activeTab', index)} className={index == this.state.activeTab ? 'active' : ''} id={'subject-' + index} data-toggle="tab" href={'#subject_' + index} role="tab" aria-controls="subject" aria-selected="false">
                        {item.name}
                    </a>
                </li>
            )
        })
        return data
    }

    propertiesModal() {
        const { t } = this.props
        return (
            <div id="details-course" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('course:course_property')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-4">{t('course:title')}</span>
                                    <ins className="col-8">{this.state.detail.title}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-4">{t('course:description')}</span>
                                    <ins className="col-8">{this.state.detail.description}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-4">{t('course:topic')}</span>
                                    <ins className="col-8">{this.state.detail.topic}</ins>
                                </div>
                                <div className="form-group row clearfix folder-detail">
                                    <span className="col-4">{t('course:created')}</span>
                                    <ins className="col-8">{this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.detail.createdOn != '' ? (convertADtoBS(this.state.detail.createdOn) != null ? convertADtoBS(this.state.detail.createdOn) : 'Invalid Date Range') : this.state.detail.createdOn}</ins>
                                </div>
                                <div className="row clearfix folder-detail">
                                    <span className="col-4">{t('course:privacy')}</span>
                                    <ins className="col-8">{this.state.detail.privacy}</ins>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button type="button" className="main-button" data-dismiss="modal">
                                    {t('common:modal close')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getCourse() {
        const data = this.state.subject.map((item, index) => {
            return (
                <div key={index} className={index == this.state.activeTab ? 'tab-pane fade show active row' : 'tab-pane fade show row'} id={'subject_' + index} role="tabpanel" aria-labelledby={'subject-' + index}>
                    {this.getCourseDetail(item.id, index)}
                </div>
            )
        })
        return data
    }

    getCourseDetail(id, courseindex) {
        const { t } = this.props
        let data = this.state.course
            .filter((item) => item.subject_id == id)
            .map((item, index) => {
                return (
                    <div className="float-left course-your course-view">
                        <div className="folder-list">
                            <div className="clearfix">
                                <a className="folder-img">{SVG.courseObjIcon()}</a>
                                <a className="folder-name course-cursor" data-toggle="collapse" data-target={'#session' + item.id}>
                                    {item.title}
                                    <span>{item.description}</span>
                                    {/* <span>23 Videos : 5 Authors </span> */}
                                </a>
                            </div>
                            <div className="e-d-t">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                        <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                        <button className="dropdown-item" type="button" onClick={() => (window.location = route.course.edit + '/' + this.props.match.params.id + '/' + item.id)}>
                                            {t('common:EditBtn')}
                                        </button>
                                        <button className="dropdown-item" type="button" onClick={() => this.openDetailProperties(item.id)}>
                                            {t('course:property')}
                                        </button>
                                        <button className="dropdown-item" type="button" onClick={() => this.openDeletePopup(item.id)}>
                                            {t('common:delete')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id={'session' + item.id} className="collapse">
                                <hr />
                                <div className="clearfix">
                                    <a href={route.course.insights + '/' + this.props.match.params.id + '/' + item.id} className="second-button">
                                        {t('course:insight')}
                                    </a>
                                    <a href={route.course.module + '/' + this.props.match.params.id + '/' + item.id} className="main-button float-right mt-0">
                                        {t('course:manage')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        if (data == '') {
            return (
                <p className="no-data">
                    {SVG.nodata()}
                    {t('course:course_msg1')}.
                </p>
            )
        }
        return data
    }

    getCourseAdd() {
        const { t } = this.props
        return (
            <div id="modal_add" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button id="close_add" type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('course:add_course')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                {this.state.currentSection == 1 && (
                                    <div className="section-1">
                                        <h6>{t('course:course_description')}:</h6>
                                        <div className="add-option-exam">
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('course:course_title')} *</label>
                                                    <Input name="title" onChange={this._handleChange} placeholder={t('course:course_title')} type="text" value={this.state.title} />
                                                    {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('course:description')} *</label>
                                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('course:description')} type="text" value={this.state.description} />
                                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('course:topic')} *</label>
                                                    <Input name="topic" onChange={this._handleChange} placeholder={t('course:topic')} type="text" value={this.state.topic} />
                                                    {this.state.errors.topic && <label className="error text-danger">{this.state.errors.topic}</label>}
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('course:start_date')} *</label>
                                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.start_date} onChange={this.setStartDate} placeholderText={t('course:start_date')} minDate={moment().toDate()} />
                                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.start_date != '' ? (convertADtoBS(this.state.start_date) != null ? convertADtoBS(this.state.start_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                                    {this.state.errors.start_date && <label className="error text-danger">{this.state.errors.start_date}</label>}
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <label className="form-title">{t('course:due_date')} *</label>
                                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.due_date} onChange={this.setDate} placeholderText={t('course:due_date')} minDate={moment().toDate()} />
                                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.due_date != '' ? (convertADtoBS(this.state.due_date) != null ? convertADtoBS(this.state.due_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                                    {this.state.errors.due_date && <label className="error text-danger">{this.state.errors.due_date}</label>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.currentSection == 2 && (
                                    <div className="section-2">
                                        <h6>{t('course:course_option')}:</h6>
                                        <div className="add-option-exam">
                                            <div className="row clearfix">
                                                <div className="form-group col-sm-12 float-left">
                                                    <div className="form-check ">
                                                        <label className="form-check-label form-title" htmlFor="course">
                                                            {t('course:course_msg2')}
                                                        </label>
                                                        <Checkbox id="course" onChange={this._handleChange} name="available_after_due" value={this.state.available_after_due} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="form-group col-sm-12 float-left">
                                                    <div className="form-check ">
                                                        <label className="form-check-label form-title" htmlFor="quiz">
                                                            {t('course:course_msg3')}
                                                        </label>
                                                        <Checkbox onChange={this._handleChange} name="quiz_after_due" value={this.state.quiz_after_due} id="quiz" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row clearfix">
                                                <div className="form-group col-sm-12 float-left">
                                                    <div className="form-check ">
                                                        <label htmlFor="progressive" className="form-title">
                                                            {t('course:course_msg4')}
                                                        </label>
                                                        <Checkbox onChange={() => this.setCourseProgressive()} name="is_course_progressive" value={this.state.is_course_progressive} id="progressive" />
                                                        {this.state.errors.selectedCourseProgressive && <label className="error text-danger">{this.state.errors.selectedCourseProgressive}</label>}
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                )}
                                {this.state.currentSection == 3 && (
                                    <div className="section-3">
                                        <h6>{t('course:course_privacy')}:</h6>
                                        <div className="add-option-exam">
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group">
                                                    <div className="form-check">
                                                        <label className="form-title" htmlFor="private">
                                                            {t('course:private')}
                                                        </label>
                                                        <Checkbox onChange={this._handleChange} className="" name="isPrivate" value={this.state.isPrivate} id="private" />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.isPrivate == true && <UserFilter selectedClass={this.state.selectedClass} showSubject={true} resetOnChange={false} hideClass={this.state.hideClass} hideSection={this.state.hideSection} showSubject={this.state.showSubject} onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} onSubjectSelect={this._selectSubject} selectedClassName={(this.props.match.params.id != 0 && this.props.match.params.id) || -1} />}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <div className="form-group">
                                    {this.state.currentSection > 1 && (
                                        <div className="actionBtn text-left display-inlineblock pull-left">
                                            <Button onClick={() => this.switchBox('prev')} type="button" text={t('course:previous')} main={true} />
                                        </div>
                                    )}
                                    {this.state.currentSection < 3 && (
                                        <div className="actionBtn text-right display-inlineblock pull-right">
                                            <Button onClick={() => this.switchBox('next')} type="button" text={t('course:next')} main={true} />
                                        </div>
                                    )}
                                    {this.state.currentSection == 3 && (
                                        <div className="actionBtn text-right display-inlineblock pull-right">
                                            <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} disabled={(this.state.currentSection != 3 && true) || false} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} &gt; </a>
                            </li>
                            <li>{this.state.classes != null ? this.state.classes.name : t('course:others')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{this.state.classes != null ? this.state.classes.name : t('course:others')}</span>
                                <h5 className="main-title heading5">{t('course:course')}</h5>
                            </div>
                            {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom || this.props.school.user_type == UserType.Teacher) && (
                                <div className="col-md-7 calendar-module float-left text-right">
                                    <div className="display-inline-block">
                                        <Button onClick={() => this.openAddPopup()} type="button" text={t('common:addBtn')} main={true} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="event-accordion">
                        <div className="role-pop-head">
                            <ul className="nav" id="myTab" role="tablist">
                                {this.state.subject.length > 0 && this.getSubject()}
                                <li>
                                    <a onClick={() => this._handleChange('activeTab', 'other')} className={'other' == this.state.activeTab ? 'active' : ''} id="subject-other" data-toggle="tab" href="#subject_other" role="tab" aria-controls="subject" aria-selected="false">
                                        {t('course:others')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="role-pop-content">
                            <div className="tab-content clearfix" id="myTabContent">
                                {this.state.subject.length > 0 && this.getCourse()}
                                <div className={'other' == this.state.activeTab ? 'tab-pane fade show active' : 'tab-pane fade show'} id="subject_other" role="tabpanel" aria-labelledby="subject-other">
                                    {this.getCourseDetail(null, 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.school.user_type == UserType.Admin || this.props.school.user_type == UserType.Custom || this.props.school.user_type == UserType.Teacher) && this.getCourseAdd()}
                {this.propertiesModal()}
                {this.getDeleteModal()}
            </div>
        )
    }
}

const CourseView = withTranslation()(connect(mapStateToProps)(CourseViewComponent))

export { CourseView }
