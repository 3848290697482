export const currency = [
    { value: 'ARP', label: 'ARP-Argentina Pesos' },
    { value: 'ATS', label: 'ATS-Austria Schillings' },
    { value: 'AUD', label: 'AUD-Australia Dollars' },
    { value: 'BBD', label: 'BBD-Barbados Dollars' },
    { value: 'BEF', label: 'BEF-Belgium Francs' },
    { value: 'BGL', label: 'BGL-Bulgaria Lev' },
    { value: 'BMD', label: 'BMD-Bermuda Dollars' },
    { value: 'BRR', label: 'BRR-Brazil Real' },
    { value: 'BSD', label: 'BSD-Bahamas Dollars' },
    { value: 'CAD', label: 'CAD-Canada Dollars' },
    { value: 'CHF', label: 'CHF-Switzerland Francs' },
    { value: 'CLP', label: 'CLP-Chile Pesos' },
    { value: 'CNY', label: 'CNY-China Yuan Renmimbi' },
    { value: 'CSK', label: 'CSK-Czech Republic Koruna' },
    { value: 'CYP', label: 'CYP-Cyprus Pounds' },
    { value: 'DEM', label: 'DEM-Germany Deutsche Marks' },
    { value: 'DKK', label: 'DKK-Denmark Kroner' },
    { value: 'DZD', label: 'DZD-Algeria Dinars' },
    { value: 'EGP', label: 'EGP-Egypt Pounds' },
    { value: 'ESP', label: 'ESP-Spain Pesetas' },
    { value: 'EUR', label: 'EUR-Euro' },
    { value: 'FIM', label: 'FIM-Finland Markka' },
    { value: 'FJD', label: 'FJD-Fiji Dollars' },
    { value: 'FRF', label: 'FRF-France Francs' },
    { value: 'GBP', label: 'GBP-United Kingdom Pounds' },
    { value: 'GRD', label: 'GRD-Greece Drachmas' },
    { value: 'HKD', label: 'HKD-Hong Kong Dollars' },
    { value: 'HUF', label: 'HUF-Hungary Forint' },
    { value: 'IDR', label: 'IDR-Indonesia Rupiah' },
    { value: 'IEP', label: 'IEP-Ireland Punt' },
    { value: 'ILS', label: 'ILS-Israel New Shekels' },
    { value: 'INR', label: 'INR-India Rupees' },
    { value: 'ISK', label: 'ISK-Iceland Krona' },
    { value: 'ITL', label: 'ITL-Italy Lira' },
    { value: 'JMD', label: 'JMD-Jamaica Dollars' },
    { value: 'JOD', label: 'JOD-Jordan Dinar' },
    { value: 'JPY', label: 'JPY-Japan Yen' },
    { value: 'KRW', label: 'KRW-South Korea Won' },
    { value: 'LBP', label: 'LBP-Lebanon Pounds' },
    { value: 'LUF', label: 'LUF-Luxembourg Francs' },
    { value: 'MXP', label: 'MXP-Mexico Pesos' },
    { value: 'MYR', label: 'MYR-Malaysia Ringgit' },
    { value: 'NLG', label: 'NLG-Dutch Guilders' },
    { value: 'NLG', label: 'NLG-Netherlands Guilders' },
    { value: 'NPR', label: 'NPR-Nepali Rupees' },
    { value: 'NOK', label: 'NOK-Norway Kroner' },
    { value: 'NZD', label: 'NZD-New Zealand Dollars' },
    { value: 'PHP', label: 'PHP-Philippines Pesos' },
    { value: 'PKR', label: 'PKR-Pakistan Rupees' },
    { value: 'PLZ', label: 'PLZ-Poland Zloty' },
    { value: 'PTE', label: 'PTE-Portugal Escudo' },
    { value: 'ROL', label: 'ROL-Romania Leu' },
    { value: 'RUR', label: 'RUR-Russia Rubles' },
    { value: 'SAR', label: 'SAR-Saudi Arabia Riyal' },
    { value: 'SDD', label: 'SDD-Sudan Dinar' },
    { value: 'SEK', label: 'SEK-Sweden Krona' },
    { value: 'SGD', label: 'SGD-Singapore Dollars' },
    { value: 'SKK', label: 'SKK-Slovakia Koruna' },
    { value: 'THB', label: 'THB-Thailand Baht' },
    { value: 'TRL', label: 'TRL-Turkey Lira' },
    { value: 'TTD', label: 'TTD-Trinidad and Tobago Dollars' },
    { value: 'TWD', label: 'TWD-Taiwan Dollars' },
    { value: 'USD', label: 'USD-United States Dollars' },
    { value: 'VEB', label: 'VEB-Venezuela Bolivar' },
    { value: 'XAG', label: 'XAG-Silver Ounces' },
    { value: 'XAU', label: 'XAU-Gold Ounces' },
    { value: 'XCD', label: 'XCD-Eastern Caribbean Dollars' },
    { value: 'XDR', label: 'XDR-Special Drawing Right (IMF)' },
    { value: 'XPD', label: 'XPD-Palladium Ounces' },
    { value: 'XPT', label: 'XPT-Platinum Ounces' },
    { value: 'ZAR', label: 'ZAR-South Africa Rand' },
    { value: 'ZMK', label: 'ZMK-Zambia Kwacha' },
]
