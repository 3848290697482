import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractStudentViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import * as Status from '~/helper/constant/status'
import { UserModel } from '~/components/userModel'
import { role_type } from '~/helper/constant/user_type'
import moment from 'moment'
import $ from 'jquery'
import { withTranslation } from 'react-i18next'

class StudentViewComponent extends AbstractStudentViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getModal = this.getModal.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getInfoModal = this.getInfoModal.bind(this)
    }

    getInfoModal() {
        const modal = this.state.data.map((item, index) => {
            return (
                <div key={index} id={'modal_info_' + index} className="modal fade mychat show" role="dialog">
                    <UserModel userData={item} />
                </div>
            )
        })
        return modal
    }

    getModal() {
        const { t } = this.props
        const modal = this.state.data.map((item, index) => {
            let text = t('student:student.disable')
            if (item.status != Status.ACTIVE) {
                text = t('student:student.enable')
            }
            return (
                <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('student:student.areyousureyouwantto')} {text} {t('student:student.title')}?
                                </p>
                                <p className="delete-file-name">"{item.user.name + ' - ' + item.user.reg_no}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getDeleteModal() {
        const { t } = this.props

        const modal = this.state.data.map((item, index) => {
            return (
                <div key={index} id={'modal_class_delete_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to delete the student?</p>
                                <p className="delete-file-name">"{item.name + ' - ' + item.code}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.delStudent} link={item.id} type="button" text="Confirm" main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.user.student.menu}>{t('student:student.title')} &gt; </a>
                            </li>
                            <li>{t('student:student.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <span className="sub-main-title">{this.state.section && this.state.section.classes.name + ' - ' + this.state.section.name}</span>
                                <h5 className="main-title heading5">{t('student:student.title')}:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.user.student.add + '/' + this.state.section_id} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.student} message="student:studentMsg" />
                </div>
                {this.getModal()}
                {this.getDeleteModal()}
                {this.getInfoModal()}
            </div>
        )
    }
}

const StudentView = withTranslation()(connect(mapStateToProps)(StudentViewComponent))

export { StudentView }
