import {Component} from 'react'
import {Dispatch} from 'react-redux'
import {category_type} from '~/helper/constant/category'


type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantContractTypeEditComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            category_list:category_type,
            color:"#000000"
        }
    }
    compnentDidMount(){}


}

export function mapStateToProps(state:Object){
    return {
        token: state.token,
    }
}