import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSchoolViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import { Button } from '~/components/form'
import { Loading } from '~/components/loader'
import $ from 'jquery'
class SuperAdminSchoolViewComponent extends AbstractSchoolViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getModal = this.getModal.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        super.toggle(link)
    }

    getModal() {
        const modal = this.state.data.map((item, index) => {
            let text = 'disable'
            if (item.status != Status.ACTIVE) {
                text = 'enable'
            }
            return (
                <div key={index} id={'modal_school_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you want to {text} the school?</p>
                                <p className="delete-file-name">"{item.name + ' - ' + item.short_code}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text="Confirm" main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.school.view}>Schools</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Schools:</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.superadmin.school.add} className="main-button">
                                        Add
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.school} />
                </div>
                {this.getModal()}
            </div>
        )
    }
}

const SuperAdminSchoolView = connect(mapStateToProps)(SuperAdminSchoolViewComponent)

export { SuperAdminSchoolView }
