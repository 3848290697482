import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass, activeSection } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as validation from '~/helper/constant/validation'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    classAry: Array,
    data: Array,
    selectedClass: String,
    errors: Object,
}

export class AbstractStudentMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            data: [],
            classAry: [],
            sectionAry: [],
            defaultClass: Object,
            defaultSection: Object,
            selectedClass: null,
            selectedSection: null,
            errors: {
                selectedClass: null,
                selectedSection: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.getURL = this.getURL.bind(this)
    }

    componentDidMount() {
        this.loadClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    getURL() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            redirect(route.admin.user.student.view + '/' + this.state.selectedSection, this.props.history)
        }
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classAry = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classAry.push({
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                    })
                })
                this._handleChange('data', response.data.class)
                this._handleChange('classAry', classAry)
                this._handleChange('defaultClass', classAry[0])
                this._handleChange('selectedClass', classAry[0].value)
                this.loadSection(classAry[0].value)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let sectionAry = []
        classes.active_section.map((item, index) => {
            sectionAry.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        if (sectionAry.length > 0) {
            this._handleChange('sectionAry', sectionAry)
            this._handleChange('defaultSection', sectionAry[0])
            this._handleChange('selectedSection', sectionAry[0].value)
        } else {
            this._handleChange('sectionAry', sectionAry)
            this._handleChange('defaultSection', '')
            this._handleChange('selectedSection', '')
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
