import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as MessageType from '~/helper/constant/message_type'
import { getChatRoomName } from '~/services/api'
import { Connection } from '~/services/socket'
const socket = new Connection()

type Props = {
    dispatch: Dispatch<any>,
    token: String,
    school: Object,
    isLogged: Boolean,
}

type State = {
    chatRoomList: Array,
    response: String,
}

export class AbstractAppComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chatRoomList: [],
            response: '',
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.school == null && this.props.school != null) {
            this.loadChat(this.props.school, null)
        } else if (prevProps.school != null && this.props.school == null) {
            this.loadChat(null, prevProps.school)
        } else if (prevProps.school != null && this.props.school != null) {
            if (prevProps.school.id != this.props.school.id) {
                this.loadChat(this.props.school, prevProps.school)
            }
        }
        const response = this.state.response
        if (prevState.response != response) {
            let chatRoomList = this.state.chatRoomList
            if (response.type == MessageType.CREATE_GROUP_CHAT_ROOM) {
                if (this.props.school != null) {
                    const chatRoom = response.data
                    if (this.props.school.id == chatRoom.author_id || chatRoom.access.includes(this.props.school.id)) {
                        chatRoomList.push(chatRoom.room_name)
                        socket.join(this.props.dispatch, chatRoom.room_name, this.props.school)
                    }
                }
            } else if (response.type == MessageType.DELETE_GROUP_CHAT_ROOM) {
                if (this.props.school != null) {
                    const chatRoom = response.data
                    const check = chatRoomList.find((item) => item != chatRoom.room_name)
                    if (check != null) {
                        socket.leave(chatRoom.room_name, this.props.school)
                    }
                    chatRoomList = chatRoomList.filter((item) => item != chatRoom.room_name)
                }
            } else if (response.type == MessageType.CREATE_CHAT_ROOM) {
                if (this.props.school != null) {
                    const chatRoom = response.data
                    if (this.props.school.id == chatRoom.author_id || chatRoom.access.includes(this.props.school.id)) {
                        chatRoomList.push(chatRoom.room_name)
                        socket.join(this.props.dispatch, chatRoom.room_name, this.props.school)
                    }
                }
            }
            this.setState({
                chatRoomList,
            })
        }
        // if (this.props.school != null) {
        //     if (prevState.message != this.state.message) {
        //         const data = this.state.message
        //         const chatRoomList = this.state.chatRoomList
        //         if (data.type == 'delete_group') {
        //             const check = chatRoomList.find((item) => item != data.room)
        //             if (check != null) {
        //                 socket.leave(data.room, this.props.school)
        //             }
        //             chatRoomList = chatRoomList.filter((item) => item != data.room)
        //         }
        //         if (data.type == 'create_group') {
        //             const chatRoom = data.message
        //             if (this.props.school.id == chatRoom.author_id || chatRoom.access.includes(this.props.school.id)) {
        //                 chatList.push(data.chat_room)
        //                 socket.join(this.props.dispatch, data.chat_room, this.props.school)
        //             }
        //         }
        //         if (data.type == 'create') {
        //             if (this.props.school.id == chatRoom.author_id || chatRoom.access.includes(this.props.school.id)) {
        //                 chatList.push(data.chat_room)
        //                 socket.join(this.props.dispatch, data.chat_room, this.props.school)
        //             }
        //         }
        //         this.setState({
        //             chatRoomList,
        //         })
        //     }
        // }
    }

    componentDidMount() {
        if (this.props.school != null && this.props.school.school != null) {
            this.loadChat(this.props.school, null)
            socket.receive('response', this)
        }
    }

    loadChat(newSchool, prevSchool) {
        if (prevSchool != null && prevSchool.school != null) {
            this.state.chatRoomList.map((item) => {
                socket.leave(item, prevSchool.id)
            })
            socket.logout(prevSchool)
        }
        if (newSchool != null && newSchool.school != null) {
            socket.login(this.props.dispatch, newSchool)
            socket.participants(this.props.dispatch, newSchool)
            socket.userInformation(this.props.dispatch)
            getChatRoomName(this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    const chatRoomList = []
                    response.data.chat.map((item) => {
                        chatRoomList.push(item.room_name)
                        socket.join(this.props.dispatch, item.room_name, this.props.school.id)
                    })
                    this.setState({
                        chatRoomList,
                    })
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        isLogged: state.isLogged,
        isFullScreen: state.isFullScreen,
    }
}
