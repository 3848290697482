import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractSubjectAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea } from '~/components/form'
import MultiImageInput from 'react-multiple-image-input'
import { Loading } from '~/components/loader'
import Select from 'react-select'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import { getBase64, getReadableFileSize, getMediaIcon } from '~/helper/function'
class SubjectAddComponent extends AbstractSubjectAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.selectFiles = this.selectFiles.bind(this)
        this.onFilesError = this.onFilesError.bind(this)
        this.setSubjectType = this.setSubjectType.bind(this)
        this.setExamStatus = this.setExamStatus.bind(this)
    }

    // getBase64(file) {
    //     if (file) {
    //         return new Promise((resolve, reject) => {
    //             var mediaAryInner = {}
    //             var mediaAry = this.state.mediaAry
    //             var reader = new FileReader()
    //             reader.readAsDataURL(file)
    //             reader.onload = function () {
    //                 var encodedFile = reader.result
    //                 mediaAryInner['name'] = file.name
    //                 mediaAryInner['data'] = encodedFile
    //                 mediaAry.push(mediaAryInner)
    //                 this.setState({
    //                     mediaAry,
    //                 })
    //             }
    //             reader.onerror = function (error) {
    //                 alert('Error: ', error)
    //                 return false
    //             }
    //         })
    //     }
    // }

    onFilesError(error, file) {
        alert('Error: ' + error.message)
    }

    filesRemoveOne = (file) => {
        let uploads = this.state.uploadedFiles
        let index = uploads.indexOf(file)
        let media = this.state.media
        uploads.splice(index, 1)
        media.splice(index, 1)
        this._handleChange('media', media)
        this._handleChange('uploadedFiles', uploads)
    }

    selectFiles(data) {
        const media = []
        if (data != null) {
            data.map((uploadedFile, index) => {
                getBase64(uploadedFile).then((file) => {
                    if (file.error == null) {
                        media[index] = file
                    }
                })
            })
            this._handleChange('media', media)
            this._handleChange('uploadedFiles', data)
        }
    }

    setSubjectType(data) {
        super._handleChange('subject_type_id', data.value)
    }

    setExamStatus(data) {
        super._handleChange('exam_status', data.value)
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="school" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.school.menu}>{t('school:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.school.subject.view + '/' + this.state.class_id}>{t('classes:subject.breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                {/* <span className="sub-main-title">{ this.state.class_name && ("Class : "+this.state.class_name)}</span> */}
                                <h5 className="main-title heading5">{t('classes:subject.add.title')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('classes:subject.add.namePlaceholder')} type="text" value="" />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.codeLabel')} *</label>
                                    <Input name="code" onChange={this._handleChange} placeholder={t('classes:subject.add.codePlaceholder')} type="text" value="" />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:subject.add.subjectTypelabel')}</label>
                                    <Select name="subject_type" options={this.state.subject_type} placeholder={t('classes:subject.add.subjectTypePlaceholder')} searchable={true} onChange={this.setSubjectType} />
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('classes:subject.add.examStatusLabel')} *</label>
                                    <Select name="exam_status" options={this.state.exam_option} placeholder={t('classes:subject.add.examStatusPlaceholder')} searchable={true} onChange={this.setExamStatus} />
                                    {this.state.errors.exam_status && <label className="error text-danger">{this.state.errors.exam_status}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value="" />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.mediaLabel')} [ .pdf ]</label>
                                    <div className="doclist sc-AxirZ sc-AxiKw lmMaya">
                                        {this.state.uploadedFiles && this.state.uploadedFiles.length > 0
                                            ? this.state.uploadedFiles.map((file) => (
                                                  <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                                      <div className="gsEyTH files-list-item" key={file.id}>
                                                          <div className="files-list-item-preview">{file.preview.type === 'image' ? <img className="files-list-item-preview-image" src={file.preview.url} /> : <div className="files-list-item-preview-extension">{file.extension}</div>}</div>
                                                          <div className="files-list-item-content">
                                                              <div className="files-list-item-content-item files-list-item-content-item-1">{file.name}</div>
                                                              <div className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</div>
                                                          </div>
                                                          <div
                                                              id={file.id}
                                                              className="files-list-item-remove"
                                                              onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                                                          />
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                        <div className="file-lists sc-AxirZ sc-AxhCb eJEChV">
                                            <Files className="files-dropzone" onChange={this.selectFiles} onError={this.onFilesError} accepts={['.pdf']} multiple maxFiles={3} maxFileSize={10000000} minFileSize={0} clickable>
                                                {t('classes:subject.add.mediaDropZone')}
                                            </Files>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const SubjectAdd = withTranslation()(connect(mapStateToProps)(SubjectAddComponent))

export { SubjectAdd }
