import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCourseEditComponent, mapStateToProps } from './abstract'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import Select from 'react-select'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import DatePicker from 'react-datepicker'
import { UserFilter } from '~/components/userfilter'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { convertADtoBS } from '../../../../../helper/function'

class CourseEditComponent extends AbstractCourseEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectStudent = this._selectStudent.bind(this)
        this._selectEmployee = this._selectEmployee.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this.setAfterDueDate = this.setAfterDueDate.bind(this)
        this.setCourseProgressive = this.setCourseProgressive.bind(this)
        this.setDate = this.setDate.bind(this)
        this.setPrivate = this.setPrivate.bind(this)
    }

    _selectEmployee(data) {
        this._handleChange('selectedEmployee', data)
    }

    _selectStudent(data) {
        this._handleChange('selectedStudent', data)
    }

    _selectSubject(data) {
        this._handleChange('selectedSubject', data)
    }

    setCourseProgressive() {
        const is_course_progressive = !this.state.is_course_progressive
        this.setState({ is_course_progressive })
    }

    setPrivate() {
        const isPrivate = !this.state.isPrivate
        this.setState({ isPrivate })
    }

    selectCourseAvailability(data) {
        this.setState({
            defaultCourseAvailability: data,
            selectedCourseAvailability: data.value,
        })
    }

    setDate(data) {
        super._handleChange('due_date', data)
    }

    setAfterDueDate(element) {
        if (element == 'course') {
            const afterDueDate = !this.state.afterDueDate
            this.setState({ afterDueDate })
        } else {
            const quizAfterDueDate = !this.state.quizAfterDueDate
            this.setState({ quizAfterDueDate })
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.menu}>{t('course:course')} > </a>
                            </li>
                            <li>{<a href={route.course.view + '/' + this.props.match.params.id}>{this.state.className} &gt; </a>}</li>
                            <li>{this.state.title}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title">{'Class: ' + this.state.className}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:course_title')} *</label>
                                    <Input name="title" onChange={this._handleChange} placeholder={t('course:course_title')} type="text" value={this.state.title} />
                                    {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:due_date')} *</label>
                                    <DatePicker dateFormat="yyyy-MM-dd" className="form-control" selected={this.state.due_date} onChange={this.setDate} placeholderText="Due Date" minDate={moment().toDate()} />
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.due_date != '' ? (convertADtoBS(this.state.due_date) != null ? convertADtoBS(this.state.due_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                    {this.state.errors.due_date && <label className="error text-danger">{this.state.errors.due_date}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:topic')} *</label>
                                    <TextArea name="topic" onChange={this._handleChange} placeholder={t('course:topic')} type="text" value={this.state.topic} />
                                    {this.state.errors.topic && <label className="error text-danger">{this.state.errors.topic}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('course:description')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('course:description')} type="text" value={this.state.description} />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="form-group col-sm-12 float-left">
                                    <div className="form-check ">
                                        <label className="form-check-label form-title" htmlFor="wont-available">
                                            {t('course:course_msg2')}
                                        </label>
                                        <Checkbox id="wont-available" onChange={() => this.setAfterDueDate('course')} name="afterDueDate" value={this.state.afterDueDate} />
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="form-group col-sm-12 float-left">
                                    <div className="form-check ">
                                        <label className="form-check-label form-title" htmlFor="quiz">
                                            {t('course:course_msg3')}
                                        </label>
                                        <Checkbox id="quiz" onChange={() => this.setAfterDueDate('quiz')} name="quizAfterDueDate" value={this.state.quizAfterDueDate} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row clearfix">
                                <div className="form-group col-sm-12 float-left">
                                    <div className="form-check ">
                                        <label htmlFor="progressive" className="form-title">
                                            {t('course:course_msg4')}
                                        </label>
                                        <Checkbox id="progressive" onChange={() => this.setCourseProgressive()} name="is_course_progressive" value={this.state.is_course_progressive} />
                                        {this.state.errors.selectedCourseProgressive && <label className="error text-danger">{this.state.errors.selectedCourseProgressive}</label>}
                                    </div>
                                </div>
                            </div> */}
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <div className="form-check">
                                        <label className="form-title" htmlFor="private">
                                            {t('course:private')}
                                        </label>
                                        <Checkbox onChange={() => this.setPrivate()} className="" name="isPrivate" value={this.state.isPrivate} id="private" />
                                    </div>
                                </div>
                            </div>
                            {this.state.isPrivate == true && (
                                <div className="add-option-exam">
                                    {console.log(this.state.allUsers)}
                                    {/* <UserFilter selectedClass={this.state.class_id} showSubject={true} resetOnChange={false} onUserLoad={this.loadUser} onStudentSelect={this._selectStudent} onEmployeeSelect={this._selectEmployee} onSubjectSelect={this._selectSubject} selectedSubject={this.state.selectedSubject} selectedStudent={this.state.selectedStudent} selectedEmployee={this.state.selectedEmployee} /> */}
                                    <UserFilter 
                                    selectedClass={this.state.class_id}
                                    hideClass
                                    resetOnChange={false} 
                                    onStudentSelect={this._selectStudent}
                                    // onUserLoad={this.state.allUsers.length == 0 && this.loadUser } 
                                    onEmployeeSelect={this._selectEmployee}
                                    selectedStudent={this.state.selectedStudent}
                                    selectedEmployee={this.state.selectedEmployee} />

                               
                                </div>
                            )}
                            <div className="form-button text-right">
                                <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const CourseEdit = withTranslation()(connect(mapStateToProps)(CourseEditComponent))

export { CourseEdit }
