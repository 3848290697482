import { get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const webinar = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('restart/server/webinar', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const chat = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('restart/server/chat', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const live = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('restart/server/live', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const test = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('restart/server/test', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
