import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { logout } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { signout } from '~/store'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { APP_URL } from '~/helper/constant/config'
import { messaging } from '~/firebaseMessaging'
import { redirect } from '~/helper/function/util'

type Props = {
    school: Object,
    user: Object,
    token: String,
    device_id: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
}

export class AbstractHeaderSettingComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            device_id: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this._logout = this._logout.bind(this)
    }

    async componentDidMount() {
        let device_id = null
        if (typeof document != 'undefined') {
            await messaging
                .requestPermission()
                .then(async function () {
                    const token = await messaging.getToken()
                    device_id = token
                })
                .catch(function (err) {})
            await this.setState({
                device_id,
            })
        } else {
            messaging()
                .getToken()
                .then((device_id) => {
                    this.setState({
                        device_id,
                    })
                })
        }
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    _logout() {
        this._handleChange('loading', true)
        const token = this.props.token
        this.props.dispatch(signout())
        logout(this.props.token, this.state.device_id).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                redirect(route.index, this.props.history)
                toastMessage('success', response.message)
                // window.location = APP_URL
            } else {
                redirect(route.index, this.props.history)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        school: state.school,
        token: state.token,
        user: state.user,
    }
}
