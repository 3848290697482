import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractNoticeAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button, TextArea } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import MultiImageInput from 'react-multiple-image-input'
import { Loading } from '~/components/loader'


class NoticeAddComponent extends AbstractNoticeAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setImages = this.setImages.bind(this)
    }

    setImages(data) {
        super._handleChange('media', data)
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="notice" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.notice.view}>Notices > </a>
                            </li>
                            <li>Add</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Add Notice:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Title *</label>
                                    <Input name="title" onChange={this._handleChange} placeholder="Title" type="text" value="" />
                                    {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Code *</label>
                                    <Input name="code" onChange={this._handleChange} placeholder="Code" type="text" value="" />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">Description *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder="description" type="text" value="" />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">Upload Media</label>
                                    <MultiImageInput images={this.state.media} setImages={this.setImages} allowCrop={false} theme="light" />
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text="Save" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const NoticeAdd = connect(mapStateToProps)(NoticeAddComponent)

export { NoticeAdd }
