import React, { Component, Fragment } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { viewBookIssue, viewBookShelf, toggleBookIssue } from '~/services/api'
import { issue_status } from '~/helper/constant/books.js'
import { toastMessage } from '~/helper/function/util'
import * as Status from '~/helper/constant/book_issue_status'
import moment from 'moment'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}

export class AbstractBookIssueComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            showModal: false,
            tableData: [],
            bookShelves: [],
            bookDetail: null,
            returnDate: '',
            fineAmount: '',
            issuedStatus: issue_status,
            dueDate: '',
            errors:{
                fineAmount: null,
                returnDate:null,

            },
            tableStructure: [
                {
                    bookshelves: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'library:bookshelves',
                    },
                    user: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'library:user',
                    },
                    issuedate: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'income:issuedate',
                    },
                    duedate: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'income:duedate',
                    },
                    issuestatus: {
                        field: 'html',
                        route: false,
                        translate: 'library:issuestatus',
                    },
                    detail: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'drive:details',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.loadBookShelf()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle() {
        const { t } = this.props
        const component = this
        const errors = validation.required(Object.keys(component.state.errors),component.state)
        component.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item]!= null)
        if(validate.length == 0){
        return new Promise(function (resolve, reject) {
            component._handleChange('loading_text', 'Loading')
            component._handleChange('loading', true)
            const data = {
                return_date: moment(component.state.returnDate).format('yyyy-MM-DD'),
                fine_amount: component.state.fineAmount,
            }

            toggleBookIssue(component.state.bookDetail.id, data, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.loadBookIssue()

                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(false)
                }
            })
        })
    }
    }

    loadBookShelf() {
        this._handleChange('loading_text', 'loading')
        this._handleChange('loading', true)
        viewBookShelf(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const bookShelves = response.data.shelf.map((item) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        bookShelves,
                    },
                    () => {
                        this.loadBookIssue()
                    }
                )
            }
        })
    }

    loadBookIssue() {
        this._handleChange('loading_text', 'loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        viewBookIssue(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.bookIssued.map((item, index) => {
                    console.log('view', item)
                    let text = null
                    if (item.issue_status == Status.ISSUED) {
                        text = {
                            text: 'Issued',
                            translate: 'library:issued',
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    } else {
                        text = {
                            text: 'Returned',
                            translate: 'library:returned',
                            badge: 'table-red',
                            modal: 'modal_class_' + index,
                        }
                    }

                    const bookShelveFound = this.state.bookShelves.find(
                        (shelf) => shelf.value === item.book_shelf.shelves_id
                    )

                    const issueStatusFound = this.state.issuedStatus.find(
                        (status) => status.value === item.issue_status
                    )

                    const bookShelvesData = {
                        bookshelves: {
                            text: bookShelveFound && bookShelveFound.label,
                        },

                        user: {
                            text: item.users_issued.name,
                        },
                        issuedate: {
                            text: item.issue_date,
                        },
                        duedate: {
                            text: item.due_date,
                        },
                        issuestatus: {
                            text: 'html',
                            html: (
                                <Fragment>
                                    <button
                                        type="button"
                                        className="table-check"
                                        onClick={() =>
                                            item.issue_status === Status.ISSUED
                                                ? this.setState({
                                                      bookDetail: item,
                                                  })
                                                : null
                                        }
                                    >
                                        {item.issue_status === Status.ISSUED ? 'Issued' : 'Returned'}
                                    </button>
                                </Fragment>
                            ),
                        },
                        detail: {
                            text: 'Detail',
                            route: route.admin.library.bookissue.detail + '/' + item.id,
                            translate: 'drive:details',
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.library.bookissue.edit + '/' + item.id,
                        },
                    }
                    tableData.push(item)
                    tableStructure.push(bookShelvesData)
                })
                this.setState({
                    tableData,
                    tableStructure,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
