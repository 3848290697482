import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getChildren } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { selectChildren } from '~/store'
import { redirect } from '~/helper/function/util'
import * as route from '~/helper/constant/route'

type Props = {
    token: String,
    student: Integer,
    dispatch: Dispatch<any>,
}

type State = {
    childrenList: Array,
}

export class AbstractChildrenComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            childrenList: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setChildren = this.setChildren.bind(this)
        this.editProfile = this.editProfile.bind(this)
    }

    componentDidMount() {
        this.loadChildren()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setChildren(id) {
        this.props.dispatch(selectChildren(id))
    }

    loadChildren() {
        this._handleChange('loading', true)
        getChildren(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState({
                    childrenList: response.data.children,
                })
            }
        })
    }

    editProfile(studentId) {
        redirect(route.children + '/' + studentId, this.props.history)
    }
}

export function mapStateToProps(state: Object) {
    return {
        student: state.student,
        token: state.token,
    }
}
