import { Component } from 'react'
import { Dispath } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../../helper/constant/api_status'
import * as route from '../../../../../../helper/constant/route'
import { redirect, toastMessage } from '../../../../../../helper/function/util'
import { getChartOfAccount, updateChartOfAccount } from '../../../../../../services/api/accounting/doubleentry'

type Props = {
    dispatch: Dispath<any>,
}
type State = {}

export class AbstractChartAccountEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            name: '',
            code: '',
            is_enabled: true,
            description: '',
            errors: {
                name: null,
                code: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount() {
        this.loadChartOfAccount()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadChartOfAccount() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getChartOfAccount(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                if (response.data.chartofAccount) {
                    const data = response.data.chartofAccount
                    console.log(data)
                    this.setState({
                        name: data.name,
                        code: data.code,
                        description: data.description,
                        is_enabled: data.is_enabled,
                    })
                }
            }
        })
    }

    onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                description: this.state.description,
            }
            if (this.state.is_enabled) {
                data['is_enabled'] = this.state.is_enabled
            }
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            updateChartOfAccount(this.props.match.params.id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.doubleentry.chartofaccount.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
