import { post, get } from '../request'

export const viewBookIssue = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-issue', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addBookIssue = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book-issue/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editBookIssue = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-issue/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const detailBookIssue = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-issue/detail/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBookIssue = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book-issue/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleBookIssue = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('book-issue/status/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeBookIssue = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('book-issue/active/', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
