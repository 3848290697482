import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewStudent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student/section/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)

        }
    })
}

export const allStudent = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/every', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewAcademicStudent = (academicId, sectionId, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/academic/section/' + academicId + '/' + sectionId, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const allAcademicStudent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/every/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addStudent = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editStudent = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteStudent = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/delete/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleStudent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getStudent = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('student-management/student/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const sectionTransfer = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('student-management/student/section-transfer', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
