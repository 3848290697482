import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewAlumniCategory = (schoolId) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/alumni-category/' + schoolId, null).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAlumniCategoryByType = (type, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/category/type/' + type, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addAlumniCategory = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('alumni/category/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAlumniCategory = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/category/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editAlumniCategory = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('alumni/category/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleAlumniCategory = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('alumni/category/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
