import { API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'
import { ACCOUNTING_API } from '../../helper/constant/config'

export const accountingGet = (path, token = null) => {
    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    const config = {
        method: 'GET',
        headers: headers,
    }

    return fetch(ACCOUNTING_API + path, config)
        .then((response) => response.json())
        .then((data) => {
            if ('code' in data) {
                const result: ApiResponse = data
                return result
            } else {
                const result = new ApiResponse()
                result.code = API_FAILURE
                result.status = 'Failure'
                result.message = 'Service Down. Please try again later.'
                return result
            }
        })
        .catch((error) => {
            const result = new ApiResponse()
            result.code = API_FAILURE
            result.status = 'Failure'
            result.message = 'Service Down. Please try again later.'
            return result
        })
}

export const accountingPost = (path, token = null, body = null) => {
    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    const config = {
        method: 'POST',
        headers: headers,
    }
    if (body) {
        config['body'] = JSON.stringify(body)
    }
    return fetch(ACCOUNTING_API + path, config)
        .then((response) => response.json())
        .then((data) => {
            if ('code' in data) {
                const result: ApiResponse = data
                return result
            } else {
                const result = new ApiResponse()
                result.code = API_FAILURE
                result.status = 'Failure'
                result.message = 'Service Down. Please try again later.'
                return result
            }
        })
        .catch((error) => {
            const result = new ApiResponse()
            result.code = API_FAILURE
            result.status = 'Failure'
            result.message = 'Service Down. Please try again later.'
            return result
        })
}
