import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewAcademic, toggleAcademic } from '~/services/api'
import { ClassDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    schoolPackage: String,
    dispatch: Dispatch<any>,
}

type State = {
    academic: Array,
    data: Array,
    access: Array,
    loading: Boolean,
}

export class AbstractAcademicViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            loading: false,
            data: [],
            academic: [
                {
                    name: {
                        field: 'text',
                        translate: 'name',
                        route: false,
                        sortable: true,
                    },
                    start_date: {
                        field: 'date',
                        translate: 'academic:table.start date',
                        route: false,
                        sortable: true,
                    },
                    end_date: {
                        field: 'date',
                        translate: 'academic:table.end date',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        translate: 'academic:table.action',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadAcademic()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolPackage != this.props.schoolPackage) {
            this.setState({
                access: this.props.schoolPackage != null ? JSON.parse(this.props.schoolPackage) : [],
            })
        }
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleAcademic(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadAcademic()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadAcademic() {
        this._handleChange('loading', true)
        viewAcademic(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let academic = []
            academic.push(this.state.academic[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.academic_year.map((item, index) => {
                    const data = item
                    let text = null
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    } else if (data.status == Status.PENDING) {
                        text = {
                            text: 'New',
                            translate: 'academic:new',
                            badge: 'table-blue',
                            modal: 'modal_class_' + index,
                        }
                    } else {
                        text = {
                            text: 'Closed',
                            translate: 'statusInactive',
                            badge: 'table-red',
                            modal: 'modal_class_' + index,
                        }
                    }
                    const tableData = {
                        name: {
                            text: data.name,
                        },
                        start_date: {
                            text: data.from_date,
                        },
                        end_date: {
                            text: data.to_date,
                        },
                        status: text,
                        action: {
                            text: 'Edit',
                            translate: 'academic:table.edit',
                            route: route.admin.academic.edit + '/' + data.id,
                        },
                    }
                    dataList.push(data)
                    academic.push(tableData)
                })
            }
            this.setState({
                data: dataList,
                academic,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
