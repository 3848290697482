import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniJobMenuComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as UserType from '~/helper/constant/user_type'

class AlumniJobMenuComponent extends AbstractAlumniJobMenuComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        return (
            <div className="main-page">
                <Sidebar id="alumniJobVacancy" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('alumni:vacany')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.alumni.job.view)}>
                                    <a href={route.alumni.job.view} className="folder-img">
                                        {SVG.subjecttype()}
                                    </a>
                                    <a href={route.alumni.job.view} className="folder-name">
                                        {t('alumni:vacany')}
                                        <span>{t('alumni:searchmsg2')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.alumni.job.applicant)}>
                                    <a href={route.alumni.job.applicant} className="folder-img">
                                        {SVG.subjecttype()}
                                    </a>
                                    <a href={route.alumni.job.applicant} className="folder-name">
                                        {this.props.school.user_type == UserType.Alumni ? t('alumni:appliedJobs') : t('alumni:jobApplicants')}
                                        <span>{t('alumni:checkJob')} </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AlumniJobMenu = withTranslation()(connect(mapStateToProps)(AlumniJobMenuComponent))

export { AlumniJobMenu }
