import React, {Component} from 'react'
import {Dispatch} from 'react-redux'
import {viewProductUnit} from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'




type Props = {
    dispatch: Dispatch<any>
}
type State = {}

export class AbstractConstantUnitViewComponent extends Component <Props, State>{
    constructor(props:Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            tableData:[],
            tableStructure:[
                {
                    unit:{
                        field:'text',
                        route:false,
                        sortable:true,
                        translate:'course:unit'
                    },
                    action:{
                        field:'button',
                        route:true,
                        sortable:false,
                        translate:'activity:action',
                    }
                }
            ]
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount(){
        this.loadUnit()
    }
    _handleChange(key, data){
        this.setState({[key]: data})
    }
    loadUnit(){
        const{t} = this.props
        this._handleChange('loadind_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData= []
        tableStructure.push(this.state.tableStructure[0])
        viewProductUnit(this.props.token).then((response)=>{
            
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                response.data.units.map((item, index)=>{
                    const rowData = {
                        unit:{
                            text:item.name
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.constant.unit.edit + '/' + item.id,
                          },
                    }
                    tableData.push(item)
                    tableStructure.push(rowData)
                })
                this.setState({
                    tableData,
                    tableStructure
                })
            }
                

        })


    }


}

export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}