export default class EnquiryDTO {
    id: integer
    name: integer
    email: integer
    institution: integer
    country: integer
    state: String
    position: String
    timezone: String
    code: String
    status: integer
    token: String
    verified: String

    constructor(id, name, email, institution, phone, country, state, position, timezone, code, start_time, status, token, verified) {
        this.id = id
        this.name = name
        this.email = email
        this.institution = institution
        this.phone = phone
        this.country = country
        this.state = state
        this.position = position
        this.timezone = timezone
        this.code = code
        this.start_time = start_time
        this.status = status
        this.token = token
        this.verified = verified
    }
}
