import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractTimetableViewComponent, mapStateToProps } from './abstract'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as Status from '~/helper/constant/status'
import { timetable_color } from '~/helper/constant/color'
import { Button, Input, Checkbox } from '~/components/form'
import $ from 'jquery'
import { Loading } from '~/components/loader'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import Select from 'react-select'
import { Table } from '~/components/table'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
class TimetableViewComponent extends AbstractTimetableViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getFilteredTimetable = this.getFilteredTimetable.bind(this)
        this.getDeleteModal = this.getDeleteModal.bind(this)
        this.getInfoModal = this.getInfoModal.bind(this)
        this.getEditModal = this.getEditModal.bind(this)
        this.getLiveModal = this.getLiveModal.bind(this)
        this.getTeacherAvailability = this.getTeacherAvailability.bind(this)
        this._selectSubject = this._selectSubject.bind(this)
        this._selectTeacher = this._selectTeacher.bind(this)
        this._selectSubstitue = this._selectSubstitue.bind(this)
        this.getSubstitute = this.getSubstitute.bind(this)
        this._join = this._join.bind(this)
        this._toggle = this._toggle.bind(this)
        this._delete = this._delete.bind(this)
        this._onSave = this._onSave.bind(this)
        this.clearState = this.clearState.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.clearState != this.state.clearState) {
            this.clearState()
        }
    }

    _join(e) {
        super._join(e)
        const link = e.target.getAttribute('link')
        let id = link.split(',')
        $('#close_join_' + id[0]).trigger('click')
    }

    _toggle(e) {
        super._toggle(e)
        const link = e.target.getAttribute('link')
        let id = link.split(',')
        $('#close_toggle_' + link).trigger('click')
    }

    _delete(e) {
        super._delete(e)
        const link = e.target.getAttribute('link')
        let id = link.split(',')
        $('#close_delete_' + link).trigger('click')
    }

    _onSave(e) {
        const link = e.target.getAttribute('link')
        super._onSave(e).then((response) => {
            if (response) {
                $('#close_edit_' + link).trigger('click')
            }
        })
    }

    _selectSubject(data) {
        this._handleChange('subject_id', data.value)
        this._handleChange('selectedSubject', data)
    }

    _selectTeacher(data) {
        this._handleChange('teacher_id', data.value)
        this.setState(
            {
                selectedTeacher: data,
            },
            () => {
                this.loadTeacherAvailabilty()
                this.filterSubstitute(data.value)
            }
        )
    }

    _selectSubstitue(data) {
        const sub = []
        const selectedSub = []
        if (data) {
            data.filter((item) => item.value != '').map((item) => {
                sub.push(item.value)
                selectedSub.push(item)
            })
        }
        this._handleChange('substitute_id', sub)
        this._handleChange('selectedSubstitute', selectedSub)
    }

    getSubstitute(substitute) {
        if (substitute) {
            this.state.teacher
                .filter((item) => substitute.includes(item.value))
                .map((item, index) => {
                    return (
                        <span key={index} className="teacher-name">
                            {item.label}
                        </span>
                    )
                })
        } else {
            return <span></span>
        }
    }

    getTeacherAvailability() {
        const { t } = this.props
        return (
            <div id="check_availability" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('timetable:availableTeacher')}</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('timetable:teacher')} *</label>
                                    <Input name="name" disabled="disabled" type="text" value={this.state.selectedTeacher.label} />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <Table data={this.state.teacherAvailabilty} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <button id="close_add" type="button" className="cancel-button" data-dismiss="modal">
                                {t('common:modal close')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getDeleteModal() {
        const { t } = this.props
        const modal = this.state.timetable.map((item, index) => {
            return (
                <div key={index} id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span> {t('common:warning')}!!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{t('timetable:deleteTimetable')}?</p>
                                <p className="delete-file-name">"{item.start_time + ' - ' + item.end_time}"</p>
                                {item.subject && <p className="delete-file-name">"{item.subject.name}"</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._delete} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                <button id={'close_delete_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getJoinModal() {
        const { t } = this.props
        const modal = this.state.timetable.map((item, index) => {
            return (
                <div key={index} id={'modal_join_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('timetable:join')} {t('timetable:toggleLiveClass')}?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                {item.bbb != null && <Button onClick={this._join} link={item.id + ',' + item.bbb.id} type="submit" text={t('common:modal confirm')} main={true} />}
                                <button id={'close_join_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getLiveModal() {
        const { t } = this.props
        const modal = this.state.timetable.map((item, index) => {
            let text = t('timetable:stopLiveClass')
            if (item.status != Status.ACTIVE) {
                text = t('timetable:startLiveClass')
            }
            return (
                <div key={index} id={'modal_toggle_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {' '}
                                    {text} {t('timetable:toggleLiveClass')}?
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._toggle} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                <button id={'close_toggle_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getInfoModal() {
        const { t } = this.props
        const modal = this.state.timetable.map((item, index) => {
            return (
                <div key={index} id={'modal_info_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('timetable:periodInfo')} : </span>
                        </div>
                        <br />
                        <form className="main-form month-view p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <ul className="list-inline day-view-list">
                                        <li className="small-green">
                                            <small>{item.start_time}</small>
                                            <div className="day-view-detail">
                                                {item.subject && (
                                                    <ins>
                                                        {item.subject.name} &nbsp;&nbsp;
                                                        {item.live_class_status == true && <ins className="new-con">{t('timetable:liveClass')}</ins>}
                                                    </ins>
                                                )}
                                                {!item.subject && <ins>{t('timetable:break')}</ins>}
                                                {item.subject && (
                                                    <span>
                                                        {t('timetable:subject_code')}: {item.subject.code}
                                                    </span>
                                                )}
                                                {item.teacher && (
                                                    <strong>
                                                        {t('timetable:teacher')} : {item.teacher.user.name}
                                                    </strong>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="row">
                                        <label className="form-title col-md-5 float-left">{t('timetable:primaryTeacher')}</label>
                                        <div className="col-md-7 float-left">
                                            {item.teacher && <span className="teacher-name">{item.teacher.user.name}</span>}
                                            <br />
                                        </div>
                                    </div>

                                    {item.substitute != null && item.substitute.length > 0 && (
                                        <Fragment>
                                            <hr />
                                            <div className="row">
                                                <label className="form-title col-md-5 float-left">{t('timetable:substituteTeacher')}</label>
                                                <div className="col-md-7 float-left">
                                                    {this.state.teacher
                                                        .filter((data) => item.substitute.includes(data.value))
                                                        .map((data, index) => {
                                                            const name = data.label.split('-')
                                                            return (
                                                                <span key={index} className="teacher-name">
                                                                    {name[0]}
                                                                </span>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <button id={'close_info_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getEditModal() {
        const { t } = this.props
        const modal = this.state.timetable.map((item, index) => {
            return (
                <div key={index} id={'modal_edit_' + item.id} data-id={item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('timetable:editPeriod')} :</span>
                        </div>
                        <br />
                        <form className="main-form month-view p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <ul className="list-inline day-view-list">
                                        <li className="small-green">
                                            <small>{item.start_time}</small>
                                            <div className="day-view-detail">
                                                {item.subject && (
                                                    <ins>
                                                        {item.subject.name} &nbsp;&nbsp;
                                                        {item.live_class_status == true && <ins className="new-con">{t('timetable:liveClass')}</ins>}
                                                    </ins>
                                                )}
                                                {!item.subject && <ins>{t('timetable:break')}</ins>}
                                                {item.subject && (
                                                    <span>
                                                        {t('timetable:subject_code')} : {item.subject.code}
                                                    </span>
                                                )}
                                                {item.teacher && (
                                                    <strong>
                                                        {t('timetable:teacher')} : {item.teacher.user.name}
                                                    </strong>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    {/* <div className="row">
                                        <label className="form-title col-5 float-left">{t('timetable:liveClass')}</label>
                                        <div className="col-7 float-left">
                                            <Checkbox onChange={this._handleChange} className="checkbox-form" name="live_class_status" value={this.state.live_class_status} />
                                        </div>
                                    </div>
                                    <hr /> */}
                                    <div className="row">
                                        <label className="form-title col-md-5 float-left">{t('classes:subject.title')}</label>
                                        <div className="col-md-7 float-left">
                                            <Select name="type" options={this.state.subject} placeholder={t('classes:subject.title')} value={this.state.selectedSubject} searchable={true} onChange={this._selectSubject} />
                                            {this.state.errors.subject_id && <label className="error text-danger">{this.state.errors.subject_id}</label>}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <label className="form-title col-md-5 float-left">{t('timetable:primaryTeacher')}</label>
                                        <div className="col-md-7 float-left">
                                            <Select name="type" options={this.state.teacher} placeholder={t('timetable:primaryTeacher')} searchable={true} value={this.state.selectedTeacher} onChange={this._selectTeacher} />
                                            <a href="#" data-toggle="modal" data-target="#check_availability" className="small-text">
                                                {t('timetable:checkTeacher')}
                                            </a>
                                            <br />
                                            {this.state.errors.teacher_id && <label className="error text-danger">{this.state.errors.teacher_id}</label>}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <label className="form-title col-md-5 float-left">{t('timetable:substituteTeacher')}</label>
                                        <div className="col-md-7 float-left">
                                            <Select name="type" options={this.state.substitute} placeholder={t('timetable:substituteTeacher')} searchable={true} isMulti value={this.state.selectedSubstitute} onChange={this._selectSubstitue} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this._onSave} link={item.id} type="submit" text={t('common:modal confirm')} main={true} />
                                <button id={'close_edit_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getFilteredTimetable() {
        const { t } = this.props
        let i = -1
        if (this.state.filterTimeTable.length > 0) {
            const modal = this.state.filterTimeTable.map((item, index) => {
                i++
                if (i == 4) {
                    i = 0
                }
                return (
                    <li key={index} className={timetable_color[i]}>
                        <small>{item.start_time}</small>
                        <div className="day-view-detail">
                            {item.subject && (
                                <ins>
                                    {item.subject.name} &nbsp;&nbsp;
                                    {item.live_class_status == true && <ins className="new-con">{t('timetable:liveClass')}</ins>}
                                </ins>
                            )}
                            {!item.subject && <ins>{t('timetable:break')}</ins>}
                            {item.subject && (
                                <span>
                                    {t('timetable:subject_code')} : {item.subject.code}
                                </span>
                            )}
                            {item.teacher && (
                                <strong>
                                    {t('timetable:teacher')} : {item.teacher.user.name}
                                </strong>
                            )}
                        </div>
                        {item.live_class_status == true && item.status == Status.ACTIVE && (
                            <button className="start" data-toggle="modal" data-target={'#modal_join_' + item.id}>
                                {t('timetable:join')}
                            </button>
                        )}
                        {item.live_class_status == true && item.status == Status.INACTIVE && (
                            <button className="start" data-toggle="modal" data-target={'#modal_toggle_' + item.id}>
                                {t('timetable:startLiveClass')}
                            </button>
                        )}
                        <div className="e-d-t">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" data-toggle="collapse" data-target={'#e-d-t' + index}>
                                    <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                </button>
                                <div className="dropdown-menu post-option" aria-labelledby={'e-d-t' + index} id={'e-d-t' + index} data-toggle="collapse" x-placement="bottom-start">
                                    <button className="dropdown-item" data-toggle="modal" data-target={'#modal_info_' + item.id}>
                                        {t('common:Info')}
                                    </button>
                                    <button className="dropdown-item" data-toggle="modal" data-target={'#modal_edit_' + item.id} type="button">
                                        {t('common:EditBtn')}
                                    </button>
                                    <button className="dropdown-item" data-toggle="modal" data-target={'#modal_delete_' + item.id}>
                                        {t('common:DeleteBtn')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            })
            return modal
        } else {
            return (
                <p className="no-data">
                    {' '}
                    {SVG.nodata()} {t('timetable:assignedPeriod')}.
                </p>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="timetable" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option no-border mb-0 pb-0">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.timetable.menu}>{t('timetable:timetable')} &gt; </a>
                            </li>
                            <li>{t('common:viewBtn')}</li>
                        </ul>
                    </div>
                    <div className="calender calendar-page">
                        <h5 className="main-title text-center">
                            <span className="sub-main-title">
                                {this.state.classes && this.state.classes.name} - {this.state.section && this.state.section.name}{' '}
                            </span>
                            <br />
                            <button className="prev-next" onClick={(e) => this.prevDay(this.state.activeDay.value)}>
                                <FontAwesomeIcon className="icon" icon="chevron-left" color="#333" size="lg" />
                            </button>
                            {this.state.activeDay.label}
                            <button className="prev-next" onClick={(e) => this.nextDay(this.state.activeDay.value)}>
                                <FontAwesomeIcon className="icon" icon="chevron-right" color="#333" size="lg" />
                            </button>
                        </h5>
                        <br />
                        <div className="main-head-option">
                            <div className="row clearfix">
                                <div className="timetable-sort col-sm-12">
                                    <div className="display-inline-block top-select">
                                        {/* <button className="select-file">
                                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.18 4.93H5.15V8.035H3.56V4.93H0.53V3.49H3.56V0.384999H5.15V3.49H8.18V4.93Z" fill="#333333" fillOpacity="0.66"></path>
                                            </svg>
                                            Upload
                                        </button> */}
                                        <a className="select-file" href={route.admin.timetable.add + '/' + this.props.match.params.id}>
                                            {SVG.add()}
                                            {t('timetable:addPeriod')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="month-view">
                            <ul className="list-inline day-view-list">{this.getFilteredTimetable()}</ul>
                        </div>
                    </div>
                </div>
                {this.getDeleteModal()}
                {this.getInfoModal()}
                {this.getEditModal()}
                {this.getLiveModal()}
                {this.getJoinModal()}
                {this.getTeacherAvailability()}
            </div>
        )
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')
            if (id != undefined) {
                const timetable = component.state.timetable.find((item) => item.id == id)
                const live_class_status = timetable.live_class_status
                const subjectData = {
                    label: timetable.subject.name + ' - ' + timetable.subject.code,
                    value: timetable.subject.id,
                }
                component._handleChange('timetable_id', timetable.id)
                component._handleChange('day', timetable.day)
                component._handleChange('start_time', timetable.start_time)
                component._handleChange('end_time', timetable.end_time)
                component._handleChange('live_class_status', live_class_status)
                component._handleChange('subject_id', timetable.subject.id)
                component._handleChange('selectedSubject', subjectData)
                component._handleChange('teacher_id', timetable.teacher.id)
                component._handleChange('defaultTeacher', timetable.teacher.id)
                if (timetable.substitute != null) {
                    component._handleChange('substitute_id', timetable.substitute)
                } else {
                    component._handleChange('substitute_id', [])
                }
                const selectedTeacher = component.state.teacher.find((item) => item.value == timetable.teacher.id)
                component.setState(
                    {
                        selectedTeacher: selectedTeacher != null ? selectedTeacher : component.state.teacher[0],
                    },
                    () => {
                        component.loadTeacherAvailabilty()
                        component.filterSubstitute(timetable.teacher.id)
                    }
                )
            }
        })
    }
}

const TimetableView = withTranslation()(connect(mapStateToProps)(TimetableViewComponent))

export { TimetableView }
