import { get, post } from './request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const getSchoolUserProfile = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('users/profile/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const getSystemInfo = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('system/information', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const searchUser = (search, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('search/user/' + search, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSchoolUser = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/users', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAllSchoolUser = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/all/users', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getAllActiveSchoolUser = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('school/active/users', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const resetPassword = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('users/reset-password/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getSession = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('users/session/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const superResetPassword = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('superadmin/user/reset-password/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggle = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('superadmin/user/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleUserSchool = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('user/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const forgotPassword = (data) => {
    return new Promise(function (resolve, reject) {
        try {
            post('forgot-password', null, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const resetPasswordUser = (data) => {
    return new Promise(function (resolve, reject) {
        try {
            post('reset-password', null, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
