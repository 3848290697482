import {Component} from 'react'
import {Dispatch} from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import moment from 'moment'
import {viewBankAccount, getBankTransfer, updateBankTransfer} from '~/services/api'




type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractBankTransferEditComponent extends Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state = {
            id:'',
            loading: false,
            loading_text: "Loading",
            from_account:'',
            to_account:'',
            allAccounts:[],
            fromAccount:[],
            defaultFromAccount:'',
            selectedFromAccount:'',
            toAccount:[],
            defaultToAccount:'',
            selectedToAccount:'',
            amount:"",
            date:"",
            reference:"",
            description:"",
            fromToAccountError:"",
            errors:{
                from_account:null,
                to_account:null,
                selectedToAccount:null,
                selectedFromAccount:null,
                amount:null,
                date:null,
                reference: null,
                description:null
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount(){
        this.loadBankTransfer();

    }
   

    _handleChange(key, data){
        this.setState({[key]: data})
    }

    loadAccount(){
        viewBankAccount(this.props.token).then((response)=>{
            this._handleChange('loading', false)
            let fromAccount = []
            let defaultFromAccount = ''
            let selectedFromAccount = ''
            let toAccount = []
            let defaultToAccount = ''
            let selectedToAccount = ''
            if (response.code == API_SUCCESS){
                response.data.accounts.map((item, index)=>{
                    const data ={
                        label: item.holder_name + '-' + item.bank_name,
                        value: item.id
                    }

                    fromAccount.push(data)
                    toAccount.push(data)
                    if(item.id == this.state.from_account){
                        defaultFromAccount=data
                        selectedFromAccount=item.id

                    }
                    if(item.id == this.state.to_account){
                        defaultToAccount= data
                        selectedToAccount = item.id
                    }

                })
                this.setState({
                    allAccounts: response.data.accounts,
                    fromAccount,
                    defaultFromAccount,
                    selectedFromAccount,
                    toAccount,
                    defaultToAccount,
                    selectedToAccount
                })
            }
             
            

        })

    }

    loadBankTransfer(){
        const {t} = this.props
        this._handleChange('loading_text', "Loading")
        this._handleChange('loading', true)
        getBankTransfer(this.props.match.params.id, this.props.token).then((response)=>{
            
            if(response.code == API_SUCCESS){
                const item = response.data.transfer
                const accounts = response.data.bankAccount
                // console.log(accounts, item)
                // console.log(accounts[item.from_account])
                this.setState({
                    // from_account: accounts[item.from_account],
                    // to_account: accounts[item.to_account],
                    from_account: item.from_account,
                    to_account: item.to_account,
                    amount:item.amount,
                    date:item.date,
                    reference:item.reference,
                    description:item.description,
                    id:item.id
                },
                ()=>{
                    this.loadAccount()
                })
                
            }
        })

    }

    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=>errors
        [item] != null)
        if(validate.length == 0){
            const { selectedFromAccount, selectedToAccount} = this.state;

            if(selectedFromAccount === selectedToAccount){
                this.setState({ fromToAccountError: 'From account cannot be the same as To account' });
            }else{
                this.setState({ fromToAccountError: '' });
                const data = {
                    from_account:this.state.selectedFromAccount,
                    to_account: this.state.selectedToAccount,
                    amount:this.state.amount,
                    date:moment(this.state.date).format('YYYY-MM-DD'),
                    reference: this.state.reference,
                    description:this.state.description
                }
                this._handleChange('loading', true)
                    updateBankTransfer(this.state.id, data, this.props.token).then((response)=>{
                        this._handleChange('loading', false)
                        if(response.code == API_SUCCESS){
                            toastMessage("success", response.message)
                            redirect(route.admin.accounting.banking.transfer.view)
                        }else{
                            toastMessage('error', response.data)
                            if(response.data){
                                if('errors' in response.data){
                                    this.setState({errros: response.data.errors})
                                }
                            }
                        }
                    })
            }
        }
        

    }
}
export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
    }
}