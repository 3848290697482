import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewResult, toggleExamPost } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import * as route from '~/helper/constant/route'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { withTranslation } from 'react-i18next'

type Props = {
    token: Object,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    tableData: Array,
    result: Array,
}

export class AbstractResultViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            result: [],
            tableData:
                this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents
                    ? [
                          {
                              result: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  translate: 'exam:result.categoryTitle',
                              },
                              class: {
                                  field: 'badge',
                                  route: false,
                                  sortable: false,
                                  translate: 'exam:exam.routine.class',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  sortable: true,
                                  translate: 'status',
                              },
                              assign_marks: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'exam:assignMarks',
                              },
                              view_report:{
                                field: 'button',
                                route:true,
                                sortable:false,
                                translate: 'exam:viewReport',
                              }
                          },
                      ]
                    : [
                          {
                              result: {
                                  field: 'text',
                                  route: false,
                                  sortable: true,
                                  sortable: true,
                                  translate: 'exam:result.categoryTitle',
                              },
                              class: {
                                  field: 'badge',
                                  route: false,
                                  sortable: false,
                                  translate: 'exam:exam.routine.class',
                              },
                              status: {
                                  field: 'badge',
                                  route: false,
                                  sortable: true,
                                  translate: 'status',
                              },
                              view: {
                                  field: 'button',
                                  route: true,
                                  sortable: false,
                                  translate: 'viewBtn',
                              },
                          },
                      ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadResult = this.loadResult.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.loadResult()
    }

    toggle(id) {
        console.log(id)
        toggleExamPost(id, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadResult()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadResult() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewResult(this.props.token).then((response) => {
            console.log("view result",response.data.post)
            this._handleChange('loading', false)
            let tableData = []
            tableData.push(this.state.tableData[0])
            let result = []
            if (response.code == API_SUCCESS) {
                response.data.post.map((item, index) => {
                    let status = {
                        text: 'Draft',
                        badge: 'table-red',
                        modal: 'modal_status_' + item.id,
                        translate: 'exam:draft',
                    }
                    if (item.status == Status.ACTIVE) {
                        status = {
                            text: 'Published',
                            translate: 'exam:published',
                            badge: 'table-green',
                            modal: 'modal_status_' + item.id,
                        }
                    }
                    let access_data = []
                    item.class_access_information.map((classes) => {
                        access_data.push(classes.name + ' - ' + classes.code)
                    })
                    const data = {
                        result: {
                            text: item.title,
                        },
                        class: {
                            text: access_data,
                            badge: 'table-grey',
                        },
                        status,
                    }
                    if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
                        data['view'] = {
                            text: 'View',
                            translate: 'viewBtn',
                            route: route.examination.result.result + '/' + item.parent_id,
                        }
                    } else {
                        data['assign_marks'] = {
                            text: 'Manage',
                            translate: 'exam:manage',
                            route: route.examination.result.menu + '/' + item.parent_id,
                        },
                        data['view_report'] = {
                            text: 'View Report Card',
                            translate: 'exam:viewReport',
                            route: route.examination.result.reportcardmenu + '/' + item.parent_id,
                        }
                    }
                    result.push(item)
                    tableData.push(data)
                })
                this.setState({
                    result,
                    tableData,
                })
            }
        })
    }
}

const AbstractResultView = withTranslation()(AbstractResultViewComponent)

export { AbstractResultView }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
