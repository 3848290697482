import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { viewAsset } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    tableData: Array,
    tableStructure: Array,
    loading: Boolean,
}

export class AbstractAssetComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            tableData: [],
            tableStructure: [
                {
                    title: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    purchase_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'income:purchaseDate',
                    },
                    supported_date: {
                        field: 'date',
                        route: false,
                        sortable: true,
                        translate: 'income:supportedDate',
                    },
                    amount: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'income:amount',
                    },
                    description: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:description',
                    },
                    action: {
                        field: 'button',
                        route: true,
                        sortable: false,
                        translate: 'activity:action',
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
    }
    componentDidMount() {
        this.loadAsset()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAsset() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let tableStructure = []
        let tableData = []
        tableStructure.push(this.state.tableStructure[0])
        viewAsset(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                response.data.assets.map((item, index) => {
                    let text = null
                    const rowData = {
                        title: {
                            text: item.name,
                        },
                        purchase_date: {
                            text: item.purchase_date,
                        },
                        supported_date: {
                            text: item.supported_date,
                        },
                        amount: {
                            text: item.amount,
                        },
                        description: {
                            text: item.description,
                        },
                        action: {
                            text: 'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.accounting.asset.edit + '/' + item.id,
                        },
                    }
                    tableData.push(item)
                    tableStructure.push(rowData)
                })
                this.setState({
                    tableData,
                    tableStructure,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
    }
}
