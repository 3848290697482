import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractAlumniCategoryAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import { Button, Input, TextArea } from '~/components/form'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'
import MultiImageInput from 'react-multiple-image-input'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'

class AlumniCategoryAddComponent extends AbstractAlumniCategoryAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setProfile = this._setProfile.bind(this)
    }

    _setProfile(data) {
        this._handleChange('profile', data)
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id={this.state.pageTitle != null && this.state.pageTitle.value == ALUMNI_CATEGORY.Alumni_Company ? 'alumniCompany' : 'alumniCategory'} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            {this.state.pageTitle != null && this.state.pageTitle.value != ALUMNI_CATEGORY.Alumni_Company && (
                                <Fragment>
                                    <li>
                                        <a href={route.admin.alumni.category.menu}>{t('alumni:alumniCategory')} > </a>
                                    </li>
                                    <li>
                                        <a href={route.admin.alumni.category.view + '/ ' + this.props.match.params.type}>{this.state.pageTitle != null ? t(this.state.pageTitle.translate) : ''} > </a>
                                    </li>
                                    <li>{t('common:addBtn')}</li>
                                </Fragment>
                            )}
                            {this.state.pageTitle != null && this.state.pageTitle.value == ALUMNI_CATEGORY.Alumni_Company && (
                                <Fragment>
                                    <li>
                                        <a href={route.admin.alumni.category.view + '/ ' + this.props.match.params.type}>{t(this.state.pageTitle.translate)} > </a>
                                    </li>
                                    <li>{t('common:addBtn')}</li>
                                </Fragment>
                            )}
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{this.state.pageTitle != null ? t(this.state.pageTitle.translate) : ''} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="clearfix">
                                <div className="float-left form-group profile-container">
                                    <label className="form-title">Logo</label>
                                    <MultiImageInput images={this.state.profile} max={1} setImages={this._setProfile} allowCrop={false} theme="light" />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:class.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('classes:class.nameLabel')} type="text" value="" />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:class.codeLabel')} *</label>
                                    <Input name="code" onChange={this._handleChange} placeholder={t('classes:class.codePlaceholder')} type="text" value="" />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                            </div>
                            {this.state.pageTitle != null && this.state.pageTitle.value == ALUMNI_CATEGORY.Alumni_Company && (
                                <Fragment>
                                    <div className="row clearfix">
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('common:email')}</label>
                                            <Input name="email" onChange={this._handleChange} placeholder={t('common:email')} type="text" value="" />
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('common:contact')}</label>
                                            <Input name="contact" onChange={this._handleChange} placeholder={t('common:contact')} type="text" value="" />
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('common:address')}</label>
                                            <Input name="address" onChange={this._handleChange} placeholder={t('common:address')} type="text" value="" />
                                        </div>
                                        <div className="col-sm-6 float-left form-group">
                                            <label className="form-title">{t('alumni:website')}</label>
                                            <Input name="website" onChange={this._handleChange} placeholder={t('alumni:website')} type="text" value="" />
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder={t('classes:subject.add.descriptionPlaceholder')} type="text" value="" />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const AlumniCategoryAdd = withTranslation()(connect(mapStateToProps)(AlumniCategoryAddComponent))

export { AlumniCategoryAdd }
