import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewStudent, toggleStudent, deleteStudent, getSection, viewStudentCategory, resetPassword } from '~/services/api'
import { StudentDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    student: Array,
    data: Array,
    loading: Boolean,
    description: String,
    section: Object,
    student_type: integer,
    extra_data: string,
}

export class AbstractStudentViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            data: [],
            description: '',
            student_type: null,
            student_category: null,
            section_id: this.props.match.params.section_id,
            section: null,
            student: [
                {
                    roll_no: {
                        field: 'text',
                        translate: 'student:student.rollNo',
                        route: false,
                        sortable: true,
                    },
                    reg_no: {
                        field: 'text',
                        translate: 'student:student.regNo',
                        route: false,
                        sortable: true,
                    },
                    student: {
                        field: 'text',
                        translate: 'student:student.title',
                        route: false,
                        sortable: true,
                    },
                    email: {
                        field: 'text',
                        translate: 'email',
                        route: false,
                        sortable: true,
                    },
                    dob: {
                        field: 'date',
                        translate: 'student:student.add.dobLabel',
                        route: false,
                        sortable: true,
                    },
                    join_date: {
                        field: 'date',
                        translate: 'student:student.add.joinDateLabel',
                        route: false,
                        sortable: true,
                    },
                    student_category: {
                        field: 'text',
                        translate: 'student:student.add.studentCategoryLabel',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    info: {
                        field: 'button',
                        translate: 'Info',
                        route: true,
                        sortable: false,
                    },
                    parents: {
                        field: 'button',
                        translate: 'student:student.parent.title',
                        route: true,
                        sortable: false,
                    },
                    action: {
                        field: 'button',
                        translate: 'student:student.action',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this.delStudent = this.delStudent.bind(this)
    }

    componentDidMount() {
        this.loadStudentCategory()
        this.loadSection()
    }

    loadStudentCategory() {
        this._handleChange('loading', true)
        viewStudentCategory(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                let student_category = []
                response.data.user_category.map((item, index) => {
                    let student_category_data = {}
                    student_category_data['value'] = item.id
                    student_category_data['label'] = item.name + ' - ' + item.code
                    student_category.push(student_category_data)
                })
                this.setState({ student_category })
            }
        })
    }

    loadSection() {
        getSection(this.state.section_id, this.props.token).then((response) => {
            let section = null
            let classes = null
            if (response.code == API_SUCCESS) {
                section = response.data.section
                this.setState(
                    {
                        section,
                    },
                    () => this.loadStudent()
                )
            }
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleStudent(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadStudent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    delStudent(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        deleteStudent(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadStudent()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadStudent() {
        const { t } = this.props
        viewStudent(this.state.section_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let student = []
            student.push(this.state.student[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                this._handleChange('loading', false)
                response.data.student.map((item, index) => {
                    const data: StudentDTO = item
                    let text = null
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: t('common:statusActive'),
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: t('common:statusInactive'),
                            badge: 'table-red',
                            modal: 'modal_class_' + index,
                        }
                    }
                    const tableData = {
                        roll_no: {
                            text: data.user_academics[0].roll_no,
                        },
                        reg_no: {
                            text: data.user.reg_no,
                        },
                        student: {
                            text: data.user.name,
                        },
                        email: {
                            text: data.user.email,
                        },
                        dob: {
                            text: moment(data.user.dob).format('yyyy-MM-DD'),
                        },
                        join_date: {
                            text: moment(data.joining_date).format('yyyy-MM-DD'),
                        },
                        student_category: {
                            text: data.users_category_id != null ? this.state.student_category.filter((el) => el.value == data.users_category_id)[0].label : 'N/A',
                        },
                        status: text,
                        info: {
                            text: 'view',
                            translate: t('common:viewBtn'),
                            modal: 'modal_info_' + index,
                        },
                        parents: {
                            text: 'view',
                            translate: t('common:viewBtn'),
                            route: route.admin.user.parent.view + '/' + this.state.section_id + '/' + data.id,
                        },
                        action: {
                            text: 'Edit',
                            translate: t('common:EditBtn'),
                            route: route.admin.user.student.edit + '/' + this.state.section_id + '/' + data.id,
                        },
                    }
                    dataList.push(data)
                    student.push(tableData)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState({
                data: dataList,
                student,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
