import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewEmployee, toggleEmployee, deleteEmployee, getRole, viewEmployeeCategory, resetPassword } from '~/services/api'
import { EmployeeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { employee_type } from '~/helper/constant/user_type'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    employee: Array,
    data: Array,
    loading: Boolean,
    description: String,
    classes: Array,
    employee_type: integer,
    extra_data: string,
    employee_category: null,
}

export class AbstractEmployeeViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            description: '',
            employee_type: null,
            user_type: this.props.match.params.user_type,
            employee_role: employee_type.find((item) => item.value == this.props.match.params.user_type),
            role_id: this.props.match.params.role_id != 0 ? this.props.match.params.role_id : null,
            classes: null,
            employee: [
                {
                    name: {
                        field: 'text',
                        translate: 'employee:employee.add.nameLabel',
                        route: false,
                        sortable: true,
                    },
                    reg_no: {
                        field: 'text',
                        translate: 'employee:employee.info.user-info.regNo',
                        route: false,
                        sortable: true,
                    },
                    email: {
                        field: 'text',
                        translate: 'employee:employee.add.emailLabel',
                        route: false,
                        sortable: true,
                    },
                    dob: {
                        field: 'date',
                        translate: 'employee:employee.add.dobLabel',
                        route: false,
                        sortable: true,
                    },
                    join_date: {
                        field: 'date',
                        translate: 'employee:employee.add.joinDateLabel',
                        route: false,
                        sortable: true,
                    },
                    employee_category: {
                        field: 'text',
                        translate: 'employee:employee.add.employeeCategoryLabel',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    info: {
                        field: 'button',
                        translate: 'employee:employee.info.title',
                        route: true,
                        sortable: false,
                    },
                    action: {
                        field: 'button',
                        translate: 'employee:employee-category.action',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this.delEmployee = this.delEmployee.bind(this)
    }

    componentDidMount() {
        this.loadEmployeeCategory()
        this.getEmployeeRole()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    getEmployeeRole() {
        if (this.state.role_id != 0) {
            getRole(this.state.role_id, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    this.setState({
                        employee_role: {
                            label: this.state.employee_role.label + ' : ' + response.data.role.name,
                            id: this.state.role_id,
                        },
                    })
                }
            })
        }
    }

    loadEmployeeCategory() {
        this._handleChange('loading', true)
        viewEmployeeCategory(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let employee_category = []
                response.data.user_category.map((item, index) => {
                    let employee_category_data = {}
                    employee_category_data['value'] = item.id
                    employee_category_data['label'] = item.name + ' - ' + item.code
                    employee_category.push(employee_category_data)
                })
                this.setState({ employee_category }, () => this.loadEmployee())
            }
        })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleEmployee(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEmployee()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    delEmployee(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        deleteEmployee(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadEmployee()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadEmployee() {
        this._handleChange('loading', true)
        viewEmployee(this.state.user_type, this.state.role_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let employee = []
            let classes = null
            employee.push(this.state.employee[0])
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.employee.map((item, index) => {
                    const data: EmployeeDTO = item
                    let text = null
                    let role_id = 0
                    if (this.state.role_id != null && this.state.role_id != '0' && this.state.role_id != 'null') {
                        role_id = this.state.role_id
                    }
                    if (data.status == Status.ACTIVE) {
                        text = {
                            text: 'Active',
                            translate: 'statusActive',
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    } else {
                        text = {
                            text: 'Inactive',
                            translate: 'statusInactive',
                            badge: 'table-red',
                            modal: 'modal_class_' + index,
                        }
                    }
                    const tableData = {
                        name: {
                            text: data.user.name,
                        },
                        reg_no: {
                            text: data.user.reg_no,
                        },
                        email: {
                            text: data.user.email,
                        },
                        dob: {
                            text: moment(data.user.dob).format('yyyy-MM-DD'),
                        },
                        join_date: {
                            text: moment(data.joining_date).format('yyyy-MM-DD'),
                        },
                        employee_category: {
                            text: data.users_category_id != null ? this.state.employee_category.filter((el) => el.value == data.users_category_id)[0].label : 'N/A',
                        },
                        status: text,
                        info: {
                            text: 'view',
                            translate: 'viewBtn',
                            modal: 'modal_info_' + index,
                        },
                        action: {
                            text: 'Edit',
                            translate: 'EditBtn',
                            route: route.admin.user.employee.edit + '/' + data.id + '/' + this.state.user_type + '/' + role_id,
                        },
                    }
                    dataList.push(data)
                    employee.push(tableData)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState({
                data: dataList,
                employee,
                classes,
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
