import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractHeaderComponent, mapStateToProps } from './abstract'
import $ from 'jquery'
import { Input } from '~/components/form'
import { ChatNotification, Notification } from './notification'
import { HeaderSetting } from './setting'
import * as SVG from '~/assets/svg'
import { activity } from '~/helper/constant/post_type'
import { filter_role } from '~/helper/constant/user_type'
import DatePicker from 'react-datepicker'
import * as UserType from '~/helper/constant/user_type'
import LanguageSelector from '~/components/language/language'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as Status from '~/helper/constant/status'

class HeaderComponent extends AbstractHeaderComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._toogleSidebar = this._toogleSidebar.bind(this)
        this.getActivity = this.getActivity.bind(this)
        this.getRole = this.getRole.bind(this)
        this._setFromDate = this._setFromDate.bind(this)
        this._setToDate = this._setToDate.bind(this)
    }

    _toogleSidebar() {
        $('.main-page').toggleClass('active')
    }

    _setFromDate(data) {
        this._handleChange('from_date', data)
    }

    _setToDate(data) {
        this._handleChange('to_date', data)
    }

    getActivity() {
        const data = activity.map((item, index) => {
            const classData = this.state.post_type.includes(item.value) ? 'mb-1 cursor active' : 'mb-1 cursor'
            return (
                <li className={classData} key={index} onClick={(e) => this.setPostType(item.value)}>
                    {item.label}
                    <button type="button" className="delete-filter">
                        {SVG.deleteFilter()}
                    </button>
                </li>
            )
        })
        return data
    }

    getRole() {
        const data = filter_role.map((item, index) => {
            const classData = this.state.user_type.includes(item.value) ? 'mb-1 cursor active' : 'mb-1 cursor'
            return (
                <li className={classData} key={index} onClick={(e) => this.setUserType(item.value)}>
                    {item.label}
                    <button type="button" className="delete-filter">
                        {SVG.deleteFilter()}
                    </button>
                </li>
            )
        })
        return data
    }

    render() {
        const { t, school } = this.props
        const { access } = this.state
        const role = school != null && school.role != null ? school.role.access : null
        return (
            <div className={'head-nav ' + this.props.className}>
                <nav className="navbar navbar-expand-lg col-md-12">
                    {this.state.hideToggle == false && (
                        <button className="sidebarCollapse" onClick={this._toogleSidebar}>
                            {SVG.compress()}
                        </button>
                    )}
                    <div className="main-name clearfix">
                        <div className="col-sm-6 float-left">
                            <h5>
                                {t('header:hi')}
                                {this.props.user && this.props.user.name && ' ' + this.props.user.name}
                                {this.props.user && !this.props.user.name && ' Classpedia Member'}
                                {this.props.school != null && this.props.school.school != null && this.props.school.school.name && ', Welcome to ' + this.props.school.school.name}
                                {this.props.school != null && this.props.school.school != null && !this.props.school.school.name && ', Welcome to Classpedia'}
                                {this.props.school != null && this.props.school.school == null && ', Welcome to Classpedia'}
                            </h5>
                        </div>
                        <div className="col-sm-6 float-left">
                            <ul className="list-inline header-right">
                                {this.props.school != null && this.props.school.user_type != UserType.SuperAdmin && (
                                    <Fragment>
                                        <LanguageSelector />
                                        {this.props.isAlumni == false && this.props.school.status == Status.ACTIVE && (
                                            <li className="search-container">
                                                <form>
                                                    {/* <input className="search" id="searchleft" type="search" placeholder="Search Here"/> */}
                                                    {/* <Input id="searchleft" className="search" name="search" placeholder="Search Here" type="search" value=""/> */}
                                                    <button className="btn button searchbutton head-button dropdown-toggle" type="button" data-toggle="collapse" data-target="#search-dropdown">
                                                        {SVG.search()}
                                                    </button>
                                                    <div className="dropdown-menu notif-pop main main-border-radius main-background chat-top collapse-body" aria-labelledby="search-dropdown" id="search-dropdown">
                                                        <div className="overflow">
                                                            <button type="button" id="search_close" className="close" data-dismiss="modal" data-toggle="collapse" data-target="#search-dropdown">
                                                                &times;
                                                            </button>
                                                            <span>{t('header:searchfilter')}</span>
                                                            <div className="search-filter">
                                                                <div className="search-list">
                                                                    <h6>{t('header:date')}</h6>
                                                                    <ul className="list-inline search-date clearfix">
                                                                        <li className={this.state.errors.from_date ? 'filter-date image-error col-md-6 float-left' : 'filter-date float-left'}>
                                                                            <DatePicker dateFormat="yyyy-MM-dd" className="form-control" placeholderText={t('fromdate')} selected={this.state.from_date} onChange={this._setFromDate} />
                                                                        </li>
                                                                        <li className={this.state.errors.from_date ? 'filter-date image-error col-md-6 float-left' : 'filter-date float-left end-date'}>
                                                                            <DatePicker dateFormat="yyyy-MM-dd" className="form-control" placeholderText={t('todate')} selected={this.state.to_date} onChange={this._setToDate} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="search-list">
                                                                    <h6>{t('header:posttype')}</h6>
                                                                    <ul className="list-inline post-type">{this.getActivity()}</ul>
                                                                </div>
                                                                <div className="search-list">
                                                                    <h6>{t('header:role')}</h6>
                                                                    <ul className="list-inline post-type">{this.getRole()}</ul>
                                                                </div>
                                                                <div className="clearfix filter-button">
                                                                    <button
                                                                        type="button"
                                                                        className="clear"
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            this.clearState()
                                                                        }}
                                                                    >
                                                                        {t('header:clearfilter')}
                                                                    </button>
                                                                    <button type="button" className="main-button float-right" onClick={this.gotoActivity}>
                                                                        {t('header:apply')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </li>
                                        )}
                                        {this.props.school.status == Status.ACTIVE && <Fragment>{AccessModule.checkSubAccess([AccessModule.CHAT], role) && access.includes(AccessModule.Module_Chat) && <ChatNotification />}</Fragment>}
                                        <Notification />
                                    </Fragment>
                                )}
                                {this.props.school != null && this.props.school.user_type == UserType.SuperAdmin && <Notification />}
                                <li>
                                    <span className="vertical-line"></span>
                                </li>
                                <li>
                                    <span className="date-month">{this.state.date}</span>
                                </li>
                                {this.props.school != null && <HeaderSetting />}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const Header = withTranslation()(connect(mapStateToProps)(HeaderComponent))

export { Header }
