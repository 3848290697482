import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractEnquiryViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import DatePicker from 'react-datepicker'
import * as Status from '~/helper/constant/status'
import { Button } from '~/components/form'
import moment from 'moment'
import { Loading } from '~/components/loader'
import $ from 'jquery'
class EnquiryViewComponent extends AbstractEnquiryViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setDate = this.setDate.bind(this)
        this.toggle = this.toggle.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    _onSave(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        $('#close_schedule_' + id).trigger('click')
        super._onSave(id)
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        super.toggle(link)
    }

    setDate(data) {
        super._handleChange('start_time', data)
    }

    getModal() {
        const modal = this.state.data.map((item, index) => {
            let text = 'Pending'
            if (item.status == Status.ACTIVE) {
                text = 'Complete'
            }
            return (
                <div key={index} id={'modal_enquiry_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>Warning !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">Are you sure you set demo as {text}?</p>
                                <p className="delete-file-name">"{item.institution + ' - ' + item.email}"</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text="Confirm" main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    getScheduleModal() {
        const modal = this.state.data.map((item, index) => {
            return (
                <div key={index} id={'modal_schedule_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span className="modal-title">Demo Request</span>
                        <br />
                        <div className="pop-main-content">
                            <div className="row clearfix">
                                <div className="col-sm-12">
                                    <div className="info-one">
                                        <span>Institution</span>
                                        <ins>{item.institution}</ins>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row clearfix">
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Name</span>
                                        <ins>{item.name}</ins>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Position</span>
                                        <ins>{item.position}</ins>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row clearfix">
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Email</span>
                                        <ins>{item.email}</ins>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Contact</span>
                                        <ins>{item.phone}</ins>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row clearfix">
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">State</span>
                                        <ins>{item.state}</ins>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Country</span>
                                        <ins>{item.country}</ins>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row clearfix">
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Timezone</span>
                                        <ins>{item.timezone}</ins>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="info-one">
                                        <span className="form-title">Verification</span>
                                        <ins>
                                            {item.verified == true && <span className="table-status table-green">Verified</span>}
                                            {item.verified == false && <span className="table-status table-green">Unverified</span>}
                                        </ins>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row clearfix">
                                {item.start_time == null && (
                                    <div className="col-sm-12 form-group">
                                        <label className="form-title">Schedule Demo</label>
                                        <label className="error text-danger">( Demo once scheduled cannot be changed )</label>
                                        <DatePicker showTimeSelect dateFormat="yyyy-MM-dd, h:mm aa" className="form-control" defaultValue={item.start_time} selected={this.state.start_time} onChange={this.setDate} />
                                        {this.state.errors.start_time && <label className="error text-danger">{this.state.errors.start_time}</label>}
                                    </div>
                                )}
                                {item.start_time != null && (
                                    <div className="col-sm-12 form-group">
                                        <div className="info-one">
                                            <span className="form-title">Schedule Demo</span>
                                            <ins>{moment(item.start_time).format('yyyy-MM-DD , h:mm a')}</ins>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="text-center form-group">
                                {item.start_time == null && <Button onClick={this._onSave} link={item.id} type="button" text="Schedule" main={true} />}
                                <button id={'close_schedule_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="enquiry" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.enquiries.view}>Enquiries</a>
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Enquiries:</h5>
                            </div>
                        </div>
                    </div>
                    <Table data={this.state.enquiries} />
                </div>
                {this.getModal()}
                {this.getScheduleModal()}
            </div>
        )
    }
}

const EnquiryView = connect(mapStateToProps)(EnquiryViewComponent)

export { EnquiryView }
