export default class SubjectTypeDTO {
    id: integer
    name: string
    code: string
    school_id: integer
    status: integer

    constructor(id, name, code, school_id, status) {
        this.id = id
        this.name = name
        this.code = code
        this.school_id = school_id
        this.status = status
    }
}
