import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addCourse, viewCourse, viewCourseProgress, viewCourseContent, getCourseModuleItems, viewCourseModuleItems, getCourseItems, getSchoolUser, getSchoolUserProfile, getCourseContentItems, getUserProgressByQuiz } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'
import { course_progress_constant } from '~/helper/constant/course_progress'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseInsightsUserQuizComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            courseCount: 0,
            activeTab: 0,
            loading: true,
            loading_text: 'Loading',
            courseModule: [],
            courseContent: [],
            courseModuleData: [],
            correctCount: 0,
            totalCount: 0,
            class_id: this.props.match.params.id,
            course_id: this.props.match.params.course_id,
            module_id: this.props.match.params.module_id,
            course_progress: [],
            course: [],
            course_content_meta: [],
            exam: [],
            classes: [],
            subject: [],
            user: [],
            givenAnswer: [],
            accessCount: 1,
            insight_details: [
                {
                    question: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:question',
                    },
                    given_answer: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:given_answer',
                    },
                    correct_answer: {
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'course:correct_answer',
                    },
                },
            ],
            course_details: [],
            selectedUser: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
        this.loadCourseContent = this.loadCourseContent.bind(this)
        this.loadCourseMeta = this.loadCourseMeta.bind(this)
        this.loadAnswers = this.loadAnswers.bind(this)
    }

    componentDidMount() {
        this.loadSubject()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadAnswers() {
        let insight_details = []
        let givenAnswer = this.state.givenAnswer
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        insight_details.push(this.state.insight_details[0])
        getUserProgressByQuiz(this.state.exam_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const data = response.data.onlineExam
                let correctCount = this.state.correctCount
                let totalCount = this.state.totalCount
                this.state.exam.map((obj, index) => {
                    if (obj.questions) {
                        obj.questions.map((ele, i) => {
                            const given_answer_id = data.filter((e) => e.question_id == ele.question[0].id)[0].answer_id
                            const given_answer = ele.question[0].answer.filter((e) => e.id == given_answer_id)
                            let answer = 'Not Answered'
                            if (given_answer.length > 0) {
                                if (given_answer_id == ele.question[0].answer[0].id) {
                                    correctCount++
                                    answer = <span className="correct-answer">{given_answer[0].answers}</span>
                                } else {
                                    answer = <span className="wrong-answer">{given_answer[0].answers}</span>
                                }
                            }
                            totalCount++
                            const tableData = {
                                question: {
                                    text: ele.question[0].questions,
                                },
                                given_answer: {
                                    text: answer,
                                },
                                correct_answer: {
                                    text: ele.question[0].answer[0].answers,
                                },
                            }
                            insight_details.push(tableData)
                        })
                    }
                })
                this.setState({
                    totalCount,
                    givenAnswer: data,
                    insight_details,
                    correctCount,
                })
            }
        })
    }

    loadCourseMeta() {
        let course_meta = []
        let timeline = []
        let exam = []
        let correctAnswers = []
        const course_content_meta = this.state.course_content_meta.sort((x, y) => x.timestamp - y.timestamp)
        course_content_meta.map((item, index) => {
            const data = item
            let action_type = null
            let value = null
            data['post_child'] = data.post_child
            if (data.questions && data.questions.length > 0) {
                if (this.state.exam_id == null) {
                    this.setState({
                        exam_id: data.questions[0]['exam_id'],
                    })
                }
            }
            exam.push(data)
        })
        this.setState(
            {
                exam,
            },
            () => {
                this.loadAnswers()
            }
        )
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                let course_details = []
                const course_progress = response.data.course_progress
                const userData = []
                let moduleCourse = []
                const user = this.state.user
                this.state.courseModule.content.map((obj, i) => {
                    let progress = course_progress.filter((el) => el.course_content_id == obj.id)
                    const feedback = progress.length > 0 ? progress[0].feedback : 'N/A'
                    progress = progress.length > 0 ? progress[0].user_progress : 'N/A'
                    progress = progress == 2 ? 'Completed' : 'In Progress'
                    moduleCourse.push(obj.id)
                })

                this.setState(
                    {
                        course_progress,
                        course_details: userData,
                    },
                    () => this.loadCourseContent()
                )
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseModuleItems(this.props.match.params.module_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                const course_progress = this.state.course_progress
                const courseContent = []
                let inProgressUsers = []
                let courseModuleData = []

                courseModule.content.map((obj, index) => {
                    if (obj.course_module_id == this.state.module_id) {
                        courseContent.push(obj)
                    }
                })
                this.setState(
                    {
                        courseContent,
                        courseModule,
                    },
                    () => this.loadCourseProgress()
                )
            }
        })
    }

    loadCourseContent() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseContentItems(this.props.match.params.course_content_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const isVideoContent = response.data.course_content.media.media_type.includes('video') ? 1 : 0
                this.setState(
                    {
                        isVideoContent,
                        course: response.data.course_content,
                        start_time: response.data.course_content.start_time,
                        coursetitle: response.data.course_content.name,
                        coursedescription: response.data.course_content.description,
                        media: response.data.course_content.media,
                        media_date: moment(response.data.course_content.created).format('Y-MM-D'),
                        media_name: response.data.course_content.media.name,
                        media_format: response.data.course_content.media.name.split('.').pop(),
                        media_url: response.data.course_content.media.media_url,
                        thumbnail_url: response.data.course_content.thumbnail ? response.data.course_content.thumbnail.media_url : null,
                        course_content_meta: response.data.course_content.meta,
                    },
                    () => {
                        {
                            this.loadCourseMeta()
                        }
                    }
                )
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseModule()
                        }
                    )
                }
            })
        } else {
            this.loadCourseModule()
        }
    }
}

withTranslation()(AbstractCourseInsightsUserQuizComponent)
export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
