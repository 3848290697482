import {Component} from 'react'
import {Dispatch} from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import moment from 'moment'
import { getAsset, updateAsset } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractAssetEditComponent extends Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state ={
            loading: false,
            loading_text:'Loading',
            assetId:'',
            name:'',
            amount:'',
            purchase_date: '',
            supported_date:'',
            description:'',
            assetElements:[],
            errors:{
                name:null,
                amount:null,
                purchase_date:null,
                supported_date:null,
            }
        }
        this._handleChange = this._handleChange.bind(this)
        this.onSave = this.onSave.bind(this)
    }
    componentDidMount(){
        this.loadAsset()
    }

    _handleChange(key, data){
        this.setState({[key]: data})
    }

    loadAsset(){
        const {t} = this.props
    this._handleChange('loading_text', 'Loading')
    this._handleChange('loading', true)
    getAsset(this.props.match.params.id, this.props.token).then((response)=>{
      this._handleChange('loading', false)
      if(response.code == API_SUCCESS){
        const item = response.data.asset
        this.setState({
            name: item.name,
            amount: item.amount,
            purchase_date:item.purchase_date,
            supported_date: item.supported_date,
            description:item.description,
            assetId:item.id
            
        })
        
      }
    })

    }

    onSave(e){
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({errors})
        const validate = Object.keys(errors).filter((item)=> errors [item] != null)
        if (validate.length == 0){
            const data={
                name: this.state.name,
                amount: this.state.amount,
                purchase_date:moment(this.state.purchase_date).format('YYYY-MM-DD'),
                supported_date:moment(this.state.supported_date).format('YYYY-MM-DD'),
                description:this.state.description,
            }
        this._handleChange("loading", true)
        updateAsset(this.state.assetId, data, this.props.token).then((response)=>{
            this._handleChange("loading", false)
            if(response.code == API_SUCCESS){
                toastMessage("success", response.message)
                redirect(route.admin.accounting.asset.view)
            }else{
                toastMessage('error', response.message)
                if(response.data){
                    if('errors' in response.data){
                        this.setState({errors:response.data.errors})
                    }
                }
            }
        })
        }

    }
}
export function mapStateToProps(state:Object){
    return {
        token: state.school.accounting_token,
        school:state.school,
    }
}