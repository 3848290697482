import React, { Component } from 'react'
import moment from 'moment'
import { viewBookCategories, addBooks, activeBookShelf, viewBooks } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { book_type } from '~/helper/constant/books.js'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'

export class AbstractBooksAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            publishedDate: moment().toDate(),
            addedDate: moment().toDate(),
            loading: false,
            title: '',
            isbn: '',
            author: '',
            edition: '',
            activeBookShelf: [],
            selectedActiveBookShelf: '',
            defaultActiveBookShelf: '',
            description: null,
            bookCategories: [],
            defaultBookCategories: '',
            selectedBookCategories: '',
            bookType: book_type,
            selectedBookType:'',
            defaultBookType: '',
            shelfElements: [],
            errors: {
                title: null,
                isbn:null,
                description: null,
                author: null,
                edition: null,
                addedDate: null,
                selectedBookType: null,
                selectedBookCategories: null,
            },
            shelfErrors: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadBookCategories()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadBookShelves() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        activeBookShelf(this.props.token).then((response) => {
            this._handleChange('loading', false)
            console.log(response)

            if (response.code === API_SUCCESS) {
                const activeBookShelf = response.data.shelf.map((item, index) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })

                this.setState({
                    activeBookShelf,
                })
            }
        })
    }

    loadBookCategories() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewBookCategories(this.props.token).then((response) => {
            this._handleChange('loading', false)

            if (response.code === API_SUCCESS) {
                const bookCategories = response.data.bookCategory.map((item, index) => {
                    return {
                        label: item.name + '-' + item.code,
                        value: item.id,
                    }
                })

                this.setState(
                    {
                        bookCategories,
                    },
                    () => {
                        this.loadBookShelves()
                    }
                )
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        let hasError = false

        const shelfErrors = this.state.shelfErrors
        const shelfElements = this.state.shelfElements
        shelfElements.map((el, i) => {
            if (el.shelf == '') {
                shelfErrors[i].shelf = 'Required'
                hasError = true
            }
            if (el.quantity == '') {
                shelfErrors[i].quantity = 'Required'
                hasError = true
            }
        })
        this.setState({
            shelfErrors,
        })
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                title: this.state.title,
                isbn: this.state.isbn,
                author: this.state.author,
                edition: this.state.edition,
                book_type: this.state.selectedBookType,
                book_categories_id: this.state.selectedBookCategories,
                description: this.state.description,
                // quantity:this.state.quantity,
                published_date: moment(this.state.publishedDate).format('yyyy-MM-DD'),
                added_date: moment(this.state.addedDate).format('yyyy-MM-DD'),
                bookShelf: shelfElements,
            }
            this._handleChange('loading', true)
            addBooks(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.library.books.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        schoolPackage: state.schoolPackage,
    }
}
