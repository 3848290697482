import React, { Fragment } from 'react'
import { Route } from 'react-router'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import { AuthenticatedRoute } from './guard'
import { Profile, Password, MySchool } from '~/views/setting'
import * as SharedComponent from '~/views/shared'
import { Block } from '~/views/blocked'

export const Authenticated = () => [
    <AuthenticatedRoute exact key="1" path={configRoute(route.my_school)} component={MySchool} />,
    <AuthenticatedRoute exact key="2" path={configRoute(route.block)} component={Block} />,
    <AuthenticatedRoute exact key="3" path={configRoute(route.notification) + '/:id'} component={SharedComponent.Notification} />,
    <AuthenticatedRoute exact key="4" path={configRoute(route.profile)} component={Profile} />,
    <AuthenticatedRoute exact key="5" path={configRoute(route.password)} component={Password} />,
]
