const userReducer = (state = null, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return action.user
        case 'PROFILE_UPDATE':
            return action.user
        case 'SIGN_OUT':
            return null
        case 'RESET':
            return null
        default:
            return state
    }
}

export default userReducer
