import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractPostReportViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import * as UserType from '~/helper/constant/user_type'
import { Loading } from '~/components/loader'
import { Button, Input, Checkbox, TextArea } from '~/components/form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import $ from 'jquery'
import * as ReportType from '~/helper/constant/report_type'
import { convertADtoBS } from '../../../../helper/function'
class PostReportViewComponent extends AbstractPostReportViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setEndDate = this._setEndDate.bind(this)
        this._setStartDate = this._setStartDate.bind(this)
        this.filterJob = this.filterJob.bind(this)
        this._deleteComment = this._deleteComment.bind(this)
        this._deletePost = this._deletePost.bind(this)
        this._toggleUserStatus = this._toggleUserStatus.bind(this)
    }

    _toggleUserStatus(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        super._toggleUserStatus(id)
        $('#user_close_' + id).trigger('click')
    }

    _deletePost(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        super._deletePost(id)
        $('#post_close_' + id).trigger('click')
    }

    _deleteComment(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        super._deleteComment(id)
        $('#comment_close_' + id).trigger('click')
    }

    _setEndDate(data) {
        this._handleChange('end_date', data)
    }
    _setStartDate(data) {
        this._handleChange('start_date', data)
    }

    filterJob(e) {
        e.preventDefault()
        this.loadReport().then((response) => {
            if (response) {
                $('#filter_close').trigger('click')
            }
        })
    }

    getFilter() {
        const { t } = this.props
        return (
            <div id="modal_filter" className="modal fade show" role="dialog">
                <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('todo:filterbyduedate')}</span>
                    </div>
                    <br />
                    <form className="p-0">
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('fromdate')}</label>
                                        <DatePicker placeholderText={t('common:selectFromDate')} dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.start_date} selected={this.state.start_date} onChange={this._setStartDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.start_date != null ? (convertADtoBS(this.state.start_date) != null ? convertADtoBS(this.state.start_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12 float-left form-group">
                                        <label className="form-title">{t('todate')}</label>
                                        <DatePicker placeholderText="Select To Date" dateFormat="yyyy-MM-dd" className="form-control" defaultValue={this.state.end_date} selected={this.state.end_date} onChange={this._setEndDate} />
                                        {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.end_date != null ? (convertADtoBS(this.state.end_date) != null ? convertADtoBS(this.state.end_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                        {this.state.filter_error && <label className="error text-danger">{this.state.filter_error}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.filterJob} type="submit" text={t('todo:filter')} main={true} />
                                <button id="filter_close" type="button" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getReport(report) {
        const modal = report.map((item, index) => {
            const name = item.user_school.user.name != null ? item.user_school.user.name : item.user_school.user.reg_no
            const profile = item.user_school.user.user_detail != null ? (item.user_school.user.user_detail.profile != null ? item.user_school.user.user_detail.profile : null) : null
            const report_type = ReportType.all.find((report) => {
                return report.value == item.type
            })

            return (
                <div key={index} className="comment-list">
                    <div className="comment-img">
                        {profile != null && <img src={profile} alt="post-user" />}
                        {profile == null && <span>{name.charAt(0)}</span>}
                    </div>
                    <p>
                        <ins className="comment-title">{name}</ins>
                        {report_type != null ? report_type.label : 'Others'}
                        <br />
                        {item.remarks}
                    </p>
                </div>
            )
        })
        return modal
    }

    getPostModal() {
        const { t } = this.props
        const modal = this.state.postList.map((item) => {
            return (
                <div id={'modal_post_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>
                                {t('alumni:report')} <small>( {item.report_count} )</small>
                            </span>
                        </div>
                        <br />
                        <form className="p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">{this.getReport(item.report)}</div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._deletePost} link={item.id} type="submit" text={t('common:DeleteBtn')} main={true} />
                                    <button id={'post_close_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        })
        return modal
    }

    getCommentModal() {
        const { t } = this.props
        const modal = this.state.commentList.map((item) => {
            return (
                <div id={'modal_comment_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>
                                {t('alumni:reports')} <small>( {item.report_count} )</small>
                            </span>
                        </div>
                        <br />
                        <form className="p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">{this.getReport(item.report)}</div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._deleteComment} link={item.id} type="submit" text={t('common:DeleteBtn')} main={true} />
                                    <button id={'comment_close_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        })
        return modal
    }

    getUserModal() {
        const { t } = this.props
        const modal = this.state.userList.map((item) => {
            return (
                <div id={'modal_user_' + item.id} className="modal fade show" role="dialog">
                    <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>
                                {t('alumni:reports')} <small>( {item.report_count} )</small>
                            </span>
                        </div>
                        <br />
                        <form className="p-0">
                            <div className="modal-content">
                                <div className="pop-main-content">{this.getReport(item.report)}</div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center">
                                    <Button onClick={this._toggleUserStatus} link={item.id} type="submit" text={t('setting:block')} main={true} />
                                    <button id={'user_close_' + item.id} type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal cancel')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        })
        return modal
    }

    render() {
        const { t, school } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="report" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>{t('alumni:postMod')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('alumni:postMod')}:</h5>
                                <small className="small-main-title">
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && (
                                        <Fragment>
                                            {convertADtoBS(this.state.start_date) != null ? convertADtoBS(this.state.start_date) + ' BS' : 'Invalid Date Range'}
                                            &nbsp; to &nbsp;
                                            {convertADtoBS(this.state.end_date) != null ? convertADtoBS(this.state.end_date) + ' BS' : 'Invalid Date Range'}
                                        </Fragment>
                                    )}
                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'AD' && (
                                        <Fragment>
                                            {moment(this.state.start_date).format('D MMM')}
                                            &nbsp; - &nbsp;
                                            {moment(this.state.end_date).format('D MMM')}
                                        </Fragment>
                                    )}
                                </small>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <Button type="button" text={t('todo:filter')} modal="modal_filter" main={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class={this.state.page == 0 ? 'nav-link active' : 'nav-link'} onClick={(e) => this.setState({ page: 0 })}>
                                {t('activity:post')}
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class={this.state.page == 1 ? 'nav-link active' : 'nav-link'} onClick={(e) => this.setState({ page: 1 })}>
                                {t('activity:comment')}
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class={this.state.page == 2 ? 'nav-link active' : 'nav-link'} onClick={(e) => this.setState({ page: 2 })}>
                                {t('sidebar:users')}
                            </button>
                        </li>
                    </ul>

                    {this.state.page == 0 && <Table data={this.state.post_moderator} />}
                    {this.state.page == 1 && <Table data={this.state.comment_moderator} />}
                    {this.state.page == 2 && <Table data={this.state.user_moderator} />}
                </div>
                {this.getFilter()}
                {this.getPostModal()}
                {this.getCommentModal()}
                {this.getUserModal()}
            </div>
        )
    }
}

const PostReportView = withTranslation()(connect(mapStateToProps)(PostReportViewComponent))

export { PostReportView }
