import React from 'react'
import * as AccessModule from '~/helper/constant/modules'
import * as route from '~/helper/constant/route'
import { configRoute } from '~/helper/function'
import * as AdminComponent from '~/views/admin'
import * as SharedComponent from '~/views/shared'
import { AdministrationRoute } from './guard'

export const Administration = () => [
    <AdministrationRoute
        exact
        key="1"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.EXAM}
        path={configRoute(route.examination.exam.routine.menu + '/:id')}
        component={SharedComponent.RoutineMenu}
    />,
    <AdministrationRoute
        exact
        key="2"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.RESULT}
        path={configRoute(route.examination.result.menu + '/:id')}
        component={SharedComponent.ResultMenu}
    />,
    <AdministrationRoute
        exact
        key="3"
        access={[AccessModule.Module_Exam_Management]}
        subAccess={AccessModule.RESULT}
        path={configRoute(route.examination.result.assign + '/:classId' + '/:sectionId' + '/:routineId')}
        component={SharedComponent.ResultAssign}
    />,

    /* Attendance */
    <AdministrationRoute
        exact
        key="4"
        access={[AccessModule.Module_Student_Management, AccessModule.Module_Employee_Management]}
        path={configRoute(route.admin.attendance.menu)}
        component={AdminComponent.Attendance}
    />,
    /* Course  */
    <AdministrationRoute
        exact
        key="5"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.menu)}
        component={SharedComponent.CourseMenu}
    />,
    <AdministrationRoute
        exact
        key="6"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.view + '/:id')}
        component={SharedComponent.CourseView}
    />,
    <AdministrationRoute
        exact
        key="7"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.edit + '/:id' + '/:course_id')}
        component={SharedComponent.CourseEdit}
    />,
    <AdministrationRoute
        exact
        key="8"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.module + '/:id' + '/:course_id')}
        component={SharedComponent.CourseModule}
    />,
    <AdministrationRoute
        exact
        key="9"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.insights + '/:id' + '/:course_id')}
        component={SharedComponent.CourseInsights}
    />,
    <AdministrationRoute
        exact
        key="10"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.insights_details + '/:id' + '/:course_id' + '/:module_id')}
        component={SharedComponent.CourseInsightsDetails}
    />,
    <AdministrationRoute
        exact
        key="11"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.insights_user + '/:id' + '/:course_id' + '/:module_id' + '/:user_id')}
        component={SharedComponent.CourseInsightsUserDetails}
    />,
    <AdministrationRoute
        exact
        key="12"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(
            route.course.insights_quiz + '/:id' + '/:course_id' + '/:module_id' + '/:course_content_id' + '/:user_id'
        )}
        component={SharedComponent.CourseInsightsUserQuiz}
    />,
    <AdministrationRoute
        exact
        key="13"
        access={[AccessModule.Module_Course_Management]}
        subAccess={AccessModule.COURSE}
        path={configRoute(route.course.manage + '/:course_id' + '/:course_content_id')}
        component={SharedComponent.CourseManage}
    />,

    <AdministrationRoute
        exact
        key="14"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.teacher.salary.view)}
        component={AdminComponent.BillView}
    />,
    <AdministrationRoute
        exact
        key="15"
        access={[AccessModule.Module_Accounting]}
        subAccess={AccessModule.EXPENSE}
        path={configRoute(route.teacher.salary.detail + '/:id')}
        component={AdminComponent.BillDetails}
    />,
]
