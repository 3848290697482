import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewChartOfAccount, viewLedgerSummary } from '../../../../../services/api/accounting/doubleentry'
type Props = {
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractLedgerSummaryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            start_date: moment().startOf('month').toDate(),
            end_date: moment().endOf('month').toDate(),
            account: null,
            duration: null,
            selectedAccount: null,
            chartOfAccounts: [],
            errors: {
                start_date: null,
                end_date: null,
                account: null,
            },
            totalAmount: {
                balance: 0.0,
                credit: 0.0,
                debit: 0.0,
            },
            journalItems: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }
    componentDidMount() {
        this.loadChartOfAccount()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadChartOfAccount() {
        this._handleChange('loading', true)
        viewChartOfAccount(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const assetsChildren = response.data.chartAccounts.Assets.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const liabilitiesChildren = response.data.chartAccounts.Liabilities.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const expenseChildren = response.data.chartAccounts.Expenses.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const incomeChildren = response.data.chartAccounts.Income.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const equityChildren = response.data.chartAccounts.Equity.map((item, index) => {
                    return {
                        label: item.code + ' - ' + item.name,
                        value: item.id,
                    }
                })
                const chartOfAccounts = [
                    {
                        label: 'Assets',
                        options: assetsChildren,
                    },
                    {
                        label: 'Liabilities',
                        options: liabilitiesChildren,
                    },
                    {
                        label: 'Expense',
                        options: expenseChildren,
                    },
                    {
                        label: 'Income',
                        options: incomeChildren,
                    },
                    {
                        label: 'Equity',
                        options: equityChildren,
                    },
                ]
                let account = null
                if (assetsChildren.length > 0) {
                    account = assetsChildren[0]
                } else if (liabilitiesChildren.length > 0) {
                    account = liabilitiesChildren[0]
                } else if (expenseChildren.length > 0) {
                    account = expenseChildren[0]
                } else if (incomeChildren.length > 0) {
                    account = incomeChildren[0]
                } else if (equityChildren.length > 0) {
                    account = equityChildren[0]
                }
                this.setState(
                    {
                        chartOfAccounts,
                        account,
                    },
                    () => {
                        this._onSearch()
                    }
                )
            }
        })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const start_date =
                convertADtoBS(this.state.start_date) != null
                    ? convertADtoBS(this.state.start_date) + ' BS'
                    : 'Invalid Date Range'
            const end_date =
                convertADtoBS(this.state.end_date) != null
                    ? convertADtoBS(this.state.end_date) + ' BS'
                    : 'Invalid Date Range'

            this.setState({
                duration: start_date + ' || ' + end_date,
                selectedAccount: this.state.account != null ? this.state.account.label : '',
            })
        } else {
            const start_date = moment(this.state.start_date).format('yyyy-MM-DD')
            const end_date = moment(this.state.end_date).format('yyyy-MM-DD')
            this.setState({
                duration: start_date + ' || ' + end_date,
                selectedAccount: this.state.account != null ? this.state.account.label : '',
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const account = this.state.account.value
            const start_date = moment(this.state.start_date).format('yyyy-MM-DD')
            const end_date = moment(this.state.end_date).format('yyyy-MM-DD')
            viewLedgerSummary(start_date, end_date, account, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            journalItems: response.data.journalItems,
                            totalAmount: response.data.filter,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.school.accounting_token,
        school: state.school.school,
    }
}
