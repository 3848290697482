export const timetable_color = ['small-green', 'small-green1', 'small-blue', 'small-blue1']

export const calendar_color = [
    ['calendar-event', 'td-red'],
    ['calendar-event', 'td-blue'],
    ['calendar-event', 'td-blue1'],
    ['calendar-event', 'td-red'],
    ['calendar-event', 'td-blue'],
    ['calendar-event', 'td-blue1'],
]

export const calendar_color_home = ['calendar-event td-event', 'calendar-event td-holiday', 'calendar-event td-homework', 'calendar-event td-exam']

export const date_color = ['holiday', 'event']

export const dashboard = ['bg-green2', 'bg-purple2', 'bg-orange2', 'bg-blue1', 'bg-pink1', 'bg-orange1']

export const circle = ['name-color1', 'name-color2', 'name-color3']
