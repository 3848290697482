import { get, post } from '../request'

export const viewFeeSchedule = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addFeeSchedule = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/bill-schedule/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editFeeSchedule = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/bill-schedule/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFeeSchedule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getByClass = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule/class/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getScheduleByUser = (classId, userId, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule/user/' + classId + '/' + userId, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteFeeSchedule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule/delete/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleFeeSchedule = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-schedule/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
