import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getRecentDetailChat, getChatCount } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { redirect } from '~/helper/function/util'
import { Connection } from '~/services/socket'
import * as MessageType from '~/helper/constant/message_type'

const socket = new Connection()

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    recentList: Array,
    count: Integer,
    response: Object,
}

export class AbstractChatNotificationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            recentList: [],
            count: 0,
            response: '',
        }
    }

    componentDidUpdate(prevState) {
        const response = this.state.response
        if (prevState.response != null && response != null && prevState.response.data.id != response.data.id) {
            if (response.type == MessageType.DELETE_GROUP_CHAT_ROOM || response.type == MessageType.SEND_MESSAGE || response.type == MessageType.EDIT_MESSAGE || response.type == MessageType.DELETE_MESSAGE) {
                this.loadCount()
                this.loadRecentChat()
            }
        }
    }

    componentDidMount() {
        this.loadCount()
        this.loadRecentChat()
        socket.receive('response', this)
    }

    loadCount() {
        getChatCount(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                this.setState({
                    count: response.data.chat_count,
                })
            }
        })
    }

    loadRecentChat() {
        getRecentDetailChat(5, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                this.setState({
                    recentList: response.data.chat,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
        user: state.user,
    }
}
