import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { Routing } from '~/services/routing'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import '~/assets/css/login.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-tagsinput/react-tagsinput.css'
import '~/assets/css/style.css'
import { script } from '~/assets/script/script.js'
import { Loading } from '~/components/loader'
import { AbstractAppComponent, mapStateToProps } from './abstract'
import { messaging } from '~/firebaseMessaging'

const routing = new Routing()

class AppComponent extends AbstractAppComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        script()
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/firebase-messaging-sw.js')
                .then(function (registration) {})
                .catch(function (err) {})
        }
    }

    render() {
        return <Suspense fallback={<Loading status={true} text="Loading" />}>{routing.getRoute()}</Suspense>
    }
}

const App = connect(mapStateToProps)(AppComponent)

export { App }
