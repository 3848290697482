import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass, activeSection, activeSubject } from '~/services/api'
import { SubjectDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as validation from '~/helper/constant/validation'
import * as UserType from '~/helper/constant/user_type'
import { redirect } from '~/helper/function/util'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    classAry: Array,
    data: Array,
    selectedClass: String,
    errors: Object,
}

export class AbstractStudentAtttendanceMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            data: [],
            classAry: [],
            subjectAry: [],
            sectionAry: [],
            attendance_type: [
                { value: 0, label: 'Subject Wise', translate: 'attendance:subjectWise' },
                { value: 1, label: 'General', translate: 'attendance:general' },
            ],
            errors: {
                selectedClass: null,
                selectedSection: null,
                selectedAttendanceType: null,
                date: null,
            },
            defaultAttendanceType: { value: 0, label: 'Subject Wise' },
            selectedType: 0,
            defaultClass: Object,
            defaultSection: Object,
            defaultSubject: Object,
            selectedAttendanceType: 0,
            selectedClass: null,
            selectedSection: null,
            selectedSubject: null,
            selectedSubjectError: null,
            error: false,
            date: moment().toDate(),
        }
        this._handleChange = this._handleChange.bind(this)
        this.getURL = this.getURL.bind(this)
    }

    componentDidMount() {
        this.setState({
            defaultAttendanceType: this.state.attendance_type[0],
        })
        this.loadClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    getURL() {
        const { t } = this.props
        if (this.state.selectedClass == null) {
            this.setState({ error: true })
        } else {
            if (this.state.selectedAttendanceType == 0 && this.state.selectedSubject == '') {
                this.setState({
                    selectedSubjectError: 'Required',
                })
                return
            }
            const errors = validation.required(Object.keys(this.state.errors), this.state)
            this.setState({ errors })
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                let selectedSubject = 0
                let attendanceType = 'general'
                if (this.state.selectedAttendanceType == 0) {
                    selectedSubject = this.state.selectedSubject
                    attendanceType = 'subject'
                }
                if (this.props.school.user_type == UserType.Admin) {
                    redirect(route.admin.attendance.student.view + '/' + attendanceType + '/' + this.state.selectedSection + '/' + selectedSubject + '/' + moment(this.state.date).format('yyyy-MM-DD'), this.props.history)
                } else {
                    redirect(route.teacher.attendance.view + '/' + attendanceType + '/' + this.state.selectedSection + '/' + selectedSubject + '/' + moment(this.state.date).format('yyyy-MM-DD'), this.props.history)
                }
            }
        }
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classAry = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classAry.push({
                        value: item.id,
                        label: item.name + ' - ' + item.code,
                    })
                })
                this._handleChange('data', response.data.class)
                this._handleChange('classAry', classAry)
                this._handleChange('defaultClass', classAry[0])
                this._handleChange('selectedClass', classAry[0].value)
                this.loadSection(classAry[0].value)
                this.loadSubject(classAry[0].value)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadSection(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let sectionAry = []
        classes.active_section.map((item, index) => {
            sectionAry.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        if (sectionAry.length > 0) {
            this._handleChange('sectionAry', sectionAry)
            this._handleChange('defaultSection', sectionAry[0])
            this._handleChange('selectedSection', sectionAry[0].value)
        } else {
            this._handleChange('sectionAry', sectionAry)
            this._handleChange('defaultSection', '')
            this._handleChange('selectedSection', '')
        }
    }

    loadSubject(class_id) {
        const classes = this.state.data.find((item) => item.id == class_id)
        let subjectAry = []
        classes.subject.map((item, index) => {
            subjectAry.push({ value: item.id, label: item.name + ' - ' + item.code })
        })
        if (subjectAry.length > 0) {
            this._handleChange('subjectAry', subjectAry)
            this._handleChange('defaultSubject', subjectAry[0])
            this._handleChange('selectedSubject', subjectAry[0].value)
        } else {
            this._handleChange('subjectAry', subjectAry)
            this._handleChange('defaultSubject', '')
            this._handleChange('selectedSubject', '')
        }
    }
}
const AbstractReport = withTranslation()(AbstractStudentAtttendanceMenuComponent)

export { AbstractReport }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
