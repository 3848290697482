import moment from 'moment'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { blood_group } from '~/helper/constant/blood_group'
import { country } from '~/helper/constant/country'
import { gender } from '~/helper/constant/gender'
import * as QualificationType from '~/helper/constant/qualification_type'
import * as UserType from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { toastMessage } from '~/helper/function/util'
import { getDocuments, saveProfile, viewAlumniCategory } from '~/services/api'
import { profile_update } from '~/store'

type Props = {
    user: Object,
    school: Object,
    token: String,
    student: Integer,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    loading_text: String,
    name: String,
    contact: String,
    father_name: String,
    mother_name: String,
    gender: String,
    country: String,
    defaultGender: Object,
    defaultCountry: Object,
    permanent_address: String,
    temporary_address: String,
    blood_group: String,
    defaultBloodGroup: Object,
    user: Object,
    loading: Boolean,
    errors: Object,
    media: Array,
    deletedMedia: Array,
    uploads: Array,
    uploadedMedia: Array,
    profile: Object,
    profileBlob: Array,
}
export class AbstractProfileComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            loading: false,
            editPage: false,
            name: this.props.user ? this.props.user.name : null,
            contact: this.props.user ? this.props.user.contact : null,
            user: this.props.user ? this.props.user : null,
            father_name: this.props.user.user_detail
                ? this.props.user.user_detail.father_name != null
                    ? this.props.user.user_detail.father_name
                    : ''
                : '',
            mother_name: this.props.user.user_detail
                ? this.props.user.user_detail.mother_name != null
                    ? this.props.user.user_detail.mother_name
                    : ''
                : '',
            gender: this.props.user.user_detail
                ? this.props.user.user_detail.gender != null
                    ? this.props.user.user_detail.gender
                    : ''
                : '',
            defaultGender: this.props.user.user_detail
                ? this.props.user.user_detail.gender != null
                    ? gender.find((item) => item.value == this.props.user.user_detail.gender)
                    : ''
                : '',
            country: this.props.user.user_detail
                ? this.props.user.user_detail.nationality != null
                    ? this.props.user.user_detail.nationality
                    : ''
                : '',
            defaultCountry: this.props.user.user_detail
                ? this.props.user.user_detail.nationality != null
                    ? country.find((item) => item.value == this.props.user.user_detail.nationality)
                    : ''
                : '',
            permanent_address: this.props.user.user_detail
                ? this.props.user.user_detail.permanent_address != null
                    ? this.props.user.user_detail.permanent_address
                    : ''
                : '',
            temporary_address: this.props.user.user_detail
                ? this.props.user.user_detail.temporary_address != null
                    ? this.props.user.user_detail.temporary_address
                    : ''
                : '',
            blood_group: this.props.user.user_detail
                ? this.props.user.user_detail.blood_group != null
                    ? this.props.user.user_detail.blood_group
                    : ''
                : '',
            defaultBloodGroup: this.props.user.user_detail
                ? this.props.user.user_detail.blood_group != null
                    ? blood_group.find((item) => item.value == this.props.user.user_detail.blood_group)
                    : ''
                : '',
            uploadedMedia: [],
            media: [],
            uploads: [],
            deletedMedia: [],
            profile: {},
            profileBlob: [],
            qualification: [],
            experience: [],
            groupList: [],
            designationList: [],
            companyList: [],
            alumni_group_id: null,
            alumni_company_id: null,
            alumni_designation_id: null,
            selectedGroup: null,
            selectedCompany: null,
            selectedDesignation: null,
            errors: {
                name: null,
                contact: null,
                father_name: null,
                mother_name: null,
                gender: null,
                country: null,
                permanent_address: null,
            },
        }
        this._onSave = this._onSave.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this.getDocuments = this.getDocuments.bind(this)
        this.addQualification = this.addQualification.bind(this)
        this.removeQualification = this.removeQualification.bind(this)
        this.addExperience = this.addExperience.bind(this)
        this.removeExperience = this.removeExperience.bind(this)
        this.setQualificationFromDate = this.setQualificationFromDate.bind(this)
        this.setQualificationToDate = this.setQualificationToDate.bind(this)
        this.setQualificationDescription = this.setQualificationDescription.bind(this)
        this.setExperienceFromDate = this.setExperienceFromDate.bind(this)
        this.setExperienceToDate = this.setExperienceToDate.bind(this)
        this.setExperienceDescription = this.setExperienceDescription.bind(this)
        this.setExperienceIsPresent = this.setExperienceIsPresent.bind(this)
        this.setExperienceCompany = this.setExperienceCompany.bind(this)
        this.togglePage = this.togglePage.bind(this)
    }

    togglePage() {
        this.setState({
            editPage: !this.state.editPage,
        })
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    loadAlumniCategory() {
        this._handleChange('loading', true)
        viewAlumniCategory(this.props.school.school_id).then((response) => {
            this._handleChange('loading', false)
            let groupList = []
            let designationList = []
            let companyList = []
            if (response.code == API_SUCCESS) {
                const alumni_category = response.data.alumni_category
                alumni_category.map((item) => {
                    let category = {}
                    category['value'] = item.id
                    category['label'] = item.name
                    if (item.type == ALUMNI_CATEGORY.Alumni_Group) {
                        groupList.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Company) {
                        companyList.push(category)
                    } else if (item.type == ALUMNI_CATEGORY.Alumni_Designation) {
                        designationList.push(category)
                    }
                })
            }

            this.setState(
                {
                    groupList,
                    designationList,
                    companyList,
                },
                () => {
                    this.getDocuments()
                }
            )
        })
    }

    setQualificationFromDate(i, value) {
        const qualification = []
        this.state.qualification.map((item, index) => {
            if (index == i) {
                item.from = value
            }
            qualification.push(item)
        })
        this.setState({
            qualification,
        })
    }
    setQualificationToDate(i, value) {
        const qualification = []
        this.state.qualification.map((item, index) => {
            if (index == i) {
                item.to = value
            }
            qualification.push(item)
        })
        this.setState({
            qualification,
        })
    }
    setQualificationDescription(i, value) {
        const qualification = []
        this.state.qualification.map((item, index) => {
            if (index == i) {
                item.description = value
            }
            qualification.push(item)
        })
        this.setState({
            qualification,
        })
    }

    setExperienceFromDate(i, value) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (index == i) {
                item.from = value
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }
    setExperienceToDate(i, value) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (index == i) {
                item.to = value
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }
    setExperienceDescription(i, value) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (index == i) {
                item.description = value
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }

    setExperienceIsPresent(i, value) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (value == true) {
                item.isPresent = false
            }
            if (index == i) {
                item.to = ''
                item.isPresent = value
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }

    setExperienceCompany(i, value) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (index == i) {
                item.company = value
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }

    addQualification() {
        const qualificationData = {
            id: null,
            from: '',
            to: '',
            description: '',
            company: '',
            isPresent: false,
            deleted: false,
        }
        const qualification = this.state.qualification
        qualification.push(qualificationData)
        this.setState({
            qualification,
        })
    }

    removeQualification(i) {
        const qualification = []
        this.state.qualification.map((item, index) => {
            if (index == i) {
                item.deleted = true
            }
            qualification.push(item)
        })
        this.setState({
            qualification,
        })
    }

    addExperience() {
        const qualificationData = {
            id: null,
            from: '',
            to: '',
            description: '',
            company: '',
            isPresent: false,
            deleted: false,
        }
        const experience = this.state.experience
        experience.push(qualificationData)
        this.setState({
            experience,
        })
    }

    removeExperience(i) {
        const experience = []
        this.state.experience.map((item, index) => {
            if (index == i) {
                item.deleted = true
            }
            experience.push(item)
        })
        this.setState({
            experience,
        })
    }

    getDocuments() {
        this._handleChange('loading', true)
        this._handleChange('loading_text', 'Loading')
        getDocuments(this.props.token).then((response) => {
            this._handleChange('loading', false)
            this._handleChange('loaded', true)
            if (response.code == API_SUCCESS) {
                const qualification = []
                const experience = []
                const userSchool = response.data.user.user_school.find(
                    (item) => item.school_id == this.props.school.school_id
                )

                if (userSchool != null && userSchool.alumni != null) {
                    const alumni = userSchool.alumni
                    let alumni_company_id = null
                    let alumni_designation_id = null
                    let alumni_group_id = null
                    let selectedGroup = null
                    let selectedCompany = null
                    let selectedDesignation = null
                    this.state.companyList.map((item) => {
                        if (item.value == alumni.alumni_company_id) {
                            alumni_company_id = alumni.alumni_company_id
                            selectedCompany = item
                        }
                    })
                    this.state.groupList.map((item) => {
                        if (item.value == alumni.alumni_group_id) {
                            alumni_group_id = alumni.alumni_group_id
                            selectedGroup = item
                        }
                    })
                    this.state.designationList.map((item) => {
                        if (item.value == alumni.alumni_designation_id) {
                            alumni_designation_id = alumni.alumni_designation_id
                            selectedDesignation = item
                        }
                    })
                    this.setState({
                        alumni_company_id,
                        alumni_designation_id,
                        alumni_group_id,
                        selectedGroup,
                        selectedCompany,
                        selectedDesignation,
                    })
                }
                response.data.user.user_qualification
                    .filter((item) => item.type == QualificationType.Qualification)
                    .map((item) => {
                        const data = {
                            id: item.id,
                            from: item.from != null ? moment(item.from).toDate() : '',
                            to: item.to != null ? moment(item.to).toDate() : '',
                            description: item.description,
                            company: item.company,
                            isPresent: item.isPresent,
                            deleted: false,
                        }
                        qualification.push(data)
                    })
                response.data.user.user_qualification
                    .filter((item) => item.type == QualificationType.Experience)
                    .map((item) => {
                        const data = {
                            id: item.id,
                            from: item.from != null ? moment(item.from).toDate() : '',
                            to: item.to != null ? moment(item.to).toDate() : '',
                            description: item.description,
                            company: item.company,
                            isPresent: item.isPresent,
                            deleted: false,
                        }
                        experience.push(data)
                    })
                this.setState(
                    {
                        qualification,
                        experience,
                    },
                    () => {}
                )

                if (response.data.user.user_detail.gallery) {
                    if (response.data.user.user_detail.gallery.media) {
                        const images = response.data.user.user_detail.gallery.media.find((item) =>
                            item.media_type.includes('data:image/')
                        )
                        const documents = response.data.user.user_detail.gallery.media.filter(
                            (item) => !item.media_type.includes('data:image/')
                        )
                        this._handleChange('uploadedMedia', documents)
                        const profileBlob = []
                        if (images != null) {
                            const profile = {
                                0: images.media_type + ';base64,' + images.media_data,
                            }
                            profileBlob.push(images)
                            this._handleChange('profile', profile)
                            this._handleChange('profileBlob', profileBlob)
                        }
                    }
                }
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        if (this.props.school.user_type == UserType.Alumni) {
            if (
                this.state.alumni_company_id == null ||
                this.state.alumni_designation_id == null ||
                this.state.alumni_group_id == null
            ) {
                toastMessage('error', 'Please fill your current organization detail')
                return
            }
        }
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                contact: this.state.contact,
                father_name: this.state.father_name,
                mother_name: this.state.mother_name,
                gender: this.state.gender,
                nationality: this.state.country,
                permanent_address: this.state.permanent_address,
                temporary_address: this.state.temporary_address,
                blood_group: this.state.blood_group,
                media: this.state.media,
                deletedMedia: this.state.deletedMedia,
                profile: this.state.profile,
                qualification: this.state.qualification,
                experience: this.state.experience,
                alumni_company_id: this.state.alumni_company_id,
                alumni_group_id: this.state.alumni_group_id,
                alumni_designation_id: this.state.alumni_designation_id,
            }
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            saveProfile(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    this.getDocuments()
                    this.togglePage()
                    this.props.dispatch(profile_update(response.data))
                    this.setState({
                        user: response.data.user,
                        media: [],
                        uploads: [],
                        deletedMedia: [],
                    })
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        user: state.user,
        school: state.school,
        student: state.student,
        token: state.token,
    }
}
