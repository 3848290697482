import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractGradingViewComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Button } from '~/components/form'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as SVG from '~/assets/svg'
import { Table } from '~/components/table'
import * as Status from '~/helper/constant/status'
import {Loading} from '~/components/loader'

class GradingViewComponent extends AbstractGradingViewComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStatusToggleModal = this.getStatusToggleModal.bind(this)
    }
    getStatusToggleModal(){
        const {t} = this.props
        const modal = this.state.gradingData.map((item, index)=>{
            let text = t('fee:disable')
            if (item.status != Status.ACTIVE){
                text = t('fee:enable')
            }
            return(
                <div key={index} id={'modal_class_' + index} className="modal fade show" role="dialog">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <span>{t('common:warning')} !!!</span>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">
                                    {t('calendar:event.areyousureyouwantto')} {text + ' ' + item.title}?"
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <Button onClick={this.toggle} link={item.id} type="button" text={t('common:modal confirm')} main={true} />
                                <button id={'close_' + item.id} type="upload" className="cancel-button" data-dismiss="modal">
                                    {t('common:modal cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return modal
    }


    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="setting" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.setting.menu}>{t('common:settings')} &nbsp; &gt; </a>
                            </li>
                            <li>{t('grading:grading')}</li>
                        </ul>

                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('grading:grading')} :</h5>
                            </div>
                            <div className="col-md-7 calendar-module float-left text-right">
                                <div className="display-inline-block">
                                    <a href={route.admin.setting.grading.add} className="main-button">
                                        {t('common:addBtn')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                      <Table data={this.state.gradingStructure} />
                        
                        <br />
                        
                    </div>
                    {this.getStatusToggleModal()}
                </div>
            </div>
        )
    }
}

const GradingView = withTranslation()(connect(mapStateToProps)(GradingViewComponent))

export {GradingView}


