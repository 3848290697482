import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractPasswordComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { Input, Button } from '~/components/form'
import { Loading } from '~/components/loader'
import { withTranslation } from 'react-i18next'

class PasswordComponent extends AbstractPasswordComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        let check = true
        if (this.props.school == null) {
            check = false
        } else if (this.props.school.active_user_academics == null && this.props.school.user_type == UserType.Student) {
            check = false
        } else if (this.props.student == null && this.props.school.user_type == UserType.Parents) {
            check = false
        } else if (this.props.school.status != Status.ACTIVE) {
            check = false
        } else if (this.props.school.school != null && this.props.school.school.setup == false) {
            check = false
        } else {
            check = true
        }
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                {check == true && <Sidebar id="" />}
                <div className={check == true ? 'main-content' : 'main-content setup-main'}>
                    {check == true && <Header />}
                    {check == false && <Header className="setup-nav" hideToggle={true} />}
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            {check == true && (
                                <li>
                                    <a href={route.activity}>{t('setting:dashboard')} &gt; </a>
                                </li>
                            )}
                            <li>{t('header:changepassword')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('header:changepassword')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('setting:password:newpassword')} *</label>
                                    <Input
                                        name="password"
                                        onChange={this._handleChange}
                                        placeholder={t('setting:password:newpassword')}
                                        type="password"
                                        value={this.state.password}
                                    />
                                    {this.state.errors.password && (
                                        <label className="error text-danger">{this.state.errors.password}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group main-select">
                                    <label className="form-title">{t('setting:password:confirmpassword')} *</label>
                                    <Input
                                        name="password_confirmation"
                                        onChange={this._handleChange}
                                        placeholder={t('setting:password:confirmpassword')}
                                        type="password"
                                        value={this.state.password_confirmation}
                                    />
                                    {this.state.errors.password_confirmation && (
                                        <label className="error text-danger">
                                            {this.state.errors.password_confirmation}
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('savebtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const Password = withTranslation()(connect(mapStateToProps)(PasswordComponent))

export { Password }
