import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { getEmployee, editEmployee, activeEmployeeCategory, activeRole } from '~/services/api'
import { EmployeeDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { employee_type } from '~/helper/constant/user_type'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    email: String,
    data: Array,
    loading: Boolean,
    dob: String,
    joining_date: String,
    errors: Object,
    employees: Array,
    section_id: integer,
    employee_category: Array,
    loading_text: String,
}

export class AbstractEmployeeEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: null,
            loading: false,
            joining_date: null,
            dob: null,
            selectedEmployeCategory: null,
            email: null,
            user_type: this.props.match.params.user_type,
            role_id: this.props.match.params.role_id,
            employee_id: this.props.match.params.id,
            errors: {
                name: null,
                dob: null,
                joining_date: null,
            },
            employees: [],
            employee_category: [],
            selectedRole: 0,
            selectedUserType: '',
            defaultUserType: this.props.match.params.user_type,
            defaultRole: '',
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadRole()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadRole() {
        this._handleChange('loading', true)
        activeRole(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let customRoleAry = []
            if (response.code == API_SUCCESS) {
                response.data.role.map((item, index) => {
                    customRoleAry.push({ value: item.id, label: item.name })
                })
                const data = customRoleAry.find((el) => el.value == this.props.match.params.role_id)
                this.setState(
                    {
                        defaultRole: data,
                        selectedRole: this.props.match.params.role_id != 0 ? this.props.match.params.role_id : 0,
                        customRoleAry,
                    },
                    () => {
                        this.loadUserTypes()
                    }
                )
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadUserTypes() {
        const data = employee_type.find((el) => el.value == this.props.match.params.user_type)
        this.setState(
            {
                userTypeAry: employee_type,
                defaultUserType: data,
                selectedUserType: this.props.match.params.user_type,
            },
            () => {
                this.loadEmployeeCategory()
            }
        )
        if (this.props.match.params.user_type == 1) {
            $('#custom-role').show()
        } else {
            $('#custom-role').hide()
        }
    }

    loadEmployeeCategory() {
        activeEmployeeCategory(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                let employee_category = []
                response.data.user_category.map((item, index) => {
                    let employee_category_data = {}
                    employee_category_data['value'] = item.id
                    employee_category_data['label'] = item.name + ' - ' + item.code
                    employee_category.push(employee_category_data)
                })
                this.setState({ employee_category }, () => {
                    this.loadEmployee()
                })
            }
        })
    }

    loadEmployee() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getEmployee(this.state.employee_id, this.props.token).then((response) => {
            this._handleChange('loading', false)

            if (response.code == API_SUCCESS) {
                const item = response.data.employee
                this.setState({
                    name: item.user.name,
                    email: item.user.email,
                    dob: moment(item.user.dob).toDate(),
                    joining_date: item.joining_date ? moment(item.joining_date).toDate() : '',
                    selectedEmployeCategory: item.users_category_id,
                    defaultEmployeCategory: this.state.employee_category.filter((el) => el.value == item.users_category_id),
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        let role_id = 0
        if (validate.length == 0) {
            const data = {
                user_type: this.state.user_type,
                name: this.state.name,
                users_category_id: this.state.selectedEmployeCategory,
            }
            if (this.state.role_id != null && this.state.role_id != '0' && this.state.role_id != 'null') {
                data['role_id'] = this.state.role_id
                role_id = this.state.role_id
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editEmployee(this.state.employee_id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.user.employee.view + '/' + this.state.user_type + '/' + role_id, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
