import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewNotificationSetting = (type, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('notification-management/notification-setting/edit/' + type, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveNotificationSetting = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('notification-management/notification-setting/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
