import {accountingGet, accountingPost} from '../accountingRequest'

export const viewProductCategory =(token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('product-category', token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const viewSingleProductCategory =(category, token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('product-category/edit/' + category, token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const addProductCategory = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-category/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            console.log($e)
            resolve(null)
        }
    })
}

export const updateProductCategory = (category, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-category/update/' + category, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteProductCategory = (category, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('product-category/delete' + category, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const viewProductUnit =(token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('product-unit', token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const viewSingleProductUnit =(unit, token)=>{
    return new Promise(function(resolve, reject){
        try{
            accountingGet('product-unit/edit/' + unit, token).then((response)=>{
                resolve(response)
            })

        } catch($e){
            resolve(null)
        }

    })
}

export const addProductUnit = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-unit/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateProductUnit = (unit, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-unit/update/' + unit, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
export const deleteProductUnit = (unit, data, token) => { // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('product-unit/delete' + unit, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}