import React from 'react'

export function feeSidebarIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.875 11.8887C6.97 13.0812 7.9125 14.01 9.6025 14.12V15H10.3525V14.1138C12.1025 13.9913 13.125 13.0563 13.125 11.6988C13.125 10.465 12.3425 9.82875 10.9438 9.49875L10.3525 9.35875V6.9625C11.1025 7.0475 11.58 7.4575 11.695 8.025H13.01C12.915 6.87625 11.93 5.9775 10.3525 5.88V5H9.6025V5.89875C8.10875 6.045 7.09 6.94375 7.09 8.215C7.09 9.34 7.8475 10.055 9.10625 10.3488L9.6025 10.4712V13.0138C8.83375 12.8975 8.325 12.4762 8.21 11.8887H6.875V11.8887ZM9.59625 9.18125C8.85875 9.01 8.45875 8.66125 8.45875 8.13625C8.45875 7.54875 8.89 7.10875 9.6025 6.98V9.18H9.59625V9.18125ZM10.4613 10.6725C11.3575 10.88 11.7712 11.2162 11.7712 11.81C11.7712 12.4875 11.2562 12.9525 10.3525 13.0375V10.6475L10.4613 10.6725V10.6725Z"
                fill="#333333"
                fillOpacity="0.77"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75V18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20V20Z"
                fill="#333333"
                fillOpacity="0.77"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 16.875C11.8234 16.875 13.572 16.1507 14.8614 14.8614C16.1507 13.572 16.875 11.8234 16.875 10C16.875 8.17664 16.1507 6.42795 14.8614 5.13864C13.572 3.84933 11.8234 3.125 10 3.125C8.17664 3.125 6.42795 3.84933 5.13864 5.13864C3.84933 6.42795 3.125 8.17664 3.125 10C3.125 11.8234 3.84933 13.572 5.13864 14.8614C6.42795 16.1507 8.17664 16.875 10 16.875V16.875ZM10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5V17.5Z"
                fill="#333333"
                fillOpacity="0.77"
            />
        </svg>
    )
}
export function sidebarBanking() {
    return (
        <svg viewBox="0 0 100.75 100.75">
            <g>
                <path
                    d="M12.356,77.606c-0.803,0-1.453,0.65-1.453,1.453s0.65,1.453,1.453,1.453h74.755c0.803,0,1.453-0.65,1.453-1.453
                    s-0.65-1.453-1.453-1.453h-8.109V34.718h8.109c0.803,0,1.453-0.65,1.453-1.453s-0.65-1.453-1.453-1.453h-66.43
                    c-0.13-0.038-0.264-0.065-0.406-0.065s-0.277,0.027-0.406,0.065h-7.513c-0.803,0-1.453,0.65-1.453,1.453s0.65,1.453,1.453,1.453
                    h6.472v42.888H12.356z M41.406,77.606V34.718h16.056v42.888H41.406z M76.096,77.606H60.368V34.718h15.728V77.606z M21.722,34.718
                    H38.5v42.888H21.722V34.718z"
                />
                <path
                    d="M95.922,89.44H3.545c-0.803,0-1.453,0.65-1.453,1.453s0.65,1.453,1.453,1.453h92.377c0.803,0,1.453-0.65,1.453-1.453
                    S96.725,89.44,95.922,89.44z"
                />
                <path
                    d="M97.176,31.8L50.987,5.408c-0.445-0.254-0.993-0.255-1.438-0.002L3.057,31.798c-0.698,0.396-0.942,1.283-0.546,1.981
                    c0.268,0.472,0.759,0.736,1.265,0.736c0.243,0,0.489-0.061,0.716-0.189L50.264,8.342l45.471,25.981
                    c0.694,0.396,1.582,0.157,1.982-0.541C98.115,33.086,97.873,32.199,97.176,31.8z"
                />
            </g>
        </svg>
    )
}
export function sidebarProduct() {
    return (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M58.48 17.12L32.48 3.12002C32.3353 3.04298 32.1739 3.00269 32.01 3.00269C31.8461 3.00269 31.6847 3.04298 31.54 3.12002L5.54 17.12C5.37845 17.2037 5.2428 17.3299 5.14765 17.4849C5.05249 17.64 5.00144 17.8181 5 18V46C5.00048 46.1809 5.05003 46.3583 5.14336 46.5133C5.2367 46.6682 5.37032 46.795 5.53 46.88L31.53 60.88C31.6747 60.9571 31.8361 60.9974 32 60.9974C32.1639 60.9974 32.3253 60.9571 32.47 60.88L58.47 46.88C58.6297 46.795 58.7633 46.6682 58.8566 46.5133C58.95 46.3583 58.9995 46.1809 59 46V18C59.0005 17.8201 58.9524 17.6434 58.8609 17.4886C58.7694 17.3337 58.6378 17.2064 58.48 17.12V17.12ZM32 5.14002L55.9 18L48.9 21.77L25.83 8.46002L32 5.14002ZM8.1 18L15.63 14L38.69 27.26L32 30.86L8.1 18ZM23.76 9.57002L46.83 22.88L40.76 26.15L17.7 12.83L23.76 9.57002ZM42.09 27.7L47.53 24.78V32.24L45.43 30.16C45.3133 30.0441 45.1699 29.9588 45.0123 29.9115C44.8548 29.8643 44.688 29.8567 44.5268 29.8893C44.3656 29.922 44.2149 29.9939 44.0882 30.0986C43.9614 30.2034 43.8624 30.3378 43.8 30.49L42.09 34.61V27.7ZM7 19.67L31 32.6V58.33L7 45.4V19.67ZM33 58.33V32.6L40.09 28.78V39.64C40.1016 39.8639 40.1881 40.0775 40.3356 40.2463C40.4831 40.4152 40.6831 40.5295 40.9034 40.5711C41.1238 40.6126 41.3517 40.5789 41.5505 40.4753C41.7494 40.3717 41.9077 40.2044 42 40L45.06 32.61L47.8 35.33C47.9399 35.4711 48.1186 35.5672 48.3133 35.6063C48.5081 35.6454 48.71 35.6256 48.8935 35.5494C49.0769 35.4732 49.2335 35.3442 49.3433 35.1786C49.4531 35.0131 49.5112 34.8186 49.51 34.62V23.7L56.99 19.7V45.4L33 58.33Z"
                fill="black"
            />
        </svg>
    )
}
export function sidebarAsset() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <g id="Layer_25" data-name="Layer 25">
                <path d="M54.06,41.9l-8.52,3.63a.06.06,0,0,1-.08-.06,4.08,4.08,0,0,0-.61-2.92,5.25,5.25,0,0,0-4.46-2.32H33.65a1.2,1.2,0,0,1-.59-.15l-6-3.35a8,8,0,0,0-3.93-1h-7.7a.06.06,0,0,1-.06-.06v-.82A3.82,3.82,0,0,0,11.59,31H7.82A3.82,3.82,0,0,0,4,34.82V54.14A3.82,3.82,0,0,0,7.82,58h3.77a3.82,3.82,0,0,0,3.82-3.82v-.09a.06.06,0,0,1,.07-.06,9.87,9.87,0,0,1,3.34,1l5.66,3.39a15,15,0,0,0,5.88,2.12,14.48,14.48,0,0,0,7.82-1.18L57.27,50a4.37,4.37,0,0,0-3.21-8.13ZM13.33,54.14a1.75,1.75,0,0,1-1.75,1.75H7.82a1.75,1.75,0,0,1-1.75-1.75V34.82a1.75,1.75,0,0,1,1.75-1.75h3.77a1.75,1.75,0,0,1,1.75,1.75Zm43.07-6.2h0L37.24,57.29a12.21,12.21,0,0,1-6.59,1,12.67,12.67,0,0,1-5-1.78L20,53.09a12.38,12.38,0,0,0-4.53-1.4.06.06,0,0,1-.05-.06V38a.06.06,0,0,1,.06-.06h7.7A5.74,5.74,0,0,1,26,38.7l6,3.35a3.47,3.47,0,0,0,1.7.45h7.2a2.36,2.36,0,0,1,2.32,1.69A2.27,2.27,0,0,1,41,47H29.45a1.13,1.13,0,1,0,0,2.27H41a4.53,4.53,0,0,0,2.13-.53L54.82,44a2.11,2.11,0,1,1,1.59,3.9Z" />
                <path d="M19.71,29.53A10.86,10.86,0,1,0,8.85,18.67,10.87,10.87,0,0,0,19.71,29.53Zm0-19.72a8.86,8.86,0,1,1-8.86,8.86A8.87,8.87,0,0,1,19.71,9.81Z" />
                <path d="M60.71,18.33,58,15.6h0L50.85,8.48a1,1,0,0,0-1.41,0l-4.32,4.32V10.85a1,1,0,1,0-2,0v3.94l-.8.8h0l-2.74,2.74A1,1,0,0,0,41,19.75l1-1v7.65a1,1,0,0,0,1,1H57.26a1,1,0,0,0,1-1V18.72l1,1a1,1,0,0,0,1.41-1.41Zm-4.45,7H44V16.72l6.12-6.12,6.12,6.12Z" />
                <path d="M28.52,8h10.4L37.67,9.2a1,1,0,1,0,1.41,1.41l3-3a1,1,0,0,0,0-1.42l-3-3a1,1,0,0,0-1.41,1.41L38.92,6H28.52a1,1,0,0,0,0,2Z" />
                <path d="M42.33,29.32a1,1,0,0,0-1-1H30.93l1.25-1.25a1,1,0,0,0-1.41-1.41l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.41-1.41l-1.25-1.25h10.4A1,1,0,0,0,42.33,29.32Z" />
                <path d="M19.69,22.67a1.69,1.69,0,0,1-1.77-1.59.91.91,0,0,0-1-.81.9.9,0,0,0-1,.8,3.29,3.29,0,0,0,2.48,3l.25.08v.49a.91.91,0,0,0,1,.81h0a.9.9,0,0,0,1-.8v-.23l0-.27.25-.07a3.27,3.27,0,0,0,2.51-3,3.51,3.51,0,0,0-3.73-3.21,1.69,1.69,0,0,1-1.77-1.59,1.79,1.79,0,0,1,3.56,0,.91.91,0,0,0,1,.81.9.9,0,0,0,1-.8,3.28,3.28,0,0,0-2.5-3l-.26-.08v-.57a.73.73,0,0,0-.27-.55,1.09,1.09,0,0,0-.72-.26.9.9,0,0,0-1,.8V13l0,.27-.25.08a3.27,3.27,0,0,0-2.48,3,3.51,3.51,0,0,0,3.73,3.21,1.69,1.69,0,0,1,1.77,1.59A1.7,1.7,0,0,1,19.69,22.67Z" />
            </g>
        </svg>
    )
}

export function sidebarFee() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path d="M29.5,26.6h5.83c1,0,.49,1.6,1.67,1.6s1.09-1.5.68-2.24a2.71,2.71,0,0,0-2.35-1.36H33V24a1,1,0,0,0-2,0v.6H29.5c-4.61,0-4.61,8.4,0,8.4h5c2,0,2,4.4,0,4.4H28.67c-.86,0-.55-1.1-1.17-1.46a1,1,0,0,0-1.5.86,2.65,2.65,0,0,0,2.67,2.6H31V40a1,1,0,0,0,2,0v-.6h1.5c4.61,0,4.61-8.4,0-8.4h-5C27.48,31,27.48,26.6,29.5,26.6Z" />
            <path d="M60,10H14a1,1,0,0,0-1,1v5H10a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1H54a1,1,0,0,0,1-1V40h5a1,1,0,0,0,1-1V11A1,1,0,0,0,60,10ZM53,46H11V18H53V46Zm6-8H55V17a1,1,0,0,0-1-1H15V12H59Z" />
            <path d="M38.63,20.82a13,13,0,1,0,6.14,13.63A13.1,13.1,0,0,0,38.63,20.82Zm-1,20.63A11,11,0,1,1,42.81,30,11.08,11.08,0,0,1,37.62,41.45Z" />
        </svg>
    )
}
export function sidebarConstant() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 384 512">
            <path d="M329.1 142.9c-62.5-62.5-155.8-62.5-218.3 0s-62.5 163.8 0 226.3s155.8 62.5 218.3 0c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3c-87.5 87.5-221.3 87.5-308.8 0s-87.5-229.3 0-316.8s221.3-87.5 308.8 0c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0z" />
        </svg>
    )
}
export function sidebarIncome() {
    return (
        <svg fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                <path d="M277.675 981.521c5.657 0 10.24-4.583 10.24-10.24V499.514c0-5.651-4.588-10.24-10.24-10.24h-81.92c-5.652 0-10.24 4.589-10.24 10.24v471.767c0 5.657 4.583 10.24 10.24 10.24h81.92zm0 40.96h-81.92c-28.278 0-51.2-22.922-51.2-51.2V499.514c0-28.271 22.924-51.2 51.2-51.2h81.92c28.276 0 51.2 22.929 51.2 51.2v471.767c0 28.278-22.922 51.2-51.2 51.2zm275.456-40.96c5.657 0 10.24-4.583 10.24-10.24V408.777c0-5.657-4.583-10.24-10.24-10.24h-81.92a10.238 10.238 0 00-10.24 10.24v562.504c0 5.657 4.583 10.24 10.24 10.24h81.92zm0 40.96h-81.92c-28.278 0-51.2-22.922-51.2-51.2V408.777c0-28.278 22.922-51.2 51.2-51.2h81.92c28.278 0 51.2 22.922 51.2 51.2v562.504c0 28.278-22.922 51.2-51.2 51.2zm275.456-40.016c5.657 0 10.24-4.583 10.24-10.24V318.974c0-5.651-4.588-10.24-10.24-10.24h-81.92c-5.652 0-10.24 4.589-10.24 10.24v653.251c0 5.657 4.583 10.24 10.24 10.24h81.92zm0 40.96h-81.92c-28.278 0-51.2-22.922-51.2-51.2V318.974c0-28.271 22.924-51.2 51.2-51.2h81.92c28.276 0 51.2 22.929 51.2 51.2v653.251c0 28.278-22.922 51.2-51.2 51.2zM696.848 40.96l102.39.154c11.311.017 20.494-9.138 20.511-20.449S810.611.171 799.3.154L696.91 0c-11.311-.017-20.494 9.138-20.511 20.449s9.138 20.494 20.449 20.511z"></path>
                <path d="M778.789 20.571l-.307 101.827c-.034 11.311 9.107 20.508 20.418 20.542s20.508-9.107 20.542-20.418l.307-101.827C819.783 9.384 810.642.187 799.331.153s-20.508 9.107-20.542 20.418z"></path>
                <path d="M163.84 317.682h154.184a51.207 51.207 0 0036.211-14.999L457.208 199.71a10.263 10.263 0 017.237-3.003h159.754a51.235 51.235 0 0036.198-14.976l141.13-141.13c7.998-7.998 7.998-20.965 0-28.963s-20.965-7.998-28.963 0L631.447 152.755a10.265 10.265 0 01-7.248 2.992H464.445a51.226 51.226 0 00-36.201 14.999L325.271 273.719a10.244 10.244 0 01-7.248 3.003H163.839c-11.311 0-20.48 9.169-20.48 20.48s9.169 20.48 20.48 20.48z"></path>
            </g>
        </svg>
    )
}
export function sidebarExpense() {
    return (
        <svg fill="#000000" height="18" width="18" version="1.1" viewBox="0 0 490.1 490.1">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                <g>
                    <line x1="20.95" y1="469.1" x2="20.95" y2="79.2"></line>
                    <line x1="132.45" y1="469.1" x2="132.45" y2="188.7"></line>
                    <line x1="245.05" y1="469.1" x2="245.05" y2="262.7"></line>
                    <line x1="357.65" y1="469.1" x2="357.65" y2="339.9"></line>
                    <line x1="469.15" y1="469.1" x2="469.15" y2="414.9"></line>
                    <g>
                        <path d="M20.95,58.4c-11.5,0-20.9,9.4-20.9,20.8v389.9c0,11.5,9.4,20.9,20.9,20.9c10.4,0,19.8-9.4,20.9-20.9V79.2 C41.75,67.8,32.35,58.4,20.95,58.4z"></path>
                        <path d="M132.45,167.9c-11.5,0-20.9,9.4-20.9,20.9v280.4c0,11.5,9.4,20.9,20.9,20.9s20.9-9.4,20.9-20.9V188.7 C153.35,177.2,143.95,167.9,132.45,167.9z"></path>
                        <path d="M245.05,241.9c-11.5,0-20.9,9.4-20.9,20.9v206.4c0,11.5,9.4,20.9,20.9,20.9c11.5,0,20.9-9.4,20.9-20.9V262.7 C265.95,251.3,256.55,241.9,245.05,241.9z"></path>
                        <path d="M357.65,319c-11.5,0-20.9,9.4-20.9,20.9v129.3c0,11.5,9.4,20.9,20.9,20.9s19.8-9.4,20.9-20.9V339.9 C378.45,328.4,369.15,319,357.65,319z"></path>
                        <path d="M469.15,394.1c-11.5,0-20.9,9.4-20.9,20.9v54.2c0,11.5,9.4,20.9,20.9,20.9s20.9-9.4,20.9-20.9V415 C490.05,403.5,480.65,394.1,469.15,394.1z"></path>
                        <path d="M404.55,254.4l-34.3-14.6c-10.4-5.2-22.9,0-27.1,10.4c-5.2,10.4,0,22.9,10.4,27.1l97,41.7c12.2,5.9,24.6-0.7,28.1-17.7 l10.4-105.3c1-10.4-7.3-20.9-18.8-21.9c-10.4-1-20.9,7.3-21.9,18.8l-4.5,47.2C292.35,10.7,67.45,0,57.35,0c-1,0-1,0-1,0 c-11.5,0-20.9,8.3-20.9,19.8s8.3,20.9,19.8,20.9C57.25,40.7,264.25,51.2,404.55,254.4z"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export function sidebarVendor() {
    return (
        <svg viewBox="0 0 512 512">
            <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm161.5-86.1c-12.2-5.2-26.3 .4-31.5 12.6s.4 26.3 12.6 31.5l11.9 5.1c17.3 7.4 35.2 12.9 53.6 16.3v50.1c0 4.3-.7 8.6-2.1 12.6l-28.7 86.1c-4.2 12.6 2.6 26.2 15.2 30.4s26.2-2.6 30.4-15.2l24.4-73.2c1.3-3.8 4.8-6.4 8.8-6.4s7.6 2.6 8.8 6.4l24.4 73.2c4.2 12.6 17.8 19.4 30.4 15.2s19.4-17.8 15.2-30.4l-28.7-86.1c-1.4-4.1-2.1-8.3-2.1-12.6V235.5c18.4-3.5 36.3-8.9 53.6-16.3l11.9-5.1c12.2-5.2 17.8-19.3 12.6-31.5s-19.3-17.8-31.5-12.6L338.7 175c-26.1 11.2-54.2 17-82.7 17s-56.5-5.8-82.7-17l-11.9-5.1zM256 160a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
        </svg>
    )
}

export function sidebarLibrary(){
    return(
        <svg viewBox="0 0 384 512">
            <path d="M0 48C0 21.5 21.5 0 48 0l0 48V441.4l130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4V48H48V0H336c26.5 0 48 21.5 48 48V488c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488V48z"/>
        </svg>

    )
}

export function sidebarProductStock() {
    return (
        <svg viewBox="0 0 512 512">
            <path d="M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49.6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z" />
        </svg>
    )
}
export function sidebarDoubleEntry() {
    return (
        <svg fill="#000000" height="18" width="18" version="1.1" viewBox="0 0 512 512">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                <g>
                    <g>
                        <circle cx="377.038" cy="314.483" r="14.244"></circle>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M448.314,202.403h-12.531V8.484c0-4.687-3.799-8.484-8.484-8.484H200.909c-4.685,0-8.484,3.797-8.484,8.484 s3.799,8.484,8.484,8.484h217.905v22.624h-283.19c-22.514,0-40.83,18.316-40.83,40.832c0,22.513,18.316,40.83,40.83,40.83h193.82 c4.685,0,8.484-3.797,8.484-8.484s-3.799-8.484-8.484-8.484h-193.82c-13.158,0-23.863-10.705-23.863-23.863 c0-13.158,10.705-23.864,23.863-23.864h283.19v47.725H363.38c-4.685,0-8.484,3.797-8.484,8.484s3.799,8.484,8.484,8.484h55.434 v22.624h-283.19c-10.518,0-20.981-2.646-30.221-7.669c-19.779-10.758-33.233-31.73-33.233-55.785 c0-34.989,28.465-63.454,63.454-63.454h31.362c4.685,0,8.484-3.797,8.484-8.484S171.672,0,166.987,0h-31.362 C91.279,0,55.202,36.078,55.202,80.423v393.418c0,21.041,17.118,38.159,38.16,38.159h42.263h261.998 c21.041,0,38.16-17.118,38.16-38.159v-47.275h12.531c4.685,0,8.484-3.797,8.484-8.484V210.887 C456.798,206.201,452.999,202.403,448.314,202.403z M418.814,473.841c0,11.685-9.507,21.191-21.192,21.191H144.108v-42.957 c0-4.687-3.799-8.484-8.484-8.484c-4.685,0-8.484,3.797-8.484,8.484v42.957H93.362c-11.685,0-21.192-9.505-21.192-21.191V129.781 c0.223,0.286,0.463,0.558,0.689,0.841c12.98,16.195,32.13,27.021,52.743,29.577c0.514,0.064,1.021,0.148,1.538,0.202V418.14 c0,4.687,3.799,8.484,8.484,8.484c4.685,0,8.484-3.797,8.484-8.484V160.844h274.706v41.558h-25.902 c-2.816,0-5.448,1.397-7.026,3.73l-59.889,88.497c-8.162,12.061-8.162,27.648,0,39.709l59.889,88.497 c1.578,2.333,4.21,3.73,7.026,3.73h25.902V473.841z M439.83,409.599h-12.531h-29.884l-57.365-84.768 c-4.252-6.286-4.253-14.407,0-20.692l57.365-84.768h29.884h12.531V409.599z"></path>{' '}
                    </g>
                </g>
            </g>
        </svg>
    )
}

export function sidebarReports() {
    return (
        <svg
            width="18"
            height="18"
            fill="#000000"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            enableBackground="new 0 0 64 64"
        >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
                <g>
                    <path d="M34,31h29c0.553,0,1-0.447,1-1C64,13.432,50.568,0,34,0c-0.553,0-1,0.447-1,1v29C33,30.553,33.447,31,34,31z M35,2.025 C49.667,2.541,61.459,14.332,61.975,29H35V2.025z"></path>{' '}
                    <path d="M63,33H36c-0.044,0-0.082,0.019-0.125,0.024c-0.084,0.011-0.168,0.019-0.248,0.05c-0.078,0.031-0.143,0.084-0.209,0.133 c-0.036,0.027-0.079,0.041-0.112,0.072c-0.002,0.002-0.003,0.006-0.005,0.008c-0.086,0.084-0.152,0.185-0.203,0.295 c-0.004,0.009-0.014,0.016-0.018,0.025c-0.016,0.038-0.015,0.084-0.026,0.125c-0.023,0.084-0.051,0.169-0.052,0.256L35,34 c0,0.053,0.022,0.1,0.031,0.152c0.012,0.074,0.016,0.148,0.044,0.219c0.035,0.088,0.092,0.16,0.149,0.233 c0.021,0.028,0.031,0.063,0.057,0.089l0.01,0.01c0.001,0.002,0.002,0.003,0.004,0.004l18.742,19.409 c0.074,0.077,0.164,0.126,0.254,0.175l0.922,0.922C60.643,49.784,64,42.284,64,34l0,0C64,33.447,63.553,33,63,33z M55.126,52.365 L38.356,35h23.618C61.741,41.637,59.2,47.683,55.126,52.365z"></path>{' '}
                    <path d="M49.827,53.795c0,0-17.231-18.523-18.212-19.504C31.012,33.688,31,32.605,31,32.605V5c0-0.553-0.447-1-1-1 C13.432,4,0,17.432,0,34s13.432,30,30,30c8.284,0,15.784-3.357,21.213-8.787l-1.335-1.335 C49.858,53.852,49.851,53.82,49.827,53.795z M30,62C14.536,62,2,49.464,2,34C2,18.871,14,6.553,29,6.025c0,0,0,26.068,0,26.975 s0.343,1.81,1.016,2.482s18.332,19.658,18.332,19.658C43.434,59.41,37.021,62,30,62z"></path>{' '}
                </g>
            </g>
        </svg>
    )
}

export function sidebarScroll(color = null, fillOpacity = null) {
    return (
        <svg width="20" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    d="M22.2038 3.06201C23.0445 3.06201 23.7393 3.67762 23.8493 4.47633L23.8644 4.69838L23.8636 33.464L34.3662 23.0715C35.0133 22.4311 36.0647 22.4288 36.7147 23.0665C37.3055 23.6462 37.3611 24.5552 36.8801 25.1968L36.7197 25.3807L23.3815 38.5807L23.3465 38.613C23.3106 38.6465 23.2732 38.6785 23.2345 38.7088L23.3815 38.5807C23.3172 38.6443 23.2489 38.7016 23.1775 38.7526C23.1326 38.7834 23.086 38.8133 23.0379 38.8409C22.9308 38.9033 22.8187 38.9516 22.7034 38.9873C22.6628 38.9993 22.6213 39.0104 22.5792 39.02C22.5471 39.0278 22.5144 39.0342 22.4816 39.0397C22.4461 39.0451 22.4105 39.0498 22.3746 39.0535C22.3386 39.0576 22.3017 39.0601 22.2648 39.0614C22.2444 39.0617 22.2241 39.062 22.2038 39.062L22.1487 39.0615C22.1078 39.0602 22.0669 39.0573 22.0262 39.053L22.2038 39.062C22.109 39.062 22.016 39.0542 21.9255 39.0391C21.8843 39.0324 21.8429 39.0239 21.8018 39.0138C21.7691 39.0057 21.7371 38.9968 21.7054 38.987C21.6678 38.9755 21.6299 38.9623 21.5925 38.9477C21.5538 38.9325 21.516 38.9161 21.479 38.8983C21.4507 38.8849 21.4214 38.8699 21.3926 38.8539C21.3385 38.8239 21.2877 38.792 21.2389 38.7576C21.2313 38.7522 21.2229 38.7462 21.2145 38.74C21.1404 38.6854 21.075 38.6289 21.0144 38.5676L7.68754 25.3808C7.04036 24.7404 7.04254 23.7043 7.6924 23.0666C8.28318 22.4869 9.20586 22.4359 9.85497 22.9126L10.0409 23.0714L20.5416 33.462L20.5432 4.69838C20.5432 3.79464 21.2867 3.06201 22.2038 3.06201Z"
                    fill={color == null ? '#292D40' : color}
                    fillOpacity={fillOpacity == null ? '0.4' : fillOpacity}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0.203575"
                    y="0.0620117"
                    width="44"
                    height="50"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="3.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}
