import ProgressBar from '@ramonak/react-progress-bar'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { access_management } from '~/helper/constant/modules'
import { AbstractSubscriptionComponent, mapStateToProps } from './abstract'

class SubscriptionComponent extends AbstractSubscriptionComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="setting" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('subscription:subscription')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="package-row row">
                        <div className="col-lg-4">
                            <div className="custom-card-box">
                                <div className="package-features">
                                    <div className="features-available">
                                        <h6 className="line-title">{t('subscription:features')} :</h6>
                                        <hr />
                                        <ul className="features-list">
                                            {access_management.map((item) => {
                                                if (this.state.packageData != null) {
                                                    if (this.state.packageData.access.includes(item.value)) {
                                                        return (
                                                            <li>
                                                                <span className="p-check feature-status">{SVG.checked()}</span> {t(item.translate)}
                                                            </li>
                                                        )
                                                    } else {
                                                        return (
                                                            <li>
                                                                <span className="p-cross feature-status">{SVG.cross()}</span> {t(item.translate)}
                                                            </li>
                                                        )
                                                    }
                                                } else {
                                                    return <li>{t(item.translate)}</li>
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="custom-card-box">
                                <div className="progress-wrap">
                                    <h6 className="line-title">{this.state.packageData != null ? this.state.packageData.name : t('subscription:package_status')} :</h6>
                                    <hr />
                                    {this.state.progressStatus.map((item, index) => {
                                        let completed = (item.completed / item.max) * 100
                                        if (item.isStorage) {
                                            completed = ((item.completed / (1024 * 1024 * 1024)).toFixed(2) / (item.max / 1024).toFixed(2)) * 100
                                        }
                                        return (
                                            <div className="progress-content mb-4" key={index}>
                                                <div className="progress-label">
                                                    <p>{t(item.translate)}</p>
                                                </div>
                                                {item.max != 0 && (
                                                    <div className="x-progress-bar">
                                                        <ProgressBar bgcolor={item.bgcolor} completed={completed} isLabelVisible={false} />
                                                    </div>
                                                )}
                                                <div className="progress-value">
                                                    <p>
                                                        {item.max != 0 && (
                                                            <span>
                                                                {item.isStorage && (
                                                                    <span>
                                                                        {(item.completed / (1024 * 1024 * 1024)).toFixed(2)} / {(item.max / 1024).toFixed(2)} GB
                                                                    </span>
                                                                )}
                                                                {!item.isStorage && (
                                                                    <span>
                                                                        {item.completed} / {item.max}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        )}
                                                        {item.max == 0 && (
                                                            <span style={{ background: item.bgcolor }} className="progress-tag">
                                                                {item.isStorage && <span>{(item.completed / (1024 * 1024 * 1024)).toFixed(2)} GB</span>}
                                                                {!item.isStorage && item.completed}
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* <div className="display-inline-block mt-2">
                                    <a href="" className="main-button">
                                        Contact us to upgrade
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Subscription = withTranslation()(connect(mapStateToProps)(SubscriptionComponent))

export { Subscription }
