import React, { Component } from 'react'
import {Dispatch} from 'react-redux'
import { viewGrading, toggleGrade} from '~/services/api'
import {API_SUCCESS, API_FAILURE} from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { connect } from 'react-redux'
import { WithTranslation } from 'react-i18next'
import * as Status from '~/helper/constant/status'
import $, { type } from 'jquery'
import { toastMessage } from '~/helper/function/util'

type Props ={
  dispatch: Dispatch<any>,
}
type State = {}

export class AbstractGradingViewComponent extends Component<Props, State> {
  constructor(props: Props){
    super(props)
    const {t} = this.props
    this.state = {
      loading: true,
      loading_text: "Loading",
      title: '',
      code: '',
      gradingData: [],
      gradingStructure:[
        {
          title: {
            field: 'text',
            route: false,
            sortable: true,
            translate: 'common:name',
          },
          status: {
            field: 'badge',
            route:false,
            sortable:true,
            translate: 'common:status',
          },
          action:{
            field:'button',
            route:true,
            sortable: false,
            translate: 'activity:action',
          }
        }
      ]

    }
    this._handleChange = this._handleChange.bind(this)
    this.toggle = this.toggle.bind(this)

  }
  componentDidMount(){
    this.loadGrading()
  }

  _handleChange(key, data){
    this.setState({[key]: data})
  }
  toggle(e){
    const link = e.target.getAttribute('link')
    $('#close_' + link).trigger('click')
    toggleGrade(link, this.props.token).then((response)=>{
      console.log(response)
      if(response.code == API_SUCCESS){
        toastMessage('success', response.message)
        this.loadGrading()
      }else{
        toastMessage('error', response.message)
      }
    })

  }


  loadGrading(){
    
    this._handleChange('loading_text', 'Loading')
    this._handleChange('loading', true)
    let gradingStructure = []
    let gradingData = []
    gradingStructure.push(this.state.gradingStructure[0])
    viewGrading(this.props.token).then((response)=>{
      this._handleChange('loading', false)
      if(response.code == API_SUCCESS){
        response.data.grading.map((item, index)=>{
          let text= null
          if(item.status == Status.ACTIVE){
            text={
              text: 'Active',
              translate: 'common:statusActive',
              badge: 'table-green',
              modal: 'modal_class_' + index,
            }
          } else{
            text = {
              text: 'Inactive',
              translate: 'common:statusInactive',
              badge: 'table-red',
              modal: 'modal_class_' + index,
            }
          }
          const tableData = {
            title: {
              text:item.title,
            },
            status: text,
            action: {
              text: 'Edit',
              translate: 'common:EditBtn',
              route: route.admin.setting.grading.edit + '/' + item.id,
            },
          }
          gradingData.push(item)
          gradingStructure.push(tableData)
        })
        this.setState({
          gradingData,
          gradingStructure,
        })
      }
    })
  }

}

export function mapStateToProps(state: Object) {
  return {
      token: state.token,
  }
}

