import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import * as validation from '~/helper/constant/validation'
import { getAlumniCategory, editAlumniCategory } from '~/services/api'
import { ClassDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    errors: Object,
    loading: Boolean,
    loading_text: String,
}

export class AbstractAlumniCategoryEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            pageTitle: this.props.match.params.type != null ? ALUMNI_CATEGORY.categoryList.find((item) => item.value == this.props.match.params.type) : null,
            name: '',
            code: '',
            description: '',
            email: '',
            contact: '',
            address: '',
            website: '',
            profile: {},
            profileBlob: [],
            errors: {
                name: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.loadAlumniCategory = this.loadAlumniCategory.bind(this)
    }

    componentDidMount() {
        this.loadAlumniCategory()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    componentDidUpdate(prevProps) {
        const { t } = this.props
        if (this.props.match.params.type != prevProps.match.params.type) {
            this.setState(
                {
                    pageTitle: this.props.match.params.type != null ? ALUMNI_CATEGORY.categoryList.find((item) => item.value == this.props.match.params.type) : null,
                },
                () => {
                    this.loadAlumniCategory()
                }
            )
        }
    }

    loadAlumniCategory() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAlumniCategory(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this._handleChange('name', response.data.alumni_category.name)
                this._handleChange('code', response.data.alumni_category.code)
                this._handleChange('description', response.data.alumni_category.description)
                this._handleChange('email', response.data.alumni_category.email)
                this._handleChange('contact', response.data.alumni_category.contact)
                this._handleChange('address', response.data.alumni_category.address)
                this._handleChange('website', response.data.alumni_category.website)
                if (response.data.alumni_category.gallery) {
                    if (response.data.alumni_category.gallery.media) {
                        const images = response.data.alumni_category.gallery.media.find((item) => item.media_type.includes('data:image/'))
                        const profileBlob = []
                        if (images != null) {
                            const profile = {
                                0: images.media_type + ';base64,' + images.media_data,
                            }
                            profileBlob.push(images)
                            this._handleChange('profile', profile)
                            this._handleChange('profileBlob', profileBlob)
                        }
                    }
                }
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                description: this.state.description,
                profile: this.state.profile,
                email: this.state.email,
                contact: this.state.contact,
                address: this.state.address,
                website: this.state.website,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editAlumniCategory(this.props.match.params.id, data, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.alumni.category.view + '/' + this.props.match.params.type, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
                this._handleChange('loading', false)
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
