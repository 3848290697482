import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractNoticeEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Input, Checkbox, Button, TextArea } from '~/components/form'
import { currency } from '~/helper/constant/currency'
import * as route from '~/helper/constant/route'
import Select from 'react-select'
import MultiImageInput from 'react-multiple-image-input'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'


class NoticeEditComponent extends AbstractNoticeEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.setImages = this.setImages.bind(this)
    }

    setImages(data) {
        super._handleChange('media', data)
    }

    getGallery() {
        if (this.state.defaultMedia.length > 0) {
            const modal = this.state.defaultMedia.map((item, index) => {
                return (
                    <div key={index} className="col-md-3 float-left">
                        <span onClick={() => this._deleteImage(item.id)} className="gallery-delete">
                            {SVG.deelete()}
                        </span>

                        <img src={item.media_url} />
                    </div>
                )
            })
            return modal
        } else {
            return <div className="gallery-message">No media available</div>
        }
    }

    render() {
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="notice" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.superadmin.notice.view}>Notices > </a>
                            </li>
                            <li>Edit</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">Edit Notice:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Title *</label>
                                    <Input name="title" onChange={this._handleChange} placeholder="Title" type="text" value={this.state.title} />
                                    {this.state.errors.title && <label className="error text-danger">{this.state.errors.title}</label>}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">Code *</label>
                                    <Input name="code" disabled="disabled" placeholder="Code" type="text" value={this.state.code} />
                                    {this.state.errors.code && <label className="error text-danger">{this.state.errors.code}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">Description *</label>
                                    <TextArea name="description" onChange={this._handleChange} placeholder="description" type="text" value={this.state.description} />
                                    {this.state.errors.description && <label className="error text-danger">{this.state.errors.description}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">Upload Media</label>
                                    <MultiImageInput images={this.state.media} setImages={this.setImages} allowCrop={false} theme="light" />
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <label className="form-title">Gallery </label>
                                    <div className="gallery-container clearfix">{this.getGallery()}</div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text="Update" main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const NoticeEdit = connect(mapStateToProps)(NoticeEditComponent)

export { NoticeEdit }
