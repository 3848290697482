import moment from 'moment'
import * as SVG from '~/assets/svg'
import { country } from '~/helper/constant/country'
import { currency } from '~/helper/constant/currency'
import { sms } from '~/helper/constant/sms'
import DateBS from '../date-converter'

const ACCOUNTING_KEY = process.env.REACT_APP_ACCOUNTING_KEY

export function convertADtoBS(date) {
    try {
        const formattedDate = moment(date).format('yyyy-MM-DD')
        const convertedDate = DateBS.fromAD(moment(formattedDate))
        const year = convertedDate.year
        const month = convertedDate.month < 10 ? '0' + convertedDate.month : convertedDate.month
        const day = convertedDate.day < 10 ? '0' + convertedDate.day : convertedDate.day
        return year + '-' + month + '-' + day
    } catch (e) {
        return null
    }
}

export function convertBStoAD(nepaliDate) {
    try {
        const dateFormat = nepaliDate.split('-')
        let date = new DateBS(parseInt(dateFormat[0]), parseInt(dateFormat[1]), parseInt(dateFormat[2]))

        return moment(date.toAD()).format('yyyy-MM-DD')
    } catch (e) {
        return null
    }
}

export function getBSWeek(nepaliDate) {
    try {
        const dateFormat = nepaliDate.split('-')
        let date = new DateBS(parseInt(dateFormat[0]), parseInt(dateFormat[1]), parseInt(dateFormat[2]))
        return moment(date.toAD()).format('dddd')
    } catch (e) {
        return null
    }
}

export function clearConstant() {
    currency.map((item) => {
        item.checked = false
    })
    sms.map((item) => {
        item.checked = false
    })
    country.map((item) => {
        item.checked = false
    })
}

export function kFormatter(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
        : Math.sign(num) * Math.abs(num)
}

export function configRoute(route) {
    return route.replace('/#', '')
}

export function calculateTime(date) {
    const msDiff = new Date().getTime() - new Date(date) //Future date - current date
    return Math.round(((msDiff % 86400000) % 3600000) / 60000)
}

export function pad2(number) {
    return (number < 10 ? '0' : '') + number
}

export function underscoreToSpace(str) {
    try {
        const data = str.split('_')
        let result = null
        data.map((value) => {
            if (result) {
                result = result + ' ' + value
            } else {
                result = value
            }
        })
        return result
    } catch (error) {
        return str
    }
}

export function capitalize(str) {
    try {
        const data = str.split(' ')
        let result = null
        data.map((value) => {
            if (result) {
                result = result + ' ' + value.charAt(0).toUpperCase() + value.slice(1)
            } else {
                result = value.charAt(0).toUpperCase() + value.slice(1)
            }
        })
        return result
    } catch (error) {
        return str
    }
}

export function getCurrency(data) {
    const item = currency
        .filter((item) => item.value === data)
        .map((item, index) => {
            return item
        })
    return item
}

export function getSms(data) {
    const item = sms
        .filter((item) => item.value === data)
        .map((item, index) => {
            return item
        })
    return item
}

export function getCountry(data) {
    const item = country
        .filter((item) => item.value === data)
        .map((item, index) => {
            return item
        })
    return item
}

export function getCountryList(data) {
    let result = []
    data.map((request) => {
        country
            .filter((item) => item.value === request)
            .map((item, index) => {
                result.push(item)
            })
    })
    return result
}

export function grabUrl(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g
    return text.match(urlRegex)
}

export function getBase64(file) {
    if (file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
                const encodedFile = reader.result
                const media = {
                    name: file.name,
                    data: encodedFile,
                }
                resolve(media)
            }
            reader.onerror = function (error) {
                const media = {
                    error,
                }
                resolve(media)
            }
        })
    }
}

export function getNumberofDays(year, month) {
    return new Date(year, month, 0).getDate()
}

export function getReadableFileSize(bytes) {
    const decimals = 2
    if (bytes === 0) return '0 B'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getMediaIcon(media_type) {
    if (media_type != null) {
        if (media_type.includes('pdf')) {
            return SVG.chatpdf()
        } else if (media_type.includes('video')) {
            return SVG.videoicon()
        } else if (media_type.includes('image')) {
            return SVG.imageicon()
        } else if (media_type.includes('presentation') || media_type.includes('ms-powerpoint')) {
            return SVG.powerpointicon()
        } else if (media_type.includes('wordprocessing') || media_type.includes('msword')) {
            return SVG.wordicon()
        } else if (media_type.includes('spreadsheet') || media_type.includes('octet-stream')) {
            return SVG.excelicon()
        } else {
            return SVG.unknownicon()
        }
    } else {
        return SVG.unknownicon()
    }
}

export function getJournalId(id) {
    return '#JUR' + String(id).padStart(5, '0')
}

export function getInvoiceId(id) {
    return '#INV' + String(id).padStart(5, '0')
}

export function getBillId(id) {
    return '#BILL' + String(id).padStart(5, '0')
}
