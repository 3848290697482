import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractResultAssignComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import Select from 'react-select'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import * as UserType from '~/helper/constant/user_type'
import * as ReactTable from 'react-super-responsive-table'
import moment from 'moment'
import * as QuestionType from '~/helper/constant/question_type'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import { convertADtoBS } from '../../../../../helper/function'

class ResultAssignComponent extends AbstractResultAssignComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getStudent = this.getStudent.bind(this)
        this.checkAnswer = this.checkAnswer.bind(this)
        this.getQuestion = this.getQuestion.bind(this)
        this.getAnswers = this.getAnswers.bind(this)
    }

    getAnswers(answers, id) {
        if (answers != null) {
            const data = answers.map((item, index) => {
                const studentAnswer = this.state.online_exam.find((result) => result.question_id == item.question_id && id == result.user_school_id)
                return (
                    <Fragment key={index}>
                        {studentAnswer != null && studentAnswer.answer_id == item.id && <input checked disabled="disabled" type="radio" id={'question_' + item.question_id} name={'question_' + item.question_id} value={item.id} />}
                        {studentAnswer != null && studentAnswer.answer_id != item.id && <input type="radio" disabled="disabled" id={'question_' + item.question_id} name={'question_' + item.question_id} value={item.id} />}
                        {studentAnswer == null && <input type="radio" disabled="disabled" id={'question_' + item.question_id} name={'question_' + item.question_id} value={item.id} />}
                        {item.is_correct == true && studentAnswer != null && studentAnswer.answer_id == item.id && <label className="text-green">{item.answers}</label>}
                        {item.is_correct == true && studentAnswer != null && studentAnswer.answer_id != item.id && <label className="text-green">{item.answers}</label>}
                        {item.is_correct == true && studentAnswer == null && <label className="text-green">{item.answers}</label>}
                        {item.is_correct != true && studentAnswer != null && studentAnswer.answer_id == item.id && <label className="text-red">{item.answers}</label>}
                        {item.is_correct != true && studentAnswer != null && studentAnswer.answer_id != item.id && <label>{item.answers}</label>}
                        {item.is_correct != true && studentAnswer == null && <label>{item.answers}</label>}
                        <br />
                    </Fragment>
                )
            })
            return data
        }
    }

    getQuestion(id, studentIndex) {
        const { t } = this.props
        const data = this.state.question.map((item, index) => {
            const media = item.gallery != null ? item.gallery.media : null
            const studentAnswer = this.state.online_exam.find((result) => result.question_id == item.id && id == result.user_school_id)
            const answers = item.answer
            let subjective_answer = null
            let marks = 0
            let error = null
            if (studentAnswer != undefined && studentAnswer != null) {
                const onlineMarks = this.state.onlineMarksList.find((result) => result.id == studentAnswer.id)
                marks = onlineMarks != undefined && onlineMarks != null ? onlineMarks.marks : 0
                subjective_answer = onlineMarks != undefined && onlineMarks != null ? onlineMarks.subjective_answer : null
                error = onlineMarks != undefined && onlineMarks != null ? onlineMarks.error : null
            }

            return (
                <div className="accordion-list" key={index}>
                    <div id={'heading_' + id + '_' + index}>
                        <h5 data-toggle="collapse" data-target={'#collapse_' + id + '_' + index} aria-expanded={index == 0 ? 'true' : 'false'} aria-controls={'collapse_' + id + '_' + index}>
                            <span>Q{index + 1}</span> {item.questions}
                        </h5>
                    </div>
                    <div id={'collapse_' + id + '_' + index} className={index == 0 ? 'accordion-content collapse-body objective-question collapse show' : 'accordion-content collapse-body objective-question collapse'} aria-labelledby={'heading_' + id + '_' + index} data-parent="#accordion">
                        <div className="row clearfix">
                            <div className="col-sm-12 float-left form-group">
                                {item.type == QuestionType.Subjective && <TextArea value={subjective_answer} placeholder={t('exam:subAns')} type="text" className="answer-textarea-height" disabled="disabled" />}
                                {item.type == QuestionType.Objective && this.getAnswers(answers, id)}
                            </div>
                        </div>

                        <em className="info text-right">
                            {t('exam:marks')} = {item.marks} | {t('exam:obtainedMarks')} = &nbsp;
                            {item.type == QuestionType.Subjective && studentAnswer != undefined && studentAnswer != null && (
                                <Fragment>
                                    <Input placeholder={t('exam:obtainedMarks')} value={marks.toString()} className="obtained-marks" onChange={(key, value) => this._updateAnswerMarks(studentAnswer.id, id, studentIndex, value)} />
                                    <br />
                                    {error != null && <label className="error text-danger">{error}</label>}
                                </Fragment>
                            )}
                            {item.type == QuestionType.Subjective && (studentAnswer == undefined || studentAnswer == null) && marks.toString()}
                            {item.type == QuestionType.Objective && marks.toString()}
                        </em>
                    </div>
                </div>
            )
        })
        return data
    }

    checkAnswer() {
        const { t } = this.props
        if (this.state.exam != null && this.state.exam.is_online) {
            const data = this.state.student.map((item, index) => {
                return (
                    <div id={'modal_check_' + item.id} className="modal fade show collapse-body" role="dialog" key={index}>
                        <div className="modal-dialog modal-lg pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                            <div className="header modal-title">
                                <button type="button" className="close" data-dismiss="modal">
                                    ×
                                </button>
                                <span>{t('exam:checkAnswer')}</span>
                            </div>
                            <div className="modal-content">
                                <div className="pop-main-content">
                                    <br />
                                    <p>
                                        <small>* {t('exam:verifyMarksObtainedStudentOnlineExam')}</small>
                                    </p>
                                    <div id="accordion" className="main-accordion">
                                        {this.getQuestion(item.id, index)}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="text-center form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('exam:assign')} main={true} />
                                    <button id="close_question_add" type="button" className="cancel-button" data-dismiss="modal">
                                        {t('common:modal close')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return data
        }
    }

    getStudent() {
        const { t } = this.props
        if (this.state.student.length > 0) {
            const data = this.state.student.map((item, index) => {
                const name = item.user.name != null ? item.user.name : item.user.reg_no
                const profile = item.user.user_detail != null ? (item.user.user_detail.profile != null ? item.user.user_detail.profile : null) : null
                const date = this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && this.state.exam.exam_date != null ? (convertADtoBS(this.state.exam.exam_date) != null ? convertADtoBS(this.state.exam.exam_date) : 'Invalid Date Range') : moment(this.state.exam.exam_date).format('DD MMM')
                return (
                    <ReactTable.Tr key={index}>
                        <ReactTable.Td>
                            <div className="post-user position-relative">
                                {profile != null && <img src={profile} alt="post-user" />}
                                {profile == null && <div>{name.charAt(0)}</div>}
                            </div>
                        </ReactTable.Td>
                        <ReactTable.Td>{item.user.reg_no}</ReactTable.Td>
                        <ReactTable.Td>{name}</ReactTable.Td>
                        <ReactTable.Td>
                            <span className={'table-status table-green'}>
                                {this.state.exam.subject.name} - {this.state.exam.subject.code}
                            </span>
                        </ReactTable.Td>
                        <ReactTable.Td>
                            {date} - [ {this.state.exam.start_time} - {this.state.exam.end_time} ]
                        </ReactTable.Td>
                        {this.state.exam != null && this.state.exam.is_online == true && (
                            <ReactTable.Td>
                                <Button type="button" text={t('todo:check')} table={true} modal={'modal_check_' + item.id} />
                            </ReactTable.Td>
                        )}
                        <ReactTable.Td>
                            {this.state.exam.is_online == true && <Input className="table-input" disabled="disabled" placeholder={t('exam:obtainedMarks')} onChange={(key, value) => this.updateMarks(index, value)} value={this.state.marksList.length > 0 ? this.state.marksList[index]['marks'].toString() : '0'} name={'marksList[' + index + '][marks]'} />}
                            {this.state.exam.is_online != true && <Input className="table-input" placeholder={t('exam:obtainedMarks')} onChange={(key, value) => this.updateMarks(index, value)} value={this.state.marksList.length > 0 ? this.state.marksList[index]['marks'].toString() : '0'} name={'marksList[' + index + '][marks]'} />}
                            {this.state.marksList.length > 0 && this.state.marksList[index].error != null && <label className="error text-danger">{this.state.marksList[index].error}</label>}
                        </ReactTable.Td>
                    </ReactTable.Tr>
                )
            })
            return data
        } else {
            return (
                <ReactTable.Tr>
                    <ReactTable.Td colSpan="6">
                        <p className="no-data">
                            {SVG.nodata()} {t('exam:result.noResults')}.
                        </p>
                    </ReactTable.Td>
                </ReactTable.Tr>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="exam" />
                <div className="main-content">
                    <Loading status={this.state.loading} text={this.state.loading_text} />
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.examination.menu}>{t('exam:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.result.view}>{t('exam:result.categoryTitle')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.examination.result.menu + '/' + (this.state.exam != null ? this.state.exam.post_id : '')}>{t('exam:result.filter')} &gt; </a>
                            </li>
                            <li>{t('exam:assign')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                {this.state.section != null && (
                                    <span className="sub-main-title">
                                        {this.state.section.classes.name} : {this.state.section.name}
                                    </span>
                                )}
                                <h5 className="main-title heading5">{this.state.exam != null ? this.state.exam.post.title : 'Examination Result'} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-table">
                        <div className="table-all table-responsive">
                            <ReactTable.Table className="table">
                                <ReactTable.Thead>
                                    <ReactTable.Tr>
                                        <ReactTable.Td>{t('exam:profile')}</ReactTable.Td>
                                        <ReactTable.Td>{t('exam:regno')}</ReactTable.Td>
                                        <ReactTable.Td>{t('student')}</ReactTable.Td>
                                        <ReactTable.Td>{t('exam:subject')}</ReactTable.Td>
                                        <ReactTable.Td>{t('exam:examRoutine')}</ReactTable.Td>
                                        {this.state.exam != null && this.state.exam.is_online == true && <ReactTable.Td>{t('todo:check')}</ReactTable.Td>}
                                        <ReactTable.Td>
                                            {t('exam:marks')}{' '}
                                            {this.state.exam != null && (
                                                <small>
                                                    [ {this.state.exam.pass_mark} / {this.state.exam.full_mark} ]
                                                </small>
                                            )}
                                        </ReactTable.Td>
                                    </ReactTable.Tr>
                                </ReactTable.Thead>
                                <ReactTable.Tbody>{this.getStudent()}</ReactTable.Tbody>
                            </ReactTable.Table>
                        </div>
                    </div>
                    <div className="main-form">
                        <div className="mt-3">
                            <Button type="submit" text={t('common:submit')} main={true} onClick={this._onSave} />
                        </div>
                    </div>
                </div>
                {this.checkAnswer()}
            </div>
        )
    }
}

const ResultAssign = withTranslation()(connect(mapStateToProps)(ResultAssignComponent))

export { ResultAssign }
