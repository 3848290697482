import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addCourse, viewCourse, viewCourseProgress, viewCourseContent, viewCourseModule, viewCourseModuleItems, getCourseItems } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'
import * as UserType from '~/helper/constant/user_type'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

export class AbstractCourseInsightsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            courseCount: 0,
            loading: true,
            loading_text: 'Loading',
            activeTab: 0,
            courseData: null,
            courseModule: [],
            courseContent: [],
            courseModuleData: [],
            class_id: this.props.match.params.id,
            course_id: this.props.match.params.course_id,
            course_progress: [],
            course: [],
            classes: [],
            subject: [],
            accessCount: 0,
        }
        this._handleChange = this._handleChange.bind(this)
        this.loadCourseProgress = this.loadCourseProgress.bind(this)
        this.loadCourseModule = this.loadCourseModule.bind(this)
        this.loadCourse = this.loadCourse.bind(this)
    }

    componentDidMount() {
        this.loadCourse()
        this.loadSubject()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadCourseProgress() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseProgress(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const course_progress = response.data.course_progress
                this.setState(
                    {
                        course_progress,
                    },
                    () => this.loadCourseModule()
                )
            }
        })
    }

    loadCourseModule() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourseModule(this.props.match.params.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseModule = response.data.course_module
                const course_progress = this.state.course_progress
                let courseModuleData = []
                let courseContent = []
                let accessCount = this.state.accessCount
                courseModule.map((item, index) => {
                    courseContent = []
                    let inProgressUsers = 0
                    let courseContentCount = 0
                    let courseContentCompletion = 0
                    let users = []
                    const courseModuleSelect = {
                        value: item.id,
                        label: item.name,
                    }
                    courseModuleData.push(courseModuleSelect)
                    item.content.map((obj, ind) => {
                        const cp = course_progress.filter((el) => {
                            return obj.id == el.course_content_id && el.user_progress == 2
                        })
                        cp.map((o, i) => {
                            users[o.user_school_id] = users[o.user_school_id] ? users[o.user_school_id] + 1 : 1
                            courseContentCompletion++
                        })
                        courseContentCount++
                        courseContent.push(obj)
                    })
                    let totalCompleted = 0
                    users.map((item) => {
                        if (item == courseContentCount) {
                            totalCompleted++
                        }
                    })
                    if (courseContentCompletion != 0) {
                        inProgressUsers = courseContentCompletion
                    } else {
                        inProgressUsers = 0
                    }
                    if (accessCount > 0) {
                        const progress = parseFloat(inProgressUsers / (courseContentCount * accessCount)) * 100
                        courseModule[index]['total_completed'] = totalCompleted
                        courseModule[index]['inprogress_count'] = progress.toFixed(2)
                    } else {
                        courseModule[index]['total_completed'] = 0
                        courseModule[index]['inprogress_count'] = 0
                    }
                })
                this.setState({
                    accessCount,
                    courseContent,
                    courseModule,
                    courseModuleData,
                })
            }
        })
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getCourseItems(this.state.course_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const courseData = response.data.post
                let accessCount = 0
                courseData.access_information.map((item) => {
                    if (UserType.Student == item.user_type) {
                        accessCount++
                    }
                })
                this.setState({
                    courseData,
                    accessCount,
                })
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.state.class_id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                        },
                        () => {
                            this.loadCourseProgress()
                        }
                    )
                }
            })
        } else {
            this.loadCourseProgress()
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
