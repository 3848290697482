import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractBooksAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import moment from 'moment'

class BooksAddComponent extends AbstractBooksAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._selectList = this._selectList.bind(this)
        this._setPublishedDate = this._setDate.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
    }
    _setDate(selectDate, data) {
        this._handleChange(selectDate, data)
    }
    _selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    _setShelf(data, i) {
        const shelfElements = this.state.shelfElements
        shelfElements[i].defaultShelf = data
        shelfElements[i].shelves_id = data.value

        const shelfErrors = [...this.state.shelfErrors]
        shelfErrors[i].shelves_id = null

        this.setState({
            shelfElements: shelfElements,
            shelfErrors,
        })
    }

    _setQuantity(data, i) {
        const shelfElements = this.state.shelfElements
        shelfElements[i].quantity = data
        this.setState({
            shelfElements: shelfElements,
        })
    }

    addRow() {
        let shelfElements = this.state.shelfElements
        let shelfErrors = this.state.shelfErrors
        shelfElements.push({
            shelves_id: '',
            quantity: '',
        })
        shelfErrors.push({
            shelves_id: null,
            quantity: null,
        })

        this.setState({
            shelfElements,
            shelfErrors,
        })
    }
    removeRow(index) {
        let shelfElements = this.state.shelfElements
        shelfElements.splice(index, 1)
        this.setState({
            shelfElements,
        })
    }
    loadBookShelf() {
        const { t } = this.props
        const shelfElements = this.state.shelfElements
        const selectedShelves = []
        const data = shelfElements.map((item, index) => {
            selectedShelves.length = 0;

            for (let i = 0; i < shelfElements.length; i++) {
                console.log(shelfElements)
                const selectedShelf = shelfElements[i].defaultShelf
                if (selectedShelf && !selectedShelves.includes(selectedShelf.value)) {
                    selectedShelves.push(selectedShelf.value)
                }
            }


            const activeShelves = this.state.activeBookShelf.filter((shelf) => {
                return !selectedShelves.includes(shelf.value)
            })
            // Update selectedShelves array with the current selected shelf
            if (item.defaultShelf) {
                selectedShelves.push(item.defaultShelf.value)
            }

            
            return (
                <tr>
                    <td>
                        <Select
                            className="mb-3 bookShelfAbove"
                            options={activeShelves}
                            placeholder={t('common:select')}
                            onChange={(data) => this._setShelf(data, index)}
                            searchable={true}
                        />
                    </td>
                    <td>
                        <Input
                            min={0}
                            type="number"
                            className="form-control mb-3"
                            id="heading"
                            placeholder={t('accounting:quantity')}
                            value={item.quantity}
                            onChange={(key, val) => this._setQuantity(val, index)}
                        />
                        {this.state.shelfErrors[index].quantity && (
                            <label className="table-error error text-danger">
                                {this.state.shelfErrors[index].quantity}
                            </label>
                        )}
                    </td>

                    <td>
                        <div className="d-flex align-items-center mb-3">
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                            <Button
                                onClick={() => this.removeRow(index)}
                                type="button"
                                text={SVG.deleteComment()}
                                main={false}
                            />
                        </div>
                    </td>
                </tr>
            )
        })
        return data
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="library" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.books.view}>{t('library:books')} > </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('library:addbooks')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:title')} *</label>
                                    <Input
                                        name="title"
                                        onChange={this._handleChange}
                                        placeholder={t('common:title')}
                                        type="text"
                                        value=""
                                    />
                                    {this.state.errors.title && (
                                        <label className="error text-danger">{this.state.errors.title}</label>
                                    )}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:isbn')} *</label>
                                    <Input
                                        name="isbn"
                                        onChange={this._handleChange}
                                        placeholder={t('library:isbn')}
                                        type="text"
                                        value=""
                                    />

                                    {this.state.errors.isbn &&(
                                        <label className="error text-danger">
                                            {this.state.errors.isbn}
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('calendar:author')} *</label>
                                    <Input
                                        name="author"
                                        onChange={this._handleChange}
                                        placeholder={t('calendar:author')}
                                        type="text"
                                        value=""
                                    />
                                    {this.state.errors.author && (
                                        <label className="error text-danger">{this.state.errors.author}</label>
                                    )}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:editions')} *</label>
                                    <Input
                                        name="edition"
                                        onChange={this._handleChange}
                                        placeholder={t('library:editions')}
                                        type="text"
                                        value=""
                                    />
                                    {this.state.errors.edition && (
                                        <label className="error text-danger">{this.state.errors.edition}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:booktype')} *</label>
                                    <Select
                                        className="mb-3"
                                        name="selectedBookType"
                                        options={this.state.bookType}
                                        value={this.state.defaultBookType || null}
                                        placeholder={t('common:select')}
                                        onChange={(data) => this._selectList('BookType', data)}
                                        searchable={true}
                                    />
                                    {this.state.errors.selectedBookType && (
                                        <label className="error text-danger">
                                            {this.state.errors.selectedBookType}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:bookscategory')} *</label>
                                    <Select
                                        className="mb-3"
                                        name="book_categories_id"
                                        options={this.state.bookCategories}
                                        value={this.state.defaultBookCategories}
                                        onChange={(data) => this._selectList('BookCategories', data)}
                                        placeholder={t('common:select')}
                                        searchable={true}
                                    />
                                    {this.state.errors.selectedBookCategories &&(
                                        <label className="error text-danger">
                                            {this.state.errors.selectedBookCategories}
                                        </label>
                                    )}
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('library:publisheddate')} *</label>
                                    <DatePicker
                                        placeholderText={t('library:publisheddate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this._setDate('publishedDate', data)}
                                        selected={this.state.publishedDate}
                                    />
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:addeddate')} *</label>
                                    <DatePicker
                                        name="addedDate"
                                        placeholderText={t('library:addeddate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this._setDate('addedDate', data)}
                                        selected={this.state.addedDate}
                                    />
                                    {this.state.errors.addedDate && (
                                        <label className="error text-danger">{this.state.errors.addedDate}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('classes:subject.add.descriptionLabel')} *</label>
                                    <TextArea
                                        name="description"
                                        onChange={this._handleChange}
                                        placeholder={t('classes:subject.add.descriptionPlaceholder')}
                                        type="text"
                                        value=""
                                    />
                                    {this.state.errors.description && (
                                        <label className="error text-danger">{this.state.errors.description}</label>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>

                    <>
                        <hr />
                        <div className="fee-title-btn">
                            <h6>{t('library:bookshelves')}:</h6>
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                        </div>
                        <hr />
                        <div className="main-table">
                            <div className="table-all table-responsive">
                                <table className="table responsiveTable fee_table">
                                    <thead>
                                        <tr>
                                            <th>{t('library:shelf')}</th>
                                            <th>{t('accounting:quantity')}</th>
                                            <th>{t('fee:action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.loadBookShelf()}</tbody>
                                </table>
                                {this.state.shelfElements.length == 0 && (
                                    <p className="no-data">
                                        {' '}
                                        {SVG.nodata()} {t('library:nobookshelves')}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>

                    <div className="form-button text-left">
                        <Button onClick={this._onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const BooksAdd = withTranslation()(connect(mapStateToProps)(BooksAddComponent))

export { BooksAdd }
