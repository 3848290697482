import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractBookIssueAddComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'

class BookIssueAddComponent extends AbstractBookIssueAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setDate = this._setDate.bind(this)
        this._selectList = this._selectList.bind(this)
        this.selectBookList = this.selectBookList.bind(this)
    }

    _setDate(selectDate, data) {
        console.log(selectDate, data)
        this._handleChange(selectDate, data)
    }
    selectBookList(data) {
        const book = this.state.bookObject.find((shelf) => shelf.id === data.value)
        this.setState({
            defaultBook: data,
            selectedBook: data.value,
            bookShelves: book.book_shelf.map((shelve) => {
                return {
                    label: shelve.shelves.name,
                    value: shelve.id,
                }
            }),
        })
    }

    _selectList(selecttype, data) {
        this.setState({
            [`default${selecttype}`]: data,
            [`selected${selecttype}`]: data.value,
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="library" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.library.menu}>{t('library:library')} > </a>
                            </li>
                            <li>
                                <a href={route.admin.library.bookissue.view}>{t('library:bookissue')} > </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('library:addbookissue')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:books')}*</label>
                                    <Select
                                        name="selectedBook"
                                        options={this.state.books}
                                        placeholder={t('common:select')}
                                        searchable={true}
                                        onChange={this.selectBookList}
                                    />
                                    {this.state.errors.selectedBook && (
                                        <label className="error text-danger">{this.state.errors.selectedBook}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:bookshelves')} *</label>
                                    <Select
                                        name="selectedBookShelves"
                                        options={
                                            this.state.selectedBook
                                                ? this.state.bookShelves
                                                : [{ label: 'Select a book first', value: null }]
                                        }
                                        placeholder={t('common:select')}
                                        searchable={true}
                                        onChange={(data) => this._selectList('BookShelves', data)}
                                    />
                                    {this.state.errors.selectedBookShelves && (
                                        <label className="error text-danger">
                                            {this.state.errors.selectedBookShelves}
                                        </label>
                                    )}
                                </div>

                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('sidebar:users')} *</label>
                                    <Select
                                        name="users"
                                        value={this.state.defaultUser || null}
                                        options={this.state.users}
                                        placeholder={t('common:select')}
                                        searchable={true}
                                        onChange={(data) => this._selectList('User', data)}
                                    />
                                    {this.state.errors.selectedUser && (
                                        <label className="error text-danger">{this.state.errors.selectedUser}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('income:issuedate')} *</label>
                                    <DatePicker
                                        name="issueDate"
                                        placeholderText={t('income:issuedate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this._setDate('issueDate', data)}
                                        selected={this.state.issueDate}
                                    />
                                    {this.state.errors.issueDate && (
                                        <label className="error text-danger">{this.state.errors.issueDate}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('income:duedate')} *</label>
                                    <DatePicker
                                        placeholderText={t('income:duedate')}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        onChange={(data) => this._setDate('dueDate', data)}
                                        selected={this.state.dueDate}
                                    />
                                    {this.state.errors.dueDate && (
                                        <label className="error text-danger">{this.state.errors.dueDate}</label>
                                    )}
                                </div>
                            </div>
                            <div className="row clearfix">
                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('library:returndate')}</label>
                                        <DatePicker
                                            placeholderText={t('library:returndate')}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            onChange= {(data)=>this._setDate("returnDate", data)}
                                            selected={this.state.returnDate}

                                        />
                                </div> */}

                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('library:issuestatus')} *</label>
                                    <Select 
                                    className="mb-3"
                                    name="issueDate"
                                    value={this.state.defaultIssueStatus || null}
                                    options={this.state.issueStatus}
                                    placeholder={t('common:select')}
                                    searchable={true}
                                    onChange= {(data)=>this._selectList('IssueStatus', data)}
                                    />
                                    {this.state.errors.selectedIssueStatus && <label className="error text-danger">{this.state.errors.selectedIssueStatus}</label>}
                                </div> */}

                                {/* <div className="col-sm-6 float-left form-group">
                                    <label className="form-title text-left">{t('library:fineamount')} </label>
                                    <Input name="fineAmount" onChange={this._handleChange} placeholder={t('library:fineamount')} type="number" value="" />                                
                                </div> */}
                            </div>

                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button
                                        onClick={this._onSave}
                                        type="submit"
                                        text={t('common:savebtn')}
                                        main={true}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const BookIssueAdd = withTranslation()(connect(mapStateToProps)(BookIssueAddComponent))

export { BookIssueAdd }
