import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import * as SVG from '~/assets/svg'
import { Header } from '~/components/header'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { Loading } from '../../../../../components/loader/loader'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { AbstractBalanceSheetComponent, mapStateToProps } from './abstract'
class BalanceSheetComponent extends AbstractBalanceSheetComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setStartDate = this._setStartDate.bind(this)
        this._setEndDate = this._setEndDate.bind(this)
    }

    _setStartDate(data) {
        this._handleChange('start_date', data)
    }
    _setEndDate(data) {
        this._handleChange('end_date', data)
    }

    getAccounts(data) {
        let total = 0
        const table = data.map((item, index) => {
            const amount = item.netAmount != null ? parseFloat(item.netAmount) : 0
            return (
                <Fragment>
                    <tr>
                        <td>
                            <span className="pl-5">
                                {item.account_code} - {item.account_name}
                            </span>
                        </td>
                        <td className={item.netAmount < 0 ? 'negative-amount' : ''}>
                            <small>{this.props.school.currency}</small>
                            &nbsp;
                            {amount < 0 ? (amount * -1).toFixed(2) : amount.toFixed(2)}
                        </td>
                    </tr>
                </Fragment>
            )
        })
        return table
    }

    getAccountType(data) {
        const table = data.map((item, index) => {
            let total = 0
            item.account.map((el, index) => {
                const amount = el.netAmount != null ? parseFloat(el.netAmount) : 0
                total = total + amount
            })
            return (
                <Fragment>
                    <tr className="invoice_table_border invoice_table_header_light">
                        <td>
                            <strong className="pl-4">{item.subType}</strong>
                        </td>
                        <td className={total < 0 ? 'negative-amount text-right' : 'text-right'}>
                            <small>{this.props.school.currency}</small>
                            &nbsp;
                            <strong>{total < 0 ? (total * -1).toFixed(2) : total.toFixed(2)}</strong>
                        </td>
                    </tr>
                    {this.getAccounts(item.account)}
                </Fragment>
            )
        })
        return table
    }
    getChartofAccount() {
        const { t } = this.props
        const accounts = this.state.chartAccounts

        const table = Object.keys(accounts).map((item, index) => {
            const data = accounts[item]
            let total = 0
            data.map((el, index) => {
                el.account.map((amt) => {
                    const amount = amt.netAmount != null ? parseFloat(amt.netAmount) : 0
                    total = total + amount
                })
            })
            return (
                <Fragment>
                    <tr className="invoice_table_border invoice_table_header_dark">
                        <td colSpan={2}>
                            <strong>{item}</strong>
                        </td>
                    </tr>
                    {this.getAccountType(data)}
                    <tr className="invoice_table_header_dark">
                        <td className="text-right">
                            <strong>{t('accounting:total')} :</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td className={total < 0 ? 'negative-amount' : ''}>
                            <small>{this.props.school.currency}</small>
                            &nbsp;
                            <strong>
                                {total < 0 ? parseFloat(total * -1).toFixed(2) : parseFloat(total).toFixed(2)}
                            </strong>
                        </td>
                    </tr>
                    <tr className="invoice_total_border">
                        <td colSpan={2}></td>
                    </tr>
                    {/* <tr className="page-break-after">
                        <td colSpan={2}></td>
                    </tr> */}
                    <tr>
                        <td colSpan={2}></td>
                    </tr>
                </Fragment>
            )
        })
        return table
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="double_entry" isAccount={true} />
                <Loading status={this.state.loading} text="Loading" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.doubleentry.menu}>
                                    {t('doubleentry:doubleentry')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('doubleentry:balancesheet')}</li>
                        </ul>
                        <div className="row clearfix ">
                            <div className="col-md-8 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:balancesheet')}</h5>
                            </div>
                            <div className="col-md-4 calendar-module text-right">
                                <div className="display-inline-block">
                                    <ReactToPrint
                                        documentTitle={
                                            this.props.school.short_code + '-' + t('doubleentry:balancesheet')
                                        }
                                        trigger={() => <a className="main-button">{t('activity:print')}</a>}
                                        content={() => this.ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-form">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="timetable-form mb-5">
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.start date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.start date')}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                defaultValue={this.state.start_date}
                                                selected={this.state.start_date}
                                                onChange={this._setStartDate}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.start_date != ''
                                                        ? convertADtoBS(this.state.start_date) != null
                                                            ? convertADtoBS(this.state.start_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.start_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.start_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-sm-12 form-group">
                                            <label className="form-title">{t('academic:table.end date')} :</label>
                                            <DatePicker
                                                placeholderText={t('academic:table.end date')}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                defaultValue={this.state.end_date}
                                                selected={this.state.end_date}
                                                onChange={this._setEndDate}
                                            />
                                            {this.props.school != null && this.props.school.date_format == 'BS' && (
                                                <label className="date-bs">
                                                    {this.state.end_date != ''
                                                        ? convertADtoBS(this.state.end_date) != null
                                                            ? convertADtoBS(this.state.end_date) + ' BS'
                                                            : 'Invalid Date Range'
                                                        : ''}
                                                </label>
                                            )}
                                            {this.state.errors.end_date && (
                                                <label className="error text-danger">
                                                    {this.state.errors.end_date}
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-sm-12 float-left form-group main-select">
                                            <button className="main-button" onClick={() => this._onSearch()}>
                                                {t('attendance:filter')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="timetable-form mb-5">
                                    <div className="row">
                                        <div className="col-lg-12 display-inline-block top-select-leaderboard">
                                            <div className="table-responsive">
                                                <table
                                                    className="table invoice_table"
                                                    ref={(div) => {
                                                        this.ref = div
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={2} className="text-center">
                                                                <div className="invoice-image">
                                                                    <img src={this.props.school.profile} />
                                                                </div>
                                                                <div className="invoice-title">
                                                                    {this.props.school.name}
                                                                </div>
                                                                <div className="invoice-sub-title">
                                                                    {t('doubleentry:balancesheet')}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="invoice_table_border">
                                                            <td colSpan={2} className="text-right">
                                                                <div className="invoice_detail">
                                                                    <strong>{t('gamification:duration')} : </strong>
                                                                    <br />
                                                                    {this.state.duration}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        {this.state.chartAccounts == null && (
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <p className="no-data">
                                                                        {SVG.nodata()}
                                                                        {t('common:table.nodata')}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {this.state.chartAccounts && this.getChartofAccount()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const BalanceSheet = withTranslation()(connect(mapStateToProps)(BalanceSheetComponent))

export { BalanceSheet }
