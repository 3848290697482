import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { getClass, addCourse, viewCourse, deletePost } from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import $ from 'jquery'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

type Props = {
    token: String,
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {
    classes: Object,
    course: Array,
    subject: Array,
    activeTab: Integer,
    isPrivate: Boolean,
    employee: Array,
    student: Array,
    selected_subject: Integer,
    errors: Object,
}

export class AbstractCourseViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            due_date: null,
            topic: null,
            courseCount: 0,
            activeTab: 0,
            currentSection: 1,
            selectedCourse: null,
            isPrivate: true,
            start_date: null,
            due_date: null,
            subject: [],
            employee: [],
            course: [],
            student: [],
            available_after_due: false,
            quiz_after_due: false,
            is_course_progressive: false,
            loading: true,
            loading_text: 'Loading',
            detail: {
                name: '',
                description: '',
                topic: '',
                privacy: '',
            },
            errors: {
                title: null,
                description: null,
                topic: null,
                start_date: null,
                due_date: null,
            },
            hideClass: false,
            hideSection: false,
            showSubject: true,
            selectedClass: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.switchBox = this.switchBox.bind(this)
        this.loadCourse = this.loadCourse.bind(this)
        this.openDetailProperties = this.openDetailProperties.bind(this)
        this.onSaveResult = this.onSaveResult.bind(this)
        this.openDeletePopup = this.openDeletePopup.bind(this)
        this.openAddPopup = this.openAddPopup.bind(this)
    }

    componentDidMount() {
        this.loadSubject()
        this.clearState()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    switchBox(direction) {
        const currentSection = this.state.currentSection
        if (currentSection < 3 && direction == 'next') {
            if (currentSection == 1) {
                let errors = validation.required(Object.keys(this.state.errors), this.state)
                const validate = Object.keys(errors).filter((item) => errors[item] != null)
                if (validate.length == 0) {
                    this.setState({
                        currentSection: currentSection + 1,
                    })
                } else {
                    this.setState({ errors })
                    return
                }
            }
            this.setState({
                currentSection: currentSection + 1,
            })
        }
        if (currentSection > 1 && direction == 'prev') {
            this.setState({
                currentSection: currentSection - 1,
            })
        }
    }

    loadCourse() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewCourse(this.props.match.params.id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.setState(
                    {
                        course: response.data.post,
                    },
                    () => {}
                )
            }
        })
    }

    loadSubject() {
        if (this.props.match.params.id != 0 && this.props.match.params.id != -1) {
            this._handleChange('loading_text', 'Loading')
            this._handleChange('loading', true)
            getClass(this.props.match.params.id, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            classes: response.data.class,
                            subject: response.data.class.subject,
                            hideClass: true,
                            selectedClass: this.props.match.params.id,
                        },
                        () => {
                            this.loadCourse()
                        }
                    )
                }
            })
        } else {
            this._handleChange('activeTab', 'other')
            this.setState({
                hideClass: true,
                hideSection: true,
                showSubject: false,
                selectedClass: null,
            })
            this.loadCourse()
        }
    }

    openDetailProperties(index) {
        const selectedCourse = this.state.course.filter((item) => item.id == index)[0]
        this.setState({
            detail: {
                title: selectedCourse.title,
                description: selectedCourse.description,
                topic: selectedCourse.course.topic,
                createdOn: moment(selectedCourse.created_at).format('YYYY-MM-DD'),
                privacy: selectedCourse.restriction == 1 ? 'Private' : 'Public',
            },
        })
        $('#details-course').modal('show')
    }

    openAddPopup() {
        this.clearState()
        $('#modal_add').modal('show')
    }

    openDeletePopup(index) {
        this.setState(
            {
                selectedCourse: index,
            },
            () => $('#delete-course').modal('show')
        )
    }

    checkDate(start_date, end_date) {
        const { t } = this.props
        const start = moment(start_date, 'yyyy-MM-DD')
        const end = moment(end_date, 'yyyy-MM-DD')
        if (end.isBefore(start)) {
            return t('toaster:invalidDate')
        }
        return null
    }

    _onSave(e) {
        const { t } = this.props
        e.preventDefault()
        let errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)

        if (validate.length == 0) {
            const checkDate = this.checkDate(this.state.start_date, this.state.due_date)
            if (checkDate != null) {
                errors['start_date'] = checkDate
                errors['due_date'] = checkDate
                this.setState({ errors }, () => {
                    toastMessage('error', t('toaster:recheckField'))
                })
            }
            const validate = Object.keys(errors).filter((item) => errors[item] != null)
            if (validate.length == 0) {
                this._handleChange('loading_text', 'Saving')
                this._handleChange('loading', true)
                const data = {
                    title: this.state.title,
                    description: this.state.description,
                    restriction: this.state.isPrivate,
                    topic: this.state.topic,
                    available_after_due: this.state.available_after_due,
                    quiz_after_due: this.state.quiz_after_due,
                    // is_progressive: this.state.is_course_progressive,
                    is_progressive: true,
                    from_date: moment(this.state.start_date).format('yyyy-MM-DD'),
                    to_date: moment(this.state.due_date).format('yyyy-MM-DD'),
                }
                if (this.props.match.params.id != 0) {
                    data.class_access = [parseInt(this.props.match.params.id)]
                }
                if (this.state.isPrivate) {
                    if (this.state.student != null && this.state.employee != null) {
                        data.access = this.state.employee.concat(this.state.student)
                    } else if (this.state.student != null) {
                        data.access = this.state.student
                    } else {
                        data.access = this.state.employee
                    }
                    if (this.state.selected_subject > 0) {
                        data.subject_id = this.state.selected_subject
                    }
                }
                addCourse(data, this.props.token).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                        this.loadCourse()
                        $('#close_add').trigger('click')
                    } else {
                        toastMessage('error', response.message)
                        if (response.data) {
                            if ('errors' in response.data) {
                                this.setState({ errors: response.data.errors })
                            }
                        }
                    }
                })
            }
        } else {
            toastMessage('error', t('toaster:recheckField'))
        }
    }

    onSaveResult() {
        const { t } = this.props
        const result = this.state.marksList.filter((item) => item.marks != '')
        if (result.length > 0) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            const data = {
                exam_id: this.props.match.params.routineId,
                marks: grade,
                onlineResult: this.state.onlineMarksList,
            }
            saveResult(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                }
            })
        } else {
            toastMessage('error', t('toaster:insertMarksMsg'))
        }
    }

    deleteCourse() {
        this._handleChange('loading_text', 'Deleting')
        this._handleChange('loading', true)

        deletePost(this.state.selectedCourse, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadCourse()
                $('#close_delete_modal').trigger('click')
                this.setState({
                    selectedCourse: null,
                })
                // window.reload();
            } else {
                toastMessage('error', response.message)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).attr('id')
            if (id == 'modal_add') {
                component._handleChange('title', '')
                component._handleChange('description', '')
                component._handleChange('start_date', '')
                component._handleChange('due_date', '')
                component._handleChange('topic', '')
                component._handleChange('student', [])
                component._handleChange('employee', [])
                component._handleChange('isPrivate', true)
                component._handleChange('selected_subject', null)
                component._handleChange('currentSection', 1)
                component._handleChange('available_after_due', false)
                component._handleChange('quiz_after_due', false)
                component._handleChange('is_course_progressive', false)
                const errors = {
                    title: null,
                    description: null,
                    start_date: null,
                    due_date: null,
                    topic: null,
                }
                component._handleChange('errors', errors)
            }
        })
    }
}

const AbstractCourseView = withTranslation()(AbstractCourseViewComponent)

export { AbstractCourseView }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
