import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { activeClass } from '~/services/api'
import { SubjectDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import { redirect } from '~/helper/function/util'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    classAry: Array,
    data: Array,
    selectedClass: String,
    errors: Object,
}

export class AbstractSubjectMenuComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            classAry: [],
            data: [],
            selectedClass: null,
            defaultClass: null,
            error: false,
        }
        this._handleChange = this._handleChange.bind(this)
        this.getURL = this.getURL.bind(this)
    }

    componentDidMount() {
        this.loadClass()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    getURL() {
        if (this.state.selectedClass == null) {
            this.setState({ error: true })
        } else {
            redirect(route.admin.school.subject.view + '/' + this.state.selectedClass, this.props.history)
        }
    }

    loadClass() {
        this._handleChange('loading', true)
        activeClass(this.props.token).then((response) => {
            this._handleChange('loading', false)
            let classAry = []
            let dataList = []
            if (response.code == API_SUCCESS) {
                response.data.class.map((item, index) => {
                    classAry.push({ value: item.id, label: item.name + ' - ' + item.code })
                })
                this.setState({
                    defaultClass: classAry[0],
                    selectedClass: classAry[0].value,
                    data: dataList,
                    classAry,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
