import { post, get } from '../request'

export const viewBookShelves = (token) =>{
    return new Promise(function(resolve, reject){
        try{
            get('book-shelf', token).then((response)=>{
                resolve(response)

            })
        }catch($e){
            resolve(null)
        }
    })
}

export const viewBookShelf = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('shelf', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        } 
    })
}

export const addBookShelf = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('shelf/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}


export const editBookShelf = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('shelf/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const detailBookShelf = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('shelf/detail/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateBookShelf = (id, data, token)=>{
    return new Promise(function(resolve, reject){
        try{
            post('shelf/update/' + id, token, data).then((response)=>{
                resolve(response)
            })
        }catch($e){
            resolve(null)
        }

    })
}

export const toggleBookShelf = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('shelf/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const activeBookShelf = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('shelf/active/', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}