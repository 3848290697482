import React from 'react'
import { connect } from 'react-redux'
import { AbstractDoubleEntryComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as SVG from '~/assets/svg'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'

class DoubleEntryComponent extends AbstractDoubleEntryComponent{
    constructor(props){
        super(props)
        this.state ={
            ...this.state,
        }
    }
    render() {
        const {t} = this.props
        return (
            <div className="main-page">
                <Sidebar id="double_entry" isAccount={true}/>
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                {t('doubleentry:doubleentry')}
                            </li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('doubleentry:doubleentry')}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.doubleentry.chartofaccount.view)}>
                                    <a href={route.admin.accounting.doubleentry.chartofaccount.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.doubleentry.chartofaccount.view} className="folder-name">
                                        {t('doubleentry:chartofaccount')} 
                                        <span> {t('doubleentry:managechartofaccount')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.doubleentry.journalaccount.view)}>
                                    <a href={route.admin.accounting.doubleentry.journalaccount.view} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.doubleentry.journalaccount.view} className="folder-name">
                                        {t('doubleentry:journalaccount')} 
                                        <span> {t('doubleentry:managejournalaccount')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.doubleentry.ledgersummary)}>
                                    <a href={route.admin.accounting.doubleentry.ledgersummary} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.doubleentry.ledgersummary} className="folder-name">
                                        {t('doubleentry:ledgersummary')} 
                                        <span> {t('doubleentry:manageledgersummary')}</span>
                                    </a>
                                </div>
                            </div> 

                             <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.doubleentry.balancesheet)}>
                                    <a href={route.admin.accounting.doubleentry.balancesheet} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.doubleentry.balancesheet} className="folder-name">
                                        {t('doubleentry:balancesheet')} 
                                        <span> {t('doubleentry:managebalancesheet')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.accounting.doubleentry.trialbalance)}>
                                    <a href={route.admin.accounting.doubleentry.trialbalance} className="folder-img">
                                        {SVG.studentcategory()}
                                    </a>
                                    <a href={route.admin.accounting.doubleentry.trialbalance} className="folder-name">
                                        {t('doubleentry:trialbalance')} 
                                        <span> {t('doubleentry:managetrialbalance')}</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const DoubleEntry = withTranslation()(connect(mapStateToProps)
(DoubleEntryComponent))

export {DoubleEntry}
