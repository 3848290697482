import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import AppContainer from './app/core'
import i18n from './app/services/i18n'
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <AppContainer />
    </I18nextProvider>,
    document.getElementById('root')
)
