export const fine_type = [
    {
        label: 'Flat',
        value: 0,
        translate: 'fee:flat',
    },
    {
        label: 'Percentage',
        value: 1,
        translate: 'fee:percentage',
    },
]
