import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { addLeaderboard, viewMedal, getAllActiveSchoolUser } from '~/services/api'
import * as route from '~/helper/constant/route'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { redirect } from '~/helper/function/util'
import * as UserType from '~/helper/constant/user_type'
import { user_role } from '~/helper/constant/leaderboard_filter'
import moment from 'moment'

type Props = {
    school: Object,
    dispatch: Dispatch<any>,
}

type State = {}

export class AbstractLeaderBoardAddComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: null,
            medalData: [],
            medalList: [],
            userList: [],
            title: null,
            issued_date: null,
            selectedUserSchool: null,
            users_school_id: null,
            selectedMedal: null,
            medal_id: null,
            points: null,
            description: null,
            errors: {
                users_school_id: null,
                medal_id: null,
                description: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._onSave = this._onSave.bind(this)
    }

    componentDidMount() {
        this.loadMedal()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadMedal() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewMedal(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const medalList = []
                response.data.medal.map((item) => {
                    const data = {
                        label: item.name + ' - ' + item.points + ' XP',
                        value: item.id,
                    }
                    medalList.push(data)
                })
                this.setState(
                    {
                        medalList,
                        medalData: response.data.medal,
                    },
                    () => {
                        this.loadAllUser()
                    }
                )
            }
        })
    }

    loadAllUser() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAllActiveSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const userList = []
                user_role.map((role) => {
                    const options = []
                    response.data.users
                        .filter((item) => item.user_type == role.value)
                        .map((item) => {
                            const data = {
                                label: item.user.name != null ? item.user.name + ' - ' + item.user.reg_no : item.user.reg_no,
                                value: item.id,
                            }
                            options.push(data)
                        })
                    const data = {
                        label: role.label,
                        options,
                    }
                    userList.push(data)
                })

                this.setState({
                    userList,
                })
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            const medal = this.state.medalData.find((item) => item.id == this.state.medal_id)
            const data = {
                title: medal.name,
                issued_date: moment().format('yyyy-MM-DD'),
                users_school_id: this.state.users_school_id,
                medal_id: this.state.medal_id,
                points: medal.points,
                description: this.state.description,
            }
            addLeaderboard(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.leaderboard.view, this.props.history)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
