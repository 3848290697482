import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewSection, addSection, editSection, toggleSection } from '~/services/api'
import { ClassDTO } from '~/model'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import moment from 'moment'
import $ from 'jquery'
import * as validation from '~/helper/constant/validation'
import { components } from 'react-select'
import { roll_type } from '~/helper/constant/roll_type'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    name: String,
    code: String,
    roll_type: String,
    class_id: Integer,
    errors: Object,
    section: Array,
    classes: Object,
    loading: Boolean,
    loading_text: String,
    defaultRoll: Object,
}

export class AbstractSectionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            name: '',
            code: '',
            roll_type: '',
            class_id: this.props.match.params.id,
            errors: {
                name: null,
                code: null,
                roll_type: null,
            },
            loading: false,
            loading_text: 'Loading',
            class: null,
            section: [
                {
                    section: {
                        field: 'text',
                        translate: 'classes:section.title',
                        route: false,
                        sortable: true,
                    },
                    code: {
                        field: 'text',
                        translate: 'classes:section.add.codeLabel',
                        route: false,
                        sortable: true,
                    },
                    roll_no_type: {
                        field: 'text',
                        translate: 'classes:section.add.rollNoLabel',
                        route: false,
                        sortable: true,
                    },
                    status: {
                        field: 'badge',
                        translate: 'status',
                        route: false,
                        sortable: true,
                    },
                    action: {
                        field: 'button',
                        translate: 'classes:section.add.action',
                        route: true,
                        sortable: false,
                    },
                },
            ],
        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
        this._onSave = this._onSave.bind(this)
        this._onEdit = this._onEdit.bind(this)
        this.clearState = this.clearState.bind(this)
    }

    componentDidMount() {
        this.loadSection()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    toggle(e) {
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleSection(link, this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                this.loadSection()
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                class_id: this.state.class_id,
                roll_type: this.state.roll_type,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            addSection(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    this._handleChange('name', '')
                    this._handleChange('code', '')
                    this._handleChange('roll_type', '')
                    $('#close_add').trigger('click')
                    this.loadSection()
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    _onEdit(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                name: this.state.name,
                code: this.state.code,
                class_id: this.state.class_id,
                roll_type: this.state.roll_type,
            }
            this._handleChange('loading_text', 'Saving')
            this._handleChange('loading', true)
            editSection(id, data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                this._handleChange('name', '')
                this._handleChange('code', '')
                this._handleChange('roll_type', '')
                if (response.code == API_SUCCESS) {
                    $('#close_edit_' + id).trigger('click')
                    setTimeout(this.loadSection(), 2000)
                    toastMessage('success', response.message)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    loadSection() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewSection(this.state.class_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let section = []
            let classes = null
            section.push(this.state.section[0])
            if (response.code == API_SUCCESS) {
                classes = response.data.class
                if (response.data.class.section) {
                    response.data.class.section.map((item, index) => {
                        const data: ClassDTO = item
                        let text = null
                        const roll_no = roll_type.find((item) => item.value == data.roll_type)
                        if (data.status == Status.ACTIVE) {
                            text = {
                                text: 'Active',
                                translate: 'statusActive',
                                badge: 'table-green',
                                modal: 'modal_section_' + index,
                            }
                        } else {
                            text = {
                                text: 'Inactive',
                                translate: 'statusInactive',
                                badge: 'table-red',
                                modal: 'modal_section_' + index,
                            }
                        }
                        const tableData = {
                            section: {
                                text: data.name,
                            },
                            code: {
                                text: data.code,
                            },
                            roll_no_type: {
                                text: roll_no.label,
                                translate: roll_no.translate,
                            },
                            status: text,
                            action: {
                                text: 'Edit',
                                translate: 'EditBtn',
                                modal: 'modal_section_edit_' + index,
                            },
                        }
                        section.push(tableData)
                    })
                }
            }
            this.setState(
                {
                    section,
                    classes,
                    name: '',
                    code: '',
                    roll_type: '',
                },
                () => {}
            )
            this.clearState()
        })
    }

    clearState() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')
            if (id != undefined) {
                const name = component.state.section[id + 1].section.text
                const code = component.state.section[id + 1].code.text
                const roll_no = roll_type.find((item) => item.label == component.state.section[id + 1].roll_no_type.text)
                component._handleChange('name', name)
                component._handleChange('code', code)
                if (roll_no != null) {
                    component._handleChange('roll_type', roll_no.value)
                    component._handleChange('defaultRoll', roll_no)
                } else {
                    component._handleChange('roll_type', null)
                    component._handleChange('defaultRoll', null)
                }
            } else {
                component._handleChange('name', '')
                component._handleChange('code', '')
                component._handleChange('roll_type', null)
                component._handleChange('defaultRoll', null)
            }
            const errors = {
                name: null,
                code: null,
                roll_type: null,
            }
            component._handleChange('errors', errors)
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
