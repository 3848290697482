import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import moment from 'moment'
import React, { Fragment } from 'react'
import Files from 'react-files'
import { withTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { Mention, MentionsInput } from 'react-mentions'
import MultiImageInput from 'react-multiple-image-input'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { ReactGallery } from '~/components/gallery'
import { Loading } from '~/components/loader'
import * as PostType from '~/helper/constant/post_type'
import * as Report from '~/helper/constant/report_type'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import { getBase64 } from '~/helper/function'
import { getReadableFileSize } from '~/helper/function/abstract'
import { toastMessage } from '~/helper/function/util'
import Hashtags from '~/helper/hashtag'
import { convertADtoBS } from '../../helper/function'
import { AbstractPostComponent, mapStateToProps } from './abstract'

export class PostComponent extends AbstractPostComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getPost = this.getPost.bind(this)
        this.getComment = this.getComment.bind(this)
        this.getModal = this.getModal.bind(this)
        this.showPreview = this.showPreview.bind(this)
        this.setImages = this.setImages.bind(this)
        this.loadGallery = this.loadGallery.bind(this)
        this.selectVideo = this.selectVideo.bind(this)
        this.loadVideo = this.loadVideo.bind(this)
        this.loadVideoPreview = this.loadVideoPreview.bind(this)
        this._tagUsers = this._tagUsers.bind(this)
        this.getSize = this.getSize.bind(this)
        this._showDeleteModal = this._showDeleteModal.bind(this)
        this.loadModal = this.loadModal.bind(this)
        this._onImageSave = this._onImageSave.bind(this)
        this._onVideoSave = this._onVideoSave.bind(this)
        this._deletePost = this._deletePost.bind(this)
        this._deleteComment = this._deleteComment.bind(this)
        this._showReportPostModal = this._showReportPostModal.bind(this)
        this._showReportCommentModal = this._showReportCommentModal.bind(this)
        this._report = this._report.bind(this)
        this._setReportType = this._setReportType.bind(this)
    }

    componentDidMount() {
        super.componentDidMount()
        this.loadModal()
    }

    _setReportType(data) {
        this.setState({
            selectedReportType: data,
            report_type: data.value,
        })
    }

    _report(e) {
        e.preventDefault()
        super._report().then((response) => {
            if (response) {
                $('#close_report').trigger('click')
            }
        })
    }

    _deletePost(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        super._deletePost(id)
        $('#close_delete_' + id).trigger('click')
    }

    _deleteComment(e) {
        e.preventDefault()
        const id = e.target.getAttribute('link')
        super._deleteComment(id)
        $('#close_delete_comment').trigger('click')
    }

    _onImageSave(e) {
        e.preventDefault()
        super._onImageSave(e).then((response) => {
            if (response) {
                $('#upload-picture-close').trigger('click')
            }
        })
    }

    _onVideoSave(e) {
        e.preventDefault()
        super._onVideoSave(e).then((response) => {
            if (response) {
                $('#upload-video-close').trigger('click')
            }
        })
    }

    _tagUsers(data, mentions) {
        data = data.replaceAll(/\s~\sCT[0-9]*</g, '<')
        const access = []
        mentions.map((item) => {
            access.push(parseInt(item.id))
        })
        this._handleChange('access', access)
        this._handleChange('description', data)
    }

    setImages(data) {
        this._handleChange('images', data)
    }

    selectVideo(data) {
        const { t } = this.props

        if (data.length > 0) {
            const media = this.state.video
            const uploadedFile = data[data.length - 1]
            getBase64(uploadedFile).then((file) => {
                if (file.error == null) {
                    media.push(file)
                    this._handleChange('video', media)
                }
            })
        }
    }

    getSize(media) {
        let count = 0
        media.map((data) => {
            count = count + data.size
        })
        return getReadableFileSize(count)
    }

    loadVideoPreview() {
        const video = this.state.video.map((item, index) => {
            return (
                <div className="video-preview">
                    <video src={item.data} controls />
                    <span className="video-svg" aria-label="Delete Image 0" role="button" onClick={this.removeVideo}>
                        {SVG.deelete()}
                    </span>
                </div>
            )
        })
        return video
    }

    uploadPicture() {
        const { t } = this.props
        return (
            <div id="upload-picture" data-id="upload-picture" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog create-post upload-photo main-box-shadow main-border-radius main-border main-background main-mb">
                    <button type="button" id="upload-picture-close" className="close" data-dismiss="modal">
                        ×
                    </button>
                    <span>{t('activity:uploadPhoto')}</span>
                    <form>
                        <label className="button create-label">
                            <Input
                                className={this.state.image_errors.image_description != null ? 'image-error' : ''}
                                name="image_description"
                                onChange={this._handleChange}
                                placeholder={t('activity:write something about this image...')}
                                type="text"
                                value={this.state.image_description}
                            />
                            {this.state.image_errors.image_description && (
                                <label className="error text-danger">{this.state.image_errors.image_description}</label>
                            )}
                        </label>
                        <div className="cleafix uploaded-img clearfix">
                            <div className="col-md-12">
                                <div className={this.state.image_errors.images != null ? 'no-image' : ''}>
                                    <MultiImageInput
                                        max={10}
                                        images={this.state.images}
                                        setImages={this.setImages}
                                        allowCrop={false}
                                        theme="light"
                                    />
                                    {this.state.image_errors.images && (
                                        <label className="error text-danger">{this.state.image_errors.images}</label>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="text-right post">
                            {Object.keys(this.state.images).length > 0 && (
                                <small>
                                    {t('activity:selected Image')} - {Object.keys(this.state.images).length}
                                </small>
                            )}

                            <button type="submit" onClick={this._onImageSave}>
                                {t('activity:post')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    uploadVideo() {
        const { t } = this.props
        return (
            <div id="upload-video" data-id="upload-video" className="modal fade mychat show" role="dialog">
                <div className="modal-dialog create-post upload-photo main-box-shadow main-border-radius main-border main-background main-mb">
                    <button type="button" id="upload-video-close" className="close" data-dismiss="modal">
                        ×
                    </button>
                    <span>{t('activity:uploadVideo')}</span>
                    <form>
                        <label className="button create-label">
                            <Input
                                className={this.state.video_errors.video_description != null ? 'image-error' : ''}
                                name="video_description"
                                onChange={this._handleChange}
                                placeholder={t('activity:write something about this video...')}
                                type="text"
                                value={this.state.video_description}
                            />
                            {this.state.video_errors.video_description && (
                                <label className="error text-danger">{this.state.video_errors.video_description}</label>
                            )}
                        </label>
                        <div className="cleafix uploaded-img clearfix">
                            <div className="col-md-12">
                                <div className={this.state.video_errors.video != null ? 'video no-image' : 'video'}>
                                    {this.state.video.length > 0 && this.loadVideoPreview()}
                                    {this.state.video.length == 0 && (
                                        <div className="files-dropzone upload-btn">
                                            <Files
                                                maxFileSize={100000000}
                                                onError={() => toastMessage('error', 'Max upload size is 100MB')}
                                                className="dropzone-text"
                                                onChange={this.selectVideo}
                                                accepts={['video/*']}
                                                minFileSize={0}
                                                clickable
                                            >
                                                {SVG.addvideopop()}
                                                <span>{t('activity:addvideo')}</span>
                                            </Files>
                                        </div>
                                    )}
                                </div>
                                {this.state.video_errors.video && (
                                    <label className="error text-danger">{this.state.video_errors.video}</label>
                                )}
                            </div>
                        </div>
                        <div className="text-right post">
                            <button type="submit" onClick={this._onVideoSave}>
                                {t('activity:post')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    getModal(item) {
        const { t } = this.props
        return (
            <div id={'modal_delete_' + item.id} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('activity:delete_msg')}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button
                                onClick={this._deletePost}
                                link={item.id}
                                type="button"
                                text={t('common:modal confirm')}
                                main={true}
                            />
                            <button
                                id={'close_delete_' + item.id}
                                type="upload"
                                className="cancel-button"
                                data-dismiss="modal"
                            >
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _showDeleteModal(id) {
        this.setState(
            {
                deleteComment: id,
            },
            () => {
                $('#modal_delete_comment').modal('show')
            }
        )
    }

    _showReportPostModal(id) {
        this.setState(
            {
                reportPost: id,
                reportComment: null,
            },
            () => {
                $('#modal_report').modal('show')
            }
        )
    }

    _showReportCommentModal(id) {
        this.setState(
            {
                reportComment: id,
                reportPost: null,
            },
            () => {
                $('#modal_report').modal('show')
            }
        )
    }

    getReportModal() {
        const { t } = this.props
        return (
            <div id="modal_report" data-id="modal_report" className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('alumni:report')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label className="form-title"> {t('alumni:reportContent')}:</label>
                                    <Select
                                        name="report_type"
                                        options={Report.all}
                                        placeholder={t('alumni:reportContent')}
                                        searchable={true}
                                        value={this.state.selectedReportType}
                                        onChange={this._setReportType}
                                    />
                                    {this.state.report_errors.report_type && (
                                        <label className="error text-danger setup-error text-left">
                                            {this.state.report_errors.report_type}
                                        </label>
                                    )}
                                </div>
                                <div className="col-md-12 form-group">
                                    <label className="form-title">{t('attendance:remarks')} * :</label>
                                    <TextArea
                                        value={this.state.report_remarks}
                                        name="report_remarks"
                                        onChange={this._handleChange}
                                        type="text"
                                        placeholder={t('attendance:remarks')}
                                    />
                                    {this.state.report_errors.report_remarks && (
                                        <label className="error text-danger setup-error text-left">
                                            {this.state.report_errors.report_remarks}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button onClick={this._report} type="button" text={t('common:modal confirm')} main={true} />
                            <button id={'close_report'} type="upload" className="cancel-button" data-dismiss="modal">
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getDeleteCommentModal() {
        const { t } = this.props
        return (
            <div id={'modal_delete_comment'} className="modal fade show" role="dialog">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">
                        <button type="button" className="close" data-dismiss="modal">
                            ×
                        </button>
                        <span>{t('warning')} !!!</span>
                    </div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">
                                {t('activity:are you sure you want to delete the comment?')}
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-center">
                            <Button
                                onClick={this._deleteComment}
                                link={this.state.deleteComment}
                                type="button"
                                text={t('common:modal confirm')}
                                main={true}
                            />
                            <button
                                id={'close_delete_comment'}
                                type="upload"
                                className="cancel-button"
                                data-dismiss="modal"
                            >
                                {t('common:modal cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    loadGallery(item) {
        if (item != null) {
            if (item.media != null) {
                const media = []
                item.media
                    .filter((data) => data.media_type.includes('data:image'))
                    .map((data) => {
                        const result = {
                            src: data.media_url,
                            width: 4,
                            height: 4,
                        }
                        media.push(result)
                    })
                if (media.length > 0) {
                    return <ReactGallery className="activityImage" images={media} showGallery={true} />
                }
            }
        }
        return null
    }

    loadVideo(item) {
        if (item != null) {
            if (item.media != null) {
                const video = item.media
                    .filter((data) => data.media_type.includes('video'))
                    .map((data, index) => {
                        return <video className="activityVideo" key={index} src={data.media_url} controls />
                    })
                return video
            }
        }
        return null
    }

    showPreview(item) {
        // const urls = grabUrl(text)
        // if (urls) {
        //     const modal = urls.map((item, index) => {
        //         return (
        //             <Fragment key={index}>
        //                 <ReactTinyLink cardSize="small" showGraphic={true} maxLine={2} minLine={1} url={item} />
        //                 <br />
        //             </Fragment>
        //         )
        //     })
        //     return modal
        // } else {
        //     return null
        // }
        if (item.link != null) {
            const data = item.link.map((linkItem, index) => {
                if (this.state.meta_tag[linkItem] != undefined) {
                    const metatag = this.state.meta_tag[linkItem]
                    return (
                        <a key={index} className="meta_wrapper" key={index} href={linkItem} target="_blank">
                            <div className="meta_img">
                                <img src={metatag.image} />
                            </div>
                            <div className="meta_content">
                                <header className="meta_header">
                                    <p className="">{metatag.title != null ? metatag.title : metatag.site_name}</p>
                                </header>
                                <div className="meta_description">
                                    <p className="">
                                        {metatag.description != null ? metatag.description : metatag.site_name}
                                    </p>
                                </div>
                                <footer className="meta_footer">
                                    <p className="">{metatag.url}</p>
                                </footer>
                            </div>
                        </a>
                    )
                } else {
                    return (
                        <a key={index} className="meta_wrapper" key={index} href={linkItem} target="_blank">
                            <div className="meta_img">
                                <Skeleton height="100%" />
                            </div>
                            <div className="meta_content">
                                <header className="meta_header">
                                    <p className="">
                                        <Skeleton />
                                    </p>
                                </header>
                                <div className="meta_description">
                                    <p className="">
                                        <Skeleton />
                                    </p>
                                </div>
                                <footer className="meta_footer">
                                    <p className="">
                                        <Skeleton />
                                    </p>
                                </footer>
                            </div>
                        </a>
                    )
                }
            })
            return data
        }
    }

    getComment(comment, id) {
        const { t } = this.props
        return (
            <div className="comments" id={'comment-add-' + id}>
                {comment.length > 0 &&
                    comment
                        .sort((a, b) => {
                            return a.id > b.id ? 1 : -1
                        })
                        .map((item, index) => {
                            const name = item.user.name != null ? item.user.name : item.user.reg_no
                            const profile =
                                item.user.user_detail != null
                                    ? item.user.user_detail.profile != null
                                        ? item.user.user_detail.profile
                                        : null
                                    : null
                            return (
                                <div key={index} className="comment-list">
                                    <div className="comment-img">
                                        {profile != null && <img src={profile} alt="post-user" />}
                                        {profile == null && <span>{name.charAt(0)}</span>}
                                    </div>
                                    <p>
                                        <ins className="comment-title">{name}</ins>
                                        {item.comment}

                                        {/* Dropdown for comment delete */}
                                        <div className="e-d-t">
                                            <div className="dropdown">
                                                <button
                                                    className="btn dropdown-toggle"
                                                    type="button"
                                                    data-toggle="collapse"
                                                    data-target={'#commentDelete' + index}
                                                >
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        icon="ellipsis-v"
                                                        color="#333"
                                                        size="lg"
                                                    />
                                                </button>
                                                <div
                                                    className="dropdown-menu post-option"
                                                    aria-labelledby={'commentDelete' + index}
                                                    id={'commentDelete' + index}
                                                    data-toggle="collapse"
                                                    x-placement="bottom-start"
                                                >
                                                    {this.props.school != null &&
                                                        (this.props.school.user_type == UserType.Admin ||
                                                            item.user.id == this.props.school.user_id) && (
                                                            <button
                                                                className="pointer dropdown-item"
                                                                onClick={() => this._showDeleteModal(item.id)}
                                                            >
                                                                {/* {SVG.deleteComment()} */}
                                                                {t('common:DeleteBtn')}
                                                            </button>
                                                        )}
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() => this._showReportCommentModal(item.id)}
                                                    >
                                                        {t('alumni:report')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            )
                        })}
            </div>
        )
    }

    getPost() {
        let classId = null
        if (this.props.school.user_type == UserType.Parents) {
            classId = this.state.children != null ? this.state.children.active_user_academics.section.class_id : null
        } else if (this.props.school.user_type == UserType.Student) {
            classId = this.props.school.active_user_academics.section.class_id
        }

        const { t } = this.props
        if (this.state.posts.length > 0) {
            const modal = this.state.posts.map((item, index) => {
                const author = item.author
                const name = author.user.name != null ? author.user.name : author.user.reg_no
                const profile =
                    author.user.user_detail != null
                        ? author.user.user_detail.profile != null
                            ? author.user.user_detail.profile
                            : null
                        : null
                const role = UserType.role_type.find((data) => data.value == author.user_type)
                let description = item.description ? item.description : ''
                if (description != '') {
                    this.state.users.map((el) => {
                        const regex = new RegExp('@.*~' + el.check, 'g')
                        description = description.replaceAll(
                            regex,
                            "<a href='/#/user/profile/" + el.id + "' className='post-link'>" + el.text + '</a>'
                        )
                    })
                }
                let comment = []
                if (this.props.postId == null) {
                    comment = item.latest_comment
                } else {
                    comment = item.comment
                }
                let liked = false
                if (item.user_like) {
                    liked = item.user_like.like ? true : false
                }
                const { t } = this.props
                return (
                    <div key={index} className="posted main-background main-mb">
                        <div className="posted-detail">
                            <div className="post-user">
                                {profile != null && <img src={profile} alt="post-user" />}
                                {profile == null && <div>{name.charAt(0)}</div>}
                            </div>
                            <div className="posted-middle">
                                <div className="other-user-detail">
                                    <span>{name}</span>
                                    <ins>
                                        {role.value != UserType.Custom && role.label}
                                        {role.value == UserType.Custom && author.role.name}
                                    </ins>
                                </div>
                                <span className="uploaded-time">{moment(item.created_at).fromNow()}</span>
                            </div>
                        </div>
                        {this.props.postId == null && (
                            <div className="e-d-t">
                                <div className="dropdown">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target={'#e-d-t' + index}
                                    >
                                        <FontAwesomeIcon className="icon" icon="ellipsis-v" color="#333" size="lg" />
                                    </button>
                                    <div
                                        className="dropdown-menu post-option"
                                        aria-labelledby={'e-d-t' + index}
                                        id={'e-d-t' + index}
                                        data-toggle="collapse"
                                        x-placement="bottom-start"
                                    >
                                        <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() => this._openPost(item.id)}
                                        >
                                            {t('activity:view')}
                                        </button>
                                        {(author.id == this.props.school.id ||
                                            this.props.school.user_type == UserType.Admin) && (
                                            <Fragment>
                                                <button
                                                    className="dropdown-item"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target={'#modal_delete_' + item.id}
                                                >
                                                    {t('common:delete')}
                                                </button>
                                                <button
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={() => this._toggleComment(item.id)}
                                                >
                                                    {item.isComment
                                                        ? t('activity:commentOff')
                                                        : t('activity:commentOn')}
                                                </button>
                                            </Fragment>
                                        )}
                                        <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() => this._showReportPostModal(item.id)}
                                        >
                                            {t('alumni:report')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.props.postId != null &&
                            (author.user.id == this.props.school.user_id ||
                                this.props.school.user_type == UserType.Admin) && (
                                <div className="e-d-t">
                                    <div className="dropdown">
                                        <button
                                            className="btn dropdown-toggle"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target={'#e-d-t' + index}
                                        >
                                            <FontAwesomeIcon
                                                className="icon"
                                                icon="ellipsis-v"
                                                color="#333"
                                                size="lg"
                                            />
                                        </button>
                                        <div
                                            className="dropdown-menu post-option"
                                            aria-labelledby={'e-d-t' + index}
                                            id={'e-d-t' + index}
                                            data-toggle="collapse"
                                            x-placement="bottom-start"
                                        >
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                                data-toggle="modal"
                                                data-target={'#modal_delete_' + item.id}
                                            >
                                                {t('common:delete')}
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                                onClick={() => this._toggleComment(item.id)}
                                            >
                                                {item.isComment ? t('activity:commentOff') : t('activity:commentOn')}
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                                onClick={() => this._showReportPostModal(item.id)}
                                            >
                                                {t('alumni:report')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        <div className="posted-main">
                            {description != null && (
                                <p className="post-paragraph">
                                    <Hashtags text={description} />
                                </p>
                            )}

                            {item.type == PostType.Medal && (
                                <Fragment>
                                    <div className="live-class courseBack">
                                        <div className="lv-img">{SVG.medal()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() => this._openRoute(route.leaderboard.view, item.id)}
                                            // href={route.leaderboard.view}
                                            className="live-class-detail"
                                        >
                                            <p>{t('gamification:medal')}</p>
                                            <span>
                                                {item.title} - {item.leaderboard.points} XP
                                            </span>
                                        </a>
                                    </div>
                                    <div className="live-class-info assign courseBack">
                                        <div className="live-class-detail ">
                                            <span>{item.leaderboard.medal.description}</span>
                                            {item.leaderboard.user_school != null && (
                                                <span>
                                                    {t('gamification:congrats')},{' '}
                                                    {item.leaderboard.user_school.user.name != null
                                                        ? item.leaderboard.user_school.user.name
                                                        : item.leaderboard.user_school.user.reg_no}{' '}
                                                    {t('gamification:achievementMsg')} + {item.leaderboard.points} XP.{' '}
                                                    {t('gamification:leaderboardMsg')}.
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                            {item.type == PostType.Course && (
                                <Fragment>
                                    <div className="live-class courseBack">
                                        <div className="lv-img">{SVG.courseactivity()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() =>
                                                this._openRoute(
                                                    this.props.school.user_type == UserType.Student ||
                                                        this.props.school.user_type == UserType.Parents
                                                        ? route.course.student.module +
                                                              '/' +
                                                              item.class_access[0] +
                                                              '/' +
                                                              item.id
                                                        : route.course.module +
                                                              '/' +
                                                              item.class_access[0] +
                                                              '/' +
                                                              item.id,
                                                    item.id
                                                )
                                            }
                                            // href={
                                            //     this.props.school.user_type == UserType.Student ||
                                            //     this.props.school.user_type == UserType.Parents
                                            //         ? route.course.student.module +
                                            //           '/' +
                                            //           (item.class_access != null ? item.class_access[0] : 0) +
                                            //           '/' +
                                            //           item.id
                                            //         : route.course.module +
                                            //           '/' +
                                            //           (item.class_access != null ? item.class_access[0] : 0) +
                                            //           '/' +
                                            //           item.id
                                            // }
                                            className="live-class-detail"
                                        >
                                            <p>{t('course:course')}</p>
                                            <span>{item.title}</span>
                                        </a>
                                    </div>
                                    <div className="live-class-info assign courseBack">
                                        <div className="live-class-detail ">
                                            {item.subject != null && (
                                                <span>
                                                    {t('classes:subject.title')}:{' '}
                                                    <ins>{item.subject.name + ' - ' + item.subject.code}</ins>
                                                </span>
                                            )}
                                            {item.from_date != null && (
                                                <span>
                                                    {t('todo:dueOn')}:
                                                    {this.props.school != null &&
                                                        this.props.school.school != null &&
                                                        this.props.school.school.date_format == 'BS' && (
                                                            <ins>
                                                                {convertADtoBS(item.to_date) != null
                                                                    ? convertADtoBS(item.to_date)
                                                                    : 'Invalid Date Range'}
                                                            </ins>
                                                        )}
                                                    {this.props.school != null &&
                                                        this.props.school.school != null &&
                                                        this.props.school.school.date_format == 'AD' && (
                                                            <ins>{item.to_date}</ins>
                                                        )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {this.props.postId == null && (
                                        <button
                                            className="vote"
                                            onClick={() =>
                                                this._openRoute(
                                                    this.props.school.user_type == UserType.Student ||
                                                        this.props.school.user_type == UserType.Parents
                                                        ? route.course.student.module +
                                                              '/' +
                                                              item.class_access[0] +
                                                              '/' +
                                                              item.id
                                                        : route.course.module +
                                                              '/' +
                                                              item.class_access[0] +
                                                              '/' +
                                                              item.id,
                                                    item.id
                                                )
                                            }
                                        >
                                            {t('activity:viewcourse')}
                                        </button>
                                    )}
                                </Fragment>
                            )}
                            {item.type == PostType.Job && (
                                <Fragment>
                                    <div className="live-class exam">
                                        <div className="lv-img">{SVG.postQuestion()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() =>
                                                this._openRoute(
                                                    route.alumni.job.detail + '/' + item.job_vacancy.id,
                                                    item.id
                                                )
                                            }
                                            // href={route.alumni.job.detail + '/' + item.job_vacancy.id}
                                            className="live-class-detail"
                                        >
                                            <p>{t('alumni:vacancyAlert')}</p>
                                            <span>{item.title}</span>
                                        </a>
                                    </div>
                                    <div className="live-class-info assign exam">
                                        <div className="live-class-detail ">
                                            <span>
                                                {t('alumni:company')}: <ins>{item.job_vacancy.alumni_company.name}</ins>
                                            </span>
                                            <span>
                                                {t('alumni:faculty')}: <ins>{item.job_vacancy.alumni_group.name}</ins>
                                            </span>
                                            <span>
                                                {t('alumni:designation')}:{' '}
                                                <ins>{item.job_vacancy.alumni_designation.name}</ins>
                                            </span>
                                            <span>
                                                {t('fromdate')}:
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.from_date) != null
                                                                ? convertADtoBS(item.from_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.from_date}</ins>
                                                    )}
                                            </span>
                                            <span>
                                                {t('todate')}:
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.to_date) != null
                                                                ? convertADtoBS(item.to_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.to_date}</ins>
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                    {this.props.postId == null && (
                                        <button
                                            className="vote"
                                            onClick={() =>
                                                this._openRoute(
                                                    route.alumni.job.detail + '/' + item.job_vacancy.id,
                                                    item.id
                                                )
                                            }
                                        >
                                            {t('common:viewBtn')} {t('alumni:vacany')}
                                        </button>
                                    )}
                                </Fragment>
                            )}
                            {item.type == PostType.Exam && (
                                <Fragment>
                                    <div className="live-class exam">
                                        <div className="lv-img">{SVG.postQuestion()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() =>
                                                this._openRoute(
                                                    this.props.school.user_type == UserType.Student ||
                                                        this.props.school.user_type == UserType.Parents
                                                        ? route.examination.exam.routine.schedule +
                                                              '/' +
                                                              item.id +
                                                              '/' +
                                                              classId
                                                        : route.examination.exam.routine.menu + '/' + item.id,
                                                    item.id
                                                )
                                            }
                                            // href={
                                            //     this.props.school.user_type == UserType.Student ||
                                            //     this.props.school.user_type == UserType.Parents
                                            //         ? route.examination.exam.routine.schedule +
                                            //           '/' +
                                            //           item.id +
                                            //           '/' +
                                            //           classId
                                            //         : route.examination.exam.routine.menu + '/' + item.id
                                            // }
                                            className="live-class-detail"
                                        >
                                            <p>{t('exam:title')}</p>
                                            <span>{item.title}</span>
                                        </a>
                                    </div>
                                    <div className="live-class-info assign exam">
                                        <div className="live-class-detail ">
                                            <span>
                                                {t('fromdate')}:{' '}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.from_date) != null
                                                                ? convertADtoBS(item.from_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.from_date}</ins>
                                                    )}
                                            </span>
                                            <span>
                                                {t('todate')}:{' '}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.to_date) != null
                                                                ? convertADtoBS(item.to_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.to_date}</ins>
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                    {this.props.postId == null && (
                                        <button
                                            className="vote"
                                            onClick={() =>
                                                this._openRoute(
                                                    this.props.school.user_type == UserType.Student ||
                                                        this.props.school.user_type == UserType.Parents
                                                        ? route.examination.exam.routine.schedule +
                                                              '/' +
                                                              item.id +
                                                              '/' +
                                                              classId
                                                        : route.examination.exam.routine.menu + '/' + item.id,
                                                    item.id
                                                )
                                            }
                                        >
                                            {t('activity:view Routine')}
                                        </button>
                                    )}
                                </Fragment>
                            )}
                            {item.type == PostType.Homework && (
                                <Fragment>
                                    <div className="live-class attendance">
                                        <div className="lv-img">{SVG.newtask()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() => this._openTodo(item.id)}
                                            //  href={route.todo.detail + '/' + item.id}
                                            className="live-class-detail"
                                        >
                                            <p>{t('todo:newTask')}</p>
                                            <span>{item.title}</span>
                                        </a>
                                    </div>
                                    <div className="live-class-info assign attendance">
                                        <div className="live-class-detail ">
                                            {item.subject != null && (
                                                <span>
                                                    {t('classes:subject.title')}:{' '}
                                                    <ins>{item.subject.name + ' - ' + item.subject.code}</ins>
                                                </span>
                                            )}
                                            <span>
                                                {t('todo:assignedBy')}: <ins>{name}</ins>
                                            </span>
                                            <span>
                                                {t('todo:dueOn')}:{' '}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.to_date) != null
                                                                ? convertADtoBS(item.to_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.to_date}</ins>
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                    {this.props.postId == null && (
                                        <button className="vote" onClick={() => this._openTodo(item.id)}>
                                            {t('activity:view Task')}
                                        </button>
                                    )}
                                </Fragment>
                            )}
                            {item.type == PostType.Question && (
                                <Fragment>
                                    <div className="live-class exam">
                                        <div className="lv-img">{SVG.postQuestion()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() =>
                                                this._openRoute(
                                                    route.examination.question_bank.question.view + '/' + item.id,
                                                    item.id
                                                )
                                            }
                                            className="live-class-detail"
                                        >
                                            <p>{t('activity:questionBank')}</p>
                                            <span>{item.title}</span>
                                        </a>
                                    </div>
                                    <button
                                        className="vote"
                                        onClick={() =>
                                            this._openRoute(
                                                route.examination.question_bank.question.view + '/' + item.id,
                                                item.id
                                            )
                                        }
                                    >
                                        {t('activity:view QuestionBank')}
                                    </button>
                                </Fragment>
                            )}
                            {item.type == PostType.Holiday && (
                                <Fragment>
                                    <div className="live-class">
                                        <div className="lv-img">{SVG.holiday()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() => this._openCalendar(item.from_date)}
                                            className="live-class-detail"
                                        >
                                            <p>{t('activity:holidayAlert')}</p>
                                            <span>
                                                {t('activity:occasion')}: <ins>{item.description}</ins>
                                            </span>
                                            <span>
                                                {t('activity:date')}:{' '}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.from_date) != null
                                                                ? convertADtoBS(item.from_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.from_date}</ins>
                                                    )}
                                            </span>
                                        </a>
                                    </div>
                                    {/* <button className="vote" onClick={() => this._openCalendar(item.from_date)}>
                                        {t('activity:view in Calendar')}
                                    </button> */}
                                </Fragment>
                            )}
                            {item.type == PostType.Event && (
                                <Fragment>
                                    <div className="live-class">
                                        <div className="lv-img">{SVG.holiday()}</div>
                                        <a
                                            href="javascript:void()"
                                            onClick={() => this._openCalendar(item.from_date)}
                                            // href={route.admin.event.schedule + '/' + item.id}
                                            className="live-class-detail"
                                        >
                                            <p>{t('activity:eventAlert')}</p>
                                            <span>
                                                {t('activity:occasion')}: <ins>{item.description}</ins>
                                            </span>
                                            <span>
                                                {t('activity:date')}:{' '}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'BS' && (
                                                        <ins>
                                                            {convertADtoBS(item.from_date) != null
                                                                ? convertADtoBS(item.from_date)
                                                                : 'Invalid Date Range'}
                                                        </ins>
                                                    )}
                                                {this.props.school != null &&
                                                    this.props.school.school != null &&
                                                    this.props.school.school.date_format == 'AD' && (
                                                        <ins>{item.from_date}</ins>
                                                    )}
                                            </span>
                                        </a>
                                    </div>
                                    <button className="vote" onClick={() => this._openCalendar(item.from_date)}>
                                        {t('common:viewCalendar')}
                                    </button>
                                </Fragment>
                            )}
                            {item.type == PostType.Drive && (
                                <div className="live-class note-feed">
                                    <div className="lv-img">{SVG.files()}</div>
                                    <a
                                        href="javascript:void()"
                                        onClick={() => this._openRoute(route.drive.folder + '/' + item.id, item.id)}
                                        // href={route.drive.folder + '/' + item.id}
                                        className="live-class-detail"
                                    >
                                        <p>{item.title}</p>
                                        {item.gallery == null && (
                                            <Fragment>
                                                <span>
                                                    {t('activity:size')}: <ins>0 {t('activity:MB')}</ins>
                                                </span>
                                                <span>
                                                    {t('activity:file')}: <ins>0 {t('activity:item')}</ins>
                                                </span>
                                            </Fragment>
                                        )}
                                        {item.gallery != null && (
                                            <Fragment>
                                                <span>
                                                    {t('activity:size')}:
                                                    <ins>
                                                        {item.gallery.media != null && this.getSize(item.gallery.media)}
                                                        {item.gallery.media == null && 0}
                                                        &nbsp;
                                                    </ins>
                                                </span>
                                                <span>
                                                    {t('activity:file')}:
                                                    <ins>
                                                        {item.gallery.media != null && item.gallery.media.length}
                                                        {item.gallery.media == null && 0}
                                                        &nbsp;{t('activity:item')}
                                                    </ins>
                                                </span>
                                            </Fragment>
                                        )}
                                    </a>
                                </div>
                            )}
                            {item.type == PostType.Post && (
                                <Fragment>
                                    {this.loadGallery(item.gallery)}
                                    {this.loadVideo(item.gallery)}
                                    {this.showPreview(item)}
                                </Fragment>
                            )}

                            <div className="liked-commented row">
                                {item.like_count <= 1 && (
                                    <span className="col-md-6 float-left">
                                        <span id={'like-count-' + item.id}>{item.like_count}</span>&nbsp;
                                        {t('activity:like')}
                                    </span>
                                )}
                                {item.like_count > 1 && (
                                    <span className="col-md-6 float-left">
                                        <span id={'like-count-' + item.id}>{item.like_count}</span>&nbsp;{' '}
                                        {t('activity:likes')}
                                    </span>
                                )}
                                <span className="col-md-6 float-left text-right">
                                    <div className="row">
                                        <span className="col-md-9 float-left text-right pr-0">
                                            <span id={'comment-count-' + item.id}>{item.view_count}</span> &nbsp;
                                            {item.comment_count <= 1 ? (
                                                <span>{t('activity:view')}</span>
                                            ) : (
                                                <span>{t('activity:views')}</span>
                                            )}
                                        </span>
                                        <span className="col-md-3 float-left text-right pl-0">
                                            <span id={'comment-count-' + item.id}>{item.comment_count}</span> &nbsp;
                                            {item.comment_count <= 1 ? (
                                                <span>{t('activity:comment')}</span>
                                            ) : (
                                                <span>{t('activity:comments')}</span>
                                            )}
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className="like-comment clearfix">
                                <div className="col-md-12">
                                    {liked && (
                                        <span
                                            id={'like-check-' + item.id}
                                            className="float-left liked"
                                            onClick={() => this._likePost(item.id)}
                                        >
                                            {SVG.like('svg_' + item.id, '#333')}
                                            {t('activity:like')}
                                        </span>
                                    )}
                                    {!liked && (
                                        <span
                                            id={'like-check-' + item.id}
                                            className="float-left"
                                            onClick={() => this._likePost(item.id)}
                                        >
                                            {SVG.like('svg_' + item.id, '#fff')}
                                            {t('activity:like')}
                                        </span>
                                    )}
                                    {item.type == PostType.Homework && (
                                        <span
                                            className="float-right text-right"
                                            onClick={() => this._openTodo(item.id)}
                                        >
                                            {item.comment_count == 0 && SVG.comment(null, '#fff')}
                                            {item.comment_count > 0 && SVG.comment(null, '#333')}
                                            {t('activity:comment')}
                                        </span>
                                    )}
                                    {item.type != PostType.Homework && (
                                        <span
                                            className="float-right text-right"
                                            onClick={() => this._openPost(item.id)}
                                        >
                                            {item.comment_count == 0 && SVG.comment(null, '#fff')}
                                            {item.comment_count > 0 && SVG.comment(null, '#333')}
                                            {t('activity:comment')}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {this.getComment(comment, item.id)}
                            <div className="comment-form">
                                {item.isComment == true && (
                                    <form>
                                        <TextArea
                                            value={this.state.comment[item.id]}
                                            name={'comment_' + item.id}
                                            onChange={this._handleCommentChange}
                                            className="comment"
                                            type="text"
                                            placeholder={t('activity:writecomment')}
                                        />
                                        <button
                                            type="button"
                                            className="comment"
                                            onClick={() => this._comment(item.id)}
                                        >
                                            {SVG.send()}
                                        </button>
                                    </form>
                                )}
                            </div>
                        </div>
                        {this.getModal(item)}
                    </div>
                )
            })
            return modal
        } else {
            return (
                <div className="text-center no-data">
                    {SVG.nodata()}
                    {t('common:table.nodata')}
                </div>
                // let data = []
                // for (let i = 0; i < 3; i++) {
                //     data.push(
                //         <div className="posted main-background main-mb">
                //             <div className="posted-detail">
                //                 <div className="post-user">
                //                     <Skeleton circle height="100%" containerClassName="avatar-skeleton" />
                //                 </div>
                //                 <div className="posted-middle">
                //                     <div className="other-user-detail">
                //                         <span>
                //                             <Skeleton width="30%" />
                //                         </span>
                //                         <ins>
                //                             <Skeleton width="20%" height={15} />
                //                         </ins>
                //                     </div>
                //                     <span className="uploaded-time">
                //                         <Skeleton width={30} />
                //                     </span>
                //                 </div>
                //             </div>
                //             <div className="posted-main">
                //                 <p className="post-paragraph">
                //                     <Skeleton width="100%" height={150} />
                //                 </p>
                //             </div>
                //         </div>
                //     )
                // }
                // return data
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <Fragment>
                {this.state.loader == true && <Loading status={this.state.loading} text="Loading" />}
                {this.props.create == true && (
                    <div className="create-post main-box-shadow main-border-radius main-border main-background main-mb">
                        <span>{t('activity:createpost')}</span>
                        <form>
                            <label className="button create-label" data-toggle="modal" data-target="#create-post">
                                <MentionsInput
                                    value={this.state.description}
                                    onChange={(event, newValue, plainText, mentions) => {
                                        this._tagUsers(newValue, mentions)
                                    }}
                                    markup="<a href='/#/user/profile/__id__' className='post-link'>__display__</a>"
                                    placeholder={t('activity:typeyourtexthere')}
                                    className={this.state.errors.description != null ? 'user_tags error' : 'user_tags'}
                                >
                                    <Mention
                                        type="user"
                                        trigger="@"
                                        data={this.state.users}
                                        markup="<a href='/#/user/profile/__id__' className='post-link'>__display__</a>"
                                        className="mentions__mention"
                                    />
                                </MentionsInput>
                                {/* <TextArea name="description" value={this.state.description} onChange={this._handleChange} className={this.state.errors.description != null && ( "error")} type="text" placeholder="Type Your Text Here...."/> */}
                            </label>
                            <div className="clearfix post-button">
                                <label className="button" data-toggle="modal" data-target="#upload-picture">
                                    <button type="button" className="add-photo">
                                        {SVG.addphoto()}
                                        {t('activity:addphoto')}
                                    </button>
                                </label>
                                <label className="button" data-toggle="modal" data-target="#upload-video">
                                    <button type="button" className="add-video">
                                        {SVG.addvideo()}
                                        {t('activity:addvideo')}
                                    </button>
                                </label>
                                {/* <label className="button" data-toggle="modal" data-target="#">
                                    <button type="button" className="add-qa">
                    {SVG.qa()}QA</button>
                                </label>
                                <label className="button" data-toggle="modal" data-target="#create-poll">
                                    <button type="button" className="add-poll">{SVG.poll()}Poll</button>
                                </label> */}
                                <label className="button float-right">
                                    <button type="submit" className="main-button" onClick={this._onSave}>
                                        {t('activity:post')}
                                    </button>
                                </label>
                            </div>
                        </form>
                    </div>
                )}
                {this.props.postId == null && this.props.postSearch != null && (
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <span
                                className="table-status table-green cursor float-right mb-2 mr-3"
                                data-toggle="collapse"
                                data-target="#search-dropdown"
                            >
                                {t('activity:filterOn')}
                            </span>
                        </div>
                    </div>
                )}
                {this.getPost()}
                {this.state.current_page != 0 && this.state.current_page != this.state.total_page && (
                    <div className="main-mb text-center">
                        <div className="load-more" onClick={this.loadPost}>
                            {t('activity:loadmore')}
                        </div>
                    </div>
                )}

                {this.uploadPicture()}
                {this.uploadVideo()}
                {this.getDeleteCommentModal()}
                {this.getReportModal()}
            </Fragment>
        )
    }

    loadModal() {
        let component = this
        $('.modal').on('show.bs.modal', function () {
            const id = $(this).data('id')

            if (id == 'upload-picture') {
                const image_errors = {
                    image_description: null,
                    images: null,
                }
                component._handleChange('image_errors', image_errors)
                component._handleChange('image_description', '')
                component._handleChange('images', {})
            }
            if (id == 'upload-video') {
                const video_errors = {
                    video_description: null,
                    video: null,
                }
                component._handleChange('video_errors', video_errors)
                component._handleChange('video_description', '')
                component._handleChange('video', [])
            }
            if (id == 'modal_report') {
                component.setState({
                    report_errors: {
                        report_type: null,
                        report_remarks: null,
                    },
                    report_remarks: '',
                    report_type: null,
                    selectedReportType: null,
                })
            }
        })
    }
}

const Post = withTranslation()(connect(mapStateToProps)(PostComponent))

export { Post }
