import $ from 'jquery'
import { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as route from '~/helper/constant/route'
import { Alumni, Student } from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import {
    deleteInvoiceRow,
    editInvoice,
    getAllSchoolUser,
    updateInvoice,
    viewAccountTax,
    viewProductCategory,
    viewProductService,
} from '~/services/api'
import { getAccountingEncryptedKey } from '../../../../../../helper/function'

type Props = {
    dispatch: Dispatch<any>,
}
type State = {}

export class AbstractInvoiceEditComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            loading_text: 'Loading',
            issue_date: '',
            due_date: '',
            userLists: [],
            defaultUserList: '',
            selectedUserList: '',
            selectedUser: '',
            ref_number: '',
            category: [],
            defaultCategory: '',
            selectedCategory: '',
            taxes: [],
            defaultTax: [],
            selectedTax: [],
            products: [],
            productElements: [],
            productErrors: [],
            status: 0,
            errors: {
                selectedUserList: null,
                issue_date: null,
                due_date: null,
                selectedCategory: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onSaveDraft = this.onSaveDraft.bind(this)
        this.onSavePublish = this.onSavePublish.bind(this)
    }
    componentDidMount() {
        this.loadTax()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    addRow() {
        let productElements = this.state.productElements
        let productErrors = this.state.productErrors

        productElements.push({
            id: '',
            item: '',
            quantity: '',
            price: '',
            discount: '',
            tax: '',
            amount: '',
            description: '',
        })
        productErrors.push({
            item: null,
            quantity: null,
            price: null,
        })
        this.setState({
            productElements,
            productErrors,
        })
    }

    removeRow(index) {
        let productElements = this.state.productElements
        productElements.splice(index, 1)
        this.setState({
            productElements,
        })
    }

    loadTax() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewAccountTax(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const taxes = response.data.taxes.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                        rate: item.rate,
                        index: index,
                    }
                })
                this.setState(
                    {
                        taxes,
                    },
                    () => {
                        this.loadProductCategory()
                    }
                )
            }
        })
    }

    loadProductCategory() {
        const { t } = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)

        viewProductCategory(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const filteredcategory = response.data.categories.filter((item) => item.type == 1)
                const category = filteredcategory.map((item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                })
                this.setState(
                    {
                        category,
                    },
                    () => {
                        this.loaduser()
                    }
                )
            }
        })
    }

    loaduser() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getAllSchoolUser(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const userLists = response.data.users
                    .filter((item) => item.user_type == Student || item.user_type == Alumni)
                    .map((item) => {
                        return {
                            label: item.user.name ? item.user.reg_no + ' - ' + item.user.name : item.user.reg_no,
                            value: item.customer_account_id,
                        }
                    })
                this.setState(
                    {
                        userLists,
                    },
                    this.loadProducts()
                )
            }
        })
    }

    loadProducts() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        viewProductService(this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const products = response.data.productServices
                    .filter((item) => item.type == 'Product')
                    .map((item, index) => {
                        const taxIds = item.tax_id.split(',').map((id) => parseInt(id.trim()))
                        // Find tax names from the loaded taxes
                        const selectedTax = this.state.taxes.filter((tax) => taxIds.includes(tax.value))
                        // const taxLabels = relatedTaxes.map(tax => tax.label);

                        return {
                            label: item.name,
                            value: item.id,
                            selectedTax,
                        }
                    })
                this.setState(
                    {
                        products,
                    },
                    () => {
                        this.loadEditInvoice()
                    }
                )
            }
        })
    }

    loadEditInvoice() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        // const key = getAccountingEncryptedKey(this.props.match.params.id)
        editInvoice(this.props.match.params.id, this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const invoiceItem = response.data.invoice
                if (invoiceItem.status == 0) {
                    const defaultUserList = this.state.userLists.find(
                        (user) => user.value == parseInt(invoiceItem.customer_id)
                    )
                    const defaultCategory = this.state.category.find(
                        (cat) => cat.value == parseInt(invoiceItem.category_id)
                    )
                    const productElements = []
                    const productErrors = []
                    invoiceItem.items.map((productItem) => {
                        const quantity = parseFloat(productItem.quantity) || 0
                        const price = parseFloat(productItem.price) || 0
                        const discount = parseFloat(productItem.discount) || 0

                        const defaultProduct = this.state.products.find(
                            (product) => product.value === parseInt(productItem.product_id)
                        )

                        const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

                        const pq = quantity * price
                        const pqd = pq - discount
                        const taxAmount = pqd * taxes.reduce((acc, tax) => acc + tax / 100, 0)
                        const totalAmount = pqd + taxAmount
                        productElements.push({
                            id: productItem.id ? productItem.id : '',
                            defaultProduct: this.state.products.find(
                                (product) => product.value == parseInt(productItem.product_id)
                            ),
                            tax: defaultProduct.selectedTax.map((tax) => tax.value).join(','),
                            item: productItem.product_id,
                            quantity: productItem.quantity,
                            price: productItem.price,
                            discount: productItem.discount,
                            description: productItem.description,
                            amount: totalAmount,
                        })
                        productErrors.push({
                            item: null,
                            quantity: null,
                            price: null,
                        })
                    })
                    this.setState({
                        defaultUserList,
                        selectedUserList: defaultUserList != null ? defaultUserList.value : null,
                        issue_date: invoiceItem.issue_date,
                        due_date: invoiceItem.due_date,
                        defaultCategory,
                        selectedCategory: defaultCategory != null ? defaultCategory.value : null,
                        ref_number: invoiceItem.ref_number,
                        productElements,
                        productErrors,
                    })
                } else {
                    redirect(route.admin.accounting.income.invoice.view, this.props.history)
                }
            }
        })
    }

    onSaveDraft(e) {
        e.preventDefault()
        this.setState(
            {
                status: 0,
            },
            () => {
                this.onSave()
            }
        )
    }

    onSavePublish(e) {
        e.preventDefault()
        this.setState(
            {
                status: 1,
            },
            () => {
                this.onSave()
            }
        )
    }

    onSave() {
        let hasError = false
        const productElements = this.state.productElements
        const productErrors = this.state.productErrors
        productElements.map((el, i) => {
            if (el.item == '') {
                productErrors[i].item = 'Required'
                hasError = true
            }
            if (el.quantity == '') {
                productErrors[i].quantity = 'Required'
                hasError = true
            }
            if (el.price == '') {
                productErrors[i].price = 'Required'
                hasError = true
            }
        })
        this.setState({
            productErrors,
        })

        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0 && hasError == false) {
            this._handleChange('loading', true)
            this._handleChange('loading_text', 'Saving')
            const data = {
                customer_id: this.state.selectedUserList,
                issue_date: this.state.issue_date,
                due_date: this.state.due_date,
                ref_number: this.state.ref_number,
                category_id: this.state.selectedCategory,
                items: this.state.productElements,
                status: this.state.status,
            }

            updateInvoice(this.props.match.params.id, data, this.props.accountingToken).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    redirect(route.admin.accounting.income.invoice.view)
                } else {
                    toastMessage('errors' in response.data)
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        }
                    }
                }
            })
        }
    }

    onDelete(e, itemId, index) {
        e.preventDefault()

        this._handleChange('loading', true)
        this._handleChange('loading_text', 'loading')
        const id = {
            id: itemId,
        }
        deleteInvoiceRow(id, this.props.accountingToken).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                this.removeRow(index)
                toastMessage('success', response.message)
                $('#close_add').trigger('click')
                $('.modal-backdrop').remove() // This removes the backdrop element
            } else {
                toastMessage('errors' in response.data)
                if (response.data) {
                    if ('errors' in response.data) {
                        this.setState({ errors: response.data.errors })
                    }
                }
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accountingToken: state.school.accounting_token,
        school: state.school.school,
    }
}
