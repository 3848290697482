import { getSchoolDomain, API_URL } from '~/helper/constant/config'
import ApiResponse from '~/model/response'
import { API_FAILURE } from '~/helper/constant/api_status'
import persistence from '../../store/reducers'
import * as UserType from '~/helper/constant/user_type'
import moment from 'moment'

const { store } = persistence()

function getOrigin() {
    const state = store.getState()
    if (state.school != null && state.school.school != null) {
        return getSchoolDomain(state.school.school.sub_domain)
    } else {
        return null
    }
}

function getStudent(headers) {
    const state = store.getState()
    if (state.school != null && state.school.user_type == UserType.Parents) {
        headers['Student'] = state.student
    }
    return headers
}

function getCurrentDate() {
    return moment().format('yyyy-MM-DD')
}

export const get = (path, token = null) => {
    const origin = getOrigin()
    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'User-Current-Date': getCurrentDate(),
    }
    // headers = getCurrentDate(headers)
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    if (origin) {
        headers['School'] = origin
    }
    headers = getStudent(headers)
    const config = {
        method: 'GET',
        headers: headers,
    }

    return fetch(API_URL + path, config)
        .then((response) => response.json())
        .then((data) => {
            if ('code' in data) {
                const result: ApiResponse = data
                return result
            } else {
                const result = new ApiResponse()
                result.code = API_FAILURE
                result.status = 'Failure'
                result.message = 'Service Down. Please try again later.'
                return result
            }
        })
        .catch((error) => {
            const result = new ApiResponse()
            result.code = API_FAILURE
            result.status = 'Failure'
            result.message = 'Service Down. Please try again later.'
            return result
        })
}

export const post = (path, token = null, body = null) => {
    const origin = getOrigin()
    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'User-Current-Date': getCurrentDate(),
    }
    // headers = getCurrentDate(headers)
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    if (origin) {
        headers['School'] = origin
    }
    headers = getStudent(headers)
    const config = {
        method: 'POST',
        headers: headers,
    }
    if (body) {
        config['body'] = JSON.stringify(body)
    }
    return fetch(API_URL + path, config)
        .then((response) => response.json())
        .then((data) => {
            if ('code' in data) {
                const result: ApiResponse = data
                return result
            } else {
                const result = new ApiResponse()
                result.code = API_FAILURE
                result.status = 'Failure'
                result.message = 'Service Down. Please try again later.'
                return result
            }
        })
        .catch((error) => {
            const result = new ApiResponse()
            result.code = API_FAILURE
            result.status = 'Failure'
            result.message = 'Service Down. Please try again later.'
            return result
        })
}

export const post_formdata = (path, token = null, body = null) => {
    const origin = getOrigin()
    let headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        'User-Current-Date': getCurrentDate(),
    }
    // headers = getCurrentDate(headers)
    if (token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    if (origin) {
        headers['School'] = origin
    }
    headers = getStudent(headers)
    const config = {
        method: 'POST',
        headers: headers,
    }
    if (body) {
        config['body'] = body
    }
    return fetch(API_URL + path, config)
        .then((response) => response.json())
        .then((data) => {
            if ('code' in data) {
                const result: ApiResponse = data
                return result
            } else {
                const result = new ApiResponse()
                result.code = API_FAILURE
                result.status = 'Failure'
                result.message = 'Service Down. Please try again later.'
                return result
            }
        })
        .catch((error) => {
            const result = new ApiResponse()
            result.code = API_FAILURE
            result.status = 'Failure'
            result.message = 'Service Down. Please try again later.'
            return result
        })
}
