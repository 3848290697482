import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { AbstractAssetAddComponent, mapStateToProps } from './abstract'
import { Button, Input, TextArea, Checkbox } from '~/components/form'
import DatePicker from 'react-datepicker'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import * as route from '~/helper/constant/route'
import { withTranslation } from 'react-i18next'
import { Loading } from '~/components/loader'
import * as SVG from '~/assets/svg'
import Select from 'react-select'
import { convertADtoBS } from '~/helper/function'

class AssetAddComponent extends AbstractAssetAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._purchaseDate = this._purchaseDate.bind(this)
        this._supportedDate = this._supportedDate.bind(this)
    }
    _purchaseDate(data) {
        this._handleChange('purchase_date', data)
    }
    _supportedDate(data) {
        this._handleChange('supported_date', data)
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Saving" />
                <Sidebar id="assets" isAccount={true} />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.asset.view}>
                                    {t('doubleentry:assets')} &nbsp; &gt; &nbsp;
                                </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('income:addAsset')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:name')} *</label>
                                    <Input
                                        name="name"
                                        onChange={this._handleChange}
                                        placeholder={t('common:name')}
                                        type="text"
                                        value=""
                                    />
                                    {this.state.errors.name && (
                                        <label className="error text-danger">{this.state.errors.name}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('fee:fee_amount')} *</label>
                                    <Input
                                        name="amount"
                                        onChange={this._handleChange}
                                        type="text"
                                        placeholder={t('fee:fee_amount')}
                                        value=""
                                    />
                                    {this.state.errors.amount && (
                                        <label className="error text-danger">{this.state.errors.amount}</label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:purchaseDate')} *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        placeholderText={t('income:purchaseDate')}
                                        selected={this.state.purchase_date}
                                        onChange={this._purchaseDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.purchase_date != ''
                                                    ? convertADtoBS(this.state.purchase_date) != null
                                                        ? convertADtoBS(this.state.purchase_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}

                                    {this.state.errors.purchase_date && (
                                        <label className="error text-danger setup-error text-left">
                                            {this.state.errors.purchase_date}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('income:supportedDate')} *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        placeholderText={t('income:supportedDate')}
                                        selected={this.state.supported_date}
                                        onChange={this._supportedDate}
                                    />
                                    {this.props.school != null &&
                                        this.props.school.school != null &&
                                        this.props.school.school.date_format == 'BS' && (
                                            <label className="date-bs">
                                                {this.state.supported_date != ''
                                                    ? convertADtoBS(this.state.supported_date) != null
                                                        ? convertADtoBS(this.state.supported_date) + ' BS'
                                                        : 'Invalid Date Range'
                                                    : ''}
                                            </label>
                                        )}

                                    {this.state.errors.purchase_date && (
                                        <label className="error text-danger setup-error text-left">
                                            {this.state.errors.purchase_date}
                                        </label>
                                    )}
                                </div>
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('common:description')}</label>
                                    <TextArea
                                        name="description"
                                        onChange={this._handleChange}
                                        placeholder={t('common:description')}
                                        type="text"
                                        value={this.state.description}
                                    />
                                 
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const AssetAdd = withTranslation()(connect(mapStateToProps)(AssetAddComponent))
export { AssetAdd }
