import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractRoleEditComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as modules from '~/helper/constant/modules'
import { Button, Input, Checkbox } from '~/components/form'
import { Loading } from '~/components/loader'
import { map } from 'jquery'
import { withTranslation } from 'react-i18next'

class RoleEditComponent extends AbstractRoleEditComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.getTab = this.getTab.bind(this)
    }

    getTab() {
        const { t } = this.props
        const data = modules.role_management
            .filter((item) => this.state.schoolAccess.includes(item.value))
            .map((item, index) => {
                const access = item
                const count = index
                return modules.access_management
                    .filter((item) => item.code == access.code)
                    .map((item, index) => {
                        if (count == 0) {
                            return (
                                <li key={index}>
                                    <a className="active show" id={item.code + '-tab'} data-toggle="tab" href={'#' + item.code} role="tab" aria-controls={item.code} aria-selected="true">
                                        {t(item.translate)}
                                    </a>
                                </li>
                            )
                        } else {
                            return (
                                <li key={index}>
                                    <a id={item.code + '-tab'} data-toggle="tab" href={'#' + item.code} role="tab" aria-controls={item.code} aria-selected="true">
                                        {t(item.translate)}
                                    </a>
                                </li>
                            )
                        }
                    })
            })
        return data
    }

    getAccess() {
        const { t } = this.props
        const data = modules.role_management
            .filter((item) => this.state.schoolAccess.includes(item.value))
            .map((item, index) => {
                let value = true
                for (var i = 0; i < item.data.length; i++) {
                    if (!this.state.access.includes(item.data[i].value)) {
                        value = false
                        break
                    }
                }
                if (index == 0) {
                    return (
                        <div key={index} className="tab-pane fade active show" id={item.code} role="tabpanel" aria-labelledby={item.code + '-tab'}>
                            <div className="table-responsive">
                                <table className="table table-bordered role-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="role-management access-status">
                                                    <div className="security-list">
                                                        <div className="tog-button">
                                                            <div className="button-check r" id="button-2">
                                                                <Checkbox onChange={this._selectAllModules} name={item.code} className="checkbox-form checkbox checkbox-with-label" value={value} />
                                                                <div className="knobs"></div>
                                                                <div className="layer"></div>
                                                            </div>
                                                        </div>
                                                        <span className="checkbox-label">{t('users:role.add.all')}</span>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>{t('users:role.add.accessControl')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.getModules(item.data)}</tbody>
                                </table>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className="tab-pane fade" id={item.code} role="tabpanel" aria-labelledby={item.code + '-tab'}>
                            <div className="table-responsive">
                                <table className="table table-bordered role-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="role-management access-status">
                                                    <div className="security-list">
                                                        <div className="tog-button">
                                                            <div className="button-check r" id="button-2">
                                                                <Checkbox onChange={this._selectAllModules} name={item.code} className="checkbox-form checkbox checkbox-with-label" value={value} />
                                                                <div className="knobs"></div>
                                                                <div className="layer"></div>
                                                            </div>
                                                        </div>
                                                        <span className="checkbox-label">{t('users:role.add.all')}</span>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>{t('users:role.add.accessControl')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.getModules(item.data)}</tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
            })
        return data
    }

    getModules(data) {
        const { t } = this.props
        return data.map((item, index) => {
            let value = false
            if (this.state.access.includes(item.value)) {
                value = true
            }
            return (
                <tr key={index}>
                    <th scope="row">
                        <div className="role-management access-status">
                            <div className="security-list mb-0">
                                <div className="tog-button inner">
                                    <div className="button-check r" id="button-2">
                                        <Checkbox onChange={this._selectModules} name={item.code} className="checkbox-form checkbox mt-0" value={value} />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </th>
                    <td>{t(item.translate)}</td>
                </tr>
            )
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <Sidebar id="user" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.user.menu}>{t('users:breadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.user.role.view}>{t('users:role.breadcrumb')} &gt; </a>
                            </li>
                            <li>{t('users:role.edit.breadcrumb')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">{t('users:role.edit.title')} :</h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-sm-6 float-left form-group">
                                    <label className="form-title">{t('users:role.add.nameLabel')} *</label>
                                    <Input name="name" onChange={this._handleChange} placeholder={t('users:role.add.namePlaceholder')} type="text" value={this.state.name} />
                                    {this.state.errors.name && <label className="error text-danger">{this.state.errors.name}</label>}
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-12 float-left form-group">
                                    <div className="vert-tab-left">
                                        <ul className="nav" id="myTab" role="tablist">
                                            {this.getTab()}
                                        </ul>
                                    </div>
                                    <div className="vert-tab-content">
                                        <div className="tab-content" id="myTabContent">
                                            {this.getAccess()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 float-left form-group">{this.state.errors.access && <label className="error text-danger">{this.state.errors.access}</label>}</div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-sm-3 float-left form-group">
                                    <Button onClick={this._onSave} type="submit" text={t('common:updateBtn')} main={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const RoleEdit = withTranslation()(connect(mapStateToProps)(RoleEditComponent))

export { RoleEdit }
