import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { viewMedal } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import * as route from '~/helper/constant/route'

export class AbstractGamificationViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            medalList: [],
            filterMedalList: [],
            searchText: null,
        }
        this._handleChange = this._handleChange.bind(this)
        this.search = this.search.bind(this)
    }

    componentDidMount() {
        this.loadMedal()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    search(value) {
        this.setState({
            searchText: value,
        })
        if (value != '') {
            const filterMedalList = this.state.medalList.filter((el) => {
                return el.name.toLowerCase().includes(value.toLowerCase())
            })
            this.setState({
                filterMedalList,
            })
        } else {
            this.setState({
                filterMedalList: this.state.medalList,
            })
        }
    }

    loadMedal() {
        this._handleChange('loading', true)
        viewMedal(this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                console.log(response.data.medal)
                const medalList = response.data.medal.sort((a, b) => {
                    return a.points > b.points ? -1 : 1
                })
                this.setState({
                    medalList: medalList,
                    filterMedalList: medalList,
                })
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
