import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AbstractCategoryComponent, mapStateToProps } from './abstract'
import { Sidebar } from '~/components/sidebar'
import { Header } from '~/components/header'
import { Table } from '~/components/table'
import * as route from '~/helper/constant/route'
import * as SVG from '~/assets/svg'
import { withTranslation } from 'react-i18next'
import * as AccessModule from '~/helper/constant/modules'
import * as ALUMNI_CATEGORY from '~/helper/constant/alumni_category'

class AlumniCategoryComponent extends AbstractCategoryComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    render() {
        const { t, school } = this.props
        return (
            <div className="main-page">
                <Sidebar id="alumniCategory" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <div className="row clearfix">
                            <div className="col-md-5 float-left">
                                <h5 className="main-title heading5">{t('alumni:alumniCategory')}:</h5>
                            </div>
                        </div>
                    </div>

                    <div className="folder-page">
                        <div className="row clearfix">
                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Batch)}>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Batch} className="folder-img">
                                        {SVG.subjecttype()}
                                    </a>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Batch} className="folder-name">
                                        {t('alumni:alumniBatch')}
                                        <span> {t('alumni:batchmsg1')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Group)}>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Group} className="folder-img">
                                        {SVG.subjecttype()}
                                    </a>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Group} className="folder-name">
                                        {t('alumni:alumniFaculty')}
                                        <span> {t('alumni:facultymsg1')}</span>
                                    </a>
                                </div>
                            </div>

                            <div className="float-left course-your">
                                <div className="folder-list clearfix" onClick={() => (window.location = route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Designation)}>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Designation} className="folder-img">
                                        {SVG.subjecttype()}
                                    </a>
                                    <a href={route.admin.alumni.category.view + '/' + ALUMNI_CATEGORY.Alumni_Designation} className="folder-name">
                                        {t('alumni:alumniDesignation')}
                                        <span> {t('alumni:designationmsg1')}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const AlumniCategory = withTranslation()(connect(mapStateToProps)(AlumniCategoryComponent))

export { AlumniCategory }
