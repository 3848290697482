import { post, get } from '../request'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import ApiResponse from '~/model/response'

export const viewEnquiry = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('enquiry', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editEnquiry = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('enquiry/schedule/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleEnquiry = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('enquiry/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
