import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { API_SUCCESS } from '~/helper/constant/api_status'
import { toastMessage } from '~/helper/function/util'
import { allNotification, getRecentNotification, setNotificationSeen } from '~/services/api'

type Props = {
    dispatch: Dispatch<any>,
}

type State = {
    notifications: Array,
    readNotifications: Array,
    unreadNotifications: Array,
    data: Array,
    page: null,
    notificationCount: Integer,
    focus: Boolean,
}

export class AbstractNotificationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            notifications: [],
            data: [],
            notificationCount: 0,
            readNotifications: [],
            unreadNotifications: [],
            page: null,
            focus: true,
            lastPage: false,
        }

        this._handleChange = this._handleChange.bind(this)
        this.markNotificationsSeen = this.markNotificationsSeen.bind(this)
        this.loadAllNotifications = this.loadAllNotifications.bind(this)
    }

    componentDidMount() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (message) => {
                const payload = message.data
                const notificationTitle = payload.notification.title
                const notificationBody = payload.notification.body
                toastMessage(
                    'success',
                    <div>
                        {notificationTitle} <br /> {notificationBody}
                    </div>
                )
                this.loadRecentNotifications()
            })
        }
        this.loadRecentNotifications()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    loadRecentNotifications() {
        getRecentNotification(this.props.token).then((response) => {
            let notifications = []
            let unreadNotifications = []
            let readNotifications = []
            let data = []
            let notificationCount = 0
            if (response.code == API_SUCCESS) {
                data = response.data
                const notificationList = data.recent.notification
                notificationList.map((item, index) => {
                    if (item.seen == 0) {
                        unreadNotifications.push(item)
                    } else {
                        readNotifications.push(item)
                    }
                    notifications.push(item)
                })

                notificationCount = unreadNotifications.length
            }
            this.setState(
                {
                    data,
                    notifications,
                    notificationCount,
                    unreadNotifications,
                    readNotifications,
                },
                () => {}
            )
        })
    }

    loadAllNotifications() {
        const current_page = this.state.page

        allNotification(this.props.token, current_page).then((response) => {
            let notifications = this.state.notifications
            let unreadNotifications = this.state.unreadNotifications
            let readNotifications = this.state.readNotifications
            let data = []
            let page = current_page
            let lastPage = false
            if (response.code == API_SUCCESS) {
                data = response.data
                const notificationList = data.notification
                page = data.notification.current_page
                const checkLastPage = notificationList.last_page
                notificationList.data.map((item, index) => {
                    if (item.seen == 0) {
                        let check = unreadNotifications.find((checkItem) => checkItem.id == item.id)
                        if (check == null) {
                            unreadNotifications.push(item)
                        }
                    } else {
                        let check = readNotifications.find((checkItem) => checkItem.id == item.id)
                        if (check == null) {
                            readNotifications.push(item)
                        }
                    }
                    let check = notifications.find((checkItem) => checkItem.id == item.id)
                    if (check == null) {
                        notifications.push(item)
                    }
                })
                if (page == checkLastPage) {
                    // $('.notification-more').hide()
                    lastPage = true
                }
            }
            this.setState({
                data,
                page: page + 1,
                notifications,
                unreadNotifications,
                readNotifications,
                lastPage,
            })
        })
    }

    markNotificationsSeen() {
        this._handleChange('loading', true)
        setNotificationSeen(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                this.setState({
                    notificationCount: 0,
                })
            } else {
            }
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
    }
}
