import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import { logout, viewEmployee, addEmployeeAttendance, viewEmployeeAttendance, updateEmployeeAttendance, getRole } from '~/services/api'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import { PRESENT, ABSENT } from '~/helper/constant/attendance'
import * as STATUS from '~/helper/constant/status'
import { EmployeeDTO } from '~/model'
import { signout } from '~/store'
import { toastMessage, loader } from '~/helper/function/util'
import * as route from '~/helper/constant/route'
import { APP_URL } from '~/helper/constant/config'
import { employee_type } from '~/helper/constant/user_type'

type Props = {
    token: String,
    dispatch: Dispatch<any>,
}

type State = {
    loading: Boolean,
    switchClass: Array,
    employees: Array,
    search: String,
}

export class AbstractEmployeeAttendanceViewComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loaded: false,
            search: null,
            loading: false,
            employees: [],
            employeeAttendance: [],
            switchClass: [],
            absentEmployees: [],
            loadingText: 'Loading',
            employee_role: employee_type.find((item) => item.value == this.props.match.params.user_type),
            user_type: this.props.match.params.user_type,
            role_id: this.props.match.params.role_id == 0 ? null : this.props.match.params.role_id,
            attendence_recorded: false,
            uploadedFiles: {},
            media: {},
            deletedMedia: {},
            remarks: '',
            reviewModal: false,
            date: this.props.match.params.date,
        }
        this._handleChange = this._handleChange.bind(this)
        this.setAttendance = this.setAttendance.bind(this)
        this.updateEmployeeAttendance = this.updateEmployeeAttendance.bind(this)
        this.toggleReviewModal = this.toggleReviewModal.bind(this)
    }

    toggleReviewModal() {
        this.setState({
            reviewModal: !this.state.reviewModal,
        })
    }

    componentDidMount() {
        this.getEmployeeRole()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.user_type != prevProps.match.params.user_type || this.props.match.params.role_id != prevProps.match.params.role_id || this.props.match.params.date != prevProps.match.params.date) {
            this.setState(
                {
                    employee_role: employee_type.find((item) => item.value == this.props.match.params.user_type),
                    user_type: this.props.match.params.user_type,
                    role_id: this.props.match.params.role_id == 0 ? null : this.props.match.params.role_id,
                    date: this.props.match.params.date,
                },
                () => {
                    this.getEmployeeRole()
                }
            )
        }
    }

    getEmployeeRole() {
        if (this.state.role_id != null) {
            getRole(this.state.role_id, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    this.setState(
                        {
                            employee_role: {
                                label: this.state.employee_role.label + ' : ' + response.data.role.name,
                                id: this.state.role_id,
                            },
                        },
                        () => {
                            this.getAttendance()
                        }
                    )
                } else {
                    toastMessage('error', response.message)
                }
            })
        } else {
            this.getAttendance()
        }
    }

    getAttendance() {
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Loading')
        let role_id = null
        if (this.state.role_id != null) {
            role_id = this.state.role_id
        }
        viewEmployeeAttendance(this.state.date, this.state.user_type, role_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let employeeAttendance = []
            if (response.code == API_SUCCESS) {
                response.data.attendance.map((item, index) => {
                    let attendanceData = {}
                    attendanceData['attendance_id'] = item.id
                    attendanceData['status'] = item.status
                    attendanceData['user_school_id'] = item.user_school_id
                    attendanceData['gallery'] = item.gallery != null ? item.gallery.media[0] : null
                    attendanceData['remarks'] = item.remarks
                    employeeAttendance.push(attendanceData)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState(
                {
                    employeeAttendance,
                },
                () => this.loadEmployee()
            )
        })
    }

    loadEmployee() {
        this._handleChange('loading', true)
        this._handleChange('loadingText', 'Loading')
        let employeeAttendance = this.state.employeeAttendance
        viewEmployee(this.state.user_type, this.state.role_id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            let employees = []
            let user_school_id = null
            if (response.code == API_SUCCESS) {
                const activeEmployee = response.data.employee.filter((el) => {
                    return el.status == STATUS.ACTIVE
                })
                activeEmployee.map((item, index) => {
                    const data: EmployeeDTO = item
                    let initial = data.user.name ? data.user.name.substring(0, 1) : data.user.email.substring(0, 1)
                    if (data.user.user_detail) {
                        initial = data.user.user_detail.profile != null ? data.user.user_detail.profile : initial
                    }
                    data.user['initial'] = initial
                    user_school_id = data.id
                    data.user['user_school_id'] = user_school_id
                    const user_data = employeeAttendance.find((el) => {
                        if (el.user_school_id == user_school_id) {
                            return el
                        }
                    })
                    data.user['status'] = user_data && user_data.status == 2 ? false : true
                    data.user['remarks'] = user_data ? user_data.remarks : ''
                    data.user['attendance_id'] = user_data ? user_data.attendance_id : null
                    data.user['media'] = user_data ? user_data.gallery : null
                    employees.push(data.user)
                })
            } else {
                toastMessage('error', response.message)
            }
            this.setState(
                {
                    employees,
                    loaded: true,
                },
                () => {}
            )
        })
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    setAttendance(current) {
        const employees = []
        this.state.employees.find((item, index) => {
            if (index == current) {
                item.status = !item.status
            }
            employees.push(item)
        })
        this.setState({
            employees,
        })
    }

    submitAttendance() {
        const component = this
        return new Promise(function (resolve, reject) {
            let employees = component.state.employees
            const attendance = []
            component.state.employees.map((item) => {
                const attendanceData = {
                    user_school_id: item.user_school_id,
                    status: item.status,
                }
                attendance.push(attendanceData)
            })
            const data = {
                date: component.state.date,
                attendance,
            }
            addEmployeeAttendance(data, component.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.getAttendance()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    resolve(true)
                }
            })
        })
    }

    updateEmployeeAttendance(index) {
        const component = this
        return new Promise(function (resolve, reject) {
            component._handleChange('loading', true)
            component._handleChange('loadingText', 'Saving')
            const employee = component.state.employees[index]
            let employeeAttendance = component.state.employeeAttendance
            const data = {
                deletedMedia: component.state.deletedMedia,
            }
            data['media'] = component.state.media
            data['remarks'] = component.state.remarks != '' ? component.state.remarks : employee.remarks
            const userAttendanceData = employeeAttendance.find((el) => {
                if (el.user_school_id == employee.user_school_id) {
                    data['attendanceStatus'] = employee.status
                    return el
                }
            })

            updateEmployeeAttendance(userAttendanceData.attendance_id, data, component.props.token).then((response) => {
                component._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    toastMessage('success', response.message)
                    component.setState({
                        attendence_recorded: true,
                        remarks: '',
                        media: {},
                        deletedMedia: {},
                        uploadedFiles: {},
                    })
                    component.getAttendance()
                    resolve(true)
                } else {
                    toastMessage('error', response.message)
                    if (response.data) {
                        if ('errors' in response.data) {
                            component.setState({ errors: response.data.errors })
                        }
                    }
                    resolve(false)
                }
            })
        })
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        school: state.school,
    }
}
