import moment from 'moment'
import React, { Fragment } from 'react'
import DatePicker from 'react-datepicker'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as ReactTable from 'react-super-responsive-table'
import * as SVG from '~/assets/svg'
import { Button, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import * as Status from '~/helper/constant/status'
import * as UserType from '~/helper/constant/user_type'
import { convertADtoBS } from '../../../../helper/function'
import { AbstractAttendanceReportComponent, mapStateToProps } from './abstract'
class AttendanceReportComponent extends AbstractAttendanceReportComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this._setFromDate = this._setFromDate.bind(this)
        this._setToDate = this._setToDate.bind(this)
        this._selectType = this._selectType.bind(this)
        this._selectedUser = this._selectedUser.bind(this)
        this._selectedSubject = this._selectedSubject.bind(this)
        this._selectedClass = this._selectedClass.bind(this)
        this._selectedSection = this._selectedSection.bind(this)
        this._setIndividual = this._setIndividual.bind(this)
    }

    _setFromDate(data) {
        this._handleChange('from_date', data)
    }

    _setToDate(data) {
        this._handleChange('to_date', data)
    }

    _selectType(data) {
        this.setState(
            {
                user_type: data,
                selectedUser: null,
            },
            () => {
                this.loadFilteredUser()
            }
        )
    }

    _setIndividual(key, value) {
        if (value == true) {
            this.setState(
                {
                    [key]: value,
                    selectedClass: null,
                    selectedSection: null,
                    selectedSubject: null,
                    selectedUser: null,
                },
                () => {
                    this.loadSubject()
                }
            )
        } else {
            this.setState(
                {
                    [key]: value,
                    selectedClass: null,
                    selectedUser: null,
                    selectedSubject: null,
                },
                () => {
                    this.loadSection()
                    this.loadSubject()
                }
            )
        }
    }

    _selectedUser(data) {
        this.setState(
            {
                selectedUser: data,
            },
            () => {
                this.loadSubject()
            }
        )
    }

    _selectedClass(data) {
        this.setState(
            {
                selectedClass: data,
                selectedSection: null,
                selectedSubject: null,
            },
            () => {
                this.loadSection()
                this.loadSubject()
            }
        )
    }

    _selectedSection(data) {
        this._handleChange('selectedSection', data)
    }

    _selectedSubject(data) {
        this._handleChange('selectedSubject', data)
    }

    getCount(present, absent) {
        const { t } = this.props
        if (this.state.count == true) {
            return (
                <Fragment>
                    <ReactTable.Tr>
                        <ReactTable.Th colSpan="6">
                            <small>
                                {t('attendance:presentDay')} &nbsp;
                                <span className="table-status table-green">{present}</span>
                            </small>
                        </ReactTable.Th>
                    </ReactTable.Tr>
                    <ReactTable.Tr>
                        <ReactTable.Th colSpan="6">
                            <small>
                                {t('attendance:absentDay')} &nbsp;
                                <span className="table-status table-red">{absent}</span>
                            </small>
                        </ReactTable.Th>
                    </ReactTable.Tr>
                </Fragment>
            )
        }
    }
    getReport() {
        const { t } = this.props
        if (this.state.tableData.length > 0) {
            let present = 0
            let absent = 0
            const data = this.state.tableData.map((item, index) => {
                let status = 'N/A'
                if (item.status === Status.ACTIVE) {
                    status = t('attendance:present')
                    present = present + 1
                } else if (item.status === Status.INACTIVE) {
                    status = t('attendance:absent')
                    absent = absent + 1
                }
                const remarks = item.remarks != null ? item.remarks : '-'
                return (
                    <ReactTable.Tr key={index}>
                        <ReactTable.Td className="whitespace-nowrap">{this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && item.date != null ? (convertADtoBS(item.date) != null ? convertADtoBS(item.date) : 'Invalid Date Range') : item.date}</ReactTable.Td>
                        <ReactTable.Td>{item.reg_no}</ReactTable.Td>
                        <ReactTable.Td>{item.name}</ReactTable.Td>
                        <ReactTable.Td>{item.type}</ReactTable.Td>
                        <ReactTable.Td>
                            {item.status == Status.ACTIVE && <span className="table-status cursor table-green">{status}</span>}
                            {item.status == Status.INACTIVE && <span className="table-status cursor table-red">{status}</span>}
                            {item.status == Status.PENDING && <span className="table-status cursor table-grey">{status}</span>}
                        </ReactTable.Td>
                        <ReactTable.Td>
                            <span className="float-left break-word">{remarks}</span>
                            {item.gallery != null && item.gallery.media.length > 0 && (
                                <a href={route.gallery + '/' + item.gallery.media[0].id} target="_blank" className="pdf-download mb-10 display-block">
                                    <small>&nbsp;{SVG.downloadreport()}</small>
                                </a>
                            )}
                            {/* <span className="float-left break-word">{remarks}</span> */}
                        </ReactTable.Td>
                    </ReactTable.Tr>
                )
            })
            data.push(this.getCount(present, absent))
            return data
        } else {
            return (
                <ReactTable.Tr>
                    <ReactTable.Td colSpan="6">
                        <p className="no-data">
                            {SVG.nodata()}
                            {t('attendance:searchAttendanceReport')}.
                        </p>
                    </ReactTable.Td>
                </ReactTable.Tr>
            )
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading..." />
                <Sidebar id="attendance" />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option no-border pb-0">
                        <ul className="list-inline breadcrum clearfix">
                            {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                <li>
                                    <a href={route.admin.attendance.menu}>{t('attendance:attendance')} &gt; </a>
                                </li>
                            )}
                            <li>{t('attendance:attendanceReport')}</li>
                        </ul>
                    </div>
                    <div className="main-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-lg-3">
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <h5 className="main-title heading5">
                                                {SVG.addeye()}
                                                {t('attendance:filter')}:
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="timetable-form mb-5">
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('common:fromdate')} *</label>
                                                <DatePicker maxDate={moment().toDate()} placeholderText={t('common:fromdate')} className="form-control" defaultValue={this.state.from_date} selected={this.state.from_date} onChange={this._setFromDate} />
                                                {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.from_date != null ? (convertADtoBS(this.state.from_date) != null ? convertADtoBS(this.state.from_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                                {this.state.errors.from_date && <label className="error text-danger">{this.state.errors.from_date}</label>}
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-sm-12 float-left form-group main-select">
                                                <label className="form-title">{t('common:todate')} *</label>
                                                <DatePicker maxDate={moment().toDate()} placeholderText={t('common:todate')} className="form-control" defaultValue={this.state.to_date} selected={this.state.to_date} onChange={this._setToDate} />
                                                {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <label className="date-bs">{this.state.to_date != null ? (convertADtoBS(this.state.to_date) != null ? convertADtoBS(this.state.to_date) + ' BS' : 'Invalid Date Range') : ''}</label>}
                                                {this.state.errors.to_date && <label className="error text-danger">{this.state.errors.to_date}</label>}
                                            </div>
                                        </div>
                                        {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group main-select">
                                                    <label className="form-title">{t('attendance:userType')} *</label>
                                                    <Select name="user_type" options={this.state.userType} placeholder={t('attendance:userType')} searchable={true} value={this.state.user_type} onChange={this._selectType} />
                                                    {this.state.errors.user_type && <label className="error text-danger">{this.state.errors.user_type}</label>}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.user_type.value == 0 && (
                                            <Fragment>
                                                {this.props.school.user_type != UserType.Student && this.props.school.user_type != UserType.Parents && (
                                                    <Fragment>
                                                        <div className="form-group form-check">
                                                            <label className="form-check-label form-title small-checkbox">{t('attendance:individual')}</label>
                                                            <Checkbox onChange={(key, value) => this._setIndividual(key, value)} name="isIndividual" value={this.state.isIndividual} />
                                                        </div>
                                                        {this.state.isIndividual == true && (
                                                            <Fragment>
                                                                <div className="row clearfix">
                                                                    <div className="col-sm-12 float-left form-group main-select">
                                                                        <div className="alert alert-primary mb-0" role="alert">
                                                                            <small>{t('attendance:attendanceHistory')}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row clearfix">
                                                                    <div className="col-sm-12 float-left form-group main-select">
                                                                        <label className="form-title">{t('attendance:users')} *</label>
                                                                        <Select name="selectedUser" options={this.state.filteredUser} placeholder={t('attendance:users')} searchable={true} value={this.state.selectedUser} onChange={this._selectedUser} />
                                                                        {this.state.errors.users && <label className="error text-danger">{this.state.errors.users}</label>}
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )}
                                                    </Fragment>
                                                )}
                                                {this.state.isIndividual == false && (
                                                    <Fragment>
                                                        <div className="row clearfix">
                                                            <div className="col-sm-12 float-left form-group main-select">
                                                                <div className="alert alert-primary mb-0" role="alert">
                                                                    <small>{t('attendance:attendanceReportMsg')}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row clearfix">
                                                            <div className="col-sm-12 float-left form-group main-select">
                                                                <label className="form-title">{t('attendance:class')} *</label>
                                                                <Select name="selctedClass" options={this.state.classes} placeholder={t('attendance:class')} searchable={true} value={this.state.selectedClass} onChange={this._selectedClass} />
                                                                {this.state.errors.classes && <label className="error text-danger">{this.state.errors.classes}</label>}
                                                            </div>
                                                        </div>
                                                        <div className="row clearfix">
                                                            <div className="col-sm-12 float-left form-group main-select">
                                                                <label className="form-title">{t('attendance:section')} *</label>
                                                                <Select name="selectedSection" options={this.state.section} placeholder={t('attendance:section')} searchable={true} value={this.state.selectedSection} onChange={this._selectedSection} />
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                                <div className="row clearfix">
                                                    <div className="col-sm-12 float-left form-group main-select">
                                                        <label className="form-title">{t('attendance:attendanceType')} *</label>
                                                        <Select name="selectedSubject" options={this.state.subject} placeholder={t('attendance:attendanceType')} searchable={true} value={this.state.selectedSubject} onChange={this._selectedSubject} />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                        {this.state.user_type.value == 1 && this.props.school.user_type != UserType.Teacher && (
                                            <div className="row clearfix">
                                                <div className="col-sm-12 float-left form-group main-select">
                                                    <label className="form-title">{t('attendance:users')} *</label>
                                                    <Select name="selectedUser" options={this.state.filteredUser} placeholder={t('attendance:users')} searchable={true} value={this.state.selectedUser} onChange={this._selectedUser} />
                                                    {this.state.errors.users && <label className="error text-danger">{this.state.errors.users}</label>}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-button text-left">
                                            <Button onClick={this._search} type="submit" text={t('attendance:filter')} main={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <h5 className="main-title heading5">
                                                {SVG.promoteStudentList()}
                                                {t('attendance:attendanceReport')}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row clearfix">
                                        <div className="col-md-12 form-group">
                                            <div className="main-table">
                                                <div className="table-all table-responsive">
                                                    <ReactTable.Table className="table">
                                                        <ReactTable.Thead>
                                                            <ReactTable.Tr>
                                                                <ReactTable.Th>
                                                                    {t('attendance:date')}
                                                                    {this.props.school != null && this.props.school.school != null && this.props.school.school.date_format == 'BS' && <small> ( BS )</small>}
                                                                </ReactTable.Th>
                                                                <ReactTable.Th>{t('attendance:regNo')}</ReactTable.Th>
                                                                <ReactTable.Th>{t('common:name')}</ReactTable.Th>
                                                                <ReactTable.Th>{t('attendance:type')}</ReactTable.Th>
                                                                <ReactTable.Th>{t('common:status')}</ReactTable.Th>
                                                                <ReactTable.Th>{t('attendance:remarks')}</ReactTable.Th>
                                                            </ReactTable.Tr>
                                                        </ReactTable.Thead>
                                                        <ReactTable.Tbody>{this.getReport()}</ReactTable.Tbody>
                                                    </ReactTable.Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const AttendanceReport = withTranslation()(connect(mapStateToProps)(AttendanceReportComponent))

export { AttendanceReport }
