import React, { Component } from 'react'
import { Dispatch } from 'react-redux'
import {viewReportCard, toggleReportCard} from '~/services/api'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS, API_FAILURE } from '~/helper/constant/api_status'
import * as Status from '~/helper/constant/status'
import { toastMessage } from '~/helper/function/util'
import $, { type } from 'jquery'
import * as route from '~/helper/constant/route'


type Props ={
    dispatch: Dispatch<any>,
}

type State ={}

export class AbstractReportCardViewComponent extends Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state = {
            loading:true,
            loading_text: "Loading",
            reportCardData:[],
            reportCardStructure:[
                {
                    title:{
                        field: 'text',
                        route: false,
                        sortable: true,
                        translate: 'common:name',
                    },
                    status:{
                        field: 'badge',
                        route: false,
                        sortable:true,
                        translate: 'common:status'
                    },
                    action:{
                        field:'button',
                        route:true,
                        sortable: false,
                        translate: 'activity:action'
                    }
                }
            ]

        }
        this._handleChange = this._handleChange.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount(){
        this.loadReportCard()

    }
    _handleChange(key, data){
        this.setState({[key]: data})
    }

    toggle(e){
        const link = e.target.getAttribute('link')
        $('#close_' + link).trigger('click')
        toggleReportCard(link, this.props.token).then((response)=>{
            if(response.code == API_SUCCESS){
                toastMessage('success', response.message)
                this.loadReportCard()
            }else{
                toastMessage('error', response.message)
            }

        })
        
    }

    loadReportCard(){
        const {t} = this.props
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        let reportCardStructure = []
        let reportCardData=[]
        reportCardStructure.push(this.state.reportCardStructure[0])
        viewReportCard(this.props.token).then((response)=>{
            this._handleChange('loading', false)
            if(response.code == API_SUCCESS){
                response.data.reportCard.map((item, index)=>{
                    let text = null
                    if(item.status == Status.ACTIVE){
                        text = {
                            text: 'Active',
                            translate: 'common:statusActive',
                            badge: 'table-green',
                            modal: 'modal_class_' + index,
                        }
                    }else{
                        text ={
                            text: 'Inactive',
                            translate: 'common:statusInactive',
                            badge: 'table-red',
                            modal:'modal_class_' + index,
                        }
                    }
                    const tableData= {
                        title: {
                            text:item.title,
                        },
                        status: text, 
                        action:{
                            text:'Edit',
                            translate: 'common:EditBtn',
                            route: route.admin.setting.reportcard.edit + '/' + item.id,
                        },
                    }
                    reportCardData.push(item)
                    reportCardStructure.push(tableData)
                })
                this.setState({
                    reportCardData,
                    reportCardStructure,
                })
            }
        })
        
        
    }

}

export function mapStateToProps(state: Object){
    return{
        token: state.token,
    }
}