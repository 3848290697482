import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { API_SUCCESS } from '~/helper/constant/api_status'
import * as MessageType from '~/helper/constant/message_type'
import * as route from '~/helper/constant/route'
import * as UserType from '~/helper/constant/user_type'
import * as validation from '~/helper/constant/validation'
import { redirect, toastMessage } from '~/helper/function/util'
import {
    addComment,
    addPost,
    deleteComment,
    deletePost,
    getChildrenProfile,
    getPost,
    getSchoolUser,
    likePost,
    metaTag,
    postViewCount,
    reportPost,
    searchPost,
    togglePostComment,
    viewPost,
} from '~/services/api'
import { Connection } from '~/services/socket'

type Props = {
    token: String,
    user: Object,
    school: Object,
    create: Boolean,
    postId: String,
    onChange: any,
    reload: any,
    onDeleteRoute: String,
    loader: Boolean,
    postSearch: Object,
    token: String,
    history: Object,
}

type State = {
    description: String,
    errors: Object,
    current_page: Integer,
    total_page: Integer,
    next_page: String,
    posts: Array,
    postSearch: Object,
    comment: Array,
    images: Object,
    image_description: String,
    image_errors: Object,
    video: Array,
    video_description: String,
    video_errors: Object,
    users: Array,
    access: Array,
    reload: Boolean,
}

const socket = new Connection()
export class AbstractPostComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loader: this.props.loader ? true : false,
            current_page: 0,
            total_page: 0,
            next_page: null,
            description: '',
            image_description: '',
            comment: [],
            images: {},
            video: [],
            access: [],
            video_description: '',
            posts: [],
            meta_tag: {},
            children: null,
            errors: {
                description: null,
            },
            image_errors: {
                image_description: null,
                images: null,
            },
            video_errors: {
                video_description: null,
                video: null,
            },
            users: [],
            deleteComment: null,
            reportComment: null,
            reportPost: null,
            receivedComment: null,
            report_remarks: null,
            report_type: null,
            selectedReportType: null,
            report_errors: {
                report_remarks: null,
                report_type: null,
            },
        }
        this._handleChange = this._handleChange.bind(this)
        this._handleCommentChange = this._handleCommentChange.bind(this)
        this._onSave = this._onSave.bind(this)
        this.clearState = this.clearState.bind(this)
        this.loadPost = this.loadPost.bind(this)
        this._likePost = this._likePost.bind(this)
        this._comment = this._comment.bind(this)
        this._openPost = this._openPost.bind(this)
        this._toggleComment = this._toggleComment.bind(this)
        this._deletePost = this._deletePost.bind(this)
        this._onImageSave = this._onImageSave.bind(this)
        this._onVideoSave = this._onVideoSave.bind(this)
        this.removeVideo = this.removeVideo.bind(this)
        this._openCalendar = this._openCalendar.bind(this)
        this._openTodo = this._openTodo.bind(this)
        this.loadUser = this.loadUser.bind(this)
        this._openRoute = this._openRoute.bind(this)
        this._deleteComment = this._deleteComment.bind(this)
        this.getMetaTag = this.getMetaTag.bind(this)
        this._report = this._report.bind(this)
    }

    componentDidMount() {
        if (this.props.school.user_type == UserType.Parents) {
            this.loadChildren()
        } else {
            this.loadUser()
        }

        socket.receive('receivedComment', this)
    }

    loadChildren() {
        this._handleChange('loading_text', 'Loading')
        this._handleChange('loading', true)
        getChildrenProfile(this.props.student, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const children = response.data.children.children
                this.setState(
                    {
                        children,
                    },
                    () => {
                        this.loadUser()
                    }
                )
            }
        })
    }

    getMetaTag() {
        this.state.posts.map((item) => {
            if (item.link != null) {
                let meta_tag = this.state.meta_tag
                item.link.map((linkItem, index) => {
                    if (meta_tag[linkItem] == undefined) {
                        const data = {
                            link: linkItem,
                        }
                        metaTag(data, this.props.token).then((response) => {
                            if (response.code == API_SUCCESS) {
                                const data = response.data.meta_tag
                                meta_tag[linkItem] = data
                                this.setState({
                                    meta_tag,
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isAlumni != this.props.isAlumni) {
            this.setState(
                {
                    current_page: 0,
                    total_page: 0,
                    next_page: null,
                    description: '',
                    image_description: '',
                    comment: [],
                    images: {},
                    video: [],
                    access: [],
                    video_description: '',
                    posts: [],
                    meta_tag: {},
                    children: null,
                    errors: {
                        description: null,
                    },
                    image_errors: {
                        image_description: null,
                        images: null,
                    },
                    video_errors: {
                        video_description: null,
                        video: null,
                    },
                    users: [],
                    deleteComment: null,
                    receivedComment: null,
                },
                () => {
                    if (this.props.school.user_type == UserType.Parents) {
                        this.loadChildren()
                    } else {
                        this.loadUser()
                    }
                }
            )
        }
        if (prevState.receivedComment != this.state.receivedComment) {
            if (this.state.receivedComment != null) {
                const type = this.state.receivedComment.type
                const data = this.state.receivedComment.data
                if (data.comment_by != this.props.school.user_id) {
                    if (type == MessageType.LIKE) {
                        const posts = []
                        this.state.posts.map((item) => {
                            if (item.id == data.posts.id) {
                                item.like_count = data.posts.like_count
                            }
                            posts.push(item)
                        })
                        this.setState({
                            posts,
                        })
                    } else if (type == MessageType.COMMENT_ADD) {
                        const posts = []
                        this.state.posts.map((item) => {
                            if (item.id == data.post_id) {
                                if (item.latest_comment != null) {
                                    item.latest_comment.push(data)
                                }
                                if (item.comment != null) {
                                    item.comment.push(data)
                                }
                                item.comment_count = item.comment_count + 1
                            }
                            posts.push(item)
                        })
                        this.setState({
                            posts,
                        })
                    } else if (type == MessageType.COMMENT_DELETE) {
                        const posts = []
                        this.state.posts.map((item) => {
                            if (item.latest_comment != null) {
                                item.latest_comment = item.latest_comment.filter(
                                    (commentItem) => commentItem.id != data.id
                                )
                            }
                            if (item.comment != null) {
                                item.comment = item.comment.filter((commentItem) => commentItem.id != data.id)
                            }
                            item.comment_count = item.comment_count - 1
                            posts.push(item)
                        })
                        this.setState({
                            posts,
                        })
                    }
                }
            }
        }
        if (this.props.postId != null) {
            if (this.props.reload) {
                this.setState(
                    {
                        posts: [],
                    },
                    () => {
                        this.loadPost()
                    }
                )
            }
        } else {
            if (prevProps.postSearch != this.props.postSearch) {
                this.clearState(true)
            }
        }
    }

    viewCountPost(id) {
        // Call the view count API when loading a post
        postViewCount(id, this.props.token)
            .then((updatedPostData) => {
                console.log('Views:', updatedPostData.data.views)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    _openPost(id) {
        redirect(route.activity + '/' + id, this.props.history)
    }

    async _openCalendar(id, date) {
        await this.viewCountPost(id)
        redirect(route.calendar + '/' + date, this.props.history)
    }

    async _openTodo(id) {
        await this.viewCountPost(id)
        redirect(route.todo.detail + '/' + id, this.props.history)
    }

    async _openDrive(id) {
        await this.viewCountPost(id)
        redirect(route.drive.folder + '/' + id, this.props.history)
    }

    async _openJob(id) {
        await this.viewCountPost(id)
        redirect(route.alumni.job.detail + '/' + id, this.props.history)
    }

    async _openCourse(item, id) {
        await this.viewCountPost(id)
        if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            redirect(route.course.student.module + '/' + item.class_access[0] + '/' + item.id, this.props.history)
        } else {
            redirect(route.activity + '/' + item.id, this.props.history)
            // redirect(route.course.module + '/' + item.class_access[0] + '/' + item.id, this.props.history)
        }
    }

    async _openQuestionBank(id) {
        await this.viewCountPost(id)
        if (this.props.school.user_type == UserType.Student || this.props.school.user_type == UserType.Parents) {
            redirect(route.examination.question_bank.question.view + '/' + id, this.props.history)
        } else {
            redirect(route.activity + '/' + id, this.props.history)
            // redirect(route.course.module + '/' + item.class_access[0] + '/' + item.id, this.props.history)
        }
    }

    async _openRoute(url, id) {
        await this.viewCountPost(id)
        redirect(url, this.props.history)
    }

    removeVideo() {
        this.setState({
            video: [],
        })
    }

    _likePost(id) {
        likePost(this.props.token, id).then((response) => {
            if (response.code == API_SUCCESS) {
                const like = response.data.like
                const posts = []
                this.state.posts.map((item) => {
                    if (item.id == id) {
                        if (like.like) {
                            item.like_count = item.like_count + 1
                            item.user_like = response.data.like
                            item.view_count = item.view_count + 1
                        } else {
                            item.like_count = item.like_count - 1
                            item.user_like = null
                        }
                        const data = {
                            room: this.props.school.school.short_code,
                            data: {
                                posts: item,
                            },
                            type: MessageType.LIKE,
                        }
                        socket.send(data)
                    }

                    posts.push(item)
                })

                this.setState({
                    posts,
                })
            }
        })
    }

    _deletePost(id) {
        this._handleChange('loading', true)
        deletePost(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                if (this.props.postId == null) {
                    let posts = []
                    this.state.posts
                        .filter((item) => id != item.id)
                        .map((item) => {
                            posts.push(item)
                        })
                    this.setState(
                        {
                            posts,
                        },
                        () => {
                            if (posts.length == 0) {
                                this.clearState(true)
                            }
                        }
                    )
                } else {
                    redirect(route.activity, this.props.history)
                }
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _report() {
        const component = this
        return new Promise(function (resolve, reject) {
            const report_errors = validation.required(Object.keys(component.state.report_errors), component.state)
            component.setState({ report_errors })
            const validate = Object.keys(report_errors).filter((item) => report_errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    post_id: component.state.reportPost,
                    comment_id: component.state.reportComment,
                    type: component.state.report_type,
                    remarks: component.state.report_remarks,
                }
                reportPost(data, component.props.token).then((response) => {
                    if (response.code == API_SUCCESS) {
                        toastMessage('success', response.message)
                    } else {
                        toastMessage('error', response.message)
                    }
                    resolve(true)
                })
            } else {
                resolve(false)
            }
        })
    }

    _deleteComment(id) {
        this._handleChange('loading', true)
        deleteComment(id, this.props.token).then((response) => {
            this._handleChange('loading', false)
            if (response.code == API_SUCCESS) {
                const posts = []
                this.state.posts.map((item) => {
                    let comment = []
                    if (item.latest_comment != null) {
                        comment = item.latest_comment.filter((commentItem) => commentItem.id != id)
                        item.latest_comment = comment
                    }
                    if (item.comment != null) {
                        comment = item.comment.filter((commentItem) => commentItem.id != id)
                        item.comment = comment
                    }
                    item.comment_count = comment.length
                    posts.push(item)
                })
                const data = {
                    room: this.props.school.school.short_code,
                    data: {
                        comment_by: this.props.school.user_id,
                        id: id,
                    },
                    type: MessageType.COMMENT_DELETE,
                }
                socket.send(data)
                this.setState({
                    posts,
                    deleteComment: null,
                })
                toastMessage('success', response.message)
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    _toggleComment(id) {
        togglePostComment(id, this.props.token, id).then((response) => {
            if (response.code == API_SUCCESS) {
                toastMessage('success', response.message)
                let posts = []
                this.state.posts.map((item) => {
                    if (id == item.id) {
                        if (item.isComment) {
                            item.isComment = false
                        } else {
                            item.isComment = true
                        }
                    }
                    posts.push(item)
                })
                this.setState({
                    posts,
                })
            } else {
                toastMessage('error', response.message)
            }
        })
    }

    loadPost() {
        this._handleChange('loader', true)
        this._handleChange('loading', true)
        if (this.props.postId) {
            getPost(this.props.postId, this.props.token).then((response) => {
                this._handleChange('loading', false)
                if (response.code == API_SUCCESS) {
                    const post = response.data.post
                    const posts = this.state.posts
                    posts.push(post)
                    this.setState(
                        {
                            posts,
                        },
                        () => {
                            this.getMetaTag()
                            if (this.props.onChange != null) {
                                this.props.onChange(post)
                            }
                        }
                    )
                }
            })
        } else {
            if (this.props.postSearch != null) {
                searchPost(
                    this.props.isAlumni,
                    JSON.parse(this.props.postSearch),
                    this.props.token,
                    this.state.next_page
                ).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const post = response.data.post
                        const current_page = post.current_page
                        const total_page = post.last_page
                        let next_page = null
                        if (current_page != total_page) {
                            next_page = current_page + 1
                        }
                        const posts = this.state.posts
                        post.data.map((item) => {
                            posts.push(item)
                        })
                        this.setState(
                            {
                                current_page,
                                total_page,
                                next_page,
                                posts: posts.filter((item) => item.isAlumni == this.props.isAlumni),
                            },
                            () => {
                                this.getMetaTag()
                            }
                        )
                    }
                })
            } else {
                viewPost(this.props.isAlumni, this.props.token, this.state.next_page).then((response) => {
                    this._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const post = response.data.post
                        const current_page = post.current_page
                        const total_page = post.last_page
                        let next_page = null
                        if (current_page != total_page) {
                            next_page = current_page + 1
                        }
                        const posts = this.state.posts
                        post.data.map((item) => {
                            posts.push(item)
                        })

                        this.setState(
                            {
                                current_page,
                                total_page,
                                next_page,
                                posts: posts.filter((item) => item.isAlumni == this.props.isAlumni),
                            },
                            () => {
                                this.getMetaTag()
                            }
                        )
                    }
                })
            }
        }
    }

    loadUser() {
        getSchoolUser(this.props.token).then((response) => {
            if (response.code == API_SUCCESS) {
                let users = []
                console.log(response.data.users)
                response.data.users.map((item, index) => {
                    const name = item.user.name != null ? item.user.name : item.user.reg_no
                    const data = {
                        id: item.user.id,
                        display: name + ' ~ ' + item.user.reg_no,
                        name: name + ' ~ ' + item.user.reg_no,
                        text: name,
                        check: item.user.reg_no.toLowerCase().replace(' ', ''),
                    }
                    if (
                        this.props.isAlumni &&
                        item.user_type != UserType.Student &&
                        item.user_type != UserType.Parents
                    ) {
                        users.push(data)
                    } else if (!this.props.isAlumni && item.user_type != UserType.Alumni) {
                        users.push(data)
                    }
                })
                this.setState(
                    {
                        users,
                    },
                    () => {
                        this.loadPost()
                    }
                )
            }
        })
    }

    _handleChange(key, value) {
        this.setState({ [key]: value })
    }

    _handleCommentChange(key, value) {
        key = key.replace('comment_', '')
        let comment = this.state.comment
        comment[key] = value
        this.setState({ comment })
    }

    clearState(reload) {
        this.setState(
            {
                current_page: reload == true ? 0 : this.state.current_page,
                total_page: reload == true ? 0 : this.state.total_page,
                next_page: reload == true ? null : this.state.total_page,
                description: '',
                image_description: '',
                comment: [],
                images: {},
                video: [],
                posts: reload == true ? [] : this.state.posts,
                video_description: '',
                errors: {
                    description: null,
                },
                image_errors: {
                    image_description: null,
                    images: null,
                },
                video_errors: {
                    video_description: null,
                    video: null,
                },
            },
            () => {
                if (reload == true) {
                    this.loadPost()
                }
            }
        )
    }

    _comment(id) {
        if (this.state.comment[id] != undefined) {
            const data = {
                post_id: id,
                comment: this.state.comment[id],
            }
            addComment(data, this.props.token).then((response) => {
                if (response.code == API_SUCCESS) {
                    const comment = response.data.comment
                    const posts = []
                    this.state.posts.map((item) => {
                        if (item.id == id) {
                            if (item.latest_comment != null) {
                                item.latest_comment.push(comment)
                            }
                            if (item.comment != null) {
                                item.comment.push(comment)
                            }
                            item.comment_count = item.comment_count + 1
                        }
                        item.view_count = item.view_count + 1
                        posts.push(item)
                    })
                    const data = {
                        room: this.props.school.school.short_code,
                        data: comment,
                        type: MessageType.COMMENT_ADD,
                    }
                    socket.send(data)
                    this.setState(
                        {
                            posts,
                        },
                        () => {
                            this._handleCommentChange('comment_' + id, '')
                        }
                    )
                }
            })
        }
    }

    _onSave(e) {
        e.preventDefault()
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            const data = {
                description: this.state.description,
                access: this.state.access,
                isAlumni: this.props.isAlumni,
            }

            this._handleChange('loader', true)
            this._handleChange('loading', true)
            addPost(data, this.props.token).then((response) => {
                this._handleChange('loading', false)
                this._handleChange('loader', this.props.loader ? true : false)
                if (response.code == API_SUCCESS) {
                    const posts = this.state.posts
                    posts.unshift(response.data.post)
                    this.setState(
                        {
                            posts,
                        },
                        () => {
                            this.getMetaTag()
                            this.clearState(false)
                        }
                    )
                } else {
                    if (response.data) {
                        if ('errors' in response.data) {
                            this.setState({ errors: response.data.errors })
                        } else {
                            toastMessage('error', response.message)
                        }
                    }
                }
            })
        }
    }

    _onImageSave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const image_errors = validation.required(Object.keys(component.state.image_errors), component.state)
            const check_image = []
            Object.keys(component.state.images).map((item) => {
                check_image.push(item)
            })
            if (check_image.length == 0) {
                image_errors.images = t('common:required')
            }
            component.setState({ image_errors })
            const validate = Object.keys(image_errors).filter((item) => image_errors[item] != null)
            if (validate.length == 0) {
                const media = Object.keys(component.state.images).map((item, index) => {
                    return {
                        name: 'post-' + index + '.png',
                        data: component.state.images[item],
                    }
                })
                const data = {
                    description: component.state.image_description,
                    media: media,
                    isAlumni: component.props.isAlumni,
                }

                component._handleChange('loader', true)
                component._handleChange('loading', true)
                addPost(data, component.props.token).then((response) => {
                    component._handleChange('loading', false)
                    component._handleChange('loader', false)
                    if (response.code == API_SUCCESS) {
                        const posts = component.state.posts
                        posts.unshift(response.data.post)
                        component.setState(
                            {
                                posts,
                            },
                            () => {
                                component.getMetaTag()
                                component.clearState(false)
                                resolve(true)
                            }
                        )
                    } else {
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ image_errors: response.data.errors }, () => {
                                    resolve(false)
                                })
                            } else {
                                toastMessage('error', response.message)
                                resolve(false)
                            }
                        }
                    }
                })
            }
        })
    }

    _onVideoSave(e) {
        const { t } = this.props
        e.preventDefault()
        const component = this
        return new Promise(function (resolve, reject) {
            const video_errors = validation.required(Object.keys(component.state.video_errors), component.state)
            if (component.state.video.length == 0) {
                video_errors.video = t('common:required')
            }
            component.setState({ video_errors })
            const validate = Object.keys(video_errors).filter((item) => video_errors[item] != null)
            if (validate.length == 0) {
                const data = {
                    description: component.state.video_description,
                    media: component.state.video,
                    isAlumni: component.props.isAlumni,
                }
                component._handleChange('loader', true)
                component._handleChange('loading', true)
                addPost(data, component.props.token).then((response) => {
                    component._handleChange('loader', false)
                    component._handleChange('loading', false)
                    if (response.code == API_SUCCESS) {
                        const posts = component.state.posts
                        posts.unshift(response.data.post)
                        component.setState(
                            {
                                posts,
                            },
                            () => {
                                component.getMetaTag()
                                component.clearState(false)
                                resolve(true)
                            }
                        )
                    } else {
                        if (response.data) {
                            if ('errors' in response.data) {
                                component.setState({ image_errors: response.data.errors })
                                resolve(false)
                            } else {
                                toastMessage('error', response.message)
                                resolve(false)
                            }
                        }
                    }
                })
            }
        })
    }
}

const AbstractPost = withTranslation()(AbstractPostComponent)

export { AbstractPost }

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        user: state.user,
        school: state.school,
        postSearch: state.postSearch,
        student: state.student,
        isAlumni: state.isAlumni,
    }
}
