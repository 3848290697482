import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as SVG from '~/assets/svg'
import { Button, Checkbox } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import * as route from '~/helper/constant/route'
import { toastMessage } from '~/helper/function/util'
import { AbstractCourseContentDetailsStudentComponent, mapStateToProps } from './abstract'
class CourseContentDetailsStudentComponent extends AbstractCourseContentDetailsStudentComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.togglePlayback = this.togglePlayback.bind(this)
        this.quizQuestions = this.quizQuestions.bind(this)
        this.onTrackedVideoFrame = this.onTrackedVideoFrame.bind(this)
        this.answerQuestion = this.answerQuestion.bind(this)
        this.setAnswer = this.setAnswer.bind(this)
        this.seekVideoTo = this.seekVideoTo.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.toggleFullscreen = this.toggleFullscreen.bind(this)
        this.setVolume = this.setVolume.bind(this)
        this.playPopup = this.playPopup.bind(this)
        this.playVideoFromGivenTime = this.playVideoFromGivenTime.bind(this)
    }

    answerQuestion(question_id) {
        super.answerQuestion(question_id).then((response) => {
            if (response != null) {
                this.player.play()
                $('#modal_course_quiz_' + this.state.currentExam).modal('hide')
                $('#playicon').hide()
            }
        })
    }

    componentDidMount() {
        super.componentDidMount()
        const component = this
        let inter = window.setInterval(function (t) {
            let player = document.getElementById('main_video')
            if (player) {
                player.addEventListener('timeupdate', () => {
                    component.onTrackedVideoFrame()
                })
                player.addEventListener('ended', () => {
                    component.setState(
                        {
                            videoEnded: true,
                            isPlaying: false,
                        },
                        () => {
                            if (component.state.userProgress != 2) {
                                component.saveResult()
                            }
                        }
                    )
                })
                if (component.props.match.params.videotime && component.props.match.params.videotime > 0) {
                    $('#modal_course_popup').modal('show')
                }

                if (player.duration) {
                    // player.currentTime = videotime.toString()
                    component.setMaxTimeStamp(player.duration)
                    let videoPlayedTime = localStorage.getItem('videoPlayback')
                    if (videoPlayedTime != null) {
                        if (JSON.parse(videoPlayedTime)[component.props.match.params.course_content_id]) {
                            videoPlayedTime = JSON.parse(videoPlayedTime)[component.props.match.params.course_content_id]
                            videoPlayedTime = videoPlayedTime > 6 ? videoPlayedTime - 5 : videoPlayedTime
                            component.seekVideoTo(videoPlayedTime)
                        }
                    }
                    window.clearInterval(inter)
                }
            }
        }, 1000)
    }

    playVideoFromGivenTime() {
        const videotime = this.state.videoStartTime
        if (videotime > 0) {
            $('#playicon').hide()
            this.player.currentTime = parseInt(videotime)
            this.player.play()
            this.setState({
                isPlaying: true,
            })
        }
    }

    ref = (player) => {
        this.player = player
    }

    toggleFullscreen() {
        var elem = this.player
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen()
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen()
        }
    }

    copyToClipboard = (e) => {
        const { t } = this.props
        if (this.state.shareAtCurrentTime) {
            this.textArea.select()
        } else {
            this.textArea2.select()
        }
        document.execCommand('copy')
        toastMessage('success', t('toaster:linkCopiedMsg'))
    }

    seekVideoTo(duration) {
        this.player.currentTime = duration
    }

    onTrackedVideoFrame() {
        const currentTime = parseInt(this.player.currentTime)
        const hours = parseInt(currentTime / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const minutes = parseInt((currentTime % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const seconds = parseInt(currentTime % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const currentVideoTime = hours + ':' + minutes + ':' + seconds
        const exam = this.state.exam.filter((el) => parseInt(el.timestamp) == currentTime)
        const data = []
        data[this.props.match.params.course_content_id] = currentTime
        localStorage.setItem('videoPlayback', JSON.stringify(data))
        if (exam.length > 0 && this.state.givenAnswer[exam[0].questions[0].question[0].id] == null) {
            this.player.pause()
            $('#modal_course_quiz_' + exam[0].id).modal('show')
            this.setState({
                currentExam: exam[0].id,
                currentTime,
            })
            $('#playicon').show()
        }
        this.setState({
            currentVideoTime,
        })
        if (this.state.shareAtCurrentTime) {
            this.setState({
                shareUrl: this.state.shareUrl + '/' + parseInt(this.player.currentTime),
            })
        }
    }

    setVolume(e) {
        this.setState({
            videoVolume: e.target.value,
        })
        this.player.volume = e.target.value
    }

    togglePlayback() {
        if (this.player.paused == true) {
            this.player.play()
            $('#playicon').hide()
            this.setState({
                isPlaying: true,
            })
        } else {
            this.player.pause()
            $('#playicon').show()
            this.setState({
                isPlaying: false,
            })
        }
    }

    setAnswer(e) {
        this.setState({
            selectedAnswer: e.target.value,
        })
    }

    playPopup() {
        const { t } = this.props
        return (
            <div id={'modal_course_popup'} className="modal fade show" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                    <div className="header modal-title">{t('course:resumePlaying')}</div>
                    <br />
                    <div className="modal-content">
                        <div className="pop-main-content">
                            <p className="modal-question">{t('course:continuePlaying')}</p>
                        </div>
                    </div>
                    <div className="modal-footer text-center">
                        <Button
                            type="submit"
                            text={t('course:continuePlaying')}
                            main={true}
                            onClick={() => {
                                this.playVideoFromGivenTime()
                                $('#modal_course_popup').modal('hide')
                            }}
                        />
                        <Button
                            type="submit"
                            className="cancel-button"
                            text={t('course:startBeginning')}
                            main={true}
                            onClick={() => {
                                this.setState(
                                    {
                                        videoStartTime: 0,
                                    },
                                    () => {
                                        $('#playicon').hide()
                                        this.player.currentTime = parseInt(this.state.videoStartTime)
                                        this.player.play()
                                        this.setState({
                                            isPlaying: true,
                                        })
                                        $('#modal_course_popup').modal('hide')
                                    }
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    quizQuestions() {
        const { t } = this.props
        let correctAnswers = []
        return this.state.exam.map((item, index) => {
            const answerArr = item.questions[0].question[0].answer
            return (
                <div id={'modal_course_quiz_' + item.id} className="modal fade show" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog pop-main main-box-shadow main-border-radius main-border main-background main-mb">
                        <div className="header modal-title">
                            <span>{t('course:courseQuiz')}</span>
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                        </div>
                        <br />
                        <div className="modal-content">
                            <div className="pop-main-content">
                                <p className="modal-question">{item.questions[0].question[0].questions}</p>
                                {answerArr.map((ele, i) => {
                                    if (ele.answers) {
                                        return (
                                            <div className="answers">
                                                {/* <Checkbox name="answer" onChange={this.setAnswer} type="radio" className="chat-radio" value={ele.answers} defaultChecked={false} /> */}
                                                <input type="radio" onChange={this.setAnswer} name="answer" value={ele.id} />
                                                <label htmlFor="all">{ele.answers}</label>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center form-group">
                                <Button type="submit" text={t('course:answer')} main={true} onClick={() => this.answerQuestion(item.questions[0].question[0].id)} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        const { t } = this.props
        return (
            <div className="main-page">
                <Sidebar id="course" />
                <Loading status={this.state.loading} text={this.state.loading_text} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.course.student.view}>{t('student_panel:content.courseBreadcrumb')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.course.student.module + '/' + this.state.courseModule.course_id + '/' + this.state.post_id}> {this.state.courseModule != null && this.state.courseModule.course != null ? this.state.courseModule.course.post.title : t('course:course_title')} &gt; </a>
                            </li>
                            <li>
                                <a href={route.course.student.content + '/' + this.props.match.params.course_id + '/' + +this.props.match.params.module_id}> {this.state.courseModule.name} &gt; </a>
                            </li>
                            <li>{this.state.course.name}</li>
                        </ul>
                    </div>
                    {this.state.media && (
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h3 className="main-title">{this.state.coursetitle}</h3>
                                <p>
                                    {t('course:courseStartTime')}: {this.state.media_date}
                                </p>
                            </div>
                            <div className="video-manage video-custom-control col-lg-7 float-left">
                                <div className="video-wrap">
                                    {(this.state.media.media_type.includes('image') && <img src={this.state.media.media_url} alt="image" />) ||
                                        (this.state.media.media_type.includes('audio') && <audio id="main_video" src={this.state.media_url} controls height={'auto'} width={'100%'} ref={this.ref} />) ||
                                        (this.state.media.media_type.includes('video') && <video id="main_video" src={this.state.media_url} pip playsinline height={'auto'} width={'100%'} ref={this.ref} playing={this.state.playing} controlsList="nodownload" />) || <iframe src={this.state.media_url} />}
                                    {this.state.isVideoContent == 1 && (
                                        <div className="video-overlay-wrap" onClick={this.togglePlayback}>
                                            <FontAwesomeIcon className="icon" icon="play" color="#FFF" size="lg" id="playicon" />
                                        </div>
                                    )}
                                    {this.state.isVideoContent == 1 && (
                                        <div className="video-control clearfix">
                                            <span className="video-timeplay" onClick={() => this.togglePlayback()}>
                                                {!this.state.isPlaying && <FontAwesomeIcon className="icon" icon="play" color="#FFF" size="md" />}
                                                {this.state.isPlaying && <FontAwesomeIcon className="icon" icon="pause" color="#FFF" size="lg" />}
                                                <span className="videotiming">
                                                    {this.state.currentVideoTime} / {this.state.videoTime}
                                                </span>
                                            </span>
                                            <div className="clearfix videovol float-right">
                                                {this.state.videoVolume > 0 && <span>{SVG.videovolume()}</span>}
                                                {this.state.videoVolume == 0 && <span>{SVG.videovolume()}</span>}
                                                <input className="video-time" type="range" name="volume" step={0.1} min={0} max={1} value={this.state.videoVolume} onChange={(e) => this.setVolume(e)} />
                                                <span className="videotiming">{this.state.videoVolume * 100}%</span>
                                                <span onClick={() => this.toggleFullscreen()}>
                                                    <FontAwesomeIcon className="icon" icon="arrows-alt" color="#FFF" size="lg" />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-5 float-left course-timeline video-manage right-tabs">
                                <div className="role-pop-head">
                                    <ul className="nav" id="myTab" role="tablist">
                                        <li>
                                            <a className="active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">
                                                {t('course:course')}
                                            </a>
                                        </li>
                                        {this.state.isVideoContent == 1 && (
                                            <li>
                                                <a id="timeline-tab" data-toggle="tab" href="#timeline" role="tab" aria-controls="timeline" aria-selected="true">
                                                    {t('course:timeline')}
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <a id="share-tab" data-toggle="tab" href="#share" role="tab" aria-controls="share" aria-selected="true">
                                                {t('course:share')}
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a id="resources-tab" data-toggle="tab" href="#resources" role="tab" aria-controls="resources" aria-selected="false">
                                                Resources
                                            </a>
                                        </li>
                                        <li>
                                            <a id="homework-tab" data-toggle="tab" href="#homework" role="tab" aria-controls="homework" aria-selected="false">
                                                Homework
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="role-pop-content">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="content-tab">
                                            {this.state.course.description}
                                        </div>
                                        {this.state.isVideoContent == 1 && (
                                            <div className="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="content-tab">
                                                {(this.state.timeline.length > 0 &&
                                                    this.state.timeline.map((data, index) => {
                                                        return (
                                                            <span className="timeline-list" onClick={() => this.seekVideoTo(parseInt(data.timestamp))}>
                                                                <ins>{data.hours + ':' + data.minutes + ':' + data.seconds}</ins>
                                                                {data.Description}
                                                            </span>
                                                        )
                                                    })) ||
                                                    t('course:noTimeline')}
                                            </div>
                                        )}
                                        <div className="tab-pane fade" id="share" role="tabpanel" aria-labelledby="content-tab">
                                            <h5>{t('course:share')}</h5>
                                            {this.state.isVideoContent == 1 && (
                                                <div className="row">
                                                    <div className="col-sm-12 float-left form-group">
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                this.setState({ shareAtCurrentTime: !this.state.shareAtCurrentTime }, () => {
                                                                    if (!this.state.shareAtCurrentTime) {
                                                                        this.setState({
                                                                            shareUrl: this.state.shareUrl + '/' + this.props.match.params.course_content_id,
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                            className="checkbox-form"
                                                            name="shareAtCurrentTime"
                                                            value={this.state.shareAtCurrentTime}
                                                        />
                                                        <label className="form-title small-checkbox mt-0 ml-3">{t('course:shareCurrentTime')}</label>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-sm-12 float-left form-group">
                                                    {(this.state.shareAtCurrentTime && <input type="text" className="form-control" placeholder={t('course:share')} URL value={this.state.shareUrl} readOnly={true} ref={(textarea) => (this.textArea = textarea)} />) || <input type="text" className="form-control" placeholder={t('course:share')} URL value={window.location.href} readOnly={true} ref={(textarea2) => (this.textArea2 = textarea2)} />}
                                                    <br />
                                                    <Button type="button" text={t('course:clipboard')} main={false} onClick={() => this.copyToClipboard()} />
                                                </div>
                                            </div>
                                            <div className="row"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {this.state.userProgress != 2 && (
                        <div className="row form-button">
                            <div className="col-sm-12">{((this.state.videoEnded && this.state.isVideoContent == 1) || this.state.isVideoContent == 0) && <Button onClick={this.saveResult} type="submit" text="Finish Course" main={true} />}</div>
                        </div>
                    )} */}
                </div>
                {this.quizQuestions()}
                {this.props.match.params.videotime && this.props.match.params.videotime > 0 && this.playPopup()}
            </div>
        )
    }
}
const CourseContentDetailsStudent = withTranslation()(connect(mapStateToProps)(CourseContentDetailsStudentComponent))
export { CourseContentDetailsStudent }
