import { accountingGet, accountingPost } from '../accountingRequest'

export const viewProductService = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('product-service', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addProductService = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-service/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const updateProductService = (productService, data, token) => {

    return new Promise(function (resolve, reject) {
        try {
            accountingPost('product-service/update/' + productService, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editProductService = (productService, token) => {
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('product-service/edit/' + productService, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const deleteProductService = (productService, data, token) => {
    // yesma id huncha kee asset pachi check garnu parcha
    return new Promise(function (resolve, reject) {
        try {
            accountingGet('product-service/delete' + productService, data, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
