import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as SVG from '~/assets/svg'
import { Button, Input, TextArea } from '~/components/form'
import { Header } from '~/components/header'
import { Loading } from '~/components/loader'
import { Sidebar } from '~/components/sidebar'
import { UserFilter } from '~/components/userfilter'
import * as route from '~/helper/constant/route'
import { AbstractInvoiceTemplateAddComponent, mapStateToProps } from './abstract'

class InvoiceTemplateAddComponent extends AbstractInvoiceTemplateAddComponent {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this._setQuantity = this._setQuantity.bind(this)
        this._selectStudent = this._selectStudent.bind(this)
        this._selectCategory = this._selectCategory.bind(this)
    }

    calculateTotalAmount(product) {
        const quantity = parseFloat(product.quantity) || 0
        const price = parseFloat(product.price) || 0
        const discount = parseFloat(product.discount) || 0
        const defaultProduct = product.defaultProduct || {}
        const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

        // const taxes = product.defaultProduct.selectedTax.map(tax => parseFloat(tax.rate) || 0);

        const pq = quantity * price
        const pqd = pq - discount
        const taxAmount = pqd * taxes.reduce((acc, tax) => acc + tax / 100, 0)
        const totalAmount = pqd + taxAmount

        return totalAmount
    }

    // Calculate the sum of prices for all rows
    calculateTotalPrice(productElements) {
        return productElements.reduce(
            (acc, product) => acc + (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0),
            0
        )
    }

    calculateTotalDiscount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.discount) || 0), 0)
    }

    // Calculate the sum of tax amounts for all rows
    calculateTotalTaxAmount(productElements) {
        return productElements.reduce((acc, product) => {
            const quantity = parseFloat(product.quantity) || 0
            const price = parseFloat(product.price) || 0
            const discount = parseFloat(product.discount) || 0

            // Pass index to retrieve the defaultProduct for the current row
            const defaultProduct = product.defaultProduct || {}
            const taxes = (defaultProduct.selectedTax || []).map((tax) => parseFloat(tax.rate) || 0)

            const pq = quantity * price
            const pqd = pq - discount
            const taxAmount = pqd * taxes.reduce((taxAcc, tax) => taxAcc + tax / 100, 0)

            return acc + taxAmount
        }, 0)
    }

    calculateFinalTotalAmount(productElements) {
        return productElements.reduce((acc, product) => acc + (parseFloat(product.amount) || 0), 0)
    }

    _selectStudent(data) {
        this._handleChange('customers', data)
    }

    _selectCategory(data) {
        this._handleChange('category', data)
    }

    _setProduct(data, i) {
        const productElements = this.state.productElements
        productElements[i].defaultProduct = data
        productElements[i].item = data.value
        productElements[i].amount = this.calculateTotalAmount(productElements[i])

        const productErrors = [...this.state.productErrors]
        productErrors[i].item = null

        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setQuantity(data, i) {
        const productElements = this.state.productElements
        productElements[i].quantity = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        const productErrors = [...this.state.productErrors]
        productErrors[i].quantity = null
        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setPrice(data, i) {
        const productElements = this.state.productElements
        productElements[i].price = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        const productErrors = [...this.state.productErrors]
        productErrors[i].price = null
        this.setState({
            productElements: productElements,
            productErrors,
        })
    }

    _setDiscount(data, i) {
        const productElements = this.state.productElements
        productElements[i].discount = data
        productElements[i].amount = this.calculateTotalAmount(productElements[i])
        this.setState({
            productElements: productElements,
        })
    }

    _setDescription(data, i) {
        const productElements = this.state.productElements
        productElements[i].description = data
        this.setState({
            productElements: productElements,
        })
    }

    addRow() {
        let productElements = this.state.productElements
        let productErrors = this.state.productErrors

        productElements.push({
            item: '',
            quantity: '',
            price: '',
            discount: '',
            tax: [],
            amount: '',
            description: '',
        })
        productErrors.push({
            item: null,
            quantity: null,
            price: null,
        })
        this.setState({
            productElements,
            productErrors,
        })
    }

    removeRow(index) {
        let productElements = this.state.productElements
        productElements.splice(index, 1)
        this.setState({
            productElements,
        })
    }

    loadIncomeProductService() {
        const { t } = this.props
        const productElements = this.state.productElements
        const data = productElements.map((item, index) => {
            return (
                <>
                    <tr>
                        <td className="w-25">
                            <Select
                                className="mb-3"
                                name="productList"
                                options={this.state.products}
                                placeholder={t('common:select')}
                                onChange={(data) => this._setProduct(data, index)}
                                searchable={true}
                            />
                            {this.state.productErrors[index].item && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].item}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                min={0}
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:quantity')}
                                value={this.state.productElements[index].quantity}
                                onChange={(key, val) => this._setQuantity(val, index)}
                            />
                            {this.state.productErrors[index].quantity && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].quantity}
                                </label>
                            )}
                        </td>
                        <td>
                            <Input
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('income:price')}
                                value={this.state.productElements[index].price}
                                onChange={(key, val) => this._setPrice(val, index)}
                            />

                            {this.state.productErrors[index].price && (
                                <label className="table-error error text-danger">
                                    {this.state.productErrors[index].price}
                                </label>
                            )}
                        </td>

                        <td>
                            <Input
                                type="text"
                                className="form-control mb-3"
                                id="heading"
                                placeholder={t('fee:discount')}
                                value={this.state.productElements[index].discount}
                                onChange={(key, val) => this._setDiscount(val, index)}
                            />
                        </td>

                        <td>
                            {item.defaultProduct &&
                                item.defaultProduct.selectedTax.map((item, i) => (
                                    <div key={i}>
                                        <span className="bg-light">
                                            {item.label} - {item.rate}
                                        </span>
                                    </div>
                                ))}
                        </td>
                        <td className={this.state.productElements[index].amount < 0 && 'text-danger'}>
                            {this.state.productElements[index].amount}
                        </td>
                        <td>
                            <div className="d-flex align-items-center mb-3">
                                <Button
                                    onClick={() => this.addRow()}
                                    type="button"
                                    text={SVG.addTable()}
                                    main={false}
                                />
                                <Button
                                    onClick={() => this.removeRow(index)}
                                    type="button"
                                    text={SVG.deleteComment()}
                                    main={false}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <TextArea
                                value={this.state.productElements[index].descprition}
                                onChange={(key, val) => this._setDescription(val, index)}
                                placeholder={t('common:description')}
                            />
                        </td>
                        <td colspan="5"></td>
                    </tr>
                </>
            )
        })
        return data
    }

    render() {
        const totalPrices = this.calculateTotalPrice(this.state.productElements)
        const totalDiscount = this.calculateTotalDiscount(this.state.productElements)
        const totalAmount = this.calculateFinalTotalAmount(this.state.productElements)
        const totalTaxAmount = this.calculateTotalTaxAmount(this.state.productElements)
        const { t } = this.props
        return (
            <div className="main-page">
                <Loading status={this.state.loading} text="Loading" />
                <Sidebar id="income" isAccount={true} />
                <div className="main-content">
                    <Header />
                    <div className="main-head-option">
                        <ul className="list-inline breadcrum clearfix">
                            <li>
                                <a href={route.admin.accounting.menu}>{t('sidebar:account')} &nbsp; &gt; </a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.income.menu}>{t('income:income')} &nbsp; &gt; &nbsp;</a>
                            </li>
                            <li>
                                <a href={route.admin.accounting.income.template.view}>
                                    {t('income:template')} &nbsp; &gt; &nbsp;{' '}
                                </a>
                            </li>
                            <li>{t('common:addBtn')}</li>
                        </ul>
                        <div className="row clearfix">
                            <div className="col-md-12 float-left">
                                <h5 className="main-title heading5">
                                    {t('income:createinvoice')} {t('income:template')}:
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="main-form">
                        <form>
                            <div class="row clearFix">
                                <div className="col-sm-6 float-left form-group mb-0">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('common:title')} *</label>
                                        <Input
                                            name="title"
                                            type="text"
                                            className="form-control"
                                            placeholder={t('common:title')}
                                            value={this.state.title}
                                            onChange={this._handleChange}
                                        />
                                        {this.state.errors.title && (
                                            <label className="error text-danger">{this.state.errors.title}</label>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6 float-left form-group mb-0">
                                    <div className="form-group">
                                        <label className="form-title text-left">{t('income:categorytype')} *</label>
                                        <Select
                                            name="selectedCategory"
                                            options={this.state.categoryList}
                                            placeholder={t('income:categorytype')}
                                            searchable={true}
                                            onChange={this._selectCategory}
                                        />
                                        {this.state.errors.category && (
                                            <label className="error text-danger">{this.state.errors.category}</label>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <UserFilter
                                hideEmployee={true}
                                showSubject={false}
                                showHeader={false}
                                onStudentSelect={this._selectStudent}
                                studentColumn={12}
                            />
                            {this.state.errors.customers && (
                                <span className="form-group">
                                    <label className="error text-danger">{this.state.errors.customers}</label>
                                </span>
                            )}
                        </form>
                    </div>
                    <>
                        <hr />
                        <div className="fee-title-btn">
                            <h6>{t('doubleentry:particular')}:</h6>
                            <Button onClick={() => this.addRow()} type="button" text={SVG.addTable()} main={false} />
                        </div>
                        <hr />
                        <div className="main-table">
                            <div className="table-all table-responsive">
                                <table className="table responsiveTable fee_table">
                                    <thead>
                                        <tr>
                                            <th>{t('income:items')} *</th>
                                            <th>{t('income:quantity')} *</th>
                                            <th>{t('income:price')} *</th>
                                            <th>{t('fee:discount')}</th>
                                            <th>{t('income:tax')}</th>
                                            <th className="text-end">
                                                {t('income:amount')}
                                                <br />
                                                <span className="text-danger smallfont">
                                                    {t('income:beforetaxdiscount')}
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.loadIncomeProductService()}</tbody>
                                    {this.state.productElements.length != 0 && (
                                        <tfoot className="tfootstrong">
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('fee:subTotal')} </strong>
                                                </td>
                                                <td className="text-end subTotal">
                                                <small>{this.props.school.currency}</small> &nbsp;{totalPrices.toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('fee:discount')} </strong>
                                                </td>
                                                <td className="text-end totalDiscount">
                                                <small>{this.props.school.currency}</small> &nbsp;{totalDiscount.toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="blue-text">
                                                    <strong> {t('accounting:total')}</strong>
                                                </td>
                                                <td
                                                    className={
                                                        totalAmount < 0
                                                            ? 'text-danger text-end totalAmount blue-text'
                                                            : 'text-end totalAmount blue-text'
                                                    }
                                                >
                                                    <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalAmount.toFixed(2)}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td></td>
                                                <td>
                                                    <strong>{t('income:tax')} </strong>
                                                </td>
                                                <td className="text-end totalTax">
                                                <small>{this.props.school.currency}</small> &nbsp;
                                                    {totalTaxAmount.toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                                {this.state.productElements.length == 0 && <p className="no-data"> {SVG.nodata()}</p>}
                            </div>
                        </div>
                    </>
                    <div className="form-button text-left">
                        <Button onClick={this.onSave} type="submit" text={t('common:savebtn')} main={true} />
                    </div>
                </div>
            </div>
        )
    }
}

const InvoiceTemplateAdd = withTranslation()(connect(mapStateToProps)(InvoiceTemplateAddComponent))
export { InvoiceTemplateAdd }
