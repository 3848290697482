import { get, post } from '../request'

export const viewFeeStructure = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-structure', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const saveFeeStructure = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/bill-structure/save/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFeeStructure = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-structure/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleFeeStructure = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-structure/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
/*End Fee Structure*/

/*Bill Schedule*/

/*End Bill Schedule*/
