import moment from 'moment'
import { Component } from 'react'
import * as validation from '~/helper/constant/validation'
import { API_SUCCESS } from '../../../../../helper/constant/api_status'
import { convertADtoBS } from '../../../../../helper/function/abstract'
import { viewIncomeExpenseSummary } from '../../../../../services/api/accounting/report'

export class AbstractIncomeExpenseSummaryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { t } = this.props
        this.state = {
            loading: false,
            year: moment().startOf('month').toDate(),
            duration: null,
            paymentList: [],
            billList: [],
            revenueList: [],
            invoiceList: [],
            errors: {
                year: null,
            },
            monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chartData: [],
            transactions: [],
        }
        this._handleChange = this._handleChange.bind(this)
        this.setInvoiceDetail = this.setInvoiceDetail.bind(this)
        this._onSearch = this._onSearch.bind(this)
    }
    componentDidMount() {
        this._onSearch()
    }

    _handleChange(key, data) {
        this.setState({ [key]: data })
    }

    setInvoiceDetail() {
        if (this.props.school.date_format == 'BS') {
            const year =
                convertADtoBS(this.state.year) != null
                    ? convertADtoBS(this.state.year).slice(0, -6) + ' BS'
                    : 'Invalid Date Range'
            this.setState({
                duration: year,
            })
        } else {
            const year = moment(this.state.year).format('yyyy-MM-DD')
            this.setState({
                duration: year.slice(0, -6),
            })
        }
    }

    _onSearch() {
        const errors = validation.required(Object.keys(this.state.errors), this.state)
        this.setState({ errors })
        const validate = Object.keys(errors).filter((item) => errors[item] != null)
        if (validate.length == 0) {
            this._handleChange('loading', true)
            const year = moment(this.state.year).format('yyyy')
            viewIncomeExpenseSummary(year, this.props.accountToken).then((response) => {
                this._handleChange('loading', false)
                console.log(response)
                const accountList = []
                const categoryList = []
                if (response.code == API_SUCCESS) {
                    const data = response.data.data
                    this.setState(
                        {
                            paymentList: data.paymentExpenseTotal,
                            billList: data.billExpenseTotal,
                            revenueList: data.revenueIncomeTotal,
                            invoiceList: data.invoiceIncomeTotal,
                            chartData: data.profit,
                        },
                        () => {
                            this.setInvoiceDetail()
                        }
                    )
                }
            })
        }
    }
}

export function mapStateToProps(state: Object) {
    return {
        token: state.token,
        accountToken: state.school.accounting_token,
        school: state.school.school,
    }
}
