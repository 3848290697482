import { get, post } from '../request'

export const viewFeeParticular = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-particular', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getActiveFeeParticular = (token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-particular/active', token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const addFeeParticular = (data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/bill-particular/add', token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const editFeeParticular = (id, data, token) => {
    return new Promise(function (resolve, reject) {
        try {
            post('accounting-management/fee/bill-particular/update/' + id, token, data).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const getFeeParticular = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-particular/edit/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}

export const toggleFeeParticular = (id, token) => {
    return new Promise(function (resolve, reject) {
        try {
            get('accounting-management/fee/bill-particular/status/' + id, token).then((response) => {
                resolve(response)
            })
        } catch ($e) {
            resolve(null)
        }
    })
}
