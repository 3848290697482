import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en',
        ns: [
            'common',
            'login',
            'drive',
            'academic',
            'school',
            'classes',
            'users',
            'student',
            'employee',
            'course',
            'timetable',
            'fee',
            'attendance',
            'calendar',
            'sidebar',
            'header',
            'activity',
            'todo',
            'exam',
            'setting',
            'user_filter',
            'student_panel',
            'circle',
            'notification',
            'chat',
            'invite',
            'subscription',
            'toaster',
            'alumni',
            'gamification',
            'grading',
            'reportcard',
            'asset',
            'productservice',
            'banking',
            'constant',
            'productstock',
            'vendor',
            'income',
            'expense',
            'doubleentry',
            'reports',
            'accounting',
            'library',
        ],
        defaultNS: 'common',
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
